<template>
  <div>
    <div style="margin: 20px">
      <v-form :disabled="isDisabled">
        <v-list dense class="ml-3">
          <v-list-item-group v-model="item2">
            <v-list-item :ripple="false" v-for="(myitem, i) in PersonalAllowanceList" :key="i">
              <v-row no-gutters>
                <v-col cols="12" lg="6" md="5" sm="4" xl="8">
                  <div class="d-flex">
                    <v-checkbox class="custom-checkbox24" color="#F99D20" v-model="myitem.check" @change="checkallowance(myitem)">
                    </v-checkbox>
                    <v-list-item-title v-if="myitem.pA_Type == 3">
                      {{ myitem.pA_Name + ", " + myitem.number_of_Percent + "%" + " not over than " + myitem.notoverthan }}
                    </v-list-item-title>
                    <v-list-item-title v-if="myitem.pA_Type != 3">
                      {{ myitem.pA_Name }}
                    </v-list-item-title>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  xl="1"
                  class="pt-3"
                  v-if="myitem.limit == 1"
                >
                  <v-text-field
                    color="#ACACAC"
                    single-line
                    v-model="myitem.actualperson"
                    @change="changeperson(myitem)"
                    hide-details
                    oninput="value=value.replace(/[^\d]/g,'')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xl="1" v-if="myitem.limit == 1">
                  <p class="pt-4 pl-2">{{ $t("Person") }}</p>
                </v-col>
                <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                  <v-text-field
                    color="#ACACAC"
                    hide-details
                    v-model="myitem.value"
                    :suffix="$t('THB')"
                    class="pt-3"
                    @change="Addallallowance()"
                    @keypress="onlyNumber"
                    :readonly="myitem.pA_Type != 2"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-list-item>
            <v-divider></v-divider>
            <div class="pt-3">
              <label class="pl-2" style="font-size: 18px">{{$t("AllowanceSummary")}}</label>
            </div>
            <v-list-item :ripple="false" class="py-3">
              <v-row no-gutters>
                <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                  <div class="d-flex pt-3">
                    <v-list-item-title>{{
                      $t("PersonalAllowanceyear")
                    }}</v-list-item-title>
                  </div>
                </v-col>
                <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                  <v-text-field
                    color="#ACACAC"
                    hide-details
                    v-model="totalamount"
                    readonly
                    :suffix="$t('THB')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="showWithId">
          <v-container class="pr-3">
            <v-btn
              class="pa-2 mt-2 mb-11 mr-5 text_transform_none commoncancelbtn"
              outlined
              v-show="CancleByID"
              @click="CancelEdit()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mt-2 mb-11 ml-5 text_transform_none commonsavebtn"
              v-show="SaveByID"
              :disabled="
                $route.path ==
                '/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax'
                  ? AddedDatalist.length != 0
                    ? permission.edit
                    : permission.add
                  : AddedDatalist.length != 0
                  ? permission_management.edit
                  : permission_management.add
              "
              @click="addpersonalallowance()"
              >{{ $t("save") }}</v-btn
            >

            <v-btn
              class="pa-2 mt-2 mb-11 text_transform_none commonsavebtn"
              v-show="UpdateByID"
              :disabled="
                $route.path ==
                '/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax'
                  ? permission.edit
                  : permission_management.edit
              "
              @click="EditForm()"
              >{{ $t("edit") }}</v-btn
            >
          </v-container>
        </div>
        <div v-else>
          <v-container>
            <v-btn
              class="pa-2 mt-2 mb-11 mr-5 text_transform_none"
              rounded
              v-show="CancleByCode"
              width="150"
              height="50px"
              @click="CancelEditCode()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="pa-2 mt-2 mb-11 mr-5 text_transform_none"
              rounded
              width="150"
              v-show="SaveByCode"
              height="50px"
              :disabled="
                $route.path ==
                '/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax'
                  ? permission.add
                  : permission_management.add
              "
              @click="addpersonalallowancewithcode()"
              >{{ $t("save") }}</v-btn
            >

            <v-btn
              color="#F99D20"
              class="pa-2 mt-2 mb-11 mr-5 text_transform_none"
              rounded
              v-show="UpdateByCode"
              width="150"
              height="50px"
              :disabled="
                $route.path ==
                '/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax'
                  ? permission.edit
                  : permission_management.edit
              "
              @click="EditFormCode()"
              >{{ $t("edit") }}</v-btn
            >
          </v-container>
        </div>
      </v-card-actions>
      <!-- Loading Dialog -->
      <v-dialog v-model="LoadingDialog" persistent width="300">
        <v-card color="#FFF4EB" dark>
          <v-card-text class="black--text">
            Loading Please Wait...
            <v-progress-linear
              indeterminate
              color="#F99D20"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import LogTrace from "@/function/Log.js";
import Axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import CheckViewAddEdit from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, CheckViewAddEdit],
  directives: {
    mask,
  },
  data() {
    return {
      permission: {
        add: true,
        edit: true,
      },
      permission_management: {
        add: true,
        edit: true,
      },
      item2: "",
      CancleByCode: false,
      SaveByCode: true,
      UpdateByCode: false,
      showWithId: true,
      CancleByID: false,
      isDisabled: false,
      LoadingDialog: true,
      SaveByID: true,
      UpdateByID: false,
      mask: "######",
      maskBefore: "#",
      maskAfter: "#",
      maskFatherMother: "#",
      maskSponsor: "#",
      limitBefore: 0,
      limitAfter: 0,
      fatherMotherLimit: 0,
      limitSponsor: 0,
      disable: {
        bornBeforeLimit: true,
        bornAfterLimit: true,
        taxExemption: true,
        fatherMotherLimit: true,
        sponsorLimit: true,
      },
      employee_ID: 0,
      reqEmp: {
        employee_ID: parseInt(this.$route.params.id),
      },
      personal: {
        bornBeforeLimit: "",
        bornAfterLimit: "",
        fatherMotherLimit: "",
        ProvidentAmt: "",
        healthInsurance: "",
        lifeInsurance: "",
        homeMortgage: "",
        retriment: "",
        longTerm: "",
        sponsor: "",
        privateFund: "",
        donation: "",
        domestic: "",
        spouseTax: "",
        socialSecurity: "",
        personalAllowance: "",
        bornBefore: "",
        bornAfter: "",
        taxExemption1: "",
        taxExemption2: "",
        husbandwife: "",
        disablePerson: "",
        exemptionFoeperson: "",
        fatherMother: "",
        healthForFather: "",
        healthForMother: "",
        allowanceName: "",
        allowanceAmount: "",
        totalAllowance: 0,
      },
      IncomeMonth: store.state.IncomeMonth,
      paymentList: [],
      check: {
        ProvidentAmt: false,
        healthInsurance: false,
        lifeInsurance: false,
        homeMortgage: false,
        retriment: false,
        longTerm: false,
        sponsor: false,
        privateFund: false,
        donation: false,
        domestic: false,
        spouseTax: false,
        socialSecurity: false,
        personalAllowance: false,
        bornBefore: false,
        bornAfter: false,
        taxExemption: false,
        husbandwife: false,
        disablePerson: false,
        exemptionFoeperson: false,
        fatherMother: false,
        healthForFather: false,
        healthForMother: false,
        allowanceName: false,
        allowanceAmount: false,
        totalAllowance: 0,
      },
      calculate: {
        bornBeforeLimit: 1,
        bornAfterLimit: 0,
        ProvidentAmt: 0,
        healthInsurance: 0,
        lifeInsurance: 0,
        homeMortgage: 0,
        retriment: 0,
        longTerm: 0,
        sponsor: 0,
        donation: 0,
        domestic: 0,
        spouseTax: 0,
        personalAllowance: 0,
        bornBefore: 0,
        bornAfter: 0,
        husbandwife: 0,
        disablePerson: 0,
        exemptionFoeperson: 0,
        fatherMother: 0,
        healthForFather: 0,
        healthForMother: 0,
        allowanceName: 0,
        allowanceAmount: 0,
        totalAllowance: 0,
      },
      routeId: parseInt(this.$route.params.id),
      personalList: [],
      tableTotal: 0,
      otherList: [],
      response: [],
      url: enurl.apiUrl,
      request: {
        company_ID: store.state.companyID,
      },
      images: {
        delete: require("@/assets/images/Mask Group 177.png"),
      },
      computer: "",
      beforeLimitedComputed: "",
      fnMLimitedComputed: "",
      sponsorLimitedComputed: "",
      PersonalAllowanceList: [],
      totalamount: null,
      totaltaxincome: null,
      SalaryList: [],
      AddedDatalist: [],
    };
  },

  computed: {
    bornBeforeComputed: {
      //
      get: function () {
        return this.beforeLimitedComputed;
      },
      set: function (v) {
        this.computingBeforeBorn(v);
      },
    },
    bornAfterComputed: {
      get: function () {
        return this.computer;
      },
      // setter
      set: function (v) {
        this.computing(v);
      },
    },
    fatherMotherComputed: {
      get: function () {
        return this.fnMLimitedComputed;
      },
      // setter
      set: function (v) {
        this.fnMcomputing(v);
      },
    },
    // taxExemption() {
    //   let tax = this.personal.taxExemption1
    //     .toString()
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    //   return tax;
    // },
    sponsorComputed: {
      get: function () {
        return this.sponsorLimitedComputed;
      },
      // setter
      set: function (v) {
        this.computingSponsor(v);
      },
    },

    getTotal() {
      let insurance = this.calculate.healthInsurance;
      let lifeInsurance = this.calculate.lifeInsurance;
      let homeMortgage = this.calculate.homeMortgage;
      let retriment = this.calculate.retriment;
      let sponsor = this.personal.sponsor.replace(/,/g, "");
      let privateFund = this.calculate.privateFund;
      let donation = this.calculate.donation;
      let domestic = this.calculate.domestic;
      let spouseTax = this.calculate.spouseTax;
      let personalAllowance = this.calculate.personalAllowance;
      let healthForFather = this.calculate.healthForFather;
      let healthForMother = this.calculate.healthForMother;

      let ProvidentAmt = this.calculate.ProvidentAmt;
      let socialSecurity = this.personal.socialSecurity.replace(/,/g, "");
      let bornAfter = this.personal.bornAfter.replace(/,/g, "");
      let bornBefore = this.personal.bornBefore.replace(/,/g, "");

      let taxExemption1 = this.personal.taxExemption1.replace(/,/g, "");
      let fatherMother = this.personal.fatherMother.replace(/,/g, "");
      let tableTotal = this.tableTotal;
      let total =
        (insurance ? parseInt(insurance) : 0) +
        (lifeInsurance ? parseInt(lifeInsurance) : 0) +
        (homeMortgage ? parseInt(homeMortgage) : 0) +
        (sponsor ? parseInt(sponsor) : 0) +
        (privateFund ? parseInt(privateFund) : 0) +
        (donation ? parseInt(donation) : 0) +
        (domestic ? parseInt(domestic) : 0) +
        (spouseTax ? parseInt(spouseTax) : 0) +
        (retriment ? parseInt(retriment) : 0) +
        (personalAllowance ? parseInt(personalAllowance) : 0) +
        (healthForFather ? parseInt(healthForFather) : 0) +
        (healthForMother ? parseInt(healthForMother) : 0) +
        (socialSecurity ? parseInt(socialSecurity) : 0) +
        (bornAfter ? parseInt(bornAfter) : 0) +
        (ProvidentAmt ? parseInt(ProvidentAmt) : 0) +
        (taxExemption1 ? parseInt(taxExemption1) : 0) +
        (bornBefore ? parseInt(bornBefore) : 0) +
        (fatherMother ? parseInt(fatherMother) : 0) +
        (tableTotal ? parseInt(tableTotal) : 0);
      return total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); //getting total
    },
  },

  watch: {
    "check.sponsor"(v) {
      this.showSponsoring(v);
    },
    "check.bornAfter"(v) {
      this.showBornAfter(v);
    },
    "check.bornBefore"(v) {
      this.showBornBefore(v);
    },
    "check.fatherMother"(v) {
      this.showFatherMother(v);
    },

    "personal.taxExemption1"(val) {
      if (val > 100000) {
        alert("Exceeds Limit Set");
        return (this.personal.taxExemption1 = "100000");
      }
    },
  },
  async mounted() {
    this.permission = this.CheckViewAddEdit(129, 130, 131);
    this.permission_management = this.CheckViewAddEdit(202, 203, 204);
    this.disable_btn();
    await this.getPersonal();
    this.GetPersonalAllowance();
    this.getFundDeduction();
    let id = parseInt(this.$route.params.id);
    let code = localStorage.getItem("empcode");
    if (id)
    {
      this.getIncomePersonal();
      this.getTableData();
      this.GetSalary();
      this.GetaddedDATA();
    }
    else if (!id && code)
    {
      this.showWithId = false;
      await this.changeCodeToId();
      this.getTableDataCode();
      this.GetSalarywithcode();
      this.GetaddedDATAwithcode();
      this.getIncomePersonalByCode();
    }
    else if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
    {
      this.getIncomePersonal();
      this.getTableData();
      this.GetSalary();
      this.GetaddedDATA();
    }
  },
  methods: {
    //permission
    disable_btn() {
      let self = this;
      if (self.$route.path == "/selfservice/PersonalProfile/PersonalIncomeTax")
      {
        if (self.permission.add == false)
        {
          self.permission.add = false;
        }
        if (self.permission.edit == false)
        {
          self.permission.edit = false;
        }
      }
      else
      {
        if (self.permission_management.add == false)
        {
          self.permission_management.add = false;
        }
        if (self.permission_management.edit == false)
        {
          self.permission_management.edit = false;
        }
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46)
      {
        $event.preventDefault();
      }
    },

    removecomma(x) {
      if (x != 0 && x != null)
      {
        return x.replace(/,/g, "");
      }
      else
      {
        return parseFloat(0).toFixed(2);
      }
    },

    checkallowance(ID) {
      //console.log("items", ID);
      if (ID.check == true)
      {
        if (ID.pA_Type == 1)
        {
          if (ID.limit == true)
          {
            let amount = 0;
            amount = this.PersonalAllowanceList[ID.ID].amount;
            if (amount.includes(",") == true)
            {
              amount = this.removecomma(amount);
            }
            this.PersonalAllowanceList[ID.ID].actualperson = 1;
            this.PersonalAllowanceList[ID.ID].value = this.PersonalAllowanceList[ID.ID].actualperson * parseInt(amount);
          }
          else
          {
            let amount = 0;
            amount = this.PersonalAllowanceList[ID.ID].amount;
            if (amount.includes(",") == true)
            {
              amount = this.removecomma(amount);
            }
            this.PersonalAllowanceList[ID.ID].value = parseInt(amount);
          }
        }
        if (ID.pA_Type == 3)
        {
          let percent = ID.number_of_Percent;
          let over = 0;
          over = ID.notoverthan;
          if (over.includes(",") == true)
          {
            over = this.removecomma(over);
          }
          let notover = parseInt(over);
          let amount = (percent / 100) * this.totaltaxincome * 12;
          if (amount > notover)
          {
            this.PersonalAllowanceList[ID.ID].value = parseFloat(notover);
          }
          else
          {
            this.PersonalAllowanceList[ID.ID].value = parseFloat(amount);
          }
        }
      }
      else
      {
        this.PersonalAllowanceList[ID.ID].actualperson = null;
        this.PersonalAllowanceList[ID.ID].value = 0;
      }
      this.Addallallowance();
      // console.log(check);
    },

    changeperson(person) {
      if (person.check == true)
      {
        // console.log(person.pA_Type)
        if (parseInt(person.actualperson) > person.number_of_People)
        {
          // console.log(person.pA_Type)
          alert("Exceeds limit set");
          this.PersonalAllowanceList[person.ID].actualperson = 1;
          let amount = this.PersonalAllowanceList[person.ID].amount;
          if (amount.includes(",") == true)
          {
            amount = this.removecomma(amount);
          }
          this.PersonalAllowanceList[person.ID].value = this.PersonalAllowanceList[person.ID].actualperson * parseInt(amount);
        }
        else
        {
          let amount = this.PersonalAllowanceList[person.ID].amount;
          if (amount.includes(",") == true)
          {
            amount = this.removecomma(amount);
          }
          this.PersonalAllowanceList[person.ID].value = parseInt(this.PersonalAllowanceList[person.ID].actualperson) * parseInt(amount);
        }
      }
      this.Addallallowance();
    },

    async GetSalary() {
      let self = this;
      let empID = 0;
      if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
      {
        empID = store.state.employeeID;
      }
      else if (this.$route.params.id != undefined)
      {
        empID = parseInt(self.$route.params.id);
      }
      self.total = 0;
      self.totalforcalculatetax = 0;
      let tempp = {
        employee_ID: empID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}Salary/GetSalaryInfo`, tempp).then(function (response)
      {
        self.SalaryList = response.data.data;
        self.totaltaxincome = parseFloat(self.SalaryList[0].totalIncome_Tax);
      });
    },

    async GetSalarywithcode() {
      let self = this;
      let empID = 0;
      empID = localStorage.empcode;
      self.total = 0;
      self.totalforcalculatetax = 0;
      let tempp = {
        employee_ID: empID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}Salary/GetnewsalaryinfowithEmpcode`,tempp).then(function (response) {
        self.SalaryList = response.data.data;
        self.totaltaxincome = parseFloat(self.SalaryList[0].totalIncome_Tax);
      });
    },

    Addallallowance() {
      let self = this;
      let total = 0;
      for (let i = 0; i < self.PersonalAllowanceList.length; i++)
      {
        if (self.PersonalAllowanceList[i].check == true)
        {
          if (self.PersonalAllowanceList[i].value != null)
          {
            total = total + parseFloat(self.PersonalAllowanceList[i].value);
          }
        }
        else
        {
          self.PersonalAllowanceList[i].value = 0;
        }
      }
      self.totalamount = total;
      localStorage.setItem("PerAllowance", total.toFixed(2));
    },

    addpersonalallowance() {
      let self = this;
      let temp = [];
      let addemp = [];
      let empID = 0;
      if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
      {
        empID = store.state.employeeID;
      }
      else if (this.$route.params.id != undefined)
      {
        empID = parseInt(self.$route.params.id);
      }
      for (let i = 0; i < self.PersonalAllowanceList.length; i++)
      {
        if (self.PersonalAllowanceList[i].check == true) 
        {
          let people = 0;
          if (self.PersonalAllowanceList[i].actualperson == null || self.PersonalAllowanceList[i].actualperson == "" )
          {
            people = 0;
          }
          else
          {
            people = self.PersonalAllowanceList[i].actualperson;
          }
          temp.push({
            pA_Master_ID: self.PersonalAllowanceList[i].pA_ID,
            pA_Check: self.PersonalAllowanceList[i].check,
            no_of_Person: parseInt(people),
            pA_Amount: parseFloat(self.PersonalAllowanceList[i].value),
          });
        }
      }
      addemp = {
        employee_ID: empID,
        company_ID: store.state.companyID,
        requestEmpwithlist: JSON.parse(JSON.stringify(temp)),
      };
      Axios.post(`${self.url}Emp_PersonalAllowance/AddEmp_PersonalWithEID`,addemp).then(function (response) {
        alert(response.data.message);
        self.SaveByID = false;
        self.UpdateByID = true;
        self.isDisabled = true;
        self.CancleByID = false;
      });
    },

    addpersonalallowancewithcode() {
      let self = this;
      let temp = [];
      let addemp = [];
      let empID = localStorage.empcode;
      localStorage.setItem("PerAllowance", self.totalamount.toFixed(2));
      for (let i = 0; i < self.PersonalAllowanceList.length; i++)
      {
        if (self.PersonalAllowanceList[i].check == true) {
          let people = 0;
          if (self.PersonalAllowanceList[i].actualperson == null || self.PersonalAllowanceList[i].actualperson == "" )
          {
            people = 0;
          }
          else
          {
            people = self.PersonalAllowanceList[i].actualperson;
          }
          temp.push({
            pA_Master_ID: self.PersonalAllowanceList[i].pA_ID,
            pA_Check: self.PersonalAllowanceList[i].check,
            no_of_Person: parseInt(people),
            pA_Amount: parseFloat(self.PersonalAllowanceList[i].value),
          });
        }
      }
      addemp = {
        employee_ID: empID,
        company_ID: store.state.companyID,
        requestEmpwithlist: JSON.parse(JSON.stringify(temp)),
      };
      Axios.post(`${self.url}Emp_PersonalAllowance/AddEmp_PersonalWithECode`,addemp).then(function (response) {
        alert(response.data.message);
        self.SaveByCode = false;
        self.UpdateByCode = true;
        self.isDisabled = true;
        self.CancleByCode = false;
      });
    },

    GetaddedDATA() {
      let self = this;
      let empID = 0;
      if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
      {
        empID = store.state.employeeID;
      }
      else if (this.$route.params.id != undefined)
      {
        empID = parseInt(self.$route.params.id);
      }
      let temp = {
        employee_ID: empID,
      };
      Axios.post(`${self.url}Emp_PersonalAllowance/GetEmpallowancewithID`,temp).then(function (response) {
        self.AddedDatalist = response.data.data;
        if (self.AddedDatalist.length != 0)
        {
          for (let i = 0; i < self.AddedDatalist.length; i++)
          {
            self.PersonalAllowanceList.map(function (data) {
              if (data.pA_ID == self.AddedDatalist[i].pA_Master_ID)
              {
                self.PersonalAllowanceList[data.ID].value = self.AddedDatalist[i].pA_Amount;
                self.PersonalAllowanceList[data.ID].check = self.AddedDatalist[i].pA_Check;
                self.PersonalAllowanceList[data.ID].actualperson = self.AddedDatalist[i].no_of_Person;
              }
            });
          }
          
          self.Addallallowance();
          self.SaveByID = false;
          self.UpdateByID = true;
          self.isDisabled = true;
          self.CancleByID = false;
        }
        //console.log("PersonalAllowanceList",self.PersonalAllowanceList)
      });
    },

    GetaddedDATAwithcode() {
      let self = this;
      let empID = 0;
      empID = localStorage.empcode;
      let temp = {
        employee_ID: empID,
      };
      Axios.post(`${self.url}Emp_PersonalAllowance/GetEmpallowancewithCode`,temp).then(function (response) {
        self.AddedDatalist = response.data.data;
        if (self.AddedDatalist.length != 0) {
          for (let i = 0; i < self.AddedDatalist.length; i++) {
            self.PersonalAllowanceList.map(function (data) 
            {
              if (data.pA_ID == self.AddedDatalist[i].pA_Master_ID) 
              {
                self.PersonalAllowanceList[data.ID].value = self.AddedDatalist[i].pA_Amount;
                self.PersonalAllowanceList[data.ID].check = self.AddedDatalist[i].pA_Check;
                self.PersonalAllowanceList[data.ID].actualperson = self.AddedDatalist[i].no_of_Person;
              }
            });
          }
          self.Addallallowance();
          self.SaveByCode = false;
          self.UpdateByCode = true;
          self.isDisabled = true;
          self.CancleByCode = false;
        }
      });
    },

    GetPersonalAllowance() {
      let self = this;
      // self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      Axios.post(`${self.url}PersonalAllowance/GetPersonalAllowance`,tempp).then(function (response) {
        let list = response.data.data;
        for (let i = 0; i < list.length; i++)
        {
          let temp = {
            ...list[i],
            check: false,
            ID: i,
            value: null,
            actualperson: null,
          };
          self.PersonalAllowanceList.push(temp);
        }
        // self.LoadingDialog = false;
      });
    },

    async changeCodeToId() {
      let Id = localStorage.getItem("empcode");
      let temp = {
        employee_Code: Id,
        company_ID: store.state.companyID,
      };
      const response = await Axios.post(`${this.url}PersonalInfo/GetPersonalInformationWithCode`,temp);
      this.employee_ID = response.data.data.employee_ID;
    },

    async getIncomePersonalByCode() {
      try
      {
        if (!this.$route.params.id)
        {
          let id = localStorage.getItem("empcode");
          let temp = {
            employee_Code: id,
            company_ID: store.state.companyID,
          };
          const response = await Axios.post(`${this.url}IncomeTaxPersonalAllowance/GetIncomeTaxPersonalAllowanceByEmployeeCode`,temp);
          this.paymentList = response.data.data;
          if (this.paymentList.length)
          {
            this.assignPayment(this.paymentList);
            this.SaveByCode = false;
            this.UpdateByCode = true;
            this.isDisabled = true;
            this.CancleByCode = false;
            //this.LogTrace(null, "Get Personal Allowance", 44, "Critical");
          }
        }
      } catch (error) {
        this.LogTrace(error, "Get Personal Allowance Fail", 42, "Critical");
        //alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    async SavePersonalCode() {
      try {
        this.LoadingDialog = true;
        localStorage.setItem("PerAllowance", this.getTotal.toFixed(2));
        let temp = this.tempMth();
        temp.employee_ID = this.employee_ID;
        this.addAllowanceTableCode();
        const response = await Axios.post(`${this.url}IncomeTaxPersonalAllowance/AddIncomeTaxPersonalAllowance`,temp);
        if (response.data.status == 0)
        {
          this.LoadingDialog = false;
          alert(response.data.message);
          this.getIncomePersonalByCode();
          //this.LogTrace(null, "Add Personal Allowance", 44, "Low");
        }
      } catch (error) {
        this.LogTrace(error, "Add Personal Allowance Fail", 44, "Low");
        alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    async addAllowanceTableCode() {
      try
      {
        let combileArray = this.otherList.map((x) => ({
          ...x,
          employee_ID: this.employee_ID,
        }));
        this.response = await Axios.post(`${this.url}Allowance_EMP/AddAllowance_EMP`,combileArray);
        //this.LogTrace(null, "Add Personal Allowance", 44, "Low");
      } catch (error) {
        this.LogTrace(error, "Add Personal Allowance Fail", 44, "Low");
        alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    async CancelEdit() {
      this.SaveByID = false;
      this.CancleByID = false;
      this.UpdateByID = true;
      this.isDisabled = true;
      this.getIncomePersonal();
      this.tableTotal = 0;
      await this.getTableData();
      this.assignPayment(this.paymentList);
      this.computingSponsor(this.sponsorLimitedComputed);
      this.computingBeforeBorn(this.beforeLimitedComputed);
      this.showBornAfter(this.limitAfter);
      this.showFatherMother(this.fatherMotherLimit);
    },

    CancelEditCode() {
      this.SaveByCode = false;
      this.CancleByCode = false;
      this.UpdateByCode = true;
      this.isDisabled = true;
      this.getIncomePersonalByCode();
    },

    EditFormCode() {
      this.SaveByCode = true;
      this.CancleByCode = true;
      this.UpdateByCode = false;
      this.isDisabled = false;
    },

    EditForm() {
      this.SaveByID = true;
      this.CancleByID = true;
      this.UpdateByID = false;
      this.isDisabled = false;
    },

    async getIncomePersonal() {
      try {
        let temp = {};
        if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
        {
          temp = { employee_ID: store.state.employeeID };
        }
        else if (this.$route.params.id != undefined)
        {
          temp = { ...this.reqEmp };
        }
        const response = await Axios.post(`${this.url}IncomeTaxPersonalAllowance/GetIncomeTaxPersonalAllowanceByEmployeeID`,temp);
        this.paymentList = response.data.data;
        if (this.paymentList.length)
        {
          this.assignPayment(this.paymentList);
          this.SaveByID = false;
          this.UpdateByID = true;
          this.isDisabled = true;
          this.CancleByID = false;
          //this.LogTrace(null, "Get Personal Allowance", 44, "Critical");
        }
      } catch (error) {
        this.LogTrace(error, "Get Personal Allowance Fail", 42, "Critical");
        //alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    async getTableData() {
      try
      {
        let temp = {};
        if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
        {
          temp = { employee_ID: store.state.employeeID, ...this.request };
        }
        else if (this.$route.params.id != undefined)
        {
          temp = { ...this.reqEmp, ...this.request };
        }
        const response = await Axios.post(`${this.url}Allowance_EMP/GetAllowance_EMPByEIDCID`,temp);
        let list = response.data.data;
        if (list.length)
        {
          this.otherList = list;
          this.otherList.map((x) => {
            this.tableTotal += parseInt(x.amount);
          });
          //this.LogTrace(null, "Get Personal Allowance", 44, "Critical");
        }
      } catch (error) {
        this.LogTrace(error, "Get Personal Allowance Fail", 42, "Critical");
        //alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    async getTableDataCode() {
      try 
      {
        if (!this.$route.params.id)
        {
          const temp = {
            employee_ID: this.employee_ID,
            company_ID: this.$store.companyID,
          };
          const response = await Axios.post(`${this.url}Allowance_EMP/GetAllowance_EMPByEIDCID`,temp);
          let list = response.data.data;
          if (list.length) 
          {
            this.otherList = list;
            this.otherList.map((x) => {
              this.tableTotal += parseInt(x.amount);
            });
           // this.LogTrace(null, "Get Personal Allowance", 44, "Critical");
          }
        }
      } catch (error) {
        this.LogTrace(error, "Get Personal Allowance Fail", 42, "Critical");
        //alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    assignPayment(paymentList) {
      let { check } = this;
      let { personal } = this;
      check.ProvidentAmt = paymentList[0].provident_Fund;
      check.healthInsurance = paymentList[0].healthInsurance;
      check.lifeInsurance = paymentList[0].lifeInsurance;
      check.homeMortgage = paymentList[0].homeMortgageInterest;
      check.retriment = paymentList[0].rmf;
      check.longTerm = paymentList[0].ltf;
      check.sponsor = paymentList[0].sponsoring;
      this.sponsorLimitedComputed = !paymentList[0].sponsoring_Person ? "" : paymentList[0].sponsoring_Person;
      check.privateFund = paymentList[0].privateFund;
      check.donation = paymentList[0].donation;
      check.domestic = paymentList[0].domesticTourism;
      check.spouseTax = paymentList[0].spouseTaxCalculation;
      check.socialSecurity = paymentList[0].ssFund;
      check.personalAllowance = paymentList[0].pa;
      check.bornBefore = paymentList[0].bornBefore;
      this.beforeLimitedComputed = !paymentList[0].bornBefore_Child ? "" : paymentList[0].bornBefore_Child;
      check.bornAfter = paymentList[0].bornAfter;
      this.computer = paymentList[0].bornAfter_Child == 0 ? "" : paymentList[0].bornAfter_Child;
      check.taxExemption = paymentList[0].tax_ExemptionCHK;
      personal.taxExemption1 = paymentList[0].tax_Exemption;
      check.taxExemption = paymentList[0].tax_ExemptionCHK;
      personal.taxExemption1 = paymentList[0].tax_Exemption;
      check.fatherMother = paymentList[0].fatherMotherAllowanceCHK;
      this.fnMLimitedComputed = !paymentList[0].fatherMotherAllowance_Person ? "" : paymentList[0].fatherMotherAllowance_Person;
      check.healthForFather = paymentList[0].fatherHealthInsuranceCHK;
      check.healthForMother = paymentList[0].motherHealthInsuranceCHK;
      if (paymentList[0].provident_Fund)
      {
        this.getProvidentFund();
      }
      this.showTaxExemption();
      this.getPersonal();
      this.calculatePersonal();
      this.getSocialFund();
    },

    tempMth() {
      let temp = { ...this.check };
      let obj = {
        provident_Fund: temp.ProvidentAmt,
        healthInsurance: temp.healthInsurance,
        lifeInsurance: temp.lifeInsurance,
        homeMortgageInterest: temp.homeMortgage,
        rmf: temp.retriment,
        ltf: temp.longTerm,
        sponsoring: temp.sponsor,
        sponsoring_Person: !this.sponsorLimitedComputed ? 0 : parseInt(this.sponsorLimitedComputed),
        privateFund: temp.privateFund,
        donation: temp.donation,
        domesticTourism: temp.domestic,
        spouseTaxCalculation: temp.spouseTax,
        ssFund: temp.socialSecurity,
        pa: temp.personalAllowance,
        bornBefore: temp.bornBefore,
        bornBefore_Child: !this.beforeLimitedComputed ? 0 : this.beforeLimitedComputed,
        bornAfter: temp.bornAfter,
        bornAfter_Child: !this.computer ? 0 : parseInt(this.computer),
        tax_ExemptionCHK: temp.taxExemption,
        tax_Exemption: this.personal.taxExemption1,
        fatherMotherAllowanceCHK: temp.fatherMother,
        fatherMotherAllowance_Person: !this.fnMLimitedComputed ? 0 : parseInt(this.fnMLimitedComputed),
        fatherHealthInsuranceCHK: temp.healthForFather,
        fatherHealthInsurance: this.getTotal,
        motherHealthInsuranceCHK: temp.healthForMother,
        motherHealthInsurance: "1",
        employee_ID: parseInt(this.$route.params.id),
      };
      return obj;
    },

    async AddIncomeaAllowance() {
      try
      {
        this.LoadingDialog = true;
        let temp = this.tempMth();
        if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
        {
          temp.employee_ID = store.state.employeeID;
        }
        else if (this.$route.params.id != undefined)
        {
          temp.employeeID = this.reqEmp;
        }
        this.addAllowanceTable();
        const response = await Axios.post(`${this.url}IncomeTaxPersonalAllowance/AddIncomeTaxPersonalAllowance`,temp);
        if (response.data.status == 0)
        {
          alert(response.data.message);
          this.LoadingDialog = false;
          this.getIncomePersonal();
          //this.LogTrace(null, "Add Personal Allowance", 44, "Low");
        }
      } catch (error) {
        this.LogTrace(error, "Add Personal Allowance Fail", 44, "Low");
        alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    AddingNewItem() {
      if (this.personal.allowanceName && this.personal.allowanceAmount)
      {
        this.tableTotal = 0;
        this.otherList.unshift({
          allowance_Name: this.personal.allowanceName,
          amount: this.personal.allowanceAmount});
        for (let i = 0; i < this.otherList.length; i++)
        {
          this.tableTotal += parseInt(this.otherList[i].amount);
        }
        (this.personal.allowanceName = null),
        (this.personal.allowanceAmount = null);
      }
      else
      {
        alert("Fill at least one data");
      }
    },

    async addAllowanceTable() {
      try 
      {
        let combileArray = null;
        this.otherList;
        if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
        {
          combileArray = this.otherList.map((x) => ({
            ...x,
            company_ID: store.state.companyID,
            employee_ID: store.state.employeeID,
          }));
        }
        else if (this.$route.params.id != undefined)
        {
          combileArray = this.otherList.map((x) => ({
            ...x,
            company_ID: store.state.companyID,
            employee_ID: parseInt(this.$route.params.id),
          }));
        }
        this.response = await Axios.post(`${this.url}Allowance_EMP/AddAllowance_EMP`,combileArray);
       // this.LogTrace(null, "Add Personal Allowance", 44, "Low");
      } catch (error) {
        this.LogTrace(error, "Add Personal Allowance Fail", 44, "Low");
        alert(error + "Correlation ID is 44");
        throw error;
      }
    },

    DeleteNewItem(v) {
      this.tableTotal = 0;
      let a = this.otherList.indexOf(v);
      this.otherList.splice(a, 1);
      for (let i = 0; i < this.otherList.length; i++)
      {
        this.tableTotal += parseInt(this.otherList[i].amount);
      }
    },

    showTaxExemption() {
      if (this.check.taxExemption)
      {
        this.personal.taxExemption2 = "100,000";
        this.disable.taxExemption = false;
      }
      else
      {
        this.personal.taxExemption2 = "";
        this.personal.taxExemption1 = "";
        this.disable.taxExemption = true;
      }
    },

    async getFundDeduction() {
      try
      {
        const response = await Axios.post(`${this.url}FundDeduction/GetFundDeduction`,this.request);
        this.fundList = response.data.data;
      } catch (error) {
        throw error;
      }
    },

    showsocialSecurity() {
      if (this.IncomeMonth)
      {
        this.getSocialFund();
      }
      else
      {
        this.check.socialSecurity = false;
        alert("Please fill in Salary details");
      }
    },

    async getSocialFund() {
      try
      {
        const response = await Axios.post(`${this.url}SocialSecurityFund/GetSocialSecurityFund`,this.request);
        let fund = response.data.data;
        let value = fund[0].amount;
        var social = 0;
        if (this.check.socialSecurity)
        {
          if (this.IncomeMonth < 15000 && this.fundList.length)
          {
            let percentage = this.fundList[0].percentage;
            social = ((this.IncomeMonth * percentage) / 100) * 12;
          }
          else
          {
            social = parseInt(value) * 12;
          }
          this.personal.socialSecurity = social.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }
        else
        {
          this.personal.socialSecurity = "";
        }
      } catch (error) {
        throw error;
      }
    },

    async getProvidentFund() {
      try
      {
        let provident_Fund = localStorage.getItem("providentFund");
        let id = parseInt(this.$route.params.id);
        if (this.$route.params.id)
        {
          let temp = {};
          if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax")
          {
            temp = { employee_ID: store.state.employeeID };
          }
          else if (this.$route.params.id != undefined)
          {
            temp = { ...this.reqEmp };
          }
          const response = await Axios.post(`${this.url}Emp_Provident/GetEmp_Provident`,temp);
          let fund = response.data.data;
          if (fund.length)
          {
            if (this.check.ProvidentAmt)
            {
              let value = fund[0].emp_Cumulative_Amt;
              if (value)
              {
                let amount = parseInt(value) * 12;
                this.personal.ProvidentAmt = amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                this.calculate.ProvidentAmt = parseInt(amount);
              }
              else
              {
                this.personal.ProvidentAmt = "-";
                this.calculate.ProvidentAmt = 0;
              }
            }
            else
            {
              this.personal.ProvidentAmt = "";
              this.calculate.ProvidentAmt = "";
            }
          }
          else
          {
            alert("Provident fund is not collected");
            this.check.ProvidentAmt = false;
          }
        }
        else if (!id)
        {
          await this.getFunByCode(provident_Fund);
        }
      } catch (error) {
        throw error;
      }
    },

    getFunByCode(provident_Fund) {
      if (provident_Fund)
      {
        if (this.check.ProvidentAmt)
        {
          let value = provident_Fund;
          let amount = parseInt(value) * 12;
          this.personal.ProvidentAmt = amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          this.calculate.ProvidentAmt = parseInt(amount);
        }
        else
        {
          this.personal.ProvidentAmt = "";
        }
      }
      else
      {
        if (this.check.ProvidentAmt)
        {
          alert("Provident fund is not collected");
        }
        this.check.ProvidentAmt = false;
      }
    },

    showBornAfter(v) {
      if (v)
      {
        this.computer = this.computer > 1 ? this.computer : 1;
        this.disable.bornAfterLimit = false;
        for (let i = 0; i < this.personalList.length; i++)
        {
          if (this.personalList[i].pA_Type == "Child Allowance per child (born after 2018)")
          {
            let bornAfterList = this.personalList[i].amount;
            let cal = bornAfterList.replace(/,/g, "");
            this.limitAfter = this.personalList[i].limit;
            let total = parseInt(cal) * parseInt(this.computer);
            this.calculate.bornAfter = bornAfterList;
            let bornAfter = total ? total : bornAfterList;
            this.personal.bornAfter = bornAfter.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
        }
      }
      else
      {
        this.personal.bornAfter = "";
        this.computer = "";
        this.disable.bornAfterLimit = true;
      }
    },

    showBornBefore(v) {
      if (v)
      {
        this.beforeLimitedComputed = this.beforeLimitedComputed > 1 ? this.beforeLimitedComputed : 1;
        this.disable.bornBeforeLimit = false;
        for (let i = 0; i < this.personalList.length; i++)
        {
          if (this.personalList[i].pA_Type == "Child Allowance per child")
          {
            let bornBeforeList = this.personalList[i].amount;
            let cal = bornBeforeList.replace(/,/g, "");
            this.limitBefore = this.personalList[i].limit;
            let total = parseInt(cal) * parseInt(this.beforeLimitedComputed);
            this.calculate.bornBefore = bornBeforeList;
            let bornBefore = total ? total : bornBeforeList;
            this.personal.bornBefore = bornBefore.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
        }
      }
      else
      {
        this.personal.bornBefore = "";
        this.beforeLimitedComputed = "";
        this.disable.bornBeforeLimit = true;
      }
    },

    showSponsoring(v) {
      if (v) {
        this.sponsorLimitedComputed = this.sponsorLimitedComputed > 1 ? this.sponsorLimitedComputed : 1;
        this.disable.sponsorLimit = false;
        for (let i = 0; i < this.personalList.length; i++)
        {
          if (this.personalList[i].pA_Type == "Sponsoring a disabled person")
          {
            let sponsorList = this.personalList[i].amount;
            let cal = sponsorList.replace(/,/g, "");
            this.limitSponsor = this.personalList[i].limit;
            let total = parseInt(cal) * parseInt(this.sponsorLimitedComputed);
            this.calculate.sponsor = sponsorList;
            let sponsor = total ? total : sponsorList;
            this.personal.sponsor = sponsor.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
        }
      }
      else
      {
        this.personal.sponsor = "";
        this.sponsorLimitedComputed = "";
        this.disable.sponsorLimit = true;
      }
    },

    showFatherMother(v) {
      if (v)
      {
        this.fnMLimitedComputed = this.fnMLimitedComputed > 1 ? this.fnMLimitedComputed : 1;
        this.disable.fatherMotherLimit = false;
        for (let i = 0; i < this.personalList.length; i++)
        {
          if (this.personalList[i].pA_Type == "Father/Mother Allowance")
          {
            let fatherMotherList = this.personalList[i].amount;
            let cal = fatherMotherList.replace(/,/g, "");
            this.fatherMotherLimit = this.personalList[i].limit;
            let total = parseInt(cal) * parseInt(this.fnMLimitedComputed);
            this.calculate.fatherMother = fatherMotherList;
            let fatherMother = total ? total : fatherMotherList;
            this.personal.fatherMother = fatherMother.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
        }
      }
      else
      {
        this.personal.fatherMother = "";
        this.fnMLimitedComputed = "";
        this.disable.fatherMotherLimit = true;
      }
    },

    computingBeforeBorn(v) {
      if (v > this.limitBefore)
      {
        let insurance = this.beforeLimitedComputed;
        this.beforeLimitedComputed = v ? insurance : 0;
        alert("Exceeds limit set");
        this.beforeLimitedComputed = this.limitBefore;
      }
      else
      {
        this.beforeLimitedComputed = v;
      }
      let value = this.calculate.bornBefore.replace(/,/g, "");
      let lastValue = v > this.limitBefore ? this.limitBefore : v;
      value = parseInt(value) * parseInt(lastValue);
      this.personal.bornBefore = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      this.personal.bornBefore = v ? this.personal.bornBefore : "";
    },

    computing(v) {
      if (v > this.limitAfter)
      {
        let insurance = this.computer;
        this.computer = v ? insurance : 0;
        this.computer = this.limitAfter;
        alert("Exceeds limit set");
      }
      else
      {
        this.computer = v;
      }
      let value = this.calculate.bornAfter.replace(/,/g, "");
      let lastValue = v > this.limitAfter ? this.limitAfter : v;
      value = parseInt(value) * parseInt(lastValue);
      this.personal.bornAfter = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      this.personal.bornAfter = v ? this.personal.bornAfter : "";
    },

    fnMcomputing(v) {
      if (v > this.fatherMotherLimit)
      {
        let insurance = this.fnMLimitedComputed;
        this.fnMLimitedComputed = v ? insurance : 0;
        this.fnMLimitedComputed = this.fatherMotherLimit;
        alert("Exceeds limit set");
      }
      else
      {
        this.fnMLimitedComputed = v;
      }
      let value = this.calculate.fatherMother.replace(/,/g, "");
      let lastValue = v > this.fatherMotherLimit ? this.fatherMotherLimit : v;
      value = parseInt(value) * parseInt(lastValue);
      this.personal.fatherMother = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      this.personal.fatherMother = v ? this.personal.fatherMother : "";
    },

    computingSponsor(v) {
      if (v > this.limitSponsor)
      {
        let insurance = this.sponsorLimitedComputed;
        this.sponsorLimitedComputed = v ? insurance : 0;
        alert("Exceeds limit set");
        this.sponsorLimitedComputed = this.limitSponsor;
      }
      else
      {
        this.sponsorLimitedComputed = v;
      }
      let value = this.calculate.sponsor.replace(/,/g, "");
      let lastValue = v > this.limitSponsor ? this.limitSponsor : v;
      value = parseInt(value) * parseInt(lastValue);
      this.personal.sponsor = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      this.personal.sponsor = v ? this.personal.sponsor : "";
    },

    showValue() {
      this.calculatePersonal();
    },

    async getPersonal() {
      try 
      {
        this.LoadingDialog = true;
        const response = await Axios.post(`${this.url}PersonalAllowance/GetPersonalAllowance`,this.request);
        this.personalList = response.data.data;
        this.LoadingDialog = false;
      } catch (error) {
        throw error;
      }
    },

    calculatePersonal() {
      for (let i = 0; i < this.personalList.length; i++)
      {
        if (this.personalList[i].pA_Type == "Insurance per year")
        {
          let gpf = this.personalList[i].amount;
          this.personal.healthInsurance = gpf.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.healthInsurance)
          {
            this.personal.healthInsurance = "";
            if (!this.personal.healthInsurance)
            {
              this.calculate.healthInsurance = 0;
            }
          }
          else if (this.check.healthInsurance)
          {
            if (gpf == "-")
            {
              this.calculate.healthInsurance = 0;
            }
            else
            {
              this.calculate.healthInsurance = gpf;
            }
          }
        }
        
        if (this.personalList[i].pA_Type == "Annuity insurance premium")
        {
          let gpf = this.personalList[i].amount;
          this.personal.lifeInsurance = gpf.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.lifeInsurance)
          {
            this.personal.lifeInsurance = "";
            if (!this.personal.lifeInsurance)
            {
              this.calculate.lifeInsurance = 0;
            }
          }
          else if (this.check.lifeInsurance)
          {
            if (gpf == "-")
            {
              this.calculate.lifeInsurance = 0;
            }
            else
            {
              this.calculate.lifeInsurance = gpf.replace(/,/g, "");
            }
          }
        }
        
        if (this.personalList[i].pA_Type == "Home Mortgage interest")
        {
          let gpf = this.personalList[i].amount;
          this.personal.homeMortgage = this.getProvidentFund.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.homeMortgage)
          {
            this.personal.homeMortgage = "";
            if (!this.personal.homeMortgage)
            {
              this.calculate.homeMortgage = 0;
            }
          }
          else if (this.check.homeMortgage)
          {
            if (gpf == "-")
            {
              this.calculate.homeMortgage = 0;
            }
            else
            {
              this.calculate.homeMortgage = gpf.replace(/,/g, "");
            }
          }
        }
        
        if (this.personalList[i].pA_Type == "Retirement Mutual Fund (RMF)")
        {
          let gpf = this.personalList[i].amount;
          this.personal.retriment = gpf.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.retriment)
          {
            this.personal.retriment = "";
            if (!this.personal.retriment)
            {
              this.calculate.retriment = 0;
            }
          }
          else if (this.check.retriment)
          {
            if (gpf == "-")
            {
              this.calculate.retriment = 0;
            }
            else
            {
              this.calculate.retriment = gpf.replace(/,/g, "");
            }
          }
        }
        
        if (this.personalList[i].pA_Type == "Long term equity fund(LTF)")
        {
          let gpf = this.personalList[i].amount;
          this.personal.longTerm = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.longTerm) {
            this.personal.longTerm = "";
            if (!this.personal.longTerm) {
              this.calculate.longTerm = 0;
            }
          } else if (this.check.longTerm) {
            if (gpf == "-") {
              this.calculate.longTerm = 0;
            } else {
              this.calculate.longTerm = gpf.replace(/,/g, "");
            }
          }
        }

        if (this.personalList[i].pA_Type == "Private Fund ") {
          let gpf = this.personalList[i].amount;
          this.personal.privateFund = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.privateFund) {
            this.personal.privateFund = "";
            if (!this.personal.privateFund) {
              this.calculate.privateFund = 0;
            }
          } else if (this.check.privateFund) {
            if (gpf == "-") {
              this.calculate.privateFund = 0;
            } else {
              this.calculate.privateFund = gpf.replace(/,/g, "");
            }
          }
        }

        if (this.personalList[i].pA_Type == "Donation") {
          let gpf = this.personalList[i].amount;
          this.personal.donation = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.donation) {
            this.personal.donation = "";
            if (!this.personal.donation) {
              this.calculate.donation = 0;
            }
          } else if (this.check.donation) {
            if (gpf == "-") {
              this.calculate.donation = 0;
            } else {
              this.calculate.donation = gpf.replace(/,/g, "");
            }
          }
        }

        if (this.personalList[i].pA_Type == "Domestic Tourism") {
          let gpf = this.personalList[i].amount;
          this.personal.domestic = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.domestic) {
            this.personal.domestic = "";
            if (!this.personal.domestic) {
              this.calculate.domestic = 0;
            }
          } else if (this.check.domestic) {
            if (gpf == "-") {
              this.calculate.domestic = 0;
            } else {
              this.calculate.domestic = gpf.replace(/,/g, "");
            }
          }
        }

        if (this.personalList[i].pA_Type == "Spouse") {
          let gpf = this.personalList[i].amount;
          this.personal.spouseTax = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.spouseTax) {
            this.personal.spouseTax = "";
            if (!this.personal.spouseTax) {
              this.calculate.spouseTax = 0;
            }
          } else if (this.check.spouseTax) {
            if (gpf == "-") {
              this.calculate.spouseTax = 0;
            } else {
              this.calculate.spouseTax = gpf.replace(/,/g, "");
            }
          }
        }

        if (this.personalList[i].pA_Type == "Personal Allowance") {
          let gpf = this.personalList[i].amount;
          this.personal.personalAllowance = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.personalAllowance) {
            this.personal.personalAllowance = "";
            if (!this.personal.personalAllowance) {
              this.calculate.personalAllowance = 0;
            }
          } else if (this.check.personalAllowance) {
            if (gpf == "-") {
              this.calculate.personalAllowance = 0;
            } else {
              this.calculate.personalAllowance = gpf.replace(/,/g, "");
            }
          }
        }

        if (this.personalList[i].pA_Type == "Health insurance for father") {
          let gpf = this.personalList[i].amount;
          this.personal.healthForFather = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.healthForFather) {
            this.personal.healthForFather = "";
            if (!this.personal.healthForFather) {
              this.calculate.healthForFather = 0;
            }
          } else if (this.check.healthForFather) {
            if (gpf == "-") {
              this.calculate.healthForFather = 0;
            } else {
              this.calculate.healthForFather = gpf.replace(/,/g, "");
            }
          }
        }
        
        if (this.personalList[i].pA_Type == "Health insurance for mother") {
          let gpf = this.personalList[i].amount;
          this.personal.healthForMother = gpf
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          if (!this.check.healthForMother) {
            this.personal.healthForMother = "";
            if (!this.personal.healthForMother) {
              this.calculate.healthForMother = 0;
            }
          } else if (this.check.healthForMother) {
            if (gpf == "-") {
              this.calculate.healthForMother = 0;
            } else {
              this.calculate.healthForMother = gpf.replace(/,/g, "");
            }
          }
        }
      }
      this.personal.healthForMother;
      this.calculate.healthForMother;
    },
  },
};
</script>