<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom:2.5rem;">
    <p class="txt-header">
      {{ $t("synctimedata") }}
    </p>

    <div class="mt-6 pb-4">
      <v-card height="100%" class="pb-8">
        <div class="mx-7 pt-6">
          <v-row class="ml-1">
            <v-col cols="12" lg="3" xl="2" md="12" sm="12">
              <v-row>
                <v-tabs v-model="modelTab" show-arrows grey--text class="mt-5 custom-tab" slider-color="#F99D20">
                  <v-tab :disabled="thisone" @click="clickjarviz()" class="text-capitalize jarviz-tab">
                    Jarviz</v-tab>
                  <v-tab @click="showjarvizz = false" class="text-capitalize customized-tab">{{
                    $t("Customized")
                  }}</v-tab>
                </v-tabs>
              </v-row>
            </v-col>
            <v-col cols="12" v-show="!showjarvizz" lg="6" xl="8" sm="8">
              <v-row>
                <p class="mt-6">{{ $t("selecttemplate") }} :</p>
                <v-col lg="3" xl="3" sm="4">
                  <v-select v-model="templateselected" :items="ImportNameList" item-text="name" item-value="val" outlined
                    dense color="#F99D20" class="name mt-1" v-on:change="getthatsyncid(templateselected)"
                    style="font-size: 14px">
                    <template v-slot:item="{ item }">
                      {{ item.name }}
                      <v-spacer></v-spacer>
                      <v-btn v-if="item.val != 0" @click="ShowIt(item.val)" icon>
                        <img :src="images.editpic" />
                      </v-btn>
                      <v-btn v-show="item.show" @click="OpenDeletedialog(item.val)" icon>
                        <img :src="images.deletepic" />
                      </v-btn>
                    </template>
                  </v-select>
                </v-col>
                <span>
                  <v-badge class="batman mt-1" color="white" :value="hover2" grow transition="slide-y-transition">
                    <template v-slot:badge>
                      <div class="my-badge">{{ $t("createNT") }}</div>
                    </template>
                    <v-hover v-model="hover2">
                      <v-btn class="mt-3" icon @click="thenewtempdialogopener">
                        <img style="width: 35px; height: 35px;" :src="images.addpic" />
                      </v-btn>
                    </v-hover>
                  </v-badge>
                </span>
              </v-row>

            </v-col>
            <v-col cols="12" v-show="showjarvizz" lg="6" xl="8" sm="8">
              <p style="color:#0FA7D8; margin-top:13px;">{{ $t("jarviztxt") }}</p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col lg="2" xl="1" sm="4" class="d-flex justify-lg-end">
              <v-btn :disabled="permission.retrieve" color="#F99D20" class="text-capitalize rebtn mt-1" max-width="170"
                max-height="35" @click="retrievingrshow" block outlined>
                {{ $t("retrieve") }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="tableShow">
            <v-col cols="4">
              <p class="mb-0">
                {{ $t("Total") }} : <span>{{ thelisttotal }}</span>
              </p>
              <p class="mb-0">
                {{ $t("date") }} : <span>{{ theliistdate }}</span>
              </p>
              <p class="mb-0">
                {{ $t("selectedDep") }} : <span>{{ theliistDep }}</span>
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" lg="6">
              <v-row class="mt-5">
                <v-col class="mt-1" cols="12" lg="3" xl="3" sm="12">
                  <v-btn :disabled="permission.retrieve" color="#707070" class="text-capitalize rebtn mt-1 mr-2"
                    min-width="110" max-height="35" block outlined @click="Canceldialog = true">
                    {{ $t("Cancel") }}</v-btn>
                </v-col>
                <v-col class="mt-1" align="center" justify="center" cols="12" lg="9" xl="9" sm="12">
                  <v-btn min-width="110" color="#707070" block
                    class="white--text text-capitalize btn-multiline mt-1 addbtn"
                    style="font-weight: normal; overflow: hidden;" max-height="35" @click="CheckDuplicate"
                    :disabled="permission.add || noTESetting">
                    <img width="30" height="20" src="@/assets/images/Mask Group 99@2x.png" class="pr-2" />
                    <span class="text-wrap ma-auto">{{ $t("addtoSTAR") }}</span></v-btn>
                  <p v-show="noTESetting" style="
                        color: #ff0000;
                        text-decoration: underline;
                        cursor: pointer;
                      " @click="gotoTimeEntriesSet()">
                    "{{ $t("TimeEntiresSettingAlert") }}"
                  </p>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </div>
        <v-card color="#F5F5F5" class="ml-7 mr-7 mb-7 mt-3" min-height="65vh" elevation="3">
          <template v-if="tableShow">
            <v-data-table :headers="headers" :items="combileArray" hide-default-footer :page.sync="page"
              :items-per-page="itemsPerPage" @page-count="pageCount = $event" :mobile-breakpoint="0"
              class="mt-4 gaga"></v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination v-model="page" :length="pageCount" color="#FFCD2C"></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                    :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-card>
        <v-row></v-row>
      </v-card>
    </div>
    <div v-if="ErrorArray.length" class="mt-6 pb-4">
      <v-card class="mb-4" height="100%" min-height="40vh">
        <v-row class="ml-5">
          <p class="pt-3" style="
                font-size: 22px;
                color: #444444;
                margin-top: 10px;
                margin-left: 10px;
              ">
            {{ $t("dataError") }}
          </p>
        </v-row>
        <div class="ml-7 mr-7 mb-7 mt-3">
          <v-data-table hide-default-header :items="ErrorArray" hide-default-footer :page.sync="epage"
            :items-per-page="eitemsPerPage" @page-count="epageCount = $event" :mobile-breakpoint="0" class="mt-4 gaga">
            <template v-slot:header="{ props: { } }">
              <thead>
                <tr>
                  <th class="white--text">{{ $t("EmployeeID") }}</th>
                  <th class="white--text">{{ $t("Actions") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr style="height: 8px">
                <td>{{ item.employee_Code }}</td>
                <td>{{ item.error_Type }}</td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-right">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination v-model="epage" :length="epageCount" color="#FFCD2C"></v-pagination>
              </v-col>
              <v-col cols="12" md="2" class="mt-1">
                <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="eitemsPerPage"
                  :items="items" @input="eitemsPerPage = parseInt($event, 10)"></v-select>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </div>

    <!-- retrieve  dialog -->
    <v-dialog v-model="retrieveDialog" persistent max-width="450px">
      <v-card style="border-radius: 5px">
        <v-container>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <div>
              <v-btn @click="closeretrieveDialog()" icon>
                <v-icon>close </v-icon>
              </v-btn>
            </div>
          </div>
          <p class="d-flex justify-center Body 1 mt-8">
            {{ $t("urabouttoupload") }}
          </p>
          <div class="d-flex justify-center mb-5 mt-8">
            <v-btn color="#F99D20" class="white--text text-capitalize" width="150" max-height="35" @click="retrieving()">
              {{ $t("OK") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- retrieve  dialog -->
    <v-dialog v-model="updateDialog" persistent max-width="300px">
      <v-card style="border-radius: 5px">
        <v-container>
          <p class="d-flex justify-center Body 1 mt-8">
            {{ $t("uploadsuccess") }}
          </p>
          <div class="d-flex justify-center mb-5 mt-8">
            <v-btn color="#F99D20" class="white--text text-capitalize" width="120" max-height="35"
              @click="closeUpdateDialog">
              {{ $t("OK") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="dialog" persistent width="450">
      <v-card>
        <div>
          <v-card-text>
            <v-progress-linear v-model="value" color="#F99D20" class="mb-2 mt-2" :buffer-value="bufferValue"
              height="15"><template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template></v-progress-linear>
            <span>Updating {{ uploadcount }} rows of {{ uploadingcount }}</span>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>


    
    <v-dialog v-model="CheckingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Checking Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- New Template Dialog -->
    <v-layout row justify-center>
      <v-dialog v-model="templateDialog" persistent max-width="1250">
        <v-card min-height="300px">
          <v-card-text style="font-size: 19px; color: black; padding-top: 1rem">{{ $t("templatefile") }}</v-card-text>
          <v-card-text style="font-size: 16px; color: #F99D20">{{ $t("Arrangefld") }}</v-card-text>
          <v-row class="mr-3">
            <v-col cols="12" lg="4" md="4">
              <v-row>
                <v-col cols="7">
                  <p class="ml-5 pl-1">{{ $t("employeeid") }} :</p>
                </v-col>
                <v-col cols="5">
                  <v-row>
                    <v-text-field autocomplete="off" color="#F99D20" v-model="employeeIDSelecter"
                      class="txtnumbox pt-1 ml-3 pl-3" style="max-width: 75px;" type="number" readonly
                      @keypress="onlyNumber" value="0" min="1" max="3">
                      <template v-slot:append>
                        <div style="margin-right: 5px; margin-top: 13px">
                          <v-row>
                            <v-btn style="width: 13px; height: 8px" @click="addEnum(employeeIDSelecter)" icon text>
                              <img src="@/assets/images/tb-up-arrow.png" alt />
                            </v-btn>
                          </v-row>
                          <v-row>
                            <v-btn style="width: 13px; height: 8px" @click="subEnum(employeeIDSelecter)" icon text>
                              <img src="@/assets/images/tb-down-arrow.png" style="margin-top: 5px" alt />
                            </v-btn>
                          </v-row>
                        </div>
                      </template>
                    </v-text-field>
                    <span><v-badge class="batman mt-1" color="white" :value="hover" grow transition="slide-y-transition">
                        <template v-slot:badge>
                          <div class="my-badge">{{ $t("employeeIDhover") }}</div>
                        </template>
                        <v-hover v-model="hover">
                          <v-icon style="width:40px; height:40px;" color="#F99D20">
                            mdi-alert-circle
                          </v-icon>
                        </v-hover>
                      </v-badge></span>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <p class="ml-5 pl-1 mt-2">{{ $t("date") }} :</p>
                </v-col>
                <v-col cols="5">
                  <v-text-field autocomplete="off" color="#F99D20" v-model="dateSelecter" class="txtnumbox pt-1 ml-0 pl-3"
                    style="width: 75px;" type="number" @keypress="onlyNumber" readonly value="0" min="0" max="3">
                    <template v-slot:append>
                      <div style="margin-right: 5px; margin-top: 13px">
                        <v-row>
                          <v-btn style="width: 13px; height: 8px" @click="addDnum(dateSelecter)" icon text>
                            <img src="@/assets/images/tb-up-arrow.png" alt />
                          </v-btn>
                        </v-row>
                        <v-row>
                          <v-btn style="width: 13px; height: 8px" @click="subDnum(dateSelecter)" icon text>
                            <img src="@/assets/images/tb-down-arrow.png" style="margin-top: 5px" alt />
                          </v-btn>
                        </v-row>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7">
                  <p class="ml-5 pl-1 mt-2">{{ $t("Time") }} :</p>
                </v-col>
                <v-col cols="5">
                  <v-text-field autocomplete="off" color="#F99D20" v-model="timeSelecter" class="txtnumbox pt-1 ml-0 pl-3"
                    style="width: 75px;" type="number" readonly @keypress="onlyNumber" value="0" min="0" max="3">
                    <template v-slot:append>
                      <div style="margin-right: 5px; margin-top: 13px">
                        <v-row>
                          <v-btn style="width: 13px; height: 8px" @click="addTnum(timeSelecter)" icon text>
                            <img src="@/assets/images/tb-up-arrow.png" alt />
                          </v-btn>
                        </v-row>
                        <v-row>
                          <v-btn style="width: 13px; height: 8px" @click="subTnum(timeSelecter)" icon text>
                            <img src="@/assets/images/tb-down-arrow.png" style="margin-top: 5px" alt />
                          </v-btn>
                        </v-row>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="8" md="8">
              <v-row class="mr-2 thedatatopping">
                <v-col class="pb-0" cols="12" lg="2" md="2">
                  <p class="mt-2 ml-6">{{ $t("dataformat") }}</p>
                </v-col>
                <v-col class="pb-0" cols="12" lg="4" md="4">
                  <v-select class="ml-6" v-model="datesel" :items="dateFormatList" item-text="text" item-value="value"
                    outlined style="max-width: 200px;" dense color="#F99D20"
                    v-on:change="changeDateFomatSel(datesel)"></v-select>
                </v-col>
                <v-col class="pb-0" cols="12" lg="6" md="6">
                  <p class="mt-2 ml-6">{{ $t("example") }} : {{ thedateexample }}</p>
                </v-col>
              </v-row>
              <v-row class="mr-2">
                <v-col cols="12" lg="2" md="2">
                  <p class="mt-2 ml-6">{{ $t("dataformat") }}</p>
                </v-col>
                <v-col cols="12" lg="4" md="4">
                  <v-select class="ml-6" v-model="timesel" :items="timeFormatList" item-text="text" item-value="value"
                    outlined style="max-width: 200px;" dense color="#F99D20"
                    v-on:change="changeTimeFomatSel(timesel)"></v-select>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <p class="mt-2 ml-6">{{ $t("example") }} : {{ thetimeexample }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="ml-5 mr-5 mt-3 mb-3" style="background:#F99D20"></v-divider>
          <v-row class="ml-3 mr-3">
            <p class="ml-3 mt-3" style="font-size: 16px; color: #F99D20">{{ $t("textofdelimiter") }}</p>
            <span>
              <v-badge class="batman mt-1" color="white" :value="hover1" grow transition="slide-y-transition">
                <template v-slot:badge>
                  <div class="my-badge">{{ $t("delimiterhover") }}
                  </div>
                </template>
                <v-hover v-model="hover1">
                  <v-icon style="width:40px; height:40px;" color="#F99D20">
                    mdi-alert-circle
                  </v-icon>
                </v-hover>
              </v-badge>
            </span>
          </v-row>
          <v-col cols="12" lg="6" sm="6" style="margin-top: -1rem; margin-bottom: -0.5rem">
            <v-select class="ml-4" v-model="tempFormat" :items="delimiterList" item-text="txt" item-value="val" outlined
              style="max-width: 300px;" dense color="#F99D20" v-on:change="changeDelimeter(tempFormat)"></v-select>
          </v-col>

          <v-divider class="ml-5 mr-5 mt-3 mb-3" style="background:#F99D20"></v-divider>
          <v-card-text style="font-size: 16px; color: #F99D20">{{ $t("example") }}</v-card-text>
          <v-row class="mr-5 mt-2 ml-1">
            <v-tabs v-model="modelTab1" show-arrows grey--text class="ml-5 mr-6" slider-color="#F99D20">
              <!-- <v-tab @click="showexcel = true" class="text-capitalize jarviz-tab">
                {{ $t("Excel") }}</v-tab> -->
              <v-tab @click="showexcel = false" class="text-capitalize customized-tab1">
                Text File</v-tab>
            </v-tabs></v-row>
          <v-col cols="12" lg="12" sm="12">
            <div v-show="showexcel">
              <v-data-table :items="TemplateArray" hide-default-footer :headers="headersfortemp"
                @page-count="pageCount = $event" :mobile-breakpoint="0" class="temmplateet mt-0 ml-3 mr-3"
                style="border: 1px solid #f99d20">
              </v-data-table>
            </div>
            <div v-show="!showexcel">
              <div class="mt-0 ml-3 mr-3 pt-3 pl-3" style="border: 1px solid #f99d20;
                   border-radius: 5px;
                   height: 98px;
                   white-space: pre;
                   tab-size: 4;
                   -moz-tab-size: 4;">{{ thetextFile }}</div>
            </div>
          </v-col>
          <v-card-actions style="margin-right: 1.5rem; margin-top: 1rem">
            <v-row style="margin-bottom: 5px">
              <v-spacer></v-spacer>
              <v-file-input ref="file" v-show="showjarviz" placeholder="No file chosen" color="white"
                id="fileInputButtonTNT" v-model="tntfile" prepend-icon class="mt-2 ml-7" @change="loadCSV($event)">
                <template v-slot:append>
                  <v-btn icon>
                    <img width="20" height="20" src="@/assets/closered.png" alt />
                  </v-btn>
                </template>
              </v-file-input>

              <v-btn class="commonsavebtn mr-5 text-capitalize" @click="ShowSaveTheSetting()">
                {{ $t("saveset") }}</v-btn>
              <v-btn outlined class="text-capitalize cancel commoncancelbtn"
                @click="ClosetemplateDialog()">{{ $t("Cancel") }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- this is where you enter name of new template -->
    <v-dialog v-model="SavingDialog" persistent width="511">
      <v-card style="border-radius: 5px">
        <v-card-title>
          <span>{{ $t("savesets") }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="CloseSavingDialog" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="black--text ml-0 mt-7 mr-3">
          <v-text-field v-model="tempName" class="mr-3" color="#F99D20">
            <template v-slot:label>
              {{ $t("tempname") }}
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions style="margin-right: 3%">
          <v-spacer></v-spacer>
          <div class="d-flex justify-center mb-5 mt-8">
            <v-btn class="mr-2 text-capitalize" width="120" height="35" text color="#707070" dense
              @click="CloseSavingDialog">{{ $t("Cancel") }}</v-btn>
            <v-btn color="#F99D20" class="black--text text-capitalize" width="150" max-height="35"
              @click="SaveTheSetting()">
              {{ $t("save") }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- this is when you going to show the table in new template -->
    <v-dialog v-model="TeuploadDialog" persistent max-width="300px">
      <v-card style="border-radius: 5px">
        <v-container>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <div>
              <v-btn @click="closeTeuploadDialog()" icon>
                <v-icon>close </v-icon>
              </v-btn>
            </div>
          </div>
          <p class="d-flex justify-center Body 1 mt-2">{{ $t("uploading") }}</p>
          <div class="d-flex justify-center mb-5 mt-8">
            <v-btn color="#F99D20" class="white--text text-capitalize" width="120" max-height="35"
              @click="LoadTheTable()">
              {{ $t("OK") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="SyncTimeRetrieve" presistent max-width="650">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("syncJav") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-4">
              <v-text-field v-show="false" readonly color="#F99D20"></v-text-field>
              <v-col cols="12" sm="6" md="6">
                <label style="color: #8a8c8e">{{ $t("start_date") }}<span class="red--text"> *</span></label>
                <!-- <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted1"
                      class="kanit-medium" persistent-hint readonly v-on="on" color="#F99D20"></v-text-field>
                  </template>
                  <v-date-picker v-model="startdate" no-title @input="menu1 = false"></v-date-picker>
                </v-menu> -->
                 <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format"
                 v-model="startdate" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label style="color: #8a8c8e">{{ $t("enddata") }}<span class="red--text"> *</span></label>
                <!-- <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted2"
                      class="kanit-medium" persistent-hint readonly v-on="on" color="#F99D20"></v-text-field>
                  </template>
                  <v-date-picker v-model="enddate" no-title @input="menu2 = false"></v-date-picker>
                </v-menu> -->
                <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="enddate"
                :disabled-date="allowedEndDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
              </v-col>
            </v-row>
            <p class="mt-5">{{ $t("Employees") }}</p>
            <v-row>
              <v-checkbox class="custom-checkbox ml-3" color="#F99D20" style="margin: 0;padding:0;" v-model="oneforall"></v-checkbox>
              <!-- <v-checkbox class="theco" v-model="oneforall" style="margin: 0; margin-left: 10px"></v-checkbox> -->
              <p>{{ $t("selectallEmp") }}</p>
            </v-row>
            <v-row v-if="!oneforall">
              <v-col cols="12" xs="7" sm="7" md="7" lg="7" xl="7" class="mt-2">
                <v-text-field class="search" v-model.lazy="thesearchtxt" color="#F99D20" :textFieldProps="{
                  placeholder: 'Search',
                  color: '#F99D20',
                  dense: false,
                }" dense outlined :placeholder="$t('searchbyEIN')">
                  <template v-slot:append>
                    <v-btn color="#F99D20" style="
                          position: absolute;
                          right: 0;
                          top: 0;
                          padding: 0;
                          height: 39px;
                        " disabled>
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="mt-0 mr-6" cols="12" md="3">
                <v-btn color="#F99D20" class="white--text text-capitalize mt-3 mr-5" width="150" max-height="35"
                  @click="showtoadd">{{ $t("AddStaff") }}</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!oneforall">
              <v-col>
                <v-data-table height="300px" :headers="theAddheader" :search="thesearchtxt" :items="ThoseToAdd"
                  hide-default-footer hide-default-header :page.sync="smallpage" :items-per-page="smallitemsPerPage"
                  @page-count="smallpageCount = $event" :mobile-breakpoint="0">
                  <template v-slot:header="{ props: { } }">
                    <thead style="background: #f99d20">
                      <tr>
                        <th class="white--text">{{ $t("no") }}</th>
                        <th class="white--text">{{ $t("Name") }}</th>
                        <th class="white--text">{{ $t("EmployeeID") }}</th>
                        <th class="white--text">{{ $t("Actions") }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr style="height: 8px">
                      <td>{{ item.No }}</td>
                      <td style="width: 200px">{{ item.fullName }}</td>
                      <td>{{ item.employee_Code }}</td>
                      <td>
                        <v-btn icon @click="deletethisrow(item.employee_Code)">
                          <img :src="images.deletepic" />
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <div class="text-right">
                  <v-row justify="end" class="ml-5 mt-5 mr-5">
                    <v-col cols="12" md="5">
                      <v-pagination v-model="smallpage" :length="smallpageCount" color="#FFCD2C"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="2" class="mt-1">
                      <v-select dense style="width: 120px; float: right" solo label="5/page" v-model="smallitemsPerPage"
                        :items="smallitems" @input="smallitemsPerPage = parseInt($event, 5)"></v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize commoncancelbtn" outlined @click="CloseSyncTimeRetrieve()" text>{{ $t("cancel") }}</v-btn>
          <v-btn  class="mr-2 text-capitalize commonsavebtn" @click="SynctheTime()">{{ $t("sync") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="templateForRetrieve" presistent max-width="650">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("importtemplate") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-4">
              <v-col cols="6">
                <v-autocomplete class="ml-2" v-model="todayyears" :items="yearitems" outlined rounded dense
                  color="#F99D20"></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-select :items="monthNames" v-model="todaymonth" item-text="txt" item-value="val" outlined rounded dense
                  color="#F99D20" class="month"></v-select>
              </v-col>
            </v-row>
            <p>{{ $t("attachfile") }}<span class="red--text"> *</span></p>
            <v-row>
              <v-col class="mt-0" cols="12" md="3">
                <v-btn color="#F99D20" class="white--text text-capitalize mt-2" width="150" max-height="35"
                  @click="getupload">{{ $t("browse") }}</v-btn>
              </v-col>
              <v-col class="mt-0" cols="12" md="5">
                <v-file-input truncate-length="50" v-show="showup" id="fileInputButtonJarviz" class="inputbot ml-5 pt-0"
                  v-model="jarvizfile" prepend-icon @change="loadCSVToUpload($event)">
                  <template v-slot:append>
                    <v-btn icon>
                      <img width="20" height="20" @click="closeinput" src="@/assets/closered.png" alt />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" @click="closetemplateForRetrieve()" text>{{
            $t("cancel") }}</v-btn>
          <v-btn class="mr-2 text-capitalize commonsavebtn" @click="retrieving()">{{
            $t("upload") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add-staff dialog -->
    <v-dialog id="gg" v-model="staffDialog" persistent max-width="724px" :retain-focus="false">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="clear()" icon>
            <v-icon>close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-row style="padding-top: 1rem">
            <v-col lg="12" md="12" cols="12" class="pr-0">
              <v-text-field class="search mr-3" color="#F99D20" v-model.lazy="thestaffsearch" :textFieldProps="{
                placeholder: 'Search',
                color: '#F99D20',
                dense: false}" dense outlined :placeholder="$t('SearchEmployeeIDDepartment')">
                <template v-slot:append>
                  <v-btn color="#F99D20" style="
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 0;
                        height: 39px;
                      " disabled>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-data-table :items="empList" :headers="thestaffheader" :search="thestaffsearch" hide-default-header
            hide-default-footer disable-pagination :mobile-breakpoint="0">
            <template color="#F99D20" v-slot:header="{ props: { } }">
              <thead>
                <tr style="background-color: #f99d20">
                  <td style="color: #ffffff">
                    <input type="checkbox" v-model="selectAll" @click="select" />
                  </td>
                  <td style="color: #ffffff">{{ $t("EmployeeID") }}</td>
                  <td style="color: #ffffff">{{ $t("EmployeeName") }}</td>
                  <td style="color: #ffffff">{{ $t("department") }}</td>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td style="border: none">
                  <input type="checkbox" :value="{
                    employee_Code: item.employee_Code,
                    employee_ID: item.employee_ID,
                    fullName: item.fullName,
                  }" v-model="selected" />
                </td>
                <td style="border: none">{{ item.employee_Code }}</td>
                <td style="border: none">{{ item.fullName }}</td>
                <td style="border: none">{{ item.department_Name }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 commoncancelbtn text-capitalize fix-btn" text
            @click="staffDialogCancel()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit, Regular; font-weight: normal" @click="AddtoThose()">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- just for show -->
    <v-dialog v-model="templateforshowDialog" persistent width="1150px">
      <v-card style="border-radius: 5px">
        <v-container>
          <div v-show="!noallow" class="ml-4 mb-7">
            <v-row>
              <p class="Body 1 mt-4" style="font-size: 19px">
                {{ $t("templatefile") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="templateforshowDialog = false" icon style="margin-right: 1rem">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <v-row>
              <p class="ml-3 mt-5" style="font-size: 16px; color: #F99D20">{{ $t("textofdelimiter") }}</p>
              <v-col cols="12" lg="6" sm="6">
                <v-select v-model="tempFormatforshow" :items="delimiterList" item-text="txt" item-value="val" outlined
                  style="max-width: 200px;" dense color="#F99D20" readonly
                  v-on:change="changeDelimeter(tempFormatforshow)"></v-select>
              </v-col>
            </v-row>

            <v-row class="mt-2 ml-0">
              <v-tabs v-model="modelTab1" show-arrows grey--text slider-color="#F99D20">
                <!-- <v-tab @click="showexcel = true" class="text-capitalize jarviz-tab">
                  {{ $t("Excel") }}</v-tab> -->
                <v-tab @click="showexcel = false" class="text-capitalize customized-tab1">
                  Text File
                </v-tab>
              </v-tabs></v-row>
            <v-row>
              <v-col cols="12" lg="12" sm="12">
                <p style="color: #F99D20">{{ $t("example") }}</p>
                <div v-show="showexcel">
                  <v-data-table :items="TemplateSampleArray" hide-default-footer hide-default-header
                    @page-count="pageCount = $event" :mobile-breakpoint="0" class="temmplateet mt-0 ml-0 mr-3"
                    style="border: 1px solid #f99d20">
                    <template v-slot:header="{ props: { } }">
                      <thead>
                        <tr style="background-color: #f99d20">
                          <th style="text-align: left; width: 130px">
                            {{ tempheader1forshow }}
                          </th>
                          <th style="text-align: left; width: 130px">
                            {{ tempheader2forshow }}
                          </th>
                          <th style="text-align: left; width: 130px">
                            {{ tempheader3forshow }}
                          </th>
                          <th v-show="realInOut" style="text-align: left; width: 130px">
                            {{ tempheader4forshow }}
                          </th>
                          <th v-show="realInOut" style="text-align: left; width: 130px">
                            {{ tempheader5forshow }}
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.tempdata1 }}</td>
                        <td>{{ item.tempdata2 }}</td>
                        <td>{{ item.tempdata3 }}</td>
                        <td v-show="realInOut">{{ item.tempdata4 }}</td>
                        <td v-show="realInOut">{{ item.tempdata5 }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <div v-show="!showexcel">
                  <div class="mt-0 ml-0 mr-3 pt-3 pl-3" style="border: 1px solid #f99d20; border-radius: 5px; height: 98px; white-space: pre;
  tab-size: 4;
  -moz-tab-size: 4;">{{ thetextFileforshow }}</div>
                </div>
              </v-col></v-row>
          </div>
          <div v-show="noallow" class="ml-4 mb-7">
            <v-row>
              <p class="Body 1 mt-4" style="font-size: 19px">
                {{ $t("templatefile") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="templateforshowDialog = false" icon style="margin-right: 1rem">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-5" justify="center">
              <p>{{ $t("oldtemplatealert") }}</p>
            </v-row>

          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="Deletedialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" @click="Deletedialog = false" text>{{
            $t("cancel") }}</v-btn>
          <v-btn class="mr-2 commondeletebtn text-capitalize"
            @click="DeleteThisWhatever()">{{ $t("delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cancel dialog which reset -->
    <v-dialog v-model="Canceldialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("cancelTime") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-6">
                  {{ $t("thecanceltimetext") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="Canceldialog = false" text>{{ $t("noo")
          }}</v-btn>
          <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" width="120" max-height="35"
            @click="cancelTheWholeThing">{{ $t("yes") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- alert dialog for white space -->
    <v-dialog v-model="alertDialog" persistent max-width="500px">
      <v-card style="border-radius: 5px">
        <v-container>
          <div class="d-flex justify-center Body 1 mt-2">
            <img width="60" height="60" :src="images.alertpic" />
          </div>
          <p class="d-flex justify-center Body 1 mt-2">{{ errMesg }}</p>
          <div class="d-flex justify-center mb-5 mt-5">
            <v-btn color="#F99D20" class="white--text text-capitalize" width="120" max-height="35"
              @click="alertDialog = false">
              {{ $t("OK") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- confirm for duplicate -->
    <v-dialog v-model="ConfirmDialog" persistent max-width="500px">
      <v-card style="border-radius: 5px">
        <v-container>
          <div class="d-flex justify-center Body 1 mt-2">
            <img width="60" height="60" :src="images.alertpic" />
          </div>
          <p class="d-flex justify-center Body 1 mt-2">{{ $t("AleradyExiststxt") }}</p>
          <div class="d-flex justify-center mb-5 mt-5">
            <v-btn class="text-capitalize mr-2" max-height="35" width="120px" @click="ConfirmDialog = false" text>{{
              $t("Cancel") }}</v-btn>
            <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" max-height="35" width="120px"
              @click="SendtoSTAR">
              {{ $t("Confirm") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import axios from "axios";
import store from "@/store";
import moment from "moment";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewRetrieveCreateAdd from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, CheckViewRetrieveCreateAdd],
  data() {
    return {
      permission: {
        retrieve: true,
        create: true,
        add: true,
      },
      power: 78,
      tableShow: false,
      url: enurl.apiUrl,
      Deletedialog: false,
      alertDialog: false,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      epage: 1,
      epageCount: 15,
      eitemsPerPage: 10,
      smallpage: 1,
      smallpageCount: 15,
      smallitemsPerPage: 5,
      smallitems: [5, 10, 20, 30],
      empList: [],
      SyncTempList: [],
      ImportNameList: [],
      retrieveDialog: false,
      jarvizfile: null,
      tntfile: null,
      showjarviz: false,
      showup: false,
      dialog: false,
      staffDialog: false,
      Canceldialog: false,
      LoadingDialog: false,
      CheckingDialog: false,
      ConfirmDialog: false,
      TeuploadDialog: false,
      value: 0,
      total: 90,
      timeOut: 0,
      iteraciones: 90,
      bufferValue: 100,
      interval: 50,
      updateDialog: false,
      createBtn: true,
      templateDialog: false,
      templateForRetrieve: false,
      SyncTimeRetrieve: false,
      SavingDialog: false,
      TheJarvizArray: [],
      PersonalList: [],
      importData: [],
      combileArray: [],
      TemplateArray: [],
      ErrorArray: [],
      tempralary: [],
      templateselected: null,
      todayyears: null,
      todaymonth: null,
      showjarvizz: true,
      showexcel: false,
      modelTab: 0,
      modelTab1: 0,
      thisone: false,
      employeeIDSelecter: 1,
      dateSelecter: 2,
      timeSelecter: 3,
      hover: false,
      hover1: false,
      hover2: false,
      errMesg: "",
      dateFormatList: [
        {
          value: 0,
          text: "mm/dd/yyyy",
          example: "02/21/2023"
        },
        {
          value: 1,
          text: "mm/dd/yy",
          example: "02/21/23"
        },
        {
          value: 2,
          text: "dd/mm/yyyy",
          example: "21/02/2023"
        },
        {
          value: 3,
          text: "dd/mm/yy",
          example: "21/02/23"
        },
        {
          value: 4,
          text: "dd-mm-yyyy",
          example: "21-02-2023"
        },
        {
          value: 5,
          text: "dd-mm-yy",
          example: "21-02-23"
        },
        {
          value: 6,
          text: "mm-dd-yyyy",
          example: "02-21-2023"
        },
        {
          value: 7,
          text: "mm-dd-yy",
          example: "02-21-23"
        },
        {
          value: 8,
          text: "yyyy-mm-dd",
          example: "2023-02-21"
        },
        {
          value: 9,
          text: "m/d/yyyy",
          example: "2/21/2023"
        },
        {
          value: 10,
          text: "m/d/yy",
          example: "2/21/23"
        },
        {
          value: 11,
          text: "d/m/yyyy",
          example: "21/2/2023"
        },
        {
          value: 12,
          text: "d/m/yy",
          example: "21/2/23"
        },
        {
          value: 13,
          text: "m-d-yyyy",
          example: "2-21-2023"
        },
        {
          value: 14,
          text: "m-d-yy",
          example: "2-21-23"
        },
        {
          value: 15,
          text: "d-m-yyyy",
          example: "21-2-2023"
        },
        {
          value: 16,
          text: "d-m-yy",
          example: "21-2-23"
        },
        {
          value: 17,
          text: "yyyymmdd",
          example: "20230221"
        },
        {
          value: 18,
          text: "ddmmyyyy",
          example: "21022023"
        },
      ],
      timeFormatList: [
        {
          value: 0,
          text: "HH:MM:SS",
          example: "14:18:23"
        },
        {
          value: 1,
          text: "HH:MM",
          example: "14:18"
        },
        {
          value: 2,
          text: "HH:MM:SS tt",
          example: "10:02:48 AM"
        },
      ],
      datesel: 0,
      timesel: 0,
      thedateexample: "02/21/2023",
      thetimeexample: "14:18:23",
      thetextFile: "",
      thetextFileforshow: "",
      noallow: false,
      startdate: moment().format('DD/MM/YYYY'),
      enddate: moment().format('DD/MM/YYYY'),
      menu1: false,
      menu2: false,
      oneforall: true,
      uploadingcount: 0,
      uploadcount: 1,
      showthetable: false,
      formatToCheck: null,
      realheader1: null,
      realheader2: null,
      realheader3: null,
      realheader4: null,
      realheader5: null,
      realInOut: false,
      realIn: null,
      realOut: null,
      thestaffheader: [
        {
          text: "EmployeeID",
          value: "employee_Code",
          align: "left",
          sortable: false,
        },
        {
          text: "EmployeeName",
          value: "fullName",
          align: "right",
          sortable: false,
        },
        {
          text: "department",
          value: "department_Name",
          align: "right",
          sortable: false,
        },
      ],
      theAddheader: [
        {
          text: "EmployeeID",
          value: "employee_Code",
          align: "left",
          sortable: false,
        },
        {
          text: "EmployeeName",
          value: "fullName",
          align: "right",
          sortable: false,
        },
      ],
      companyName: "",
      thesearchtxt: "",
      thestaffsearch: "",
      tempName: "",
      tempFormat: "Tab",
      tempFormatforshow: "",
      whichline: 0,
      field1show: "field 1",
      field2show: "field 2",
      field3show: "field 3",
      field4show: "field 4",
      field5show: "field 5",
      tempheader1: "Machine ID",
      tempheader2: "Time",
      tempheader3: "Employee ID",
      tempheader4: "Shift",
      tempheader5: "In/Out",
      tempheader1forshow: "Machine ID",
      tempheader2forshow: "Time",
      tempheader3forshow: "Employee ID",
      tempheader4forshow: "Shift",
      tempheader5forshow: "In/Out",
      inVal: "",
      outVal: "",
      inValforshow: "",
      outValforshow: "",
      tempDateFormat: null,
      tempDateFormatforshow: null,
      INOUTCheck: false,
      continueornot: false,
      sayahh: [],
      ThoseToAdd: [],
      thelisttotal: 0,
      theliistdate: "",
      theliistDep: "",
      ApiToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiIzNzhmMDRlNS0xMDBjLTQ1ZDgtOWVhYS0wOGQ4MjNmMzBjOWMiLCJ1c2VydHlwZSI6IkVYVCAgICAgICAiLCJ1c2VybmFtZSI6IjEiLCJ1c2Vycm9sZSI6IjIiLCJuYmYiOjE2NjIxMDQyODksImV4cCI6MTc1Njc5ODY4OSwiaWF0IjoxNjYyMTA0Mjg5LCJpc3MiOiJGdXNpb24iLCJhdWQiOiJGdXNpb24ifQ.7tk08qYxt6gNqPbZiU2YsHyy4gCr6JPtGdtxx5cuTV4",
      FieldList: ["field 1", "field 2", "field 3", "field 4", "field 5"],
      jarvizStatus: false,
      headers: [],
      yearitems: [],
      uploadData: [],
      chosenfiles: [],
      chosenfile: [],
      csvname: null,
      kazuhaarr: [],
      kenshin: [],
      realdateformat: [],
      realtimeformat: [],
      parse_header: [],
      TTTemNaAr: [],
      sortOrders: {},
      sortKey: "",
      csv: null,
      valitem: null,
      Yesrad: this.$t("yes"),
      Norad: this.$t("noo"),
      images: {
        addpic: require("@/assets/images/plusic.png"),
        editpic: require("@/assets/images/reveal icon.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
        alertpic: require("@/assets/images/Iconly-Bold-Info-Circle.png")
      },
      selectAll: false,
      selected: [],
      com: 0,
      templateforshowDialog: false,
      headersfortemp: [],
      noTESetting: false,
      monthName: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      TemplateSampleArray: [
        {
          tempdata1: "Sample1",
          tempdata2: "Sample2",
          tempdata3: "Sample3",
          tempdata4: "Sample4",
          tempdata5: "Sample5",
        },
      ],
      TemplateSampleArrayJZ: [
        {
          tempdata1: "14-June-2021",
          tempdata2: "E0001",
          tempdata3: "Tony Stark",
          tempdata4: "Stark Inc",
          tempdata5: "NewYork",
          tlatt: "40.74878654186555",
          tlong: "-73.98556671325096",
          tempdata6: "Not Detected",
          tempdata7: "Not Detected",
          tempdata8: "Not Detected",
          tempdata9: "08:30",
          tempdata10: "Mon June 14 2021",
          tempdata11: "-",
          tempdata12: "-",
          tempdata13: "-",
          tempdata14: "-",
          tempdata15: "-",
          tempdata16: "-",
        },
      ],
      IDtodelete: 0,
      bakayaro: true,
      ShiftList: [],
    };
  },
  watch: {
    value(val) {
      if (val <= 100) return;
      this.value = 50;
      this.bufferValue = 100;
    },
    startdate(newStartDate) {
      if (new Date(this.$moment(newStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD')) > new Date(this.$moment(this.enddate, 'DD/MM/YYYY').format('YYYY-MM-DD'))) 
      {
      this.enddate = null;
      }
    },
    tntfile() {
      if (this.tntfile != null)
      {
        this.TeuploadDialog = true;
      }
      else
      {
        this.TeuploadDialog = false;
      }
    },

    jarvizfile() {
      if (this.jarvizfile != null)
      {
        //this.retrieveDialog = true;
        this.showup = true;
      }
      else
      {
        //this.retrieveDialog = false;
        this.showup = false;
      }
    },

    selected() {
      this.hellothere();
    },
  },

  computed: {
    delimiterList() {
      return [
        // { val: "Colon", txt: this.$t("colon") },
        { val: "Comma", txt: this.$t("comma") },
        { val: "Equals Sign", txt: this.$t("equal") },
        { val: "Semicolon", txt: this.$t("semi") },
        //{ val: "Space", txt: this.$t("space") },
        { val: "Tab", txt: this.$t("tab") },
      ];
    },

    FieldList1() {
      return [
        { val: 1, txt: this.$t("machineid") },
        { val: 2, txt: this.$t("Time") },
        { val: 3, txt: this.$t("employeeid") },
        { val: 4, txt: "Shift" },
        { val: 5, txt: this.$t("inout") },
        { val: 6, txt: "N/A" },
      ];
    },

    monthNames() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
  },

  async mounted() {
    try {
      this.LoadingDialog = true;
      //this.GetJarvizConfig();
      await this.GetCompany();
      await this.GetTimeAttSetting();
      await this.GetPersonal();
      await this.GetSyncTempName();
      await this.GetShiftAssignment();
      this.com = 1;
      this.permission = this.CheckViewRetrieveCreateAdd(14, 15, 16, 17);
      this.LoadingDialog = false;
      // Do something after all data is fetched
    }
    catch (error)
    {
      console.error("Error in mounted:", error);
    }
  },

  methods: {
    loadLoading() {
      this.value = 50;
      this.createBtn = false;
      this.retrieveDialog = false;
      this.dialog = true;
      this.gonnaload();
    },

    async gonnaload() {
      var arr = [];
      var sum = 0;
      this.value = 0;
      // Set the sleep Interval based on initial total
      Object.defineProperty(this, "sleepInterval", {
        value: 3000 / this.total,
        writeable: false,
      });

      let countmonster = this.uploadingcount;
      let thisandthat = parseInt(100 / countmonster);
      //let howmanytime = 100/thisandthat;
      let shhhhuuu = thisandthat;
      await this.forEach(this.iteraciones, async () => {
        await this.Sleep(this.sleepInterval);
        arr.push(1);
        sum++;
        this.SetProgressBar(sum);
        if (sum == shhhhuuu) {
          if (this.uploadcount < this.uploadingcount) {
            this.uploadcount = this.uploadcount + 1;
            shhhhuuu = shhhhuuu + thisandthat;
          }
        }
        clearTimeout(this.timeOut);
      });
      this.dialog = false;
      this.jarvizfile = null;
      this.updateDialog = true;
    },

    SetProgressBar(num) {
      if (num) {
        this.value = Math.round(this.Map(num));
      }
    },

    Map(value) {
      return ((value - 0) * (100 - 0)) / (this.total - 0) + 0;
    },

    async forEach(arr, callback) {
      for (let i = 0; i < arr; i++) {
        await callback(arr[i], i);
      }
    },

    Sleep(milisegundos) {
      return new Promise(
        (resolve) => (this.timeOut = setTimeout(resolve, milisegundos))
      );
    },

    closetemplateForRetrieve() {
      this.templateForRetrieve = false;
      this.showup = false;
      this.jarvizfile = null;
    },

    closeinput() {
      this.showup = false;
      this.jarvizfile = null;
    },

    closeUpdateDialog() {
      this.updateDialog = false;
      this.tableShow = true;
      this.createBtn = true;
      if (this.ErrorArray.length != 0) {
        this.alertDialog = true;
        self.errMesg = self.ErrorArray[0].error_Type;
      }
    },

    closeretrieveDialog() {
      this.retrieveDialog = false;
      this.jarvizfile = null;
    },

    closeTeuploadDialog() {
      this.TeuploadDialog = false;
      this.tntfile = null;
    },

    getupload() {
      document.getElementById("fileInputButtonJarviz").click();
    },

    showtoadd() {
      let self = this;
      self.SyncTimeRetrieve = false;
      self.staffDialog = true;
      if (self.selected.length == 0)
      {
        self.selected = self.ThoseToAdd;
      }
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}PersonalInfo/GetPersonalInfoByCompanyID`, temp)
        .then(function (response) {
          self.empList = response.data.data;
        });
    },

    select() {
      this.selected = [];
      if (!this.selectAll)
      {
        for (let i in this.empList) {
          this.selected.push({
            employee_Code: this.empList[i].employee_Code,
            employee_ID: this.empList[i].employee_ID,
            fullName: this.empList[i].fullName,
          });
        }
      }
    },

    hellothere() {
      if (
        this.selected.length == this.empList.length &&
        this.com == 0 &&
        this.empList.length != 0
      )
      {
        this.selectAll = true;
      }
      else
      {
        this.selectAll = false;
      }
      this.com = 0;
    },

    staffDialogCancel() {
      this.staffDialog = false;
      this.SyncTimeRetrieve = true;
      this.selectAll = false;
    },

    AddtoThose() {
      this.staffDialog = false;
      this.ThoseToAdd = [];
      let ahh = this.selected;
      for (let i = 0; i < ahh.length; i++) {
        let List = {
          ...ahh[i],
          No: i + 1,
        };
        this.ThoseToAdd.push(List);
      }
      this.SyncTimeRetrieve = true;
    },

    deletethisrow(id) {
      let self = this;
      for (let i = 0; i < self.ThoseToAdd.length; i++) {
        if (self.ThoseToAdd[i].employee_Code == id) {
          self.ThoseToAdd.splice(i, 1);
        }
      }
      for (let i = 0; i < self.selected.length; i++) {
        if (self.selected[i].employee_Code == id) {
          self.selected.splice(i, 1);
        }
      }
    },

    CloseSyncTimeRetrieve() {
      this.SyncTimeRetrieve = false;
      this.ThoseToAdd = [];
      this.selected = [];
      this.oneforall = true;
    },

    cancelTheWholeThing() {
      let self = this;
      self.Canceldialog = false;
      self.templateselected = null;
      self.jarvizfile = null;
      self.kenshin = [];
      self.combileArray = [];
      self.uploadingcount = 0;
      self.uploadcount = 0;
      self.thelisttotal = "";
      self.theliistdate = "";
      self.theliistDep = "";
      self.tableShow = false;
      self.ErrorArray = [];
      self.kazuhaarr = [];
      self.realdateformat = [];
    },

    //Check if there is time entries setting
    async GetTimeAttSetting() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,temp)
      .then(function (response)
      {
        let TimeSettingList = response.data.data;
        if (TimeSettingList.length != 0)
        {
          self.noTESetting = false;
        }
        else
        {
          self.noTESetting = true;
        }
      });
    },

    gotoTimeEntriesSet() {
      this.$router.push("/organization/attendance/timeworkdaycalender").catch(() => { });
      this.activeTab = `/organization/attendance/timeworkdaycalender/timeattendancesetting`;
      this.$router.push(`/organization/attendance/timeworkdaycalender/timeattendancesetting`).catch(() => { });
    },

    //ClickImport
    retrievingrshow() {
      if (this.showjarvizz)
      {
        this.SyncTimeRetrieve = true;
      }
      else
      {
        if (this.templateselected != null)
        {
          if (!this.noallow)
          {
            let d = new Date();
            this.todaymonth = d.getMonth() + 1;
            this.todayyears = new Date().getFullYear();
            this.yearitems = [
              this.todayyears,
              this.todayyears - 1,
              this.todayyears - 2,
            ];
            this.templateForRetrieve = true;
          }
          else
          {
            alert(this.$t("oldtemplatealert"));
          }
        }
        else
        {
          alert(this.$t("pleaseselect"));
        }
      }
    },

    //AfterImport
    retrieving() {
      let self = this;
      self.combileArray = [];
      self.headers = [];
      self.kenshin = [];
      self.kazuhaarr = [];
      self.ErrorArray = [];
      if (self.templateselected == 0)
      {
        // Import Jarviz with excel which is no longer used
        var files = self.jarvizfile;
        let fieldstype = files.type;
        if (files != null)
        {
          if (fieldstype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
          {
            var reader = new FileReader();
            reader.onload = function (e) {
              //let filename = files.name;
              let data = new Uint8Array(e.target.result);
              let workbook = XLSX.read(data, { type: "array" });
              let sheetName =
                workbook.SheetNames[workbook.SheetNames.length - 1];
              let worksheet = workbook.Sheets[sheetName];
              const file_data = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
              });
              let tempdatas = [];
              for (let k = 1; k < file_data.length; k++) {
                let whattherow = file_data[0].length;
                if (whattherow == 12)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                  });
                }
                else if (whattherow == 13)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                  });
                }
                else if (whattherow == 14)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                  });
                }
                else if (whattherow == 15)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                  });
                }
                else if (whattherow == 16)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                    [file_data[0][15].toLowerCase()]: file_data[k][15],
                  });
                }
                else if (whattherow == 17)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                    [file_data[0][15].toLowerCase()]: file_data[k][15],
                    [file_data[0][16].toLowerCase()]: file_data[k][16],
                  });
                }
                else if (whattherow == 18)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                    [file_data[0][15].toLowerCase()]: file_data[k][15],
                    [file_data[0][16].toLowerCase()]: file_data[k][16],
                    [file_data[0][17].toLowerCase()]: file_data[k][17],
                  });
                }
                else if (whattherow == 19)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                    [file_data[0][15].toLowerCase()]: file_data[k][15],
                    [file_data[0][16].toLowerCase()]: file_data[k][16],
                    [file_data[0][17].toLowerCase()]: file_data[k][17],
                    [file_data[0][18].toLowerCase()]: file_data[k][18],
                  });
                }
                else if (whattherow == 20)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                    [file_data[0][15].toLowerCase()]: file_data[k][15],
                    [file_data[0][16].toLowerCase()]: file_data[k][16],
                    [file_data[0][17].toLowerCase()]: file_data[k][17],
                    [file_data[0][18].toLowerCase()]: file_data[k][18],
                    [file_data[0][19].toLowerCase()]: file_data[k][19],
                  });
                }
                else if (whattherow == 21)
                {
                  tempdatas.push({
                    [file_data[0][0].toLowerCase()]: file_data[k][0],
                    [file_data[0][1].toLowerCase()]: file_data[k][1],
                    [file_data[0][2].toLowerCase()]: file_data[k][2],
                    [file_data[0][3].toLowerCase()]: file_data[k][3],
                    [file_data[0][4].toLowerCase()]: file_data[k][4],
                    [file_data[0][5].toLowerCase()]: file_data[k][5],
                    [file_data[0][6].toLowerCase()]: file_data[k][6],
                    [file_data[0][7].toLowerCase()]: file_data[k][7],
                    [file_data[0][8].toLowerCase()]: file_data[k][8],
                    [file_data[0][9].toLowerCase()]: file_data[k][9],
                    [file_data[0][10].toLowerCase()]: file_data[k][10],
                    [file_data[0][11].toLowerCase()]: file_data[k][11],
                    [file_data[0][12].toLowerCase()]: file_data[k][12],
                    [file_data[0][13].toLowerCase()]: file_data[k][13],
                    [file_data[0][14].toLowerCase()]: file_data[k][14],
                    [file_data[0][15].toLowerCase()]: file_data[k][15],
                    [file_data[0][16].toLowerCase()]: file_data[k][16],
                    [file_data[0][17].toLowerCase()]: file_data[k][17],
                    [file_data[0][18].toLowerCase()]: file_data[k][18],
                    [file_data[0][19].toLowerCase()]: file_data[k][19],
                    [file_data[0][20].toLowerCase()]: file_data[k][20],
                  });
                }
              }

              let cinti = tempdatas.filter((o1) => o1["check in time"]);
              let indate = tempdatas.filter((o1) => o1["check in date"]);
              let inlat = tempdatas.filter((o1) => o1["check in latitude"]);
              let inlong = tempdatas.filter((o1) => o1["check in longitude"]);
              let eidd = tempdatas.filter((o1) => o1["employee id"]);
              let theemsg = [];
              if (eidd.length == 0)
              {
                theemsg.push("Employee ID");
              }
              if (inlat.length == 0)
              {
                theemsg.push("Check In Latitude");
              }
              if (inlong.length == 0)
              {
                theemsg.push("Check In Longitude");
              }
              if (indate.length == 0)
              {
                theemsg.push("Check In Date");
              }
              if (cinti.length == 0)
              {
                theemsg.push("Check In Time");
              }
              self.importData = tempdatas.filter(
                (o1) => o1["check in time"] && o1["check in date"]
              );
              self.uploadingcount = self.importData.length;
              let validate = self.ValidateNameForJarviz();
              if (self.uploadingcount != 0 && theemsg.length == 0)
              {
                if (validate)
                {
                  let coti = [];
                  for (let i = 0; i < self.importData.length; i++) {
                    if (
                      self.importData[i]["check in latitude"] != null &&
                      self.importData[i]["check in longitude"] != null
                    )
                    {
                      self.tempimport = {
                        employee_Code:
                          self.importData[i]["employee id"].toString(),
                        timeEntry_Date: self.ChangeDateToYYYYMMDD(
                          self.importData[i]["check in date"]
                        ),
                        company_ID: store.state.companyID,
                        user_ID: store.state.employeeID,
                        inOut_Required: true,
                        jarviz: true,
                        timetoIncheck: self.importData[i]["check in time"],
                        timetoOutcheck: self.importData[i]["check out time"],
                        fullName:
                          self.importData[i]["full name"] != null
                            ? self.importData[i]["full name"]
                            : "-",
                        companyName:
                          self.importData[i]["company name"] != null
                            ? self.importData[i]["company name"]
                            : "-",
                        clock_In_Time: self.ChangeToUTCDateTimeCheckIn(
                          self.importData[i]["check in time"],
                          self.importData[i]["check in date"]
                        ),
                        clock_Out_Time: self.ChangeToUTCDateTimeCheckIn(
                          self.importData[i]["check out time"],
                          self.importData[i]["check out date"]
                        ),
                        actual_Clock_In_Time: self.ChangeToLocalDateTimeCheckIn(
                          self.importData[i]["check in time"],
                          self.importData[i]["check in date"]
                        ),
                        actual_Clock_Out_Time:
                          self.ChangeToLocalDateTimeCheckIn(
                            self.importData[i]["check out time"],
                            self.importData[i]["check out date"]
                          ),
                        shift_Start: self.importData[i]["check in time"],
                        shift_End: self.importData[i]["check out time"],
                        clock_In_Location:
                          self.importData[i]["check in location"] != null
                            ? self.importData[i]["check in location"]
                            : "-",
                        clock_In_Lattitude:
                          self.importData[i]["check in latitude"] != null
                            ? self.importData[i]["check in latitude"]
                            : "-",
                        clock_In_Longitude:
                          self.importData[i]["check in longitude"] != null
                            ? self.importData[i]["check in longitude"]
                            : "-",
                        clock_Out_Location:
                          self.importData[i]["check out location"] != null
                            ? self.importData[i]["check out location"]
                            : "-",
                        showdate: self.importData[i]["date"],
                        cintime:
                          self.importData[i]["check in time"] != null
                            ? self.importData[i]["check in time"]
                            : "-",
                        couttime:
                          self.importData[i]["check out time"] != null
                            ? self.importData[i]["check out time"]
                            : "-",
                        cindate:
                          self.importData[i]["check in date"] != null
                            ? self.importData[i]["check in date"]
                            : "-",
                        coutdate:
                          self.importData[i]["check out date"] != null
                            ? self.importData[i]["check out date"]
                            : "-",
                        distance:
                          self.importData[i][
                            "distance between check in and check out locations"
                          ] != null
                            ? self.importData[i][
                            "distance between check in and check out locations"
                            ]
                            : "-",
                        LeaveStatus:
                          self.importData[i]["leavestatus"] != null
                            ? self.importData[i]["leavestatus"]
                            : "-",
                        remark:
                          self.importData[i]["remark"] != null
                            ? self.importData[i]["remark"]
                            : "-",
                      };
                      coti.push(true);
                      self.combileArray.push(self.tempimport);
                      self.kenshin.push(self.tempimport);
                    }
                    else
                    {
                      coti.push(false);
                    }
                  }
                  if (coti.some((e) => e == false))
                  {
                    alert("Please make sure Check In Latitude and Check In Longitude are included");
                    self.jarvizfile = null;
                    self.retrieveDialog = false;
                    self.tableShow = false;
                    self.combileArray = [];
                    self.importData = [];
                  }
                  else
                  {
                    self.headers.push(
                      {
                        text: "Date",
                        value: "showdate",
                      },
                      { text: "Employee ID", value: "employee_Code" },
                      { text: "Full Name", value: "fullName" },
                      { text: "Company Name", value: "companyName" },
                      { text: "Check In Location", value: "clock_In_Location" },
                      {
                        text: "Check In Latitude",
                        value: "clock_In_Lattitude",
                      },
                      {
                        text: "Check In Longitude",
                        value: "clock_In_Longitude",
                      },
                      { text: "Root Detected", value: "root" },
                      { text: "Mock GPS", value: "mock" },
                      { text: "Developer Mode", value: "devmode" },
                      { text: "Check In Time", value: "cintime" },
                      { text: "Check In Date", value: "cindate" },
                      {
                        text: "Check Out Location",
                        value: "clock_Out_Location",
                      },
                      { text: "Check Out Time", value: "couttime" },
                      { text: "Check Out Date", value: "coutdate" },
                      {
                        text: "Distance between In Out Location",
                        value: "distance",
                      },
                      { text: "Leave Status", value: "LeaveStatus" },
                      { text: "Remark", value: "remark" }
                    );

                    let monthvali = [];
                    let yearvali = [];
                    let formatvali = [];
                    let goonornot = [];
                    for (let j = 0; j < self.combileArray.length; j++)
                    {
                      //const today = new Date();
                      const date1 = new Date(
                        self.combileArray[j].timeEntry_Date
                      );
                      let monvalue = date1.getMonth() + 1;
                      if (self.todaymonth == monvalue)
                      {
                        monthvali.push(true);
                      }
                      else
                      {
                        monthvali.push(false);
                      }

                      if (self.todayyears == date1.getFullYear())
                      {
                        yearvali.push(true);
                      }
                      else
                      {
                        yearvali.push(false);
                      }

                      var incheck = "";
                      var outcheck = "";
                      if (self.combileArray[j].clock_In_Time != "-")
                      {
                        incheck = self.combileArray[j].timetoIncheck;
                      }
                      if (self.combileArray[j].clock_Out_Time != "-")
                      {
                        outcheck = self.combileArray[j].timetoOutcheck;
                      }
                      if (incheck.includes("AM") || incheck.includes("PM") || outcheck.includes("AM") || outcheck.includes("PM"))
                      {
                        formatvali.push(false);
                      }
                      else
                      {
                        formatvali.push(true);
                      }
                      for (let s = 0; s < self.ShiftList.length; s++) {
                        if (
                          self.combileArray[j].employee_Code ==
                          self.ShiftList[s].employee_Code
                        )
                        {
                          if (
                            moment(self.ShiftList[s].effective_Date) <
                            moment(self.combileArray[j].timeEntry_Date).add(
                              1,
                              "day"
                            )
                          )
                          {
                            goonornot.push(true);
                          }
                          else
                          {
                            goonornot.push(false);
                          }
                        }
                      }
                    }
                    if ( monthvali.some((e) => e == false) || yearvali.some((e) => e == false))
                    {
                      alert("Date time doesn't match");
                      self.jarvizfile = null;
                      self.retrieveDialog = false;
                      self.tableShow = false;
                      self.combileArray = [];
                      self.importData = [];
                    }
                    else
                    {
                      if (formatvali.some((e) => e == false))
                      {
                        alert("Format doesn't match the Jarviz template");
                        self.jarvizfile = null;
                        self.retrieveDialog = false;
                        self.tableShow = false;
                        self.combileArray = [];
                        self.importData = [];
                      }
                      else
                      {
                        if (goonornot.some((e) => e == false))
                        {
                          self.jarvizfile = null;
                          self.retrieveDialog = false;
                          self.combileArray = [];
                          self.importData = [];
                          self.tableShow = false;
                          alert(
                            "Can't be upload data before defined shift Assigment's Effective Date"
                          );
                        }
                        else
                        {
                          self.retrieveDialog = false;
                          self.tableShow = false;
                          self.loadLoading();
                        }
                      }
                    }
                  }
                }
                else
                {
                  alert("Employee ID doesn't match");
                  self.jarvizfile = null;
                  self.retrieveDialog = false;
                  self.tableShow = false;
                }
              }
              else
              {
                let Message = [...new Set(theemsg)];
                alert(`Please make sure ${Message.join(", ")} included`);
                self.jarvizfile = null;
                self.retrieveDialog = false;
                self.tableShow = false;
              }
            };
            reader.readAsArrayBuffer(files);
          }
          else
          {
            alert("Please select the correct format");
            self.jarvizfile = null;
            self.retrieveDialog = false;
            self.tableShow = false;
          }
        }
        else
        {
          alert(this.$t("nofilesele"));
          self.jarvizfile = null;
          self.retrieveDialog = false;
          self.tableShow = false;
        }
      }
      else
      {
        var files1 = self.jarvizfile;
        if (files1 != null)
        {
          let fieldtype = files1.type;
          if (fieldtype == "text/plain")
          {
            let reader = new FileReader();
            let realary = [];
            let hiyaa = "";
            // Define a map for format values
            const formatMapping = {
              "Colon": ":",
              "Comma": ",",
              "Equals Sign": "=",
              "Semicolon": ";",
              "Space": " ",
              "Tab": "\t"
            };

            // Check if the formatToCheck exists in the mapping, use it; otherwise, default to an empty string
            hiyaa = formatMapping[self.formatToCheck] || "";

            reader.onload = (e) => {
              this.uploadData = [];
              this.uploadData = e.target.result;
              if (this.uploadData.includes(hiyaa))
              {
                let array = this.uploadData.split(/\r\n|\n/);
                let itBlank = false;
                for (let k = 0; k < array.length; k++) {
                  let array1 = array[k].split(hiyaa);
                  if (array1 != "")
                  {
                    //itBlank = true;
                    realary.push(array1);
                    self.tempralary.push(array1);
                    self.kazuhaarr.push(array1);
                  }
                }
                if (!itBlank)
                {
                  if (self.realInOut)
                  {
                    self.headers.push(
                      {
                        text: self.realheader1,
                        align: "start",
                        sortable: false,
                        value: "tempdata1",
                      },
                      { text: self.realheader2, value: "tempdata2" },
                      { text: self.realheader3, value: "tempdata3" },
                      { text: self.realheader4, value: "tempdata4" },
                      { text: self.realheader5, value: "tempdata5" }
                    );
                  }
                  else
                  {
                    self.headers.push(
                      {text: self.realheader1,align: "start",sortable: false,value: "tempdata1"},
                      { text: self.realheader2, value: "tempdata2" },
                      { text: self.realheader3, value: "tempdata3" },
                      //{ text: self.realheader4, value: "tempdata4" }
                    );
                  }

                  for (let y = 0; y < realary.length; y++)
                  {
                    self.combileArray.push({
                      id: y,
                      tempdata1: self.checkudenfine(realary[y][0]),
                      tempdata2: self.checkudenfine(realary[y][1]),
                      tempdata3: self.checkudenfine(realary[y][2]),
                      // tempdata4: self.checkudenfine(realary[y][3]),
                      // tempdata5: self.checkudenfine(realary[y][4]),
                    });
                  }

                  self.retrieveDialog = false;
                  self.kenshin = self.tempuploadlist();
                  self.uploadingcount = self.combileArray.length;

                  if (self.continueornot)
                  {
                    let theteearr = [];
                    for (let j = 0; j < self.kenshin.length; j++)
                    {
                      for (let t = 0; t < self.PersonalList.length; t++)
                      {
                        if (
                          self.kenshin[j].employee_Code ==
                          self.PersonalList[t].employee_Code
                        )
                        {
                          theteearr.push(self.PersonalList[t].department_Name);
                        }
                      }
                    }

                    if (self.combileArray.length != 0)
                    {
                      self.templateForRetrieve = false;
                      let Message = [...new Set(theteearr)];
                      self.theliistDep = `${Message.join(", ")}`;
                      self.thelisttotal = self.combileArray.length;
                      self.theliistdate =
                        self.monthNames[self.todaymonth - 1].txt +
                        " - " +
                        self.todayyears;
                      self.loadLoading();
                    }
                    else
                    {
                      self.templateForRetrieve = false;
                      self.jarvizfile = null;
                      self.combileArray = [];
                      self.kenshin = [];
                      self.alertDialog = true;
                      self.errMesg = self.ErrorArray[0].error_Type;
                      //alert("There is no data that can upload.");
                    }
                  }
                }
                else
                {
                  self.templateForRetrieve = false;
                  self.jarvizfile = null;
                  self.combileArray = [];
                  self.kenshin = [];
                  self.alertDialog = true;
                  self.errMesg = self.ErrorArray[0].error_Type;
                }
              }
              else
              {
                alert("Wrong Upload Format");
                self.jarvizfile = null;
                self.templateForRetrieve = false;
                self.tableShow = false;
              }
            };
            reader.readAsText(files1);
            document.getElementById("fileInputButtonJarviz").value = "";
            self.templateForRetrieve = false;
            self.tableShow = false;
          }
          // else
          // {
          //   self.parse_csv = self.csvJSONToUpload(self.csv);
          // }
        }
        else
        {
          alert(this.$t("nofilesele"));
          self.jarvizfile = null;
          self.templateForRetrieve = false;
          self.tableShow = false;
        }
      }
    },

    loadCSVToUpload(e) {
      let self = this;
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsText(e);
        self.csvname = e.name;
        reader.onload = function (event) {
          self.csv = event.target.result;
        };
      }
    },

    csvJSONToUpload(csv) {
      let self = this;
      self.TemplateArray = [];
      self.TTTemNaAr = [];
      let lines = csv.split("\n");
      // self.headers.push(
      //   {text: self.realheader1,align: "start",sortable: false,value: "tempdata1"},
      //   { text: self.realheader2, value: "tempdata2" },
      //   { text: self.realheader3, value: "tempdata3" }
      // );
      let headers = [
        "tempdata1",
        "tempdata2",
        "tempdata3"
      ];

      if (lines[0].includes("\t"))
      {
        self.parse_header = lines[0].split("\t");
        lines[0].split("\t").forEach(function (key) {
          self.sortOrders[key] = 1;
        });
        lines.map(function (line) {
          let obj = {};
          let currentline = line.split("\t");

          headers.map(function (header, indexHeader) {
            obj[header] = currentline[indexHeader];
          });
          self.combileArray.push(obj);
          self.kazuhaarr.push(obj);
        });
      }
      else if (lines[0].includes("="))
      {
        self.parse_header = lines[0].split("=");
        lines[0].split("=").forEach(function (key) {
          self.sortOrders[key] = 1;
        });
        lines.map(function (line) {
          let obj = {};
          let currentline = line.split("=");
          headers.map(function (header, indexHeader) {
            obj[header] = currentline[indexHeader];
          });
          self.combileArray.push(obj);
          self.kazuhaarr.push(obj);
        });
      }
      else
      {
        self.parse_header = lines[0].split(",");
        lines[0].split(",").forEach(function (key) {
          self.sortOrders[key] = 1;
        });
        // lines.map(function (line) {
        //   let obj = {};
        //   let currentline = line.split(",");
        //   headers.forEach(function (header, indexHeader) {
        //     obj[header] = currentline[indexHeader];
        //   });
        //   self.combileArray.push(obj);
        //   self.kazuhaarr.push(obj);
        // });
        lines.filter(line => line.trim() !== '')  // Remove empty lines
        .map(function (line) {
          let obj = {};
          let currentline = line.replace(/\r/g, '').split(","); // Remove '\r'
          headers.forEach(function (header, indexHeader) {
            obj[header] = currentline[indexHeader];
          });
          self.combileArray.push(obj);
          self.kazuhaarr.push(obj);
        });
      }

      self.combileArray.shift();
      self.kazuhaarr.shift();
      self.kenshin = self.tempuploadlistwithcsv();
      self.uploadingcount = self.combileArray.length;
      if (self.continueornot)
      {
        let theteearr = [];
        for (let j = 0; j < self.kenshin.length; j++)
        {
          for (let t = 0; t < self.PersonalList.length; t++)
          {
            if (
              self.kenshin[j].employee_Code ==
              self.PersonalList[t].employee_Code
            )
            {
              theteearr.push(self.PersonalList[t].department_Name);
            }
          }
        }

        if (self.combileArray.length != 0)
        {
          self.templateForRetrieve = false;
          let Message = [...new Set(theteearr)];
          self.theliistDep = `${Message.join(", ")}`;
          self.thelisttotal = self.combileArray.length;
          self.theliistdate = self.monthNames[self.todaymonth - 1].txt + " - " + self.todayyears;
          self.loadLoading();
        }
        else
        {
          self.templateForRetrieve = false;
          self.combileArray = [];
          self.kenshin = [];
          self.jarvizfile = null;
          self.alertDialog = true;
          self.errMesg = self.ErrorArray[0].error_Type;
          //alert("There is no data that can upload.");
        }
      }
    },

    ValidateIDForTemp() {
      let self = this;
      let result = self.kenshin.filter(
        (o1) =>
          !self.PersonalList.some((o2) => o1.employee_Code === o2.employee_Code)
      );
      if (result.length != 0)
      {
        return false;
      }
      else
      {
        return true;
      }
    },

    //conditions of emp id and shift maatch
    wanttoknowifwork() {
      let self = this;
      let theone = [];
      let result = 0;

      let anum = 0;
      if (self.realheader1 == "Employee ID")
      {
        anum = 0;
      }
      else if (self.realheader2 == "Employee ID")
      {
        anum = 1;
      }
      else if (self.realheader3 == "Employee ID")
      {
        anum = 2;
      }
      else if (self.realheader4 == "Employee ID")
      {
        anum = 3;
      }
      else if (self.realheader5 == "Employee ID")
      {
        anum = 4;
      }

      let bnum = 0;
      if (self.realheader1 == "Shift")
      {
        bnum = 0;
      }
      else if (self.realheader2 == "Shift")
      {
        bnum = 1;
      }
      else if (self.realheader3 == "Shift")
      {
        bnum = 2;
      }
      else if (self.realheader4 == "Shift")
      {
        bnum = 3;
      }
      else if (self.realheader5 == "Shift")
      {
        bnum = 4;
      }

      let cnum = 0;
      if (self.realheader1 == "In/Out")
      {
        cnum = 0;
      }
      else if (self.realheader2 == "In/Out")
      {
        cnum = 1;
      }
      else if (self.realheader3 == "In/Out")
      {
        cnum = 2;
      }
      else if (self.realheader4 == "In/Out")
      {
        cnum = 3;
      }
      else if (self.realheader5 == "In/Out")
      {
        cnum = 4;
      }

      for (let l = 0; l < self.kazuhaarr.length; l++) {
        let List = {
          ShiftCode: self.kazuhaarr[l][bnum],
          Employee_ID: self.kazuhaarr[l][anum],
          TimeType: self.kazuhaarr[l][cnum],
        };
        theone.push(List);
      }
      for (let j = 0; j < theone.length; j++) {
        if (self.realInOut)
        {
          if (
            theone[j].TimeType == self.realIn ||
            theone[j].TimeType == self.realOut
          )
          {
            for (let o = 0; o < self.ShiftList.length; o++) {
              if (theone[j].Employee_ID == self.ShiftList[o].employee_Code)
              {
                if (theone[j].ShiftCode == self.ShiftList[o].time_Group) {
                  if (result >= 4)
                  {
                    result = result + 4;
                    self.whichline = j + 1;
                    break;
                  }
                  else
                  {
                    result = 2;
                    break;
                  }
                }
                else
                {
                  result = result + 4;
                  self.whichline = j + 1;
                  break;
                }
              }
              else
              {
                result = 3;
                self.whichline = j + 1;
              }
            }
            if (result != 2)
            {
              break;
            }
          }
          else
          {
            result = 1;
            self.whichline = j + 1;
            break;
          }
        }
        else
        {
          for (let t = 0; t < self.ShiftList.length; t++) {
            if (theone[j].Employee_ID == self.ShiftList[t].employee_Code)
            {
              if (theone[j].ShiftCode == self.ShiftList[t].time_Group)
              {
                if (result >= 4)
                {
                  result = result + 4;
                  self.whichline = j + 1;
                  break;
                }
                else
                {
                  result = 2;
                  break;
                }
              }
              else
              {
                result = result + 4;
                self.whichline = j + 1;
                break;
              }
            }
            else
            {
              result = 3;
              self.whichline = j + 1;
            }
          }
          if (result != 2)
          {
            break;
          }
        }
      }
      return result;
    },

    wanttoknowifworkforcsv() {
      let self = this;
      let theone = [];
      let result = 0;
      for (let u = 0; u < self.kazuhaarr.length; u++) {
        let dati = "";
        let eid = "";
        let type = "";
        if (self.realheader1 == "Shift")
        {
          dati = self.kazuhaarr[u].tempdata1;
        }
        else if (self.realheader2 == "Shift")
        {
          dati = self.kazuhaarr[u].tempdata2;
        }
        else if (self.realheader3 == "Shift")
        {
          dati = self.kazuhaarr[u].tempdata3;
        }
        else if (self.realheader4 == "Shift")
        {
          dati = self.kazuhaarr[u].tempdata4;
        }
        else if (self.realheader5 == "Shift")
        {
          dati = self.kazuhaarr[u].tempdata5;
        }

        if (self.realheader1 == "Employee ID")
        {
          eid = self.kazuhaarr[u].tempdata1;
        }
        else if (self.realheader2 == "Employee ID")
        {
          eid = self.kazuhaarr[u].tempdata2;
        }
        else if (self.realheader3 == "Employee ID")
        {
          eid = self.kazuhaarr[u].tempdata3;
        }
        else if (self.realheader4 == "Employee ID")
        {
          eid = self.kazuhaarr[u].tempdata4;
        }
        else if (self.realheader5 == "Employee ID")
        {
          eid = self.kazuhaarr[u].tempdata5;
        }

        if (self.realheader1 == "In/Out")
        {
          type = self.kazuhaarr[u].tempdata1;
        }
        else if (self.realheader2 == "In/Out")
        {
          type = self.kazuhaarr[u].tempdata2;
        }
        else if (self.realheader3 == "In/Out")
        {
          type = self.kazuhaarr[u].tempdata3;
        }
        else if (self.realheader4 == "In/Out")
        {
          type = self.kazuhaarr[u].tempdata4;
        }
        else if (self.realheader5 == "In/Out")
        {
          type = self.kazuhaarr[u].tempdata5;
        }

        let List = {
          ShiftCode: dati.trim(),
          Employee_ID: eid,
          TimeType: type.trim(),
        };
        theone.push(List);
      }

      for (let j = 0; j < theone.length; j++) {
        if (self.realInOut)
        {
          if (
            theone[j].TimeType == self.realIn ||
            theone[j].TimeType == self.realOut
          )
          {
            for (let o = 0; o < self.ShiftList.length; o++) {
              if (theone[j].Employee_ID == self.ShiftList[o].employee_Code)
              {
                if (theone[j].ShiftCode == self.ShiftList[o].time_Group)
                {
                  if (result >= 4)
                  {
                    result = result + 4;
                    self.whichline = j + 1;
                    break;
                  }
                  else
                  {
                    result = 2;
                    break;
                  }
                }
                else
                {
                  result = result + 4;
                  self.whichline = j + 1;
                  break;
                }
              }
              else
              {
                result = 3;
                self.whichline = j + 1;
              }
            }
            if (result != 2)
            {
              break;
            }
          }
          else
          {
            result = 1;
            self.whichline = j + 1;
            break;
          }
        }
        else
        {
          for (let t = 0; t < self.ShiftList.length; t++){
            if (theone[j].Employee_ID == self.ShiftList[t].employee_Code)
            {
              if (theone[j].ShiftCode != self.ShiftList[t].time_Group)
              {
                result = result + 4;
                self.whichline = j + 1;
                break;
              }
              else
              {
                if (result >= 4)
                {
                  result = result + 4;
                  self.whichline = j + 1;
                  break;
                }
                else
                {
                  result = 2;
                  break;
                }
              }
            }
            else
            {
              result = 3;
              self.whichline = j + 1;
            }
          }
          if (result != 2)
          {
            break;
          }
        }
      }
      return result;
    },

    //upload lists to send staff time
    tempuploadlist() {
      let self = this;
      self.chosenfile = [];
      let result = [];
      let anum = 0;
      let bnum = 0;
      let cnum = 0;
      let dnum = 0;

      if (self.realheader1 == "Employee ID")
      {
        anum = 0;
      }
      else if (self.realheader2 == "Employee ID")
      {
        anum = 1;
      }
      else if (self.realheader3 == "Employee ID")
      {
        anum = 2;
      }
      else if (self.realheader4 == "Employee ID")
      {
        anum = 3;
      }
      else if (self.realheader5 == "Employee ID")
      {
        anum = 4;
      }

      if (self.realheader1 == "Time")
      {
        bnum = 0;
      }
      else if (self.realheader2 == "Time")
      {
        bnum = 1;
      }
      else if (self.realheader3 == "Time")
      {
        bnum = 2;
      }
      else if (self.realheader4 == "Time")
      {
        bnum = 3;
      }
      else if (self.realheader5 == "Time")
      {
        bnum = 4;
      }

      if (self.realheader1 == "In/Out")
      {
        cnum = 0;
      }
      else if (self.realheader2 == "In/Out")
      {
        cnum = 1;
      }
      else if (self.realheader3 == "In/Out")
      {
        cnum = 2;
      }
      else if (self.realheader4 == "In/Out")
      {
        cnum = 3;
      }
      else if (self.realheader5 == "In/Out")
      {
        cnum = 4;
      }

      if (self.realheader1 == "Shift")
      {
        dnum = 0;
      }
      else if (self.realheader2 == "Shift")
      {
        dnum = 1;
      }
      else if (self.realheader3 == "Shift")
      {
        dnum = 2;
      }
      else if (self.realheader4 == "Shift")
      {
        dnum = 3;
      }
      else if (self.realheader5 == "Shift")
      {
        dnum = 4;
      }

      if (self.realInOut)
      {
        for (let l = 0; l < self.kazuhaarr.length; l++) {
          let rick = self.heymorty(self.checkudenfine(self.kazuhaarr[l][bnum]));

          let List = {
            ShiftCode: self.checkudenfine(self.kazuhaarr[l][dnum]),
            DateAndTime: self.checkudenfine(self.kazuhaarr[l][bnum]),
            Employee_ID: self.checkudenfine(self.kazuhaarr[l][anum]),
            howtheformat: rick,
            timeEntry_Date: self.Showwithdateformat(self.checkudenfine(self.kazuhaarr[l][bnum])),
            TimeType: self.checkudenfine(self.kazuhaarr[l][cnum]).trim(),
          };
          self.chosenfile.push(List);
          self.continueornot = true;
        }

        if (self.continueornot)
        {
          for (let j = 0; j < self.chosenfile.length; j++) {
            let line = j + 1;
            if (self.chosenfile[j].howtheformat)
            {
              let isitthere = self.PersonalList.some(
                (p) =>
                  p.employee_Code == self.chosenfile[j].Employee_ID.toString()
              );
              if (isitthere)
              {
                if (
                  self.chosenfile[j].TimeType == self.realIn ||
                  self.chosenfile[j].TimeType == self.realOut
                )
                {
                  for (let o = 0; o < self.ShiftList.length; o++) {
                    if (
                      self.chosenfile[j].Employee_ID ==
                      self.ShiftList[o].employee_Code
                    )
                    {
                      if (
                        self.chosenfile[j].ShiftCode ==
                        self.ShiftList[o].time_Group
                      )
                      {
                        let dd = self.ChangeToDate(
                          self.chosenfile[j].DateAndTime
                        );
                        const date1 = new Date(dd);
                        let monvalue = date1.getMonth() + 1;
                        if (
                          self.todaymonth == monvalue &&
                          self.todayyears == date1.getFullYear()
                        )
                        {
                          if (
                            moment(self.ShiftList[o].effective_Date) <
                            moment(dd).add(1, "day")
                          )
                          {
                            let k = 0;
                            if (j < self.chosenfile.length - 1)
                            {
                              k = j + 1;
                            }
                            else
                            {
                              k = j;
                            }
                            if (j == 0)
                            {
                              result[j].employee_Code =
                                self.chosenfile[j].Employee_ID.toString();
                              result[j].timeEntry_Date = self.ChangeToDate(
                                self.chosenfile[j].DateAndTime
                              );
                              result[j].company_ID = store.state.companyID;
                              result[j].user_ID = store.state.userid;
                              result[j].inOut_Required = self.realInOut;
                              result[j].jarviz = false;
                              if (self.chosenfile[j].TimeType == self.realIn)
                              {
                                result[j].clock_In_Time = self.ChangeToTime(
                                  self.chosenfile[j].DateAndTime
                                );
                                result[j].clock_In_Location = "N/A";
                                result[j].actual_Clock_In_Time =
                                  self.ChangeToActualTime(
                                    self.chosenfile[j].DateAndTime
                                  );
                                result[j].shift_Start =
                                  self.ChangeToActualTimeForShift(
                                    self.chosenfile[j].DateAndTime
                                  );
                                if (
                                  self.chosenfile[j].Employee_ID ==
                                  self.chosenfile[k].Employee_ID &&
                                  self.chosenfile[k].TimeType ===
                                  self.realOut &&
                                  self.chosenfile[j].timeEntry_Date ==
                                  self.chosenfile[k].timeEntry_Date
                                )
                                {
                                  result[j].clock_Out_Time = self.ChangeToTime(
                                    self.chosenfile[k].DateAndTime
                                  );
                                  result[j].actual_Clock_Out_Time =
                                    self.ChangeToActualTime(
                                      self.chosenfile[k].DateAndTime
                                    );
                                  result[j].clock_Out_Location = "N/A";
                                  result[j].shift_End =
                                    self.ChangeToActualTimeForShift(
                                      self.chosenfile[k].DateAndTime
                                    );
                                  j++;
                                }
                                else
                                {
                                  result[j].clock_Out_Time = "-";
                                  result[j].actual_Clock_Out_Time = "-";
                                }
                              }
                              else if (
                                self.chosenfile[j].TimeType == self.realOut
                              )
                              {
                                result[j].clock_In_Time = "-";
                                result[j].clock_In_Location = "N/A";
                                result[j].clock_In_Lattitude = "";
                                result[j].clock_In_Longitude = "";
                                result[j].actual_Clock_In_Time = "-";
                                result[j].shift_Start = "-";
                                result[j].clock_Out_Time = self.ChangeToTime(
                                  self.chosenfile[j].DateAndTime
                                );
                                result[j].actual_Clock_Out_Time =
                                  self.ChangeToActualTime(
                                    self.chosenfile[j].DateAndTime
                                  );
                                result[j].clock_Out_Location = "N/A";
                                result[j].shift_End =
                                  self.ChangeToActualTimeForShift(
                                    self.chosenfile[j].DateAndTime
                                  );
                              }
                            }
                            else
                            {
                              let inn = "-";
                              let out = "-";
                              let actinn = "-";
                              let actout = "-";
                              let sin = "-";
                              let sout = "-";
                              if (self.chosenfile[j].TimeType === self.realIn)
                              {
                                inn = self.ChangeToTime(
                                  self.chosenfile[j].DateAndTime
                                );
                                actinn = self.ChangeToActualTime(
                                  self.chosenfile[j].DateAndTime
                                );
                                sin = self.ChangeToActualTimeForShift(
                                  self.chosenfile[j].DateAndTime
                                );
                                if (
                                  self.chosenfile[j].Employee_ID ==
                                  self.chosenfile[k].Employee_ID &&
                                  self.chosenfile[k].TimeType ===
                                  self.realOut &&
                                  self.chosenfile[j].timeEntry_Date ==
                                  self.chosenfile[k].timeEntry_Date
                                ) {
                                  out = self.ChangeToTime(
                                    self.chosenfile[k].DateAndTime
                                  );
                                  actout = self.ChangeToActualTime(
                                    self.chosenfile[k].DateAndTime
                                  );
                                  sout = self.ChangeToActualTimeForShift(
                                    self.chosenfile[k].DateAndTime
                                  );
                                  j++;
                                }
                                else
                                {
                                  out = "-";
                                  actout = "-";
                                }
                              }
                              else if (
                                self.chosenfile[j].TimeType === self.realOut
                              )
                              {
                                inn = "-";
                                actinn = "-";
                                sin = "-";
                                out = self.ChangeToTime(
                                  self.chosenfile[j].DateAndTime
                                );
                                actout = self.ChangeToActualTime(
                                  self.chosenfile[j].DateAndTime
                                );
                                sout = self.ChangeToActualTimeForShift(
                                  self.chosenfile[j].DateAndTime
                                );
                              }

                              result.push({
                                employee_Code:
                                  self.chosenfile[j].Employee_ID.toString(),
                                timeEntry_Date: self.ChangeToDate(
                                  self.chosenfile[j].DateAndTime
                                ),
                                company_ID: store.state.companyID,
                                user_ID: store.state.userid,
                                clock_In_Time: inn,
                                clock_Out_Time: out,
                                actual_Clock_In_Time: actinn,
                                actual_Clock_Out_Time: actout,
                                clock_In_Lattitude: "",
                                clock_In_Longitude: "",
                                clock_In_Location: "N/A",
                                clock_Out_Location: "N/A",
                                shift_Start: sin,
                                shift_End: sout,
                                inOut_Required: self.realInOut,
                                jarviz: false,
                              });
                            }
                          }
                          else
                          {
                            self.ErrorArray.push({
                              employee_Code:
                                self.chosenfile[j].Employee_ID.toString(),
                              error_Type:
                                self.formatDate1(dd) +
                                " was before Shift Effective Date at line " +
                                line,
                            });
                            let removeIndex = self.combileArray
                              .map((item) => item.id)
                              .indexOf(j);
                            self.combileArray.splice(removeIndex, 1);
                          }
                        }
                        else
                        {
                          self.ErrorArray.push({
                            employee_Code:
                              self.chosenfile[j].Employee_ID.toString(),
                            error_Type:
                              "Date time doesn't match at line " + line,
                          });
                          let removeIndex = self.combileArray
                            .map((item) => item.id)
                            .indexOf(j);
                          self.combileArray.splice(removeIndex, 1);
                        }
                      }
                      else
                      {
                        self.ErrorArray.push({
                          employee_Code:
                            self.chosenfile[j].Employee_ID.toString(),
                          error_Type:
                            "Shift value don't match the template at line " +
                            line,
                        });
                        let removeIndex = self.combileArray
                          .map((item) => item.id)
                          .indexOf(j);
                        self.combileArray.splice(removeIndex, 1);
                      }
                    }
                  }
                }
                else
                {
                  self.ErrorArray.push({
                    employee_Code: self.chosenfile[j].Employee_ID.toString(),
                    error_Type:
                      "In/Out value don't match the template at line " + line,
                  });
                  let removeIndex = self.combileArray
                    .map((item) => item.id)
                    .indexOf(j);
                  self.combileArray.splice(removeIndex, 1);
                }
              }
              else
              {
                self.ErrorArray.push({
                  employee_Code: self.chosenfile[j].Employee_ID.toString(),
                  error_Type: "Employee ID doesn't match at line " + line,
                });
                let removeIndex = self.combileArray
                  .map((item) => item.id)
                  .indexOf(j);
                self.combileArray.splice(removeIndex, 1);
              }
            }
            else
            {
              self.ErrorArray.push({
                employee_Code: self.chosenfile[j].Employee_ID.toString(),
                error_Type:
                  "Data format at line " +
                  line +
                  " doesn't match the Template file.",
              });
              let removeIndex = self.combileArray
                .map((item) => item.id)
                .indexOf(j);
              self.combileArray.splice(removeIndex, 1);
            }
          }
        }
        return result;
      }
      else
      {
        if (self.realheader1 == "Date")
        {
          cnum = 0;
        }
        else if (self.realheader2 == "Date")
        {
          cnum = 1;
        }
        else if (self.realheader3 == "Date")
        {
          cnum = 2;
        }
        else if (self.realheader4 == "Date")
        {
          cnum = 3;
        }
        else if (self.realheader5 == "Date")
        {
          cnum = 4;
        }

        for (let l = 0; l < self.kazuhaarr.length; l++)
        {
          let rick = self.thenewdateformatchecker(self.checkudenfine(self.kazuhaarr[l][cnum]),this.realdateformat);
          let morty = self.thenewtimeformatchecker(self.checkudenfine(self.kazuhaarr[l][bnum]));

          let List = {
            Date: self.checkudenfine(self.kazuhaarr[l][cnum]),
            Time: self.checkudenfine(self.kazuhaarr[l][bnum]),
            Employee_ID: self.kazuhaarr[l][anum],
            howthedateformat: rick,
            howthetimeformat: morty,
            //timeEntry_Date: self.Showwithdateformat(self.kazuhaarr[l][bnum]),
          };
          self.chosenfile.push(List);
          self.continueornot = true;
        }

        if (self.continueornot)
        {
          for (let j = 0; j < self.chosenfile.length; j++)
          {
            let line = j + 1;
            if (self.chosenfile[j].howthedateformat && self.chosenfile[j].howthetimeformat)
            {
                let isitthere = self.PersonalList.some(
                  (p) =>
                    p.employee_Code == self.chosenfile[j].Employee_ID.toString()
                );

                if (isitthere)
                {
                  for (let o = 0; o < self.ShiftList.length; o++)
                  {
                    if (
                      self.chosenfile[j].Employee_ID ==
                      self.ShiftList[o].employee_Code
                    )
                    {
                      let dd = self.newDateChanger(
                        self.chosenfile[j].Date
                      );
                      const date1 = new Date(dd);
                      let monvalue = date1.getMonth() + 1;
                      if ( self.todaymonth == monvalue && self.todayyears == date1.getFullYear())
                      {
                        if (self.ShiftList[o].dateList.length != 0)
                        {
                          let moveforward = false;
                          for (let p = 0; p < self.ShiftList[o].dateList.length; p++) {
                            if (self.ShiftList[o].dateList[p].disableEndDate)
                            {
                              if (moment(self.ShiftList[o].dateList[p].startDate) < moment(dd).add(1, "day"))
                              {
                                moveforward = true;
                                break;
                              }
                              else
                              {
                                moveforward = false;
                              }
                            }
                            else
                            {
                              if (
                                (moment(self.ShiftList[o].dateList[p].startDate) <
                                  moment(dd).add(1, "day")) &&
                                (moment(self.ShiftList[o].dateList[p].endDate) >
                                  moment(dd).add(1, "day")))
                              {
                                moveforward = true;
                                break;
                              }
                              else
                              {
                                moveforward = false;
                              }
                            }
                          }

                          if (moveforward)
                          {
                            if (j == 0)
                            {
                              for (let j = 0; j < self.chosenfile.length; j++)
                              {
                                let entry = {
                                  employee_Code: self.chosenfile[j].Employee_ID.toString(),
                                  timeEntry_Date: self.newDateChanger(self.chosenfile[j].Date),
                                  inOut_Required: self.realInOut,
                                  jarviz: false,
                                  company_ID: store.state.companyID,
                                  user_ID: store.state.userid,
                                  clock_In_Time: self.newDateTimeChanger(
                                    self.chosenfile[j].Date,
                                    self.chosenfile[j].Time
                                  ),
                                  clock_In_Location: "N/A",
                                  actual_Clock_In_Time: self.newActualDateTimeChanger(
                                    self.chosenfile[j].Date,
                                    self.chosenfile[j].Time
                                  ),
                                  shift_Start: self.newActualShiftDateTimeChanger(
                                    self.chosenfile[j].Time
                                  ),
                                  clock_Out_Time: "-",
                                  actual_Clock_Out_Time: "-",
                                  clock_Out_Location: "N/A",
                                  shift_End: "-",
                                };
                                result.push(entry);
                              }
                            }
                            else
                            {
                              let inn = "-";
                              let out = "-";
                              let actinn = "-";
                              let actout = "-";
                              let sin = "-";
                              let sout = "-";

                              inn = self.newDateTimeChanger(
                                self.chosenfile[j].Date, self.chosenfile[j].Time
                              );
                              actinn = self.newActualDateTimeChanger(
                                self.chosenfile[j].Date, self.chosenfile[j].Time
                              );
                              sin = self.newActualShiftDateTimeChanger(
                                self.chosenfile[j].Time
                              );
                              out = "-";
                              actout = "-";
                              sout = "-";

                              result.push({
                                employee_Code:
                                  self.chosenfile[j].Employee_ID.toString(),
                                timeEntry_Date: self.newDateChanger(
                                  self.chosenfile[j].Date
                                ),
                                company_ID: store.state.companyID,
                                user_ID: store.state.userid,
                                clock_In_Time: inn,
                                clock_Out_Time: out,
                                clock_In_Location: "N/A",
                                clock_Out_Location: "N/A",
                                shift_Start: sin,
                                shift_End: sout,
                                inOut_Required: self.realInOut,
                                jarviz: false,
                                actual_Clock_In_Time: actinn,
                                actual_Clock_Out_Time: actout,
                              });
                            }
                          }
                          else
                          {
                            self.ErrorArray.push({
                              employee_Code:
                                self.chosenfile[j].Employee_ID.toString(),
                              error_Type:
                                self.formatDate1(dd) +
                                " don't have Shift Time at line " +
                                line,
                            });
                            let removeIndex = self.combileArray
                              .map((item) => item.id)
                              .indexOf(j);
                            self.combileArray.splice(removeIndex, 1);
                          }
                        }
                        else
                        {
                          self.ErrorArray.push({
                            employee_Code:
                              self.chosenfile[j].Employee_ID.toString(),
                            // error_Type:
                            //   "This Employee doesn't have Shift Date at line " +
                            //   line,
                            error_Type:
                              "This Employee doesn't have Shift Date."
                          });
                          let removeIndex = self.combileArray
                            .map((item) => item.id)
                            .indexOf(j);
                          self.combileArray.splice(removeIndex, 1);
                          self.tableShow = false;
                        }
                      }
                      else
                      {
                        self.ErrorArray.push({
                          employee_Code:
                            self.chosenfile[j].Employee_ID.toString(),
                          error_Type: "Date time doesn't match."
                        });
                        let removeIndex = self.combileArray
                          .map((item) => item.id)
                          .indexOf(j);
                        self.combileArray.splice(removeIndex, 1);
                        self.tableShow = false;
                      }
                    }
                  }
                }
                else
                {
                  self.ErrorArray.push({
                    employee_Code: self.chosenfile[j].Employee_ID.toString(),
                    error_Type: "Employee ID doesn't match.",
                  });
                  let removeIndex = self.combileArray
                    .map((item) => item.id)
                    .indexOf(j);
                  self.combileArray.splice(removeIndex, 1);
                  self.tableShow = false;
                }
            }
            else
            {
              self.ErrorArray.push({
                employee_Code: self.chosenfile[j].Employee_ID.toString(),
                error_Type: "Data format doesn't match the Template file.",
              });
              let removeIndex = self.combileArray
                .map((item) => item.id)
                .indexOf(j);
              self.combileArray.splice(removeIndex, 1);
              self.tableShow = false;
            }
          }
        }
        return result;
      }
    },

    checkudenfine(item) {
      if (item == undefined)
      {
        return "-";
      }
      else
      {
        return item;
      }
    },

    tempuploadlistwithcsv() {
      let self = this;
      self.chosenfile = [];
      if (self.realInOut)
      {
        for (let u = 0; u < self.kazuhaarr.length; u++)
        {
          let dati = "";
          let eid = "";
          let timeheader = "";
          if (self.realheader1 == "Date")
          {
            dati = self.kazuhaarr[u].tempdata1;
          }
          else if (self.realheader2 == "Date")
          {
            dati = self.kazuhaarr[u].tempdata2;
          }
          else if (self.realheader3 == "Date")
          {
            dati = self.kazuhaarr[u].tempdata3;
          }

          if (self.realheader1 == "Time")
          {
            timeheader = self.kazuhaarr[u].tempdata1;
          }
          else if (self.realheader2 == "Time")
          {
            timeheader = self.kazuhaarr[u].tempdata2;
          }
          else if (self.realheader3 == "Time")
          {
            timeheader = self.kazuhaarr[u].tempdata3;
          }

          if (self.realheader1 == "Employee ID")
          {
            eid = self.kazuhaarr[u].tempdata1;
          }
          else if (self.realheader2 == "Employee ID")
          {
            eid = self.kazuhaarr[u].tempdata2;
          }
          else if (self.realheader3 == "Employee ID")
          {
            eid = self.kazuhaarr[u].tempdata3;
          }

          let rick = self.thenewdateformatchecker(dati);

          let List = {
            DateAndTime: dati,
            Employee_ID: eid,
            howtheformat: rick,
            timeEntry_Date: timeheader,
          };
          self.chosenfile.push(List);
          self.continueornot = true;
        }

        let result = [];

        if (self.continueornot)
        {
          // for (let j = 0; j < self.chosenfile.length; j++) {
          //   let line = j + 1;
          //   if (self.chosenfile[j].howtheformat)
          //   {
          //     let isitthere = self.PersonalList.some(
          //       (p) =>
          //         p.employee_Code == self.chosenfile[j].Employee_ID.toString()
          //     );
          //     if (isitthere)
          //     {
          //       if (
          //         self.chosenfile[j].TimeType == self.realIn ||
          //         self.chosenfile[j].TimeType == self.realOut
          //       ) {
          //         for (let o = 0; o < self.ShiftList.length; o++) {
          //           if (
          //             self.chosenfile[j].Employee_ID ==
          //             self.ShiftList[o].employee_Code
          //           )
          //           {
          //             if (
          //               self.chosenfile[j].ShiftCode ==
          //               self.ShiftList[o].time_Group
          //             )
          //             {
          //               let dd = self.ChangeToDate(
          //                 self.chosenfile[j].DateAndTime
          //               );
          //               const date1 = new Date(dd);
          //               let monvalue = date1.getMonth() + 1;
          //               if (
          //                 self.todaymonth == monvalue &&
          //                 self.todayyears == date1.getFullYear()
          //               )
          //               {
          //                 if (
          //                   moment(self.ShiftList[o].effective_Date) <
          //                   moment(dd).add(1, "day")
          //                 )
          //                 {
          //                   let k = 0;
          //                   if (j < self.chosenfile.length - 1)
          //                   {
          //                     k = j + 1;
          //                   }
          //                   else
          //                   {
          //                     k = j;
          //                   }

          //                   if (j == 0)
          //                   {
          //                     result[j].employee_Code =
          //                       self.chosenfile[j].Employee_ID.toString();
          //                     result[j].timeEntry_Date = self.ChangeToDate(
          //                       self.chosenfile[j].DateAndTime
          //                     );
          //                     result[j].company_ID = store.state.companyID;
          //                     result[j].user_ID = store.state.userid;
          //                     result[j].inOut_Required = self.realInOut;
          //                     result[j].jarviz = false;
          //                     if (self.chosenfile[j].TimeType == self.realIn)
          //                     {
          //                       result[j].clock_In_Time = self.ChangeToTime(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       result[j].clock_In_Location = "N/A";
          //                       result[j].actual_Clock_In_Time =
          //                         self.ChangeToActualTime(
          //                           self.chosenfile[j].DateAndTime
          //                         );
          //                       result[j].shift_Start =
          //                         self.ChangeToActualTimeForShift(
          //                           self.chosenfile[j].DateAndTime
          //                         );
          //                       if (
          //                         self.chosenfile[j].Employee_ID ==
          //                         self.chosenfile[k].Employee_ID &&
          //                         self.chosenfile[k].TimeType ===
          //                         self.realOut &&
          //                         self.chosenfile[j].timeEntry_Date ==
          //                         self.chosenfile[k].timeEntry_Date
          //                       )
          //                       {
          //                         result[j].clock_Out_Time = self.ChangeToTime(
          //                           self.chosenfile[k].DateAndTime
          //                         );
          //                         result[j].actual_Clock_Out_Time =
          //                           self.ChangeToActualTime(
          //                             self.chosenfile[k].DateAndTime
          //                           );
          //                         result[j].clock_Out_Location = "N/A";
          //                         result[j].shift_End =
          //                           self.ChangeToActualTimeForShift(
          //                             self.chosenfile[k].DateAndTime
          //                           );
          //                         j++;
          //                       }
          //                       else
          //                       {
          //                         result[j].clock_Out_Time = "-";
          //                         result[j].actual_Clock_Out_Time = "-";
          //                       }
          //                     }
          //                     else if (
          //                       self.chosenfile[j].TimeType == self.realOut
          //                     )
          //                     {
          //                       result[j].clock_In_Time = "-";
          //                       result[j].clock_In_Location = "N/A";
          //                       result[j].actual_Clock_In_Time = "-";
          //                       result[j].shift_Start = "-";
          //                       result[j].clock_Out_Time = self.ChangeToTime(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       result[j].actual_Clock_Out_Time =
          //                         self.ChangeToActualTime(
          //                           self.chosenfile[j].DateAndTime
          //                         );
          //                       result[j].clock_Out_Location = "N/A";
          //                       result[j].shift_End =
          //                         self.ChangeToActualTimeForShift(
          //                           self.chosenfile[j].DateAndTime
          //                         );
          //                     }
          //                   }
          //                   else
          //                   {
          //                     let inn = "-";
          //                     let out = "-";
          //                     let actinn = "-";
          //                     let actout = "-";
          //                     let sin = "-";
          //                     let sout = "-";
          //                     if (self.chosenfile[j].TimeType === self.realIn)
          //                     {
          //                       inn = self.ChangeToTime(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       actinn = self.ChangeToActualTime(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       sin = self.ChangeToActualTimeForShift(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       if (
          //                         self.chosenfile[j].Employee_ID ==
          //                         self.chosenfile[k].Employee_ID &&
          //                         self.chosenfile[k].TimeType ===
          //                         self.realOut &&
          //                         self.chosenfile[j].timeEntry_Date ==
          //                         self.chosenfile[k].timeEntry_Date
          //                       )
          //                       {
          //                         out = self.ChangeToTime(
          //                           self.chosenfile[k].DateAndTime
          //                         );
          //                         actout = self.ChangeToActualTime(
          //                           self.chosenfile[k].DateAndTime
          //                         );
          //                         sout = self.ChangeToActualTimeForShift(
          //                           self.chosenfile[k].DateAndTime
          //                         );
          //                         j++;
          //                       }
          //                       else
          //                       {
          //                         out = "-";
          //                         actout = "-";
          //                       }
          //                     }
          //                     else if (
          //                       self.chosenfile[j].TimeType === self.realOut
          //                     )
          //                     {
          //                       inn = "-";
          //                       actinn = "-";
          //                       sin = "-";
          //                       out = self.ChangeToTime(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       actout = self.ChangeToActualTime(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                       sout = self.ChangeToActualTimeForShift(
          //                         self.chosenfile[j].DateAndTime
          //                       );
          //                     }

          //                     result.push({
          //                       employee_Code:
          //                         self.chosenfile[j].Employee_ID.toString(),
          //                       timeEntry_Date: self.ChangeToDate(
          //                         self.chosenfile[j].DateAndTime
          //                       ),
          //                       company_ID: store.state.companyID,
          //                       user_ID: store.state.userid,
          //                       clock_In_Time: inn,
          //                       clock_Out_Time: out,
          //                       clock_In_Location: "N/A",
          //                       clock_Out_Location: "N/A",
          //                       shift_Start: sin,
          //                       shift_End: sout,
          //                       inOut_Required: self.realInOut,
          //                       jarviz: false,
          //                       actual_Clock_In_Time: actinn,
          //                       actual_Clock_Out_Time: actout,
          //                     });
          //                   }
          //                 }
          //                 else
          //                 {
          //                   self.ErrorArray.push({
          //                     employee_Code:
          //                       self.chosenfile[j].Employee_ID.toString(),
          //                     error_Type:
          //                       self.formatDate1(dd) +
          //                       " was before Shift Effective Date at line " +
          //                       line,
          //                   });
          //                   let removeIndex = self.combileArray
          //                     .map((item) => item.id)
          //                     .indexOf(j);
          //                   self.combileArray.splice(removeIndex, 1);
          //                 }
          //               }
          //               else
          //               {
          //                 self.ErrorArray.push({
          //                   employee_Code:
          //                     self.chosenfile[j].Employee_ID.toString(),
          //                   error_Type:
          //                     "Date time doesn't match at line " + line,
          //                 });
          //                 let removeIndex = self.combileArray
          //                   .map((item) => item.id)
          //                   .indexOf(j);
          //                 self.combileArray.splice(removeIndex, 1);
          //               }
          //             }
          //             else
          //             {
          //               self.ErrorArray.push({
          //                 employee_Code:
          //                   self.chosenfile[j].Employee_ID.toString(),
          //                 error_Type:
          //                   "Shift value don't match the template at line " +
          //                   line,
          //               });
          //               let removeIndex = self.combileArray
          //                 .map((item) => item.id)
          //                 .indexOf(j);
          //               self.combileArray.splice(removeIndex, 1);
          //             }
          //           }
          //         }
          //       }
          //       else
          //       {
          //         self.ErrorArray.push({
          //           employee_Code: self.chosenfile[j].Employee_ID.toString(),
          //           error_Type:
          //             "In/Out value don't match the template at line " + line,
          //         });
          //         let removeIndex = self.combileArray
          //           .map((item) => item.id)
          //           .indexOf(j);
          //         self.combileArray.splice(removeIndex, 1);
          //       }
          //     }
          //     else
          //     {
          //       self.ErrorArray.push({
          //         employee_Code: self.chosenfile[j].Employee_ID.toString(),
          //         error_Type: "Employee ID doesn't match at line " + line,
          //       });
          //       let removeIndex = self.combileArray
          //         .map((item) => item.id)
          //         .indexOf(j);
          //       self.combileArray.splice(removeIndex, 1);
          //     }
          //   }
          //   else
          //   {
          //     self.ErrorArray.push({
          //       employee_Code: self.chosenfile[j].Employee_ID.toString(),
          //       error_Type:
          //         "Data format at line " +
          //         line +
          //         " doesn't match the Template file.",
          //     });
          //     let removeIndex = self.combileArray
          //       .map((item) => item.id)
          //       .indexOf(j);
          //     self.combileArray.splice(removeIndex, 1);
          //   }
          // }
        }
        return result;
      }
      else
      {
        for (let u = 0; u < self.kazuhaarr.length; u++)
        {
          let dati = "";
          let eid = "";
          let timeheader = "";
          if (self.realheader1 == "Date")
          {
            dati = self.kazuhaarr[u].tempdata1;
          }
          else if (self.realheader2 == "Date")
          {
            dati = self.kazuhaarr[u].tempdata2;
          }
          else if (self.realheader3 == "Date")
          {
            dati = self.kazuhaarr[u].tempdata3;
          }

          if (self.realheader1 == "Time")
          {
            timeheader = self.kazuhaarr[u].tempdata1;
          }
          else if (self.realheader2 == "Time")
          {
            timeheader = self.kazuhaarr[u].tempdata2;
          }
          else if (self.realheader3 == "Time")
          {
            timeheader = self.kazuhaarr[u].tempdata3;
          }

          if (self.realheader1 == "Employee ID")
          {
            eid = self.kazuhaarr[u].tempdata1;
          }
          else if (self.realheader2 == "Employee ID")
          {
            eid = self.kazuhaarr[u].tempdata2;
          }
          else if (self.realheader3 == "Employee ID")
          {
            eid = self.kazuhaarr[u].tempdata3;
          }

          let rick = self.thenewdateformatchecker(dati);

          let List = {
            DateAndTime: dati,
            Employee_ID: eid,
            howtheformat: rick,
            timeEntry_Date: timeheader
          };
          self.chosenfile.push(List);
          self.continueornot = true;
        }

        let result = [];

        if (self.continueornot)
        {
          for (let j = 0; j < self.chosenfile.length; j++) {
            let line = j + 1;
            if (self.chosenfile[j].howtheformat)
            {
              let isitthere = self.PersonalList.some(
                (p) =>
                  p.employee_Code == self.chosenfile[j].Employee_ID.toString()
              );
              if (isitthere)
              {
                // for (let o = 0; o < self.ShiftList.length; o++) {
                //   if (
                //     self.chosenfile[j].Employee_ID ==
                //     self.ShiftList[o].employee_Code
                //   )
                //   {
                //     if (
                //       self.chosenfile[j].ShiftCode ==
                //       self.ShiftList[o].time_Group
                //     )
                //     {
                //       let dd = self.ChangeToDate(
                //         self.chosenfile[j].DateAndTime
                //       );
                //       const date1 = new Date(dd);
                //       let monvalue = date1.getMonth() + 1;
                //       if (
                //         self.todaymonth == monvalue &&
                //         self.todayyears == date1.getFullYear()
                //       )
                //       {
                //         if (
                //           moment(self.ShiftList[o].effective_Date) <
                //           moment(dd).add(1, "day")
                //         )
                //         {
                //           if (j == 0)
                //           {
                //             for (let j = 0; j < self.chosenfile.length; j++)
                //             {
                //               let entry = {
                //                 employee_Code: self.chosenfile[j].Employee_ID.toString(),
                //                 timeEntry_Date: self.ChangeToDate(self.chosenfile[j].DateAndTime),
                //                 company_ID: store.state.companyID,
                //                 user_ID: store.state.userid,
                //                 clock_In_Time: self.ChangeToTime(self.chosenfile[j].DateAndTime),
                //                 clock_In_Location: "N/A",
                //                 actual_Clock_In_Time: self.ChangeToActualTime(self.chosenfile[j].DateAndTime),
                //                 shift_Start: self.ChangeToActualTimeForShift(self.chosenfile[j].DateAndTime),
                //                 inOut_Required: self.realInOut,
                //                 jarviz: false,
                //                 clock_Out_Time: "-",
                //                 actual_Clock_Out_Time: "-",
                //                 clock_Out_Location: "N/A",
                //                 shift_End: "-",
                //               };
                //               result.push(entry);
                //             }
                //           }
                //           else
                //           {
                //             let inn = "-";
                //             let out = "-";
                //             let actinn = "-";
                //             let actout = "-";
                //             let sin = "-";
                //             let sout = "-";

                //             inn = self.ChangeToTime(
                //               self.chosenfile[j].DateAndTime
                //             );
                //             actinn = self.ChangeToActualTime(
                //               self.chosenfile[j].DateAndTime
                //             );
                //             sin = self.ChangeToActualTimeForShift(
                //               self.chosenfile[j].DateAndTime
                //             );
                //             out = "-";
                //             actout = "-";
                //             sout = "-";

                //             result.push({
                //               employee_Code:
                //                 self.chosenfile[j].Employee_ID.toString(),
                //               timeEntry_Date: self.ChangeToDate(
                //                 self.chosenfile[j].DateAndTime
                //               ),
                //               company_ID: store.state.companyID,
                //               user_ID: store.state.userid,
                //               clock_In_Time: inn,
                //               clock_Out_Time: out,
                //               clock_In_Location: "N/A",
                //               clock_Out_Location: "N/A",
                //               shift_Start: sin,
                //               shift_End: sout,
                //               inOut_Required: self.realInOut,
                //               jarviz: false,
                //               actual_Clock_In_Time: actinn,
                //               actual_Clock_Out_Time: actout,
                //             });
                //           }
                //         }
                //         else
                //         {
                //           self.ErrorArray.push({
                //             employee_Code:
                //               self.chosenfile[j].Employee_ID.toString(),
                //             error_Type:
                //               self.formatDate1(dd) +
                //               " was before Shift Effective Date at line " +
                //               line,
                //           });
                //           let removeIndex = self.combileArray
                //             .map((item) => item.id)
                //             .indexOf(j);
                //           self.combileArray.splice(removeIndex, 1);
                //         }
                //       }
                //       else
                //       {
                //         self.ErrorArray.push({
                //           employee_Code:
                //             self.chosenfile[j].Employee_ID.toString(),
                //           error_Type: "Date time doesn't match at line " + line,
                //         });
                //         let removeIndex = self.combileArray
                //           .map((item) => item.id)
                //           .indexOf(j);
                //         self.combileArray.splice(removeIndex, 1);
                //       }
                //     }
                //     else
                //     {
                //       self.ErrorArray.push({
                //         employee_Code:
                //           self.chosenfile[j].Employee_ID.toString(),
                //         error_Type:
                //           "Shift value don't match the template at line " +
                //           line,
                //       });
                //       let removeIndex = self.combileArray
                //         .map((item) => item.id)
                //         .indexOf(j);
                //       self.combileArray.splice(removeIndex, 1);
                //     }
                //   }
                // }
              }
              else
              {
                self.ErrorArray.push({
                  employee_Code: self.chosenfile[j].Employee_ID.toString(),
                  error_Type: "Employee ID doesn't match at line " + line,
                });
                let removeIndex = self.combileArray
                  .map((item) => item.id)
                  .indexOf(j);
                self.combileArray.splice(removeIndex, 1);
              }
            }
            else
            {
              self.ErrorArray.push({
                employee_Code: self.chosenfile[j].Employee_ID.toString(),
                error_Type:
                  "Data format at line " +
                  line +
                  " doesn't match the Template file.",
              });
              let removeIndex = self.combileArray
                .map((item) => item.id)
                .indexOf(j);
              self.combileArray.splice(removeIndex, 1);
            }
          }
        }
        return result;
      }
    },

    //date format checker
    heymorty(date) {
      let result = true;
      let string = this.realdateformat;
      let yearpos = string.indexOf("YYYY");
      let monpos = string.indexOf("MM");
      let daypos = string.indexOf("dd");
      let minpos = string.indexOf("mm");

      let min = date.substr(minpos, 2);
      let minpo = min.trim();
      if (minpo.length != 2)
      {
        result = false;
      }
      else
      {
        if (
          minpo.includes(":") ||
          minpo.includes("/") ||
          minpo.includes(",") ||
          minpo.includes("-") ||
          minpo.includes(";")
        )
        {
          result = false;
        }
      }

      if (string.includes("HH")) {
        let hourpos = string.indexOf("HH");
        let hour = date.substr(hourpos, 2);
        let hourpo = hour.trim();
        if (hourpo.length != 2)
        {
          result = false;
        }
        else
        {
          if (
            hourpo.includes(":") ||
            hourpo.includes("/") ||
            hourpo.includes(",") ||
            hourpo.includes("-") ||
            hourpo.includes(";")
          ) {
            result = false;
          }
        }
      }
      else if (string.includes("hh"))
      {
        let hourpos = string.indexOf("hh");
        let hour = date.substr(hourpos, 2);
        let hourpo = hour.trim();
        if (hourpo.length != 2) {
          result = false;
        }
        else
        {
          if (
            hourpo.includes(":") ||
            hourpo.includes("/") ||
            hourpo.includes(",") ||
            hourpo.includes("-") ||
            hourpo.includes(";")
          )
          {
            result = false;
          }
        }
      }

      let year = date.substr(yearpos, 4);
      let yearpo = year.trim();
      if (yearpo.length != 4)
      {
        result = false;
      }
      else
      {
        if (
          yearpo.includes(":") ||
          yearpo.includes("/") ||
          yearpo.includes(",") ||
          yearpo.includes("-") ||
          yearpo.includes(";")
        )
        {
          result = false;
        }
      }

      let days = date.substr(daypos, 2);
      let daypo = days.trim();
      if (daypo.length != 2) {
        result = false;
      }
      else
      {
        if (
          daypo.includes(":") ||
          daypo.includes("/") ||
          daypo.includes(",") ||
          daypo.includes("-") ||
          daypo.includes(";")
        )
        {
          result = false;
        }
      }

      let month = "";
      if (string.includes("MM")) {
        month = date.substr(monpos, 2);
        let monpo = month.trim();
        if (monpo.length != 2)
        {
          result = false;
        }
        else
        {
          if (
            monpo.includes(":") ||
            monpo.includes("/") ||
            monpo.includes(",") ||
            monpo.includes("-") ||
            monpo.includes(";")
          )
          {
            result = false;
          }
        }
      }
      else if (string.includes("MMM"))
      {
        if (date.includes("Jan"))
        {
          month = "Jan";
        }
        else if (date.includes("Feb"))
        {
          month = "Jan";
        }
        else if (date.includes("Mar"))
        {
          month = "Jan";
        }
        else if (date.includes("Apr"))
        {
          month = "Jan";
        }
        else if (date.includes("May"))
        {
          month = "Jan";
        }
        else if (date.includes("Jun") || date.includes("June"))
        {
          month = "Jan";
        }
        else if (date.includes("Jul") || date.includes("July"))
        {
          month = "Jan";
        }
        else if (date.includes("Aug")) {
          month = "Jan";
        }
        else if (date.includes("Sep") || date.includes("Sept"))
        {
          month = "Jan";
        }
        else if (date.includes("Oct"))
        {
          month = "Oct";
        }
        else if (date.includes("Nov"))
        {
          month = "Nov";
        }
        else if (date.includes("Dec"))
        {
          month = "Dec";
        }
        else
        {
          result = false;
        }
      }
      else if (string.includes("MMMM"))
      {
        if (date.includes("January"))
        {
          month = "Jan";
        }
        else if (date.includes("February"))
        {
          month = "Jan";
        }
        else if (date.includes("March"))
        {
          month = "Jan";
        }
        else if (date.includes("April"))
        {
          month = "Jan";
        }
        else if (date.includes("May"))
        {
          month = "Jan";
        } else if (date.includes("June"))
        {
          month = "Jan";
        }
        else if (date.includes("July"))
        {
          month = "Jan";
        }
        else if (date.includes("August"))
        {
          month = "Jan";
        }
        else if (date.includes("September"))
        {
          month = "Jan";
        }
        else if (date.includes("October"))
        {
          month = "Jan";
        }
        else if (date.includes("November"))
        {
          month = "Jan";
        }
        else if (date.includes("December"))
        {
          month = "Jan";
        }
        else
        {
          result = false;
        }
      }
      return result;
    },

    parseDateString(dateString, formatText) {
      const format = this.dateFormatList.find((format) => format.text === formatText);
      if (format)
      {
        const options = { year: format.value >= 8 ? "numeric" : format.value <= 1 ? "2-digit" : undefined, month: "2-digit", day: "2-digit" };
        const dateFormatter = new Intl.DateTimeFormat(undefined, options);
        const parsedDate = dateFormatter.format(new Date(dateString));

        if (parsedDate === dateString)
        {
          return parsedDate;
        }
        else
        {
          //console.log("Text and format do not match");
          return null;
        }
      }
      else
      {
        //console.log("Invalid date format");
        return null;
      }
    },

    parseTimeString(timeString, formatText) {
      const format = this.timeFormatList.find((format) => format.text === formatText);
      if (format)
      {
        const options = { hour: "numeric", minute: "2-digit", second: format.text.includes("SS") ? "2-digit" : undefined, hour12: format.text.includes("tt") };
        const timeFormatter = new Intl.DateTimeFormat(undefined, options);
        const parsedTime = timeFormatter.format(new Date(`2000-01-01T${timeString}`));

        if (parsedTime === timeString)
        {
          return parsedTime;
        }
        else
        {
          //console.log("Text and format do not match");
          return null;
        }
      }
      else
      {
        //console.log("Invalid time format");
        return null;
      }
    },

    parseDateTimeString(dateString, timeString) {
      let datefmstring = this.realdateformat;
      let timesfmtring = this.realtimeformat;
      const parsedDate = this.parseDateString(dateString, datefmstring);
      const parsedTime = this.parseTimeString(timeString, timesfmtring);

      if (parsedDate !== null && parsedTime !== null)
      {
        const combinedDateTimeString = `${parsedDate} ${parsedTime}`;
        return new Date(combinedDateTimeString);
      }
      else
      {
        //console.log("Failed to parse date and time");
        return null;
      }
    },

    thenewdateformatchecker(date, expectedFormat) {
      const dateParts = date.split(/[/-]/);
      const [firstPart, secondPart, thirdPart] = dateParts.map(part => parseInt(part.trim(), 10));

      if (isNaN(firstPart) || isNaN(secondPart) || isNaN(thirdPart)) {
          return false; // If any part is not a valid number, return false
      }

      // Check if the date is in one of the supported formats
      const isYearMonthDayFormat = ( firstPart.toString().length === 4 || firstPart.toString().length === 2 ) && secondPart <= 12 && thirdPart <= this.getDaysInMonth(firstPart,secondPart);
      const isYearDayMonthFormat = ( firstPart.toString().length === 4 || firstPart.toString().length === 2 ) && secondPart <= this.getDaysInMonth(firstPart,thirdPart) && thirdPart <= 12;
      const isMonthDayYearFormat = firstPart <= 12 && secondPart <= this.getDaysInMonth(thirdPart,firstPart) && ( thirdPart.toString().length === 4 || thirdPart.toString().length === 2 );
      const isMonthYearDayFormat = firstPart <= 12 &&  ( secondPart.toString().length === 4 || secondPart.toString().length === 2 ) && thirdPart <= this.getDaysInMonth(secondPart,firstPart);
      const isDayMonthYearFormat = firstPart <= this.getDaysInMonth(thirdPart,secondPart) && secondPart <= 12 && ( thirdPart.toString().length === 4 || thirdPart.toString().length === 2 );
      const isDayYearMonthFormat = firstPart <= this.getDaysInMonth(secondPart,thirdPart) && ( secondPart.toString().length === 4 || secondPart.toString().length === 2 ) && thirdPart <= 12;

      // Adjust the order of day, month, and year based on the detected format
      const [day, month, year] = isYearMonthDayFormat
          ? [thirdPart, secondPart, firstPart]
          : isYearDayMonthFormat
          ? [secondPart, thirdPart, firstPart]
          : isMonthDayYearFormat
          ? [secondPart, firstPart, thirdPart]
          : isMonthYearDayFormat
          ? [thirdPart, firstPart, secondPart]
          : isDayMonthYearFormat
          ? [firstPart, secondPart, thirdPart]
          : isDayYearMonthFormat
          ? [firstPart, thirdPart, secondPart]
          : [0, 0, 0]; // Invalid format, set placeholders

      if(expectedFormat === "d-m-yy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "dd-mm-yy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "m-d-yy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "mm-dd-yy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "yy-m-d" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "yy-mm-dd" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "d-m-yyyy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "dd-mm-yyyy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "m-d-yyyy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "mm-dd-yyyy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "yyyy-m-d" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "yyyy-mm-dd" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "d/m/yy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "dd/mm/yy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "m/d/yy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "mm/dd/yy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "yy/m/d" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "yy/mm/dd" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 2);
      }
      else if(expectedFormat === "d/m/yyyy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "dd/mm/yyyy" && isDayMonthYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "m/d/yyyy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "mm/dd/yyyy" && isMonthDayYearFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "yyyy/m/d" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "yyyy/mm/dd" && isYearMonthDayFormat)
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else if(expectedFormat === "yyyymmdd" || expectedFormat === "ddmmyyyy")
      {
        return day <= 31 && month <= 12 && (year.toString().length === 4);
      }
      else
      {
        return false;
      }
    },

    getDaysInMonth(year, month) {
      // Handle leap years for February
      if (month == 2) {
        if(year.toString().length == 2)
        {
          let fullYear = this.convertTwoDigitYearToFourDigit(year);
          return (fullYear % 4 == 0 && (fullYear % 100 != 0 || fullYear % 400 == 0)) ? 29 : 28;
        }
        else
        {
          return (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) ? 29 : 28;
        }

      }
      // For other months
      return [undefined, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    },

    convertTwoDigitYearToFourDigit(twoDigitYear) {
      const currentYear = new Date().getFullYear();
      const currentCentury = Math.floor(currentYear / 100) * 100;
      const twoDigitYearNumber = parseInt(twoDigitYear, 10);

      // Assuming a threshold for deciding whether the two-digit year belongs to the current or next century
      const threshold = 30; // You may adjust this threshold as needed

      const fullYear =
          twoDigitYearNumber < threshold
              ? currentCentury + twoDigitYearNumber
              : currentCentury - 100 + twoDigitYearNumber;

      return fullYear;
    },

    thenewtimeformatchecker(time) {
      let string = this.realtimeformat;
      let result = false;
      const [firstPart, secondPart, thirdPart, ampmPart] = time.split(/:|\s/).map(part => part.trim());
      const format1 = (!isNaN(firstPart) && !isNaN(secondPart) && !isNaN(thirdPart) && ampmPart === undefined);
      const format2 = (!isNaN(firstPart) && !isNaN(secondPart) && thirdPart === undefined && ampmPart === undefined);
      const format3 = (!isNaN(firstPart) && !isNaN(secondPart) && !isNaN(thirdPart) && ampmPart !== undefined);

      if (string == "HH:MM:SS" && format1)
      {
        const [hour, min, sec] = time.split(":");
        if (hour <= 24 && min < 60 && sec < 60)
        {
          result = true;
        }
      }
      else if (string == "HH:MM" && format2)
      {
        const [hour, min] = time.split(":");
        if (hour <= 24 && min < 60)
        {
          result = true;
        }
      }
      else if (string == "HH:MM:SS tt" && format3)
      {
        const [hour, min, secs] = time.split(":");
        const [sec, times] = secs.split(" ");
        if (hour <= 24 && min < 60 && sec < 60 && (times == "AM" || times == "PM"))
        {
          result = true;
        }
      }
      return result;
    },

    newDateChanger(date) {
      let datestring = this.realdateformat;
      let day = 0;
      let month = 0;
      let year = 0;
      let result = false;
      if (datestring.includes("-"))
      {
        //for d-m-y
        if (datestring == "dd-mm-yyyy" || datestring == "dd-mm-yy" || datestring == "dd-m-yyyy" || datestring == "d-m-yy")
        {
          [day, month, year] = date.split("-");
        }
        // for m-d-y
        if (datestring == "mm-dd-yyyy" || datestring == "mm-dd-yy" || datestring == "m-d-yyyy" || datestring == "m-d-yy")
        {
          [month, day, year] = date.split("-");
        }
        // for y-m-d
        if (datestring == "yyyy-mm-dd")
        {
          [year, month, day] = date.split("-");
        }
        if (day <= 31 && month <= 12 && (year.length == 2 || year.length == 4))
        {
          result = true;
        }
        else
        {
          result = false;
        }
      }
      else if (datestring.includes("/"))
      {
        // for d/m/y
        if (datestring == "dd/mm/yyyy" || datestring == "dd/mm/yy" || datestring == "d/m/yyyy" || datestring == "d/m/yy")
        {
          [day, month, year] = date.split("/");
        }
        // for m/d/y
        if (datestring == "mm/dd/yyyy" || datestring == "mm/dd/yy" || datestring == "m/d/yyyy" || datestring == "m/d/yy")
        {
          [month, day, year] = date.split("/");
        }
        if (day <= 31 && month <= 12 && (year.length == 2 || year.length == 4))
        {
          result = true;
        }
      }
      else if (datestring == "yyyymmdd" || datestring == "ddmmyyyy")
      {
        let yearpos = datestring.indexOf("yyyy");
        let monpos = datestring.indexOf("mm");
        let daypos = datestring.indexOf("dd");
        let yearaa = date.substr(yearpos, 4);
        year = yearaa.trim();
        let days = date.substr(daypos, 2);
        day = days.trim();

        let monthaa = date.substr(monpos, 2);
        month = monthaa.trim();

        if (day <= 31 && day.length == 2 && month <= 12 && month.length == 2 && year.length == 4)
        {
          result = true;
        }
        else
        {
          result = false;
        }
      }

      if (!result)
      {
        return "invalid date";
      }
      else
      {
        if (year.length == 2)
        {
          year = parseInt(year) + 2000;
        }
        let D = new Date(year, month - 1, day);
        return D;
      }
    },

    newDateTimeChanger(date, time) {
      let datestring = this.realdateformat;
      let timestring = this.realtimeformat;
      let day = 0;
      let month = 0;
      let year = 0;
      let hour = 0;
      let min = 0;
      let sec = 0;
      let secs = null;
      let times = null;
      let result = false;
      if (datestring.includes("-"))
      {
        //for d-m-y
        if (datestring == "dd-mm-yyyy" || datestring == "dd-mm-yy" || datestring == "dd-m-yyyy" || datestring == "d-m-yy")
        {
          [day, month, year] = date.split("-");
        }
        // for m-d-y
        if (datestring == "mm-dd-yyyy" || datestring == "mm-dd-yy" || datestring == "m-d-yyyy" || datestring == "m-d-yy")
        {
          [month, day, year] = date.split("-");
        }
        // for y-m-d
        if (datestring == "yyyy-mm-dd")
        {
          [year, month, day] = date.split("-");
        }
        if (day <= 31 && month <= 12 && (year.length == 2 || year.length == 4))
        {
          result = true;
        }
        else
        {
          result = false;
        }
      }
      else if (datestring.includes("/"))
      {
        // for d/m/y
        if (datestring == "dd/mm/yyyy" || datestring == "dd/mm/yy" || datestring == "d/m/yyyy" || datestring == "d/m/yy")
        {
          [day, month, year] = date.split("/");
        }
        // for m/d/y
        if (datestring == "mm/dd/yyyy" || datestring == "mm/dd/yy" || datestring == "m/d/yyyy" || datestring == "m/d/yy")
        {
          [month, day, year] = date.split("/");
        }
        if (day <= 31 && month <= 12 && (year.length == 2 || year.length == 4))
        {
          result = true;
        }
      }
       else if (datestring == "yyyymmdd" || datestring == "ddmmyyyy")
       {
        let yearpos = datestring.indexOf("yyyy");
        let monpos = datestring.indexOf("mm");
        let daypos = datestring.indexOf("dd");
        let yearaa = date.substr(yearpos, 4);
        year = yearaa.trim();
        let days = date.substr(daypos, 2);
        day = days.trim();
        let monthaa = date.substr(monpos, 2);
        month = monthaa.trim();

        if (day <= 31 && day.length == 2 && month <= 12 && month.length == 2 && year.length == 4)
        {
          result = true;
        }
        else
        {
          result = false;
        }
      }

      if (timestring == "HH:MM:SS")
      {
        [hour, min, sec] = time.split(":");
        if (hour <= 24 && min < 60 && sec < 60)
        {
          result = true;

        }
      }
      else if (timestring == "HH:MM")
      {
        [hour, min] = time.split(":");
        if (hour <= 24 && min < 60)
        {
          result = true;
        }
      }
      else if (timestring == "HH:MM:SS tt")
      {
        [hour, min, secs] = time.split(":");
        [sec, times] = secs.split(" ");
        if (hour <= 24 && min < 60 && sec < 60 && (times == "AM" || times == "PM"))
        {
          if (times == "PM")
          {
            if (hour == 1)
            {
              hour = 13;
            }
            else if (hour == 2)
            {
              hour = 14;
            }
            else if (hour == 3)
            {
              hour = 15;
            }
            else if (hour == 4)
            {
              hour = 16;
            }
            else if (hour == 5)
            {
              hour = 17;
            }
            else if (hour == 6)
            {
              hour = 18;
            }
            else if (hour == 7)
            {
              hour = 19;
            } else if (hour == 8)
            {
              hour = 20;
            }
            else if (hour == 9)
            {
              hour = 21;
            }
            else if (hour == 10)
            {
              hour = 22;
            }
            else if (hour == 11)
            {
              hour = 23;
            }
            else if (hour == 12)
            {
              hour = 24;
            }
          }
          result = true;
        }
        else
        {
          result = false;
        }
      }

      if (!result)
      {
        return "invalid date";
      }
      else
      {
        if (year.length == 2)
        {
          year = parseInt(year) + 2000;
        }
        let nD = new Date(year, month - 1, day);
        nD.setHours(hour);
        nD.setMinutes(min);
        nD.setSeconds(sec);

        const getutcmin = nD.getUTCMinutes();
        const getutchrs = nD.getUTCHours();
        let utctime = "";
        if (getutchrs < 10)
        {
          if (getutcmin < 10)
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              nD.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          }
          else
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              nD.getUTCHours() +
              ":" +
              getutcmin;
          }
        }
        else
        {
          if (getutcmin < 10)
           {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              nD.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          }
          else
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              nD.getUTCHours() +
              ":" +
              getutcmin;
          }
        }
        return utctime;
      }
    },

    newActualDateTimeChanger(date, time) {
      let datestring = this.realdateformat;
      let timestring = this.realtimeformat;
      let day = 0;
      let month = 0;
      let year = 0;
      let hour = 0;
      let min = 0;
      let sec = 0;
      let secs = null;
      let times = null;
      let result = false;

      if (datestring.includes("-"))
      {
        //for d-m-y
        if (datestring == "dd-mm-yyyy" || datestring == "dd-mm-yy" || datestring == "dd-m-yyyy" || datestring == "d-m-yy")
        {
          [day, month, year] = date.split("-");
        }
        // for m-d-y
        if (datestring == "mm-dd-yyyy" || datestring == "mm-dd-yy" || datestring == "m-d-yyyy" || datestring == "m-d-yy")
        {
          [month, day, year] = date.split("-");
        }
        // for y-m-d
        if (datestring == "yyyy-mm-dd")
        {
          [year, month, day] = date.split("-");
        }
        if (day <= 31 && month <= 12 && (year.length == 2 || year.length == 4))
        {
          result = true;
        }
         else
        {
          result = false;
        }
      }
      else if (datestring.includes("/"))
      {
        // for d/m/y
        if (datestring == "dd/mm/yyyy" || datestring == "dd/mm/yy" || datestring == "d/m/yyyy" || datestring == "d/m/yy")
        {
          [day, month, year] = date.split("/");
        }
        // for m/d/y
        if (datestring == "mm/dd/yyyy" || datestring == "mm/dd/yy" || datestring == "m/d/yyyy" || datestring == "m/d/yy")
        {
          [month, day, year] = date.split("/");
        }
        if (day <= 31 && month <= 12 && (year.length == 2 || year.length == 4))
        {
          result = true;
        }
      }
      else if (datestring == "yyyymmdd" || datestring == "ddmmyyyy")
      {
        let yearpos = datestring.indexOf("yyyy");
        let monpos = datestring.indexOf("mm");
        let daypos = datestring.indexOf("dd");
        let yearaa = date.substr(yearpos, 4);
        year = yearaa.trim();
        let days = date.substr(daypos, 2);
        day = days.trim();
        let monthaa = date.substr(monpos, 2);
        month = monthaa.trim();
        if (day <= 31 && day.length == 2 && month <= 12 && month.length == 2 && year.length == 4)
        {
          result = true;
        }
        else
        {
          result = false;
        }
      }
      if (timestring == "HH:MM:SS")
      {
        [hour, min, sec] = time.split(":");
        if (hour <= 24 && min < 60 && sec < 60)
        {
          result = true;

        }
      }
      else if (timestring == "HH:MM")
      {
        [hour, min] = time.split(":");
        if (hour <= 24 && min < 60)
        {
          result = true;
        }
      }
      else if (timestring == "HH:MM:SS tt")
      {
        [hour, min, secs] = time.split(":");
        [sec, times] = secs.split(" ");
        if (hour <= 24 && min < 60 && sec < 60 && (times == "AM" || times == "PM"))
        {
          if (times == "PM") {
            if (hour == 1) {
              hour = 13;
            }
            else if (hour == 2)
            {
              hour = 14;
            }
            else if (hour == 3)
            {
              hour = 15;
            }
            else if (hour == 4)
            {
              hour = 16;
            }
            else if (hour == 5)
            {
              hour = 17;
            }
            else if (hour == 6)
            {
              hour = 18;
            }
            else if (hour == 7)
            {
              hour = 19;
            }
            else if (hour == 8)
            {
              hour = 20;
            }
            else if (hour == 9)
            {
              hour = 21;
            }
            else if (hour == 10)
            {
              hour = 22;
            }
            else if (hour == 11)
            {
              hour = 23;
            }
            else if (hour == 12)
            {
              hour = 24;
            }
          }
          result = true;
        }
        else
        {
          result = false;
        }
      }

      if (!result)
      {
        return "invalid date";
      }
      else
      {
        if (year.length == 2)
        {
          year = parseInt(year) + 2000;
        }
        let nD = new Date(year, month - 1, day);
        nD.setHours(hour);
        nD.setMinutes(min);
        nD.setSeconds(sec);

        let localtime = "";
        localtime =
          nD.getFullYear() +
          "/" +
          (nD.getMonth() + 1) +
          "/" +
          nD.getDate() +
          " " +
          hour +
          ":" +
          min;
        return localtime;
      }
    },

    newActualShiftDateTimeChanger(time) {
      let timestring = this.realtimeformat;
      let hour = 0;
      let min = 0;
      let sec = 0;
      let secs = null;
      let times = null;
      let result = false;
      if (timestring == "HH:MM:SS")
      {
        [hour, min, sec] = time.split(":");
        if (hour <= 24 && min < 60 && sec < 60)
        {
          result = true;
        }
      }
      else if (timestring == "HH:MM")
      {
        [hour, min] = time.split(":");
        if (hour <= 24 && min < 60)
        {
          result = true;
        }
      }
      else if (timestring == "HH:MM:SS tt")
      {
        [hour, min, secs] = time.split(":");
        [sec, times] = secs.split(" ");
        if (hour <= 24 && min < 60 && sec < 60 && (times == "AM" || times == "PM"))
        {
          if (times == "PM")
          {
            if (hour == 1)
            {
              hour = 13;
            }
            else if (hour == 2)
            {
              hour = 14;
            }
            else if (hour == 3)
            {
              hour = 15;
            }
            else if (hour == 4)
            {
              hour = 16;
            }
            else if (hour == 5)
            {
              hour = 17;
            }
            else if (hour == 6)
            {
              hour = 18;
            }
            else if (hour == 7)
            {
              hour = 19;
            }
            else if (hour == 8)
            {
              hour = 20;
            }
            else if (hour == 9)
            {
              hour = 21;
            }
            else if (hour == 10)
            {
              hour = 22;
            }
            else if (hour == 11)
            {
              hour = 23;
            } else if (hour == 12)
            {
              hour = 24;
            }
          }
          result = true;
        }
        else
        {
          result = false;
        }
      }

      if (!result)
      {
        return "invalid date";
      }
      else
      {
        let nD = new Date();
        nD.setHours(hour);
        nD.setMinutes(min);
        nD.setSeconds(sec);

        let localtime = "";
        if (hour < 10)
        {
          hour = "0" + hour;
        }
        if (min < 10)
        {
          min = "0" + min;
        }
        localtime = hour + ":" + min;
        return localtime;
      }
    },

    //time entry date format changer
    Showwithdateformat(date) {
      let string = this.realdateformat;
      let err = false;
      let indexnum = "";
      if (string.includes("ddMMYYYY"))
      {
        let pos = string.indexOf("ddMMYYYY");
        indexnum = date.substr(pos, 8);
      }
      else if (string.includes("ddYYYYMM"))
      {
        let pos = string.indexOf("ddYYYYMM");
        indexnum = date.substr(pos, 8);
      }
      else if (string.includes("MMddYYYY"))
      {
        let pos = string.indexOf("MMddYYYY");
        indexnum = date.substr(pos, 8);
      }
      else if (string.includes("MMYYYYdd"))
      {
        let pos = string.indexOf("MMYYYYdd");
        indexnum = date.substr(pos, 8);
      }
      else if (string.includes("YYYYddMM"))
      {
        let pos = string.indexOf("YYYYddMM");
        indexnum = date.substr(pos, 8);
      }
      else if (string.includes("YYYYMMdd"))
      {
        let pos = string.indexOf("YYYYMMdd");
        indexnum = date.substr(pos, 8);
      }

      let int_number = indexnum.trim();
      if (int_number != "")
      {
        let int_length = int_number.length;
        if (int_length != 8)
        {
          err = true;
        }
        else
        {
          if (
            int_number.includes(":") ||
            int_number.includes("/") ||
            int_number.includes(",") ||
            int_number.includes("-") ||
            int_number.includes(";")
          )
          {
            err = true;
          }
        }
      }

      let yearpos = string.indexOf("YYYY");
      let monpos = string.indexOf("MM");
      let daypos = string.indexOf("dd");
      let whatismonth = null;
      if (string.includes("MM"))
      {
        whatismonth = date.substr(monpos, 2) - 1;
      }
      else if (string.includes("MMM"))
      {
        if (date.includes("Jan")) {
          whatismonth = 0;
        }
        else if (date.includes("Feb"))
        {
          whatismonth = 1;
        }
        else if (date.includes("Mar"))
        {
          whatismonth = 2;
        }
        else if (date.includes("Apr"))
        {
          whatismonth = 3;
        }
        else if (date.includes("May"))
        {
          whatismonth = 4;
        }
        else if (date.includes("Jun") || date.includes("June"))
        {
          whatismonth = 5;
        }
        else if (date.includes("Jul") || date.includes("July"))
        {
          whatismonth = 6;
        }
        else if (date.includes("Aug"))
        {
          whatismonth = 7;
        }
        else if (date.includes("Sep") || date.includes("Sept"))
        {
          whatismonth = 8;
        }
        else if (date.includes("Oct"))
        {
          whatismonth = 9;
        }
        else if (date.includes("Nov"))
        {
          whatismonth = 10;
        }
        else if (date.includes("Dec"))
        {
          whatismonth = 11;
        }
        else
        {
          err = true;
        }
      }
      else if (string.includes("MMMM"))
      {
        if (date.includes("January"))
        {
          whatismonth = 0;
        }
        else if (date.includes("February"))
        {
          whatismonth = 1;
        }
        else if (date.includes("March"))
        {
          whatismonth = 2;
        }
        else if (date.includes("April"))
        {
          whatismonth = 3;
        }
        else if (date.includes("May"))
        {
          whatismonth = 4;
        }
        else if (date.includes("June"))
        {
          whatismonth = 5;
        }
        else if (date.includes("July"))
        {
          whatismonth = 6;
        }
        else if (date.includes("August"))
        {
          whatismonth = 7;
        }
        else if (date.includes("September"))
        {
          whatismonth = 8;
        }
        else if (date.includes("October"))
        {
          whatismonth = 9;
        }
        else if (date.includes("November"))
        {
          whatismonth = 10;
        }
        else if (date.includes("December"))
        {
          whatismonth = 11;
        }
        else
        {
          err = true;
        }
      }

      if (err)
      {
        //alert("ERROR");
        return "invalid date";
      }
      else
      {
        let year = date.substr(yearpos, 4);
        let month = whatismonth;
        let days = date.substr(daypos, 2);
        let D = new Date(year, month, days);
        return D.getDate() + "/" + D.getMonth() + "/" + D.getFullYear();
      }
    },

    //also time entry date format changer
    ChangeToDate(date) {
      let string = this.realdateformat;
      let err = false;
      let indexnum = "";
      if (string.includes("ddMMYYYY")) {
        let pos = string.indexOf("ddMMYYYY");
        indexnum = date.substr(pos, 8);
      } else if (string.includes("ddYYYYMM")) {
        let pos = string.indexOf("ddYYYYMM");
        indexnum = date.substr(pos, 8);
      } else if (string.includes("MMddYYYY")) {
        let pos = string.indexOf("MMddYYYY");
        indexnum = date.substr(pos, 8);
      } else if (string.includes("MMYYYYdd")) {
        let pos = string.indexOf("MMYYYYdd");
        indexnum = date.substr(pos, 8);
      } else if (string.includes("YYYYddMM")) {
        let pos = string.indexOf("YYYYddMM");
        indexnum = date.substr(pos, 8);
      } else if (string.includes("YYYYMMdd")) {
        let pos = string.indexOf("YYYYMMdd");
        indexnum = date.substr(pos, 8);
      }
      let int_number = indexnum.trim();
      if (int_number != "") {
        let int_length = int_number.length;
        if (int_length != 8) {
          err = true;
        } else {
          if (
            int_number.includes(":") ||
            int_number.includes("/") ||
            int_number.includes(",") ||
            int_number.includes("-") ||
            int_number.includes(";")
          ) {
            err = true;
          }
        }
      }
      let yearpos = string.indexOf("YYYY");
      let monpos = string.indexOf("MM");
      let daypos = string.indexOf("dd");
      let whatismonth = null;
      if (string.includes("MM")) {
        whatismonth = date.substr(monpos, 2) - 1;
      } else if (string.includes("MMM")) {
        if (date.includes("Jan")) {
          whatismonth = 0;
        } else if (date.includes("Feb")) {
          whatismonth = 1;
        } else if (date.includes("Mar")) {
          whatismonth = 2;
        } else if (date.includes("Apr")) {
          whatismonth = 3;
        } else if (date.includes("May")) {
          whatismonth = 4;
        } else if (date.includes("Jun") || date.includes("June")) {
          whatismonth = 5;
        } else if (date.includes("Jul") || date.includes("July")) {
          whatismonth = 6;
        } else if (date.includes("Aug")) {
          whatismonth = 7;
        } else if (date.includes("Sep") || date.includes("Sept")) {
          whatismonth = 8;
        } else if (date.includes("Oct")) {
          whatismonth = 9;
        } else if (date.includes("Nov")) {
          whatismonth = 10;
        } else if (date.includes("Dec")) {
          whatismonth = 11;
        } else {
          err = true;
        }
      } else if (string.includes("MMMM")) {
        if (date.includes("January")) {
          whatismonth = 0;
        } else if (date.includes("February")) {
          whatismonth = 1;
        } else if (date.includes("March")) {
          whatismonth = 2;
        } else if (date.includes("April")) {
          whatismonth = 3;
        } else if (date.includes("May")) {
          whatismonth = 4;
        } else if (date.includes("June")) {
          whatismonth = 5;
        } else if (date.includes("July")) {
          whatismonth = 6;
        } else if (date.includes("August")) {
          whatismonth = 7;
        } else if (date.includes("September")) {
          whatismonth = 8;
        } else if (date.includes("October")) {
          whatismonth = 9;
        } else if (date.includes("November")) {
          whatismonth = 10;
        } else if (date.includes("December")) {
          whatismonth = 11;
        } else {
          err = true;
        }
      }

      if (err)
      {
        //alert("ERROR");
        return "invalid date";
      }
      else
      {
        let year = date.substr(yearpos, 4);
        let month = whatismonth;
        let days = date.substr(daypos, 2);
        let D = new Date(year, month, days);
        return D.getFullYear() == year &&
          D.getMonth() == month &&
          D.getDate() == days
          ? D
          : "invalid date";
      }
    },

    // time format changer
    ChangeToTime(time) {
      let string = this.realdateformat;
      let hour = null;
      let minpos = string.indexOf("mm");
      let min = parseInt(time.substr(minpos, 2));
      if (string.includes("hh"))
      {
        let hourpos = string.indexOf("hh");
        let ampmpos = string.indexOf("Tt");
        hour = parseInt(time.substr(hourpos, 2));
        let amp = time.substr(ampmpos, 2);
        if (amp == "PM")
        {
          if (hour == 1)
          {
            hour = 13;
          }
          else if (hour == 2)
          {
            hour = 14;
          }
          else if (hour == 3)
          {
            hour = 15;
          }
          else if (hour == 4)
          {
            hour = 16;
          }
          else if (hour == 5)
          {
            hour = 17;
          }
          else if (hour == 6)
          {
            hour = 18;
          }
          else if (hour == 7)
          {
            hour = 19;
          }
          else if (hour == 8)
          {
            hour = 20;
          }
          else if (hour == 9)
          {
            hour = 21;
          }
          else if (hour == 10)
          {
            hour = 22;
          }
          else if (hour == 11)
          {
            hour = 23;
          } else if (hour == 12)
          {
            hour = 24;
          }
        }
      }
      else if (string.includes("HH"))
      {
        let hourpos = string.indexOf("HH");
        hour = parseInt(time.substr(hourpos, 2));
      }

      let nD = this.ChangeToDate(time);
      nD.setHours(hour);
      nD.setMinutes(min);
      const getutcmin = nD.getUTCMinutes();
      const getutchrs = nD.getUTCHours();
      let utctime = "";
      if (getutchrs < 10)
      {
        if (getutcmin < 10)
        {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            "0" +
            nD.getUTCHours() +
            ":" +
            "0" +
            getutcmin;
        }
        else
        {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            "0" +
            nD.getUTCHours() +
            ":" +
            getutcmin;
        }
      }
      else
      {
        if (getutcmin < 10)
        {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            nD.getUTCHours() +
            ":" +
            "0" +
            getutcmin;
        }
        else
        {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            nD.getUTCHours() +
            ":" +
            getutcmin;
        }
      }
      return utctime;
    },

    //also time format changer
    ChangeToActualTime(time) {
      let string = this.realdateformat;
      let hour = null;
      let minpos = string.indexOf("mm");
      let min = parseInt(time.substr(minpos, 2));
      if (string.includes("hh"))
      {
        let hourpos = string.indexOf("hh");
        let ampmpos = string.indexOf("Tt");
        hour = parseInt(time.substr(hourpos, 2));
        let amp = time.substr(ampmpos, 2);
        if (amp == "PM")
        {
          if (hour == 1) {
            hour = 13;
          }
          else if (hour == 2)
          {
            hour = 14;
          }
          else if (hour == 3)
          {
            hour = 15;
          }
          else if (hour == 4)
          {
            hour = 16;
          }
          else if (hour == 5)
          {
            hour = 17;
          }
          else if (hour == 6)
          {
            hour = 18;
          }
          else if (hour == 7)
          {
            hour = 19;
          }
          else if (hour == 8)
          {
            hour = 20;
          }
          else if (hour == 9)
          {
            hour = 21;
          }
          else if (hour == 10)
          {
            hour = 22;
          }
          else if (hour == 11)
          {
            hour = 23;
          }
          else if (hour == 12)
          {
            hour = 24;
          }
        }
      }
      else if (string.includes("HH"))
      {
        let hourpos = string.indexOf("HH");
        hour = parseInt(time.substr(hourpos, 2));
      }

      let nD = this.ChangeToDate(time);
      nD.setHours(hour);
      nD.setMinutes(min);
      let localtime = "";
      localtime =
        nD.getFullYear() +
        "/" +
        (nD.getMonth() + 1) +
        "/" +
        nD.getDate() +
        " " +
        hour +
        ":" +
        min;
      return localtime;
    },

    //also also time format changer
    ChangeToActualTimeForShift(time) {
      let string = this.realdateformat;
      let hour = null;
      let minpos = string.indexOf("mm");
      let min = parseInt(time.substr(minpos, 2));
      if (string.includes("hh"))
      {
        let hourpos = string.indexOf("hh");
        let ampmpos = string.indexOf("Tt");
        hour = parseInt(time.substr(hourpos, 2));
        let amp = time.substr(ampmpos, 2);

        if (amp == "PM")
        {
          if (hour == 1)
          {
            hour = 13;
          }
          else if (hour == 2)
          {
            hour = 14;
          }
          else if (hour == 3)
          {
            hour = 15;
          }
          else if (hour == 4)
          {
            hour = 16;
          }
          else if (hour == 5)
          {
            hour = 17;
          }
          else if (hour == 6)
          {
            hour = 18;
          }
          else if (hour == 7)
          {
            hour = 19;
          }
          else if (hour == 8)
          {
            hour = 20;
          }
          else if (hour == 9)
          {
            hour = 21;
          }
          else if (hour == 10)
          {
            hour = 22;
          }
          else if (hour == 11)
          {
            hour = 23;
          }
          else if (hour == 12)
          {
            hour = 24;
          }
        }
      }
      else if (string.includes("HH"))
      {
        let hourpos = string.indexOf("HH");
        hour = parseInt(time.substr(hourpos, 2));
      }
      let nD = this.ChangeToDate(time);
      nD.setHours(hour);
      nD.setMinutes(min);

      let localtime = "";
      if (hour < 10)
      {
        hour = "0" + hour;
      }
      if (min < 10)
      {
        min = "0" + min;
      }
      localtime = hour + ":" + min;

      return localtime;
    },

    // handleStartDateChange(event) {
    //   const newStartDate = event;
    //   const parsedStartDate = moment(newStartDate, 'DD/MM/YYYY', true);

    //   if (!parsedStartDate.isValid()) {
    //     // Handle invalid date format
    //     console.error('Invalid date format');
    //     return;
    //   }
    //   this.startdate = newStartDate;
    //   const parsedEndDate = moment(this.enddate, 'DD/MM/YYYY', true);
    //   if (parsedEndDate.isValid() && parsedStartDate.isAfter(parsedEndDate)) {
    //     this.enddate = null;
    //   }
    // },

    SynctheTime() {
      let self = this;
      // this.LoadingDialog = true;
      if (moment(self.startdate, 'DD/MM/YYYY').isBefore(moment(self.enddate, 'DD/MM/YYYY').add(1, 'day')))
      {
        self.combileArray = [];
        self.headers = [];
        self.kenshin = [];
        self.ErrorArray = [];
        let companycode = store.state.companycode;
        let now = new Date();
        // this.LoadingDialog = true;
        axios.post(
            //"https://jarviz-api.azurewebsites.net/api/v11/Optimistic/GetEmployeeCheckinOptimistic",
            "https://jarvizapi-customerweb.azurewebsites.net/api/v11/Optimistic/GetEmployeeCheckinOptimistic",
            {
              companyCode: companycode,
              employeeID: null,
              // startDate: self.startdate,
              startDate: moment(self.startdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              // endDate: self.enddate,
              endDate: moment(self.enddate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              offset: now.getTimezoneOffset(),
            },
            {
              headers: {
                Authorization: "Bearer " + self.ApiToken,
              },
            }
          )
          .then(function (response) {
            if (response.data.responseData == null)
            {
              self.SyncTimeRetrieve = false;
              self.ThoseToAdd = [];
              self.selected = [];
              self.oneforall = true;
              alert(response.data.responseMsg);
              self.LoadingDialog = false;
            }
            else
            {
              self.TheJarvizArray = response.data.responseData.employee;
              self.headers.push(
                { text: "Date", value: "showdate" },
                { text: "Employee ID", value: "employee_Code" },
                { text: "Full Name", value: "fullName" },
                { text: "Company Name", value: "companyName" },
                { text: "Check In Location", value: "clock_In_Location" },
                { text: "Check In Latitude", value: "clock_In_Lattitude" },
                { text: "Check In Longitude", value: "clock_In_Longitude" },
                { text: "Root Detected", value: "root" },
                { text: "Mock GPS", value: "mocky" },
                { text: "Developer Mode", value: "devmod" },
                { text: "Check In Time", value: "cintime" },
                { text: "Check In Date", value: "cindate" },
                { text: "Check Out Location", value: "clock_Out_Location" },
                { text: "Check Out Time", value: "couttime" },
                { text: "Check Out Date", value: "coutdate" },
                {
                  text: "Distance between In Out Location",
                  value: "distance",
                },
                { text: "Leave Status", value: "LeaveStatus" },
                { text: "Remark", value: "remark" }
              );
              
              if (!self.oneforall)
              {
                for (let i in self.TheJarvizArray)
                {
                  for (let r2 = 0; r2 < self.ThoseToAdd.length; r2++)
                  {
                    if (self.ThoseToAdd[r2].employee_Code == self.TheJarvizArray[i].employeeID)
                    {
                      for (let l = 0; l < self.ShiftList.length; l++)
                      {
                        if (self.ThoseToAdd[r2].employee_Code == self.ShiftList[l].employee_Code)
                        {
                          if (self.ShiftList[l].dateList.length != 0)
                          {
                            for (let j1 = 0;j1 < self.TheJarvizArray[i].checkInOut.length;j1++)
                            {
                              let thedate = self.ChangeDateToYYYYMMDD(self.TheJarvizArray[i].checkInOut[j1].timeEntryDate);
                              let moveforward = false;
                              for (let p = 0; p < self.ShiftList[l].dateList.length; p++)
                              {
                                if (self.ShiftList[l].dateList[p].disableEndDate)
                                {
                                  if (moment(self.ShiftList[l].dateList[p].startDate) < moment(thedate).add(1, "day"))
                                  {
                                    moveforward = true;
                                    break;
                                  }
                                  else
                                  {
                                    moveforward = false;
                                  }
                                }
                                else
                                {
                                  if ((moment(self.ShiftList[l].dateList[p].startDate) < moment(thedate).add(1, "day")) && (moment(self.ShiftList[l].dateList[p].endDate) > moment(thedate).add(1, "day")))
                                  {
                                    moveforward = true;
                                    break;
                                  }
                                  else
                                  {
                                    moveforward = false;
                                  }
                                }
                              }
                              if (moveforward)
                              {
                                let iintime = self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j1].checkInDateTime);
                                let oouttime = self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j1].checkOutDateTime);
                                let tempimport = {
                                  employee_Code: self.ThoseToAdd[r2].employee_Code,
                                  timeEntry_Date: self.ChangeDateToYYYYMMDD(self.TheJarvizArray[i].checkInOut[j1].timeEntryDate),
                                  company_ID: store.state.companyID,
                                  user_ID: store.state.employeeID,
                                  inOut_Required: true,
                                  jarviz: true,
                                  fullName: self.ThoseToAdd[r2].fullName,
                                  companyName: self.companyName,
                                  clock_In_Time: self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j1].checkInDateTime),
                                  clock_Out_Time: self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j1].checkOutDateTime),
                                  actual_Clock_In_Time: self.Converttolocal2(iintime),
                                  actual_Clock_Out_Time: self.Converttolocal2(oouttime),
                                  shift_Start: "-",
                                  shift_End: "-",
                                  clock_In_Location: self.TheJarvizArray[i].checkInOut[j1].checkInLocation,
                                  clock_In_Lattitude: self.TheJarvizArray[i].checkInOut[j1].checkInLatitude.toString(),
                                  clock_In_Longitude: self.TheJarvizArray[i].checkInOut[j1].checkInLongtitude.toString(),
                                  clock_Out_Location: self.TheJarvizArray[i].checkInOut[j1].checkOutLocation,
                                  clock_Out_Lattitude: self.TheJarvizArray[i].checkInOut[j1].checkOutLatitude.toString(),
                                  clock_Out_Longitude: self.TheJarvizArray[i].checkInOut[j1].checkOutLongtitude.toString(),
                                  showdate: self.ChangeDateToShow(iintime),
                                  root: "Not Detected",
                                  mocky: "Not Detected",
                                  devmod: "Not Detected",
                                  cintime: self.Converttolocal(iintime),
                                  couttime: self.Converttolocal(oouttime),
                                  cindate: self.Converttolocaldate(iintime),
                                  coutdate: self.Converttolocaldate(oouttime),
                                  distance: "-",
                                  LeaveStatus: "-",
                                  remark: "-",
                                };
                                self.combileArray.push(tempimport);
                                self.kenshin.push(tempimport);
                              }
                              else
                              {
                                self.ErrorArray.push({
                                  employee_Code: self.ThoseToAdd[r2].employee_Code,
                                  error_Type: self.formatDate1(self.TheJarvizArray[i].checkInOut[j1].checkInDateTime) + " don't have Shift Time",
                                });
                              }
                            }
                          }
                          else
                          {
                            self.ErrorArray.push({
                              employee_Code: self.ThoseToAdd[r2].employee_Code,
                              error_Type: "This employee doesn't have shift time",
                            });
                          }
                        }
                      }
                    }
                  }
                }
              }
              else
              {
                for (let i in self.TheJarvizArray)
                {
                  let isitthere = self.PersonalList.some((p) => p.employee_Code == self.TheJarvizArray[i].employeeID);
                  if (isitthere)
                  {
                    for (let k = 0; k < self.PersonalList.length; k++)
                    {
                      if (self.PersonalList[k].employee_Code == self.TheJarvizArray[i].employeeID)
                      {
                        for (let l = 0; l < self.ShiftList.length; l++)
                        {
                          if (self.PersonalList[k].employee_Code == self.ShiftList[l].employee_Code)
                          {
                            if (self.ShiftList[l].dateList.length != 0)
                            {
                              for (let j = 0;j < self.TheJarvizArray[i].checkInOut.length;j++)
                              {
                                let thedate = self.ChangeDateToYYYYMMDD(self.TheJarvizArray[i].checkInOut[j].timeEntryDate);
                                let moveforward = false;
                                for (let p1 = 0; p1 < self.ShiftList[l].dateList.length; p1++)
                                {
                                  if (self.ShiftList[l].dateList[p1].disableEndDate)
                                  {
                                    if (moment(self.ShiftList[l].dateList[p1].startDate) < moment(thedate).add(1, "day"))
                                    {
                                      moveforward = true;
                                      break;
                                    }
                                    else
                                    {
                                      moveforward = false;
                                    }
                                  }
                                  else
                                  {
                                    if ((moment(self.ShiftList[l].dateList[p1].startDate) < moment(thedate).add(1, "day")) && (moment(self.ShiftList[l].dateList[p1].endDate) > moment(thedate).add(1, "day")))
                                    {
                                      moveforward = true;
                                      break;
                                    }
                                    else
                                    {
                                      moveforward = false;
                                    }
                                  }
                                }
                                if (moveforward)
                                {
                                  let iintime = self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j].checkInDateTime);
                                  let oouttime = self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j].checkOutDateTime);
                                  let tempimport = {
                                    employee_Code: self.PersonalList[k].employee_Code,
                                    timeEntry_Date: self.ChangeDateToYYYYMMDD(self.TheJarvizArray[i].checkInOut[j].timeEntryDate),
                                    company_ID: store.state.companyID,
                                    user_ID: store.state.employeeID,
                                    inOut_Required: true,
                                    jarviz: true,
                                    fullName: self.PersonalList[k].fullName,
                                    companyName: self.companyName,
                                    clock_In_Time: self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j].checkInDateTime),
                                    clock_Out_Time: self.ChangeJJToUTCDate(self.TheJarvizArray[i].checkInOut[j].checkOutDateTime),
                                    actual_Clock_In_Time: self.Converttolocal2(iintime),
                                    actual_Clock_Out_Time: self.Converttolocal2(oouttime),
                                    shift_Start: "-",
                                    shift_End: "-",
                                    clock_In_Location: self.TheJarvizArray[i].checkInOut[j].checkInLocation,
                                    clock_In_Lattitude: self.TheJarvizArray[i].checkInOut[j].checkInLatitude.toString(),
                                    clock_In_Longitude: self.TheJarvizArray[i].checkInOut[j].checkInLongtitude.toString(),
                                    clock_Out_Location: self.TheJarvizArray[i].checkInOut[j].checkOutLocation,
                                    clock_Out_Lattitude: self.TheJarvizArray[i].checkInOut[j].checkOutLatitude.toString(),
                                    clock_Out_Longitude: self.TheJarvizArray[i].checkInOut[j].checkOutLongtitude.toString(),
                                    showdate: self.ChangeDateToShow(iintime),
                                    root: "Not Detected",
                                    mocky: "Not Detected",
                                    devmod: "Not Detected",
                                    cintime: self.Converttolocal(iintime),
                                    couttime: self.Converttolocal(oouttime),
                                    cindate: self.Converttolocaldate(iintime),
                                    coutdate: self.Converttolocaldate(oouttime),
                                    distance: "-",
                                    LeaveStatus: "-",
                                    remark: "-",
                                  };
                                  self.combileArray.push(tempimport);
                                  self.kenshin.push(tempimport);
                                }
                                else
                                {
                                  self.ErrorArray.push({
                                    employee_Code: self.PersonalList[k].employee_Code,
                                    error_Type: self.formatDate1(self.TheJarvizArray[i].checkInOut[j].checkInDateTime) + " don't have Shift Time",
                                  });
                                }
                              }
                            }
                            else
                            {
                              self.ErrorArray.push({
                                employee_Code: self.PersonalList[k].employee_Code,
                                error_Type: "This employee doesn't have shift time",
                              });
                            }
                          }
                        }
                      }
                    }
                  }
                  else
                  {
                    self.ErrorArray.push({
                      employee_Code: self.TheJarvizArray[i].employeeID,
                      error_Type: "This employee doesn't exist",
                    });
                  }
                }
              }
              if (self.combileArray.length != 0)
              {
                self.uploadingcount = self.combileArray.length;
                self.SyncTimeRetrieve = false;
                let theteearr = [];
                for (let j = 0; j < self.kenshin.length; j++)
                {
                  for (let t = 0; t < self.PersonalList.length; t++)
                  {
                    if (self.kenshin[j].employee_Code == self.PersonalList[t].employee_Code)
                    {
                      theteearr.push(self.PersonalList[t].department_Name);
                    }
                  }
                }
                let Message = [...new Set(theteearr)];
                self.theliistDep = `${Message.join(", ")}`;
                self.thelisttotal = self.combileArray.length;
                // self.theliistdate = self.formatDate(self.startdate) + " - " + self.formatDate(self.enddate);
                self.theliistdate = self.startdate + " - " + self.enddate;
                self.loadLoading();
                self.ThoseToAdd = [];
                self.selected = [];
                self.oneforall = true;
              }
              else
              {
                self.SyncTimeRetrieve = false;
                self.ThoseToAdd = [];
                self.selected = [];
                self.oneforall = true;
                alert("There is no data for selected date!");
              }
              self.LoadingDialog = false;
            }
          })
          .catch(function (error) {
            alert(error);
            self.LoadingDialog = false;
          });
      }
      else
      {
        self.LoadingDialog = false;
        alert("Start date can't be after end date!");
      }
    },

    //Upload To Staff Time
    SendtoSTAR() {
      let self = this;
      self.ConfirmDialog = false;
      self.LoadingDialog = true;
      if (self.kenshin.length != 0)
      {
        axios.post(`${self.url}SyncTimeTemplate/SendTxtToStaffTime`, self.kenshin)
        .then(function (response) {
          self.LoadingDialog = false;
          alert(response.data.message);
          if (response.data.status == 0)
          {
            //alert(response.data.message);
            self.jarvizfile = null;
            self.kenshin = [];
            self.combileArray = [];
            self.thelisttotal = "";
            self.theliistdate = "";
            self.theliistDep = "";
            self.uploadingcount = 0;
            self.uploadcount = 0;
            self.templateselected = null;
            self.tableShow = false;
            self.ErrorArray = [];
            //self.LogTrace(null, "Upload SyncTime", 51, "Verbose");
            // self.LoadingDialog = false;
            // if (response.data.message == "Add Successfully")
            // {
            //   alert("Upload Successfully!");
            // }
            // else
            // {
            //   alert(response.data.message);
            // }
          }
          // else
          // {
          //   alert(response.data.message);
          // }
        })
        .catch(function (error)
        {
          self.LogTrace(error, "Upload SyncTime Fail", 51, "Verbose");
          alert(error);
          self.LoadingDialog = false;
        });
      }
      else
      {
        alert("Please Upload Data to Sync!");
        self.LoadingDialog = false;
      }
    },

    //Check for duplicate
    CheckDuplicate() {
      let self = this;
      self.CheckingDialog = true;
      if (self.kenshin.length != 0)
      {
        let temp = self.kenshin;
        axios.post(`${self.url}SyncTimeTemplate/GetClockTimeDuplicate`, temp)
        .then(function (response) {
          if (response.data.status == 0)
          {
            //it duplicate
            self.CheckingDialog = false;
            self.ConfirmDialog = true;
          }
          else if (response.data.status == 1)
          {
            //it not duplicate
            //alert("ScoobyDoobyDoo!");
            self.CheckingDialog = false;
            self.SendtoSTAR();
          }
          else
          {
            self.CheckingDialog = false;
            alert("Error!");
          }
        })
        .catch(function (error) {
          alert(error);
          self.CheckingDialog = false;
        });
      }
      else
      {
        alert("Please Upload Data to Sync!");
        self.CheckingDialog = false;
      }
    },

    //To Check Employee ID match at Jarviz import
    async GetPersonal() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}Stafftimeattendance/GetMonthViewOfSTA`, temp)
      .then(function (response) {
        let ahh = response.data.data;
        for (let i = 0; i < ahh.length; i++)
        {
          let List = {
            ...ahh[i],
            Employee_cde: parseInt(ahh[i].employee_Code),
          };
          self.PersonalList.push(List);
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    // To get company name
    async GetCompany() {
      try {
        let self = this;
        let tempID = store.state.companyID;
        let temp = {
          company_ID: tempID,
        };
        await axios.post(`${self.url}Company/GetCompanyID`, temp)
        .then(function (response)
        {
          let list = response.data.data;
          self.companyName = list.companyName;
        });
      }
      catch (error)
      {
        alert(error);
      }
    },

    //To Check Employee ID match at txt and csv
    async GetShiftAssignment() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}ShiftAssignment/GetShiftAssignmentForEmp`, temp)
        .then(function (response)
        {
          self.ShiftList = response.data.data;
        })
        .catch(function (error)
        {
          alert(error);
        });
    },

    //For DDL
    async GetSyncTempName() {
      let self = this;
      //self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}SyncTimeTemplate/GetSyncTimeTemplateByComID`, temp)
      .then(function (response) {
        self.jarvizStatus = response.data.data.jarviz;
        self.SyncTempList = response.data.data.theTemplates;
        if (!self.jarvizStatus)
        {
          self.thisone = true;
          self.modelTab = 1;
          self.showjarvizz = false;
        }
        for (let i = 0; i < self.SyncTempList.length; i++)
        {
          self.ImportNameList.push({
            name: self.SyncTempList[i].template_Name,
            val: self.SyncTempList[i].template_ID,
            show: true,
          });
        }
        //self.LogTrace(null, "Get Sync Template", 51, "Critical");
        //self.LoadingDialog = false;
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Sync Template Fail", 51, "Critical");
        //alert(error);
        //self.LoadingDialog = false;
      });
    },

    //For show in ddl
    getthatsyncid(id) {
      let self = this;
      self.tableShow = false;
      self.uploadingcount = 0;
      self.uploadcount = 0;
      //self.jarvizfile = [];
      self.ErrorArray = [];
      self.kenshin = [];
      self.combileArray = [];
      //self.LoadingDialog = true;
      if (id != 0)
      {
        let temp = {
          template_ID: id,
        };
        axios.post(`${self.url}SyncTimeTemplate/GetSyncTimeTemplateByID`, temp)
          .then(function (response) {
            let tarray = response.data.data;
            //self.LoadingDialog = false;
            self.formatToCheck = tarray[0].template_Format;
            self.tempFormatforshow = tarray[0].template_Format;
            self.realheader1 = tarray[0].fieldOne;
            self.realheader2 = tarray[0].fieldTwo;
            self.realheader3 = tarray[0].fieldThree;
            self.realheader4 = tarray[0].fieldFour;
            self.realheader5 = tarray[0].fieldFive;
            self.tempheader1forshow = tarray[0].fieldOne;
            self.tempheader2forshow = tarray[0].fieldTwo;
            self.tempheader3forshow = tarray[0].fieldThree;
            self.tempheader4forshow = tarray[0].fieldFour;
            self.tempheader5forshow = tarray[0].fieldFive;
            self.realInOut = tarray[0].inOut_Required;
            self.realIn = tarray[0].clock_In;
            self.realOut = tarray[0].clock_Out;
            self.inValforshow = tarray[0].clock_In;
            self.outValforshow = tarray[0].clock_Out;
            self.realdateformat = tarray[0].date_Format;
            self.tempDateFormatforshow = tarray[0].date_Format;

            if (self.tempheader4forshow != "-")
            {
              self.noallow = true;
            }
            else
            {
              self.realtimeformat = tarray[0].time_Format;
              self.noallow = false;
              let firsttxt = "";
              let sectxt = "";
              let trdtxt = "";

              if (self.tempheader1forshow == "Employee ID")
              {
                self.TemplateSampleArray[0].tempdata1 = "0001";
                firsttxt = "0001";
              }
              else if (self.tempheader1forshow == "Time")
              {
                self.TemplateSampleArray[0].tempdata1 = tarray[0].time_Format;
                const indexa = self.timeFormatList.findIndex((format) => format.text === self.TemplateSampleArray[0].tempdata1);
                firsttxt = self.timeFormatList[indexa].example;
                self.TemplateSampleArray[0].tempdata1 = firsttxt;
              }
              else if (self.tempheader1forshow == "Date")
              {
                self.TemplateSampleArray[0].tempdata1 = tarray[0].date_Format;
                const indexb = self.dateFormatList.findIndex((format) => format.text === self.TemplateSampleArray[0].tempdata1);
                firsttxt = self.dateFormatList[indexb].example;
                self.TemplateSampleArray[0].tempdata1 = firsttxt;
              }

              if (self.tempheader2forshow == "Employee ID")
              {
                self.TemplateSampleArray[0].tempdata2 = "0001";
                sectxt = "0001";
              }
              else if (self.tempheader2forshow == "Time")
              {
                self.TemplateSampleArray[0].tempdata2 = tarray[0].time_Format;
                const indexa = self.timeFormatList.findIndex((format) => format.text === self.TemplateSampleArray[0].tempdata2);
                sectxt = self.timeFormatList[indexa].example;
                self.TemplateSampleArray[0].tempdata2 = sectxt;
              }
              else if (self.tempheader2forshow == "Date")
              {
                self.TemplateSampleArray[0].tempdata2 = tarray[0].date_Format;
                const indexb = self.dateFormatList.findIndex((format) => format.text === self.TemplateSampleArray[0].tempdata2);
                sectxt = self.dateFormatList[indexb].example;
                self.TemplateSampleArray[0].tempdata2 = sectxt;
              }

              if (self.tempheader3forshow == "Employee ID")
              {
                self.TemplateSampleArray[0].tempdata3 = "0001";
                trdtxt = "0001";
              }
              else if (self.tempheader3forshow == "Time")
              {
                self.TemplateSampleArray[0].tempdata3 = tarray[0].time_Format;
                const indexa = self.timeFormatList.findIndex((format) => format.text === self.TemplateSampleArray[0].tempdata3);
                trdtxt = self.timeFormatList[indexa].example;
                self.TemplateSampleArray[0].tempdata3 = trdtxt;
              }
              else if (self.tempheader3forshow == "Date")
              {
                self.TemplateSampleArray[0].tempdata3 = tarray[0].date_Format;
                const indexb = self.dateFormatList.findIndex((format) => format.text === self.TemplateSampleArray[0].tempdata3);
                trdtxt = self.dateFormatList[indexb].example;
                self.TemplateSampleArray[0].tempdata3 = trdtxt;
              }

              let thedeli = "";
              if (self.tempFormatforshow == "Tab")
              {
                thedeli = "\t \t";
              }
              else if (self.tempFormatforshow == "Space")
              {
                thedeli = " ";
              }
              else if (self.tempFormatforshow == "Semicolon")
              {
                thedeli = ";";
              }
              else if (self.tempFormatforshow == "Equals Sign")
              {
                thedeli = "=";
              }
              else if (self.tempFormatforshow == "Comma")
              {
                thedeli = ",";
              }
              else if (self.tempFormatforshow == "Colon")
              {
                thedeli = ":";
              }
              self.thetextFileforshow = firsttxt + thedeli +
                sectxt + thedeli +
                trdtxt;
            }
          })
          .catch(function (error) {
            alert(error);
            //self.LoadingDialog = false;
          });
      }
    },

    //just as the function name say
    ValidateNameForJarviz() {
      let self = this;
      //let re = 0;
      let result = self.importData.filter(
        (o1) =>
          !self.PersonalList.some(
            (o2) =>
              o1["employee id"] === o2.employee_Code ||
              o1["employee id"] === o2.Employee_cde
            //o1["Full Name(First Name Middle Name Last Name)"] === o2.fullName
          )
      );
      if (result.length != 0)
      {
        return false;
      }
      else
      {
        return true;
      }
    },

    //Local Converter
    Converttolocal(item) {
      if (item != "-" && item != null && item != "")
      {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10)
        {
          if (inmins < 10)
          {
            output = "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          }
          else
          {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        else
        {
          if (inmins < 10)
          {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          }
          else
          {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      }
      else
      {
        return "-";
      }
    },

    Converttolocal2(item) {
      if (item != "-")
      {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let theresult = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10)
        {
          if (inmins < 10)
          {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          }
          else
          {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        else
        {
          if (inmins < 10)
          {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          }
          else
          {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        theresult = newDate.getFullYear() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getDate() + " " + output;
        return theresult;
      }
      else
      {
        return "-";
      }
    },

    //Time format for checkin changer
    ChangeToLocalDateTimeCheckIn(dig, date) {
      if (
        (dig != null && dig != undefined && dig != "") ||
        (date != null && date != undefined && date != "")
      )
      {
        let resulr_int = "";
        let int_number = dig.trim();
        resulr_int = int_number;
        let m = resulr_int.substr(3, 4),
          h = resulr_int.substr(0, 2);
        let Dat = new Date(date);
        let someDate = Dat.setDate(Dat.getDate() + 1);
        let realDate = new Date(someDate);
        let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
        let yr = dateformat.substr(0, 4),
          mo = dateformat.substr(4, 2) - 1,
          dy = dateformat.substr(6, 2);
        let nD = new Date(yr, mo, dy);
        let localtime = "";
        localtime = nD.getFullYear() + "/" + (nD.getMonth() + 1) + "/" + nD.getDate() + " " + h + ":" + m;
        return localtime;
      }
      else
      {
        let localtime = "-";
        return localtime;
      }
    },

    Converttolocaldate(item) {
      let todate = new Date(Date.parse(item));
      let timezone = todate.getTimezoneOffset() * 60000;
      let newDate = new Date(todate.getTime() - timezone);
      return newDate.toString().substr(0, 15);
    },

    ChangeJJToLocalDate(thetime) {
      if (thetime != null && thetime != undefined && thetime != "")
      {
        // let Dat = new Date(thetime);
        // let someDate = Dat.setDate(Dat.getDate() + 1);
        let realDate = new Date(thetime);
        let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
        let yr = dateformat.substr(0, 4),
          mo = dateformat.substr(4, 2) - 1,
          dy = dateformat.substr(6, 2);
        let nD = new Date(yr, mo, dy);
        const getmin = realDate.getMinutes();
        const gethrs = realDate.getHours();
        let localtime = "";
        if (gethrs < 10)
        {
          if (getmin < 10)
          {
            localtime =
              nD.getFullYear() +
              "/" +
              (nD.getMonth() + 1) +
              "/" +
              nD.getDate() +
              " " +
              "0" +
              gethrs +
              ":" +
              "0" +
              getmin;
          }
          else
          {
            localtime =
              nD.getFullYear() +
              "/" +
              (nD.getMonth() + 1) +
              "/" +
              nD.getDate() +
              " " +
              "0" +
              gethrs +
              ":" +
              getmin;
          }
        }
        else
        {
          if (getmin < 10)
          {
            localtime =
              nD.getFullYear() +
              "/" +
              (nD.getMonth() + 1) +
              "/" +
              nD.getDate() +
              " " +
              gethrs +
              ":" +
              "0" +
              getmin;
          }
          else
          {
            localtime =
              nD.getFullYear() +
              "/" +
              (nD.getMonth() + 1) +
              "/" +
              nD.getDate() +
              " " +
              gethrs +
              ":" +
              getmin;
          }
        }
        return localtime;
      }
      else
      {
        let localtime = "-";
        return localtime;
      }
    },

    //Jarviz Check IN Date format change for import
    ChangeDateToYYYYMMDD(date) {
      let Dat = new Date(date);
      //let someDate = Dat.setDate(Dat.getDate() + 1);
      let realDate = new Date(Dat);
      let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
      let y = dateformat.substr(0, 4),
        m = dateformat.substr(4, 2) - 1,
        d = dateformat.substr(6, 2);
      let D = new Date(y, m, d);
      return D.getFullYear() == y && D.getMonth() == m && D.getDate() == d
        ? D
        : "invalid date";
    },

    //Jarviz Check IN And Time UTC format change for import
    ChangeToUTCDateTimeCheckIn(dig, date) {
      if ((dig != null && dig != undefined && dig != "") || (date != null && date != undefined && date != ""))
      {
        let resulr_int = "";
        let int_number = dig.trim();
        resulr_int = int_number;
        let m = resulr_int.substr(3, 4),
          h = resulr_int.substr(0, 2);
        let Dat = new Date(date);
        let someDate = Dat.setDate(Dat.getDate() + 1);
        let realDate = new Date(someDate);
        let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
        let yr = dateformat.substr(0, 4),
          mo = dateformat.substr(4, 2) - 1,
          dy = dateformat.substr(6, 2);
        let nD = new Date(yr, mo, dy);
        nD.setHours(h);
        nD.setMinutes(m);

        const getutcmin = nD.getUTCMinutes();
        const getutchrs = nD.getUTCHours();
        let utctime = "";
        if (getutchrs < 10)
        {
          if (getutcmin < 10)
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              nD.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          }
          else
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              nD.getUTCHours() +
              ":" +
              getutcmin;
          }
        }
        else
        {
          if (getutcmin < 10)
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              nD.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          }
          else
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              nD.getUTCHours() +
              ":" +
              getutcmin;
          }
        }
        return utctime;
      }
      else
      {
        let utctime = "-";
        return utctime;
      }
    },

    ChangeJJToUTCDate(thetime) {
      if (thetime != null && thetime != undefined && thetime != "") {
        // let resulr_int = "";
        // let int_number = dig.trim();
        // resulr_int = int_number;
        // let m = resulr_int.substr(3, 4),
        //   h = resulr_int.substr(0, 2);
        let Dat = new Date(thetime);
        let someDate = Dat.setDate(Dat.getDate() + 1);
        let realDate = new Date(someDate);
        let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
        let yr = dateformat.substr(0, 4),
          mo = dateformat.substr(4, 2) - 1,
          dy = dateformat.substr(6, 2);
        let nD = new Date(yr, mo, dy);
        // nD.setHours(h);
        // nD.setMinutes(m);

        const getutcmin = realDate.getUTCMinutes();
        const getutchrs = realDate.getUTCHours();
        let utctime = "";
        if (getutchrs < 10)
        {
          if (getutcmin < 10)
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              getutchrs +
              ":" +
              "0" +
              getutcmin;
          }
          else
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              getutchrs +
              ":" +
              getutcmin;
          }
        }
        else
        {
          if (getutcmin < 10)
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              getutchrs +
              ":" +
              "0" +
              getutcmin;
          }
          else
          {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              getutchrs +
              ":" +
              getutcmin;
          }
        }
        return utctime;
      }
      else
      {
        let utctime = "-";
        return utctime;
      }
    },

    ChangeDateToShow(date) {
      if (!date) return null;
      let todate = new Date(Date.parse(date));
      let timezone = todate.getTimezoneOffset() * 60000;
      let newDate = new Date(todate.getTime() - timezone);
      return (
        newDate.getDate() +
        "-" +
        this.monthName[newDate.getMonth()] +
        "-" +
        newDate.getFullYear()
      );
    },

    //New Template Dialog Opener
    thenewtempdialogopener() {
      let _this = this;
      _this.templateDialog = true;
      _this.headersfortemp.push({
        text: _this.$t("employeeid"),
        value: "employee_ID",
        id: 1
      }, {
        text: _this.$t("date"),
        value: "date",
        id: 2
      }, {
        text: _this.$t("Time"),
        value: "time",
        id: 3
      },
      );
      _this.employeeIDSelecter = 1;
      _this.dateSelecter = 2;
      _this.timeSelecter = 3;
      _this.datesel = 0;
      _this.timesel = 0;
      _this.modelTab1 = 0;
      _this.showexcel = false;
      _this.tempFormat = "Tab";
      _this.thedateexample = "02/21/2023";
      _this.thetimeexample = "14:18:23";
      _this.TemplateArray.push({
        employee_ID: "0001",
        date: _this.thedateexample,
        time: _this.thetimeexample,
      });

      _this.thetextFile = "0001" + "\t \t" + _this.thedateexample + "\t \t" + _this.thetimeexample;
    },

    //make sure which header is the one
    changeHeader(val, id) {
      let self = this;
      self.GoHeader();
      for (let s = 0; s < self.sayahh.length; s++)
      {
        if (
          self.sayahh[s].id != id &&
          self.sayahh[s].value == val &&
          self.FieldList1[val - 1].txt != "N/A"
        )
        {
          //if(self.sayahh[s].value == val){
          alert(
            "This field already has sample data assigned. Please check again!"
          );
        }
      }
    },

    changeHeader1(val) {
      let self = this;
      let arr = self.TemplateArray;
      if (val == "field 2")
      {
        self.TemplateArray = [];
        let tip = self.tempheader1;
        self.tempheader1 = self.tempheader2;
        self.tempheader2 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata2,
            tempdata2: arr[i].tempdata1,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field2show = "field 1";
        self.field3show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 3")
      {
        self.TemplateArray = [];
        let tip = self.tempheader1;
        self.tempheader1 = self.tempheader3;
        self.tempheader3 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata3,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata1,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field2show = "field 2";
        self.field3show = "field 1";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 4")
      {
        self.TemplateArray = [];
        let tip = self.tempheader1;
        self.tempheader1 = self.tempheader4;
        self.tempheader4 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata4,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata1,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 1";
        self.field5show = "field 5";
      }
      else if (val == "field 5")
      {
        self.TemplateArray = [];
        let tip = self.tempheader1;
        self.tempheader1 = self.tempheader5;
        self.tempheader5 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata5,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata1,
          });
        }
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 1";
      }
      else
      {
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
    },

    changeHeader2(val) {
      let self = this;
      let arr = self.TemplateArray;
      if (val == "field 1")
      {
        self.TemplateArray = [];
        let tip = self.tempheader2;
        self.tempheader2 = self.tempheader1;
        self.tempheader1 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata2,
            tempdata2: arr[i].tempdata1,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 3")
      {
        self.TemplateArray = [];
        let tip = self.tempheader2;
        self.tempheader2 = self.tempheader3;
        self.tempheader3 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata3,
            tempdata3: arr[i].tempdata2,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 1";
        self.field3show = "field 2";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 4")
      {
        self.TemplateArray = [];
        let tip = self.tempheader2;
        self.tempheader2 = self.tempheader4;
        self.tempheader4 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata4,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata2,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 1";
        self.field3show = "field 3";
        self.field4show = "field 2";
        self.field5show = "field 5";
      }
      else if (val == "field 5")
      {
        self.TemplateArray = [];
        let tip = self.tempheader2;
        self.tempheader2 = self.tempheader5;
        self.tempheader5 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata5,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata2,
          });
        }
        self.field1show = "field 1";
        self.field3show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 2";
      }
      else
      {
        self.field1show = "field 1";
        self.field3show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
    },

    changeHeader3(val) {
      let self = this;
      let arr = self.TemplateArray;
      if (val == "field 2")
      {
        self.TemplateArray = [];
        let tip = self.tempheader3;
        self.tempheader3 = self.tempheader2;
        self.tempheader2 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata3,
            tempdata3: arr[i].tempdata2,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 3";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 1")
      {
        self.TemplateArray = [];
        let tip = self.tempheader3;
        self.tempheader3 = self.tempheader1;
        self.tempheader1 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata3,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata1,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 3";
        self.field2show = "field 2";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 4")
      {
        self.TemplateArray = [];
        let tip = self.tempheader3;
        self.tempheader3 = self.tempheader4;
        self.tempheader4 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata4,
            tempdata4: arr[i].tempdata3,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field4show = "field 3";
        self.field5show = "field 5";
      }
      else if (val == "field 5")
      {
        self.TemplateArray = [];
        let tip = self.tempheader3;
        self.tempheader3 = self.tempheader5;
        self.tempheader5 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata5,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata3,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field4show = "field 4";
        self.field5show = "field 3";
      }
      else
      {
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field4show = "field 4";
        self.field5show = "field 5";
      }
    },

    changeHeader4(val) {
      let self = this;
      let arr = self.TemplateArray;
      if (val == "field 2")
      {
        self.TemplateArray = [];
        let tip = self.tempheader4;
        self.tempheader4 = self.tempheader2;
        self.tempheader2 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata4,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata2,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 4";
        self.field3show = "field 3";
        self.field5show = "field 5";
      }
      else if (val == "field 3")
      {
        self.TemplateArray = [];
        let tip = self.tempheader4;
        self.tempheader4 = self.tempheader3;
        self.tempheader3 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata4,
            tempdata4: arr[i].tempdata3,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field3show = "field 4";
        self.field5show = "field 5";
      }
      else if (val == "field 1")
      {
        self.TemplateArray = [];
        let tip = self.tempheader4;
        self.tempheader4 = self.tempheader1;
        self.tempheader1 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata4,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata1,
            tempdata5: arr[i].tempdata5,
          });
        }
        self.field1show = "field 4";
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field5show = "field 5";
      }
      else if (val == "field 5")
      {
        self.TemplateArray = [];
        let tip = self.tempheader4;
        self.tempheader4 = self.tempheader5;
        self.tempheader5 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata5,
            tempdata5: arr[i].tempdata4,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field5show = "field 4";
      }
      else
      {
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field5show = "field 5";
      }
    },

    changeHeader5(val) {
      let self = this;
      let arr = self.TemplateArray;
      if (val == "field 2")
      {
        self.TemplateArray = [];
        let tip = self.tempheader5;
        self.tempheader5 = self.tempheader2;
        self.tempheader2 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata5,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata2,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 5";
        self.field3show = "field 3";
        self.field4show = "field 4";
      }
      else if (val == "field 3")
      {
        self.TemplateArray = [];
        let tip = self.tempheader5;
        self.tempheader5 = self.tempheader3;
        self.tempheader3 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata5,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata3,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field3show = "field 5";
        self.field4show = "field 4";
      }
      else if (val == "field 4")
      {
        self.TemplateArray = [];
        let tip = self.tempheader5;
        self.tempheader5 = self.tempheader4;
        self.tempheader4 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata1,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata5,
            tempdata5: arr[i].tempdata4,
          });
        }
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 5";
      }
      else if (val == "field 1")
      {
        self.TemplateArray = [];
        let tip = self.tempheader5;
        self.tempheader5 = self.tempheader1;
        self.tempheader1 = tip;
        for (let i = 0; i < arr.length; i++)
        {
          self.TemplateArray.push({
            tempdata1: arr[i].tempdata5,
            tempdata2: arr[i].tempdata2,
            tempdata3: arr[i].tempdata3,
            tempdata4: arr[i].tempdata4,
            tempdata5: arr[i].tempdata1,
          });
        }
        self.field1show = "field 5";
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 4";
      }
      else
      {
        self.field1show = "field 1";
        self.field2show = "field 2";
        self.field3show = "field 3";
        self.field4show = "field 4";
      }
    },

    //As the function says
    changeDelimeter() {
      let self = this;
      self.thetextchanger();
    },

    //Show To Enter Template Name
    ShowSaveTheSetting() {
      let self = this;
      self.GoHeader();
      self.templateDialog = false;
      self.SavingDialog = true;
    },

    tholdsaveset() {
      if (
        self.tempheader1 != self.tempheader2 &&
        self.tempheader1 != self.tempheader3 &&
        self.tempheader1 != self.tempheader4 &&
        self.tempheader1 != self.tempheader5 &&
        self.tempheader2 != self.tempheader3 &&
        self.tempheader2 != self.tempheader4 &&
        self.tempheader2 != self.tempheader5 &&
        self.tempheader3 != self.tempheader4 &&
        self.tempheader3 != self.tempheader5 &&
        self.tempheader4 != self.tempheader5
      )
      {
        let saywhat = true;
        if (
          self.tempheader1 != "N/A" &&
          self.tempheader2 != "N/A" &&
          self.tempheader3 != "N/A" &&
          self.tempheader4 != "N/A"
        )
        {
          if (self.INOUTCheck && self.tempheader5 != "N/A")
          {
            saywhat = true;
          }
          else if (!self.INOUTCheck && self.tempheader5 == "N/A")
          {
            saywhat = true;
          }
          else
          {
            saywhat = false;
          }
        }
        else
        {
          saywhat = false;
        }

        if (saywhat)
        {
          if (self.INOUTCheck)
          {
            if (
              self.tempDateFormat != null &&
              self.tempDateFormat != "" &&
              self.inVal != null &&
              self.inVal != "" &&
              self.outVal != null &&
              self.outVal != "" &&
              self.tempFormat != null &&
              self.tempFormat != ""
            )
            {
              if (self.inVal != self.outVal)
              {
                let hiyaa = null;
                if (self.tempFormat == "Colon")
                {
                  hiyaa = ":";
                }
                else if (self.tempFormat == "Comma")
                {
                  hiyaa = ",";
                }
                else if (self.tempFormat == "Equals Sign")
                {
                  hiyaa = "=";
                }
                else if (self.tempFormat == "Semicolon")
                {
                  hiyaa = ";";
                }
                else if (self.tempFormat == "Space")
                {
                  hiyaa = " ";
                }
                else if (self.tempFormat == "Tab")
                {
                  hiyaa = "\t";
                }

                if (this.tempDateFormat.includes(hiyaa))
                {
                  alert("Can't input " + self.tempFormat + " In Data Format");
                }
                else
                {
                  self.templateDialog = false;
                  self.SavingDialog = true;
                }
              }
              else
              {
                alert("In and Out value must not be the same");
              }
            }
            else
            {
              alert(this.$t("pleasefill"));
            }
          }
          else
          {
            if (
              self.tempDateFormat != null &&
              self.tempDateFormat != "" &&
              self.tempFormat != null &&
              self.tempFormat != ""
            )
            {
              let hiyaa = null;
              if (self.tempFormat == "Colon")
              {
                hiyaa = ":";
              }
              else if (self.tempFormat == "Comma")
              {
                hiyaa = ",";
              }
              else if (self.tempFormat == "Equals Sign")
              {
                hiyaa = "=";
              }
              else if (self.tempFormat == "Semicolon")
              {
                hiyaa = ";";
              }
              else if (self.tempFormat == "Space")
              {
                hiyaa = " ";
              }
              else if (self.tempFormat == "Tab")
              {
                hiyaa = "\t";
              }
              if (this.tempDateFormat.includes(hiyaa))
              {
                alert("Can't input " + self.tempFormat + " In Data Format");
              }
              else
              {
                self.templateDialog = false;
                self.SavingDialog = true;
              }
            }
            else
            {
              alert(this.$t("pleasefill"));
            }
          }
        }
        else
        {
          if (self.INOUTCheck && self.sayahh.length == 4)
          {
            alert("Can't save In/Out required with 4 columns");
          }
          else
          {
            alert("Some header can't be N/A");
          }
        }
      }
      else
      {
        alert("Headers can't be duplicate");
      }
    },

    CloseSavingDialog() {
      let self = this;
      self.templateDialog = true;
      self.SavingDialog = false;
    },

    //When Click Load
    LoadOpenTheTable() {
      let self = this;
      self.TemplateArray = [];
      self.TTTemNaAr = [];
      //self.tntfile = null;
      self.showthetable = false;
      //self.$refs.file.value = null;
      if (self.tempFormat != null && self.tempFormat != "")
      {
        document.getElementById("fileInputButtonTNT").click();
        // setTimeout(() => {
        //   self.TeuploadDialog = true;
        // }, 2000);
      }
      else
      {
        document.getElementById("fileInputButtonTNT").click();
        self.csvname = null;
        // setTimeout(() => {
        //   self.TeuploadDialog = true;
        // }, 2000);
      }
    },

    //csv uploader
    sortBy: function (key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
    },

    allowedEndDates(date) {
      if (!this.startdate) return true;
      const startDate = this.$moment(this.startdate, 'DD/MM/YYYY');
      const endDate = this.$moment(date, 'DD/MM/YYYY');
      return endDate.isSameOrBefore(startDate);
    },

    loadCSV(e) {
      let self = this;
      if (window.FileReader)
      {
        let reader = new FileReader();
        reader.readAsText(e);
        self.csvname = e.name;
        reader.onload = function (event) {
          self.csv = event.target.result;
          //self.parse_csv = self.csvJSON(self.csv);
        };
      }
    },

    csvJSON(csv) {
      let self = this;
      self.TemplateArray = [];
      self.TTTemNaAr = [];
      let lines = csv.split("\n");
      // let headers = lines[0].split(",").map(function (u) {
      //   return u.trim();
      // });

      let headers = [];
      if (lines[0].includes("\t"))
      {
        self.parse_header = lines[0].split("\t");
        for (let i = 0; i < self.parse_header.length; i++)
        {
          let yum = i + 1;
          if (yum < 8)
          {
            self.headersfortemp.push({
              text: self.$t("field") + " " + yum,
              value: "tempdata" + yum,
            });
            self.sayahh.push({
              id: yum,
              value: yum,
            });
            headers.push("tempdata" + yum);
          }
        }

        lines[0].split("\t").forEach(function (key) {
          self.sortOrders[key] = 1;
        });
        lines.map(function (line) {
          //if (indexLine < 1) return;
          let obj = {};
          let currentline = line.split("\t");

          headers.map(function (header, indexHeader) {
            obj[header] = currentline[indexHeader];
          });
          self.TemplateArray.push(obj);
          self.TTTemNaAr.push(obj);
        });
      }
      else
      {
        self.parse_header = lines[0].split(",");
        for (let i = 0; i < self.parse_header.length; i++)
        {
          let yum = i + 1;
          if (yum < 8)
          {
            self.headersfortemp.push({
              text: self.$t("field") + " " + yum,
              value: "tempdata" + yum,
            });
            self.sayahh.push({
              id: yum,
              value: yum,
            });
            headers.push("tempdata" + yum);
          }
        }
        lines[0].split(",").forEach(function (key) {
          self.sortOrders[key] = 1;
        });

        lines.map(function (line) {
          //if (indexLine < 1) return;
          let obj = {};
          let currentline = line.split(",");
          headers.map(function (header, indexHeader) {
            obj[header] = currentline[indexHeader];
          });
          self.TemplateArray.push(obj);
          self.TTTemNaAr.push(obj);
        });
      }

      self.TemplateArray.pop();
      self.TTTemNaAr.pop();
      return self.TemplateArray;
    },

    //ShowTable
    LoadTheTable() {
      let self = this;
      let hiyaa = null;
      if (self.tempFormat == "Colon")
      {
        hiyaa = ":";
      }
      else if (self.tempFormat == "Comma")
      {
        hiyaa = ",";
      }
      else if (self.tempFormat == "Equals Sign")
      {
        hiyaa = "=";
      }
      else if (self.tempFormat == "Semicolon")
      {
        hiyaa = ";";
      }
      else if (self.tempFormat == "Space")
      {
        hiyaa = " ";
      }
      else if (self.tempFormat == "Tab")
      {
        hiyaa = "\t";
      }
      var selectedFile = document.getElementById("fileInputButtonTNT").files;

      if (selectedFile != null) {
        let files1 = selectedFile[0];
        let fieldtype = files1.type;
        self.TeuploadDialog = false;
        self.headersfortemp = [];
        self.sayahh = [];
        self.TemplateArray = [];
        self.TTTemNaAr = [];
        var reader = new FileReader();
        if (fieldtype == "text/plain")
        {
          if (self.tempFormat != null && self.tempFormat != "")
          {
            let realary = [];
            reader.onload = function readSuccess(e) {
              this.uploadData = [];
              this.uploadData = e.target.result;
              if (this.uploadData.includes(hiyaa))
              {
                self.valitem = self.tempFormat;
                let array = this.uploadData.split(/\r\n|\n/);
                for (let k = 0; k < array.length; k++)
                {
                  let array1 = array[k].split(hiyaa);
                  realary.push(array1);
                }
                let dontshow = false;
                for (let y = 0; y < realary.length; y++)
                {
                  if (y == 0)
                  {
                    for (let i = 0; i < realary[y].length; i++)
                    {
                      let yum = i + 1;
                      if (yum < 8)
                      {
                        self.headersfortemp.push({
                          text: self.$t("field") + " " + yum,
                          value: "tempdata" + yum,
                        });
                        if (yum == 7)
                        {
                          let ilu = yum - 1;
                          self.sayahh.push({
                            id: yum,
                            value: ilu,
                          });
                        }
                        else
                        {
                          self.sayahh.push({
                            id: yum,
                            value: yum,
                          });
                        }
                      }
                    }
                  }
                  if (realary[y].length == 4)
                  {
                    self.TemplateArray.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                    });
                    self.TTTemNaAr.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                    });
                  }
                  else if (realary[y].length == 5)
                  {
                    self.TemplateArray.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                      tempdata5: realary[y][4],
                    });
                    self.TTTemNaAr.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                      tempdata5: realary[y][4],
                    });
                  }
                  else if (realary[y].length == 6)
                  {
                    self.TemplateArray.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                      tempdata5: realary[y][4],
                      tempdata6: realary[y][5],
                    });
                    self.TTTemNaAr.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                      tempdata5: realary[y][4],
                      tempdata6: realary[y][5],
                    });
                  }
                  else if (realary[y].length >= 7)
                  {
                    self.TemplateArray.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                      tempdata5: realary[y][4],
                      tempdata6: realary[y][5],
                      tempdata7: realary[y][6],
                    });
                    self.TTTemNaAr.push({
                      tempdata1: realary[y][0],
                      tempdata2: realary[y][1],
                      tempdata3: realary[y][2],
                      tempdata4: realary[y][3],
                      tempdata5: realary[y][4],
                      tempdata6: realary[y][5],
                      tempdata7: realary[y][6],
                    });
                  }
                  // else if(realary[y].length >= 8){
                  //   self.TemplateArray.push({
                  //     tempdata1: realary[y][0],
                  //     tempdata2: realary[y][1],
                  //     tempdata3: realary[y][2],
                  //     tempdata4: realary[y][3],
                  //     tempdata5: realary[y][4],
                  //     tempdata6: realary[y][5],
                  //     tempdata7: realary[y][6],
                  //     tempdata8: realary[y][7],
                  //   });
                  //   self.TTTemNaAr.push({
                  //     tempdata1: realary[y][0],
                  //     tempdata2: realary[y][1],
                  //     tempdata3: realary[y][2],
                  //     tempdata4: realary[y][3],
                  //     tempdata5: realary[y][4],
                  //     tempdata6: realary[y][5],
                  //     tempdata7: realary[y][6],
                  //     tempdata8: realary[y][7],
                  //   });
                  // }
                  else if (realary[y].length <= 3)
                  {
                    alert("Please Upload the correct format");
                    dontshow = true;
                    break;
                  }
                }
                self.INOUTCheck = true;
                self.changeforneed();

                if (!dontshow)
                {
                  self.showthetable = true;
                }
                else
                {
                  self.showthetable = false;
                }
              }
              else
              {
                alert(
                  "The delimiter selected doesn't match the file uploaded.Please check"
                );
                self.tntfile = null;
              }
            };
            reader.readAsText(files1);
            document.getElementById("fileInputButtonTNT").value = "";
            selectedFile = null;
          }
          else
          {
            alert("Please Select Delimiter First");
            self.tntfile = null;
          }
        }
        else
        {
          self.INOUTCheck = true;
          self.changeforneed();
          self.tempFormat = "Comma";
          self.valitem = self.tempFormat;
          self.parse_csv = self.csvJSON(self.csv);
          self.showthetable = true;
        }
      }
      else
      {
        alert(this.$t("nofilesele"));
        self.TeuploadDialog = false;
      }
    },

    ClosetemplateDialog() {
      let self = this;
      self.tntfile = null;
      //self.tempFormat = null;
      self.tempDateFormat = null;
      self.inVal = null;
      self.outVal = null;
      self.showthetable = false;
      self.tempheader1 = "Employee ID";
      self.tempheader2 = "Date";
      self.tempheader3 = "Time";
      self.tempheader4 = "-";
      self.tempheader5 = "-";
      self.headersfortemp = [];
      self.TemplateArray = [];
      self.templateDialog = false;
    },

    SaveTheSetting() {
      let self = this;
      self.LoadingDialog = true;
      if (self.tempheader1 == "Column1")
      {
        self.tempheader1 = "Machine ID";
        self.tempheader2 = "Time";
        self.tempheader3 = "Employee ID";
        self.tempheader4 = "Shift";
        self.tempheader5 = "In/Out";
      }

      if (self.employeeIDSelecter == 1)
      {
        self.tempheader1 = "Employee ID";
      }
      else if (self.employeeIDSelecter == 2)
      {
        self.tempheader2 = "Employee ID";
      }
      else if (self.employeeIDSelecter == 3)
      {
        self.tempheader3 = "Employee ID";
      }
      if (self.dateSelecter == 1)
      {
        self.tempheader1 = "Date";
      }
      else if (self.dateSelecter == 2)
      {
        self.tempheader2 = "Date";
      }
      else if (self.dateSelecter == 3)
      {
        self.tempheader3 = "Date";
      }

      if (self.timeSelecter == 1)
      {
        self.tempheader1 = "Time";
      }
      else if (self.timeSelecter == 2)
      {
        self.tempheader2 = "Time";
      }
      else if (self.timeSelecter == 3)
      {
        self.tempheader3 = "Time";
      }
      //let valentine = self.validateformat();
      //self.GoHeader();
      if (self.tempName != null && self.tempName != "") {
        let tempads = {
          template_Name: self.tempName,
          template_Format: self.tempFormat,
          fieldOne: self.tempheader1,
          fieldTwo: self.tempheader2,
          fieldThree: self.tempheader3,
          fieldFour: "-",
          fieldFive: "-",
          date_Format: self.dateFormatList[self.datesel].text,
          time_Format: self.timeFormatList[self.timesel].text,
          clock_In: "",
          clock_Out: "",
          inOut_Required: false,
          employee_ID: store.state.userid,
          company_ID: store.state.companyID,
          //user_ID: store.state.userid,
        };
        axios
          .post(`${self.url}SyncTimeTemplate/AddSyncTimeTemplate`, tempads)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.SavingDialog = false;
              self.tempFormat = null;
              self.tempDateFormat = null;
              self.inVal = null;
              self.outVal = null;
              self.showthetable = false;
              self.tempName = "";
              self.tempheader1 = "Machine ID";
              self.tempheader2 = "Time";
              self.tempheader3 = "Employee ID";
              self.tempheader4 = "Shift";
              self.tempheader5 = "In/Out";
              self.LoadingDialog = false;
              self.SyncTempList = [];
              self.ImportNameList = [];
              self.tntfile = null;
              self.GetSyncTempName();
            }
          })
          .catch(function (error)
          {
            self.LogTrace(error, "Add Sync Template Fail", 51, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        self.LoadingDialog = false;
      }
      else if (self.tempName == null || self.tempName == "")
      {
        alert("Please Enter Template Name");
        self.LoadingDialog = false;
      }
      // else {
      //   alert("Datetime Format doesn't match the system Format.");
      //   self.LoadingDialog = false;
      // }
    },

    GoHeader() {
      let self = this;
      for (let i = 0; i < self.sayahh.length; i++) {
        if (self.sayahh[i].id == 1) {
          if (self.INOUTCheck) {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader1 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader1 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader1 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader1 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader1 = "In/Out";
            }
          }
          else
          {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader1 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader1 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader1 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader1 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader1 = "N/A";
            }
          }
        }
        else if (self.sayahh[i].id == 2)
        {
          if (self.INOUTCheck)
          {
            if (self.sayahh[i].value == 1) {
              self.tempheader2 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader2 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader2 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader2 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader2 = "In/Out";
            }
          }
          else
          {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader2 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader2 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader2 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader2 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader2 = "N/A";
            }
          }
        }
        else if (self.sayahh[i].id == 3)
        {
          if (self.INOUTCheck)
          {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader3 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader3 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader3 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader3 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader3 = "In/Out";
            }
          }
          else
          {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader3 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader3 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader3 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader3 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader3 = "N/A";
            }
          }
        }
        else if (self.sayahh[i].id == 4)
        {
          if (self.INOUTCheck) {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader4 = "Machine ID";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader4 = "Time";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader4 = "Employee ID";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader4 = "Shift";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader4 = "In/Out";
              self.tempheader5 = "N/A";
            }
          }
          else
          {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader4 = "Machine ID";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader4 = "Time";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader4 = "Employee ID";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader4 = "Shift";
              self.tempheader5 = "N/A";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader4 = "N/A";
              self.tempheader5 = "N/A";
            }
          }
        }
        else if (self.sayahh[i].id == 5)
        {
          if (self.INOUTCheck) {
            if (self.sayahh[i].value == 1)
            {
              self.tempheader5 = "Machine ID";
            }
            else if (self.sayahh[i].value == 2)
            {
              self.tempheader5 = "Time";
            }
            else if (self.sayahh[i].value == 3)
            {
              self.tempheader5 = "Employee ID";
            }
            else if (self.sayahh[i].value == 4)
            {
              self.tempheader5 = "Shift";
            }
            else if (self.sayahh[i].value == 5)
            {
              self.tempheader5 = "In/Out";
            }
          }
          else
          {
            self.tempheader5 = "N/A";
          }
        }
      }
    },

    changeforneed() {
      let self = this;
      if (!self.INOUTCheck)
      {
        self.FieldList1[4].txt = "N/A";
        for (let i = 0; i < self.sayahh.length; i++)
        {
          if (self.sayahh[i].id == 5)
          {
            self.sayahh[i].value = 5;
          }
        }
        self.FieldList1.pop();
      }
      else
      {
        self.FieldList1[4].txt = "In/Out";
        for (let j = 0; j < self.sayahh.length; j++)
        {
          if (self.sayahh[j].id == 5)
          {
            self.sayahh[j].value = 5;
          }
        }
        self.FieldList1.push({ val: 6, txt: "N/A" });
      }
    },

    ShowIt(id) {
      let self = this;
      self.templateforshowDialog = true;
      if (id == 0)
      {
        self.bakayaro = false;
      }
      else
      {
        self.bakayaro = true;
      }
    },

    OpenDeletedialog(id) {
      let self = this;
      self.Deletedialog = true;
      self.IDtodelete = id;
    },

    DeleteThisWhatever() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.IDtodelete;
      let tempads = {
        template_ID: id,
      };
      axios
        .post(`${self.url}SyncTimeTemplate/DeleteSyncTimeTemplate`, tempads)
        .then(function (response)
        {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.Deletedialog = false;
            self.LoadingDialog = false;
            self.ImportNameList = [];
            self.SyncTempList = [];
            self.GetSyncTempName();
          }
        })
        .catch(function (error)
        {
          self.LogTrace(error, "Delete Sync Template Fail", 51, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    formatDate1(date) {
      if (!date) return null;
      let newdatetosent = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      newdatetosent.setDate(day);
      newdatetosent.setMonth(month);
      newdatetosent.setFullYear(year);
      return `${day}/${month}/${year}`;
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (![49, 50, 51].includes(keyCode)) {
        $event.preventDefault();
      }
    },

    clickjarviz() {
      this.showjarvizz = true;
      this.templateselected = null;
    },

    addEnum(item) {
      if (item < 3 && item >= 1)
      {
        this.employeeIDSelecter++;
        if (this.employeeIDSelecter == this.dateSelecter)
        {
          this.dateSelecter = item;
        }
        else if (this.employeeIDSelecter == this.timeSelecter)
        {
          this.timeSelecter = item;
        }
        this.sortArrayBasedOnOrder();
        this.thetextchanger();
      }
    },

    subEnum(item) {
      if (item > 1 && item <= 3)
      {
        this.employeeIDSelecter--;
        if (this.employeeIDSelecter == this.dateSelecter)
        {
          this.dateSelecter = item;
        }
        else if (this.employeeIDSelecter == this.timeSelecter)
        {
          this.timeSelecter = item;
        }
        this.sortArrayBasedOnOrder();
        this.thetextchanger();
      }
    },

    addDnum(item) {
      if (item < 3 && item >= 1)
      {
        this.dateSelecter++;
        if (this.employeeIDSelecter == this.dateSelecter)
        {
          this.employeeIDSelecter = item;
        } else if (this.dateSelecter == this.timeSelecter)
        {
          this.timeSelecter = item;
        }
        this.sortArrayBasedOnOrder();
        this.thetextchanger();
      }
    },

    subDnum(item) {
      if (item > 1 && item <= 3) {
        this.dateSelecter--;
        if (this.employeeIDSelecter == this.dateSelecter)
        {
          this.employeeIDSelecter = item;
        }
        else if (this.dateSelecter == this.timeSelecter)
        {
          this.timeSelecter = item;
        }
        this.sortArrayBasedOnOrder();
        this.thetextchanger();
      }
    },

    addTnum(item) {
      if (item < 3 && item >= 1)
      {
        this.timeSelecter++;
        if (this.employeeIDSelecter == this.timeSelecter)
        {
          this.employeeIDSelecter = item;
        }
        else if (this.dateSelecter == this.timeSelecter)
        {
          this.dateSelecter = item;
        }
        this.sortArrayBasedOnOrder();
        this.thetextchanger();
      }
    },

    subTnum(item) {
      if (item > 1 && item <= 3)
      {
        this.timeSelecter--;
        if (this.employeeIDSelecter == this.timeSelecter)
        {
          this.employeeIDSelecter = item;
        }
        else if (this.dateSelecter == this.timeSelecter)
        {
          this.dateSelecter = item;
        }
        this.sortArrayBasedOnOrder();
        this.thetextchanger();
      }
    },

    changeEmpSelecter(item) {
      if (item >= 1 && item <= 3)
      {
        this.employeeIDSelecter = item;
        this.dateSelecter = (item === this.dateSelecter) ? this.dateSelecter : 1;
        this.timeSelecter = (item === this.timeSelecter) ? this.timeSelecter : 1;
      }
    },

    changeDateSelecter(item) {
      if (item >= 1 && item <= 3)
      {
        this.dateSelecter = item;
        this.employeeIDSelecter = (item === this.employeeIDSelecter) ? this.employeeIDSelecter : 1;
        this.timeSelecter = (item === this.timeSelecter) ? this.timeSelecter : 1;
      }
    },

    changeTimeSelecter(item) {
      if (item >= 1 && item <= 3)
      {
        this.timeSelecter = item;
        this.employeeIDSelecter = (item === this.employeeIDSelecter) ? this.employeeIDSelecter : 1;
        this.dateSelecter = (item === this.dateSelecter) ? this.dateSelecter : 1;
      }
    },

    changeDateFomatSel(e) {
      this.thedateexample = this.dateFormatList[e].example;
      this.TemplateArray = [];
      this.TemplateArray.push({
        employee_ID: "0001",
        date: this.thedateexample,
        time: this.thetimeexample,
      });
      this.thetextchanger();
    },

    changeTimeFomatSel(e) {
      this.thetimeexample = this.timeFormatList[e].example;
      this.TemplateArray = [];
      this.TemplateArray.push({
        employee_ID: "0001",
        date: this.thedateexample,
        time: this.thetimeexample,
      });
      this.thetextchanger();
    },

    sortArrayBasedOnOrder() {
      let sortedArr = [];
      let temar = [{
        text: this.$t("employeeid"),
        value: "employee_ID",
        id: 1
      }, {
        text: this.$t("date"),
        value: "date",
        id: 2
      }, {
        text: this.$t("Time"),
        value: "time",
        id: 3
      },];
      let order = [this.employeeIDSelecter, this.dateSelecter, this.timeSelecter];
      for (let i = 0; i < order.length; i++)
      {
        let index = order.indexOf(i + 1);
        sortedArr.push(temar[index]);
      }
      this.headersfortemp = sortedArr;
    },

    thetextchanger() {
      let self = this;
      let thedeli = "";
      if (self.tempFormat == "Tab")
      {
        thedeli = "\t \t";
      }
      else if (self.tempFormat == "Space")
      {
        thedeli = " ";
      }
      else if (self.tempFormat == "Semicolon")
      {
        thedeli = ";";
      }
      else if (self.tempFormat == "Equals Sign")
      {
        thedeli = "=";
      }
      else if (self.tempFormat == "Comma")
      {
        thedeli = ",";
      }
      else if (self.tempFormat == "Colon")
      {
        thedeli = ":";
      }

      let number1 = "";
      let number2 = "";
      let number3 = "";
      if (self.employeeIDSelecter == 1)
      {
        number1 = "0001";
      }
      else if (self.employeeIDSelecter == 2)
      {
        number2 = "0001";
      }
      else if (self.employeeIDSelecter == 3)
      {
        number3 = "0001";
      }

      if (self.dateSelecter == 1)
      {
        number1 = self.thedateexample;
      }
      else if (self.dateSelecter == 2)
      {
        number2 = self.thedateexample;
      }
      else if (self.dateSelecter == 3)
      {
        number3 = self.thedateexample;
      }

      if (self.timeSelecter == 1)
      {
        number1 = self.thetimeexample;
      }
      else if (self.timeSelecter == 2)
      {
        number2 = self.thetimeexample;
      }
      else if (self.timeSelecter == 3)
      {
        number3 = self.thetimeexample;
      }
      self.thetextFile = number1 + thedeli + number2 + thedeli + number3;
    }
  },
};
</script>

<style scoped>
/* @media screen and (max-width: 668px) {
  .fix-height {
    height: 50vh;
  }
} */
@media (min-width: 767px) and (max-width: 1024px) {
  .new {
    margin-left: 1.5rem !important;
  }
}

@media (min-width: 375px) and (max-width: 414px) {
  .month {
    width: 210px !important;
  }

  .spacer {
    flex-grow: 0.2 !important;
  }
}

@media (min-width: 414px) and (max-width: 414px) {
  .cancel {
    margin-top: -0.2rem !important;
  }
}

@media (min-width: 415px) {
  .spacer {
    flex-grow: 1 !important;
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .cancel {
    margin-top: 1rem;
    margin-left: 0.5rem !important;
  }
}

@media (min-width: 321px) and (max-width: 414px) {
  .name {
    width: 216px !important;
  }

  .rebtn {
    width: 216px !important;
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .line {
    margin-top: -1rem !important;
  }

  .lines {
    margin-top: -1.7rem !important;
  }
}

@media (max-width: 414px) {
  .rebtn {
    margin-top: -1.5rem !important;
  }

  .addbtn {
    margin-top: -1.5rem !important;
  }
}

@media (max-width: 956px) {
  .thedatatopping {
    margin-top: 0rem !important;
  }

}

.thedatatopping {
  margin-top: 3rem;
}

>>>.sda .v-data-table__empty-wrapper {
  display: none;
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.gaga tr th {
  color: #f99d20 !important;
  min-width: 200px !important;
}

>>>.temmplateet tr th {
  color: white !important;
  background-color: #f99d20;
  min-width: 200px !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  color: #f99d20;
}

>>>.mx-input {
  height: 42px;
  border-radius: 24px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

.hidden {
  display: none;
}

.trigger:hover+.hidden {
  display: inline;
}

>>>.v-dialog::-webkit-scrollbar {
  width: 6px;
  background-color: none;
  /* You can add other properties & values */
}

>>>.v-dialog::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
  /* You can add other properties & values */
}

>>> ::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 1 px;
}

>>>.theco .v-application .primary--text {
  color: #0e2741b0;
  caret-color: #212b35;
}

.btn-multiline>span {
  width: 100%
}

.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #F99D20;
  ;
}

.v-tabs-bar .v-tab {
  background-color: white;
  /* width: 130px; */
  height: 35px;
  border: #f99d20 solid thin;
}

>>>.v-tabs-slider {
  /* background-color: currentColor; */
  height: 0px;
  width: 0px;
}

::v-deep .jarviz-tab {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* Custom styles for the "Customized" tab */
::v-deep .customized-tab {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
::v-deep .customized-tab1 {
  border-radius: 5px;
}
.my-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.9em;
  font-size: 14px;
  height: 80px;
  width: 220px;
  color: #F99D20;
  background: #fff;
  white-space: pre-wrap;
  /* border-radius: 15px;
  border: #F99D20 1px solid; */
}

/* >>>.batman .v-badge__badge { */
/* remove border radius to allow icon to fill space */
/* border-radius: 5;
  border: #F99D20 1px solid; */
/* use a clip-path to form a quirky triangle */
/* /clip-path: polygon(100% 51%, 0 0, 3% 100%); */
/* background: #fff;
  height: 80px;
  width: 220px; */
/* } */
/* .template {
  border-radius: 4px;
  margin: 24px;
  overflow-y: hidden !important;
  width: 100%;
  z-index: inherit;
} */</style>
