<template>
  <div>
    <v-card class="pt-5" style="margin-bottom: 2.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 25px; margin-right: 20px">
        <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <v-text-field
            autocomplete="off"
            :placeholder="$t('Search')"
            color="#F99D20"
            v-model.lazy="forsearch"
            dense
          ></v-text-field>
        </v-col>

        <v-btn
          color="#F99D20"
          @click="Search"
          class="white--text text-capitalize mt-2 ml-5"
          width="120"
          max-height="35"
        >
          <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
        </v-btn> -->
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            :placeholder="$t('Search')"
            v-model.lazy="forsearch"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;"
            @keyup.enter="Search()">
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="#F99D20"
          class="orange--text text-capitalize mt-2 mr-5"
          width="150"
          max-height="35"
          outlined
          @click="uploaddialog = true"
          :disabled="permission.upload">
          {{ $t("upload") }}
        </v-btn>
        <v-btn
          color="#F99D20"
          class="white--text text-capitalize mt-2 mr-5 btnres"
          width="150"
          max-height="35"
          @click="Adddialog = true"
          :disabled="permission.add"
          >{{ $t("Add") }}</v-btn
        >
      </v-row>
      <v-data-table
        style="margin-left: 40px; margin-right: 40px"
        class="tbheader"
        :headers="headers"
        :items="positionsearch"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        hide-default-header
        @page-count="pageCount = $event"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('position_lvl')"
              >
                {{ $t("positonLevel") }}
                <v-icon small v-if="!position_lvl">mdi-arrow-down</v-icon>
                <v-icon small v-if="position_lvl">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('job_lvl')"
              >
                {{ $t("jobLevel") }}
                <v-icon small v-if="!job_lvl">mdi-arrow-down</v-icon>
                <v-icon small v-if="job_lvl">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('position_Name')"
              >
                {{ $t("position") }}
                <v-icon small v-if="!position_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="position_Name">mdi-arrow-up</v-icon>
              </th>
              <th>{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.position_lvl }}</td>
            <td>{{ item.job_lvl }}</td>
            <td>{{ item.position_Name }}</td>
            <td>
              <v-btn @click="Editposition(item)" icon :disabled="permission.edit">
                <img class="editIcon" :src="images.edit" alt="editIcon"/>
              </v-btn>
              <v-btn @click="Deleteposition(item.position_ID)" icon :disabled="permission.delete">
                <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="positionsearch.length">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPage"
              :items="items"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- add position dialog -->
    <v-dialog v-model="Adddialog" persistent max-width="786px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
          {{ $t("addNewposition") }}
        </p>
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  :rules="positionlvl"
                  v-model="position.positionlvl"
                  ><template v-slot:label>
                    <span>
                      {{ $t("positonLevel")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="position.joblvl"
                  :rules="joblvl"
                  ><template v-slot:label>
                    <span>
                      {{ $t("jobLevel")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="position.positionname"
                  :rules="positionname"
                  ><template v-slot:label>
                    <span>
                      {{ $t("PositionName")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mb-4 mr-2 text-capitalize commoncancelbtn"
              text
              @click="cancelAdd()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mb-5 mr-2 text-capitalize commonsavebtn"
              @click="AddPosition()"
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="786px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
          {{ $t("EditPosition") }}
        </p>
        <v-card-text class="mt-3">
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formEdit" lazy-validation>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edposition.positionlvl"
                  :rules="positionlvlEdit"
                  ><template v-slot:label>
                    <span>
                      {{ $t("positonLevel")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edposition.joblvl"
                  :rules="joblvlEdit"
                  ><template v-slot:label>
                    <span>
                      {{ $t("jobLevel")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edposition.positionname"
                  :rules="positionnameEdit"
                  ><template v-slot:label>
                    <span>
                      {{ $t("PositionName")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mb-4 mr-2 text-capitalize commoncancelbtn"
              text
              @click="cancel()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mb-5 mr-2 text-capitalize commonsavebtn"
              @click="UpdatePosition()"
              >{{ $t("Update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteThisPosition?") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="Deletedialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commondeletebtn"
              @click="Deletepositionrow()"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload dialog -->
    <v-dialog v-model="uploaddialog" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: -30px; padding-top: 20px" cols="5">
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  color="white"
                  prepend-icon
                  accept=".xlsx"
                  id="fileInputButton"
                  class="inputbot ml-8"
                  v-model="chosenfile"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Position Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="CheckLang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="
                uploaddialog = false;
                chosenfile = null;
                showup = false;
              "
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="PreviewFiles"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
    forsearch: function () {
      if (this.searchlength == 1) {
        this.GetPosition();
      }
    },
    "position.positionlvl"() {
      this.positionlvl = [];
    },
    "position.positionname"() {
      this.positionname = [];
    },
    "position.joblvl"() {
      this.joblvl = [];
    },
    "edposition.positionlvl"() {
      this.positionlvlEdit = [];
    },
    "edposition.positionname"() {
      this.positionnameEdit = [];
    },
    "edposition.joblvl"() {
      this.joblvlEdit = [];
    },
  },
  data() {
    return {
      searchlength: 0,
      positionlvl: [],
      positionname: [],
      joblvl: [],
      positionlvlEdit: [],
      positionnameEdit: [],
      joblvlEdit: [],

      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      position_lvl: null,
      job_lvl: null,
      position_Name: null,
      chosenfile: null,
      importData: null,
      url: enurl.apiUrl,
      Adddialog: false,
      Editdialog: false,
      Deletedialog: false,
      uploaddialog: false,
      LoadingDialog: false,
      comID: null,
      upload: [],
      showup: false,
      positionID: 0,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      tempimport: [],
      PositionList: [],
      positionsearch: [],
      forsearch: "",
      headers: [
        {
          text: "PositionLevel",
          value: "position_lvl",
          sortable: false,
        },
        {
          text: "Job Level",
          value: "job_lvl",
          sortable: false,
        },
        {
          text: "Position",
          value: "position_Name",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
      ],
      position: {
        positionlvl: null,
        positionname: null,
        joblvl: null,
      },
      edposition: {
        position_ID: null,
        positionlvl: null,
        positionname: null,
        joblvl: null,
      },
      errormsg: [],
      fileshow: null,
    };
  },

  mounted() {
    let self = this;
    self.GetPosition();
    self.permission = self.CheckViewAddEditDeleteUpload(59, 60, 61, 62, 63);
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Position Master Data.xlsx";
      } else {
        url =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Position Master Data.xlsx";
      }
      return url;
    },
    ValidatePosition() {
      let self = this;
      let result;
      let FilterPosition = self.positionsearch.filter(
        (data) => data.position_Name
      );
      for (let i = 0; i < FilterPosition.length; i++) {
        if (
          FilterPosition[i].position_Name.toLowerCase() ==
          self.position.positionname.toLowerCase()
        ) {
          alert("Position already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    EditValidatePosition() {
      let self = this;
      let result;
      let FilterPosition = self.positionsearch.filter(
        (data) => data.position_ID != self.edposition.position_ID
      );
      for (let i = 0; i < FilterPosition.length; i++) {
        if (
          FilterPosition[i].position_Name.toLowerCase() ==
          self.edposition.positionname.toLowerCase()
        ) {
          alert("Position already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["position_lvl", "job_lvl", "position_Name"];
      if (
        (rowName == "position_lvl",
        rowName == "job_lvl",
        rowName == "position_Name")
      ) {
        self.PositionList = self.PositionList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.PositionList = self.PositionList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.position_lvl = null;
      this.job_lvl = null;
      this.position_Name = null;
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      // this.showup = true;
    },
    closeUpload() {
      this.uploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    checkposlvl(temp) {
      if (temp == undefined) {
        this.errormsg.push("position_lvl");
      }
    },
    checkjoblvl(temp) {
      if (temp == undefined) {
        this.errormsg.push("job_lvl");
      }
    },
    checkposname(temp) {
      if (temp == undefined) {
        this.errormsg.push("position_Name");
      }
    },
    PreviewFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      if (files != null) {
        let reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.importData = XLSX.utils.sheet_to_json(worksheet);
          const file_data = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          let combileArray = [];

          for (let i = 0; i < this.importData.length; i++) {
            self.checkposlvl(file_data[i + 1][0]);
            self.checkjoblvl(file_data[i + 1][1]);
            self.checkposname(file_data[i + 1][2]);
            this.tempimport = {
              position_lvl: file_data[i + 1][0].toString(),
              job_lvl: file_data[i + 1][1].toString(),
              position_Name: file_data[i + 1][2].toString(),
              company_ID: store.state.companyID,
            };
            combileArray.push(this.tempimport);
          }
          if (filename == "Position Master Data.xlsx")
          {
            if (self.errormsg.length == 0)
            {
              axios.post(`${self.url}Position/ImportPositionMasterData`,combileArray)
                .then(function (response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    self.uploaddialog = false;
                    self.chosenfile = null;
                    self.LoadingDialog = false;
                    self.GetPosition();
                    self.defaultSort();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(error, "Upload Position Fail", 3, "Verbose");
                  alert(error);
                  self.LoadingDialog = false;
                });
            } else {
              let Message = [...new Set(self.errormsg)];
              alert(` Please fill ${Message.join(", ")}`);
              self.errormsg = [];
            }
          } else {
            alert("File name must be Position Master Data .xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
        self.LoadingDialog = false;
      } else {
        alert("Please choose File");
        self.LoadingDialog = false;
      }
    },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => {});
    },
    capital(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    // keymonitor: function (event) {
    //   if (event.key == "Backspace") {
    //     this.GetPosition();
    //   }
    // },
    GetPosition() {
      let self = this;
      self.LoadingDialog = true;
      self.searchlength = 0;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Position/GetPosition`, tempp)
        .then(function (response) {
          self.PositionList = response.data.data;
          self.positionsearch = response.data.data;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Position Fail", 3, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },

    AddPosition() {
      try {
        // alert(this.comID)
        let self = this;
        self.LoadingDialog = true;
        self.positionlvl = [(v) => !!v || "Position Level is required"];
        self.positionname = [(v) => !!v || "Position Name is required"];
        self.joblvl = [(v) => !!v || "Job Level is required"];
        let tempvalidate = self.$refs.formAdd.validate();
        if (
          tempvalidate == true &&
          self.position.positionname != null &&
          self.position.positionname != null &&
          self.position.joblvl != null &&
          self.position.positionname != "" &&
          self.position.positionname != "" &&
          self.position.joblvl != ""
        ) {
          let tempPosition = self.ValidatePosition();
          if (tempPosition == true) {
            var nmtxt = self.position.positionname;

            var pslvl = self.position.positionlvl;

            var jlvl = self.position.joblvl;

            let positiondata = {
              position_Name: nmtxt,
              position_lvl: pslvl,
              job_lvl: jlvl,
              company_ID: store.state.companyID,
            };
            axios
              .post(`${self.url}Position/AddPosition`, positiondata)
              .then(function (response) {
                if (response.data.status == 0) {
                  self.Adddialog = false;
                  alert(response.data.message);
                  self.position.positionname = null;
                  self.position.positionlvl = null;
                  self.position.joblvl = null;
                  self.LoadingDialog = false;
                  self.GetPosition();
                  self.defaultSort();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Add  Position Fail", 3, "Low");
                alert(error);
                self.LoadingDialog = false;
              });
          } else {
            self.LoadingDialog = false;
          }
        } else {
          // alert("Please fill all fields");
          self.LoadingDialog = false;
        }
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    Editposition(temp) {
      let self = this;
      self.Editdialog = true;
      self.edposition.position_ID = temp.position_ID;
      self.edposition.positionlvl = temp.position_lvl;
      self.edposition.positionname = temp.position_Name;
      self.edposition.joblvl = temp.job_lvl;
    },
    cancelAdd() {
      let self = this;
      self.positionlvl = [];
      self.positionname = [];
      self.joblvl = [];
      self.$refs.formAdd.reset();
      self.Adddialog = false;
    },
    cancel() {
      let self = this;
      self.positionlvlEdit = [];
      self.positionnameEdit = [];
      self.joblvlEdit = [];
      self.$refs.formEdit.reset();
      self.Editdialog = false;
    },
    UpdatePosition() {
      let self = this;
      self.LoadingDialog = true;
      self.positionlvlEdit = [(v) => !!v || "Position Level is required"];
      self.positionnameEdit = [(v) => !!v || "Position Name is required"];
      self.joblvlEdit = [(v) => !!v || "Job Level is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.edposition.positionname != null &&
        self.edposition.positionlvl != null &&
        self.edposition.joblvl != null &&
        self.edposition.positionlvl != "" &&
        self.edposition.positionname != "" &&
        self.edposition.joblvl != ""
      ) {
        let tempPosition = self.EditValidatePosition();
        if (tempPosition == true) {
          var pntxt = self.edposition.positionname;
          var plvl = self.edposition.positionlvl;
          var jlvl = self.edposition.joblvl;
          let tempedit = {
            position_ID: self.edposition.position_ID,
            position_Name: pntxt,
            position_lvl: plvl,
            job_lvl: jlvl,
            company_ID: store.state.companyID,
          };
          axios
            .post(`${self.url}Position/UpdatePosition`, tempedit)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.Editdialog = false;
                self.LoadingDialog = false;
                self.GetPosition();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Position Fail", 3, "Medium");
              alert(error);
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("please fill all fields");
        self.LoadingDialog = false;
      }
    },
    validateupdate() {
      let self = this;
      if (
        self.edposition.positionname != null &&
        self.edposition.positionlvl != null &&
        self.edposition.joblvl != null &&
        self.edposition.positionname != "" &&
        self.edposition.positionlvl != "" &&
        self.edposition.joblvl != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    Deleteposition(id) {
      let self = this;
      self.positionID = id;
      self.Deletedialog = true;
    },
    Deletepositionrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.positionID;
      let tempdel = {
        position_ID: id,
      };
      axios
        .post(`${self.url}Position/DeletePosition`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.Deletedialog = false;
            self.LoadingDialog = false;
            self.GetPosition();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Position Fail", 3, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },
    Search() {
      let self = this;
      if (self.forsearch != "" && self.forsearch != undefined) {
        self.searchlength = 1;
        let List = self.positionsearch;
        self.positionsearch = [];

        let temp = List.filter(
          (v) =>
            v.position_Name
              .toLowerCase()
              .indexOf(self.forsearch.toLowerCase()) > -1 ||
            v.position_lvl.toLowerCase().indexOf(self.forsearch.toLowerCase()) >
              -1 ||
            v.job_lvl.toLowerCase().indexOf(self.forsearch.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.positionsearch.push(temp[i]);
        }
      } else {
        alert("Please fill in search field");
        self.GetPosition();
      }
    },
    // Search() {
    //   let self = this;
    //   if (self.forsearch != null && self.forsearch != "") {
    //     let List = self.positionsearch;
    //     self.positionsearch = [];
    //     var txt =
    //       self.forsearch.charAt(0).toUpperCase() + self.forsearch.slice(1);
    //     for (let i = 0; i < List.length; i++) {
    //       if (
    //         List[i].position_Name == txt ||
    //         List[i].position_lvl == txt ||
    //         List[i].job_lvl == txt
    //       ) {
    //         self.positionsearch.push(List[i]);
    //       }
    //     }
    //   } else {
    //     alert("Please fill in search field");
    //     self.GetPosition();
    //   }
    // },
  },
};
</script>
<style scoped>
>>> .v-dialog {
  overflow-y: unset !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
@media (max-width: 380px) {
  .headtext {
    margin-left: 2rem !important;
  }
}
@media (max-width: 375px) {
  .btnres {
    margin-top: 1rem !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}
</style>
