<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 1.5rem">
    <div style="display: flex">
      <v-btn icon router color="white" :to="{ name: 'EmployeesTimeOffRequest' }" class="mt-5">
        <img src="@/assets/images/back button.png" />
      </v-btn>
      <p class="ml-2 txt-header">
        {{ $t("leaverequest") }}
      </p>
    </div>
    <div style="height: auto" class="mt-4">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="9">
          <v-card min-height="80vh">
            <v-container style="padding-left: 10px; padding-right: 10px">
              <v-card-text>
                <div class="mt-8">
                  <v-row>
                    <!-- Request Date -->
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">{{
                      $t("RequestDate") }}:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-text-field v-model="timeOff.requested_Datetxt" autocomplete="off" readonly height="10px"
                        color="#F99D20" single-line outlined dense required class="readonly"></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Employee Name -->
                  <v-row>
                    <v-col class="pb-0" cols="12" xs="3" sm="2" md="2" lg="2" xl="2" style="font-size: 15px">{{ $t("EmployeeName")
                    }}<span class="red--text">*</span>:</v-col>
                    <v-col class="pb-0" cols="12" xs="3" sm="3" md="4" lg="4" xl="4">
                      <v-autocomplete @change="chooseemp()" class="empID" v-model="timeOff.employee"
                        :error-messages="error.employee" item-value="employee_ID"
                        :items="employeeList" :item-text="(item) => ` ${item.employee_Name}`" outlined
                        append-icon="mdi-chevron-down" dense color="#F99D20">
                        <template v-slot:selection="data">
                          <span style="font-size: 17px">{{
                            data.item.employee_Name
                          }}</span>
                        </template>
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-avatar size="30px">
                              <img :src="data.item.profile_Pic" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                data.item.nickname == null
                                ? data.item.employee_Name
                                : data.item.employee_Name +
                                " (" +
                                data.item.nickname +
                                ")"
                              }}</v-list-item-title>
                              <p style="font-size: 11px">
                                {{ data.item.position_Name }}
                              </p>
                            </v-list-item-content>
                          </template>
                        </template>
                        <template v-slot:message="{ }">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{ error.employee }}</span>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <!-- Position Name -->
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" style="font-size: 15px" class="mt-2 pb-0">{{
                      $t("PositionName") }}:</v-col>
                    <v-col class="pb-0" cols="12" xs="3" sm="3" md="4" lg="4" xl="4">
                      <v-text-field v-model="timeOff.position_Name" autocomplete="off" readonly height="10px"
                        color="#F99D20" class="readonly" single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" style="font-size: 15px" class="mt-2 pb-0">
                      {{ $t("leavetype") }}
                      <span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-autocomplete outlined dense :items="leaveTypeList" item-value="timeOff_ID"
                        v-model="timeOff.timeoff_ID" :item-text="thethaihead == true
                            ? 'timeoff_TypeTH'
                            : 'timeoff_TypeEN'
                          " color="#F99D20" :error-messages="error.timeoff_ID">
                        <template v-slot:message="{ }">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{ error.timeoff_ID }}</span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      <span v-if="timeOff.wholeDay">{{ $t("Allday") }}</span>
                      <span v-if="!timeOff.wholeDay">{{ $t("halfDay") }}</span>
                      <v-checkbox class="custom-checkbox" color="#F99D20" v-model="timeOff.wholeDay" @change="wholdDayCheck" style="margin-top: -1.5rem; margin-left: 4rem"></v-checkbox>
                      <!-- <v-checkbox v-model="timeOff.wholeDay" color="orange" hide-details @change="wholdDayCheck" style="margin-top: -1.5rem; margin-left: 4rem">
                      </v-checkbox> -->
                    </v-col>
                  </v-row>

                  <!-- Start Date and End Date -->
                  <div v-if="timeOff.wholeDay === true">
                    <v-row class="mt-3">
                      <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                        {{ $t("startDate") }}
                        <span class="red--text">*</span>:</v-col>
                      <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                        <!-- <v-menu v-model="menu7" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field single-line autocomplete="off" dense outlined :value="computedDateFormatted"
                              :error-messages="error.start_Date" append-icon="mdi-calendar-month-outline" color="#F99D20"
                              persistent-hint v-on="on">
                              <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                                <span>{{ error.start_Date }}</span>
                              </template></v-text-field>
                          </template>
                          <v-date-picker v-model="timeOff.start_Date" no-title :allowed-dates="allowedDates"
                            @input="menu7 = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker :class="error.start_Date ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" v-bind:clearable="false" value-type="format" v-model="timeOff.start_Date"
                        no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                        <span v-if="error.start_Date" class="error-message ml-2">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{ error.start_Date }}</span>
                        </span>
                      </v-col>

                      <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                        {{ $t("enddata") }}
                        <span class="red--text">*</span>:
                      </v-col>
                      <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                        <!-- <v-menu v-model="menu8" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field single-line autocomplete="off" dense outlined :value="computedDateFormatted1"
                              :error-messages="error.end_Date" append-icon="mdi-calendar-month-outline" color="#F99D20"
                              persistent-hint v-on="on">
                              <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                                <span>{{ error.end_Date }}</span>
                              </template></v-text-field>
                          </template>
                          <v-date-picker v-model="timeOff.end_Date" no-title :allowed-dates="allowedEndDates"
                            @input="menu8 = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker :class="error.end_Date ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" v-bind:clearable="false" value-type="format" v-model="timeOff.end_Date"
                        :disabled-date="allowedEndDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                        <span v-if="error.end_Date" class="error-message ml-2">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{ error.end_Date }}</span>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <!-- Date , start time , end time -->
                <div v-if="timeOff.wholeDay === false">
                  <v-row>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      {{ $t("date") }}<span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <!-- <v-menu v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted3"
                            class="kanit-medium mt-3" persistent-hint :error-messages="error.start_DateForHalf" readonly
                            v-on="on" color="#F99D20" outlined dense>
                            <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                                <span>{{ error.start_DateForHalf }}</span>
                              </template></v-text-field>
                        </template>
                        <v-date-picker v-model="timeOff.start_DateForHalf" no-title
                          @input="menu4 = false"></v-date-picker>
                      </v-menu> -->
                      <date-picker :class="error.start_DateForHalf ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" v-bind:clearable="false" value-type="format" v-model="timeOff.start_DateForHalf"
                      no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      <span v-if="error.start_DateForHalf" class="error-message ml-2">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.start_DateForHalf }}</span>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      {{ $t("StartTime") }}<span class="red--text">*</span>:
                    </v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="timeOff.start_Time" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="timeOff.start_Time" append-icon="mdi-alarm" class="kanit-medium" readonly
                            :error-messages="error.start_Time" color="#8A8C8E" v-on="on" outlined dense>
                            <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                                <span>{{ error.start_Time }}</span>
                              </template>
                          </v-text-field>
                        </template>
                        <v-time-picker v-if="menu2" v-model="timeOff.start_Time" fluid
                          @click:minute="$refs.menu.save(timeOff.start_Time)"></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      {{ $t("EndTime") }}<span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="timeOff.end_Time" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="timeOff.end_Time" append-icon="mdi-alarm" class="kanit-medium"
                            :error-messages="error.end_Time" readonly dense color="#8A8C8E" v-on="on"
                            outlined>
                            <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" alt="" />
                                <span>{{ error.end_Time }}</span>
                              </template></v-text-field>
                        </template>
                        <v-time-picker v-if="menu3" v-model="timeOff.end_Time" fluid
                          @click:minute="$refs.menu3.save(timeOff.end_Time)"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <v-row class="mt-8">
                  <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                    {{ $t("DescriptionOT") }}<span class="red--text">*</span>:
                  </v-col>
                  <v-col cols="12" xs="3" sm="9" md="10" lg="10" xl="10" class="pb-0">
                    <v-textarea v-model="timeOff.message" :error-messages="error.message"
                    :placeholder="$t('Providefurtherdetailshere')" no-resize rows="3" min-height="70" color="#F99D20"
                      single-line outlined dense required>
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.message }}</span>
                      </template></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                    {{ $t("AttachmentOT") }}:
                  </v-col>
                  <v-col cols="12" xs="3" sm="9" md="10" lg="10" xl="10" class="pb-0">
                    <div>
                      <div v-if="uploaddefaultshow == true" @click="onpickfile" class="drop-area"
                        @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                        <div style="text-align: center">
                          <v-icon color="#A3A7AA">mdi-cloud-upload</v-icon>
                          <p style="color: #a3a7aa">{{ $t("draganddrop") }}</p>
                        </div>
                      </div>
                      <div class="container">
                        <div style="width: 130px; height: 120px; margin-right: 5px" v-if="uploaddefaultshow == false"
                          @click="onpickfile" class="drop-area" @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                          <div style="text-align: center" class="mt-2">
                            <v-icon style="color: #a3a7aa">mdi-cloud-upload</v-icon>
                            <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                          </div>
                        </div>
                        <div v-for="file in uploadedFiles" :key="file.name" class="mr-0 pa-3 relative" style="
                            width: 130px;
                            height: 120px;
                            border: 1px solid #ccc;
                            border-radius: 7px;
                          ">
                          <v-btn @click="removefile(file)" class="close-button top-0 right-0"
                            icon><v-icon>close</v-icon></v-btn>
                          <div @click="showiframe(file)" style="margin-top: -1.9rem">
                            <v-img class="mb-3 ml-5" v-if="file.type == 'image/png'" src="@/assets/images/png.png"
                              width="50px"></v-img>
                            <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpeg'" src="@/assets/images/jpg.png"
                              width="50px"></v-img>
                            <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpg'" src="@/assets/images/jpg.png"
                              width="50px"></v-img>
                            <v-img class="mb-3 ml-5" v-if="file.type == 'application/pdf'" src="@/assets/images/pdf.png"
                              width="50px"></v-img>
                            <div class="text-center">
                              {{
                                file.name
                                ? file.name.substring(0, 10) + " ..."
                                : ""
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none"
                        id="profile_picture" accept=".pdf, .png, .jpeg, .jpg" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0">{{ $t("approver") }}:</v-col>
                  <v-col class="pb-0"> 
                    <v-row>
                      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                        <v-text-field v-model="timeOff.approver" :value="timeOff.approverID"
                          readonly height="10px" color="#F99D20" single-line outlined dense required
                          class="readonly"></v-text-field>
                      </v-col>
                      <!-- <v-col v-if="EditOT.status != 'Draft Request'">
                      <p class="mt-3">{{ EditOT.RequestedDate }}</p>
                    </v-col> -->
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- Action -->
              <v-card-actions class="pb-6" style="width: 100%">
                <v-spacer></v-spacer>
                <v-btn class="mr-2 text-capitalize commoncancelbtn" text outlined @click="opencancel()">
                  {{ $t("Cancel") }}
                </v-btn>
                <v-btn class="text-capitalize commonsavebtn"
                  @click="sentRequest()">
                  {{ $t("Submit") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-col>

        <!-- Leave Balance -->
        <v-col cols="12" xs="12" sm="12" md="12" lg="3">
          <v-card height="65%" min-height="50vh">
            <div style="padding-left: 3%; padding-right: 3%; border-bottom: 1px solid #cfcdcd">
              <div class="d-flex ml-3" style="display:flex;text-align:center;">
                <v-row class="mt-4 mb-4">
                  <img class="mr-3" src="@/assets/images/balance.png" width="20" height="20" />
                  <span style="margin-top: 0px; color: #F99D20" class="pt-0">{{
                    $t("leavebalance") }}</span>
                </v-row>
              </div>
            </div>
            <v-row style="padding-left: 3%; padding-right: 3%; padding-bottom: 3%;">
              <v-data-table class="mt-4 ml-2 mr-2" style="max-height: 430px; overflow-y: auto; width: 100%;"
                :items="leaveDaysList" disable-pagination hide-default-footer hide-default-header :mobile-breakpoint="0">
                <template v-slot:item="{ item }">
                  <tr>
                    <td v-show="!thethaihead">
                      <v-col>
                        {{ item.timeoff_TypeEN }}
                        <p style="color:#7F7F7F">
                          {{ item.availablDays }}
                          <span>
                            {{ $t("daysavailable") }}
                          </span>
                        </p>
                      </v-col>
                    </td>
                    <td v-show="thethaihead">
                      <v-col>
                        {{ item.timeoff_TypeTH }}
                        <p style="color:#7F7F7F">
                          {{ item.availablDays }}
                          <span>
                            {{ $t("daysavailable") }}
                          </span>
                        </p>
                      </v-col>
                    </td>
                    <td style="min-width: 100px">
                      {{ item.leaveDaysLeft }} {{ $t("days") }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-row>
          </v-card>

          <!-- Upcoming Leave -->
          <v-card height="34.2%" min-height="20vh" class="mt-5 upcomingmain">
            <div class="d-flex mb-4 pb-4" style="border-bottom: 1px solid #cfcdcd">
              <span style="padding-top: 20px; color: #F99D20"> <span class="mr-3 mt-2 ml-4"> <img
                    src="@/assets/images/wall-clock.png" width="20" height="20" /></span>
                {{ $t("upcomingLeave") }}
              </span>
              <!-- <div class="circle" style="margin-top:1.5rem;margin-left:1rem;">1</div> upcomingcard -->

              <v-avatar v-if="lengthOfUpcoming > 0" style="margin-top: 22px" class="ml-1" color="orange" size="18">
                <p class="white--text mt-3" style="font-size: 15px">
                  {{ lengthOfUpcoming }}
                </p>
              </v-avatar>
            </div>
            <p v-if="lengthOfUpcoming == 0" style="margin-top: 18px; color: #f99d20; margin-left: 20px;">
              {{ $t("NoUpcomingTimeOff") }}
            </p>
            <div v-if="approverNameList.length">
              <v-row v-for="item in approverNameList" :key="item.id">
                <v-col class="mx-4">
                  <v-card style="border-bottom: 2px solid #f99d20">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle v-show="!thethaihead">{{ item.days_left ? item.days_left : 1 }}
                          {{ $t("DaysOf") }}
                          {{ item.timeoff_Name }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-show="thethaihead">{{ item.days_left ? item.days_left : 1 }}
                          {{ $t("DaysOf") }}
                          {{ item.timeoff_NameTH }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          item.end_Date == "Invalid date"
                          ? item.start_Date
                          : item.start_Date + "-" + item.end_Date
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ $t("ApprovedBy") }}
                          {{ item.approver_Name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <template>
      <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
        style="overflow: hidden">
        <v-card style="
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
          ">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

            <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
          </v-card-actions>

          <v-img :src="imgForshow" max-width="500"></v-img>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
      <v-card style="background-color: white">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn @click="
            printdialogforupdate = false;
          iframeShow = false;
          " icon style="margin-right: 3rem; margin-top: 1rem">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-row>

        <v-card class="mt-4 pdf-container">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Discard Dialog -->
    <v-dialog v-model="DiscardDialog" persistent max-width="400px">
      <v-card style="border-radius: 5px">
        <v-card-text class="pt-6">
          <div style="font-size: 20px;" class="d-flex justify-center Body 1 pt-2">
            {{ $t("discardlist") }}
          </div>
          <p class="d-flex justify-center Body 1 mt-2">{{ $t("listnotsaved") }}</p>
          <div class="d-flex justify-center mb-5 mt-5">
            <v-btn class="text-capitalize mr-2" max-height="35" width="120px" @click="DiscardDialog = false" outlined
              color="#707070">{{
                $t("keepEditing") }}</v-btn>
            <v-btn color="#F99D20" @click="goback" class="mr-2 white--text text-capitalize" max-height="35" width="120px">
              {{ $t("yesDiscard") }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- loading dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
import store from "@/store";
import jsPDF from "jspdf";
import enurl from "@/api/environment";
import ReportTrace from "@/function/Report.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [ReportTrace, utils],
  data: (vm) => ({
    dialogforDownload: false,
    printdialogforupdate: false,
    iframeShow: false,
    dllink: null,
    imgForshow: "",
    imgString: "",
    pdfString: "",
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    uploaddefaultshow: true,
    url: enurl.apiUrl,
    LoadingDialog: false,
    userIditems: ["123456", "327578", "334565", "454778"],
    leaveTypeList: [],
    leaveDaysList: [],
    employeeList: [],
    items: [],
    approverNameList: [],
    selectedFiles: [],
    uploadedFiles: [],
    checkboxallday: true,
    menu: false,
    menu1: false,
    date1: "",
    startdate: "",
    lengthOfUpcoming: null,
    sentReq: false,
    halfdate: "",
    menu4: "",
    menu2: false,
    menu3: false,
    tempvalid: "",
    error: {
      start_DateForHalf: "",
      timeoff_ID: "",
      start_Date: "",
      end_Date: "",
      start_Time: "",
      end_Time: "",
      message: "",
      employee: "",
    },
    timeOff: {
      start_DateForHalf: "",
      timeoff_ID: 0,
      wholeDay: true,
      balance: "",
      start_Date: "",
      end_Date: "",
      start_Time: "",
      end_Time: "",
      message: "",
      saveDraft: false,
      employee: "",
      position_Name: "",
      approver: null,
      approverID: null,
      company_ID: store.state.companyID,
      requested_Date: new Date(),
      attachment: [],
      requested_Datetxt: ""
    },
    approverID: 0,
    menu7: false,
    menu8: false,
    thelang: "",
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    DiscardDialog: false,
  }),

  watch: {
    thelang() {
      this.whatthehead();
    },
    "timeOff.employee"(v) {
      if (v) this.error.employee = "";
    },
    "timeOff.message"(v) {
      if (v) this.error.message = "";
    },
    "timeOff.end_Time"(v) {
      if (v) this.error.end_Time = "";
    },
    "timeOff.start_Time"(v) {
      if (v) this.error.start_Time = "";
    },
    "timeOff.end_Date"(v) {
      if (v) this.error.end_Date = "";
    },
    "timeOff.start_Date"(v) {
      if (v) this.error.start_Date = "";
      if (new Date(v) > new Date(this.timeOff.end_Date))
      {
        this.timeOff.end_Date = null;
      }
    },
    "timeOff.timeoff_ID"(v) {
      if (v) this.error.timeoff_ID = "";
    },
    "timeOff.start_DateForHalf"(v) {
      if (v) this.error.start_DateForHalf = "";
    },
  },

  mounted() {
    this.GetLeaveTypeList();
    this.GetEmployeeList();
    let d = new Date();
    this.timeOff.requested_Datetxt = this.formatDateRD(d);
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem("Templang");
    }, 100);
    this.sendNotification();
  },

  computed: {
    thethaihead() {
      return this.whatthehead();
    },
    computedDateFormatted() {
      return this.formatDate(this.timeOff.start_Date);
    },
    computedDateFormatted1() {
      return this.formatDate(this.timeOff.end_Date);
    },
    computedDateFormatted3() {
      return this.formatDate(this.timeOff.start_DateForHalf);
    },
  },

  methods: {
    allowedEndDates(date) {
      if (!this.timeOff.start_Date)
      {
        return false; // No start date selected, all dates are enabled
      }
       // Parse the start_Date string (format: DD/MM/YYYY) to a Date object
      const [day, month, year] = this.timeOff.start_Date.split('/');
      const startDate = new Date(year, month - 1, day);
      startDate.setHours(0, 0, 0, 0); // Reset time part for comparison
      return date < startDate; // Disable dates before start date
    },

    goback() {
      this.$router.push({ name: "EmployeesTimeOffRequest" });
    },

    opencancel() {
      this.DiscardDialog = true;
    },

    GetLeaveTypeWithRemainingDays(id) {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
        employee_ID: id,
      };
      Axios.post(`${self.url}TimeOff_Request/GetLeaveWithRemainingDays`, tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.leaveDaysList = response.data.data;
          self.timeOff.approver = self.leaveDaysList[0].approve_Name;
          self.timeOff.approverID = self.leaveDaysList[0].approver_ID;
          self.GetDateApproverName(self.timeOff.employee);
          self.LoadingDialog = false;
        }
      })
      .catch(function (error) {
        alert(error);
        self.LoadingDialog = false;
      });
    },

    chooseemp() {
      let self = this;
      if (self.timeOff.employee != null)
      {
        let result = self.employeeList.filter(
          (x) => x.employee_ID === self.timeOff.employee
        );
        self.timeOff.position_Name = result[0].position_Name;
        self.GetLeaveTypeWithRemainingDays(result[0].employee_ID);
        //self.Getspecificapprovername(self.timeOff.employee);

      }
    },

    Getspecificapprovername(empID) {
      let self = this;
      let temp = {
        employee_ID: empID,
        company_ID: store.state.companyID,
      };
      Axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          let approverlist = response.data.data;
          self.timeOff.approver = approverlist[0].reportName;
          self.timeOff.approverID = approverlist[0].report_ID;
        }
      }
      );
    },

    formatDateTime(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      // const hours = String(date.getHours()).padStart(2, "0");
      // const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}/${month}/${year}`;
    },

    handleFileDrop(event) {
      event.preventDefault();
      this.handleFiledrag(event);
    },

    whatthehead() {
      if (this.thelang == "ประเทศไทย")
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    onpickfile() {
      this.$refs.fileupload.click();
    },

    async handleFileSelect(event) {
      let self = this;
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) 
      {
        if (file.type === "application/pdf") 
        {
          pdfString = URL.createObjectURL(file);
        } 
        else if (file.type.startsWith("image/")) 
        {
          pdfString = URL.createObjectURL(file);
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          self.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    async handleFiledrag(event) {
      let self = this;
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) 
      {
        if (file.type === "application/pdf") 
        {
          pdfString = URL.createObjectURL(file);
        } 
        else if (file.type.startsWith("image/")) 
        {
          pdfString = URL.createObjectURL(file);
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          self.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    uploadFilesall(imgname, type, url, pdfurl) {
      let self = this;
      let plusid = self.uploadedFiles.length;
      self.uploadedFiles.push({
        id: plusid,
        name: imgname,
        type: type,
        imgurl: url,
        pdfurl: pdfurl,
      });
      self.uploaddefaultshow = false;
    },

    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },

    base64ToBlobforimg(base64String, contentType) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) 
      {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) 
        {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },

    async convertBase64ImageToPdf(base64Image) {
      const pdf = new jsPDF();
      const blob = this.base64ToBlobforimg(base64Image, "image/jpeg"); // Adjust content type if needed
      const imgData = URL.createObjectURL(blob);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed
      const pdfData = pdf.output("blob");
      return pdfData;
    },

    async convertImageToPdf(selectedFile) {
      const pdf = new jsPDF();
      const imgData = await this.readFileAsDataUrl(selectedFile);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed
      const pdfData = pdf.output("blob");
      return pdfData;
    },

    readFileAsDataUrl(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },

    removefile(file) {
      let self = this;
      const index = self.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1) 
      {
        self.uploadedFiles.splice(index, 1);
      }
      if (self.uploadedFiles.length == 0) 
      {
        self.uploaddefaultshow = true;
      }
    },

    removefileEdit(file) {
      let self = this;
      const index = self.EditOT.attachmentlist.findIndex((item) => item.id === file.id);
      if (index !== -1) 
      {
        self.EditOT.attachmentlist.splice(index, 1);
      }
      if (self.EditOT.attachmentlist.length == 0) 
      {
        self.uploaddefaultshow = true;
      }
    },

    showiframe(item) {
      let self = this;
      if (
        item.type == "image/png" ||
        item.type == "image/jpeg" ||
        item.type == "image/jpg"
      ) 
      {
        self.imgForshow = "data:image/png;base64," + item.imgurl;
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
      } 
      else 
      {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }
    },

    async showiframeforDetail(item) {
      let self = this;
      if (item.filetype == "pdf") {
        const blob = await self.base64ToBlob(
          item.attachment_file,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      } 
      else 
      {
        self.dialogforDownload = true;
        self.imgForshow = "data:image/png;base64," + item.attachment_file;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },

    GetEmployeeList() {
      let self = this;
      self.LoadingDialog = true;
      Axios.get(`${self.url}Confirmemployee/GetConfirmEmployeeByCompanyID?Company_ID=${store.state.companyID}`)
      .then(function (response) {
        let temp = response.data.data;
        self.LoadingDialog = false;
        self.employeeList = temp;
        for (var i = 0; i < self.employeeList.length; i++) 
        {
          if (self.employeeList[i].profile_Pic == null) 
          {
            self.employeeList[i].profile_Pic = "https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group%203166.png";
          }
        }
        self.employeeList.profile_Pic = "data:image/png;base64," + self.employeeList.profile_Pic;
      });
    },

    GetLeaveTypeList() {
      try {
        let self = this;
        let temp = {
          company_ID: store.state.companyID,
        };
        Axios.post(`${self.url}TimeOff/GetLeaveTypeByCompanyID`, temp)
        .then(function (response) {
          let temp = response.data.data;
          self.leaveTypeList = temp.filter((v) => v.leaveStatus == true);
        })
      }
      catch (ex) {
        throw ex;
      }
    },

    async sendNotification() {
      let self = this;
      let TempReportID = null;
      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}PersonalInfo/GetShortInfo`,userInfoTemp)
      .then(function (response) {
        var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
        var reportID = response.data.data[0].report_ID;
        self.username = username;
        self.reportID = reportID;
        TempReportID = reportID;
      });
      if (TempReportID) 
      {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await Axios.post(`${self.url}Firebasetoken/GetFirebaseTokenByUserID`,temp)
        .then(function (response) {
          var temp = response.data.data;
          if (temp[0]) 
          {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });
        self.tokenList = token;
      }
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}OT_Request/GetworkFlowOfOTRequest`,temp)
      .then(function (response) {
      if (response.data.status === 0) 
      {
        let WF_ID = response.data.data.wF_ID;
        let senderID = response.data.data.requester_ID;
        let recipientID = response.data.data.approver_ID;
        self.newnotiList(WF_ID, senderID, recipientID);
      }
      });
    },

    newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for Time Off`,
      };
      Axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp).then(
        function (response) {
          if (response.data.status == 0)
          {
            self.pushNotification(recipientID);
          }
        }
      );
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Timeoff Request",
        body: "Timeoff Request from " + self.username,
        reportID: recipientID,
      };
      await Axios.post(`${this.url}Firebasetoken/sendNotification`,noti_temp).then(function () { });
    },

    wholdDayCheck() {
      this.clearData(this.error);
    },

    formatDate(date) 
    {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    async GetPosition() {
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
        };
        const response = await Axios.post(`${this.url}TimeOff_Request/GetByPositionID`,temp);
        this.timeTypeList = response.data.data;
      } catch (ex) {
        throw ex;
      }
    },

    async GetReport() {
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
        };
        const response = await Axios.post(`${this.url}TimeOff_Request/GetReporter`,temp);
        let list = response.data.data;
        let { employee_ID, name, lastName } = list;
        this.timeOff.approver_ID = employee_ID;
        this.timeOff.report_Name = `${name} ${lastName}`;
      } catch (ex) {
        throw ex;
      }
    },

    checkDaysLeft() {
      let self = this;
      let result = false;
      let takendays = self.getDays();
      let theone = self.leaveDaysList.filter((v) => v.timeOff_ID == self.timeOff.timeoff_ID);
      if (theone[0].availablDays > takendays) 
      {
        result = true;
        self.timeOff.balance = (theone[0].availablDays - takendays).toString();
      }
      return result;
    },

    getDays() {
      if (this.timeOff.wholeDay)
      {
        const [startDay, startMonth, startYear] = this.timeOff.start_Date.split('/').map(Number);
        const [endDay, endMonth, endYear] = this.timeOff.end_Date.split('/').map(Number);

        // Create Date objects with the parsed components
        const sd = new Date(startYear, startMonth - 1, startDay); // Month is 0-based in JavaScript Date
        const ed = new Date(endYear, endMonth - 1, endDay);

        // Calculate the time difference in milliseconds
        const timeDifference = ed.getTime() - sd.getTime();
        
        // Calculate the number of days by dividing the time difference by milliseconds in a day
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Since it's whole day, we include both the start and end dates
        return daysDifference + 1;
        // let sd = new Date(this.timeOff.start_Date);
        // let ed = new Date(this.timeOff.end_Date);
        // // Convert both dates to UTC to ensure consistent calculation
        // const startUtc = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        // const endUtc = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());

        // // Calculate the time difference in milliseconds
        // const timeDifference = endUtc - startUtc;

        // // Calculate the number of days by dividing the time difference by milliseconds in a day
        // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // return daysDifference + 1;
      } 
      else 
      {
        return 0.5;
      }
    },

    async sentRequest() {
      try {
        let self = this;
        if (self.uploadedFiles.length) 
        {
          for (var i = 0; i < self.uploadedFiles.length; i++) 
          {
            self.timeOff.attachment.push({
              attachment_file: self.uploadedFiles[i].imgurl,
              filename: self.uploadedFiles[i].name,
              filetype: self.uploadedFiles[i].type,
            });
          }
        }

        // self.uploadedFiles = null;
        let startDate = self.$moment(this.timeOff.start_Date, 'DD/MM/YYYY');
        let endDate = self.$moment(this.timeOff.end_Date, 'DD/MM/YYYY');
        self.timeOff["saveDraft"] = false;
        self.error.employee = !self.timeOff.employee ? self.$t("PleaseSpecify") : "";
        self.error.message = !self.timeOff.message ? self.$t("PleaseSpecify") : "";
        self.error.timeoff_ID = !self.timeOff.timeoff_ID ? self.$t("PleaseSpecify") : "";
        if (self.timeOff.wholeDay)
        {
          self.error.start_Date = !self.timeOff.start_Date ? self.$t("PleaseSpecify") : "";
          self.error.end_Date = !self.timeOff.end_Date ? self.$t("PleaseSpecify") : "";
          let tempvalid = self.ValidateAddTrue();
          if (tempvalid) 
          {
            if (self.timeOff.approver) 
            {
              if ((startDate).isSameOrBefore((endDate)))
              {
                let anyleft = self.checkDaysLeft();
                if (anyleft) 
                {
                  let temp = {
                    timeoff_ID: self.timeOff.timeoff_ID,
                    wholeDay: self.timeOff.wholeDay,
                    // start_Date: self.timeOff.start_Date,
                    // end_Date: self.timeOff.end_Date,
                    start_Date: startDate.format('YYYY-MM-DD'),
                    end_Date: endDate.format('YYYY-MM-DD'),
                    requested_Date: self.timeOff.requested_Date,
                    start_Time: self.timeOff.start_Time,
                    end_Time: self.timeOff.end_Time,
                    message: self.timeOff.message,
                    saveDraft: false,
                    requester_ID: self.timeOff.employee,
                    approver_ID: self.timeOff.approverID,
                    company_ID: store.state.companyID,
                    workFlowType: "TimeOff",
                    attachments: self.timeOff.attachment,
                    balance: self.timeOff.balance
                  };
                  self.LoadingDialog = true;
                  const respone = await Axios.post(`${self.url}TimeOff_Request/AddTimeOff_RequestForEmp`,temp);
                  if(respone.data.status !== 0) 
                  {
                    self.LoadingDialog = false;
                    alert("Please recheck the date since there seems to be a clash with another request");
                  } 
                  else 
                  {
                    alert(respone.data.message);
                    self.LoadingDialog = false;
                    self.ReportTrace(6);
                    self.timeOff.timeoff_ID = 0;
                    self.timeOff.start_Date = "";
                    self.timeOff.end_Date = "";
                    self.timeOff.employee = "";
                    self.timeOff.start_Time = "";
                    self.timeOff.end_Time = "";
                    self.timeOff.message = "";
                    self.timeOff.attachments = [];
                    self.timeOff.wholeDay = true;
                    self.goback();
                  }
                }
                else
                {
                  alert("There is no days left for selected Leave Type");
                }
              }
              else
              {
                alert("Start date can’t be after end date");
              }
            }
            else
            {
              alert("Please assign approver");
            }
          } 
          else 
          {
            self.ValidateAddFalse();
          }
        } 
        else 
        {
          self.error.start_Time = !self.timeOff.start_Time ? self.$t("PleaseSpecify") : "";
          self.error.end_Time = !self.timeOff.end_Time ? self.$t("PleaseSpecify") : "";
          self.error.start_DateForHalf = !self.timeOff.start_DateForHalf ? self.$t("PleaseSpecify") : "";
          let tempvalid = self.ValidateAddHalf();
          if (tempvalid) 
          {
            if (self.timeOff.approver) 
            {
              let start_Time = self.$moment(self.timeOff.start_Time, "h:mma");
              let end_time = self.$moment(self.timeOff.end_Time, "h:mma");
              if (start_Time.isBefore(end_time)) 
              {
                let anyleft = self.checkDaysLeft();
                if (anyleft) 
                {
                  let temp = {
                    timeoff_ID: self.timeOff.timeoff_ID,
                    wholeDay: self.timeOff.wholeDay,
                    // start_Date: self.timeOff.start_DateForHalf,
                    // end_Date: self.timeOff.start_DateForHalf,
                    // start_Date: startDate.format('YYYY-MM-DD'),
                    // end_Date: endDate.format('YYYY-MM-DD'),
                    //requested_Date: self.timeOff.start_DateForHalf,
                    start_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    end_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    requested_Date: this.$moment(self.timeOff.start_DateForHalf, 'DD/MM/YYYY').toISOString(),
                    start_Time: self.timeOff.start_Time,
                    end_Time: self.timeOff.end_Time,
                    message: self.timeOff.message,
                    saveDraft: false,
                    requester_ID: self.timeOff.employee,
                    approver_ID: self.timeOff.approverID,
                    company_ID: store.state.companyID,
                    workFlowType: "TimeOff",
                    attachments: self.timeOff.attachment,
                    balance: self.timeOff.balance
                  };
                  const respone = await Axios.post(`${self.url}TimeOff_Request/AddTimeOff_RequestForEmp`,temp);
                  if (respone.data.status !== 0) 
                  {
                    self.LoadingDialog = false;
                    alert("Please recheck the date since there seems to be a clash with another request");
                  } 
                  else 
                  {
                    self.LoadingDialog = false;
                    alert(respone.data.message);
                    self.ReportTrace(6);
                    self.timeOff.timeoff_ID = 0;
                    self.timeOff.start_Date = "";
                    self.timeOff.end_Date = "";
                    self.timeOff.employee = "";
                    self.timeOff.start_Time = "";
                    self.timeOff.end_Time = "";
                    self.timeOff.message = "";
                    self.timeOff.attachments = [];
                    self.timeOff.wholeDay = true;
                    self.goback();
                  }
                } 
                else 
                {
                  alert("There is no days left for selected Leave Type");
                }
              } 
              else 
              {
                alert("Start time can’t be after end time");
              }
            } 
            else 
            {
              alert("Please assign approver");
            }
          } 
          else 
          {
            self.ValidateAddHalfFalse();
          }
        }
      } catch (ex) {
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    ValidateAddTrue() {
      if (this.timeOff.wholeDay) 
      {
        if (this.timeOff.timeoff_ID != 0 &&
          this.timeOff.start_Date != null &&
          this.timeOff.end_Date != null &&
          this.timeOff.message != null &&
          this.timeOff.timeoff_ID != "" &&
          this.timeOff.start_Date != "" &&
          this.timeOff.end_Date != "" &&
          this.timeOff.message != "") 
        {
          return true;
        } 
        else 
        {
          return false;
        }
      }
    },

    ValidateAddHalf() {
      if (!this.timeOff.wholeDay)
      {
        if (this.timeOff.timeoff_ID != 0 &&
          this.timeOff.start_DateForHalf != null &&
          this.timeOff.start_Time != null &&
          this.timeOff.end_Time != null &&
          this.timeOff.message != null &&
          this.timeOff.start_DateForHalf != "" &&
          this.timeOff.start_Time != "" &&
          this.timeOff.end_Time != "" &&
          this.timeOff.message != "") 
        {
          return true;
        } 
        else 
        {
          return false;
        }
      }
    },

    ValidateAddFalse() {
      if (this.timeOff.timeoff_ID == 0 ||
        this.timeOff.start_Date == null ||
        this.timeOff.end_Date == null ||
        this.timeOff.message == null ||
        this.timeOff.start_Date == "" ||
        this.timeOff.end_Date == "" ||
        this.timeOff.message == "") 
      {
        alert("Please fill in the required fields!");
      }
    },

    ValidateAddHalfFalse() {
      if (this.timeOff.timeoff_ID == 0 ||
        this.timeOff.start_DateForHalf == null ||
        this.timeOff.start_Time == null ||
        this.timeOff.end_Time == null ||
        this.timeOff.message == null) 
      {
        alert("Please fill in the required fields!");
      }
    },

    clearData(obj = {}, defaultValue = null) {
      for (const key in obj) {
        obj[key] = defaultValue;
      }
    },

    async onInputHandler(id, timeTypeList) {
      this.sentReq = false;
      const filter = timeTypeList.find((x) => x.timeoff_ID == id);
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          timeoff_ID: filter.timeoff_ID,
        };
        const respone = await Axios.post(`${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyIdTimeOffId`, temp);
        if(respone.data.status === 0)
        {
          let date = respone.data.data;
          let availability_Days = filter.availability_Days;
          if (availability_Days == date.avalable_Day || availability_Days < date.avalable_Day) 
          {
            this.timeOff.balance = 0;
            this.sentReq = true;
          } 
          else 
          {
            let result = availability_Days - date.avalable_Day;
            result = String(result);
            if (result == 0.5) 
            {
              this.timeOff.balance = "Half";
              this.isHalf = 0.5;
            } 
            else if (result.match(/\./)) 
            {
              let final = result.split(".")[0];
              this.timeOff.balance = final + " and half";
              this.isHalf = +final + 0.5;
            } 
            else 
            {
              this.timeOff.balance = result;
              this.isHalf = result;
            }
          }
        }
      } catch (ex) {
        throw ex;
      }
    },

    async GetDateApproverName(eid) {
      try {
        let self = this;
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: eid,
          requester_ID: self.timeOff.approverID,
        };
        const respone = await Axios.post(`${self.url}TimeOff_Request/GetTimeOff_ForApprove`,temp);
        if(respone.data.status === 0)
        {
          self.approverNameList = respone.data.data;
          self.approverNameList.map((x) => {
            x.start_Date = self.$moment(x.start_Date)
              .format("DD/MMM/YYYY")
              .split("/")
              .join(" ");
            x.end_Date = self.$moment(x.end_Date)
              .format("DD/MMM/YYYY")
              .split("/")
              .join(" ");
            x.days_left = x.days_left == 0.5 ? "Half" : x.days_left;
          });
          self.lengthOfUpcoming = self.approverNameList.length;
        }
      }
      catch (ex)
      {
        throw ex;
      }
    },

    formatDateRD(d) {
      let td = new Date(d);
      const formattedDate = `${this.padZero(td.getDate())}/${this.padZero(td.getMonth() + 1)}/${td.getFullYear()} ${this.padZero(td.getHours())}:${this.padZero(td.getMinutes())}`;
      return formattedDate;
    },

    padZero(num) {
      return num.toString().padStart(2, '0');
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

.pdf-container {
  background-color: #aaa9ae;
  margin-left: 3rem;
  margin-right: 3rem;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}

.close-button {
  position: relative;
  top: -15px;
  left: 80px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.image-iframe {
  width: 400px;
  /* Set your desired width */
  height: 400px;
  /* Set your desired height */
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  font-size: 10px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  background: #F99D20
}

.upcomingcard {
  overflow-y: auto;
  overflow-x: hidden;
}

.upcomingmain {
  overflow-x: hidden;
}
</style>