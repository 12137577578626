<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem;margin-bottom: 3rem">
    <p class="txt-header">
      {{ $t("StaffTimeAttendance") }}
    </p>

    <v-tabs v-model="model" class="mt-6" grey--text background-color="#f0f8fc" show-arrows slider-color="#F99D20">
      <v-tab class="text-capitalize" style="border-radius: 10px 10px 5px 5px; width: 160px; font-size: 16px"
        @click="showme1">
        {{ $t("DayView") }}
      </v-tab>
      <v-tab class="text-capitalize" style="border-radius: 10px 10px 5px 5px; width: 160px; font-size: 16px"
        @click="showme2">
        {{ $t("MonthView") }}
      </v-tab>

      <v-tab-item>
        <v-card class="pt-5">
          <v-row style="margin-left: 2rem; margin-right: 0rem" class="fixed-lg">
            <v-col class="ml-0 mt-1 depart_text" cols="12" xs="12" sm="3" md="3" lg="3" xl="2">
              <p class="ml-0 mt-1">{{ $t("SraechCri") }}</p>
              <v-autocomplete v-model="SearchWithCrit" :items="CriteriaOption" item-text="crittxt" item-value="critval"
                color="#F99D20" class="text" clearable outlined style="
                          border-radius: 5px;
                          margin-top: -1rem;
                          margin-right: 1rem;
                        " dense v-on:change="changesearch(SearchWithCrit)">
                <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
              </v-autocomplete>
            </v-col>
            <v-col class="mr-4" cols="12" xs="6" sm="8" md="8" lg="4" xl="4">
              <v-row>
                <v-col cols="12" xs="12" sm="8" md="8" lg="8">
                  <v-text-field class="movetop" :placeholder="$t('Search')" v-model="searchtxt" outlined style="
                              border-radius: 5px;
                              margin-top: 2rem;
                              margin-right: 1rem;
                            " color="#F99D20" dense></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="4" md="4" lg="4">
                  <v-btn color="#F99D20" @click="SearchDaily()" class="white--text text-capitalize mb-2 movetop"
                    width="120" max-height="35" style="margin-top: 2.1rem">
                    <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="mt-2 mr-3" cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
              <p class="ml-0 mt-0">{{ $t("TimeType") }}</p>
              <v-autocomplete v-model="FilterDay" :items="TimeOption" item-text="time" item-value="timeval"
                color="#F99D20" class="text" clearable outlined
                style="min-width: 140px; border-radius: 5px; margin-top: -1rem" dense
                v-on:change="filtertable(FilterDay)">
                <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
              </v-autocomplete>
            </v-col>
            <v-col class="mt-2 mr-3" cols="12" xs="12" sm="3" md="3" lg="2" xl="2">
              <p class="ml-0 mt-0">{{ $t("TimeAttent") }}</p>
              <v-autocomplete v-model="FilterDayAttendance" :items="AttendanceOption" item-text="time"
                item-value="timeval" color="#F99D20" class="text" clearable outlined
                style="min-width: 140px; border-radius: 5px; margin-top: -1rem" dense
                v-on:change="filtertable(FilterDay)">
                <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
              </v-autocomplete>
            </v-col>
            <v-col class="ml-3 moveleft" cols="12" md="5" lg="3" xl="2" sm="5" xs="2">
              <div class="d-flex moveleft gobelow">
                <v-btn class="white--text text-capitalize mr-3 mt-1" @click="yesterday()" color="#F99D20" small
                  style="min-width: 10px; max-width: 30px">
                  <v-icon small>mdi-chevron-left</v-icon>
                </v-btn>
                <date-picker class="customicon-vuedatepicker" v-bind:clearable="false" v-model="gettodaydate" format="DD/MM/YYYY" :disabled-date="(date) => date > new Date()"
                  placeholder="DD/MM/YYYY" @change="GetDayList(1)">
                  <!-- <template slot="icon-calendar">
                    <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                  </template> -->
                </date-picker>

                <v-btn class="white--text text-capitalize ml-3 mt-1" @click="tomorrow()" :disabled="disforfuture" small
                  color="#F99D20" style="min-width: 10px; max-width: 30px">
                  <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col class="mr-15 mvl" cols="12" xs="12" sm="2" md="2" lg="1" xl="1">
              <div v-if="permission.export == false">
                <!-- <v-btn
                  right
                  class="approve_btn green--text text-capitalize fixed-btn-align"
                  color="#2E7D32"
                  width="160"
                  max-height="34"
                  outlined
                  @click="Openexportdailydialog"
                >
                  <img src="@/assets/images/excel.png" class="pr-2" />
                  {{ $t("exportExcel") }}
                </v-btn> -->
                <v-btn color="#F99D20" class="
                            white--text
                            text-capitalize
                            ml-5
                            rright
                            approve_btn
                            mb-5
                          " style="font-weight: normal" block max-height="35" @click="Openexportdailydialog">
                  <img src="@/assets/images/Group 8613.png" class="pr-2" />
                  {{ $t("ExportFile") }}
                </v-btn>
              </div>
            </v-col>
            <v-col v-show="fornow" class="mr-4 ml-2 mvl" cols="12" xs="12" sm="3" md="2" lg="2" xl="1">
              <div v-if="permission.approve == false">
                <v-btn color="#F99D20" class="
                            white--text
                            text-capitalize
                            ml-5
                            rright
                            approve_btn
                            mb-5
                          " style="font-weight: normal" block max-height="35" @click="OpenApprovedialog()">
                  <img src="@/assets/images/correct.png" class="pr-2" />
                  {{ $t("Approve") }}
                </v-btn>
              </div>
            </v-col>
            <v-col v-show="fornow" class="ml-2 mvl" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <div>
                <v-btn right class="
                            white--text
                            text-capitalize
                            approve_btn
                            lleft
                            ml-5
                            mb-5
                          " color="#F99D20" width="250" max-height="34" block style="font-weight: normal"
                  @click="UpdateAllIrregularForToday">
                  {{ $t("acceptallcinout") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div class="mt-5">
            <v-data-table style="margin-left: 2rem; margin-right: 2rem" :items="DayViewListForReal" hide-default-footer
              hide-default-header :page.sync="page" :items-per-page="itemsPerPage" :mobile-breakpoint="0">
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th style="max-width: 50px">
                      <!-- <label class="form-checkbox ml-1">
                        <input
                          type="checkbox"
                          v-model="selectAll"
                          @click="select"
                        />
                        <i class="form-icon"></i>
                      </label> -->
                    </th>
                    <th style="width: 101px">{{ $t("EmployeeID") }}</th>
                    <th>{{ $t("department") }}</th>
                    <th>{{ $t("employee_name") }}</th>
                    <th>{{ $t("shiftStartTime") }}</th>
                    <th>{{ $t("shiftEndTime") }}</th>
                    <th>{{ $t("otStartTime") }}</th>
                    <th>{{ $t("otEndTime") }}</th>
                    <th>{{ $t("clockIn") }}</th>
                    <th>{{ $t("clockOut") }}</th>
                    <th>{{ $t("otClockIn") }}</th>
                    <th>{{ $t("otClockOut") }}</th>
                    <th>{{ $t("Remark") }}</th>
                    <th>{{ $t("AddTime") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr :style="{
                  color: ShiftVali(item) == true ? '#46D95A' : ' #FF4B55',}" style="cursor: pointer">
                  <td style="text-align: center">
                    <v-checkbox class="custom-checkbox mb-5" 
                    :value="{
                        empid: item.employee_ID,
                        clkid: item.clocklist,
                        empcde: item.employee_Code,
                        apv: item.approve,
                        cond: item.yescondi,
                        begin: item.start_Time,
                        end: item.end_Time,
                        intime: item.clock_In_Time,
                        outtime: item.clock_Out_Time,
                        thisday: item.timeEntry_Date,
                        tid: item.time_ID,
                      }" 
                    v-model="selected" 
                    color="#F89D1F" hide-details></v-checkbox>
                    <!-- <label class="form-checkbox">
                      <input type="checkbox" :value="{
                        empid: item.employee_ID,
                        clkid: item.clocklist,
                        empcde: item.employee_Code,
                        apv: item.approve,
                        cond: item.yescondi,
                        begin: item.start_Time,
                        end: item.end_Time,
                        intime: item.clock_In_Time,
                        outtime: item.clock_Out_Time,
                        thisday: item.timeEntry_Date,
                        tid: item.time_ID,
                      }" v-model="selected"/>
                      <i class="form-icon"></i>
                    </label> -->
                  </td>
                  <td @click="handleclick(item)">{{ item.employee_Code }}</td>
                  <td @click="handleclick(item)">{{ item.department_Name }}</td>
                  <td @click="handleclick(item)">{{ item.fullName }}</td>
                  <td @click="handleclick(item)">{{ item.start_Time }}</td>
                  <td @click="handleclick(item)">{{ item.end_Time }}</td>
                  <td @click="handleclick(item)">{{ item.oT_Start_Time }}</td>
                  <td @click="handleclick(item)">{{ item.oT_End_Time }}</td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.clock_In_Time) }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.clock_Out_Time) }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.oT_Clock_In_Time) }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.oT_Clock_Out_Time) }}
                  </td>
                  <td v-show="!thethaihead" @click="handleclick(item)">{{ item.remark }}</td>
                  <td v-show="thethaihead" @click="handleclick(item)">{{ item.remarkTH }}</td>
                  <td>
                    <v-btn :disabled="item.aprvd" icon>
                      <img src="@/assets/images/Icon ionic-ios-add-circle-outline.png" @click="OpenAddTimeDialog(item)"
                        class="mr-2 mb-1" />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right" v-if="DayViewListForReal.length">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" @input="GetDayList(1)"></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                    :items="items" @input="itemsPerPage = parseInt($event, 10)" v-on:change="GetDayList(3)"></v-select>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- synctimedatadialog -->
          <v-dialog v-model="synctimedatadialog" max-width="786">
            <v-card style="border-radius: 15px">
              <p class="pt-5" style="font-size: 23px; margin-left: 1.5rem; margin-right: 2rem">
                {{ $t("SyncTimeData") }}
              </p>
              <v-card-text>
                <v-row>
                  <v-col style="margin-left: -30px; margin-top: -20px" cols="12" md="12">
                    <v-row v-show="showup">
                      <v-col cols="4">
                        <input type="file" id="fileInputButton" name="fileInputButton" class="mt-2 ml-4"
                          @change="loadCSV($event)" />
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon>
                          <img width="20" height="20" @click="closeinput" src="@/assets/closered.png" alt />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col style="margin-left: -30px; margin-top: -20px" cols="12" md="12">
                    <v-row v-show="showjarviz">
                      <v-col cols="4">
                        <v-file-input placeholder="No file chosen" color="white" id="fileInputButtonJarviz"
                          v-model="jarvizfile" prepend-icon class="mt-2 ml-7" accept=".xlsx">
                          <template v-slot:append>
                            <v-btn @click="closeinput" icon>
                              <img width="20" height="20" src="@/assets/closered.png" alt />
                            </v-btn>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-btn color="#ACACAC" class="white--text text-capitalize" width="150" max-height="35"
                      @click="getupload">
                      {{ $t("browse") }}</v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p class="mt-2" style="color: #f74747">
                      ** {{ $t("ThisFileTypeCSVOnly.") }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn color="#F99D20" class="white--text text-capitalize" width="150" max-height="35"
                      @click="getimportjarviz">
                      Import from Jarviz</v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
                <v-row>
                  <v-col cols="12" md="4">
                    <p>{{ $t("DocumentTemplateCSV") }}</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <a href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sync Time for Day view.csv"
                      style="color: #f99d20">
                      {{ $t("download") }}</a>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />
                <br />
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-spacer></v-spacer>
                <v-btn class="mr-2 text-capitalize" width="120" height="35" text @click="closeSyncDialog">{{ $t("Cancel")
                }}</v-btn>
                <v-btn v-show="upload" style="background-color: #f99d20" width="120" height="35"
                  class="mr-2 white--text text-capitalize" @click="UploadSyncTime" text>{{ $t("upload") }}</v-btn>
                <v-btn v-show="!upload" style="background-color: #f99d20" width="120" height="35"
                  class="mr-2 white--text text-capitalize" @click="ImportFromJarvizTimeAttendance" text>{{ $t("upload")
                  }}</v-btn>
              </v-card-actions>
              <br />
            </v-card>
          </v-dialog>
          
          <!-- timeoff dialog -->
          <v-dialog v-model="timeoffdialog" persistent max-width="786" class="timeoffdialog">
            <v-card style="border-radius: 15px">
              <v-row>
                <v-col cols="5">
                  <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
                    {{ $t("AddTimeOff") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn class="ma-5 pa-4 text-capitalize" outlined color="#F99D20" @click="opentimedialog()">
                    <v-icon> mdi-clock-outline </v-icon>
                    <p class="mt-4 ml-2">{{ $t("AddTimeClockIn/Out") }}</p>
                  </v-btn>
                </v-col>
              </v-row>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field autocomplete="off" color="#F99D20" v-model="timetoAdd.TheName" readonly>
                      <template v-slot:label>
                        <span> {{ $t("emp") }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6">
                    <v-select :items="LeaveList" :item-text="(item) => thethaihead == true ? `${item.timeoff_TypeTH}` : `${item.timeoff_TypeEN}`" item-value="timeOff_ID"
                      v-model="timeoffAdd.TimeoffID" append-icon="mdi-chevron-down" color="#F89D1F">
                      
                      <template v-slot:label>
                        <span>
                          {{ $t("TimeOffType") }}<span class="red--text"> *</span></span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="6">
                    <div>
                      <!-- <input v-model="timeoffAdd.Alldaycheck" type="checkbox" style="margin-top: 2rem !important" /> -->
                      <v-checkbox
                      v-model="timeoffAdd.Alldaycheck"
                      color="#F89D1F"
                      :label="timeoffAdd.Alldaycheck ?  $t('Allday')  :  $t('halfDay')"
                      class="custom-checkbox shrink mr-0 mt-4"
                    ></v-checkbox>
                      <!-- <label> {{ $t("Allday") }}</label> -->
                    </div>
                  </v-col>
                </v-row>
                <v-row v-show="timeoffAdd.Alldaycheck" class="mb-4">
                  <v-col md="6">
                    <label>
                      {{ $t("startDate") }}<span class="red--text"> *</span>
                    </label>
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" v-model="timeoffAdd.StartDate" value-type="format"
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                  <v-col md="6">
                    <label>
                      {{ $t("enddata") }}<span class="red--text"> *</span>
                    </label>
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" v-model="timeoffAdd.EndDate" value-type="format"
                    :disabled-date="allowedEndDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>

                <v-row v-show="!timeoffAdd.Alldaycheck" class="mb-5">
                  <v-col md="6">
                    <label>
                      {{ $t("Date") }}<span class="red--text"> *</span>
                    </label>
                    <!-- <v-menu v-model="menunonecheck" :close-on-content-click="false" transition="scale-transition" offset-y
                      fluid max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" v-model="computedDateFormattedTimeoffAlldayuncheck"
                          class="kanit-medium" dense color="#F99D20" persistent-hint
                          append-icon="mdi-calendar-month-outline" v-on="on">
                          <template v-slot:label>
                            <span> Date<span class="red--text"> *</span></span>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="timeoffAdd.Date" no-title @input="menunonecheck = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" v-model="timeoffAdd.Date" value-type="format"
                     no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row v-show="!timeoffAdd.Alldaycheck">
                  <v-col md="6">
                    <v-menu ref="menustarttime" v-model="menustarttime" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="timeoffAdd.StartTime" append-icon="access_time" dense color="#F99D20"
                          readonly v-on="on">
                          <template v-slot:label>
                            <span>
                              {{ $t("StartTime")
                              }}<span class="red--text"> *</span></span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-time-picker v-if="menustarttime" v-model="timeoffAdd.StartTime" no-title fluid @click:minute="
                        $refs.menustarttime.save(timeoffAdd.StartTime)
                      "></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="6">
                    <v-menu ref="menuendtime" v-model="menuendtime" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="timeoffAdd.EndTime" append-icon="access_time" dense color="#F99D20"
                          readonly v-on="on">
                          <template v-slot:label>
                            <span>
                              {{ $t("EndTime")
                              }}<span class="red--text"> *</span></span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-time-picker v-if="menuendtime" v-model="timeoffAdd.EndTime" no-title fluid @click:minute="
                        $refs.menuendtime.save(timeoffAdd.EndTime)
                      "></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <p>{{ $t("DescriptionOT") }}</p>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea v-model="timeoffAdd.message" :placeholder="$t('Providefurtherdetailshere')" no-resize
                      rows="3" min-height="70" color="#F99D20" single-line outlined dense required>
                    </v-textarea>
                  </v-col>
                </v-row>
                <p>{{ $t("AttachmentOT") }}</p>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                      <div
                        v-if="uploaddefaultshow == true"
                        @click="onpickfile"
                        class="drop-area"
                        @dragover.prevent="handleDragOver"
                        @drop="handleFileDrop"
                      >
                        <div style="text-align: center">
                          <v-icon color="#A3A7AA">mdi-cloud-upload</v-icon>
                          <p style="color: #a3a7aa">{{ $t("draganddrop") }}</p>
                        </div>
                      </div>
                      <div class="container1">
                        <div
                          style="width: 130px; height: 120px; margin-right: 5px"
                          v-if="uploaddefaultshow == false"
                          @click="onpickfile"
                          class="drop-area"
                          @dragover.prevent="handleDragOver"
                          @drop="handleFileDrop"
                        >
                          <div style="text-align: center" class="mt-2">
                            <v-icon style="color: #a3a7aa"
                              >mdi-cloud-upload</v-icon
                            >
                            <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                          </div>
                        </div>
                        <div
                          v-for="file in uploadedFiles"
                          :key="file.name"
                          class="mr-0 pa-3 relative"
                          style="
                            width: 130px;
                            height: 120px;
                            border: 1px solid #ccc;
                            border-radius: 7px;
                          "
                        >
                          <v-btn
                            @click="removefile(file)"
                            class="close-button top-0 right-0"
                            icon
                            ><v-icon>close</v-icon></v-btn
                          >
                          <div
                            @click="showiframe(file)"
                            style="margin-top: -1.9rem"
                          >
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.type == 'image/png'"
                              src="@/assets/images/png.png"
                              width="50px"
                            ></v-img>
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.type == 'image/jpeg'"
                              src="@/assets/images/jpg.png"
                              width="50px"
                            ></v-img>
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.type == 'image/jpg'"
                              src="@/assets/images/jpg.png"
                              width="50px"
                            ></v-img>
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.type == 'application/pdf'"
                              src="@/assets/images/pdf.png"
                              width="50px"
                            ></v-img>
                            <div class="text-center">
                              {{
                                file.name
                                  ? file.name.substring(0, 10) + " ..."
                                  : ""
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        ref="fileupload"
                        @change="handleFileSelect"
                        type="file"
                        style="display: none"
                        id="profile_picture"
                        accept=".pdf, .png, .jpeg, .jpg"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" class="mr-2 text-capitalize commoncancelbtn" outlined text
                    @click="CancelTimeOffdialog()">{{ $t("cancel") }}</v-btn>
                  <v-btn class="mr-2 text-capitalize commonsavebtn"
                    @click="RequestTimeoff()">{{ $t("Submit") }}</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <!-- exportdialog -->
          <v-dialog v-model="exportdailydialog" persistent scrollable max-width="1500px">
            <v-card style="border-radius: 15px">
              <v-card-title>
                <span>{{ $t("ExportFile") }}</span>
                <v-spacer></v-spacer>
                <!-- <v-btn @click="exportdailydialog = false" icon>
                  <v-icon>close</v-icon>
                </v-btn> -->
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <div :style="{ border: ColumnGone == false ? '2px solid #70707038' : '2px solid #FF0000', }"
                      class="thebox1">
                      <p class="theheadtext d-flex justify-center white--text">{{ $t("ColumnDisplay") }}</p>
                      <v-row class="ml-4 mr-4 mb-4">
                        <draggable :list="ColumnList" class="list-group mt-5 align-center justify-start row fill-height thehei"
                          ghost-class="ghost" :item-key="ColumnList.id" :key="ColumnList.length" :move="checkMove"
                          group="dailyGp" @start="dragging = true" @end="dragging = false">
                          <div class="list-group-item ml-2 mr-2 align-start justify-start theitembox"
                            v-for="(element, index) in ColumnList" :key="index" :item-key="element.name">
                            {{ TranslateThis(element.name) }}
                            <img src="@/assets/images/Group 28861.png"
                              style="cursor: pointer; margin-bottom: -6px; margin-top:10px;"
                              class="ml-3 mr-3 align-center justify-center" @click="movetoNo(element, index)" />

                          </div>
                        </draggable>
                      </v-row>
                    </div>
                    <p style="color:#FF0000" v-show="ColumnGone">***{{ $t("selectColumn") }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div class="thebox">
                      <p class="theheadtext d-flex justify-center white--text">{{ $t("ColumnNotDisplay") }}</p>
                      <v-row class="ml-4 mr-4 mb-4">
                        <draggable :list="NoColumnList" class="list-group mt-5 align-center justify-start row fill-height thehei"
                          min-height="300px" :item-key="NoColumnList.id" ghost-class="ghost" group="dailyGp"
                          :key="NoColumnList.length" :move="checkMove" @start="dragging = true" @end="dragging = false">
                          <div class="list-group-item ml-2 mr-2 align-start justify-start theNoitembox"
                            v-for="(element,index) in NoColumnList" :key="index" :item-key="element.name">
                            {{ TranslateThis(element.name) }}
                            <img src="@/assets/images/Group 2886.png"
                              style="cursor: pointer; margin-bottom: -6px; margin-top:10px;"
                              class="ml-3 mr-3 align-center justify-center" @click="movetoYes(element, index)" />

                          </div>
                        </draggable>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3" md="3" class="pb-0">
                    <p class="mt-2">
                      {{ $t("Employees") }}<span class="red--text"> *</span>
                    </p>
                    <v-text-field v-model="dailyselectusertype" readonly height="25px" class="mt-4" color="#F99D20"
                      single-line dense required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="pb-0">
                    <p class="mt-2">
                      {{ $t("FileType") }}<span class="red--text"> *</span>
                    </p>
                    <v-text-field readonly height="25px" class="mt-4" color="#F99D20" value="Excel" single-line dense
                      required></v-text-field>
                  </v-col>
                  <!-- </v-row>
                <v-row class="mt-3"> -->
                  <v-col cols="12" sm="3" md="3">
                    <!-- <label>{{ $t("startDate") }}<span class="red--text"> *</span></label> -->
                    <p class="mt-2" style="margin-bottom: 7px;">
                      {{ $t("startDate") }}<span class="red--text"> *</span>
                    </p>
                    <!-- <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                      max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted4"
                          :rules="starday" class="kanit-medium" persistent-hint v-on="on" readonly
                          color="#F99D20"></v-text-field>
                      </template>
                      <v-date-picker v-model="startdate" :max="enddate" no-title @input="menu = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="startdate" 
                    :max="enddate" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <!-- <label>{{ $t("enddata") }}<span class="red--text"> *</span></label> -->
                    <p class="mt-2" style="margin-bottom: 7px;">
                      {{ $t("enddata") }}<span class="red--text"> *</span>
                    </p>
                    <!-- <v-menu v-model="menu5" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                      max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted2"
                          :rules="enday" class="kanit-medium" persistent-hint v-on="on" color="#F99D20"
                          readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="enddate" :min="startdate" no-title @input="menu5 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="enddate" 
                    :min="startdate" :disabled-date="disableEndDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pb-2">
                <v-row class="ml-0 mr-0 mb-1 d-flex justify-center">
                  <v-col cols="12" md="2" sm="3">
                    <v-btn class="text-capitalize" outlined text @click="Closeexportdailydialog" width="100%" max-height="32">
                      {{ $t("Cancel") }}</v-btn>
                  </v-col>
                  <v-col cols="12" md="2" sm="3">
                    <v-btn class="text-capitalize" width="100%" max-height="32" color="#FF0000" outlined dense
                      @click="resetcolumn()">
                      {{ $t("ResetData1") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2" sm="3">
                    <v-btn color="#F99D20" class="white--text text-capitalize" width="100%" max-height="32"
                      @click="exportdaily()">
                      {{ $t("Export") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <!-- add time dialog -->
          <v-dialog v-model="AddTimedialog" persistent max-width="700px">
            <v-card style="border-radius: 15px">
              <v-row class="pa-0">
                <v-col cols="5">
                  <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
                    {{ $t("AddTimeClockIn/Out") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <v-btn class="mt-5 pa-4 text-capitalize" outlined color="#F99D20" @click="opentimeoffdialog()">
                    <v-icon> mdi-clock-outline </v-icon>
                    <p class="mt-4 ml-2"> {{ $t("AddTimeOff") }}</p>
                  </v-btn>
                </v-col>
              </v-row>

              <v-card-text>
                <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
                  <v-form ref="formAdd" lazy-validation>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field autocomplete="off" color="#F99D20" v-model="timetoAdd.TheName" readonly>
                        <template v-slot:label>
                          <span>{{ $t("emp") }}<span class="red--text"> *</span></span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select :items="TimeTypeitems" item-text="time" item-value="timeval" v-model="timetoAdd.TheType"
                        append-icon="mdi-chevron-down" color="#F89D1F">
                        <template v-slot:label>
                          <span>
                            {{ $t("TimeType") }}<span class="red--text"> *</span>
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-row style="margin-left: 0.2rem">
                      <v-col cols="12" sm="6" md="6">
                        <label>{{ $t("date") }}<span class="red--text"> *</span></label>
                        <!-- <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition">
                          <template v-slot:activator="{ on }">
                            <v-text-field dense v-model="computedDateFormatted1" style="margin-top: 1.5rem"
                              append-icon="mdi-calendar-month-outline" color="#F99D20" persistent-hint readonly v-on="on">
                              <template v-slot:label>
                                <span>
                                  {{ $t("date")
                                  }}<span class="red--text"> *</span></span>
                              </template></v-text-field>
                          </template>
                          <v-date-picker format="DD/MM/YYYY" v-model="timetoAdd.TheDate" no-title @input="menu1 = false"
                            :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="timetoAdd.TheDate" 
                        :allowed-dates="allowedDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                      
                      <v-col cols="12" sm="6" md="6" class="mt-2">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40" 
                          :return-value.sync="time1" transition="scale-transition">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="timetoAdd.TheTime" style="margin-top: 1.5rem"
                              append-icon="access_time" dense color="#F99D20" readonly v-on="on">
                              <template v-slot:label>
                                <span>{{ $t("Time") }}<span class="red--text"> *</span></span>
                              </template></v-text-field>
                          </template>
                          <v-time-picker v-if="menu2" v-model="timetoAdd.TheTime" no-title fluid
                            @click:minute="$refs.menu2.save(timetoAdd.TheTime)"></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" class="mr-2 text-capitalize commoncancelbtn" outlined text
                    @click="CancelTimedialog()">{{ $t("cancel") }}</v-btn>
                  <v-btn class="mr-2 text-capitalize commonsavebtn"
                    @click="ConfirmtoSave()">{{ $t("Save") }}</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <!-- Confirm Dialog -->
          <v-dialog v-model="Confirmdialog" persistent max-width="532">
            <v-card style="border-radius: 15px">
              <v-card-title>
                <p style="margin-top: 10px; margin-bottom: -30px">
                  {{ $t("AddTimeClockIn/Out") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <p style="font-size: 17px; color: #444444" class="pt-4">
                        {{ $t("thisnotmatch") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-5 text-capitalize commoncancelbtn" outlined @click="CloseConfirmdialog" text>{{
                    $t("cancel") }}</v-btn>
                  <v-btn class="mr-2 text-capitalize commonsavebtn"
                    @click="AddClockInOut()">{{ $t("Confirm") }}</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
         
          <!-- loading dialog -->
          <v-dialog v-model="LoadingDialog" persistent width="300">
            <v-card color="#FFF4EB" dark>
              <v-card-text class="black--text">
                Loading Please Wait...
                <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-tab-item>

      <!-- for month view -->
      <v-tab-item>
        <v-card class="pt-5 elevation-1">
          <v-row style="margin-left: 2rem; margin-right: 0rem" class="fixed-lg-item2">
            <v-col class="ml-0 mt-1 depart_text" cols="12" xs="12" sm="4" md="4" lg="3">
              <p class="ml-0 mt-2">{{ $t("SraechCri") }}</p>
              <v-autocomplete v-model="SearchWithCritMonth" :items="CriteriaOption" item-text="crittxt"
                item-value="critval" color="#F99D20" class="text" clearable outlined style="
                          border-radius: 5px;
                          margin-top: -1rem;
                          margin-right: 1rem;
                        " dense v-on:change="changesearchmonth(SearchWithCritMonth)">
                <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="4" md="3" lg="3" xl="3">
              <v-text-field :placeholder="$t('Search')" v-model="monthsearchtxt" outlined style="
                          border-radius: 5px;
                          margin-top: 2.3rem;
                          margin-right: 1rem;
                        " color="#F99D20" dense></v-text-field>
            </v-col>
            <v-btn color="#F99D20" @click="SearchMonthly()" class="white--text text-capitalize ml-3 mb-2" width="120"
              max-height="35" style="margin-top: 3.1rem">
              <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-col class="mr-5" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
              <div v-if="permission.export == false">
                <!-- <v-btn
                  right
                  class="empID green--text text-capitalize fixed-btn-align"
                  color="#2E7D32"
                  width="160"
                  max-height="34"
                  outlined
                  style="float: right; margin-top: 2.3rem"
                  @click="Openexportmonthlydialog"
                >
                  <img src="@/assets/images/excel.png" class="pr-2" />
                  {{ $t("exportExcel") }}
                </v-btn> -->
                <v-btn color="#F99D20" class="
                            white--text
                            text-capitalize
                            ml-5
                            rright
                            mb-5
                          " width="150" max-height="34" style="float: right; margin-top: 2.3rem" block
                  @click="Openexportmonthlydialog">
                  <img src="@/assets/images/Group 8613.png" class="pr-2" />
                  {{ $t("ExportFile") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-data-table style="margin-left: 2rem; margin-right: 2rem; margin-top: 1rem" :headers="monthheaders"
            :items="MonthViewList" hide-default-footer hide-default-header :page.sync="page1"
            :items-per-page="itemsPerPage1" @page-count="pageCount1 = $event" :mobile-breakpoint="0">
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th style="width: 50px">
                    <!-- <label class="form-checkbox ml-1">
                      <input type="checkbox" v-model="selectAll1" disabled @click="select1" />
                      <i class="form-icon"></i>
                    </label> -->
                    <!-- <img
                      :src="images.selectallimg"
                      style="width: 30px; height: 20px"
                      class="mt-2 ml-1"
                    /> -->
                  </th>
                  <th>{{ $t("EmployeeID") }}</th>
                  <th>{{ $t("department") }}</th>
                  <th>{{ $t("employee_name") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr style="cursor: pointer">
                <td style="text-align: center">
                  <!-- <v-checkbox class="custom-checkbox mb-5" 
                    :value="{
                        empid: item.employee_ID,
                        empname: item.fullName,
                    }" 
                    color="#F89D1F" 
                    hide-details 
                    v-model="selected1" 
                    v-on:click="justcheckone()">
                  </v-checkbox> -->
                  <v-checkbox class="custom-checkbox mt-0" 
                      v-model="item.checked"
                      @change="checkmyemp(item.employee_ID)" 
                      color="#F89D1F" hide-details>
                  </v-checkbox>
                  <!-- <label class="form-checkbox">
                    <input type="checkbox" :value="{
                      empid: item.employee_ID,
                      empname: item.fullName,
                    }" v-model="selected1" v-on:click="justcheckone()" />
                    <i class="form-icon"></i>
                  </label> -->
                </td>
                <td @click="handleclickmonth(item.employee_ID)">
                  {{ item.employee_Code }}
                </td>
                <td @click="handleclickmonth(item.employee_ID)">
                  {{ item.department_Name }}
                </td>
                <td @click="handleclickmonth(item.employee_ID)">
                  {{ item.fullName }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-right" v-if="MonthViewList.length">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination v-model="page1" :length="pageCount1" color="#FFCD2C"></v-pagination>
              </v-col>
              <v-col cols="12" md="2" class="mt-1">
                <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage1"
                  :items="items1" @input="itemsPerPage1 = parseInt($event, 10)"></v-select>
              </v-col>
            </v-row>
          </div>
          <!-- syncmonthtimedatadialog -->
          <v-dialog v-model="synctimemonthdatadialog" max-width="786">
            <v-card style="border-radius: 15px">
              <p class="pt-5" style="font-size: 23px; margin-left: 1.5rem; margin-right: 2rem">
                {{ $t("SyncTimeData") }}
              </p>
              <v-card-text>
                <v-row>
                  <v-col style="margin-left: -30px; margin-top: -20px" cols="12" md="12">
                    <v-row v-show="showup">
                      <v-col cols="4">
                        <input type="file" id="fileInputButton1" name="fileInputButton1" class="mt-2 ml-4"
                          @change="MonthloadCSV($event)" />
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon>
                          <img width="20" height="20" @click="closeinput" src="@/assets/closered.png" alt />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col style="margin-left: -30px; margin-top: -20px" cols="12" md="12">
                    <v-row v-show="showjarviz">
                      <v-col cols="4">
                        <v-file-input placeholder="No file chosen" color="white" id="fileInputButtonMonthJarviz"
                          v-model="jarvizmonthfile" prepend-icon class="mt-2 ml-7" accept=".xlsx">
                          <template v-slot:append>
                            <v-btn @click="closeinput" icon>
                              <img width="20" height="20" src="@/assets/closered.png" alt />
                            </v-btn>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-btn color="#ACACAC" class="white--text text-capitalize" width="150" max-height="35"
                      @click="getupload1">
                      {{ $t("browse") }}</v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p class="mt-2" style="color: #f74747">
                      ** {{ $t("ThisFileTypeCSVOnly.") }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn color="#F99D20" class="white--text text-capitalize" width="150" max-height="35"
                      @click="getimportmonthjarviz">
                      Import from Jarviz</v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
                <v-row>
                  <v-col cols="12" md="4">
                    <p>{{ $t("DocumentTemplateCSV") }}</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <a href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sync Time for Month view.csv"
                      style="color: #f99d20">{{ $t("download") }}
                    </a>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />
                <br />
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-spacer></v-spacer>
                <v-btn class="mr-2 text-capitalize" width="120" height="35" text @click="closeSyncMonDialog">{{
                  $t("Cancel") }}</v-btn>
                <v-btn v-show="upload" style="background-color: #f99d20" width="120" height="35"
                  class="mr-2 white--text text-capitalize" @click="UploadMonthSyncTime" text>{{ $t("upload") }}</v-btn>
                <v-btn v-show="!upload" style="background-color: #f99d20" width="120" height="35"
                  class="mr-2 white--text text-capitalize" @click="ImportFromMonthJarvizTimeAttendance" text>{{
                    $t("upload") }}</v-btn>
              </v-card-actions>
              <br />
            </v-card>
          </v-dialog>
          <!-- Don't Use Sync -->
          <v-dialog v-model="syncmonthtimedatadialog" max-width="786">
            <v-card style="border-radius: 15px">
              <p class="pt-5" style="font-size: 23px; margin-left: 1.5rem; margin-right: 2rem">
                {{ $t("SyncTimeData") }}
              </p>
              <v-card-text>
                <v-row>
                  <v-col style="margin-left: -30px; margin-top: -20px" cols="5" md="5">
                    <v-file-input v-show="showup" id="fileInputButton" class="inputbot">
                      <template v-slot:append>
                        <v-btn icon>
                          <img width="20" height="20" @click="closeinput" src="@/assets/closered.png" alt />
                        </v-btn>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-btn color="#ACACAC" class="white--text text-capitalize" width="150" max-height="35"
                      @click="getupload">
                      {{ $t("Browse") }}</v-btn>
                  </v-col>
                  <v-col cols="12" md="9">
                    <p class="mt-2" style="color: #f74747">
                      ** {{ $t("excelFileFormat") }}
                    </p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="mt-3">
                  <v-col cols="12" sm="6" md="6">
                    <label> {{ $t("startDate") }}</label>
                    <!-- <v-menu v-model="monthmenu" :close-on-content-click="false" transition="scale-transition" offset-y
                      fluid max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormattedmon"
                          class="kanit-medium" persistent-hint v-on="on" color="#F99D20"></v-text-field>
                      </template>
                      <v-date-picker v-model="startdatemon" no-title @input="monthmenu = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="startdatemon"
                     no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="6">
                    <label>{{ $t("enddata") }}</label>
                    <!-- <v-menu v-model="monEndmenu" :close-on-content-click="false" transition="scale-transition" offset-y
                      fluid max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormattedendmon"
                          class="kanit-medium" persistent-hint v-on="on" color="#F99D20"></v-text-field>
                      </template>
                      <v-date-picker v-model="monthenddate" no-title @input="monEndmenu = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="monthenddate"
                     no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
                <v-row>
                  <v-col cols="12" md="4">
                    <p>{{ $t("DocumentTemplateCSV") }}</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <a href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sync Time for Month view.csv"
                      style="color: #f99d20">
                      {{ $t("download") }}</a>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />
                <br />
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-spacer></v-spacer>
                <v-btn class="mr-2 text-capitalize" width="120" height="35" text @click="closeUpload">{{ $t("Cancel")
                }}</v-btn>
                <v-btn style="background-color: #f99d20" width="120" height="35" class="mr-2 white--text text-capitalize"
                  text>
                  {{ $t("upload") }}</v-btn>
              </v-card-actions>
              <br />
            </v-card>
          </v-dialog>
          <!-- exportmonthlydialog -->
          <v-dialog v-model="exportmonthlydialog" persistent scrollable max-width="1500px">
            <v-card style="border-radius: 15px">
              <v-card-title>
                <span> {{ $t("ExportFile") }}</span>
                <v-spacer></v-spacer>
                <!-- <v-btn @click="exportmonthlydialog = false" icon>
                  <v-icon>close</v-icon>
                </v-btn> -->
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <div :style="{ border: ColumnGone == false ? '2px solid #70707038' : '2px solid #FF0000', }"
                      class="thebox1">
                      <p class="theheadtext d-flex justify-center white--text">{{ $t("ColumnDisplay") }}</p>
                      <v-row class="ml-4 mr-4 mb-4">
                        <draggable :list="ColumnListMonth"
                          class="list-group mt-5 align-center justify-start row fill-height thehei" ghost-class="ghost"
                          :key="ColumnListMonth.length" :item-key="ColumnList.id" :move="checkMove" group="MonthlyGp"
                          @start="dragging = true" @end="dragging = false">
                          <div class="list-group-item ml-2 mr-2 align-start justify-start theitembox"
                            v-for="(element, index) in ColumnListMonth" :key="index" :item-key="element.name">
                            {{ TranslateThis(element.name) }}
                            <img src="@/assets/images/Group 28861.png"
                              style="cursor: pointer; margin-bottom: -6px; margin-top:10px;"
                              class="ml-3 mr-3 align-center justify-center" @click="movetoMonthNo(element, index)" />

                          </div>
                        </draggable>
                      </v-row>
                    </div>
                    <p style="color:#FF0000" v-show="ColumnGone">***{{ $t("selectColumn") }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div class="thebox">
                      <p class="theheadtext d-flex justify-center white--text">{{ $t("ColumnNotDisplay") }}</p>
                      <v-row class="ml-4 mr-4 mb-4">
                        <draggable :list="NoColumnListMonth"
                          class="list-group mt-5 align-center justify-start row fill-height thehei" min-height="300px"
                          ghost-class="ghost" group="MonthlyGp" :key="NoColumnListMonth.length" :item-key="element.id"
                          :move="checkMove" @start="dragging = true" @end="dragging = false">
                          <div class="list-group-item ml-2 mr-2 align-start justify-start theNoitembox"
                            v-for="(element,index) in NoColumnListMonth" :key="index" :item-key="element.name">
                            {{ TranslateThis(element.name) }}
                            <img src="@/assets/images/Group 2886.png"
                              style="cursor: pointer; margin-bottom: -6px; margin-top:10px;"
                              class="ml-3 mr-3 align-center justify-center" @click="movetoMonthYes(element, index)" />

                          </div>
                        </draggable>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3" md="3" class="pb-0">
                    <p class="mt-2">
                      {{ $t("Employees") }}<span class="red--text"> *</span>
                    </p>
                    <v-text-field v-model="monthselectusertype" readonly height="25px" class="mt-4" color="#F99D20"
                      single-line dense required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="pb-0">
                    <p class="mt-2">
                      {{ $t("FileType") }}<span class="red--text"> *</span>
                    </p>
                    <v-text-field readonly height="25px" class="mt-4" color="#F99D20" value="Excel" single-line dense
                      required></v-text-field>
                  </v-col>
                  <!-- </v-row>
                <v-row class="mt-3"> -->
                  <v-col cols="12" sm="3" md="3">
                    <label>
                      {{ $t("Month") }}<span class="red--text"> *</span></label>
                    <v-select dense height="25px" class="mt-6" v-model="monthselect" :items="monthNames1" item-text="txt"
                      item-value="val" :rules="mthselect" append-icon="mdi-chevron-down" color="#F99D20"></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <label>
                      {{ $t("Year") }}<span class="red--text"> *</span></label>
                    <!-- <date-picker
                      v-model="yearselect"
                      type="year"
                      color="#0FA7D8"
                      class="mt-4"
                    ></date-picker> -->
                    <v-select dense height="25px" class="mt-6" v-model="yearselect" :items="itemforyearselect"
                      append-icon="mdi-chevron-down" :rules="yerselect" color="#F99D20"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pb-2">
                <v-row class="ml-0 mr-0 mb-1 d-flex justify-center">
                  <v-col cols="12" md="2" sm="3">
                    <v-btn class="text-capitalize" outlined text @click="Closeexportmonthlydialog" width="100%" max-height="32">{{
                      $t("Cancel") }}</v-btn>
                  </v-col>
                  <v-col cols="12" md="2" sm="3">
                    <v-btn class="text-capitalize" width="100%" max-height="32" color="#FF0000" outlined dense
                      @click="resetcolumnMonth()">
                      {{ $t("ResetData1") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2" sm="3">
                    <v-btn color="#F99D20" class="white--text text-capitalize" width="100%" max-height="32"
                      @click="exportmonthly()">
                      {{ $t("Export") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- </div> -->
        </v-card>
      </v-tab-item>
    </v-tabs>
    
    <v-dialog v-model="Approvedialog" persistent max-width="500px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span class="pl-2"> {{ $t("ConfrimToApprove") }}</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row justify="center">
            <p class="pl-5">{{ $t("ApproveTimeAttendance") }}</p>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize mr-2" max-height="35" width="120px" @click="Approvedialog = false" text>{{
              $t("Cancel") }}</v-btn>
            <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" max-height="35" width="120px"
              @click="ApproveTime()">
              {{ $t("Confirm") }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="alertDialog" persistent max-width="500px">
      <v-card style="border-radius: 5px">
        <v-container>
          <div class="d-flex justify-center Body 1 mt-2">
            <img width="60" height="60" src="@/assets/images/Iconly-Bold-Info-Circle.png" />
          </div>
          <p class="d-flex justify-center Body 1 mt-2">{{ $t("selectColumnLongTxt") }}</p>
          <div class="d-flex justify-center mb-5 mt-5">
            <v-btn color="#F99D20" class="white--text text-capitalize" width="120" max-height="35"
              @click="ThereNoColumn()">
              {{ $t("OK") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog
        v-model="dialogforDownload"
        persistent
        width="500"
        height="500"
        class="rounded-lg"
        style="overflow: hidden"
      >
        <v-card
          style="
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
          "
        >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadImage()"
              ><v-icon size="30">mdi-download</v-icon></v-btn
            >

            <v-btn icon @click="dialogforDownload = false"
              ><v-icon size="30">mdi-window-close</v-icon></v-btn
            >
          </v-card-actions>

          <v-img :src="imgForshow" max-width="500"></v-img>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog
      v-model="printdialogforupdate"
      max-width="75%"
      style="overflow: hidden"
    >
      <v-card style="background-color: white">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              printdialogforupdate = false;
              iframeShow = false;
            "
            icon
            style="margin-right: 3rem; margin-top: 1rem"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-row>

        <v-card class="mt-4 pdf-container">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <iframe
                :src="pdfString"
                width="100%"
                height="1000px"
                frameborder="0"
                scrolling="no"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import draggable from "@/function/vuedraggable";
import CheckViewApproveSyncTimeExportEdit from "@/function/RolePermissions.js";

export default {
  mixins: [LogTrace, CheckViewApproveSyncTimeExportEdit, draggable],
  components: { draggable },
  data: (vm) => ({
    permission: {
      approve: true,
      export: true,
      edit: true, 
    },
    dragging: false,
    url: enurl.apiUrl,
    model: 0,
    jarvizfile: null,
    jarvizmonthfile: null,
    fornow: false,
    page: 1,
    pageCount: 15,
    itemsPerPage: 10,
    items: [10, 20, 30],
    page1: 1,
    pageCount1: 15,
    itemsPerPage1: 10,
    items1: [10, 20, 30],
    exportdailydialog: false,
    exportmonthlydialog: false,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    menu6: false,
    showup: false,
    showjarviz: false,
    upload: true,
    gettodaydate: new Date(),
    disforfuture: false,
    startdate: "",
    enddate: "",
    monEndmenu: false,
    monthenddate: "",
    monthmenu: false,
    startdatemon: "",
    MonthColumn: 0,
    DayColumn: 0,
    menu: false,
    menu5: false,
    showthis: true,
    timeoffdialog: false,
    synctimedatadialog: false,
    synctimemonthdatadialog: false,
    syncmonthtimedatadialog: false,
    LoadingDialog: false,
    searchtxt: null,
    monthsearchtxt: null,
    searchsearch: "",
    DayViewList: [],
    DayViewListForReal: [],
    MonthViewList: [],
    TimeViewList: [],
    TimeSettingList: [],
    selected: [],
    selected1: [],
    selectAll: false,
    selectAll1: false,
    calendarView: false,
    startbefore: null,
    startbeforetime: null,
    startafter: null,
    startaftertime: null,
    endbefore: null,
    endbeforetime: null,
    endafter: null,
    endaftertime: null,
    Approvedialog: false,
    chosenfile: [],
    chosenfiles: [],
    importData: [],
    parse_header: [],
    parse_csv: [],
    sortOrders: {},
    sortKey: "",
    csvname: null,
    exportlist: [],
    sheetTitle: null,
    dailyselectusertype: "Everyone",
    monthselectusertype: "",
    monthselect: null,
    yearselect: null,
    code: null,
    thedsearchlength: 0,
    theoldlength: 0,
    dailyhead: 1,
    monthlyhead: 1,
    SearchWithCrit: 1,
    SearchWithCritMonth: 1,
    FilterDay: 0,
    FilterDayAttendance: 1,
    itemforyearselect: [2019, 2020, 2021, 2022, 2023, 2024, 2025],
    AddTimedialog: false,
    Confirmdialog: false,
    alertDialog: false,
    ColumnGone: false,
    NoColumnList: [],
    ColumnList: [
      { id: 0, val: 1, name: "Employee ID" },
      { id: 1, val: 2, name: "Department" },
      { id: 2, val: 3, name: "Employee Name" },
      { id: 3, val: 4, name: "Shift Start Time" },
      { id: 4, val: 5, name: "Shift End Time" },
      { id: 5, val: 6, name: "OT Start Time" },
      { id: 6, val: 7, name: "OT End Time" },
      { id: 7, val: 8, name: "Time Off Type" },
      { id: 8, val: 9, name: "Time Off Hour" },
      { id: 9, val: 10, name: "Clock In" },
      { id: 10, val: 11, name: "Clock Out" },
      { id: 11, val: 12, name: "OT Clock In" },
      { id: 12, val: 13, name: "OT Clock Out" },
      { id: 13, val: 14, name: "Remark" },
      { id: 14, val: 15, name: "Status" },
      { id: 15, val: 16, name: "Approver" },
      { id: 16, val: 17, name: "Clock In Status" },
      { id: 17, val: 18, name: "OT Clock In Status" },
    ],
    NoColumnListMonth: [],
    ColumnListMonth: [
      { id: 0, val: 1, name: "Employee ID" },
      { id: 1, val: 2, name: "Department" },
      { id: 2, val: 3, name: "Employee Name" },
      { id: 3, val: 4, name: "Shift Start Time" },
      { id: 4, val: 5, name: "Shift End Time" },
      { id: 5, val: 6, name: "OT Start Time" },
      { id: 6, val: 7, name: "OT End Time" },
      { id: 7, val: 8, name: "Time Off Type" },
      { id: 8, val: 9, name: "Time Off Hour" },
      { id: 9, val: 10, name: "Clock In" },
      { id: 10, val: 11, name: "Clock Out" },
      { id: 11, val: 12, name: "OT Clock In" },
      { id: 12, val: 13, name: "OT Clock Out" },
      { id: 13, val: 14, name: "Remark" },
      { id: 14, val: 15, name: "Status" },
      { id: 15, val: 16, name: "Approver" },
      { id: 16, val: 17, name: "Clock In Status" },
      { id: 17, val: 18, name: "OT Clock In Status" },
    ],
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    monthNames1: [
      { val: 1, txt: "   January" },
      { val: 2, txt: "   February" },
      { val: 3, txt: "   March" },
      { val: 4, txt: "   April" },
      { val: 5, txt: "   May" },
      { val: 6, txt: "   June" },
      { val: 7, txt: "   July" },
      { val: 8, txt: "   August" },
      { val: 9, txt: "   September" },
      { val: 10, txt: "   October" },
      { val: 11, txt: "   November" },
      { val: 12, txt: "   December" },
    ],
    isHalf: 0,
    timeoffAdd: {
      Employee_ID: 0,
      TimeoffID: 0,
      Alldaycheck: true,
      Date: "",
      StartDate: "",
      EndDate: "",
      StartTime: "",
      EndTime: "",
      Showallday: false,
      ApproverID: 0,
      Balance: "",
      message: "",
      attachment: [],
    },
    timetoAdd: {
      Employee_ID: 0,
      TheName: null,
      TheDate: null,
      TheTime: null,
      TheType: null,
      OtherType: null,
      start_Time: null,
      end_Time: null,
      ot_start_Time: null,
      ot_end_Time: null,
      time_ID: 0,
      TheUTCTime: null,
      isitthere: "",
      attachment: [],
    },
    movehere: "",
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menunonecheck: false,
    menustarttime: false,
    menuendtime: false,
    time1: null,
    enday: [],
    starday: [],
    mthselect: [],
    yerselect: [],
    headers: [],
    monthheaders: [],
    com: 0,
    images: {
      selectallimg: require("@/assets/images/selectall@2x.png"),
    },
    csvmonthname: null,
    clocklist: [],
    timeTypeList: [],
    mypersonalist: [],
    thelang: "",
    LeaveList: [],
    dialogforDownload: false,
    printdialogforupdate: false,
    iframeShow: false,
    dllink: null,
    imgForshow: "",
    imgString: "",
    pdfString: "",
    uploaddefaultshow: true,
    selectedFiles: [],
    uploadedFiles: [],
    clockinDate: null,
  }),
  watch: {
    startdate(v) {
      this.starday = [];
      if (new Date(v) > new Date(this.enddate)) {
        this.enddate = null;
      }
    },
    'timeoffAdd.StartDate'(v){
      if (new Date(v) > new Date(this.timeoffAdd.EndDate)) {
        this.timeoffAdd.EndDate = null;
      }
    },
    enddate() {
      this.enday = [];
    },
    monthselect() {
      this.mthselect = [];
    },
    yearselect() {
      this.yerselect = [];
    },
    searchtxt: function () {
      if (this.searchtxt == "") {
        this.searchsearch = this.searchtxt;
        this.GetDayList();
      }
    },
    monthsearchtxt: function () {
      this.GetMonthList();
    },
    selected() {
      this.hellothere();
    },
    ColumnList() {
      if (this.ColumnList.length != 0) {
        this.ColumnGone = false;
      }
    },
    ColumnListMonth() {
      if (this.ColumnListMonth.length != 0) {
        this.ColumnGone = false;
      }
    },
    thelang() {
      this.whatthehead()
    },
  },
  computed: {
    computedDateFormatted1() {
      return this.formatDate(this.timetoAdd.TheDate);
    },
    computedDateFormattedTimeoffAllday() {
      return this.formatDate(this.timeoffAdd.StartDate);
    },
    computedDateFormattedTimeoffAllday1() {
      return this.formatDate(this.timeoffAdd.EndDate);
    },
    computedDateFormattedTimeoffAlldayuncheck() {
      return this.formatDate(this.timeoffAdd.Date);
    },
    computedDateFormattedmon() {
      return this.formatDate(this.startdatemon);
    },
    computedDateFormattedendmon() {
      return this.formatDate(this.monthenddate);
    },
    computedDateFormatted4() {
      return this.formatDate(this.startdate);
    },
    computedDateFormatted2() {
      return this.formatDate(this.enddate);
    },
    CriteriaOption() {
      return [
        { critval: 1, crittxt: this.$t("employeeid") },
        { critval: 2, crittxt: this.$t("department") },
        { critval: 3, crittxt: this.$t("employee_name") },
      ];
    },
    TimeOption() {
      return [
        { timeval: 1, time: this.$t("Normal") },
        { timeval: 2, time: this.$t("Irregular") },
      ];
    },
    AttendanceOption() {
      return [
        { timeval: 1, time: this.$t("Require") },
        { timeval: 2, time: this.$t("Optional") },
      ];
    },
    TimeTypeitems() {
      return [
        { timeval: "Regular In", time: this.$t("RegIn") },
        { timeval: "Regular Out", time: this.$t("RegOut") },
        { timeval: "Overtime In", time: this.$t("OvtIn") },
        { timeval: "Overtime Out", time: this.$t("OvtOut") },
      ];
    },
    TranslateThis() {
      return function (salut) {
        let value = salut;
        return value == "Employee ID"
          ? this.$t("EmployeeID")

          : value == "Department"
            ? this.$t("department")

            : value == "Employee Name"
              ? this.$t("employee_name")

              : value == "Shift Start Time"
                ? this.$t("shiftStartTime")

                : value == "Shift End Time"
                  ? this.$t("shiftEndTime")

                  : value == "OT Start Time"
                    ? this.$t("otStartTime")

                    : value == "OT End Time"
                      ? this.$t("otEndTime")

                      : value == "Time Off Type"
                        ? this.$t("TimeOffType")

                        : value == "Time Off Hour"
                          ? this.$t("TimeOffHour")

                          : value == "Clock In"
                            ? this.$t("clockIn")

                            : value == "Clock Out"
                              ? this.$t("clockOut")

                              : value == "OT Clock In"
                                ? this.$t("otClockIn")

                                : value == "OT Clock Out"
                                  ? this.$t("otClockOut")

                                  : value == "Remark"
                                    ? this.$t("Remark")

                                    : value == "Status"
                                      ? this.$t("status")

                                      : value == "Approver"
                                        ? this.$t("approver")

                                        : value == "Clock In Status"
                                          ? this.$t("ClockInStatus")

                                          : value == "OT Clock In Status"
                                            ? this.$t("OTClockInStatus")

                                            : this.$t("status");
      };
    },
    thethaihead() {
      return this.whatthehead();
    },
  },
  mounted() {
    let tabs = parseInt(localStorage.getItem("temp_tabs"));
    this.model = tabs;
    //this.Getpersonal();
    this.GetTimeAttSetting();
    this.GetDayList();
    this.GetMonthList();
    this.GetTimeView();
    this.GetLeaveType();
    //this.GetTimeoffType();
    this.permission = this.CheckViewApproveSyncTimeExportEdit(10, 11, 12, 13);
    this.headers.push({
      value: "employee_Code",
      align: "left",
      sortable: false,
    });
    this.monthheaders.push({
      value: "employee_Code",
      align: "left",
      sortable: false,
    });
    this.com = 1;
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  methods: {
    allowedEndDates(date) {
      if (!this.timeoffAdd.StartDate)
      {
        return false; // No start date selected, all dates are enabled
      }
       // Parse the start_Date string (format: DD/MM/YYYY) to a Date object
      const [day, month, year] = this.timeoffAdd.StartDate.split('/');
      const startDate = new Date(year, month - 1, day);
      startDate.setHours(0, 0, 0, 0); // Reset time part for comparison
      return date < startDate; // Disable dates before start date
    },
    disableEndDates(date){
      if (!this.startdate)
      {
        return false;
      }
      const [day, month, year] = this.startdate.split('/');
      const startDate = new Date(year, month - 1, day);
      startDate.setHours(0, 0, 0, 0);
      return date < startDate;
    },
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    opentimeoffdialog() {
      let self = this;
      if (self.LeaveList.length != 0) {
        self.timeoffdialog = true;
        self.AddTimedialog = false;
      }
      else {
        alert("Please fill the data in time off master data first");
      }

    },
    GetLeaveType() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOff/GetLeaveTypeByCompanyID`, tempp)
        .then(function (response) {
          let thelist = response.data.data;
          self.LeaveList = thelist.filter((v) => v.leaveStatus == true);
          if (self.LeaveList.length != 0) {
            self.timeoffAdd.TimeoffID = self.LeaveList[0].timeOff_ID;
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    GetLeaveTypeWithRemainingDays(eid) {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
        employee_ID: eid,
      };
      axios
        .post(`${self.url}TimeOff_Request/GetLeaveWithRemainingDays`, tempp)
        .then(function (response) {
          self.leaveDaysList = response.data.data;
          //self.timeOff.approver_Name = self.leaveDaysList[0].approve_Name;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    Getpersonal(ID) {
      let self = this;
      let temp = {
        employee_ID: parseInt(ID),
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}PersonalInfo/GetShortInfo`, temp)
        .then(function (response) {

          self.timeoffAdd.ApproverID = response.data.data[0].report_ID;

        });
    },
    checkMove: function (e) {
      this.movehere = e.draggedContext.futureIndex;
    },
    opentimedialog() {
      let self = this;
      self.timeoffdialog = false;
      self.AddTimedialog = true;
    },
    CancelTimedialog() {
      this.AddTimedialog = false;
      this.timeoffAdd.Alldaycheck = true;
    },
    CancelTimeOffdialog() {
      this.timeoffdialog = false;
      this.timeoffAdd.message="";
      this.uploadedFiles=[];
      this.timeoffAdd.Alldaycheck = true;
      this.uploaddefaultshow = true;
    },
    async GetTimeoffType(empID, PositionID) {
      try {
        let position_ID = PositionID;
        let temp = {
          company_ID: store.state.companyID,
          position_ID: position_ID,
          employee_ID: empID,
        };
        const response = await axios.post(
          `${this.url}TimeOff_Request/GetByPositionID`,
          temp
        );

        this.timeTypeList = response.data.data;
        if (this.timeTypeList.length != 0) {
          this.timeoffAdd.TimeoffID = this.timeTypeList[0].timeOff_ID;
        }
      } catch (ex) {
        throw ex;
      }
    },
    filtertable() {
      let those = this;
      those.GetDayList(1);
      // those.DayViewListForReal = [];
      // if (val == 1) {
      //   for (let i = 0; i < those.DayViewList.length; i++) {
      //     let youfindthis = those.ShiftVali(those.DayViewList[i]);
      //     if (youfindthis == true) {
      //       those.DayViewListForReal.push(those.DayViewList[i]);
      //     }
      //   }
      // } else if (val == 2) {
      //   for (let i = 0; i < those.DayViewList.length; i++) {
      //     let youfindthis = those.ShiftVali(those.DayViewList[i]);
      //     if (youfindthis == false) {
      //       those.DayViewListForReal.push(those.DayViewList[i]);
      //     }
      //   }
      // }
    },
    changesearch(val) {
      let _this = this;
      _this.headers = [];
      _this.dailyhead = val;
      if (val == 1) {
        _this.headers.push({
          value: "employee_Code",
          align: "left",
          sortable: false,
        });
      } else if (val == 2) {
        _this.headers.push({
          value: "department_Name",
          align: "left",
          sortable: false,
        });
      } else {
        _this.headers.push({
          value: "fullName",
          align: "left",
          sortable: false,
        });
      }
    },
    SearchDaily() {
      let self = this;
      if (
        self.searchtxt != "" &&
        self.searchtxt != undefined &&
        self.searchtxt != null
      ) {
        // let List = self.DayViewListForReal;
        // self.DayViewListForReal = [];
        // let Stxt = self.searchtxt.toLowerCase();
        // for (let i = 0; i < List.length; i++) {
        //   if (self.dailyhead == 1) {
        //     if (List[i].employee_Code.toLowerCase().includes(Stxt)) {
        //       self.DayViewListForReal.push(List[i]);
        //     }
        //   } else if (self.dailyhead == 2) {
        //     if (List[i].department_Name.toLowerCase().includes(Stxt)) {
        //       self.DayViewListForReal.push(List[i]);
        //     }
        //   } else {
        //     if (List[i].fullName.toLowerCase().includes(Stxt)) {
        //       self.DayViewListForReal.push(List[i]);
        //     }
        //   }
        // }
        self.page = 1;
        self.thedsearchlength = 0;
        self.searchsearch = self.searchtxt;
        self.GetDayList();
      } else {
        alert("Please enter anything!!");
      }
    },
    changesearchmonth(val) {
      let _this = this;
      _this.monthheaders = [];
      _this.monthlyhead = val;
      if (val == 1) {
        _this.monthheaders.push({
          value: "employee_Code",
          align: "left",
          sortable: false,
        });
      } else if (val == 2) {
        _this.monthheaders.push({
          value: "department_Name",
          align: "left",
          sortable: false,
        });
      } else {
        _this.monthheaders.push({
          value: "fullName",
          align: "left",
          sortable: false,
        });
      }
    },
    SearchMonthly() {
      let self = this;
      if (self.monthsearchtxt != "" && self.monthsearchtxt != undefined) {
        //let List = self.MonthViewList;
        self.MonthViewList = [];
        let Stxt = self.monthsearchtxt.toLowerCase();
        for (let i = 0; i < self.MonthViewList1.length; i++) {
          if (self.monthlyhead == 1) {
            if (self.MonthViewList1[i].employee_Code.toLowerCase().includes(Stxt)) {
              self.MonthViewList.push(self.MonthViewList1[i]);
            }
          } else if (self.monthlyhead == 2) {
            if (self.MonthViewList1[i].department_Name.toLowerCase().includes(Stxt)) {
              self.MonthViewList.push(self.MonthViewList1[i]);
            }
          } else {
            if (self.MonthViewList1[i].fullName.toLowerCase().includes(Stxt)) {
              self.MonthViewList.push(self.MonthViewList1[i]);
            }
          }
        }
      } else {
        alert("Please enter anything!!");
        self.GetMonthList();
      }
    },
    yesterday() {
      let self = this;
      if (self.gettodaydate != null) {
        const date = new Date(
          self.gettodaydate.getFullYear(),
          self.gettodaydate.getMonth(),
          self.gettodaydate.getDate() - 1
        );
        self.gettodaydate = date;
        self.thedsearchlength = 0;
        self.searchsearch = "";
        self.searchtxt = "";
        self.FilterDay = 0;
        this.GetDayList(1);
      }
    },
    tomorrow() {
      let self = this;
      if (self.gettodaydate != null) {
        const date = new Date(
          self.gettodaydate.getFullYear(),
          self.gettodaydate.getMonth(),
          self.gettodaydate.getDate() + 1
        );
        self.gettodaydate = date;
        self.thedsearchlength = 0;
        self.searchsearch = "";
        self.searchtxt = "";
        self.FilterDay = 0;
        this.GetDayList(1);
      }
    },
    GetDayList(v) {
      let self = this;
      if (v == 3) {
        self.page = 1;
        v = 1;
      }
      if (
        v == 1 ||
        self.thedsearchlength == 0 ||
        self.thedsearchlength != self.DayViewListForReal.length ||
        self.theoldlength != self.DayViewListForReal.length
      ) {
        let getdate = "";
        let NowDay = JSON.parse(
          localStorage.getItem("get_date_of_today_return")
        );
        let NowDay1 = JSON.parse(
          localStorage.getItem("get_date_of_today_return1")
        );
        if (NowDay != null && v != 1) {
          getdate = NowDay;
          self.gettodaydate = new Date(Date.parse(NowDay1));
        } else {
          if (self.gettodaydate != null) {
            const condate = new Date();
            if (
              self.gettodaydate.getDate() == condate.getDate() &&
              self.gettodaydate.getMonth() == condate.getMonth() &&
              self.gettodaydate.getFullYear() == condate.getFullYear()
            ) {
              self.disforfuture = true;
            } else {
              self.disforfuture = false;
            }
            let month = String(self.gettodaydate.getMonth() + 1);
            let day = String(self.gettodaydate.getDate());
            const year = String(self.gettodaydate.getFullYear());
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            getdate = `${day}/${month}/${year}`;
            let fornow = `${month}/${day}/${year}`;
            localStorage.setItem(
              "get_date_of_today_return",
              JSON.stringify(getdate)
            );
            localStorage.setItem(
              "get_date_of_today_return1",
              JSON.stringify(fornow)
            );
          }
        }
        self.DayViewList = [];
        self.DayViewListForReal = [];
        this.selected = [];
        self.LoadingDialog = true;
        let newdatetosent = new Date();
        let tgetdate = "";
        let day = self.gettodaydate.getDate();
        let month = self.gettodaydate.getMonth() + 1;
        let year = self.gettodaydate.getFullYear();
        newdatetosent.setDate(day);
        newdatetosent.setMonth(month);
        newdatetosent.setFullYear(year);
        tgetdate = `${year}-${month}-${day}`;
        localStorage.setItem("get_date_of_today", JSON.stringify(tgetdate));
        let idtxt = "";
        let nametxt = "";
        let deptxt = "";
        if (self.SearchWithCrit == 1) {
          idtxt = self.searchsearch;
        } else if (self.SearchWithCrit == 2) {
          deptxt = self.searchsearch;
        } else {
          nametxt = self.searchsearch;
        }
        let temp = {
          company_ID: store.state.companyID,
          today_Date: getdate,
          employeeID: idtxt,
          department: deptxt,
          employeeName: nametxt,
          timeType: self.FilterDay,
          timeAttendance: self.FilterDayAttendance,
          perpage: self.itemsPerPage,
          page: self.page,
        };
        axios
          .post(`${self.url}Stafftimeattendance/GetDayViewPerformance`, temp)
          .then(function (response) {
            let templist = response.data.data.list;
            if (self.theoldlength == 0) {
              self.theoldlength = templist.length;
            }
            self.pageCount = response.data.data.countAll / self.itemsPerPage;
            if (self.pageCount % 1 != 0) {
              self.pageCount = parseInt(self.pageCount) + 1;
            } else {
              self.pageCount;
            }
            for (let i = 0; i < templist.length; i++) {
              let intime = "-";
              let outtime = "-";
              let otintime = "-";
              let otouttime = "-";
              let cinoutcount = 0;
              let otcinoutcount = 0;
              let regin = "-";
              let latein = "-";
              let otin = "-";
              let lateotin = "-";
              let yesc = false;
              let clist = [];
              let status = null;
              let outlists = [];
              let otoutlist = [];
              for (let f = 0; f < templist[i].regCinoutData.length; f++) {
                clist.push({
                  clock_ID: templist[i].regCinoutData[f].clock_ID,
                });
                self.clocklist.push({
                  clock_ID: templist[i].regCinoutData[f].clock_ID,
                });

                if (templist[i].regCinoutData[f].clock_In_Time != "-") {
                  regin = templist[i].regCinoutData[f].clock_In_Time;
                  // console.log("time", regin);
                }
              }
              for (let r = 0; r < templist[i].otCinoutData.length; r++) {
                clist.push({
                  clock_ID: templist[i].otCinoutData[r].clock_ID,
                });
                // self.clocklist.push({
                //   clock_ID: templist[i].otCinoutData[r].clock_ID,
                // });
              }
              if (templist[i].irrRegCinoutData.length != 0) {
                latein = templist[i].irrRegCinoutData[0].clock_In_Time;
                for (let c = 0; c < templist[i].irrRegCinoutData.length; c++) {
                  outlists.unshift({
                    time: templist[i].irrRegCinoutData[c].clock_Out_Time,
                  });
                }
              }
              if (templist[i].regCinoutData.length != 0) {
                cinoutcount = templist[i].regCinoutData.length;
                for (let a = 0; a < templist[i].regCinoutData.length; a++) {
                  outlists.unshift({
                    time: templist[i].regCinoutData[a].clock_Out_Time,
                  });
                }
              }
              if (templist[i].irrOTCinoutData.length != 0) {
                lateotin = templist[i].irrOTCinoutData[0].clock_In_Time;
                for (let d = 0; d < templist[i].irrOTCinoutData.length; d++) {
                  otoutlist.unshift({
                    time: templist[i].irrOTCinoutData[d].clock_Out_Time,
                  });
                }
              }
              if (templist[i].otCinoutData.length != 0) {
                otin = templist[i].otCinoutData[0].clock_In_Time;
                otcinoutcount = templist[i].otCinoutData.length;
                for (let b = 0; b < templist[i].otCinoutData.length; b++) {
                  otoutlist.unshift({
                    time: templist[i].otCinoutData[b].clock_Out_Time,
                  });
                }
              }

              if (regin != "-" && regin != null) {
                intime = regin;
              } else if (latein != "-" && latein != null) {
                intime = latein;
              }

              for (let e = 0; e < outlists.length; e++) {
                if (outlists[e].time != "-") {
                  outtime = outlists[e].time;
                  break;
                }
              }

              if (otin != "-" && otin != null) {
                otintime = otin;
              } else if (lateotin != "-" && lateotin != null) {
                otintime = lateotin;
              }

              for (let f = 0; f < otoutlist.length; f++) {
                if (otoutlist[f].time != "-") {
                  otouttime = otoutlist[f].time;
                  break;
                }
              }

              let inregular = templist[i].regCinoutData.filter(
                (val) => val.clock_In_Time != "-"
              );
              let outregular = templist[i].regCinoutData.filter(
                (val) => val.clock_Out_Time != "-"
              );
              let otinregular = templist[i].otCinoutData.filter(
                (val) => val.clock_In_Time != "-"
              );
              let otoutregular = templist[i].otCinoutData.filter(
                (val) => val.clock_Out_Time != "-"
              );

              let maincondi = false;
              if (inregular.length == 0 && otinregular.length == 0) {
                maincondi = true;
              }
              let iknow = "";
              if (
                templist[i].start_Time != "-" &&
                templist[i].oT_Start_Time != "-"
              ) {
                if (
                  inregular.length != 0 &&
                  outregular.length != 0 &&
                  otinregular.length != 0 &&
                  otoutregular.length != 0
                ) {
                  yesc = true;
                }
                if (inregular.length == 0 && otinregular.length == 0) {
                  iknow = "NoIn";
                } else if (outregular.length == 0 && otoutregular.length == 0) {
                  iknow = "NoOut";
                }
              } else if (
                templist[i].start_Time != "-" &&
                templist[i].oT_Start_Time == "-"
              ) {
                if (inregular.length != 0 && outregular.length != 0) {
                  yesc = true;
                }
                if (inregular.length == 0) {
                  iknow = "NoIn";
                } else if (outregular.length == 0) {
                  iknow = "NoOut";
                }
              } else if (
                templist[i].start_Time == "-" &&
                templist[i].oT_Start_Time != "-"
              ) {
                if (otinregular.length != 0 && otoutregular.length != 0) {
                  yesc = true;
                }
                if (otinregular.length == 0) {
                  iknow = "NoIn";
                } else if (otoutregular.length == 0) {
                  iknow = "NoOut";
                }
              }

              if (
                templist[i].oT_Start_Time == null ||
                templist[i].oT_Start_Time == ""
              ) {
                templist[i].oT_Start_Time = "-";
              }
              if (
                templist[i].oT_End_Time == null ||
                templist[i].oT_End_Time == ""
              ) {
                templist[i].oT_End_Time = "-";
              }
              if (
                templist[i].oT_Start_Time == "-" &&
                templist[i].oT_End_Time == "-" &&
                templist[i].start_Time == "-" &&
                templist[i].end_Time == "-"
              ) {
                status = "-";
              } else if (templist[i].approve == true) {
                status = "Approved";
                templist[i].remark = "Approved";
              } else {
                status = "Pending";
              }
              let otsttime = "-";
              if (templist[i].oT_Start_Time != null) {
                otsttime = templist[i].oT_Start_Time;
              }
              let otedtime = "-";
              if (templist[i].oT_End_Time != null) {
                otedtime = templist[i].oT_End_Time;
              }

              let RegularArray = templist[i].regCinoutData;
              let LateRegArray = templist[i].irrRegCinoutData;
              let temparr = [];
              for (let p = 0; p < LateRegArray.length; p++) {
                if (
                  (LateRegArray[p].clock_In_Time != null &&
                    LateRegArray[p].clock_In_Time != "-") ||
                  (LateRegArray[p].clock_Out_Time != null &&
                    LateRegArray[p].clock_Out_Time != "-")
                ) {
                  temparr.push({
                    clock_ID: LateRegArray[p].clock_ID,
                    clock_In_Time: LateRegArray[p].clock_In_Time,
                    clock_Out_Time: LateRegArray[p].clock_Out_Time,
                    clock_In_Location: LateRegArray[p].clock_In_Location,
                    clock_Out_Location: LateRegArray[p].clock_Out_Location,
                    clock_In_Lattitude: LateRegArray[p].clock_In_Lattitude,
                    clock_In_Longitude: LateRegArray[p].clock_In_Longitude,
                    clock_Out_Lattitude: LateRegArray[p].clock_Out_Lattitude,
                    clock_Out_Longitude: LateRegArray[p].clock_Out_Longitude,
                    time_ID: LateRegArray[p].time_ID,
                    time_Type: LateRegArray[p].time_Type,
                  });
                }
              }
              //in time
              let shiftbefore = new Date(
                Date.parse(templist[i].timeEntry_Date)
              );
              let shiftafter = new Date(Date.parse(templist[i].timeEntry_Date));
              let [sshours, ssminutes] = templist[i].start_Time.split(":");
              shiftbefore.setHours(+sshours);
              shiftbefore.setMinutes(ssminutes);
              shiftafter.setHours(+sshours);
              shiftafter.setMinutes(ssminutes);
              if (self.startbeforetime == "hour") {
                let adds = shiftbefore.getHours() - parseInt(self.startbefore);
                shiftbefore.setHours(+adds);
              } else if (self.startbeforetime == "min") {
                let adds =
                  shiftbefore.getMinutes() - parseInt(self.startbefore);
                shiftbefore.setMinutes(adds);
              }
              if (self.startaftertime == "hour") {
                let adds = shiftafter.getHours() + parseInt(self.startafter);
                shiftafter.setHours(+adds);
              } else if (self.startaftertime == "min") {
                let adds = shiftafter.getMinutes() + parseInt(self.startafter);
                shiftafter.setMinutes(adds);
              }
              let ssafterDate = new Date(shiftafter.getTime());
              let ssbeforeDate = new Date(shiftbefore.getTime());
              //out time
              let shiftendbefore = new Date(
                Date.parse(templist[i].timeEntry_Date)
              );
              let shiftendafter = new Date(
                Date.parse(templist[i].timeEntry_Date)
              );
              let [sehours, seminutes] = templist[i].end_Time.split(":");
              shiftendbefore.setHours(+sehours);
              shiftendbefore.setMinutes(seminutes);
              shiftendbefore.setSeconds("0");
              shiftendbefore.setMilliseconds("0");
              shiftendafter.setHours(+sehours);
              shiftendafter.setMinutes(seminutes);
              if (self.endbeforetime == "hour") {
                let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
                shiftendbefore.setHours(+adds);
              } else if (self.endbeforetime == "min") {
                let adds =
                  shiftendbefore.getMinutes() - parseInt(self.endbefore);
                shiftendbefore.setMinutes(adds);
              }
              if (self.endaftertime == "hour") {
                let adds = shiftendafter.getHours() + parseInt(self.endafter);
                shiftendafter.setHours(+adds);
              } else if (self.endaftertime == "min") {
                let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
                shiftendafter.setMinutes(adds);
              }
              let seafterDate = new Date(shiftendafter.getTime());
              let sebeforeDate = new Date(shiftendbefore.getTime());

              let sslatetime = "";
              let clockin = "-";
              let incon = 0;
              let outcon = 0;
              let clockout = "-";
              let outlist = [];

              let thattimetable = [];
              let thatlatetable = [];

              for (let a = 0; a < RegularArray.length; a++) {
                if (RegularArray[a].clock_In_Time != "-") {
                  if (thattimetable.length != 0) {
                    if (incon != 1) {
                      if (thattimetable[0].Time != "-") {
                        clockin = RegularArray[a].clock_In_Time;
                        let rfrom = new Date(Date.parse(clockin));
                        let frtimezone = rfrom.getTimezoneOffset() * 60000;
                        let frnewDate = new Date(rfrom.getTime() - frtimezone);
                        if (frnewDate > ssafterDate) {
                          var rtimeDiff = Math.abs(frnewDate - ssafterDate);
                          var rhh = Math.floor(rtimeDiff / 1000 / 60 / 60);
                          if (rhh < 10) {
                            rhh = "0" + rhh;
                          }
                          rtimeDiff -= rhh * 1000 * 60 * 60;
                          var rmm = Math.floor(rtimeDiff / 1000 / 60);
                          if (rmm < 10) {
                            rmm = "0" + rmm;
                          }
                          sslatetime = rhh + ":" + rmm + " Hours";
                          thattimetable.push({
                            PunchType: "Regular In",
                            Time: clockin,
                            clock_ID: RegularArray[a].clock_ID,
                            time_ID: RegularArray[a].time_ID,
                            Location: RegularArray[a].clock_In_Location,
                            Lat: RegularArray[a].clock_In_Lattitude,
                            Lang: RegularArray[a].clock_In_Longitude,
                            Late: sslatetime,
                            shifttime: templist[i].start_Time,
                          });
                        } else if (ssbeforeDate > frnewDate) {
                          thattimetable.push({
                            PunchType: "Regular In",
                            Time: clockin,
                            clock_ID: RegularArray[a].clock_ID,
                            time_ID: RegularArray[a].time_ID,
                            Location: RegularArray[a].clock_In_Location,
                            Lat: RegularArray[a].clock_In_Lattitude,
                            Lang: RegularArray[a].clock_In_Longitude,
                            Late: "-",
                            shifttime: templist[i].start_Time,
                          });
                        } else {
                          thattimetable.push({
                            PunchType: "Regular In",
                            Time: clockin,
                            clock_ID: RegularArray[a].clock_ID,
                            time_ID: RegularArray[a].time_ID,
                            Location: RegularArray[a].clock_In_Location,
                            Lat: RegularArray[a].clock_In_Lattitude,
                            Lang: RegularArray[a].clock_In_Longitude,
                            Late: "-",
                            shifttime: templist[i].start_Time,
                          });
                        }
                        incon = 1;
                      }
                    } else {
                      thatlatetable.push({
                        PunchType: "Regular In",
                        Time: RegularArray[a].clock_In_Time,
                        clock_ID: RegularArray[a].clock_ID,
                        time_ID: RegularArray[a].time_ID,
                        Location: RegularArray[a].clock_In_Location,
                        Lat: RegularArray[a].clock_In_Lattitude,
                        Lang: RegularArray[a].clock_In_Longitude,
                        Late: sslatetime,
                        shifttime: templist[i].start_Time,
                      });
                    }
                  } else {
                    clockin = RegularArray[a].clock_In_Time;
                    let rfrom = new Date(Date.parse(clockin));
                    let frtimezone = rfrom.getTimezoneOffset() * 60000;
                    let frnewDate = new Date(rfrom.getTime() - frtimezone);
                    if (frnewDate > ssafterDate) {
                      var rtimeDiff1 = Math.abs(frnewDate - ssafterDate);
                      var rhh1 = Math.floor(rtimeDiff1 / 1000 / 60 / 60);
                      if (rhh1 < 10) {
                        rhh1 = "0" + rhh1;
                      }
                      rtimeDiff1 -= rhh1 * 1000 * 60 * 60;
                      var rmm1 = Math.floor(rtimeDiff1 / 1000 / 60);
                      if (rmm1 < 10) {
                        rmm1 = "0" + rmm1;
                      }
                      sslatetime = rhh1 + ":" + rmm1 + " Hours";
                      thattimetable.push({
                        PunchType: "Regular In",
                        Time: clockin,
                        clock_ID: RegularArray[a].clock_ID,
                        time_ID: RegularArray[a].time_ID,
                        Location: RegularArray[a].clock_In_Location,
                        Lat: RegularArray[a].clock_In_Lattitude,
                        Lang: RegularArray[a].clock_In_Longitude,
                        Late: sslatetime,
                        shifttime: templist[i].start_Time,
                      });
                    } else if (ssbeforeDate > frnewDate) {
                      thattimetable.push({
                        PunchType: "Regular In",
                        Time: clockin,
                        clock_ID: RegularArray[a].clock_ID,
                        time_ID: RegularArray[a].time_ID,
                        Location: RegularArray[a].clock_In_Location,
                        Lat: RegularArray[a].clock_In_Lattitude,
                        Lang: RegularArray[a].clock_In_Longitude,
                        Late: "-",
                        shifttime: templist[i].start_Time,
                      });
                    } else {
                      thattimetable.push({
                        PunchType: "Regular In",
                        Time: clockin,
                        clock_ID: RegularArray[a].clock_ID,
                        time_ID: RegularArray[a].time_ID,
                        Location: RegularArray[a].clock_In_Location,
                        Lat: RegularArray[a].clock_In_Lattitude,
                        Lang: RegularArray[a].clock_In_Longitude,
                        Late: "-",
                        shifttime: templist[i].start_Time,
                      });
                    }
                    incon = 1;
                  }
                }
                let Lists = {
                  ...RegularArray[a],
                };
                outlist.unshift(Lists);
              }
              for (let b = 0; b < outlist.length; b++) {
                if (outlist[b].clock_Out_Time != "-") {
                  if (outcon != 1) {
                    clockout = outlist[b].clock_Out_Time;
                    let rto = new Date(Date.parse(clockout));
                    let rtotimezone = rto.getTimezoneOffset() * 60000;
                    let rtonewDate = new Date(rto.getTime() - rtotimezone);
                    if (rtonewDate > seafterDate) {
                      var retimeDiff = Math.abs(rtonewDate - seafterDate);
                      var rehh = Math.floor(retimeDiff / 1000 / 60 / 60);
                      if (rehh < 10) {
                        rehh = "0" + rehh;
                      }
                      retimeDiff -= rehh * 1000 * 60 * 60;
                      var remm = Math.floor(retimeDiff / 1000 / 60);
                      if (remm < 10) {
                        remm = "0" + remm;
                      }
                      thattimetable.push({
                        PunchType: "Regular Out",
                        Time: clockout,
                        clock_ID: outlist[b].clock_ID,
                        time_ID: outlist[b].time_ID,
                        Location: outlist[b].clock_Out_Location,
                        Lat: outlist[b].clock_Out_Lattitude,
                        Lang: outlist[b].clock_Out_Longitude,
                        Late: "-",
                        shifttime: templist[i].end_Time,
                      });
                    } else if (sebeforeDate > rtonewDate) {
                      thattimetable.push({
                        PunchType: "Regular Out",
                        Time: clockout,
                        clock_ID: outlist[b].clock_ID,
                        time_ID: outlist[b].time_ID,
                        Location: outlist[b].clock_Out_Location,
                        Lat: outlist[b].clock_Out_Lattitude,
                        Lang: outlist[b].clock_Out_Longitude,
                        Late: "-",
                        shifttime: templist[i].end_Time,
                      });
                    } else {
                      thattimetable.push({
                        PunchType: "Regular Out",
                        Time: clockout,
                        clock_ID: outlist[b].clock_ID,
                        time_ID: outlist[b].time_ID,
                        Location: outlist[b].clock_Out_Location,
                        Lat: outlist[b].clock_Out_Lattitude,
                        Lang: outlist[b].clock_Out_Longitude,
                        Late: "-",
                        shifttime: templist[i].end_Time,
                      });
                    }
                    outcon = 1;
                  } else {
                    thatlatetable.push({
                      PunchType: "Regular Out",
                      Time: outlist[b].clock_Out_Time,
                      clock_ID: outlist[b].clock_ID,
                      time_ID: outlist[b].time_ID,
                      Location: outlist[b].clock_Out_Location,
                      Lat: outlist[b].clock_Out_Lattitude,
                      Lang: outlist[b].clock_Out_Longitude,
                      Late: "-",
                      shifttime: templist[i].end_Time,
                    });
                  }
                }
              }

              for (let k = 0; k < temparr.length; k++) {
                //if (temparr[k].PunchType == "Regular In") {
                if (temparr[k].clock_In_Time != "-") {
                  thatlatetable.push({
                    clock_ID: temparr[k].clock_ID,
                    time_ID: temparr[k].time_ID,
                    PunchType: "Regular In",
                    Location: temparr[k].clock_In_Location,
                    Lat: temparr[k].clock_In_Lattitude,
                    Lang: temparr[k].clock_In_Longitude,
                    Time: temparr[k].clock_In_Time,
                    shifttime: templist[i].start_Time,
                  });
                }
                if (temparr[k].clock_Out_Time != "-") {
                  thatlatetable.push({
                    clock_ID: temparr[k].clock_ID,
                    time_ID: temparr[k].time_ID,
                    PunchType: "Regular Out",
                    Location: temparr[k].clock_Out_Location,
                    Lat: temparr[k].clock_Out_Lattitude,
                    Lang: temparr[k].clock_Out_Longitude,
                    Time: temparr[k].clock_Out_Time,
                    shifttime: templist[i].end_Time,
                  });
                }
                //}
              }
              let sst = false;
              if (templist[i].remark == "Approved") {
                sst = true;
              }

              let Lists = {
                ...templist[i],
                clock_In_Time: intime,
                clock_Out_Time: outtime,
                oT_Clock_In_Time: otintime,
                oT_Clock_Out_Time: otouttime,
                oT_Start_Time: otsttime,
                oT_End_Time: otedtime,
                regcount: cinoutcount,
                otcount: otcinoutcount,
                clocklist: clist,
                yescondi: yesc,
                isitthere: iknow,
                maincondi: maincondi,
                aprvd: sst,
                status: status,
                regulartab: thattimetable,
                irregulartab: thatlatetable,
              };
              self.DayViewList.push(Lists);
              self.DayViewListForReal.push(Lists);
            }
            self.thedsearchlength = self.DayViewListForReal.length;
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get DayList Fail", 23, "Critical");
            //alert(error);
            self.LoadingDialog = false;
          });
      }
    },
    GetMonthList() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Stafftimeattendance/GetMonthViewOfSTA`, temp)
      .then(function (response) {
        let list = response.data.data;
        self.MonthViewList = list.map((v) => ({
          ...v,
          checked: false,
        }));
        self.MonthViewList1 = self.MonthViewList;
      })
      .catch(function (error) {
        self.LogTrace(error, "Get MonthList Fail", 23, "Critical");
        //alert(error);
      });
    },
    checkmyemp(id) {
      this.MonthViewList = this.MonthViewList.map((v) => ({
        ...v,
        checked: id === v.employee_ID,
      }));
      this.selected1 = 
      this.MonthViewList
      .filter(user => user.checked)
      .map((v) => {
        return {
          empid: v.employee_ID,
          clkid: v.clock_ID,
          empname: v.fullName
        };
      });
      // console.log(this.selected1);
    },
    GetTimeView() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceView/GetTimeAttendanceViewByEmployeeID`,
          temp
        )
        .then(function (response) {
          self.TimeViewList = response.data.data;
          self.calendarView = self.TimeViewList[0].calendar_View;
        });
    },
    GetTimeAttSetting() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,
          temp
        )
        .then(function (response) {
          self.TimeSettingList = response.data.data;
          if(self.TimeSettingList.length != 0)
          {
            let [stbefore, stbeforetime] =
            self.TimeSettingList[0].start_Time_Before.split(" ");
          let [stafter, staftertime] =
            self.TimeSettingList[0].start_Time_After.split(" ");
          self.startbefore = stbefore;
          self.startbeforetime = stbeforetime;
          self.startafter = stafter;
          self.startaftertime = staftertime;

          let [edbefore, edbeforetime] =
            self.TimeSettingList[0].end_Time_Before.split(" ");
          let [edafter, edaftertime] =
            self.TimeSettingList[0].end_Time_After.split(" ");
          self.endbefore = edbefore;
          self.endbeforetime = edbeforetime;
          self.endafter = edafter;
          self.endaftertime = edaftertime;
          }
          
        });
    },
    Converttolocal(item) {
      if (item != "-" && item != null && item != "") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    forexpstatus(intime, starttime, date) {
      let self = this;
      let result = "";
      if (intime != "-") {
        let from = new Date(Date.parse(intime));
        let shiftstartbefore = new Date(Date.parse(date));
        let shiftstartafter = new Date(Date.parse(date));
        let [sshours, ssminutes] = starttime.split(":");
        shiftstartbefore.setHours(+sshours);
        shiftstartbefore.setMinutes(ssminutes);
        shiftstartbefore.setSeconds("0");
        shiftstartbefore.setMilliseconds("0");
        shiftstartafter.setHours(+sshours);
        shiftstartafter.setMinutes(ssminutes);
        if (self.startbeforetime == "hour") {
          let adds = shiftstartbefore.getHours() - parseInt(self.startbefore);
          shiftstartbefore.setHours(+adds);
        } else if (self.startbeforetime == "min") {
          let adds = shiftstartbefore.getMinutes() - parseInt(self.startbefore);
          shiftstartbefore.setMinutes(adds);
        }
        if (self.startaftertime == "hour") {
          let adds = shiftstartafter.getHours() + parseInt(self.startafter);
          shiftstartafter.setHours(+adds);
        } else if (self.startaftertime == "min") {
          let adds = shiftstartafter.getMinutes() + parseInt(self.startafter);
          shiftstartafter.setMinutes(adds);
        }
        let frtimezone = from.getTimezoneOffset() * 60000;
        let frnewDate = new Date(from.getTime() - frtimezone);
        let ssnewDate = new Date(shiftstartafter.getTime());
        let ssbnewDate = new Date(shiftstartbefore.getTime());
        if (frnewDate > ssnewDate) {
          result = "Late";
        } else if (ssbnewDate > frnewDate) {
          result = "Early";
        } else {
          result = "In time";
        }
      } else {
        result = "-";
      }
      return result;
    },
    ShiftVali(item) {
      let self = this;
      if (item.maincondi == false || item.maincondi == "false") {
        if (item.yescondi == "true" || item.yescondi == true) {
          return true;
        } else {
          if (item.start_Time != "-" && item.oT_Start_Time == "-") {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.start_Time.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.end_Time.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (item.start_Time == "-" && item.oT_Start_Time != "-") {
            let from = new Date(Date.parse(item.oT_Clock_In_Time));
            let to = new Date(Date.parse(item.oT_Clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.oT_Start_Time.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.oT_End_Time.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (item.start_Time != "-" && item.oT_Start_Time != "-") {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.start_Time.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.end_Time.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }

            let otfrom = new Date(Date.parse(item.oT_Clock_In_Time));
            let otto = new Date(Date.parse(item.oT_Clock_Out_Time));

            //ot in time
            let otshiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsshours, otssminutes] = item.oT_Start_Time.split(":");
            otshiftstartbefore.setHours(+otsshours);
            otshiftstartbefore.setMinutes(otssminutes);
            otshiftstartbefore.setSeconds("0");
            otshiftstartbefore.setMilliseconds("0");
            otshiftstartafter.setHours(+otsshours);
            otshiftstartafter.setMinutes(otssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                otshiftstartbefore.getHours() - parseInt(self.startbefore);
              otshiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                otshiftstartbefore.getMinutes() - parseInt(self.startbefore);
              otshiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds =
                otshiftstartafter.getHours() + parseInt(self.startafter);
              otshiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                otshiftstartafter.getMinutes() + parseInt(self.startafter);
              otshiftstartafter.setMinutes(adds);
            }
            //ot out time
            let otshiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsehours, otseminutes] = item.oT_End_Time.split(":");
            otshiftendbefore.setHours(+otsehours);
            otshiftendbefore.setMinutes(otseminutes);
            otshiftendbefore.setSeconds("0");
            otshiftendbefore.setMilliseconds("0");
            otshiftendafter.setHours(+otsehours);
            otshiftendafter.setMinutes(otseminutes);
            if (self.endbeforetime == "hour") {
              let adds = otshiftendbefore.getHours() - parseInt(self.endbefore);
              otshiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds =
                otshiftendbefore.getMinutes() - parseInt(self.endbefore);
              otshiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = otshiftendafter.getHours() + parseInt(self.endafter);
              otshiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = otshiftendafter.getMinutes() + parseInt(self.endafter);
              otshiftendafter.setMinutes(adds);
            }

            let otfrtimezone = otfrom.getTimezoneOffset() * 60000;
            let otfrnewDate = new Date(otfrom.getTime() - otfrtimezone);
            let ottotimezome = otto.getTimezoneOffset() * 60000;
            let ottonewDate = new Date(otto.getTime() - ottotimezome);
            let otssnewDate = new Date(otshiftstartafter.getTime());
            let otssbnewDate = new Date(otshiftstartbefore.getTime());
            let otsenewDate = new Date(otshiftendafter.getTime());
            let otsebnewDate = new Date(otshiftendbefore.getTime());
            let otsslatetime = true;
            if (item.approve != true) {
              if (item.oT_Clock_In_Time != "-") {
                if (otfrnewDate > otssnewDate) {
                  otsslatetime = false;
                } else if (otssbnewDate > otfrnewDate) {
                  otsslatetime = false;
                } else {
                  otsslatetime = true;
                }
                if (item.oT_Clock_Out_Time != "-" && otsslatetime == true) {
                  if (ottonewDate > otsenewDate) {
                    otsslatetime = false;
                  } else if (otsebnewDate > ottonewDate) {
                    otsslatetime = false;
                  } else {
                    otsslatetime = true;
                  }
                }
              }
            } else {
              otsslatetime = true;
            }
            if (sslatetime == true && otsslatetime == true) {
              return true;
            } else if (
              item.clock_In_Time != "-" &&
              item.clock_Out_Time != "-" &&
              item.oT_Clock_In_Time == "-" &&
              item.oT_Clock_Out_Time == "-"
            ) {
              if (sslatetime == true) {
                return true;
              } else {
                return false;
              }
            } else if (
              item.clock_In_Time == "-" &&
              item.clock_Out_Time == "-" &&
              item.oT_Clock_In_Time != "-" &&
              item.oT_Clock_Out_Time != "-"
            ) {
              if (otsslatetime == true) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        if (
          item.clock_In_Time == "-" &&
          item.clock_Out_Time == "-" &&
          item.oT_Clock_In_Time == "-" &&
          item.oT_Clock_Out_Time == "-"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    Opensynctimedatadialog() {
      let self = this;
      self.csvmonthname = null;
      self.csvname = null;
      self.chosenfiles = [];
      self.jarvizfile = null;
      self.showup = false;
      self.showjarviz = false;
      self.synctimedatadialog = true;
    },
    Opensynctimemonthdatadialog() {
      let self = this;
      self.csvmonthname = null;
      self.csvname = null;
      self.chosenfiles = [];
      self.showjarviz = false;
      self.showup = false;
      self.synctimemonthdatadialog = true;
    },
    async ValidateBalancedays(EmpID, ID) {
      //let self=this;
      try {
        const filter = this.LeaveList.find((x) => x.timeOff_ID == ID);
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: EmpID,
          timeoff_ID: filter.timeOff_ID,
        };
        const respone = await axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyIdTimeOffId`,
          temp
        );
        let date = respone.data.data;

        let availability_Days = filter.availability_Days;
        if (
          availability_Days == date.avalable_Day ||
          availability_Days < date.avalable_Day
        ) {
          this.timeoffAdd.Balance = 0;
          this.isHalf = 0;
          // this.sentReq = true;
        } else {
          let result = availability_Days - date.avalable_Day;
          result = String(result);
          if (result == 0.5) {
            this.timeoffAdd.Balance = "Half";
            this.isHalf = 0.5;
          } else if (result.match(/\./)) {
            let final = result.split(".")[0];
            this.timeoffAdd.Balance = final + " and half";
            this.isHalf = +final + 0.5;
          } else {
            this.timeoffAdd.Balance = result;
            this.isHalf = result;
          }
        }
      } catch (ex) {
        throw ex;
      }

    },

    async OpenAddTimeDialog(item) {
      let self = this;
      self.LoadingDialog = true;
      await self.Getpersonal(item.employee_ID);
      await self.GetLeaveTypeWithRemainingDays(item.employee_ID)
      self.AddTimedialog = true;
      self.timetoAdd.TheName = item.employee_Code + " " + item.fullName + " " + item.department_Name;
      self.timetoAdd.Employee_ID = item.employee_ID;
      self.timeoffAdd.Employee_ID = item.employee_ID;
      self.timetoAdd.TheType = "Regular In";
      let bfk = new Date(self.gettodaydate);
      let realDate = new Date(bfk);
      let mytodayDate = realDate.getDate();
      let mydate = "";
      //console.log("condition",mytodayDate);
      if (mytodayDate < 10)
      {
        mydate = realDate.getMonth()+1<10
        ?(realDate.getFullYear() +
          "-" + "0" +
          (realDate.getMonth() + 1) +
          "-" + "0" +
          realDate.getDate())
          :(realDate.getFullYear() +
          "-" +
          (realDate.getMonth() + 1) +
          "-" + "0" +
          realDate.getDate());
        // self.timetoAdd.TheDate =
        //   realDate.getMonth()+1<10
        //   ?(realDate.getFullYear() +
        //   "-"  + "0" +
        //   (realDate.getMonth() + 1) +
        //   "-" + "0" +
        //   realDate.getDate())
        //   :(realDate.getFullYear() +
        //   "-"  +
        //   (realDate.getMonth() + 1) +
        //   "-" + "0" +
        //   realDate.getDate());
        self.timetoAdd.TheDate = self.$moment(mydate).format('DD/MM/YYYY');
      }
      else
      {
        mydate = realDate.getMonth()+1<10
        ?(realDate.getFullYear() +
          "-" + "0" +
          (realDate.getMonth() + 1) +
          "-" +
          realDate.getDate())
          :(realDate.getFullYear() +
          "-" +
          (realDate.getMonth() + 1) +
          "-" + realDate.getDate());
        // self.timetoAdd.TheDate = realDate.getMonth()+1<10 ? (realDate.getFullYear() + "-" + "0" + (realDate.getMonth() + 1) +
        //   "-" +
        //   realDate.getDate())
        // :(realDate.getFullYear() +
        //   "-" +
        //   (realDate.getMonth() + 1) +
        //   "-" +
        //   realDate.getDate());
        self.timetoAdd.TheDate = self.$moment(mydate).format('DD/MM/YYYY');
      }
      self.clockinDate = mydate;
      self.timeoffAdd.StartDate = self.$moment(mydate).format('DD/MM/YYYY');
      self.timeoffAdd.EndDate = self.$moment(mydate).format('DD/MM/YYYY');
      self.timeoffAdd.Date = self.$moment(mydate).format('DD/MM/YYYY');
      self.timeoffAdd.StartTime = "08:00";
      self.timeoffAdd.EndTime = "12:00";

      const today = new Date();
      const gethrs = today.getHours();
      const getmin = today.getMinutes();
      let time = "";
      if (gethrs < 10) {
        if (getmin < 10) {
          time = "0" + today.getHours() + ":" + "0" + today.getMinutes();
        } else {
          time = "0" + today.getHours() + ":" + today.getMinutes();
        }
      } else {
        if (getmin < 10) {
          time = today.getHours() + ":" + "0" + today.getMinutes();
        } else {
          time = today.getHours() + ":" + today.getMinutes();
        }
      }
      // console.log("clock in time", time);
      self.timetoAdd.TheTime = time;
      self.timetoAdd.start_Time = item.start_Time;
      self.timetoAdd.end_Time = item.end_Time;
      self.timetoAdd.ot_start_Time = item.oT_Start_Time;
      self.timetoAdd.ot_end_Time = item.oT_End_Time;
      self.timetoAdd.time_ID = item.time_ID;
      self.timetoAdd.isitthere = item.isitthere;

      self.LoadingDialog = false;
    },
    async RequestTimeoff() {
      let self = this;
      self.LoadingDialog = true;
      let validate = true;
      let startdate = "";
      let enddate = "";
      let starttime = "";
      let endtime = "";
      //await self.ValidateBalancedays(self.timeoffAdd.Employee_ID, self.timeoffAdd.TimeoffID)

      if (self.timeoffAdd.Alldaycheck == true) 
      {
        startdate = self.timeoffAdd.StartDate;
        enddate = self.timeoffAdd.EndDate;
        starttime = "";
        endtime = "";
        
        if (
          self.$moment(self.timeoffAdd.StartDate, "DD/MM/YYYY") <= (self.$moment(self.timeoffAdd.EndDate, "DD/MM/YYYY"))
        ) 
        {
          validate = true;
        } 
        else 
        {
          validate = false;
          alert("Start date cant be after End date");
          self.LoadingDialog = false;
        }
      } 
      else 
      {
        startdate = self.timeoffAdd.Date;
        enddate = self.timeoffAdd.Date;
        starttime = self.timeoffAdd.StartTime;
        endtime = self.timeoffAdd.EndTime;
        let start_Time = self.$moment(self.timeoffAdd.StartTime, "h:mma");
        let end_time = self.$moment(self.timeoffAdd.EndTime, "h:mma");
        if (start_Time.isBefore(end_time)) {
          validate = true;
        } else {
          validate = false;
          alert("Start Time cant be after End time");
        }
      }
      if (self.uploadedFiles.length) {
        for (var i = 0; i < self.uploadedFiles.length; i++) {
          self.timeoffAdd.attachment.push({
            attachment_file: self.uploadedFiles[i].imgurl,
            filename: self.uploadedFiles[i].name,
            filetype: self.uploadedFiles[i].type,
          });
        }
      }
      let anyleft = self.checkDaysLeft();
      if (self.timeoffAdd.ApproverID != 0) {
        if (anyleft) {
          if (validate == true) {
            let temp = {
              timeoff_ID: self.timeoffAdd.TimeoffID,
              wholeDay: self.timeoffAdd.Alldaycheck,
              start_Date: self.$moment(startdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              end_Date: self.$moment(enddate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              start_Time: starttime,
              end_Time: endtime,
              balance: self.timeoffAdd.Balance,
              requester_ID: self.timeoffAdd.Employee_ID,
              approver_ID: parseInt(store.state.employeeID),
              company_ID: store.state.companyID,
              workflowType: "Manual",
              message: self.timeoffAdd.message,
              saveDraft: false,
              attachments: self.timeoffAdd.attachment,
            };
            axios
              .post(`${self.url}TimeOff_Request/AddTimeOff_RequestForEmp`, temp)
              .then(function (response) {
                if (response.data.message == "Posted invalid data.") {
                  self.LoadingDialog = false;
                  alert(
                    "Please recheck the date since there seems to be a clash with another request"
                  );
                } else {
                  alert(response.data.message);
                  self.timeoffdialog = false;
                  self.GetDayList(1);
                  // self.LoadingDialog = false;
                }
              });
          }
          else {
            self.LoadingDialog = false;
          }
        }
        else {
          alert("The Time Off requested is more than balance you have");
          self.LoadingDialog = false;
        }
      }
      else {
        alert("Please fill Report to Field in Personal Profile.");
        self.LoadingDialog = false;
      }
    },

    CloseConfirmdialog() {
      this.Confirmdialog = false;
      this.AddTimedialog = true;
    },

    ConfirmtoSave() {
      let self = this;
      let stoprightthere = false;
      let shift = null;
      let before = null;
      let after = null;
      if (self.timetoAdd.TheType == "Regular In" && self.timetoAdd.start_Time != "-") 
      {
        shift = self.timetoAdd.start_Time;
        before = self.startbefore;
        after = self.startafter;
        self.timetoAdd.OtherType = "Regular";
      }
      else if (self.timetoAdd.TheType == "Regular Out" && self.timetoAdd.end_Time != "-") 
      {
        shift = self.timetoAdd.end_Time;
        before = self.endbefore;
        after = self.endafter;
        self.timetoAdd.OtherType = "Regular";
      } 
      else if (self.timetoAdd.TheType == "Overtime In" && self.timetoAdd.ot_start_Time != "-") 
      {
        shift = self.timetoAdd.ot_start_Time;
        before = self.startbefore;
        after = self.startafter;
        self.timetoAdd.OtherType = "Overtime";
      } 
      else if (self.timetoAdd.TheType == "Overtime Out" && self.timetoAdd.ot_end_Time != "-") 
      {
        shift = self.timetoAdd.ot_end_Time;
        before = self.endbefore;
        after = self.endafter;
        self.timetoAdd.OtherType = "Overtime";
      } 
      else 
      {
        stoprightthere = true;
      }
      if (stoprightthere == false) 
      {
        // console.log("beforeconvert",self.timetoAdd.TheDate );

        let addingtime = new Date(Date.parse(self.clockinDate));
        // console.log("adddate", addingtime);
        let [hh, mm] = self.timetoAdd.TheTime.split(":");
        addingtime.setHours(+hh);
        addingtime.setMinutes(mm);
        let shiftbefore = new Date(Date.parse(self.clockinDate));
        let shiftafter = new Date(Date.parse(self.clockinDate));
        let [hours, minutes] = shift.split(":");
        shiftbefore.setHours(+hours);
        shiftbefore.setMinutes(minutes);
        shiftafter.setHours(+hours);
        shiftafter.setMinutes(minutes);
        if (self.startbeforetime == "hour") 
        {
          let adds = shiftbefore.getHours() - parseInt(before);
          shiftbefore.setHours(+adds);
        } 
        else if (self.startbeforetime == "min") 
        {
          let adds = shiftbefore.getMinutes() - parseInt(before);
          shiftbefore.setMinutes(adds);
        }
        if (self.startaftertime == "hour") 
        {
          let adds = shiftafter.getHours() + parseInt(after);
          shiftafter.setHours(+adds);
        } 
        else if (self.startaftertime == "min") 
        {
          let adds = shiftafter.getMinutes() + parseInt(after);
          shiftafter.setMinutes(adds);
        }
        let nowtime = new Date(addingtime.getTime());
        let aftertime = new Date(shiftafter.getTime());
        let beforetime = new Date(shiftbefore.getTime());
        self.AddTimedialog = false;
        const getutcmin = addingtime.getUTCMinutes();
        const getutchrs = addingtime.getUTCHours();
        let utctime = "";
        if (getutchrs < 10) 
        {
          if (getutcmin < 10) 
          {
            utctime =
              addingtime.getUTCFullYear() +
              "/" +
              (addingtime.getUTCMonth() + 1) +
              "/" +
              addingtime.getUTCDate() +
              " " +
              "0" +
              addingtime.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          } 
          else 
          {
            utctime =
              addingtime.getUTCFullYear() +
              "/" +
              (addingtime.getUTCMonth() + 1) +
              "/" +
              addingtime.getUTCDate() +
              " " +
              "0" +
              addingtime.getUTCHours() +
              ":" +
              getutcmin;
          }
        } 
        else 
        {
          if (getutcmin < 10) {
            utctime =
              addingtime.getUTCFullYear() +
              "/" +
              (addingtime.getUTCMonth() + 1) +
              "/" +
              addingtime.getUTCDate() +
              " " +
              addingtime.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          } 
          else 
          {
            utctime =
              addingtime.getUTCFullYear() +
              "/" +
              (addingtime.getUTCMonth() + 1) +
              "/" +
              addingtime.getUTCDate() +
              " " +
              addingtime.getUTCHours() +
              ":" +
              getutcmin;
          }
        }
        self.timetoAdd.TheUTCTime = utctime;
        if (nowtime > aftertime) 
        {
          self.Confirmdialog = true;
        } 
        else if (beforetime > nowtime)
        {
          self.Confirmdialog = true;
        } 
        else 
        {
          self.AddClockInOut();
        }
      } else 
      {
        alert("There is no Shift Time for selected Time Type.");
      }
    },

    AddClockInOut() {
      let self = this;
      self.LoadingDialog = true;
      let temparr = null;
      //let getdate = "";
      // console.log(self.timetoAdd.TheDate);
      // let ththth = new Date(self.timetoAdd.TheDate);
      // let month = String(ththth.getMonth() + 1);
      // let day = String(ththth.getDate());
      // const year = String(ththth.getFullYear());
      // if (month.length < 2) month = "0" + month;
      // if (day.length < 2) day = "0" + day;
      // getdate = `${day}/${month}/${year}`;
      // console.log("clockinTime", self.timetoAdd.TheUTCTime)
      if (self.timetoAdd.TheType.includes("In"))
      {
        if (self.timetoAdd.isitthere == "NoIn")
        {
          temparr = {
            the_Date: self.timetoAdd.TheDate,
            clock_In_Time: self.timetoAdd.TheUTCTime,
            // clock_In_Time: self.$moment(self.timetoAdd.TheUTCTime, "YYYY/DD/MM HH:mm").format("YYYY/MM/DD HH:mm"),
            clock_Out_Time: "-",
            clock_In_Location: "N/A",
            clock_Out_Location: "-",
            clock_In_Lattitude: "",
            clock_In_Longitude: "",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            clock_In_Out_Type: "Manual",
            irregular_In_Time: "-",
            irregular_Out_Time: "-",
            irregular_In_Location: "-",
            irregular_Out_Location: "-",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            approve: false,
            time_Type: self.timetoAdd.OtherType,
            employee_ID: self.timetoAdd.Employee_ID,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
        } 
        else 
        {
          temparr = {
            the_Date: self.timetoAdd.TheDate,
            clock_In_Time: "-",
            clock_Out_Time: "-",
            clock_In_Location: "-",
            clock_Out_Location: "-",
            clock_In_Lattitude: "",
            clock_In_Longitude: "",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            clock_In_Out_Type: "Manual",
            irregular_In_Time: self.timetoAdd.TheUTCTime,
            // irregular_In_Time: self.$moment(self.timetoAdd.TheUTCTime, "YYYY/D/M HH:mm").format("YYYY/M/D HH:mm"),
            irregular_Out_Time: "-",
            irregular_In_Location: "N/A",
            irregular_Out_Location: "-",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            approve: false,
            time_Type: self.timetoAdd.OtherType,
            employee_ID: self.timetoAdd.Employee_ID,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
        }
      } 
      else if (self.timetoAdd.TheType.includes("Out")) 
      {
        if (self.timetoAdd.isitthere == "NoOut") 
        {
          temparr = {
            the_Date: self.timetoAdd.TheDate,
            clock_In_Time: "-",
            clock_Out_Time: self.timetoAdd.TheUTCTime,
            // clock_Out_Time: self.$moment(self.timetoAdd.TheUTCTime, "YYYY/D/M HH:mm").format("YYYY/M/D HH:mm"),
            clock_In_Location: "-",
            clock_Out_Location: "N/A",
            clock_In_Lattitude: "",
            clock_In_Longitude: "",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            clock_In_Out_Type: "Manual",
            irregular_In_Time: "-",
            irregular_Out_Time: "-",
            irregular_In_Location: "-",
            irregular_Out_Location: "-",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            approve: false,
            time_Type: self.timetoAdd.OtherType,
            employee_ID: self.timetoAdd.Employee_ID,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
        } 
        else 
        {
          temparr = {
            the_Date: self.timetoAdd.TheDate,
            clock_In_Time: "-",
            clock_Out_Time: "-",
            clock_In_Location: "-",
            clock_Out_Location: "-",
            clock_In_Lattitude: "",
            clock_In_Longitude: "",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            clock_In_Out_Type: "Manual",
            irregular_In_Time: "-",
            irregular_Out_Time: self.timetoAdd.TheUTCTime,
            // irregular_Out_Time: self.$moment(self.timetoAdd.TheUTCTime, "YYYY/D/M HH:mm").format("YYYY/M/D HH:mm"),
            irregular_In_Location: "-",
            irregular_Out_Location: "N/A",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            approve: false,
            time_Type: self.timetoAdd.OtherType,
            employee_ID: self.timetoAdd.Employee_ID,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
        }
      }
      axios.post(`${self.url}ClockInOut/AddClockInOutWithManual`, temparr)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0) 
          {
            alert(response.data.message);
            self.Confirmdialog = false;
            self.LoadingDialog = false;
            self.GetDayList(1);
          }
          else
          {
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },

    closeUpload() {
      let self = this;
      self.csvmonthname = null;
      self.csvname = null;
      self.syncmonthtimedatadialog = false;
      self.chosenfile = [];
      self.chosenfiles = [];
      self.showup = false;
      self.showjarviz = false;
    },
    closeSyncDialog() {
      let self = this;
      self.csvmonthname = null;
      self.csvname = null;
      self.synctimedatadialog = false;
      self.chosenfile = [];
      self.chosenfiles = [];
      self.jarvizfile = null;
      self.showup = false;
      self.showjarviz = false;
    },
    closeSyncMonDialog() {
      let self = this;
      self.csvmonthname = null;
      self.csvname = null;
      self.synctimemonthdatadialog = false;
      self.chosenfile = [];
      self.chosenfiles = [];
      self.jarvizmonthfile = null;
      self.showup = false;
      self.showjarviz = false;
    },
    closeinput() {
      let self = this;
      self.csvmonthname = null;
      self.csvname = null;
      self.showjarviz = false;
      self.showup = false;
      self.chosenfile = [];
      self.chosenfiles = [];
      self.jarvizmonthfile = null;
      self.jarvizfile = null;
    },
    getupload() {
      let self = this;
      document.getElementById("fileInputButton").value = "";
      document.getElementById("fileInputButton").click();
      self.csvmonthname = null;
      self.csvname = null;
      self.showup = true;
      self.showjarviz = false;
    },
    getupload1() {
      let self = this;
      document.getElementById("fileInputButton1").value = "";
      document.getElementById("fileInputButton1").click();
      self.csvmonthname = null;
      self.csvname = null;
      self.showup = true;
      self.showjarviz = false;
    },
    getimportjarviz() {
      let self = this;
      document.getElementById("fileInputButtonJarviz").click();
      self.jarvizfile = null;
      self.jarvizmonthfile = null;
      self.showup = false;
      self.showjarviz = true;
      self.show = true;
      self.upload = false;
    },
    getimportmonthjarviz() {
      let self = this;
      document.getElementById("fileInputButtonMonthJarviz").click();
      self.jarvizfile = null;
      self.jarvizmonthfile = null;
      self.showup = false;
      self.showjarviz = true;
      self.show = true;
      self.upload = false;
    },
    sortBy: function (key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
    },
    loadCSV(e) {
      let self = this;
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        self.csvname = e.target.files[0].name;
        reader.onload = function (event) {
          let csv = event.target.result;
          self.parse_csv = self.csvJSON(csv);
        };
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Cannot Read File!");
          }
        };
      } else {
        alert("Not support");
      }
    },
    MonthloadCSV(e) {
      let self = this;
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        self.csvmonthname = e.target.files[0].name;
        reader.onload = function (event) {
          let csv = event.target.result;
          self.parse_csv = self.csvJSON(csv);
        };
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Cannot Read File!");
          }
        };
      } else {
        alert("Not support");
      }
    },
    changedigittodate(date) {
      let y = date.substr(0, 4),
        m = date.substr(4, 2) - 1,
        d = date.substr(6, 2);
      let D = new Date(y, m, d);
      return D.getFullYear() == y && D.getMonth() == m && D.getDate() == d
        ? D
        : "invalid date";
    },
    //Jarviz Check IN Date format change for import
    ChangeDateToYYYYMMDD(date) {
      let Dat = new Date(date);
      let someDate = Dat.setDate(Dat.getDate() + 1);
      let realDate = new Date(someDate);
      let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
      let y = dateformat.substr(0, 4),
        m = dateformat.substr(4, 2) - 1,
        d = dateformat.substr(6, 2);
      let D = new Date(y, m, d);
      return D.getFullYear() == y && D.getMonth() == m && D.getDate() == d
        ? D
        : "invalid date";
    },
    //Jarviz Check IN And Time UTC format change for import
    ChangeToUTCDateTimeCheckIn(dig, date) {
      if (
        (dig != null && dig != undefined && dig != "") ||
        (date != null && date != undefined && date != "")
      ) {
        let resulr_int = "";
        let int_number = dig.trim();
        resulr_int = int_number;
        let m = resulr_int.substr(3, 4),
          h = resulr_int.substr(0, 2);
        let Dat = new Date(date);
        let someDate = Dat.setDate(Dat.getDate() + 1);
        let realDate = new Date(someDate);
        let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
        let yr = dateformat.substr(0, 4),
          mo = dateformat.substr(4, 2) - 1,
          dy = dateformat.substr(6, 2);
        let nD = new Date(yr, mo, dy);
        nD.setHours(h);
        nD.setMinutes(m);

        const getutcmin = nD.getUTCMinutes();
        const getutchrs = nD.getUTCHours();
        let utctime = "";
        if (getutchrs < 10) {
          if (getutcmin < 10) {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              nD.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          } else {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              "0" +
              nD.getUTCHours() +
              ":" +
              getutcmin;
          }
        } else {
          if (getutcmin < 10) {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              nD.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          } else {
            utctime =
              nD.getUTCFullYear() +
              "/" +
              (nD.getUTCMonth() + 1) +
              "/" +
              nD.getUTCDate() +
              " " +
              nD.getUTCHours() +
              ":" +
              getutcmin;
          }
        }

        return utctime;
      } else {
        let utctime = "-";
        return utctime;
      }
    },
    //Jarviz Check IN And Time Local format change for import
    ChangeToLocalDateTimeCheckIn(dig, date) {
      if (
        (dig != null && dig != undefined && dig != "") ||
        (date != null && date != undefined && date != "")
      ) {
        let resulr_int = "";
        let int_number = dig.trim();
        resulr_int = int_number;
        let m = resulr_int.substr(3, 4),
          h = resulr_int.substr(0, 2);
        let Dat = new Date(date);
        let someDate = Dat.setDate(Dat.getDate() + 1);
        let realDate = new Date(someDate);
        let dateformat = realDate.toISOString().substr(0, 10).replace(/-/g, "");
        let yr = dateformat.substr(0, 4),
          mo = dateformat.substr(4, 2) - 1,
          dy = dateformat.substr(6, 2);
        let nD = new Date(yr, mo, dy);

        let localtime = "";

        localtime =
          nD.getFullYear() +
          "/" +
          (nD.getMonth() + 1) +
          "/" +
          nD.getDate() +
          " " +
          h +
          ":" +
          m;

        return localtime;
      } else {
        let localtime = "-";
        return localtime;
      }
    },
    changedigittotime(dig, date) {
      let resulr_int = "";
      let int_number = dig.trim();
      let int_length = int_number.length;
      if (int_length == 3) {
        resulr_int = "0" + int_number;
      } else {
        resulr_int = int_number;
      }
      let m = parseInt(resulr_int.substr(2, 4)),
        h = parseInt(resulr_int.substr(0, 2));

      let yr = date.substr(0, 4),
        mo = date.substr(4, 2) - 1,
        dy = date.substr(6, 2);
      let nD = new Date(yr, mo, dy);
      nD.setHours(h);
      nD.setMinutes(m);

      const getutcmin = nD.getUTCMinutes();
      const getutchrs = nD.getUTCHours();
      let utctime = "";
      if (getutchrs < 10) {
        if (getutcmin < 10) {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            "0" +
            nD.getUTCHours() +
            ":" +
            "0" +
            getutcmin;
        } else {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            "0" +
            nD.getUTCHours() +
            ":" +
            getutcmin;
        }
      } else {
        if (getutcmin < 10) {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            nD.getUTCHours() +
            ":" +
            "0" +
            getutcmin;
        } else {
          utctime =
            nD.getUTCFullYear() +
            "/" +
            (nD.getUTCMonth() + 1) +
            "/" +
            nD.getUTCDate() +
            " " +
            nD.getUTCHours() +
            ":" +
            getutcmin;
        }
      }

      return utctime;
    },
    changedigittolocaltime(dig, date) {
      let resulr_int = "";
      let int_number = dig.trim();
      let int_length = int_number.length;
      if (int_length == 3) {
        resulr_int = "0" + int_number;
      } else {
        resulr_int = int_number;
      }
      let m = resulr_int.substr(2, 4),
        h = resulr_int.substr(0, 2);

      let yr = date.substr(0, 4),
        mo = date.substr(4, 2) - 1,
        dy = date.substr(6, 2);
      let nD = new Date(yr, mo, dy);

      let localtime = "";

      localtime =
        nD.getFullYear() +
        "/" +
        (nD.getMonth() + 1) +
        "/" +
        nD.getDate() +
        " " +
        h +
        ":" +
        m;

      return localtime;
    },
    csvJSON(csv) {
      let self = this;
      self.chosenfile = [];
      self.chosenfiles = [];
      let lines = csv.split("\n");
      let headers = lines[0].split(",").map(function (u) {
        return u.trim();
      });
      self.parse_header = lines[0].split(",");
      lines[0].split(",").forEach(function (key) {
        self.sortOrders[key] = 1;
      });
      lines.map(function (line, indexLine) {
        if (indexLine < 1) return;
        let obj = {};
        let currentline = line.split(",");

        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader];
        });
        self.chosenfiles.push(obj);
      });
      self.chosenfiles.pop();
      return self.chosenfiles;
    },
    UploadSyncTime() {
      let self = this;
      self.LoadingDialog = true;
      if (self.csvname == "Sync Time for Day view.csv") {
        if (self.chosenfiles.length != 0) {
          let validate = self.validatename();
          if (validate == true) {
            let tempdata = self.tempuploadlist();
            axios
              .post(`${self.url}ClockInOut/ImportExternalClockInOut`, tempdata)
              .then(function (response) {
                if (response.data.status == 0) {
                  // if (response.data.message == "Add Successfully") {
                  //   alert("Upload Successfully!");
                  // } else {
                  //   alert(response.data.message);
                  // }
                  alert(response.data.message);
                  self.LoadingDialog = false;
                  self.synctimedatadialog = false;
                  self.synctimemonthdatadialog = false;
                  self.selected = [];
                  self.showup = false;
                  self.DayViewList = [];
                  self.chosenfile = [];
                  self.chosenfiles = [];
                  self.GetDayList();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Upload SyncTime Fail", 23, "Low");
                alert(error);
                self.LoadingDialog = false;
                self.showup = false;
                self.chosenfile = [];
                self.chosenfiles = [];
                self.synctimedatadialog = false;
                self.synctimemonthdatadialog = false;
              });
          } else {
            alert("Employee ID and Employee Name doesn't match");
            self.LoadingDialog = false;
          }
        } else {
          alert("Please choose file to upload!");
          self.LoadingDialog = false;
          self.showup = false;
          self.chosenfile = [];
          self.chosenfiles = [];
          self.synctimedatadialog = false;
          self.synctimemonthdatadialog = false;
        }
      } else {
        alert("File name must be Sync Time for Day view.csv");
        self.LoadingDialog = false;
        self.csvname = null;
        self.csvmonthname = null;
      }
    },
    //Import Excel from Optimistic Time Attendance
    UploadMonthSyncTime() {
      let self = this;
      self.LoadingDialog = true;
      if (self.csvmonthname == "Sync Time for Month view.csv") {
        if (self.chosenfiles.length != 0) {
          let validate = self.validatename();
          if (validate == true) {
            let tempdata = self.tempuploadlist();
            axios
              .post(`${self.url}ClockInOut/ImportExternalClockInOut`, tempdata)
              .then(function (response) {
                if (response.data.status == 0) {
                  if (response.data.message == "Add Successfully") {
                    alert("Upload Successfully!");
                  } else {
                    alert(response.data.message);
                  }
                  self.LoadingDialog = false;
                  self.synctimedatadialog = false;
                  self.synctimemonthdatadialog = false;
                  self.selected = [];
                  self.showup = false;
                  self.DayViewList = [];
                  self.chosenfile = [];
                  self.chosenfiles = [];
                  self.GetDayList();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Upload SyncTime Fail", 23, "Low");
                alert(error);
                self.LoadingDialog = false;
                self.showup = false;
                self.chosenfile = [];
                self.chosenfiles = [];
                self.synctimedatadialog = false;
                self.synctimemonthdatadialog = false;
              });
          } else {
            alert("Employee ID and Employee Name doesn't match");
            self.LoadingDialog = false;
          }
        } else {
          alert("Please choose file to upload!");
          self.LoadingDialog = false;
          self.showup = false;
          self.chosenfile = [];
          self.chosenfiles = [];
          self.synctimedatadialog = false;
          self.synctimemonthdatadialog = false;
        }
      } else {
        alert("File name must be Sync Time for Month view.csv");
        self.LoadingDialog = false;
        self.csvname = null;
        self.csvmonthname = null;
      }
    },
    //Import Excel from Jarviz Time Attendance
    ImportFromJarvizTimeAttendance() {
      let self = this;
      self.LoadingDialog = true;
      var files = self.jarvizfile;
      if (files != null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          //let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[workbook.SheetNames.length - 1];
          let worksheet = workbook.Sheets[sheetName];
          self.LoadingDialog = true;
          let TempData = XLSX.utils.sheet_to_json(worksheet);
          self.importData = TempData.filter(
            (o1) => o1["Check-In Time"] != "" && o1["Check-In Date"] != ""
          );
          let validate = self.ValidateNameForJarviz();
          if (validate == true) {
            let combileArray = [];
            for (let i = 0; i < self.importData.length; i++) {
              self.tempimport = {
                employee_Code: self.importData[i]["Employee ID"],
                timeEntry_Date: self.ChangeDateToYYYYMMDD(
                  self.importData[i]["Check-In Date"]
                ),
                company_ID: store.state.companyID,
                user_ID: store.state.userid,
                clock_In_Time: self.ChangeToUTCDateTimeCheckIn(
                  self.importData[i]["Check-In Time"],
                  self.importData[i]["Check-In Date"]
                ),
                clock_Out_Time: self.ChangeToUTCDateTimeCheckIn(
                  self.importData[i]["Check-Out Time"],
                  self.importData[i]["Check-Out Date"]
                ),
                actual_Clock_In_Time: self.ChangeToLocalDateTimeCheckIn(
                  self.importData[i]["Check-In Time"],
                  self.importData[i]["Check-In Date"]
                ),
                actual_Clock_Out_Time: self.ChangeToLocalDateTimeCheckIn(
                  self.importData[i]["Check-Out Time"],
                  self.importData[i]["Check-Out Date"]
                ),
                clock_In_Location:
                  self.importData[i]["Check In Location"] != null
                    ? self.importData[i]["Check In Location"]
                    : "-",
                clock_Out_Location:
                  self.importData[i]["Check Out Location"] != null
                    ? self.importData[i]["Check Out Location"]
                    : "-",
              };
              combileArray.push(self.tempimport);
            }

            axios
              .post(
                `${self.url}ClockInOut/ImportJarvizTimeAttendance`,
                combileArray
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  if (response.data.message == "Add Successfully") {
                    alert("Upload Successfully!");
                  } else {
                    alert(response.data.message);
                  }
                  self.synctimedatadialog = false;
                  self.synctimemonthdatadialog = false;
                  self.selected = [];
                  self.showup = false;
                  self.DayViewList = [];
                  self.jarvizfile = [];
                  self.GetDayList();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Upload Bank Fail", 7, "Verbose");
                alert(error + "Correlation ID is 7");
                self.LoadingDialog = false;
              });
          } else {
            alert("Employee ID and Employee Name doesn't match");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
        self.LoadingDialog = false;
      } else {
        alert("Please choose file to upload!");
        self.showup = false;
        self.jarvizfile = [];
        self.synctimedatadialog = false;
      }
    },
    //Import Excel From Month Jarviz Time Attendance
    ImportFromMonthJarvizTimeAttendance() {
      let self = this;
      self.LoadingDialog = true;
      var files = self.jarvizmonthfile;
      if (files != null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          //let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[workbook.SheetNames.length - 1];
          let worksheet = workbook.Sheets[sheetName];
          self.LoadingDialog = true;
          let TempData = XLSX.utils.sheet_to_json(worksheet);
          self.importData = TempData.filter(
            (o1) => o1["Check-In Time"] != "" && o1["Check-In Date"] != ""
          );
          let validate = self.ValidateNameForJarviz();
          if (validate == true) {
            let combileArray = [];
            for (let i = 0; i < self.importData.length; i++) {
              self.tempimport = {
                employee_Code: self.importData[i]["Employee ID"],
                timeEntry_Date: self.ChangeDateToYYYYMMDD(
                  self.importData[i]["Check-In Date"]
                ),
                company_ID: store.state.companyID,
                user_ID: store.state.userid,
                clock_In_Time: self.ChangeToUTCDateTimeCheckIn(
                  self.importData[i]["Check-In Time"],
                  self.importData[i]["Check-In Date"]
                ),
                clock_Out_Time: self.ChangeToUTCDateTimeCheckIn(
                  self.importData[i]["Check-Out Time"],
                  self.importData[i]["Check-Out Date"]
                ),
                actual_Clock_In_Time: self.ChangeToLocalDateTimeCheckIn(
                  self.importData[i]["Check-In Time"],
                  self.importData[i]["Check-In Date"]
                ),
                actual_Clock_Out_Time: self.ChangeToLocalDateTimeCheckIn(
                  self.importData[i]["Check-Out Time"],
                  self.importData[i]["Check-Out Date"]
                ),
                clock_In_Location:
                  self.importData[i]["Check In Location"] != null
                    ? self.importData[i]["Check In Location"]
                    : "-",
                clock_Out_Location:
                  self.importData[i]["Check Out Location"] != null
                    ? self.importData[i]["Check Out Location"]
                    : "-",
              };
              combileArray.push(self.tempimport);
            }

            axios
              .post(
                `${self.url}ClockInOut/ImportJarvizTimeAttendance`,
                combileArray
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  if (response.data.message == "Add Successfully") {
                    alert("Upload Successfully!");
                  } else {
                    alert(response.data.message);
                  }
                  self.synctimedatadialog = false;
                  self.synctimemonthdatadialog = false;
                  self.selected = [];
                  self.showup = false;
                  self.DayViewList = [];
                  self.jarvizmonthfile = [];
                  self.GetDayList();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Upload Bank Fail", 7, "Verbose");
                alert(error + "Correlation ID is 7");
                self.LoadingDialog = false;
              });
          } else {
            alert("Employee ID and Employee Name doesn't match");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
        self.LoadingDialog = false;
      } else {
        alert("Please choose file to upload!");
        self.showup = false;
        self.jarvizmonthfile = [];
        self.synctimemonthdatadialog = false;
      }
    },
    tempuploadlist() {
      let self = this;
      let result = [
        {
          employee_Code: 0,
          timeEntry_Date: "2020-10-22T00:00:00.000Z",
          clock_In_Time: "string",
          clock_Out_Time: "string",
          actual_Clock_In_Time: "string",
          actual_Clock_Out_Time: "string",
          company_ID: 0,
          user_ID: null,
        },
      ];
      for (let l = 0; l < self.chosenfiles.length; l++) {
        let List = {
          ...self.chosenfiles[l],
          TimeType: self.chosenfiles[l].Type.trim(),
        };
        self.chosenfile.push(List);
      }
      for (let j = 0; j < self.chosenfile.length; j++) {
        let k = 0;
        if (j < self.chosenfile.length - 1) {
          k = j + 1;
        } else {
          k = j;
        }
        if (j == 0) {
          result[j].employee_Code = self.chosenfile[j].Employee_ID;
          result[j].timeEntry_Date = self.changedigittodate(
            self.chosenfile[j].Date
          );
          result[j].company_ID = store.state.companyID;
          result[j].user_ID = store.state.userid;

          if (self.chosenfile[j].TimeType == "In") {
            result[j].clock_In_Time = self.changedigittotime(
              self.chosenfile[j].Time,
              self.chosenfile[j].Date
            );
            result[j].actual_Clock_In_Time = self.changedigittolocaltime(
              self.chosenfile[j].Time,
              self.chosenfile[j].Date
            );
            if (
              self.chosenfile[j].Employee_Name ==
              self.chosenfile[k].Employee_Name &&
              self.chosenfile[k].TimeType === "Out" &&
              self.chosenfile[j].Date == self.chosenfile[k].Date
            ) {
              result[j].clock_Out_Time = self.changedigittotime(
                self.chosenfile[k].Time,
                self.chosenfile[k].Date
              );
              result[j].actual_Clock_Out_Time = self.changedigittolocaltime(
                self.chosenfile[k].Time,
                self.chosenfile[k].Date
              );
              j++;
            } else {
              result[j].clock_Out_Time = "-";
              result[j].actual_Clock_Out_Time = "-";
            }
          }
        } else {
          let inn = "-";
          let out = "-";
          let actinn = "-";
          let actout = "-";
          if (self.chosenfile[j].TimeType === "In") {
            inn = self.changedigittotime(
              self.chosenfile[j].Time,
              self.chosenfile[j].Date
            );
            actinn = self.changedigittolocaltime(
              self.chosenfile[j].Time,
              self.chosenfile[j].Date
            );
            if (
              self.chosenfile[j].Employee_Name ==
              self.chosenfile[k].Employee_Name &&
              self.chosenfile[k].TimeType === "Out" &&
              self.chosenfile[j].Date == self.chosenfile[k].Date
            ) {
              out = self.changedigittotime(
                self.chosenfile[k].Time,
                self.chosenfile[k].Date
              );
              actout = self.changedigittolocaltime(
                self.chosenfile[k].Time,
                self.chosenfile[k].Date
              );
              j++;
            } else {
              out = "-";
              actout = "-";
            }
          }

          result.push({
            employee_Code: self.chosenfile[j].Employee_ID,
            timeEntry_Date: self.changedigittodate(self.chosenfile[j].Date),
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
            clock_In_Time: inn,
            clock_Out_Time: out,
            actual_Clock_In_Time: actinn,
            actual_Clock_Out_Time: actout,
          });
        }
      }
      return result;
    },
    validatename() {
      let self = this;
      let con = [];
      for (let i = 0; i < self.chosenfiles.length; i++) {
        //let code = parseInt(self.chosenfiles[i].Employee_ID);
        for (let j = 0; j < self.DayViewList.length; j++) {
          if (
            self.chosenfiles[i].Employee_ID == self.DayViewList[j].employee_Code
          ) {
            if (
              self.DayViewList[j].fullName == self.chosenfiles[i].Employee_Name
            ) {
              con.push(true);
            } else {
              con.push(false);
            }
          }
        }
      }
      if (con.some((e) => e == false)) {
        return false;
      } else {
        return true;
      }
    },
    ValidateNameForJarviz() {
      let self = this;
      let result = self.importData.filter(
        (o1) =>
          !self.DayViewList.some(
            (o2) =>
              o1["Employee ID"] === o2.employee_Code &&
              o1["Full Name"] === o2.fullName
          )
      );
      if (result.length != 0) {
        return false;
      } else {
        return true;
      }
    },

    hellothere() {
      if (this.selected.length == this.DayViewList.length && this.com == 0 && this.DayViewList.length != 0)
      {
        this.selectAll = true;
      }
      else
      {
        this.selectAll = false;
      }
      this.com = 0;
    },

    select() {
      this.selected = [];
      //this.selectAll = !this.selectAll;
      if (!this.selectAll)
      {
        for (let i = 0; i < this.DayViewListForReal.length; i++)
        {
          this.selected.push({
            empid: this.DayViewListForReal[i].employee_ID,
            clkid: this.DayViewListForReal[i].clocklist,
            empcde: this.DayViewListForReal[i].employee_Code,
            apv: this.DayViewListForReal[i].approve,
            cond: this.DayViewListForReal[i].yescondi,
            begin: this.DayViewListForReal[i].start_Time,
            end: this.DayViewListForReal[i].end_Time,
            intime: this.DayViewListForReal[i].clock_In_Time,
            outtime: this.DayViewListForReal[i].clock_Out_Time,
            thisday: this.DayViewListForReal[i].timeEntry_Date,
            tid: this.DayViewListForReal[i].time_ID,
          });
        }
      }
    },

    justcheckone() {
      this.selected1 = [];
    },
    
    select1() {
      this.selected1 = [];
      this.selectAll1 = !this.selectAll1;
      if (!this.selectAll1) {
        for (let i in this.MonthViewList) {
          this.selected1.push({
            empid: this.MonthViewList[i].employee_ID,
            clkid: this.MonthViewList[i].clock_ID,
          });
        }
      }
    },

    OpenApprovedialog() {
      if (this.selected.length == 0)
      {
        alert("Please select time attendance!");
      }
      else
      {
        this.Approvedialog = true;
      }
    },

    UpdateAllIrregularForToday() {
      let self = this;
      let arr = self.tempcreatearray();
      if (arr[0].clock_ID != null) {
        axios
          .post(`${self.url}ClockInOut/UpdateIrregularToClockInOut`, arr)
          .then(function (response) {
            if (response.data.status == 0)
            {
              self.LoadingDialog = false;
              self.thedsearchlength = 0;
              self.GetDayList();
              alert("Changes Saved Successfully!");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Irregular Fail", 23, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
      } else {
        alert("There is no acceptable time.");
      }
    },
    tempcreatearray() {
      let self = this;
      let temparr = [
        {
          time_ID: null,
          clock_ID: null,
          clock_In_Time: null,
          clock_Out_Time: null,
          clock_In_Location: null,
          clock_Out_Location: null,
          clock_In_Lattitude: null,
          clock_In_Longitude: null,
          clock_Out_Lattitude: null,
          clock_Out_Longitude: null,
          irregular_In_Time: null,
          irregular_Out_Time: null,
          irregular_In_Location: null,
          irregular_Out_Location: null,
          irregular_In_Lattitude: null,
          irregular_In_Longitude: null,
          irregular_Out_Lattitude: null,
          irregular_Out_Longitude: null,
          time_Type: null,
          user_ID: null,
          employee_ID: null,
          company_ID: null,
        },
      ];

      for (let y = 0; y < self.DayViewList.length; y++) {
        if (
          (self.DayViewList[y].regulartab.length == 2 ||
            self.DayViewList[y].regulartab.length == 0) &&
          self.DayViewList[y].irregulartab.length == 2
        ) {
          for (let z = 0; z < self.DayViewList[y].clocklist.length; z++) {
            let regular = self.DayViewList[y].irregulartab.filter(
              (val) => val.clock_ID == self.DayViewList[y].clocklist[z].clock_ID
            );
            let irregular = self.DayViewList[y].regulartab.filter(
              (val) => val.clock_ID == self.DayViewList[y].clocklist[z].clock_ID
            );
            if (regular.length != 0) {
              if (z == 0) {
                temparr[0].user_ID = store.state.userid;
                if (regular.length == 1) {
                  temparr[0].time_ID = self.DayViewList[y].time_ID;
                  temparr[0].clock_ID = regular[0].clock_ID;
                  temparr[0].employee_ID = self.DayViewList[y].employee_ID;
                  temparr[0].company_ID = store.state.companyID;
                  if (regular[0].PunchType == "Regular In") {
                    temparr[0].clock_In_Time = regular[0].Time;
                    temparr[0].clock_In_Location = regular[0].Location;
                    temparr[0].clock_In_Lattitude = regular[0].Lat;
                    temparr[0].clock_In_Longitude = regular[0].Lang;
                    temparr[0].clock_Out_Time = "-";
                    temparr[0].clock_Out_Location = "-";
                    temparr[0].clock_Out_Lattitude = "-";
                    temparr[0].clock_Out_Longitude = "-";
                    temparr[0].time_Type = "Regular";
                  } else {
                    temparr[0].clock_In_Time = "-";
                    temparr[0].clock_In_Location = "-";
                    temparr[0].clock_In_Lattitude = "-";
                    temparr[0].clock_In_Longitude = "-";
                    temparr[0].clock_Out_Time = regular[0].Time;
                    temparr[0].clock_Out_Location = regular[0].Location;
                    temparr[0].clock_Out_Lattitude = regular[0].Lat;
                    temparr[0].clock_Out_Longitude = regular[0].Lang;
                    temparr[0].time_Type = "Regular";
                  }
                  if (irregular.length != 0) {
                    if (irregular[0].PunchType == "Regular In") {
                      temparr[0].irregular_In_Time = irregular[0].Time;
                      temparr[0].irregular_In_Location = irregular[0].Location;
                      temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                      temparr[0].irregular_In_Longitude = irregular[0].Lang;
                      temparr[0].irregular_Out_Time = "-";
                      temparr[0].irregular_Out_Location = "-";
                      temparr[0].irregular_Out_Lattitude = "-";
                      temparr[0].irregular_Out_Longitude = "-";
                      temparr[0].time_Type = "Regular";
                    } else {
                      temparr[0].irregular_In_Time = "-";
                      temparr[0].irregular_In_Location = "-";
                      temparr[0].irregular_In_Lattitude = "-";
                      temparr[0].irregular_In_Longitude = "-";
                      temparr[0].irregular_Out_Time = irregular[0].Time;
                      temparr[0].irregular_Out_Location = irregular[0].Location;
                      temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                      temparr[0].irregular_Out_Longitude = irregular[0].Lang;
                      temparr[0].time_Type = "Regular";
                    }
                  } else {
                    temparr[0].irregular_In_Time = "-";
                    temparr[0].irregular_In_Location = "-";
                    temparr[0].irregular_In_Lattitude = "-";
                    temparr[0].irregular_In_Longitude = "-";
                    temparr[0].irregular_Out_Time = "-";
                    temparr[0].irregular_Out_Location = "-";
                    temparr[0].irregular_Out_Lattitude = "-";
                    temparr[0].irregular_Out_Longitude = "-";
                    temparr[0].time_Type = "Regular";
                  }
                } else if (regular.length == 2) {
                  temparr[0].time_ID = self.DayViewList[y].time_ID;
                  temparr[0].clock_ID = regular[0].clock_ID;
                  temparr[0].employee_ID = self.DayViewList[y].employee_ID;
                  temparr[0].company_ID = store.state.companyID;
                  if (regular[0].PunchType == "Regular In") {
                    temparr[0].clock_In_Time = regular[0].Time;
                    temparr[0].clock_In_Location = regular[0].Location;
                    temparr[0].clock_In_Lattitude = regular[0].Lat;
                    temparr[0].clock_In_Longitude = regular[0].Lang;
                  } else {
                    temparr[0].clock_Out_Time = regular[0].Time;
                    temparr[0].clock_Out_Location = regular[0].Location;
                    temparr[0].clock_Out_Lattitude = regular[0].Lat;
                    temparr[0].clock_Out_Longitude = regular[0].Lang;
                  }
                  if (regular[1].PunchType == "Regular In") {
                    temparr[0].clock_In_Time = regular[1].Time;
                    temparr[0].clock_In_Location = regular[1].Location;
                    temparr[0].clock_In_Lattitude = regular[1].Lat;
                    temparr[0].clock_In_Longitude = regular[1].Lang;
                  } else {
                    temparr[0].clock_Out_Time = regular[1].Time;
                    temparr[0].clock_Out_Location = regular[1].Location;
                    temparr[0].clock_Out_Lattitude = regular[1].Lat;
                    temparr[0].clock_Out_Longitude = regular[1].Lang;
                  }
                  temparr[0].irregular_In_Time = "-";
                  temparr[0].irregular_Out_Time = "-";
                  temparr[0].irregular_In_Location = "-";
                  temparr[0].irregular_Out_Location = "-";
                  temparr[0].irregular_In_Lattitude = "-";
                  temparr[0].irregular_In_Longitude = "-";
                  temparr[0].irregular_Out_Lattitude = "-";
                  temparr[0].irregular_Out_Longitude = "-";
                  temparr[0].time_Type = "Regular";
                } else {
                  temparr[0].time_ID = irregular[0].time_ID;
                  temparr[0].clock_ID = irregular[0].clock_ID;
                  temparr[0].employee_ID = self.DayViewList[y].employee_ID;
                  temparr[0].company_ID = store.state.companyID;
                  if (irregular.length == 2) {
                    if (irregular[0].PunchType == "Regular In") {
                      temparr[0].irregular_In_Time = irregular[0].Time;
                      temparr[0].irregular_In_Location = irregular[0].Location;
                      temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                      temparr[0].irregular_In_Longitude = irregular[0].Lang;
                    } else {
                      temparr[0].irregular_Out_Time = irregular[0].Time;
                      temparr[0].irregular_Out_Location = irregular[0].Location;
                      temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                      temparr[0].irregular_Out_Longitude = irregular[0].Lang;
                    }
                    if (irregular[1].PunchType == "Regular In") {
                      temparr[0].irregular_In_Time = irregular[1].Time;
                      temparr[0].irregular_In_Location = irregular[1].Location;
                      temparr[0].irregular_In_Lattitude = irregular[1].Lat;
                      temparr[0].irregular_In_Longitude = irregular[1].Lang;
                    } else {
                      temparr[0].irregular_Out_Time = irregular[1].Time;
                      temparr[0].irregular_Out_Location = irregular[1].Location;
                      temparr[0].irregular_Out_Lattitude = irregular[1].Lat;
                      temparr[0].irregular_Out_Longitude = irregular[1].Lang;
                    }
                  } else {
                    if (irregular.length != 0) {
                      if (irregular[0].PunchType == "Regular In") {
                        temparr[0].irregular_In_Time = irregular[0].Time;
                        temparr[0].irregular_In_Location =
                          irregular[0].Location;
                        temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                        temparr[0].irregular_In_Longitude = irregular[0].Lang;
                        temparr[0].irregular_Out_Time = "-";
                        temparr[0].irregular_Out_Location = "-";
                        temparr[0].irregular_Out_Lattitude = "-";
                        temparr[0].irregular_Out_Longitude = "-";
                      } else {
                        temparr[0].irregular_In_Time = "-";
                        temparr[0].irregular_In_Location = "-";
                        temparr[0].irregular_In_Lattitude = "-";
                        temparr[0].irregular_In_Longitude = "-";
                        temparr[0].irregular_Out_Time = irregular[0].Time;
                        temparr[0].irregular_Out_Location =
                          irregular[0].Location;
                        temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                        temparr[0].irregular_Out_Longitude = irregular[0].Lang;
                      }
                    } else if (irregular.length == 0) {
                      temparr[0].irregular_In_Time = "-";
                      temparr[0].irregular_In_Location = "-";
                      temparr[0].irregular_In_Lattitude = "-";
                      temparr[0].irregular_In_Longitude = "-";
                      temparr[0].irregular_Out_Time = "-";
                      temparr[0].irregular_Out_Location = "-";
                      temparr[0].irregular_Out_Lattitude = "-";
                      temparr[0].irregular_Out_Longitude = "-";
                    }
                  }
                  temparr[0].time_Type = "Regular";
                  temparr[0].clock_In_Time = "-";
                  temparr[0].clock_Out_Time = "-";
                  temparr[0].clock_In_Location = "-";
                  temparr[0].clock_Out_Location = "-";
                  temparr[0].clock_In_Lattitude = "-";
                  temparr[0].clock_In_Longitude = "-";
                  temparr[0].clock_Out_Lattitude = "-";
                  temparr[0].clock_Out_Longitude = "-";
                }
              } else {
                let cid = 0;
                let tid = 0;
                let cin = null;
                let cout = null;
                let cinloc = null;
                let coutloc = null;
                let cinlat = null;
                let coutlat = null;
                let cinlang = null;
                let coutlang = null;
                let latein = null;
                let lateout = null;
                let lateinloc = null;
                let lateoutloc = null;
                let lateinlat = null;
                let lateoutlat = null;
                let lateinlang = null;
                let lateoutlang = null;
                let ttype = null;
                if (regular.length == 1) {
                  cid = regular[0].clock_ID;
                  tid = self.DayViewList[y].time_ID;
                  ttype = "Regular";
                  if (regular[0].PunchType == "Regular In") {
                    cin = regular[0].Time;
                    cinloc = regular[0].Location;
                    cinlat = regular[0].Lat;
                    cinlang = regular[0].Lang;
                    cout = "-";
                    coutloc = "-";
                    coutlat = "-";
                    coutlang = "-";
                  } else {
                    cin = "-";
                    cinloc = "-";
                    cinlat = "-";
                    cinlang = "-";
                    cout = regular[0].Time;
                    coutloc = regular[0].Location;
                    coutlat = regular[0].Lat;
                    coutlang = regular[0].Lang;
                  }
                  if (irregular.length != 0) {
                    if (irregular[0].PunchType == "Regular In") {
                      latein = irregular[0].Time;
                      lateinloc = irregular[0].Location;
                      lateinlat = irregular[0].Lat;
                      lateinlang = irregular[0].Lang;
                      lateout = "-";
                      lateoutloc = "-";
                      lateoutlat = "-";
                      lateoutlang = "-";
                    } else {
                      latein = "-";
                      lateinloc = "-";
                      lateinlat = "-";
                      lateinlang = "-";
                      lateout = irregular[0].Time;
                      lateoutloc = irregular[0].Location;
                      lateoutlat = irregular[0].Lat;
                      lateoutlang = irregular[0].Lang;
                    }
                  } else {
                    latein = "-";
                    lateinloc = "-";
                    lateinlat = "-";
                    lateinlang = "-";
                    lateout = "-";
                    lateoutloc = "-";
                    lateoutlat = "-";
                    lateoutlang = "-";
                  }
                } else if (regular.length == 2) {
                  cid = regular[0].clock_ID;
                  tid = self.DayViewList[y].time_ID;
                  if (regular[0].PunchType == "Regular In") {
                    cin = regular[0].Time;
                    cinloc = regular[0].Location;
                    cinlat = regular[0].Lat;
                    cinlang = regular[0].Lang;
                  } else {
                    cout = regular[0].Time;
                    coutloc = regular[0].Location;
                    coutlat = regular[0].Lat;
                    coutlang = regular[0].Lang;
                  }
                  if (regular[1].PunchType == "Regular In") {
                    cin = regular[1].Time;
                    cinloc = regular[1].Location;
                    cinlat = regular[1].Lat;
                    cinlang = regular[1].Lang;
                  } else {
                    cout = regular[1].Time;
                    coutloc = regular[1].Location;
                    coutlat = regular[1].Lat;
                    coutlang = regular[1].Lang;
                  }
                  ttype = "Regular";
                  latein = "-";
                  lateout = "-";
                  lateinloc = "-";
                  lateoutloc = "-";
                  lateinlat = "-";
                  lateinlang = "-";
                  lateoutlat = "-";
                  lateoutlang = "-";
                } else {
                  cid = irregular[0].clock_ID;
                  tid = irregular[0].time_ID;
                  ttype = "Regular";
                  if (irregular.length == 2) {
                    if (irregular[0].PunchType == "Regular In") {
                      latein = irregular[0].Time;
                      lateinloc = irregular[0].Location;
                      lateinlat = irregular[0].Lat;
                      lateinlang = irregular[0].Lang;
                    } else {
                      lateout = irregular[0].Time;
                      lateoutloc = irregular[0].Location;
                      lateoutlat = irregular[0].Lat;
                      lateoutlang = irregular[0].Lang;
                    }
                    if (irregular[1].PunchType == "Regular In") {
                      latein = irregular[1].Time;
                      lateinloc = irregular[1].Location;
                      lateinlat = irregular[1].Lat;
                      lateinlang = irregular[1].Lang;
                    } else {
                      lateout = irregular[1].Time;
                      lateoutloc = irregular[1].Location;
                      lateoutlat = irregular[1].Lat;
                      lateoutlang = irregular[1].Lang;
                    }
                  } else {
                    if (irregular[0].PunchType == "Regular In") {
                      latein = irregular[0].Time;
                      lateinloc = irregular[0].Location;
                      lateinlat = irregular[0].Lat;
                      lateinlang = irregular[0].Lang;
                      lateout = "-";
                      lateoutloc = "-";
                      lateoutlat = "-";
                      lateoutlang = "-";
                    } else {
                      latein = "-";
                      lateinloc = "-";
                      lateinlat = "-";
                      lateinlang = "-";
                      lateout = irregular[0].Time;
                      lateoutloc = irregular[0].Location;
                      lateoutlat = irregular[0].Lat;
                      lateoutlang = irregular[0].Lang;
                    }
                  }

                  cin = "-";
                  cinloc = "-";
                  cinlat = "-";
                  cinlang = "-";
                  cout = "-";
                  coutloc = "-";
                  coutlat = "-";
                  coutlang = "-";
                }

                temparr.push({
                  clock_ID: cid,
                  time_ID: tid,
                  clock_In_Time: cin,
                  clock_Out_Time: cout,
                  clock_In_Location: cinloc,
                  clock_Out_Location: coutloc,
                  clock_In_Lattitude: cinlat,
                  clock_In_Longitude: cinlang,
                  clock_Out_Lattitude: coutlat,
                  clock_Out_Longitude: coutlang,
                  irregular_In_Time: latein,
                  irregular_Out_Time: lateout,
                  irregular_In_Location: lateinloc,
                  irregular_Out_Location: lateoutloc,
                  irregular_In_Lattitude: lateinlat,
                  irregular_In_Longitude: lateinlang,
                  irregular_Out_Lattitude: lateoutlat,
                  irregular_Out_Longitude: lateoutlang,
                  user_ID: store.state.userid,
                  time_Type: ttype,
                  employee_ID: self.DayViewList[y].employee_ID,
                  company_ID: store.state.companyID,
                });
              }
            }
          }
        }
      }
      return temparr;
    },
    ApproveTime() {
      let self = this;
      //let con = [];
      let temparr = [];
      for (let i = 0; i < self.selected.length; i++) {
        // if (self.selected[i].apv == true) {
        //   con.push(true);
        // }
        if (self.selected[i].cond == "true" || self.selected[i].cond == true) {
          //con.push(true);
          temparr.push({
            employee_ID: self.selected[i].empid,
            clockidlist: self.selected[i].clkid,
            user_ID: store.state.userid,
            approver_ID: store.state.employeeID,
            time_ID: self.selected[i].tid,
            shift_StartTime: self.selected[i].begin,
            shift_EndTime: self.selected[i].end,
          });
        } else {
          if (
            self.selected[i].begin != "-" &&
            self.selected[i].begin != null &&
            self.selected[i].begin != ""
          ) {
            if (
              self.selected[i].intime != "-" &&
              self.selected[i].outtime != "-"
            ) {
              let from = new Date(Date.parse(self.selected[i].intime));
              let to = new Date(Date.parse(self.selected[i].outtime));

              //in time
              let shiftstartbefore = new Date(
                Date.parse(self.selected[i].thisday)
              );
              let shiftstartafter = new Date(
                Date.parse(self.selected[i].thisday)
              );
              let [sshours, ssminutes] = self.selected[i].begin.split(":");
              shiftstartbefore.setHours(+sshours);
              shiftstartbefore.setMinutes(ssminutes);
              shiftstartbefore.setSeconds("0");
              shiftstartbefore.setMilliseconds("0");
              shiftstartafter.setHours(+sshours);
              shiftstartafter.setMinutes(ssminutes);
              if (self.startbeforetime == "hour") {
                let adds =
                  shiftstartbefore.getHours() - parseInt(self.startbefore);
                shiftstartbefore.setHours(+adds);
              } else if (self.startbeforetime == "min") {
                let adds =
                  shiftstartbefore.getMinutes() - parseInt(self.startbefore);
                shiftstartbefore.setMinutes(adds);
              }
              if (self.startaftertime == "hour") {
                let adds =
                  shiftstartafter.getHours() + parseInt(self.startafter);
                shiftstartafter.setHours(+adds);
              } else if (self.startaftertime == "min") {
                let adds =
                  shiftstartafter.getMinutes() + parseInt(self.startafter);
                shiftstartafter.setMinutes(adds);
              }
              //out time
              let shiftendbefore = new Date(
                Date.parse(self.selected[i].thisday)
              );
              let shiftendafter = new Date(
                Date.parse(self.selected[i].thisday)
              );
              let [sehours, seminutes] = self.selected[i].end.split(":");
              shiftendbefore.setHours(+sehours);
              shiftendbefore.setMinutes(seminutes);
              shiftendbefore.setSeconds("0");
              shiftendbefore.setMilliseconds("0");
              shiftendafter.setHours(+sehours);
              shiftendafter.setMinutes(seminutes);
              if (self.endbeforetime == "hour") {
                let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
                shiftendbefore.setHours(+adds);
              } else if (self.endbeforetime == "min") {
                let adds =
                  shiftendbefore.getMinutes() - parseInt(self.endbefore);
                shiftendbefore.setMinutes(adds);
              }
              if (self.endaftertime == "hour") {
                let adds = shiftendafter.getHours() + parseInt(self.endafter);
                shiftendafter.setHours(+adds);
              } else if (self.endaftertime == "min") {
                let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
                shiftendafter.setMinutes(adds);
              }

              let frtimezone = from.getTimezoneOffset() * 60000;
              let frnewDate = new Date(from.getTime() - frtimezone);
              let totimezome = to.getTimezoneOffset() * 60000;
              let tonewDate = new Date(to.getTime() - totimezome);
              let ssnewDate = new Date(shiftstartafter.getTime());
              let ssbnewDate = new Date(shiftstartbefore.getTime());
              let senewDate = new Date(shiftendafter.getTime());
              let sebnewDate = new Date(shiftendbefore.getTime());
              let sslatetime = true;
              if (self.selected[i].apv != true) {
                if (self.selected[i].intime != "-") {
                  if (frnewDate > ssnewDate) {
                    sslatetime = false;
                    //con.push(false);
                  } else if (ssbnewDate > frnewDate) {
                    sslatetime = false;
                    //con.push(false);
                  } else {
                    sslatetime = true;
                    //con.push(true);
                  }
                  if (self.selected[i].outtime != "-" && sslatetime == true) {
                    if (tonewDate > senewDate) {
                      sslatetime = false;
                      //con.push(false);
                    } else if (sebnewDate > tonewDate) {
                      sslatetime = false;
                      //con.push(false);
                    } else {
                      sslatetime = true;
                      //con.push(true);
                      temparr.push({
                        employee_ID: self.selected[i].empid,
                        clockidlist: self.selected[i].clkid,
                        user_ID: store.state.userid,
                        approver_ID: store.state.employeeID,
                        time_ID: self.selected[i].tid,
                        shift_StartTime: self.selected[i].begin,
                        shift_EndTime: self.selected[i].end,
                      });
                    }
                  }
                }
              } // else {
              //  con.push(false);
              //}
            } // else if (
            //   self.selected[i].intime == "-" &&
            //   self.selected[i].outtime == "-"
            // ) {
            //   con.push(true);
            // } else {
            //   con.push(false);
            // }
          } // else {
          //  con.push(true);
          //}
        }
      }
      // if (con.some((e) => e == false)) {
      //   alert("Please check again, there are some irregular time attendance");
      // } else {
      let tempdata = temparr;
      axios
        .post(
          `${self.url}Stafftimeattendance/UpdateMutipleStaffTimeAttendance`,
          tempdata
        )
        .then(function (response) {
          if (response.data.status == 0) {
            alert("Approved Successfully!");
            self.Approvedialog = false;
            self.selected = [];
            self.DayViewList = [];
            self.GetDayList();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Approve Time Fail", 23, "Medium");
          alert(error);
        });
      //}
    },
    tearraylist() {
      let self = this;
      let temparr = [
        {
          employee_ID: null,
          clockidlist: [],
          user_ID: null,
        },
      ];
      for (let i = 0; i < self.selected.length; i++) {
        if (i == 0) {
          temparr[i].employee_ID = self.selected[i].empid;
          temparr[i].clockidlist = self.selected[i].clkid;
          temparr[i].user_ID = store.state.userid;
        } else {
          temparr.push({
            employee_ID: self.selected[i].empid,
            clockidlist: self.selected[i].clkid,
            user_ID: store.state.userid,
          });
        }
      }
      return temparr;
    },
    Openexportdailydialog() {
      let self = this;
      self.GetTimeAttSetting();
      self.exportdailydialog = true;
      self.startdate = new Date().toISOString().substr(0, 10);
      self.enddate = new Date().toISOString().substr(0, 10);
      if (self.selected.length != 0) {
        if (self.selected.length - 1 == self.DayViewList.length) {
          self.dailyselectusertype = "Everyone";
        } else {
          self.dailyselectusertype = "Selected Individual";
        }
      } else {
        self.dailyselectusertype = "Everyone";
      }
      if (self.DayColumn != 0) {
        self.NoColumnList = [];
        self.ColumnList = [];
        self.ColumnList = [
          { id: 0, val: 1, name: "Employee ID" },
          { id: 1, val: 2, name: "Department" },
          { id: 2, val: 3, name: "Employee Name" },
          { id: 3, val: 4, name: "Shift Start Time" },
          { id: 4, val: 5, name: "Shift End Time" },
          { id: 5, val: 6, name: "OT Start Time" },
          { id: 6, val: 7, name: "OT End Time" },
          { id: 7, val: 8, name: "Time Off Type" },
          { id: 8, val: 9, name: "Time Off Hour" },
          { id: 9, val: 10, name: "Clock In" },
          { id: 10, val: 11, name: "Clock Out" },
          { id: 11, val: 12, name: "OT Clock In" },
          { id: 12, val: 13, name: "OT Clock Out" },
          { id: 13, val: 14, name: "Remark" },
          { id: 14, val: 15, name: "Status" },
          { id: 15, val: 16, name: "Approver" },
          { id: 16, val: 17, name: "Clock In Status" },
          { id: 17, val: 18, name: "OT Clock In Status" },
        ];
      }
    },
    Closeexportdailydialog() {
      let self = this;
      self.exportdailydialog = false;
      self.startdate = "";
      self.enddate = "";
      self.starday = [];
      self.enday = [];
      self.DayColumn = 0;
    },
    Openexportmonthlydialog() {
      let self = this;
      // console.log(self.selected1);
      if (self.selected1.length != 0) {
        self.monthselectusertype = self.selected1[0].empname;
      } else {
        self.monthselectusertype = "";
      }
      if (self.monthselectusertype != "" && self.monthselectusertype != null) {
        self.exportmonthlydialog = true;
        self.GetTimeAttSetting();
        let d = new Date();
        self.monthselect = this.monthNames1[d.getMonth()].val;
        self.yearselect = d.getFullYear();
        if (self.MonthColumn != 0) {
          self.NoColumnListMonth = [];
          self.ColumnListMonth = [];
          self.ColumnListMonth = [
            { id: 0, val: 1, name: "Employee ID" },
            { id: 1, val: 2, name: "Department" },
            { id: 2, val: 3, name: "Employee Name" },
            { id: 3, val: 4, name: "Shift Start Time" },
            { id: 4, val: 5, name: "Shift End Time" },
            { id: 5, val: 6, name: "OT Start Time" },
            { id: 6, val: 7, name: "OT End Time" },
            { id: 7, val: 8, name: "Time Off Type" },
            { id: 8, val: 9, name: "Time Off Hour" },
            { id: 9, val: 10, name: "Clock In" },
            { id: 10, val: 11, name: "Clock Out" },
            { id: 11, val: 12, name: "OT Clock In" },
            { id: 12, val: 13, name: "OT Clock Out" },
            { id: 13, val: 14, name: "Remark" },
            { id: 14, val: 15, name: "Status" },
            { id: 15, val: 16, name: "Approver" },
            { id: 16, val: 17, name: "Clock In Status" },
            { id: 17, val: 18, name: "OT Clock In Status" },
          ]
        }
      } else {
        alert(this.$t("SelectOnePlease"));
      }
    },
    Closeexportmonthlydialog() {
      let self = this;
      self.exportmonthlydialog = false;
      let d = new Date();
      self.monthselect = this.monthNames1[d.getMonth()].val;
      self.yearselect = d.getFullYear();
      self.mthselect = [];
      self.yerselect = [];
      self.MonthColumn = 0;
    },
    checkDateExport(value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },
    excelExportDateRange() {
      let self = this;
      let inputdate = self.startdate.split("/");

      let inputenddate = self.enddate.split("/");

      let datestring1 = inputdate[0] + "-" + inputdate[1] + "-" + inputdate[2];
      let datestring2 =
        inputenddate[0] + "-" + inputenddate[1] + "-" + inputenddate[2];
      return `${datestring1}-${datestring2}`;
    },
    exportdaily() {
      let self = this;
      self.exportlist = [];
      self.enday = [(v) => !!v || this.$t("SpecifyStart")];
      self.starday = [(v) => !!v || this.$t("SpecifyEnd")];
      if (self.ColumnList.length != 0) {
        self.LoadingDialog = true;
        if (self.startdate != "" && self.enddate != "") {
          if (self.$moment(self.startdate, "DD/MM/YYYY").format('YYYY-MM-DD') <= self.$moment(self.enddate, "DD/MM/YYYY").format('YYYY-MM-DD')) 
          {
            if (self.dailyselectusertype == "Everyone") {
              let inputdate = self.startdate.split("/");
              // let mystartdate =
              // self.$moment(self.startdate).format('DD/MM/YYYY');
              let inputenddate = self.enddate.split("/");
              // let myenddate =
              // self.$moment(self.enddate).format('DD/MM/YYYY');
              let temp = {
                startdate: self.startdate,
                enddate: self.enddate,
                timeAttendance: self.FilterDayAttendance,
                company_ID: store.state.companyID,
              };
              axios
                .post(`${self.url}Stafftimeattendance/GetAllExportDayView`, temp)
                .then(function (response) {
                  let templist = response.data.data;
                  for (let i = 0; i < templist.length; i++) {
                    let intime = "-";
                    let outtime = "-";
                    let otintime = "-";
                    let otouttime = "-";
                    let cinoutcount = 0;
                    let otcinoutcount = 0;
                    let regin = "-";
                    let latein = "-";
                    let regout = "-";
                    let otin = "-";
                    let lateotin = "-";
                    let yesc = false;
                    let clist = [];
                    let status = null;
                    let outlist = [];
                    let otoutlist = [];
                    for (let f = 0; f < templist[i].regCinoutData.length; f++) {
                      clist.push({
                        clock_ID: templist[i].regCinoutData[f].clock_ID,
                      });
                    }
                    for (let r = 0; r < templist[i].otCinoutData.length; r++) {
                      clist.push({
                        clock_ID: templist[i].otCinoutData[r].clock_ID,
                      });
                    }
                    if (templist[i].irrRegCinoutData.length != 0) {
                      latein = templist[i].irrRegCinoutData[0].clock_In_Time;
                      for (
                        let c = 0;
                        c < templist[i].irrRegCinoutData.length;
                        c++
                      ) {
                        outlist.unshift({
                          time: templist[i].irrRegCinoutData[c].clock_Out_Time,
                        });
                      }
                    }
                    if (templist[i].regCinoutData.length != 0) {
                      let leg = templist[i].regCinoutData.length - 1;
                      regin = templist[i].regCinoutData[0].clock_In_Time;
                      regout = templist[i].regCinoutData[leg].clock_Out_Time;
                      cinoutcount = templist[i].regCinoutData.length;
                      for (let a = 0; a < templist[i].regCinoutData.length; a++) {
                        outlist.unshift({
                          time: templist[i].regCinoutData[a].clock_Out_Time,
                        });
                      }
                    }
                    if (templist[i].irrOTCinoutData.length != 0) {
                      lateotin = templist[i].irrOTCinoutData[0].clock_In_Time;
                      for (
                        let d = 0;
                        d < templist[i].irrOTCinoutData.length;
                        d++
                      ) {
                        otoutlist.unshift({
                          time: templist[i].irrOTCinoutData[d].clock_Out_Time,
                        });
                      }
                    }
                    if (templist[i].otCinoutData.length != 0) {
                      otin = templist[i].otCinoutData[0].clock_In_Time;
                      otcinoutcount = templist[i].otCinoutData.length;
                      for (let b = 0; b < templist[i].otCinoutData.length; b++) {
                        otoutlist.unshift({
                          time: templist[i].otCinoutData[b].clock_Out_Time,
                        });
                      }
                    }

                    if (regin != "-" && regin != null) {
                      intime = regin;
                    } else if (latein != "-" && latein != null) {
                      intime = latein;
                    }

                    for (let e = 0; e < outlist.length; e++) {
                      if (outlist[e].time != "-") {
                        outtime = outlist[e].time;
                        break;
                      }
                    }

                    if (otin != "-" && otin != null) {
                      otintime = otin;
                    } else if (lateotin != "-" && lateotin != null) {
                      otintime = lateotin;
                    }

                    for (let f = 0; f < otoutlist.length; f++) {
                      if (otoutlist[f].time != "-") {
                        otouttime = otoutlist[f].time;
                        break;
                      }
                    }

                    if (
                      regin != "-" &&
                      regin != null &&
                      regout != "-" &&
                      regout != null
                    ) {
                      yesc = true;
                    }

                    if (
                      templist[i].oT_Start_Time == null ||
                      templist[i].oT_Start_Time == ""
                    ) {
                      templist[i].oT_Start_Time = "-";
                    }
                    if (
                      templist[i].oT_End_Time == null ||
                      templist[i].oT_End_Time == ""
                    ) {
                      templist[i].oT_End_Time = "-";
                    }
                    if (
                      templist[i].oT_Start_Time == "-" &&
                      templist.oT_End_Time == "-" &&
                      templist[i].start_Time == "-" &&
                      templist.end_Time == "-"
                    ) {
                      status = "-";
                    } else if (templist[i].approve == true) {
                      status = "Approved";
                    } else {
                      status = "Pending";
                    }
                    let timetypeex = "N/A";
                    let st = "-";
                    let ed = "-";
                    if (templist[i].time_Off_Whole_Day != null) {
                      timetypeex = templist[i].tO_Type;
                      if (templist[i].time_Off_Whole_Day == true) {
                        st = templist[i].start_Time;
                        ed = templist[i].end_Time;
                      } else {
                        st = templist[i].tO_StartTime;
                        ed = templist[i].tO_EndTime;
                      }
                    }
                    let Lists = {
                      ...templist[i],
                      clock_In_Time: intime,
                      clock_Out_Time: outtime,
                      oT_Clock_In_Time: otintime,
                      oT_Clock_Out_Time: otouttime,
                      regcount: cinoutcount,
                      otcount: otcinoutcount,
                      clocklist: clist,
                      yescondi: yesc,
                      status: status,
                      tO_Type: timetypeex,
                      clinstat: self.forexpstatus(
                        intime,
                        templist[i].start_Time,
                        templist[i].timeEntry_Date
                      ),
                      otclinstat: self.forexpstatus(
                        otintime,
                        templist[i].oT_Start_Time,
                        templist[i].timeEntry_Date
                      ),
                      tothr: self.TotalTOHours(st, ed),
                    };
                    self.exportlist.push(Lists);
                  }
                  self.LoadingDialog = false;
                  self.exportdailydialog = false;

                  let hasData = false;
                  let lastSheetData = [];

                  let wb = XLSX.utils.book_new();
                  let mynewstartdate = new Date(
                    inputdate[2],
                    inputdate[1],
                    inputdate[0]
                  );
                  let mynewenddate = new Date(
                    inputenddate[2],
                    inputenddate[1],
                    inputenddate[0]
                  );
                  let wsdata = [];
                  let Difference_In_Time =
                    mynewenddate.getTime() - mynewstartdate.getTime();
                  let Difference_In_Days =
                    Difference_In_Time / (1000 * 3600 * 24) + 1;
                  let totalsheet = Difference_In_Days;

                  for (let sheet = 0; sheet < totalsheet; sheet++) {
                    let tempsheetdata = [];
                    let input = parseInt(inputdate[0]) + sheet;
                    wsdata = [];
                    let startoftheyear = new Date(
                      inputdate[2],
                      inputdate[1] - 1,
                      input
                    );

                    tempsheetdata = self.exportlist.filter((x) => {
                      let tempdate2 = new Date(x.timeEntry_Date);
                      let date2 = new Date(
                        tempdate2.getFullYear(),
                        tempdate2.getMonth(),
                        tempdate2.getDate()
                      );
                      return date2.getTime() == startoftheyear.getTime();
                    });
                    let tempsheetname =
                      self.checkDateExport(startoftheyear.getDate()) +
                      "_" +
                      self.checkDateExport(startoftheyear.getMonth() + 1) +
                      "_" +
                      startoftheyear.getFullYear();

                    if (self.exportlist.length != lastSheetData.length) {
                      lastSheetData = [...lastSheetData, ...tempsheetdata].sort(
                        (a, b) =>
                          new Date(b.timeEntry_Date) - new Date(a.timeEntry_Date)
                      );
                    }
                    if (tempsheetdata.length) {
                      for (let j = 0; j < tempsheetdata.length; j++) {
                        if (localStorage.Templang == "ประเทศไทย") {
                          wsdata.push({
                            ["รหัสพนักงาน"]: tempsheetdata[j].employee_Code,
                            ["แผนก"]: tempsheetdata[j].department_Name,
                            ["ชื่อพนักงาน"]: tempsheetdata[j].fullName,
                            ["เวลาเริ่มต้น Shift"]: tempsheetdata[j].start_Time,
                            ["เวลาสิ้นสุด Shift"]: tempsheetdata[j].end_Time,
                            ["เวลาเริ่มโอที"]: tempsheetdata[j].oT_Start_Time,
                            ["เวลาสิ้นสุดโอที"]: tempsheetdata[j].oT_End_Time,
                            ["ประเภทวันลา"]: tempsheetdata[j].tO_Type,
                            ["จำนวนชั่วโมงการลา"]: tempsheetdata[j].tothr,
                            ["เวลาเข้า"]: self.Converttolocal(
                              tempsheetdata[j].clock_In_Time
                            ),
                            ["เวลาออก"]: self.Converttolocal(
                              tempsheetdata[j].clock_Out_Time
                            ),
                            ["เวลาเข้าโอที"]: self.Converttolocal(
                              tempsheetdata[j].oT_Clock_In_Time
                            ),
                            ["เวลาออกโอที"]: self.Converttolocal(
                              tempsheetdata[j].oT_Clock_Out_Time
                            ),
                            ["ข้อสังเกต"]: tempsheetdata[j].remarkTH,
                            ["สถานะ"]: tempsheetdata[j].status,
                            ["ผู้อนุมัติ"]: tempsheetdata[j].approver_Name,
                            ["สถานะการเข้างาน"]: tempsheetdata[j].clinstat,
                            ["สถานะการทำ OT"]: tempsheetdata[j].otclinstat,
                          });
                        } else {
                          if (sessionStorage.flag == "Thai") {
                            wsdata.push({
                              ["รหัสพนักงาน"]: tempsheetdata[j].employee_Code,
                              ["แผนก"]: tempsheetdata[j].department_Name,
                              ["ชื่อพนักงาน"]: tempsheetdata[j].fullName,
                              ["เวลาเริ่มต้น Shift"]: tempsheetdata[j].start_Time,
                              ["เวลาสิ้นสุด Shift"]: tempsheetdata[j].end_Time,
                              ["เวลาเริ่มโอที"]: tempsheetdata[j].oT_Start_Time,
                              ["เวลาสิ้นสุดโอที"]: tempsheetdata[j].oT_End_Time,
                              ["ประเภทวันลา"]: tempsheetdata[j].tO_Type,
                              ["จำนวนชั่วโมงการลา"]: tempsheetdata[j].tothr,
                              ["เวลาเข้า"]: self.Converttolocal(
                                tempsheetdata[j].clock_In_Time
                              ),
                              ["เวลาออก"]: self.Converttolocal(
                                tempsheetdata[j].clock_Out_Time
                              ),
                              ["เวลาเข้าโอที"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_In_Time
                              ),
                              ["เวลาออกโอที"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_Out_Time
                              ),
                              ["ข้อสังเกต"]: tempsheetdata[j].remarkTH,
                              ["สถานะ"]: tempsheetdata[j].status,
                              ["ผู้อนุมัติ"]: tempsheetdata[j].approver_Name,
                              ["สถานะการเข้างาน"]: tempsheetdata[j].clinstat,
                              ["สถานะการทำ OT"]: tempsheetdata[j].otclinstat,
                            });
                          } else {
                            wsdata.push({
                              ["Employee ID"]: tempsheetdata[j].employee_Code,
                              ["Department"]: tempsheetdata[j].department_Name,
                              ["Employee Name"]: tempsheetdata[j].fullName,
                              ["Shift Start Time"]: tempsheetdata[j].start_Time,
                              ["Shift End Time"]: tempsheetdata[j].end_Time,
                              ["OT Start Time"]: tempsheetdata[j].oT_Start_Time,
                              ["OT End Time"]: tempsheetdata[j].oT_End_Time,
                              ["Time Off Type"]: tempsheetdata[j].tO_Type,
                              ["Time Off Hour"]: tempsheetdata[j].tothr,
                              ["Clock In"]: self.Converttolocal(
                                tempsheetdata[j].clock_In_Time
                              ),
                              ["Clock Out"]: self.Converttolocal(
                                tempsheetdata[j].clock_Out_Time
                              ),
                              ["OT Clock In"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_In_Time
                              ),
                              ["OT Clock Out"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_Out_Time
                              ),
                              ["Remark"]: tempsheetdata[j].remark,
                              ["Status"]: tempsheetdata[j].status,
                              ["Approver"]: tempsheetdata[j].approver_Name,
                              ["Clock In Status"]: tempsheetdata[j].clinstat,
                              ["OT Clock In Status"]: tempsheetdata[j].otclinstat,
                            });
                          }
                        }
                      }
                      var dataexport = wsdata.map(obj => {
                        const newObj = {};
                        self.ColumnList.forEach(key => {
                          newObj[self.TranslateThis(key.name)] = obj[self.TranslateThis(key.name)];
                        });
                        return newObj;
                      });
                      var ws = XLSX.utils.json_to_sheet(dataexport);
                      ws["!cols"] = [
                        { wch: 20 },
                        { wch: 25 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                      ];
                      XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
                      hasData = true;
                    }
                  }

                  if (!hasData) {
                    alert("Your selected date range doesn't have Data!");
                  } else {
                    let wsalldata = [];
                    for (let j = 0; j < lastSheetData.length; j++) {
                      if (localStorage.Templang == "ประเทศไทย") {
                        wsalldata.unshift({
                          ["วันที่"]: self.formatDate(
                            lastSheetData[j].timeEntry_Date
                          ),
                          ["รหัสพนักงาน"]: lastSheetData[j].employee_Code,
                          ["แผนก"]: lastSheetData[j].department_Name,
                          ["ชื่อพนักงาน"]: lastSheetData[j].fullName,
                          ["เวลาเริ่มต้น Shift"]: lastSheetData[j].start_Time,
                          ["เวลาสิ้นสุด Shift"]: lastSheetData[j].end_Time,
                          ["เวลาเริ่มโอที"]: lastSheetData[j].oT_Start_Time,
                          ["เวลาสิ้นสุดโอที"]: lastSheetData[j].oT_End_Time,
                          ["ประเภทวันลา"]: lastSheetData[j].tO_Type,
                          ["จำนวนชั่วโมงการลา"]: lastSheetData[j].tothr,
                          ["เวลาเข้า"]: self.Converttolocal(
                            lastSheetData[j].clock_In_Time
                          ),
                          ["เวลาออก"]: self.Converttolocal(
                            lastSheetData[j].clock_Out_Time
                          ),
                          ["เวลาเข้าโอที"]: self.Converttolocal(
                            lastSheetData[j].oT_Clock_In_Time
                          ),
                          ["เวลาออกโอที"]: self.Converttolocal(
                            lastSheetData[j].oT_Clock_Out_Time
                          ),
                          ["ข้อสังเกต"]: lastSheetData[j].remarkTH,
                          ["สถานะ"]: lastSheetData[j].status,
                          ["ผู้อนุมัติ"]: lastSheetData[j].approver_Name,
                          ["สถานะการเข้างาน"]: lastSheetData[j].clinstat,
                          ["สถานะการทำ OT"]: lastSheetData[j].otclinstat,
                        });
                      } else {
                        if (sessionStorage.flag == "Thai") {
                          wsalldata.unshift({
                            ["วันที่"]: self.formatDate(
                              lastSheetData[j].timeEntry_Date
                            ),
                            ["รหัสพนักงาน"]: lastSheetData[j].employee_Code,
                            ["แผนก"]: lastSheetData[j].department_Name,
                            ["ชื่อพนักงาน"]: lastSheetData[j].fullName,
                            ["เวลาเริ่มต้น Shift"]: lastSheetData[j].start_Time,
                            ["เวลาสิ้นสุด Shift"]: lastSheetData[j].end_Time,
                            ["เวลาเริ่มโอที"]: lastSheetData[j].oT_Start_Time,
                            ["เวลาสิ้นสุดโอที"]: lastSheetData[j].oT_End_Time,
                            ["ประเภทวันลา"]: lastSheetData[j].tO_Type,
                            ["จำนวนชั่วโมงการลา"]: lastSheetData[j].tothr,
                            ["เวลาเข้า"]: self.Converttolocal(
                              lastSheetData[j].clock_In_Time
                            ),
                            ["เวลาออก"]: self.Converttolocal(
                              lastSheetData[j].clock_Out_Time
                            ),
                            ["เวลาเข้าโอที"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_In_Time
                            ),
                            ["เวลาออกโอที"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_Out_Time
                            ),
                            ["ข้อสังเกต"]: lastSheetData[j].remarkTH,
                            ["สถานะ"]: lastSheetData[j].status,
                            ["ผู้อนุมัติ"]: lastSheetData[j].approver_Name,
                            ["สถานะการเข้างาน"]: lastSheetData[j].clinstat,
                            ["สถานะการทำ OT"]: lastSheetData[j].otclinstat,
                          });
                        } else {
                          wsalldata.unshift({
                            ["Date"]: self.formatDate(
                              lastSheetData[j].timeEntry_Date
                            ),
                            ["Employee ID"]: lastSheetData[j].employee_Code,
                            ["Department"]: lastSheetData[j].department_Name,
                            ["Employee Name"]: lastSheetData[j].fullName,
                            ["Shift Start Time"]: lastSheetData[j].start_Time,
                            ["Shift End Time"]: lastSheetData[j].end_Time,
                            ["OT Start Time"]: lastSheetData[j].oT_Start_Time,
                            ["OT End Time"]: lastSheetData[j].oT_End_Time,
                            ["Time Off Type"]: lastSheetData[j].tO_Type,
                            ["Time Off Hour"]: lastSheetData[j].tothr,
                            ["Clock In"]: self.Converttolocal(
                              lastSheetData[j].clock_In_Time
                            ),
                            ["Clock Out"]: self.Converttolocal(
                              lastSheetData[j].clock_Out_Time
                            ),
                            ["OT Clock In"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_In_Time
                            ),
                            ["OT Clock Out"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_Out_Time
                            ),
                            ["Remark"]: lastSheetData[j].remark,
                            ["Status"]: lastSheetData[j].status,
                            ["Approver"]: lastSheetData[j].approver_Name,
                            ["Clock In Status"]: lastSheetData[j].clinstat,
                            ["OT Clock In Status"]: lastSheetData[j].otclinstat,
                          });
                        }
                      }
                    }

                    var dataexportall = wsalldata.map(obj => {
                      const newObj = {};
                      self.ColumnList.forEach(key => {
                        newObj[self.TranslateThis(key.name)] = obj[self.TranslateThis(key.name)];
                      });
                      return newObj;
                    });
                    let oneforall = [];
                    if (localStorage.Templang == "ประเทศไทย") {
                      for (let l = 0; l < dataexportall.length; l++) {
                        let List = {
                          ["วันที่"]: wsalldata[l]["วันที่"],
                          ...dataexportall[l],
                        };
                        oneforall.push(List);
                      }
                    } else {
                      if (sessionStorage.flag == "Thai") {
                        for (let l = 0; l < dataexportall.length; l++) {
                          let List = {
                            ["วันที่"]: wsalldata[l]["วันที่"],
                            ...dataexportall[l],
                          };
                          oneforall.push(List);
                        }
                      } else {
                        for (let l = 0; l < dataexportall.length; l++) {
                          let List = {
                            ["Date"]: wsalldata[l]["Date"],
                            ...dataexportall[l],
                          };
                          oneforall.push(List);
                        }
                      }
                    }

                    ws = XLSX.utils.json_to_sheet(oneforall);
                    ws["!cols"] = [
                      { wch: 20 },
                      { wch: 25 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                    ];

                    let datestring1 =
                      inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
                    let datestring2 =
                      inputenddate[2] +
                      "_" +
                      inputenddate[1] +
                      "_" +
                      inputenddate[0];
                    XLSX.utils.book_append_sheet(
                      wb,
                      ws,
                      datestring1 + "-" + datestring2
                    );
                  }

                  if (hasData)
                  {
                    let exportExcelName = `OptimisticStaffTimeAttendanceDailyView_${self.excelExportDateRange()}.xlsx`;
                    self.exportList = [];
                    self.startdate = "";
                    self.enddate = "";
                    self.LoadingDialog = false;
                    XLSX.writeFile(wb, exportExcelName);
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Export Daily Attendance Fail",
                    23,
                    "Verbose"
                  );
                  alert(error);
                  self.LoadingDialog = false;
                });
            } else {
              let combilearrary = [];
              let tempexport = [];
              let inputdate1 = self.startdate.split("/");
              // let mystartdate1 =
              //   inputdate1[2] + "/" + inputdate1[1] + "/" + inputdate1[0];
              let inputenddate1 = self.enddate.split("/");
              // let myenddate1 =
              //   inputenddate1[2] +
              //   "/" +
              //   inputenddate1[1] +
              //   "/" +
              //   inputenddate1[0];
              for (let i = 0; i < self.selected.length; i++) {
                tempexport = {
                  employee_ID: self.selected[i].empid,
                };
                combilearrary.push(tempexport);
              }
              let tempo = {
                employeeList: combilearrary,
                startdate: self.startdate,
                enddate: self.enddate,
                company_ID: store.state.companyID,
              };
              axios
                .post(
                  `${self.url}Stafftimeattendance/GetExclusiveExportDayView`,
                  tempo
                )
                .then(function (response) {
                  let templist = response.data.data;
                  for (let i = 0; i < templist.length; i++) {
                    let intime = "-";
                    let outtime = "-";
                    let otintime = "-";
                    let otouttime = "-";
                    let cinoutcount = 0;
                    let otcinoutcount = 0;
                    let regin = "-";
                    let latein = "-";
                    let regout = "-";
                    let otin = "-";
                    let lateotin = "-";
                    let yesc = false;
                    let clist = [];
                    let status = null;
                    let outlist = [];
                    let otoutlist = [];
                    for (let f = 0; f < templist[i].regCinoutData.length; f++) {
                      clist.push({
                        clock_ID: templist[i].regCinoutData[f].clock_ID,
                      });
                    }
                    for (let r = 0; r < templist[i].otCinoutData.length; r++) {
                      clist.push({
                        clock_ID: templist[i].otCinoutData[r].clock_ID,
                      });
                    }
                    if (templist[i].irrRegCinoutData.length != 0) {
                      latein = templist[i].irrRegCinoutData[0].clock_In_Time;
                      for (
                        let c = 0;
                        c < templist[i].irrRegCinoutData.length;
                        c++
                      ) {
                        outlist.unshift({
                          time: templist[i].irrRegCinoutData[c].clock_Out_Time,
                        });
                      }
                    }
                    if (templist[i].regCinoutData.length != 0) {
                      let leg = templist[i].regCinoutData.length - 1;
                      regin = templist[i].regCinoutData[0].clock_In_Time;
                      regout = templist[i].regCinoutData[leg].clock_Out_Time;
                      cinoutcount = templist[i].regCinoutData.length;
                      for (let a = 0; a < templist[i].regCinoutData.length; a++) {
                        outlist.unshift({
                          time: templist[i].regCinoutData[a].clock_Out_Time,
                        });
                      }
                    }
                    if (templist[i].irrOTCinoutData.length != 0) {
                      lateotin = templist[i].irrOTCinoutData[0].clock_In_Time;
                      for (
                        let d = 0;
                        d < templist[i].irrOTCinoutData.length;
                        d++
                      ) {
                        otoutlist.unshift({
                          time: templist[i].irrOTCinoutData[d].clock_Out_Time,
                        });
                      }
                    }
                    if (templist[i].otCinoutData.length != 0) {
                      otin = templist[i].otCinoutData[0].clock_In_Time;
                      otcinoutcount = templist[i].otCinoutData.length;
                      for (let b = 0; b < templist[i].otCinoutData.length; b++) {
                        otoutlist.unshift({
                          time: templist[i].otCinoutData[b].clock_Out_Time,
                        });
                      }
                    }

                    if (regin != "-" && regin != null) {
                      intime = regin;
                    } else if (latein != "-" && latein != null) {
                      intime = latein;
                    }

                    for (let e = 0; e < outlist.length; e++) {
                      if (outlist[e].time != "-") {
                        outtime = outlist[e].time;
                        break;
                      }
                    }

                    if (otin != "-" && otin != null) {
                      otintime = otin;
                    } else if (lateotin != "-" && lateotin != null) {
                      otintime = lateotin;
                    }

                    for (let f = 0; f < otoutlist.length; f++) {
                      if (otoutlist[f].time != "-") {
                        otouttime = otoutlist[f].time;
                        break;
                      }
                    }

                    if (
                      regin != "-" &&
                      regin != null &&
                      regout != "-" &&
                      regout != null
                    ) {
                      yesc = true;
                    }

                    if (
                      templist[i].oT_Start_Time == null ||
                      templist[i].oT_Start_Time == ""
                    ) {
                      templist[i].oT_Start_Time = "-";
                    }
                    if (
                      templist[i].oT_End_Time == null ||
                      templist[i].oT_End_Time == ""
                    ) {
                      templist[i].oT_End_Time = "-";
                    }
                    if (
                      templist[i].oT_Start_Time == "-" &&
                      templist.oT_End_Time == "-" &&
                      templist[i].start_Time == "-" &&
                      templist.end_Time == "-"
                    ) {
                      status = "-";
                    } else if (templist[i].approve == true) {
                      status = "Approved";
                    } else {
                      status = "Pending";
                    }
                    let timetypeex = "N/A";
                    let st = "-";
                    let ed = "-";

                    if (templist[i].time_Off_Whole_Day != null) {
                      timetypeex = templist[i].tO_Type;
                      if (templist[i].time_Off_Whole_Day == true) {
                        st = templist[i].start_Time;
                        ed = templist[i].end_Time;
                      } else {
                        st = templist[i].tO_StartTime;
                        ed = templist[i].tO_EndTime;
                      }
                    }
                    let Lists = {
                      ...templist[i],
                      clock_In_Time: intime,
                      clock_Out_Time: outtime,
                      oT_Clock_In_Time: otintime,
                      oT_Clock_Out_Time: otouttime,
                      regcount: cinoutcount,
                      otcount: otcinoutcount,
                      clocklist: clist,
                      yescondi: yesc,
                      status: status,
                      tO_Type: timetypeex,
                      clinstat: self.forexpstatus(
                        intime,
                        templist[i].start_Time,
                        templist[i].timeEntry_Date
                      ),
                      otclinstat: self.forexpstatus(
                        otintime,
                        templist[i].oT_Start_Time,
                        templist[i].timeEntry_Date
                      ),
                      tothr: self.TotalTOHours(st, ed),
                    };
                    self.exportlist.push(Lists);
                  }
                  self.LoadingDialog = false;
                  self.exportdailydialog = false;
                  let hasData = false;
                  let lastSheetData = [];

                  let wb = XLSX.utils.book_new();
                  let mynewstartdate = new Date(
                    inputdate1[2],
                    inputdate1[1],
                    inputdate1[0]
                  );
                  let mynewenddate = new Date(
                    inputenddate1[2],
                    inputenddate1[1],
                    inputenddate1[0]
                  );
                  let wsdata = [];
                  let Difference_In_Time =
                    mynewenddate.getTime() - mynewstartdate.getTime();
                  let Difference_In_Days =
                    Difference_In_Time / (1000 * 3600 * 24) + 1;
                  let totalsheet = Difference_In_Days;

                  for (let sheet = 0; sheet < totalsheet; sheet++) {
                    let tempsheetdata = [];
                    let input = parseInt(inputdate1[0]) + sheet;
                    wsdata = [];
                    let startoftheyear = new Date(
                      inputdate1[2],
                      inputdate1[1] - 1,
                      input
                    );

                    tempsheetdata = self.exportlist.filter((x) => {
                      let tempdate2 = new Date(x.timeEntry_Date);
                      let date2 = new Date(
                        tempdate2.getFullYear(),
                        tempdate2.getMonth(),
                        tempdate2.getDate()
                      );
                      return date2.getTime() == startoftheyear.getTime();
                    });
                    let tempsheetname =
                      self.checkDateExport(startoftheyear.getDate()) +
                      "_" +
                      self.checkDateExport(startoftheyear.getMonth() + 1) +
                      "_" +
                      startoftheyear.getFullYear();

                    if (self.exportlist.length != lastSheetData.length) {
                      lastSheetData = [...lastSheetData, ...tempsheetdata].sort(
                        (a, b) =>
                          new Date(b.timeEntry_Date) - new Date(a.timeEntry_Date)
                      );
                    }
                    if (tempsheetdata.length) {
                      for (let j = 0; j < tempsheetdata.length; j++) {
                        if (localStorage.Templang == "ประเทศไทย") {
                          wsdata.push({
                            ["รหัสพนักงาน"]: tempsheetdata[j].employee_Code,
                            ["แผนก"]: tempsheetdata[j].department_Name,
                            ["ชื่อพนักงาน"]: tempsheetdata[j].fullName,
                            ["เวลาเริ่มต้น Shift"]: tempsheetdata[j].start_Time,
                            ["เวลาสิ้นสุด Shift"]: tempsheetdata[j].end_Time,
                            ["เวลาเริ่มโอที"]: tempsheetdata[j].oT_Start_Time,
                            ["เวลาสิ้นสุดโอที"]: tempsheetdata[j].oT_End_Time,
                            ["ประเภทวันลา"]: tempsheetdata[j].tO_Type,
                            ["จำนวนชั่วโมงการลา"]: tempsheetdata[j].tothr,
                            ["เวลาเข้า"]: self.Converttolocal(
                              tempsheetdata[j].clock_In_Time
                            ),
                            ["เวลาออก"]: self.Converttolocal(
                              tempsheetdata[j].clock_Out_Time
                            ),
                            ["เวลาเข้าโอที"]: self.Converttolocal(
                              tempsheetdata[j].oT_Clock_In_Time
                            ),
                            ["เวลาออกโอที"]: self.Converttolocal(
                              tempsheetdata[j].oT_Clock_Out_Time
                            ),
                            ["ข้อสังเกต"]: tempsheetdata[j].remarkTH,
                            ["สถานะ"]: tempsheetdata[j].status,
                            ["ผู้อนุมัติ"]: tempsheetdata[j].approver_Name,
                            ["สถานะการเข้างาน"]: tempsheetdata[j].clinstat,
                            ["สถานะการทำ OT"]: tempsheetdata[j].otclinstat,
                          });
                        } else {
                          if (sessionStorage.flag == "Thai") {
                            wsdata.push({
                              ["รหัสพนักงาน"]: tempsheetdata[j].employee_Code,
                              ["แผนก"]: tempsheetdata[j].department_Name,
                              ["ชื่อพนักงาน"]: tempsheetdata[j].fullName,
                              ["เวลาเริ่มต้น Shift"]: tempsheetdata[j].start_Time,
                              ["เวลาสิ้นสุด Shift"]: tempsheetdata[j].end_Time,
                              ["เวลาเริ่มโอที"]: tempsheetdata[j].oT_Start_Time,
                              ["เวลาสิ้นสุดโอที"]: tempsheetdata[j].oT_End_Time,
                              ["ประเภทวันลา"]: tempsheetdata[j].tO_Type,
                              ["จำนวนชั่วโมงการลา"]: tempsheetdata[j].tothr,
                              ["เวลาเข้า"]: self.Converttolocal(
                                tempsheetdata[j].clock_In_Time
                              ),
                              ["เวลาออก"]: self.Converttolocal(
                                tempsheetdata[j].clock_Out_Time
                              ),
                              ["เวลาเข้าโอที"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_In_Time
                              ),
                              ["เวลาออกโอที"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_Out_Time
                              ),
                              ["ข้อสังเกต"]: tempsheetdata[j].remarkTH,
                              ["สถานะ"]: tempsheetdata[j].status,
                              ["ผู้อนุมัติ"]: tempsheetdata[j].approver_Name,
                              ["สถานะการเข้างาน"]: tempsheetdata[j].clinstat,
                              ["สถานะการทำ OT"]: tempsheetdata[j].otclinstat,
                            });
                          } else {
                            wsdata.push({
                              ["Employee ID"]: tempsheetdata[j].employee_Code,
                              ["Department"]: tempsheetdata[j].department_Name,
                              ["Employee Name"]: tempsheetdata[j].fullName,
                              ["Shift Start Time"]: tempsheetdata[j].start_Time,
                              ["Shift End Time"]: tempsheetdata[j].end_Time,
                              ["OT Start Time"]: tempsheetdata[j].oT_Start_Time,
                              ["OT End Time"]: tempsheetdata[j].oT_End_Time,
                              ["Time Off Type"]: tempsheetdata[j].tO_Type,
                              ["Time Off Hour"]: tempsheetdata[j].tothr,
                              ["Clock In"]: self.Converttolocal(
                                tempsheetdata[j].clock_In_Time
                              ),
                              ["Clock Out"]: self.Converttolocal(
                                tempsheetdata[j].clock_Out_Time
                              ),
                              ["OT Clock In"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_In_Time
                              ),
                              ["OT Clock Out"]: self.Converttolocal(
                                tempsheetdata[j].oT_Clock_Out_Time
                              ),
                              ["Remark"]: tempsheetdata[j].remark,
                              ["Status"]: tempsheetdata[j].status,
                              ["Approver"]: tempsheetdata[j].approver_Name,
                              ["Clock In Status"]: tempsheetdata[j].clinstat,
                              ["OT Clock In Status"]: tempsheetdata[j].otclinstat,
                            });
                          }
                        }
                      }
                      var dataexport = wsdata.map(obj => {
                        const newObj = {};
                        self.ColumnList.forEach(key => {
                          newObj[self.TranslateThis(key.name)] = obj[self.TranslateThis(key.name)];
                        });
                        return newObj;
                      });
                      var ws = XLSX.utils.json_to_sheet(dataexport);
                      //var ws = XLSX.utils.json_to_sheet(wsdata);
                      ws["!cols"] = [
                        { wch: 20 },
                        { wch: 25 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 20 },
                      ];
                      XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
                      hasData = true;
                    }
                  }

                  if (!hasData) {
                    alert("Your selected date range doesn't have Data!");
                  } else {
                    let wsalldata = [];
                    for (let j = 0; j < lastSheetData.length; j++) {
                      if (localStorage.Templang == "ประเทศไทย") {
                        wsalldata.unshift({
                          ["วันที่"]: self.formatDate(
                            lastSheetData[j].timeEntry_Date
                          ),
                          ["รหัสพนักงาน"]: lastSheetData[j].employee_Code,
                          ["แผนก"]: lastSheetData[j].department_Name,
                          ["ชื่อพนักงาน"]: lastSheetData[j].fullName,
                          ["เวลาเริ่มต้น Shift"]: lastSheetData[j].start_Time,
                          ["เวลาสิ้นสุด Shift"]: lastSheetData[j].end_Time,
                          ["เวลาเริ่มโอที"]: lastSheetData[j].oT_Start_Time,
                          ["เวลาสิ้นสุดโอที"]: lastSheetData[j].oT_End_Time,
                          ["ประเภทวันลา"]: lastSheetData[j].tO_Type,
                          ["จำนวนชั่วโมงการลา"]: lastSheetData[j].tothr,
                          ["เวลาเข้า"]: self.Converttolocal(
                            lastSheetData[j].clock_In_Time
                          ),
                          ["เวลาออก"]: self.Converttolocal(
                            lastSheetData[j].clock_Out_Time
                          ),
                          ["เวลาเข้าโอที"]: self.Converttolocal(
                            lastSheetData[j].oT_Clock_In_Time
                          ),
                          ["เวลาออกโอที"]: self.Converttolocal(
                            lastSheetData[j].oT_Clock_Out_Time
                          ),
                          ["ข้อสังเกต"]: lastSheetData[j].remarkTH,
                          ["สถานะ"]: lastSheetData[j].status,
                          ["ผู้อนุมัติ"]: lastSheetData[j].approver_Name,
                          ["สถานะการเข้างาน"]: lastSheetData[j].clinstat,
                          ["สถานะการทำ OT"]: lastSheetData[j].otclinstat,
                        });
                      } else {
                        if (sessionStorage.flag == "Thai") {
                          wsalldata.unshift({
                            ["วันที่"]: self.formatDate(
                              lastSheetData[j].timeEntry_Date
                            ),
                            ["รหัสพนักงาน"]: lastSheetData[j].employee_Code,
                            ["แผนก"]: lastSheetData[j].department_Name,
                            ["ชื่อพนักงาน"]: lastSheetData[j].fullName,
                            ["เวลาเริ่มต้น Shift"]: lastSheetData[j].start_Time,
                            ["เวลาสิ้นสุด Shift"]: lastSheetData[j].end_Time,
                            ["เวลาเริ่มโอที"]: lastSheetData[j].oT_Start_Time,
                            ["เวลาสิ้นสุดโอที"]: lastSheetData[j].oT_End_Time,
                            ["ประเภทวันลา"]: lastSheetData[j].tO_Type,
                            ["จำนวนชั่วโมงการลา"]: lastSheetData[j].tothr,
                            ["เวลาเข้า"]: self.Converttolocal(
                              lastSheetData[j].clock_In_Time
                            ),
                            ["เวลาออก"]: self.Converttolocal(
                              lastSheetData[j].clock_Out_Time
                            ),
                            ["เวลาเข้าโอที"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_In_Time
                            ),
                            ["เวลาออกโอที"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_Out_Time
                            ),
                            ["ข้อสังเกต"]: lastSheetData[j].remarkTH,
                            ["สถานะ"]: lastSheetData[j].status,
                            ["ผู้อนุมัติ"]: lastSheetData[j].approver_Name,
                            ["สถานะการเข้างาน"]: lastSheetData[j].clinstat,
                            ["สถานะการทำ OT"]: lastSheetData[j].otclinstat,
                          });
                        } else {
                          wsalldata.unshift({
                            ["Date"]: self.formatDate(
                              lastSheetData[j].timeEntry_Date
                            ),
                            ["Employee ID"]: lastSheetData[j].employee_Code,
                            ["Department"]: lastSheetData[j].department_Name,
                            ["Employee Name"]: lastSheetData[j].fullName,
                            ["Shift Start Time"]: lastSheetData[j].start_Time,
                            ["Shift End Time"]: lastSheetData[j].end_Time,
                            ["OT Start Time"]: lastSheetData[j].oT_Start_Time,
                            ["OT End Time"]: lastSheetData[j].oT_End_Time,
                            ["Time Off Type"]: lastSheetData[j].tO_Type,
                            ["Time Off Hour"]: lastSheetData[j].tothr,
                            ["Clock In"]: self.Converttolocal(
                              lastSheetData[j].clock_In_Time
                            ),
                            ["Clock Out"]: self.Converttolocal(
                              lastSheetData[j].clock_Out_Time
                            ),
                            ["OT Clock In"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_In_Time
                            ),
                            ["OT Clock Out"]: self.Converttolocal(
                              lastSheetData[j].oT_Clock_Out_Time
                            ),
                            ["Remark"]: lastSheetData[j].remark,
                            ["Status"]: lastSheetData[j].status,
                            ["Approver"]: lastSheetData[j].approver_Name,
                            ["Clock In Status"]: lastSheetData[j].clinstat,
                            ["OT Clock In Status"]: lastSheetData[j].otclinstat,
                          });
                        }
                      }
                    }
                    var dataexportall = wsalldata.map(obj => {
                      const newObj = {};
                      self.ColumnList.forEach(key => {
                        newObj[self.TranslateThis(key.name)] = obj[self.TranslateThis(key.name)];
                      });
                      return newObj;
                    });
                    let oneforall = [];
                    // for (let l = 0; l < dataexportall.length; l++) {
                    //   let List = {
                    //     ["Date"]: wsalldata[l]["Date"],
                    //     ...dataexportall[l],
                    //   };
                    //   oneforall.push(List);
                    // }
                    if (localStorage.Templang == "ประเทศไทย") {
                      for (let l = 0; l < dataexportall.length; l++) {
                        let List = {
                          ["วันที่"]: wsalldata[l]["วันที่"],
                          ...dataexportall[l],
                        };
                        oneforall.push(List);
                      }
                    } else {
                      if (sessionStorage.flag == "Thai") {
                        for (let l = 0; l < dataexportall.length; l++) {
                          let List = {
                            ["วันที่"]: wsalldata[l]["วันที่"],
                            ...dataexportall[l],
                          };
                          oneforall.push(List);
                        }
                      } else {
                        for (let l = 0; l < dataexportall.length; l++) {
                          let List = {
                            ["Date"]: wsalldata[l]["Date"],
                            ...dataexportall[l],
                          };
                          oneforall.push(List);
                        }
                      }
                    }
                    ws = XLSX.utils.json_to_sheet(oneforall);

                    ws["!cols"] = [
                      { wch: 20 },
                      { wch: 25 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                      { wch: 20 },
                    ];

                    let datestring1 =
                      inputdate1[0] + "_" + inputdate1[1] + "_" + inputdate1[2];
                    let datestring2 =
                      inputenddate1[0] +
                      "_" +
                      inputenddate1[1] +
                      "_" +
                      inputenddate1[2];
                    XLSX.utils.book_append_sheet(
                      wb,
                      ws,
                      datestring1 + "-" + datestring2
                    );
                  }

                  if (hasData) {
                    let exportExcelName = `OptimisticStaffTimeAttendanceDailyView_${self.excelExportDateRange()}.xlsx`;
                    self.exportList = [];
                    self.startdate = "";
                    self.enddate = "";
                    self.LoadingDialog = false;
                    XLSX.writeFile(wb, exportExcelName);
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Export Daily Attendance Fail",
                    23,
                    "Verbose"
                  );
                  alert(error);
                  self.LoadingDialog = false;
                });
            }
          } else {
            self.LoadingDialog = false;
            alert("Start date can’t be after end date!");
          }
        } else {
          alert(this.$t("SelectDatePlease"));
          self.LoadingDialog = false;
        }
      } else {
        self.alertDialog = true;
      }
    },
    exportmonthly() {
      let self = this;
      self.mthselect = [(v) => !!v || "Month is required"];
      self.yerselect = [(v) => !!v || "Year is required"];
      if (self.ColumnListMonth.length != 0) {
        self.LoadingDialog = true;
        if (self.monthselectusertype != "") {
          let temp = {
            employee_ID: self.selected1[0].empid,
            month_Num: self.monthselect,
            year_Num: self.yearselect,
            company_ID: store.state.companyID,
          };
          axios
            .post(
              `${self.url}Stafftimeattendance/GetExclusiveExportMonthView`,
              temp
            )
            .then(function (response) {
              let templist = response.data.data;
              let reallist = [];
              for (let i = 0; i < templist.length; i++) {
                let intime = "-";
                let outtime = "-";
                let otintime = "-";
                let otouttime = "-";
                let cinoutcount = 0;
                let otcinoutcount = 0;
                let regin = "-";
                let latein = "-";
                let regout = "-";
                let otin = "-";
                let lateotin = "-";
                let yesc = false;
                let clist = [];
                let status = null;
                let outlist = [];
                let otoutlist = [];
                for (let f = 0; f < templist[i].regCinoutData.length; f++) {
                  clist.push({
                    clock_ID: templist[i].regCinoutData[f].clock_ID,
                  });
                }
                for (let r = 0; r < templist[i].otCinoutData.length; r++) {
                  clist.push({
                    clock_ID: templist[i].otCinoutData[r].clock_ID,
                  });
                }
                if (templist[i].irrRegCinoutData.length != 0) {
                  latein = templist[i].irrRegCinoutData[0].clock_In_Time;
                  for (let c = 0; c < templist[i].irrRegCinoutData.length; c++) {
                    outlist.unshift({
                      time: templist[i].irrRegCinoutData[c].clock_Out_Time,
                    });
                  }
                }
                if (templist[i].regCinoutData.length != 0) {
                  let leg = templist[i].regCinoutData.length - 1;
                  regin = templist[i].regCinoutData[0].clock_In_Time;
                  regout = templist[i].regCinoutData[leg].clock_Out_Time;
                  cinoutcount = templist[i].regCinoutData.length;
                  for (let a = 0; a < templist[i].regCinoutData.length; a++) {
                    outlist.unshift({
                      time: templist[i].regCinoutData[a].clock_Out_Time,
                    });
                  }
                }
                if (templist[i].irrOTCinoutData.length != 0) {
                  lateotin = templist[i].irrOTCinoutData[0].clock_In_Time;
                  for (let d = 0; d < templist[i].irrOTCinoutData.length; d++) {
                    otoutlist.unshift({
                      time: templist[i].irrOTCinoutData[d].clock_Out_Time,
                    });
                  }
                }
                if (templist[i].otCinoutData.length != 0) {
                  otin = templist[i].otCinoutData[0].clock_In_Time;
                  otcinoutcount = templist[i].otCinoutData.length;
                  for (let b = 0; b < templist[i].otCinoutData.length; b++) {
                    otoutlist.unshift({
                      time: templist[i].otCinoutData[b].clock_Out_Time,
                    });
                  }
                }

                if (regin != "-" && regin != null) {
                  intime = regin;
                } else if (latein != "-" && latein != null) {
                  intime = latein;
                }

                for (let e = 0; e < outlist.length; e++) {
                  if (outlist[e].time != "-") {
                    outtime = outlist[e].time;
                    break;
                  }
                }

                if (otin != "-" && otin != null) {
                  otintime = otin;
                } else if (lateotin != "-" && lateotin != null) {
                  otintime = lateotin;
                }

                for (let f = 0; f < otoutlist.length; f++) {
                  if (otoutlist[f].time != "-") {
                    otouttime = otoutlist[f].time;
                    break;
                  }
                }

                if (
                  regin != "-" &&
                  regin != null &&
                  regout != "-" &&
                  regout != null
                ) {
                  yesc = true;
                }

                if (
                  templist[i].oT_Start_Time == null ||
                  templist[i].oT_Start_Time == ""
                ) {
                  templist[i].oT_Start_Time = "-";
                }
                if (
                  templist[i].oT_End_Time == null ||
                  templist[i].oT_End_Time == ""
                ) {
                  templist[i].oT_End_Time = "-";
                }
                if (
                  templist[i].oT_Start_Time == "-" &&
                  templist.oT_End_Time == "-" &&
                  templist[i].start_Time == "-" &&
                  templist.end_Time == "-"
                ) {
                  status = "-";
                } else if (templist[i].approve == true) {
                  status = "Approved";
                } else {
                  status = "Pending";
                }

                let timetypeex = "N/A";
                let st = "-";
                let ed = "-";

                if (templist[i].time_Off_Whole_Day != null) {
                  timetypeex = templist[i].tO_Type;
                  if (templist[i].time_Off_Whole_Day == true) {
                    st = templist[i].start_Time;
                    ed = templist[i].end_Time;
                  } else {
                    st = templist[i].tO_StartTime;
                    ed = templist[i].tO_EndTime;
                  }
                }
                let Lists = {
                  ...templist[i],
                  clock_In_Time: intime,
                  clock_Out_Time: outtime,
                  oT_Clock_In_Time: otintime,
                  oT_Clock_Out_Time: otouttime,
                  regcount: cinoutcount,
                  otcount: otcinoutcount,
                  clocklist: clist,
                  yescondi: yesc,
                  status: status,
                  tO_Type: timetypeex,
                  clinstat: self.forexpstatus(
                    intime,
                    templist[i].start_Time,
                    templist[i].timeEntry_Date
                  ),
                  otclinstat: self.forexpstatus(
                    otintime,
                    templist[i].oT_Start_Time,
                    templist[i].timeEntry_Date
                  ),
                  tothr: self.TotalTOHours(st, ed),
                };
                reallist.push(Lists);
              }
              if (reallist.length != 0) {
                let tempsheetname = "";
                let wb = XLSX.utils.book_new();
                let wsdata = [];
                for (let v of reallist) {
                  if (localStorage.Templang == "ประเทศไทย") {
                    wsdata.push({
                      ["วันที่รายงาน"]: self.formatDate(v.timeEntry_Date),
                      ["รหัสพนักงาน"]: v.employee_Code,
                      ["แผนก"]: v.department_Name,
                      ["ชื่อพนักงาน"]: v.fullName,
                      ["เวลาเริ่มต้น Shift"]: v.start_Time,
                      ["เวลาสิ้นสุด Shift"]: v.end_Time,
                      ["เวลาเริ่มโอที"]: v.oT_Start_Time,
                      ["เวลาสิ้นสุดโอที"]: v.oT_End_Time,
                      ["ประเภทวันลา"]: v.tO_Type,
                      ["จำนวนชั่วโมงการลา"]: v.tothr,
                      ["เวลาเข้า"]: self.Converttolocal(v.clock_In_Time),
                      ["เวลาออก"]: self.Converttolocal(v.clock_Out_Time),
                      ["เวลาเข้าโอที"]: self.Converttolocal(v.oT_Clock_In_Time),
                      ["เวลาออกโอที"]: self.Converttolocal(v.oT_Clock_Out_Time),
                      ["ข้อสังเกต"]: v.remarkTH,
                      ["สถานะ"]: v.status,
                      ["ผู้อนุมัติ"]: v.approver_Name,
                      ["สถานะการเข้างาน"]: v.clinstat,
                      ["สถานะการทำ OT"]: v.otclinstat,
                    });
                  } else {
                    if (sessionStorage.flag == "Thai") {
                      wsdata.push({
                        ["วันที่รายงาน"]: self.formatDate(v.timeEntry_Date),
                        ["รหัสพนักงาน"]: v.employee_Code,
                        ["แผนก"]: v.department_Name,
                        ["ชื่อพนักงาน"]: v.fullName,
                        ["เวลาเริ่มต้น Shift"]: v.start_Time,
                        ["เวลาสิ้นสุด Shift"]: v.end_Time,
                        ["เวลาเริ่มโอที"]: v.oT_Start_Time,
                        ["เวลาสิ้นสุดโอที"]: v.oT_End_Time,
                        ["ประเภทวันลา"]: v.tO_Type,
                        ["จำนวนชั่วโมงการลา"]: v.tothr,
                        ["เวลาเข้า"]: self.Converttolocal(v.clock_In_Time),
                        ["เวลาออก"]: self.Converttolocal(v.clock_Out_Time),
                        ["เวลาเข้าโอที"]: self.Converttolocal(v.oT_Clock_In_Time),
                        ["เวลาออกโอที"]: self.Converttolocal(v.oT_Clock_Out_Time),
                        ["ข้อสังเกต"]: v.remarkTH,
                        ["สถานะ"]: v.status,
                        ["ผู้อนุมัติ"]: v.approver_Name,
                        ["สถานะการเข้างาน"]: v.clinstat,
                        ["สถานะการทำ OT"]: v.otclinstat,
                      });
                    } else {
                      wsdata.push({
                        ["Report Date"]: self.formatDate(v.timeEntry_Date),
                        ["Employee ID"]: v.employee_Code,
                        ["Department"]: v.department_Name,
                        ["Employee Name"]: v.fullName,
                        ["Shift Start Time"]: v.start_Time,
                        ["Shift End Time"]: v.end_Time,
                        ["OT Start Time"]: v.oT_Start_Time,
                        ["OT End Time"]: v.oT_End_Time,
                        ["Time Off Type"]: v.tO_Type,
                        ["Time Off Hour"]: v.tothr,
                        ["Clock In"]: self.Converttolocal(v.clock_In_Time),
                        ["Clock Out"]: self.Converttolocal(v.clock_Out_Time),
                        ["OT Clock In"]: self.Converttolocal(v.oT_Clock_In_Time),
                        ["OT Clock Out"]: self.Converttolocal(v.oT_Clock_Out_Time),
                        ["Remark"]: v.remark,
                        ["Status"]: v.status,
                        ["Approver"]: v.approver_Name,
                        ["Clock In Status"]: v.clinstat,
                        ["OT Clock In Status"]: v.otclinstat,
                      });
                    }
                  }
                }

                var dataexport = wsdata.map(obj => {
                  const newObj = {};
                  self.ColumnListMonth.forEach(key => {
                    newObj[self.TranslateThis(key.name)] = obj[self.TranslateThis(key.name)];
                  });
                  return newObj;
                });
                let dead = [];
                if (localStorage.Templang == "ประเทศไทย") {
                  for (let l = 0; l < dataexport.length; l++) {
                    let List = {
                      ["วันที่รายงาน"]: wsdata[l]["วันที่รายงาน"],
                      ...dataexport[l],
                    };
                    dead.push(List);
                  }
                } else {
                  if (sessionStorage.flag == "Thai") {
                    for (let l = 0; l < dataexport.length; l++) {
                      let List = {
                        ["วันที่รายงาน"]: wsdata[l]["วันที่รายงาน"],
                        ...dataexport[l],
                      };
                      dead.push(List);
                    }
                  } else {
                    for (let l = 0; l < dataexport.length; l++) {
                      let List = {
                        ["Report Date"]: wsdata[l]["Report Date"],
                        ...dataexport[l],
                      };
                      dead.push(List);
                    }
                  }
                }

                var ws = XLSX.utils.json_to_sheet(dead);
                tempsheetname = `OptimisticStaffTimeAttendance_${self.monthNames[self.monthselect - 1]
                  }${self.yearselect}_${self.selected1[0].empname.replace(
                    / /g,
                    ""
                  )}`;
                self.sheetTitle = `${self.selected1[0].empname}`;
                XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
                XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
                  compression: true,
                });
                self.LoadingDialog = false;
              } else {
                alert("Your selected date range doesn't have Data!");
                self.LoadingDialog = false;
              }
            })
            .catch(function (error) {
              alert(error);
              self.LogTrace(
                error,
                "Export Monthly Attendance Fail",
                23,
                "Verbose"
              );
              self.LoadingDialog = false;
            });
        } else {
          alert(this.$t("SelectOnePlease"));
          self.LoadingDialog = false;
        }
      } else {
        self.alertDialog = true;
      }

    },
    handleclick(item) {
      localStorage.setItem("shift_start", JSON.stringify(item.start_Time));
      localStorage.setItem("shift_end", JSON.stringify(item.end_Time));
      localStorage.setItem("otshift_start", JSON.stringify(item.oT_Start_Time));
      localStorage.setItem("otshift_end", JSON.stringify(item.oT_End_Time));
      localStorage.setItem("toshift_start", JSON.stringify(item.tO_StartTime));
      localStorage.setItem("toshift_end", JSON.stringify(item.tO_EndTime));
      localStorage.setItem(
        "timoffcon",
        JSON.stringify(item.time_Off_Whole_Day)
      );
      localStorage.setItem("local_remark", JSON.stringify(item.remark));
      this.$router
        .push({
          name: "Timesheetdetail",
          params: { id: item.employee_ID },
        })
        .catch(() => { });
    },
    handleclickmonth(id) {
      let self = this;
      // if (self.calendarView == true) {
      //   this.$router.push({
      //     name: "StaffTimeAttendanceCal",
      //     params: { id: id },
      //   });
      // } else {
      self.$router
        .push({ name: "Monthsheetdetail", params: { id: id } })
        .catch(() => { });
      // }
    },
    TotalTOHours(start, end) {
      if (
        start != "-" &&
        end != "-" &&
        start != "" &&
        end != "" &&
        start != null &&
        end != null
      ) {
        let from = new Date();
        let to = new Date();
        let [frhours, frminutes] = start.split(":");
        let [tohours, tominutes] = end.split(":");
        from.setHours(+frhours);
        from.setMinutes(frminutes);
        to.setHours(+tohours);
        to.setMinutes(tominutes);
        let frr = parseInt(frhours);
        let too = parseInt(tohours);
        if (frr > too) {
          let days = to.getDate();
          days = days + 1;
          to.setDate(days);
        }
        let frtimezone = from.getTimezoneOffset() * 60000;
        let totimezone = to.getTimezoneOffset() * 60000;

        let frnewDate = new Date(from.getTime() - frtimezone);
        let tonewDate = new Date(to.getTime() - totimezone);

        var timeDiff = Math.abs(frnewDate - tonewDate);
        var hh = Math.floor(timeDiff / 1000 / 60 / 60);
        timeDiff -= hh * 1000 * 60 * 60;
        var mm = Math.floor(timeDiff / 1000 / 60);
        if (mm < 10) {
          mm = "0" + mm;
        }
        timeDiff -= mm * 1000 * 60;
        var ss = Math.floor(timeDiff / 1000);
        let tt = "";
        if (ss < 10) {
          ss = "0" + ss;
          if (hh == "00") {
            tt = mm + "minutes";
          } else if (mm == "00") {
            tt = hh + " " + "hours";
          } else {
            tt = hh + " " + "hours" + " " + mm + " " + "minutes ";
          }
        }

        return tt;
      } else {
        return "-";
      }
    },

    showme1() {
      localStorage.setItem("temp_tabs", 0);
      this.page = 1;
      this.itemsPerPage = 10;
      this.SearchWithCrit = 1;
      this.searchtxt = "";
      this.FilterDay = 0;
      this.DayViewListForReal = this.DayViewList;
      this.selected = [];
    },

    showme2() {
      localStorage.setItem("temp_tabs", 1);
      this.page1 = 1;
      this.itemsPerPage1 = 10;
      this.SearchWithCritMonth = 1;
      this.monthsearchtxt = "";
      this.selected1 = [];
    },

    movetoYes(item, index) {
      let self = this;
      self.ColumnList.push(item);
      self.NoColumnList.splice(index, 1);
    },
    movetoNo(item, index) {
      let self = this;
      self.NoColumnList.push(item);
      self.ColumnList.splice(index, 1);
    },
    movetoMonthYes(item, index) {
      let self = this;
      self.ColumnListMonth.push(item);
      self.NoColumnListMonth.splice(index, 1);
    },
    movetoMonthNo(item, index) {
      let self = this;
      self.NoColumnListMonth.push(item);
      self.ColumnListMonth.splice(index, 1);
    },
    resetcolumn() {
      let self = this;
      self.NoColumnList = [];
      self.ColumnList = [];
      let temp = [
        { id: 0, val: 1, name: "Employee ID" },
        { id: 1, val: 2, name: "Department" },
        { id: 2, val: 3, name: "Employee Name" },
        { id: 3, val: 4, name: "Shift Start Time" },
        { id: 4, val: 5, name: "Shift End Time" },
        { id: 5, val: 6, name: "OT Start Time" },
        { id: 6, val: 7, name: "OT End Time" },
        { id: 7, val: 8, name: "Time Off Type" },
        { id: 8, val: 9, name: "Time Off Hour" },
        { id: 9, val: 10, name: "Clock In" },
        { id: 10, val: 11, name: "Clock Out" },
        { id: 11, val: 12, name: "OT Clock In" },
        { id: 12, val: 13, name: "OT Clock Out" },
        { id: 13, val: 14, name: "Remark" },
        { id: 14, val: 15, name: "Status" },
        { id: 15, val: 16, name: "Approver" },
        { id: 16, val: 17, name: "Clock In Status" },
        { id: 17, val: 18, name: "OT Clock In Status" },
      ]
      self.ColumnList = [...temp];
      self.DayColumn = 1;
    },
    resetcolumnMonth() {
      let self = this;
      self.NoColumnListMonth = [];
      self.ColumnListMonth = [];
      self.ColumnListMonth = [
        { id: 0, val: 1, name: "Employee ID" },
        { id: 1, val: 2, name: "Department" },
        { id: 2, val: 3, name: "Employee Name" },
        { id: 3, val: 4, name: "Shift Start Time" },
        { id: 4, val: 5, name: "Shift End Time" },
        { id: 5, val: 6, name: "OT Start Time" },
        { id: 6, val: 7, name: "OT End Time" },
        { id: 7, val: 8, name: "Time Off Type" },
        { id: 8, val: 9, name: "Time Off Hour" },
        { id: 9, val: 10, name: "Clock In" },
        { id: 10, val: 11, name: "Clock Out" },
        { id: 11, val: 12, name: "OT Clock In" },
        { id: 12, val: 13, name: "OT Clock Out" },
        { id: 13, val: 14, name: "Remark" },
        { id: 14, val: 15, name: "Status" },
        { id: 15, val: 16, name: "Approver" },
        { id: 16, val: 17, name: "Clock In Status" },
        { id: 17, val: 18, name: "OT Clock In Status" },
      ];
      self.MonthColumn = 1;
    },
    ThereNoColumn() {
      let self = this;
      self.alertDialog = false;
      self.ColumnGone = true;
    },
    //here lie the attachment all sort of thing function
    onpickfile() {
      this.$refs.fileupload.click();
    },
    async handleFileSelect(event) {
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) {
        if (file.type === "application/pdf") {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        } else if (file.type.startsWith("image/")) {
          //const pdfBlob = await this.convertImageToPdf(file);
          // console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];

          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },
    async handleFiledrag(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) {
        if (file.type === "application/pdf") {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        } else if (file.type.startsWith("image/")) {
          //const pdfBlob = await this.convertImageToPdf(file);
          // console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];

          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    uploadFilesall(imgname, type, url, pdfurl) {
      let plusid = this.uploadedFiles.length;
      this.uploadedFiles.push({
        id: plusid,
        name: imgname,
        type: type,
        imgurl: url,
        pdfurl: pdfurl,
      });
      this.uploaddefaultshow = false;
    },
    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },
    base64ToBlobforimg(base64String, contentType) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
    async convertBase64ImageToPdf(base64Image) {
      const pdf = new jsPDF();
      const blob = this.base64ToBlobforimg(base64Image, "image/jpeg"); // Adjust content type if needed
      const imgData = URL.createObjectURL(blob);

      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed

      const pdfData = pdf.output("blob");
      return pdfData;
    },
    async convertImageToPdf(selectedFile) {
      const pdf = new jsPDF();
      const imgData = await this.readFileAsDataUrl(selectedFile);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed

      const pdfData = pdf.output("blob");
      return pdfData;
    },
    readFileAsDataUrl(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },
    removefile(file) {
      const index = this.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1) {
        this.uploadedFiles.splice(index, 1);
      }

      if (this.uploadedFiles.length == 0) {
        this.uploaddefaultshow = true;
      }
    },
    removefileEdit(file) {
      const index = this.EditOT.attachmentlist.findIndex(
        (item) => item.id === file.id
      );
      if (index !== -1) {
        this.EditOT.attachmentlist.splice(index, 1);
      }

      if (this.EditOT.attachmentlist.length == 0) {
        this.uploaddefaultshow = true;
      }
    },
    showiframe(item) {
      let self = this;
      if (
        item.type == "image/png" ||
        item.type == "image/jpeg" ||
        item.type == "image/jpg"
      ) {
        self.imgForshow = "data:image/png;base64," + item.imgurl;
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
      } else {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }
    },
    async showiframeforDetail(item) {
      let self = this;
      if (item.filetype == "pdf") {
        const blob = await self.base64ToBlob(
          item.attachment_file,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      } else {
        self.dialogforDownload = true;
        self.imgForshow = "data:image/png;base64," + item.attachment_file;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },
    handleFileDrop(event) {
      event.preventDefault();
      // const files = event.dataTransfer.files;
      // this.uploadFiles(files);
      this.handleFiledrag(event);
    },
    //till here
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    formatDate1(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      let day = finaldate[2];
      let month = finaldate[1];
      let year = finaldate[0];
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return day + "/" + month + "/" + year;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    checkDaysLeft(){
      let self = this;
      let result = false;
      let takendays = this.getDays();
      let theone = self.leaveDaysList.filter((v) => v.timeOff_ID == self.timeoffAdd.TimeoffID);
      if(theone[0].availablDays > takendays){
        result = true;
        this.timeoffAdd.Balance = (theone[0].availablDays - takendays).toString();
      }
      return result;
    },
    getDays() {
      if(this.timeoffAdd.Alldaycheck == true){
      // let sd = new Date(this.timeoffAdd.StartDate);
      // let ed = new Date(this.timeoffAdd.EndDate);
      // Convert both dates to UTC to ensure consistent calculation
      // const startUtc = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
      // const endUtc = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());

      const [startDay, startMonth, startYear] = this.timeoffAdd.StartDate.split('/').map(Number);
      const [endDay, endMonth, endYear] = this.timeoffAdd.EndDate.split('/').map(Number);

      const sd = new Date(startYear, startMonth - 1, startDay);
        const ed = new Date(endYear, endMonth - 1, endDay);
        const timeDifference = ed.getTime() - sd.getTime();
        
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference + 1;

      // Calculate the time difference in milliseconds
      // const timeDifference = endUtc - startUtc;

      // // Calculate the number of days by dividing the time difference by milliseconds in a day
      // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
      //   return daysDifference + 1;
      } else {
        return 0.5;
      }
      }
  },
};
</script>
<style scoped>
::-webkit-file-upload-button {
  overflow: hidden;
  height: 0;
  width: 0;
  border: none;
}

/* >>>.mx-datepicker svg {
  width: 0;
  height: 0;
  fill: currentColor;
  display: none !important;
}

>>>.mx-input {
  border: 2px solid #f99d20 !important;
} */

@media only screen and (max-width: 320px) {
  .calendar_box {
    width: 100px !important;
  }
}

@media only screen and (max-width: 320px) {
  .month_yeardate {
    margin-left: unset !important;
  }
}

@media only screen and (max-width: 768px) {
  .month_yeardate {
    width: 100px !important;
  }
}

@media only screen and (device-width: 768px) {
  .excel_btn {
    margin-right: -8rem !important;
  }
}

/* @media only screen and (device-width: 768px) {
  .approve_btn {
    margin-left: -0.5rem !important;
  }
} */

.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}

.v-tabs-bar .v-tab {
  background-color: white;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}

>>>.v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}

.empID {
  font-family: "Kanit", sans-serif;
}

>>>.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--fluid>.v-input__control>.v-input__slot,
.v-text-field--outlined>.v-input__control>.v-input__slot {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 40px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

>>>.mdi-paperclip::before {
  content: none;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

::v-deep .v-window {
  overflow: visible!important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}

>>>.v-dialog {
  overflow-y: auto!important;
  overflow-x: hidden!important;
  border-radius: 20px;
}

/* >>>.timeoffdialog .v-dialog{
  overflow-y: auto!important;
  overflow-x: hidden!important;
  border-radius: 20px;
} */

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}

>>>.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  height: 70px;
}

.thebox1 {
  min-height: 500px;
  width: 100%;
  border-radius: 10px;
}

.thebox {
  border: 2px solid #70707038;
  min-height: 500px;
  width: 100%;
  border-radius: 10px;
}

.theheadtext {
  background-color: #f99d20;
  font-size: 20px;
  border-radius: 9px 9px 0px 0px;
  height: 55px;
  padding-top: 15px;
  font-weight: lighter;
  /* overflow: hidden;
  text-overflow: ellipsis;  */
}

.theitembox {
  border: 1px solid #f99d20;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  max-height: 50px;
  min-width: 120px;
  color: #f99d20;
}

.theNoitembox {
  border: 1px solid #D7DAE2;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  max-height: 50px;
  min-width: 120px;
  color: #D7DAE2;
}
.thehei {
  height: 100px;
}
@media (max-width: 1904px) {
  .fixed-lg {
    margin-right: 1rem !important;
  }

  .fixed-lg-item2 {
    margin-right: 0rem !important;
  }
}

@media (max-width: 960px) {
  .fixed-btn-align {
    float: left !important;
  }
}

@media (max-width: 3200px) {
  .fixed-lg {
    margin-right: 1rem !important;
  }

  .fixed-lg-item2 {
    margin-right: 0rem !important;
  }
}

@media (min-width: 767px) and (max-width: 768px) {
  .moveleft {
    margin-left: 15px;
  }

  .type {
    margin-top: -3rem !important;
  }
}

@media (min-width: 600px) and (max-width: 1270px) {
  .gobelow {
    margin-top: 30px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .gobelow1 {
    margin-top: 30px;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .lleft {
    margin-left: 30px;
  }

  .rright {
    margin-right: 30px;
  }
}

@media (min-width: 1904px) {
  .moveleft {
    margin-top: 1rem !important;
  }

  .mvl {
    margin-top: 2rem !important;
  }
}

@media (min-width: 250px) and (max-width: 416px) {
  .movetop {
    margin-top: -0.8rem !important;
  }
}

@media (min-width: 250px) and (max-width: 600px) {
  .approve_btn {
    margin-left: -0.5rem !important;
  }
}

.calendar-margin {
  margin-left: 60px;
}

@media (max-height: 900px) and (max-width: 1300px) {
  .calendar-margin {
    margin-left: 0px;
  }
}

@media (max-height: 1400px) and (max-width: 1030px) {
  .calendar-margin {
    margin-left: 0px;
  }
}
.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}
.container1 {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}
.pdf-container {
  background-color: #aaa9ae;
  margin-left: 3rem;
  margin-right: 3rem;
}
.close-button {
  position: relative;
  top: -15px;
  left: 80px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.image-iframe {
  width: 400px;
  /* Set your desired width */
  height: 400px;
  /* Set your desired height */
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
