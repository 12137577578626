<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem" class="fix-calender">
    <div>
      <p
        style="
          font-size: 25px;
          color: #43425d;
          margin-top: 20px;
        "
      >
        {{ $t("timeEntries") }}
      </p>
    </div>
    <div v-show="!showsheetview">
      <v-card class="mt-6 mb-5" style="margin-bottom: 5rem">
        <v-row class="pt-5" style="margin-left: 15px; margin-right: 15px">
          <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4">
            <v-text-field
              :placeholder="$t('Search')"
              v-model.lazy="searchtxt"
              color="#F99D20"
              dense
              style="margin-top: -0.8rem"
            ></v-text-field>
          </v-col> -->

          <v-spacer></v-spacer>
          <v-btn
            width="10px"
            class="white--text text-capitalize ml-3 mr-2"
            max-height="30"
            color="#F99D20"
            @click="prev"
          >
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <p style="display: inline" class="ml-1 mr-1 mt-1">
            {{ SortingDate }}
          </p>
          <v-btn
            max-height="30"
            class="white--text text-capitalize ml-2 mr-3"
            width="30"
            color="#F99D20"
            @click="next"
          >
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :headers="headers"
          class="tbheader"
          :items="CinCoutLists"
          :search="searchtxt"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-header
          hide-default-footer
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 130px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('timeEntry_Date')"
                >
                  {{ $t("date") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small v-if="!timeEntry_Date">mdi-arrow-down</v-icon>
                  <v-icon small v-if="timeEntry_Date">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 170px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('shift_StartTime')"
                >
                  {{ $t("shiftStartTime") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small v-if="!shift_StartTime">mdi-arrow-down</v-icon>
                  <v-icon small v-if="shift_StartTime">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 160px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('shft_EndTime')"
                >
                  {{ $t("shiftEndTime") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!shft_EndTime">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="shft_EndTime">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 110px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('oT_Shift_StartTime')"
                >
                  {{ $t("otStartTime") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!ot_Shift_StartTime">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="ot_Shift_StartTime">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 110px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('oT_Shift_EndTime')"
                >
                  {{ $t("otEndTime") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!ot_Shift_EndTime">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="ot_Shift_EndTime">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 120px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('clock_In_Time')"
                >
                  {{ $t("clockIn") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!clock_In_Time">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="clock_In_Time">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 120px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('clock_Out_Time')"
                >
                  {{ $t("clockOut") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!clock_Out_Time">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="clock_Out_Time">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 120px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('oT_Clock_In_Time')"
                >
                  {{ $t("otClockIn") }}
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!ot_clock_In_Time">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="ot_clock_In_Time">mdi-arrow-up</v-icon> -->
                </th>
                <th
                  style="
                    font-size: 15px;
                    cursor: pointer;
                    min-width: 130px !important;
                    text-align: center;
                  "
                  class="b-text text-center"
                  @click="sortFun('oT_Clock_Out_Time')"
                >
                  {{ $t("otClockOut") }}sdfsdfsdfsdf
                  <img
                    :src="images.sort"
                    style="width: 15px; height: 15px"
                    class="mt-1 ml-1"
                  />
                  <!-- <v-icon small color="orange darken-2" v-if="!ot_clock_Out_Time">mdi-arrow-down</v-icon>
                  <v-icon small color="orange darken-2" v-if="ot_clock_Out_Time">mdi-arrow-up</v-icon> -->
                </th>
                <th style="text-align: center; font-size: 15px">
                  {{ $t("action") }}
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ formatDate(item.timeEntry_Date) }}</td>
              <!-- <td style="text-align:center">{{item.timeEntry_Date}}</td> -->
              <td style="text-align: right">{{ item.shift_StartTime }}</td>
              <td style="text-align: right">{{ item.shft_EndTime }}</td>
              <td style="text-align: right">{{ item.oT_Shift_StartTime }}</td>
              <td style="text-align: right">{{ item.oT_Shift_EndTime }}</td>
              <td style="text-align: right">
                {{ Converttolocal(item.clock_In_Time) }}
              </td>
              <td style="text-align: right">
                {{ Converttolocal(item.justforshowOut) }}
              </td>
              <td style="text-align: right">
                {{ Converttolocal(item.oT_Clock_In_Time) }}
              </td>
              <td style="text-align: right">
                {{ Converttolocal(item.justforshowOTOut) }}
              </td>
              <td style="text-align: center">
                <div v-show="item.showview">
                  <p
                    style="
                      color: #f99d20;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    @click="gotoview(item)"
                  >
                    {{ $t("view") }}
                  </p>
                </div>
                <div v-show="item.showholiday">
                  <p
                    style="
                      color: #18a32b;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                  >
                    Holiday
                  </p>
                </div>
                <div v-show="item.showdayoff">
                  <!-- <v-row> -->
                  <p
                    style="
                      color: #f99d20;
                      text-decoration: underline;
                      cursor: pointer;
                      margin-right: 5px;
                    "
                    @click="gotoviewwithremark(item)"
                  >
                    {{ $t("view") }}
                  </p>
                  <!-- <p
                      style="
                        color: #18a32b;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                    >
                      Time-Off
                    </p> 
                  </v-row>-->
                </div>
                <div v-show="item.showfalseview">
                  <p
                    style="
                      color: #acacac;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                  >
                    {{ $t("view") }}
                  </p>
                </div>
                <div v-show="item.showclockin">
                  <p
                    style="
                      color: #0fa7d8;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    :readonly="permission.clockin"
                    @click="OpenClockInDialig(item)"
                  >
                    {{ $t("Clock-in") }}
                  </p>
                </div>
                <div v-show="item.showclockinwithview">
                  <v-row>
                    <p
                      style="
                        color: #f99d20;
                        text-decoration: underline;
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      @click="gotoview(item)"
                    >
                      {{ $t("view") }}
                    </p>
                    <p
                      style="
                        color: #0fa7d8;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      :readonly="permission.clockin"
                      @click="OpenClockInDialig(item)"
                    >
                      {{ $t("Clock-in") }}
                    </p>
                  </v-row>
                </div>
                <div v-show="item.makereadonly">
                  <!-- <v-row> -->
                  <p
                    style="
                      color: #f5aa45;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                  >
                    {{ $t("view") }}
                  </p>
                  <!-- <p
                      style="
                        color: #86c0db;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                    >
                      Clock-in
                    </p> -->
                  <!-- <p style="color:#0FA7D8; text-decoration: underline; cursor:pointer;"  @click="OpenClockInDialig(item)">Clock-in</p> -->
                  <!-- </v-row> -->
                </div>
                <div v-show="item.showclockout">
                  <p
                    style="
                      color: #ff0000;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    :readonly="permission.clockin"
                    @click="OpenClockOutDialig(item)"
                  >
                    {{ $t("Clock-out") }} 
                  </p>
                </div>
                <div v-show="item.showclockoutwithview">
                  <v-row>
                    <p
                      style="
                        color: #f99d20;
                        text-decoration: underline;
                        cursor: pointer;
                        margin-right: 5px;
                      "
                      @click="gotoview(item)"
                    >
                      {{ $t("view") }}
                    </p>
                    <p
                      style="
                        color: #ff0000;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      :readonly="permission.clockin"
                      @click="OpenClockOutDialig(item)"
                    >
                      {{ $t("Clock-out") }}
                    </p>
                  </v-row>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="pt-2 d-flex">
          <div style="margin-left: auto; padding: 1rem;margin-right: 2rem; cursor:pointer;">
            <v-spacer></v-spacer>
            <v-row>
              <v-col>
                <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
              </v-col>
              <v-col style="margin-top:-10px">
                <div style="width:110px" class="mt-3">
                  <v-select
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    :items="items"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>-->
      </v-card>
    </div>
    <div class="pt-6" v-show="showsheetview">
      <v-card class="pt-5" style="margin-bottom: 5rem">
        <div
          style="margin-left: 2rem; margin-right: 2rem"
          class="fixed-calendar"
        >
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="4" xl="3" lg="4" class="d-flex">
              <div
                style="
                  width: 20px;
                  height: 20px;
                  background-color: #0fa7d8;
                  border-radius: 4px;
                "
              ></div>
              <p class="ml-3" style="margin-top: -3px">
                {{ $t("regularClock-InClock-Out") }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="3" lg="4" class="d-flex">
              <div
                style="
                  width: 20px;
                  height: 20px;
                  background-color: #f99d20;
                  border-radius: 4px;
                "
              ></div>
              <p class="ml-3" style="margin-top: -3px">
                {{ $t("overTimeClock-InClock-Out") }}
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <div
              class="mr-3 boxhsize"
              style="
                background-color: #f99d20;

                border-radius: 5px;
                margin-left: 12px;
              "
            >
              <v-row>
                <v-autocomplete
                  class="selectboxs minizebox ml-4 mt-1 mr-4 mb-1 pl-0"
                  v-model="todaymonths"
                  height="34px"
                  single-line
                  :items="monthNames1"
                  item-text="txt"
                  item-value="val"
                  background-color="#FFFFFF"
                  return-object
                  persistent-hint
                  v-on:change="ChangeMonth(`${todaymonths.val}`)"
                ></v-autocomplete>
                <date-picker
                  v-model="todayyears"
                  type="year"
                  color="#0FA7D8"
                  class="selectboxs minizebox ml-1 mr-4 mt-1 mb-1 pl-0"
                  v-on:change="ChangeYear(todayyears)"
                >
                  <template slot="icon-calendar">
                    <img
                      src="@/assets/images/down1@2x.png"
                      style="
                        width: 20px;
                        height: 20px;
                        margin-right: -7px;
                        padding-top: 3px;
                      "
                    />
                  </template>
                </date-picker>
                <!--style="margin-top: 0.5rem;width:150px" 
                 <v-select
                  class="selectboxs minizebox ml-1 mr-4 mt-1 mb-1 pl-0"
                  single-line
                  v-model="todayyears"
                  :items="years"
                  background-color="#FFFFFF"
                  item-text="txt"
                  persistent-hint
                ></v-select>-->
              </v-row>
            </div>
          </v-row>

          <v-row class="mt-5">
            <!-- <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="12"> -->
            <v-col>
              <v-card class="card" style="height: 700px">
                <v-sheet height="700px">
                  <v-calendar
                    :now="focus"
                    ref="calendar"
                    type="month"
                    :value="focus"
                    color="primary"
                    :events="events"
                    event-color="white"
                  >
                    <template v-slot:event="{ event }">
                      <div class="pa-1 my-event" layout="column">
                        <div v-show="event.showview">
                          <v-chip
                            style="background: #dbf1de"
                            color="#18A32B"
                            class="green--text chiptoggle"
                            :disabled="event.disall"
                            @click="gotoview(event)"
                            small
                            >{{ $t("Complete") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showoffview">
                          <v-chip
                            style="background: #dbf1de"
                            color="#18A32B"
                            class="green--text chiptoggle"
                            :disabled="event.disall"
                            @click="gotoview(event)"
                            small
                            >{{ $t("view") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showdayoff">
                          <v-chip
                            style="background: #dbf1de"
                            color="#18A32B"
                            class="green--text chiptoggle"
                            :disabled="event.disall"
                            @click="gotoviewwithremark(event)"
                            small
                            >{{ $t("TimeOff") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showfalseview">
                          <v-chip
                            style="background: #0000001a"
                            color="#ACACAC"
                            class="grey--text chiptoggle"
                            small
                            >{{ $t("Complete") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showclockinwithview">
                          <v-chip
                            style="background: #daf2f9; "
                            color="#0FA7D8"
                            class="blue--text chiptoggle gosideway"
                            :disabled="event.makereadonly || permission.clockin"
                            @click="OpenClockInDialig(event)"
                            small
                            >{{ $t("Clock-in") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showclockoutwithview">
                          <v-chip
                            style="background: #fa8e8c"
                            color="#FF0000"
                            class="red--text chiptoggle gosideway"
                            :disabled="event.disall || permission.clockin"
                            @click="OpenClockOutDialig(event)"
                            small
                            >{{ $t("Clock-out") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showclockin">
                          <v-chip
                            style="background: #daf2f9"
                            color="#0FA7D8"
                            class="blue--text chiptoggle"
                            :disabled="event.makereadonly || permission.clockin"
                            @click="OpenClockInDialig(event)"
                            small
                            >{{ $t("Clock-in") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showclockout">
                          <v-chip
                            style="background: #fa8e8c"
                            color="#FF0000"
                            class="red--text chiptoggle"
                            :disabled="event.disall || permission.clockin"
                            @click="OpenClockOutDialig(event)"
                            small
                            >{{ $t("Clock-out") }}</v-chip
                          >
                        </div>
                        <div v-show="event.showholiday">
                          <v-chip
                            style="background: #dbf1de"
                            color="#18A32B"
                            class="green--text chiptoggle"
                            :disabled="event.makereadonly"
                            small
                          >
                            {{ $t("Holiday") }}</v-chip
                          >
                        </div>
                        <!-- <div v-show="event.showview">
                          <v-chip
                            style=" background:#DAF2F9; margin-top:-70px;"
                            color="#0FA7D8"
                            class="blue--text chiptoggle"
                            :disabled="event.makereadonly"
                            @click="OpenClockInDialig(event)"
                            small
                          >Clock-in</v-chip>
                        </div> -->

                        <v-row class="hidetxts">
                          <v-col
                            style="text-align: center; margin-bottom: 10px"
                          >
                            <p style="margin-top: -40px; color: #0fa7d8">
                              {{ Converttolocal(event.clock_In_Time) }}
                            </p>
                            <p style="cursor: pointer; color: #f89d1f">
                              {{ Converttolocal(event.oT_Clock_In_Time) }}
                            </p>
                          </v-col>
                          <v-divider
                            style="margin-top: -25px; height: 40px"
                            vertical
                          ></v-divider>
                          <v-col style="text-align: center">
                            <p style="margin-top: -40px; color: #0fa7d8">
                              {{ Converttolocal(event.justforshowOut) }}
                            </p>
                            <p style="cursor: pointer; color: #f89d1f">
                              {{ Converttolocal(event.justforshowOTOut) }}
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </template>
                    <!-- <template v-slot:day="{present, past, date}">
                        <v-col class="fill-height">
                          <template v-if="present || past && CalCinCoutLis[date]">
                            <v-sheet
                              v-for="i in CalCinCoutList"
                              :key="i"
                            >
                            <v-chip
                              style="margin-top:-90px; margin-left: 40px;"
                              color="orange"
                              outlined
                              @click="gotoview"
                            >Clock-In</v-chip>
                            <v-row>
                              <v-col>
                                <p class="ml-2" style="margin-top:-30px; color:#0FA7D8">8:30</p>
                                <p
                                  class="ml-5"
                                  @click="OpenClockInDialig(item)"
                                  style="cursor:pointer;color:#F89D1F"
                                >-</p>
                              </v-col>
                              <v-divider style="margin-top:-10px; height:40px;" vertical></v-divider>
                              <v-col style="align:center">
                                <p class="ml-2" style=" margin-top:-30px; color:#0FA7D8">17:00</p>
                                <p
                                  class="ml-5"
                                  @click="OpenClockOutDialig(item)"
                                  style="cursor:pointer;color:#F89D1F"
                                >-</p>
                              </v-col>
                            </v-row>
                            </v-sheet>
                    </template>-->
                    <!-- <template v-if="past && tracked[date]">
                            <v-sheet
                              v-for="(percent, i) in tracked[date]"
                              :key="i"
                              :title="category[i]"
                              :color="colors[i]"
                              :width="`${percent}%`"
                              height="20%"
                              tile
                            ></v-sheet>
                    </template>-->
                    <!-- </v-col>
                    </template>-->
                  </v-calendar>
                </v-sheet>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div style="height: 10vh"></div>
      </v-card>
    </div>

    <!-- open clok-in dialog -->
    <v-dialog v-model="ClockInDialig" persistent max-width="408px">
      <v-card ref="form">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: 0px">
            {{ $t("Clock-in") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="clock.timeType"
                  class="empID"
                  style="margin-top: 0.95rem"
                  :label="$t('TimeType')"
                  :items="timeTypeItem"
                  :error-messages="error.errormessagetimetype"
                  append-icon="mdi-chevron-down"
                  color="#F89D1F"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-inTime") }}</v-col>
              <v-col cols="6">{{ cintime }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-outTime") }}</v-col>
              <v-col cols="6">-</v-col>
            </v-row>
            <v-row align="center">
              <v-col align="center" cols="12">
                <p
                  @click="openmap()"
                  style="
                    color: #0fa7d8;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  {{ $t("ViewClock-inMap") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-container>
          <v-row no-gutters>
            <v-col cols="12" sm="12" lg="6" xs="12" xl="6" class="pr-2">
              <v-btn
                class="text-capitalize mt-2"
                width="180"
                block
                style="font-weight: normal"
                max-height="35"
                @click="CloseCinDialog()"
                text
                >{{ $t("Cancel") }}</v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" lg="6" xs="12" xl="6" class="pr-2">
              <v-btn
                color="#0FA7D8"
                class="white--text text-capitalize mr-2 mt-2"
                style="font-weight: normal"
                block
                @click="clockingIn()"
                max-height="34"
              >
                <img src="@/assets/images/Group 3194.png" class="pr-1" />
                {{ $t("Clock-in") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ClockOutDialig" persistent max-width="408px">
      <v-card ref="form">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("Clock-out") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  class="empID"
                  v-model="clock.timeType"
                  style="margin-top: 0.95rem"
                  :label="$t('TimeType')"
                  :items="timeTypeItem"
                  :error-messages="error.errormessagetimetype"
                  append-icon="mdi-chevron-down"
                  readonly
                  color="#F89D1F"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-inTime") }}</v-col>
              <v-col cols="6">{{ cintime }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-outTime") }}</v-col>
              <v-col cols="6">{{ coutime }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6"> {{ $t("Total-Hours") }}</v-col>
              <v-col cols="6">{{ totaltime }}{{ $t("Hours") }}</v-col>
            </v-row>
            <v-row align="center">
              <v-col align="center" cols="12">
                <p
                  @click="mapp"
                  style="
                    color: #0fa7d8;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  {{ $t("ViewClock-inMap") }}
                </p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col align="center" cols="12">
                <p
                  @click="openmapout"
                  style="
                    color: #ff0000;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  {{ $t("ViewClock-OutMap") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

           <v-container>
          <v-row no-gutters>
            <v-col cols="12" sm="12" lg="5" xs="12" xl="5" class="pr-2">
              <v-btn
                                class="text-capitalize mr-2 mt-2"

             block
              style="font-weight: normal"
              max-height="35"
              @click="CloseCoutDialog()"
              text
              >{{ $t("Cancel") }}</v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" lg="6" xs="12" xl="6" class="pr-2">
              <v-btn
                 color="#FF0000"
               class="white--text text-capitalize mr-2 mt-2"
              block
              style="font-weight: normal"
              @click="clockingOut()"
              max-height="34"
            >
              <img src="@/assets/images/Group 3195.png" class="pr-2" />{{
                $t("Clock-out")
              }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        
      </v-card>
    </v-dialog>

    <v-dialog v-model="MapInDialog" persistent max-width="1350px">
      <v-card>
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <!-- <v-col cols="12" xs="12" sm="7" md="7" lg="7" xl="7" ></v-col> -->
          <v-col
            cols="12"
            xs="12"
            sm="9"
            md="9"
            lg="9"
            xl="9"
            class="pt-0 pl-0 pr-0 pb-0"
          >
            <gmap-map
              :center="center"
              ref="map"
              :zoom="15"
              style="width: 100%; height: 500px"
            >
              <gmap-marker
                :position="marker.position"
                @click="center = marker.position"
              ></gmap-marker>
            </gmap-map>
          </v-col>
          <v-col v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-row>
              <p style="font-size: 20px" class="ml-5 mt-5">
                {{ $t("Details") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="closemap()" icon style="margin-right: 1rem">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p
              class="mt-7 ml-2 fontwei"
              style="font-size: 14px; color: #8a8c8e"
            >
              {{ $t("address") }}
            </p>
            <v-textarea
              readonly
              v-model="location"
              class="mt-2 ml-2 fontwei"
              outlined
            ></v-textarea>

            <p
              class="mt-3 ml-2 fontwei"
              style="font-size: 14px; color: #8a8c8e"
            >
              latitude, longitude
            </p>
            <p
              class="mt-1 ml-2 fontwei"
              style="font-size: 16px; color: #f99d20"
            >
              {{ locationlat }}&nbsp;{{ locationlang }}
            </p>

            <v-row style="margin-top: 90px" justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-btn
                  @click="confirmcinlocate()"
                  class="fontwei text-capitalize"
                  width="100%"
                  color="#F99D20"
                  >{{ $t("confirm") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="MapOutDialog" persistent max-width="1350px">
      <v-card>
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <v-col
            cols="12"
            xs="12"
            sm="9"
            md="9"
            lg="9"
            xl="9"
            class="pt-0 pl-0 pr-0 pb-0"
          >
            <gmap-map
              :center="center"
              ref="map"
              :zoom="15"
              style="width: 100%; height: 500px"
            >
              <gmap-marker
                :position="marker.position"
                @click="center = marker.position"
              ></gmap-marker>
            </gmap-map>
          </v-col>
          <v-col v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-row>
              <p style="font-size: 20px" class="ml-5 mt-5">
                {{ $t("Details") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn
                @click="closemapout()"
                icon
                style="margin-right: 1rem"
                class="mt-3"
              >
                <!-- <img :src="images.minus" class="mb-1" /> -->
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p
              class="mt-7 ml-2 fontwei"
              style="font-size: 14px; color: #8a8c8e"
            >
              {{ $t("address") }}
            </p>
            <v-textarea
              readonly
              v-model="outlocation"
              class="mt-2 ml-2 fontwei"
              outlined
            ></v-textarea>

            <p
              class="mt-3 ml-2 fontwei"
              style="font-size: 14px; color: #8a8c8e"
            >
              latitude, longitude
            </p>
            <p
              class="mt-1 ml-2 fontwei"
              style="font-size: 16px; color: #f99d20"
            >
              {{ outlocationlat }}&nbsp;{{ outlocationlang }}
            </p>

            <v-row style="margin-top: 90px" justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-btn
                  @click="confirmcoutlocate()"
                  class="fontwei text-capitalize"
                  color="#F99D20"
                  width="100%"
                  >{{ $t("confirm") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="MapInEditDialog" persistent max-width="1350px">
      <v-card>
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <v-col
            cols="12"
            xs="12"
            sm="9"
            md="9"
            lg="9"
            xl="9"
            class="pt-0 pl-0 pr-0 pb-0"
          >
            <gmap-map
              :center="center"
              ref="map"
              :zoom="15"
              style="width: 100%; height: 500px"
            >
              <gmap-marker
                :position="marker.position"
                @click="center = marker.position"
              ></gmap-marker>
            </gmap-map>
            <!-- <div id="map"></div> -->
          </v-col>
          <v-col v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-row>
              <p style="font-size: 20px" class="ml-5 mt-5">
                {{ $t("Details") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="closemapin" icon style="margin-right: 1rem">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p
              class="mt-7 ml-2 fontwei"
              style="font-size: 14px; color: #8a8c8e"
            >
              {{ $t("address") }}
            </p>
            <v-textarea
              readonly
              v-model="showlocation"
              class="mt-2 ml-2 fontwei"
              outlined
            ></v-textarea>

            <p
              class="mt-3 ml-2 fontwei"
              style="font-size: 14px; color: #8a8c8e"
            >
              latitude, longitude
            </p>
            <p
              class="mt-1 ml-2 fontwei"
              style="font-size: 16px; color: #f99d20"
            >
              {{ showlocationlat }}&nbsp;{{ showlocationlang }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import DatePicker from "vue2-datepicker";
import ReportTrace from "@/function/Report.js";
import LogTrace from "@/function/Log.js";
import CheckViewClockIn from "@/function/RolePermissions.js";
import "vue2-datepicker/index.css";
import { utils } from "@/function/AuthenticatedRedirect.js";

export default {
  name: "GoogleMap",
  mixins: [ReportTrace, LogTrace, CheckViewClockIn,utils],
  components: { DatePicker },
  data() {
    return {
      permission: {
        clockin: true,
      },
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      value3: new Date(),
      reportDateFrom: new Date(),
      map: "",
      marker: "",
      center: { lat: 45.508, lng: -73.587 },
      location: "",
      locationlat: null,
      locationlang: null,
      showlocation: "",
      showlocationlat: null,
      showlocationlang: null,
      outlocation: "",
      outlocationlat: null,
      outlocationlang: null,
      focus: new Date().toISOString().substr(0, 10),
      images: {
        minus: require("@/assets/images/Mask Group 71.png"),
        sort: require("@/assets/images/sort@2x.png"),
      },
      searchtxt: "",
      showview: false,
      todaymonth: "",
      showsheetview: false,
      showfalseview: true,
      showclockin: true,
      showclockout: true,
      ClockInDialig: false,
      ClockOutDialig: false,
      LoadingDialog: false,
      MapInDialog: false,
      MapOutDialog: false,
      MapInEditDialog: false,
      TimeViewList: [],
      HolidayList: [],
      WorkList: [],
      CinCoutList: [],
      CinCoutLists: [],
      clock: {
        clockintime: "",
        clockouttime: "",
        timeID: null,
        clockID: null,
        timeType: null,
        clockinlocate: null,
        clockoutlocate: null,
        cinlang: null,
        coutlang: null,
        cinlat: null,
        coutlat: null,
        shiftstart: null,
        shiftend: null,
        otshiftstart: null,
        otshiftend: null,
      },
      cintime: "",
      coutime: "",
      totaltime: null,
      timeEntry_Date: null,
      shift_StartTime: null,
      shft_EndTime: null,
      oT_Shift_StartTime: null,
      oT_Shift_EndTime: null,
      clock_In_Time: null,
      clock_Out_Time: null,
      ot_Clock_In_Time: null,
      ot_Clock_Out_Time: null,
      startbefore: null,
      startbeforetime: null,
      startafter: null,
      startaftertime: null,
      endbefore: null,
      endbeforetime: null,
      endafter: null,
      endaftertime: null,
      headers: [
        {
          text: "Date",
          value: "timeEntry_Date",
          align: "left",
          sortable: true,
        },
        {
          text: "Shift Start Time",
          value: "shift_StartTime",
          align: "center",
          sortable: true,
        },
        {
          text: "Shift End Time",
          value: "shft_EndTime",
          align: "center",
          sortable: true,
        },
        {
          text: "Shift Start Time",
          value: "oT_Shift_StartTime",
          align: "center",
          sortable: true,
        },
        {
          text: "Shift End Time",
          value: "oT_Shift_EndTime",
          align: "center",
          sortable: true,
        },
        {
          text: "Clock In",
          value: "clock_In_Time",
          align: "center",
          sortable: true,
        },
        {
          text: "Clock Out",
          value: "clock_Out_Time",
          align: "center",
          sortable: true,
        },
        {
          text: "Clock In",
          value: "oT_Clock_In_Time",
          align: "center",
          sortable: true,
        },
        {
          text: "Clock Out",
          value: "oT_Clock_Out_Time",
          align: "center",
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      CalCinCoutList: [],
      error: {
        errormessagetimetype: "",
      },
      timeTypeItem: ["Regular", "Overtime"],
      type: "month",

      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
      todaymonths: null,
      todayyears: null,
      sat: [],
      sun: [],
      events: [],
      SortingDate: "",
      SortedDateList: [],
      MySortDateList: [],
      DataShowList: [],
      count: 0,
      countnext: 0,
      countcom: 0,
      countcomnext: 0,
      ShiftList: [],
      TimeGroup: null,
      effective_date: "",
    };
  },
  computed: {
    monthNames1() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
  },
  mounted() {
    let d = new Date();
    this.$refs.calendar.checkChange();
    this.todaymonths = this.monthNames1[d.getMonth()].val;
    this.todayyears = new Date();
    this.GetSortDate();
    this.GetShiftTime();
    this.GetTimeAttSetting();
    this.GetWorkdays();
    this.GetHolidays();
    this.GetTimeView();
    this.permission = this.CheckViewClockIn(37, 38);
  },
  created() {
    this.events.push({
      name: "Vacation",
      start: "2019-07-08",
      end: "2019-07-08",
    });
  },
  methods: {
    GetTimeView() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceView/GetTimeAttendanceViewByEmployeeID`,
          temp
        )
        .then(function (response) {
          self.TimeViewList = response.data.data;
          self.showsheetview = self.TimeViewList[0].calendar_View;
          if (self.showsheetview == false) {
            self.GetTimesheet();
          } else {
            self.GetTimeCalendar();
          }
        });
    },
    GetShiftTime() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
      };
      axios
        .post(`${self.url}ShiftAssignment/GetShiftAssignmentWithEmpId`, temp)
        .then(function (response) {
          self.ShiftList = response.data.data;
          self.TimeGroup = self.ShiftList[0].time_Group;
          self.effective_date = self.ShiftList[0].effective_Date;
        });
    },
    GetTimeAttSetting() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,
          temp
        )
        .then(function (response) {
          self.TimeSettingList = response.data.data;
          let [
            stbefore,
            stbeforetime,
          ] = self.TimeSettingList[0].start_Time_Before.split(" ");
          let [
            stafter,
            staftertime,
          ] = self.TimeSettingList[0].start_Time_After.split(" ");
          self.startbefore = stbefore;
          self.startbeforetime = stbeforetime;
          self.startafter = stafter;
          self.startaftertime = staftertime;

          let [
            edbefore,
            edbeforetime,
          ] = self.TimeSettingList[0].end_Time_Before.split(" ");
          let [
            edafter,
            edaftertime,
          ] = self.TimeSettingList[0].end_Time_After.split(" ");
          self.endbefore = edbefore;
          self.endbeforetime = edbeforetime;
          self.endafter = edafter;
          self.endaftertime = edaftertime;
        });
    },
    GetHolidays() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
        wC_Type: "N",
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetWorkDayWithHoliday`, temp)
        .then(function (response) {
          self.HolidayList = response.data.data;
        });
    },
    GetWorkdays() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
        wC_Type: "W",
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetWorkDayWithHoliday`, temp)
        .then(function (response) {
          self.WorkList = response.data.data;
        });
    },
    sortFun(rowName) {
      let self = this;

      let keyName = [
        "timeEntry_Date",
        "shift_StartTime",
        "shft_EndTime",
        "oT_Shift_StartTime",
        "oT_Shift_EndTime",
        "clock_In_Time",
        "clock_Out_Time",
        "oT_Clock_In_Time",
        "oT_Clock_Out_Time",
      ];
      if (
        rowName == "timeEntry_Date" ||
        rowName == "shift_StartTime" ||
        rowName == "shft_EndTime" ||
        rowName == "oT_Shift_StartTime" ||
        rowName == "oT_Shift_EndTime" ||
        rowName == "clock_In_Time" ||
        rowName == "clock_Out_Time" ||
        rowName == "oT_Clock_In_Time" ||
        rowName == "oT_Clock_Out_Time"
      ) {
        self.CinCoutLists = self.CinCoutLists.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.CinCoutLists = self.CinCoutLists.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.timeEntry_Date = null;
      this.shift_StartTime = null;
      this.shft_EndTime = null;
      this.oT_Shift_StartTime = null;
      this.oT_Shift_EndTime = null;
      this.clock_In_Time = null;
      this.clock_Out_Time = null;
      this.oT_Clock_In_Time = null;
      this.oT_Clock_Out_Time = null;
    },
    gotoview(item) {
      localStorage.setItem(
        "ot_shift_start",
        JSON.stringify(item.oT_Shift_StartTime)
      );
      localStorage.setItem(
        "ot_shift_end",
        JSON.stringify(item.oT_Shift_EndTime)
      );
      localStorage.setItem("to_shift_start", JSON.stringify(item.tO_StartTime));
      localStorage.setItem("to_shift_end", JSON.stringify(item.tO_EndTime));
      localStorage.setItem("dayoff_remark", JSON.stringify(item.remark));
      localStorage.setItem("dayoff_today", JSON.stringify(item.timeEntry_Date));
      localStorage.setItem(
        "timoffcnd",
        JSON.stringify(item.time_Off_Whole_Day)
      );
      this.$router.push({
        name: "timeentriessheetview",
        params: { id: item.time_ID },
      }).catch(()=>{});
    },
    gotoviewwithremark(item) {
      localStorage.setItem("dayoff_today", JSON.stringify(item.timeEntry_Date));
      localStorage.setItem("dayoff_remark", JSON.stringify(item.remark));
      localStorage.setItem(
        "lclstarttime",
        JSON.stringify(item.shift_StartTime)
      );
      localStorage.setItem("lclendtime", JSON.stringify(item.shft_EndTime));
      localStorage.setItem(
        "timoffcnd",
        JSON.stringify(item.time_Off_Whole_Day)
      );
      this.$router.push({
        name: "timeentriessheetview",
        params: { id: item.time_ID },
      }).catch(()=>{});
    },
    Converttolocal(item) {
      if (item != "-" && item != "" && item != null) {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    Converttolocal1(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let theresult = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        theresult =
          newDate.getFullYear() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getDate() +
          " " +
          output;
        return theresult;
      } else {
        return "-";
      }
    },
    OpenClockInDialig(item) {
      let self = this;
      self.ClockInDialig = true;
      const today = new Date();
      const gethrs = today.getHours();
      const getmin = today.getMinutes();
      let time = "";
      if (gethrs < 10) {
        if (getmin < 10) {
          time = "0" + today.getHours() + ":" + "0" + today.getMinutes();
        } else {
          time = "0" + today.getHours() + ":" + today.getMinutes();
        }
      } else {
        if (getmin < 10) {
          time = today.getHours() + ":" + "0" + today.getMinutes();
        } else {
          time = today.getHours() + ":" + today.getMinutes();
        }
      }
      const utctime =
        today.getUTCFullYear() +
        "/" +
        (today.getUTCMonth() + 1) +
        "/" +
        today.getUTCDate() +
        " " +
        today.getUTCHours() +
        ":" +
        today.getUTCMinutes();
      self.clock.clockintime = utctime;
      self.cintime = time;
      self.clock.timeID = item.time_ID;
      self.clock.shiftstart = item.shift_StartTime;
      self.clock.shiftend = item.shft_EndTime;
      self.clock.otshiftstart = item.oT_Shift_StartTime;
      self.clock.otshiftend = item.oT_Shift_EndTime;
    },
    OpenClockOutDialig(item) {
      let self = this;
      self.ClockOutDialig = true;
      const today = new Date();
      const getmin = today.getMinutes();
      const getutcmin = today.getUTCMinutes();
      let time = "";
      let utctime = "";
      if (getmin < 10) {
        time = today.getHours() + ":" + "0" + getmin;
      } else {
        time = today.getHours() + ":" + today.getMinutes();
      }
      if (getutcmin < 10) {
        utctime = today.getUTCHours() + ":" + "0" + getutcmin;
      } else {
        utctime = today.getUTCHours() + ":" + today.getUTCMinutes();
      }
      let d = "";
      if (item.time_Type == "Regular") {
        d = new Date(Date.parse(item.clock_In_Time));
      } else {
        d = new Date(Date.parse(item.oT_Clock_In_Time));
      }

      let timezone = d.getTimezoneOffset() * 60000;
      let newDate = new Date(d.getTime() - timezone);

      var timeDiff = Math.abs(today - newDate);
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      if (hh < 10) {
        hh = "0" + hh;
      }
      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      if (mm < 10) {
        mm = "0" + mm;
      }
      timeDiff -= mm * 1000 * 60;
      let tt = "";
      var ss = Math.floor(timeDiff / 1000);
      if (ss < 10) {
        ss = "0" + ss;
      }
      tt = hh + ":" + mm + " ";
      let inhours = newDate.getHours();
      let inmins = newDate.getMinutes();
      if (inhours < 10) {
        if (inmins < 10) {
          self.cintime =
            "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
        } else {
          self.cintime = "0" + newDate.getHours() + ":" + newDate.getMinutes();
        }
      } else {
        if (inmins < 10) {
          self.cintime = newDate.getHours() + ":" + "0" + newDate.getMinutes();
        } else {
          self.cintime = newDate.getHours() + ":" + newDate.getMinutes();
        }
      }
      self.coutime = time;
      self.totaltime = tt;
      self.clock.timeType = item.time_Type;
      if (self.clock.timeType == "Regular") {
        self.clock.clockintime = item.clock_In_Time;
      } else {
        self.clock.clockintime = item.oT_Clock_In_Time;
      }
      self.clock.clockouttime = utctime;
      self.clock.clockinlocate = item.clock_In_Location;
      self.clock.cinlang = item.clock_In_Longitude;
      self.clock.cinlat = item.clock_In_Lattitude;
      self.clock.timeID = item.time_ID;
      self.clock.clockID = item.clock_ID;
      self.clock.shiftstart = item.shift_StartTime;
      self.clock.shiftend = item.shft_EndTime;
      self.clock.otshiftstart = item.oT_Shift_StartTime;
      self.clock.otshiftend = item.oT_Shift_EndTime;
    },
    openmap() {
      let self = this;
      self.MapInDialog = true;
      self.$nextTick(() => {
        self.$refs.map.$gmapApiPromiseLazy().then(self.geolocate);
      });
    },
    mapp() {
      let self = this;
      self.MapInEditDialog = true;
      self.$nextTick(() => {
        self.$refs.map.$gmapApiPromiseLazy().then(self.openmapin);
      });
    },
    openmapin() {
      let self = this;
      self.showlocation = self.clock.clockinlocate;
      self.showlocationlat = self.clock.cinlat;
      self.showlocationlang = self.clock.cinlang;

      var controlDiv = document.createElement("div");
      var firstChild = document.createElement("button");
      firstChild.style.backgroundColor = "#fff";
      firstChild.style.border = "none";
      firstChild.style.outline = "none";
      firstChild.style.width = "28px";
      firstChild.style.height = "28px";
      firstChild.style.borderRadius = "2px";
      firstChild.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
      firstChild.style.cursor = "pointer";
      firstChild.style.marginRight = "10px";
      firstChild.style.padding = "0px";
      firstChild.title = "Your Location";
      controlDiv.appendChild(firstChild);

      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        "center_changed",
        function () {
          // secondChild.style["background-position"] = "0 0";
        }
      );
      var ref = this;

      navigator.geolocation.getCurrentPosition(() => {
        // let latlng = new window.google.maps.LatLng(
        //   parseFloat(position.coords.latitude),
        //   parseFloat(position.coords.longitude)
        // );
        let latlngtemp = new window.google.maps.LatLng(
          parseFloat(ref.showlocationlat),
          parseFloat(ref.showlocationlang)
        );

        ref.center = {
          lat: parseFloat(ref.showlocationlat),
          lng: parseFloat(ref.showlocationlang),
        };

        ref.createMarker(latlngtemp);
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].pop();
    },
    openmapout() {
      let self = this;
      self.MapOutDialog = true;
      self.$nextTick(() => {
        self.$refs.map.$gmapApiPromiseLazy().then(self.geolocate);
      });
    },
    closemap() {
      let self = this;
      if (self.clock.clockinlocate != "" && self.clock.clockinlocate != null) {
        self.MapInDialog = false;
      } else {
        self.location = "";
        self.locationlat = "";
        self.locationlang = "";
        self.outlocation = "";
        self.outlocationlat = "";
        self.outlocationlang = "";
        self.center = { lat: 45.508, lng: -73.587 };
        self.MapInDialog = false;
      }
      self.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].pop();
    },
    closemapin() {
      let self = this;
      self.center = { lat: 45.508, lng: -73.587 };
      self.outlocation = "";
      self.MapInEditDialog = false;
    },
    closemapout() {
      let self = this;
      if (
        self.clock.clockoutlocate != "" &&
        self.clock.clockoutlocate != null
      ) {
        self.MapOutDialog = false;
      } else {
        self.location = "";
        self.locationlat = "";
        self.locationlang = "";
        self.outlocation = "";
        self.outlocationlat = "";
        self.outlocationlang = "";
        self.center = { lat: 45.508, lng: -73.587 };
        self.MapOutDialog = false;
      }
      self.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].pop();
    },
    confirmcinlocate() {
      let self = this;
      if (self.location != "" && self.location != null) {
        self.clock.clockinlocate = self.location;
        self.clock.cinlang = self.locationlang;
        self.clock.cinlat = self.locationlat;
        self.MapInDialog = false;
        self.$refs.map.$mapObject.controls[
          window.google.maps.ControlPosition.RIGHT_BOTTOM
        ].pop();
      } else {
        alert("please select your location.");
      }
    },
    confirmcoutlocate() {
      let self = this;
      if (self.location != "" && self.location != null) {
        self.clock.clockoutlocate = self.outlocation;
        self.clock.coutlang = self.outlocationlang;
        self.clock.coutlat = self.outlocationlat;
        self.MapOutDialog = false;
        self.$refs.map.$mapObject.controls[
          window.google.maps.ControlPosition.RIGHT_BOTTOM
        ].pop();
      } else {
        alert("please select your location.");
      }
    },
    GetSortDate() {
      let self = this;

      var today = new Date();
      var realmonth = today.getMonth() + 1;

      self.SortingDate =
        self.monthNames[today.getMonth(realmonth)] + " " + today.getFullYear();
    },
    prev() {
      let self = this;
      let num = self.getMonthDays(self.SortingDate);
      let connum = num - 2;
      let yer = parseInt(self.getYearDays(self.SortingDate));
      if (connum < 0) {
        connum = 11;
        yer = yer - 1;
        self.SortingDate = self.monthNames[connum] + " " + yer;
      } else {
        self.SortingDate = self.monthNames[connum] + " " + yer;
      }
      self.CinCoutLists = [];

      self.GetTimesheet();
    },
    next() {
      let self = this;
      let num = self.getMonthDays(self.SortingDate);
      let yer = parseInt(self.getYearDays(self.SortingDate));
      if (num > 11) {
        num = 0;
        yer = yer + 1;
        self.SortingDate = self.monthNames[num] + " " + yer;
      } else {
        self.SortingDate = self.monthNames[num] + " " + yer;
      }
      self.CinCoutLists = [];

      self.GetTimesheet();
    },
    getMonthDays(MonthYear) {
      var Value = MonthYear.split(" ");
      var month = this.monthNames.indexOf(Value[0]) + 1;
      return month;
    },
    getYearDays(MonthYear) {
      var Value = MonthYear.split(" ");
      var month = Value[1];
      return month;
    },

    GetTimesheet() {
      let self = this;
      self.LoadingDialog = true;
      let num = self.getMonthDays(self.SortingDate);
      let yer = parseInt(self.getYearDays(self.SortingDate));
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
        month_Num: num,
        year_Num: yer,
      };

      axios
        .post(`${self.url}TimeEntries/GetTimeEntriesByEmployeeIDAndTime`, temp)
        .then(function (response) {
          self.CinCoutList = response.data.data;
          for (let i = 0; i < self.CinCoutList.length; i++) {
            let intime = "-";
            let outtime = "-";
            let otintime = "-";
            let otouttime = "-";
            let cinoutcount = 0;
            let otcinoutcount = 0;
            let regin = "-";
            let latein = "-";
            let regout = "-";
            let lateout = "-";
            let otin = "-";
            let lateotin = "-";
            let otout = "-";
            let lateotout = "-";
            let cinlocate = "-";
            let cinlat = "-";
            let cinlang = "-";
            let coutlocate = "-";
            let coutlat = "-";
            let coutlang = "-";
            let outlist = [];
            let otoutlist = [];
            let showouttime = "-";
            let showotouttime = "-";
            if (self.CinCoutList[i].irregularRegCinoutData.length != 0) {
              let leg = self.CinCoutList[i].irregularRegCinoutData.length - 1;
              latein =
                self.CinCoutList[i].irregularRegCinoutData[0].clock_In_Time;
              lateout =
                self.CinCoutList[i].irregularRegCinoutData[leg].clock_Out_Time;
              for (
                let c = 0;
                c < self.CinCoutList[i].irregularRegCinoutData.length;
                c++
              ) {
                outlist.unshift({
                  time:
                    self.CinCoutList[i].irregularRegCinoutData[c]
                      .clock_Out_Time,
                  id: self.CinCoutList[i].irregularRegCinoutData[c].clock_ID,
                });
              }
            }

            if (self.CinCoutList[i].regCinoutData.length != 0) {
              let leg = self.CinCoutList[i].regCinoutData.length - 1;
              regin = self.CinCoutList[i].regCinoutData[0].clock_In_Time;
              regout = self.CinCoutList[i].regCinoutData[leg].clock_Out_Time;
              cinoutcount = self.CinCoutList[i].regCinoutData.length;
              for (
                let a = 0;
                a < self.CinCoutList[i].regCinoutData.length;
                a++
              ) {
                outlist.unshift({
                  time: self.CinCoutList[i].regCinoutData[a].clock_Out_Time,
                  id: self.CinCoutList[i].regCinoutData[a].clock_ID,
                });
              }
            }

            if (self.CinCoutList[i].irregularOTCinoutData.length != 0) {
              let leg = self.CinCoutList[i].irregularOTCinoutData.length - 1;
              lateotin =
                self.CinCoutList[i].irregularOTCinoutData[0].oT_Clock_In_Time;
              lateotout =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .oT_Clock_Out_Time;
              for (
                let d = 0;
                d < self.CinCoutList[i].irregularOTCinoutData.length;
                d++
              ) {
                otoutlist.unshift({
                  time:
                    self.CinCoutList[i].irregularOTCinoutData[d]
                      .oT_Clock_Out_Time,
                });
              }
            }

            if (self.CinCoutList[i].otCinoutData.length != 0) {
              let leg = self.CinCoutList[i].otCinoutData.length - 1;
              otin = self.CinCoutList[i].otCinoutData[0].oT_Clock_In_Time;
              otout = self.CinCoutList[i].otCinoutData[leg].oT_Clock_Out_Time;
              otcinoutcount = self.CinCoutList[i].otCinoutData.length;
              for (
                let b = 0;
                b < self.CinCoutList[i].otCinoutData.length;
                b++
              ) {
                otoutlist.unshift({
                  time: self.CinCoutList[i].otCinoutData[b].oT_Clock_Out_Time,
                });
              }
            }

            if (regin != "-" && regin != null) {
              intime = regin;
              cinlocate =
                self.CinCoutList[i].regCinoutData[0].clock_In_Location;
              cinlat = self.CinCoutList[i].regCinoutData[0].clock_In_Lattitude;
              cinlang = self.CinCoutList[i].regCinoutData[0].clock_In_Longitude;
            } else if (latein != "-" && latein != null) {
              intime = latein;
              cinlocate =
                self.CinCoutList[i].irregularRegCinoutData[0].clock_In_Location;
              cinlat =
                self.CinCoutList[i].irregularRegCinoutData[0]
                  .clock_In_Lattitude;
              cinlang =
                self.CinCoutList[i].irregularRegCinoutData[0]
                  .clock_In_Longitude;
            }

            if (regout != "-" && regout != null) {
              outtime = regout;
              let leg = cinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].regCinoutData[leg].clock_Out_Location;
              coutlat =
                self.CinCoutList[i].regCinoutData[leg].clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].regCinoutData[leg].clock_Out_Longitude;
            } else if (lateout != "-" && lateout != null) {
              outtime = lateout;
              let leg = cinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].irregularRegCinoutData[leg]
                  .clock_Out_Location;
              coutlat =
                self.CinCoutList[i].irregularRegCinoutData[leg]
                  .clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].irregularRegCinoutData[leg]
                  .clock_Out_Longitude;
            }

            if (otin != "-" && otin != null) {
              otintime = otin;
              cinlocate = self.CinCoutList[i].otCinoutData[0].clock_In_Location;
              cinlat = self.CinCoutList[i].otCinoutData[0].clock_In_Lattitude;
              cinlang = self.CinCoutList[i].otCinoutData[0].clock_In_Longitude;
            } else if (lateotin != "-" && lateotin != null) {
              otintime = lateotin;
              cinlocate =
                self.CinCoutList[i].irregularOTCinoutData[0].clock_In_Location;
              cinlat =
                self.CinCoutList[i].irregularOTCinoutData[0].clock_In_Lattitude;
              cinlang =
                self.CinCoutList[i].irregularOTCinoutData[0].clock_In_Longitude;
            }

            if (otout != "-" && otout != null) {
              otouttime = otout;
              let leg = otcinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].otCinoutData[leg].clock_Out_Location;
              coutlat =
                self.CinCoutList[i].otCinoutData[leg].clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].otCinoutData[leg].clock_Out_Longitude;
            } else if (lateotout != "-" && lateotout != null) {
              otouttime = lateotout;
              let leg = otcinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .clock_Out_Location;
              coutlat =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .clock_Out_Longitude;
            }

            for (let e = 0; e < outlist.length; e++) {
              if (outlist[e].time != "-") {
                showouttime = outlist[e].time;
                break;
              }
            }
            for (let f = 0; f < otoutlist.length; f++) {
              if (otoutlist[f].time != "-") {
                showotouttime = otoutlist[f].time;
                break;
              }
            }

            if (
              self.CinCoutList[i].oT_Shift_StartTime == null ||
              self.CinCoutList[i].oT_Shift_StartTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_StartTime = "-";
            }
            if (
              self.CinCoutList[i].oT_Shift_EndTime == null ||
              self.CinCoutList[i].oT_Shift_EndTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_EndTime = "-";
            }
            if (
              self.CinCoutList[i].tO_StartTime == null ||
              self.CinCoutList[i].tO_StartTime == ""
            ) {
              self.CinCoutList[i].tO_StartTime = "-";
            }
            if (
              self.CinCoutList[i].tO_EndTime == null ||
              self.CinCoutList[i].tO_EndTime == ""
            ) {
              self.CinCoutList[i].tO_EndTime = "-";
            }
            let Lists = {
              ...self.CinCoutList[i],
              justforshowOut: showouttime,
              justforshowOTOut: showotouttime,
              clock_In_Time: intime,
              clock_Out_Time: outtime,
              oT_Clock_In_Time: otintime,
              oT_Clock_Out_Time: otouttime,
              clock_In_Location: cinlocate,
              clock_In_Lattitude: cinlat,
              clock_In_Longitude: cinlang,
              clock_Out_Location: coutlocate,
              clock_Out_Lattitude: coutlat,
              clock_Out_Longitude: coutlang,
              regcount: cinoutcount,
              otcount: otcinoutcount,
              showholiday: false,
              showview: false,
              showclockin: false,
              showclockinwithview: false,
              showdayoff: false,
              showclockout: false,
              showclockoutwithview: false,
              showfalseview: false,
              makereadonly: false,
              disall: false,
            };
            self.CinCoutLists.push(Lists);
            self.events.push({
              name: "Vacation",
              start: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              end: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              regCinoutData: self.CinCoutList[i].regCinoutData,
              otCinoutData: self.CinCoutList[i].otCinoutData,
              clock_In_Time: intime,
              clock_Out_Time: outtime,
              oT_Clock_In_Time: otintime,
              oT_Clock_Out_Time: otouttime,
              regcount: cinoutcount,
              otcount: otcinoutcount,
              justforshowOut: showouttime,
              justforshowOTOut: showotouttime,
              shift_StartTime: self.CinCoutList[i].shift_StartTime,
              shft_EndTime: self.CinCoutList[i].shft_EndTime,
              oT_Shift_StartTime: self.CinCoutList[i].oT_Shift_StartTime,
              oT_Shift_EndTime: self.CinCoutList[i].oT_Shift_EndTime,
              tO_StartTime: self.CinCoutList[i].tO_StartTime,
              tO_EndTime: self.CinCoutList[i].tO_EndTime,
              time_Off_Whole_Day: self.CinCoutList[i].time_Off_Whole_Day,
              timeEntry_Date: self.CinCoutList[i].timeEntry_Date,
              time_ID: self.CinCoutList[i].time_ID,
              clock_ID: self.CinCoutList[i].clock_ID,
              time_Type: self.CinCoutList[i].time_Type,
              clock_In_Location: cinlocate,
              clock_In_Lattitude: cinlat,
              clock_In_Longitude: cinlang,
              clock_Out_Location: coutlocate,
              clock_Out_Lattitude: coutlat,
              clock_Out_Longitude: coutlang,
              company_ID: self.CinCoutList[i].company_ID,
              showholiday: false,
              showview: false,
              showdayoff: false,
              showclockin: false,
              showclockinwithview: false,
              showclockout: false,
              showclockoutwithview: false,
              showfalseview: false,
              showoffview: false,
              makereadonly: false,
              remark: self.CinCoutList[i].remark,
              disall: false,
            });
          }
          for (let j = 0; j < self.CinCoutLists.length; j++) {
            const today = new Date();
            const date1 = new Date(self.CinCoutLists[j].timeEntry_Date);

            if (
              self.CinCoutLists[j].tO_StartTime == "-" &&
              self.CinCoutLists[j].tO_EndTime == "-" &&
              self.CinCoutLists[j].time_Off_Whole_Day == true
            ) {
              const date = today.getTime() - 100000000;
              const date2 = today.getTime();
              const date3 = new Date(self.effective_date);
              let date4 = date3.getTime();

              let dated = date1.getTime();
              if (date > dated) {
                if (date4 < dated) {
                  self.CinCoutLists[j].showholiday = false;
                  self.CinCoutLists[j].showview = false;
                  self.CinCoutLists[j].showdayoff = true;
                  self.CinCoutLists[j].showclockin = false;
                  self.CinCoutLists[j].showclockinwithview = false;
                  self.CinCoutLists[j].showclockout = false;
                  self.CinCoutLists[j].showclockoutwithview = false;
                  self.CinCoutLists[j].showfalseview = false;
                  self.CinCoutLists[j].makereadonly = false;
                } else {
                  self.CinCoutLists[j].showholiday = false;
                  self.CinCoutLists[j].showview = false;
                  self.CinCoutLists[j].showdayoff = false;
                  self.CinCoutLists[j].showclockin = false;
                  self.CinCoutLists[j].showclockinwithview = false;
                  self.CinCoutLists[j].showclockout = false;
                  self.CinCoutLists[j].showclockoutwithview = false;
                  self.CinCoutLists[j].showfalseview = true;
                  self.CinCoutLists[j].makereadonly = false;
                }
              } else if (date2 > dated) {
                self.CinCoutLists[j].showholiday = false;
                self.CinCoutLists[j].showview = false;
                self.CinCoutLists[j].showdayoff = true;
                self.CinCoutLists[j].showclockin = false;
                self.CinCoutLists[j].showclockinwithview = false;
                self.CinCoutLists[j].showclockout = false;
                self.CinCoutLists[j].showclockoutwithview = false;
                self.CinCoutLists[j].showfalseview = false;
                self.CinCoutLists[j].makereadonly = false;
              } else {
                self.CinCoutLists[j].showholiday = false;
                self.CinCoutLists[j].showview = false;
                self.CinCoutLists[j].showdayoff = true;
                self.CinCoutLists[j].showclockin = false;
                self.CinCoutLists[j].showclockinwithview = false;
                self.CinCoutLists[j].showclockout = false;
                self.CinCoutLists[j].showclockoutwithview = false;
                self.CinCoutLists[j].showfalseview = false;
                self.CinCoutLists[j].makereadonly = false;
              }
            } else if (
              self.CinCoutLists[j].tO_StartTime != "-" &&
              self.CinCoutLists[j].tO_EndTime != "-" &&
              self.CinCoutLists[j].time_Off_Whole_Day == false
            ) {
              if (
                self.CinCoutLists[j].clock_In_Time == "-" &&
                self.CinCoutLists[j].clock_Out_Time == "-"
              ) {
                const date = today.getTime() - 100000000;
                const date2 = today.getTime();
                let dated = date1.getTime();

                if (self.CinCoutLists[j].oT_Shift_StartTime != "-") {
                  if (
                    self.CinCoutLists[j].oT_Clock_In_Time == "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = true;
                      self.CinCoutLists[j].makereadonly = false;
                    } else if (date2 > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = true;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = true;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.CinCoutLists[j].regcount > 1 ||
                      self.CinCoutLists[j].otcount > 1
                    ) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = true;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = true;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = true;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockinwithview = true;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].showfalseview = true;
                    self.CinCoutLists[j].makereadonly = false;
                  } else if (date2 > dated) {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = true;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].makereadonly = false;
                  } else {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].makereadonly = true;
                  }
                }
              } else if (
                self.CinCoutLists[j].clock_In_Time != "-" &&
                self.CinCoutLists[j].clock_Out_Time == "-"
              ) {
                if (
                  self.CinCoutLists[j].regcount > 1 ||
                  self.CinCoutLists[j].otcount > 1
                ) {
                  self.CinCoutLists[j].showholiday = false;
                  self.CinCoutLists[j].showview = false;
                  self.CinCoutLists[j].showdayoff = false;
                  self.CinCoutLists[j].showclockin = false;
                  self.CinCoutLists[j].showclockinwithview = false;
                  self.CinCoutLists[j].showclockout = false;
                  self.CinCoutLists[j].showclockoutwithview = true;
                  self.CinCoutLists[j].showfalseview = false;
                  self.CinCoutLists[j].makereadonly = false;
                } else {
                  self.CinCoutLists[j].showholiday = false;
                  self.CinCoutLists[j].showview = false;
                  self.CinCoutLists[j].showdayoff = false;
                  self.CinCoutLists[j].showclockin = false;
                  self.CinCoutLists[j].showclockinwithview = false;
                  self.CinCoutLists[j].showclockout = true;
                  self.CinCoutLists[j].showclockoutwithview = false;
                  self.CinCoutLists[j].showfalseview = false;
                  self.CinCoutLists[j].makereadonly = false;
                }
              } else if (
                self.CinCoutLists[j].clock_In_Time != "-" &&
                self.CinCoutLists[j].clock_Out_Time != "-"
              ) {
                const date = today.getTime() - 200000000;
                let dated = date1.getTime();
                const date2 = today.getTime();
                if (self.CinCoutLists[j].oT_Shift_StartTime != "-") {
                  if (
                    self.CinCoutLists[j].oT_Clock_In_Time == "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = true;
                      self.CinCoutLists[j].makereadonly = false;
                    } else if (date2 > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = true;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = true;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.CinCoutLists[j].regcount > 1 ||
                      self.CinCoutLists[j].otcount > 1
                    ) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = true;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = true;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = true;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockinwithview = true;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = true;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].makereadonly = false;
                  } else {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockinwithview = true;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].makereadonly = false;
                  }
                }
              }
            } else if (
              self.CinCoutLists[j].shift_StartTime == "-" &&
              self.CinCoutLists[j].shft_EndTime == "-" &&
              self.CinCoutLists[j].oT_Shift_StartTime == "-" &&
              self.CinCoutLists[j].oT_Shift_EndTime == "-"
            ) {
              self.CinCoutLists[j].showholiday = false;
              self.CinCoutLists[j].showview = false;
              self.CinCoutLists[j].showdayoff = false;
              self.CinCoutLists[j].showclockin = false;
              self.CinCoutLists[j].showclockinwithview = false;
              self.CinCoutLists[j].showclockout = false;
              self.CinCoutLists[j].showclockoutwithview = false;
              self.CinCoutLists[j].showfalseview = true;
              self.CinCoutLists[j].makereadonly = false;
            } else {
              if (
                self.CinCoutLists[j].clock_In_Time == "-" &&
                self.CinCoutLists[j].clock_Out_Time == "-"
              ) {
                const date = today.getTime() - 100000000;
                const date2 = today.getTime();

                let dated = date1.getTime();
                if (self.CinCoutLists[j].oT_Shift_StartTime != "-") {
                  if (
                    self.CinCoutLists[j].oT_Clock_In_Time == "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = true;
                      self.CinCoutLists[j].makereadonly = false;
                    } else if (date2 > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = true;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = true;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.CinCoutLists[j].regcount > 1 ||
                      self.CinCoutLists[j].otcount > 1
                    ) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = true;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = true;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = true;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockinwithview = true;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].showfalseview = true;
                    self.CinCoutLists[j].makereadonly = false;
                  } else if (date2 > dated) {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = true;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].makereadonly = false;
                  } else {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].makereadonly = true;
                  }
                }
              } else if (
                self.CinCoutLists[j].clock_In_Time != "-" &&
                self.CinCoutLists[j].clock_Out_Time == "-"
              ) {
                if (
                  self.CinCoutLists[j].regcount > 1 ||
                  self.CinCoutLists[j].otcount > 1
                ) {
                  self.CinCoutLists[j].showholiday = false;
                  self.CinCoutLists[j].showview = false;
                  self.CinCoutLists[j].showdayoff = false;
                  self.CinCoutLists[j].showclockin = false;
                  self.CinCoutLists[j].showclockinwithview = false;
                  self.CinCoutLists[j].showclockout = false;
                  self.CinCoutLists[j].showclockoutwithview = true;
                  self.CinCoutLists[j].showfalseview = false;
                  self.CinCoutLists[j].makereadonly = false;
                } else {
                  self.CinCoutLists[j].showholiday = false;
                  self.CinCoutLists[j].showview = false;
                  self.CinCoutLists[j].showdayoff = false;
                  self.CinCoutLists[j].showclockin = false;
                  self.CinCoutLists[j].showclockinwithview = false;
                  self.CinCoutLists[j].showclockout = true;
                  self.CinCoutLists[j].showclockoutwithview = false;
                  self.CinCoutLists[j].showfalseview = false;
                  self.CinCoutLists[j].makereadonly = false;
                }
              } else if (
                self.CinCoutLists[j].clock_In_Time != "-" &&
                self.CinCoutLists[j].clock_Out_Time != "-"
              ) {
                const date = today.getTime() - 100000000;
                let dated = date1.getTime();
                const date2 = today.getTime();
                if (self.CinCoutLists[j].oT_Shift_StartTime != "-") {
                  if (
                    self.CinCoutLists[j].oT_Clock_In_Time == "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = true;
                      self.CinCoutLists[j].makereadonly = false;
                    } else if (date2 > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = true;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = true;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.CinCoutLists[j].regcount > 1 ||
                      self.CinCoutLists[j].otcount > 1
                    ) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showclockoutwithview = true;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = true;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  } else if (
                    self.CinCoutLists[j].oT_Clock_In_Time != "-" &&
                    self.CinCoutLists[j].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = true;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showclockinwithview = false;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    } else {
                      self.CinCoutLists[j].showholiday = false;
                      self.CinCoutLists[j].showview = false;
                      self.CinCoutLists[j].showclockin = false;
                      self.CinCoutLists[j].showdayoff = false;
                      self.CinCoutLists[j].showclockinwithview = true;
                      self.CinCoutLists[j].showclockout = false;
                      self.CinCoutLists[j].showfalseview = false;
                      self.CinCoutLists[j].showclockoutwithview = false;
                      self.CinCoutLists[j].makereadonly = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = true;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showclockinwithview = false;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].makereadonly = false;
                  } else {
                    self.CinCoutLists[j].showholiday = false;
                    self.CinCoutLists[j].showview = false;
                    self.CinCoutLists[j].showclockin = false;
                    self.CinCoutLists[j].showdayoff = false;
                    self.CinCoutLists[j].showclockinwithview = true;
                    self.CinCoutLists[j].showclockout = false;
                    self.CinCoutLists[j].showfalseview = false;
                    self.CinCoutLists[j].showclockoutwithview = false;
                    self.CinCoutLists[j].makereadonly = false;
                  }
                }
              }
            }
            if (
              date1.getDay() == 0 &&
              self.CinCoutLists[j].shift_StartTime == "-" &&
              self.CinCoutLists[j].shft_EndTime == "-" &&
              self.CinCoutLists[j].oT_Shift_StartTime == "-" &&
              self.CinCoutLists[j].oT_Shift_EndTime == "-"
            ) {
              self.CinCoutLists[j].showholiday = true;
              self.CinCoutLists[j].showview = false;
              self.CinCoutLists[j].showdayoff = false;
              self.CinCoutLists[j].showclockin = false;
              self.CinCoutLists[j].showclockinwithview = false;
              self.CinCoutLists[j].showclockout = false;
              self.CinCoutLists[j].showclockoutwithview = false;
              self.CinCoutLists[j].showfalseview = false;
              self.CinCoutLists[j].makereadonly = false;
            } else if (
              date1.getDay() == 6 &&
              self.CinCoutLists[j].shift_StartTime == "-" &&
              self.CinCoutLists[j].shft_EndTime == "-" &&
              self.CinCoutLists[j].oT_Shift_StartTime == "-" &&
              self.CinCoutLists[j].oT_Shift_EndTime == "-"
            ) {
              self.CinCoutLists[j].showholiday = true;
              self.CinCoutLists[j].showview = false;
              self.CinCoutLists[j].showdayoff = false;
              self.CinCoutLists[j].showclockin = false;
              self.CinCoutLists[j].showclockinwithview = false;
              self.CinCoutLists[j].showclockout = false;
              self.CinCoutLists[j].showclockoutwithview = false;
              self.CinCoutLists[j].showfalseview = false;
              self.CinCoutLists[j].makereadonly = false;
            }
          }
          let nnum = localStorage.getItem("pagenum");
          if (nnum != null) {
            self.page = parseInt(nnum);
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get TimeEntries Fail", 22, "Critical");
          //alert(error + "Correlation ID is 22");
          self.LoadingDialog = false;
        });
    },
    GetTimeCalendar() {
      let self = this;
      self.LoadingDialog = true;
      let num = self.todaymonths;
      let yer = self.todayyears.getFullYear();
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
        month_Num: num,
        year_Num: yer,
      };
      axios
        .post(`${self.url}TimeEntries/GetTimeEntriesByEmployeeIDAndTime`, temp)
        .then(function (response) {
          self.CinCoutList = response.data.data;
          for (let i = 0; i < self.CinCoutList.length; i++) {
            let intime = "-";
            let outtime = "-";
            let otintime = "-";
            let otouttime = "-";
            let cinoutcount = 0;
            let otcinoutcount = 0;
            let regin = "-";
            let latein = "-";
            let regout = "-";
            let lateout = "-";
            let otin = "-";
            let lateotin = "-";
            let otout = "-";
            let lateotout = "-";
            let cinlocate = "-";
            let cinlat = "-";
            let cinlang = "-";
            let coutlocate = "-";
            let coutlat = "-";
            let coutlang = "-";
            let outlist = [];
            let otoutlist = [];
            let showouttime = "-";
            let showotouttime = "-";

            if (self.CinCoutList[i].regCinoutData.length != 0) {
              let leg = self.CinCoutList[i].regCinoutData.length - 1;
              regin = self.CinCoutList[i].regCinoutData[0].clock_In_Time;
              regout = self.CinCoutList[i].regCinoutData[leg].clock_Out_Time;
              cinoutcount = self.CinCoutList[i].regCinoutData.length;
              for (
                let a = 0;
                a < self.CinCoutList[i].regCinoutData.length;
                a++
              ) {
                outlist.unshift({
                  time: self.CinCoutList[i].regCinoutData[a].clock_Out_Time,
                });
              }
            }
            if (self.CinCoutList[i].otCinoutData.length != 0) {
              let leg = self.CinCoutList[i].otCinoutData.length - 1;
              otin = self.CinCoutList[i].otCinoutData[0].oT_Clock_In_Time;
              otout = self.CinCoutList[i].otCinoutData[leg].oT_Clock_Out_Time;
              otcinoutcount = self.CinCoutList[i].otCinoutData.length;
              for (
                let b = 0;
                b < self.CinCoutList[i].otCinoutData.length;
                b++
              ) {
                otoutlist.unshift({
                  time: self.CinCoutList[i].otCinoutData[b].oT_Clock_Out_Time,
                });
              }
            }
            if (self.CinCoutList[i].irregularRegCinoutData.length != 0) {
              let leg = self.CinCoutList[i].irregularRegCinoutData.length - 1;
              latein =
                self.CinCoutList[i].irregularRegCinoutData[0].clock_In_Time;
              lateout =
                self.CinCoutList[i].irregularRegCinoutData[leg].clock_Out_Time;
              for (
                let c = 0;
                c < self.CinCoutList[i].irregularRegCinoutData.length;
                c++
              ) {
                outlist.unshift({
                  time:
                    self.CinCoutList[i].irregularRegCinoutData[c]
                      .clock_Out_Time,
                });
              }
            }
            if (self.CinCoutList[i].irregularOTCinoutData.length != 0) {
              let leg = self.CinCoutList[i].irregularOTCinoutData.length - 1;
              lateotin =
                self.CinCoutList[i].irregularOTCinoutData[0].oT_Clock_In_Time;
              lateotout =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .oT_Clock_Out_Time;
              for (
                let d = 0;
                d < self.CinCoutList[i].irregularOTCinoutData.length;
                d++
              ) {
                otoutlist.unshift({
                  time:
                    self.CinCoutList[i].irregularOTCinoutData[d]
                      .oT_Clock_Out_Time,
                });
              }
            }

            if (regin != "-" && regin != null) {
              intime = regin;
              cinlocate =
                self.CinCoutList[i].regCinoutData[0].clock_In_Location;
              cinlat = self.CinCoutList[i].regCinoutData[0].clock_In_Lattitude;
              cinlang = self.CinCoutList[i].regCinoutData[0].clock_In_Longitude;
            } else if (latein != "-" && latein != null) {
              intime = latein;
              cinlocate =
                self.CinCoutList[i].irregularRegCinoutData[0].clock_In_Location;
              cinlat =
                self.CinCoutList[i].irregularRegCinoutData[0]
                  .clock_In_Lattitude;
              cinlang =
                self.CinCoutList[i].irregularRegCinoutData[0]
                  .clock_In_Longitude;
            }

            if (regout != "-" && regout != null) {
              outtime = regout;
              let leg = cinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].regCinoutData[leg].clock_Out_Location;
              coutlat =
                self.CinCoutList[i].regCinoutData[leg].clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].regCinoutData[leg].clock_Out_Longitude;
            } else if (lateout != "-" && lateout != null) {
              outtime = lateout;
              let leg = cinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].irregularRegCinoutData[leg]
                  .clock_Out_Location;
              coutlat =
                self.CinCoutList[i].irregularRegCinoutData[leg]
                  .clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].irregularRegCinoutData[leg]
                  .clock_Out_Longitude;
            }

            if (otin != "-" && otin != null) {
              otintime = otin;
              cinlocate = self.CinCoutList[i].otCinoutData[0].clock_In_Location;
              cinlat = self.CinCoutList[i].otCinoutData[0].clock_In_Lattitude;
              cinlang = self.CinCoutList[i].otCinoutData[0].clock_In_Longitude;
            } else if (lateotin != "-" && lateotin != null) {
              otintime = lateotin;
              cinlocate =
                self.CinCoutList[i].irregularOTCinoutData[0].clock_In_Location;
              cinlat =
                self.CinCoutList[i].irregularOTCinoutData[0].clock_In_Lattitude;
              cinlang =
                self.CinCoutList[i].irregularOTCinoutData[0].clock_In_Longitude;
            }

            if (otout != "-" && otout != null) {
              otouttime = otout;
              let leg = otcinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].otCinoutData[leg].clock_Out_Location;
              coutlat =
                self.CinCoutList[i].otCinoutData[leg].clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].otCinoutData[leg].clock_Out_Longitude;
            } else if (lateotout != "-" && lateotout != null) {
              otouttime = lateotout;
              let leg = otcinoutcount - 1;
              coutlocate =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .clock_Out_Location;
              coutlat =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .clock_Out_Lattitude;
              coutlang =
                self.CinCoutList[i].irregularOTCinoutData[leg]
                  .clock_Out_Longitude;
            }

            for (let e = 0; e < outlist.length; e++) {
              if (outlist[e].time != "-") {
                showouttime = outlist[e].time;
                break;
              }
            }
            for (let f = 0; f < otoutlist.length; f++) {
              if (otoutlist[f].time != "-") {
                showotouttime = otoutlist[f].time;
                break;
              }
            }

            if (
              self.CinCoutList[i].oT_Shift_StartTime == null ||
              self.CinCoutList[i].oT_Shift_StartTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_StartTime = "-";
            }
            if (
              self.CinCoutList[i].oT_Shift_EndTime == null ||
              self.CinCoutList[i].oT_Shift_EndTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_EndTime = "-";
            }

            self.events.push({
              name: "Vacation",
              start: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              end: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              regCinoutData: self.CinCoutList[i].regCinoutData,
              otCinoutData: self.CinCoutList[i].otCinoutData,
              clock_In_Time: intime,
              clock_Out_Time: outtime,
              oT_Clock_In_Time: otintime,
              oT_Clock_Out_Time: otouttime,
              regcount: cinoutcount,
              otcount: otcinoutcount,
              justforshowOut: showouttime,
              justforshowOTOut: showotouttime,
              shift_StartTime: self.CinCoutList[i].shift_StartTime,
              shft_EndTime: self.CinCoutList[i].shft_EndTime,
              oT_Shift_StartTime: self.CinCoutList[i].oT_Shift_StartTime,
              oT_Shift_EndTime: self.CinCoutList[i].oT_Shift_EndTime,
              tO_StartTime: self.CinCoutList[i].tO_StartTime,
              tO_EndTime: self.CinCoutList[i].tO_EndTime,
              time_Off_Whole_Day: self.CinCoutList[i].time_Off_Whole_Day,
              timeEntry_Date: self.CinCoutList[i].timeEntry_Date,
              time_ID: self.CinCoutList[i].time_ID,
              clock_ID: self.CinCoutList[i].clock_ID,
              time_Type: self.CinCoutList[i].time_Type,
              clock_In_Location: cinlocate,
              clock_In_Lattitude: cinlat,
              clock_In_Longitude: cinlang,
              clock_Out_Location: coutlocate,
              clock_Out_Lattitude: coutlat,
              clock_Out_Longitude: coutlang,
              company_ID: self.CinCoutList[i].company_ID,
              showholiday: false,
              showview: false,
              showdayoff: false,
              showclockin: false,
              showclockinwithview: false,
              showclockout: false,
              showclockoutwithview: false,
              showfalseview: false,
              showoffview: false,
              makereadonly: false,
              remark: self.CinCoutList[i].remark,
              disall: false,
            });
          }
          for (let k = 0; k < self.events.length; k++) {
            const today = new Date();
            const date1 = new Date(self.events[k].timeEntry_Date);

            if (
              self.events[k].tO_StartTime == "-" &&
              self.events[k].tO_EndTime == "-" &&
              self.events[k].time_Off_Whole_Day == true
            ) {
              const date = today.getTime() - 100000000;
              const date2 = today.getTime();
              const date3 = new Date(self.effective_date);
              let date4 = date3.getTime();

              let dated = date1.getTime();
              if (date > dated) {
                if (date4 < dated) {
                  self.events[k].showholiday = false;
                  self.events[k].showview = false;
                  self.events[k].showdayoff = true;
                  self.events[k].showclockin = false;
                  self.events[k].showclockout = false;
                  self.events[k].showfalseview = false;
                  self.events[k].makereadonly = false;
                  self.events[k].showoffview = false;
                  self.events[k].showclockinwithview = false;
                  self.events[k].showclockoutwithview = false;
                } else {
                  self.events[k].showholiday = false;
                  self.events[k].showview = false;
                  self.events[k].showdayoff = true;
                  self.events[k].showclockin = false;
                  self.events[k].showclockout = false;
                  self.events[k].showfalseview = false;
                  self.events[k].makereadonly = false;
                  self.events[k].showoffview = false;
                  self.events[k].showclockinwithview = false;
                  self.events[k].showclockoutwithview = false;
                }
              } else if (date2 > dated) {
                self.events[k].showholiday = false;
                self.events[k].showview = false;
                self.events[k].showdayoff = true;
                self.events[k].showclockin = false;
                self.events[k].showclockout = false;
                self.events[k].showfalseview = false;
                self.events[k].makereadonly = false;
                self.events[k].showoffview = false;
                self.events[k].showclockinwithview = false;
                self.events[k].showclockoutwithview = false;
              } else {
                self.events[k].showholiday = false;
                self.events[k].showview = false;
                self.events[k].showdayoff = true;
                self.events[k].showclockin = false;
                self.events[k].showclockout = false;
                self.events[k].showfalseview = false;
                self.events[k].makereadonly = false;
                self.events[k].showoffview = false;
                self.events[k].showclockinwithview = false;
                self.events[k].showclockoutwithview = false;
              }
            } else if (
              self.events[k].tO_StartTime != "-" &&
              self.events[k].tO_EndTime != "-" &&
              self.events[k].time_Off_Whole_Day == false
            ) {
              if (
                self.events[k].clock_In_Time == "-" &&
                self.events[k].clock_Out_Time == "-"
              ) {
                const date = today.getTime() - 100000000;
                const date2 = today.getTime();

                let dated = date1.getTime();
                if (self.events[k].oT_Shift_StartTime != "-") {
                  if (
                    self.events[k].oT_Clock_In_Time == "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = true;
                      self.events[k].makereadonly = false;
                    } else if (date2 > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.events[k].regcount > 1 ||
                      self.events[k].otcount > 1
                    ) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = true;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockout = true;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].showoffview = true;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = true;
                      self.events[k].showclockoutwithview = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showdayoff = false;
                    self.events[k].showclockin = false;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockout = false;
                    self.events[k].showclockoutwithview = false;
                    self.events[k].showfalseview = true;
                    self.events[k].showoffview = false;
                    self.events[k].makereadonly = false;
                  } else if (date2 > dated) {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showdayoff = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockin = true;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockout = false;
                    self.events[k].showclockoutwithview = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = false;
                  } else {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockin = true;
                    self.events[k].showclockout = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = true;
                    self.events[k].showdayoff = false;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockoutwithview = false;
                  }
                }
              } else if (
                self.events[k].clock_In_Time != "-" &&
                self.events[k].clock_Out_Time == "-"
              ) {
                if (self.events[k].regcount > 1 || self.events[k].otcount > 1) {
                  self.events[k].showholiday = false;
                  self.events[k].showview = true;
                  self.events[k].showclockin = false;
                  self.events[k].showoffview = true;
                  self.events[k].showclockout = false;
                  self.events[k].showfalseview = false;
                  self.events[k].makereadonly = false;
                  self.events[k].showdayoff = false;
                  self.events[k].showclockinwithview = false;
                  self.events[k].showclockoutwithview = true;
                } else {
                  self.events[k].showholiday = false;
                  self.events[k].showview = false;
                  self.events[k].showclockin = false;
                  self.events[k].showoffview = false;
                  self.events[k].showclockout = true;
                  self.events[k].showfalseview = false;
                  self.events[k].makereadonly = false;
                  self.events[k].showdayoff = false;
                  self.events[k].showclockinwithview = false;
                  self.events[k].showclockoutwithview = false;
                }
              } else if (
                self.events[k].clock_In_Time != "-" &&
                self.events[k].clock_Out_Time != "-"
              ) {
                const date = today.getTime() - 200000000;
                let dated = date1.getTime();
                const date2 = today.getTime();
                if (self.events[k].oT_Shift_StartTime != "-") {
                  if (
                    self.events[k].oT_Clock_In_Time == "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = true;
                      self.events[k].makereadonly = false;
                    } else if (date2 > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockinwithview = true;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.events[k].regcount > 1 ||
                      self.events[k].otcount > 1
                    ) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = true;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockout = true;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].showoffview = true;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = true;
                      self.events[k].showclockoutwithview = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showdayoff = false;
                    self.events[k].showclockin = false;
                    self.events[k].showclockout = false;
                    self.events[k].showfalseview = false;
                    self.events[k].showoffview = true;
                    self.events[k].makereadonly = false;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockoutwithview = false;
                  } else {
                    self.events[k].showholiday = false;
                    self.events[k].showview = true;
                    self.events[k].showdayoff = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockin = false;
                    self.events[k].showclockout = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = false;
                    self.events[k].showclockinwithview = true;
                    self.events[k].showclockoutwithview = false;
                  }
                }
              }
            } else if (
              self.events[k].shift_StartTime == "-" &&
              self.events[k].shft_EndTime == "-" &&
              self.events[k].oT_Shift_StartTime == "-" &&
              self.events[k].oT_Shift_EndTime == "-"
            ) {
              self.events[k].showholiday = false;
              self.events[k].showview = false;
              self.events[k].showdayoff = false;
              self.events[k].showclockin = false;
              self.events[k].showclockinwithview = false;
              self.events[k].showclockout = false;
              self.events[k].showclockoutwithview = false;
              self.events[k].showfalseview = true;
              self.events[k].makereadonly = false;
            } else {
              if (
                self.events[k].clock_In_Time == "-" &&
                self.events[k].clock_Out_Time == "-"
              ) {
                const date = today.getTime() - 100000000;
                const date2 = today.getTime();

                let dated = date1.getTime();
                if (self.events[k].oT_Shift_StartTime != "-") {
                  if (
                    self.events[k].oT_Clock_In_Time == "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = true;
                      self.events[k].makereadonly = false;
                    } else if (date2 > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.events[k].regcount > 1 ||
                      self.events[k].otcount > 1
                    ) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = true;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockout = true;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].showoffview = true;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = true;
                      self.events[k].showclockoutwithview = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showdayoff = false;
                    self.events[k].showclockin = false;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockout = false;
                    self.events[k].showclockoutwithview = false;
                    self.events[k].showfalseview = true;
                    self.events[k].showoffview = false;
                    self.events[k].makereadonly = false;
                  } else if (date2 > dated) {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showdayoff = false;
                    self.events[k].showclockin = true;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockout = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockoutwithview = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = false;
                  } else {
                    self.events[k].showholiday = false;
                    self.events[k].showview = false;
                    self.events[k].showclockin = true;
                    self.events[k].showclockout = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = true;
                    self.events[k].showdayoff = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockoutwithview = false;
                  }
                }
              } else if (
                self.events[k].clock_In_Time != "-" &&
                self.events[k].clock_Out_Time == "-"
              ) {
                if (self.events[k].regcount > 1 || self.events[k].otcount > 1) {
                  self.events[k].showholiday = false;
                  self.events[k].showview = true;
                  self.events[k].showclockin = false;
                  self.events[k].showclockout = false;
                  self.events[k].showfalseview = false;
                  self.events[k].makereadonly = false;
                  self.events[k].showdayoff = false;
                  self.events[k].showoffview = false;
                  self.events[k].showclockinwithview = false;
                  self.events[k].showclockoutwithview = true;
                } else {
                  self.events[k].showholiday = false;
                  self.events[k].showview = false;
                  self.events[k].showclockin = false;
                  self.events[k].showclockout = true;
                  self.events[k].showfalseview = false;
                  self.events[k].makereadonly = false;
                  self.events[k].showoffview = false;
                  self.events[k].showdayoff = false;
                  self.events[k].showclockinwithview = false;
                  self.events[k].showclockoutwithview = false;
                }
              } else if (
                self.events[k].clock_In_Time != "-" &&
                self.events[k].clock_Out_Time != "-"
              ) {
                const date = today.getTime() - 100000000;
                let dated = date1.getTime();
                const date2 = today.getTime();
                if (self.events[k].oT_Shift_StartTime != "-") {
                  if (
                    self.events[k].oT_Clock_In_Time == "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = true;
                      self.events[k].makereadonly = false;
                    } else if (date2 > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockout = false;
                      self.events[k].showclockoutwithview = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time == "-"
                  ) {
                    if (
                      self.events[k].regcount > 1 ||
                      self.events[k].otcount > 1
                    ) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = true;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = true;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockout = true;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    }
                  } else if (
                    self.events[k].oT_Clock_In_Time != "-" &&
                    self.events[k].oT_Clock_Out_Time != "-"
                  ) {
                    if (date > dated) {
                      self.events[k].showholiday = false;
                      self.events[k].showview = false;
                      self.events[k].showdayoff = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].showoffview = true;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = false;
                      self.events[k].showclockoutwithview = false;
                    } else {
                      self.events[k].showholiday = false;
                      self.events[k].showview = true;
                      self.events[k].showdayoff = false;
                      self.events[k].showoffview = false;
                      self.events[k].showclockin = false;
                      self.events[k].showclockout = false;
                      self.events[k].showfalseview = false;
                      self.events[k].makereadonly = false;
                      self.events[k].showclockinwithview = true;
                      self.events[k].showclockoutwithview = false;
                    }
                  }
                } else {
                  if (date > dated) {
                    self.events[k].showholiday = false;
                    self.events[k].showview = true;
                    self.events[k].showdayoff = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockin = false;
                    self.events[k].showclockout = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = false;
                    self.events[k].showclockinwithview = false;
                    self.events[k].showclockoutwithview = false;
                  } else {
                    self.events[k].showholiday = false;
                    self.events[k].showview = true;
                    self.events[k].showdayoff = false;
                    self.events[k].showclockin = false;
                    self.events[k].showoffview = false;
                    self.events[k].showclockout = false;
                    self.events[k].showfalseview = false;
                    self.events[k].makereadonly = false;
                    self.events[k].showclockinwithview = true;
                    self.events[k].showclockoutwithview = false;
                  }
                }
              }
            }

            if (
              date1.getDay() == 0 &&
              self.events[k].shift_StartTime == "-" &&
              self.events[k].shft_EndTime == "-" &&
              self.events[k].oT_Shift_StartTime == "-" &&
              self.events[k].oT_Shift_EndTime == "-"
            ) {
              self.events[k].showholiday = true;
              self.events[k].showview = false;
              self.events[k].showdayoff = false;
              self.events[k].showclockin = false;
              self.events[k].showclockout = false;
              self.events[k].showfalseview = false;
              self.events[k].showoffview = false;
              self.events[k].makereadonly = false;
              self.events[k].showclockinwithview = false;
              self.events[k].showclockoutwithview = false;
            } else if (
              date1.getDay() == 6 &&
              self.events[k].shift_StartTime == "-" &&
              self.events[k].shft_EndTime == "-" &&
              self.events[k].oT_Shift_StartTime == "-" &&
              self.events[k].oT_Shift_EndTime == "-"
            ) {
              self.events[k].showholiday = true;
              self.events[k].showview = false;
              self.events[k].showdayoff = false;
              self.events[k].showclockin = false;
              self.events[k].showclockout = false;
              self.events[k].showoffview = false;
              self.events[k].showfalseview = false;
              self.events[k].makereadonly = false;
              self.events[k].showclockinwithview = false;
              self.events[k].showclockoutwithview = false;
            }
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get TimeEntries Fail", 22, "Critical");
          //alert(error + "Correlation ID is 22");
          self.LoadingDialog = false;
        });
    },
    ChangeMonth(mth) {
      let self = this;
      let d = new Date();
      d.setMonth(mth - 1);
      let year = self.todayyears.getFullYear();
      d.setFullYear(year);
      let mm = parseInt(mth);
      self.todaymonths = mm;
      self.focus = d;
      self.events = [];
      self.GetTimeCalendar();
    },
    ChangeYear(year) {
      let self = this;
      let d = new Date();
      d.setMonth(self.todaymonths - 1);
      let y = year.getFullYear();
      d.setFullYear(y);
      self.focus = d;
      self.events = [];
      self.GetTimeCalendar();
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    formatDate1(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[0] + "-" + finaldate[1] + "-" + finaldate[2];
    },
    CloseCinDialog() {
      let self = this;
      self.clock.clockouttime = null;
      self.clock.clockintime = null;
      self.clock.timeType = null;
      self.ClockInDialig = false;
    },
    CloseCoutDialog() {
      let self = this;
      self.clock.clockouttime = null;
      self.clock.clockintime = null;
      self.clock.timeType = null;
      self.ClockOutDialig = false;
    },
    clockingIn() {
      let self = this;
      localStorage.setItem("pagenum", self.page);
      self.error.errormessagetimetype = !self.clock.timeType
        ? "Please fill in the required fields"
        : "";
      if (
        self.clock.timeType != null &&
        self.clock.timeType != "" &&
        self.clock.clockinlocate != null &&
        self.clock.clockinlocate != "" &&
        self.clock.cinlat != null &&
        self.clock.cinlat != "" &&
        self.clock.cinlang != null &&
        self.clock.cinlang != ""
      ) {
        let incon = false;
        if (self.clock.timeType == "Regular") {
          if (self.clock.shiftstart != "-") {
            incon = true;
          }
        } else {
          if (self.clock.otshiftstart != "-") {
            incon = true;
          }
        }

        if (incon == true) {
          const today = new Date();
          const getutcmin = today.getUTCMinutes();
          const getutchrs = today.getUTCHours();
          let utctime = "";
          if (getutchrs < 10) {
            if (getutcmin < 10) {
              utctime =
                today.getUTCFullYear() +
                "/" +
                (today.getUTCMonth() + 1) +
                "/" +
                today.getUTCDate() +
                " " +
                "0" +
                today.getUTCHours() +
                ":" +
                "0" +
                getutcmin;
            } else {
              utctime =
                today.getUTCFullYear() +
                "/" +
                (today.getUTCMonth() + 1) +
                "/" +
                today.getUTCDate() +
                " " +
                "0" +
                today.getUTCHours() +
                ":" +
                getutcmin;
            }
          } else {
            if (getutcmin < 10) {
              utctime =
                today.getUTCFullYear() +
                "/" +
                (today.getUTCMonth() + 1) +
                "/" +
                today.getUTCDate() +
                " " +
                today.getUTCHours() +
                ":" +
                "0" +
                getutcmin;
            } else {
              utctime =
                today.getUTCFullYear() +
                "/" +
                (today.getUTCMonth() + 1) +
                "/" +
                today.getUTCDate() +
                " " +
                today.getUTCHours() +
                ":" +
                getutcmin;
            }
          }
          self.clock.clockintime = utctime;
          let temin = self.Converttolocal1(self.clock.clockintime);
          let from = new Date(Date.parse(temin));
          let shiftstartbefore = new Date();
          let shiftstartafter = new Date();
          let [sshours, ssminutes] = "";
          if (self.clock.timeType == "Regular") {
            [sshours, ssminutes] = self.clock.shiftstart.split(":");
          } else {
            [sshours, ssminutes] = self.clock.otshiftstart.split(":");
          }
          shiftstartbefore.setHours(+sshours);
          shiftstartbefore.setMinutes(ssminutes);
          shiftstartafter.setHours(+sshours);
          shiftstartafter.setMinutes(ssminutes);
          if (self.startbeforetime == "hour") {
            let adds = shiftstartbefore.getHours() - parseInt(self.startbefore);
            shiftstartbefore.setHours(+adds);
          } else if (self.startbeforetime == "min") {
            let adds =
              shiftstartbefore.getMinutes() - parseInt(self.startbefore);
            shiftstartbefore.setMinutes(adds);
          }
          if (self.startaftertime == "hour") {
            let adds = shiftstartafter.getHours() + parseInt(self.startafter);
            shiftstartafter.setHours(+adds);
          } else if (self.startaftertime == "min") {
            let adds = shiftstartafter.getMinutes() + parseInt(self.startafter);
            shiftstartafter.setMinutes(adds);
          }

          let frnewDate = new Date(from.getTime());
          let ssnewDate = new Date(shiftstartafter.getTime());
          let ssbnewDate = new Date(shiftstartbefore.getTime());

          let temparr = null;
          if (frnewDate > ssnewDate) {
            temparr = {
              time_ID: self.clock.timeID,
              clock_In_Time: "-",
              clock_Out_Time: "-",
              clock_In_Location: "-",
              clock_Out_Location: "-",
              clock_In_Lattitude: "-",
              clock_In_Longitude: "-",
              clock_Out_Lattitude: "-",
              clock_Out_Longitude: "-",
              irregular_In_Time: self.clock.clockintime,
              irregular_Out_Time: "-",
              irregular_In_Location: self.clock.clockinlocate,
              irregular_Out_Location: "-",
              irregular_In_Lattitude: (self.clock.cinlat).toString(),
              irregular_In_Longitude: (self.clock.cinlang).toString(),
              irregular_Out_Lattitude: "-",
              irregular_Out_Longitude: "-",
              time_Type: self.clock.timeType,
              employee_ID: store.state.employeeID,
              company_ID: store.state.companyID,
              approve: false,
              user_ID: store.state.userid,
              shift_StartTime: self.clock.shiftstart,
              shft_EndTime: self.clock.shiftend,
            };
          } else if (ssbnewDate > frnewDate) {
            temparr = {
              time_ID: self.clock.timeID,
              clock_In_Time: "-",
              clock_Out_Time: "-",
              clock_In_Location: "-",
              clock_Out_Location: "-",
              clock_In_Lattitude: "-",
              clock_In_Longitude: "-",
              clock_Out_Lattitude: "-",
              clock_Out_Longitude: "-",
              irregular_In_Time: self.clock.clockintime,
              irregular_Out_Time: "-",
              irregular_In_Location: self.clock.clockinlocate,
              irregular_Out_Location: "-",
              irregular_In_Lattitude: (self.clock.cinlat).toString(),
              irregular_In_Longitude: (self.clock.cinlang).toString(),
              irregular_Out_Lattitude: "-",
              irregular_Out_Longitude: "-",
              approve: false,
              time_Type: self.clock.timeType,
              employee_ID: store.state.employeeID,
              company_ID: store.state.companyID,
              shift_StartTime: self.clock.shiftstart,
              shft_EndTime: self.clock.shiftend,
              user_ID: store.state.userid,
            };
          } else {
            temparr = {
              time_ID: self.clock.timeID,
              clock_In_Time: self.clock.clockintime,
              clock_Out_Time: "-",
              clock_In_Location: self.clock.clockinlocate,
              clock_Out_Location: "-",
              clock_In_Lattitude: (self.clock.cinlat).toString(),
              clock_In_Longitude: (self.clock.cinlang).toString(),
              clock_Out_Lattitude: "-",
              clock_Out_Longitude: "-",
              irregular_In_Time: "-",
              irregular_Out_Time: "-",
              irregular_In_Location: "-",
              irregular_Out_Location: "-",
              irregular_In_Lattitude: "-",
              irregular_In_Longitude: "-",
              irregular_Out_Lattitude: "-",
              irregular_Out_Longitude: "-",
              approve: false,
              time_Type: self.clock.timeType,
              employee_ID: store.state.employeeID,
              company_ID: store.state.companyID,
              shift_StartTime: self.clock.shiftstart,
              shft_EndTime: self.clock.shiftend,
              user_ID: store.state.userid,
            };
          }

          axios
            .post(`${self.url}ClockInOut/AddClockInOut`, temparr)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ReportTrace(3);
                self.ClockInDialig = false;
                self.CinCoutLists = [];
                self.events = [];
                self.DataShowList = [];
                self.location = "";
                self.locationlat = "";
                self.locationlang = "";
                self.outlocation = "";
                self.outlocationlat = "";
                self.outlocationlang = "";
                self.center = { lat: 45.508, lng: -73.587 };
                alert("Clock-In Successfully");
                if (self.showsheetview == false) {
                  self.GetTimesheet();
                } else {
                  self.GetTimeCalendar();
                }
                self.events.push({
                  name: "Vacation",
                  start: "2019-07-08",
                  end: "2019-07-08",
                });
                self.error.errormessagetimetype = null;
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "ClockIn TimeEntries Fail",
                22,
                "Critical"
              );
            });
        } else {
          if (self.clock.timeType == "Regular") {
            alert("There is no regular shift time today!");
          } else {
            alert("There is no overtime shift time today!");
          }
        }
      } else if (
        self.clock.clockinlocate == null ||
        self.clock.clockinlocate == "" ||
        self.clock.cinlat == null ||
        self.clock.cinlat == "" ||
        self.clock.cinlang == null ||
        self.clock.cinlang == ""
      ) {
        alert("Please select your location!");
      } else {
        alert("Please fill in the required fields!");
      }
    },
    clockingOut() {
      let self = this;
      localStorage.setItem("pagenum", self.page);
      self.error.errormessagetimetype = !self.clock.timeType
        ? "Please fill in the required fields"
        : "";
      if (
        self.clock.timeType != null &&
        self.clock.timeType != "" &&
        self.clock.clockoutlocate != null &&
        self.clock.clockoutlocate != "" &&
        self.clock.coutlat != null &&
        self.clock.coutlat != "" &&
        self.clock.coutlang != null &&
        self.clock.coutlang != ""
      ) {
        const today = new Date();
        const getutcmin = today.getUTCMinutes();
        const getutchrs = today.getUTCHours();
        let utctime = "";
        if (getutchrs < 10) {
          if (getutcmin < 10) {
            utctime =
              today.getUTCFullYear() +
              "/" +
              (today.getUTCMonth() + 1) +
              "/" +
              today.getUTCDate() +
              " " +
              "0" +
              today.getUTCHours() +
              ":" +
              "0" +
              getutcmin;
          } else {
            utctime =
              today.getUTCFullYear() +
              "/" +
              (today.getUTCMonth() + 1) +
              "/" +
              today.getUTCDate() +
              " " +
              "0" +
              today.getUTCHours() +
              ":" +
              getutcmin;
          }
        } else {
          utctime =
            today.getUTCFullYear() +
            "/" +
            (today.getUTCMonth() + 1) +
            "/" +
            today.getUTCDate() +
            " " +
            today.getUTCHours() +
            ":" +
            today.getUTCMinutes();
        }
        self.clock.clockouttime = utctime;
        let temout = self.Converttolocal1(self.clock.clockouttime);
        let to = new Date(Date.parse(temout));
        let temin = self.Converttolocal1(self.clock.clockintime);
        let shiftendbefore = new Date(Date.parse(temin));
        let shiftendafter = new Date(Date.parse(temin));
        let [sehours, seminutes] = "";
        if (self.clock.timeType == "Regular") {
          [sehours, seminutes] = self.clock.shiftend.split(":");
        } else {
          [sehours, seminutes] = self.clock.otshiftend.split(":");
        }
        shiftendbefore.setHours(+sehours);
        shiftendbefore.setMinutes(seminutes);
        shiftendafter.setHours(+sehours);
        shiftendafter.setMinutes(seminutes);
        if (self.endbeforetime == "hour") {
          let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
          shiftendbefore.setHours(+adds);
        } else if (self.endbeforetime == "min") {
          let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
          shiftendbefore.setMinutes(adds);
        }
        if (self.endaftertime == "hour") {
          let adds = shiftendafter.getHours() + parseInt(self.endafter);
          shiftendafter.setHours(+adds);
        } else if (self.endaftertime == "min") {
          let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
          shiftendafter.setMinutes(adds);
        }

        let tonewDate = new Date(to.getTime());
        let senewDate = new Date(shiftendafter.getTime());
        let sebnewDate = new Date(shiftendbefore.getTime());

        let temparr = [];
        if (tonewDate > senewDate) {
          temparr = {
            clock_ID: self.clock.clockID,
            time_ID: self.clock.timeID,
            clock_In_Time: "-",
            clock_Out_Time: "-",
            clock_In_Location: "-",
            clock_Out_Location: "-",
            clock_In_Lattitude: "-",
            clock_In_Longitude: "-",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            time_Type: self.clock.timeType,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
            approve: false,
            irregular_In_Time: self.clock.clockintime,
            irregular_Out_Time: self.clock.clockouttime,
            irregular_In_Location: self.clock.clockinlocate,
            irregular_Out_Location: self.clock.clockoutlocate,
            irregular_In_Lattitude: (self.clock.cinlat).toString(),
            irregular_In_Longitude: (self.clock.cinlang).toString(),
            irregular_Out_Lattitude: (self.clock.coutlat).toString(),
            irregular_Out_Longitude: (self.clock.coutlang).toString(),
            user_ID: store.state.userid,
            shift_StartTime: self.clock.shiftstart,
            shft_EndTime: self.clock.shiftend,
          };
        } else if (sebnewDate > tonewDate) {
          temparr = {
            clock_ID: self.clock.clockID,
            time_ID: self.clock.timeID,
            clock_In_Time: "-",
            clock_Out_Time: "-",
            clock_In_Location: "-",
            clock_Out_Location: "-",
            clock_In_Lattitude: "-",
            clock_In_Longitude: "-",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            time_Type: self.clock.timeType,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
            approve: false,
            irregular_In_Time: self.clock.clockintime,
            irregular_Out_Time: self.clock.clockouttime,
            irregular_In_Location: self.clock.clockinlocate,
            irregular_Out_Location: self.clock.clockoutlocate,
            irregular_In_Lattitude: (self.clock.cinlat).toString(),
            irregular_In_Longitude: (self.clock.cinlang).toString(),
            irregular_Out_Lattitude: (self.clock.coutlat).toString(),
            irregular_Out_Longitude: (self.clock.coutlang).toString(),
            user_ID: store.state.userid,
            shift_StartTime: self.clock.shiftstart,
            shft_EndTime: self.clock.shiftend,
          };
        } else {
          temparr = {
            clock_ID: self.clock.clockID,
            time_ID: self.clock.timeID,
            clock_In_Time: self.clock.clockintime,
            clock_Out_Time: self.clock.clockouttime,
            clock_In_Location: self.clock.clockinlocate,
            clock_Out_Location: self.clock.clockoutlocate,
            clock_In_Lattitude: (self.clock.cinlat).toString(),
            clock_In_Longitude: (self.clock.cinlang).toString(),
            clock_Out_Lattitude: (self.clock.coutlat).toString(),
            clock_Out_Longitude: (self.clock.coutlang).toString(),
            time_Type: self.clock.timeType,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
            approve: false,
            irregular_In_Time: "-",
            irregular_Out_Time: "-",
            irregular_In_Location: "-",
            irregular_Out_Location: "-",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            user_ID: store.state.userid,
            shift_StartTime: self.clock.shiftstart,
            shft_EndTime: self.clock.shiftend,
          };
        }
        axios
          .post(`${self.url}ClockInOut/UpdateClockInOut`, temparr)
          .then(function (response) {
            if (response.data.status == 0) {
              self.ClockOutDialig = false;
              alert("Clock-Out Successfully");
              self.ReportTrace(4);
              self.CinCoutLists = [];
              self.events = [];
              self.DataShowList = [];
              self.location = "";
              self.locationlat = "";
              self.locationlang = "";
              self.outlocation = "";
              self.outlocationlat = "";
              self.outlocationlang = "";
              self.center = { lat: 45.508, lng: -73.587 };
              if (self.showsheetview == false) {
                self.GetTimesheet();
              } else {
                self.GetTimeCalendar();
              }
              self.events.push({
                name: "Vacation",
                start: "2019-07-08",
                end: "2019-07-08",
              });
              self.error.errormessagetimetype = null;
            }
          })
          .catch(function (error) {
            self.LogTrace(error, " ClockOut TimeEntries Fail", 22, "Critical");
          });
      } else if (
        self.clock.clockoutlocate == null ||
        self.clock.clockoutlocate == "" ||
        self.clock.coutlat == null ||
        self.clock.coutlat == "" ||
        self.clock.coutlang == null ||
        self.clock.coutlang == ""
      ) {
        alert("Please select your location!");
      } else {
        alert("Please fill in the required fields!");
      }
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    createMarker: function (latlng) {
      let lats = latlng;
      let self = this;
      self.marker = new window.google.maps.Marker({
        setMap: this.$refs.map.$mapObject,
        position: lats,
        animation: window.google.maps.Animation.DROP,
      });
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: lats }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            self.location = results[0].formatted_address;
            self.outlocation = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },
    geolocate: function () {
      var controlDiv = document.createElement("div");
      var firstChild = document.createElement("button");
      firstChild.style.backgroundColor = "#fff";
      firstChild.style.border = "none";
      firstChild.style.outline = "none";
      firstChild.style.width = "28px";
      firstChild.style.height = "28px";
      firstChild.style.borderRadius = "2px";
      firstChild.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
      firstChild.style.cursor = "pointer";
      firstChild.style.marginRight = "10px";
      firstChild.style.padding = "0px";
      firstChild.title = "Your Location";
      controlDiv.appendChild(firstChild);
      var secondChild = document.createElement("div");
      secondChild.style.margin = "5px";
      secondChild.style.width = "18px";
      secondChild.style.height = "18px";
      secondChild.style.backgroundImage =
        "url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)";
      secondChild.style.backgroundSize = "180px 18px";
      secondChild.style.backgroundPosition = "0px 0px";
      secondChild.style.backgroundRepeat = "no-repeat";
      secondChild.id = "you_location_img";
      firstChild.appendChild(secondChild);
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        "center_changed",
        function () {
          secondChild.style["background-position"] = "0 0";
        }
      );
      var ref = this;
      firstChild.addEventListener("click", function () {
        navigator.geolocation.getCurrentPosition((position) => {
          let latlng = new window.google.maps.LatLng(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude)
          );

          ref.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          ref.locationlat = position.coords.latitude;
          ref.locationlang = position.coords.longitude;
          ref.outlocationlat = position.coords.latitude;
          ref.outlocationlang = position.coords.longitude;
          ref.createMarker(latlng);
        });
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
    },
  },
};
</script>
<style scoped>
.mr-6 {
  height: 45px !important;
}
@media only screen and (max-width: 375px) {
  .mr-6 {
    height: 90px !important;
  }
}
>>> .v-calendar-weekly__head-weekday {
  color: black !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
>>> .v-input__icon--append .v-icon {
  color: purple;
}
>>> .selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
>>> .selectboxs.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
>>> .selectboxs.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
>>> .selectboxs.theme--light.v-select .v-select__selections {
  color: #444444;
}
.fontwei {
  font-weight: normal;
  /* margin-left: 15px; */
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 24px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 16px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
/* >>> .theme--light.v-data-table thead tr th {
  font-size: 15px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
} */
>>> .v-calendar-weekly__day-label {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: left;
  margin: 4px 0 0 0;
}
>>> .v-application .primary {
  margin-left: 10px;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
>>> .v-calendar .v-event {
  position: relative;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 15px;
  margin-left: 2px;
  background: transparent !important;
}
/* >>>.v-calendar .v-event.v-event-start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
 >>>.v-calendar .v-event.v-event-start .v-application .secondary {
      background-color: #424242 !important; 
     border-color: #424242 !important; 
} */
>>> .element.style {
  height: 60px;
  top: 10px;
  margin-bottom: 1px;
}
>>> .v-calendar-weekly__head-weekday {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20px;
  flex: 1 0 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px px 0px 4px;
  font-size: 18px;
  background-color: bisque;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: bisque;
}
.my-event {
  overflow: visible;
  text-overflow: ellipsis;
  border-radius: 2px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 14px;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 0px;
  margin-top: 5px;
  margin-bottom: 1px;
}
.line-clamp {
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chiptoggle {
  margin-top: -85px;
  margin-left: 35px;
}
.gosideway{
  margin-top: -70px;
}
@media (max-width: 900px) {
  .tbheader {
    margin-top: 1rem !important;
  }
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 20px;
    margin-left: -5px;
    margin-right: 5px;
    max-width: 70px;
  }
  .gosideway {
    margin-top: -45px;
    margin-left: -5px;
  }
  .hidetxts {
    visibility: hidden;
  }
}

@media (max-width: 280px) {
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 17px;
    margin-left: -20px;
    margin-right: 5px;
    max-width: 70px;
  }
  .minizebox {
    width: 100px !important;
    height: 30px;
  }
  .boxhsize {
    height: 42px;
  }
}

@media (min-width: 380px) {
  .boxhsize {
    height: 42px;
  }
}

@media (max-width: 320px) {
  .fix-calender {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 5px;
    margin-left: -8px;
    margin-right: 5px;
    max-width: 40px;
  }
  .gosideway {
    margin-top: -45px;
    margin-left: -5px;
  }
  .fixed-calendar {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}
@media (max-width: 375px) {
  .fix-calender {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .fixed-calendar {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media (max-width: 450px) {
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 5px;
    margin-left: -17px;
    margin-right: 5px;
    max-width: 70px;
  }
  .gosideway {
    margin-top: -45px;
    margin-left: -17px;
  }
}

>>> .mx-input {
  color: black;
}

>>> .mdi:before {
  color: black;
}

>>> .v-application .white--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
  background: transparent !important;
}

>>> .theme--light.v-calendar-weekly .v-calendar-weekly__day {
  /* border-right: #ccc8c8 1px solid; */
  /* border-bottom: #e0e0e0 1px solid; */
  color: #000000;
  border: #ccc8c8 1px solid;
}
</style>