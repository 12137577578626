<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" align="center">
        <p class="text-center welcomeToHeader">Welcome to</p>
        <br />
        <p class="text-center optimisticHeader" style>OPTIMISTIC</p>
      </v-col>
      <v-col cols="12" align="center">
        <p class="text-center resetPas">{{ $t("ResetYourPassword") }}</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <p class="yourEmail">
        {{ $t("PleaseEnterYourEmail") }}
      </p>
    </v-row>

    <v-row justify="center" class="mt-3">
      <v-col cols="12" style="flex-basis: 500px">
        <v-alert type="error" v-model="erroralert">{{ errorMessage }}</v-alert>
        <v-alert
          type="info"
          v-model="successalert"
          style="text-align: left; font-size: 15px"
          >{{ successMessage }}</v-alert
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="offset-sm-3">
        <v-text-field
          color="warning"
          :label="$t('companyCode')"
          :error-messages="error_Code"
          v-model="company_code"
        >
          <template v-slot:prepend-inner>
            <img
              src="@/assets/images/company id.png"
              style="margin-right: 0.5rem"
            />
          </template>
        </v-text-field>
        <v-text-field
          color="warning"
          :label="$t('username')"
          :error-messages="error_Name"
          v-model="user_name"
        >
          <template v-slot:prepend-inner>
            <img
              style="
                width: 25px;
                height: 25px;
                margin-right: 1rem;
                margin-left: 0.5rem;
              "
              src="@/assets/images/user.png"
            />
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="offset-sm-3">
        <v-btn
          rounded
          color="#F99D20"
          block
          style="height: 52px; font-size: 18px; color: white"
          depressed
          class="text-capitalize kanit"
          @click="resetPassword"
          >{{ $t("ResetPassword") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters style="margin-top: 2rem">
      <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="offset-sm-3">
        <p
          class="kanit font17 float-right"
          style="
            color: #0fa7d8;
            text-decoration: underline;
            font-size: 13px;
            cursor: pointer;
          "
          @click="backToLogin()"
        >
          {{ $t("BackToLogin") }}
        </p>
      </v-col>
    </v-row>
    <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
export default {
  mixins: [LogTrace],
  data() {
    return {
      loadingdialog: false,
      url: enurl.apiUrl,
      company_code: "",
      user_name: "",
      error_Code: "",
      error_Name: "",
      erroralert: false,
      successalert: false,
      errorMessage: "",
      successMessage: "",
      companylist: [],
      validate: false,
      company_ID: [],
      user_Name: [],
    };
  },

  mounted() {
    this.GetCompanyCode();
  },

  methods: {
    backToLogin() {
      let self = this;
      self.$router.push("/").catch(()=>{});
    },

    validateEmail(email) {
      let valid = /\S+@\S+\.\S+/;
      return valid.test(email) || "Invalid Email";
    },
    
    clearMessage: function () {
      this.erroralert = false;
      this.errorMessage = "";
      this.successalert = false;
      this.successMessage = "";
    },

    GetCompanyCode() {
      let self = this;
      axios.get(`${self.url}Company/GetCompany`).then(function (response) {
        self.companylist = response.data.data;
      });
    },

    ValidateCompanyCode() {
      let self = this;
      self.company_ID = self.companylist.filter((x) => x.companyCode.toLowerCase() === self.company_code.toLowerCase() && x.status === true);
      if (self.company_ID.length != 0)
      {
        let temp = {
          companyId: self.company_ID[0].company_ID,
        };
        axios.post(`${self.url}UserInfo/GetUserbyCompanyId`, temp)
        .then(function (response) {
          self.user_Name = response.data.data.filter((x) => x.userName.toLowerCase() == self.user_name.toLowerCase());
          if (self.user_Name.length != 0)
          {
            self.validate = true;
          }
          else
          {
            self.error_Name = "User is not matched in application";
            self.validate = false;
          }
        });
      }
      else
      {
        self.error_Code = "Company code is not matched in application";
        self.validate = false;
      }
    },

    async resetPassword() {
      let self = this;
      self.clearMessage();
      self.error_Code = !self.company_code ? "Please fill in the required field" : "";
      self.error_Name = !self.user_name ? "Please fill in the required field" : "";
      if (!self.error_Code && !self.error_Name)
      {
        self.ValidateCompanyCode();
        setTimeout(() => {
          let tempp = {
            company_Id: self.company_ID[0].company_ID,
            company_Code: self.company_ID[0].companyCode,
            user_Id: self.user_Name[0].userId,
          };
          if (self.validate)
          {
            self.loadingdialog = true;
            axios.post(`${self.url}Login/ForgetPassword`, tempp)
            .then(function () {
              self.successalert = true;
              self.successMessage = self.$t("forgetpassw");
              self.company_code = "";
              self.user_name = "";
              self.loadingdialog = false;
            })
            .catch(function (error) {
              //alert(error);
              self.LogTrace(error, "Forget Password Fail", 54, "Low");
              self.loadingdialog = false;
            });
          }
          else
          {
            alert("The company code and / or username are not matched in application");
            self.loadingdialog = false;
          }
        }, 2000);
      }
    },
  },
};
</script>
<style scoped>
.rowjust {
  margin-top: -1.5rem;
}
.welcomeToHeader {
  color: #0fa7d8;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
}
.optimisticHeader {
  font-size: 60px;
  color: #f99d20;
  font-family: "Montserrat", sans-serif;
  margin-top: -30px;
  font-weight: bold;
}
.resetPas {
  font-size: 20px;
  color: #f99d20;
  font-family: Kanit, Regular;
  margin-top: -3rem;
}
.underline {
  font-size: 14px;
  color: #3c4043;
  font-family: Kanit, Regular;
  margin-left: 4px;
  text-decoration: underline;
}
.yourEmail {
  font-size: 14px;
  color: #3c4043;
  font-family: Kanit, Regular;
  margin-left: 10px;
}
.companyLogoCustom {
  min-width: 428px;
  height: 180px;
  background-color: #ffffff;
  padding-top: 80px;
}
.p-custom {
  padding: 0px;
}
@media (min-width: 1179px) {
  .hello-right {
    margin-left: 100px;
  }
}
@media (max-height: 600px) and (max-width: 350px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 60px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
@media (max-height: 660px) and (max-width: 300px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 20px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
@media (max-height: 570px) and (max-width: 370px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 40px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
</style>
