<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #444444; margin-top: 15px">
        {{ $t("Role") }}
      </p>
    </div>
    <v-card class="mt-6">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="12" md="3" lg="4">
            <v-text-field
              autocomplete="off"
              v-model.lazy="searchtxt"
              :placeholder="$t('Search')"
              color="#F99D20"
              dense
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search()"
            class="white--text text-capitalize ml-6 mb-2"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-row>
        <v-data-table
          :headers="headers"
          style="margin-left: 40px; margin-right: 40px"
          :items="roleList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>
                  <!-- <label class="form-checkbox">
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      disabled
                      @click="select"
                    />
                    <i class="form-icon"></i>
                  </label> -->
                </th>
                <th>{{ $t("RoleID") }}</th>
                <th>{{ $t("RoleName") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <!-- <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="{ roleid: item.role_ID, rolename: item.role_Name }"
                    v-model="selected"
                    v-on:click="justcheckone()"
                  />
                  <i class="form-icon"></i>
                </label> -->
                <v-checkbox class="custom-checkbox mt-0" 
                    v-model="item.checked"
                    @change="checkmyemp(item.role_ID)" 
                    color="#F89D1F" hide-details>
                </v-checkbox>
              </td>
              <td>{{ item.role_Code }}</td>
              <td>{{ item.role_Name }}</td>
            </tr>
          </template>
        </v-data-table>

        <!-- <div class="pt-2 d-flex">
          <div style="margin-left: auto; padding: 1rem;margin-right: 2rem;">
            <v-spacer></v-spacer>
            <v-row style="margin-top:50px">
              <v-col>
                <v-pagination v-model="page" :length="pageCount" color="#f99d20" :total-visible="5"></v-pagination>
              </v-col>
              <v-col style="margin-top:-10px">
                <div style="width:110px" class="mt-3">
                  <v-select
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    :items="items"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>-->
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-2 text-capitalize commoncancelbtn"
          @click="goto"
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mr-4 text-capitalize commonsavebtn"
          @click="gotoedit"
          >{{ $t("Add") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      selected: [],
      selectAll: false,
      LoadingDialog: false,
      ugID: 0,
      roleList: [],
      rlList: [],
      tempuser: [],
      headers: [
        {
          text: "Role ID",
          value: "role_Code",
          align: "left",
          sortable: true,
        },
        {
          text: "Role Name",
          value: "role_Name",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  //computed: {
  //   selectedAll: {
  //     set(val) {
  //       this.selected = []
  //       if (val) {
  //         for(let i = 1; i <= this.roleList; i++) {
  //           this.selected.push(i)
  //         }
  //       }
  //     },
  //     get() {
  //       return this.selected.length == this.roleList
  //        //return this.roleList ? this.selected.length == this.roleList.length : false;
  //     }
  //   }
  // },
  mounted: function () {
    this.getRole();
    this.GetRl();
  },
  watch: {
    searchtxt: function () {
      this.getRole();
    },
  },
  methods: {
    goto() {
      let self = this;
      self.ugID = self.$route.params.ugid;
      localStorage.setItem("localgrouprole", JSON.stringify(null));
      this.$router.push({
        name: "usergroupedit",
        params: { usergroupedit: "usergroupedit", ugid: self.ugID },
      }).catch(()=>{});
    },
    justcheckone() {
      this.selected = [];
    },
    gotoedit() {
      let self = this;
      self.ugID = self.$route.params.ugid;
      this.SaveLocal();
      this.$router.push({
        name: "usergroupedit",
        params: { usergroupedit: "usergroupedit", ugid: self.ugID },
      }).catch(()=>{});
    },
    getRole() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Role/GetRoleByCompanyID`, tempp)
        .then(function (response) {
          let llist = response.data.data;
          self.roleList = llist.map((v) => ({
                ...v,
                checked: false,
              }));
        });
    },
    GetRl() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        user_Group_ID: parseInt(self.$route.params.ugid),
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroupRole/GetUserGroupRole`, tempp)
        .then(function (response) {
          self.rlList = response.data.data;
          self.tempuser = JSON.parse(localStorage.getItem("localgrouprole"));
          if (self.tempuser != null) {
            self.roleList = self.roleList.map((v) => ({
              ...v,
              checked: self.tempuser.some((user) => user.roleid === v.role_ID),
            }));
            for (let i in self.tempuser) {
              self.selected.push({
                roleid: self.tempuser[i].roleid,
                rolename: self.tempuser[i].rolename,
              });
            }
          } else {
            for (let i = 0; i < self.rlList.length; i++) {
              self.selected.push({
                roleid: self.rlList[i].role_ID,
                rolename: self.rlList[i].role_Name,
              });
            }
            self.roleList = self.roleList.map((v) => ({
              ...v,
              checked: self.selected.some((user) => user.roleid === v.role_ID),
            }));
          }

          self.LoadingDialog = false;
        });
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.roleList) {
          this.selected.push({
            roleid: this.roleList[i].role_ID,
            rolename: this.roleList[i].role_Name,
          });
        }
      }
      //localStorage.setItem("localgrouprole",JSON.stringify(this.selected));
    },
    checkmyemp(id) {
      //this.roleList = this.roleList.filter(a => a.roleid == id);
      this.roleList = this.roleList.map((v) => ({
        ...v,
        checked: id === v.role_ID,
      }));
      this.selected = 
      this.roleList
      .filter(user => user.checked)
      .map((v) => {
        return {
            roleid: v.role_ID,
            rolename: v.role_Name,
        };
      });
    },
    SaveLocal() {
      localStorage.setItem("localgrouprole", JSON.stringify(this.selected));
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.roleList;
        self.roleList = [];
        let temp = List.filter(
          (v) =>
            v.role_Name.toLowerCase().indexOf(self.searchtxt.toLowerCase()) >
              -1 || v.role_Code.toString().indexOf(self.searchtxt) > -1
        );
        for (let i = 0; i < temp.length; i++) {
          self.roleList.push(temp[i]);
        }
      } else {
        alert("Please enter anything!!");
        self.getRole();
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>