<template>
  <div>
    <v-card style="margin-bottom: 2.5rem;padding:1.5rem;">
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <div class="content">
            
            <v-row>
              <v-col>
                <img src="@/assets/images/building.png" class="pr-1" alt="Company Logo" />
                <label style="font-size: 20px; color: #4f4f4f;">
                  {{ $t("BrandIdentity") }}
                </label>
              </v-col>
            </v-row>
            <!-- <hr color="#dcdcdc"> -->
            
            <!-- Company code -->
            <v-row style="height:85px;">
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("companyCode") }}<span class="red--text"> *</span></label>
                <v-text-field
                  readonly
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  filled
                  outlined
                  class="Rd5"
                  type="text"
                  v-model="company.code"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <!-- Company name & Company Tax ID -->
            <v-row style="height:85px;">
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("companyName") }}<span class="red--text"> *</span></label>
                <v-text-field
                  color="#F99D20"
                  dense
                  outlined
                  class="Rd5"
                  v-model="company.name"
                  :rules="nameAdd"
                  @keydown="showEdit=false">
                </v-text-field>
              </v-col>
              <!-- Company Tax ID -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("TaxIDNo")}}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  outlined
                  class="Rd5"
                  v-mask="mask1"
                  v-model="company.companytaxID"
                  :rules="CompanyTaxAdd"
                  @keydown="showEdit=false">
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <hr color="#dcdcdc"> -->
            
            <!-- Address Header --> 
            <v-row class="pt-5">
              <v-col>
                <img src="@/assets/images/Mask Group 23.png" class="pr-1" alt="Company Logo" />
                <label style="font-size: 20px; color: #4f4f4f;">{{ $t("address") }}</label>
              </v-col>
            </v-row>

            <v-row style="height:85px;">
              <!-- Address -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("address") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.address"
                  :rules="addressAdd"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderAddress"
                  @keydown="showEdit=false">
                </v-text-field>
              </v-col>
              <!-- Subdistrict -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("subdistrict") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.subDistrict"
                  :rules="subDistrictAdd"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderSubdistrict"
                  @keydown="showEdit=false"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="height:85px;">
              <!-- District -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("district") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.district"
                  :rules="districtAdd"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderDistrict"
                  @keydown="showEdit=false"
                ></v-text-field>
              </v-col>
              <!-- City -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("province") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.city"
                  :rules="cityAdd"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderProvince"
                  @keydown="showEdit=false"
                ></v-text-field>
              </v-col>           
            </v-row>

            <v-row style="height:85px;">
              <!-- Postal Code -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("postalcode") }}<span class="red--text"> *</span></label>
                <!-- to change -->
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderPostalCode"
                  @keydown="showEdit=false"
                  v-model="company.postalCode"
                  :rules="postalCodeAdd"
                  v-mask="mask"
                ></v-text-field>
              </v-col>
              <!-- City & Country -->
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("country") }}<span class="red--text"> *</span></label>
                <v-autocomplete
                  dense
                  outlined
                  hide-details
                  v-model="company.country"
                  style="margin-top: 0; font-size: 17px"
                  :items="CountryList"
                  :placeholder="$t('country')+'*'"
                  item-value="txt"
                  :item-text="(item) => ` ${item.txt}`"
                  append-icon="mdi-chevron-down"
                  color="#F99D20"
                  :rules="countryAdd"
                  @change="showEdit=false"
                  class="Rd5"
                >
                </v-autocomplete>
                <!-- <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.country"
                  :rules="countryAdd"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderCountry"
                  @keydown="showEdit=false"
                ></v-text-field> -->
              </v-col>
            </v-row>
            <!-- <hr color="#dcdcdc"> -->
            
            <!-- Phone No & Email -->
            <v-row class="pt-5">
              <v-col>
                <img src="@/assets/images/contact-us.png" class="pr-1" alt="Company Logo" />
                <label style="font-size: 20px; color: #4f4f4f;">
                  {{ $t("contact") }}
                </label>
              </v-col>
            </v-row>
            
            <v-row style="height:85px;">
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("telephone_number") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.teleNumber"
                  :rules="phoneRules"
                  v-mask="mask"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderPhone"
                  @keydown="showEdit=false">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="5">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("email")}}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.email"
                  :rules="emailRules"
                  dense
                  outlined
                  class="Rd5"
                  :placeholder="placeholderEmail"
                  @keydown="showEdit=false">
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <hr color="#dcdcdc"> -->
          </div>
        </v-form>
      </v-container>
      
      <!-- Brand Identiry (Color Code & Company Logo) -->
      <v-container fluid>
        <v-row class="pt-5">
          <v-col>
            <img src="@/assets/images/Icon metro-palette.svg" class="pr-1" alt="Company Logo" />
            <label style="font-size: 20px; color: #4f4f4f;">
              {{ $t("companyLogo&Color") }}
            </label>
          </v-col>
        </v-row>
        
        <v-row> 
        <!-- Company Logo -->
        <v-col cols="12" md="6" lg="6" xl="5">
          <label style="color: #7F7F7F;">{{ $t("Companylogo") }}</label>
          <v-row>
            <v-col>
              <div v-if="errorMessage.logo">
                <p class="errorPtag">{{ $t("UploadRequrid") }}</p>
              </div>
              <div v-if="brand.logo==null || brand.logo==''" justify="center"
                style="position: relative;
                  display: inline-block;">
                <v-img
                  src="@/assets/images/apartment.png"
                  height="250px"
                  width="250px"
                  style="border-radius: 50%;"
                  aspect-ratio="1.4"
                >
                </v-img>
                <v-btn
                  @click="$refs.inputLogo.click()"
                  small
                  fab
                  style="font-weight: normal; position: absolute; top :80%; left: 80%"
                  color="#A3A7AA"
                >
                  <v-icon color="#ffffff">mdi-camera</v-icon>
                </v-btn>
              </div>
              <div v-else justify="center" 
                style="position: relative;
                  display: inline-block;">
                <v-img
                  :src="getPhoto"
                  height="250px"
                  width="250px"
                  style="border-radius: 50%;border:3px solid #F89D1F;"
                  aspect-ratio="1.4"
                >
                </v-img>
                <v-btn
                  @click="$refs.inputLogo.click()"
                  small
                  fab
                  style="font-weight: normal; position: absolute; top :80%; left: 80%"
                  color="#A3A7AA"
                >
                  <v-icon color="#ffffff">mdi-camera</v-icon>
                </v-btn>
              </div>
              <input
                type="file"
                accept="image/png, image/jpeg, image/bmp"
                @change="onUpdate"
                ref="inputLogo"
                style="display: none"
                id="sky"
              />
            </v-col>
          </v-row>
          <div>
            <div class="text" style="color: red">
              <label>** {{ $t("Filetype") }}</label>
            </div>
          </div>
        </v-col>
        <!-- Company Color Code -->
        <v-col cols="12" md="6" lg="6" xl="5">
          <label style="font-size: 16px; color: #7F7F7F;"
            >{{ $t("Companycolorcode")
            }}
          </label>
          <v-col cols="12">
            <v-text-field
              v-model="brand.color_Code"
              @keydown="showEdit=false"
              dense
              solo
              style="margin-left: -0.7rem"
            >
              <template v-slot:append>
                <v-menu
                  v-model="colorPicker"
                  top
                  nudge-bottom="105"
                  nudge-left="16"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div :style="switchStyle" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        class="colorPicker"
                        v-show="colorPicker"
                        dot-size="25"
                        v-model="companycolorPicker"
                        mode="hexa"
                        hide-mode-switch
                        style="z-index: -1"
                      >
                      </v-color-picker>
                      <v-btn
                        @click="colorPickerForclose(), showEdit=false"
                        color="#F99D20"
                        class="white--text"
                        style="
                          margin-top: -60px;
                          margin-right: 15px;
                          right: 0;
                          z-index: 10;
                          position: absolute;
                        "
                      >
                        OK
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
            <span
              v-if="errorMessage.color_Code"
              style="font-size: 13px"
              class="red--text"
              >{{ $t("Requirefield") }}</span
            >
          </v-col>
        </v-col>
      </v-row>
      </v-container>
    </v-card>
    
    <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- crop dialog -->
    <v-dialog v-model="cropDialog" persistent width="550">
      <v-card
        style="border-radius: 15px"
        class="overflow-x-hidden overflow-y-hidden"
      >
        <v-card-title>
          <p>{{ $t("Companylogo") }}</p>
          <v-spacer></v-spacer>
          <v-btn @click="CancelCropdialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="justify-content:center pa-12">
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-center justify-center cropper-container"
            >
              <cropper
                v-if="!cropped"
                class="cropper"
                ref="cropper"
                :src="cropperSrc"
                :stencil-props="{
                  aspectRatio: dynamicAspectRatio,
                  movable: true,
                  resizable: true,
                }"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-center justify-center cropper-container"
            >
              <v-img
                v-if="cropped == true"
                :src="getcroppedphoto"
                height="350px"
                width="256"
                aspect-ratio="1.4"
              >
              </v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              @click="Oncrop"
              depressed
              color="#0FA7D8"
              class="white--text"
              v-if="!cropped"
            >
              <img
                src="@/assets/images/Icon ionic-md-crop copy.png"
                class="pr-1"
                alt="Crop Icon"
              />
              Crop</v-btn
            >
            <v-btn
              v-if="cropped"
              color="darken-1"
              class="mr-3 text-capitalize fix-btn"
              width="150"
              max-height="35"
              text
              @click="CancelCroppedimage"
            >
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn
              v-if="cropped"
              @click="SaveCroppedimage(), showEdit=false"
              color="#F99D20"
              class="mr-3 text-capitalize white--text fix-btn"
              width="150"
              max-height="35"
              style="font-family: kanit, Regular; font-weight: normal"
            >
              {{ $t("Save") }}
            </v-btn>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    
    <template>
      <v-footer v-if="!showEdit" style="background-color:#FFFFFF" elevation="20" height="80px" fixed>
        <v-row style="flex-direction: row-reverse">
          <!-- Save & Cancel -->
          <v-btn
            :disabled="permission.edit"
            class="pa-2 mr-7 text-capitalize commonsavebtn"
            style="border-radius: 5px"
            @click="UpdateCompany"
            >{{ $t("saveChanges") }}</v-btn
          >
          <v-btn
            class="pa-2 mr-7 text-capitalize commoncancelbtn"
            outlined
            @click="cancelGone"
            text
            >{{ $t("cancel") }}</v-btn
          >
        </v-row>
      </v-footer>
    </template>
  </div>
</template>


<script>
import enurl from "@/api/environment";
import CheckViewEdit from "@/function/RolePermissions.js";
import store from "@/store";
import Axios from "axios";
import LogTrace from "@/function/Log.js";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  components: {
    Cropper,
  },
  mixins: [LogTrace, CheckViewEdit],
  data() {
    return {
      loadingdialog: false,
      cropDialog: false,
      cropperSrc: null,
      nameAdd: [],
      emailAdd: [],
      countryAdd: [],
      ContactAdd: [],
      CompanyTaxAdd: [],
      cityAdd: [],
      addressAdd: [],
      districtAdd: [],
      subDistrictAdd: [],
      postalCodeAdd: [],
      ComList: [],
      disabled: false,
      mask: "###########",
      mask1: "#############",
      size: 10,
      emailRules: [],
      phoneRules: [],
      pkgID: 0,
      registerdate: "",
      dynamicAspectRatio: null,
      company: {
        code: null,
        name: null,
        teleNumber: null,
        city: null,
        country: null,
        email: null,
        companytaxID: null,
        address: null,
        contactname: null,
        uid: null,
        pwd: null,
        expireddate: "",
        subDistrict: null,
        district: null,
        postalCode: null,
      },
      permission: {
        edit: true,
      },
      imageUrl: "",
      croppedimage: "",
      cropped: false,
      brandList: "",
      url: enurl.apiUrl,
      companycolorPicker: "#DA902C",
      colorPicker: false,
      isApi: true,
      showEdit: false,
      showerror: false,

      brand: {
        color_Code: "#DA902C",
        logo: "",
      },
      constbrand:{
        color_Code: "#DA902C",
        logo: "",
      },
      errorMessage: {
        color_Code: "",
        logo: "",
        errorMessagename: "",
        errorMessageph: "",
        errorMessageemail: "",
        errorMessagecountry: "",
        errorMessagecity: "",
        errorMessageadd: "",
        errorMessagecontactname: "",
      },
      request: {
        company_ID: store.state.companyID,
      },
      companyList: [],
      CountryList: [
        { val: 1, txt: "Brunei" },
        { val: 2, txt: "Cambodia" },
        { val: 3, txt: "China" },
        { val: 4, txt: "EastTimor" },
        { val: 5, txt: "Indonesia" },
        { val: 6, txt: "Japan" },
        { val: 7, txt: "Laos" },
        { val: 8, txt: "Malaysia" },
        { val: 9, txt: "Myanmar" },
        { val: 10, txt: "Philippines" },
        { val: 11, txt: "Singapore" },
        { val: 12, txt: "South Korea" },
        { val: 13, txt: "Taiwan" },
        { val: 14, txt: "Thailand" },
        { val: 15, txt: "Vietnam" },
        { val: 16, txt: "Other" }
    ],
    };
  },
  watch: {
    "company.name"() {
      this.nameAdd = [];
    },
    "company.email"() {
      this.emailRules = [];
    },
    "company.teleNumber"() {
      this.phoneRules = [];
    },
    "company.country"() {
      this.countryAdd = [];
    },
    "company.city"() {
      this.cityAdd = [];
    },
    "company.address"() {
      this.addressAdd = [];
    },
    "company.district"() {
      this.districtAdd = [];
    },
    "company.subDistrict"() {
      this.subDistrictAdd = [];
    },
    "company.postalCode"() {
      this.postalCodeAdd = [];
    },
    "company.contactname"() {
      this.ContactAdd = [];
    },
    "company.companytaxID"() {
      this.CompanyTaxAdd = [];
    },

    "brand.color_Code"(v) {
      if (v) this.errorMessage.color_Code = "";
    },
    "brand.logo"(v) {
      if (v) this.errorMessage.logo = "";
    },
  },

  computed: {
    placeholderAddress(){
      return this.$t("fillInAddress");
    },
    placeholderProvince(){
      return this.$t("fillInProvince");
    },
    placeholderSubdistrict(){
      return this.$t("fillInSubdistrict");
    },
    placeholderDistrict(){
      return this.$t("fillInDistrict");
    },
    placeholderPostalCode(){
      return this.$t("fillInPostalCode");
    },
    placeholderCountry(){
      return this.$t("fillInCountry");
    },
    placeholderPhone(){
      return this.$t("fillInPhone");
    },
    placeholderEmail(){
      return this.$t("fillInE-mail");
    },
    getPhoto() {
      return this.isApi
        ? this.brand.logo
        : "data:image/png;base64," + this.brand.logo;
    },
    getcroppedphoto() {
      return "data:image/png;base64," + this.croppedimage;
    },
    switchStyle() {
      if (!this.brand.color_Code) {
        return {
          backgroundColor: this.companycolorPicker,
          cursor: "pointer",
          height: "35px",
          width: "60px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      } else {
        return {
          backgroundColor: this.brand.color_Code,
          cursor: "pointer",
          height: "35px",
          width: "60px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      }
    },
  },

  mounted() {
    this.GetCompanyInfo();
    this.GetAllCompanyList();
    this.permission = this.CheckViewEdit(116, 117);
  },
  methods: {
    //GetAllCompanyList
    GetAllCompanyList() {
      let self = this;
      Axios.get(`${self.url}Company/GetCompany`).then(function (response) {
        let conlist = response.data.data;

        self.ComList = conlist.filter(
          (e) => e.company_ID != 0 && e.activeFlag != false
        );
      });
    },
    ValidationCompanyName() {
      let self = this;
      let notSameList = self.ComList.filter(
        (el) => el.company_ID != store.state.companyID
      );

      return !notSameList.some(
        (el) => el.companyName.toLowerCase() == self.company.name.toLowerCase()
      );
    },
    //Validation Email
    async CheckEmail() {
      let self = this;
      let temp = {
        user_Email: self.company.email,
        isEdit: "Edit",
        company_ID: store.state.companyID,
      };
      const response = await Axios.post(
        `${self.url}Company/GetEmployeeEmail`,
        temp
      );
      return response.data.message;
    },
    //Validation Telephone Number
    ValidationTeleandEmail() {
      let self = this;
      let phone = self.company.teleNumber;

      for (let j in self.ComList) {
        if (phone == self.ComList[j].tele_Number) {
          alert("Telephone number is already used by another company");
          return false;
        }
      }
      return true;
    },
    //Get Company Information
    GetCompanyInfo() {
      try {
        let self = this;
        self.loadingdialog = true;
        let tempID = store.state.companyID;
        let temp = {
          company_ID: tempID,
        };
        Axios.post(`${self.url}Company/GetCompanyProfile`, temp).then(function (
          response
        ) {
          self.companyList = response.data.data[0];
          self.loadingdialog = false;
          self.company.name = self.companyList.companyName;
          self.company.teleNumber = self.companyList.tele_Number;
          self.company.email = self.companyList.company_Email;
          self.company.code = self.companyList.companyCode;
          self.company.contactname = self.companyList.contact_Name;
          self.company.city = self.companyList.city;
          self.company.country = self.companyList.country;
          self.company.address = self.companyList.company_Address;
          self.company.companytaxID = self.companyList.company_Tax_ID;
          self.company.subDistrict = self.companyList.subDistrict;
          self.company.district = self.companyList.district;
          self.company.postalCode = self.companyList.postalCode;
          //self.LogTrace(null, "Get Brand Identity", 52, "Critical");
          self.showEdit = true;
          self.brand.logo = self.companyList.logo;
          self.constbrand.logo = self.companyList.logo;
          if (self.companyList.logo)
          {
            self.isApi = true;
            self.companycolorPicker = self.companyList.color_Code;
            sessionStorage.setItem("logoImage", self.getPhoto);
            store.state.logoImage = sessionStorage.getItem("logoImage");
            sessionStorage.setItem("color_Code", self.brand.color_Code);
            store.state.color_Code = sessionStorage.getItem("color_Code");
          }
          if (self.companyList.color_Code)
          {
          self.brand.color_Code = self.companyList.color_Code;
          self.constbrand.color_Code = self.companyList.color_Code;
          }
        });
      } catch (error) {
        alert(error);
        self.loadingdialog = false;
      }
    },
    emailvalidate(val) {
      let v = true;
      if (val != null || val != "") {
        v = /.+@.+\..+/.test(val);
      } else {
        v = false;
      }
      return v;
    },
    companytaxvalidate(val) {
      let v = true;
      if (val != null || val != "") {
        v = /^\d{13}$/.test(val);
      } else {
        v = false;
      }
      return v;
    },
    phonenumbervalidation(val) {
      let v;
      if (val != null || val != "") {
        if (val.length >= 9) {
          v = true;
        } else {
          v = false;
        }
      } else {
        v = false;
      }
      return v;
    },
    // Update Company Information
    async UpdateCompany() {
      let self = this;
      let compID = store.state.companyID;
      let emailvalidate = self.emailvalidate(self.company.email);
      let companytaxvalidate = self.companytaxvalidate(
        self.company.companytaxID
      );
      let phonevalidate = self.phonenumbervalidation(self.company.teleNumber);
      self.nameAdd = [(v) => !!v || "Company Name is required"];

      self.phoneRules = [
        (v) => !!v || "Telephone number is required",
        (v) => v.length >= 9 || "Telephone number must be at least 9 numbers",
      ];
      self.emailRules = [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
      self.countryAdd = [(v) => !!v || "Country is required"];
      self.cityAdd = [(v) => !!v || "City is required"];
      self.ContactAdd = [(v) => !!v || "Contact Name is required"];
      self.CompanyTaxAdd = [
        (v) => !!v || "Company Tax ID is required",
        (v) =>
          /^\d{13}$/.test(v) || "Company Tax ID must be at least 13 numbers",
      ];
      self.addressAdd = [(v) => !!v || "Address is required"];
      self.districtAdd = [(v) => !!v || "District is required"];
      self.subDistrictAdd = [(v) => !!v || "SubDistrict is required"];
      self.postalCodeAdd = [(v)=> !!v || "Postal Code is required"];
      let tempvalidate = await self.$refs.form.validate();
      let checkBrand = self.errorAlert();
      if (
        emailvalidate == true &&
        companytaxvalidate == true &&
        phonevalidate == true &&
        checkBrand == true &&
        tempvalidate == true &&
        self.company.name != null &&
        self.company.name != "" &&
        self.company.teleNumber != null &&
        self.company.teleNumber != "" &&
        self.company.email != null &&
        self.company.email != "" &&
        self.company.country != null &&
        self.company.country != "" &&
        self.company.city != null &&
        self.company.city != "" &&
        self.company.address != null &&
        self.company.address != "" &&
        self.company.companytaxID != null &&
        self.company.companytaxID != ""&&
        self.company.subDistrict != null &&
        self.company.subDistrict != ""&&
        self.company.district != null &&
        self.company.district != ""&&
        self.company.postalCode != null &&
        self.company.postalCode != ""&&
        self.brand.color_Code != null &&
        self.brand.color_Code != ""
      ) {
        let CheckName = self.ValidationCompanyName();
        self.loadingdialog = true;
        if (CheckName) {
          let companyUser = {
            company_ID: compID,
            companyCode: self.company.code,
            companyName: self.company.name,
            tele_Number: self.company.teleNumber,
            city: self.company.city,
            country: self.company.country,
            company_Email: self.company.email,
            company_Address: self.company.address,
            company_Tax_ID: self.company.companytaxID,
            subDistrict: self.company.subDistrict,
            district: self.company.district,
            postalCode: self.company.postalCode,
            color_Code: self.brand.color_Code,
            logo: self.brand.logo,
          };
          self.loadingdialog = true;
          if(self.brand.logo==null){
            companyUser.logo='';
          }
          Axios.post(`${self.url}Company/UpdateCompanyProfile`, companyUser)
            .then(function (response) {
              if (
                response.data.message ==
                "This company code is already taken. Please use a different one."
              ) {
                self.loadingdialog = false;
                alert(response.data.message);
                self.error.errorMessagecode =
                  "This company code is already taken. Please use a different one.";
              } else {
                self.GetCompanyInfo();
                self.$refs.inputLogo.value="";
                alert(response.data.message);
                self.loadingdialog = false;
                self.LogTrace(null, "Update Company", 12, "Low");
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Company Fail", 12, "Low");
              self.loadingdialog = false;
            });
        } else {
          alert("Company name already exists.");
          self.loadingdialog = false;
        }
      }
    },
    colorPickerForclose() {
      this.brand.color_Code = "";
      this.brand.color_Code = this.companycolorPicker.slice(0, 7);
      this.colorPicker = false;
    },
    Oncrop() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          this.convertBackToImageFile(blob);
        });
        this.cropped = true;
        
      }
    },
    convertBackToImageFile(imageBlob) {
      const file = imageBlob;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let arr = reader.result.split(","),
          mime = arr[1];
        this.croppedimage = mime;
        this.isApi = false;
        this.brand.logo = this.croppedimage;
      };
    },
    SaveCroppedimage() {
      this.brand.logo = this.croppedimage;
      this.isApi=false;
      this.cropped = false;
      this.cropDialog = false;
      this.$refs.inputLogo.value="";
    },
    CancelCroppedimage(){
      this.cropped=false;
      this.croppedimage="";
    },
    CancelCropdialog()
    {
      this.isApi = true;
      this.brand.logo=this.constbrand.logo;
      this.croppedimage="";
      this.$refs.inputLogo.value="";
      this.cropDialog=false;
      this.cropperSrc=null;
    },
    // File Update
    onUpdate() {
      let self = this;
      let input = self.$refs.inputLogo.files;

      let image = self.$refs.inputLogo;
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      this.dynamicAspectRatio = imageWidth / imageHeight;
      if (input[0]) {
        let reader = new FileReader();
        let file = input[0];

        let filesize = Math.round(file.size / 1024).toString();
        let filetype = file.type.split("/")[1];
        if (
          filetype == "png" ||
          filetype == "jpg" ||
          filetype == "bmp" ||
          filetype == "jpeg"
        ) {
          if (filesize < 2048) {
            reader.onload = (e) => {
              let image = new Image();
              image.src = e.target.result;

              self.cropperSrc = reader.result;
            };
            reader.readAsDataURL(input[0]);
            self.cropDialog = true;
          } else {
            alert("Uploaded photo is greater than 2MB");
            document.getElementById("sky").value = "";
          }
        } else {
          alert("Files must be png only");
          document.getElementById("sky").value = "";
        }
      }
    },

    ValidateTrue(obj) {
      return Object.values(obj).every((x) => x);
    },
    errorAlert() {
      let { errorMessage, brand } = this;
      errorMessage.color_Code = !brand.color_Code
        ? "Please fill in the required fields"
        : "";
      if (errorMessage.color_Code != "") {
        this.showerror = true;
      } else {
        this.showerror = false;
      }
      return !(brand.logo == "" || brand.color_Code == "");
    },
    editGone() {
      this.showEdit = false;
    },
    cancelGone() {
      this.GetCompanyInfo();
      this.districtAdd = [];
      this.subDistrictAdd = [];
      this.CompanyTaxAdd = [];
      this.postalCodeAdd = [];
      this.errorMessage.logo = "";
      document.getElementById("sky").value = "";
    },
  },
};
</script>
<style scoped>
>>>.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #d3d3d3;
  }
.Rd5 {
  border-radius: 5px;
}
.colorPicker >>> .v-color-picker__dot {
  display: none;
}
.colorPicker >>> canvas {
  width: 270px;
}
>>> .v-color-picker.colorPicker.v-sheet.theme--light.theme--light {
  max-width: 300px !important;
}
>>> .v-text-field--enclosed .v-input__append-inner {
  margin: 10px 0 !important;
}
>>> .v-color-picker__canvas {
  margin: 10px;
}

.colorPicker >>> span {
  position: absolute;
  left: 20px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 3px;
  font-family: Dbx;
}
.colorPicker >>> input {
  font-size: 12px;
  width: 100px;
  margin-right: 80px;
}
.colorCodeBoxAppend {
  cursor: pointer;
  width: 50px;
  height: 28px;
  position: absolute;
  right: 12px;
  bottom: 15px;
  border-radius: 2px;
}

.errorPtag {
  font-family: "Kanit", sans-serif;
  color: #ff5252 !important ;
  font-size: 12px;
}

@media only screen and (max-width: 1280px) {
  .cropper-container {
    height: 660px;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .cropper-container {
    height: 570px;
    width: 100%;
  }
}
</style>
