<template>
  <div style="margin-left: 2rem; margin-right: 2rem; margin-bottom: 2rem">
    <div class="pt-6">
      <v-row>
        <v-col cols="12" xs="12" sm="4" md="3" lg="3" xl="3">
          <div class="d-flex">
            <div class="pt-2">{{ $t("Year") }}</div>
            <div>
              <v-autocomplete
                class="ml-2"
                v-model="filteryear"
                :items="yearitems"
                outlined
                dense
                color="#F99D20"
                @change="YearChange(filteryear)"
              ></v-autocomplete>
            </div>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="5" lg="6" xl="7">
          <div style="text-align: start">
            <p>H : {{ $t("Holiday") }}</p>
            <p>-- : {{ $t("WorkingDay") }}</p>
            <p>W : {{ $t("Holidaychangedtoworkingday") }}</p>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="3" lg="3" xl="2" class="text-right">
          <v-btn
            color="#F99D20"
            class="white--text text-capitalize pl-7 pr-8"
            max-height="35"
            :disabled="permission.upload"
            @click="uploadholidaysDialog = true"
            style="margin-bottom: 1rem"
          >
            {{ $t("UploadHolidays") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text text-capitalize"
            max-height="35"
            :disabled="permission.upload"
            @click="annualholidaysDialog = true"
          >
            <img src="@/assets/images/eye.png" class="mr-2" />
            {{ $t("Annualholidays") }}</v-btn
          >
        </v-col>
      </v-row>
      <div>
        <v-simple-table style="color: #f99d20" class="simpletb">
          <tr>
            <td></td>
            <th scope="col" class="customth">1</th>
            <th scope="col" class="customth">2</th>
            <th scope="col" class="customth">3</th>
            <th scope="col" class="customth">4</th>
            <th scope="col" class="customth">5</th>
            <th scope="col" class="customth">6</th>
            <th scope="col" class="customth">7</th>
            <th scope="col" class="customth">8</th>
            <th scope="col" class="customth">9</th>
            <th scope="col" class="customth">10</th>
            <th scope="col" class="customth">11</th>
            <th scope="col" class="customth">12</th>
            <th scope="col" class="customth">13</th>
            <th scope="col" class="customth">14</th>
            <th scope="col" class="customth">15</th>
            <th scope="col" class="customth">16</th>
            <th scope="col" class="customth">17</th>
            <th scope="col" class="customth">18</th>
            <th scope="col" class="customth">19</th>
            <th scope="col" class="customth">20</th>
            <th scope="col" class="customth">21</th>
            <th scope="col" class="customth">22</th>
            <th scope="col" class="customth">23</th>
            <th scope="col" class="customth">24</th>
            <th scope="col" class="customth">25</th>
            <th scope="col" class="customth">26</th>
            <th scope="col" class="customth">27</th>
            <th scope="col" class="customth">28</th>
            <th scope="col" class="customth">29</th>
            <th scope="col" class="customth">30</th>
            <th scope="col" class="customth">31</th>
          </tr>
          <tr>
            <th scope="row" class="customth">Jan</th>
            <td
              class="txt"
              @click="NewHoliday('01', '01', January.one)"
              v-bind:style="{ background: activeColor(1, 'Jan', January.one) }"
            >
              {{
                (this.January.one =
                  IsDataInclude(1, "Jan") != 0
                    ? TypeOption(1, "Jan")
                    : this.sunOption == Option(1, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '01', January.two)"
              v-bind:style="{ background: activeColor(2, 'Jan', January.two) }"
            >
              {{
                (this.January.two =
                  IsDataInclude(2, "Jan") != 0
                    ? TypeOption(2, "Jan")
                    : this.sunOption == Option(2, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '01', January.three)"
              v-bind:style="{
                background: activeColor(3, 'Jan', January.three),
              }"
            >
              {{
                (this.January.three =
                  IsDataInclude(3, "Jan") != 0
                    ? TypeOption(3, "Jan")
                    : this.sunOption == Option(3, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '01', January.four)"
              v-bind:style="{ background: activeColor(4, 'Jan', January.four) }"
            >
              {{
                (this.January.four =
                  IsDataInclude(4, "Jan") != 0
                    ? TypeOption(4, "Jan")
                    : this.sunOption == Option(4, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '01', January.five)"
              v-bind:style="{ background: activeColor(5, 'Jan', January.five) }"
            >
              {{
                (this.January.five =
                  IsDataInclude(5, "Jan") != 0
                    ? TypeOption(5, "Jan")
                    : this.sunOption == Option(5, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '01', January.six)"
              v-bind:style="{ background: activeColor(6, 'Jan', January.six) }"
            >
              {{
                (this.January.six =
                  IsDataInclude(6, "Jan") != 0
                    ? TypeOption(6, "Jan")
                    : this.sunOption == Option(6, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '01', January.seven)"
              v-bind:style="{
                background: activeColor(7, 'Jan', January.seven),
              }"
            >
              {{
                (this.January.seven =
                  IsDataInclude(7, "Jan") != 0
                    ? TypeOption(7, "Jan")
                    : this.sunOption == Option(7, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '01', January.eight)"
              v-bind:style="{
                background: activeColor(8, 'Jan', January.eight),
              }"
            >
              {{
                (this.January.eight =
                  IsDataInclude(8, "Jan") != 0
                    ? TypeOption(8, "Jan")
                    : this.sunOption == Option(8, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '01', January.nine)"
              v-bind:style="{ background: activeColor(9, 'Jan', January.nine) }"
            >
              {{
                (this.January.nine =
                  IsDataInclude(9, "Jan") != 0
                    ? TypeOption(9, "Jan")
                    : this.sunOption == Option(9, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '01', January.ten)"
              v-bind:style="{ background: activeColor(10, 'Jan', January.ten) }"
            >
              {{
                (this.January.ten =
                  IsDataInclude(10, "Jan") != 0
                    ? TypeOption(10, "Jan")
                    : this.sunOption == Option(10, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '01', January.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Jan', January.eleven),
              }"
            >
              {{
                (this.January.eleven =
                  IsDataInclude(11, "Jan") != 0
                    ? TypeOption(11, "Jan")
                    : this.sunOption == Option(11, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '01', January.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Jan', January.twelve),
              }"
            >
              {{
                (this.January.twelve =
                  IsDataInclude(12, "Jan") != 0
                    ? TypeOption(12, "Jan")
                    : this.sunOption == Option(12, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '01', January.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Jan', January.thirteen),
              }"
            >
              {{
                (this.January.thirteen =
                  IsDataInclude(13, "Jan") != 0
                    ? TypeOption(13, "Jan")
                    : this.sunOption == Option(13, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '01', January.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Jan', January.fourteen),
              }"
            >
              {{
                (this.January.fourteen =
                  IsDataInclude(14, "Jan") != 0
                    ? TypeOption(14, "Jan")
                    : this.sunOption == Option(14, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '01', January.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Jan', January.fifteeen),
              }"
            >
              {{
                (this.January.fifteeen =
                  IsDataInclude(15, "Jan") != 0
                    ? TypeOption(15, "Jan")
                    : this.sunOption == Option(15, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '01', January.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Jan', January.sixteen),
              }"
            >
              {{
                (this.January.sixteen =
                  IsDataInclude(16, "Jan") != 0
                    ? TypeOption(16, "Jan")
                    : this.sunOption == Option(16, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '01', January.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Jan', January.seventeen),
              }"
            >
              {{
                (this.January.seventeen =
                  IsDataInclude(17, "Jan") != 0
                    ? TypeOption(17, "Jan")
                    : this.sunOption == Option(17, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '01', January.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Jan', January.eighteen),
              }"
            >
              {{
                (this.January.eighteen =
                  IsDataInclude(18, "Jan") != 0
                    ? TypeOption(18, "Jan")
                    : this.sunOption == Option(18, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '01', January.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Jan', January.ninteen),
              }"
            >
              {{
                (this.January.ninteen =
                  IsDataInclude(19, "Jan") != 0
                    ? TypeOption(19, "Jan")
                    : this.sunOption == Option(19, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '01', January.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Jan', January.twenty),
              }"
            >
              {{
                (this.January.twenty =
                  IsDataInclude(20, "Jan") != 0
                    ? TypeOption(20, "Jan")
                    : this.sunOption == Option(20, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '01', January.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Jan', January.twentyone),
              }"
            >
              {{
                (this.January.twentyone =
                  IsDataInclude(21, "Jan") != 0
                    ? TypeOption(21, "Jan")
                    : this.sunOption == Option(21, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '01', January.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Jan', January.twentytwo),
              }"
            >
              {{
                (this.January.twentytwo =
                  IsDataInclude(22, "Jan") != 0
                    ? TypeOption(22, "Jan")
                    : this.sunOption == Option(22, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '01', January.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Jan', January.twentythree),
              }"
            >
              {{
                (this.January.twentythree =
                  IsDataInclude(23, "Jan") != 0
                    ? TypeOption(23, "Jan")
                    : this.sunOption == Option(23, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '01', January.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Jan', January.twentyfour),
              }"
            >
              {{
                (this.January.twentyfour =
                  IsDataInclude(24, "Jan") != 0
                    ? TypeOption(24, "Jan")
                    : this.sunOption == Option(24, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '01', January.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Jan', January.twentyfive),
              }"
            >
              {{
                (this.January.twentyfive =
                  IsDataInclude(25, "Jan") != 0
                    ? TypeOption(25, "Jan")
                    : this.sunOption == Option(25, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '01', January.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Jan', January.twentysix),
              }"
            >
              {{
                (this.January.twentysix =
                  IsDataInclude(26, "Jan") != 0
                    ? TypeOption(26, "Jan")
                    : this.sunOption == Option(26, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '01', January.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Jan', January.twentyseven),
              }"
            >
              {{
                (this.January.twentyseven =
                  IsDataInclude(27, "Jan") != 0
                    ? TypeOption(27, "Jan")
                    : this.sunOption == Option(27, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '01', January.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Jan', January.twentyeight),
              }"
            >
              {{
                (this.January.twentyeight =
                  IsDataInclude(28, "Jan") != 0
                    ? TypeOption(28, "Jan")
                    : this.sunOption == Option(28, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '01', January.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Jan', January.twentynine),
              }"
            >
              {{
                (this.January.twentynine =
                  IsDataInclude(29, "Jan") != 0
                    ? TypeOption(29, "Jan")
                    : this.sunOption == Option(29, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '01', January.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Jan', January.thirty),
              }"
            >
              {{
                (this.January.thirty =
                  IsDataInclude(30, "Jan") != 0
                    ? TypeOption(30, "Jan")
                    : this.sunOption == Option(30, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '01', January.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'Jan', January.thirtyone),
              }"
            >
              {{
                (this.January.thirtyone =
                  IsDataInclude(31, "Jan") != 0
                    ? TypeOption(31, "Jan")
                    : this.sunOption == Option(31, "Jan")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
          <tr>
            <th scope="row" class="customth">Feb</th>
            <td
              class="txt"
              @click="NewHoliday('01', '02', February.one)"
              v-bind:style="{ background: activeColor(1, 'Feb', February.one) }"
            >
              {{
                (this.February.one =
                  IsDataInclude(1, "Feb") != 0
                    ? TypeOption(1, "Feb")
                    : this.sunOption == Option(1, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '02', February.two)"
              v-bind:style="{ background: activeColor(2, 'Feb', February.two) }"
            >
              {{
                (this.February.two =
                  IsDataInclude(2, "Feb") != 0
                    ? TypeOption(2, "Feb")
                    : this.sunOption == Option(2, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '02', February.three)"
              v-bind:style="{
                background: activeColor(3, 'Feb', February.three),
              }"
            >
              {{
                (this.February.three =
                  IsDataInclude(3, "Feb") != 0
                    ? TypeOption(3, "Feb")
                    : this.sunOption == Option(3, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '02', February.four)"
              v-bind:style="{
                background: activeColor(4, 'Feb', February.four),
              }"
            >
              {{
                (this.February.four =
                  IsDataInclude(4, "Feb") != 0
                    ? TypeOption(4, "Feb")
                    : this.sunOption == Option(4, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '02', February.five)"
              v-bind:style="{
                background: activeColor(5, 'Feb', February.five),
              }"
            >
              {{
                (this.February.five =
                  IsDataInclude(5, "Feb") != 0
                    ? TypeOption(5, "Feb")
                    : this.sunOption == Option(5, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '02', February.six)"
              v-bind:style="{ background: activeColor(6, 'Feb', February.six) }"
            >
              {{
                (this.February.six =
                  IsDataInclude(6, "Feb") != 0
                    ? TypeOption(6, "Feb")
                    : this.sunOption == Option(6, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '02', February.seven)"
              v-bind:style="{
                background: activeColor(7, 'Feb', February.seven),
              }"
            >
              {{
                (this.February.seven =
                  IsDataInclude(7, "Feb") != 0
                    ? TypeOption(7, "Feb")
                    : this.sunOption == Option(7, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '02', February.eight)"
              v-bind:style="{
                background: activeColor(8, 'Feb', February.eight),
              }"
            >
              {{
                (this.February.eight =
                  IsDataInclude(8, "Feb") != 0
                    ? TypeOption(8, "Feb")
                    : this.sunOption == Option(8, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '02', February.nine)"
              v-bind:style="{
                background: activeColor(9, 'Feb', February.nine),
              }"
            >
              {{
                (this.February.nine =
                  IsDataInclude(9, "Feb") != 0
                    ? TypeOption(9, "Feb")
                    : this.sunOption == Option(9, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '02', February.ten)"
              v-bind:style="{
                background: activeColor(10, 'Feb', February.ten),
              }"
            >
              {{
                (this.February.ten =
                  IsDataInclude(10, "Feb") != 0
                    ? TypeOption(10, "Feb")
                    : this.sunOption == Option(10, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '02', February.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Feb', February.eleven),
              }"
            >
              {{
                (this.February.eleven =
                  IsDataInclude(11, "Feb") != 0
                    ? TypeOption(11, "Feb")
                    : this.sunOption == Option(11, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '02', February.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Feb', February.twelve),
              }"
            >
              {{
                (this.February.twelve =
                  IsDataInclude(12, "Feb") != 0
                    ? TypeOption(12, "Feb")
                    : this.sunOption == Option(12, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '02', February.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Feb', February.thirteen),
              }"
            >
              {{
                (this.February.thirteen =
                  IsDataInclude(13, "Feb") != 0
                    ? TypeOption(13, "Feb")
                    : this.sunOption == Option(13, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '02', February.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Feb', February.fourteen),
              }"
            >
              {{
                (this.February.fourteen =
                  IsDataInclude(14, "Feb") != 0
                    ? TypeOption(14, "Feb")
                    : this.sunOption == Option(14, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '02', February.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Feb', February.fifteeen),
              }"
            >
              {{
                (this.February.fifteeen =
                  IsDataInclude(15, "Feb") != 0
                    ? TypeOption(15, "Feb")
                    : this.sunOption == Option(15, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '02', February.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Feb', February.sixteen),
              }"
            >
              {{
                (this.February.sixteen =
                  IsDataInclude(16, "Feb") != 0
                    ? TypeOption(16, "Feb")
                    : this.sunOption == Option(16, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '02', February.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Feb', February.seventeen),
              }"
            >
              {{
                (this.February.seventeen =
                  IsDataInclude(17, "Feb") != 0
                    ? TypeOption(17, "Feb")
                    : this.sunOption == Option(17, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '02', February.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Feb', February.eighteen),
              }"
            >
              {{
                (this.February.eighteen =
                  IsDataInclude(18, "Feb") != 0
                    ? TypeOption(18, "Feb")
                    : this.sunOption == Option(18, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '02', February.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Feb', February.ninteen),
              }"
            >
              {{
                (this.February.ninteen =
                  IsDataInclude(19, "Feb") != 0
                    ? TypeOption(19, "Feb")
                    : this.sunOption == Option(19, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '02', February.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Feb', February.twenty),
              }"
            >
              {{
                (this.February.twenty =
                  IsDataInclude(20, "Feb") != 0
                    ? TypeOption(20, "Feb")
                    : this.sunOption == Option(20, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '02', February.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Feb', February.twentyone),
              }"
            >
              {{
                (this.February.twentyone =
                  IsDataInclude(21, "Feb") != 0
                    ? TypeOption(21, "Feb")
                    : this.sunOption == Option(21, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '02', February.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Feb', February.twentytwo),
              }"
            >
              {{
                (this.February.twentytwo =
                  IsDataInclude(22, "Feb") != 0
                    ? TypeOption(22, "Feb")
                    : this.sunOption == Option(22, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '02', February.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Feb', February.twentythree),
              }"
            >
              {{
                (this.February.twentythree =
                  IsDataInclude(23, "Feb") != 0
                    ? TypeOption(23, "Feb")
                    : this.sunOption == Option(23, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '02', February.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Feb', February.twentyfour),
              }"
            >
              {{
                (this.February.twentyfour =
                  IsDataInclude(24, "Feb") != 0
                    ? TypeOption(24, "Feb")
                    : this.sunOption == Option(24, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '02', February.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Feb', February.twentyfive),
              }"
            >
              {{
                (this.February.twentyfive =
                  IsDataInclude(25, "Feb") != 0
                    ? TypeOption(25, "Feb")
                    : this.sunOption == Option(25, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '02', February.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Feb', February.twentysix),
              }"
            >
              {{
                (this.February.twentysix =
                  IsDataInclude(26, "Feb") != 0
                    ? TypeOption(26, "Feb")
                    : this.sunOption == Option(26, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '02', February.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Feb', February.twentyseven),
              }"
            >
              {{
                (this.February.twentyseven =
                  IsDataInclude(27, "Feb") != 0
                    ? TypeOption(27, "Feb")
                    : this.sunOption == Option(27, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '02', February.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Feb', February.twentyeight),
              }"
            >
              {{
                (this.February.twentyeight =
                  IsDataInclude(28, "Feb") != 0
                    ? TypeOption(28, "Feb")
                    : this.sunOption == Option(28, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '02', February.twentynine)"
              v-bind:style="{
                background:
                  activeColor(29, 'Feb', February.twentynine) ||
                  changeFebcolor(29, 'Feb'),
              }"
            >
              {{
                (this.February.twentynine =
                  this.dayinfeb != 29
                    ? null
                    : this.sunOption == Option(29, "Feb")
                    ? "H"
                    : "--")
              }}
            </td>
            <td class="txt" readonly style="background-color: #e8e9ec"></td>
            <td class="txt" readonly style="background-color: #e8e9ec"></td>
          </tr>
          <tr>
            <th scope="row" class="customth">Mar</th>
            <td
              class="txt"
              @click="NewHoliday('01', '03', March.one)"
              v-bind:style="{ background: activeColor(1, 'Mar', March.one) }"
            >
              {{
                (this.March.one =
                  IsDataInclude(1, "Mar") != 0
                    ? TypeOption(1, "Mar")
                    : this.sunOption == Option(1, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '03', March.two)"
              v-bind:style="{ background: activeColor(2, 'Mar', March.two) }"
            >
              {{
                (this.March.two =
                  IsDataInclude(2, "Mar") != 0
                    ? TypeOption(2, "Mar")
                    : this.sunOption == Option(2, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '03', March.three)"
              v-bind:style="{ background: activeColor(3, 'Mar', March.three) }"
            >
              {{
                (this.March.three =
                  IsDataInclude(3, "Mar") != 0
                    ? TypeOption(3, "Mar")
                    : this.sunOption == Option(3, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '03', March.four)"
              v-bind:style="{ background: activeColor(4, 'Mar', March.four) }"
            >
              {{
                (this.March.four =
                  IsDataInclude(4, "Mar") != 0
                    ? TypeOption(4, "Mar")
                    : this.sunOption == Option(4, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '03', March.five)"
              v-bind:style="{ background: activeColor(5, 'Mar', March.five) }"
            >
              {{
                (this.March.five =
                  IsDataInclude(5, "Mar") != 0
                    ? TypeOption(5, "Mar")
                    : this.sunOption == Option(5, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '03', March.six)"
              v-bind:style="{ background: activeColor(6, 'Mar', March.six) }"
            >
              {{
                (this.March.six =
                  IsDataInclude(6, "Mar") != 0
                    ? TypeOption(6, "Mar")
                    : this.sunOption == Option(6, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '03', March.seven)"
              v-bind:style="{ background: activeColor(7, 'Mar', March.seven) }"
            >
              {{
                (this.March.seven =
                  IsDataInclude(7, "Mar") != 0
                    ? TypeOption(7, "Mar")
                    : this.sunOption == Option(7, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '03', March.eight)"
              v-bind:style="{ background: activeColor(8, 'Mar', March.eight) }"
            >
              {{
                (this.March.eight =
                  IsDataInclude(8, "Mar") != 0
                    ? TypeOption(8, "Mar")
                    : this.sunOption == Option(8, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '03', March.nine)"
              v-bind:style="{ background: activeColor(9, 'Mar', March.nine) }"
            >
              {{
                (this.March.nine =
                  IsDataInclude(9, "Mar") != 0
                    ? TypeOption(9, "Mar")
                    : this.sunOption == Option(9, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '03', March.ten)"
              v-bind:style="{ background: activeColor(10, 'Mar', March.ten) }"
            >
              {{
                (this.March.ten =
                  IsDataInclude(10, "Mar") != 0
                    ? TypeOption(10, "Mar")
                    : this.sunOption == Option(10, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '03', March.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Mar', March.eleven),
              }"
            >
              {{
                (this.March.eleven =
                  IsDataInclude(11, "Mar") != 0
                    ? TypeOption(11, "Mar")
                    : this.sunOption == Option(11, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '03', March.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Mar', March.twelve),
              }"
            >
              {{
                (this.March.twelve =
                  IsDataInclude(12, "Mar") != 0
                    ? TypeOption(12, "Mar")
                    : this.sunOption == Option(12, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '03', March.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Mar', March.thirteen),
              }"
            >
              {{
                (this.March.thirteen =
                  IsDataInclude(13, "Mar") != 0
                    ? TypeOption(13, "Mar")
                    : this.sunOption == Option(13, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '03', March.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Mar', March.fourteen),
              }"
            >
              {{
                (this.March.fourteen =
                  IsDataInclude(14, "Mar") != 0
                    ? TypeOption(14, "Mar")
                    : this.sunOption == Option(14, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '03', March.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Mar', March.fifteeen),
              }"
            >
              {{
                (this.March.fifteeen =
                  IsDataInclude(15, "Mar") != 0
                    ? TypeOption(15, "Mar")
                    : this.sunOption == Option(15, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '03', March.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Mar', March.sixteen),
              }"
            >
              {{
                (this.March.sixteen =
                  IsDataInclude(16, "Mar") != 0
                    ? TypeOption(16, "Mar")
                    : this.sunOption == Option(16, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '03', March.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Mar', March.seventeen),
              }"
            >
              {{
                (this.March.seventeen =
                  IsDataInclude(17, "Mar") != 0
                    ? TypeOption(17, "Mar")
                    : this.sunOption == Option(17, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '03', March.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Mar', March.eighteen),
              }"
            >
              {{
                (this.March.eighteen =
                  IsDataInclude(18, "Mar") != 0
                    ? TypeOption(18, "Mar")
                    : this.sunOption == Option(18, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '03', March.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Mar', March.ninteen),
              }"
            >
              {{
                (this.March.ninteen =
                  IsDataInclude(19, "Mar") != 0
                    ? TypeOption(19, "Mar")
                    : this.sunOption == Option(19, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '03', March.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Mar', March.twenty),
              }"
            >
              {{
                (this.March.twenty =
                  IsDataInclude(20, "Mar") != 0
                    ? TypeOption(20, "Mar")
                    : this.sunOption == Option(20, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '03', March.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Mar', March.twentyone),
              }"
            >
              {{
                (this.March.twentyone =
                  IsDataInclude(21, "Mar") != 0
                    ? TypeOption(21, "Mar")
                    : this.sunOption == Option(21, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '03', March.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Mar', March.twentytwo),
              }"
            >
              {{
                (this.March.twentytwo =
                  IsDataInclude(22, "Mar") != 0
                    ? TypeOption(22, "Mar")
                    : this.sunOption == Option(22, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '03', March.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Mar', March.twentythree),
              }"
            >
              {{
                (this.March.twentythree =
                  IsDataInclude(23, "Mar") != 0
                    ? TypeOption(23, "Mar")
                    : this.sunOption == Option(23, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '03', March.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Mar', March.twentyfour),
              }"
            >
              {{
                (this.March.twentyfour =
                  IsDataInclude(24, "Mar") != 0
                    ? TypeOption(24, "Mar")
                    : this.sunOption == Option(24, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '03', March.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Mar', March.twentyfive),
              }"
            >
              {{
                (this.March.twentyfive =
                  IsDataInclude(25, "Mar") != 0
                    ? TypeOption(25, "Mar")
                    : this.sunOption == Option(25, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '03', March.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Mar', March.twentysix),
              }"
            >
              {{
                (this.March.twentysix =
                  IsDataInclude(26, "Mar") != 0
                    ? TypeOption(26, "Mar")
                    : this.sunOption == Option(26, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '03', March.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Mar', March.twentyeight),
              }"
            >
              {{
                (this.March.twentyseven =
                  IsDataInclude(27, "Mar") != 0
                    ? TypeOption(27, "Mar")
                    : this.sunOption == Option(27, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '03', March.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Mar', March.twentyeight),
              }"
            >
              {{
                (this.March.twentyeight =
                  IsDataInclude(28, "Mar") != 0
                    ? TypeOption(28, "Mar")
                    : this.sunOption == Option(28, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '03', March.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Mar', March.twentynine),
              }"
            >
              {{
                (this.March.twentynine =
                  IsDataInclude(29, "Mar") != 0
                    ? TypeOption(29, "Mar")
                    : this.sunOption == Option(29, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '03', March.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Mar', March.thirty),
              }"
            >
              {{
                (this.March.thirty =
                  IsDataInclude(30, "Mar") != 0
                    ? TypeOption(30, "Mar")
                    : this.sunOption == Option(30, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '03', March.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'Mar', March.thirtyone),
              }"
            >
              {{
                (this.March.thirtyone =
                  IsDataInclude(31, "Mar") != 0
                    ? TypeOption(31, "Mar")
                    : this.sunOption == Option(31, "Mar")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
          <tr>
            <th scope="row" class="customth">Apr</th>
            <td
              class="txt"
              @click="NewHoliday('01', '04', April.one)"
              v-bind:style="{ background: activeColor(1, 'Apr', April.one) }"
            >
              {{
                (this.April.one =
                  IsDataInclude(1, "Apr") != 0
                    ? TypeOption(1, "Apr")
                    : this.sunOption == Option(1, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '04', April.two)"
              v-bind:style="{ background: activeColor(2, 'Apr', April.two) }"
            >
              {{
                (this.April.two =
                  IsDataInclude(2, "Apr") != 0
                    ? TypeOption(2, "Apr")
                    : this.sunOption == Option(2, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '04', April.three)"
              v-bind:style="{ background: activeColor(3, 'Apr', April.three) }"
            >
              {{
                (this.April.three =
                  IsDataInclude(3, "Apr") != 0
                    ? TypeOption(3, "Apr")
                    : this.sunOption == Option(3, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '04', April.four)"
              v-bind:style="{ background: activeColor(4, 'Apr', April.four) }"
            >
              {{
                (this.April.four =
                  IsDataInclude(4, "Apr") != 0
                    ? TypeOption(4, "Apr")
                    : this.sunOption == Option(4, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '04', April.five)"
              v-bind:style="{ background: activeColor(5, 'Apr', April.five) }"
            >
              {{
                (this.April.five =
                  IsDataInclude(5, "Apr") != 0
                    ? TypeOption(5, "Apr")
                    : this.sunOption == Option(5, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '04', April.six)"
              v-bind:style="{ background: activeColor(6, 'Apr', April.six) }"
            >
              {{
                (this.April.six =
                  IsDataInclude(6, "Apr") != 0
                    ? TypeOption(6, "Apr")
                    : this.sunOption == Option(6, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '04', April.seven)"
              v-bind:style="{ background: activeColor(7, 'Apr', April.seven) }"
            >
              {{
                (this.April.seven =
                  IsDataInclude(7, "Apr") != 0
                    ? TypeOption(7, "Apr")
                    : this.sunOption == Option(7, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '04', April.eight)"
              v-bind:style="{ background: activeColor(8, 'Apr', April.eight) }"
            >
              {{
                (this.April.eight =
                  IsDataInclude(8, "Apr") != 0
                    ? TypeOption(8, "Apr")
                    : this.sunOption == Option(8, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '04', April.nine)"
              v-bind:style="{ background: activeColor(9, 'Apr', April.nine) }"
            >
              {{
                (this.April.nine =
                  IsDataInclude(9, "Apr") != 0
                    ? TypeOption(9, "Apr")
                    : this.sunOption == Option(9, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '04', April.ten)"
              v-bind:style="{ background: activeColor(10, 'Apr', April.ten) }"
            >
              {{
                (this.April.ten =
                  IsDataInclude(10, "Apr") != 0
                    ? TypeOption(10, "Apr")
                    : this.sunOption == Option(10, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '04', April.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Apr', April.eleven),
              }"
            >
              {{
                (this.April.eleven =
                  IsDataInclude(11, "Apr") != 0
                    ? TypeOption(11, "Apr")
                    : this.sunOption == Option(11, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '04', April.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Apr', April.twelve),
              }"
            >
              {{
                (this.April.twelve =
                  IsDataInclude(12, "Apr") != 0
                    ? TypeOption(12, "Apr")
                    : this.sunOption == Option(12, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '04', April.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Apr', April.thirteen),
              }"
            >
              {{
                (this.April.thirteen =
                  IsDataInclude(13, "Apr") != 0
                    ? TypeOption(13, "Apr")
                    : this.sunOption == Option(13, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '04', April.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Apr', April.fourteen),
              }"
            >
              {{
                (this.April.fourteen =
                  IsDataInclude(14, "Apr") != 0
                    ? TypeOption(14, "Apr")
                    : this.sunOption == Option(14, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '04', April.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Apr', April.fifteeen),
              }"
            >
              {{
                (this.April.fifteeen =
                  IsDataInclude(15, "Apr") != 0
                    ? TypeOption(15, "Apr")
                    : this.sunOption == Option(15, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '04', April.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Apr', April.sixteen),
              }"
            >
              {{
                (this.April.sixteen =
                  IsDataInclude(16, "Apr") != 0
                    ? TypeOption(16, "Apr")
                    : this.sunOption == Option(16, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '04', April.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Apr', April.seventeen),
              }"
            >
              {{
                (this.April.seventeen =
                  IsDataInclude(17, "Apr") != 0
                    ? TypeOption(17, "Apr")
                    : this.sunOption == Option(17, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '04', April.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Apr', April.eighteen),
              }"
            >
              {{
                (this.April.eighteen =
                  IsDataInclude(18, "Apr") != 0
                    ? TypeOption(18, "Apr")
                    : this.sunOption == Option(18, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '04', April.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Apr', April.ninteen),
              }"
            >
              {{
                (this.April.ninteen =
                  IsDataInclude(19, "Apr") != 0
                    ? TypeOption(19, "Apr")
                    : this.sunOption == Option(19, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '04', April.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Apr', April.twenty),
              }"
            >
              {{
                (this.April.twenty =
                  IsDataInclude(20, "Apr") != 0
                    ? TypeOption(20, "Apr")
                    : this.sunOption == Option(20, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '04', April.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Apr', April.twentyone),
              }"
            >
              {{
                (this.April.twentyone =
                  IsDataInclude(21, "Apr") != 0
                    ? TypeOption(21, "Apr")
                    : this.sunOption == Option(21, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '04', April.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Apr', April.twentytwo),
              }"
            >
              {{
                (this.April.twentytwo =
                  IsDataInclude(22, "Apr") != 0
                    ? TypeOption(22, "Apr")
                    : this.sunOption == Option(22, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '04', April.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Apr', April.twentythree),
              }"
            >
              {{
                (this.April.twentythree =
                  IsDataInclude(23, "Apr") != 0
                    ? TypeOption(23, "Apr")
                    : this.sunOption == Option(23, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '04', April.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Apr', April.twentyfour),
              }"
            >
              {{
                (this.April.twentyfour =
                  IsDataInclude(24, "Apr") != 0
                    ? TypeOption(24, "Apr")
                    : this.sunOption == Option(24, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '04', April.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Apr', April.twentyfive),
              }"
            >
              {{
                (this.April.twentyfive =
                  IsDataInclude(25, "Apr") != 0
                    ? TypeOption(25, "Apr")
                    : this.sunOption == Option(25, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '04', April.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Apr', April.twentysix),
              }"
            >
              {{
                (this.April.twentysix =
                  IsDataInclude(26, "Apr") != 0
                    ? TypeOption(26, "Apr")
                    : this.sunOption == Option(26, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '04', April.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Apr', April.twentyseven),
              }"
            >
              {{
                (this.April.twentyseven =
                  IsDataInclude(27, "Apr") != 0
                    ? TypeOption(27, "Apr")
                    : this.sunOption == Option(27, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '04', April.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Apr', April.twentyeight),
              }"
            >
              {{
                (this.April.twentyeight =
                  IsDataInclude(28, "Apr") != 0
                    ? TypeOption(28, "Apr")
                    : this.sunOption == Option(28, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '04', April.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Apr', April.twentynine),
              }"
            >
              {{
                (this.April.twentynine =
                  IsDataInclude(29, "Apr") != 0
                    ? TypeOption(29, "Apr")
                    : this.sunOption == Option(29, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '04', April.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Apr', April.thirty),
              }"
            >
              {{
                (this.April.thirty =
                  IsDataInclude(30, "Apr") != 0
                    ? TypeOption(30, "Apr")
                    : this.sunOption == Option(30, "Apr")
                    ? "H"
                    : "--")
              }}
            </td>
            <td class="txt" readonly style="background-color: #e8e9ec"></td>
          </tr>
          <tr>
            <th scope="row" class="customth">May</th>
            <td
              class="txt"
              @click="NewHoliday('01', '05', Mayy.one)"
              v-bind:style="{ background: activeColor(1, 'May', Mayy.one) }"
            >
              {{
                (this.Mayy.one =
                  IsDataInclude(1, "May") != 0
                    ? TypeOption(1, "May")
                    : this.sunOption == Option(1, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '05', Mayy.two)"
              v-bind:style="{ background: activeColor(2, 'May', Mayy.two) }"
            >
              {{
                (this.Mayy.two =
                  IsDataInclude(2, "May") != 0
                    ? TypeOption(2, "May")
                    : this.sunOption == Option(2, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '05', Mayy.three)"
              v-bind:style="{ background: activeColor(3, 'May', Mayy.three) }"
            >
              {{
                (this.Mayy.three =
                  IsDataInclude(3, "May") != 0
                    ? TypeOption(3, "May")
                    : this.sunOption == Option(3, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '05', Mayy.four)"
              v-bind:style="{ background: activeColor(4, 'May', Mayy.four) }"
            >
              {{
                (this.Mayy.four =
                  IsDataInclude(4, "May") != 0
                    ? TypeOption(4, "May")
                    : this.sunOption == Option(4, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '05', Mayy.five)"
              v-bind:style="{ background: activeColor(5, 'May', Mayy.five) }"
            >
              {{
                (this.Mayy.five =
                  IsDataInclude(5, "May") != 0
                    ? TypeOption(5, "May")
                    : this.sunOption == Option(5, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '05', Mayy.six)"
              v-bind:style="{ background: activeColor(6, 'May', Mayy.six) }"
            >
              {{
                (this.Mayy.six =
                  IsDataInclude(6, "May") != 0
                    ? TypeOption(6, "May")
                    : this.sunOption == Option(6, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '05', Mayy.seven)"
              v-bind:style="{ background: activeColor(7, 'May', Mayy.seven) }"
            >
              {{
                (this.Mayy.seven =
                  IsDataInclude(7, "May") != 0
                    ? TypeOption(7, "May")
                    : this.sunOption == Option(7, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '05', Mayy.eight)"
              v-bind:style="{ background: activeColor(8, 'May', Mayy.eight) }"
            >
              {{
                (this.Mayy.eight =
                  IsDataInclude(8, "May") != 0
                    ? TypeOption(8, "May")
                    : this.sunOption == Option(8, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '05', Mayy.nine)"
              v-bind:style="{ background: activeColor(9, 'May', Mayy.nine) }"
            >
              {{
                (this.Mayy.nine =
                  IsDataInclude(9, "May") != 0
                    ? TypeOption(9, "May")
                    : this.sunOption == Option(9, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '05', Mayy.ten)"
              v-bind:style="{ background: activeColor(10, 'May', Mayy.ten) }"
            >
              {{
                (this.Mayy.ten =
                  IsDataInclude(10, "May") != 0
                    ? TypeOption(10, "May")
                    : this.sunOption == Option(10, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '05', Mayy.eleven)"
              v-bind:style="{ background: activeColor(11, 'May', Mayy.eleven) }"
            >
              {{
                (this.Mayy.eleven =
                  IsDataInclude(11, "May") != 0
                    ? TypeOption(11, "May")
                    : this.sunOption == Option(11, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '05', Mayy.twelve)"
              v-bind:style="{ background: activeColor(12, 'May', Mayy.twelve) }"
            >
              {{
                (this.Mayy.twelve =
                  IsDataInclude(12, "May") != 0
                    ? TypeOption(12, "May")
                    : this.sunOption == Option(12, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '05', Mayy.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'May', Mayy.thirteen),
              }"
            >
              {{
                (this.Mayy.thirteen =
                  IsDataInclude(13, "May") != 0
                    ? TypeOption(13, "May")
                    : this.sunOption == Option(13, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '05', Mayy.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'May', Mayy.fourteen),
              }"
            >
              {{
                (this.Mayy.fourteen =
                  IsDataInclude(14, "May") != 0
                    ? TypeOption(14, "May")
                    : this.sunOption == Option(14, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '05', Mayy.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'May', Mayy.fifteeen),
              }"
            >
              {{
                (this.Mayy.fifteeen =
                  IsDataInclude(15, "May") != 0
                    ? TypeOption(15, "May")
                    : this.sunOption == Option(15, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '05', Mayy.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'May', Mayy.sixteen),
              }"
            >
              {{
                (this.Mayy.sixteen =
                  IsDataInclude(16, "May") != 0
                    ? TypeOption(16, "May")
                    : this.sunOption == Option(16, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '05', Mayy.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'May', Mayy.seventeen),
              }"
            >
              {{
                (this.Mayy.seventeen =
                  IsDataInclude(17, "May") != 0
                    ? TypeOption(17, "May")
                    : this.sunOption == Option(17, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '05', Mayy.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'May', Mayy.eighteen),
              }"
            >
              {{
                (this.Mayy.eighteen =
                  IsDataInclude(18, "May") != 0
                    ? TypeOption(18, "May")
                    : this.sunOption == Option(18, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '05', Mayy.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'May', Mayy.ninteen),
              }"
            >
              {{
                (this.Mayy.ninteen =
                  IsDataInclude(19, "May") != 0
                    ? TypeOption(19, "May")
                    : this.sunOption == Option(19, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '05', Mayy.twenty)"
              v-bind:style="{ background: activeColor(20, 'May', Mayy.twenty) }"
            >
              {{
                (this.Mayy.twenty =
                  IsDataInclude(20, "May") != 0
                    ? TypeOption(20, "May")
                    : this.sunOption == Option(20, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '05', Mayy.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'May', Mayy.twentyone),
              }"
            >
              {{
                (this.Mayy.twentyone =
                  IsDataInclude(21, "May") != 0
                    ? TypeOption(21, "May")
                    : this.sunOption == Option(21, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '05', Mayy.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'May', Mayy.twentytwo),
              }"
            >
              {{
                (this.Mayy.twentytwo =
                  IsDataInclude(22, "May") != 0
                    ? TypeOption(22, "May")
                    : this.sunOption == Option(22, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '05', Mayy.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'May', Mayy.twentythree),
              }"
            >
              {{
                (this.Mayy.twentythree =
                  IsDataInclude(23, "May") != 0
                    ? TypeOption(23, "May")
                    : this.sunOption == Option(23, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '05', Mayy.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'May', Mayy.twentyfour),
              }"
            >
              {{
                (this.Mayy.twentyfour =
                  IsDataInclude(24, "May") != 0
                    ? TypeOption(24, "May")
                    : this.sunOption == Option(24, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '05', Mayy.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'May', Mayy.twentyfive),
              }"
            >
              {{
                (this.Mayy.twentyfive =
                  IsDataInclude(25, "May") != 0
                    ? TypeOption(25, "May")
                    : this.sunOption == Option(25, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '05', Mayy.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'May', Mayy.twentysix),
              }"
            >
              {{
                (this.Mayy.twentysix =
                  IsDataInclude(26, "May") != 0
                    ? TypeOption(26, "May")
                    : this.sunOption == Option(26, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '05', Mayy.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'May', Mayy.twentyseven),
              }"
            >
              {{
                (this.Mayy.twentyseven =
                  IsDataInclude(27, "May") != 0
                    ? TypeOption(27, "May")
                    : this.sunOption == Option(27, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '05', Mayy.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'May', Mayy.twentyeight),
              }"
            >
              {{
                (this.Mayy.twentyeight =
                  IsDataInclude(28, "May") != 0
                    ? TypeOption(28, "May")
                    : this.sunOption == Option(28, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '05', Mayy.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'May', Mayy.twentynine),
              }"
            >
              {{
                (this.Mayy.twentynine =
                  IsDataInclude(29, "May") != 0
                    ? TypeOption(29, "May")
                    : this.sunOption == Option(29, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '05', Mayy.thirty)"
              v-bind:style="{ background: activeColor(30, 'May', Mayy.thirty) }"
            >
              {{
                (this.Mayy.thirty =
                  IsDataInclude(30, "May") != 0
                    ? TypeOption(30, "May")
                    : this.sunOption == Option(30, "May")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '05', Mayy.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'May', Mayy.thirtyone),
              }"
            >
              {{
                (this.Mayy.thirtyone =
                  IsDataInclude(31, "May") != 0
                    ? TypeOption(31, "May")
                    : this.sunOption == Option(31, "May")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
          <tr>
            <th scope="row" class="customth">Jun</th>
            <td
              class="txt"
              @click="NewHoliday('01', '06', June.one)"
              v-bind:style="{ background: activeColor(1, 'Jun', June.one) }"
            >
              {{
                (this.June.one =
                  IsDataInclude(1, "Jun") != 0
                    ? TypeOption(1, "Jun")
                    : this.sunOption == Option(1, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '06', June.two)"
              v-bind:style="{ background: activeColor(2, 'Jun', June.two) }"
            >
              {{
                (this.June.two =
                  IsDataInclude(2, "Jun") != 0
                    ? TypeOption(2, "Jun")
                    : this.sunOption == Option(2, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '06', June.three)"
              v-bind:style="{ background: activeColor(3, 'Jun', June.three) }"
            >
              {{
                (this.June.three =
                  IsDataInclude(3, "Jun") != 0
                    ? TypeOption(3, "Jun")
                    : this.sunOption == Option(3, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '06', June.four)"
              v-bind:style="{ background: activeColor(4, 'Jun', June.four) }"
            >
              {{
                (this.June.four =
                  IsDataInclude(4, "Jun") != 0
                    ? TypeOption(4, "Jun")
                    : this.sunOption == Option(4, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '06', June.five)"
              v-bind:style="{ background: activeColor(5, 'Jun', June.five) }"
            >
              {{
                (this.June.five =
                  IsDataInclude(5, "Jun") != 0
                    ? TypeOption(5, "Jun")
                    : this.sunOption == Option(5, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '06', June.six)"
              v-bind:style="{ background: activeColor(6, 'Jun', June.six) }"
            >
              {{
                (this.June.six =
                  IsDataInclude(6, "Jun") != 0
                    ? TypeOption(6, "Jun")
                    : this.sunOption == Option(6, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '06', June.seven)"
              v-bind:style="{ background: activeColor(7, 'Jun', June.seven) }"
            >
              {{
                (this.June.seven =
                  IsDataInclude(7, "Jun") != 0
                    ? TypeOption(7, "Jun")
                    : this.sunOption == Option(7, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '06', June.eight)"
              v-bind:style="{ background: activeColor(8, 'Jun', June.eight) }"
            >
              {{
                (this.June.eight =
                  IsDataInclude(8, "Jun") != 0
                    ? TypeOption(8, "Jun")
                    : this.sunOption == Option(8, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '06', June.nine)"
              v-bind:style="{ background: activeColor(9, 'Jun', June.nine) }"
            >
              {{
                (this.June.nine =
                  IsDataInclude(9, "Jun") != 0
                    ? TypeOption(9, "Jun")
                    : this.sunOption == Option(9, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '06', June.ten)"
              v-bind:style="{ background: activeColor(10, 'Jun', June.ten) }"
            >
              {{
                (this.June.ten =
                  IsDataInclude(10, "Jun") != 0
                    ? TypeOption(10, "Jun")
                    : this.sunOption == Option(10, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '06', June.eleven)"
              v-bind:style="{ background: activeColor(11, 'Jun', June.eleven) }"
            >
              {{
                (this.June.eleven =
                  IsDataInclude(11, "Jun") != 0
                    ? TypeOption(11, "Jun")
                    : this.sunOption == Option(11, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '06', June.twelve)"
              v-bind:style="{ background: activeColor(12, 'Jun', June.twelve) }"
            >
              {{
                (this.June.twelve =
                  IsDataInclude(12, "Jun") != 0
                    ? TypeOption(12, "Jun")
                    : this.sunOption == Option(12, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '06', June.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Jun', June.thirteen),
              }"
            >
              {{
                (this.June.thirteen =
                  IsDataInclude(13, "Jun") != 0
                    ? TypeOption(13, "Jun")
                    : this.sunOption == Option(13, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '06', June.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Jun', June.fourteen),
              }"
            >
              {{
                (this.June.fourteen =
                  IsDataInclude(14, "Jun") != 0
                    ? TypeOption(14, "Jun")
                    : this.sunOption == Option(14, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '06', June.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Jun', June.fifteeen),
              }"
            >
              {{
                (this.June.fifteeen =
                  IsDataInclude(15, "Jun") != 0
                    ? TypeOption(15, "Jun")
                    : this.sunOption == Option(15, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '06', June.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Jun', June.sixteen),
              }"
            >
              {{
                (this.June.sixteen =
                  IsDataInclude(16, "Jun") != 0
                    ? TypeOption(16, "Jun")
                    : this.sunOption == Option(16, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '06', June.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Jun', June.seventeen),
              }"
            >
              {{
                (this.June.seventeen =
                  IsDataInclude(17, "Jun") != 0
                    ? TypeOption(17, "Jun")
                    : this.sunOption == Option(17, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '06', June.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Jun', June.eighteen),
              }"
            >
              {{
                (this.June.eighteen =
                  IsDataInclude(18, "Jun") != 0
                    ? TypeOption(18, "Jun")
                    : this.sunOption == Option(18, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '06', June.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Jun', June.ninteen),
              }"
            >
              {{
                (this.June.ninteen =
                  IsDataInclude(19, "Jun") != 0
                    ? TypeOption(19, "Jun")
                    : this.sunOption == Option(19, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '06', June.twenty)"
              v-bind:style="{ background: activeColor(20, 'Jun', June.twenty) }"
            >
              {{
                (this.June.twenty =
                  IsDataInclude(20, "Jun") != 0
                    ? TypeOption(20, "Jun")
                    : this.sunOption == Option(20, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '06', June.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Jun', June.twentyone),
              }"
            >
              {{
                (this.June.twentyone =
                  IsDataInclude(21, "Jun") != 0
                    ? TypeOption(21, "Jun")
                    : this.sunOption == Option(21, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '06', June.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Jun', June.twentytwo),
              }"
            >
              {{
                (this.June.twentytwo =
                  IsDataInclude(22, "Jun") != 0
                    ? TypeOption(22, "Jun")
                    : this.sunOption == Option(22, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '06', June.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Jun', June.twentythree),
              }"
            >
              {{
                (this.June.twentythree =
                  IsDataInclude(23, "Jun") != 0
                    ? TypeOption(23, "Jun")
                    : this.sunOption == Option(23, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '06', June.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Jun', June.twentyfour),
              }"
            >
              {{
                (this.June.twentyfour =
                  IsDataInclude(24, "Jun") != 0
                    ? TypeOption(24, "Jun")
                    : this.sunOption == Option(24, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '06', June.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Jun', June.twentyfive),
              }"
            >
              {{
                (this.June.twentyfive =
                  IsDataInclude(25, "Jun") != 0
                    ? TypeOption(25, "Jun")
                    : this.sunOption == Option(25, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '06', June.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Jun', June.twentysix),
              }"
            >
              {{
                (this.June.twentysix =
                  IsDataInclude(26, "Jun") != 0
                    ? TypeOption(26, "Jun")
                    : this.sunOption == Option(26, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '06', June.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Jun', June.twentyseven),
              }"
            >
              {{
                (this.June.twentyseven =
                  IsDataInclude(27, "Jun") != 0
                    ? TypeOption(27, "Jun")
                    : this.sunOption == Option(27, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '06', June.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Jun', June.twentyeight),
              }"
            >
              {{
                (this.June.twentyeight =
                  IsDataInclude(28, "Jun") != 0
                    ? TypeOption(28, "Jun")
                    : this.sunOption == Option(28, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '06', June.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Jun', June.twentynine),
              }"
            >
              {{
                (this.June.twentynine =
                  IsDataInclude(29, "Jun") != 0
                    ? TypeOption(29, "Jun")
                    : this.sunOption == Option(29, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '06', June.thirty)"
              v-bind:style="{ background: activeColor(30, 'Jun', June.thirty) }"
            >
              {{
                (this.June.thirty =
                  IsDataInclude(30, "Jun") != 0
                    ? TypeOption(30, "Jun")
                    : this.sunOption == Option(30, "Jun")
                    ? "H"
                    : "--")
              }}
            </td>
            <td class="txt" readonly style="background-color: #e8e9ec"></td>
          </tr>
          <tr>
            <th scope="row" class="customth">Jul</th>
            <td
              class="txt"
              @click="NewHoliday('01', '07', July.one)"
              v-bind:style="{ background: activeColor(1, 'Jul', July.one) }"
            >
              {{
                (this.July.one =
                  IsDataInclude(1, "Jul") != 0
                    ? TypeOption(1, "Jul")
                    : this.sunOption == Option(1, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '07', July.two)"
              v-bind:style="{ background: activeColor(2, 'Jul', July.two) }"
            >
              {{
                (this.July.two =
                  IsDataInclude(2, "Jul") != 0
                    ? TypeOption(2, "Jul")
                    : this.sunOption == Option(2, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '07', July.three)"
              v-bind:style="{ background: activeColor(3, 'Jul', July.three) }"
            >
              {{
                (this.July.three =
                  IsDataInclude(3, "Jul") != 0
                    ? TypeOption(3, "Jul")
                    : this.sunOption == Option(3, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '07', July.four)"
              v-bind:style="{ background: activeColor(4, 'Jul', July.four) }"
            >
              {{
                (this.July.four =
                  IsDataInclude(4, "Jul") != 0
                    ? TypeOption(4, "Jul")
                    : this.sunOption == Option(4, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '07', July.five)"
              v-bind:style="{ background: activeColor(5, 'Jul', July.five) }"
            >
              {{
                (this.July.five =
                  IsDataInclude(5, "Jul") != 0
                    ? TypeOption(5, "Jul")
                    : this.sunOption == Option(5, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '07', July.six)"
              v-bind:style="{ background: activeColor(6, 'Jul', July.six) }"
            >
              {{
                (this.July.six =
                  IsDataInclude(6, "Jul") != 0
                    ? TypeOption(6, "Jul")
                    : this.sunOption == Option(6, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '07', July.seven)"
              v-bind:style="{ background: activeColor(7, 'Jul', July.seven) }"
            >
              {{
                (this.July.seven =
                  IsDataInclude(7, "Jul") != 0
                    ? TypeOption(7, "Jul")
                    : this.sunOption == Option(7, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '07', July.eight)"
              v-bind:style="{ background: activeColor(8, 'Jul', July.eight) }"
            >
              {{
                (this.July.eight =
                  IsDataInclude(8, "Jul") != 0
                    ? TypeOption(8, "Jul")
                    : this.sunOption == Option(8, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '07', July.nine)"
              v-bind:style="{ background: activeColor(9, 'Jul', July.nine) }"
            >
              {{
                (this.July.nine =
                  IsDataInclude(9, "Jul") != 0
                    ? TypeOption(9, "Jul")
                    : this.sunOption == Option(9, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '07', July.ten)"
              v-bind:style="{ background: activeColor(10, 'Jul', July.ten) }"
            >
              {{
                (this.July.ten =
                  IsDataInclude(10, "Jul") != 0
                    ? TypeOption(10, "Jul")
                    : this.sunOption == Option(10, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '07', July.eleven)"
              v-bind:style="{ background: activeColor(11, 'Jul', July.eleven) }"
            >
              {{
                (this.July.eleven =
                  IsDataInclude(11, "Jul") != 0
                    ? TypeOption(11, "Jul")
                    : this.sunOption == Option(11, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '07', July.twelve)"
              v-bind:style="{ background: activeColor(12, 'Jul', July.twelve) }"
            >
              {{
                (this.July.twelve =
                  IsDataInclude(12, "Jul") != 0
                    ? TypeOption(12, "Jul")
                    : this.sunOption == Option(12, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '07', July.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Jul', July.thirteen),
              }"
            >
              {{
                (this.July.thirteen =
                  IsDataInclude(13, "Jul") != 0
                    ? TypeOption(13, "Jul")
                    : this.sunOption == Option(13, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '07', July.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Jul', July.fourteen),
              }"
            >
              {{
                (this.July.fourteen =
                  IsDataInclude(14, "Jul") != 0
                    ? TypeOption(14, "Jul")
                    : this.sunOption == Option(14, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '07', July.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Jul', July.fifteeen),
              }"
            >
              {{
                (this.July.fifteeen =
                  IsDataInclude(15, "Jul") != 0
                    ? TypeOption(15, "Jul")
                    : this.sunOption == Option(15, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '07', July.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Jul', July.sixteen),
              }"
            >
              {{
                (this.July.sixteen =
                  IsDataInclude(16, "Jul") != 0
                    ? TypeOption(16, "Jul")
                    : this.sunOption == Option(16, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '07', July.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Jul', July.seventeen),
              }"
            >
              {{
                (this.July.seventeen =
                  IsDataInclude(17, "Jul") != 0
                    ? TypeOption(17, "Jul")
                    : this.sunOption == Option(17, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '07', July.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Jul', July.eighteen),
              }"
            >
              {{
                (this.July.eighteen =
                  IsDataInclude(18, "Jul") != 0
                    ? TypeOption(18, "Jul")
                    : this.sunOption == Option(18, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '07', July.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Jul', July.ninteen),
              }"
            >
              {{
                (this.July.ninteen =
                  IsDataInclude(19, "Jul") != 0
                    ? TypeOption(19, "Jul")
                    : this.sunOption == Option(19, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '07', July.twenty)"
              v-bind:style="{ background: activeColor(20, 'Jul', July.twenty) }"
            >
              {{
                (this.July.twenty =
                  IsDataInclude(20, "Jul") != 0
                    ? TypeOption(20, "Jul")
                    : this.sunOption == Option(20, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '07', July.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Jul', July.twentyone),
              }"
            >
              {{
                (this.July.twentyone =
                  IsDataInclude(21, "Jul") != 0
                    ? TypeOption(21, "Jul")
                    : this.sunOption == Option(21, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '07', July.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Jul', July.twentytwo),
              }"
            >
              {{
                (this.July.twentytwo =
                  IsDataInclude(22, "Jul") != 0
                    ? TypeOption(22, "Jul")
                    : this.sunOption == Option(22, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '07', July.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Jul', July.twentythree),
              }"
            >
              {{
                (this.July.twentythree =
                  IsDataInclude(23, "Jul") != 0
                    ? TypeOption(23, "Jul")
                    : this.sunOption == Option(23, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '07', July.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Jul', July.twentyfour),
              }"
            >
              {{
                (this.July.twentyfour =
                  IsDataInclude(24, "Jul") != 0
                    ? TypeOption(24, "Jul")
                    : this.sunOption == Option(24, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '07', July.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Jul', July.twentyfive),
              }"
            >
              {{
                (this.July.twentyfive =
                  IsDataInclude(25, "Jul") != 0
                    ? TypeOption(25, "Jul")
                    : this.sunOption == Option(25, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '07', July.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Jul', July.twentysix),
              }"
            >
              {{
                (this.July.twentysix =
                  IsDataInclude(26, "Jul") != 0
                    ? TypeOption(26, "Jul")
                    : this.sunOption == Option(26, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '07', July.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Jul', July.twentyseven),
              }"
            >
              {{
                (this.July.twentyseven =
                  IsDataInclude(27, "Jul") != 0
                    ? TypeOption(27, "Jul")
                    : this.sunOption == Option(27, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '07', July.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Jul', July.twentyeight),
              }"
            >
              {{
                (this.July.twentyeight =
                  IsDataInclude(28, "Jul") != 0
                    ? TypeOption(28, "Jul")
                    : this.sunOption == Option(28, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '07', July.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Jul', July.twentynine),
              }"
            >
              {{
                (this.July.twentynine =
                  IsDataInclude(29, "Jul") != 0
                    ? TypeOption(29, "Jul")
                    : this.sunOption == Option(29, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '07', July.thirty)"
              v-bind:style="{ background: activeColor(30, 'Jul', July.thirty) }"
            >
              {{
                (this.July.thirty =
                  IsDataInclude(30, "Jul") != 0
                    ? TypeOption(30, "Jul")
                    : this.sunOption == Option(30, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '07', July.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'Jul', July.thirtyone),
              }"
            >
              {{
                (this.July.thirtyone =
                  IsDataInclude(31, "Jul") != 0
                    ? TypeOption(31, "Jul")
                    : this.sunOption == Option(31, "Jul")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
          <tr>
            <th scope="row" class="customth">Aug</th>
            <td
              class="txt"
              @click="NewHoliday('01', '08', August.one)"
              v-bind:style="{ background: activeColor(1, 'Aug', August.one) }"
            >
              {{
                (this.August.one =
                  IsDataInclude(1, "Aug") != 0
                    ? TypeOption(1, "Aug")
                    : this.sunOption == Option(1, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '08', August.two)"
              v-bind:style="{ background: activeColor(2, 'Aug', August.two) }"
            >
              {{
                (this.August.two =
                  IsDataInclude(2, "Aug") != 0
                    ? TypeOption(2, "Aug")
                    : this.sunOption == Option(2, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '08', August.three)"
              v-bind:style="{ background: activeColor(3, 'Aug', August.three) }"
            >
              {{
                (this.August.three =
                  IsDataInclude(3, "Aug") != 0
                    ? TypeOption(3, "Aug")
                    : this.sunOption == Option(3, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '08', August.four)"
              v-bind:style="{ background: activeColor(4, 'Aug', August.four) }"
            >
              {{
                (this.August.four =
                  IsDataInclude(4, "Aug") != 0
                    ? TypeOption(4, "Aug")
                    : this.sunOption == Option(4, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '08', August.five)"
              v-bind:style="{ background: activeColor(5, 'Aug', August.five) }"
            >
              {{
                (this.August.five =
                  IsDataInclude(5, "Aug") != 0
                    ? TypeOption(5, "Aug")
                    : this.sunOption == Option(5, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '08', August.six)"
              v-bind:style="{ background: activeColor(6, 'Aug', August.six) }"
            >
              {{
                (this.August.six =
                  IsDataInclude(6, "Aug") != 0
                    ? TypeOption(6, "Aug")
                    : this.sunOption == Option(6, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '08', August.seven)"
              v-bind:style="{ background: activeColor(7, 'Aug', August.seven) }"
            >
              {{
                (this.August.seven =
                  IsDataInclude(7, "Aug") != 0
                    ? TypeOption(7, "Aug")
                    : this.sunOption == Option(7, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '08', August.eight)"
              v-bind:style="{ background: activeColor(8, 'Aug', August.eight) }"
            >
              {{
                (this.August.eight =
                  IsDataInclude(8, "Aug") != 0
                    ? TypeOption(8, "Aug")
                    : this.sunOption == Option(8, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '08', August.nine)"
              v-bind:style="{ background: activeColor(9, 'Aug', August.nine) }"
            >
              {{
                (this.August.nine =
                  IsDataInclude(9, "Aug") != 0
                    ? TypeOption(9, "Aug")
                    : this.sunOption == Option(9, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '08', August.ten)"
              v-bind:style="{ background: activeColor(10, 'Aug', August.ten) }"
            >
              {{
                (this.August.ten =
                  IsDataInclude(10, "Aug") != 0
                    ? TypeOption(10, "Aug")
                    : this.sunOption == Option(10, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '08', August.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Aug', August.eleven),
              }"
            >
              {{
                (this.August.eleven =
                  IsDataInclude(11, "Aug") != 0
                    ? TypeOption(11, "Aug")
                    : this.sunOption == Option(11, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '08', August.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Aug', August.twelve),
              }"
            >
              {{
                (this.August.twelve =
                  IsDataInclude(12, "Aug") != 0
                    ? TypeOption(12, "Aug")
                    : this.sunOption == Option(12, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '08', August.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Aug', August.thirteen),
              }"
            >
              {{
                (this.August.thirteen =
                  IsDataInclude(13, "Aug") != 0
                    ? TypeOption(13, "Aug")
                    : this.sunOption == Option(13, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '08', August.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Aug', August.fourteen),
              }"
            >
              {{
                (this.August.fourteen =
                  IsDataInclude(14, "Aug") != 0
                    ? TypeOption(14, "Aug")
                    : this.sunOption == Option(14, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '08', August.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Aug', August.fifteeen),
              }"
            >
              {{
                (this.August.fifteeen =
                  IsDataInclude(15, "Aug") != 0
                    ? TypeOption(15, "Aug")
                    : this.sunOption == Option(15, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '08', August.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Aug', August.sixteen),
              }"
            >
              {{
                (this.August.sixteen =
                  IsDataInclude(16, "Aug") != 0
                    ? TypeOption(16, "Aug")
                    : this.sunOption == Option(16, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '08', August.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Aug', August.seventeen),
              }"
            >
              {{
                (this.August.seventeen =
                  IsDataInclude(17, "Aug") != 0
                    ? TypeOption(17, "Aug")
                    : this.sunOption == Option(17, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '08', August.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Aug', August.eighteen),
              }"
            >
              {{
                (this.August.eighteen =
                  IsDataInclude(18, "Aug") != 0
                    ? TypeOption(18, "Aug")
                    : this.sunOption == Option(18, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '08', August.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Aug', August.ninteen),
              }"
            >
              {{
                (this.August.ninteen =
                  IsDataInclude(19, "Aug") != 0
                    ? TypeOption(19, "Aug")
                    : this.sunOption == Option(19, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '08', August.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Aug', August.twenty),
              }"
            >
              {{
                (this.August.twenty =
                  IsDataInclude(20, "Aug") != 0
                    ? TypeOption(20, "Aug")
                    : this.sunOption == Option(20, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '08', August.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Aug', August.twentyone),
              }"
            >
              {{
                (this.August.twentyone =
                  IsDataInclude(21, "Aug") != 0
                    ? TypeOption(21, "Aug")
                    : this.sunOption == Option(21, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '08', August.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Aug', August.twentytwo),
              }"
            >
              {{
                (this.August.twentytwo =
                  IsDataInclude(22, "Aug") != 0
                    ? TypeOption(22, "Aug")
                    : this.sunOption == Option(22, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '08', August.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Aug', August.twentythree),
              }"
            >
              {{
                (this.August.twentythree =
                  IsDataInclude(23, "Aug") != 0
                    ? TypeOption(23, "Aug")
                    : this.sunOption == Option(23, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '08', August.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Aug', August.twentyfour),
              }"
            >
              {{
                (this.August.twentyfour =
                  IsDataInclude(24, "Aug") != 0
                    ? TypeOption(24, "Aug")
                    : this.sunOption == Option(24, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '08', August.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Aug', August.twentyfive),
              }"
            >
              {{
                (this.August.twentyfive =
                  IsDataInclude(25, "Aug") != 0
                    ? TypeOption(25, "Aug")
                    : this.sunOption == Option(25, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '08', August.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Aug', August.twentysix),
              }"
            >
              {{
                (this.August.twentysix =
                  IsDataInclude(26, "Aug") != 0
                    ? TypeOption(26, "Aug")
                    : this.sunOption == Option(26, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '08', August.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Aug', August.twentyseven),
              }"
            >
              {{
                (this.August.twentyseven =
                  IsDataInclude(27, "Aug") != 0
                    ? TypeOption(27, "Aug")
                    : this.sunOption == Option(27, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '08', August.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Aug', August.twentyeight),
              }"
            >
              {{
                (this.August.twentyeight =
                  IsDataInclude(28, "Aug") != 0
                    ? TypeOption(28, "Aug")
                    : this.sunOption == Option(28, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '08', August.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Aug', August.twentynine),
              }"
            >
              {{
                (this.August.twentynine =
                  IsDataInclude(29, "Aug") != 0
                    ? TypeOption(29, "Aug")
                    : this.sunOption == Option(29, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '08', August.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Aug', August.thirty),
              }"
            >
              {{
                (this.August.thirty =
                  IsDataInclude(30, "Aug") != 0
                    ? TypeOption(30, "Aug")
                    : this.sunOption == Option(30, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '08', August.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'Aug', August.thirtyone),
              }"
            >
              {{
                (this.August.thirtyone =
                  IsDataInclude(31, "Aug") != 0
                    ? TypeOption(31, "Aug")
                    : this.sunOption == Option(31, "Aug")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
          <tr>
            <th scope="row" class="customth">Sep</th>
            <td
              class="txt"
              @click="NewHoliday('01', '09', Sept.one)"
              v-bind:style="{ background: activeColor(1, 'Sep', Sept.one) }"
            >
              {{
                (this.Sept.one =
                  IsDataInclude(1, "Sep") != 0
                    ? TypeOption(1, "Sep")
                    : this.sunOption == Option(1, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '09', Sept.two)"
              v-bind:style="{ background: activeColor(2, 'Sep', Sept.two) }"
            >
              {{
                (this.Sept.two =
                  IsDataInclude(2, "Sep") != 0
                    ? TypeOption(2, "Sep")
                    : this.sunOption == Option(2, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '09', Sept.three)"
              v-bind:style="{ background: activeColor(3, 'Sep', Sept.three) }"
            >
              {{
                (this.Sept.three =
                  IsDataInclude(3, "Sep") != 0
                    ? TypeOption(3, "Sep")
                    : this.sunOption == Option(3, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '09', Sept.four)"
              v-bind:style="{ background: activeColor(4, 'Sep', Sept.four) }"
            >
              {{
                (this.Sept.four =
                  IsDataInclude(4, "Sep") != 0
                    ? TypeOption(4, "Sep")
                    : this.sunOption == Option(4, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '09', Sept.five)"
              v-bind:style="{ background: activeColor(5, 'Sep', Sept.five) }"
            >
              {{
                (this.Sept.five =
                  IsDataInclude(5, "Sep") != 0
                    ? TypeOption(5, "Sep")
                    : this.sunOption == Option(5, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '09', Sept.six)"
              v-bind:style="{ background: activeColor(6, 'Sep', Sept.six) }"
            >
              {{
                (this.Sept.six =
                  IsDataInclude(6, "Sep") != 0
                    ? TypeOption(6, "Sep")
                    : this.sunOption == Option(6, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '09', Sept.seven)"
              v-bind:style="{ background: activeColor(7, 'Sep', Sept.seven) }"
            >
              {{
                (this.Sept.seven =
                  IsDataInclude(7, "Sep") != 0
                    ? TypeOption(7, "Sep")
                    : this.sunOption == Option(7, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '09', Sept.eight)"
              v-bind:style="{ background: activeColor(8, 'Sep', Sept.eight) }"
            >
              {{
                (this.Sept.eight =
                  IsDataInclude(8, "Sep") != 0
                    ? TypeOption(8, "Sep")
                    : this.sunOption == Option(8, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '09', Sept.nine)"
              v-bind:style="{ background: activeColor(9, 'Sep', Sept.nine) }"
            >
              {{
                (this.Sept.nine =
                  IsDataInclude(9, "Sep") != 0
                    ? TypeOption(9, "Sep")
                    : this.sunOption == Option(9, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '09', Sept.ten)"
              v-bind:style="{ background: activeColor(10, 'Sep', Sept.ten) }"
            >
              {{
                (this.Sept.ten =
                  IsDataInclude(10, "Sep") != 0
                    ? TypeOption(10, "Sep")
                    : this.sunOption == Option(10, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '09', Sept.eleven)"
              v-bind:style="{ background: activeColor(11, 'Sep', Sept.eleven) }"
            >
              {{
                (this.Sept.eleven =
                  IsDataInclude(11, "Sep") != 0
                    ? TypeOption(11, "Sep")
                    : this.sunOption == Option(11, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '09', Sept.twelve)"
              v-bind:style="{ background: activeColor(12, 'Sep', Sept.twelve) }"
            >
              {{
                (this.Sept.twelve =
                  IsDataInclude(12, "Sep") != 0
                    ? TypeOption(12, "Sep")
                    : this.sunOption == Option(12, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '09', Sept.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Sep', Sept.thirteen),
              }"
            >
              {{
                (this.Sept.thirteen =
                  IsDataInclude(13, "Sep") != 0
                    ? TypeOption(13, "Sep")
                    : this.sunOption == Option(13, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '09', Sept.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Sep', Sept.fourteen),
              }"
            >
              {{
                (this.Sept.fourteen =
                  IsDataInclude(14, "Sep") != 0
                    ? TypeOption(14, "Sep")
                    : this.sunOption == Option(14, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '09', Sept.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Sep', Sept.fifteeen),
              }"
            >
              {{
                (this.Sept.fifteeen =
                  IsDataInclude(15, "Sep") != 0
                    ? TypeOption(15, "Sep")
                    : this.sunOption == Option(15, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '09', Sept.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Sep', Sept.sixteen),
              }"
            >
              {{
                (this.Sept.sixteen =
                  IsDataInclude(16, "Sep") != 0
                    ? TypeOption(16, "Sep")
                    : this.sunOption == Option(16, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '09', Sept.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Sep', Sept.seventeen),
              }"
            >
              {{
                (this.Sept.seventeen =
                  IsDataInclude(17, "Sep") != 0
                    ? TypeOption(17, "Sep")
                    : this.sunOption == Option(17, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '09', Sept.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Sep', Sept.eighteen),
              }"
            >
              {{
                (this.Sept.eighteen =
                  IsDataInclude(18, "Sep") != 0
                    ? TypeOption(18, "Sep")
                    : this.sunOption == Option(18, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '09', Sept.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Sep', Sept.ninteen),
              }"
            >
              {{
                (this.Sept.ninteen =
                  IsDataInclude(19, "Sep") != 0
                    ? TypeOption(19, "Sep")
                    : this.sunOption == Option(19, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '09', Sept.twenty)"
              v-bind:style="{ background: activeColor(20, 'Sep', Sept.twenty) }"
            >
              {{
                (this.Sept.twenty =
                  IsDataInclude(20, "Sep") != 0
                    ? TypeOption(20, "Sep")
                    : this.sunOption == Option(20, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '09', Sept.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Sep', Sept.twentyone),
              }"
            >
              {{
                (this.Sept.twentyone =
                  IsDataInclude(21, "Sep") != 0
                    ? TypeOption(21, "Sep")
                    : this.sunOption == Option(21, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '09', Sept.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Sep', Sept.twentytwo),
              }"
            >
              {{
                (this.Sept.twentytwo =
                  IsDataInclude(22, "Sep") != 0
                    ? TypeOption(22, "Sep")
                    : this.sunOption == Option(22, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '09', Sept.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Sep', Sept.twentythree),
              }"
            >
              {{
                (this.Sept.twentythree =
                  IsDataInclude(23, "Sep") != 0
                    ? TypeOption(23, "Sep")
                    : this.sunOption == Option(23, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '09', Sept.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Sep', Sept.twentyfour),
              }"
            >
              {{
                (this.Sept.twentyfour =
                  IsDataInclude(24, "Sep") != 0
                    ? TypeOption(24, "Sep")
                    : this.sunOption == Option(24, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '09', Sept.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Sep', Sept.twentyfive),
              }"
            >
              {{
                (this.Sept.twentyfive =
                  IsDataInclude(25, "Sep") != 0
                    ? TypeOption(25, "Sep")
                    : this.sunOption == Option(25, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '09', Sept.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Sep', Sept.twentysix),
              }"
            >
              {{
                (this.Sept.twentysix =
                  IsDataInclude(26, "Sep") != 0
                    ? TypeOption(26, "Sep")
                    : this.sunOption == Option(26, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '09', Sept.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Sep', Sept.twentyseven),
              }"
            >
              {{
                (this.Sept.twentyseven =
                  IsDataInclude(27, "Sep") != 0
                    ? TypeOption(27, "Sep")
                    : this.sunOption == Option(27, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '09', Sept.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Sep', Sept.twentyeight),
              }"
            >
              {{
                (this.Sept.twentyeight =
                  IsDataInclude(28, "Sep") != 0
                    ? TypeOption(28, "Sep")
                    : this.sunOption == Option(28, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '09', Sept.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Sep', Sept.twentynine),
              }"
            >
              {{
                (this.Sept.twentynine =
                  IsDataInclude(29, "Sep") != 0
                    ? TypeOption(29, "Sep")
                    : this.sunOption == Option(29, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '09', Sept.thirty)"
              v-bind:style="{ background: activeColor(30, 'Sep', Sept.thirty) }"
            >
              {{
                (this.Sept.thirty =
                  IsDataInclude(30, "Sep") != 0
                    ? TypeOption(30, "Sep")
                    : this.sunOption == Option(30, "Sep")
                    ? "H"
                    : "--")
              }}
            </td>
            <td class="txt" readonly style="background-color: #e8e9ec"></td>
          </tr>
          <tr>
            <th scope="row" class="customth">Oct</th>
            <td
              class="txt"
              @click="NewHoliday('01', '10', October.one)"
              v-bind:style="{ background: activeColor(1, 'Oct', October.one) }"
            >
              {{
                (this.October.one =
                  IsDataInclude(1, "Oct") != 0
                    ? TypeOption(1, "Oct")
                    : this.sunOption == Option(1, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '10', October.two)"
              v-bind:style="{ background: activeColor(2, 'Oct', October.two) }"
            >
              {{
                (this.October.two =
                  IsDataInclude(2, "Oct") != 0
                    ? TypeOption(2, "Oct")
                    : this.sunOption == Option(2, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '10', October.three)"
              v-bind:style="{
                background: activeColor(3, 'Oct', October.three),
              }"
            >
              {{
                (this.October.three =
                  IsDataInclude(3, "Oct") != 0
                    ? TypeOption(3, "Oct")
                    : this.sunOption == Option(3, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '10', October.four)"
              v-bind:style="{ background: activeColor(4, 'Oct', October.four) }"
            >
              {{
                (this.October.four =
                  IsDataInclude(4, "Oct") != 0
                    ? TypeOption(4, "Oct")
                    : this.sunOption == Option(4, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '10', October.five)"
              v-bind:style="{ background: activeColor(5, 'Oct', October.five) }"
            >
              {{
                (this.October.five =
                  IsDataInclude(5, "Oct") != 0
                    ? TypeOption(5, "Oct")
                    : this.sunOption == Option(5, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '10', October.six)"
              v-bind:style="{ background: activeColor(6, 'Oct', October.six) }"
            >
              {{
                (this.October.six =
                  IsDataInclude(6, "Oct") != 0
                    ? TypeOption(6, "Oct")
                    : this.sunOption == Option(6, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '10', October.seven)"
              v-bind:style="{
                background: activeColor(7, 'Oct', October.seven),
              }"
            >
              {{
                (this.October.seven =
                  IsDataInclude(7, "Oct") != 0
                    ? TypeOption(7, "Oct")
                    : this.sunOption == Option(7, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '10', October.eight)"
              v-bind:style="{
                background: activeColor(8, 'Oct', October.eight),
              }"
            >
              {{
                (this.October.eight =
                  IsDataInclude(8, "Oct") != 0
                    ? TypeOption(8, "Oct")
                    : this.sunOption == Option(8, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '10', October.nine)"
              v-bind:style="{ background: activeColor(9, 'Oct', October.nine) }"
            >
              {{
                (this.October.nine =
                  IsDataInclude(9, "Oct") != 0
                    ? TypeOption(9, "Oct")
                    : this.sunOption == Option(9, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '10', October.ten)"
              v-bind:style="{ background: activeColor(10, 'Oct', October.ten) }"
            >
              {{
                (this.October.ten =
                  IsDataInclude(10, "Oct") != 0
                    ? TypeOption(10, "Oct")
                    : this.sunOption == Option(10, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '10', October.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Oct', October.eleven),
              }"
            >
              {{
                (this.October.eleven =
                  IsDataInclude(11, "Oct") != 0
                    ? TypeOption(11, "Oct")
                    : this.sunOption == Option(11, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '10', October.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Oct', October.twelve),
              }"
            >
              {{
                (this.October.twelve =
                  IsDataInclude(12, "Oct") != 0
                    ? TypeOption(12, "Oct")
                    : this.sunOption == Option(12, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '10', October.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Oct', October.thirteen),
              }"
            >
              {{
                (this.October.thirteen =
                  IsDataInclude(13, "Oct") != 0
                    ? TypeOption(13, "Oct")
                    : this.sunOption == Option(13, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '10', October.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Oct', October.fourteen),
              }"
            >
              {{
                (this.October.fourteen =
                  IsDataInclude(14, "Oct") != 0
                    ? TypeOption(14, "Oct")
                    : this.sunOption == Option(14, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '10', October.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Oct', October.fifteeen),
              }"
            >
              {{
                (this.October.fifteeen =
                  IsDataInclude(15, "Oct") != 0
                    ? TypeOption(15, "Oct")
                    : this.sunOption == Option(15, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '10', October.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Oct', October.sixteen),
              }"
            >
              {{
                (this.October.sixteen =
                  IsDataInclude(16, "Oct") != 0
                    ? TypeOption(16, "Oct")
                    : this.sunOption == Option(16, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '10', October.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Oct', October.seventeen),
              }"
            >
              {{
                (this.October.seventeen =
                  IsDataInclude(17, "Oct") != 0
                    ? TypeOption(17, "Oct")
                    : this.sunOption == Option(17, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '10', October.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Oct', October.eighteen),
              }"
            >
              {{
                (this.October.eighteen =
                  IsDataInclude(18, "Oct") != 0
                    ? TypeOption(18, "Oct")
                    : this.sunOption == Option(18, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '10', October.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Oct', October.ninteen),
              }"
            >
              {{
                (this.October.ninteen =
                  IsDataInclude(19, "Oct") != 0
                    ? TypeOption(19, "Oct")
                    : this.sunOption == Option(19, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '10', October.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Oct', October.twenty),
              }"
            >
              {{
                (this.October.twenty =
                  IsDataInclude(20, "Oct") != 0
                    ? TypeOption(20, "Oct")
                    : this.sunOption == Option(20, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '10', October.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Oct', October.twentyone),
              }"
            >
              {{
                (this.October.twentyone =
                  IsDataInclude(21, "Oct") != 0
                    ? TypeOption(21, "Oct")
                    : this.sunOption == Option(21, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '10', October.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Oct', October.twentytwo),
              }"
            >
              {{
                (this.October.twentytwo =
                  IsDataInclude(22, "Oct") != 0
                    ? TypeOption(22, "Oct")
                    : this.sunOption == Option(22, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '10', October.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Oct', October.twentythree),
              }"
            >
              {{
                (this.October.twentythree =
                  IsDataInclude(23, "Oct") != 0
                    ? TypeOption(23, "Oct")
                    : this.sunOption == Option(23, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '10', October.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Oct', October.twentyfour),
              }"
            >
              {{
                (this.October.twentyfour =
                  IsDataInclude(24, "Oct") != 0
                    ? TypeOption(24, "Oct")
                    : this.sunOption == Option(24, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '10', October.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Oct', October.twentyfive),
              }"
            >
              {{
                (this.October.twentyfive =
                  IsDataInclude(25, "Oct") != 0
                    ? TypeOption(25, "Oct")
                    : this.sunOption == Option(25, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '10', October.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Oct', October.twentysix),
              }"
            >
              {{
                (this.October.twentysix =
                  IsDataInclude(26, "Oct") != 0
                    ? TypeOption(26, "Oct")
                    : this.sunOption == Option(26, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '10', October.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Oct', October.twentyseven),
              }"
            >
              {{
                (this.October.twentyseven =
                  IsDataInclude(27, "Oct") != 0
                    ? TypeOption(27, "Oct")
                    : this.sunOption == Option(27, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '10', October.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Oct', October.twentyfive),
              }"
            >
              {{
                (this.October.twentyeight =
                  IsDataInclude(28, "Oct") != 0
                    ? TypeOption(28, "Oct")
                    : this.sunOption == Option(28, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '10', October.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Oct', October.twentynine),
              }"
            >
              {{
                (this.October.twentynine =
                  IsDataInclude(29, "Oct") != 0
                    ? TypeOption(29, "Oct")
                    : this.sunOption == Option(29, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '10', October.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Oct', October.thirty),
              }"
            >
              {{
                (this.October.thirty =
                  IsDataInclude(30, "Oct") != 0
                    ? TypeOption(30, "Oct")
                    : this.sunOption == Option(30, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '10', October.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'Oct', October.thirtyone),
              }"
            >
              {{
                (this.October.thirtyone =
                  IsDataInclude(31, "Oct") != 0
                    ? TypeOption(31, "Oct")
                    : this.sunOption == Option(31, "Oct")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
          <tr>
            <th scope="row" class="customth">Nov</th>
            <td
              class="txt"
              @click="NewHoliday('01', '11', November.one)"
              v-bind:style="{ background: activeColor(1, 'Nov', November.one) }"
            >
              {{
                (this.November.one =
                  IsDataInclude(1, "Nov") != 0
                    ? TypeOption(1, "Nov")
                    : this.sunOption == Option(1, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '11', November.two)"
              v-bind:style="{ background: activeColor(2, 'Nov', November.two) }"
            >
              {{
                (this.November.two =
                  IsDataInclude(2, "Nov") != 0
                    ? TypeOption(2, "Nov")
                    : this.sunOption == Option(2, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '11', November.three)"
              v-bind:style="{
                background: activeColor(3, 'Nov', November.three),
              }"
            >
              {{
                (this.November.three =
                  IsDataInclude(3, "Nov") != 0
                    ? TypeOption(3, "Nov")
                    : this.sunOption == Option(3, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '11', November.four)"
              v-bind:style="{
                background: activeColor(4, 'Nov', November.four),
              }"
            >
              {{
                (this.November.four =
                  IsDataInclude(4, "Nov") != 0
                    ? TypeOption(4, "Nov")
                    : this.sunOption == Option(4, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '11', November.five)"
              v-bind:style="{
                background: activeColor(5, 'Nov', November.five),
              }"
            >
              {{
                (this.November.five =
                  IsDataInclude(5, "Nov") != 0
                    ? TypeOption(5, "Nov")
                    : this.sunOption == Option(5, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '11', November.six)"
              v-bind:style="{ background: activeColor(6, 'Nov', November.six) }"
            >
              {{
                (this.November.six =
                  IsDataInclude(6, "Nov") != 0
                    ? TypeOption(6, "Nov")
                    : this.sunOption == Option(6, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '11', November.seven)"
              v-bind:style="{
                background: activeColor(7, 'Nov', November.seven),
              }"
            >
              {{
                (this.November.seven =
                  IsDataInclude(7, "Nov") != 0
                    ? TypeOption(7, "Nov")
                    : this.sunOption == Option(7, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '11', November.eight)"
              v-bind:style="{
                background: activeColor(8, 'Nov', November.eight),
              }"
            >
              {{
                (this.November.eight =
                  IsDataInclude(8, "Nov") != 0
                    ? TypeOption(8, "Nov")
                    : this.sunOption == Option(8, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '11', November.nine)"
              v-bind:style="{
                background: activeColor(9, 'Nov', November.nine),
              }"
            >
              {{
                (this.November.nine =
                  IsDataInclude(9, "Nov") != 0
                    ? TypeOption(9, "Nov")
                    : this.sunOption == Option(9, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '11', November.ten)"
              v-bind:style="{
                background: activeColor(10, 'Nov', November.ten),
              }"
            >
              {{
                (this.November.ten =
                  IsDataInclude(10, "Nov") != 0
                    ? TypeOption(10, "Nov")
                    : this.sunOption == Option(10, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '11', November.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Nov', November.eleven),
              }"
            >
              {{
                (this.November.eleven =
                  IsDataInclude(11, "Nov") != 0
                    ? TypeOption(11, "Nov")
                    : this.sunOption == Option(11, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '11', November.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Nov', November.twelve),
              }"
            >
              {{
                (this.November.twelve =
                  IsDataInclude(12, "Nov") != 0
                    ? TypeOption(12, "Nov")
                    : this.sunOption == Option(12, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '11', November.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Nov', November.thirteen),
              }"
            >
              {{
                (this.November.thirteen =
                  IsDataInclude(13, "Nov") != 0
                    ? TypeOption(13, "Nov")
                    : this.sunOption == Option(13, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '11', November.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Nov', November.fourteen),
              }"
            >
              {{
                (this.November.fourteen =
                  IsDataInclude(14, "Nov") != 0
                    ? TypeOption(14, "Nov")
                    : this.sunOption == Option(14, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '11', November.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Nov', November.fifteeen),
              }"
            >
              {{
                (this.November.fifteeen =
                  IsDataInclude(15, "Nov") != 0
                    ? TypeOption(15, "Nov")
                    : this.sunOption == Option(15, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '11', November.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Nov', November.sixteen),
              }"
            >
              {{
                (this.November.sixteen =
                  IsDataInclude(16, "Nov") != 0
                    ? TypeOption(16, "Nov")
                    : this.sunOption == Option(16, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '11', November.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Nov', November.seventeen),
              }"
            >
              {{
                (this.November.seventeen =
                  IsDataInclude(17, "Nov") != 0
                    ? TypeOption(17, "Nov")
                    : this.sunOption == Option(17, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '11', November.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Nov', November.eighteen),
              }"
            >
              {{
                (this.November.eighteen =
                  IsDataInclude(18, "Nov") != 0
                    ? TypeOption(18, "Nov")
                    : this.sunOption == Option(18, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '11', November.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Nov', November.ninteen),
              }"
            >
              {{
                (this.November.ninteen =
                  IsDataInclude(19, "Nov") != 0
                    ? TypeOption(19, "Nov")
                    : this.sunOption == Option(19, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '11', November.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Nov', November.twenty),
              }"
            >
              {{
                (this.November.twenty =
                  IsDataInclude(20, "Nov") != 0
                    ? TypeOption(20, "Nov")
                    : this.sunOption == Option(20, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '11', November.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Nov', November.twentyone),
              }"
            >
              {{
                (this.November.twentyone =
                  IsDataInclude(21, "Nov") != 0
                    ? TypeOption(21, "Nov")
                    : this.sunOption == Option(21, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '11', November.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Nov', November.twentytwo),
              }"
            >
              {{
                (this.November.twentytwo =
                  IsDataInclude(22, "Nov") != 0
                    ? TypeOption(22, "Nov")
                    : this.sunOption == Option(22, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '11', November.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Nov', November.twentythree),
              }"
            >
              {{
                (this.November.twentythree =
                  IsDataInclude(23, "Nov") != 0
                    ? TypeOption(23, "Nov")
                    : this.sunOption == Option(23, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '11', November.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Nov', November.twentyfour),
              }"
            >
              {{
                (this.November.twentyfour =
                  IsDataInclude(24, "Nov") != 0
                    ? TypeOption(24, "Nov")
                    : this.sunOption == Option(24, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '11', November.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Nov', November.twentyfive),
              }"
            >
              {{
                (this.November.twentyfive =
                  IsDataInclude(25, "Nov") != 0
                    ? TypeOption(25, "Nov")
                    : this.sunOption == Option(25, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '11', November.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Nov', November.twentysix),
              }"
            >
              {{
                (this.November.twentysix =
                  IsDataInclude(26, "Nov") != 0
                    ? TypeOption(26, "Nov")
                    : this.sunOption == Option(26, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '11', November.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Nov', November.twentyseven),
              }"
            >
              {{
                (this.November.twentyseven =
                  IsDataInclude(27, "Nov") != 0
                    ? TypeOption(27, "Nov")
                    : this.sunOption == Option(27, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '11', November.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Nov', November.twentyeight),
              }"
            >
              {{
                (this.November.twentyeight =
                  IsDataInclude(28, "Nov") != 0
                    ? TypeOption(28, "Nov")
                    : this.sunOption == Option(28, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '11', November.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Nov', November.twentynine),
              }"
            >
              {{
                (this.November.twentynine =
                  IsDataInclude(29, "Nov") != 0
                    ? TypeOption(29, "Nov")
                    : this.sunOption == Option(29, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '11', November.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Nov', November.thirty),
              }"
            >
              {{
                (this.November.thirty =
                  IsDataInclude(30, "Nov") != 0
                    ? TypeOption(30, "Nov")
                    : this.sunOption == Option(30, "Nov")
                    ? "H"
                    : "--")
              }}
            </td>
            <td class="txt" readonly style="background-color: #e8e9ec"></td>
          </tr>
          <tr>
            <th scope="row" class="customth">Dec</th>
            <td
              class="txt"
              @click="NewHoliday('01', '12', December.one)"
              v-bind:style="{ background: activeColor(1, 'Dec', December.one) }"
            >
              {{
                (this.December.one =
                  IsDataInclude(1, "Dec") != 0
                    ? TypeOption(1, "Dec")
                    : this.sunOption == Option(1, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('02', '12', December.two)"
              v-bind:style="{ background: activeColor(2, 'Dec', December.two) }"
            >
              {{
                (this.December.two =
                  IsDataInclude(2, "Dec") != 0
                    ? TypeOption(2, "Dec")
                    : this.sunOption == Option(2, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('03', '12', December.three)"
              v-bind:style="{
                background: activeColor(3, 'Dec', December.three),
              }"
            >
              {{
                (this.December.three =
                  IsDataInclude(3, "Dec") != 0
                    ? TypeOption(3, "Dec")
                    : this.sunOption == Option(3, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('04', '12', December.four)"
              v-bind:style="{
                background: activeColor(4, 'Dec', December.four),
              }"
            >
              {{
                (this.December.four =
                  IsDataInclude(4, "Dec") != 0
                    ? TypeOption(4, "Dec")
                    : this.sunOption == Option(4, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('05', '12', December.five)"
              v-bind:style="{
                background: activeColor(5, 'Dec', December.five),
              }"
            >
              {{
                (this.December.five =
                  IsDataInclude(5, "Dec") != 0
                    ? TypeOption(5, "Dec")
                    : this.sunOption == Option(5, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('06', '12', December.six)"
              v-bind:style="{ background: activeColor(6, 'Dec', December.six) }"
            >
              {{
                (this.December.six =
                  IsDataInclude(6, "Dec") != 0
                    ? TypeOption(6, "Dec")
                    : this.sunOption == Option(6, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('07', '12', December.seven)"
              v-bind:style="{
                background: activeColor(7, 'Dec', December.seven),
              }"
            >
              {{
                (this.December.seven =
                  IsDataInclude(7, "Dec") != 0
                    ? TypeOption(7, "Dec")
                    : this.sunOption == Option(7, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('08', '12', December.eight)"
              v-bind:style="{
                background: activeColor(8, 'Dec', December.eight),
              }"
            >
              {{
                (this.December.eight =
                  IsDataInclude(8, "Dec") != 0
                    ? TypeOption(8, "Dec")
                    : this.sunOption == Option(8, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('09', '12', December.nine)"
              v-bind:style="{
                background: activeColor(9, 'Dec', December.nine),
              }"
            >
              {{
                (this.December.nine =
                  IsDataInclude(9, "Dec") != 0
                    ? TypeOption(9, "Dec")
                    : this.sunOption == Option(9, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('10', '12', December.ten)"
              v-bind:style="{
                background: activeColor(10, 'Dec', December.ten),
              }"
            >
              {{
                (this.December.ten =
                  IsDataInclude(10, "Dec") != 0
                    ? TypeOption(10, "Dec")
                    : this.sunOption == Option(10, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('11', '12', December.eleven)"
              v-bind:style="{
                background: activeColor(11, 'Dec', December.eleven),
              }"
            >
              {{
                (this.December.eleven =
                  IsDataInclude(11, "Dec") != 0
                    ? TypeOption(11, "Dec")
                    : this.sunOption == Option(11, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('12', '12', December.twelve)"
              v-bind:style="{
                background: activeColor(12, 'Dec', December.twelve),
              }"
            >
              {{
                (this.December.twelve =
                  IsDataInclude(12, "Dec") != 0
                    ? TypeOption(12, "Dec")
                    : this.sunOption == Option(12, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('13', '12', December.thirteen)"
              v-bind:style="{
                background: activeColor(13, 'Dec', December.thirteen),
              }"
            >
              {{
                (this.December.thirteen =
                  IsDataInclude(13, "Dec") != 0
                    ? TypeOption(13, "Dec")
                    : this.sunOption == Option(13, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('14', '12', December.fourteen)"
              v-bind:style="{
                background: activeColor(14, 'Dec', December.fourteen),
              }"
            >
              {{
                (this.December.fourteen =
                  IsDataInclude(14, "Dec") != 0
                    ? TypeOption(14, "Dec")
                    : this.sunOption == Option(14, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('15', '12', December.fifteeen)"
              v-bind:style="{
                background: activeColor(15, 'Dec', December.fifteeen),
              }"
            >
              {{
                (this.December.fifteeen =
                  IsDataInclude(15, "Dec") != 0
                    ? TypeOption(15, "Dec")
                    : this.sunOption == Option(15, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('16', '12', December.sixteen)"
              v-bind:style="{
                background: activeColor(16, 'Dec', December.sixteen),
              }"
            >
              {{
                (this.December.sixteen =
                  IsDataInclude(16, "Dec") != 0
                    ? TypeOption(16, "Dec")
                    : this.sunOption == Option(16, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('17', '12', December.seventeen)"
              v-bind:style="{
                background: activeColor(17, 'Dec', December.seventeen),
              }"
            >
              {{
                (this.December.seventeen =
                  IsDataInclude(17, "Dec") != 0
                    ? TypeOption(17, "Dec")
                    : this.sunOption == Option(17, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('18', '12', December.eighteen)"
              v-bind:style="{
                background: activeColor(18, 'Dec', December.eighteen),
              }"
            >
              {{
                (this.December.eighteen =
                  IsDataInclude(18, "Dec") != 0
                    ? TypeOption(18, "Dec")
                    : this.sunOption == Option(18, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('19', '12', December.ninteen)"
              v-bind:style="{
                background: activeColor(19, 'Dec', December.ninteen),
              }"
            >
              {{
                (this.December.ninteen =
                  IsDataInclude(19, "Dec") != 0
                    ? TypeOption(19, "Dec")
                    : this.sunOption == Option(19, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('20', '12', December.twenty)"
              v-bind:style="{
                background: activeColor(20, 'Dec', December.twenty),
              }"
            >
              {{
                (this.December.twenty =
                  IsDataInclude(20, "Dec") != 0
                    ? TypeOption(20, "Dec")
                    : this.sunOption == Option(20, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('21', '12', December.twentyone)"
              v-bind:style="{
                background: activeColor(21, 'Dec', December.twentyone),
              }"
            >
              {{
                (this.December.twentyone =
                  IsDataInclude(21, "Dec") != 0
                    ? TypeOption(21, "Dec")
                    : this.sunOption == Option(21, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('22', '12', December.twentytwo)"
              v-bind:style="{
                background: activeColor(22, 'Dec', December.twentytwo),
              }"
            >
              {{
                (this.December.twentytwo =
                  IsDataInclude(22, "Dec") != 0
                    ? TypeOption(22, "Dec")
                    : this.sunOption == Option(22, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('23', '12', December.twentythree)"
              v-bind:style="{
                background: activeColor(23, 'Dec', December.twentythree),
              }"
            >
              {{
                (this.December.twentythree =
                  IsDataInclude(23, "Dec") != 0
                    ? TypeOption(23, "Dec")
                    : this.sunOption == Option(23, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('24', '12', December.twentyfour)"
              v-bind:style="{
                background: activeColor(24, 'Dec', December.twentyfour),
              }"
            >
              {{
                (this.December.twentyfour =
                  IsDataInclude(24, "Dec") != 0
                    ? TypeOption(24, "Dec")
                    : this.sunOption == Option(24, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('25', '12', December.twentyfive)"
              v-bind:style="{
                background: activeColor(25, 'Dec', December.twentyfive),
              }"
            >
              {{
                (this.December.twentyfive =
                  IsDataInclude(25, "Dec") != 0
                    ? TypeOption(25, "Dec")
                    : this.sunOption == Option(25, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('26', '12', December.twentysix)"
              v-bind:style="{
                background: activeColor(26, 'Dec', December.twentysix),
              }"
            >
              {{
                (this.December.twentysix =
                  IsDataInclude(26, "Dec") != 0
                    ? TypeOption(26, "Dec")
                    : this.sunOption == Option(26, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('27', '12', December.twentyseven)"
              v-bind:style="{
                background: activeColor(27, 'Dec', December.twentyseven),
              }"
            >
              {{
                (this.December.twentyseven =
                  IsDataInclude(27, "Dec") != 0
                    ? TypeOption(27, "Dec")
                    : this.sunOption == Option(27, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('28', '12', December.twentyeight)"
              v-bind:style="{
                background: activeColor(28, 'Dec', December.twentyeight),
              }"
            >
              {{
                (this.December.twentyeight =
                  IsDataInclude(28, "Dec") != 0
                    ? TypeOption(28, "Dec")
                    : this.sunOption == Option(28, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('29', '12', December.twentynine)"
              v-bind:style="{
                background: activeColor(29, 'Dec', December.twentynine),
              }"
            >
              {{
                (this.December.twentynine =
                  IsDataInclude(29, "Dec") != 0
                    ? TypeOption(29, "Dec")
                    : this.sunOption == Option(29, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('30', '12', December.thirty)"
              v-bind:style="{
                background: activeColor(30, 'Dec', December.thirty),
              }"
            >
              {{
                (this.December.thirty =
                  IsDataInclude(30, "Dec") != 0
                    ? TypeOption(30, "Dec")
                    : this.sunOption == Option(30, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
            <td
              class="txt"
              @click="NewHoliday('31', '12', December.thirtyone)"
              v-bind:style="{
                background: activeColor(31, 'Dec', December.thirtyone),
              }"
            >
              {{
                (this.December.thirtyone =
                  IsDataInclude(31, "Dec") != 0
                    ? TypeOption(31, "Dec")
                    : this.sunOption == Option(31, "Dec")
                    ? "H"
                    : "--")
              }}
            </td>
          </tr>
        </v-simple-table>
      </div>
      <div style="height: 10vh"></div>
      <!-- uploadholidaysDialog -->
      <v-dialog v-model="uploadholidaysDialog" presistent max-width="786">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p style="margin-top: 10px; margin-left: 6px">
              {{ $t("UploadFile") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-row style="margin-top: -1.5rem">
              <v-col style="margin-left: 6px" align="center" cols="5">
                <v-file-input
                  v-show="showup"
                  color="white"
                  class="myfile"
                  id="myFileInput"
                  v-model="chosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row style="margin-top: -2rem">
              <v-col cols="12" md="3">
                <v-btn
                  @click="getupload"
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                >
                  {{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("filetype") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("DocumentTemplate") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a :href="CheckLang()" style="color: #f99d20">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="closeUpload"
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="CloseHolidayDialog()"
            >
              {{ $t("Upload") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- saveUploadDialog -->
      <v-dialog
        v-model="saveUploadDialog"
        presistent
        max-width="729"
        style="border-radius: 0px"
      >
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p
              style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px"
            >
              {{ $t("UploadHolidays") }}
            </p>
          </v-card-title>
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="3"
            lg="3"
            xl="3"
            class="ml-5 mt-7"
          >
            <div class="d-flex">
              <div class="pt-2">{{ $t("Year") }}</div>
              <div>
                <v-autocomplete
                  class="ml-2"
                  v-model="uploadyear"
                  :items="uploadyearitems"
                  outlined
                  dense
                  color="#F99D20"
                ></v-autocomplete>
              </div>
            </div>
          </v-col>
          <v-card-text class="mt-3">
            <div>
              <v-data-table
                :headers="headers"
                :items="HolidayList"
                hide-default-footer
                hide-default-header
                disable-pagination
                :mobile-breakpoint="0"
              >
                <template v-slot:header="{ props: {} }">
                  <thead style="background-color: #f99d20">
                    <tr>
                      <th class="white--text">Month/Date</th>
                      <th class="white--text">Holidays</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="border: 1px solid #f99d20">{{ item.date1 }}</td>
                    <td style="border: 1px solid #f99d20">
                      {{ item.holiday }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              text
              @click="closeUpload"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="white--text mr-2 text-capitalize"
              width="120"
              height="35"
              @click="UploadHolidayData()"
              >{{ $t("Upload") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- NewholidaysDialog  Holiday to working day -->
      <v-dialog v-model="NewholidaysDialog" presistent max-width="480">
        <v-card style="border-radius: 15px">
          <h2
            class="font-weight-medium pt-6"
            style="margin-left: 20px; margin-right: 20px; margin-bottom: 20px"
          >
            {{ $t("ChangeCompanyCalendar") }}
          </h2>
          <div v-show="Nameshow">
            <p style="margin-left: 22px; color: #f3b605">Weekend</p>
          </div>
          <div v-show="!Nameshow">
            <p style="margin-left: 22px; color: #f3b605">
              Holiday - {{ HolidayName }}
            </p>
          </div>
          <p style="margin-left: 22px; margin-right: 20px">
            {{ $t("WouldYouLikeToChange") }} {{ clickdate }}
            {{ $t("ToAWorkingDay") }}
          </p>

          <v-card-actions class="pb-6 mt-5" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              text
              @click="NewholidaysDialog = false"
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="white--text mr-2 text-capitalize"
              width="120"
              height="35"
              :disabled="permission.add"
              @click="AddHolidayToWorkingDay"
            >
              {{ $t("confirm") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- NewholidaysDialog  Working day to Holiday -->
      <v-dialog v-model="WToHolidayDialog" presistent max-width="480">
        <v-card style="border-radius: 15px">
          <h2
            class="font-weight-medium pt-6"
            style="margin-left: 20px; margin-right: 20px; margin-bottom: 20px"
          >
            {{ $t("ChangeCompanyCalendar") }}
          </h2>

          <p style="margin-left: 22px; margin-right: 20px">
            {{ $t("WouldYouLikeToChange") }} {{ clickdate }}
            {{ $t("ToAHoliday") }}
          </p>
          <v-text-field
            color="#F99D20"
            class="mt-6 mr-7 ml-7"
            dense
            outlined
            v-model="holidayname"
          ></v-text-field>

          <v-card-actions class="pb-3" style="margin-right: 1%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              text
              @click="WToHolidayDialog = false"
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="white--text mr-2 text-capitalize"
              width="120"
              height="35"
              :disabled="permission.add"
              @click="AddWokingDayToHoliday"
              >{{ $t("confirm") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Loading Dialog -->
      <v-dialog v-model="LoadingDialog" persistent width="300">
        <v-card color="#FFF4EB" dark>
          <v-card-text class="black--text">
            Loading Please Wait...
            <v-progress-linear
              indeterminate
              color="#F99D20"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Upload Confirm Dialog -->
      <v-dialog v-model="confirmdialog" class="dialog" max-width="532">
        <v-card style="border-radius: 15px">
          <!-- <v-card-title>
            <p style="margin-top: 10px; margin-bottom: -30px">
              {{ $t("deleteitem") }}
            </p>
          </v-card-title> -->
          <v-card-text>
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <p style="font-size: 17px; color: #444444" class="pt-4">
                    {{ $t("Replace") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6 mr-2">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-5 text-capitalize"
              width="120"
              max-height="35"
              @click="ConfirmCancel()"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-5 white--text text-capitalize delete_btn"
              width="120"
              max-height="35"
              @click="ConfirmUploadHoliday()"
              >{{ $t("Confirm") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Annual holidays Dialog -->
      <v-dialog v-model="annualholidaysDialog" max-width="700" persistent>
        <v-card style="border-radius: 15px">
          <v-card-title>
            <p class="ml-3" style="margin-top: 10px; margin-bottom: -30px">
              {{ $t("Annualholidays") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                  <div class="d-flex">
                    <div class="pt-2" style="color: #000; font-size: 16px">
                      {{ $t("Year") }}
                    </div>
                    <div>
                      <v-autocomplete
                        class="ml-2"
                        v-model="exportyear"
                        :items="yearitems"
                        outlined
                        dense
                        color="#F99D20"
                        @change="GetHolidayList(exportyear)"
                      ></v-autocomplete>
                    </div>
                  </div>
                </v-col>
                <v-spacer />
                <v-col
                  cols="12"
                  xs="12"
                  sm="3"
                  md="2"
                  lg="3"
                  xl="3"
                  class="text-right"
                >
                  <v-btn
                    outlined
                    color="#2E7D32"
                    @click="Export()"
                    class="green--text text-capitalize"
                  >
                    <v-img
                      class="mr-2 mt-1"
                      src="@/assets/images/excel.png"
                    ></v-img
                    >{{ $t("Export") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div class="mt-2">
                <v-data-table
                  :headers="headers"
                  :items="AnnualHolidaysList"
                  hide-default-footer
                  hide-default-header
                  disable-pagination
                  :mobile-breakpoint="0"
                  class="holidaytable"
                >
                  <template v-slot:header="{ props: {} }">
                    <thead style="background-color: #f99d20">
                      <tr>
                        <th class="white--text">Date</th>
                        <th class="white--text">Holidays</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td style="border: 1px solid #f99d20">
                        {{ Dateformat(item.wC_Date.split("T")[0]) }}
                      </td>
                      <td style="border: 1px solid #f99d20">
                        {{ item.wC_Name }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn
              color="#FF0000"
              class="white--text text-capitalize mr-6"
              width="135"
              max-height="35"
              @click="CancelExport()"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import XLSX from "xlsx";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddUpload from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, utils, CheckViewAddUpload],
  data: (vm) => ({
    annualholidaysDialog: false,
    confirmdialog: false,
    permission: {
      add: true,
      upload: true,
    },
    HolidayName: null,
    Nameshow: true,
    LoadingDialog: false,
    WDataList: [],
    url: enurl.apiUrl,
    holidayname: null,
    HolidayList: [],
    tempimport: [],
    WToHolidayDialog: false,
    enday: [],
    starday: [],
    January: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },
    February: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
    },
    March: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },
    April: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
    },
    Mayy: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },
    June: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
    },
    July: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },
    August: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },
    Sept: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
    },
    October: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },
    November: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
    },
    December: {
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      eleven: null,
      twelve: null,
      thirteen: null,
      fourteen: null,
      fifteeen: null,
      sixteen: null,
      seventeen: null,
      eighteen: null,
      ninteen: null,
      twenty: null,
      twentyone: null,
      twentytwo: null,
      twentythree: null,
      twentyfour: null,
      twentyfive: null,
      twentysix: null,
      twentyseven: null,
      twentyeight: null,
      twentynine: null,
      thirty: null,
      thirtyone: null,
    },

    date: new Date().toISOString().substr(0, 10),
    filteryear: new Date().getFullYear(),
    exportyear: new Date().getFullYear(),
    uploadyear: new Date().getFullYear(),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    yearitems: [],
    uploadyearitems: [],
    clickdate: null,
    uploadholidaysDialog: false,
    sunOption: false,
    disableday: 0,
    sat: [],
    sun: [],
    dayinfeb: null,
    saveUploadDialog: false,
    NewholidaysDialog: false,
    showup: false,
    chosenfile: null,
    startdate: "",
    date1: "",
    menu: false,
    menu5: false,
    headers: [
      {
        value: "date1",
        align: "left",
        sortable: false,
      },
      {
        value: "holiday",
        align: "left",
        sortable: false,
      },
    ],
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    Jan: {
      sat: [],
      sun: [],
    },
    Feb: {
      sat: [],
      sun: [],
    },
    Mar: {
      sat: [],
      sun: [],
    },
    Apr: {
      sat: [],
      sun: [],
    },
    May: {
      sat: [],
      sun: [],
    },
    Jun: {
      sat: [],
      sun: [],
    },
    Jul: {
      sat: [],
      sun: [],
    },
    Aug: {
      sat: [],
      sun: [],
    },
    Sep: {
      sat: [],
      sun: [],
    },
    Oct: {
      sat: [],
      sun: [],
    },
    Nov: {
      sat: [],
      sun: [],
    },
    Dec: {
      sat: [],
      sun: [],
    },
    Result: [],
    YearList: [],
    latestyear: [],
    finalArray: [],
    finalList: [],
    filterdate: [],
    AnnualHolidaysList: [],
  }),
  mounted() {
    let self = this;
    self.GetUploadYears();
    self.GetHolidaysByCompany();
    self.GetYearWork();
    self.YearChange(self.filteryear);
    self.GetWorkDayCalendar();
    self.GetHolidayList(self.exportyear);
    self.permission = self.CheckViewAddUpload(20, 21, 22);
  },
  watch: {
    startdate() {
      this.starday = [];
    },
    date1() {
      this.enday = [];
    },
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
  },
  computed: {
    computedDateFormatted4() {
      return this.formatDate(this.startdate);
    },
    computedDateFormatted1() {
      return this.formatDate(this.date1);
    },
  },
  methods: {
    Dateformat(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    Export() {
      let self = this;
      let wb = XLSX.utils.book_new();

      self.LogListExport = self.AnnualHolidaysList;
      // xlsx json data
      let wsdata = [];
      for (let v of self.LogListExport) {
        wsdata.push({
          ["Date"]: self.Dateformat(v.wC_Date.split("T")[0]),
          ["Holidays"]: v.wC_Name,
        });
      }
      const ws = XLSX.utils.json_to_sheet(wsdata);
      // xlsx append sheet
      XLSX.utils.book_append_sheet(wb, ws, "Holidays");

      // xlsx sheet name
      self.tempsheetname = `AnnualHolidays_Export_${self.exportyear} `;
      // xlsx write file
      XLSX.writeFile(wb, `${self.tempsheetname}.xlsx`, {
        compression: true,
      });
      self.annualholidaysDialog = false;
      self.exportyear = new Date().getFullYear();
      self.GetHolidayList(self.exportyear);
    },
    CancelExport() {
      let self = this;
      self.annualholidaysDialog = false;
      self.exportyear = new Date().getFullYear();
      self.GetHolidayList(self.exportyear);
    },
    ConfirmCancel() {
      let self = this;
      self.confirmdialog = false;
      self.finalArray = [];
    },
    GetUploadYears() {
      let self = this;
      let current = new Date().getFullYear();
      let max = current + 2;
      for (var i = current; i <= max; i++) {
        self.uploadyearitems.push(i);
      }
    },
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Holiday.xlsx";
      } else {
        url =
          "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Holiday.xlsx";
      }
      return url;
    },
    GetHolidayName(date) {
      let self = this;
      let nowdate = new Date();
      let [yy, mm, dd] = date.split("/");
      nowdate.setDate(dd);
      nowdate.setMonth(mm - 1);
      nowdate.setFullYear(yy);

      let mmm = null;
      let mmn = nowdate.getMonth() + 1;
      if (mmn < 10) {
        mmm = "0" + mmn;
      } else {
        mmm = mmn;
      }

      let ddd = null;
      let dd1 = nowdate.getDate();
      if (dd1 < 10) {
        ddd = "0" + dd1;
      } else {
        ddd = dd1;
      }

      let tempdate =
        nowdate.getFullYear() + "-" + mmm + "-" + ddd + "T00:00:00";

      let temp = {
        company_ID: store.state.companyID,
        wC_Date: tempdate,
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetHolidayNameByHolidayDate`, temp)
        .then(function (response) {
          if (response.data.data.length != 0) {
            self.HolidayName = response.data.data[0].wC_Name;
          } else {
            self.HolidayName = null;
          }
        });
    },
    GetHolidaysByCompany() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetWorkDayByCompanyID`, temp)
        .then(function (response) {
          let List = response.data.data;
          if (List.length != 0) {
            self.finalList = List.filter((v) => v.wC_Type == "H");
            self.filterdate = self.finalList.map(
              (v) => v.wC_Date.split("T")[0]
            );
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    ValidateHolidayDate(date) {
      let self = this;
      if (self.finalList != undefined || self.finalList != null) {
        let result = self.finalList.filter((o1) => o1.wC_Date === date);
        if (result.length != 0) {
          self.Nameshow = false;
        } else {
          self.Nameshow = true;
        }
      }
    },
    IsDataInclude(day, mon) {
      let self = this;
      if (self.Result.some((e) => e.wC_Day == day && e.WC_Month == mon)) {
        return 1;
      } else {
        return 0;
      }
    },
    TypeOption(day, mon) {
      for (let i = 0; i < this.Result.length; i++) {
        if (this.Result[i].wC_Day == day && this.Result[i].WC_Month == mon) {
          return this.Result[i].wC_Type;
        }
      }
    },
    GetYearWork() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetYearOfWorkDay`, temp)
        .then(function (response) {
          self.YearList = response.data.data;
          let nowyear = new Date();
          self.filteryear = nowyear.getFullYear();
          self.exportyear = nowyear.getFullYear();
          if (self.YearList.length != 0) {
            self.yearitems = self.YearList.map((v) => v.year_Num);
          } else {
            self.yearitems.push(self.filteryear);
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Yearwork Fail", 25, "Critical");
          //alert(error + "Correlation ID is 25");
          self.LoadingDialog = false;
        });
    },
    GetWorkDayCalendar() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
        year_Num: self.filteryear,
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetWorkDayByYear`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.WDataList = response.data.data;
            // self.AnnualHolidaysList = self.WDataList.filter(
            //   (v) => v.wC_Type == "H"
            // );
            for (let i = 0; i < self.WDataList.length; i++) {
              let newdate = new Date(self.WDataList[i].wC_Date);

              self.Result.push({
                WC_ID: self.WDataList[i].WC_ID,
                wC_Day: newdate.getDate(),
                WC_Month: self.monthNames[newdate.getMonth()],
                wC_Type: self.WDataList[i].wC_Type,
              });
            }
            self.TypeOption();
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Workday Calendar Fail", 25, "Critical");
          //alert(error + "Correlation ID is 25");
          self.LoadingDialog = false;
        });
    },
    GetHolidayList(exportyear) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
        year_Num: exportyear,
      };
      axios
        .post(`${self.url}WorkdayCalendar/GetWorkDayByYear`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            let List = response.data.data;
            self.AnnualHolidaysList = List.filter((v) => v.wC_Type == "H");
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          self.LogTrace(error);
          self.LoadingDialog = false;
        });
    },
    AddWokingDayToHoliday() {
      let self = this;
      let nowdate = new Date();
      if (self.holidayname != null && self.holidayname != "") {
        let [dd, mm, yy] = self.clickdate.split("/");
        nowdate.setDate(dd);
        nowdate.setMonth(mm - 1);
        nowdate.setFullYear(yy);

        let mmm = null;
        let mmn = nowdate.getMonth() + 1;
        if (mmn < 10) {
          mmm = "0" + mmn;
        } else {
          mmm = mmn;
        }

        let ddd = null;
        let dd1 = nowdate.getDate();
        if (dd1 < 10) {
          ddd = "0" + dd1;
        } else {
          ddd = dd1;
        }

        let tempdate =
          nowdate.getFullYear() + "-" + mmm + "-" + ddd + "T00:00:00";

        if (self.WDataList.some((e) => e.wC_Date == tempdate)) {
          self.LoadingDialog = true;
          let updateworkdaytemp = {
            wC_Name: self.holidayname,
            wC_Type: "H",
            wC_Date: tempdate,
            company_ID: store.state.companyID,
          };
          axios
            .post(
              `${self.url}WorkdayCalendar/UpdateWorkdayCalendar`,
              updateworkdaytemp
            )
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.GetHolidaysByCompany();
                self.TypeOption();
                self.holidayname = null;
                self.WToHolidayDialog = false;
                self.Result = [];
                // self.WDataList=[];
                self.GetWorkDayCalendar();
                self.GetHolidayList(self.exportyear);
                self.LoadingDialog = false;
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Workday Calendar", 25, "Low");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = true;
          let workdaytemp = {
            wC_Name: self.holidayname,
            wC_Type: "H",
            wC_Date: tempdate,
            company_ID: store.state.companyID,
          };
          axios
            .post(`${self.url}WorkdayCalendar/AddWorkdayCalendar`, workdaytemp)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.GetHolidaysByCompany();
                self.holidayname = null;
                self.WToHolidayDialog = false;
                self.GetWorkDayCalendar();
                self.GetHolidayList(self.exportyear);
                self.LoadingDialog = false;
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Workday Calendar", 25, "Low");
              self.LoadingDialog = false;
            });
        }
      }
    },
    AddHolidayToWorkingDay() {
      let self = this;
      let nowdate = new Date();
      let [dd, mm, yy] = self.clickdate.split("/");
      nowdate.setDate(dd);
      nowdate.setMonth(mm - 1);
      nowdate.setFullYear(yy);
      let mmm = null;
      let mmn = nowdate.getMonth() + 1;
      if (mmn < 10) {
        mmm = "0" + mmn;
      } else {
        mmm = mmn;
      }

      let ddd = null;
      let dd1 = nowdate.getDate();
      if (dd1 < 10) {
        ddd = "0" + dd1;
      } else {
        ddd = dd1;
      }

      let tempdate =
        nowdate.getFullYear() + "-" + mmm + "-" + ddd + "T00:00:00";

      if (self.WDataList.some((e) => e.wC_Date == tempdate)) {
        self.LoadingDialog = true;
        let updateworkdaytemp = {
          wC_Name: "Working Day",
          wC_Type: "W",
          wC_Date: tempdate,
          company_ID: store.state.companyID,
        };
        axios
          .post(
            `${self.url}WorkdayCalendar/UpdateWorkdayCalendar`,
            updateworkdaytemp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.GetHolidaysByCompany();
              self.HolidayName = null;
              self.Result = [];
              self.TypeOption();
              self.NewholidaysDialog = false;
              //self.WDataList=[];
              self.GetWorkDayCalendar();
              self.GetHolidayList(self.exportyear);
              self.LoadingDialog = false;
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Workday Calendar", 25, "Low");
            self.LoadingDialog = false;
          });
      } else {
        self.LoadingDialog = true;
        let workdaytemp = {
          wC_Name: "Working Day",
          wC_Type: "W",
          wC_Date: tempdate,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}WorkdayCalendar/AddWorkdayCalendar`, workdaytemp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.GetHolidaysByCompany();
              self.NewholidaysDialog = false;
              self.GetWorkDayCalendar();
              self.GetHolidayList(self.exportyear);
              self.LoadingDialog = false;
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Workday Calendar", 25, "Low");
            self.LoadingDialog = false;
          });
      }
    },
    closeUpload() {
      this.uploadholidaysDialog = false;
      this.saveUploadDialog = false;
      this.HolidayList = [];
      this.startdate = null;
      this.date1 = null;
      this.starday = [];
      this.enday = [];
      (this.chosenfile = null), (this.showup = false);
    },
    getupload() {
      document.getElementById("myFileInput").click();
      //this.showup = true;
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },

    CloseHolidayDialog() {
      let self = this;
      var files = this.chosenfile;
      if (files != null) {
        let combileArray = [];
        let tempimport1 = [];
        var reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Holiday.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            for (let i = 0; i < this.importData.length; i++) {
              //let ddd= this.getJsDateFromExcel(this.importData[i].date);
              this.tempimport = {
                date: file_data[i + 1][0],
                holiday: file_data[i + 1][1],
                company_ID: store.state.companyID,
              };
              //self.HolidayList
              combileArray.push(this.tempimport);
            }
            for (let j = 0; j < combileArray.length; j++) {
              let ddd = self.changeeeee(combileArray[j].date);
              tempimport1 = {
                ...combileArray[j],
                date1: ddd,
              };
              self.HolidayList.push(tempimport1);
            }
            self.uploadholidaysDialog = false;
          } else {
            alert("File name must be Holiday.xlsx");
            self.saveUploadDialog = false;
            // self.uploadholidaysDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
        self.saveUploadDialog = true;
      } else {
        alert("Please choose File");
      }
    },
    changeeeee(d) {
      let day = new Date(Math.round((d - (25567 + 2)) * 86400) * 1000);
      return day.getMonth() + 1 + "/" + day.getDate();
    },
    UploadHolidayData() {
      let self = this;
      let nowdate = new Date();
      self.finalArray = [];
      let max = self.uploadyear + 2;
      for (let i = self.uploadyear; i <= max; i++) {
        self.latestyear.push(i);
      }
      for (let l = 0; l < self.HolidayList.length; l++) {
        let [mm, dd] = self.HolidayList[l].date1.split("/");
        nowdate.setDate(dd);
        nowdate.setMonth(mm - 1);

        let mmm = null;
        let mmn = nowdate.getMonth() + 1;
        if (mmn < 10) {
          mmm = "0" + mmn;
        } else {
          mmm = mmn;
        }

        let ddd = null;
        let dd1 = nowdate.getDate();
        if (dd1 < 10) {
          ddd = "0" + dd1;
        } else {
          ddd = dd1;
        }

        for (let y = 0; y < self.latestyear.length; y++) {
          self.finalArray.push({
            wC_Name: self.HolidayList[l].holiday,
            wC_Type: "H",
            wC_Date: self.latestyear[y] + "-" + mmm + "-" + ddd,
            company_ID: store.state.companyID,
          });
        }
      }
      let temp = self.finalArray.map((v) => v.wC_Date);
      var FilterList = temp.filter((el) => self.filterdate.includes(el));
      if (FilterList.length == 0) {
        self.confirmdialog = false;
        self.ConfirmUploadHoliday();
      } else {
        self.confirmdialog = true;
      }
    },
    ConfirmUploadHoliday() {
      let self = this;
      self.LoadingDialog = true;
      axios
        .post(
          `${self.url}WorkdayCalendar/ImportWorkdayCalendar`,
          self.finalArray
        )
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.confirmdialog = false;
            self.LoadingDialog = false;
            self.latestyear = [];
            self.finalArray = [];
            self.GetHolidaysByCompany();
            self.HolidayList = [];
            self.Result = [];
            self.GetWorkDayCalendar();
            self.GetHolidayList(self.exportyear);
            self.GetYearWork();
            self.showup = false;
            self.chosenfile = null;
            self.saveUploadDialog = false;
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Upload Holidays Fail", 25, "Verbose");
          self.LoadingDialog = false;
        });
    },
    changeFebcolor(val, mon) {
      if (this.dayinfeb == 28 && mon == "Feb") {
        return "#E8E9EC";
      }
    },
    YearChange(val) {
      this.Result = [];
      this.Jan.sat = [];
      this.Jan.sun = [];
      this.Feb.sat = [];
      this.Feb.sun = [];
      this.Mar.sat = [];
      this.Mar.sun = [];
      this.Apr.sat = [];
      this.Apr.sun = [];
      this.May.sat = [];
      this.May.sun = [];
      this.Jun.sat = [];
      this.Jun.sun = [];
      this.Jul.sat = [];
      this.Jul.sun = [];
      this.Aug.sat = [];
      this.Aug.sun = [];
      this.Sep.sat = [];
      this.Sep.sun = [];
      this.Oct.sat = [];
      this.Oct.sun = [];
      this.Nov.sat = [];
      this.Nov.sun = [];
      this.Dec.sat = [];
      this.Dec.sun = [];

      var d = new Date();
      d.setMonth(0);
      for (var j = 0; j < 12; j++) {
        var getTot = daysInMonth(j + 1, val);
        let nam = this.monthNames[0 + j];
        let yer = d.getFullYear();
        d.setMonth(0 + j);
        d.setDate(1);
        for (var i = 1; i <= getTot; i++) {
          var newDate = new Date(val, d.getMonth(), i);
          if (newDate.getDay() == 0) {
            this.sun.push(i + "/" + nam + "/" + yer);
            if (nam == "Jan") {
              this.Jan.sun.push(i);
            }
            if (nam == "Feb") {
              this.Feb.sun.push(i);
            }
            if (nam == "Mar") {
              this.Mar.sun.push(i);
            }
            if (nam == "Apr") {
              this.Apr.sun.push(i);
            }
            if (nam == "May") {
              this.May.sun.push(i);
            }
            if (nam == "Jun") {
              this.Jun.sun.push(i);
            }
            if (nam == "Jul") {
              this.Jul.sun.push(i);
            }
            if (nam == "Aug") {
              this.Aug.sun.push(i);
            }
            if (nam == "Sep") {
              this.Sep.sun.push(i);
            }
            if (nam == "Oct") {
              this.Oct.sun.push(i);
            }
            if (nam == "Nov") {
              this.Nov.sun.push(i);
            }
            if (nam == "Dec") {
              this.Dec.sun.push(i);
            }
          }
          if (newDate.getDay() == 6) {
            this.sat.push(i + "/" + nam + "/" + yer);
            if (nam == "Jan") {
              this.Jan.sat.push(i);
            }
            if (nam == "Feb") {
              this.Feb.sat.push(i);
            }
            if (nam == "Mar") {
              this.Mar.sat.push(i);
            }
            if (nam == "Apr") {
              this.Apr.sat.push(i);
            }
            if (nam == "May") {
              this.May.sat.push(i);
            }
            if (nam == "Jun") {
              this.Jun.sat.push(i);
            }
            if (nam == "Jul") {
              this.Jul.sat.push(i);
            }
            if (nam == "Aug") {
              this.Aug.sat.push(i);
            }
            if (nam == "Sep") {
              this.Sep.sat.push(i);
            }
            if (nam == "Oct") {
              this.Oct.sat.push(i);
            }
            if (nam == "Nov") {
              this.Nov.sat.push(i);
            }
            if (nam == "Dec") {
              this.Dec.sat.push(i);
            }
          }
        }
      }
      this.DaysInFeb();
      this.GetWorkDayCalendar();

      function daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      }
    },
    DaysInFeb() {
      this.dayinfeb = new Date(this.filteryear, 2, 0).getDate();
    },
    NewHoliday(val, mon, item) {
      this.HolidayName = null;
      let holidaydate = this.filteryear + "/" + mon + "/" + val;
      let validate = this.filteryear + "-" + mon + "-" + val + "T00:00:00";
      this.GetHolidayName(holidaydate);
      this.ValidateHolidayDate(validate);
      this.NewholidaysDialog = true;
      this.clickdate = val + "/" + mon + "/" + this.filteryear;
      let itemvalue = item;
      if (itemvalue == "--" || itemvalue == "W") {
        //WorkingDay To Holiday
        this.WToHolidayDialog = true;
        this.NewholidaysDialog = false;
      } else {
        //Holiday To Working Day
        this.NewholidaysDialog = true;
        this.WToHolidayDialog = false;
      }
    },
    activeColor(val, mon, item) {
      if (this.Jan.sat.some((e) => e == val && mon == "Jan")) {
        return "#D9DAEB";
      }

      if (this.Feb.sat.some((e) => e == val && mon == "Feb")) {
        return "#D9DAEB";
      }

      if (this.Mar.sat.some((e) => e == val && mon == "Mar")) {
        return "#D9DAEB";
      }
      if (this.Apr.sat.some((e) => e == val && mon == "Apr")) {
        return "#D9DAEB";
      }
      if (this.May.sat.some((e) => e == val && mon == "May")) {
        return "#D9DAEB";
      }
      if (this.Jun.sat.some((e) => e == val && mon == "Jun")) {
        return "#D9DAEB";
      }
      if (this.Jul.sat.some((e) => e == val && mon == "Jul")) {
        return "#D9DAEB";
      }
      if (this.Aug.sat.some((e) => e == val && mon == "Aug")) {
        return "#D9DAEB";
      }
      if (this.Sep.sat.some((e) => e == val && mon == "Sep")) {
        return "#D9DAEB";
      }
      if (this.Oct.sat.some((e) => e == val && mon == "Oct")) {
        return "#D9DAEB";
      }
      if (this.Nov.sat.some((e) => e == val && mon == "Nov")) {
        return "#D9DAEB";
      }
      if (this.Dec.sat.some((e) => e == val && mon == "Dec")) {
        return "#D9DAEB";
      }

      if (this.Jan.sun.some((e) => e == val && mon == "Jan" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Jan.sun.some((e) => e == val && mon == "Jan" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Feb.sun.some((e) => e == val && mon == "Feb" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Feb.sun.some((e) => e == val && mon == "Feb" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Mar.sun.some((e) => e == val && mon == "Mar" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Mar.sun.some((e) => e == val && mon == "Mar" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Apr.sun.some((e) => e == val && mon == "Apr" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Apr.sun.some((e) => e == val && mon == "Apr" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.May.sun.some((e) => e == val && mon == "May" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.May.sun.some((e) => e == val && mon == "May" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Jun.sun.some((e) => e == val && mon == "Jun" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Jun.sun.some((e) => e == val && mon == "Jun" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Jul.sun.some((e) => e == val && mon == "Jul" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Jul.sun.some((e) => e == val && mon == "Jul" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Aug.sun.some((e) => e == val && mon == "Aug" && item == "H")) {
        //this.sunOption=true;
        return "#FFC8CB";
      }
      if (this.Aug.sun.some((e) => e == val && mon == "Aug" && item == "W")) {
        //this.sunOption=true;
        return "#D9DAEB";
      }
      if (this.Sep.sun.some((e) => e == val && mon == "Sep" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Sep.sun.some((e) => e == val && mon == "Sep" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Oct.sun.some((e) => e == val && mon == "Oct" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Oct.sun.some((e) => e == val && mon == "Oct" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Nov.sun.some((e) => e == val && mon == "Nov" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Nov.sun.some((e) => e == val && mon == "Nov" && item == "W")) {
        return "#D9DAEB";
      }
      if (this.Dec.sun.some((e) => e == val && mon == "Dec" && item == "H")) {
        return "#FFC8CB";
      }
      if (this.Dec.sun.some((e) => e == val && mon == "Dec" && item == "W")) {
        return "#D9DAEB";
      }
    },

    Option(val, mon) {
      if (this.Jan.sat.some((e) => e == val && mon == "Jan")) {
        return false;
      }
      if (this.Feb.sat.some((e) => e == val && mon == "Feb")) {
        return false;
      }
      if (this.Mar.sat.some((e) => e == val && mon == "Mar")) {
        return false;
      }
      if (this.Apr.sat.some((e) => e == val && mon == "Apr")) {
        return false;
      }
      if (this.May.sat.some((e) => e == val && mon == "May")) {
        return false;
      }
      if (this.Jun.sat.some((e) => e == val && mon == "Jun")) {
        return false;
      }
      if (this.Jul.sat.some((e) => e == val && mon == "Jul")) {
        return false;
      }
      if (this.Aug.sat.some((e) => e == val && mon == "Aug")) {
        //this.satOption=true;
        return false;
      }
      if (this.Sep.sat.some((e) => e == val && mon == "Sep")) {
        return false;
      }
      if (this.Oct.sat.some((e) => e == val && mon == "Oct")) {
        return false;
      }
      if (this.Nov.sat.some((e) => e == val && mon == "Nov")) {
        return false;
      }
      if (this.Dec.sat.some((e) => e == val && mon == "Dec")) {
        return false;
      }
      if (this.Jan.sun.some((e) => e == val && mon == "Jan")) {
        return false;
      }
      if (this.Feb.sun.some((e) => e == val && mon == "Feb")) {
        return false;
      }
      if (this.Mar.sun.some((e) => e == val && mon == "Mar")) {
        return false;
      }
      if (this.Apr.sun.some((e) => e == val && mon == "Apr")) {
        return false;
      }
      if (this.May.sun.some((e) => e == val && mon == "May")) {
        return false;
      }
      if (this.Jun.sun.some((e) => e == val && mon == "Jun")) {
        return false;
      }
      if (this.Jul.sun.some((e) => e == val && mon == "Jul")) {
        return false;
      }
      if (this.Aug.sun.some((e) => e == val && mon == "Aug")) {
        //this.sunOption=true;
        return false;
      }
      if (this.Sep.sun.some((e) => e == val && mon == "Sep")) {
        return false;
      }
      if (this.Oct.sun.some((e) => e == val && mon == "Oct")) {
        return false;
      }
      if (this.Nov.sun.some((e) => e == val && mon == "Nov")) {
        return false;
      }
      if (this.Dec.sun.some((e) => e == val && mon == "Dec")) {
        return false;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.simpletb table,
th,
td {
  border: 1px solid #f0f0f7;
  height: 4rem;
  width: 3rem;
}
.txt {
  color: #444;
  text-align: center;
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}
>>>.v-dialog::-webkit-scrollbar {
  width: 2px;
   background-color: none;
   margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Track */
>>>.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle */
>>>.v-dialog::-webkit-scrollbar-thumb {
  background: #f0f0f7;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle on hover */
>>>.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0f0f7;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
@media only screen and (min-width: 1024px) {
  .w {
    margin-left: 8.9rem !important;
  }
}
@media only screen and (max-width: 1281px) {
  .holidaytable {
    overflow-y: auto;
    height: 300px !important;
    overflow-x: hidden;
  }
}
.holidaytable {
  overflow-y: auto;
  height: 550px;
  overflow-x: hidden;
}
</style>
