<template>
  <div class="ml-5 mr-5">
    <br />
    <v-row class="pl-md-0" style="margin-left: 15px; margin-right: 10px; margin-top: 0rem !important">
      <!-- <p class="ml-2 mt-5" style="font-size: 17px; color: #f89d1f">
        {{ $t("provdentFundDetails") }}
      </p> -->
        <img class="funIcon mt-2" style="margin-top:auto;margin-bottom:auto;" :src="images.funIcon" alt="funIcon"/>
        <div class="ml-3 mt-2">
          <label style="font-size: 17px; color: #f89d1f">
            {{ $t("provdentFundDetails") }}
          </label>
        </div>
    </v-row>

    <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
      <v-col cols="12" md="3">
        <label style="color: #8a8c8e">{{ $t("fundAccountNumber") }}</label>
        <v-text-field
          autocomplete="off"
          @keypress="onlyNumber"
          color="#F99D20"
          v-model="provident.fundnumber"
          :disabled="mydisable"
          class="empID"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <label class="ml-5" style="font-size: 16px; color: #f89d1f">
      {{ $t("employmentContribution") }}
    </label>
    
    <v-row class="mt-2" style="margin-left: 15px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3">
        <label style="color: #8a8c8e">{{ $t("cumculativeAmount") }}</label>
        <v-text-field
          autocomplete="off"
          readonly
          :disabled="mydisable"
          v-model="provident.empcumulative"
          color="#F99D20"
          class="empID"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <label :style="{color: error.empdate ? ' #FF4B55' : '#8a8c8e'}">{{ $t("cumulativeStartDate") }}<span class="red--text"> *</span></label>
        <!-- <v-menu
          v-model="menu6"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          fluid
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              autocomplete="off"
              class="empID"
              :disabled="mydisable"
              :error-messages="error.empdate"
              label="DD/MM/YY"
              append-icon="mdi-calendar-month-outline"
              v-model="computedDateFormatted6"
              color="#F99D20"
              persistent-hint
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @change="changedate()"
            v-model="enddate"
            no-title
            @input="menu6 = false"
          ></v-date-picker>
        </v-menu> -->
        <date-picker :class=" error.empdate ? 'custom-vuedatepicker-er-mg mt-3' : 'custom-vuedatepicker mt-3'" v-bind:clearable="false" value-type="format" v-model="enddate" :disabled="mydisable"
        @change="changedate()" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
        <span v-if="error.empdate" class="error-message">{{ error.empdate }}</span>
      </v-col>
      <v-col cols="12" sm="6" md="5">
        <v-list-item-title style="color: #8a8c8e">{{ $t("contributionToTheFund") }}</v-list-item-title>
        <v-text-field
          autocomplete="off"
          :disabled="mydisable"
          color="#F99D20"
          readonly
          v-model="provident.empafteryear"
          class="empID"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <label class="ml-5" style="font-size: 16px; color: #f89d1f">
      {{ $t("companyContribution") }}
    </label>
    
    <v-row class="pl-md-0 mt-2" style="margin-left: 15px; margin-right: 10px">
      <v-col cols="12" md="3">
        <label style="color: #8a8c8e">{{ $t("cumculativeAmount") }}</label>
        <v-text-field
          autocomplete="off"
          readonly
          :disabled="mydisable"
          v-model="provident.comcumulative"
          color="#F99D20"
          class="empID"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <label :style="{color: error.comdate ? ' #FF4B55' : '#8a8c8e'}">{{ $t("cumulativeStartDate") }}<span class="red--text"> *</span></label>
        <!-- <v-menu
          v-model="menu7"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          fluid
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              autocomplete="off"
              :disabled="mydisable"
              class="empID"
              :error-messages="error.comdate"
              label="DD/MM/YY"
              append-icon="mdi-calendar-month-outline"
              v-model="computedDateFormatted7"
              color="#F99D20"
              persistent-hint
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @change="changedate1()"
            v-model="startdate"
            no-title
            @input="menu7 = false"
          ></v-date-picker>
        </v-menu> -->
        <date-picker :class=" error.comdate ? 'custom-vuedatepicker-er-mg mt-3' : 'custom-vuedatepicker mt-3'" v-bind:clearable="false" value-type="format" v-model="startdate" :disabled="mydisable"
        @change="changedate1()" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
        <span v-if="error.comdate" class="error-message">{{ error.comdate }}</span>
      </v-col>
      <v-col cols="12" md="5">
        <v-list-item-title style="color: #8a8c8e">
          {{ $t("contributionToTheFund") }}
        </v-list-item-title>
        <v-text-field
          autocomplete="off"
          readonly
          v-model="provident.compafteryear"
          :disabled="mydisable"
          color="#F99D20"
          class="empID">
        </v-text-field>
      </v-col>
    </v-row>
    <div
      style="
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: 100px;
        padding-bottom: 3rem;
      "
    >
      <v-row v-show="showedit">
        <v-col md="12">
          <v-btn
            class="mr-5 text_transform_none float-right btn_edit commonsavebtn"
            @click="Showandhide"
            :disabled="
              $route.path ==
              '/selfservice/PersonalProfile/PersonalInformation/ProvidentFund'
                ? permission.edit
                : permission_management.edit
            "
            >{{ $t("edit") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showsavewithid">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 text_transform_none float-right btn_edit commonsavebtn"
            :disabled="permission_management.add"
            @click="AddProvidentWithEid()"
            >{{ $t("save") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showsavewithsid">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 text_transform_none float-right btn_edit commonsavebtn"
            :disabled="permission.add"
            @click="AddProvidentWithSid()"
            >{{ $t("save") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showsavewithcode">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 text_transform_none float-right btn_edit commonsavebtn"
            :disabled="permission_management.add"
            @click="AddProvidentWithCode()"
            >{{ $t("save") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showbyid">
        <v-spacer></v-spacer>
        <div style="margin-right: 1.5rem">
          <v-btn
            outlined
            class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn"
            @click="hidethis"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="pa-2 text_transform_none save_btn commonsavebtn"
            :disabled="permission_management.edit"
            @click="EditProvidentWithEid()"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
      <v-row v-show="showbysid">
        <v-spacer></v-spacer>
        <div style="margin-right: 1.5rem">
          <v-btn
            class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn"
            @click="hidethis"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F89D1F"
            style="font-size: 15px; font-family: 'Kanit', sans-serif"
            class="pa-2 text_transform_none save_btn"
            rounded
            width="160px"
            height="50px"
            :disabled="permission.edit"
            @click="EditProvidentWithSid()"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
      <v-row v-show="showbycode">
        <v-spacer></v-spacer>
        <div style="margin-right: 1.5rem">
          <v-btn
            class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn"
            @click="hidethis"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F89D1F"
            style="font-size: 15px; font-family: 'Kanit', sans-serif"
            class="pa-2 text_transform_none btn_edit save_btn"
            rounded
            width="160px"
            height="50px"
            :disabled="permission_management.edit"
            @click="EditProvidentWithCode()"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
    </div>
    
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import CheckViewAddEdit from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, CheckViewAddEdit],
  data() {
    return {
      LoadingDialog: false,
      permission: {
        add: true,
        edit: true,
      },
      permission_management: {
        add: true,
        edit: true,
      },
      gg: "",
      showhidebutton: true,
      url: enurl.apiUrl,
      aa: "",
      startdate: "",
      enddate: "",
      mydisable: false,
      showedit: false,
      showsavewithid: false,
      showsavewithsid: false,
      showsavewithcode: false,
      showbyid: false,
      showbysid: false,
      showbycode: false,
      error: {
        empcumulative: null,
        empdate: "",
        empafteryear: null,
        comcumulative: null,
        comdate: "",
        compafteryear: null,
      },
      PersonalList: [],
      EditPersonalList: [],
      ProvidentList: [],
      EditProvidentList: [],
      provident: {
        fundnumber: null,
        empcumulative: null,
        empafteryear: "",
        comcumulative: null,
        compafteryear: "",
      },
      ProvidentAlllist: [],
      ProvidentData: [],
      NewProvidentdata: [],
      fullyear: "",
      temp: [],
      edittemp: [],
      SalaryList: [],
      SidSalaryList: [],
      newmonth: "",
      menu6: false,
      menu7: false,
    };
  },
  watch: {
    // "provident.fundnumber"(v) {
    //   if (v) this.error.fundnumber = "";
    // },
    enddate(v) {
      if (v) this.error.empdate = "";
    },
    startdate(v) {
      if (v) this.error.comdate = "";
    },
  },
  mounted() {
    this.GetProvidentfund();
    this.permission = this.CheckViewAddEdit(134, 137, 138);
    this.permission_management = this.CheckViewAddEdit(208, 209, 210);
    this.disable_btn();
    if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/ProvidentFund")
    {
      this.GetsalaryWithSid();
      this.GetmyprovidentBySid();
    }
    else if (this.$route.params.id != null)
    {
      this.Getsalary();
      this.Getmyprovident();
    }
    else if (localStorage.empcode != null)
    {
      this.showsavewithcode = true;
      this.Getnewempprovident();
    }
    else
    {
      this.showsavewithcode = true;
    }
  },
  computed: {
    images() {
      return this.$store.state.images;
    }
  },
  methods: {
    //permission
    disable_btn() {
      let self = this;
      if (
        self.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/ProvidentFund"
      )
      {
        if (self.permission.add == false)
        {
          self.permission.add = false;
        }
        if (self.permission.edit == false)
        {
          self.permission.edit = false;
        }
      }
      else
      {
        if (self.permission_management.add == false)
        {
          self.permission_management.add = false;
        }
        if (self.permission_management.edit == false)
        {
          self.permission_management.edit = false;
        }
      }
    },
    // show and hide add and edit buttons
    Showandhide() {
      let self = this;
      self.showedit = false;
      self.mydisable = false;
      if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/ProvidentFund")
      {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = false;
        self.showbysid = true;
        self.showbycode = false;
      }
      else if (self.$route.params.id != undefined)
      {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = true;
        self.showbysid = false;
        self.showbycode = false;
      }
      else
      {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = false;
        self.showbysid = false;
        self.showbycode = true;
      }
    },

    hidethis() {
      this.showedit = true;
      this.mydisable = true;
      this.showsavewithid = false;
      this.showsavewithsid = false;
      this.showsavewithcode = false;
      this.showbyid = false;
      this.showbysid = false;
      this.showbycode = false;
    },

    // validate fund number
    fundnumbervalidation(fund) {
      let teleph = /^\d{13}$/;
      if (teleph.test(fund))
      {
        return true;
      }
      else
      {
        return "Invalid Fund number format";
      }
    },
    // only number allow
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    // get provident when click start date
    changedate() {
      if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/ProvidentFund")
      {
        this.GetEmployeeWithSid();
      }
      else if (this.$route.params.id != null)
      {
        this.GetEditEmployee();
      }
      else
      {
        this.GetEmployee();
      }
    },

    changedate1() {
      if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/ProvidentFund")
      {
        this.GetEmployeeWithSid();
      }
      else if (this.$route.params.id != null)
      {
        this.GetEditEmployee();
      }
      else
      {
        this.GetEmployee();
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    numberWithCommas(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    removecomma(x) {
      if (x != 0 && x != null)
      {
        return x.replace(/,/g, "");
      }
      else
      {
        return "0";
      }
    },

    // get cumulative amount from route employee id
    GetEmployee() {
      let self = this;
      if (localStorage.empcode != "undefined")
      {
        let temp = {
          employee_Code: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}PersonalInfo/GetPersonalInfoByEmpcode`, temp)
        .then(function (response) {
            self.PersonalList = response.data.data;
            let aa = self.PersonalList[0].hiredDate;
            let bb = aa.split("T")[0];
            let month = bb.split("-")[1];
            let year = bb.split("-")[0];
            let mymonth = 13 - month;
            var someDate = new Date();
            var thisyear = someDate.getFullYear();
            var currentmonth = someDate.getMonth();
            let fullyear = thisyear - year;
            if (fullyear != null)
            {
              let thismonth = 0;
              if (fullyear == 0)
              {
                thismonth = currentmonth + 1 - month;
                self.newmonth = thismonth;
              }
              if (fullyear > 0)
              {
                thismonth = (fullyear - 1) * 12 + currentmonth;
                self.newmonth = thismonth + mymonth;
              }

              if (self.ProvidentAlllist.length == 0)
              {
                if (self.enddate != "")
                {
                  self.provident.empafteryear = 0;
                  self.provident.empcumulative = 0;
                }
                if (self.startdate != "")
                {
                  self.provident.compafteryear = 0;
                  self.provident.comcumulative = 0;
                }
              }
              let validatte = false;
              for (let i = 0; i < self.ProvidentAlllist.length; i++)
              {
                if (!validatte)
                {
                  if (self.ProvidentAlllist[i].lengthOfEmployment == 1)
                  {
                    let lessyear = self.ProvidentAlllist[i].year * 12;
                    if (self.newmonth < lessyear)
                    {
                      self.temp = {
                        year: self.ProvidentAlllist[i].year,
                        lengthOfEmployment: self.ProvidentAlllist[i].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.temp = {};
                    }
                  }

                  if (self.ProvidentAlllist[i].lengthOfEmployment == 2)
                  {
                    let greateryear = self.ProvidentAlllist[i].year * 12;
                    if (self.newmonth > greateryear)
                    {
                      self.temp = {
                        year: self.ProvidentAlllist[i].year,
                        lengthOfEmployment: self.ProvidentAlllist[i].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.temp = {};
                    }
                  }

                  if (self.ProvidentAlllist[i].lengthOfEmployment == 3)
                  {
                    let betweenstartyear = self.ProvidentAlllist[i].year * 12;
                    let betweenendyear = self.ProvidentAlllist[i].endYear * 12;
                    if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear)
                    {
                      self.temp = {
                        year: self.ProvidentAlllist[i].year,
                        lengthOfEmployment: self.ProvidentAlllist[i].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.temp = {};
                    }
                  }

                  if (self.ProvidentAlllist[i].lengthOfEmployment == 4)
                  {
                    let equalyear = self.ProvidentAlllist[i].year * 12;
                    if (self.newmonth == equalyear)
                    {
                      self.temp = {
                        year: self.ProvidentAlllist[i].year,
                        lengthOfEmployment: self.ProvidentAlllist[i].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.temp = {};
                    }
                  }
                  if (validatte) 
                  {
                    axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.temp).then(function (response) {
                      if(response.data.status === 0)
                      {
                        self.ProvidentList = response.data.data;
                        if (self.enddate != "")
                        {
                          if (self.ProvidentList.length != 0)
                          {
                            if (localStorage.totalsalary != undefined && self.ProvidentList[0].employmentContribution != null)
                            {
                              let beforeyear = parseFloat((self.ProvidentList[0].employmentContribution / 100) * localStorage.totalsalary);
                              let myempstartdate = self.enddate.split("/")[1];
                              let myempstartyear = self.enddate.split("/")[0];
                              let allmonth = (someDate.getFullYear() - myempstartyear) * 12 - (myempstartdate - 1);
                              let ff = beforeyear * allmonth;
                              if (myempstartyear != someDate.getFullYear())
                              {
                                let ee = beforeyear * currentmonth;
                                self.provident.empafteryear = self.numberWithCommas(parseFloat(ff).toFixed(2));
                                self.provident.empcumulative = self.numberWithCommas(parseFloat(ee).toFixed(2));
                              }
                              else
                              {
                                self.provident.empafteryear = 0;
                                let thiscurrentmonth = someDate.getMonth() + 1;
                                let calculatemonth = thiscurrentmonth - myempstartdate;
                                self.provident.empcumulative = parseFloat(calculatemonth * beforeyear).toFixed(2);
                                self.provident.empafteryear = self.numberWithCommas(self.provident.empafteryear);
                                self.provident.empcumulative = self.numberWithCommas(self.provident.empcumulative);
                              }
                            }
                          }
                          else
                          {
                            self.provident.empafteryear = 0;
                            self.provident.empcumulative = 0;
                          }
                        }

                        if (self.startdate != "" && self.startdate != null)
                        {
                          if (self.ProvidentList.length != 0)
                          {
                            if (localStorage.totalsalary != undefined && self.ProvidentList[0].companyContribution != null)
                            {
                              let lastyear = parseFloat((self.ProvidentList[0].companyContribution / 100) * localStorage.totalsalary);
                              let mycomstartdate = self.startdate.split("/")[1];
                              let mycomstartyear = self.startdate.split("/")[0];
                              let allmonth = (someDate.getFullYear() - mycomstartyear) * 12 - (mycomstartdate - 1);
                              let ff = lastyear * allmonth;
                              if (mycomstartyear != someDate.getFullYear())
                              {
                                let ee = lastyear * currentmonth;
                                self.provident.compafteryear = ff;
                                self.provident.comcumulative = ee;
                              }
                              else
                              {
                                self.provident.compafteryear = parseInt(0);
                                let thiscurrentmonth = someDate.getMonth() + 1;
                                let calculatemonth = thiscurrentmonth - mycomstartdate;
                                self.provident.comcumulative = parseFloat(calculatemonth * lastyear).toFixed(2);
                                self.provident.compafteryear = self.numberWithCommas(self.provident.compafteryear);
                                self.provident.comcumulative = self.numberWithCommas(self.provident.comcumulative);
                              }
                            }
                          }
                          else
                          {
                            self.provident.compafteryear = 0;
                            self.provident.comcumulative = 0;
                          }
                        }
                      }
                    });
                  }
                  else
                  {
                    if (self.enddate != "" && self.enddate != null)
                    {
                      self.provident.empafteryear = 0;
                      self.provident.empcumulative = 0;
                    }
                    if (self.startdate != "" && self.startdate != null)
                    {
                      self.provident.compafteryear = 0;
                      self.provident.comcumulative = 0;
                    }
                  }
                }
              }
              //end of looping
            }
          });
      }
    },

    GetEditEmployee() {
      let self = this;
      if (self.$route.params.id != "")
      {
        let temp = {
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
          .then(function (response) {
            self.EditPersonalList = response.data.data;
            let aa = self.EditPersonalList[0].hiredDate;
            let bb = aa.split("T")[0];
            let month = bb.split("-")[1];
            let year = bb.split("-")[0];
            let mymonth = 12 - month;
            var someDate = new Date();
            var thisyear = someDate.getFullYear();
            var currentmonth = someDate.getMonth();
            let fullyear = thisyear - year;
            if (fullyear != null)
            {
              let thismonth = 0;
              if (fullyear == 0)
              {
                thismonth = currentmonth + 1 - month;
                self.newmonth = thismonth;
              }
              if (fullyear > 0)
              {
                thismonth = (fullyear - 1) * 12 + currentmonth;
                self.newmonth = thismonth + mymonth;
              }
              if (self.ProvidentAlllist.length == 0)
              {
                if (self.enddate != "")
                {
                  self.provident.empafteryear = 0;
                  self.provident.empcumulative = 0;
                }
                if (self.startdate != "")
                {
                  self.provident.compafteryear = 0;
                  self.provident.comcumulative = 0;
                }
              }
              let validatte = false;
              for (let j = 0; j < self.ProvidentAlllist.length; j++)
              {
                if (!validatte)
                {
                  if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                  {
                    let lessyear = self.ProvidentAlllist[j].year * 12;
                    if (self.newmonth < lessyear)
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.edittemp = {};
                    }
                  }

                  if (self.ProvidentAlllist[j].lengthOfEmployment == 2)
                  {
                    let greateryear = self.ProvidentAlllist[j].year * 12;
                    if (self.newmonth > greateryear)
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.edittemp = {};
                    }
                  }

                  if (self.ProvidentAlllist[j].lengthOfEmployment == 3)
                  {
                    let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                    let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                    if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear)
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.edittemp = {};
                    }
                  }

                  if (self.ProvidentAlllist[j].lengthOfEmployment == 4)
                  {
                    let equalyear = self.ProvidentAlllist[j].year * 12;
                    if (self.newmonth == equalyear)
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      validatte = true;
                    }
                    else
                    {
                      self.edittemp = {};
                    }
                  }

                  if (validatte)
                  {
                    axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                    .then(function (response) {
                      if(response.data.status === 0)
                      {
                        self.EditProvidentList = response.data.data;
                        if (self.enddate != "" && self.enddate != null)
                        {
                          if (self.SalaryList.length != 0 && self.EditProvidentList.length != 0)
                          {
                            if (self.SalaryList[0].salary_Amount != "undefined" && self.EditProvidentList[0].employmentContribution != null)
                            {
                              let beforeyear = parseFloat((self.EditProvidentList[0].employmentContribution / 100) * self.SalaryList[0].salary_Amount);
                              let myempstartdate = self.enddate.split("/")[1];
                              let myempstartyear = self.enddate.split("/")[0];
                              let allmonth = (someDate.getFullYear() - myempstartyear) * 12 - (myempstartdate - 1);
                              let ff = beforeyear * allmonth;
                              if (myempstartyear != someDate.getFullYear())
                              {
                                let ee = beforeyear * currentmonth;
                                self.provident.empafteryear = self.numberWithCommas(parseFloat(ff).toFixed(2));
                                self.provident.empcumulative = self.numberWithCommas(parseFloat(ee).toFixed(2));
                              }
                              else
                              {
                                let forzero = 0;
                                self.provident.empafteryear = forzero.toString();
                                let thiscurrentmonth = someDate.getMonth() + 1;
                                let calculatemonth = thiscurrentmonth - myempstartdate;
                                self.provident.empcumulative = parseFloat(calculatemonth * beforeyear).toFixed(2);
                                self.provident.empafteryear = self.numberWithCommas(self.provident.empafteryear);
                                self.provident.empcumulative = self.numberWithCommas(self.provident.empcumulative);
                              }
                            }
                          }
                          else
                          {
                            self.provident.empafteryear = 0;
                            self.provident.empcumulative = 0;
                          }
                        }

                        if (self.startdate != "" && self.startdate != null)
                        {
                          if (self.SalaryList.length != 0 && self.EditProvidentList.length != 0)
                          {
                            if (localStorage.totalsalary != "undefined" && self.EditProvidentList[0].companyContribution != null)
                            {
                              let lastyear = parseFloat((self.EditProvidentList[0].companyContribution / 100) * self.SalaryList[0].salary_Amount);
                              let mycomstartdate = self.startdate.split("/")[1];
                              let mycomstartyear = self.startdate.split("/")[0];
                              let allmonth = (someDate.getFullYear() - mycomstartyear) * 12 - (mycomstartdate - 1);
                              let ff = lastyear * allmonth;
                              if (mycomstartyear != someDate.getFullYear())
                              {
                                let ee = lastyear * currentmonth;
                                self.provident.compafteryear = self.numberWithCommas(parseFloat(ff).toFixed(2));
                                self.provident.comcumulative = self.numberWithCommas(parseFloat(ee).toFixed(2));
                              }
                              else
                              {
                                let forzero = 0;
                                self.provident.compafteryear = forzero.toString();
                                let thiscurrentmonth = someDate.getMonth() + 1;
                                let calculatemonth = thiscurrentmonth - mycomstartdate;
                                let mycomcumulative = calculatemonth * lastyear;
                                self.provident.comcumulative = parseFloat(mycomcumulative).toFixed(2);
                                self.provident.compafteryear = self.numberWithCommas(self.provident.compafteryear);
                                self.provident.comcumulative = self.numberWithCommas(self.provident.comcumulative);
                              }
                            }
                          }
                          else
                          {
                            self.provident.compafteryear = 0;
                            self.provident.comcumulative = 0;
                          }
                        }
                      }
                    });
                  }
                  else
                  {
                    if (self.enddate != "" && self.enddate != null)
                    {
                      self.provident.empafteryear = 0;
                      self.provident.empcumulative = 0;
                    }
                    if (self.startdate != "" && self.startdate != null)
                    {
                      self.provident.compafteryear = 0;
                      self.provident.comcumulative = 0;
                    }
                  }
                }
              }
            }
          });
      }
    },

    GetEmployeeWithSid() {
      let self = this;
      if (store.state.employeeID != "")
      {
        let temp = {
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
        .then(function (response) {
          self.EditPersonalList = response.data.data;
          let aa = self.EditPersonalList[0].hiredDate;
          let bb = aa.split("T")[0];
          let month = bb.split("-")[1];
          let year = bb.split("-")[0];
          let mymonth = 13 - month;
          var someDate = new Date();
          var thisyear = someDate.getFullYear();
          var currentmonth = someDate.getMonth();
          let fullyear = thisyear - year;
          if (fullyear != null)
          {
            let thismonth = 0;
            if (fullyear == 0)
            {
              thismonth = currentmonth + 1 - month;
              self.newmonth = thismonth;
            }
            if (fullyear > 0)
            {
              thismonth = (fullyear - 1) * 12 + currentmonth;
              self.newmonth = thismonth + mymonth;
            }
            if (self.ProvidentAlllist.length == 0)
            {
              if (self.enddate != "")
              {
                self.provident.empafteryear = 0;
                self.provident.empcumulative = 0;
              }
              if (self.startdate != "")
              {
                self.provident.compafteryear = 0;
                self.provident.comcumulative = 0;
              }
            }
            let validatte = false;
            for (let j = 0; j < self.ProvidentAlllist.length; j++)
            {
              if (!validatte) {

                if (self.ProvidentAlllist[j].lengthOfEmployment == 1)
                {
                  let lessyear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth < lessyear)
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    validatte = true;
                  }
                  else
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 2)
                {
                  let greateryear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth > greateryear)
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    validatte = true;
                  }
                  else
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 3)
                {
                  let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                  let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                  if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear)
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    validatte = true;
                  }
                  else
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 4)
                {
                  let equalyear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth == equalyear)
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    validatte = true;
                  }
                  else
                  {
                    self.edittemp = {};
                  }
                }
                if (validatte)
                {
                  axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                    .then(function (response) {
                      self.EditProvidentList = response.data.data;
                      if (self.enddate != "" && self.enddate != null)
                      {
                        if (self.SidSalaryList.length != 0 && self.EditProvidentList.length != 0)
                        {
                          if (self.SidSalaryList[0].salary_Amount != "undefined" && self.EditProvidentList[0].employmentContribution != null)
                          {
                            let beforeyear = parseFloat((self.EditProvidentList[0].employmentContribution / 100) *self.SidSalaryList[0].salary_Amount);
                            let myempstartdate = self.enddate.split("/")[1];
                            let myempstartyear = self.enddate.split("/")[0];
                            let allmonth = (someDate.getFullYear() - myempstartyear) * 12 - (myempstartdate - 1);
                            let ff = beforeyear * allmonth;
                            if (myempstartyear != someDate.getFullYear())
                            {
                              let ee = beforeyear * currentmonth;
                              self.provident.empafteryear = self.numberWithCommas(parseFloat(ff).toFixed(2));
                              self.provident.empcumulative = self.numberWithCommas(parseFloat(ee).toFixed(2));
                            }
                            else
                            {
                              let forzero = 0;
                              self.provident.empafteryear = forzero.toString();
                              let thiscurrentmonth = someDate.getMonth() + 1;
                              let calculatemonth = thiscurrentmonth - myempstartdate;
                              self.provident.empcumulative = parseFloat(calculatemonth * beforeyear).toFixed(2);
                              self.provident.empafteryear = self.numberWithCommas(self.provident.empafteryear);
                              self.provident.empcumulative = self.numberWithCommas(self.provident.empcumulative);
                            }
                          }
                        }
                        else
                        {
                          self.provident.empafteryear = 0;
                          self.provident.empcumulative = 0;
                        }
                      }
                      if (self.startdate != "" && self.startdate != null)
                      {
                        if (self.SidSalaryList.length != 0 && self.EditProvidentList.length != 0)
                        {
                          if (localStorage.totalsalary != "undefined" && self.EditProvidentList[0].companyContribution != null)
                          {
                            let lastyear = parseFloat((self.EditProvidentList[0].companyContribution / 100) * self.SidSalaryList[0].salary_Amount);
                            let mycomstartdate = self.startdate.split("/")[1];
                            let mycomstartyear = self.startdate.split("/")[0];
                            let allmonth = (someDate.getFullYear() - mycomstartyear) * 12 - (mycomstartdate - 1);
                            let ff = lastyear * allmonth;
                            if (mycomstartyear != someDate.getFullYear())
                            {
                              let ee = lastyear * currentmonth;
                              self.provident.compafteryear = self.numberWithCommas(parseFloat(ff).toFixed(2));
                              self.provident.comcumulative = self.numberWithCommas(parseFloat(ee).toFixed(2));
                            }
                            else
                            {
                              let forzero = 0;
                              self.provident.compafteryear =  forzero.toString();
                              let thiscurrentmonth = someDate.getMonth() + 1;
                              let calculatemonth = thiscurrentmonth - mycomstartdate;
                              let mycomcumulative = calculatemonth * lastyear;
                              self.provident.comcumulative = parseInt(mycomcumulative).toFixed(2);
                              self.provident.compafteryear = self.numberWithCommas(self.provident.compafteryear);
                              self.provident.comcumulative = self.numberWithCommas(self.provident.comcumulative);
                            }
                          }
                        }
                        else
                        {
                          self.provident.compafteryear = 0;
                          self.provident.comcumulative = 0;
                        }
                      }
                    });
                }
                else
                {
                  if (self.enddate != "" && self.enddate != null)
                  {
                    self.provident.empafteryear = 0;
                    self.provident.empcumulative = 0;
                  }
                  if (self.startdate != "" && self.startdate != null)
                  {
                    self.provident.compafteryear = 0;
                    self.provident.comcumulative = 0;
                  }
                }
              }
            }
          }
        });
      }
    },

    // Get provident list
    GetProvidentfund() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Providentfund/GetProvidentAll`, tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.ProvidentAlllist = response.data.data;
        }
        self.LoadingDialog = false;
      })
      .catch(function (error) {
        alert(error);
      });
    },

    // Get employee provident fund with route employee ID
    Getmyprovident() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: parseInt(self.$route.params.id),
      };
      axios.post(`${self.url}Emp_Provident/GetEmp_Provident`, tempp)
      .then(function (response) {
        self.LoadingDialog = false;
        if(response.data.status == 0)
        {
          self.ProvidentData = response.data.data;
          if (self.$route.params.id != null) 
          {
            if (self.ProvidentData.length == 0) 
            {
              self.mydisable = false;
              self.showedit = false;
              self.showsavewithid = true;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
            } 
            else 
            {
              self.mydisable = true;
              self.showedit = true;
              self.showsavewithid = false;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
            }
          }
          if (self.ProvidentData.length != 0) 
          {
            //console.log(self.$moment(self.ProvidentData[0].cmp_Cumulative_Startdate).format('DD/MM/YYYY'))
            self.provident.fundnumber = self.ProvidentData[0].fund_Acc_Num;
            self.provident.empcumulative = self.ProvidentData[0].emp_Cumulative_Amt;
            self.enddate = self.$moment(self.ProvidentData[0].emp_Cumulative_Startdate).format('DD/MM/YYYY');
            self.provident.empafteryear = self.ProvidentData[0].emp_Contribution;
            self.provident.comcumulative = self.ProvidentData[0].cmp_Cumulative_Amt;
            self.startdate = self.$moment(self.ProvidentData[0].cmp_Cumulative_Startdate).format('DD/MM/YYYY');
            self.provident.compafteryear = self.ProvidentData[0].cmp_Contribution;
          }
          self.provident.empcumulative = self.numberWithCommas(self.ProvidentData[0].emp_Cumulative_Amt);
          self.provident.empafteryear = self.numberWithCommas(self.ProvidentData[0].emp_Contribution);
          self.provident.comcumulative = self.numberWithCommas(self.ProvidentData[0].cmp_Cumulative_Amt);
          self.provident.compafteryear = self.numberWithCommas(self.ProvidentData[0].cmp_Contribution);
        }
        else
        {
          alert(response.data.message);
        }
        //self.LogTrace(null, "Get Provident Fund", 17, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Provident Fund Fail", 17, "Critical");
        //alert(error);
      });
    },

    // Get employee provident fund with session employee ID
    GetmyprovidentBySid() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: store.state.employeeID,
      };
      axios.post(`${self.url}Emp_Provident/GetEmp_Provident`, tempp)
      .then(function (response) {
        self.LoadingDialog = false;
        if(response.data.status == 0)
        {
          self.ProvidentData = response.data.data;      
          if (store.state.employeeID != null) 
          {
            if (self.ProvidentData.length == 0)
            {
              self.mydisable = false;
              self.showedit = false;
              self.showsavewithid = false;
              self.showsavewithsid = true;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
            } 
            else 
            {
              self.mydisable = true;
              self.showedit = true;
              self.showsavewithid = false;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
            }
          }
          if (self.ProvidentData.length != 0) 
          {
            //console.log(self.$moment(self.ProvidentData[0].cmp_Cumulative_Startdate).format('DD/MM/YYYY'))
            self.provident.fundnumber = self.ProvidentData[0].fund_Acc_Num;
            self.provident.empcumulative = self.ProvidentData[0].emp_Cumulative_Amt;
            self.enddate = self.$moment(self.ProvidentData[0].emp_Cumulative_Startdate).format('DD/MM/YYYY');
            self.provident.empafteryear = self.ProvidentData[0].emp_Contribution;
            self.provident.comcumulative = self.ProvidentData[0].cmp_Cumulative_Amt;
            self.startdate = self.$moment(self.ProvidentData[0].cmp_Cumulative_Startdate).format('DD/MM/YYYY');
            self.provident.compafteryear = self.ProvidentData[0].cmp_Contribution;
          }
          self.provident.empcumulative = self.numberWithCommas(self.ProvidentData[0].emp_Cumulative_Amt);
          self.provident.empafteryear = self.numberWithCommas(self.ProvidentData[0].emp_Contribution);
          self.provident.comcumulative = self.numberWithCommas(self.ProvidentData[0].cmp_Cumulative_Amt);
          self.provident.compafteryear = self.numberWithCommas(self.ProvidentData[0].cmp_Contribution);
        }
        else
        {
          alert(response.data.message);
        }
        //self.LogTrace(null, "Get Provident Fund", 17, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Provident Fund Fail", 17, "Critical");
        //alert(error);
      });
    },

    // Get employee provident fund employee code
    Getnewempprovident() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Emp_Provident/GetNewEmp_Provident`, tempp)
      .then(function (response) {
        self.LoadingDialog = false;
        if(response.data.status == 0)
        {
          self.NewProvidentdata = response.data.data;
          if (self.NewProvidentdata.length != 0)
          {
            self.mydisable = true;
            self.showedit = true;
            self.showsavewithid = false;
            self.showsavewithsid = false;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
          }
          else
          {
            self.mydisable = false;
            self.showedit = false;
            self.showsavewithid = false;
            self.showsavewithsid = false;
            self.showsavewithcode = true;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
          }
          if (self.NewProvidentdata.length != 0)
          {
            self.provident.fundnumber = self.NewProvidentdata[0].fund_Acc_Num;
            self.provident.empcumulative = self.NewProvidentdata[0].emp_Cumulative_Amt;
            // self.enddate = self.NewProvidentdata[0].emp_Cumulative_Startdate;
            self.enddate = self.$moment(self.NewProvidentdata[0].emp_Cumulative_Startdate).format('DD/MM/YYYY');
            self.startdate = self.$moment(self.NewProvidentdata[0].cmp_Cumulative_Startdate).format('DD/MM/YYYY');
            self.provident.empafteryear = self.NewProvidentdata[0].emp_Contribution;
            self.provident.comcumulative = self.NewProvidentdata[0].cmp_Cumulative_Amt;
            // self.startdate = self.NewProvidentdata[0].cmp_Cumulative_Startdate;
            self.provident.compafteryear = self.NewProvidentdata[0].cmp_Contribution;
          }
          self.provident.empcumulative = self.numberWithCommas(self.ProvidentData[0].emp_Cumulative_Amt);
          self.provident.empafteryear = self.numberWithCommas(self.ProvidentData[0].emp_Contribution);
          self.provident.comcumulative = self.numberWithCommas(self.ProvidentData[0].cmp_Cumulative_Amt);
          self.provident.compafteryear = self.numberWithCommas(self.ProvidentData[0].cmp_Contribution);
        }
        else
        {
          alert(response.data.message)
        }
        //self.LogTrace(null, "Get Provident Fund", 17, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Provident Fund Fail", 17, "Critical");
        //alert(error);
      });
    },

    // Add provident with route employee iD
    AddProvidentWithEid() {
      let self = this;
      let tempvalidate = self.validateedit();
      if (tempvalidate)
      {
        self.LoadingDialog = true;
        let temp = {
          fund_Acc_Num: self.provident.fundnumber,
          emp_Cumulative_Amt: self.removecomma(self.provident.empcumulative),
          //emp_Cumulative_Startdate: self.enddate,
          emp_Cumulative_Startdate: self.$moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          emp_Contribution: self.removecomma(self.provident.empafteryear),
          cmp_Cumulative_Amt: self.removecomma(self.provident.comcumulative),
          //cmp_Cumulative_Startdate: self.startdate,
          cmp_Cumulative_Startdate: self.$moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          cmp_Contribution: self.removecomma(self.provident.compafteryear),
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}Emp_Provident/AddNewEmp_Provident`, temp)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetform();
            self.Getmyprovident();
            //self.LogTrace(null, "Add Provident Fund", 17, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Provident Fund Fail", 17, "Low");
          alert(error);
          self.LoadingDialog = false;
        });
      }
      else
      {
        self.validateediterror();
        self.LoadingDialog = false;
      }
    },

    // add provident with session employee ID
    AddProvidentWithSid() {
      let self = this;
      let tempvalidate = self.validateedit();
      if (tempvalidate)
      {
        self.LoadingDialog = true;
        let temp = {
          fund_Acc_Num: self.provident.fundnumber,
          emp_Cumulative_Amt: self.removecomma(self.provident.empcumulative),
          // emp_Cumulative_Startdate: self.enddate,
          emp_Cumulative_Startdate: self.$moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          emp_Contribution: self.removecomma(self.provident.empafteryear),
          cmp_Cumulative_Amt: self.removecomma(self.provident.comcumulative),
          // cmp_Cumulative_Startdate: self.startdate,
          cmp_Cumulative_Startdate: self.$moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          cmp_Contribution: self.removecomma(self.provident.compafteryear),
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}Emp_Provident/AddNewEmp_Provident`, temp)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0) 
          {
            alert(response.data.message);
            self.resetform();
            self.GetmyprovidentBySid();
            //self.LogTrace(null, "Add  Provident Fund", 17, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add  Provident Fund Fail", 17, "Low");
          alert(error);
          self.LoadingDialog = false;
        });
      } else {
        self.validateediterror();
        self.LoadingDialog = false;
      }
    },

    // add provident fund with employee code
    AddProvidentWithCode() {
      let self = this;
      let tempvalidate = self.validateedit();
      if (tempvalidate == true) {
        self.LoadingDialog = true;
        let temp = {
          fund_Acc_Num: self.provident.fundnumber,
          emp_Cumulative_Amt: self.removecomma(self.provident.empcumulative),
          //emp_Cumulative_Startdate: self.enddate,
          emp_Cumulative_Startdate: self.$moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          emp_Contribution: self.removecomma(self.provident.empafteryear),
          cmp_Cumulative_Amt: self.removecomma(self.provident.comcumulative),
          // cmp_Cumulative_Startdate: self.startdate,
          cmp_Cumulative_Startdate: self.$moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          cmp_Contribution: self.removecomma(self.provident.compafteryear),
          employee_ID: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        localStorage.setItem("providentFund", self.provident.empcumulative);
        axios.post(`${self.url}Emp_Provident/AddEmp_Provident`, temp)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetform();
            self.Getnewempprovident();
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
      }
      else
      {
        self.validateediterror();
        self.LoadingDialog = false;
      }
    },

    // get salary with employee id
    Getsalary() {
      let self = this;
      let tempp = {
        employee_ID: parseInt(self.$route.params.id),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Salary/GetSalaryInfo`, tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.SalaryList = response.data.data;
        }
      });
    },

    //get salary with session employee ID
    GetsalaryWithSid() {
      let self = this;
      let tempp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Salary/GetSalaryInfo`, tempp)
      .then(function (response) {
        self.SidSalaryList = response.data.data;
      });
    },

    // edit provident fund with employee ID
    EditProvidentWithEid() {
      let self = this;
      let tempvalidate = self.validateedit();
      if (tempvalidate)
      {
        self.LoadingDialog = true;
        let tempedit = {
          emp_Fund_ID: self.ProvidentData[0].emp_Fund_ID,
          fund_Acc_Num: self.provident.fundnumber,
          emp_Cumulative_Amt: self.removecomma(self.provident.empcumulative),
          //emp_Cumulative_Startdate: self.enddate,
          emp_Cumulative_Startdate: self.$moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          emp_Contribution: self.removecomma(self.provident.empafteryear),
          cmp_Cumulative_Amt: self.removecomma(self.provident.comcumulative),
          // cmp_Cumulative_Startdate: self.startdate,
          cmp_Cumulative_Startdate: self.$moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          cmp_Contribution: self.removecomma(self.provident.compafteryear),
          employee_ID: parseInt(self.$route.params.id),
        };
        axios.post(`${self.url}Emp_Provident/UpdateEmp_Provident`, tempedit)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            localStorage.clear();
            self.resetform();
            self.Getmyprovident();
            //self.LogTrace(null, "Update Provident Fund", 17, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Provident Fund Fail", 17, "Medium");
          alert(error);
          self.LoadingDialog = false;
        });
      } else {
        self.validateediterror();
        self.LoadingDialog = false;
      }
    },

    // edit provident fund with employee code
    EditProvidentWithCode() {
      let self = this;
      let tempvalidate = self.validateedit();
      if (tempvalidate)
      {
        self.LoadingDialog = true;
        let tempedit = {
          emp_Fund_ID: self.NewProvidentdata[0].emp_Fund_ID,
          fund_Acc_Num: self.provident.fundnumber,
          emp_Cumulative_Amt: self.removecomma(self.provident.empcumulative),
          //emp_Cumulative_Startdate: self.enddate,
          emp_Cumulative_Startdate: self.$moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          emp_Contribution: self.removecomma(self.provident.empafteryear),
          cmp_Cumulative_Amt: self.removecomma(self.provident.comcumulative),
          //cmp_Cumulative_Startdate: self.startdate,
          cmp_Cumulative_Startdate: self.$moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          cmp_Contribution: self.removecomma(self.provident.compafteryear),
          employee_ID: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}Emp_Provident/UpdateNewEmp_Provident`, tempedit)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            localStorage.providentFund = self.provident.empcumulative;
            self.resetform();
            self.Getnewempprovident();
            //self.LogTrace(null, "Update Provident Fund", 17, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Provident Fund Fail", 17, "Medium");
          alert(error);
          self.LoadingDialog = false;
        });
      }
    },

    // edit provident fund with session employee ID
    EditProvidentWithSid() {
      let self = this;
      let tempvalidate = self.validateedit();
      if (tempvalidate)
      {
        self.LoadingDialog = true;
        let tempedit = {
          emp_Fund_ID: self.ProvidentData[0].emp_Fund_ID,
          fund_Acc_Num: self.provident.fundnumber,
          emp_Cumulative_Amt: self.removecomma(self.provident.empcumulative),
          //emp_Cumulative_Startdate: self.enddate,
          emp_Cumulative_Startdate: self.$moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          emp_Contribution: self.removecomma(self.provident.empafteryear),
          cmp_Cumulative_Amt: self.removecomma(self.provident.comcumulative),
          //cmp_Cumulative_Startdate: self.startdate,
          cmp_Cumulative_Startdate: self.$moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
          cmp_Contribution: self.removecomma(self.provident.compafteryear),
          employee_ID: store.state.employeeID,
        };
        axios.post(`${self.url}Emp_Provident/UpdateEmp_Provident`, tempedit)
        .then(function (response) {
          self.LoadingDialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            localStorage.clear();
            self.resetform();
            self.GetmyprovidentBySid();
            //self.LogTrace(null, "Update Provident Fund", 17, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Provident Fund Fail", 17, "Medium");
          alert(error);
          self.LoadingDialog = false;
        });
      }
      else
      {
        self.validateediterror();
        self.LoadingDialog = false;
      }
    },

    validateedit() {
      let self = this;
      // let fundvali = self.fundnumbervalidation(self.provident.fundnumber);
      if (
        self.provident.empcumulative != null &&
        self.enddate != "" &&
        self.provident.empafteryear != null &&
        self.provident.comcumulative != null &&
        self.startdate != "" &&
        self.provident.compafteryear != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateediterror() {
      let self = this;
      //let fundvali = self.fundnumbervalidation(self.provident.fundnumber);
      if (
        self.provident.empcumulative == null ||
        self.enddate == "" ||
        self.provident.empafteryear == null ||
        self.provident.comcumulative == null ||
        self.startdate == "" ||
        self.provident.compafteryear == null
      ) {
        alert("Please fill all the  fields");
        // self.error.fundnumber = !self.provident.fundnumber
        //   ? "Fund Account Number is required"
        //   : "";

        self.error.empcumulative = !self.provident.empcumulative
          ? "Please fill in the required fields"
          : "";

        self.error.empdate = !self.enddate
          ? "Cumulative start date is required"
          : "";

        self.error.empafteryear = !self.provident.empafteryear
          ? "Please fill in the required fields"
          : "";

        self.error.comcumulative = !self.provident.comcumulative
          ? "Please fill in the required fields"
          : "";

        self.error.comdate = !self.startdate
          ? "Cumulative start date is required"
          : "";

        self.error.compafteryear = !self.provident.compafteryear
          ? "Please fill in the required fields"
          : "";
      }
      // } else if (fundvali != true) {
      //   alert("Please fill the correct format");
      //   self.error.fundnumber =
      //     self.provident.fundnumber != fundvali
      //       ? "Please fill 13 digit numbers"
      //       : self.provident.fundnumber;
      // }
    },
    resetform() {
      let self = this;
      // self.error.fundnumber = null;
      self.error.empcumulative = null;
      self.error.empdate = "";
      self.error.empafteryear = null;
      self.error.comcumulative = null;
      self.error.comdate = "";
      self.error.compafteryear = null;
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .btn_edit {
    margin-left: -1rem !important;
  }
}
@media only screen and (max-width: 414px) {
  .save_btn {
    margin-top: 1rem !important;
    margin-left: 1.5rem !important;
  }
  .cancle_btn {
    margin-left: 1.5rem !important;
  }
}
</style>
