<template>
  <div style="height: auto">
    <v-card>
      <v-card-title class="pb-0">
        <p class="pl-8 pt-8" style="font-size: 17px">
          {{ $t("Defaultdeduction") }}
        </p>
      </v-card-title>
      <div class="pl-12">
        <div class="d-flex ml-3">
          <v-checkbox class="custom-checkbox" color="#F99D20" v-model="Personaltax" :disabled="disablecheck"></v-checkbox>
          <!-- <v-checkbox
            v-model="Personaltax"
            :disabled="disablecheck"
            color="#F99D20"
          ></v-checkbox> -->
          <p class="mt-5" style="font-size: 15px; color: #8a8c8e">
            {{ $t("PersonalTax/Month") }}
          </p>
        </div>
        <div class="d-flex ml-3">
          <v-checkbox class="custom-checkbox" color="#F99D20" v-model="SSF" :disabled="disablecheck"></v-checkbox>
          <!-- <v-checkbox
            v-model="SSF"
            :disabled="disablecheck"
            color="#F99D20"
          ></v-checkbox> -->
          <p class="mt-5" style="font-size: 15px; color: #8a8c8e">
            {{ $t("social_security") }}
          </p>
        </div>
        <div class="d-flex ml-3">
          <v-checkbox class="custom-checkbox" color="#F99D20" v-model="PVD" :disabled="disablecheck"></v-checkbox>
          <!-- <v-checkbox
            v-model="PVD"
            :disabled="disablecheck"
            color="#F99D20"
          ></v-checkbox> -->
          <p class="mt-5" style="font-size: 15px; color: #8a8c8e">
            {{ $t("provident_fund") }}
          </p>
        </div>
      </div>
      <div style="height: 18vh"></div>
      <v-row style="padding-bottom: 2rem">
        <v-spacer></v-spacer>
        <div v-show="showEdit" style="margin-right: 1.5rem">
          <v-btn
            class="pa-2 mr-5 text_transform_none save_btn commonsavebtn"
            :disabled="permission.edit"
            @click="CloseEdit"
            >{{ $t("edit") }}</v-btn
          >
        </div>
        <div v-show="showSave" style="margin-right: 1.5rem">
          <v-btn
            class="pa-2 mr-5 text_transform_none commoncancelbtn"
            text
            outlined
            @click="showEditbtn()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="pa-2 mr-5 text_transform_none commonsavebtn"
            :disabled="permission.edit"
            @click="UpdateDeduction()"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import CheckViewEdit from "@/function/RolePermissions.js";
export default {
  props: ["id"],
  mixins: [CheckViewEdit],
  data() {
    return {
      permission: {
        edit: true,
      },
      url: enurl.apiUrl,
      Personaltax: false,
      SSF: false,
      PVD: false,
      Resultlist: [],
      LoadingDialog: false,
      showEdit: true,
      showSave: false,
      disablecheck: true,
    };
  },
  mounted() {
    this.GetDeduction();
    this.permission = this.CheckViewEdit(197, 198);
  },

  methods: {
    CloseEdit() {
      this.showSave = true;
      this.showEdit = false;
      this.disablecheck = false;
    },
    showEditbtn() {
      this.showSave = false;
      this.showEdit = true;
      this.disablecheck = true;
    },
    GetDeduction() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };

      axios
        .post(
          `${self.url}Payroll_DefaultDeduction_Setting/GetPayrolldeductionByCompanyID`,
          temp
        )
        .then(function (response) {
          self.Resultlist = response.data.data;
          if (self.Resultlist.length != 0) {
            self.Personaltax = self.Resultlist[0].personaltax;
            self.SSF = self.Resultlist[0].ssf;
            self.PVD = self.Resultlist[0].pvd;
          }
          self.LoadingDialog = false;
        });
    },
    UpdateDeduction() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        personaltax: self.Personaltax,
        ssf: self.SSF,
        pvd: self.PVD,
        company_ID: store.state.companyID,
      };

      axios
        .post(
          `${self.url}Payroll_DefaultDeduction_Setting/UpdateDeduction`,
          temp
        )
        .then(function (response) {
          alert(response.data.message);
          self.GetDeduction();
          self.disablecheck = true;
          self.showSave = false;
          self.showEdit = true;
        });
    },
  },
};
</script>