<template>
  <div>
    <div style="padding-left: 0px; padding-right: 1%; margin-bottom: 1rem">
      <v-row class="mr-0">
        <p
          class="ml-4"
          text
          style="
            cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            color: #acacac;
            padding: 18px;
          "
          @click="back()"
        >
          {{ $t("masterData") }}
        </p>
        <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext" style="font-size: 26px; margin-top: 20px">
          {{ $t("ShiftCode") }}
        </p>
      </v-row>
    </div>
    <v-card
      class="pt-5 mb-5"
      style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem"
    >
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <v-text-field
            autocomplete="off"
            v-model.lazy="forsearch"
            :placeholder="$t('SearchShiftCode')"
            color="#F99D20"
            dense
            style="margin-top: -0.8rem; margin-left: 1rem"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="permission.upload"
          color="#F99D20"
          class="orange--text text-capitalize mr-5"
          width="150"
          max-height="35"
          outlined
          @click="AUploaddialog = true"
        >
          {{ $t("Upload") }}</v-btn
        >
        <v-btn
          color="#F99D20"
          class="white--text text-capitalize mr-5 add_btn"
          width="150"
          max-height="35"
          :disabled="permission.add"
          @click="addDialog = true"
          >{{ $t("Add") }}</v-btn
        >
      </v-row>
      <v-data-table
        class="mt-4 tbheader"
        style="margin-left: 40px; margin-right: 40px"
        :headers="headers"
        :items="ShiftCodeLists"
        :search="forsearch"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        hide-default-header
        @page-count="pageCount = $event"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('shift_Code')"
              >
                {{ $t("ShiftCode") }}
                <v-icon small v-if="!shift_Code">mdi-arrow-down</v-icon>
                <v-icon small v-if="shift_Code">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('start_Time')"
              >
                {{ $t("StartTime") }}
                <v-icon small v-if="!start_Time">mdi-arrow-down</v-icon>
                <v-icon small v-if="start_Time">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-center"
                @click="sortFun('end_Time')"
              >
                {{ $t("EndTime") }}
                <v-icon small v-if="!end_Time">mdi-arrow-down</v-icon>
                <v-icon small v-if="end_Time">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-center"
                @click="sortFun('wh')"
              >
                {{ $t("WorkingHours") }}
                <v-icon small v-if="!wh">mdi-arrow-down</v-icon>
                <v-icon small v-if="wh">mdi-arrow-up</v-icon>
              </th>
              <th class="b-text text-end">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.shift_Code }}</td>
            <td>{{ item.start_Time }}</td>
            <td class="text-center">{{ item.end_Time }}</td>
            <td class="text-center">{{ item.wh }}</td>

            <td class="text-end">
              <v-btn @click="EditShiftCode(item)" icon :disabled="permission.edit">
                <img class="editIcon" :src="images.edit" />
              </v-btn>
              <v-btn @click="DeleteShift(item.shift_ID)" icon :disabled="permission.delete">
                <img class="deleteIcon" :src="images.delete" />
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPage"
              :items="items"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- add shift code dialog -->
    <v-dialog v-model="addDialog" persistent width="660">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
          {{ $t("addShiftCode") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  :label="$t('ShiftCode') + '*'"
                  v-model="add.shift_Code"
                  :error-messages="error.shift_Code"
                  color="#F99D20"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("StartTime") + "*" }}
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      v-model="add.start_Time"
                      append-icon="access_time"
                      :error-messages="error.start_Time"
                      color="#F99D20"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="add.start_Time"
                    no-title
                    fluid
                    @click:minute="$refs.menu2.save(add.start_Time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t("EndTime") + "*" }}
                <v-menu
                  ref="menu1"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time3"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      v-model="add.end_Time"
                      append-icon="access_time"
                      :error-messages="error.end_Time"
                      color="#F99D20"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="add.end_Time"
                    no-title
                    fluid
                    @click:minute="$refs.menu1.save(add.end_Time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            text
            @click="cancel"
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddShiftCode()"
            >{{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit shift code dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
          {{ $t("EditShiftCode") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="off"
                  :label="$t('ShiftCode') + '*'"
                  v-model="edit.shift_Code"
                  :error-messages="error.shift_Code"
                  required
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("StartTime") + "*" }}

                <v-menu
                  ref="menu4"
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time4"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      v-model="edit.start_Time"
                      append-icon="access_time"
                      :error-messages="error.start_Time"
                      color="#F99D20"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu4"
                    v-model="edit.start_Time"
                    no-title
                    fluid
                    @click="$refs.menu4.save(time4)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t("EndTime") + "*" }}
                <v-menu
                  ref="menu5"
                  v-model="menu5"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time5"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      v-model="edit.end_Time"
                      append-icon="access_time"
                      :error-messages="error.end_Time"
                      color="#F99D20"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu5"
                    v-model="edit.end_Time"
                    no-title
                    fluid
                    @click:minute="$refs.menu5.save(edit.time5)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            text
            @click="Editcancel()"
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateShiftCode()"
          >
            {{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Shift dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteShitfCode") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="Deletedialog = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="DeleteShiftrow()"
            >{{ $t("Delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- uploadDialog -->
    <v-dialog v-model="AUploaddialog" presistent max-width="700">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  color="white"
                  class="myfile"
                  id="fileInputButton"
                  v-model="chosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                >
                  {{ $t("Browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Shift Code Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  :href="CheckLang()"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            height="35"
            text
            @click="closeUpload"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text mr-2 text-capitalize"
            width="120"
            height="35"
            @click="PreviewFiles"
            >{{ $t("Upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import moment from "moment";

export default {
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  data() {
    return {
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      time: null,
      menu2: false,
      modal2: false,

      time3: null,
      menu3: false,
      modal3: false,

      time4: null,
      menu4: false,
      modal4: false,

      time5: null,
      menu5: false,
      modal5: false,

      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      addDialog: false,
      Editdialog: false,
      Deletedialog: false,
      uploaddialog: false,
      AUploaddialog: false,
      LoadingDialog: false,
      ShiftCodeList: [],
      ShiftCodeLists: [],
      ShiftCodeSearch: [],
      forsearch: "",

      editShiftId: null,
      deleteShiftId: 0,
      chosenfile: null,
      tempimport: [],
      importData: [],
      showup: false,
      start_Time: null,
      end_Time: null,
      shift_Code: null,
      wh: null,

      mytemp: {
        company_ID: store.state.companyID,
      },

      search: "",
      headers: [
        {
          text: "Shift Code",
          value: "shift_Code",
        },
        {
          text: "Start Time ",
        },
        {
          text: "End Time",
        },
        {
          text: "Working Hours",
        },
        {
          text: "Action",
          value: "actionadd",
        },
      ],
      add: {
        shift_Code: "",
        company_ID: "",
        start_Time: "",
        end_Time: "",
      },
      edit: {
        ShiftId: "",
        shift_Code: "",
        company_ID: "",
        start_Time: "",
        end_Time: "",
      },
      error: {
        shift_Code: "",
        company_ID: "",
        start_Time: "",
        end_Time: "",
      },
    };
  },
  watch: {
    "add.shift_Code"(v) {
      if (v) this.error.shift_Code = "";
    },
    "add.start_Time"(v) {
      if (v) this.error.start_Time = "";
    },
    "add.end_Time"(v) {
      if (v) this.error.end_Time = "";
    },
    "edit.shift_Code"(v) {
      if (v) this.error.shift_Code = "";
    },
    "edit.start_Time"(v) {
      if (v) this.error.start_Time = "";
    },
    "edit.end_Time"(v) {
      if (v) this.error.end_Time = "";
    },
  },

  mounted: function () {
    let self = this;
    self.GetShiftCode();
    self.permission = self.CheckViewAddEditDeleteUpload(
      107,
      108,
      109,
      110,
      111
    );
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
     CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Shift Code Master Data.xlsx";
      } else {
        url =
          "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
      }
      return url;
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["end_Time", "start_Time", "shift_Code", "wh"];
      if (rowName == "end_Time" || rowName == "start_Time") {
        self.ShiftCodeLists = self.ShiftCodeLists.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else if (rowName == "wh") {
        self.ShiftCodeLists = self.ShiftCodeLists.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName], undefined, {
                numeric: true,
                sensitivity: "base",
              })
            : a[rowName].localeCompare(b[rowName], undefined, {
                numeric: true,
                sensitivity: "base",
              })
        );
      } else {
        self.ShiftCodeLists = self.ShiftCodeLists.sort((a, b) =>
          self[rowName]
            ? b[rowName] - a[rowName] ||
              b[rowName].toString().localeCompare(a[rowName].toString())
            : a[rowName] - b[rowName] ||
              a[rowName].toString().localeCompare(b[rowName].toString())
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.end_Time = null;
      this.start_Time = null;
      this.shift_Code = null;
      this.wh = null;
    },
    resetForm() {
      this.error.shift_Code = [];
      this.error.start_Time = [];
      this.error.end_Time = [];

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    cancel() {
      this.addDialog = false;
      this.add.start_Time = null;
      this.add.end_Time = null;
      this.add.shift_Code = null;
      this.resetForm();
    },
    Editcancel() {
      this.Editdialog = false;
      this.edit.start_Time = null;
      this.edit.end_Time = null;
      this.edit.shift_Code = null;
      this.resetForm();
    },
    checkDuplicate(item) {
      return !this.ShiftCodeLists.some(
        (el) => el.shift_Code.toLowerCase() == item.toLowerCase()
      );
    },
    checkStartEnd(startTime, endTime) {
      return !this.ShiftCodeLists.some(
        (el) => el.start_Time == startTime && el.end_Time == endTime
      );
    },
    editDuplicate(item, id) {
      let notSameList = this.ShiftCodeLists.filter((el) => el.shift_ID != id);
      return !notSameList.some(
        (el) => el.shift_Code.toLowerCase() == item.toLowerCase()
      );
    },
    editStartEnd(id, startTime, endTime) {
      let notSameList = this.ShiftCodeLists.filter((el) => el.shift_ID != id);
      return !notSameList.some(
        (el) => el.start_Time == startTime && el.end_Time == endTime
      );
    },
    WorkingHours(start, end) {
      let startTime = moment(start, "HH:mm");
      let endTime = moment(end, "HH:mm");
      let minutes = endTime.diff(startTime, "minutes");
      let interval = moment().hour(0).minute(minutes);
      let result = interval.format("HH:mm");
      let getHours = moment(result, "HH:mm").format("H");
      let getMin = moment(result, "HH:mm").format("m");
      let results = "";
      if (getHours == "0") {
        results = `${getMin} minutes`;
      } else if (getMin == "0") {
        results = `${getHours} hours`;
      } else {
        results = `${getHours} hours ${getMin} minutes`;
      }

      return results;
    },
    updateDate(date) {
      var upTime = new Date(Date.parse(date));

      return upTime;
    },
    formatDate(date) {
      if (date) {
        var temp = new Date(date);
        var hour = "" + temp.getHours();
        if (hour.length < 2) {
          hour = "0" + temp.getHours();
        }
        var minut = "" + temp.getMinutes();
        if (minut.length < 2) {
          minut = "0" + temp.getMinutes();
        }
        const retvalue = hour + ":" + minut;
        return retvalue;
      } else {
        return "";
      }
    },

    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => {});
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      this.showup = true;
    },
    closeUpload() {
      this.AUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    GetShiftCode() {
      let self = this;
      self.LoadingDialog = true;

      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}ShiftCode/GetShiftCodebyCompanyID`, tempp)
        .then(function (response) {
          self.ShiftCodeList = response.data.data;
          //let list = [];

          for (let i = 0; i < self.ShiftCodeList.length; i++) {
            let Lists = {
              ...self.ShiftCodeList[i],
              wh: self.WorkingHours(
                self.ShiftCodeList[i].start_Time,
                self.ShiftCodeList[i].end_Time
              ),
            };
            self.ShiftCodeLists.push(Lists);
          }
          self.defaultSort();
          self.LoadingDialog = false;
          // self.benefitsearch=response.data.data;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get ShiftCode Fail", 8, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    AddShiftCode() {
      let self = this;
      self.error.shift_Code = !self.add.shift_Code
        ? "Please fill in the required fields"
        : "";
      self.error.start_Time = !self.add.start_Time
        ? "Please fill in the required fields"
        : "";
      self.error.end_Time = !self.add.end_Time
        ? "Please fill in the required fields"
        : "";

      let tempvali = self.ValidateAddTrue();
      if (tempvali == true) {
        if (self.checkDuplicate(self.add.shift_Code)) {
          if (self.checkStartEnd(self.add.start_Time, self.add.end_Time)) {
            self.LoadingDialog = true;

            let tempShift = {
              shift_Code: self.add.shift_Code,
              company_ID: store.state.companyID,
              start_Time: self.add.start_Time,
              end_Time: self.add.end_Time,
            };
            axios
              .post(`${self.url}ShiftCode/AddShiftCode`, tempShift)
              .then(function (response) {
                if (response.data.status == 0)
                {
                  alert(response.data.message);
                  self.add.shift_Code = null;
                  self.add.start_Time = null;
                  self.add.end_Time = null;
                  self.addDialog = false;
                  self.LoadingDialog = false;
                  self.ShiftCodeLists = [];
                  self.GetShiftCode();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Add ShiftCode Fail", 8, "Low");
                //alert(error + "Correlation ID is 8");
              });
          } else {
            alert(
              "Shift code/shift start time/shift end time already exists for another shift in the Master data. Please check and revise"
            );
          }
        } else {
          alert("Shift code already exists");
        }
      }
    },
    ValidateAddTrue() {
      let self = this;
      if (
        self.add.shift_Code != null &&
        self.add.start_Time != null &&
        self.add.end_Time != null &&
        self.add.shift_Code != "" &&
        self.add.start_Time != "" &&
        self.add.end_Time != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    EditShiftCode(temp) {
      let self = this;
      self.Editdialog = true;
      self.edit.ShiftId = temp.shift_ID;
      self.edit.shift_Code = temp.shift_Code;
      self.edit.start_Time = temp.start_Time;
      self.edit.end_Time = temp.end_Time;
    },
    UpdateShiftCode() {
      let self = this;
      self.error.shift_Code = !self.edit.shift_Code
        ? "Please fill in the required fields"
        : "";
      self.error.start_Time = !self.edit.start_Time
        ? "Please fill in the required fields"
        : "";
      self.error.end_Time = !self.edit.end_Time
        ? "Please fill in the required fields"
        : "";

      let tempvalid = self.ValidateEditTrue();
      if (tempvalid == true) {
        let editDuplicate = self.editDuplicate(
          self.edit.shift_Code,
          self.edit.ShiftId
        );
        if (editDuplicate) {
          let editStartEnd = self.editStartEnd(
            self.edit.ShiftId,
            self.edit.start_Time,
            self.edit.end_Time
          );
          if (editStartEnd) {
            self.LoadingDialog = true;

            let tempEdit = {
              shift_ID: self.edit.ShiftId,
              shift_Code: self.edit.shift_Code,
              company_ID: store.state.companyID,
              start_Time: self.edit.start_Time,
              end_Time: self.edit.end_Time,
            };
            axios.post(`${self.url}ShiftCode/UpdateShiftCode`, tempEdit)
              .then(function (response) {
                if (response.data.status == 0)
                {
                  alert(response.data.message);
                  self.LoadingDialog = false;
                  self.ShiftCodeLists = [];
                  self.Editdialog = false;
                  self.GetShiftCode();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Update ShiftCode Fail", 8, "Medium");
                //alert(error + "Correlation ID is 8");
              });
          } else {
            alert(
              "Shift code/shift start time/shift end time already exists for another shift in the Master data. Please check and revise"
            );
          }
        } else {
          alert("Shift code already exists");
        }
      }
    },

    ValidateEditTrue() {
      let self = this;
      if (
        self.edit.shift_Code != null &&
        self.edit.start_Time != null &&
        self.edit.end_Time != null &&
        self.edit.shift_Code != "" &&
        self.edit.start_Time != "" &&
        self.edit.end_Time != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    DeleteShift(id) {
      let self = this;
      self.deleteShiftId = id;
      self.Deletedialog = true;
    },
    DeleteShiftrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.deleteShiftId;
      let tempdel = {
        shift_ID: id,
      };
      axios.post(`${self.url}ShiftCode/DeleteShiftCode`, tempdel)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.ShiftCodeLists = [];
            self.GetShiftCode();
            self.Deletedialog = false;
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          this.LogTrace(error, "Delete  ShiftCode Fail", 8, "High");
          //alert(error + "Correlation ID is 8");
          self.LoadingDialog = false;
        });
    },
    ChangeToTime(d) {
      let day = new Date(Math.round((d - (25567 + 2)) * 86400) * 1000);
      const getutcmin = day.getUTCMinutes();
      const getutchrs = day.getUTCHours();
      let result = "";
      if (getutchrs < 10) {
        if (getutcmin < 10) {
          result = "0" + getutchrs + ":" + "0" + getutcmin;
        } else {
          result = "0" + getutchrs + ":" + getutcmin;
        }
      } else {
        if (getutcmin < 10) {
          result = getutchrs + ":" + "0" + getutcmin;
        } else {
          result = getutchrs + ":" + getutcmin;
        }
      }
      return result;
    },
    PreviewFiles() {
      let self = this;

      let files = self.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          self.importData = XLSX.utils.sheet_to_json(worksheet);
          let combileArray = [];
          let isDuplicate = [];
          for (let i = 0; i < self.importData.length; i++) {
            self.tempimport = {
              ...self.importData[i],
              start_Time: self.ChangeToTime(self.importData[i].StartTime),
              end_Time: self.ChangeToTime(self.importData[i].EndTime),
              company_ID: store.state.companyID,
            };

            combileArray.push(self.tempimport);
            isDuplicate.push(self.checkDuplicate(combileArray[i].shift_Code));
            isDuplicate.push(
              self.checkStartEnd(
                combileArray[i].start_Time,
                combileArray[i].end_Time
              )
            );
          }

          if (filename == "Shift Code Master Data.xlsx") {
            if (isDuplicate.every((x) => x)) {
              self.LoadingDialog = true;
              axios.post(`${self.url}ShiftCode/ImportShiftCode`, combileArray)
                .then(function (response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    self.ShiftCodeLists = [];
                    self.GetShiftCode();
                    self.chosenfile = null;
                    self.showup = false;
                    self.AUploaddialog = false;
                    self.LoadingDialog = false;
                  }
                })
                .catch(function (error) {
                  self.LogTrace(error, "Upload ShiftCode Fail", 8, "Verbose");
                  //alert(error + "Correlation ID is 8");
                  self.LoadingDialog = false;
                });
            } else {
              alert(
                "Shift code/shift start time/shift end time already exists for another shift in the Master data.Please check and revise"
              );
            }
          } else {
            alert("File name must be Shift Code Master Data.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 375px) {
  .add_btn {
    margin-top: 15px;
  }
}
@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 16px;
  color: #f99d20;
}
>>> .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}
.v-input__icon--append .v-icon {
  color: purple;
}
</style>
<style>
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
</style>
