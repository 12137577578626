import "@babel/polyfill";
import Vue from "vue";
import IdleVue from "idle-vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "@/translate/i18n";
import lineClamp from "vue-line-clamp";
import FlagIcon from "vue-flag-icon";
import JwPagination from "jw-vue-pagination";
import CountryFlag from "vue-country-flag";
import store from "./store";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import firebase from "firebase/app";
import "@firebase/messaging";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import './plugins/moment'; // Import the moment plugin
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style
import 'swiper/dist/css/swiper.css'

window.$ = window.jQuery = require("jquery");
Vue.use(VueMask);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCFKIXnUCz1rgcvPELdc6ffGs23NfrxirQ",
    libraries: "places", // necessary for places input
  },
});
// Swiper
Vue.use(VueAwesomeSwiper);
// }
Vue.config.productionTip = false;
Vue.component("country-flag", CountryFlag);
Vue.component('date-picker', DatePicker);
Vue.use(FlagIcon);
Vue.use(lineClamp);
Vue.use(JwPagination);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 43200000, //  12 hours
  startAtIdle: false,
});
new Vue({
  i18n,
  vuetify,
  router,
  lineClamp,
  store,
  IdleVue,
  render: h => h(App),
}).$mount("#app");

if (firebase.messaging.isSupported()) {
  var firebaseConfig = {
    apiKey: "AIzaSyAI4HkLNx1R3atL7BxB7qwvEKQqjiY8k4Y",
    authDomain: "optimistic-cca96.firebaseapp.com",
    databaseURL: "https://optimistic-cca96.firebaseio.com",
    projectId: "optimistic-cca96",
    storageBucket: "optimistic-cca96.appspot.com",
    messagingSenderId: "116479546535",
    appId: "1:116479546535:web:c6cbe479b93035b75eb782",
    measurementId: "G-0L3GT2B6LB",
  };

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
    // firebase.analytics();

    Vue.prototype.$messaging = firebase.messaging();

    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(registration => {
        Vue.prototype.$messaging.useServiceWorker(registration);
        // console.log(registration);
      });
  }
}
