<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 2.5rem">
    <div>
      <p class="txt-header">
        {{ $t("ShiftAssignment1") }}
      </p>
      <v-tabs class="mt-6" grey--text background-color="#f0f8fc" show-arrows slider-color="#F99D20">
        <v-tab class="text-capitalize" style="border-radius: 10px 10px 5px 5px; width: 160px; font-size: 16px">
          {{ $t("ManageShift") }}</v-tab>
      </v-tabs>
      
      <v-card class="pt-5" style="margin-bottom: 3rem">
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="3">
            <v-text-field class="movetop" prepend-inner-icon="search" :placeholder="$t('SearchEmployee')"
              v-model.lazy="searchtxt" style="
                    border-radius: 5px;
                    margin-top: 0.9rem;
                    margin-right: 1rem;
                    margin-left: 1rem;
                  " color="#F99D20" outlined dense>
            </v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="5">
            <v-row class="mt-4" justify="center">
              <v-btn color="#707070" class="text-capitalize" width="145" max-height="35" outlined @click="goToday()">
                {{ $t("today") }}</v-btn>
              <div>
                <v-btn @click="lastMonth()" icon style="margin-right: 1rem">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>{{ WhatTheMonth }}</span>
                <v-btn @click="nextMonth()" icon style="margin-left: 1rem">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-row>
          </v-col>
          <v-col class="text-right" cols="12" xs="12" sm="4" md="3" lg="2">
            <v-menu ref="positionMenu" v-model="positionMenu" offset-y :close-on-content-click="false"
              @focus="$event.target.click()">
              <template v-slot:activator="{ on }">
                <v-btn style="margin-top: 0.9rem;
                    margin-left: 1rem;
                    margin-right: 1rem;" outlined width="145" color="#707070" @click="togglePositionMenu" v-on="on">
                  <v-icon left class="v-icon mdi mdi-filter-variant theme--light" />
                  {{ $t("Filter") }}
                  <v-icon>{{positionMenu ? "mdi-chevron-up" : "mdi-chevron-down"}}</v-icon>
                </v-btn>
              </template>
              <v-list @focus="$event.target.click()">
                <v-list-item class="tile ml-1 mr-1" v-for="(position, index) in positions" :key="index"
                  :style="{ backgroundColor: position.color }" style="border-radius: 5px;"
                  @focus="$event.target.click()">
                  <v-list-item-title @click="handlePositionSelect(position)" @focus="$event.target.click()"
                    style="cursor:pointer">{{position.text}}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-if="selectedPosition && selectedPosition.value === position.value">mdi-check</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-menu ref="subMenu" v-if="subMenuVisible" v-model="subMenu" :close-on-content-click="false" offset-y
            :position-x="subMenuX" :position-y="subMenuY" absolute @focus="$event.target.click()">
            <v-card max-height="400px">
              <v-text-field v-model="searchInput" :label="$t('Search')" class="pa-3" color="#F99D20"
                @focus="$event.target.click()" @click="positionMenu = true"></v-text-field>
              <v-list style="margin-top:-1.5rem;">
                <v-list-item style="border-radius: 5px;" class="tile2 ml-1 mr-1"
                  v-for="(subPosition, index) in filteredSubPositions" :key="index" @focus="$event.target.click()">
                  <v-list-item-content @click="handleSubPositionSelect(subPosition)">
                    <v-list-item-title style="cursor:pointer;">
                      <v-row>
                        <v-col cols="3">
                          {{ subPosition.text }}
                        </v-col>
                        <v-col cols="7">
                          <span v-if="selectone == 4" style="color:orange">: {{ subPosition.start_Time }} - {{ subPosition.end_Time }}</span>
                        </v-col></v-row></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="#F99D20" v-if="selectedSubPositions.includes(subPosition)">mdi-check</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <v-col class="text-right pr-7" cols="12" xs="12" sm="4" md="3" lg="2">
            <v-btn color="#F99D20" class="white--text text-capitalize" style="margin-top: 0.9rem;" min-width="100" max-height="35" :disabled="permission.add" @click="ChangingShift()">
              {{ $t("ChangeShift") }}</v-btn>
          </v-col>
        </v-row>
        <div class="mt-5">
          <v-data-table :search="searchtxt" :headers="headers" :items="ShiftList" :page.sync="page1"
            :items-per-page="itemsPerPage1" hide-default-footer hide-default-header :fixed-colmun="1"
            @page-count="pageCount1 = $event" :mobile-breakpoint="0" class="fluid-table ml-5 mr-5"
            style="border-bottom: 1px solid #D0CECE; border-top: 1px solid #D0CECE">
            <template v-slot:header="{ props: { } }">
              <thead>
                <tr>
                  <th style="min-width: 50px; position: sticky; border-bottom: 1px solid #D0CECE;">
                    <v-checkbox v-model="selectAll1" class="custom-checkbox" color="#F89D1F" hide-details @change="checkall(selectAll1)"
                      style="margin-top:-0.5rem;"></v-checkbox>
                  </th>
                  <th
                    style="text-align: center; position: sticky;  min-width: 300px; border-bottom: 1px solid #D0CECE;">
                    {{ $t("empnameinSA") }}</th>
                  <th style="min-width: 1300px; position: sticky; border-bottom: 1px solid #D0CECE;">
                    <v-row>
                      <div class="date-container">
                        <div v-for="dayObj in thedatehead" :key="dayObj.day" class="date-item"
                          :style="{ backgroundColor: dayObj.isWeekend ? '#EEEEEE' : 'transparent' }">
                          <div class="day-number">{{ dayObj.day }}</div>
                          <div class="day-name">
                            {{
          dayObj.date == "Sun"
            ? $t("Sun")
            : dayObj.date == "Sat"
              ? $t("Sat")
              : dayObj.date == "Mon"
                ? $t("Mon")
                : dayObj.date == "Tue"
                  ? $t("Tue")
                  : dayObj.date == "Wed"
                    ? $t("Wed")
                    : dayObj.date == "Thu"
                      ? $t("Thu")
                      : dayObj.date == "Fri"
                        ? $t("Fri")
                        : $t("Fri")
        }}
                          </div>
                        </div>
                      </div>
                    </v-row>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td style="text-align: left; position: sticky; border-bottom: 1px solid #fff">
                  <v-checkbox class="custom-checkbox" v-model="item.checked" color="#F89D1F" hide-details @change="checkmylist(item)"
                    style="margin-top:-0.5rem;"></v-checkbox>
                </td>
                <td
                  style="width: 350px; position: sticky; border-bottom: 1px solid #fff; border-right: 1px solid #cfcdcd">
                  <v-row>
                    <v-col cols="3" class="pr-0">
                      <v-img :src="item.image" max-width="40" max-height="40" class="rounded-circle" />
                    </v-col>
                    <v-col cols="9" class="pl-0">
                      <span> {{ item.employee_Code }}</span> : {{ item.fullName }}<br />
                      <span class="position-style">{{ item.position_Name }}</span>
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <v-row>
                    <div v-for="(item1, index) in item.dateList1" :key="index">
                      <v-chip :color="item1.shiftColor" :style="{ width: chipWidth(item1) + 'px', height: '55px' }">{{
          item1.thetext }}</v-chip>
                    </div>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-right">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination v-model="page1" :length="pageCount1" color="#FFCD2C" :total-visible="6"></v-pagination>
              </v-col>
              <v-col cols="12" md="2" class="mt-1">
                <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage1"
                  :items="items1" @input="itemsPerPage1 = parseInt($event, 10)"></v-select>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <!-- Loading Dialog -->
      <v-dialog v-model="LoadingDialog" persistent width="300">
        <v-card color="#FFF4EB" dark>
          <v-card-text class="black--text">
            Loading Please Wait...
            <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Can't Do it Dialog -->
      <v-dialog v-model="NopeDialog" persistent width="290">
        <v-card style="border-radius: 15px">
          <v-card-text class="black--text d-flex justify-center ">
            <v-col style="text-align: center;">
              <p style="font-size: 18px; padding-top: 5px;">{{ $t("cannotcreate") }}</p>
              <p>{{ $t("plschsemp") }}</p>
              <v-btn @click="NopeDialog = false" color="#F99D20" class="text-capitalize add" width="70"
                max-height="35">{{
          $t("ok") }}</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- ChangeShift Dialog -->
      <v-dialog v-model="ChangeShiftDialog" persistent width="700">
        <v-card style="border-radius: 15px">
          <v-card-title>
            {{ $t("ChangeShift") }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <label style="color: #8a8c8e">
                  {{ $t("shiftcode")
                  }}<span class="red--text"> *</span></label>
                <v-autocomplete :items="listShift" v-model="ShiftToChange" item-value="shift_ID"
                  :placeholder="$t('PlsChooseShift')" :error-messages="error.shiftCode" outlined dense
                  :item-text="(item) => `${item.shift_Code}`" color="#F99D20">
                  <template v-slot:item="{ item }">
                    <v-row>
                      <v-col cols="2">{{ item.shift_Code }}</v-col>
                      <v-col cols="8">
                        <p style="color:orange">: {{ item.start_Time }} - {{ item.end_Time }}</p>
                      </v-col>
                    </v-row>

                  </template>
                  <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span style="margin-top: -15px;">{{
          error.shiftCode }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <label style="color: #8a8c8e">
                  {{ $t("start_date")}}
                  <span class="red--text"> *</span></label>
                <!-- <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y fluid max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted5"
                      class="kanit-medium" outlined dense persistent-hint readonly v-on="on" hide-details 
                      color="#F99D20"></v-text-field>
                  </template>
                  <v-date-picker v-model="startdate" no-title @input="menu1 = false"></v-date-picker>
                </v-menu> -->
                <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format"
                v-model="startdate" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label style="color: #8a8c8e">{{ $t("enddata") }}<span class="red--text"> *</span></label>
                <!-- <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y fluid max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted6"
                      class="kanit-medium" :disabled="disenddate"
                      :style="{ backgroundColor: disenddate ? '#EEEEEE' : 'transparent' }" hide-details outlined dense
                      persistent-hint readonly v-on="on" color="#F99D20"></v-text-field>
                  </template>
                  <v-date-picker v-model="enddate" :allowed-dates="allowedEndDates" no-title @input="menu2 = false" ></v-date-picker>
                </v-menu> -->
                <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" :disabled="disenddate" value-type="format"
                v-model="enddate" :disabled-date="allowedEndDates" no-title format="DD/MM/YYYY" :style="{ width: '100%',backgroundColor: disenddate ? '#EEEEEE' : 'transparent'  }"></date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <div style="display: flex;">
                  <v-checkbox class="custom-checkbox" color="#F99D20" v-model="disenddate" style="margin-top: 0;"></v-checkbox>
                  <p class="mt-1" style="margin: 0 0 0 10px;color: #8a8c8e">{{ $t("DisableEndDate") }}</p>
                </div>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="6">
                <v-checkbox class="custom-checkbox" color="#F99D20" v-model="disenddate"></v-checkbox>
                <p>{{ $t("DisableEndDate") }}</p>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" @click="CloseChangeShift()" text>
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="2 commonsavebtn text-capitalize" @click="UpdateShift()">
              {{ $t("Save") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";

export default {
  mixins: [LogTrace, utils, CheckViewAddEdit],
  data: () => ({
    allowedDates: (val) => val >= new Date(new Date().getFullYear(), 0, 2).toISOString().substr(0, 10),
    permission: {
      add: true,
      edit: true,
    },
    searchtxt: null,
    images: {
      edit: require("@/assets/images/Mask Group 178.png"),
    },
    ShiftList: [],
    url: enurl.apiUrl,
    items: [5, 10, 20, 30],
    date: new Date().toISOString().substr(0, 10),
    TimesGroupList: "",
    departmentList: [],
    itemsPerPage1: 10,
    pageCount1: 15,
    page1: 1,
    items1: [10, 20, 30],
    page: 1,
    pageCount: 15,
    selectAll: false,
    selected: [],
    differentList: [],
    differentList1: [],
    LoadingDialog: false,
    selected1: [],
    selectAll1: false,
    NopeDialog: false,
    ChangeShiftDialog: false,
    disenddate: false,
    startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    enddate: new Date(Date.now()).toISOString().substr(0, 10),
    enddatebu: new Date(Date.now()).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    ShiftToChange: null,
    WhatTheMonth: null,
    themonth: 0,
    theyear: 0,
    thedatehead: [],
    positionMenu: false,
    subMenu: false,
    subMenuVisible: false,
    positionMenuX: 0,
    positionMenuY: 0,
    subMenuX: 0,
    subMenuY: 0,
    selectone: null,
    selectedPosition: null,
    selectedSubPositions: [],
    filteremplist: [],
    searchInput: "",
    positionList: [],
    subPositionList: [],
    subDepartmentList: [],
    subShiftList: [],
    positions: [
      { text: "Position", value: 1, color: "" },
      { text: "Department", value: 2, color: "" },
      { text: "Employee Type", value: 3, color: "" },
      { text: "Shift", value: 4, color: "" },
    ],
    subPositions: [],
    subemptype: [
      { text: "Full Time", value: 1 },
      { text: "Daily", value: 2 },
      { text: "Part-time", value: 3 },
      { text: "Contract", value: 4 },
      { text: "Fixed Pay", value: 5 },
    ],
    headers: [
      {
        value: "employee_Code",
        align: "left",
        sortable: false,
        fixed: true
      },
      {
        value: "fullName",
        align: "left",
        sortable: false,
        fixed: true
      },
      {
        value: "position_Name",
        align: "left",
        sortable: false,
      },
      {
        value: "effectice_date",
        align: "left",
        sortable: false,
      },
      {
        align: "center",
        sortable: false,
      },
    ],
    listShift: [],
    error: {
      effective_Date: "",
      time_Group: "",
      Neweffective_Date: null,
      Newtime_Group: null,
      shiftCode: ""
    },
    index: 0,
  }),
  computed: {
    filteredSubPositions() {
      return this.subPositions.filter((subPosition) =>
        subPosition.text.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
    // computedDateFormatted5() {
    //   return this.formatDate(this.startdate);
    // },
    // computedDateFormatted6() {
    //   return this.formatDate(this.enddate);
    // },
    monthNames() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
  },
  mounted() {
    this.themonth = new Date().getMonth();
    this.theyear = new Date().getFullYear()
    this.WhatTheMonth = this.monthNames[this.themonth].txt + " " + this.theyear;
    this.GetShiftCode();
    this.GetShiftAssignment();
    this.GetPosition();
    this.GetDepartment();
    this.permission = this.CheckViewAddEdit(27, 28, 29);
  },
  watch: {
    "ShiftToChange"() {
      this.error.shiftCode = [];
    },

    startdate(newStartDate) {
      if (new Date(this.$moment(newStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD')) > new Date(this.$moment(this.enddate, 'DD/MM/YYYY').format('YYYY-MM-DD'))) 
      {
      this.enddate = null;
      }
    },

    disenddate: function () {
      if (this.disenddate == false) 
      {
        this.enddate = this.enddatebu;
      }
      else
      {
        this.enddate = null;
      }
    },

    ShiftList: {
      handler(v)
      {
        this.select1(v);
      },
      deep: true,
    },
  },
  methods: {
    allowedEndDates(date) {
      if (!this.startdate) return true;
      const startDate = this.$moment(this.startdate, 'DD/MM/YYYY');
      const endDate = this.$moment(date, 'DD/MM/YYYY');
      return endDate.isSameOrBefore(startDate);
    },

    NextStep() {
      if (this.index < this.selected.length - 1)
      {
        this.index++;
      }
    },

    PreviousStep() {
      if (this.index > 0) {
        this.index--;
      }
    },

    checkall(value) {
      this.ShiftList = this.ShiftList.map((v) => ({
        ...v,
        checked: value,
      }));
    },

    select1(array = null) {
      if (array)
      {
        this.selected1 = array.filter((v) => v.checked);
        return;
      }
      this.selected1 = this.ShiftList.filter((v) => v.checked);
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    GetShiftAssignment() {
      let self = this;
      self.selectone = null;
      self.thedatehead = self.daysInMonth();
      try {
        self.LoadingDialog = true;
        let temp = {
          company_ID: store.state.companyID,
          month: self.themonth + 1,
          year: self.theyear,
        };
        Axios.post(`${self.url}ShiftAssignment/GetShiftAssignmentWithComID`, temp)
          .then(function (response)
          {
            let llist = response.data.data;
            self.ShiftList = llist.map((v) => ({
              ...v,
              dateList1: self.dateRangesWithGaps1(v.dateList),
              checked: false,
            }));
            self.filteremplist = self.ShiftList;
            self.LoadingDialog = false;
            //self.LogTrace(null, "Get Shift Assignment", 27, "Critical");
          })
          .catch(function (error) {
            alert(error);
            self.LoadingDialog = false;
          });
      } catch (ex) {
        self.LogTrace(ex, "Get Shift Assignment Fail", 27, "Critical");
        alert(ex + " Correlation ID is 27");
      }
    },

    daysInMonth() {
      const daysArray = [];
      const firstDayOfMonth = new Date(this.theyear, this.themonth, 1);
      const lastDayOfMonth = new Date(this.theyear, this.themonth + 1, 0);
      const currentDate = new Date(firstDayOfMonth);
      while (currentDate <= lastDayOfMonth) {
        const dayNumber = currentDate.getDate();
        const dayName = currentDate.toLocaleDateString('en-US', { weekday: 'short' });
        const isWeekend = dayName === 'Sat' || dayName === 'Sun';
        daysArray.push({ day: dayNumber, date: dayName, isWeekend });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return daysArray;
    },

    async GetShiftCode() {
      const self = this;
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await Axios.post(`${self.url}ShiftCode/GetShiftCodebyCompanyID`,temp);
        let ShiftCode = response.data.data;
        self.listShift = response.data.data;
        self.listShift.map(function (data) {
          self.subShiftList.push({
            text: data.shift_Code,
            value: data.shift_ID,
            start_Time: data.start_Time,
            end_Time: data.end_Time
          });
        });
        let ShiftCodeList = [];
        for (let item in ShiftCode)
        {
          ShiftCodeList.push(ShiftCode[item].shift_Code);
        }
        self.getTimesGroup(ShiftCodeList);
      } catch (ex) {
        throw ex;
      }
    },

    async getTimesGroup(ShiftCodeList) {
      const self = this;
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await Axios.post(`${self.url}TimesGroup/GetTimesGroupByCompanyId`,temp);
        let TimesGroupList = response.data.data;
        self.TimesGroupList = TimesGroupList;
        let listgroup = TimesGroupList.map((x) => x.new_Time_Group);
        let differentList1 = ["All", ...listgroup, ...ShiftCodeList];
        let differentList = [...listgroup, ...ShiftCodeList];
        self.differentList1 = differentList1.filter((x) => x);
        self.differentList = differentList.filter((x) => x);
      } catch (ex) {
        throw ex;
      }
    },

    async ChangingShift() {
      let self = this;
      if (this.selected1.length == 0)
      {
        self.NopeDialog = true;
      }
      else
      {
        //console.log(this.selected1)
        self.ChangeShiftDialog = true;
        self.startdate = self.$moment(self.enddatebu).format('DD/MM/YYYY');
        self.enddate = self.$moment(self.enddatebu).format('DD/MM/YYYY');
        self.disenddate = false;
      }
    },

    async UpdateShift() {
      let self = this;
      let thedatevali = false;
      if (!self.disenddate)
      {
        if (self.$moment(self.startdate, "DD/MM/YYYY").isBefore(self.$moment(self.enddate, "DD/MM/YYYY")))
        {
          thedatevali = false;
        }
        else
        {
          thedatevali = true;
          alert("Start date can't be after End date");
        }
      }
      if (!thedatevali)
      {
        if (self.ShiftToChange == null)
        {
          self.error.shiftCode = !self.ShiftToChange ? self.$t("PlsChooseShift") : "";
        }
        else
        {
          let temp = self.thearrayhere();
          self.LoadingDialog = true;
          const response = await Axios.post(`${self.url}ShiftAssignment/ChangeShiftAssignment`,temp);
          self.LoadingDialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.ChangeShiftDialog = false;
            self.GetShiftAssignment();
          }
          else
          {
            alert(response.data.message);
          }
        }
      }
    },

    thearrayhere() {
      let self = this;
      let thearray = [];
      for (let i = 0; i < self.selected1.length; i++)
      {
        let tempimport = {
          employee_ID: self.selected1[i].employee_ID,
          shift_ID: self.ShiftToChange,
          // startDate: self.startdate,
          startDate: self.startdate !== null ? self.$moment(self.startdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss') : null,
          endDate: self.enddate !== null ? self.$moment(self.enddate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss') : null,
          disableEndDate: self.disenddate,
          user_ID: store.state.userid
        };
        thearray.push(tempimport);
      }
      return thearray;
    },

    CloseChangeShift() {
      this.ChangeShiftDialog = false;
      this.ShiftToChange = null;
      this.error.shiftCode = [];
    },
    
    goToday() {
      this.themonth = new Date().getMonth();
      this.theyear = new Date().getFullYear();
      this.WhatTheMonth = this.monthNames[this.themonth].txt + " " + this.theyear;
      this.GetShiftAssignment();
    },

    lastMonth() {
      if (this.themonth != 0)
      {
        this.themonth--;
        this.WhatTheMonth = this.monthNames[this.themonth].txt + " " + this.theyear;
      }
      else if (this.themonth == 0)
      {
        this.themonth = 11;
        this.theyear--;
        this.WhatTheMonth = this.monthNames[this.themonth].txt + " " + this.theyear;
      }
      this.thedatehead = this.daysInMonth();
      this.GetShiftAssignment();
    },

    nextMonth() {
      if (this.themonth != 11)
      {
        this.themonth++;
        this.WhatTheMonth = this.monthNames[this.themonth].txt + " " + this.theyear;
      }
      else if (this.themonth == 11)
      {
        this.themonth = 0;
        this.theyear++;
        this.WhatTheMonth = this.monthNames[this.themonth].txt + " " + this.theyear;
      }
      this.thedatehead = this.daysInMonth();
      this.GetShiftAssignment();
    },

    chipWidth(item) {
      let start = new Date(item.startDate);
      let end = new Date(item.endDate);
      if (item.endDate == null)
      {
        end = new Date(this.theyear, this.themonth + 1, 0);
      }
      const dateRangeInDays = end.getDate() - start.getDate() + 1;
      return dateRangeInDays * 41;
    },

    dateRangesWithGaps1(item) {
      // Step 1: Sort the input date ranges by start date
      if (item.length != 0) {
        const sortedRanges = item.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );

        const filledRanges = [];
        let currentDate = new Date(sortedRanges[0].startDate);

        // Step 2: Handle the case where the first range doesn't start on the 1st day of the month
        if (currentDate.getDate() !== 1) {
          const missingStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          const missingEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
          filledRanges.push({
            startDate: missingStart,
            endDate: missingEnd,
            shiftColor: 'transparent',
            thetext: " ",
            shift_Code: null,
            start_Time: null,
            end_Time: null
          });
        }

        // Step 3: Iterate through each range and fill in any missing gaps
        for (const range of sortedRanges) {
          const startDate = new Date(range.startDate);
          const endDate = new Date(range.endDate);

          // Fill the gap before the current range if there's one
          if (startDate > currentDate) {
            filledRanges.push({
              shiftColor: 'transparent',
              thetext: " ",
              shift_Code: null,
              start_Time: null,
              end_Time: null,
              startDate: currentDate,
              endDate: new Date(startDate.getTime() - 24 * 60 * 60 * 1000)
            });
          }

          // Push the current range into the result
          filledRanges.push({
            ...range,
            thetext: range.shift_Code + " : " + range.start_Time + " - " + range.end_Time,
            startDate: range.startDate,
            endDate: range.endDate,
          });

          // Update currentDate for the next iteration
          currentDate = new Date(endDate.getTime() + 24 * 60 * 60 * 1000);
        }

        return filledRanges;
      }
    },
    GetPosition() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      Axios
        .post(`${self.url}Position/GetPosition`, temp)
        .then(function (response) {
          self.positionList = response.data.data;
          self.positionList.map(function (data) {
            self.subPositionList.push({
              text: data.position_Name,
              value: data.position_ID,
            });
          });
        });
    },
    GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      Axios
        .post(`${self.url}Department/GetAllDepartmentsByCompanyID`, temp)
        .then(function (response) {
          self.departmentList = response.data.data;

          self.departmentList.map(function (data) {
            self.subDepartmentList.push({
              text: data.department_Name,
              value: data.department_ID,
            });
          });
        });
    },
    togglePositionMenu(event) {
      this.positionMenuX = event.clientX;
      this.positionMenuY = event.clientY;
      this.positionMenu = !this.positionMenu;
    },
    handlePositionSelect(position) {
      this.selectedPosition = position;
      this.selectone = position.value;

      if (position.value == 1) {
        this.subPositions = this.subPositionList;
        this.selectedSubPositions = [];
      }
      if (position.value == 2) {
        this.subPositions = this.subDepartmentList;
        this.selectedSubPositions = [];
      }
      if (position.value == 3) {
        this.subPositions = this.subemptype;
        this.selectedSubPositions = [];
      }
      if (position.value == 4) {
        this.subPositions = this.subShiftList;
        this.selectedSubPositions = [];
      }
      this.subMenuVisible = true;
      this.subMenu = true;
      this.subMenuX = this.positionMenuX + 100; // Adjust the value for proper positioning
      this.subMenuY = this.positionMenuY;
      this.positions.forEach((position) => {
        if (position.value == this.selectone) {
          position.color = "#F99D20";
        } else {
          position.color = "initial";
        }
      });
      this.ShiftList = this.filteremplist;
    },
    handleSubPositionSelect(subPosition) {
      this.positionMenu = this.subMenu;
      //this.selectedSubPositions = subPosition;
      const index = this.selectedSubPositions.indexOf(subPosition);
      if (index === -1) {
        this.selectedSubPositions.push(subPosition);
      } else {
        this.selectedSubPositions.splice(index, 1);
      }
      this.positionMenu = true;

      let myalllist = this.filteremplist;
      this.ShiftList = [];
      if (this.selectone == 1) {
        this.ShiftList = myalllist.filter(
          (date) => this.selectedSubPositions.some(item => item.text === date.position_Name)
        );
      }
      if (this.selectone == 2) {
        this.ShiftList = myalllist.filter(
          (date) => this.selectedSubPositions.some(item => item.text === date.department_Name)
        );
      }
      if (this.selectone == 3) {
        this.ShiftList = myalllist.filter(
          (date) => this.selectedSubPositions.some(item => item.text === date.employee_Type)
        );
      }
      if (this.selectone == 4) {
        this.ShiftList = myalllist.filter((item) =>
          item.dateList.some(dateItem =>
            this.selectedSubPositions.some(selectedItem =>
              selectedItem.text == dateItem.shift_Code
            )
          )
        );
      }
      if (this.selectedSubPositions.length == 0) {
        this.ShiftList = myalllist;
      }
    },
    checkmylist() {
      let checkvalidate = this.ShiftList.filter((date) => date.checked == true);

      let checkfalsevalidate = this.ShiftList.filter((date) => date.checked == false);
      if (checkfalsevalidate.length != 0) {
        this.selectAll1 = false;
      }

      if (checkvalidate.length != 0) {
        if (checkvalidate.length == this.ShiftList.length) {
          this.selectAll1 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
>>>.mdi-menu-down::before {
  color: #f99d20;
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 84px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 15px;
  font-weight: normal;
  color: #f99d20;
  height: 55px;
}

tbody tr {
  height: 75px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

label {
  color: #8a8c8e;
  font-weight: normal;
}

.date-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin-left: 7rem; */
}

.date-item {
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  margin-bottom: 4px;
  width: 41px;
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.day-number {
  font-size: 14px;
  margin-top: 4px;
}

.day-name {
  font-size: 12px;
  margin-bottom: 4px;
}

.position-style{
color: #817F7F;
font-weight: normal;
}

.headcol {
  position: absolute;
}

.theco {
  accent-color: #f99d20;
}

.form-checkbox input:checked~.checkmark {
  background-color: #f99d20;
}

>>>.form-checkbox input.white:checked~.checkmark:after {
  border: solid white;
  border-width: 0 3px 3px 0;
}

/* Customize the label (the container) */
.form-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

/* Hide the browser's default checkbox */
.form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #7f7171 !important;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

>>>.tile:hover {
  background: #f2e4d1 !important;
}

>>>.tile:active {
  background: #f99d20 !important;
}

>>>.tile2:hover {
  background: #f2e4d1 !important;
}

>>>.tile2:active {
  background: #f99d20 !important;
}
</style>
