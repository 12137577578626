<template>
  <div>
    <!-- <div
      style="
        padding-left: 0px;
        padding-right: 1%;
        margin-bottom: 1rem;
        margin-left: 1rem;
      "
    >
      <v-row class="mr-0">
        <v-btn @click="back()" icon color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p
          class="headtext venti"
          style="
            cursor: pointer;
            font-size: 25px;
            font-family: 'Kanit';
            margin-left: 4px;
            margin-top: 20px;
          "
          @click="back()"
        >
          {{ $t("socialsecreport") }}
        </p>
      </v-row>
    </div> -->
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("socialsecreport") }}
        </p>
    </div>

    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="3" md="6" lg="3" xl="2">
          <v-row class="pt-2" style="height: 30px; padding-top: 5px">
            <p style="color: #8a8c8e; margin-left: 15px; margin-top: 10px">
              {{ $t("Year") }}
            </p>
            <p :style="thethaihead ? 'padding-left:110px;' : ''" style="color: #8a8c8e; padding-left: 86px; margin-top: 10px">
              {{ $t("Month") }}
            </p>
          </v-row>
          <div
            id="duo"
            class="d-flex"
            style="
              background-color: #f99d20;
              border-radius: 5px;
              margin-top: 25px;
            "
          >
            <date-picker
              name="payrollyearpicker"
              v-model="todayyears"
              v-on:change="getstart()"
              type="year"
              color="#F99D20"
              class="minizebox ml-1 mr-1 mt-1 mb-1 pl-0 today_years"
            >
              <template slot="icon-calendar">
                <img
                  src="@/assets/images/down1@2x.png"
                  style="
                    width: 30px;
                    height: 15px;
                    margin-top: 4px;
                    margin-right: -6px;
                  "
                  class="pl-2"
                />
              </template>
            </date-picker>
            <v-autocomplete
              class="selectbox minizebox ml-0 mt-1 mr-1 mb-1 pl-1"
              v-model="monthselet"
              height="34px"
              single-line
              :items="months"
              item-text="txt"
              item-value="val"
              background-color="#FFFFFF"
              color="#f99d20"
              persistent-hint
              v-on:change="getstart()"
              style="max-width: 200px"
            ></v-autocomplete>
          </div>
        </v-col>
        <!-- <v-col class="pb-0" cols="12" xs="2" sm="2" md="2" lg="1" xl="1">
          <p class="mt-8 ml-2 pb-0">{{ $t("Month") }}</p>
        </v-col> -->
        <!-- <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-autocomplete
            :items="months"
            item-text="txt"
            item-value="val"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="monthselet"
            style="
              margin-top: 1.5rem;
              min-width: 150px;
              max-width: 150px;
              border-radius: 5px;
            "
            dense
            v-on:change="getstart()"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col> -->
        <!-- <v-col class="pb-0" cols="12" xs="2" sm="2" md="2" lg="1" xl="1">
          <p class="mt-8 pb-0">{{ $t("Year") }}</p>
        </v-col> -->
        <!-- <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
          <date-picker
            v-model="todayyears"
            type="year"
            class="mr-4 mb-1 pl-0"
            style="
              margin-top: 1.5rem;
              min-width: 150px;
              max-width: 150px;
              border-radius: 5px;
            "
            v-on:change="getstart()"
          >
            <template slot="icon-calendar">
              <img
                src="@/assets/images/down1@2x.png"
                style="
                  width: 20px;
                  height: 20px;
                  margin-right: -7px;
                  padding-top: 3px;
                "
              />
            </template>
          </date-picker>
        </v-col> -->
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
          <v-select
            :items="options"
            item-text="txt"
            item-value="val"
            @change="Apply"
            :label="$t('EmploymentType')"
            v-model="theEmptype"
            color="#F99D20"
            class="movelf ml-2 mt-6 customcheckbox"
            outlined
            persistent-hint
            multiple
            style="border-radius: 4px"
            dense
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon :color="isSelectAll ? '#F99D20' : ''">
                    {{ isSelectAll ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Showall') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="isSelectAll && index === 0">{{ $t('Showall') }}</span>
              <span v-else-if="!isSelectAll">{{ item.txt }}</span>
            </template>
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2" class="d-flex justify-end">
          <v-autocomplete
            :items="exportItem"
            item-text="exptxt"
            item-value="expval"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="expit"
            :placeholder="$t(`Export`)"
            style="
              min-width: 130px;
              max-width: 200px;
              border-radius: 5px;
              margin-top: 1.5rem;
            "
            dense
            v-on:change="changetoexport(expit)"
            :disabled="permission.export"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="ml-4 my-5">
        <v-col cols="12" xl="1" lg="2" md="4" class="pb-0">
          <p style="font-size: 16px; font-family: 'Kanit'">
            {{ $t("PersonnelManagement") }}
          </p>
          <p
            style="
              font-size: 18px;
              font-family: 'Kanit';
              color: #f99d20;
              margin-bottom: 0px !important;
            ">
            {{ filteredList.length }}
          </p>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" xl="1" lg="2" md="4" class="pb-0">
          <p style="font-size: 16px; font-family: 'Kanit'">
            {{ $t("TotalNetPaid") }} SSO
          </p>
          <p
            style="
              font-size: 18px;
              font-family: 'Kanit';
              color: #f99d20;
              margin-bottom: 0px !important;
            ">
            {{ totalNetPaid }}
          </p>
        </v-col>
      </v-row>

      <div class="mt-5">
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :items="filteredList"
          hide-default-footer
          hide-default-header
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <!-- <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th style="text-align:center;">{{ $t("personal_id") }}</th>
                <th style="text-align:center;">{{ $t("Prefix") }}</th>
                <th style="text-align:center;">{{ $t("name") }}</th>
                <th style="text-align:center;">{{ $t("last_name") }}</th>
                <th style="text-align:right;">{{ $t("Salary") }}</th>
                <th style="text-align:right;">{{ $t("social_security") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td style="text-align:center;">{{ isitnull(item.personalID_Number) }}</td>
              <td style="text-align:center;">{{ isitnull(item.prefix) }}</td>
              <td style="text-align:center;">{{ item.name }}</td>
              <td style="text-align:center;">{{ item.lastName }}</td>
              <td style="text-align:right;">{{ praseFloatComma(item.salaryamount) }}</td>
              <td style="text-align:right;">{{ praseFloatComma(item.socialSecurity) }}</td>
            </tr>
          </template> -->
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th id="table-header">{{ $t("personal_id") }}</th>
                <th id="table-header">{{ $t("Prefix") }}</th>
                <th id="table-header">{{ $t("name") }}</th>
                <th id="table-header">{{ $t("last_name") }}</th>
                <th id="table-header" class="align-right">{{ $t("Salary") }}</th>
                <th id="table-header" class="align-right">{{ $t("social_security") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td class="table-cell">{{ isitnull(item.personalID_Number) }}</td>
              <td class="table-cell">{{ isitnull(item.prefix) }}</td>
              <td class="table-cell">{{ item.name }}</td>
              <td class="table-cell">{{ item.lastName }}</td>
              <td class="table-cell align-right">{{ praseFloatComma(item.salaryamount) }}</td>
              <td class="table-cell align-right">{{ praseFloatComma(item.socialSecurity) }}</td>
            </tr>
          </template>
        </v-data-table>
       
        <div v-show="showtablerow" class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#f99d20"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <!-- print dialog -->
    <v-dialog v-model="printDialog" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p
                    class="headtext"
                    style="font-size: 28px; font-family: 'Kanit'"
                  >
                    {{ $t("socialsecreport") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="ggg"
                    style="padding: -10px; padding-right: 0px"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <div class="mt-4" id="middlecol">
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :items="SocialReportList"
                hide-default-footer
                hide-default-header
                :items-per-page="itemsPerPage1"
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("personal_id") }}</th>
                      <th>{{ $t("Prefix") }}</th>
                      <th>{{ $t("name") }}</th>
                      <th>{{ $t("last_name") }}</th>
                      <th>{{ $t("Salary") }}</th>
                      <th>{{ $t("social_security") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ isitnull(item.personalID_Number) }}</td>
                    <td>{{ isitnull(item.prefix) }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.lastName }}</td>
                    <td>{{ item.salaryamount }}</td>
                    <td>{{ item.socialSecurity }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              printDialog = false;
              expit = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-family: kanit, Regular; font-weight: normal"
            @click="downloadPDF()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import enurl from "@/api/environment";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import store from "@/store";
import DatePicker from "vue2-datepicker";
import XLSX from "xlsx";
import $ from "jquery";
import CheckViewExport from "@/function/RolePermissions.js";
import ThaiFontBoldBase64 from "@/function/global.js";
import ThaiFontBase64 from "@/function/global.js";
export default {
  components: { DatePicker },
  mixins: [CheckViewExport, ThaiFontBoldBase64, ThaiFontBase64,],
  data() {
    return {
       permission: {
        export: true,
      },
      url: enurl.apiUrl,
      tot: 0,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      itemsPerPage1: 500,
      items: [10, 20, 30, 50, 100],
      showtablerow: false,
      LoadingDialog: false,
      todaydate: new Date(),
      todayyears: new Date(),
      expit: "",
      employsort: 1,
      showact: 1,
      monthselet: "",
      showDep: false,
      sheetTitle: null,
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      printDialog: false,
      SocialReportList: [],
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      theEmptype: [],
      filteredList: [],
      thesheetnum: 0,
      Commonsocial: {
        percentage: 5,
      },
      ver1: {
        companyName: "Thety",
        accountNumber: "1234567890123",
        address : "",
        subDistrict: "MyaKhwarNyo",
        district: "TharKayTa",
        province: "Yangon",
        postalcode: "116006",
        telephone: "094548515426",
        contributionRate: "1000",
        thaiYear: "",
        thaiMonth: "",
        totalWages: 0,
        totalAmount: 0,
        totalAmount2: 0,
        finalTotal: 0,
        currencyBaht: "",
        insuredPerson: "0",
        ver2pages: "0",
        paymentDate: "",
      },
      ver1arr: [],
      ver2arr: [],
      ver2_thaiMonth: "",
      ver2_thaiYear: "",
      dummyImage: require("@/assets/images/SSF Logo2.jpg"),
    };
  },
  computed: {
    months() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    thethaihead() {
      return this.whatthehead();
    },
    options(){
      return [
        { val: 1, txt: this.$t("Monthly Payment")},
        { val: 2, txt: this.$t("Daily Payment")}
      ]
    },
    thaiMonths() {
      return [
        { val: 1, txt: "มกราคม" },
        { val: 2, txt: "กุมภาพันธ์" },
        { val: 3, txt: "มีนาคม" },
        { val: 4, txt: "เมษายน" },
        { val: 5, txt: "พฤษภาคม" },
        { val: 6, txt: "มิถุนายน" },
        { val: 7, txt: "กรกฎาคม" },
        { val: 8, txt: "สิงหาคม" },
        { val: 9, txt: this.$t("กันยายน") },
        { val: 10, txt: this.$t("ตุลาคม") },
        { val: 11, txt: this.$t("พฤศจิกายน") },
        { val: 12, txt: this.$t("ธันวาคม") }
      ];
    },
    isSelectAll() {
      return this.theEmptype.length === this.options.length;
    },
    totalNetPaid() {
    if (!Array.isArray(this.filteredList)) {
      return "0.00";
    }
    const total = this.filteredList.reduce((sum, item) => {
    let socialSecurity = item.socialSecurity;

    if (typeof socialSecurity === 'string') {
      socialSecurity = socialSecurity.replace(/,/g, '');
    }
    const value = parseFloat(socialSecurity) || 0;
    return sum + value;
      }, 0);

      return this.praseFloatComma(total);
    },
  },
  mounted() {
    let d = new Date();
    this.monthselet = d.getMonth() + 1;
    this.getstart();
    this.GetsocialconRate();
    this.Getver1detail();
    this.permission = this.CheckViewExport(184, 185);
  },
  methods: {
    back() {
      this.$router.push(`/payrollreport`).catch(()=>{});
    },
    whatthehead() {
      if (localStorage.Templang === 'ประเทศไทย')
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    praseFloatComma(item)
    {
      if (item != null) {
    let resData = parseFloat(item).toFixed(2);
    return resData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "0.00";
    },  
    getstart() {
      try {
        let self = this;
        self.SocialReportList = [];
        self.LoadingDialog = true;
        let thisYear = "";
        if (self.todayyears.getFullYear()) {
          thisYear = self.todayyears.getFullYear();
        } else {
          self.todayyears = new Date();
          thisYear = new Date().getFullYear();
        }
        let temp = {
          year: thisYear,
          month: self.monthselet,
          companyID: store.state.companyID,
        };
        axios
          .post(`${self.url}Payment_Due/GetSocialSecurityReport`, temp)
          .then(function (response) {
            self.SocialReportList = response.data.data;
            self.filteredList = response.data.data;
            if (self.SocialReportList.length != 0) {
              self.tot = self.SocialReportList.length;
              self.showtablerow = true;
            } else {
              self.showtablerow = false;
            }
            self.sortIDout();
            self.LoadingDialog = false;
          });
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },
    toggle() {
      if (this.isSelectAll) {
        this.theEmptype = [];
        this.Apply();
      } else {
        this.theEmptype = this.options.map(option => option.val);
        this.Apply();
      }
    },
    Apply() {
      this.filteredList = this.theEmptype.length
        ? this.SocialReportList.filter(report =>
            this.theEmptype.includes(report.employmentTypeID)
          )
        : this.SocialReportList;
      this.sortIDout();
    },
    sortIDout(){
      let self = this;
      let tempArr = self.filteredList;
      self.filteredList = [];
      const uniqueEids = [...new Set(tempArr.map(item => item.employee_ID))];
      for(let i = 0; i<uniqueEids.length; i++){
        let personalID_Number = null;
        let prefix = null;
        let name = null;
        let lastName = null;
        let salary = 0;
        let ssf = 0;
        let payment_Acc = null;
        let payment_Date = null;
        let companyName = null;
        let employee_ID = null;
        for(let j = 0; j<tempArr.length; j++){
          if(tempArr[j].employee_ID == uniqueEids[i]){
            personalID_Number = tempArr[j].personalID_Number;
            prefix = tempArr[j].prefix;
            name = tempArr[j].name;
            lastName = tempArr[j].lastName;
            salary += parseFloat(tempArr[j].salaryamount); 
            //ssf += tempArr[j].socialSecurity;
            payment_Acc = tempArr[j].payment_Acc;
            payment_Date = tempArr[j].payment_Date;
            companyName = tempArr[j].companyName;
            employee_ID = tempArr[j].employee_ID;
          }
        }

        if(salary >= self.Commonsocial.lowestSubvention)
        {
          if (salary >= self.Commonsocial.highestsalary) 
          {
            ssf = self.Commonsocial.highestSubvention;
          } 
          else if (salary < self.Commonsocial.lowestsalary) 
          {
            ssf = 0;
          }
          else if (salary == self.Commonsocial.lowestsalary) 
          {
            ssf = self.Commonsocial.lowestSubvention;
          } 
          else 
          {
            ssf = Math.round((self.Commonsocial.percentage / 100) * salary);
          }
        }
        else
        {
          ssf = 0;
        }

        // ssf = salary * (self.Commonsocial.percentage/100);

        let temp = {
          employee_ID: employee_ID,
          personalID_Number: personalID_Number,
          prefix: prefix,
          name: name,
          lastName: lastName,
          salaryamount: salary, 
          socialSecurity: ssf,
          payment_Acc: payment_Acc,
          payment_Date: payment_Date,
          companyName: companyName,
        };
        self.filteredList.push(temp);
      }
    },
    changetoexport(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1)
      {
        // Excel
        if (self.SocialReportList.length != 0)
        {
          const workbook = new exceljs.Workbook();
          const worksheet = workbook.addWorksheet('Social Security');
          let headers;
          if (localStorage.Templang === 'ประเทศไทย')
          {
            headers = ['หมายเลขบัตรประชาชน', 'คำนำหน้า', 'ชื่อ', 'นามสกุล', 'เงินเดือน', 'ประกันสังคม'];
          }
          else
          {
            headers = ['Personal ID', 'Prefix', 'Name', 'Last Name', 'Salary', 'Social Security'];
          }
          const headerRow = worksheet.addRow(headers);

          // Apply styles to the headers
          headerRow.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'EEEEEE' },
            };
            cell.font = {
              bold: true,
              color: { argb: 'fa7d00' },
            };
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });

          // Set the preferred height for the header row
          const preferredHeight = 30;
          worksheet.getRow(1).height = preferredHeight;

          // Add the data rows with specific alignments
          self.filteredList.forEach(item => {
            const dataRow = worksheet.addRow([
              item.personalID_Number == "" ? "-" : item.personalID_Number,
              item.prefix,
              item.name,
              item.lastName,
              self.praseFloatComma(item.salaryamount),
              self.praseFloatComma(item.socialSecurity)
            ]);

              // Set alignment for each cell in the row
              dataRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                if (colNumber <= 4)
                {
                  // Left align for Personal ID, Prefix, Name, Last Name
                  cell.alignment = { horizontal: 'left' };
                }
                else
                {
                  // Right align for Salary, Social Security
                  cell.alignment = { horizontal: 'right' };
                }
              });
            });
            self.AdjustColumnWidth(worksheet);
            const todayDate = moment().format("YYYYMMDD");
            const fileName = `Salary History Report - ${todayDate}.xlsx`;
            // Save the workbook with the generated file name
            workbook.xlsx.writeBuffer().then((val) => {
              let blob = new Blob([val], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
              });
              saveAs(blob, fileName);
            }); 
            self.LoadingDialog = false;
              // let tempsheetname = "";
              // let wb = XLSX.utils.book_new();
              // let wsdata = [];
              // if (localStorage.Templang == "ประเทศไทย") {
              //   for (let v of self.SocialReportList) {
              //     wsdata.push({
              //       ["หมายเลขบัตรประชาชน"]: v.personalID_Number,
              //       ["คำนำหน้าชื่อ"]: v.prefix,
              //       ["ชื่อ"]: v.name,
              //       ["นามสกุล"]: v.lastName,
              //       ["เงินเดือน"]: v.salaryamount,
              //       ["ประกันสังคม"]: v.socialSecurity,
              //     });
              //   }
              // } else {
              //   if (sessionStorage.flag == "Thai") {
              //     for (let v of self.SocialReportList) {
              //       wsdata.push({
              //         ["หมายเลขบัตรประชาชน"]: v.personalID_Number,
              //         ["คำนำหน้าชื่อ"]: v.prefix,
              //         ["ชื่อ"]: v.name,
              //         ["นามสกุล"]: v.lastName,
              //         ["เงินเดือน"]: v.salaryamount,
              //         ["ประกันสังคม"]: v.socialSecurity,
              //       });
              //     }
              //   } else {
              //     for (let v of self.SocialReportList) {
              //       wsdata.push({
              //         ["Personal ID"]: v.personalID_Number,
              //         ["Prefix"]: v.prefix,
              //         ["Name"]: v.name,
              //         ["Last Name"]: v.lastName,
              //         ["Salary"]: v.salaryamount,
              //         ["Social Security"]: v.socialSecurity,
              //       });
              //     }
              //   }
              // }
              // const ws = XLSX.utils.json_to_sheet(wsdata);
              // tempsheetname = `Social Security Report`;
              // self.sheetTitle = `Social Security`;
              // XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
              // XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
              //   compression: true,
              // });
              // self.LoadingDialog = false;
            }
        else
        {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        // self.printDialog = true;
        // self.todaydate = new Date();
        // self.itemsPerPage1 = self.SocialReportList.length;
        self.downloadSSO();
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.SocialReportList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.SocialReportList) {
              wsdata.push({
                ["หมายเลขบัตรประชาชน"]: v.personalID_Number,
                ["คำนำหน้าชื่อ"]: v.prefix,
                ["ชื่อ"]: v.name,
                ["นามสกุล"]: v.lastName,
                ["เงินเดือน"]: v.salaryamount,
                ["ประกันสังคม"]: v.socialSecurity,
              });
            }
          } else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.SocialReportList) {
                wsdata.push({
                  ["หมายเลขบัตรประชาชน"]: v.personalID_Number,
                  ["คำนำหน้าชื่อ"]: v.prefix,
                  ["ชื่อ"]: v.name,
                  ["นามสกุล"]: v.lastName,
                  ["เงินเดือน"]: v.salaryamount,
                  ["ประกันสังคม"]: v.socialSecurity,
                });
              }
            } else {
              for (let v of self.SocialReportList) {
                wsdata.push({
                  ["Personal ID"]: v.personalID_Number,
                  ["Prefix"]: v.prefix,
                  ["Name"]: v.name,
                  ["Last Name"]: v.lastName,
                  ["Salary"]: v.salaryamount,
                  ["Social Security"]: v.socialSecurity,
                });
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Social Security Report`;
          self.sheetTitle = `Social Security`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },

    AdjustColumnWidth(worksheet) {
      worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v.toString().length);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        column.width = maxLength + 8;
      })
    },

    downloadPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Social Security Report.pdf");
      });
    },
    isitnull(va) {
      if (va) {
        return va;
      } else {
        return "-";
      }
    },
    DateFormat(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}/${month}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}-${month}-${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    GetsocialconRate() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurityFund/GetSocialSecurityFund`, temp)
        .then(function (response) {
          self.Commonsocial.percentage = parseInt(response.data.data.contributionRate);
          self.Commonsocial.lowestsalary = parseInt(response.data.data.lowestBaseSalary);
          self.Commonsocial.highestsalary = parseInt(response.data.data.highestBaseSalary);
          self.Commonsocial.highestSubvention = parseInt(response.data.data.highestSubvention);
          self.Commonsocial.lowestSubvention = parseInt(response.data.data.lowestSubvention);
        });
    },
    Getver1detail(){
      let self = this;
      let temp = {
        companyID: store.state.companyID
      };
      axios.post(`${self.url}Payment_Due/GetDownloadForSSODetail`, temp)
        .then(function (response) {
          self.ver1arr = response.data.data;
        });
    },
    async downloadSSO() {
      let self = this;
      self.ver2arr = [];
      const uniqueEids = [...new Set(self.filteredList.map(item => item.employee_ID))];

      for(let i = 0; i<uniqueEids.length; i++){
        let salary = 0;
        let ssf = 0;
        let payment_Acc = null;
        let payment_Date = null;
        let companyName = null;
        let name = null;
        let lastName = null;
        let personalID_Number = null;
        for(let j = 0; j<self.filteredList.length; j++){
          if(self.filteredList[j].employee_ID == uniqueEids[i]){
            salary += parseFloat(self.filteredList[j].salaryamount); 
            ssf += self.filteredList[j].socialSecurity;
            payment_Acc = self.filteredList[j].payment_Acc;
            payment_Date = self.filteredList[j].payment_Date;
            companyName = self.filteredList[j].companyName;
            name = self.filteredList[j].name;
            lastName = self.filteredList[j].lastName;
            personalID_Number = self.filteredList[j].personalID_Number;
          }
        }
        let temp = {
          salaryamount: salary, 
          socialSecurity: ssf,
          payment_Acc: payment_Acc,
          payment_Date: payment_Date,
          companyName: companyName,
          name: name,
          lastName: lastName,
          personalID_Number: personalID_Number
        };
        self.ver2arr.push(temp);
      }

      for(let k = 0; k<self.ver2arr.length; k++){
        self.ver1.totalWages += self.ver2arr[k].salaryamount;
        self.ver1.totalAmount += self.ver2arr[k].socialSecurity;
        
      }
      
      const totalpages = self.ver2arr.length / 10;
      if (Number.isInteger(totalpages)) 
      {
        self.ver1.ver2pages = totalpages.toString();
        self.thesheetnum = totalpages + 1;
      } 
      else 
      {
        self.ver1.ver2pages = Math.ceil(totalpages).toString();
        self.thesheetnum = Math.ceil(totalpages) + 1;
      }
        self.ver1.companyName = self.ver2arr[0].companyName;
        
        self.ver1.accountNumber = self.ver2arr[0].payment_Acc;
      self.ver1.address =  self.ver1arr.address;
      self.ver1.contributionRate = self.ver1arr.contri_Rate == null ? "0.00" : self.ver1arr.contri_Rate;
      self.ver1.subDistrict = self.ver1arr.subDistrict == null ? "" : self.ver1arr.subDistrict;
      self.ver1.district = self.ver1arr.district == null ? "" : self.ver1arr.district;
      self.ver1.province = self.ver1arr.province == null ? "" : self.ver1arr.province;
      self.ver1.postalcode = self.ver1arr.postalCode.toString();
      self.ver1.telephone = self.ver1arr.telephone;

      self.ver1.paymentDate = self.ver2arr[0].payment_Date.toString();
      self.ver1.totalWages = parseFloat(self.ver1.totalWages).toFixed(2);
      self.ver1.totalAmount = parseFloat(self.ver1.totalAmount).toFixed(2);
      self.ver1.totalAmount2 = self.ver1.totalAmount;

      let finaltemp = self.ver1.totalAmount + self.ver1.totalAmount;
      finaltemp = parseFloat(finaltemp).toFixed(2);
      self.ver1.finalTotal = finaltemp.toString();
      self.ver1.insuredPerson = self.ver2arr.length.toString();
      
      setTimeout(() => {
        self.GetPDFAttach();
      }, "700");
    },
    GetPDFAttach(){
      let self = this;
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: [297, 210], 
      });
      for (let i = 0; i < self.thesheetnum; i++) 
      {
        doc.addFileToVFS("THSarabunNew.ttf", self.ThaiFontBase64());
        doc.addFileToVFS("THSarabunNewBold.ttf", self.ThaiFontBoldBase64());
        doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        let normalfont = "THSarabunNew";
        let boldfont = "THSarabunNewBold";
        if(i == 0)
        {
          doc.setFont(boldfont);
          doc.setFontSize(16.5);
          doc.addImage(self.dummyImage, "JPEG", 7, 1, 26, 26);
          doc.text("แบบรายการแสดงการส่งเงินสมทบ", 120, 15);
          doc.text("สปส.1-10  (ส่วนที่ 1)", 253, 5);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          let finalTotalNum = parseFloat(self.ver1.finalTotal).toFixed(2);
          let getpaymentMonth = moment(self.ver1.paymentDate).month() + 1;
          let getpaymentYear = moment(self.ver1.paymentDate).year();
          self.ver1.thaiYear = (getpaymentYear + 543).toString();
          self.thaiMonths.forEach((month) => {
            if (month.val === getpaymentMonth) 
            {
              self.ver1.thaiMonth = month.txt;
            }
          });
          self.ver1.accountNumber = self.ver1.accountNumber.replace(/-/g, "");
          let accountnum = self.ver1.accountNumber.split("");
          if (accountnum.length > 12) 
          {
            accountnum = accountnum.slice(0, 12);
          }
          let limit = 0;
          limit = accountnum.length;
          doc.text(self.ver1.companyName, 50, 32);
          doc.text(self.ver1.address + " " + self.ver1.subDistrict + " " + self.ver1.district + " " + self.ver1.province, 50, 47);
          doc.text(self.ver1.postalcode, 34, 62);
          doc.text(self.ver1.telephone, 70, 62);
          let conRate = self.Commonsocial.percentage + " %";
          doc.text(conRate, 230, 62);
          doc.text(self.ver1.thaiMonth, 70, 75);
          doc.text(self.ver1.thaiYear, 125, 75);
          let alignX = 105;
          let alignY = 98.5;
          const textWidth1 = doc.getTextWidth(self.ver1.totalWages);
          const textWidth2 = doc.getTextWidth(self.ver1.totalAmount);
          const textWidth3 = doc.getTextWidth(self.ver1.totalAmount2);
          const textWidth4 = doc.getTextWidth(self.ver1.finalTotal);
          const textWidth5 = doc.getTextWidth(self.ver1.insuredPerson);
          alignX = 130 - textWidth1;
          doc.text(this.numberWithCommas(self.ver1.totalWages.split('.')[0]), alignX, alignY);
          alignX = 130 - textWidth2;
          doc.text(this.numberWithCommas(self.ver1.totalAmount.split('.')[0]), alignX, alignY + 6.5);
          alignX = 130 - textWidth3;
          doc.text(this.numberWithCommas(self.ver1.totalAmount2.split('.')[0]), alignX, alignY + 13);
          alignX = 130 - textWidth4;
          doc.text(this.numberWithCommas(self.ver1.finalTotal.split('.')[0]), alignX, alignY + 19.5);
          alignX = 115 - textWidth5;
          doc.text(self.ver1.insuredPerson, alignX, alignY + 32.5);
          self.ver1.currencyBaht = self.convertBahttext(finalTotalNum);
          doc.text(self.ver1.currencyBaht, 13, alignY + 26.5);
          doc.text(self.ver1.ver2pages, 95, 153);
          doc.text(self.ver1.totalWages.split('.')[1], 130, 98.5);
          doc.text(self.ver1.totalAmount.split('.')[1], 130, 105);
          doc.text(self.ver1.totalAmount2.split('.')[1], 130, 111.5);
          doc.text(self.ver1.finalTotal.split('.')[1], 130, 118);
          let xindex = 201.5;
          let yindex = 31.5;
          for (var x1 = 0; x1 < limit; x1++) {
            if (x1 == 2) 
            {
              doc.text(accountnum[x1], xindex + 5, yindex);
              xindex = xindex + 10;
            } 
            else 
            {
              doc.text(accountnum[x1], xindex, yindex);
              if (x1 == 8) 
              {
                xindex = xindex + 10;
              } 
              else 
              {
                xindex = xindex + 5;
              }
            }
          }
          var startX = 40;
          var startY = 35;
          var numDots = 50;
          var dotSpacing = 2;
          doc.text("ชื่อสถานประกอบการ", 7, startY - 3);
          //Start Blank Dash --------------------------------------------------------------------------
          for (var i1 = 0; i1 < numDots + 2; i1++) 
          {
            doc.text((startX + i1 - 22) * dotSpacing, startY, "-");
          }
          doc.text("ชื่อสาขา ( ถ้ามี )", 7, startY + 4.5);
          for (var j = 0; j < numDots + 5; j++) 
          {
            doc.text((startX + j - 25) * dotSpacing, startY + 7.5, "-");
          }
          doc.text("ที่ตั้งสำนักงานใหญ่ / สาขา", 7, startY + 12);
          for (var k = 0; k < numDots - 2; k++) 
          {
            doc.text((startX + k - 18) * dotSpacing, startY + 15, "-");
          }
          for (var l = 0; l < numDots + 16; l++) {
            doc.text((startX - 36 + l) * dotSpacing, startY + 22.5, "-");
          }
          doc.text("รหัสไปรษณีย์", 7, startY + 26.5);
          for (var m = 0; m < numDots - 36; m++) 
          {
            doc.text((startX - 27 + m) * dotSpacing, startY + 30, "-");
          }
          doc.text("โทรศัพท์", 55, startY + 26.5);
          for (var n = 0; n < numDots - 36; n++) 
          {
            doc.text((startX - 6 + n) * dotSpacing, startY + 30, "-");
          }
          doc.text("โทรสาร", 97, startY + 26.5);
          for (var p = 0; p < numDots - 34; p++) 
          {
            doc.text((startX + 14 + p) * dotSpacing, startY + 30, "-");
          }
          //End Blank Dash -----------------------------------------------------------------------------

          //Start Rectangle Blank ----------------------------------------------------------------------
          var rectX = 200;
          var rectY = 27;
          var boxWidth = 5;
          var boxHeight = 7;
          doc.setFont(boldfont);
          doc.text("เลขที่บัญชี", 175, startY - 3);
          doc.text("ลำดับที่สาขา", 175, startY + 13);
          doc.text("อัตราเงินสมทบร้อยละ", 175, startY + 26.5);
          doc.setFontSize(31);
          doc.text("-", 211.5, 33);
          doc.text("-", 251.5, 33);
          doc.setFontSize(14);
          doc.setFont(normalfont);
          // Draw the rectangle box
          doc.rect(rectX, rectY, boxWidth, boxHeight);
          doc.rect(rectX + boxWidth, rectY, boxWidth, boxHeight);
          for (var r = 3; r < 10; r++) 
          {
            doc.rect(rectX + r * boxWidth, rectY, boxWidth, boxHeight);
          }
          doc.rect(rectX + 11 * boxWidth, rectY, boxWidth, boxHeight);
          if (limit > 10) 
          {
            limit = limit - 10;
            for (var r3 = 12; r3 < 12 + limit; r3++) 
            {
              doc.rect(rectX + r3 * boxWidth, rectY, boxWidth, boxHeight);
            }
          }
          for (var r2 = 0; r2 < 6; r2++) 
          {
            doc.rect(rectX + r2 * boxWidth, rectY + 16, boxWidth, boxHeight);
          }
          //Blank Dash Start
          for (var b = 0; b < numDots - 22; b++) {
            doc.text(rectX + 6 + b * dotSpacing, rectY + 38, "-");
          }
          //Blank Dash End

          //End Rectangle Blank ------------------------------------------------------------------------

          //Table start
          doc.setFont(normalfont);
          doc.setFontSize(14);
          for (var q = 0; q < numDots - 33; q++) 
          {
            doc.text((startX + q - 5) * dotSpacing, startY + 43, "-");
          }
          for (var v = 0; v < numDots - 37; v++) 
          {
            doc.text((startX + v + 17) * dotSpacing, startY + 43, "-");
          }
          for (var a = 0; a < numDots + 17; a++) 
          {
            doc.text((startX + a - 37) * dotSpacing, startY + 66.5, "-");
          }
          for (var c = 0; c < numDots + 17; c++) 
          {
            doc.text((startX + c - 37) * dotSpacing, startY + 73, "-");
          }
          for (var d = 0; d < numDots + 17; d++) 
          {
            doc.text((startX + d - 37) * dotSpacing, startY + 79.5, "-");
          }
          for (var e = 0; e < numDots + 17; e++) 
          {
            doc.text((startX + e - 37) * dotSpacing, startY + 86, "-");
          }
          for (var f = 0; f < numDots + 17; f++) 
          {
            doc.text((startX + f - 37) * dotSpacing, startY + 92.5, "-");
          }
          for (var f1 = 0; f1 < numDots - 34; f1++) 
          {
            doc.text((startX + f1 - 31) * dotSpacing, 175.5, "-");
          }
          for (var f2 = 0; f2 < numDots - 38; f2++) 
          {
            doc.text((startX + f2 + 3) * dotSpacing, startY + 120.5, "-");
          }
          for (var f3 = 0; f3 < numDots - 38; f3++) 
          {
            doc.text((startX + f3 + 3) * dotSpacing, startY + 127, "-");
          }
          for (var g = 0; g < numDots + 1; g++) 
          {
            doc.text((startX + g + 52) * dotSpacing, 88.5, "-");
          }
          for (var w = 0; w < numDots - 26; w++) 
          {
            doc.text((startX + w + 53) * dotSpacing, 95, "-");
          }
          for (var w1 = 0; w1 < numDots - 32; w1++) 
          {
            doc.text((startX + w1 + 81) * dotSpacing, 95, "-");
          }
          for (var s = 0; s < numDots - 3; s++) 
          {
            doc.text((startX + s + 56) * dotSpacing, 101.5, "-");
          }
          for (var t = 0; t < numDots + 6; t++) 
          {
            doc.text((startX + t + 47) * dotSpacing, 114.5, "-");
          }
          for (var u = 0; u < numDots + 6; u++) 
          {
            doc.text((startX + u + 47) * dotSpacing, 121, "-");
          }
          for (var u1 = 0; u1 < numDots; u1++) 
          {
            doc.text((startX + u1 + 52) * dotSpacing, 148, "-");
          }
          for (var u2 = 0; u2 < numDots - 4; u2++)
          {
            doc.text((startX + u2 + 56) * dotSpacing, 154.5, "-");
          }
          for (var u3 = 0; u3 < numDots + 6; u3++) 
          {
            doc.text((startX + u3 + 47) * dotSpacing, 174, "-");
          }
          for (var u4 = 0; u4 < numDots + 6; u4++) 
          {
            doc.text((startX + u4 + 47) * dotSpacing, 180.5, "-");
          }
          for (var u5 = 0; u5 < numDots - 14; u5++) 
          {
            doc.text((startX + u5 - 16.5) * dotSpacing, 182, "-");
          }
          for (var u6 = 0; u6 < numDots - 14; u6++) 
          {
            doc.text((startX + u6 - 16.5) * dotSpacing, 188.5, "-");
          }
          for (var u7 = 0; u7 < numDots - 2; u7++) 
          {
            doc.text((startX + u7 - 15) * dotSpacing, 195.5, "-");
          }
          for (var u8 = 0; u8 < numDots - 39; u8++) 
          {
            doc.text((startX + u8 - 12) * dotSpacing, 202.5, "-");
          }
          for (var u9 = 0; u9 < numDots - 34; u9++) 
          {
            doc.text((startX + u9 + 3) * dotSpacing, 202.5, "-");
          }
          for (var u0 = 0; u0 < numDots - 40; u0++) 
          {
            doc.text((startX + u0 + 23) * dotSpacing, 202.5, "-");
          }

          doc.text("1.", 7.5, 99);
          doc.text("เงินค่าจ้างทั้งสิ้น", 13, 99);
          // doc.text("-", 118, 98.5);
          // doc.text("-", 130, 98.5);
          doc.text("2.", 7.5, 105.5);
          doc.text("เงินสมทบผู้ประกันตน", 13, 105.5);
          // doc.text("-", 118, 105);
          // doc.text("-", 130, 105);
          doc.text("3.", 7.5, 112);
          doc.text("เงินสมทบนายจ้าง", 13, 112);
          // doc.text("-", 118, 111.5);
          // doc.text("-", 130, 111.5);
          doc.text("4.", 7.5, 118.5);
          doc.text("รวมเงินสมทบที่นำส่งทั้งสิ้น", 13, 118.5);
          // doc.text("-", 118, 118);
          // doc.text("-", 130, 118);
          doc.text("5.", 7.5, 131.5);
          doc.text("จำนวนผู้ประกันตนที่ส่งเงินสมทบ", 13, 131.5);
          doc.text("คน", 132, 131);
          doc.text("ข้าพเจ้าขอรับรองว่ารายการที่แจ้งไว้เป็นรายการที่ถูกต้องครบถ้วนและเป็นจริงทุกประการ",6,140.5);
          doc.text("พร้อมนี้ได้แนบ", 6, 147);
          doc.text("รายละเอียดการนำส่งเงินสมทบ", 10, 153.5);
          doc.text("จำนวน", 75, 153.5);
          doc.text("แผ่น หรือ", 110, 153.5);
          doc.text("จำนวน", 75, 160);
          doc.text("แผ่น", 110, 160);
          doc.text("แผ่นจานแม่เหล็ก", 10, 160);
          doc.text("อินเตอร์เน็ท", 10, 166.5);
          doc.text("อื่นๆ", 10, 173);
          doc.setFont(boldfont);
          doc.setFontSize(14);
          doc.text("ลงชื่อ", 38, 179.5);
          doc.text("นายจ้าง/ผู้รับมอบอำนาจ", 119, 179.5);
          doc.setFontSize(16.5);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text("(", 45, 187);
          doc.text(")", 119.5, 187);
          doc.text("ตำแหน่ง", 38, 193.5);
          doc.text("ยื่นแบบวันที่", 38, 200);
          doc.text("เดือน", 78, 200);
          doc.text("พ.ศ.", 119, 200);

          // function addCheckbox(x, y, checked) {
          //   // Draw a rectangle for the checkbox
          //   doc.rect(x, y, 3, 3);

          //   // If checked is true, fill the rectangle
          //   if (checked) 
          //   {
          //     doc.setFont("zapfdingbats");
          //     doc.setFontSize(10);
          //     doc.text("4", x, y + 2.5);
          //     doc.setFont(normalfont);
          //     doc.setFontSize(14);
          //   }
          // }
          // Add checkboxes with different states
          //addCheckbox(6, 151, true); // Checked checkbox
          let acx = 0;
          let acy = 0;
          acx = 6;
          acy = 151;
          doc.rect(acx, acy, 3, 3);
          doc.setFont("zapfdingbats");
          doc.setFontSize(10);
          doc.text("4", acx, acy + 2.5);
          doc.setFont(normalfont);
          doc.setFontSize(14);

          //addCheckbox(6, 157.5, true);
          acx = 6;
          acy = 157.5;
          doc.rect(acx, acy, 3, 3);
          doc.setFont("zapfdingbats");
          doc.setFontSize(10);
          doc.text("4", acx, acy + 2.5);
          doc.setFont(normalfont);
          doc.setFontSize(14);

          //addCheckbox(6, 164, false);
          acx = 6;
          acy = 164;
          doc.rect(acx, acy, 3, 3);
          //addCheckbox(6, 170.5, true);
          acx = 6;
          acy = 170.5;
          doc.rect(acx, acy, 3, 3);
          doc.setFont("zapfdingbats");
          doc.setFontSize(10);
          doc.text("4", acx, acy + 2.5);
          doc.setFont(normalfont);
          doc.setFontSize(14);

          doc.setFont(boldfont);
          doc.setFontSize(16.5);
          //Horizontal//
          doc.line(6, 68, 139.5, 68);
          doc.text("การนำส่งเงินสมทบสำหรับค่าจ้างเดือน", 7, 75.5);
          doc.text("พ.ศ.", 105, 75.5);
          doc.text("รายการ", 39.5, 88.5);
          doc.text("จำนวนเงิน", 105, 85.5);
          doc.text("บาท", 105, 92);
          doc.text("สต.", 131, 92);
          doc.line(6, 81, 139.5, 81);
          doc.line(90, 87.5, 139.5, 87.5);
          doc.line(6, 94, 139.5, 94);
          doc.line(6, 133, 139.5, 133);
          //doc.text("รายการ",)

          //Vartical//
          doc.line(6, 68, 6, 133);
          doc.line(11, 94, 11, 133);
          doc.line(90, 81, 90, 120.2);
          doc.line(90, 126.3, 90, 133);
          doc.line(128, 126.3, 128, 133);
          doc.line(128, 81, 128, 120.2);
          doc.line(139.5, 68, 139.5, 133);
          //Table End

          //Table2 Start
          //Horizontal
          doc.line(160, 68, 290, 68);
          doc.text("สำหรับเจ้าหน้าที่สำนักงานประกันสังคม", 190, 75.5);
          doc.text("สำหรับเจ้าหน้าที่ธนาคาร", 205, 135.5);
          doc.line(160, 81, 290, 81);
          doc.line(160, 127.5, 290, 127.5);
          doc.line(160, 140.5, 290, 140.5);
          doc.line(160, 187, 290, 187);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text("ชำระเงินวันที่", 165, 85.5);
          doc.text("เงินเพิ่ม ( ถ้ามี )", 165, 92);
          doc.text("บาท", 235, 92);
          doc.text("สตางค์", 277, 92);
          doc.text("ใบเสร็จรับเงินเลขที่", 165, 98.5);
          doc.text("ลงชื่อ", 165, 111.5);
          doc.text("(", 172, 118.5);
          doc.text(")", 286, 118.5);
          doc.text("ชำระเงินวันที่", 165, 145);
          doc.text("ใบเสร็จรับเงินเลขที่", 165, 151.5);
          doc.text("ประทับตราธนาคาร", 165, 158);
          doc.text("ลงชื่อ", 165, 171);
          doc.text("(", 172, 177.5);
          doc.text(")", 286, 177.5);
          //doc.text(")",)
          //vartical
          doc.setFont(boldfont);
          doc.setFontSize(16.5);
          doc.line(160, 68, 160, 187);
          doc.line(290, 68, 290, 187);
        } 
        else 
        {
          let Xpoint = 5;
          let Ypoint = 7;
          doc.setFont(boldfont);
          doc.setFontSize(22);
          doc.text("รายละเอียดการนำส่งเงินสมทบ", Xpoint, Ypoint);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text("สำหรับค่าจ้างเดือน", Xpoint, Ypoint + 6);
          doc.text(".....................................", Xpoint + 25, Ypoint + 6);
          doc.text("พ.ศ.", Xpoint + 55, Ypoint + 6);
          doc.text(".......................", Xpoint + 60, Ypoint + 6);
          doc.text("สปส. 1-10  (ส่วนที่ 2)", Xpoint + 260, Ypoint);
          Xpoint = 227;
          doc.text("แผ่นที่", Xpoint - 8, Ypoint + 6);
          doc.text(".....................", Xpoint + 8, Ypoint + 6);
          doc.text("ในจำนวน", Xpoint + 25, Ypoint + 6);
          doc.text(".....................", Xpoint + 38, Ypoint + 6);
          doc.text("แผ่น", Xpoint + 55, Ypoint + 6);
          Ypoint = 14;
          doc.text("เลขที่บัญชี", Xpoint - 8, Ypoint + 6);
          Ypoint = 21;
          doc.text("ลำดับที่สาขา", Xpoint - 8, Ypoint + 6);
          Xpoint = 30;
          doc.setFont(boldfont);
          doc.text("ชื่อสถานประกอบการ", Xpoint - 1, Ypoint + 6);
          doc.setFont(normalfont);
          doc.text(".......................................................................................................................................", Xpoint + 28, Ypoint + 6);
          let xposition = 10;
          let yposition = 30;
          let increment = 0;
          let recwidth = 0;
          let companytaxcode = "";
          xposition = 230;
          yposition = 16.3;
          self.ver2arr[0].payment_Acc=self.ver2arr[0].payment_Acc.replace(/-/g, "");
          companytaxcode = self.ver2arr[0].payment_Acc.toString().split("");
          while (companytaxcode.length > 12) 
          {
            companytaxcode.pop(); // Remove elements from the end of the array
          }
          xposition = 237;
          yposition = 16.3;
          companytaxcode = companytaxcode.map((v, i) => ({
            no: ++i,
            xposition: xposition,
            text: v,
          }));
          doc.setFont(normalfont);
          doc.setFontSize(13.5);
          increment = 0;
          companytaxcode.forEach((data) => {
            if (data.no == 3 || data.no == 10) 
            {
              doc.text(
                data.xposition + 1 + increment + 2.5,
                yposition + 3.2,
                data.text
              );
              increment += 6.5;
            } 
            else 
            {
              doc.text(
                data.xposition + 1 + increment,
                yposition + 3.2,
                data.text
              );
              increment += 4;
            }
          });
          doc.setLineWidth(0.1);
          recwidth = 8;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.setLineDash([]);
          xposition = xposition + recwidth + 2.5;
          doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
          recwidth = 28;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
          doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
          doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
          doc.line(xposition + 20, yposition, xposition + 20, yposition + 4.5);
          doc.line(xposition + 24, yposition, xposition + 24, yposition + 4.5);
          doc.setLineDash([]);
          doc.rect(xposition, yposition, recwidth, 4.5);
          xposition = xposition + recwidth + 2.5;
          doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
          if (companytaxcode.length > 10) 
          {
            if (companytaxcode.length == 11) 
            {
              recwidth = 8;
              doc.rect(xposition, yposition, recwidth, 4.5);
              doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
              doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
              doc.setLineDash([]);
            }
            else if (companytaxcode.length == 12) 
            {
              recwidth = 12;
              doc.rect(xposition, yposition, recwidth, 4.5);
              doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
              doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
              doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
              doc.setLineDash([]);
            }
          }
          else 
          {
            recwidth = 4;
            doc.rect(xposition, yposition, recwidth, 4.5);
          }
          let thet = "";
          companytaxcode = thet.split("");
          xposition = 237;
          yposition = 23.5;
          companytaxcode = companytaxcode.map((v, i) => ({
            no: ++i,
            xposition: xposition,
            text: v,
          }));
          doc.setFont(normalfont);
          doc.setFontSize(13.5);
          increment = 0;
          companytaxcode.forEach((data) => {
            doc.text(
              data.xposition + 1 + increment,
              yposition + 3.2,
              data.text
            );
            increment += 4;
          });
          doc.setLineWidth(0.1);
          recwidth = 24;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
          doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
          doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
          doc.line(xposition + 20, yposition, xposition + 20, yposition + 4.5);
          doc.setLineDash([]);
          Xpoint = 5;
          Ypoint = 30;
          xposition = 247;
          yposition = 23.5;
          //hori
          doc.line(Xpoint, Ypoint, Xpoint + 287, Ypoint);
          doc.line(Xpoint, Ypoint + 5, Xpoint + 287, Ypoint + 5);
          doc.line(Xpoint, Ypoint + 20, Xpoint + 287, Ypoint + 20);
          doc.line(Xpoint, Ypoint + 100, Xpoint + 287, Ypoint + 100);
          doc.line(Xpoint + 190, Ypoint + 108, Xpoint + 287, Ypoint + 108);
          //verti
          doc.line(Xpoint, Ypoint, Xpoint, Ypoint + 100);
          doc.line(Xpoint + 25, Ypoint, Xpoint + 25, Ypoint + 100);
          doc.line(Xpoint + 110, Ypoint, Xpoint + 110, Ypoint + 100);
          doc.line(Xpoint + 190, Ypoint, Xpoint + 190, Ypoint + 108);
          doc.line(Xpoint + 235, Ypoint, Xpoint + 235, Ypoint + 108);
          doc.line(Xpoint + 287, Ypoint, Xpoint + 287, Ypoint + 108);
          //dash line
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(Xpoint, Ypoint + 28, Xpoint + 287, Ypoint + 28);
          doc.line(Xpoint, Ypoint + 36, Xpoint + 287, Ypoint + 36);
          doc.line(Xpoint, Ypoint + 44, Xpoint + 287, Ypoint + 44);
          doc.line(Xpoint, Ypoint + 52, Xpoint + 287, Ypoint + 52);
          doc.line(Xpoint, Ypoint + 60, Xpoint + 287, Ypoint + 60);
          doc.line(Xpoint, Ypoint + 68, Xpoint + 287, Ypoint + 68);
          doc.line(Xpoint, Ypoint + 76, Xpoint + 287, Ypoint + 76);
          doc.line(Xpoint, Ypoint + 84, Xpoint + 287, Ypoint + 84);
          doc.line(Xpoint, Ypoint + 92, Xpoint + 287, Ypoint + 92);
          doc.setLineDash([]);
          Ypoint = 33.5;
          doc.setFont(boldfont);
          doc.text("1", Xpoint + 12.5, Ypoint);
          doc.text("2", Xpoint + 65, Ypoint);
          doc.text("3", Xpoint + 147.5, Ypoint);
          doc.text("4", Xpoint + 212.5, Ypoint);
          doc.text("5", Xpoint + 261, Ypoint);
          Ypoint = 40;
          doc.text("ลำดับที่", Xpoint + 9, Ypoint);
          doc.text("เลขประจำตัวประชาชน", Xpoint + 50, Ypoint);
          doc.text("คำนำหน้านาม-ชื่อ-ชื่อสกุล", Xpoint + 131, Ypoint);
          doc.text("ค่าจ้างที่จ่ายจริง", Xpoint + 204, Ypoint);
          doc.text("เงินสมทบผู้ประกันตน", Xpoint + 248, Ypoint);
          doc.text("รวม", Xpoint + 152.5, Ypoint + 95);
          doc.setFont(normalfont);
          doc.setFontSize(11);
          doc.text("(สำหรับคนต่างด้าวให้กรอกเลขที่บัตรประกันสังคม)", Xpoint + 39, Ypoint + 5);
          doc.text("(ค่าจ้างที่ใช้ในการคำนวณไม่ต่ำกว่า", Xpoint + 242.5, Ypoint + 5);
          doc.text("1,650 บาท และไม่เกิน 15,000 บาท)", Xpoint + 242.5, Ypoint + 9);
          doc.setFontSize(14);
          doc.setFont(boldfont);
          //doc.text("คำชี้แจง", Xpoint, Ypoint+110);
          //doc.line(Xpoint,Ypoint+111,Xpoint+11,Ypoint+111)
          doc.text("ลงชื่อ", Xpoint + 180, Ypoint + 110);
          doc.setFont(normalfont);
          doc.text(".................................................................................", Xpoint + 188, Ypoint + 110);
          doc.setFont(boldfont);
          doc.text("นายจ้าง/ผู้รับมอบอำนาจ", Xpoint + 253, Ypoint + 110);
          doc.setFont(normalfont);
          doc.text("ยื่นแบบวันที่", Xpoint + 180, Ypoint + 116);
          doc.text(".................", Xpoint + 196, Ypoint + 116);
          doc.text("เดือน", Xpoint + 210, Ypoint + 116);
          doc.text("........................................", Xpoint + 217, Ypoint + 116);
          doc.text("พ.ศ.", Xpoint + 250, Ypoint + 116);
          doc.text("....................................", Xpoint + 255, Ypoint + 116);
          var increment1 = 0;
          for (let j = 0; j < 10; j++) 
          {
            xposition = Xpoint + 35;
            yposition = Ypoint + increment1 + 12;
            doc.setLineWidth(0.1);
            recwidth = 4;
            doc.rect(xposition, yposition, recwidth, 4.5);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 16;
            doc.rect(xposition, yposition, recwidth, 4.5);
            doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
            doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
            doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
            doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
            doc.setLineDash([]);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 20;
            doc.rect(xposition, yposition, recwidth, 4.5);
            doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
            doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
            doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
            doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
            doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
            doc.setLineDash([]);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 8;
            doc.rect(xposition, yposition, recwidth, 4.5);
            doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
            doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
            doc.setLineDash([]);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 4;
            doc.rect(xposition, yposition, recwidth, 4.5);
            increment1 += 7.9;
          }
          doc.text(240, 12, (i).toString());
          doc.text(270, 12, (self.thesheetnum - 1).toString());
          let totalWages = 0.00;
          let totalContri = 0.00;
          let start = ((i - 1) * 10);
          let end = ((i) * 10);
          for (var t2 = start; t2 < end && t2 < self.ver2arr.length; t2++)
          {
            totalWages = parseFloat(self.ver2arr[t2].salaryamount) + totalWages;
            let temp = self.ver2arr[t2].socialSecurity;
            totalContri = temp + totalContri;
          }
          totalWages = parseFloat(totalWages).toFixed(2);
          totalContri = parseFloat(totalContri).toFixed(2);
          const widthWages = doc.getTextWidth(totalWages);
          const widthContri = doc.getTextWidth(totalContri);
          doc.text(this.numberWithCommas(totalWages), 234 - widthWages, Ypoint + 95);
          doc.text(this.numberWithCommas(totalContri), 287 - widthContri, Ypoint + 95);
          let date = self.ver2arr[0].payment_Date;
          let getpaymentMonth = moment(date).month() + 1;
          let getpaymentYear = moment(date).year();
          self.ver2_thaiYear = (getpaymentYear + 543).toString();
          self.thaiMonths.forEach((month) => {
            if (month.val === getpaymentMonth) 
            {
              self.ver2_thaiMonth = month.txt;
            }
          });
          doc.text(self.ver2_thaiMonth, Xpoint + 30, 12);
          doc.text(self.ver2_thaiYear, Xpoint + 65, 12);
          doc.text(self.ver2arr[0].companyName, Xpoint + 65, 26);

          let seqX = Xpoint + 12;
          let seqY = 55;
          for (var s2 = start; s2 < end && s2 < self.ver2arr.length; s2++) 
          {
            doc.text((s2+1).toString(), seqX, seqY);
            seqY = seqY + 8;
          }

          let nameX = 140;
          let nameY = 55;
          for (var v2 = start; v2 < end && v2 < self.ver2arr.length; v2++)
          {
            doc.text(self.ver2arr[v2].name + " " + self.ver2arr[v2].lastName, nameX, nameY);
            nameY = nameY + 8;
          }

          let wagesX = 236;
          let wagesY = 55;
          for (var w2 = start; w2 < end && w2 < self.ver2arr.length; w2++)
          {
            let temp = parseFloat(self.ver2arr[w2].salaryamount).toFixed(2);
            let empWage = this.numberWithCommas(temp);
            const widthSalary = doc.getTextWidth(empWage);
            doc.text(empWage, wagesX - widthSalary, wagesY);
            wagesY = wagesY + 8;
          }

          let ssfX = 287;
          let ssfY = 55;
          for (var f21 = start; f21< end && f21 < self.ver2arr.length; f21++)
          {
            let temp1 = self.ver2arr[f21].socialSecurity;
            temp1 = parseFloat(temp1).toFixed(2);
            let empSSF = this.numberWithCommas(temp1);
            const widthSSF = doc.getTextWidth(empSSF);
            doc.text(empSSF, ssfX - widthSSF, ssfY);
            ssfY = ssfY + 8;
          }

          //let theincomingarr = self.ver2arr;
          increment1 = 0;
          for (var j2 = start; j2 < end && j2 < self.ver2arr.length; j2++)
          {
            let personalcode = self.ver2arr[j2].personalID_Number.toString().split("");
            if (personalcode == "N/A" || personalcode == "" ) 
            {
              personalcode = "-";
            }
            xposition = Xpoint + 35;
            yposition = Ypoint + increment1 + 12;
            if(personalcode != "-"){personalcode = personalcode.map((v, i) => ({
              no: ++i,
              xposition: xposition,
              text: v,
            }));
            doc.setFont(normalfont);
            doc.setFontSize(13.5);
            increment = 0;
            
            personalcode.forEach((data) => {
              if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) 
              {
                doc.text(data.xposition + 1 + increment + 2.5,yposition + 3.2,data.text);
                increment += 6.5;
              } 
              else 
              {
                doc.text(data.xposition + 1 + increment,yposition + 3.2,data.text);
                increment += 4;
              }
            });}
            increment1 += 7.9;
          }

        }
        if (i < self.thesheetnum - 1) 
        {
          doc.addPage();
        }
      }
      return doc.save("Social-Security-Report2.pdf");
    },
    numberWithCommas(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    convertBahttext(number) {
      var num = +(Math.round(Math.abs(number) + "e+2") + "e-2");
      if (num > 9999999999999.99) return "#NUM!";
      if (!num) return "ศูนย์บาทถ้วน";
      var numArray = num.toFixed(2).split(".");
      var bahtText = numArray[0] == "0" ? "" : realConvert(numArray[0]) + "บาท";
      bahtText +=
        numArray[1] == "00" ? "ถ้วน" : realConvert(numArray[1]) + "สตางค์";

      function realConvert(item) {
        var digitArray = [
          "ศูนย์",
          "หนึ่ง",
          "สอง",
          "สาม",
          "สี่",
          "ห้า",
          "หก",
          "เจ็ด",
          "แปด",
          "เก้า",
          "สิบ"
        ];
        var placeValueArray = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน"];
        var numberText = "";
        item = String(+item);

        for (var i = 1; i <= item.length; i++) {
          var digit = item.charAt(i - 1);
          var place = (item.length - i) % 6;
          if (digit != 0)
            numberText +=
              digit == 1 && place == 0 && i != 1
                ? "เอ็ด"
                : digitArray[digit] + placeValueArray[place];
          if (place == 0 && i != item.length) numberText += "ล้าน";
        }

        return numberText
          .replace(/หนึ่งสิบ/g, "สิบ")
          .replace(/สองสิบ/g, "ยี่สิบ");
      }

      return number < 0 ? "ลบ" + bahtText : bahtText;
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
>>> ::-webkit-scrollbar-thumb {
  background: #f99d20;
  border-radius: 1px;
  width: 300px;
}
>>> ::-webkit-scrollbar {
  height: 8px;
}
>>> .today_years {
  width: 120px !important;
  border-radius: 4px !important;
  border: none !important;
  height: 34px !important;
}
>>> .mdi-checkbox-marked::before {
  color: #f99d20;
}
/* >>> .mx-input {
  height: 42px;
  border-radius: 5px;
  width: 150px;
  border: 1px solid #808080ab !important;
} */
>>> .selectbox {
  width: 130px;
  height: 34px!important;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
>>> .selectbox.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    padding-left: 10px;
}

.backGG {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}
.ddl {
  float: right;
}
.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}

#table-header, .table-cell {
  padding: 8px;
  text-align: left;
}

#table-header.align-right,.table-cell.align-right {
  text-align: right;
  padding-right: 4rem !important;
}

@media screen and (max-width: 600px) {
  #table-header, .table-cell {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  #table-header {
    text-align: left;
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .table-cell {
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  .table-cell.align-right {
    text-align: right;
  }
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}

.print-break-page {
  page-break-after: always;
}
.ggg {
  margin-left: 30px;
}
@media print {
  body {
    overflow: auto;
    height: auto;
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
}
@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }
  .ddl {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
  .ddl {
    float: right;
  }
}
@media (max-width: 860px) {
  .ggg {
    margin-left: 0px;
  }
}
@media (min-width: 960px) {
  .lefz {
    margin-left: 20px;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .ggg {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1900px) {
  /* .movelf {
    margin-right: 2rem;
  } */
}
@media (max-width: 328px) {
  .venti {
    padding-left: 15px;
  }
}
</style>