<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem;margin-bottom: 3rem;">
    <div style="background-color: #fff;">
      <v-row class="mt-3" style=" margin-left: 20px;">
        <v-btn @click="showApproval(1)" text large :style="styleApproval">
          <v-badge v-if="pendingList.length > 0" color="#f99d20" :content="pendingList.length" offset-x="0" offset-y="5">
            <p class="text-capitalize">{{ $t("PendingApproval") }}</p>
          </v-badge>
          <p v-else>{{ $t("PendingApproval") }}</p>
        </v-btn>
        <v-btn @click="showApproval(2)" text large :style="styleHistory">
          <p class="text-capitalize">{{ $t("HistoryMT") }}</p>
        </v-btn>
      </v-row>
      <br><br>
      <v-row v-if="itemInner == 'Waiting Approval'" style="font-size:14px;padding:0 1.5rem 0 0;">
        <v-col cols="3" lg="4" md="5" sm="5">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 60px; margin-top:30px">{{ $t("Year") }}</p>
            <p :style="thethaihead ? 'margin-left:120px;' : ''" style="color:#8A8C8E; margin-left: 100px; margin-top:30px">{{
              $t("Month") }}</p>
          </v-row>
          <div id="duo" class="mr-8 d-flex" style="
                      background-color: #f99d20;
                      border-radius: 5px;
                      margin-left: 50px;
                    "><date-picker v-model="todayyears" type="year" color="#0FA7D8" class="
                        selectboxs
                        minizebox
                        ml-1
                        mr-1
                        mt-1
                        mb-1
                        pl-0
                        today_years
                      " v-on:change="ChangeYear(todayyears)">
              <template slot="icon-calendar">
                <img src="@/assets/images/down1@2x.png" style="
                            width: 30px;
                            height: 15px;
                            margin-top: 4px;
                            margin-right: -6px;
                          " class="pl-2" />
              </template>
            </date-picker>
            <v-autocomplete id="monthbox" class="selectboxs minizebox ml-0 mt-1 mr-1 mb-1 pl-1" v-model="todaymonths"
              height="34px" single-line :items="monthNames" :item-text="(item) => item.txt"
              :item-value="(item) => item.val" background-color="#FFFFFF" color="#f99d20" return-object persistent-hint
              v-on:change="ChangeMonth(`${todaymonths}`)"></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="3" lg="2" md="1" sm="1"></v-col>
        <v-col cols="3" lg="3" md="3" sm="3">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 10px; margin-top:30px">{{ $t("employee") }}</p>
          </v-row>
          <v-autocomplete @change="empSelector()" v-model="selectedEmp" class="tile-1" :items="empList"
            :item-text="(item) => `${item.fullName}`" item-value="val" outlined dense color="#F99D20"
            placeholder="Select">
            <template v-slot:selection="data">
              <span>{{ data.item.fullName }}</span>
            </template>
            <template v-slot:item="data">
              <template class="empid">
                <v-list-item-avatar size="30">
                  <img style="width:30px;height:30px;" :src="data.item.image==null ? 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png' : data.item.image">
                </v-list-item-avatar>
                <v-list-item-content style="height:40px; margin-bottom:5px">
                  <v-list-item-title style="font-size:13px">{{ data.item.nickname == null ? data.item.fullName :
                    data.item.fullName + ' (' + data.item.nickname + ')' }}</v-list-item-title>
                  <p style="font-size:12px">{{ data.item.position_Name }}</p>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3" lg="3" md="3" sm="3">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 10px; margin-top:30px;">{{ $t("document_type") }}</p>
          </v-row>
          <v-autocomplete v-model="selectedDoc" :items="docType" :item-text="(item) => `${item.txt}`"
            :item-value="(item) => item.val" outlined dense color="#F99D20" placeholder="Select"
            @change="changeDoc"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="itemInner == 'All'" style="font-size:14px;padding:0 1.5rem 0 0;">
        <v-col cols="3" lg="4" md="5" sm="5">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 60px; margin-top:30px">{{ $t("Year") }}</p>
            <p :style="thethaihead ? 'margin-left:120px;' : ''" style="color:#8A8C8E; margin-left: 100px; margin-top:30px">
              {{ $t("Month") }}</p>
          </v-row>
          <div id="duo" class="mr-8 d-flex" style="
                      background-color: #f99d20;
                      border-radius: 5px;
                      margin-left: 50px;
                    "><date-picker v-model="todayyear1" type="year" color="#0FA7D8" class="
                        selectboxs
                        minizebox
                        ml-1
                        mr-1
                        mt-1
                        mb-1
                        pl-0
                        today_years
                      " v-on:change="ChangeYear(todayyear1)">
              <template slot="icon-calendar">
                <img src="@/assets/images/down1@2x.png" style="
                            width: 30px;
                            height: 15px;
                            margin-top: 4px;
                            margin-right: -6px;
                          " class="pl-2" />
              </template>
            </date-picker>
            <v-autocomplete id="monthbox" class="selectboxs minizebox ml-0 mt-1 mr-1 mb-1 pl-1" v-model="todayMonths"
              height="34px" single-line :items="monthNames" :item-text="(item) => item.txt"
              :item-value="(item) => item.val" background-color="#FFFFFF" color="#f99d20" return-object persistent-hint
              v-on:change="ChangeMonth(`${todayMonths}`)"></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="3" lg="2" md="1" sm="0"></v-col>
        <v-col cols="2" lg="2" md="3" sm="3">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 10px; margin-top:30px">{{ $t("employee") }}</p>
          </v-row>
          <v-autocomplete @change="empSelector()" v-model="SelectedEmp" class="tile-1" :items="empList"
            :item-text="(item) => `${item.fullName}`" item-value="val" outlined dense color="#F99D20"
            placeholder="Select">
            <template v-slot:selection="data">
              <span>{{ data.item.fullName }}</span>
            </template>
            <template v-slot:item="data">
              <template class="empid">
                <v-list-item-avatar size="30">
                  <img style="width:30px;height:30px;" :src="data.item.image==null ? 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png': data.item.image">
                </v-list-item-avatar>
                <v-list-item-content style="height:40px; margin-bottom:5px">
                  <v-list-item-title style="font-size:13px">{{ data.item.nickname == null ? data.item.fullName :
                    data.item.fullName + ' (' + data.item.nickname + ')' }}</v-list-item-title>
                  <p style="font-size:12px">{{ data.item.position_Name }}</p>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" lg="2" md="3" sm="3">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 10px; margin-top:30px;">{{ $t("document_type") }}</p>
          </v-row>
          <v-autocomplete v-model="SelectedDoc" :items="docType" :item-text="(item) => `${item.txt}`"
            @change="changeDoc()" :item-value="(item) => item.val" outlined dense color="#F99D20"
            placeholder="Select"></v-autocomplete>
        </v-col>
        <v-col cols="2" lg="2" md="3" sm="3">
          <v-row>
            <p style="color:#8A8C8E; margin-left: 10px; margin-top:30px">{{ $t("status") }}</p>
          </v-row>
          <v-autocomplete v-model="SelectedStatus" :items="statusType" :item-text="(item) => `${item.txt}`"
            @change="changeStatus()" :item-value="(item) => item.val" outlined dense color="#F99D20"
            placeholder="Select"></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="filterOrNot == false ? itemFilter : filterbydate" :page.sync="page"
         :items-per-page="itemsPerPage" @page-count="pageCount = $event"
        :hide-default-header="itemInner != 'All' ? true : false" hide-default-footer class="ml-8 mr-8 mt-2" v-model="selected"
        item-key="wF_ID" :mobile-breakpoint="0">
        <template v-if="itemInner != 'All'" v-slot:header="{ props: { } }">
          <thead>
            <tr style="height: 60px;">
              <th style="width:20px">
                <v-checkbox class="custom-checkbox mb-3 pl-3" v-model="allCheck" color="#f99d20" hide-details @change="checkall(allCheck)"></v-checkbox>
              </th>
              <th style="text-align: center">{{ $t("DescriptionOT") }}</th>
              <th style="text-align: center">{{ $t("status") }}</th>
              <th style="text-align: center">{{ itemInner == "Waiting Approval" ? $t("action") : $t("Supervisor") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:[`item.checkboxs`]="{ item }" style="justify-item:right;">
          <v-checkbox class="custom-checkbox mb-3 pl-3" v-model="item.isCheck" color="#f99d20" hide-details @change="multiSelect(item)"></v-checkbox>
        </template>
        <template v-slot:[`item.Details`]="{ item }">
          <v-row class="fitWidth" style="padding:1rem" @dblclick="openViewdialog(item)">
            <v-col cols="2" class="d-flex align-center justify-center">
              <v-avatar size="40"><v-img :src="item.req_Image" style="width:40px;height:40px;"></v-img></v-avatar>
            </v-col>
            <v-col cols="10" style="text-align:left; line-height:1.2; overflow-wrap: break-word; word-wrap: break-word; hyphens: auto;">
              <p style="color: #444444; font-size: 16px; margin: 0;">
                {{ item.requestername }} {{ item.nickname == null ? '' : '(' + item.nickname + ')' }} {{ $t("HasRequestedFor") }} {{ item.workFlow_Name == "Time Off" ? '"Time Off"' : '"Over Time"' }}
              </p>
              <p style="color:#817F7F; font-size: 13px; margin: 0;">
                {{ $t("FromMT") }} {{ convertUTCDateToLocalDate(item.start_Date, 1) }}
                {{ $t("To") }}
                {{ convertUTCDateToLocalDate(item.end_Date, 1) }}
              </p>
              <p style="color:#817F7F; font-size: 13px; margin: 0;">
                {{ $t("requestedDate") }} : {{ convertUTCDateToLocalDate(item.requested_Date, 0) }}
              </p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-col class="flex" @dblclick="openViewdialog(item)">          
            <div class="d-flex" :style="{
              backgroundColor:
                item.status == 'Approved' || item.status == 'Manual'
                  ? '#CCF3E9'
                  : item.status == 'Waiting Approval'
                    ? '#DAE3F3'
                    : item.status == 'Draft Request'
                      ? '#D0CECE'
                      : item.status == 'Denied'
                        ? '#7F7F7F'
                        : item.status == 'Canceled'
                          ? '#FFCCCC'
                          : '#CCF3E9',

              borderRadius: '20px',
              padding: '5px',
              width: '150px',
              height: '35px',
              alignItems: 'center',
              margin: 'auto',
              cursor: 'pointer',
              justifyContent: 'center'
            }" @dblclick="openViewdialog(item)">
              <p class="d-flex pt-4" :style="{
                color:
                  item.status == 'Approved' || item.status == 'Manual'
                    ? '#00C092'
                    : item.status == 'Waiting Approval'
                      ? '#5B9BD5'
                      : item.status == 'Draft Request'
                        ? '#595959'
                        : item.status == 'Canceled'
                          ? '#FF0000'
                          : item.status == 'Denied'
                            ? '#FFFFFF'
                            : '#00C092',
              }">
                {{
                  item.status == "Approved"
                  ? $t("approved")
                  : item.status == "Denied"
                    ? $t("denied")
                    : item.status == "Canceled"
                      ? $t("Canceled")
                      : item.status == "Manual"
                        ? $t("approved")
                        : item.status == "Waiting Approval"
                          ? $t("waitingApproval")
                          : $t("SaveDraft")
                }}
              </p>
            </div>
          </v-col>
        </template>
        <template id="action" style="width:500px" v-slot:[`item.actions`]="{ item }">
          <v-col class="flex" :style="$vuetify.breakpoint.lgAndUp ? 'display:flex !important' : ''"
            v-if="itemInner == 'Waiting Approval'">
            <v-btn :disabled="`${item.status}` == 'Waiting Approval' ? false : true" color="#F99D20"
              class="white--text text-capitalize" max-height="30px" width="100px" @click="approveConfirm(item)">
              <img src="@/assets/images/Icon awesome-check.png" /><v-spacer></v-spacer><span>{{ $t("approve") }}</span>
            </v-btn>
            &nbsp;
            <v-btn :disabled="`${item.status}` == 'Waiting Approval' ? false : true" color="#FF4B55"
              class="ml-1 white--text text-capitalize" max-height="30px" width="100px" @click="denyConfirm(item)">
              <img src="@/assets/images/Icon metro-cross-white.png" />
              <v-spacer></v-spacer>{{ $t("Reject") }}
            </v-btn>
            &nbsp;
            <v-btn :disabled="`${item.status}` == 'Waiting Approval' ? false : true" color="#0FA7D8"
              class="ml-1 white--text text-capitalize" max-height="30px" width="100px" style="font-weight: normal"
              @click="allowDelegate(item)">
              <img src="@/assets/images/Icon metro-user-check.png" /><v-spacer></v-spacer>{{ $t("delegate") }}
            </v-btn>
          </v-col>
          <v-col v-if="itemInner == 'All'" style="line-height:0.8;">
            <p style="color:#444444; font-size:16px; ">{{ item.approvername }}</p>
            <p style="color:#817F7F; font-size:13px;">{{ convertUTCDateToLocalDate(item.updated_Date, 0) }}</p>
          </v-col>
        </template>
        <template v-slot:[`item.cancelactions`]="{ item }">
          <v-btn :disabled="`${item.status}` != 'Approved' ? true : false" color="#FF0000"
            class="white--text text-capitalize" max-height="30px" width="100px" @click="Getcancelid(item)">
            {{ $t("Cancel") }}
          </v-btn>
          &nbsp;
        </template>
      </v-data-table>
      <div class="text-right" v-if="showPagination">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination v-model="page" :length="pageCount" color="#F99D20"></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage" :items="items"
              @input="itemsPerPage = parseInt($event, 10)"></v-select>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- Approve Dialog -->
    <v-dialog v-model="approveDialog" max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-text class="text-center justify-center">
          <br />
          <p class="mt-3" style="font-weight:bold; font-size: 23px; color: #444444">
            {{ $t("confirmToApprove") }}
          </p>
          <p class="mt-5 ">{{ $t("wantToApprove") }}</p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined text width="159px" class="text-capitalize mb-5" @click="approveDialog = false">
            {{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="text-capitalize white--text mb-5 mr-5" width="159px" @click="approveMyTasks">{{
            $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Approve All Dialog -->
    <v-dialog v-model="approveAllDialog" max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-text class="text-center justify-center">
          <br />
          <p class="mt-3" style="font-weight:bold; font-size: 23px; color: #444444">
            {{ $t("confirmToApprove") }}
          </p>
          <p class="mt-5 text-center">
            {{ $t("ApproveThisRequest") }}
          </p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text width="159px" class="text-capitalize mb-5" @click="approveAllDialog = false">
            {{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="text-capitalize white--text mb-5" width="159px" @click="approveAllMyTasks">
            {{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Deny Dialog -->
    <v-dialog v-model="denyDialog" width="532">
      <v-card style="border-radius: 15px">
        <v-card-text class="text-center">
          <br />
          <p class="mt-3" style="font-weight:bold; font-size: 23px; color: #444444">
            {{ $t("ConfirmToReject") }}
          </p>
          <p class="mt-5 ">{{ $t("WantToReject") }}</p>
          <v-card-actions class="justify-center">
            <v-btn outlined text width="159px" class="text-capitalize" @click="denyDialog = false">{{ $t("Cancel")
            }}</v-btn>
            <v-btn color="#F99D20" class="text-capitalize white--text" width="159px" @click="denyMyTasks">{{ $t("confirm")
            }}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Deny All Dialog -->
    <v-dialog v-model="denyAllDialog" width="532">
      <v-card style="border-radius: 15px">
        <v-card-text class="text-center">
          <br />
          <p class="justify-center mt-3" style="font-size: 23px; color: #444444">
            {{ $t("confirmToDeny") }}
          </p>
          <p class="mt-5 text-center">
            {{ $t("DenyThisRequest") }}
          </p>
          <v-card-actions class="mt-3 justify-center">

            <v-btn text width="159px" class="text-capitalize" @click="denyAllDialog = false">
              {{ $t("Cancel") }}</v-btn>
            <v-btn color="#F99D20" class="text-capitalize white--text" width="159px" @click="denyAllMyTasks">
              {{ $t("confirm") }}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delegateDialog" max-width="600" max-height="none">
      <v-card style="border-radius:20px">

        <v-card-title>
          <p>{{ $t("DelegateTaskTo") }}</p>
          <v-spacer></v-spacer>
          <v-btn @click="delegateDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-row style="padding-left:1.5rem; padding-right:1.5rem;">
            <v-col cols="5"> <v-text-field class="readonly" v-model="delegateApprover" readonly outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="2" style="text-align:center">
              <p style="padding-top:5px">{{ $t("To") }} </p>
            </v-col>
            <v-col cols="5"><v-autocomplete v-model="delegateID" @change="selectDelegate" class="tile-1"
                :items="TempDelegateToList" :item-text="(item) => `${item.fullName}`" item-value="employee_ID" outlined
                dense color="#F99D20" placeholder="Select">
                <template v-slot:selection="data">

                  <span style="font-size:17px">{{ data.item.fullName }}</span>

                </template>
                <template v-slot:item="data">
                  <template class="empid">
                    <v-list-item-avatar size="40px">
                      <img :src="data.item.image">
                    </v-list-item-avatar>
                    <v-list-item-content style="height:40px; margin-bottom:5px">
                      <v-list-item-title style="font-size:13px">{{ data.item.nickname == null ? data.item.fullName :
                        data.item.fullName + ' (' + data.item.nickname + ')' }}</v-list-item-title>
                      <p style="font-size:12px">{{ data.item.position_Name }}</p>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

          </v-row>
        </v-card-title>
        <br>
        <hr style="color:#A3A7AA" />

        <v-card-actions class="justify-center mt-4 pb-5">
          <v-btn class="text-capitalize" align="center" width="120" max-height="35" @click="delegateDialog = false">
            {{ $t("cancel") }}</v-btn>
          <v-btn align="center" color="#F99D20" class="text-capitalize white--text" width="120" max-height="35"
            :disabled="delegateConfirm" @click="completeDelegate">
            {{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showOT" persistent max-width="1100px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span> {{ $t("OTRequests") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="closeNoti()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-4">
          <hr />
          <v-row class="mt-3">
            <!-- Requested Date -->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("RequestDate") }}:
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="showreqDate" height="10px" single-line outlined dense readonly
                    class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Status -->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("status") }} : </v-col>

                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-row class="mt-1">
                    <div :style="{
                      maxWidth: '150px',
                      height: '40px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      textAlign: 'center',

                      color:
                      OTDetail.status == 'Approved' || OTDetail.status == 'Manual'
                          ? '#00C092'
                          : OTDetail.status == 'Waiting Approval'
                            ? '#5B9BD5'
                            : OTDetail.status == 'Draft Request'
                              ? '#595959'
                              : OTDetail.status == 'Denied'
                                ? '#FFFFFF'
                                : OTDetail.status == 'Canceled'
                                  ? '#FF0000'
                                  : '#5B9BD5',
                      backgroundColor:
                      OTDetail.status == 'Approved' || OTDetail.status == 'Manual'
                          ? '#CCF3E9'
                          : OTDetail.status == 'Waiting Approval'
                            ? '#DAE3F3'
                            : OTDetail.status == 'Draft Request'
                              ? '#D0CECE'
                              : OTDetail.status == 'Denied'
                                ? '#7F7F7F'
                                : OTDetail.status == 'Canceled'
                                  ? '#FFCCCC'
                                  : '#DAE3F3',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                    }">
                      {{
                        OTDetail.status == "Approved" || OTDetail.status == "Manual"
                        ? $t("approved")
                        : OTDetail.status == "Denied"
                          ? $t("denied")
                          : OTDetail.status == "Draft Request"
                            ? $t("Draftreq")
                            : OTDetail.status == "Canceled"
                              ? $t("Canceled")
                              : $t("waitingApproval")
                      }}
                    </div>
                   
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("EmployeeName") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="OTDetail.empname" height="10px" single-line outlined readonly
                    dense class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("position") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="OTDetail.position" height="10px" single-line outlined dense readonly
                    class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("date") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y fluid max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="showstartDate" disabled single-line autocomplete="off" dense outlined
                        readonly color="#F99D20" persistent-hint v-on="on" class="readonly"></v-text-field>
                    </template>

                  </v-menu>
                </v-col>
       
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ ("OTHours") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu6" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="OTDetail.OThours" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
               
                
              </v-row>
            </v-col>
          </v-row>
          
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("StartTime") }} :
                </v-col>
                <v-col  cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="OTDetail.OTstart" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
             
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("EndTime") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu6" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="OTDetail.OTend" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
               
                
              </v-row>
            </v-col>
          </v-row>

          
       
  

          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
              <v-row>
                <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                  {{ $t("DescriptionOT") }}: </v-col>

                <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                  <div :style="{
                    width: $vuetify.breakpoint.lg
                      ? '1010px'
                      : $vuetify.breakpoint.xl
                        ? '910px'
                        : $vuetify.breakpoint.md
                          ? '800px'
                          : '',
                  }">
                    <v-textarea  disabled v-model="OTDetail.purposeOT" solo
                      no-resize color="#F99D20" rows="3" min-height="80" name="input-7-4" class="mb-4 readonly"
                      single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                    
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("AttachmentOT") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <div v-if="OTDetail.attachmentlist==null" class="mr-0 pa-3 relative" style="
              width: 130px;height:120px;
              border: 1px solid #ccc;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 7px;
            ">
                    <v-img class="mb-3 mt-2" src="@/assets/images/none.png" width="60px"></v-img>
                    <div class="text-center">None</div>
                  </div>
                  <div v-else-if="OTDetail.attachmentlist != null" class="container">
                    <div v-for="file in OTDetail.attachmentlist" :key="file.filename" class="mr-0 pa-3 relative"
                      style="width: 130px;height:120px; border: 1px solid #ccc; border-radius:7px;">

                      <div @click="showiframeforDetail(file)"
                        style="margin-top:5px;">
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'png'" src="@/assets/images/png.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpeg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'pdf'" src="@/assets/images/pdf.png"
                          width="50px"></v-img>
                        <div class="text-center">{{ file.filename ? file.filename.substring(0, 10) +
                          " ..." : '' }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" xl="6"></v-col>
          </v-row>

          <v-row class="mt-10">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("approver") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="OTDetail.approver" readonly height="10px" color="#F99D20"
                    single-line outlined dense required class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
           
          </v-row>
          <v-row v-if="OTDetail.status == 'Canceled'" class="mt-10">
            <hr>
            <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
              <v-row>
                <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                  <span style="font-weight:bold;">{{ $t("ActionNote") }}</span><span class="red--text">*</span></v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                  <div
                    :style="{ width: $vuetify.breakpoint.lg ? '1010px' : $vuetify.breakpoint.xl ? '910px' : $vuetify.breakpoint.md ? '800px' : '' }">
                    <v-textarea solo disabled v-model="OTDetail.reason" color="#F99D20" no-resize rows="3" min-height="80"
                      name="input-7-4" class="mb-4 readonly" single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

     <!-- New-Timeoff-Req dialog -->
     <v-dialog v-model="TimeOffDialog" persistent max-width="1100px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>{{ $t("TimeOffRequest") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="closeNoti()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-4">
          <hr />
          <v-row class="mt-3">
            <!-- Requested Date -->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("RequestDate") }}:
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="showreqDate" height="10px" single-line outlined dense readonly
                    class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Status -->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("status") }} : </v-col>

                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-row class="mt-1">
                    <div :style="{
                      maxWidth: '150px',
                      height: '40px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      textAlign: 'center',

                      color:
                      TimeOffDetail.status == 'Approved' || TimeOffDetail.status == 'Manual'
                          ? '#00C092'
                          : TimeOffDetail.status == 'Waiting Approval'
                            ? '#5B9BD5'
                            : TimeOffDetail.status == 'Draft Request'
                              ? '#595959'
                              : TimeOffDetail.status == 'Denied'
                                ? '#FFFFFF'
                                : TimeOffDetail.status == 'Canceled'
                                  ? '#FF0000'
                                  : '#5B9BD5',
                      backgroundColor:
                      TimeOffDetail.status == 'Approved' || TimeOffDetail.status == 'Manual'
                          ? '#CCF3E9'
                          : TimeOffDetail.status == 'Waiting Approval'
                            ? '#DAE3F3'
                            : TimeOffDetail.status == 'Draft Request'
                              ? '#D0CECE'
                              : TimeOffDetail.status == 'Denied'
                                ? '#7F7F7F'
                                : TimeOffDetail.status == 'Canceled'
                                  ? '#FFCCCC'
                                  : '#DAE3F3',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                    }">
                      {{
                        TimeOffDetail.status == "Approved" || TimeOffDetail.status == "Manual"
                        ? $t("approved")
                        : TimeOffDetail.status == "Denied"
                          ? $t("denied")
                          : TimeOffDetail.status == "Draft Request"
                            ? $t("Draftreq")
                            : TimeOffDetail.status == "Canceled"
                              ? $t("Canceled")
                              : $t("waitingApproval")
                      }}
                    </div>
                   
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("EmployeeName") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="TimeOffDetail.empname" height="10px" single-line outlined readonly
                    dense class="readonly"></v-text-field>
                </v-col>
              </v-row>
              
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("position") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="TimeOffDetail.position" height="10px" single-line outlined readonly
                    dense class="readonly"></v-text-field>
                </v-col>
              </v-row>
              
            </v-col>
 
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("date") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y fluid max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="showstartDate" disabled single-line autocomplete="off" dense outlined
                        readonly color="#F99D20" persistent-hint v-on="on" class="readonly"></v-text-field>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ TimeOffDetail.wholeDay == true ? $t("Allday") : $t("halfDay") }} :
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-checkbox readonly style="margin-top:5px" v-model="TimeOffDetail.wholeDay" :value="TimeOffDetail.wholeDay" color="#F99D20"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("start_date") }} :
                </v-col>
                <v-col  cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="showstartDate" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
             
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("enddate") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu6" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="showendDate" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
               
                
              </v-row>
            </v-col>
          </v-row>

          
       
  

          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
              <v-row>
                <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                  {{ $t("DescriptionOT") }}: </v-col>

                <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                  <div :style="{
                    width: $vuetify.breakpoint.lg
                      ? '1010px'
                      : $vuetify.breakpoint.xl
                        ? '910px'
                        : $vuetify.breakpoint.md
                          ? '800px'
                          : '',
                  }">
                    <v-textarea  disabled v-model="TimeOffDetail.message" solo
                      no-resize color="#F99D20" rows="3" min-height="80" name="input-7-4" class="mb-4 readonly"
                      single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                    
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("AttachmentOT") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <div v-if="TimeOffDetail.attachmentlist == null" class="mr-0 pa-3 relative" style="
              width: 130px;height:120px;
              border: 1px solid #ccc;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 7px;
            ">
                    <v-img class="mb-3 mt-2" src="@/assets/images/none.png" width="60px"></v-img>
                    <div class="text-center">None</div>
                  </div>
                  <div v-else-if="TimeOffDetail.attachmentlist != null" class="container">
                    <div v-for="file in TimeOffDetail.attachmentlist" :key="file.filename" class="mr-0 pa-3 relative"
                      style="width: 130px;height:120px; border: 1px solid #ccc; border-radius:7px;">

                      <div @click="showiframeforDetail(file)"
                        style="margin-top:5px;">
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'png'" src="@/assets/images/png.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpeg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'pdf'" src="@/assets/images/pdf.png"
                          width="50px"></v-img>
                        <div class="text-center">{{ file.filename ? file.filename.substring(0, 10) +
                          " ..." : '' }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" xl="6"></v-col>
          </v-row>

          <v-row class="mt-10">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("approver") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="TimeOffDetail.approver_Name" readonly height="10px" color="#F99D20"
                    single-line outlined dense required class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
           
          </v-row>
          
          <v-row v-if="TimeOffDetail.status == 'Canceled'" class="mt-10">
            <hr>
            <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
              <v-row>
                <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                  <span style="font-weight:bold;">{{ $t("ActionNote") }}</span><span class="red--text">*</span></v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                  <div
                    :style="{ width: $vuetify.breakpoint.lg ? '1010px' : $vuetify.breakpoint.xl ? '910px' : $vuetify.breakpoint.md ? '800px' : '' }">
                    <v-textarea solo disabled v-model="TimeOffDetail.reason" color="#F99D20" no-resize rows="3" min-height="80"
                      name="input-7-4" class="mb-4 readonly" single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Cancel dialog -->
    <v-dialog v-model="canceldialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: -30px" class="ml-2">
            {{ $t("CancelApproval") }}
          </p>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container class="mt-2">
            <v-form lazy-validation>
              <v-row>
                <v-col cols="12">
                  <span class="red--text"> {{ $t("Fillremark") }}*</span>
                  <v-text-field v-model="txtreason" outlined autocomplete="off" color="#F89D1F" required
                    class="mt-2"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="canceldialog = false" text>{{
            $t("Cancel") }}</v-btn>

          <v-btn color="#F99D20" class="mr-2 text-capitalize" width="120" max-height="35" @click="cancelmyapprove()">
            {{ $t("Confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!---- View Detail Dialog -->
    <v-dialog v-model="viewDialog" persistent max-width="1100px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span> {{ viewItem.workFlow_Name == "OT" ? $t("Overtime") : $t("leaverequestdetail") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="hidedetaildialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-4">
          <hr />
          <v-row class="mt-3">
            <!-- Requested Date -->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("RequestDate") }}:
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="showreqDate" height="10px" single-line outlined dense readonly
                    class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Status -->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("status") }} : </v-col>

                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-row class="mt-1">
                    <div :style="{
                      maxWidth: '150px',
                      height: '40px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      textAlign: 'center',

                      color:
                        viewItem.status == 'Approved' || viewItem.status == 'Manual'
                          ? '#00C092'
                          : viewItem.status == 'Waiting Approval'
                            ? '#5B9BD5'
                            : viewItem.status == 'Draft Request'
                              ? '#595959'
                              : viewItem.status == 'Denied'
                                ? '#FFFFFF'
                                : viewItem.status == 'Canceled'
                                  ? '#FF0000'
                                  : '#5B9BD5',
                      backgroundColor:
                        viewItem.status == 'Approved' || viewItem.status == 'Manual'
                          ? '#CCF3E9'
                          : viewItem.status == 'Waiting Approval'
                            ? '#DAE3F3'
                            : viewItem.status == 'Draft Request'
                              ? '#D0CECE'
                              : viewItem.status == 'Denied'
                                ? '#7F7F7F'
                                : viewItem.status == 'Canceled'
                                  ? '#FFCCCC'
                                  : '#DAE3F3',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                    }">
                      {{
                        viewItem.status == "Approved" || viewItem.status == "Manual"
                        ? $t("approved")
                        : viewItem.status == "Denied"
                          ? $t("denied")
                          : viewItem.status == "Draft Request"
                            ? $t("Draftreq")
                            : viewItem.status == "Canceled"
                              ? $t("Canceled")
                              : $t("waitingApproval")
                      }}
                    </div>
                    <div v-if="viewItem.status == 'Approved'" class="cancel pt-2" style="padding-left:10px; cursor:pointer"
                      @click="noteOrNot = true">
                      <p style="text-decoration:underline; font-size:18px">Cancel</p>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("EmployeeName") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="viewItem.requestername" height="10px" single-line outlined readonly
                    dense class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("position") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="viewItem.req_Position" height="10px" single-line outlined dense readonly
                    class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ viewItem.workFlow_Name == 'Time Off' ? $t("LeaveType") : $t("date") }} : </v-col>
                <v-col v-if="viewItem.workFlow_Name != 'Time Off'" cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y fluid max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="showstartDate" disabled single-line autocomplete="off" dense outlined
                        readonly color="#F99D20" persistent-hint v-on="on" class="readonly"></v-text-field>
                    </template>

                  </v-menu>
                </v-col>
                <v-col v-if="viewItem.workFlow_Name == 'Time Off'" cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y fluid max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="viewItem.timeoff_Name" disabled single-line autocomplete="off" dense outlined
                        readonly color="#F99D20" persistent-hint v-on="on" class="readonly"></v-text-field>
                    </template>

                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="viewItem.workFlow_Name == 'Time Off'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ viewItem.wholeDay == true ? $t("Allday") : $t("halfDay") }} :
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-checkbox readonly style="margin-top:5px" v-model="viewItem.wholeDay" :value="viewItem.wholeDay" color="warning"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ viewItem.wholeDay == true && viewItem.workFlow_Name == 'Time Off' ? $t("start_date") : $t("StartTime") }} :
                </v-col>
                <v-col v-if="viewItem.workFlow_Name != 'Time Off'" cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="viewItem.oT_Start_Time" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="viewItem.workFlow_Name == 'Time Off' && viewItem.wholeDay == true" cols="12" xs="9" sm="9" md="9"
                  lg="9" xl="9" class="pt-0">
                  <v-menu ref="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="showstartDate" single-line outlined dense color="#F99D20" readonly
                        v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col v-else-if="viewItem.workFlow_Name == 'Time Off' && viewItem.wholeDay != true" cols="12" xs="9" sm="9"
                  md="9" lg="9" xl="9" class="pt-0">
                  <v-menu ref="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="viewItem.start_Time" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ viewItem.wholeDay == true && viewItem.workFlow_Name == 'Time Off' ? $t("enddate") : $t("EndTime") }} : </v-col>
                <v-col v-if="viewItem.workFlow_Name != 'Time Off'" cols="12" xs="9" sm="9" md="9" lg="9" xl="9"
                  class="pt-0">
                  <v-menu ref="menu6" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="viewItem.oT_End_Time" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col v-if="viewItem.workFlow_Name == 'Time Off' && viewItem.wholeDay == true" cols="12" xs="9" sm="9" md="9"
                  lg="9" xl="9" class="pt-0">
                  <v-menu ref="menu6" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="showendDate" single-line outlined dense color="#F99D20" readonly
                        v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col v-else-if="viewItem.workFlow_Name == 'Time Off' && viewItem.wholeDay != true" cols="12" xs="9" sm="9"
                  md="9" lg="9" xl="9" class="pt-0">
                  <v-menu ref="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field disabled v-model="viewItem.end_Time" single-line outlined dense color="#F99D20"
                        readonly v-on="on" class="readonly"></v-text-field>
                    </template>
                    <v-time-picker no-title fluid></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
              <v-row>
                <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                  {{ $t("DescriptionOT") }}: </v-col>

                <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                  <div :style="{
                    width: $vuetify.breakpoint.lg
                      ? '1010px'
                      : $vuetify.breakpoint.xl
                        ? '910px'
                        : $vuetify.breakpoint.md
                          ? '800px'
                          : '',
                  }">
                    <v-textarea v-if="viewItem.workFlow_Name != 'Time Off'" disabled v-model="viewItem.purpose_OT" solo
                      no-resize color="#F99D20" rows="3" min-height="80" name="input-7-4" class="mb-4 readonly"
                      single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                    <v-textarea v-if="viewItem.workFlow_Name == 'Time Off'" disabled v-model="viewItem.message" solo
                      no-resize color="#F99D20" rows="3" min-height="80" name="input-7-4" class="mb-4 readonly"
                      single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("AttachmentOT") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <div v-if="viewItem.req_Attachmentlist == null" class="mr-0 pa-3 relative" style="
              width: 130px;height:120px;
              border: 1px solid #ccc;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 7px;
            ">
                    <v-img class="mb-3 mt-2" src="@/assets/images/none.png" width="60px"></v-img>
                    <div class="text-center">None</div>
                  </div>
                  <div v-else-if="viewItem.req_Attachmentlist != null" class="container">
                    <div v-for="file in viewItem.req_Attachmentlist" :key="file.filename" class="mr-0 pa-3 relative"
                      style="width: 130px;height:120px; border: 1px solid #ccc; border-radius:7px;">

                      <div @click="showiframeforDetail(file)"
                        style="margin-top:5px;">
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'png'" src="@/assets/images/png.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpeg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'pdf'" src="@/assets/images/pdf.png"
                          width="50px"></v-img>
                        <div class="text-center">{{ file.filename ? file.filename.substring(0, 10) +
                          " ..." : '' }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" xl="6"></v-col>
          </v-row>

          <v-row class="mt-10">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("approver") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field disabled v-model="viewItem.approvername" readonly height="10px" color="#F99D20"
                    single-line outlined dense required class="readonly"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="itemInner == 'All'" cols="12" xs="12" sm="12" md="6" xl="6">
              <p>{{ showaprDate }}</p>
            </v-col>
          </v-row>

          <v-row v-if="viewItem.status == 'Canceled'" class="mt-10">
            <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
              <v-row>
                <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                  <span style="font-weight:bold;">{{ $t("ActionNote") }}</span><span class="red--text">*</span></v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                  <div
                    :style="{ width: $vuetify.breakpoint.lg ? '1010px' : $vuetify.breakpoint.xl ? '910px' : $vuetify.breakpoint.md ? '800px' : '' }">
                    <v-textarea solo disabled v-model="viewItem.reason" color="#F99D20" no-resize rows="3" min-height="80"
                      name="input-7-4" class="mb-4 readonly" single-line outlined dense :style="$vuetify.breakpoint.lg
                          ? 'caret-color: orange !important;margin-left:6rem;'
                          : 'caret-color: orange !important'
                        ">
                    </v-textarea>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div v-if="noteOrNot == true">
            <hr style="color:#8A8C8E" />
            <v-row class="mt-10">
              <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
                <v-row>
                  <v-col class="des d-flex" cols="12" xs="3" sm="3" md="3" lg="1" xl="2">
                    <span style="font-weight:bold;">{{ $t("ActionNote") }}</span><span class="red--text">*</span> </v-col>
                  <v-col cols="12" xs="9" sm="9" md="9" lg="11" xl="10" class="pt-0 des2">
                    <div
                      :style="{ width: $vuetify.breakpoint.lg ? '1010px' : $vuetify.breakpoint.xl ? '910px' : $vuetify.breakpoint.md ? '800px' : '' }">
                      <v-textarea solo v-model="cancelReason" :placeholder="actionNote" no-resize rows="3" min-height="80"
                        name="input-7-4" class="mb-4" color="#F99D20" single-line outlined dense :style="$vuetify.breakpoint.lg
                            ? 'caret-color: orange !important;margin-left:6rem;'
                            : 'caret-color: orange !important'
                          ">
                      </v-textarea>
                    </div>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="cancelNote()" style="text-transform:none" text width="98px">{{ $t("cancel") }}</v-btn>
                  <v-btn @click="cancelRequest()" width="98px" class="white--text" color="#F99D20"
                    style="margin-left:50px; text-transform:none">{{ $t("Confirm") }}</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <template>
      <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
        style="overflow: hidden">
        <v-card style="padding-left:1.5rem; padding-right:1.5rem; padding-bottom:1.5rem;">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

            <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
          </v-card-actions>

          <v-img :src="imgForshow" max-width="500"></v-img>

        </v-card>
      </v-dialog>

      <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
        <v-card style="background-color: white">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn @click="
              printdialogforupdate = false;
            iframeShow = false;
            " icon style="margin-right: 3rem; margin-top: 1rem">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-row>

          <v-card class="mt-4 pdf-container">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-footer v-if="selectAll == true && itemInner == 'Waiting Approval' && selected.length > 0"
        style="background-color:#FFFFFF" elevation="20" height="100px" fixed>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="#F99D20" class="mr-10 white--text text-capitalize" max-height="30px" width="100px"
            @click="approveAllShow()">
            <img src="@/assets/images/Icon awesome-check.png" />{{ $t("approve") }}
          </v-btn>
          <v-btn color="#FF4B55" class="white--text text-capitalize" style="margin-right:80px" max-height="30px"
            width="100px" @click="denyAllShow()">
            <img src="@/assets/images/Icon metro-cross-white.png" />
            <v-spacer></v-spacer>{{ $t("Reject") }}
          </v-btn>
        </v-row>
      </v-footer></template>
    <template>
      <v-footer v-if="multiCheck == true && itemInner == 'Waiting Approval' && selected.length > 0"
        style="background-color:#FFFFFF" elevation="20" height="100px" fixed>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="#F99D20" class="mr-10 white--text text-capitalize" max-height="30px" width="100px"
            @click="approveAllShow()">
            <img src="@/assets/images/Icon awesome-check.png" />{{ $t("approve") }}
          </v-btn>
          <v-btn color="#FF4B55" class="white--text text-capitalize" style="margin-right:80px" max-height="30px"
            width="100px" @click="denyAllShow()">
            <img src="@/assets/images/Icon metro-cross-white.png" />
            <v-spacer></v-spacer>{{ $t("Reject") }}
          </v-btn>
        </v-row>
      </v-footer></template>

  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import CheckViewApproveDeny from "@/function/RolePermissions.js";

export default {
  mixins: [CheckViewApproveDeny],
  components: { DatePicker },
  data() {
    return {
      permission: {
        approve: true,
        deny: true,
      },
      delegateApprover: null,
      dialogforDownload: false,
      printdialogforupdate: false,
      iframeShow: false,
      dllink: null,
      imgForshow: "",
      pdfString: "",
      filteredItem: [],
      filterOrNot: false,
      filterYears: null,
      filterYear1: null,
      filterMonth: null,
      noteOrNot: false,
      showendDate: null,
      showstartDate: null,
      showreqDate: null,
      showaprDate: null,
      viewDialog: false,
      viewItem: [],
      todaymonths: { val: 0, txt: this.$t("All") },
      todayMonths: { val: 0, txt: this.$t("All") },
      selectedDoc: null,
      SelectedDoc: null,
      selectedEmp: null,
      SelectedEmp: null,
      SelectedStatus: "All",
      multiCheck: false,
      multiSelected: [],
      wholeSelected: [],
      empList: [],
      pendingList: [],
      todayyears: new Date().getFullYear(),
      todayyear1: new Date().getFullYear(),
      styleApproval: "",
      styleHistory: "",
      thelang: "",
      oneCheck: false,
      allCheck: false,
      cancelReason: "",
      chosenstatus: "",
      itemInner: "Waiting Approval",
      isAppoved: true,
      CancelID: 0,
      selected: [],
      DelegateToList: [],
      TempDelegateToList: [],
      delegateTo: "",
      selectAll: false,
      txtreason: "",
      canceldialog: false,
      approveAllDialog: false,
      denyAllDialog: false,
      delegateConfirm: true,
      TimeOffDialog: false,
      approveDialog: false,
      denyDialog: false,
      delegateDialog: false,
      showOT: false,

      requesterID: null,
      approvername: null,
      workFlow_Name: null,
      workFlow_ID: null,

      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      time1: null,
      time2: null,
      time3: null,
      time4: null,
      OtandTimeoff: [],
      url: enurl.apiUrl,
      page: 1,
      delegate: [],
      itemsPerPage: 10,
      pageCount: 15,
      items: [10, 20, 30],
      newList: [],
      delegateName: "",
      requestDelegate: [],
      OTDetail: {
        OTrequest_ID: "",
        empname: "",
        OTdate: "",
        OTenddateforwholeday: "",
        OTstartdate: "",
        OTenddate: "",
        OTstart: "",
        wholeday: false,
        OTend: "",
        OThours: "",
        purposeOT: null,
        approver: "",
        approverID: null,
        status: null,
        wfID: null,
        reason: null,
        attachmentlist:null,
        position: null
      },
      LoadingDialog: false,
      reqname: null,
      TimeOffDetail: {
        timeoff_Name: "",
        empname: "",
        balance: null,
        start_Date: "",
        timeoff_ID: 0,
        end_Date: "",
        start_Time: "",
        end_Time: "",
        Requested_Date: "",
        status: "",
        approver_Name: "",
        message: "",
        wholeDay: false,
        wfID: null,
        reason: null,
        attachmentlist: null,
        position: null
      },
      idPosition: {
        wfID: null,
      },
      delegateID: null,
      delegateAppprover: null,
      delegatePosition: {
        wfID: null,
        employeeID: null,
        selectedDel: [
          {
            ID: null,
            name: null
          },
        ],
      },
      delegatedItem: [],
      message: "",

      SearchDelegateTo: [],
      headers1: [
        {
          text: "Name",
          value: "fullName",
          align: "left",
          sortable: false,
        },

        {
          text: "Employee ID",
          value: "employee_ID",
          align: "right",
          sortable: false,
        },
        {
          text: "Personal ID",
          value: "personalId_Number",
          align: "left",
          sortable: false,
        },
      ],
      pagination: {},
      delegateData: {
        delegate_old_approverID: null,
        delegate_approverID: null,
        delegate_requesterID: null,
        wf_ID: null,
        wf_Name: null,
      },
    };
  },
  async created() {
    this.permission = this.CheckViewApproveDeny(164, 165, 166);
    this.GetOTandTimeOff();
    this.GetDelegateTo();
    this.getDataFromParams();
  },

  computed: {
    thethaihead() {
      return this.whatthehead();
    },
    actionNote() {
      return this.$t("cancelnote");
    },
    status() {
      return [
        { val: "Waiting Approval", txt: this.$t("waitingApproval") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Denied", txt: this.$t("denied") },
        { val: "Canceled", txt: this.$t("Canceled") }
      ];
    },
    monthNames() {
      return [
        { val: 0, txt: this.$t("All") },
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    docType() {
      return [
        { val: "OT", txt: this.$t("Overtime") },
        { val: "Time Off", txt: this.$t("Leave") }
      ]

    },
    statusType() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Canceled", txt: this.$t("Canceled") },
        { val: "Denied", txt: this.$t("rejected") }
      ]
    },

    headers() {
      if (this.itemInner == 'Waiting Approval') {
        return [
          {
            text: "",
            align: "right",
            sortable: false,
            value: "checkboxs"
          },
          {
            text: this.$t("DescriptionOT"),
            align: "center",
            sortable: false,
            value: "Details",
            width: "500px"
          },
          {
            text: this.$t("status"),
            align: "center",
            value: "status",
            sortable: false,
          },
          {
            text: this.itemInner == "Waiting Approval" ? this.$t("action") : this.$t("Supervisor"),
            align: "center",
            width: "500px",
            sortable: false,
            value: "actions",
          },
        ];
      } else {
        return [

          {
            text: this.$t("DescriptionOT"),
            align: "center",
            sortable: false,
            value: "Details",
            width: "400px",
          },
          {
            text: this.$t("status"),
            align: "center",
            value: "status",
            sortable: false,
          },
          {
            text: this.itemInner == "Waiting Approval" ? this.$t("action") : this.$t("Supervisor"),
            align: "center",
            width: "500px",
            sortable: false,
            value: "actions",
          },
        ];
      }

    },
    itemFilter() {
      let self = this;
      let mylist = self.newList;
      let resultlist = [];


      if (self.itemInner != "Waiting Approval") {
        for (let i = 0; i < mylist.length; i++) {
          if (
            mylist[i].status != "Waiting Approval" && mylist[i].status != "Draft Request"
          ) {
            resultlist.push(mylist[i]);
          }
        }


      } else {
        resultlist = self.newList.filter(
          (x) => x.status === self.itemInner && !x.saveDraft
        );
        self.pendingList = resultlist;
      }


      return resultlist;

    },
    filterbydate() {

      let self = this;
      let filterlist = [];
      filterlist = self.newList;
      self.filteredItem = [];
      self.filterYears = self.todayyears.getFullYear();
      self.filterYear1 = self.todayyear1.getFullYear();
      let docFilter = [];
      let realFilter = [];
      let doubleFilter = [];
      let statusfilter = [];
      if (self.itemInner == "Waiting Approval") {
        for (var i = 0; i < filterlist.length; i++) {
          if (filterlist[i].status == "Waiting Approval") {
            let y = new Date(filterlist[i].requested_Date);
            let x = y.getFullYear(y);

            if (self.filterYears == x) {
              self.filteredItem.push(filterlist[i]);
            }
          }

        }
        if (self.todaymonths.val != 0) {
          for (var j = 0; j < self.filteredItem.length; j++) {
            let xmonth = new Date(self.filteredItem[j].requested_Date);

            let ymonth = (xmonth.getMonth()) + 1;
            if (self.todaymonths.val == ymonth) {
              doubleFilter.push(self.filteredItem[j]);
            }

          }

          self.filteredItem = doubleFilter;

        }
        if (self.selectedEmp != null) {

          for (var k = 0; k < self.filteredItem.length; k++) {
            if (self.filteredItem[k].requestername == self.selectedEmp && self.filteredItem[k].status == self.itemInner) {
              realFilter.push(self.filteredItem[k]);

            }
          }
          self.filteredItem = realFilter;

        }

        if (self.selectedDoc != null) {
          for (var l = 0; l < self.filteredItem.length; l++) {
            if (self.filteredItem[l].workFlow_Name == self.selectedDoc) {
              docFilter.push(self.filteredItem[l]);
            }
          }
          self.filteredItem = docFilter;
        }

      }


      if (self.itemInner == "All") {
        for (var n = 0; n < filterlist.length; n++) {
          if (filterlist[n].status != "Waiting Approval" && filterlist[n].status != "Draft Request") {
            let y = new Date(filterlist[n].requested_Date);
            let x = y.getFullYear(y);

            if (self.filterYear1 == x) {
              self.filteredItem.push(filterlist[n]);
            }
          }
        }
        if (self.todayMonths.val != 0) {
          for (var m = 0; m < self.filteredItem.length; m++) {
            let xmonth = new Date(self.filteredItem[m].requested_Date);

            let ymonth = (xmonth.getMonth()) + 1;
            if (self.todayMonths.val == ymonth) {
              doubleFilter.push(self.filteredItem[m]);
            }

          }
          self.filteredItem = doubleFilter;
        }
        if (self.SelectedEmp != null) {
          for (var p = 0; p < self.filteredItem.length; p++) {
            if (self.filteredItem[p].requestername == self.SelectedEmp && self.filteredItem[p].status != "Waiting Approval") {
              realFilter.push(self.filteredItem[p]);

            }
          }
          self.filteredItem = realFilter;

        }
        if (self.SelectedDoc != null) {
          for (var u = 0; u < self.filteredItem.length; u++) {
            if (self.filteredItem[u].workFlow_Name == self.SelectedDoc) {
              docFilter.push(self.filteredItem[u]);
            }
          }
          self.filteredItem = docFilter;
        }
        if (self.SelectedStatus != "All" && self.SelectedStatus != null && self.SelectedStatus != "") {

          for (var v = 0; v < self.filteredItem.length; v++) {
            if (self.filteredItem[v].status == self.SelectedStatus) {
              statusfilter.push(self.filteredItem[v]);
            }

          }
          self.filteredItem = statusfilter;
        }


      }
      return self.filteredItem;
    },
    filterfunc(v) {
      let y = new Date(v);
      let x = y.getFullYear();
      return x;
    },
    showPagination() {
      if (this.itemFilter.length) {
        return true;
      }
      return false;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted1() {
      return this.formatDate(this.OTDetail.OTdate);
    },
    computedDateFormatted2() {
      return this.formatDate(this.OTDetail.OTdate);
    },
    computedDateFormatted3() {
      return this.formatDate(this.TimeOffDetail.start_Date);
    },
    computedDateFormatted4() {
      return this.formatDate(this.TimeOffDetail.end_Date);
    },
    computedDateFormatted5() {
      return this.formatDate(this.OTDetail.OTstartdate);
    },
    computedDateFormatted6() {
      return this.formatDate(this.OTDetail.OTenddate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.OTDetail.OTenddateforwholeday);
    },
  },
  watch: {
    SearchDelegateTo(e) {
      this.searchDelegate(e);
    },
    $route() {
      this.getDataFromParams();
    },
    thelang() {
      this.whatthehead();
    },
  },
  mounted() {
    let self = this;
    self.showApproval(1);
    self.todayyears = new Date();
    self.todayyear1 = new Date();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
    self.ChangeYear(self.todayyears);
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    showApproval(v) {
      if (v == 1) {
        this.styleApproval = "color:#F99D20; border-bottom:3px solid #F99D20;";
        this.styleHistory = "";
        this.itemInner = "Waiting Approval";
        this.todaymonths.val = 0;
        this.todayyear1 = new Date();
        this.filterOrNot = true;
        this.SelectedEmp = null;
        this.SelectedDoc = null;
        this.SelectedStatus = "All";
      }
      else if (v == 2) {
        this.styleHistory = "color:#F99D20; border-bottom:3px solid #F99D20;";
        this.styleApproval = "";
        this.itemInner = "All";
        this.todayMonths.val = 0;
        this.todayyears = new Date();
        this.filterOrNot = true;
        this.selectedEmp = null;
        this.selectedDoc = null;
        this.SelectedStatus = "All";
        this.allCheck = false;
        this.oneCheck = false;
      }

    },
    ChangeMonth() {
      this.filterOrNot = true;
    },
    ChangeYear(v) {
      let y = new Date(v);
      this.filterOrNot = true;
      this.filterYear = y.getFullYear();
    },
    empSelector() {
      this.filterOrNot = true;
    },
    changeDoc() {
      this.filterOrNot = true;
    },
    changeStatus() {
      this.filterOrNot = true;
    },
    checkall(va) {
      this.selectAll = va;
      this.newList = this.newList.map((v) => ({
        ...v,
        isCheck: va,
      }));
      if (this.selectAll == true) {
        this.multiSelected = [];
        this.select();
      }
      else {
        this.selected = [];
        this.multiSelected = [];
        this.select();

      }

    },
    multiSelect(item) {
      let self = this;
      if(item.isCheck){
        self.selected.push(item.wF_ID);
      }else{
        self.selected = self.selected.filter((x) => x != item.wF_ID);
      }
      self.wholeSelected = self.newList.filter((x) => x.status == 'Waiting Approval');

      let checkvalidate = this.wholeSelected.filter((date) => date.isCheck == true);

      let checkfalsevalidate = this.wholeSelected.filter((date) => date.isCheck == false);

      self.multiSelected = self.wholeSelected;
      
      if (checkfalsevalidate.length != 0) {
        self.allCheck = false;
      }

      if (checkvalidate.length != 0) {
        self.selectAll=true;
        if (checkvalidate.length == self.wholeSelected.length) {
          self.allCheck = true;
        }
      }

    }
    ,
    downloadImage() {
      let self = this;
      const link = document.createElement('a');
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },
    async showiframeforDetail(item) {

      let self = this;
      if (item.filetype == "pdf") {
        const blob = await self.base64ToBlob(
          item.attachment_file,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      } else {
        self.dialogforDownload = true;
        self.imgForshow = 'data:image/png;base64,' + item.attachment_file;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },
    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },
    async cancelRequest() {
      let self = this;
      if (self.cancelReason != "" && self.cancelReason != null) {
        let temp = {
          wF_ID: self.viewItem.wF_ID,
          status: "Canceled",
          reason: self.cancelReason,
          requeststatus: self.viewItem.workFlow_Name
        };
        self.LoadingDialog = true;
        await axios
          .post(`${self.url}MyTasks/UpdateStatus`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.viewDialog = false;
              self.LoadingDialog = false;

            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        self.LoadingDialog = false;
        alert("Please fill required field");
      }
    },
    //  change noti for delegate case (Timeoff)

    NewnotiListForTimeOff(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for Time Off`,
      };

      axios
        .post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.pushNotificationForTimeOff(WF_ID, senderID, recipientID);
          }
        });
    },

    async pushNotificationForTimeOff(WF_ID, senderID, recipientID) {
      let temp = {
        user_ID: recipientID,
      };
      let token = [];
      await axios
        .post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
        .then(function (response) {
          var temp = response.data.data;

          if (temp[0]) {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });
      let self = this;
      let noti_temp = {
        registerIDs: token,
        title: "Timeoff Request",
        body:
          "Timeoff Request from " + self.delegateData.delegate_requestername,
        reportID: recipientID,
      };

      var response = await axios.post(
        `${this.url}Firebasetoken/sendNotification`,
        noti_temp
      );

      if (response.data.status == 0) {
        let noti_Delicate_temp = {
          wF_ID: WF_ID,
          senderID: senderID,
          receiver_ID: store.state.employeeID,
        };

        axios
          .post(
            `${self.url}NotificationInfo/EditDelicatedState`,
            noti_Delicate_temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              alert("This task has successfully been delegated");
            }
          });
      }
    },
    // --------------------------------------------------------------
    // change noti for delegate case (OT)
    async newnotiListForOT(WF_ID, senderID, recipientID, old_recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for OT`,
      };

      await axios
        .post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.pushNotificationForOT(
              WF_ID,
              senderID,
              recipientID,
              old_recipientID
            );
          }
        });
    },
    async pushNotificationForOT(WF_ID, senderID, recipientID, old_recipientID) {
      let temp_token = {
        user_ID: recipientID,
      };
      let token = [];
      await axios
        .post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp_token)
        .then(function (response) {
          var temp = response.data.data;

          if (temp[0]) {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });

      let self = this;
      let noti_temp = {
        registerIDs: token,
        title: "OT Request",
        body: "OT request from " + self.delegateData.delegate_requestername,
        reportID: recipientID,
      };

      var response = await axios.post(
        `${this.url}Firebasetoken/sendNotification`,
        noti_temp
      );

      if (response.data.status == 0) {
        let noti_Delicate_temp = {
          wF_ID: WF_ID,
          senderID: senderID,
          receiver_ID: old_recipientID,
        };

        axios
          .post(
            `${self.url}NotificationInfo/EditDelicatedState`,
            noti_Delicate_temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              alert("This task has successfully been delegated");
            }
          });
      }
    },
    // ----------------------------------------------------------------------

    
    async sendNotification(
      requester_ID,
      approvername,
      workFlow_Name,
      condition
    ) {
      if (requester_ID) {
        let temp = {
          user_ID: requester_ID,
        };
        let token = [];
        await axios
          .post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
          .then(function (response) {
            var temp = response.data.data;

            if (temp[0]) {
              temp.map((t) => token.push(t.fireBase_Token));
            }
          });

        this.pushNotification(
          token,
          approvername,
          workFlow_Name,
          requester_ID,
          condition
        );
      }
    },

    async pushNotification(
      token,
      approvername,
      workFlow_Name,
      requester_ID,
      condition
    ) {
      let noti_temp = {
        registerIDs: token,
        title: workFlow_Name,
        body: workFlow_Name + " " + condition + " from " + approvername,
        reportID: requester_ID,
      };

      await axios.post(`${this.url}Firebasetoken/sendNotification`, noti_temp);
    },

    async newnotiList(
      WF_ID,
      WF_Name,
      senderID,
      recipientID,
      approvername,
      condition
    ) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: store.state.employeeID,
        recipientID: senderID,
        seen: false,
        delicated: false,
        description: `${condition} your ${WF_Name} request`,
      };

      await axios
        .post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.sendNotification(senderID, approvername, WF_Name, condition);
          }
        });
    },
    newnotiListForMultiple(data) {
      data.map((x) =>
        setInterval(
          this.newnotiList(
            x.workFlowID,
            x.workFlowName,
            x.requesterID,
            x.approverID,
            x.approverName,
            x.condition
          ),
          3000
        )
      );
    },

    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    GetOTandTimeOff() {
      let self = this;

      self.LoadingDialog = true;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}MyTasks/Get_OTandTimeOff`, temp)
        .then(function (response) {
          self.OtandTimeoff = response.data.data;
          self.GetnewList();
        });
    },

    Detailrequest(temp) {
      let self = this;
      self.showreqDate=null;
      self.showOT = true;
      self.OTDetail.OTrequest_ID = temp.oT_Request_ID;

      if (temp.name && temp.lastName) {
        self.OTDetail.empname = temp.name+" "+temp.lastName;
      } else if (temp.requestername) {
        self.OTDetail.empname = temp.requestername;
      }

      if (temp.firstName && temp.myLastName!=null) {
        self.OTDetail.approver = temp.firstName+" "+temp.myLastName;
      } else if (temp.approvername) {
        self.OTDetail.approver = temp.approvername;
      }
      self.OTDetail.wholeday = temp.wholeDay;
      self.OTDetail.reqDate=temp.oT_Requested_Date;
      self.OTDetail.OTdate = temp.start_Date;
      self.OTDetail.OTenddateforwholeday = temp.end_Date;
      self.OTDetail.OTstart = temp.oT_Start_Time;
      self.OTDetail.OTend = temp.oT_End_Time;
      self.OTDetail.OTstartdate = temp.start_Date;
      self.OTDetail.OTenddate = temp.end_Date;
      self.OTDetail.OThours = temp.oT_Hours;
      self.OTDetail.purposeOT = temp.purpose_OT;
      self.OTDetail.attachmentlist=temp.attachmentlist.length==0?null:temp.attachmentlist;
      self.OTDetail.position=temp.positionName.toString();
      self.showendDate = self.convertUTCDateToLocalDate(self.OTDetail.OTenddate, 1)
      self.showstartDate = self.convertUTCDateToLocalDate(self.OTDetail.OTstartdate, 1);
      self.showreqDate = self.convertUTCDateToLocalDate(self.OTDetail.reqDate, 0);
      

      self.OTDetail.status = temp.status;
      if (temp.status == "Canceled") {
        self.OTDetail.reason = temp.reason;
      } else {
        self.OTDetail.reason = null;
      }
      self.OTDetail.wfID = temp.wF_ID;
    },
    DetailTimeOff(temp) {
      let self = this;
      self.showtoreqDate=null;
      self.TimeOffDialog = true;
      if (temp.name && temp.lastName) {
        self.TimeOffDetail.empname = `${temp.name} ${temp.lastName}`;
      } else if (temp.requestername) {
        self.TimeOffDetail.empname = temp.requestername;
      }
      self.TimeOffDetail.timeoff_Name = temp.timeoff_Name;
      self.TimeOffDetail.balance = temp.balance;
      self.TimeOffDetail.start_Date = temp.start_Date;
      self.TimeOffDetail.timeoff_ID = temp.timeoff_ID;
      self.TimeOffDetail.end_Date = temp.end_Date;
      self.TimeOffDetail.start_Time = temp.start_Time;
      self.TimeOffDetail.end_Time = temp.end_Time;
      self.TimeOffDetail.Requested_Date = temp.requested_Date;
      self.showreqDate = self.convertUTCDateToLocalDate(temp.requested_Date, 0);
      self.showstartDate =  self.convertUTCDateToLocalDate(temp.start_Date, 1);
      self.showendDate = self.convertUTCDateToLocalDate(temp.end_Date, 1);
      self.TimeOffDetail.attachmentlist=temp.req_Attachmentlist.length==0?null:temp.req_Attachmentlist;
      self.TimeOffDetail.position=temp.req_Position;
      //console.log(self.TimeOffDetail.position);
      self.TimeOffDetail.status = temp.status;
      if (temp.status == "Canceled") {
        self.TimeOffDetail.reason = temp.reason;
      } else {
        self.TimeOffDetail.reason = null;
      }
      self.TimeOffDetail.approver_Name = temp.approvername;
      self.TimeOffDetail.message = temp.message;
      self.TimeOffDetail.wholeDay = temp.wholeDay;
      self.TimeOffDetail.wfID = temp.wF_ID;
     
    },

    GetnewList() {
      let self = this;
      let list = self.OtandTimeoff;
      // testingKrish
      self.newList = list.map((x) => ({
        ...x,
        taskDetails: `${x.requestername} has requested for ${x.workFlow_Name
          } ${this.convertUTCDateToLocalDate(x.requested_Date, 0)} `,
        isCheck: false
      }));
      self.newList.sort(this.sortFunction);
      self.LoadingDialog = false;
    },

    sortFunction(a, b) {
      var dateA = new Date(a.requested_Date).getTime();
      var dateB = new Date(b.requested_Date).getTime();
      return dateA > dateB ? -1 : 1;
    },

    async getDataFromParams() {
      if (this.$route.params.notiString) {
        let self = this;

        let temp = {
          wF_ID: parseInt(this.$route.params.notiString),
        };

        let timeoff = null;
        let OT = null;

        await axios
          .post(
            `${self.url}NotificationController/Get_workflowByWorkFlowId`,
            temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              timeoff = response.data.data[0];
              //console.log(timeoff);
            }
          })
          .catch(function (error) {
            alert(error);
          });

        await axios
          .post(`${self.url}OT_Request/GetOTByWorkFlowId`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              OT = response.data.data[0];
            }
          })
          .catch(function (error) {
            alert(error);
          });

        if (timeoff) {
          self.dialogToggle(timeoff);
        } else if (OT) {
          self.dialogToggle(OT);
        }
      }
    },
    closeNoti() {
      this.showOT = false;
      this.TimeOffDialog = false;
      this.$router.replace({
        name: "mytask",
      });
    },

    dialogToggle(item) {
      let self = this;
      if (item.workFlow_Name == "OT") {
        self.Detailrequest(item);
      } else if (item.workFlow_Name == "Time Off") {
        self.DetailTimeOff(item);
      }
    },
    async openViewdialog(item) {
      let self = this;
      self.LoadingDialog = true;
      self.viewItem = item;
      let Request_ID = item.workFlow_Name === "Time Off" ? item.time_off_RequestID: item.oT_Request_ID;
      let temp = {
        requester_ID:item.requester_ID,
        company_ID:item.company_ID,
        oT_RequestID:Request_ID,
        wF_ID:item.wF_ID,
        isOT:item.workFlow_Name === "Time Off" ? false : true,
      }
      await axios
          .post(`${self.url}MyTasks/GetDetailOTTF`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.viewItem.req_Attachmentlist = response.data.data.req_Attachmentlist;
              self.viewItem.req_Position = response.data.data.req_Position;
                if(item.workFlow_Name === "Time Off"){
                  self.viewItem.timeoff_Name = response.data.data.timeoff_Name;
                  self.viewItem.timeoff_Name_TH = response.data.data.timeoff_Name_TH;
                }
            }
          })
          .catch(function (error) {
            alert(error);
          });
      self.viewDialog = true;
      if (self.viewItem.req_Attachmentlist == null) {
        self.viewItem.req_Attachmentlist = null;
      }
      self.LoadingDialog = false;

      self.showendDate = self.convertUTCDateToLocalDate(self.viewItem.end_Date, 1)
      self.showstartDate = self.convertUTCDateToLocalDate(self.viewItem.start_Date, 1);
      self.showreqDate = self.convertUTCDateToLocalDate(self.viewItem.requested_Date, 0);
      self.showaprDate = self.convertUTCDateToLocalDate(self.viewItem.updated_Date, 1)

    },
    hidedetaildialog() {
      this.viewDialog = false;
      this.noteOrNot = false;

    },
    cancelNote() {
      this.noteOrNot = false;
    },

    approveConfirm(temp) {
      let self = this;
      self.chosenstatus = temp.workFlow_Name;
      self.approveDialog = true;
      self.idPosition.wfID = temp.wF_ID;
      self.requester_ID = temp.requester_ID;
      self.approverID = temp.approver_ID;
      self.workFlow_Name = temp.workFlow_Name;
      self.approvername = temp.approvername;
      self.workFlow_ID = temp.wF_ID;
    },

    approveMyTasks() {
      let self = this;
      let temp = {
        wF_ID: self.idPosition.wfID,
        status: "Approved",
        requeststatus: self.chosenstatus,
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}MyTasks/UpdateStatus`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);

            let condition = "approved";
            self.newnotiList(
              self.workFlow_ID,
              self.workFlow_Name,
              self.requester_ID,
              self.approverID,
              self.approvername,
              condition
            );
            self.LoadingDialog = false;

            self.approveDialog = false;
            self.GetOTandTimeOff();
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    Getcancelid(item) {
      let self = this;
      self.canceldialog = true;
      self.CancelID = item.wF_ID;
    },
    cancelmyapprove() {
      let self = this;
      if (self.txtreason != "") {
        let temp = {
          wF_ID: self.CancelID,
          status: "Canceled",
          reason: self.txtreason,
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}MyTasks/UpdateStatus`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.txtreason = "";
              self.LoadingDialog = false;
              self.CancelID = 0;
              self.canceldialog = false;
              self.GetOTandTimeOff();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        self.LoadingDialog = false;
        alert("Please fill required field");
      }
    },
    approveAllShow() {
      let self = this;
      if (self.selected.length != 0) {
        self.approveAllDialog = true;
      }
    },

    approveAllMyTasks() {
      let self = this;
      self.message = "";
      let multipleNotiList = [];
      self.selected.map((x) =>
        multipleNotiList.push({
          workFlowID: x.wF_ID,
          workFlowName: x.workFlow_Name,
          requesterID: x.requester_ID,
          approverID: x.approver_ID,
          approverName: x.approvername,
          condition: "approved",
        })
      );

      for (var i = 0; i < self.selected.length; i++) {
        let temp = {
          wF_ID: self.selected[i],
          status: "Approved",
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}MyTasks/UpdateStatus`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.message = response.data.message;

              self.newnotiListForMultiple(multipleNotiList);
              self.GetOTandTimeOff();
            }
          });
      }
      alert("Successful");
      self.LoadingDialog = false;
      self.approveAllDialog = false;
      self.selected = [];
    },

    denyConfirm(temp) {
      let self = this;
      self.denyDialog = true;
      self.chosenstatus = temp.workFlow_Name;
      self.idPosition.wfID = temp.wF_ID;
      self.requester_ID = temp.requester_ID;
      self.approverID = temp.approver_ID;
      self.workFlow_Name = temp.workFlow_Name;
      self.approvername = temp.approvername;
      self.workFlow_ID = temp.wF_ID;
    },

    denyMyTasks() {
      let self = this;
      let temp = {
        wF_ID: self.idPosition.wfID,
        status: "Denied",
        requeststatus: self.chosenstatus,
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}MyTasks/UpdateStatus`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.denyDialog = false;

            let condition = "denied";
            self.newnotiList(
              self.workFlow_ID,
              self.workFlow_Name,
              self.requester_ID,
              self.approverID,
              self.approvername,
              condition
            );
            self.LoadingDialog = false;
            self.GetOTandTimeOff();
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },

    denyAllShow() {
      let self = this;
      if (self.selected.length != 0) {
        self.denyAllDialog = true;
      }
    },

    denyAllMyTasks() {
      let self = this;
      self.message = "";
      let multipleNotiList = [];
      self.selected.map((x) =>
        multipleNotiList.push({
          workFlowID: x.wF_ID,
          workFlowName: x.workFlow_Name,
          requesterID: x.requester_ID,
          approverID: x.approver_ID,
          approverName: x.approvername,
          condition: "denied",
        })
      );
      for (var i = 0; i < self.selected.length; i++) {
        let temp = {
          wF_ID: self.selected[i],
          status: "Denied",
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}MyTasks/UpdateStatus`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.message = response.data.message;
              self.multiSelected = [];
              self.selected = [];
              self.newnotiListForMultiple(multipleNotiList);
              self.GetOTandTimeOff();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
      alert("Successful");
      self.LoadingDialog = false;
      self.denyAllDialog = false;
      self.selected = [];
    },

    allowDelegate(temp) {
      let self = this;
      let test = temp.requestername;
      self.delegateApprover = temp.approvername;
      self.chosenstatus = temp.workFlow_Name;
      self.GetDelegateTo(test);
      self.delegateData = {
        delegate_old_approverID: null,
        delegate_approverID: null,
        delegate_requesterID: null,
        delegate_requestername: null,
        wf_ID: null,
        wf_Name: null,
      };
      self.delegateDialog = true;
      self.delegatePosition.wfID = temp.wF_ID;
      self.delegatedItem = [];
      self.SearchDelegateTo = [];
      self.DelegateToList = [];
      self.delegateName = "";
      self.delegateConfirm = true;
      self.delegatePosition.selectedDel = [];
      self.delegateData = {
        delegate_old_approverID: temp.approver_ID,
        delegate_approverID: temp.approver_ID,
        delegate_requesterID: temp.requester_ID,
        delegate_requestername: temp.requestername,
        wf_ID: temp.wF_ID,
        wf_Name: temp.workFlow_Name,
      };
    },

    delegateItem() {
      let self = this;

      let temp = {
        approvername: self.delegateName,
      };

      axios
        .post(`${self.url}MyTasks/RetrieveEmp`, temp)
        .then(function (response) {
          self.requestDelegate = response.data.data;

          self.delegatedItem = [
            {
              approvername: self.requestDelegate.approvername,
              employeeID: self.requestDelegate.employee_ID,
              personalNumber: self.requestDelegate.personalID_Number,
            },
          ];
          self.GetOTandTimeOff();
        })
        .catch(function (err) {
          alert(err);
        });
    },

    completeDelegate() {
      let self = this;

      self.delegateData.delegate_approverID = self.delegatePosition.employeeID;

      let temp = {
        wF_ID: self.delegatePosition.wfID,
        employee_ID: self.delegatePosition.employeeID,
        requeststatus: self.chosenstatus,
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}MyTasks/UpdateDelegate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.delegateDialog = false;

            if (self.delegateData.wf_Name === "Time Off") {
              self.NewnotiListForTimeOff(
                self.delegateData.wf_ID,
                self.delegateData.delegate_requesterID,
                self.delegateData.delegate_approverID,
                self.delegateData.delegate_old_approverID
              );
            } else if (self.delegateData.wf_Name === "OT") {
              self.newnotiListForOT(
                self.delegateData.wf_ID,
                self.delegateData.delegate_requesterID,
                self.delegateData.delegate_approverID,
                self.delegateData.delegate_old_approverID
              );
            }
            self.LoadingDialog = false;
            self.GetOTandTimeOff();
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },

    select() {
      let self = this;
      self.selected = [];

      if (self.selectAll == true) {
        let temp = self.newList;
        for (let i in temp) {
          if (temp[i].status == "Waiting Approval") {
            self.newList[i].isCheck = true;
            self.selected.push(self.newList[i].wF_ID);
          }
        }
      } else {
        self.selected = [];
        for (let v in self.newList) {
          if (self.newList[v].status == "Waiting Approval") {

            self.newList[v].isCheck = false;
          }
        }
      }
    },
    selectButNotAll() {
      let self = this;
      self.selected = [];
      if (self.multiSelected.length > 0) {
        for (var i = 0; i < self.multiSelected.length; i++) {
          if (self.multiSelected[i].status == "Waiting Approval" && self.multiSelected[i].isCheck == true) {
            self.selected.push(self.multiSelected[i].wF_ID);
          }
        }
      } else {
        self.selected = [];
      }
    },

    convertUTCDateToLocalDate(date, num) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);

      let output = null;

      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      if (num == 0) {
        return output;
      }
      else if (num == 1) {
        return newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
      }
    },

    ConvertUTCTimeToLocalTime(UTCDateString) {
      var convertdLocalTime = new Date(UTCDateString);

      var hourOffset = convertdLocalTime.getTimezoneOffset() / 60;

      convertdLocalTime.setHours(convertdLocalTime.getHours() + hourOffset);

      return convertdLocalTime;
    },

    GetDelegateTo(test) {
      let self = this;
      let temp = { company_ID: store.state.companyID };

      axios
        .post(`${self.url}PersonalInfo/GetPersonalInfoByCompanyID`, temp)
        .then(function (response) {
          self.empList = response.data.data;
          self.TempDelegateToList = response.data.data.filter(
            (x) => x.fullName != test
          );
        });
    },

    searchDelegate(input) {
      let self = this;
      function filterItems() {
        return self.TempDelegateToList.filter(function (el) {
          if (input) {
            return (
              el.fullName.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
              el.employee_Code.toString().indexOf(input) !== -1
            );
          } else {
            self.DelegateToList = [];
          }
        });
      }

      let a = filterItems();
      self.DelegateToList = a;
    },

    selectDelegate() {
      if (this.delegateID == null || this.delegateID == '') {
        this.delegateConfirm = true;
      }
      let temp = this.TempDelegateToList.filter((x) => x.employee_ID == this.delegateID);
      this.delegatePosition.selectedDel.ID = temp[0].employee_ID;
      this.delegatePosition.selectedDel.name = temp[0].fullName;
      if (this.delegatePosition.selectedDel != null && this.delegatePosition.selectedDel.ID != null) {
        this.delegatePosition.employeeID = this.delegatePosition.selectedDel.ID;
        this.delegateName = this.delegatePosition.selectedDel.name;
        this.delegateConfirm = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

@media (max-width: 375px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 17px;
  font-weight: normal;
  color: #f99d20;
}

@media screen and (max-width: 1600px) {
  .fix-row {
    padding-left: 100px !important;
  }
}

@media screen and (max-width: 1400px) {
  .fix-row {
    padding-left: 80px !important;
  }
}

@media screen and (max-width: 1300px) {
  .fix-row {
    padding-left: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .fix-row {
    padding-left: 0px !important;
  }
}

.deletepadd {
  padding: 0;
}

>>>.theme--light.v-input input,
.theme--light.v-input textarea {
  padding-left: 10px;
}

>>>.selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}

.form-checkbox input:checked~.checkmark {
  background-color: #f99d20;
}

>>>.form-checkbox input.white:checked~.checkmark:after {
  border: solid white;
  border-width: 0 3px 3px 0;
}

/* Customize the label (the container) */
.form-checkbox {
  display: block;
  position: relative;
  padding-top: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

/* Hide the browser's default checkbox */
.form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #7f7171 !important;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



>>>.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f99d20;
}

::v-deep>>>.v-application .primary--text {
  caret-color: #f99d20 !important;
}

@media only screen and (min-width:1400px) {
  #duo {
    background-color: rgb(249, 157, 32);
    border-radius: 5px;
    margin-left: 50px;
    width: fit-content;
  }
}

@media only screen and (max-width:540px) {
  .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width:820px) {}

@media only screen and (max-width:1028px) {
  @media only screen and (min-width:913px) {
    .des {

      display: flex !important;
      margin-right: -118px !important;

    }
  }

}

@media only screen and (min-width:1290px) {
  .des {
    display: flex !important;
    margin-right: -44px !important;
  }
}

.flex {
  justify-content: center;
}

.fitWidth {
  display: flex !important;
  width: 500px;
}

@media only screen and (max-width:1280px) {
  .flex {
    display: flex !important;
    justify-content: center;
  }
}

@media only sreen and (min-width:1280px) {
  .flex {
    display: flex !important;
    justify-content: center;
  }
}

@media only screen and (min-width:718px) {
  @media only screen and (max-width:830px) {
    .fitWidth {
      display: flex !important;
      width: 500px;
    }
  }
}
</style>
