<template style="background:#F0F0F7">
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <p class="txt-header">
      {{ $t("MyTimeOffRequest") }}
    </p>
    <div class="mt-6">
      <v-card class="pt-5 mb-5" style="margin-bottom: 5rem">
        <div style="margin-left: 2%; margin-right: 2%">
          <br />

          <div>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                <v-row>
                  <div class="mr-8 d-flex" style="background-color: #f99d20;border-radius: 5px;margin-left: 12px;">
                    <v-autocomplete
                      class="selectboxs minizebox ml-4 mt-1 mr-4 mb-1 pl-0"
                      v-model="todaymonths"
                      height="34px"
                      single-line
                      :items="monthNames"
                      item-text="txt"
                      item-value="val"
                      background-color="#FFFFFF"
                      return-object
                      persistent-hint
                      v-on:change="ChangeMonth(`${todaymonths.val}`)">
                    </v-autocomplete>
                    <date-picker
                      v-model="todayyears"
                      type="year"
                      color="#0FA7D8"
                      class="
                        selectboxs
                        minizebox
                        ml-1
                        mr-4
                        mt-1
                        mb-1
                        pl-0
                        today_years"
                      v-on:change="ChangeYear(todayyears)" >
                      <template slot="icon-calendar">
                        <img
                          src="@/assets/images/down1@2x.png"
                          style="
                            width: 30px;
                            height: 15px;
                            margin-top: 4px;
                            margin-right: -6px;
                          "
                          class="pl-2"
                        />
                      </template>
                    </date-picker>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="7">
                <div class="d-flex">
                  <div class="mr-4 mt-2">
                    <p>{{ $t("status") }}</p>
                  </div>
                  <div>
                    <v-autocomplete
                      v-model="itemInner"
                      :items="status"
                      outlined
                      item-text="txt"
                      item-value="val"
                      rounded
                      dense
                      color="#F99D20"
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" xs="12" sm="4" md="2" lg="2" xl="2">
                <v-btn
                  color="#F99D20"
                  class="white--text mr-5 text-capitalize btnres"
                  block
                  max-height="35"
                  :disabled="permission.add"
                  @click="NewTimeOffRequest()"
                  >{{ $t("newRequest") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>

          <div>
            <v-data-table
              :headers="headers"
              :items="itemFilter"
              hide-default-footer
              hide-default-header
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th>{{ $t("TimeOffType") }}</th>
                    <th>{{ $t("Balance") }}</th>
                    <th>{{ $t("startDate") }}</th>
                    <th>{{ $t("enddata") }}</th>
                    <th>{{ $t("StartTime") }}</th>
                    <th>{{ $t("EndTime") }}</th>
                    <th>{{ $t("RequestedOn") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th>{{ $t("Approver") }}</th>
                    <th class="pl-6">{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.timeoff_Name }}</td>
                  <td>{{ item.balance + " " + "Days" }}</td>
                  <td>{{ formatDate(item.start_Date) }}</td>
                  <td>
                    {{
                      item.wholeDay
                        ? formatDate(item.end_Date)
                        : formatDate(item.start_Date)
                    }}
                  </td>
                  <td>{{ item.start_Time ? item.start_Time : "-" }}</td>
                  <td>{{ item.end_Time ? item.end_Time : "-" }}</td>
                  <td>{{ convertUTCDateToLocalDate(item.request_On) }}</td>
                  <td
                    :style="{
                      color:
                        item.status == 'Approved'
                          ? '#22A534'
                          : item.status == 'Waiting Approval'
                          ? '#F99D20'
                          : '#FF6565',
                    }"
                  >
                    {{
                      item.status == "Approved"
                        ? $t("approved")
                        : item.status == "Denied"
                        ? $t("denied")
                        : $t("waitingApproval")
                    }}
                  </td>
                  <td>{{ item.approver_Name }}</td>
                  <td>
                    <v-btn
                      color="#F99D20"
                      class="white--text text-capitalize"
                      max-width="120"
                      max-height="35"
                      :disabled="permission.add"
                      @click="ViewTimeOffRequest(item)"
                    >
                      {{ $t("view") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div class="text-right" v-if="itemFilter.length">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="#FFCD2C"
                  :total-visible="6"
                ></v-pagination>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  dense
                  style="width: 120px; float: right"
                  solo
                  label="10/page"
                  v-model="itemsPerPage"
                  :items="items"
                  @input="itemsPerPage = parseInt($event, 10)"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <div>

        <v-dialog v-model="editTimeOFf" persistent max-width="600px">
          <v-card>
            <v-container class="pl-11">
              <div class="d-flex mt-3">
                <p class="mt-3" style="font-size: 23px; color: #444444">
                  {{ $t("TimeOffRequest") }}
                </p>
                <v-spacer></v-spacer>
                <div>
                  <v-btn @click="editDiaglog()" icon>
                    <v-icon>close </v-icon>
                  </v-btn>
                </div>
              </div>
              <v-row no-gutters class="mt-7">
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px">
                  {{ $t("TypeOfTimeOff") }}<span class="red--text"> *</span>
                </v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-autocomplete
                    outlined
                    dense
                    :disabled="notEditing"
                    :items="timeTypeList"
                    @input="(v) => onInputHandler(v, timeTypeList)"
                    item-value="timeoff_ID"
                    :error-messages="error.timeoff_ID"
                    v-model="viewTimeOff.timeoff_ID"
                    :item-text="(item) => ` ${item.timeoff_Type}`"
                    color="#F99D20">
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px">
                {{ $t("DirectSupervisor") }}<span class="red--text"> *</span>
                </v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="notEditing"
                    v-model="viewTimeOff.approver_Name"
                    outlined
                    readonly
                    dense
                    color="#F99D20"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <div>
                  <input
                    class="mb-6 mr-3"
                    :disabled="notEditing"
                    v-model="viewTimeOff.wholeDay"
                    id="allday"
                    type="checkbox"
                  />
                  <label for="allday"> {{ $t("wholeDay") }}</label>
                </div>
              </v-row>
              <div v-show="!viewTimeOff.wholeDay">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                  >
                    {{ $t("date") }}<span class="red--text"> *</span></v-col
                  >

                  <v-col cols="12" xs="12" sm="7" md="7">
                    <!-- <v-menu
                      v-model="forDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          v-model="computedDateFormated"
                          class="kanit-medium"
                          outlined
                          :disabled="notEditing"
                          :error-messages="error.start_Date"
                          dense
                          color="#F99D20"
                          persistent-hint
                          append-icon="mdi-calendar-month-outline"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="viewTimeOff.start_Date"
                        no-title
                        @input="forDate = false"
                      ></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="viewTimeOff.start_Date" 
                     no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px">
                    {{ $t("StartTime") }}<span class="red--text"> *</span>
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          v-model="viewTimeOff.start_Time"
                          :error-messages="error.start_Time"
                          append-icon="access_time"
                          outlined
                          :disabled="notEditing"
                          dense
                          color="#F99D20"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="viewTimeOff.start_Time"
                        no-title
                        fluid
                        @click:minute="$refs.menu2.save(viewTimeOff.start_Time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                  >
                    {{ $t("EndTime") }}<span class="red--text"> *</span>
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          v-model="viewTimeOff.end_Time"
                          append-icon="access_time"
                          :error-messages="error.end_Time"
                          outlined
                          :disabled="notEditing"
                          dense
                          color="#F99D20"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu"
                        v-model="viewTimeOff.end_Time"
                        no-title
                        fluid
                        @click:minute="$refs.menu.save(end_Time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>

              <div v-show="viewTimeOff.wholeDay">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px">
                    {{ $t("startDate") }}<span class="red--text"> *</span>
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <!-- <v-menu
                      v-model="forDateStart"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          v-model="computedDateFormatedStart"
                          class="kanit-medium"
                          outlined
                          :error-messages="error.start_Date"
                          dense
                          readonly
                          :disabled="notEditing"
                          color="#F99D20"
                          persistent-hint
                          append-icon="mdi-calendar-month-outline"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="viewTimeOff.start_Date"
                        no-title
                        @input="forDateStart = false"
                      ></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="viewTimeOff.start_Date" 
                     no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px">
                    {{ $t("enddata") }}<span class="red--text"> *</span>
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <!-- <v-menu
                      v-model="forEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          autocomplete="off"
                          v-model="computedDateFormated2"
                          class="kanit-medium"
                          outlined
                          :disabled="notEditing"
                          :error-messages="error.end_Date"
                          dense
                          readonly
                          color="#F99D20"
                          persistent-hint
                          append-icon="mdi-calendar-month-outline"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="viewTimeOff.end_Date"
                        no-title
                        @input="forEndDate = false"
                      ></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="viewTimeOff.end_Date" 
                     no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
              </div>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                >
                  {{ $t("Message") }}<span class="red--text"> *</span></v-col
                >
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="notEditing"
                    v-model="viewTimeOff.message"
                    :error-messages="error.message"
                    outlined
                    dense
                    color="#F99D20"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                >
                  {{ $t("status") }}<span class="red--text"> *</span></v-col
                >
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="notEditing"
                    outlined
                    dense
                    readonly
                    :error-messages="error.status"
                    color="#F99D20"
                    v-model="viewTimeOff.status"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                >
                  {{ $t("Balance") }}<span class="red--text"> *</span></v-col
                >
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="notEditing"
                    outlined
                    dense
                    readonly
                    color="#F99D20"
                    v-model="viewTimeOff.balance"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div
                v-if="viewTimeOff.status != 'Approved'"
                v-show="viewTimeOff.saveDraft"
              >
                <div class="ml-11 mr-11">
                  <v-row>
                    <v-col cols="12" xs="12" sm="4" md="4" v-show="isEdit">
                      <v-btn
                        color="#F99D20"
                        class="white--text mr-5 text-capitalize btnres"
                        block
                        max-height="35"
                        @click="EditGone()"
                      >
                        {{ $t("edit") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4" v-show="!isEdit">
                      <v-btn
                        color="#F99D20"
                        class="white--text mr-5 text-capitalize btnres"
                        block
                        max-height="35"
                        :disabled="permission.add"
                        @click="SaveGone()"
                      >
                        {{ $t("save") }}</v-btn
                      >
                    </v-col>

                    <v-col cols="12" xs="12" sm="4" md="4">
                      <v-btn
                        color="#F99D20"
                        class="white--text mr-5 text-capitalize btnres"
                        block
                        max-height="35"
                        :disabled="sendReq || permission.add"
                        @click="sendRequest()"
                      >
                        {{ $t("sendRequest") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4">
                      <v-btn
                        color="#FF0000"
                        class="white--text mr-5 text-capitalize"
                        block
                        max-height="35"
                        :disabled="cancelReq || permission.delete"
                        @click="
                          cancelingRequest(viewTimeOff.time_off_RequestID)
                        "
                        >{{ $t("cancelRequest") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row justify="center" v-show="!viewTimeOff.saveDraft">
                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-btn
                    color="#FF0000"
                    class="white--text mr-5 text-capitalize"
                    block
                    max-height="35"
                    v-if="
                      viewTimeOff.status != 'Approved' &&
                      viewTimeOff.status != 'Denied'
                    "
                    @click="cancelingRequest(viewTimeOff.time_off_RequestID)"
                  >
                    {{ $t("cancelRequest") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <!-- delete  dialog -->
        <v-dialog v-model="Deletedialog" persistent max-width="532">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <p style="margin-top: 10px; margin-bottom: -30px">
                {{ $t("ConfirmToCancelRequest") }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col align="center" cols="12">
                    <p style="font-size: 17px; color: #444444" class="pt-4">
                      {{ $t("DoYouWantToCancelThisRequest") }}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions style="margin-right: 3%" class="pb-6">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                max-height="35"
                @click="DeleteCancel"
                text
              >
                {{ $t("cancel") }}</v-btn
              >
              <v-btn
                color="#FF2727"
                class="mr-2 white--text text-capitalize"
                width="120"
                max-height="35"
                @click="DeleteRow()"
              >
                {{ $t("confirm") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="LoadingDialog" persistent width="300">
          <v-card color="#FFF4EB" dark>
            <v-card-text class="black--text">
              Loading Please Wait...
              <v-progress-linear
                indeterminate
                color="#F99D20"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="TimeOffDialog" persistent max-width="600px">
          <v-card style="border-radius: 15px">
            <v-container class="pl-11">
              <div class="d-flex mt-3">
                <p class="mt-3" style="font-size: 23px; color: #444444">
                  {{ $t("TimeOffRequest") }}
                </p>

                <v-spacer></v-spacer>
                <div>
                  <v-btn @click="closeNoti" icon>
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-row no-gutters class="mt-7">
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                  >{{ $t("TypeOfTimeOff") }}</v-col
                >

                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="true"
                    outlined
                    dense
                    v-model="TimeOffDetail.timeoff_Name"
                    color="#F99D20"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                  >{{ $t("DirectSupervisor") }}</v-col
                >
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="true"
                    v-model="TimeOffDetail.approver_Name"
                    outlined
                    readonly
                    dense
                    color="#F99D20"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <div>
                  <input
                    :disabled="true"
                    class="mb-6 mr-3"
                    v-model="TimeOffDetail.wholeDay"
                    id="allday"
                    type="checkbox"
                  />
                  <label for="allday">{{ $t("wholeDay") }}</label>
                </div>
              </v-row>
              <div v-show="!TimeOffDetail.wholeDay">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                    >{{ $t("date") }}</v-col
                  >

                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field
                      :disabled="true"
                      v-model="computedDateFormatted3"
                      autocomplete="off"
                      class="kanit-medium"
                      outlined
                      dense
                      color="#F99D20"
                      persistent-hint
                      append-icon="mdi-calendar-month-outline"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                    >{{ $t("StartTime") }}</v-col
                  >
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field
                      :disabled="true"
                      autocomplete="off"
                      v-model="TimeOffDetail.start_Time"
                      append-icon="access_time"
                      outlined
                      dense
                      color="#F99D20"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                    >{{ $t("EndTime") }}</v-col
                  >
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field
                      :disabled="true"
                      autocomplete="off"
                      v-model="TimeOffDetail.end_Time"
                      append-icon="access_time"
                      outlined
                      dense
                      color="#F99D20"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div v-show="TimeOffDetail.wholeDay">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                    >{{ $t("startDate") }}</v-col
                  >

                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field
                      :disabled="true"
                      v-model="computedDateFormatted3"
                      autocomplete="off"
                      class="kanit-medium"
                      outlined
                      dense
                      color="#F99D20"
                      persistent-hint
                      append-icon="mdi-calendar-month-outline"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="4"
                    md="4"
                    class="mt-2"
                    style="font-size: 15px"
                  >
                    {{ $t("enddata") }}</v-col
                  >

                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field
                      v-model="computedDateFormatted4"
                      :disabled="true"
                      autocomplete="off"
                      class="kanit-medium"
                      outlined
                      dense
                      color="#F99D20"
                      persistent-hint
                      append-icon="mdi-calendar-month-outline"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                  >{{ $t("Message") }}</v-col
                >
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="true"
                    v-model="TimeOffDetail.message"
                    outlined
                    dense
                    color="#F99D20"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                  >{{ $t("status") }}</v-col
                >

                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="true"
                    outlined
                    dense
                    readonly
                    color="#F99D20"
                    v-model="TimeOffDetail.status"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="mt-2"
                  style="font-size: 15px"
                  >{{ $t("Balance") }}</v-col
                >
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field
                    :disabled="true"
                    outlined
                    dense
                    readonly
                    color="#F99D20"
                    v-model="TimeOffDetail.balance"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>

import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewAddDelete from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddDelete, utils],
  data: () => ({
    permission: {
      add: true,
      delete: true,
    },
    url: enurl.apiUrl,
    timetypeitems: ["sick", "personal"],
    focus: new Date().toISOString().substr(0, 10),
    isEdit: true,
    time_off_RequestID: 0,
    Deletedialog: false,
    page: 1,
    pageCount: 15,
    itemsPerPage: 10,
    items: [10, 15, 20],
    LoadingDialog: false,
    cancelReq: false,
    notEditing: true,
    sendReq: false,
    todaymonths: null,
    todayyears: null,
    editTimeOFf: false,
    time: null,
    menu2: false,
    start_Time: null,
    forDate: false,
    forDateStart: false,
    forEndDate: false,
    menu: false,
    end_Time: null,
    itemInner: "All",
    timeTypeList: [],
    viewTimeOff: {
      timeoff_Name: "",
      balance: null,
      start_Date: "",
      timeoff_ID: 0,
      end_Date: "",
      start_Time: "",
      end_Time: "",
      request_On: "",
      status: "",
      approver_Name: "",
      message: "",
      wholeDay: false,
      time_off_RequestID: 0,
    },
    error: {
      timeoff_ID: "",
      balance: "",
      start_Date: "",
      end_Date: "",
      start_Time: "",
      end_Time: "",
      message: "",
    },

    headers: [
      {
        text: "",
        value: "rowcheckbox",
        align: "center",
        sortable: false,
      },
      {
        text: "Employee Name",
        value: "empname",
        align: "center",
        sortable: false,
      },
      {
        text: "Time Off Type",
        value: "leavetype",
        align: "left",
        sortable: false,
      },
      {
        text: "Balance",
        value: "balance",
        align: "center",
        sortable: false,
      },
      {
        text: "Start Date",
        value: "startdate",
        align: "center",
        sortable: false,
      },
      {
        text: "End Date",
        value: "enddate",
        align: "center",
        sortable: false,
      },
      {
        text: "Requested On",
        value: "requeston",
        align: "center",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        sortable: false,
      },
    ],
    staffTimeOffList: [],
    TimeOffDialog: false,
    TimeOffDetail: {
      timeoff_Name: "",
      empname: "",
      balance: null,
      start_Date: "",
      timeoff_ID: 0,
      end_Date: "",
      start_Time: "",
      end_Time: "",
      Requested_Date: "",
      status: "",
      approver_Name: "",
      message: "",
      wholeDay: false,
      wfID: null,
    },
  }),

  computed: {
    status() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Waiting Approval", txt: this.$t("waitingApproval") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Denied", txt: this.$t("denied") },
        {val:"Manual",txt: this.$t("Manual")},
      ];
    },

    monthNames() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },

    itemFilter() {
       let returndata=[];
      if(this.itemInner==="All")
      {
        returndata=this.staffTimeOffList;
      }
      else{
         returndata=this.staffTimeOffList.filter((x) => x.status === this.itemInner);
      }
      if (!this.itemInner) return this.staffTimeOffList;
      //if(this.itemInner==="All") return this.staffTimeOffList;
      return returndata;
    },
    computedDateFormated() {
      return this.formatDate(this.viewTimeOff.start_Date);
    },
    computedDateFormatedStart() {
      return this.formatDate(this.viewTimeOff.start_Date);
    },
    computedDateFormated2() {
      return this.formatDate(this.viewTimeOff.end_Date);
    },
    // computedDateFormatted3() {
    //   return this.formatDate(this.viewTimeOff.start_DateForHalf);
    // },
    computedDateFormatted3() {
      return this.formatDate(this.TimeOffDetail.start_Date);
    },
    computedDateFormatted4() {
      return this.formatDate(this.TimeOffDetail.end_Date);
    },
  },
  mounted() {
    let d = new Date();
    this.todaymonths = this.monthNames[d.getMonth()].val;
    this.todayyears = new Date();
    this.GetTimeOff();
    this.GetPosition();
    this.sendNotification();
    this.getDataFromParams();
    this.permission = this.CheckViewAddDelete(64, 65, 66);
    this.itemInner = this.$route.params.condition
      ? this.$route.params.condition
      : "All";
  },
  // define a watcher
  watch: {
    $route() {
      this.getDataFromParams();
    },
    "viewTimeOff.timeoff_ID"(v) {
      if (v) this.error.timeoff_ID = "";
    },
    "viewTimeOff.start_Date"(v) {
      if (v) this.error.start_Date = "";
    },
    "viewTimeOff.end_Date"(v) {
      if (v) this.error.end_Date = "";
    },
    "viewTimeOff.start_Time"(v) {
      if (v) this.error.start_Time = "";
    },
    "viewTimeOff.end_Time"(v) {
      if (v) this.error.end_Time = "";
    },
    "viewTimeOff.message"(v) {
      if (v) this.error.message = "";
    },
  },
  methods: {
    async sendNotification() {
      let self = this;
      let TempReportID = null;

      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(
        `${self.url}PersonalInfo/GetShortInfo`,
        userInfoTemp
      ).then(function (response) {
        var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
        var reportID = response.data.data[0].report_ID;
        self.username = username;
        self.reportID = reportID;
        TempReportID = reportID;
      });

      if (TempReportID) {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await Axios.post(
          `${self.url}Firebasetoken/GetFirebaseTokenByUserID`,
          temp
        ).then(function (response) {
          var temp = response.data.data;

          if (temp[0]) {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });

        self.tokenList = token;
      }
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}OT_Request/GetworkFlowOfOTRequest`,temp)
      .then(function (response) {
        if (response.data.status === 0) 
        {
          let WF_ID = response.data.data.wF_ID;
          let senderID = response.data.data.requester_ID;
          let recipientID = response.data.data.approver_ID;
          self.newnotiList(WF_ID, senderID, recipientID);
        }
      });
    },

    newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for Time Off`,
      };

      Axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
      .then(function (response) {
          if (response.data.status == 0) 
          {
            self.pushNotification(recipientID);
          }
        }
      );
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Timeoff Request",
        body: "Timeoff Request from " + self.username,
        reportID: recipientID,
      };

      await Axios.post(
        `${self.url}Firebasetoken/sendNotification`,
        noti_temp
      ).then(function () {
      });
    },

    async getDataFromParams() {
      try {
        let notiSring = this.$route.params.notiString;
        if (notiSring) {
          this.TimeOffDialog = true;
          let temp = {
            wF_ID: parseInt(notiSring),
          };
          const response = await Axios.post(`${this.url}NotificationController/Get_workflowByWorkFlowId`,temp);
          let list = response.data.data;
          let condition = this.$route.params.condition;
          this.itemInner = condition;
          this.TimeOffDetail.timeoff_Name = list[0].timeoff_Name;
          this.TimeOffDetail.balance = list[0].balance;
          this.TimeOffDetail.start_Date = list[0].start_Date;
          this.TimeOffDetail.timeoff_ID = list[0].timeoff_ID;
          this.TimeOffDetail.end_Date = list[0].end_Date;
          this.TimeOffDetail.start_Time = list[0].start_Time;
          this.TimeOffDetail.end_Time = list[0].end_Time;
          this.TimeOffDetail.Requested_Date = list[0].Requested_Date;
          this.TimeOffDetail.status = list[0].status;
          this.TimeOffDetail.approver_Name = list[0].approvername;
          this.TimeOffDetail.message = list[0].message;
          this.TimeOffDetail.wholeDay = list[0].wholeDay;
          this.TimeOffDetail.wfID = list[0].wF_ID;
        }
      } catch (error) {
        throw error;
      }
    },
    closeNoti() {
      this.TimeOffDialog = false;

      this.$router.replace({
        name: "MyTimeOffRequest",
        params: { condition: this.$route.params.condition },
      });
    },

    DetailTimeOff(temp) {
      let self = this;
      self.TimeOffDialog = true;
      //self.TimeOffDetail.temp.requestername;

      self.TimeOffDetail.timeoff_Name = temp.timeoff_Name;
      self.TimeOffDetail.balance = temp.balance;
      self.TimeOffDetail.start_Date = temp.start_Date;
      self.TimeOffDetail.timeoff_ID = temp.timeoff_ID;
      self.TimeOffDetail.end_Date = temp.end_Date;
      self.TimeOffDetail.start_Time = temp.start_Time;
      self.TimeOffDetail.end_Time = temp.end_Time;
      self.TimeOffDetail.Requested_Date = temp.Requested_Date;
      self.TimeOffDetail.status = temp.status;
      self.TimeOffDetail.approver_Name = temp.approvername;
      self.TimeOffDetail.message = temp.message;
      self.TimeOffDetail.wholeDay = temp.wholeDay;
      self.TimeOffDetail.wfID = temp.wF_ID;
    },

    convertUTCDateToLocalDate(date) {
      let stillUtc = this.$moment.utc(date).toDate(); //still utc
      let localDate = this.$moment(stillUtc).local().format("DD/MM/YYYY");
      return localDate;
    },

    editDiaglog() {
      this.editTimeOFf = false;
      this.isEdit = true;
      this.sendReq = false;
      this.cancelReq = false;
      this.notEditing = true;
    },

    EditGone() {
      this.isEdit = !this.isEdit;
      this.sendReq = !this.sendReq;
      this.cancelReq = !this.cancelReq;
      this.notEditing = !this.notEditing;
    },

    async SaveGone() {
      try {
        let startDate = this.$moment(this.viewTimeOff.start_Date);
        let endDate = this.$moment(this.viewTimeOff.end_Date);
        let dayDiff = endDate.diff(startDate, "days") + 1;
        this.error.timeoff_ID = !this.viewTimeOff.timeoff_ID ? "Time Off Type is required" : "";
        this.error.message = !this.viewTimeOff.message ? "Message is required" : "";
        if (this.viewTimeOff.wholeDay)
        {
          this.error.start_Date = !this.viewTimeOff.start_Date ? "Start date is required" : "";
          this.error.end_Date = !this.viewTimeOff.end_Date ? "End date is required" : "";
          this.viewTimeOff.start_Time = "";
          this.viewTimeOff.end_Time = "";
          // this.viewTimeOff.start_Date = this.viewTimeOff.start_Date;
          this.viewTimeOff.start_Date = self.$moment(self.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          this.viewTimeOff.end_Date = self.$moment(self.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          let tempvalid = this.ValidateAddTrue();
          if (tempvalid)
          {
            if (startDate < endDate.add(1, "day"))
            {
              if (dayDiff <= this.isHalf)
              {
                this.LoadingDialog = true;
                this.viewTimeOff.saveDraft = true;
                const respone = await Axios.post(`${this.url}TimeOff_Request/UpdateTimeOff_Request`,this.viewTimeOff);
                if (respone.data.status !== 0)
                {
                  this.LoadingDialog = false;
                  alert("Please recheck the date since there seems to be a clash with another request");
                }
                else
                {
                  this.LoadingDialog = false;
                  alert("Updated successfully");
                  this.isEdit = !this.isEdit;
                  this.sendReq = !this.sendReq;
                  this.cancelReq = !this.cancelReq;
                  this.notEditing = !this.notEditing;
                  this.GetTimeOff();
                  //this.LogTrace(null, "Update TimeOff", 31, "Medium");
                }
              }
              else 
              {
                alert("The Time Off requested is more than balance you have");
              }
            }
            else
            {
              alert("Start date can't be after end date");
            }
          }
        }
        else
        {
          this.error.start_Time = !this.viewTimeOff.start_Time ? "Start Time is required" : "";
          this.error.end_Time = !this.viewTimeOff.end_Time ? "End Time is required" : "";
          this.viewTimeOff.start_Time = this.viewTimeOff.start_Time;
          this.viewTimeOff.end_Time = this.viewTimeOff.end_Time;
          // this.viewTimeOff.end_Date = this.viewTimeOff.start_Date;
          this.viewTimeOff.start_Date = this.$moment(this.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          this.viewTimeOff.end_Date = this.$moment(this.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          let tempvalid = this.ValidateAddHalf();
          if (tempvalid)
          {
            this.viewTimeOff.saveDraft = true;
            let start_Time = this.$moment(this.viewTimeOff.start_Time, "h:mma");
            let end_time = this.$moment(this.viewTimeOff.end_Time, "h:mma");
            if (start_Time.isBefore(end_time)) 
            {
              this.LoadingDialog = true;
              const respone = await Axios.post(`${this.url}TimeOff_Request/UpdateTimeOff_Request`,this.viewTimeOff);
              if (respone.data.status !== 0)
              {
                this.LoadingDialog = false;
                alert("Please recheck the date since there seems to be a clash with another request");
              }
              else
              {
                alert("Updated successfully");
                this.LoadingDialog = false;
                this.isEdit = !this.isEdit;
                this.sendReq = !this.sendReq;
                this.cancelReq = !this.cancelReq;
                this.notEditing = !this.notEditing;
                this.GetTimeOff();
                //this.LogTrace(null, "Update TimeOff", 31, "Medium");
              }
            }
            else
            {
              alert("Start time can't be after end time");
            }
          }
        }
      } catch (ex) {
        this.LogTrace(ex, "Update TimeOff Fail", 31, "Medium");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    async sendRequest() {
      try {
        let temp = {
          time_off_RequestID: this.viewTimeOff.time_off_RequestID,
          timeoff_ID: this.viewTimeOff.timeoff_ID,
          wholeDay: this.viewTimeOff.wholeDay,
          // start_Date: this.viewTimeOff.start_Date,
          // end_Date: this.viewTimeOff.end_Date,
          start_Date: this.$moment(this.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end_Date: this.$moment(this.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          start_Time: this.viewTimeOff.start_Time,
          end_Time: this.viewTimeOff.end_Time,
          message: this.viewTimeOff.message,
          balance: this.viewTimeOff.balance,
          saveDraft: false,
        };
        this.error.timeoff_ID = !this.viewTimeOff.timeoff_ID ? "Time Off Type is required" : "";
        this.error.message = !this.viewTimeOff.message ? "Message is required" : "";
        if (this.viewTimeOff.wholeDay)
        {
          this.error.start_Date = !this.viewTimeOff.start_Date ? "Start date is required" : "";
          this.error.end_Date = !this.viewTimeOff.end_Date ? "End date is required" : "";
          this.viewTimeOff.start_Time = "";
          this.viewTimeOff.end_Time = "";
          this.viewTimeOff.start_Date = this.viewTimeOff.start_Date;
          let tempvalid = this.ValidateAddTrue();
          if (tempvalid)
          {
            if (this.$moment(this.viewTimeOff.start_Date) < this.$moment(this.viewTimeOff.end_Date).add(1, "day"))
            {
              this.LoadingDialog = true;
              const respone = await Axios.post(`${this.url}TimeOff_Request/UpdateTimeOff_Request`,temp);
              if (respone.data.status !== 0)
              {
                this.LoadingDialog = false;
                alert("Please recheck the date since there seems to be a clash with another request");
              }
              else
              {
                alert("Updated successfully");
                this.LoadingDialog = false;
                if (respone.data.status == 0)
                {
                  await this.getLatestData();
                }
                this.editTimeOFf = false;
                this.GetTimeOff();
                this.clearData(this.viewTimeOff);
                //this.LogTrace(null, "Update TimeOff", 31, "Medium");
              }
            } 
            else
            {
              alert("Start date can't be after end date");
            }
          }
        }
        else
        {
          this.error.start_Time = !this.viewTimeOff.start_Time ? "Start Time is required" : "";
          this.error.end_Time = !this.viewTimeOff.end_Time ? "End Time is required" : "";
          this.viewTimeOff.start_Time = this.viewTimeOff.start_Time;
          this.viewTimeOff.end_Time = this.viewTimeOff.end_Time;
          let temp = {
            time_off_RequestID: this.viewTimeOff.time_off_RequestID,
            timeoff_ID: this.viewTimeOff.timeoff_ID,
            wholeDay: this.viewTimeOff.wholeDay,
            // start_Date: this.viewTimeOff.start_Date,
            // end_Date: this.viewTimeOff.start_Date,
            start_Date: this.$moment(this.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            end_Date: this.$moment(this.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            start_Time: this.viewTimeOff.start_Time,
            end_Time: this.viewTimeOff.end_Time,
            message: this.viewTimeOff.message,
            balance: this.viewTimeOff.balance,
            saveDraft: false,
          };
          let tempvalid = this.ValidateAddHalf();
          if (tempvalid)
          {
            this.LoadingDialog = true;
            const respone = await Axios.post(`${this.url}TimeOff_Request/UpdateTimeOff_Request`,temp);
            if (respone.data.status !== 0) 
            {
              this.LoadingDialog = false;
              alert("Please recheck the date since there seems to be a clash with another request");
            } 
            else 
            {
              this.LoadingDialog = false;
              alert("Updated successfully");
              if (respone.data.status == 0) 
              {
                await this.getLatestData();
              }
              this.editTimeOFf = false;
              this.GetTimeOff();
              this.clearData(this.viewTimeOff);
              //this.LogTrace(null, "Update TimeOff", 31, "Medium");
            }
          }
        }
      }
      catch (ex)
      {
        this.LogTrace(ex, "Update TimeOff Fail", 31, "Medium");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    ValidateAddTrue() {
      if (this.viewTimeOff.wholeDay)
      {
        if (
          this.viewTimeOff.timeoff_ID != null &&
          this.viewTimeOff.start_Date != null &&
          this.viewTimeOff.end_Date != null &&
          this.viewTimeOff.message != null &&
          this.viewTimeOff.start_Date != "" &&
          this.viewTimeOff.end_Date != "" &&
          this.viewTimeOff.message != ""
        )
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },
    
    ValidateAddHalf() {
      if (!this.viewTimeOff.wholeDay) {
        if (
          this.viewTimeOff.timeoff_ID != 0 &&
          this.viewTimeOff.start_Time != null &&
          this.viewTimeOff.end_Time != null &&
          this.viewTimeOff.message != null &&
          this.viewTimeOff.start_Time != "" &&
          this.viewTimeOff.end_Time != "" &&
          this.viewTimeOff.message != ""
        )
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },

    clearData(obj = {}, defaultValue = null) {
      for (const key in obj) {
        obj[key] = defaultValue;
      }
    },

    cancelingRequest(id) {
      this.time_off_RequestID = id;
      this.editTimeOFf = false;
      this.Deletedialog = true;
    },

    async DeleteRow() {
      try {
        let temp = {
          time_off_RequestID: this.time_off_RequestID,
        };
        this.LoadingDialog = true;
        const response = await Axios.post(`${this.url}TimeOff_Request/DeleteTimeOff`,temp);
        if (response.data.status == 0)
        {
          this.LoadingDialog = false;
          alert("Delete Success");
          this.Deletedialog = false;
          this.editDiaglog();
          this.GetTimeOff();
        }
      } catch (ex) {
        throw ex;
      }
    },

    async onInputHandler(id, timeTypeList) {
      const filter = timeTypeList.find((x) => x.timeoff_ID == id);
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          timeoff_ID: filter.timeoff_ID,
        };
        const respone = await Axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyIdTimeOffId`,
          temp
        );
        let date = respone.data.data;
        let availability_Days = filter.availability_Days;
        if (
          availability_Days == date.avalable_Day ||
          availability_Days < date.avalable_Day
        ) {
          this.viewTimeOff.balance = 0;
          this.sendReq = false;
          this.cancelReq = false;
        } else {
          let result = availability_Days - date.avalable_Day;

          result = String(result);
          if (result == 0.5) {
            this.timeOff.balance = "Half";
            this.isHalf = 0.5;
          } else if (result.match(/\./)) {
            let final = result.split(".")[0];
            this.viewTimeOff.balance = final + " and half";
            this.isHalf = +final + 0.5;
          } else {
            this.viewTimeOff.balance = result;
            this.isHalf = result;
          }
        }
      } catch (ex) {
        throw ex;
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    NewTimeOffRequest() {
      this.$router.push({ name: "NewTimeOffRequest" }).catch(()=>{});
    },

    async GetPosition() {
      try {
        let position_ID = sessionStorage.getItem("positionID");
        let temp = {
          company_ID: store.state.companyID,
          position_ID: position_ID,
          employee_ID: store.state.employeeID,
        };
        const response = await Axios.post(`${this.url}TimeOff_Request/GetByPositionID`,temp);
        this.timeTypeList = response.data.data;
      } catch (ex) {
        throw ex;
      }
    },

    ViewTimeOffRequest(prop) {
      this.editTimeOFf = true;
      let checkValue = prop.balance;
      if (checkValue.includes("and half"))
      {
        let balance = checkValue.split(" ");
        this.isHalf = +balance[0] + 0.5;
      }
      else if (checkValue == "Half")
      {
        this.isHalf = 0.5;
      }
      else
      {
        this.isHalf = checkValue;
      }
      this.viewTimeOff = Object.assign({}, prop);
      let date1 = new Date(prop.start_Date);
      let plusday1 = date1.getDate() + 1;
      date1.setDate(plusday1);
      let date2 = new Date(prop.end_Date);
      let plusday2 = date2.getDate() + 1;
      date2.setDate(plusday2);
      this.viewTimeOff.start_Date = new Date(date1).toISOString().substr(0, 10);
      this.viewTimeOff.end_Date = new Date(date2).toISOString().substr(0, 10);
    },

    ChangeMonth() {
      this.GetTimeOff();
    },

    DeleteCancel() {
      this.Deletedialog = false;
      this.editTimeOFf = true;
    },

    ChangeYear() {
      let d = new Date();
      d.setMonth(this.todaymonths - 1);
      this.focus = d;
    },

    async GetTimeOff() {
      try {
        let myyear = this.todayyears;
        let y = myyear.getFullYear();
        let thisyear = "";
        if (this.todaymonths.val == undefined)
        {
          thisyear = this.todaymonths;
        }
        else
        {
          thisyear = this.todaymonths.val;
        }
        this.LoadingDialog = true;
        let temp = {
          month_Num: thisyear,
          year_Num: y,
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          requester_ID: parseInt(store.state.reportID),
        };
        const response = await Axios.post(`${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyId`,temp);
        this.staffTimeOffList = response.data.data;
        this.LoadingDialog = false;
        //this.LogTrace(null, "Get TimeOff", 31, "Critical");
      } catch (ex) {
        this.LogTrace(ex, "Get TimeOff Fail", 31, "Critical");
        alert(ex + "Correlation ID is 31");
        this.LoadingDialog = false;
      }
    },
  },
};
</script>

<style scoped>
.tableCol {
  border-bottom: 1px solid #fff;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .v-dialog {
  border-radius: 10px !important;
}
>>> .selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
@media (max-width: 320px) {
  .minizebox {
    width: 100px;
    height: 35px;
  }
}
@media (max-width: 280px) {
  .minizebox {
    width: 85px;
    height: 35px;
  }
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

>>> .v-select.v-text-field input {
  margin-left: 10px;
}
>>> .mdi-menu-down::before {
  content: "\F035D";
  color: black;
}

>>> .mx-input {
  color: black;
}
>>> .v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-size: 17px;
  font-weight: normal;
  color: #f99d20;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
tbody tr:hover {
  background-color: #fff9f0 !important;
}
</style>
