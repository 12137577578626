<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 20px">{{ $t("Payroll") }}</p>
    </div>
    <div class="mt-6">
      <v-tabs v-show="employeeTab || slipDetailTab || btnshow" v-model="tab" class="mt-6" grey--text
        background-color="#f0f8fc" show-arrows slider-color="#F99D20">
        <v-tab v-show="false" @click="closeBtn" href="#one" class="text-capitalize" style="
            border-radius: 10px 10px 0px 5px;
            width: 180px;
            margin-right: 3px;
            background-color: white;
          ">{{ $t("Payroll") }}</v-tab>
        <v-tab v-show="employeeTab" @click="closeBtn" href="#two" class="text-capitalize" style="
            border-radius: 10px 10px 0px 5px;
            width: 180px;
            margin-right: 3px;
            background-color: white;
          ">{{ $t("Employees") }}</v-tab>
        <v-tab v-show="slipDetailTab" @click="clickSlipDetails(empInfoForaddPayroll[0])" href="#three" class="text-capitalize" style="
            border-radius: 10px 10px 0px 5px;
            width: 180px;
            margin-right: 3px;
            background-color: white;
          ">{{ $t("PayslipDetails") }}</v-tab>

        <v-spacer v-show="btnshow"></v-spacer>
        <v-btn color="#F99D20" class="white--text mr-5 mt-2 text-capitalize" max-height="35" @click="showduesetup"
          v-show="btnshow">{{ $t("PaymentDueSetUp") }}</v-btn>
      </v-tabs>
      <v-tabs-items touchless :value="tab" style="margin-bottom: 2.5rem">       
        <!-- Payroll tab -->
        <v-tab-item :key="1" value="one">
          <v-card>
            <v-row class="mx-4" style="height: 100px; padding-top: 5px">
              <v-col cols="12" xs="12" sm="8" md="11" lg="12" xl="12">
                <v-tabs class="typetabs" style="margin-top:10px;">
                    <v-tabs-slider color="#F89D27"></v-tabs-slider>
                    <v-tab
                      class="text-capitalize"
                      style="width: 150px;"
                      :class="{ 'activeclass': togglevalue == 0 }"
                      @click="dualclick(0)"
                    > 
                      {{ $t("Showall") }}
                    </v-tab>
                    <v-tab
                      class="text-capitalize emptabs"
                      :class="{ 'activeclass': togglevalue == 1 }"
                      @click="dualclick(1)"
                    > 
                    <img :src="togglevalue == 1 ? datelogo : datelogo" class="mr-3 calimg"/>{{ $t("MonthlySalary") }}
                    </v-tab>
                    <v-tab
                      class="text-capitalize emptabs"
                      :class="{ 'activeclass': togglevalue == 2 }"
                      @click="dualclick(2)"
                    > 
                    <img :src="togglevalue == 2 ? daylogow : daylogo" class="mr-3 calimg"/>{{ $t("Daily-Wage") }}
                    </v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row class="pt-2" style="height: 30px; padding-top: 5px">
              <p style="color: #8a8c8e; margin-left: 45px;">{{ $t("Year") }}</p>
              <p :style="thethaihead ? 'padding-left:120px;' : ''"
                style="color: #8a8c8e; padding-left: 95px;">{{ $t("Month") }}</p>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="5" md="3" lg="4" xl="2" class="pt-0">
                <div id="duo" class="mr-5 d-flex" style="background-color: #f99d20; border-radius: 5px">
                  <date-picker name="payrollyearpicker" v-model="todayyears" type="year" color="#F99D20"
                    v-on:change="ChangeMonth(todayyears)"
                    class="selectbox minizebox ml-1 mr-1 mt-1 mb-1 pl-0 today_years">
                    <template slot="icon-calendar">
                      <img src="@/assets/images/down1@2x.png" style="
                          width: 30px;
                          height: 15px;
                          margin-top: 4px;
                          margin-right: -6px;
                        " class="pl-2" />
                    </template>
                  </date-picker>
                  <v-autocomplete id="monthbox" class="selectbox minizebox ml-0 mt-1 mr-1 mb-1 pl-1" v-model="todaymonths"
                    height="34px" single-line :items="monthNames" :item-text="(item) => item.txt"
                    :item-value="(item) => item.val" background-color="#FFFFFF" color="#f99d20" return-object
                    persistent-hint v-on:change="ChangeMonth(`${todaymonths.val}`)"></v-autocomplete>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2" class="d-flex justify-end pt-0">
                <v-btn color="#F99D20" style="font-weight: normal" class="white--text text-capitalize mr-7 px-1 mt-3" @click="showDialog()"
                  max-height="41" :style="$vuetify.breakpoint.xs
                      ? 'width:110px; font-size:10px; float:left; margin-left:20px'
                      : 'float:left; height:38px; margin-top:10px !important'
                    " width="auto" :disabled="permission.add">{{ $t("CreateNewPayroll") }}</v-btn>
              </v-col>
            </v-row>

            <div style="margin-top: 30px; margin-left: 1rem; margin-right: 1rem; padding-bottom: 30px;">
              <v-data-table :headers="headers" :items="payrollData" :page.sync="page" :items-per-page="itemsPerPage"
                hide-default-footer hide-default-header :mobile-breakpoint="0">
                <template v-slot:header="{ props: { } }">
                  <thead>
                    <tr>
                      <th>{{ $t("Period") }}</th>
                      <th>{{ $t("PaymentDate") }}</th>
                      <th style="text-align: center">{{ $t("PaymentMethod") }}</th>
                      <th style="text-align: right">{{ $t("EmployeeExpenses") }}</th>
                      <th style="text-align: right">{{ $t("NetPaid") }}</th>
                      <th class="text-center">{{ $t("Status") }}</th>
                      <th style="text-align: center">{{ $t("Actions") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{
                        periodDay(item.paydueStart_Date) > 9
                        ? periodDay(item.paydueStart_Date)
                        : "0" + periodDay(item.paydueStart_Date)
                      }}
                      {{
                        periodDay(item.paydueEnd_Date) > 9
                        ? " " + "-" + " " + periodDay(item.paydueEnd_Date)
                        : " " +
                        "-" +
                        " " +
                        "0" +
                        periodDay(item.paydueEnd_Date)
                      }}
                      {{ " " + periodFormat(item.paydueEnd_Date) }}
                    </td>
                    <td>
                      {{
                        periodDay(item.payment_Date) > 9
                        ? periodDay(item.payment_Date) +
                        " " +
                        periodFormat(item.payment_Date)
                        : "0" +
                        periodDay(item.payment_Date) +
                        " " +
                        periodFormat(item.payment_Date)
                      }}
                    </td>
                    <td style="text-align: center">
                      <v-row cols="12" class="d-flex justify-center align-center">
                        <p class="pt-0 ml-0 mb-0">{{ item.paid_By }}</p>
                        <div class="pt-1 ml-3">
                          <img class="calimg1" :src="item.empTypeID == 1 ? datelogo : item.empTypeID == 2 ? daylogo : ''" />
                        </div>
                      </v-row>
                    </td>
                    <td style="text-align: right">
                      {{numberWithCommas(parseFloat(item.totalexpense).toFixed(2))}}
                    </td>
                    <td style="text-align: right;">
                      {{numberWithCommas(parseFloat(item.totalNetpaid).toFixed(2))}}
                    </td>
                    <td style="text-align: center">
                      <div class="d-flex" :style="{
                            backgroundColor:
                              item.status == 'Paid'
                                ? '#CCF3E9'
                                : item.status == 'In Progress'
                                  ? '#70707026'
                                  : '#70707026',
                            borderRadius: '20px',
                            padding: '5px',
                            width: '150px',
                            height: '35px',
                            alignItems: 'center',
                            margin: 'auto',
                            cursor: 'pointer',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }">
                        <p class="pt-4" :style="{
                          color:
                            item.status == 'Paid'
                              ? '#00C092'
                              : item.status == 'In Progress'
                                ? '#8a8c8e'
                                : '',
                          justifyContent: 'center',
                          display: 'contents',
                        }">
                          {{
                            item.status == "Paid"
                            ? $t("paid")
                            : item.status == "In Progress"
                              ? $t("awaiting")
                              : ""
                          }}
                        </p>
                      </div>
                    </td>
                    <td style="text-align: center">
                      <v-menu v-if="item.status == 'In Progress'" class="min-square-btn" v-model="item.isClick" absolute offset-y
                        :position-x="positionMenuX" :position-y="positionMenuY" min-width="235px">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn plain small outlined tile fab width="30" height="30" :class="item.isClick
                              ? 'orange--text ma-5 '
                              : 'black--text ma-5 dots'
                            " v-bind="attrs" v-on="on" :color="item.isClick ? '#FFF2E3' : 'initial'"
                            :opacity="item.isClick ? '0.5' : '1'" @click="togglePositionMenu" :style="item.isClick
                                ? 'border:2px solid #FFF2E3; border-radius:5px;'
                                : 'border:2px solid #d3d3d3; border-radius:5px;'
                              " @click-outside="outsidechange()">
                            <span class="mdi mdi-dots-horizontal" style="font-size: 30px"></span>
                          </v-btn>
                        </template>
                        <v-list class="listhover">
                          <v-list-item @click="editpaydueDetail(item)" link @focus="$event.target.click()">
                            <img alt="`${chat.title} avatar`" :src="imagesIcon.edit" class="editIconPayrollV2 mr-2 no-hover"/>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px;">
                                {{ $t("edit") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <hr style="border: none; background-color: rgba(0, 0, 0, 0.1); height: 1px;" />
                          <v-list-item @click="deletePaydue(item.payrollID)" link @focus="$event.target.click()">
                            <img alt="`${chat.title} avatar`" :src="imagesIcon.delete" class="deleteIconPayrollV2 mr-2 no-hover"/>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px;">
                                {{ $t("Delete") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu v-else class="min-square-btn" v-model="item.isClick" absolute offset-y
                        :position-x="positionMenuX" :position-y="positionMenuY" min-width="303px">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn plain small outlined tile fab width="30" height="30" :class="item.isClick
                              ? 'orange--text ma-5 '
                              : 'black--text ma-5 dots'
                            " v-bind="attrs" v-on="on" :color="item.isClick ? '#FFF2E3' : 'initial'"
                            :opacity="item.isClick ? '0.5' : '1'" @click="togglePositionMenu" :style="item.isClick
                                ? 'border:2px solid #FFF2E3; border-radius:5px;'
                                : 'border:2px solid #d3d3d3; border-radius:5px;'
                              " @click-outside="outsidechange()">
                            <span class="mdi mdi-dots-horizontal" style="font-size: 25px"></span>
                          </v-btn>
                        </template>
                        <v-list class="listhover">
                          <v-list-item @click="editpaydueDetail(item)" @focus="$event.target.click()">
                            <img alt="`${chat.title} avatar`" :src="imagesIcon.edit" class="editIconPayrollV2 mr-2 no-hover"/>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px;">
                                {{ $t("edit") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item :style="{ backgroundColor: bgcolor }">
                            <v-menu :position-x="subMenuX" :position-y="subMenuY - 20" offset-x absolute max-width="200px" min-width="200px">
                              <template v-slot:activator="{ on, attrs }">
                                <div style="display: flex !important">
                                  <img v-bind="attrs" v-on="on" alt="`${chat.title} avatar`" :src="imagesIcon.downloadIcon" class="editIconPayrollV2 mr-2 mt-2 no-hover"/>
                                  <v-list-item-content v-bind="attrs" v-on="on">
                                    <v-list-item-title style="width: 250px; font-size: 14px;" >
                                      {{ $t("downloadSSo") }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </div>
                              </template>
                              <v-list class="sublisthover" style="cursor: pointer">
                                <v-list-item style="font-size: 14px;">
                                  <v-list-item-title @click="exportssoexcel(item)">
                                    SSO 1-10/1 ver.1
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item style="font-size: 14px;">
                                  <v-list-item-title @click="downloadSSO(item)">
                                    SSO 1-10/1 ver.2
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item>
                          <v-list-item :style="{ backgroundColor: bgcolor }">
                            <v-menu :position-x="subMenuX" :position-y="subMenuY + 30" offset-x absolute max-width="200px" min-width="200px">
                              <template v-slot:activator="{ on, attrs }">
                                <div style="display: flex !important">
                                  <img v-bind="attrs" v-on="on" alt="`${chat.title} avatar`" :src="imagesIcon.downloadIcon" class="editIconPayrollV2 mr-2 mt-2 no-hover"/>
                                  <v-list-item-content v-bind="attrs" v-on="on">
                                    <v-list-item-title style="font-size: 14px;">
                                      {{ $t("downloadPND1") }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </div>
                              </template>
                              <v-list class="sublisthover" style="cursor: pointer">
                                <v-list-item v-for="(itemss, index) in itemsMenu" :key="index">
                                  <v-list-item-title style="font-size: 14px;" @click="downloadPND(itemss.val, item)">
                                    {{ itemss.Title }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item>
                          <v-list-item link @click="openAssigndialog(
                              item.paymentDue_ID,
                              item.paydueStart_Date,
                              item.paydueEnd_Date,
                              item.payment_Date,
                              item.paid_By
                            ) " @focus="$event.target.click()">
                              <img alt="`${chat.title} avatar`" :src="imagesIcon.downloadIcon" class="editIconPayrollV2 mr-2 no-hover"/>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px;">
                                {{ $t("downloadAllSlip") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item link @click="openEmaildialog(item.paymentDue_ID,item.paydueStart_Date,item.paydueEnd_Date,item.payment_Date,item.paid_By) " @focus="$event.target.click()">
                            <img alt="`${chat.title} avatar`" :src="imagesIcon.mailsendIcon" class="editIconPayrollV2 mr-2 no-hover"/>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px;">
                                {{ $t("sendSlipToMail") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <hr style="border: none; background-color: rgba(0, 0, 0, 0.1); height: 1px;" />
                          <v-list-item link @click="deletePaydue(item.payrollID)" @focus="$event.target.click()">
                            <img alt="`${chat.title} avatar`" :src="imagesIcon.delete" class="deleteIconPayrollV2 mr-2 no-hover"/>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px;">
                                {{ $t("Delete") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div class="text-right" v-if="payrollData.length">
                <v-row justify="end" class="ml-5 mt-5 mr-5">
                  <v-col cols="12" lg="3" md="3" sm="4">
                    <v-pagination v-model="page" @input="clickpagi" :length="pageCount" color="#F99D20"
                      :total-visible="6"></v-pagination>
                  </v-col>
                  <v-col cols="12" lg="2" md="2" sm="4" class="mt-1">
                    <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                      :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>

            <!-- //print design -->
            <div ref="content1" style="position: absolute; left: -9999px; background-color: white">
              <div style="margin-left: 1rem">
                <v-row>
                  <v-col>
                    <v-row class="ml-0" style="margin-top: 2rem">
                      <v-col>
                        <p style="
                            font-size: 21px;
                            color: #444444;
                            margin-left: -1rem;
                          ">{{ Printempdetail.companyName }}</p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col>
                        <p style="
                            font-size: 21px;
                            color: #444444;
                            margin-right: 0;
                            text-align: end;
                          ">{{ $t("Payslip") }}</p>
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: -1rem">
                      <v-col>
                        <p class="printDialogText" style="color: #444444">{{ $t("employeeid") }} : {{
                          Printempdetail.Empcode }}</p>
                      </v-col>
                      <v-col>
                        <p class="printDialogText" style="color: #444444">
                          {{ $t("EmployeeName") }} :
                          {{ Printempdetail.Empname }}
                        </p>
                      </v-col>
                      <v-col>
                        <p class="printDialogText" style="color: #444444">
                          {{ $t("PaymentDate") }} :
                          {{ formatDateForTable(Printempdetail.PaymentDate) }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: -1.5rem">
                      <v-col>
                        <p class="printDialogText" style="color: #444444">{{ $t("position") }} : {{
                          Printempdetail.Position }}</p>
                      </v-col>
                      <v-col>
                        <p class="printDialogText" style="color: #444444">{{ $t("department") }} : {{
                          Printempdetail.Position }}</p>
                      </v-col>
                      <v-col>
                        <p class="printDialogText" style="color: #444444">{{ $t("BankAccount") }} : {{ Printempdetail.Bank
                        }}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>

              <div id="middlecol">
                <table style="border: 2px solid black; border-collapse: collapse">
                  <tr style="height: 57px; background: #f8f8f8">
                    <th colspan="2" style="
                        border: 2px solid black;
                        font-weight: normal;
                        width: 100%;
                      ">{{ $t("Income") }}</th>
                    <th colspan="2" style="
                        border: 2px solid black;
                        font-weight: normal;
                        width: 100%;
                      ">{{ $t("Deductions") }}</th>
                    <th colspan="2" style="
                        border: 2px solid black;
                        font-weight: normal;
                        width: 100%;
                      ">{{ $t("ytd") }}</th>
                  </tr>

                  <tr style="height: 57px">
                    <td colspan="2" class="printDialogText" style="
                        border-right: 2px solid black;
                        color: #f99d20;
                        font-weight: normal;
                      ">
                      <table style="
                          width: 100%;
                          border: 0;
                          margin-top: -1.5rem;
                          padding-left: 0.8rem;
                        ">
                        <tr style="height: 57px" v-for="(item, i) in Revenuelistforprint" :key="i">
                          <td colspan="4" class="printDialogTextInTable" style="
                              color: black;
                              font-weight: normal;
                              margin-left: 20px;
                            ">{{ item.name }}</td>
                          <td colspan="4" class="printDialogTextInTable" style="
                              color: black;

                              text-align: right;
                              font-weight: normal;
                            ">{{ item.value }}</td>
                        </tr>
                      </table>
                    </td>
                    <td colspan="2" style="
                        font-size: 19px;
                        color: #f99d20;
                        font-weight: normal;
                        border-right: 2px solid black;
                      ">
                      <table style="width: 100%; border: 0; padding-left: 0.8rem">
                        <tr style="height: 57px" v-for="(item, i) in Deductionlistforprint" :key="i">
                          <td v-if="item.name != 'Social Security' &&
                            item.name != 'Tax' &&
                            item.name != 'PVD'
                            " colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
    item.name }}</td>
                          <td v-if="item.name == 'Social Security'" colspan="4" class="printDialogTextInTable"
                            style="color: black; font-weight: normal">{{ $t("social_security") }}</td>
                          <td v-if="item.name == 'Tax'" colspan="4" class="printDialogTextInTable"
                            style="color: black; font-weight: normal">{{ $t("witholding") }}</td>
                          <td v-if="item.name == 'PVD'" colspan="4" class="printDialogTextInTable"
                            style="color: black; font-weight: normal">{{ $t("provident_fund") }}</td>
                          <td colspan="4" class="printDialogTextInTable" style="
                              color: black;

                              text-align: right;
                              font-weight: normal;
                            ">{{ item.value }}</td>
                        </tr>
                      </table>
                    </td>
                    <td colspan="2" style="
                        font-size: 19px;
                        color: #f99d20;
                        font-weight: normal;
                        border-right: 2px solid black;
                      ">
                      <table style="
                          width: 100%;
                          margin-top: -3rem;
                          border: 0;
                          padding-left: 0.8rem;
                        ">
                        <tr>
                          <td colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
                            $t("ytdincome") }}</td>
                          <td colspan="4" class="printDialogTextInTable" style="
                              color: black;
                              text-align: right;
                              font-weight: normal;
                              padding-right: 0.8rem;
                            ">{{ YTDIncome }}</td>
                        </tr>
                        <tr>
                          <td colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
                            $t("ytdwitholding") }}</td>
                          <td colspan="4" class="printDialogTextInTable" style="
                              color: black;
                              text-align: right;
                              font-weight: normal;
                              padding-right: 0.8rem;
                            ">{{ YTDwithholdingtax }}</td>
                        </tr>
                        <tr>
                          <td colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
                            $t("accmulatedssf") }}</td>
                          <td colspan="4" class="printDialogTextInTable" style="
                              color: black;
                              text-align: right;
                              font-weight: normal;
                              padding-right: 0.8rem;
                            ">{{ AccumulateSSF }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" class="printDialogTextInTable" style="
                      border: 1px solid black;
                      background-color:#c2c2c2;
                      color: #444444;
                      padding-left: 0.8rem;
                    ">{{ $t("TotalIncome") }}</td>
                    <td colspan="1" class="printDialogTextInTable text-right" style="
                        border: 1px solid black;
                        color: #444444;
                        padding-right: 0.8rem;
                      ">{{ totalincomeforprint }}</td>
                    <td colspan="1" class="printDialogTextInTable" style="
                        border: 1px solid black;
                        padding-left: 0.8rem;
                        color: #444444;
                        background-color: #c2c2c2;
                      ">{{ $t("TotalDeductions") }}</td>
                    <td colspan="1" class="printDialogTextInTable text-right" style="
                        border-width: 1px 1px 1px 0px;
                        border-color: black;
                        border-style: solid;
                        padding-right: 0.8rem;
                        color: #444444;
                      ">{{ totaldeductionforprint }}</td>
                    <td colspan="3" class="printDialogTextInTable" style="
                        border-width: 0px 1px 0px 0px;
                        border-color: black;
                        border-style: solid;
                        color: #444444;
                        padding-left: 0.8rem;
                      "></td>
                  </tr>
                  <tr>
                    <td colspan="3" class="printDialogTextInTable text-right" style="
                        color: #444444;
                        border-width: 0px 0px 1px 1px;
                        border-color: black;
                        border-style: solid;
                        background-color: #c2c2c2;
                        padding-right: 0.8rem;
                      ">{{ $t("netpay") }}</td>
                    <td class="printDialogTextInTable text-right" style="
                        color: #444444;
                        border-width: 0px 1px 1px 1px;
                        border-color: black;
                        border-style: solid;
                        padding-right: 0.8rem;
                      ">{{ netincomeforprint }}</td>
                    <td colspan="3" class="printDialogTextInTable" style="
                        border-width: 0 1px 1px 0px;
                        border-color: black;
                        border-style: solid;
                        color: #444444;
                        padding-left: 0.8rem;
                      "></td>
                  </tr>
                </table>
              </div>
            </div>
          </v-card>
        </v-tab-item>

        <!-- employee Tab -->
        <v-tab-item :key="2" value="two">
          <v-card>
            <v-row class="pt-5">
              <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5" class>
                <div style="max-width: 500px; margin-right: 0px">
                  <v-text-field class="search ml-4" color="#F99D20" v-model="Searchforemplist" :textFieldProps="{
                    placeholder: 'Search',
                    color: '#F99D20',
                    dense: false,
                  }" dense outlined :placeholder="$t('SearchEmployeeIDDepartment')" width="150px"></v-text-field>
                </div>
              </v-col>
              <v-col cols="2" sm="2" lg="2" md="2">
                <v-btn color="#F99D20" @click="searchReportemplist()" class="white--text text-capitalize ml-2" width="120"
                  max-height="35">
                  <v-icon class="pr-1">mdi-magnify</v-icon>Search
                </v-btn>
              </v-col>
              <v-spacer v-if="!$vuetify.breakpoint.md || !$vuetify.breakpoint.sm"></v-spacer>
              <!-- <v-col cols="2" sm="1" lg="2" md="2"></v-col> -->
              <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3" class="pt-0 ml-1">
                <div class="recalculateBtnForEmployeeTab">
                  <!-- <v-btn color="#F99D20" class="orange--text text-capitalize mt-2 mr-5" max-height="35" outlined
                    @click="Updatestafftimedialog = true" :disabled="disabledAddPayment">{{ $t("Updatetime") }}</v-btn> -->
                  <v-btn color="#F99D20" class="white--text mr-2 mt-3 text-capitalize" max-height="35"
                    @click="recalculateDialog = true" :disabled="disabledAddPayment">{{ $t("Recaculate") }}</v-btn>
                </div>
              </v-col>
            </v-row>
            <div style="
                margin-top: 30px;
                margin-left: 30px;
                margin-bottom: 100px;
                margin-right: 30px;
              ">
              <v-data-table :items="EditpaymentDuesetup" :page.sync="page" :items-per-page="itemsPerPage1"
                @page-count="pageCount = $event" hide-default-footer hide-default-header style="margin-bottom: 30px"
                :mobile-breakpoint="0">
                <template v-slot:header="{ props: { } }">
                  <thead>
                    <tr>
                      <th>
                        <!-- <input type="checkbox" v-model="selectAll_EmpTab2"/> -->
                        <v-checkbox class="custom-checkbox" color="#F99D20" v-model="selectAll_EmpTab2" ></v-checkbox>
                      </th>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("EmployeeName") }}</th>
                      <th>{{ $t("position") }}</th>
                      <th>{{ $t("department") }}</th>
                      <th>{{ $t("status") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="padding-top: 20px; vertical-align: middle">
                      <!-- <input type="checkbox" v-model="checkemplistforeditstaff" :value="item.empID" style="margin-left: 5px" /> -->
                      <v-checkbox class="custom-checkbox" color="#F99D20" v-model="checkemplistforeditstaff" :value="item.empID"></v-checkbox>
                    </td>
                    <td @click="Editdetail(item.empID)">
                      <v-row style="align-items: center; text-align: center">
                        <p style="margin-top: 20px; margin-left: 20px">{{ item.employee_Code }}</p>
                      </v-row>
                    </td>
                    <td @click="Editdetail(item.empID)">{{ item.empname }}</td>
                    <td @click="Editdetail(item.empID)">{{ item.positioinName }}</td>
                    <td @click="Editdetail(item.empID)">{{ item.department_Name }}</td>
                    <td @click="Editdetail(item.empID)">{{ item.active == 1 ? "Active" : "In Active" }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="text-right">
              +
              <v-row justify="end" class="ml-5 mt-5 mr-5 pb-5">
                <v-col cols="12" md="3" sm="4">
                  <v-pagination v-model="page" :length="pageCount" color="#F99D20" :total-visible="6"></v-pagination>
                </v-col>
                <v-col cols="12" md="2" sm="2" class="mt-1">
                  <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage1"
                    :items="items" @input="itemsPerPage1 = parseInt($event, 10)"></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-tab-item>

        <!-- payment Slip Details Tab -->
        <v-tab-item :key="3" value="three">
          <v-card>
            <v-card-text>
              <div>
                <v-row style="margin-right: 1rem">
                  <v-col>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn class="white--text text-capitalize ma-0" color="#F99D20" @click="prevemp()"
                        :disabled="disablebtn">
                        <v-icon small>mdi-chevron-left</v-icon>
                      </v-btn>

                      <p class="mt-2 ml-2 mr-2">{{ myemp }} of {{ totallist }}</p>

                      <v-btn class="white--text text-capitalize ma-0" color="#F99D20" @click="nextemp()"
                        :disabled="disablebtn">
                        <v-icon small>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-row>
                    <v-row class="mt-5">
                      <v-spacer></v-spacer>
                      <p class="mr-2">{{ $t("Update_date") }}</p>
                      <p style="font-size: 17px; color: #444444">{{ computedDateFormatted6 }}</p>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -4rem">
                  <v-col>
                    <label style="font-size: 21px; color: #444444">
                      {{
                        $t("Payslip")
                      }}
                    </label>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="2" lg="2" sm="2">
                    <label for>{{ $t("companies") }}</label>
                  </v-col>
                  <v-col>
                    <p>{{ payslipdetail.company }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="2" lg="2" sm="2" cols="4" class="ma-0">
                    <label for>{{ $t("WorkingDay") }}</label>
                  </v-col>
                  <v-col md="5" cols="6" class="ma-0 pt-0 pr-0">
                    <v-row>
                      <div style="max-width: 170px; margin-top:15px;">
                        <v-col class="ma-0 pt-0 pr-0">
                          <v-text-field v-model="payslipdetail.workingstartdate" color="#F99D20" height="10px" outlined
                            dense fluid>
                            <template v-slot:append>
                              <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </div>
                      <div style="max-width: 170px; margin-top:15px;">
                        <v-col class="ma-0 pt-0 pr-0">
                          <v-text-field class="pl-1" v-model="payslipdetail.workingenddate" color="#F99D20" outlined
                            dense>
                            <template v-slot:append>
                              <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col>
                    <label style="font-size: 21px; color: #444444">{{ $t("PayslipDetails") }}</label>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="2" lg="2" sm="2">
                    <label for>{{ $t("EmployeeID") }}</label>
                  </v-col>
                  <div style="max-width: 260px">
                    <v-col>
                      <p style="font-size: 17px; color: #444444">{{ payslipdetail.empcode }}</p>
                    </v-col>
                  </div>
                </v-row>

                <v-row>
                  <v-col md="2" lg="2" sm="2">
                    <label for>{{ $t("EmployeeName") }}</label>
                  </v-col>
                  <div style="max-width: 260px">
                    <v-col>
                      <p style="font-size: 17px; color: #444444">{{ payslipdetail.empname }}</p>
                    </v-col>
                  </div>
                </v-row>

                <v-row>
                  <v-col md="2" lg="2" sm="2">
                    <label for>{{ $t("position") }}</label>
                  </v-col>
                  <div style="max-width: 260px">
                    <v-col>
                      <p style="font-size: 17px; color: #444444">{{ payslipdetail.position }}</p>
                    </v-col>
                  </div>
                </v-row>
              </div>

              <div>
                <v-row>
                  <v-col>
                    <label style="font-size: 21px; color: #444444;">
                      {{$t("RegularEarnings")}}
                    </label>
                  </v-col>
                </v-row>

                <v-row class="pb-0">
                  <div style="max-width: 280px" v-show="empButton == 2">
                    <v-col class="pb-0">
                      <v-row>
                        <v-col cols="5">
                          <label for>
                            {{
                              showworkingdays == "Working Days"
                              ? $t("WorkingDays")
                              : $t("WorkingHours")
                            }}
                          </label>
                        </v-col>
                        <v-col cols="3" class="ma-0 pt-0 pb-0">
                          <v-text-field :disabled="disableworkingday" @keypress="onlyInteger($event,payslipdetail.workingdays,'workingdays')" color="#F99D20" @focus="selectText($event, payslipdetail.workingdays, 'payslipdetail.workingdays')"
                            v-model="payslipdetail.workingdays" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <p style="font-size: 17px; color: #444444">
                            {{
                              daymonth == "Days(s)" ? $t("Day(s)") : "Hour(s)"
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>

                  <div style="max-width: 260px">
                    <v-col class="pb-0" v-show="showrateperhour">
                      <v-row>
                        <v-col cols="5" class="pr-0">
                          <label for>Rate/hour</label>
                        </v-col>
                        <v-col cols="4" class="ma-0 pt-0 pl-0">
                          <v-text-field @keypress="onlyNumber($event,payslipdetail.rateperhour,'rateperhour')" @change="gettotalsalary()" color="#F99D20"
                            v-model="payslipdetail.rateperhour" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="3" class="pl-0">
                          <p style="font-size: 17px; color: #444444">{{ $t("THB") }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pb-0" v-show="showrateperday">
                      <v-row>
                        <v-col cols="4">
                          <label for>Rate/days</label>
                        </v-col>
                        <v-col cols="4" class="ma-0 pt-0">
                          <v-text-field @keypress="onlyNumber($event,payslipdetail.rateperday,'rateperday')" @change="gettotalsalarybydays()" color="#F99D20"
                            v-model="payslipdetail.rateperday" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <p style="font-size: 17px; color: #444444">THB</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>

                  <div style="max-width: 260px">
                    <v-col class="pb-0">
                      <v-row>
                        <v-col cols="3">
                          <label for>{{ $t("salary") }}</label>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0">
                          <v-text-field readonly v-model="payslipdetail.regularsalary" outlined color="#F99D20" dense></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <p style="font-size: 17px; color: #444444">{{ $t("THB") }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>

                  <div style="max-width: 260px" v-show="empButton == 2">
                    <v-col class="pb-0">
                      <v-row>
                        <v-col cols="3">
                          <label for>YTD</label>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0">
                          <v-text-field v-model="payslipdetail.YTD" outlined readonly color="#F99D20" dense></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <p style="font-size: 17px; color: #444444">{{ $t("THB") }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 260px; margin-left:14px;">
                    <v-btn icon @click="disableworkingday = false" v-show="empButton == 2">
                      <img class="editIcon" :src="imagesIcon.edit" alt="editIcon"/>
                      <!-- <img :src="images.edit" /> -->
                    </v-btn>
                    <v-btn style="background: #f99d20" class="text-capitalize white--text ml-4" height="30px"
                      width="120px" @click="workingdayhistorydialog = true">{{ $t("payrollhistory") }}</v-btn>
                  </div>
                </v-row>

                <v-row class="pt-4">
                  <v-col cols="10" sm="12">
                    <v-row class="mb-3">
                      <v-col cols="2" xl="1" lg="1" md="2" sm="5">
                        <label for>{{ $t("Overtime") }}</label>
                      </v-col>

                      <v-col cols="8" xl="8" lg="8" md="8" sm="8" class="ma-0 pt-0">
                        <v-data-table hide-default-footer hide-default-header no-data-text :items="OTList"
                          :mobile-breakpoint="0" disable-pagination class="OTtable">
                          <template v-slot:header="{ props: { } }">
                            <thead class="OTheader">
                              <tr class="Rheader">
                                <th style="max-width: 250px !important" scope="col">{{ $t("Code") }}</th>
                                <th class="text-center" scope="col">{{ $t("oTHours") }}</th>
                                <th class="text-center" scope="col">{{ $t("OTRate") }}</th>
                                <th class="text-center" scope="col">{{ $t("OTPay") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ $t("OT1") }}</td>
                                <td>
                                  <v-text-field v-model="payslipdetail.OThours" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-2 OTHone"
                                    :class="{ 'OTHoneactive': disableOT === false }" @keypress="onlyInteger($event,payslipdetail.OThours,'OThours')" @focusout="handleFocusout(payslipdetail.OThours,'OThours')" @focus="selectText($event, payslipdetail.OThours, 'payslipdetail.OThours')"
                                    v-on:keyup="changerate(1)" :disabled="disableOT"></v-text-field>
                                </td>
                                <td class="text-end">
                                  <v-text-field v-model="payslipdetail.OTrate" color="#F99D20"
                                    outlined dense reverse hide-details class="custom-text-field-1 OTone OTHone"
                                     readonly></v-text-field>
                                </td>
                                <td class="text-end">
                                  <v-text-field v-model="payslipdetail.OTPay" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone"
                                    readonly></v-text-field>
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("OT2") }}</td>
                                <td>
                                  <v-text-field v-model="payslipdetail.OThours2" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-2 OTHone"
                                    :class="{ 'OTHoneactive': disableOT === false }" @keypress="onlyInteger($event,payslipdetail.OThours2,'OThours2')" @focusout="handleFocusout(payslipdetail.OThours2,'OThours2')" @focus="selectText($event, payslipdetail.OThours2, 'payslipdetail.OThours2')"
                                    v-on:keyup="changerate(2)" :disabled="disableOT"></v-text-field>
                                </td>
                                <td class="text-end">
                                  <v-text-field v-model="payslipdetail.OTrate2" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone" readonly></v-text-field>
                                </td>
                                <td class="text-end">
                                  <v-text-field v-model="payslipdetail.OTPay2" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone" readonly></v-text-field>
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("OT3") }}</td>
                                <td>
                                  <v-text-field v-model="payslipdetail.OThours3" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-2 OTHone"
                                    :class="{ 'OTHoneactive': disableOT === false }" @keypress="onlyInteger($event,payslipdetail.OThours3,'OThours3')" @focusout="handleFocusout(payslipdetail.OThours3,'OThours3')" @focus="selectText($event, payslipdetail.OThours3, 'payslipdetail.OThours3')"
                                    v-on:keyup="changerate(3)" :disabled="disableOT"></v-text-field>
                                </td>
                                <td>
                                  <v-text-field v-model="payslipdetail.OTrate3" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone" readonly></v-text-field>
                                </td>
                                <td class="text-end">
                                  <v-text-field v-model="payslipdetail.OTPay3" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone" readonly></v-text-field>
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("OT4") }}</td>
                                <td>
                                  <v-text-field v-model="payslipdetail.OThours4" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-2 OTHone"
                                    :class="{ 'OTHoneactive': disableOT === false }" @keypress="onlyInteger($event,payslipdetail.OThours4,'OThours4')" @focusout="handleFocusout(payslipdetail.OThours4,'OThours4')" @focus="selectText($event, payslipdetail.OThours4, 'payslipdetail.OThours4')"
                                    v-on:keyup="changerate(4)" :disabled="disableOT"></v-text-field>
                                </td>
                                <td class="text-end right" style="text-align: right; margin-right: 1em;">
                                  <v-text-field v-model="payslipdetail.OTrate4" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone" readonly></v-text-field>
                                </td>
                                <td class="text-end">
                                  <v-text-field v-model="payslipdetail.OTPay4" color="#F99D20" outlined dense reverse
                                    hide-details class="custom-text-field-1 OTone OTHone" readonly></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot class="OTfooter">
                              <tr class="Rheader">
                                <td></td>
                                <td></td>
                                <td class="text-end">{{ $t("TotalOTpay") }}</td>
                                <td>
                                  <v-text-field v-model="payslipdetail.OTPayTotal" color="#F99D20" outlined dense
                                    background-color="#FFFFFF" reverse hide-details class="bwht" readonly></v-text-field>
                                </td>
                              </tr>
                            </tfoot>
                          </template>
                        </v-data-table>
                      </v-col>

                      <v-col cols="2" xl="2" lg="3" md="2" sm="4">
                        <div style="max-width: 280px">
                          <v-btn icon @click="disableOT = false">
                            <img class="editIcon" :src="imagesIcon.edit" alt="editIcon"/>
                          </v-btn>
                          <v-btn style="background: #f99d20" class="text-capitalize white--text ml-4" height="30px" width="120px" @click="OTdayhistorydialog = true">
                            {{ $t("payrollhistory") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>

              <div>
                <v-row>
                  <v-col>
                    <label style="font-size: 21px; color: #444444">{{ myadd ? $t("AdditionalEarnings") : "" }}</label>
                  </v-col>
                </v-row>

                <v-row v-for="(item, i) in additionlist" :key="i" class="income">
                  <v-col md="2" sm="2" align="center">
                    <label style="color: #8a8c8e; font-size: 15px">{{ $t("taxcalculate") }}</label>
                    <img class="mt-3 d-flex" style="height: 23px; width: 23px"
                      :src="item.Tax == true ? rwimages.Right : rwimages.Wrong" />
                  </v-col>
                  <v-col md="3" sm="2" align="center">
                    <label style="color: #8a8c8e; font-size: 15px">{{ $t("sscalculate") }}</label>
                    <img class="mt-3 d-flex" style="height: 23px; width: 23px"
                      :src="item.SSF == true ? rwimages.Right : rwimages.Wrong" />
                  </v-col>
                  <v-col md="2" sm="3">
                    <p>{{ $t("Earnings") }}</p>
                    <v-select :items="allincomelist" :disabled="status == 'Paid'" item-value="income_Type_ID" v-model="item.income_Type" @change="changeincometype(item.income_Type, item.id), Addadditional(item.amount, item.id)" style="font-size: 19px;
                        color: #f99d20;
                        margin-top: 0.5rem;
                      " append-icon="mdi-chevron-down" color="#F99D20" outlined dense>
                      <template v-slot:selection="data">
                        {{
                          thethaihead == true
                          ? data.item.income_Type_Name
                          : data.item.income_Type_Name_Eng
                        }}
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              {{
                                thethaihead == true
                                ? data.item.income_Type_Name
                                : data.item.income_Type_Name_Eng
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="2" sm="3">
                    <label for>{{ $t("amount") }}</label>
                    <v-text-field style="margin-top: 0.5rem" v-model="item.amount" outlined @keypress="onlyNumberAdditionalEarnings($event, item.amount, item.id)" @focus="selectTextAddIncome($event,item.id)" dense color="#F99D20" @focusout="handleFocusoutAdd(item.id)" v-on:keyup="addlistcomma($event, item.id)"  v-on:click="handleClickItemAdd(item.id)" @input="intitudeHandlerAdd($event,item.id)"
                    >{{ item.amount }}</v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="3">
                    <v-btn :disabled="status == 'Paid'" v-show="item.Showtrash" @click="Removeincome(item.id)" icon class="mt-9">
                      <img class="deleteIcon" :src="imagesIcon.delete" alt="deleteIcon"/>
                    </v-btn>
                    <v-btn :disabled="status == 'Paid'" v-show="item.Showplus" @click="AddIncome()" icon class="mt-9 ml-5">
                      <img class="plusIcon" :src="imagesIcon.plusIcon" alt="plusIcon"/>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mb-6">
                  <v-col cols="6" md="2" class="pa-0 pl-2">
                    <p style="text-decoration: underline">{{ $t("TotalIncome(THB)") }}</p>
                  </v-col>
                  <v-col cols="6" md="3" class="pa-0">
                    <p>{{ payslipdetail.totalincome }}</p>
                  </v-col>
                </v-row>

                <v-row class="mb-6">
                  <v-col cols="6" md="3" class="pa-0 pl-2">
                    <p style="text-decoration: underline">{{ $t("calculatetaxforpayroll") }}</p>
                  </v-col>
                  <v-col cols="6" md="3" class="pa-0">
                    <p>{{ payslipdetail.totalincomecalculatetax }}</p>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div style="margin-top: 1rem">
                <v-row>
                  <v-col>
                    <label style="font-size: 21px; color: #444444">
                      {{
                        $t("Deductions")
                      }}
                    </label>
                  </v-col>
                </v-row>

                <v-row>
                  <div style="max-width: 300px">
                    <v-col>
                      <v-row>
                        <v-col cols="6" class="pr-0">
                          <label for>{{ $t("NotStamp(days)") }}</label>
                        </v-col>
                        <v-col cols="6" class="ma-0 pt-0 pl-0">
                          <v-text-field @keypress="onlyInteger($event,payslipdetail.Notstampdays,'Notstampdays')" @focusout="handleFocusout(payslipdetail.Notstampdays,'Notstampdays')" v-on:keyup="changerate()" :disabled="disablestampday" color="#F99D20" @focus="selectText($event, payslipdetail.Notstampdays, 'payslipdetail.Notstampdays')"
                            v-model="payslipdetail.Notstampdays" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 300px" v-show="empButton == 1">
                    <v-col>
                      <v-row>
                        <v-col cols="6" class="pr-0">
                          <label for>{{ $t("NotStampRate") }}</label>
                        </v-col>
                        <v-col cols="3" class="ma-0 pt-0 pl-0">
                          <v-text-field v-model="payslipdetail.Notstamprate" @keypress="onlyNumber($event,payslipdetail.Notstamprate,'payslipdetail.Notstamprate')" color="#F99D20" outlined dense v-on:keyup="commasub(1)" @focus="selectText1($event, payslipdetail.Notstamprate, 'Notstamprate')" @focusout="handleFocusoutNotStamp(payslipdetail.Notstamprate)" v-on:click="handleClickModel('Notstamprate')"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <p style="font-size: 17px; color: #444444">{{ $t("THB") }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 380px" v-show="empButton == 1">
                    <v-col>
                      <v-row>
                        <v-col cols="6" class="pr-0">
                          <label for>{{ $t("NotStampDeduction") }}</label>
                        </v-col>
                        <v-col cols="3" class="ma-0 pt-0 pl-0">
                          <v-text-field outlined readonly v-model="payslipdetail.NotstampDeduction" color="#F99D20" dense></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <p style="font-size: 17px; color: #444444">{{ $t("THB") }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 280px" v-show="empButton == 1">
                    <v-btn icon @click="disablestampday = false">
                      <img class="editIcon" :src="imagesIcon.edit" alt="editIcon"/>
                    </v-btn>
                    <v-btn style="background: #f99d20" class="text-capitalize white--text ml-4" height="30px"
                      width="120px" @click="Notstampdayhistorydialog = true">
                      {{ $t("payrollhistory") }}
                    </v-btn>
                  </div>
                </v-row>
                <v-row>
                  <div style="max-width: 280px">
                    <v-col>
                      <v-row>
                        <v-col cols="4">
                          <label for>{{ $t("Late(days)") }}</label>
                        </v-col>
                        <v-col cols="8" class="ma-0 pt-0">
                          <v-text-field v-on:keyup="changerate()" @keypress="onlyInteger($event,payslipdetail.latedays,'latedays')" @focusout="handleFocusout(payslipdetail.latedays,'latedays')" :disabled="disablelateday" color="#F99D20" @focus="selectText($event, payslipdetail.latedays, 'payslipdetail.latedays')"
                            v-model="payslipdetail.latedays" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 280px">
                    <v-col>
                      <v-row>
                        <v-col cols="4">
                          <label for>{{ $t("LateRate") }}</label>
                        </v-col>
                        <v-col cols="4" class="ma-0 pt-0">
                          <v-text-field @keypress="onlyNumber($event,payslipdetail.laterate)" v-model="payslipdetail.laterate" v-on:keyup="commasub(2)" color="#F99D20" @focus="selectText1($event, payslipdetail.laterate, 'laterate')" @focusout="handleFocusoutlateR(payslipdetail.laterate)" v-on:click="handleClickModel('laterate')"
                            outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <p style="font-size: 17px; color: #444444">{{ $t("THB") }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 300px">
                    <v-col>
                      <v-row>
                        <v-col cols="6" class="pr-0">
                          <label for>{{ $t("LateDeduction") }}</label>
                        </v-col>
                        <v-col cols="3" class="ma-0 pt-0 pl-0 pr-0">
                          <v-text-field v-model="payslipdetail.latededuction" outlined readonly color="#F99D20" dense></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <p style="font-size: 17px; color: #444444">
                            {{ $t("THB") }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                  <div style="max-width: 280px">
                    <v-btn icon @click="disablelateday = false">
                      <img class="editIcon" :src="imagesIcon.edit" alt="editIcon"/>
                    </v-btn>
                    <v-btn style="background: #f99d20" class="text-capitalize white--text ml-4" height="30px" width="120px" @click="Latedayhistorydialog = true">
                      {{ $t("payrollhistory") }}
                    </v-btn>
                  </div>
                </v-row>
              </div>

              <v-row style="margin-top: 0.5rem">
                <v-col>
                  <label style="font-size: 21px; color: #444444">{{ $t("AdditionalDeductions") }}</label>
                </v-col>
              </v-row>

              <v-row style="margin-top: -1rem">
                <v-col cols="5" md="3">
                  <label for>{{ $t("Deductions") }}</label>
                </v-col>
                <v-col cols="5" md="3">
                  <label for>{{ $t("amount") }}</label>
                </v-col>
              </v-row>
              
              <v-row v-for="(myitem, i) in additionaldeduction" :key="i">
                <v-col cols="5" lg="3" xl="3" sm="3" xs="3">
                  <v-text-field :placeholder="$t('Loan')" outlined dense color="#F99D20" @keypress="checkItemError(i)"
                    v-model="myitem.name" :error-messages="myitem.errormessagededuname"></v-text-field>
                  <p v-if="myitem.errormessagededuname" class="red--text mb-1">{{myitem.errormessagededuname}}</p>
                </v-col>
                <v-col cols="5" lg="3" xl="3" sm="3" xs="3">
                  <v-text-field outlined @keypress="onlyNumber($event,myitem.amount)" @change="thedot($event, myitem.id)" color="#F99D20" @focus="selectTextSubIncome($event,myitem.id)" @focusout="handleFocusoutSub(myitem.id)" v-on:keyup="sublistcomma($event, myitem.id)"  v-on:click="handleClickItemSub(myitem.id)" @input="intitudeHandlerSub($event,myitem.id)"
                    v-model="myitem.amount" dense></v-text-field>
                </v-col>
                <v-col cols="2" lg="3" xl="3" sm="3" xs="3">
                  <v-btn v-show="myitem.showdelete" @click="removededuction()" style="width: 30px" icon>
                    <img class="deleteIcon" :src="imagesIcon.delete" alt="deleteIcon"/>
                  </v-btn>
                  <v-btn style="width: 30px" @click="adddeduction()" v-show="myitem.show" icon class="ml-3">
                    <img class="plusIcon" :src="imagesIcon.plusIcon" alt="plusIcon"/>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3" xl="3" sm="3" xs="3" class="pt-0">
                  <v-checkbox class="custom-checkbox cb-cus" :label="combinedLabel" color="#F99D20" v-model="payslipdetail.checkpersonaltax" @change="checkpersonaltaxon(1)"></v-checkbox>
                  <!-- <input type="checkbox" v-model="payslipdetail.checkpersonaltax" @change="checkpersonaltaxon(1)"
                    style="float: left" class="mr-2 mt-1" width="200" /> -->
                  <!-- <v-checkbox v-model="payslipdetail.checkpersonaltax" color="orange" hide-details @change="checkpersonaltaxon()"
                    style="margin-top:-0.5rem;"></v-checkbox> -->
                  <!-- <p style="float: left">{{ $t("PersonalTax/Month") }}</p>
                  <p style="float: left">{{ $t("PaySlipDetail") }}</p> -->
                </v-col>
                <v-col cols="12" lg="3" xl="3" sm="3" xs="3">
                  <v-text-field readonly v-model="payslipdetail.personaltax" style="margin-top: 0rem" outlined color="#F99D20"
                    dense></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3" xl="3" sm="3" xs="3" class="pt-0">
                  <v-checkbox class="custom-checkbox"
                  :label="$t('social_security')"
                   color="#F99D20" v-model="payslipdetail.checksocialsecurity" @change="checksocail(1)"></v-checkbox>
                  <!-- <input type="checkbox" color="orange" v-model="payslipdetail.checksocialsecurity" @change="checksocail(1)"
                    style="float: left" class="mr-2 mt-1" width="200" /> -->
                  <!-- <p style="float: left">{{ $t("social_security") }}</p> -->
                </v-col>
                <v-col cols="12" lg="3" xl="3" sm="3" xs="3">
                  <v-text-field readonly v-model="payslipdetail.socialsecurity" style="margin-top: 0rem" outlined color="#F99D20"
                    dense></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3" xl="3" sm="3" xs="3" class="pt-0">
                  <!-- <v-col cols="3" lg="3" xl="3" sm="3" xs="3"></v-col> -->
                  <v-checkbox class="custom-checkbox" :label="$t('provident_fund')" color="#F99D20" v-model="payslipdetail.checkprovident" @change="checkprovi()"></v-checkbox>

                  <!-- <input type="checkbox" v-model="payslipdetail.checkprovident" @change="checkprovi()" style="float: left"
                    class="mr-2 mt-1" width="200" />
                  <p style="float: left">{{ $t("provident_fund") }}</p> -->
                </v-col>
                <v-col cols="12" lg="3" xl="3" sm="3" xs="3">
                  <v-text-field readonly v-model="payslipdetail.providentfund" style="margin-top: 0rem" outlined color="#F99D20"
                    dense></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -1rem" class>
                <v-col>
                  <v-row>
                    <div style="max-width: 300px" class="mb-3">
                      <v-col>
                        <p style="
                            font-size: 17px;
                            color: #444444;
                            text-decoration: underline;
                          ">{{ $t("TotalDeductions") }} {{ $t("(THB)") }}</p>
                      </v-col>
                    </div>
                    <div style="max-width: 300px" class="mb-3">
                      <v-col>
                        <p style="font-size: 17px; color: #444444">{{ payslipdetail.totaldeduction }}</p>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider></v-divider>
              <div>
                <v-row>
                  <v-col>
                    <v-row>
                      <div style="max-width: 300px" class="mt-3">
                        <v-col>
                          <p style="
                              font-size: 17px;
                              color: #444444;
                              text-decoration: underline;
                            ">{{ $t("NetIncome(THB)") }}</p>
                        </v-col>
                      </div>
                      <div style="max-width: 300px" class="mt-3">
                        <v-col>
                          <p style="font-size: 17px; color: #444444">{{ payslipdetail.totalnetincome }}</p>
                        </v-col>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions class="pb-6">
              <v-spacer></v-spacer>
              <v-btn color="#F99D20" class="mr-3 text-capitalize black--text fix-btn" width="150" max-height="35"
                :disabled="disabledAddPayment" style="font-family: kanit; font-weight: normal"
                @click="Updateemp(payslipdetail.empID, false)">{{ $t("update") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!-- payroll option dialog -->
    <v-dialog v-model="optionDialog" persistent max-width="750">
      <v-card style="border-radius: 15px">
        <v-card-title class="justify-center createPayroll">
          <p>{{ $t("CreatePayroll") }}</p> 
          
        </v-card-title>
        <v-card-text>
          <v-row class="ml-2 mt-3">
            <p class="payrolloption">{{ $t("PayrollOption") + ":" }}</p>
          </v-row>
          <v-row class="mt-3 ml-2">
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="justify-space-around d-flex pl-0">
              <v-btn outlined @click="payrollOpen(1)" :class="{ active: activeButton === 1 }"
                class="payrollOpti justify-space-around">
                <!-- <v-img src="@/assets/images/createPeriod.svg" class="periodimg"></v-img> -->
                <p class="text-capitalize font-weight-light">{{ $t("CreatePeriod") }}</p>
              </v-btn>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="justify-space-around d-flex Optipay pl-0">
              <v-btn outlined @click="payrollOpen(2)" :class="{ active: activeButton === 2 }"
                class="payrollOpti justify-space-around">
                <!-- <v-img src="@/assets/images/Predefined.svg" class="periodimg"></v-img> -->
                <p class="text-capitalize font-weight-light">{{ $t("Predefined") }}</p>
              </v-btn>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="justify-center d-flex Optipay pl-0">
              <v-btn outlined @click="payrollOpen(3)" :class="{ active: activeButton === 3 }"
                class="payrollOpti justify-space-around">
                <!-- <v-img src="@/assets/images/Customize.svg" class="periodimg"></v-img> -->
                <p class="text-capitalize font-weight-light">{{ $t("Customize") }}</p>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Create a period -->
          <v-row class="ml-2" v-if="activeButton === 1">
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0 pb-1">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                    margin-top: 0.5rem;
                  " class="payrolltext font-weight-light">{{ $t("PayPeriodStart") }}</p>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="pl-0 pt-0">
                <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu11">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="coDPSDate" autocomplete="off" class="kanit-medium" outlined readonly dense
                      color="#F99D20" persistent-hint v-on="on">
                      <template v-slot:append>
                        <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="creating.PayPerStartDate" no-title @input="menu11 = false"></v-date-picker>
                </v-menu> -->
                <date-picker 
                  v-bind:clearable="false"
                  readonly 
                  format="DD/MM/YYYY"
                  value-type="YYYY-MM-DD"
                  :style="{ width: '100%' }"  
                  v-model="creating.PayPerStartDate" 
                  :placeholder="coDPSDate"
                  class="createpayroll custom-vuedatepicker1"
                />
              </v-col>
            </v-row>
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0 pt-0 pb-1">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="payrolltext font-weight-light">{{ $t("PayPeriodEnd") }}</p>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="pl-0 pt-0">
                <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu12">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="coDPEDate" autocomplete="off" class="kanit-medium" outlined readonly dense
                      color="#F99D20" persistent-hint v-on="on">
                      <template v-slot:append>
                        <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                      </template>
                    </v-text-field>
                  </template>

                  <v-date-picker v-model="creating.PayPerEndDate" no-title @input="menu12 = false"></v-date-picker>
                </v-menu> -->
                <date-picker 
                  v-bind:clearable="false"
                  readonly 
                  format="DD/MM/YYYY"
                  value-type="YYYY-MM-DD"
                  :style="{ width: '100%' }"  
                  v-model="creating.PayPerEndDate" 
                  :placeholder="coDPEDate"
                  class="createpayroll custom-vuedatepicker1"
                />
              </v-col>
            </v-row>
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0 pt-1">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="font-weight-light">{{ $t("paymentType") }}</p>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="employeeType(1)" :class="{ active: empButton === 1 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/Monthlywage.svg" class="periodimg"></v-img> -->
                  <img class="createPayrollIcon1" :src="imagesIcon.payrollIcon1" alt="payrollIcon1"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("MonthlyWage") }}</p>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="employeeType(2)" :class="{ active: empButton === 2 }"
                  class="employeeType d-flex justify-start">
                    <img class="createPayrollIcon2" :src="imagesIcon.payrollIcon2" alt="payrollIcon2"/>
                  <!-- <v-img src="@/assets/images/Dailywage.svg" class="periodimg"></v-img> -->
                    <p class="text-capitalize font-weight-light employeeText">{{ $t("DailyWage") }}</p>
                </v-btn>
              </v-col>
            </v-row>
            <!-- Paid by -->
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0 pt-1">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="payrolltext font-weight-light">{{ $t("PaidBy") }}</p>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="Payby(0)" :class="{ active: paybyButton === 0 }"
                  class="employeeType d-flex justify-start">
                    <img class="createPayrollIcon3" :src="imagesIcon.payrollIcon3" alt="payrollIcon3"/>
                  <!-- <v-img src="@/assets/images/PayCash.svg" class="paidby"></v-img> -->
                    <p class="text-capitalize font-weight-light employeeText">{{ $t("PayByCash") }}</p>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="Payby(1)" :class="{ active: paybyButton === 1 }"
                  class="employeeType d-flex justify-start">
                    <img class="createPayrollIcon4" :src="imagesIcon.payrollIcon4" alt="payrollIcon4"/>
                  <!-- <v-img src="@/assets/images/bank-transfer 1.svg" class="paidby"></v-img> -->
                    <p class="text-capitalize font-weight-light employeeText">{{ $t("PayByTransfer") }}</p>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>

          <!-- Perdefined -->
          <v-row class="ml-2 mt-5" v-if="activeButton === 2">
            <p style="
                font-size: 16px;
                font-family: kanit;
              " class="payrolltext font-weight-thin">{{ $t("SelectPayrollCalculationForCreate") }}</p>
            <v-col cols="12" class="pl-0">
              <v-list>
                <label class="payrollTitle">
                  {{
                    $t("PayrollCalculationPeriod")
                  }}
                </label>

                <v-card v-for="item in paymentcalculationlist" :key="item.payroll_Period_ID" style="margin-top: 1rem"
                  elevation="0">
                  <v-list-group :prepend-icon="item.action" no-action class="listgp">
                    <template v-slot:activator>
                      <v-list-item-action></v-list-item-action>
                      <v-list-item-icon>
                        <v-icon color="#F99D20" style="margin-left: -3.5rem">mdi-calendar-month-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <!-- v-text="item.period_Name" -->
                        <v-list-item-title>
                          <p style="
                              color: #444444;
                              position: absolute;
                              margin-left: -30px;
                              margin-top: -8px;
                            ">{{ item.period_Name }}</p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item v-for="child in item.period" :key="child.period_ID">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-list-item-action>
                            <v-checkbox
                              class="custom-checkbox"
                              color="#F99D20"
                              :value="{item: item, child: child, id: child.period_ID}"
                              v-model="payrollInfo.selected"
                              @click.native.stop="justcheckone(child.period_ID)"
                            ></v-checkbox>
                            <!-- <input color="#F99D20" type="checkbox" :value="{
                              item: item,
                              child: child,
                            }" v-model="payrollInfo.selected" v-on:click="justcheckone()" /> -->
                          </v-list-item-action>
                          <p style="color: #f99d20; display: inline">{{ child.item }}</p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-card>
              </v-list>
            </v-col>
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="payrolltext font-weight-light">{{ $t("paymentType") }}</p>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="employeeType(1)" :class="{ active: empButton === 1 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/Monthlywage.svg" class="periodimg"></v-img> -->
                  <img class="createPayrollIcon1" :src="imagesIcon.payrollIcon1" alt="payrollIcon1"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("MonthlyWage")}}</p>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="employeeType(2)" :class="{ active: empButton === 2 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/Dailywage.svg" class="periodimg"></v-img> -->
                  <img class="createPayrollIcon2" :src="imagesIcon.payrollIcon2" alt="payrollIcon2"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("DailyWage")}}</p>
                </v-btn>
              </v-col>
            </v-row>
            <!-- Paid by -->
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0 pt-0">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="payrolltext font-weight-light">{{ $t("PaidBy") }}</p>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="Payby(0)" :class="{ active: paybyButton === 0 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/PayCash.svg" class="paidby"></v-img> -->
                  <img class="createPayrollIcon3" :src="imagesIcon.payrollIcon3" alt="payrollIcon3"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("PayByCash") }}</p>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="Payby(1)" :class="{ active: paybyButton === 1 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/bank-transfer 1.svg" class="paidby"></v-img> -->
                  <img class="createPayrollIcon4" :src="imagesIcon.payrollIcon4" alt="payrollIcon4"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("PayByTransfer") }}</p>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>

          <!-- Customize -->
          <v-row class="ml-2 mt-5" v-if="activeButton === 3">
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="payrolltext font-weight-light">{{ $t("paymentType") }}</p>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="employeeType(1)" :class="{ active: empButton === 1 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/Monthlywage.svg" class="periodimg"></v-img> -->
                  <img class="createPayrollIcon1" :src="imagesIcon.payrollIcon1" alt="payrollIcon1"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("MonthlyWage")}}</p>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="employeeType(2)" :class="{ active: empButton === 2 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/Dailywage.svg" class="periodimg"></v-img> -->
                  <img class="createPayrollIcon2" :src="imagesIcon.payrollIcon2" alt="payrollIcon2"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("DailyWage")}}</p>
                </v-btn>
              </v-col>
            </v-row>
            <!-- Paid by -->
            <v-row class="ml-0">
              <v-col cols="12" class="pl-0 pt-0">
                <p style="
                    font-size: 16px;
                    font-family: kanit;
                    font-weight: normal;
                  " class="payrolltext font-weight-light">{{ $t("PaidBy") }}</p>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="Payby(0)" :class="{ active: paybyButton === 0 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img src="@/assets/images/PayCash.svg" class="paidby"></v-img> -->
                  <img class="createPayrollIcon3" :src="imagesIcon.payrollIcon3" alt="payrollIcon3"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("PayByCash") }}</p>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="pl-0 pt-0">
                <v-btn outlined @click="Payby(1)" :class="{ active: paybyButton === 1 }"
                  class="employeeType d-flex justify-start">
                  <!-- <v-img :src="imagesIcon.payrollIcon4" class="createPayrollIcon4" alt="payrollIcon4"></v-img> -->
                  <img class="createPayrollIcon4" :src="imagesIcon.payrollIcon4" alt="payrollIcon4"/>
                  <p class="text-capitalize font-weight-light employeeText">{{ $t("PayByTransfer") }}</p>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>

        <v-divider class="payrolltext mt-8"></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn outlined color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="payrollOptionDialogCancel()">{{ $t("Cancel") }}</v-btn>
          <!-- @click="toggleDialog" -->
          <v-btn despressed color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            @click="createdPayroll()" style="
              font-family: kanit;
              font-size: 14px;
              font-weight: normal;
            ">{{ $t("CreatePayroll") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--create payroll dialog for ADD-->
    <v-dialog v-model="payrollDialog" persistent max-width="550">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="
              font-size: 23px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("CreatePayroll") }}</p>
          <p style="
              font-size: 19px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("SelectPayrollCalculationForCreate") }}</p>
        </v-card-title>
        <v-card-text>
          <v-list>
            <label>{{ $t("PayrollCalculationPeriod") }}</label>

            <v-card v-for="item in paymentcalculationlist" :key="item.payroll_Period_ID" style="margin-top: 1rem">
              <v-list-group :prepend-icon="item.action" no-action>
                <template v-slot:activator>
                  <v-list-item-action></v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="#F99D20" style="margin-left: -3.5rem">mdi-calendar-month-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <!-- v-text="item.period_Name" -->
                    <v-list-item-title>
                      <p style="
                          color: #f99d20;
                          position: absolute;
                          margin-left: -30px;
                          margin-top: -8px;
                        ">{{ item.period_Name }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="child in item.period" :key="child.period_ID">
                  <v-list-item-content>
                    <!-- v-text="child.item" -->
                    <v-list-item-title>
                      <v-list-item-action>
                        <v-checkbox
                          class="custom-checkbox"
                          color="#F99D20"
                          :value="{ item: item, child: child, id: child.period_ID }"
                          v-model="payrollInfo.selected"
                          @click.native.stop="justcheckone(child.period_ID)"
                        ></v-checkbox>
                        <!-- <input color="#F99D20" type="checkbox" :value="{
                          item: item,
                          child: child,
                        }" v-model="payrollInfo.selected" v-on:click="justcheckone()" /> -->
                      </v-list-item-action>
                      <p style="color: #f99d20; display: inline">{{ child.item }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-card>
          </v-list>

          <v-list>
            <label>{{ $t("PaidBy") }}</label>
            <v-radio-group v-model="ActiveRadioForEditPayDue">
              <v-card style="margin-top: -1rem">
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="0"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByCash") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
              <v-card class="mt-3">
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="1">></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-rotate-3d-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByTransfer") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
            </v-radio-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn commoncancelbtn" outlined text
            @click="createPayrollDialogCancel()">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize commonsavebtn fix-btn" 
             @click="createdPayroll()">{{ $t("CreatePayroll")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="wageDialog" max-width="550" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="
              font-size: 22px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("ChooseRegularEarningOption") }}</p>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-radio-group v-model="activeRadioRegularEarning">
              <v-card style="margin-top: -1rem">
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="0" :input-value="active"></v-radio>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("MonthlyWage") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
              <v-card class="mt-3">
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="1" :input-value="active"></v-radio>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("DailyWage") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
              <v-card class="mt-3">
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="2" :input-value="active"></v-radio>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("HourlyWage") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
            </v-radio-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="wageDialogCancel()">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="wagepayroll()">{{ $t("Confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--create customize payroll dialog for ADD-->
    <v-dialog v-model="customDialog" persistent max-width="550">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="
              font-size: 23px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            " v-if="EditText">{{ $t("EditPayroll") }}</p>
          <p style="
              font-size: 23px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            " v-if="!EditText">{{ $t("CreatePayroll") }}</p>
          <p style="
              font-size: 19px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            " v-if="EditText">{{ $t("SelectPayrollPaymentMethodForEditPayroll") }}</p>
          <p style="
              font-size: 19px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            " v-if="!EditText">{{ $t("SelectPayrollPaymentMethodForCreatePayroll") }}</p>
        </v-card-title>
        <v-card-text>
          <v-list>
            <label>{{ $t("PaidBy") }}</label>
            <v-radio-group v-model="ActiveRadioForEditPayDue">
              <v-card style="margin-top: -1rem">
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="0"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByCash") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
              <v-card class="mt-3">
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="1"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-rotate-3d-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByTransfer") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
            </v-radio-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap justify-end pb-6">
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="customDialogCancel()">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn px-1" min-width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="customCreatePayRoll()">
            <p v-if="EditText">{{ $t("EditPayroll") }}</p>
            <p v-if="!EditText">{{ $t("CreatePayroll") }}</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete payroll dialog -->
    <v-dialog v-model="deleteDialog" max-width="416">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p>{{ $t("DeletePayroll") }}</p>
        </v-card-title>
        <v-card-text>
          <p>{{ $t("confirmdelete") }}</p>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="deleteDialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn color="red" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="deletePayrollDetail()">{{ $t("Delete")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirm edit dialog -->
    <v-dialog v-model="confirmEditDialog" max-width="416" persistent>
      <v-card style="border-radius: 15px">
        <v-card-text style="padding-top: 2rem">
          <v-data-table :items="editpaydueList" hide-default-header hide-default-footer :mobile-breakpoint="0">
            <template v-slot:header="{ props: { } }">
              <thead>
                <tr>
                  <th>Payment Due Number</th>
                  <th>{{ $t("Actions") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td style="border: none">
                  payment Due
                  {{
                    editpaydueList
                      .map(function (x) {
                        return x.paymentDue_ID;
                      })
                      .indexOf(item.paymentDue_ID) + 1
                  }}
                </td>
                <td style="border: none">
                  <v-btn icon @click="editpaydueDetail(item.paymentDue_ID)">
                    <img :src="images.edit" />
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="confirmEditDialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="AddNewPayDueInEdit()">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- recalculate dialog -->
    <v-dialog v-model="recalculateDialog" max-width="416">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p> {{ $t("Recaculate") }}</p>
        </v-card-title>
        <v-card-text>
          <p>{{ $t("DoYouConfirmToRecalculate") }}</p>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="recalculateDialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="Recalculateemp()">{{ $t("Confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- update staff time dialog -->
    <v-dialog v-model="Updatestafftimedialog" max-width="416">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p>{{ $t("Updatetime") }}</p>
        </v-card-title>
        <v-card-text>
          <p>{{ $t("confirmupdate") }}</p>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text
            @click="Updatestafftimedialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="UpdateStafftime()">{{ $t("Confirm")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentDueConfirm" max-width="416" class="no-scroll-dialog">
      <v-card style="border-radius: 15px;padding: 3px;">
        <v-card-title class="d-flex justify-center mt-2" style="font-size:18px">
          {{ $t("canceltransaction") }}
        </v-card-title>
        <v-card-text class="d-flex justify-center center-align text-center mt-6" style="font-size:16px">
          {{ $t("transactionsmessage") }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center mt-5">
          <!-- <v-row class="d-flex justify-center align-center" style="font-family: kanit; font-weight: normal"> -->
            <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text @click="paymentDueConfirm = false;">
              {{ $t("keepcontinue") }}
            </v-btn>
            <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35" @click="paymentDueSetupCancel()">
              {{ $t("discard") }}
            </v-btn>
          <!-- </v-row> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit set up dialog -->
    <v-dialog v-model="createSetUpDialog" persistent max-width="1161px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p v-if="!EditText">{{ $t("PaymentDueSetUp") }}</p>
          <p v-if="EditText">{{ $t("EditPaymentDueSetUp") }}</p>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="paymentDueSetupCancel()" icon>
            <v-icon>close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text class="pb-0">
          <div style="margin-left: 1rem; margin-top: 1rem">
            <v-row>
              <v-col xs="12" sm="4" md="4" lg="4" xl="4" style="padding: 0px; margin: 0px">
                <v-row class="pa-0 ma-0 delete-pad">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("CreatedDate")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <v-text-field readonly outlined dense block color="#F99D20" v-model="createdDate"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption != 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodStart")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted2" autocomplete="off" class="kanit-medium" outlined
                          dense color="#F99D20" persistent-hint v-on="on" readonly disabled>
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_start_Date" no-title
                        @input="menu1 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly disabled
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_start_Date" 
                      :placeholder="computedDateFormatted2"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption == 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodStart")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu2">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted3" autocomplete="off" class="kanit-medium" outlined
                          readonly dense color="#F99D20" persistent-hint v-on="on">
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_start_Date_notFormat" no-title
                        @input="menu2 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_start_Date_notFormat" 
                      :placeholder="computedDateFormatted3"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption != 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodEnd")
                      }}
                    </label>
                  </v-col>

                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu3">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted4" autocomplete="off" class="kanit-medium" outlined
                          dense color="#F99D20" persistent-hint v-on="on" readonly disabled>
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_end_Date" no-title
                        @input="menu3 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly disabled 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_end_Date" 
                      :placeholder="computedDateFormatted4"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption == 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodEnd")
                      }}
                    </label>
                  </v-col>

                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu4">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted5" autocomplete="off" readonly class="kanit-medium"
                          outlined dense color="#F99D20" persistent-hint v-on="on">
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_end_Date_notFormat" no-title
                        @input="menu4 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_end_Date_notFormat"
                      :disabled-date="allowedEndDates" 
                      :placeholder="computedDateFormatted5"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 delete-pad mt-1">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PaymentDate")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu5">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted1" autocomplete="off" class="kanit-medium" outlined
                          readonly dense color="#F99D20" persistent-hint v-on="on">
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.calender_income_date" no-title
                        @input="menu5 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.calender_income_date" 
                      :placeholder="computedDateFormatted1"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="12" sm="8" md="8" lg="8" xl="8" align-self="start">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col xs="12" sm="6" md="6" lg="6" xl="6" style="text-align: right">
                    <p>{{ $t("TotalEmployeeExpenses") }}</p>
                    <p style="color: #f99d20">{{ totalExpenses }}</p>
                  </v-col>
                  <v-divider vertical style="height: 2.5rem; margin-top: 1rem"></v-divider>
                  <v-col xs="12" sm="3" md="2" lg="2" xl="2" style="text-align: right">
                    <p>{{ $t("TotalNetPaid") }}</p>
                    <p style="color: #f99d20">{{ totalNetpaid_Unchange }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="editBtnShow" class="mt-0">
              <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4" class="pl-0 pb-0 mt-3">
                <v-text-field class="search" color="#F99D20" v-model="forsearch" :textFieldProps="{
                  placeholder: 'Search',
                  color: '#F99D20',
                  dense: false,
                }" dense outlined :placeholder="$t('searchbyEIN')">
                  <template v-slot:append>
                    <v-btn color="#F99D20" style="
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 0;
                        height: 39px;
                      " disabled>
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4" class="d-flex justify-end">
                <v-btn style="background: #f99d20" class="addStaffBtnAlign text-capitalize white--text mt-3" height="35px"
                  :disabled="disabledAddPayment" @click="AddStaff()">{{ $t("AddStaff") }}</v-btn>
                <v-btn style="background: #f99d20" class="addStaffBtnAlign text-capitalize white--text ml-2 mt-3"
                  height="35px" :disabled="disabledAddPayment" @click="editShow" router>{{ $t("edit") }}</v-btn>
              </v-col>
            </v-row>
            <v-row class="ml-1" v-if="addStaffBtnShow">
              <v-spacer></v-spacer>
              <v-btn color="#F99D20" class="text-capitalize white--text" width="150px" height="35px"
                style="margin-right: 15px;" :disabled="disabledAddPayment" @click="AddStaff()">{{ $t("AddStaff")}}
              </v-btn>
            </v-row>
          </div>

          <div class="ml-1 mt-6 pr-0">
            <v-data-table height="300px" :items="paymentDueSetupData" :page.sync="page1" :items-per-page="itemsPerPage1" @page-count="pageCount1 = $event" hide-default-footer hide-default-header
              style="border: 1px solid grey;" class="custom-data-table" :mobile-breakpoint="0">
              <template v-slot:header="{ props: { } }">
                <thead style="background: #f99d20">
                  <tr>
                    <th class="white--text" style="text-align: center;width:20px;">{{ $t("payrollNo") }}</th>
                    <th class="white--text"></th>
                    <th class="white--text" style="width:200px">{{ $t("Name") }}/{{ $t("EmployeeID") }}</th>
                    <th class="white--text" style="width:150px;">{{ $t("PaidTo") }}</th>
                    <th class="white--text" style="text-align:right;">{{ $t("salary") }}</th>
                    <th class="white--text" style="text-align:right;width:140px;">{{ $t("Additions") }}/<br>{{ $t("Deductions") }}</th>
                    <th class="white--text" style="text-align:right;width:130px;">{{ $t("NetPaid") }}</th>
                    <th class="white--text" style="text-align:center;">{{ $t("status") }}</th>
                    <th class="white--text" style="text-align:center;">{{ $t("Actions") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="table-cell-content" style="width:25px">{{ (page1 - 1) * itemsPerPage1 + index + 1 }}</td>
                  <td class="table-cell-content">
                    <v-avatar style="height:60px;">
                      <img style="width:36px;height:36px;margin-top:20px;" :src="item.image == null ? 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png' : item.image" alt="John" />
                    </v-avatar>
                  </td>
                  <td class="full-name-column table-cell-content" style="width:200px">
                    <p>{{ item.empname }}</p>
                    <p>{{ item.employee_Code }}</p>
                  </td>
                  <td class="table-cell-content" style="width:150px;">
                    <p>{{ item.bankAccount }}</p>
                    <p style="padding-bottom:12px;">{{ thethaihead == true ? item.bankname : item.bankname_ENG }}</p>
                  </td>
                  <!-- <td v-else rowspan="2" style="text-align:center;">{{ item.bankAccount }}</td>              -->
                  <td class="table-cell-content" style="text-align: right;">
                    {{numberWithCommas(parseFloat(item.salaryamount).toFixed(2))}}
                  </td>
                  <td class="table-cell-content" style="text-align: right;width:140px;">
                    <p>
                      {{numberWithCommas(parseFloat(item.addition).toFixed(2))}}
                      <img :src="images.up" />
                    </p>
                    <p style="padding-bottom:12px;">
                      {{numberWithCommas(parseFloat(item.deduction).toFixed(2))}}
                      <img :src="images.down" />
                    </p>
                  </td>
                  <td class="table-cell-content" style="text-align: right;width:130px;">
                    {{numberWithCommas(parseFloat(item.netincome).toFixed(2))}}
                  </td>
                  <td style="width:100px;" class="table-cell-content">
                    <v-sheet style="margin-top:7px !important;" rounded-lg height="26px" width="110px" line-height="30px" :class="item.status === 'Completed'
                        ? 'status_list'
                        : 'status_list1'
                      " class="pt-1">{{ Checkingtranslationfortable(item.status) }}</v-sheet>
                  </td>
                  <td style="min-width:105px;" class="table-cell-content">
                    <v-btn icon @click="printDilalog(item.empID)" v-if="payConfirm">
                      <img width="24px" height="24px" :src="images.print" />
                    </v-btn>
                    <v-btn icon color="white" v-if="payConfirm" @click="Editdetail(item.empID)">
                      <img width="40" height="40" :src="pwdimage.showpwd" alt />
                    </v-btn>
                    <v-btn icon @click="Editdetail(item.empID)" to="/Payrolls?tab=three" v-if="!payConfirm">
                      <img class="editIconPayroll" :src="imagesIcon.edit" alt="editIconPayroll"/>
                    </v-btn>
                    <v-btn icon @click="removeFromPaymentDueSetupData(item.No, item.empID)" v-if="!+payConfirm">
                      <img class="deleteIconPayroll" :src="imagesIcon.delete" alt="deleteIconPayroll"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3" sm="3">
                <v-pagination v-model="page1" class="pagination" color="#F99D20" :length="pageCount1"></v-pagination>
              </v-col>
              <v-col cols="12" md="2" sm="2" class="mt-1">
                <v-select dense style="width: 120px; float: right" solo label="5/page" v-model="itemsPerPage1"
                  :items="items1" @input="itemsPerPage1 = parseInt($event, 10)"></v-select>
              </v-col>
            </v-row>
            </div>
          </div>
          <div style="margin-left: 1rem">
            <v-row class="mt-1">
              <v-col xs="12" sm="4" md="4" lg="4" xl="4" class="pb-0">
                <v-row>
                  <label style="color: #f99d20">{{ $t("NoOfEmployee") }}</label>
                  <v-spacer></v-spacer>
                  <p>{{ totalNumberOfEmployee }}{{ $t("person") }}</p>
                </v-row>
                <br />
                <v-divider style="margin-top: -10px"></v-divider>

                <v-row class="mt-2">
                  <label style="color: #f99d20">{{ $t("TotalSalary") }}</label>
                  <v-spacer></v-spacer>
                  <p>{{ totalSalary }}</p>
                </v-row>
                <v-row class="mt-3">
                  <label style="color: #f99d20">
                    {{
                      $t("TotalAddditions")
                    }}
                  </label>
                  <v-spacer></v-spacer>
                  <p>{{ totalAddition }}</p>
                </v-row>
                <v-row class="mt-3">
                  <label style="color: #f99d20">
                    {{ $t("TotalDeductions") }}
                  </label>
                  <v-spacer></v-spacer>
                  <p>{{ totalDeduction }}</p>
                </v-row>
                <v-row class="mt-3">
                  <label style="color: #f99d20">{{ $t("TotalNetPaid") }}</label>
                  <v-spacer></v-spacer>
                  <p>{{ totalNetpaid_Unchange }}</p>
                </v-row>
                <!-- <v-divider></v-divider>
                <v-row class="mt-1">
                  <label style="color: #f99d20">
                    {{ $t("TotalTransfer") }}
                  </label>
                  <v-spacer></v-spacer>
                  <p>{{ totalTransfer }}</p>
                </v-row>
                <v-row class="mt-1">
                  <label style="color: #f99d20"> {{ $t("TotalCash") }} </label>
                  <v-spacer></v-spacer>
                  <p>{{ totalCash }}</p>
                </v-row>-->
              </v-col>
            </v-row>
          </div>
          <div style="margin-left: 1rem; margin-top: 2rem">
            <v-row class="mt-1">
              <v-col cols="12" xs="12" sm="4" md="4" lg="7" xl="7">
                <v-row>
                  <label class="mt-4">{{ $t("PayrollAccount") }}</label>
                  <v-spacer></v-spacer>
                  <v-row v-if="istherepa != 0">
                      <v-avatar style="height:60px;">
                        <img style="width:36px;height:36px;margin-top:10px;" :src="thepayacc.icon" />
                      </v-avatar>
                    <p style="padding-top:25px; margin-left:10px;">
                      {{
                        thethaihead == true
                        ? thepayacc.bank_Name
                        : thepayacc.bankName_EN
                      }}
                    </p>
                  </v-row>
                  <v-row v-else class="pt-7">
                    <p style="color: #ff0000;">
                      {{ $t("NotHavePayAcc") }}
                    </p>
                    <p style="
                          margin-left: 5px;
                          color: #ff0000;
                          text-decoration: underline;
                          cursor: pointer;
                        " @click="gotoPayrollAccount()">
                      {{ $t("AddPayAcc") }}
                    </p>
                  </v-row>
                  <!--<div class="d-flex bankdiv" style="width: 70%">
                     <v-autocomplete class="selectboxs minizebox mt-1 mb-1 pl-0" v-model="chooseBank" height="34px"
                      single-line :items="companyPaymentAccount" item-value="paymentAcc_ID" background-color="#FFFFFF"
                      style="font-size: 15px">
                      <template v-slot:selection="data">-->
                        <!-- <v-avatar left size="30" style="margin-right: 10px">
                          <v-img :src="thepayacc.icon"></v-img>
                        </v-avatar>
                        {{
                          thethaihead == true
                          ? thepayacc.bank_Name
                          : thepayacc.bankName_EN
                        }} -->
                      <!--</template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-avatar size="30">
                            <img :src="data.item.icon" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                thethaihead == true
                                ? data.item.bank_Name
                                : data.item.bankName_EN
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </div> -->
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12" xs="12" sm="4" md="4" lg="5" xl="4">
                <v-row class="mt-6">
                  <label>{{ $t("PayrollPaymentDetails") }}</label>
                  <v-spacer></v-spacer>
                  <v-btn color="#F99D20" class="text-capitalize white--text" :disabled="disabledAddPayment"
                    @click="addpayment()">{{ $t("AddPayment") }}</v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" offset="3" align-self="end" class="mt-3 totalnetdiv">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col style="text-align: right">
                    <p>{{ $t("TotalNetPaid") }}</p>
                    <p style="color: #f99d20">{{ totalNetPaid }}</p>
                  </v-col>
                  <v-divider vertical style="height: 2.5rem; margin-top: 1rem"></v-divider>
                  <v-col>
                    <p>{{ $t("UnpaidAmount") }}</p>
                    <p style="color: #f99d20">{{ unpaidAmount }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div v-show="confirmPayment" class="mt-5">
            <v-row>
              <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                <v-data-table :items="confrimPaymentData" hide-default-header hide-default-footer
                  style="border: 1px solid grey" :mobile-breakpoint="0">
                  <template v-slot:header="{ props: { } }">
                    <thead style="background: #f99d20">
                      <tr>
                        <th class="white--text" style="width:200px">{{ $t("PaymentDate") }}</th>
                        <th class="white--text" style="text-align:right;">{{ $t("TotalNetPaid") }}</th>
                        <th class="white--text" style="text-align:right;">{{ $t("BankFee") }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td style="width:200px">{{ computedDateFormatted1 }}</td>
                      <td style="text-align:right;">{{ item.totalNetPaid }}</td>
                      <td style="text-align:right;">{{ numberWithCommas(item.bankFee) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pt-6" style="margin-top: 1rem">
          <v-row class="mt-9">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
              @click="paymentDueConfirmDialog()">{{ $t("Cancel") }}</v-btn>
            <v-btn v-if="!EditState" class="mr-3 text-capitalize commonsavebtn fix-btn" @click="savepaydue()">{{ $t("Save")}}</v-btn>
            <v-btn v-if="showResetButton" class="mr-3 text-capitalize commonsavebtn fix-btn"
              @click="resetPayroll()">{{ $t("Reset") }}</v-btn>
            <v-btn v-if="EditState" class="mr-3 text-capitalize commonsavebtn fix-btn"
              @click="updatePayDueDetailInfo()">{{ $t("Save") }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <!-- Create set up dialog -->
     <v-dialog v-model="editSetUpDialog" persistent max-width="1161px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p v-if="!EditText">{{ $t("PaymentDueSetUp") }}</p>
          <p v-if="EditText">{{ $t("EditPaymentDueSetUp") }}</p>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="editpaymentDueSetupCancel()" icon>
            <v-icon>close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text class="pb-0">
          <div style="margin-left: 1rem; margin-top: 1rem">
            <v-row>
              <v-col xs="12" sm="4" md="4" lg="4" xl="4" style="padding: 0px; margin: 0px">
                <v-row class="pa-0 ma-0 delete-pad">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("CreatedDate")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <v-text-field readonly outlined dense color="#F99D20" v-model="createdDate"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption != 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodStart")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu6">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted2" autocomplete="off" class="kanit-medium" outlined
                          block dense color="#F99D20" persistent-hint v-on="on" readonly disabled>
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_start_Date" no-title
                        @input="menu6 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly disabled 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_start_Date" 
                      :placeholder="computedDateFormatted2"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption == 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodStart")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu7">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted3" autocomplete="off" class="kanit-medium" outlined
                          readonly dense color="#F99D20" persistent-hint v-on="on">
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_start_Date_notFormat" no-title
                        @input="menu7 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_start_Date_notFormat" 
                      :placeholder="computedDateFormatted3"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption != 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodEnd")
                      }}
                    </label>
                  </v-col>

                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu8">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted4" autocomplete="off" class="kanit-medium" outlined
                          dense color="#F99D20" persistent-hint v-on="on" readonly disabled>
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_end_Date" no-title
                        @input="menu8 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly disabled
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_end_Date" 
                      :placeholder="computedDateFormatted4"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-1" v-if="payrollOption == 'Customized'">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PayPeriodEnd")
                      }}
                    </label>
                  </v-col>

                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu9">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted5" autocomplete="off" class="kanit-medium" outlined
                          readonly dense color="#F99D20" persistent-hint v-on="on">
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.payroll_end_Date_notFormat" no-title
                        @input="menu9 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.payroll_end_Date_notFormat" 
                      :placeholder="computedDateFormatted5"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 delete-pad mt-1">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="delete-pad pt-2">
                    <label style="width: 120px" class="mt-2">
                      {{
                        $t("PaymentDate")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8" class="delete-pad">
                    <!-- <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu10">
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="computedDateFormatted1" autocomplete="off" class="kanit-medium" outlined
                          dense readonly color="#F99D20" persistent-hint v-on="on">
                          <template v-slot:append>
                            <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                          </template>
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="payrollInfo.calender_income_date" no-title
                        @input="menu10 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker 
                      v-bind:clearable="false"
                      readonly 
                      format="DD/MM/YYYY"
                      value-type="YYYY-MM-DD"
                      :style="{ width: '100%' }"  
                      v-model="payrollInfo.calender_income_date" 
                      :placeholder="computedDateFormatted1"
                      class="createpayroll custom-vuedatepicker1"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="12" sm="8" md="8" lg="8" xl="8" align-self="start">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col xs="12" sm="6" md="6" lg="6" xl="6" style="text-align: right">
                    <p>{{ $t("TotalEmployeeExpenses") }}</p>
                    <p style="color: #f99d20">{{ totalExpenses }}</p>
                  </v-col>
                  <v-divider vertical style="height: 2.5rem; margin-top: 1rem"></v-divider>
                  <v-col xs="12" sm="3" md="2" lg="2" xl="2" style="text-align: right">
                    <p>{{ $t("TotalNetPaid") }}</p>
                    <p style="color: #f99d20">{{ totalNetpaid_Unchange }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4" class="pl-0 pb-0 mt-3">
                <v-text-field class="search" color="#F99D20" v-model="forsearch" :textFieldProps="{
                  placeholder: 'Search',
                  color: '#F99D20',
                  dense: false,
                }" dense outlined :placeholder="$t('searchbyEIN')">
                  <template v-slot:append>
                    <v-btn color="#F99D20" style="
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 0;
                        height: 39px;
                      " disabled>
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="6" sm="6" md="6" lg="4" xl="4" class="d-flex justify-end">
                <v-btn color="#F99D20" class="addStaffBtnAlign text-capitalize white--text mt-3" height="35px" :disabled="disabledAddPayment" @click="AddStaff()">
                  {{ $t("AddStaff") }}
                </v-btn>
                <v-btn color="#F99D20" class="addStaffBtnAlign text-capitalize white--text ml-2 mt-3" height="35px" @click="editShow" :disabled="disabledAddPayment">
                  {{ $t("edit") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="ml-1 mt-2 pr-0">
            <v-data-table height="300px" :items="paymentDueSetupData" hide-default-footer
              hide-default-header style="border: 1px solid grey" :mobile-breakpoint="0" :page.sync="page1" :items-per-page="itemsPerPage1"
              @page-count="pageCount1 = $event">
              <template v-slot:header="{ props: { } }">
                <thead style="background: #f99d20">
                  <tr>
                    <th class="white--text" style="text-align: center;width:20px;">{{ $t("payrollNo") }}</th>
                    <th class="white--text"></th>
                    <th class="white--text" style="width:200px">{{ $t("Name") }}/{{ $t("EmployeeID") }}</th>
                    <th class="white--text" style="width:150px;">{{ $t("PaidTo") }}</th>
                    <th class="white--text" style="text-align:right;">{{ $t("salary") }}</th>
                    <th class="white--text" style="text-align:right;width:140px;">{{ $t("Additions") }}/<br>{{ $t("Deductions") }}</th>
                    <th class="white--text" style="text-align:right;width:130px;">{{ $t("NetPaid") }}</th>
                    <th class="white--text" style="text-align:center;">{{ $t("status") }}</th>
                    <th class="white--text" style="text-align:center;">{{ $t("Actions") }}</th>
                  </tr>
                </thead>
              </template>
              <!-- <template v-slot:item="{ item, index}">
                <tr>
                  <td>{{ (page1 - 1) * itemsPerPage1 + index + 1 }}</td>
                  <td>
                    <v-avatar style="width:36px;height:36px;">
                      <img :src="item.image==null?'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png':item.image" alt="John">
                    </v-avatar>
                  </td>
                  <td style="width: 200px">{{ item.empname }} <br /> {{ item.employee_Code }}</td>
                  <td>
                    <div>
                      <p>
                        {{
                          thethaihead == true
                          ? item.bankname
                          : item.bankname_ENG
                        }}
                      </p>
                    </div>
                    <div>
                      <p>{{ item.bankAccount }}</p>
                    </div>
                  </td>
                  <td>{{numberWithCommas(parseFloat(item.salaryamount).toFixed(2))}}</td>
                  <td>
                    <p align="right" style="margin-right: 3rem">
                      {{numberWithCommas(parseFloat(item.addition).toFixed(2))}}
                      <img :src="images.up" />
                    </p>
                    <p align="right" style="margin-right: 3rem">
                      {{numberWithCommas(parseFloat(item.deduction).toFixed(2))}}
                      <img :src="images.down" />
                    </p>
                  </td>
                  <td>{{numberWithCommas(parseFloat(item.netincome).toFixed(2))}}</td>
                  <td style="width:108px;">
                    <v-sheet rounded-lg height="26px" width="110px" line-height="30px" :class="item.status === 'Completed'
                        ? 'status_list'
                        : 'status_list1'
                      " class="pt-1">{{ Checkingtranslationfortable(item.status) }}</v-sheet>
                  </td>
                  <td style="min-width:108px;">
                    <v-btn icon @click="printDilalog(item.empID)" v-if="payConfirm">
                      <img width="24" height="24" :src="images.print" />
                    </v-btn>
                    <v-btn icon color="white" v-if="payConfirm" @click="Editdetail(item.empID)">
                      <img width="40" height="40" :src="pwdimage.showpwd" alt />
                    </v-btn>
                    <v-btn color="white" icon @click="Editdetail(item.empID)" to="/Payrolls?tab=three" v-if="!payConfirm">
                      <img :src="images.edit1" />
                    </v-btn>
                    <v-btn icon @click="
                      removeFromPaymentDueSetupData(item.No, item.empID)
                      " v-if="!payConfirm">
                      <img :src="images.delete1" />
                    </v-btn>
                  </td>
                </tr>
              </template> -->
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="table-cell-content" style="width:20px;">{{ (page1 - 1) * itemsPerPage1 + index + 1 }}</td>
                  <td class="table-cell-content">
                    <v-avatar style="height:60px;">
                      <img style="width:36px;height:36px;margin-top:20px;" :src="item.image == null ? 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png' : item.image" alt="John" />
                    </v-avatar>
                  </td>
                  <td class="full-name-column table-cell-content" style="width:200px">
                    <p>{{ item.empname }}</p>
                    <p>{{ item.employee_Code }}</p>
                  </td>
                  <td class="table-cell-content" style="width:150px;">
                    <p>{{ item.bankAccount }}</p>
                    <p style="padding-bottom:12px;">{{ thethaihead == true ? item.bankname : item.bankname_ENG }}</p>
                  </td>
                  <!-- <td v-else rowspan="2" style="text-align:center;">{{ item.bankAccount }}</td>              -->
                  <td class="table-cell-content" style="text-align: right;">
                    {{numberWithCommas(parseFloat(item.salaryamount).toFixed(2))}}
                  </td>
                  <td class="table-cell-content" style="text-align: right;width:140px;">
                    <p>
                      {{numberWithCommas(parseFloat(item.addition).toFixed(2))}}
                      <img :src="images.up" />
                    </p>
                    <p style="padding-bottom:12px;">
                      {{numberWithCommas(parseFloat(item.deduction).toFixed(2))}}
                      <img :src="images.down" />
                    </p>
                  </td>
                  <td class="table-cell-content" style="text-align: right;width:130px;">
                    {{numberWithCommas(parseFloat(item.netincome).toFixed(2))}}
                  </td>
                  <td style="width:100px;" class="table-cell-content">
                    <v-sheet style="margin-top:7px !important;" rounded-lg height="26px" width="110px" line-height="30px" :class="item.status === 'Completed'
                        ? 'status_list'
                        : 'status_list1'
                      " class="pt-1">{{ Checkingtranslationfortable(item.status) }}</v-sheet>
                  </td>
                  <td style="min-width:105px;" class="table-cell-content">
                    <v-btn icon @click="printDilalog(item.empID)" v-if="payConfirm">
                      <img width="24px" height="24px" :src="images.print" />
                    </v-btn>
                    <v-btn icon color="white" v-if="payConfirm" @click="Editdetail(item.empID)">
                      <img width="40" height="40" :src="pwdimage.showpwd" alt />
                    </v-btn>
                    <v-btn icon @click="Editdetail(item.empID)" to="/Payrolls?tab=three" v-if="!payConfirm">
                      <img class="editIconPayroll" :src="imagesIcon.edit" alt="editIconPayroll"/>
                    </v-btn>
                    <v-btn icon @click="removeFromPaymentDueSetupData(item.No, item.empID)" v-if="!+payConfirm">
                      <img class="deleteIconPayroll" :src="imagesIcon.delete" alt="deleteIconPayroll"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3" sm="3">
                  <v-pagination v-model="page1" class="pagination"  color="#F99D20" :length="pageCount1"></v-pagination>
                </v-col>
                <v-col cols="12" md="2" sm="2" class="mt-1">
                  <v-select dense style="width: 120px; float: right" solo label="5/page" v-model="itemsPerPage1"
                    :items="items1" @input="itemsPerPage1 = parseInt($event, 10)"></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="mt-3" style="margin-left: 1rem">
            <v-row class="mt-1">
              <v-col xs="12" sm="4" md="4" lg="4" xl="4">
                <v-row>
                  <label style="color: #f99d20">{{ $t("NoOfEmployee") }}</label>
                  <v-spacer></v-spacer>
                  <p>{{ totalNumberOfEmployee }} Persons</p>
                </v-row>
                <br />
                <v-divider style="margin-top: -10px"></v-divider>
                <v-row class="mt-2">
                  <label style="color: #f99d20">{{ $t("TotalSalary") }}</label>
                  <v-spacer></v-spacer>
                  <p>{{ totalSalary }}</p>
                </v-row>
                <v-row class="mt-3">
                  <label style="color: #f99d20">
                    {{
                      $t("TotalAddditions")
                    }}
                  </label>
                  <v-spacer></v-spacer>
                  <p>{{ totalAddition }}</p>
                </v-row>
                <v-row class="mt-3">
                  <label style="color: #f99d20">
                    {{
                      $t("TotalDeductions")
                    }}
                  </label>
                  <v-spacer></v-spacer>
                  <p>{{ totalDeduction }}</p>
                </v-row>
                <v-row class="mt-3">
                  <label style="color: #f99d20">{{ $t("TotalNetPaid") }}</label>
                  <v-spacer></v-spacer>
                  <p>{{ totalNetpaid_Unchange }}</p>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3" style="margin-left: 1rem; margin-top: 2rem">
            <v-row class="mt-1">
              <v-col xs="12" sm="4" md="5" lg="7" xl="7">
                <v-row>
                  <label class="mt-4">{{ $t("PayrollAccount") }}</label>
                  <v-spacer></v-spacer>
                  <v-row v-if="istherepa != 0">
                      <v-avatar style="height:60px;">
                        <img style="width:36px;height:36px;margin-top:10px;" :src="thepayacc.icon" />
                      </v-avatar>
                    <p style="padding-top:25px; margin-left:10px;">
                      {{
                        thethaihead == true
                        ? thepayacc.bank_Name
                        : thepayacc.bankName_EN
                      }}
                    </p>
                  </v-row>
                  <v-row v-else class="pt-7">
                    <p style="color: #ff0000;">
                      {{ $t("NotHavePayAcc") }}
                    </p>
                    <p style="
                          margin-left: 5px;
                          color: #ff0000;
                          text-decoration: underline;
                          cursor: pointer;
                        " @click="gotoPayrollAccount()">
                      {{ $t("AddPayAcc") }}
                    </p>
                  </v-row>
                  <!-- <div class="d-flex bankdiv" style="width: 70%">
                    <v-autocomplete class="selectboxs minizebox mt-1 mb-1 pl-0" v-model="chooseBank" height="34px"
                      single-line :items="companyPaymentAccount" item-value="paymentAcc_ID" background-color="#FFFFFF"
                      style="font-size: 15px">
                      <template v-slot:selection="data">
                        <v-avatar left size="30" style="margin-right: 10px">
                          <v-img :src="data.item.icon"></v-img>
                        </v-avatar>
                        {{
                          thethaihead == true
                          ? data.item.bank_Name
                          : data.item.bankName_EN
                        }}
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-avatar size="30">
                            <img :src="data.item.icon" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                thethaihead == true
                                ? data.item.bank_Name
                                : data.item.bankName_EN
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </div> -->
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="4" md="5" lg="5" xl="4">
                <v-row class="mt-4">
                  <label>{{ $t("PayrollPaymentDetails") }}</label>
                  <v-spacer></v-spacer>
                  <v-btn color="#F99D20" class="text-capitalize white--text" :disabled="disabledAddPayment"
                    @click="addpayment()">{{ $t("AddPayment") }}</v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" offset="3" align-self="end" class="mt-3 totalnetdiv">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col style="text-align: right">
                    <p>{{ $t("TotalNetPaid") }}</p>
                    <p style="color: #f99d20">{{ totalNetPaid }}</p>
                  </v-col>
                  <v-divider vertical style="height: 2.5rem; margin-top: 1rem"></v-divider>
                  <v-col>
                    <p>{{ $t("UnpaidAmount") }}</p>
                    <p style="color: #f99d20">{{ unpaidAmount }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-show="confirmPayment" class="mt-5">
            <v-row>
              <v-col xs="12" sm="8" md="8" lg="8" xl="8">
                <v-data-table :items="confrimPaymentData" hide-default-header hide-default-footer
                  style="border: 1px solid grey" :mobile-breakpoint="0">
                  <template v-slot:header="{ props: { } }">
                    <thead style="background: #f99d20">
                      <tr>
                        <th class="white--text" style="width:200px">{{ $t("PaymentDate") }}</th>
                        <th class="white--text" style="text-align:right;">{{ $t("TotalNetPaid") }}</th>
                        <th class="white--text" style="text-align:right;">{{ $t("BankFee") }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td style="width:200px">{{ computedDateFormatted1 }}</td>
                      <td style="text-align:right;">{{ item.totalNetPaid }}</td>
                      <td style="text-align:right;">{{ numberWithCommas(item.bankFee) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pt-6" style="margin-top: 1rem">
          <v-row class="mt-9">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
              @click="paymentDueConfirmDialog()">{{ $t("Cancel") }}</v-btn>
            <v-btn v-if="showResetButton" class="mr-3 text-capitalize commonsavebtn fix-btn"
              @click="resetPayroll()">{{ $t("Reset") }}</v-btn>
            <v-btn v-if="!EditState" class="mr-3 text-capitalize commonsavebtn fix-btn" 
             @click="savepaydue()">{{ $t("Save")}}</v-btn>
            <v-btn v-if="EditState" class="mr-3 text-capitalize commonsavebtn fix-btn"
              @click="updatePayDueDetailInfo()">{{ $t("Save") }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add-staff dialog -->
    <v-dialog id="gg" v-model="staffDialog" persistent max-width="724px" :retain-focus="false">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="clear()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row style="padding-top: 1rem">
            <v-col lg="9" md="9" cols="12" class="pr-0">
              <v-text-field class="search" color="#F99D20" v-model="SearchReportTo" :textFieldProps="{
                placeholder: 'Search',
                color: '#F99D20',
                dense: false,
              }" dense outlined :placeholder="$t('SearchEmployeeIDDepartment')"></v-text-field>
            </v-col>
            <v-col>
              <v-btn color="#F99D20" @click="searchReport()" class="white--text text-capitalize ml-5" width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>
          <div>
            <v-data-table height="600px" class="mt-2" :items="empList" hide-default-header hide-default-footer
              disable-pagination :mobile-breakpoint="0">
              <template v-slot:header="{ props: { } }">
                <thead>
                  <tr style="background-color: #f99d20">
                    <td style="color: #ffffff">
                      <v-checkbox class="custom-checkbox" color="#1284e7" v-model="selectAll_Emp" @change="checkall(selectAll_Emp)"></v-checkbox>
                      <!-- <input type="checkbox" v-model="selectAll_Emp"/> -->
                      </td>
                    <td style="color: #ffffff">{{ $t("EmployeeID") }}</td>
                    <td style="color: #ffffff">{{ $t("EmployeeName") }}</td>
                    <td style="color: #ffffff">{{ $t("department") }}</td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td style="border: none">
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="item.checked" @change="checkmyemp()" ></v-checkbox>
                    <!-- <input type="checkbox" v-model="checkEmpListForAddStaff" :value="item.employee_ID"> -->
                  </td>
                  <td style="border: none">{{ item.employee_Code }}</td>
                  <td style="border: none">{{ item.empname }}</td>
                  <td style="border: none">{{ item.department_Name }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
            @click="clear()">{{ $t("Cancel") }}</v-btn>
          <v-btn class="mr-3 text-capitalize commonsavebtn fix-btn"
            style="font-family: kanit; font-weight: normal" @click="checkStaff()">{{ $t("Save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- payroll payment detail -->
    <v-dialog v-model="paymentDetailDialog" max-width="1161px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p class="titleForConfirmpayment" style="margin-left: 1rem">{{ $t("PayrollPaymentDetails") }}</p>
          <v-spacer></v-spacer>
          <v-btn @click="paymentDetailDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div style="margin-left: 1rem; margin-top: 1rem">
            <v-row>
              <v-col xs="12" sm="4" md="4" lg="4" xl="4">
                <v-row class="textForComfirmpayment" md="4">
                  <v-col>
                    <label>{{ $t("PayrollPeriod") }}</label>
                  </v-col>
                  <v-col v-if="payrollOption !== 'Customized'">
                    <p>{{ computedDateFormatted2 }}-{{ computedDateFormatted4 }}</p>
                  </v-col>
                  <v-col v-if="payrollOption === 'Customized'">
                    <p>{{ computedDateFormatted3 }}-{{ computedDateFormatted5 }}</p>
                  </v-col>
                  <!-- <v-col>
                    <p>
                      {{ computedDateFormatted2 }}_{{ computedDateFormatted4 }}
                    </p>
                  </v-col>-->
                </v-row>

                <v-row class="textForComfirmpayment">
                  <v-col>
                    <label>{{ $t("PaymentDate") }}</label>
                  </v-col>
                  <v-col class="mb-0 pb-0 mt-0 pt-0">
                    <v-text-field class="textInputForCofirmpayment" outlined dense color="#F99D20"
                      v-model="computedDateFormatted1" readonly disabled></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="titleForConfirmpayment">
                  <v-col>
                    <label class="titleForConfirmpayment" style="color: #444444">{{ $t("PaymentInformation") }}</label>
                  </v-col>
                </v-row>
                <v-row class>
                  <v-col>
                    <label class="textForComfirmpayment">
                      {{
                        $t("PaidBy")
                      }}
                    </label>
                  </v-col>
                  <v-col>
                    <p class="textForComfirmpayment">{{ companyBankAccount }}</p>
                  </v-col>
                </v-row>
                <v-row class="textForComfirmpayment">
                  <v-col>
                    <label>{{ $t("amount") }}</label>
                  </v-col>
                  <v-col>
                    <p>{{ totalNetpaid_Unchange }}</p>
                  </v-col>
                </v-row>
                <v-row class="textForComfirmpayment">
                  <v-col>
                    <label>{{ $t("BankFee") }}</label>
                  </v-col>
                  <v-col class="mb-0 pb-0 mt-0 pt-0">
                    <v-text-field class="textInputForCofirmpayment" color="#F99D20" @keypress="onlyNumber($event,bankfee)" @focus="selectText($event, bankfee, 'bankfee')" v-on:keyup="bankfeecomma()" @focusout="handleFocusoutbankfee(bankfee)" v-on:click="handleClickbankfee()"
                      v-model="bankfee" dense outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="textForComfirmpayment">
                  <v-col>
                    <label>{{ $t("PaidBy") }}</label>
                  </v-col>
                  <v-col>
                    <p>{{ paidBy }}</p>
                  </v-col>
                </v-row>
                <v-row class="textForComfirmpayment">
                  <v-col>
                    <label>{{ $t("amount") }}</label>
                  </v-col>
                  <v-col>
                    <p>{{ additionOfNetPaidAndBankFee }}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="12" sm="8" md="8" lg="8" xl="8" align-self="start">
                <v-row class="textForComfirmpayment">
                  <v-spacer></v-spacer>
                  <v-col xs="12" sm="6" md="6" lg="6" xl="6" style="text-align: right">
                    <p>{{ $t("NoOfEmployee") }}</p>
                    <p style="color: #f99d20">{{ totalNumberOfEmployee }}</p>
                  </v-col>
                  <v-divider vertical style="height: 2.5rem; margin-top: 1rem"></v-divider>
                  <v-col xs="12" sm="3" md="2" lg="2" xl="2" style="text-align: right">
                    <p>{{ $t("TotalNetPaid") }}</p>
                    <p style="color: #f99d20">{{ totalNetpaid_Unchange }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div class="mt-3 ml-1">
            <v-data-table class="textForComfirmpayment" disable-pagination height="150px" :items="paymentDueSetupData" hide-default-footer
              hide-default-header style="border: 1px solid grey" :mobile-breakpoint="0">
              <template v-slot:header="{ props: { } }">
                <thead style="background: #f99d20">
                  <tr>
                    <th class="white--text">{{ $t("no") }}</th>
                    <th class="white--text">{{ $t("Name") }}</th>
                    <th class="white--text">{{ $t("EmployeeID") }}</th>
                    <th class="white--text">{{ $t("PaidTo") }}</th>
                    <th class="white--text" style="text-align:right;">{{ $t("NetPaid") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.No }}</td>
                  <td>{{ item.empname }}</td>
                  <td>{{ item.employee_Code }}</td>
                  <td>
                    <p>
                      {{ item.bankAccount }}
                    </p>
                    <p>
                      {{ thethaihead == true ? item.bankname : item.bankname_ENG }}
                    </p>
                  </td>
                  <td style="text-align:right;">{{ numberWithCommas(item.netincome) }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="confirmPaymentBtnAlign pa-0 text-capitalize fix-btn commoncancelbtn"
            text @click="paymentDetailDialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn  class="confirmPaymentBtnAlign pa-0 text-capitalize mr-2 commonsavebtn fix-btn"
             @click="confirmPay()">{{
              $t("ConfirmToPay") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--create payroll dialog for EDIT-->
    <v-dialog v-model="editPayrollDialog" max-width="550" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="
              font-size: 23px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("EditPayroll") }}</p>
          <p style="
              font-size: 19px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("SelectPayrollCalculationForEdit") }}</p>
        </v-card-title>
        <v-card-text>
          <v-list>
            <label>{{ $t("PayrollCalculationPeriod") }}</label>
            <!-- <ul class="object administrator-checkbox-list">
              <li v-for="module in modules" :key="module.id">
                <label v-bind:for="module.id">
                  <input
                    id="checkbox"
                    type="checkbox"
                    :checked="module.checked"
                    v-on:input="checkboxVal = $event.target.value"
                  />
                  <span>@{{ module.name }}</span>
                </label>
              </li>
            </ul>-->

            <v-card v-for="(item, i) in paymentcalculationlist" :key="i" style="margin-top: 1rem">
              <v-list-group :prepend-icon="item.action" no-action>
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon color="#F99D20">mdi-calendar-month-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <!-- v-text="item.period_Name" -->
                    <v-list-item-title>
                      <p style="
                          color: #f99d20;
                          position: absolute;
                          margin-left: -10px;
                          margin-top: -8px;
                        ">{{ item.period_Name }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="child in item.period" :key="child.id">
                  <v-list-item-content>
                    <!-- v-text="child.item" -->
                    <v-list-item-title>
                      <v-list-item-action>
                        <input id="checkbox" type="checkbox" :value="item.payroll_Period_ID != null
                            ? {
                              payroll_Period_ID: item.payroll_Period_ID,
                              period_ID: child.period_ID,
                            }
                            : {
                              payroll_Period_ID: payroll_Period_ID,
                              period_ID: period_ID,
                            }
                          " v-model="selectForedit" v-on:click="justcheckone()" />
                      </v-list-item-action>
                      <p style="color: #f99d20; display: inline">{{ child.item }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-card>
          </v-list>

          <v-list>
            <label>{{ $t("PaidBy") }}</label>
            <v-radio-group v-model="ActiveRadioForEditPayDue">
              <v-card style="margin-top: -1rem">
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="0"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByCash") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
              <v-card class="mt-3">
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-radio color="#F99D20" :value="1"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-rotate-3d-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByTransfer") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
            </v-radio-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
            @click="editPayrollDialogCancel()">{{ $t("Cancel") }}</v-btn>
          <v-btn class="mr-3 text-capitalize commonsavebtn fix-btn" @click="editpaydueDetailInfo()">{{ $t("EditPayroll")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--create customize payroll dialog for EDIT-->
    <v-dialog v-model="editCustomDialog" max-width="550">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="
              font-size: 22px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("EditPayroll") }}</p>
          <p style="
              font-size: 18px;
              font-family: kanit;
              font-weight: normal;
              margin-top: 1rem;
            ">{{ $t("SelectPayrollPaymentMethodForEditPayroll") }}</p>
        </v-card-title>
        <v-card-text>
          <v-list>
            <label>{{ $t("PaidBy") }}</label>
            <v-radio-group>
              <v-card style="margin-top: -1rem">
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-radio color="#F99D20" :input-value="active"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByCash") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
              <v-card class="mt-3">
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-radio color="#F99D20" :input-value="active"></v-radio>
                    </v-list-item-action>
                    <v-list-item-icon>
                      <v-icon color="#F99D20" style="margin-left: -1rem">mdi-rotate-3d-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p style="color: #f99d20">{{ $t("PayByTransfer") }}</p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
            </v-radio-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
            @click="editCustomDialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn class="mr-3 text-capitalize commonsavebtn fix-btn"
            @click="(editCustomDialog = false), (editSetUpDialog = true)">{{ $t("CreatePayroll") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- print dialog -->
    <v-dialog v-model="printDialog" max-width="100%" v-if="PrintData">
      <v-card>
        <v-card-text ref="content">
          <div style="margin-left: 1rem">
            <v-row>
              <v-col>
                <v-row class="ml-0" style="margin-top: 2rem">
                  <v-col>
                    <p style="
                        font-size: 21px;
                        color: #444444;
                        margin-left: -1rem;
                      ">{{ companyName }}</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <p style="
                        font-size: 21px;
                        color: #444444;
                        margin-right: 0;
                        text-align: end;
                      ">{{ $t("Payslip") }}</p>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -1rem">
                  <v-col>
                    <p class="printDialogText" style="color: #444444">{{ $t("employeeid") }} : {{ PrintData.empCode }}</p>
                  </v-col>
                  <v-col>
                    <p class="printDialogText" style="color: #444444">{{ $t("EmployeeName") }} : {{ PrintData.empname }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="printDialogText" style="color: #444444">{{ $t("PaymentDate") }} : {{ computedDateFormatted1
                    }}</p>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -1.5rem">
                  <v-col>
                    <p class="printDialogText" style="color: #444444">{{ $t("position") }} : {{ PrintData.positioinName }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="printDialogText" style="color: #444444">{{ $t("department") }} : {{ PrintData.positioinName
                    }}</p>
                  </v-col>
                  <v-col>
                    <p class="printDialogText" style="color: #444444">
                      {{ $t("BankAccount") }} : {{ PrintData.bankname }}
                      {{ PrintData.bankAccount }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div id="middlecol">
            <table style="border: 2px solid black; border-collapse: collapse">
              <tr style="height: 57px; background: #f8f8f8">
                <th colspan="2" style="
                    border: 2px solid black;
                    font-weight: normal;
                    width: 100%;
                  ">{{ $t("Income") }}</th>
                <th colspan="2" style="
                    border: 2px solid black;
                    font-weight: normal;
                    width: 100%;
                  ">{{ $t("Deductions") }}</th>
                <th colspan="2" style="
                    border: 2px solid black;
                    font-weight: normal;
                    width: 100%;
                  ">{{ $t("ytd") }}</th>
              </tr>

              <tr style="height: 57px">
                <td colspan="2" class="printDialogText" style="
                    border-right: 2px solid black;
                    color: #f99d20;
                    font-weight: normal;
                  ">
                  <table style="
                      width: 100%;
                      border: 0;
                      margin-top: -1.5rem;
                      padding-left: 0.8rem;
                    ">
                    <tr style="height: 57px" v-for="(item, i) in PrintRevenueList" :key="i">
                      <td colspan="4" class="printDialogTextInTable" style="
                          color: black;
                          font-weight: normal;
                          margin-left: 20px;
                        ">{{ item.name }}</td>
                      <td colspan="4" class="printDialogTextInTable" style="
                          color: black;

                          text-align: right;
                          font-weight: normal;
                        ">{{ item.value }}</td>
                    </tr>
                  </table>
                </td>
                <td colspan="2" style="
                    font-size: 19px;
                    color: #f99d20;
                    font-weight: normal;
                    border-right: 2px solid black;
                  ">
                  <table style="width: 100%; border: 0; padding-left: 0.8rem">
                    <tr style="height: 57px" v-for="(item, i) in PrintDeductionList" :key="i">
                      <td v-if="item.name != 'Social Security' &&
                        item.name != 'Tax' &&
                        item.name != 'PVD'
                        " colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
    item.name }}</td>
                      <td v-if="item.name == 'Social Security'" colspan="4" class="printDialogTextInTable"
                        style="color: black; font-weight: normal">{{ $t("social_security") }}</td>
                      <td v-if="item.name == 'Tax'" colspan="4" class="printDialogTextInTable"
                        style="color: black; font-weight: normal">{{ $t("witholding") }}</td>
                      <td v-if="item.name == 'PVD'" colspan="4" class="printDialogTextInTable"
                        style="color: black; font-weight: normal">{{ $t("provident_fund") }}</td>
                      <td colspan="4" class="printDialogTextInTable" style="
                          color: black;

                          text-align: right;
                          font-weight: normal;
                        ">{{ item.value }}</td>
                    </tr>
                  </table>
                </td>
                <td colspan="2" style="
                    font-size: 19px;
                    color: #f99d20;
                    font-weight: normal;
                    border-right: 2px solid black;
                  ">
                  <table style="
                      width: 100%;
                      margin-top: -3rem;
                      border: 0;
                      padding-left: 0.8rem;
                    ">
                    <tr>
                      <td colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
                        $t("ytdincome") }}</td>
                      <td colspan="4" class="printDialogTextInTable" style="
                          color: black;
                          text-align: right;
                          font-weight: normal;
                          padding-right: 0.8rem;
                        ">{{ YTDIncome }}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
                        $t("ytdwitholding") }}</td>
                      <td colspan="4" class="printDialogTextInTable" style="
                          color: black;
                          text-align: right;
                          font-weight: normal;
                          padding-right: 0.8rem;
                        ">{{ YTDwithholdingtax }}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="printDialogTextInTable" style="color: black; font-weight: normal">{{
                        $t("accmulatedssf") }}</td>
                      <td colspan="4" class="printDialogTextInTable" style="
                          color: black;
                          text-align: right;
                          font-weight: normal;
                          padding-right: 0.8rem;
                        ">{{ AccumulateSSF }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="1" class="printDialogTextInTable" style="
                      border: 1px solid black;
                       background-color:#c2c2c2
                      color: #444444;
                    padding-left: 0.8rem;
                    ">{{ $t("TotalIncome") }}</td>
                <td colspan="1" class="printDialogTextInTable text-right" style="
                    border: 1px solid black;
                    color: #444444;
                    padding-right: 0.8rem;
                  ">{{ TotalIncome_THB }}</td>
                <td colspan="1" class="printDialogTextInTable" style="
                    border: 1px solid black;
                    padding-left: 0.8rem;
                    color: #444444;
                    background-color: #c2c2c2;
                  ">{{ $t("TotalDeductions") }}</td>
                <td colspan="1" class="printDialogTextInTable text-right" style="
                    border-width: 1px 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    padding-right: 0.8rem;
                    color: #444444;
                  ">{{ TotalNet_THB }}</td>
                <td colspan="3" class="printDialogTextInTable" style="
                    border-width: 0px 1px 0px 0px;
                    border-color: black;
                    border-style: solid;
                    color: #444444;
                    padding-left: 0.8rem;
                  "></td>
              </tr>
              <tr>
                <td colspan="3" class="printDialogTextInTable text-right" style="
                    color: #444444;
                    border-width: 0px 0px 1px 1px;
                    border-color: black;
                    border-style: solid;
                    background-color: #c2c2c2;
                    padding-right: 0.8rem;
                  ">{{ $t("netpay") }}</td>
                <td class="printDialogTextInTable text-right" style="
                    color: #444444;
                    border-width: 0px 1px 1px 1px;
                    border-color: black;
                    border-style: solid;
                    padding-right: 0.8rem;
                  ">
                  {{numberWithCommas(parseFloat(removecomma(TotalIncome_THB)).toFixed(2) - (parseFloat(removecomma(TotalNet_THB))).toFixed(2))}}
                </td>
                <td colspan="3" class="printDialogTextInTable" style="
                    border-width: 0 1px 1px 0px;
                    border-color: black;
                    border-style: solid;
                    color: #444444;
                    padding-left: 0.8rem;
                  "></td>
              </tr>
            </table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
            @click="printDialog = false">{{ $t("Cancel") }}</v-btn>
          <v-btn class="mr-3 text-capitalize commonsavebtn fix-btn" 
           @click="downloadWithCSS1()">{{ $t("Print") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- print dialog -->
    <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
      <v-card style="background-color: white">
        <div style="display: flex; justify-content: space-between">
          <v-card-title style="font-size: 24px; font-weight: normal" class="mb-4 ml-5">{{ $t("Print Preview")
          }}</v-card-title>
          <v-btn @click="
          printdialogforupdate = false;
          iframeShow = false;
          " icon style="margin-right: 3rem; margin-top: 1rem">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-card class="pdf-container">
          <v-row align="center" justify="center">
            <v-col cols="8" style="margin-top: 0.2rem">
              <pdf id="pdf_frame" :src="pdfString"></pdf>
            </v-col>
          </v-row>
        </v-card>

        <v-card-actions class="mt-5" style="display: flex">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text @click="Cancel">{{
            $t("Cancel") }}</v-btn>
          <v-btn class="mr-8 text-capitalize commonsavebtn fix-btn"
            @click="Print">{{ $t("Print") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="visibility: hidden">
      <iframe id="i_frame" v-if="iframeShow" width="100%" height="100%" :src="pdfString" frameborder="0" scrolling="no"
        name="frame1"></iframe>
    </div>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog2" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text pb-0">
          Uploading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
          <div class="text-center">
          {{ theuploaded }} / {{ theuploadlength }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- working day history dialog -->
    <v-dialog v-model="workingdayhistorydialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="font-size: 20px">{{ $t("workingdayhistory") }}</p>

          <v-spacer></v-spacer>
          <v-btn @click="workingdayhistorydialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table style="margin-left: 2rem; margin-right: 2rem; padding-bottom: 2rem" :items="workingdayhistorylist"
          hide-default-footer hide-default-header :headers="headers" :mobile-breakpoint="0">
          <template v-slot:header="{ props: { } }">
            <thead>
              <tr>
                <th>{{ $t("previoiusworkingdays") }}</th>
                <th>{{ $t("newworkingdays") }}</th>
                <th>{{ $t("DateEdit") }}</th>
                <th>{{ $t("Incharge") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.previousdays }}</td>
              <td>{{ item.newdays }}</td>
              <td>{{ convertFromUTCDateToLocalDate(item.datechange) }}</td>
              <td>{{ item.changeby }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- OT day history dialog -->
    <v-dialog v-model="OTdayhistorydialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="font-size: 20px">{{ $t("OThoursHistory") }}</p>

          <v-spacer></v-spacer>
          <v-btn @click="OTdayhistorydialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table style="margin-left: 2rem; margin-right: 2rem; padding-bottom: 2rem" :items="workingdayhistorylist"
          hide-default-footer hide-default-header :headers="headers" :mobile-breakpoint="0">
          <template v-slot:header="{ props: { } }">
            <thead>
              <tr>
                <th>{{ $t("PreviousOT") }}</th>
                <th>{{ $t("NewOT") }}</th>
                <th>{{ $t("DateEdit") }}</th>
                <th>{{ $t("Incharge") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.previousOTdays }}</td>
              <td>{{ item.newOTdays }}</td>
              <td>{{ convertFromUTCDateToLocalDate(item.datechange) }}</td>
              <td>{{ item.changeby }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Not stamp day history dialog -->
    <v-dialog v-model="Notstampdayhistorydialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="font-size: 20px">{{ $t("NotClokinhistory") }}</p>

          <v-spacer></v-spacer>
          <v-btn @click="Notstampdayhistorydialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table style="margin-left: 2rem; margin-right: 2rem; padding-bottom: 2rem" hide-default-footer
          hide-default-header :headers="headers" :items="workingdayhistorylist" :mobile-breakpoint="0">
          <template v-slot:header="{ props: { } }">
            <thead>
              <tr>
                <th>{{ $t("Previousnotclockin") }}</th>
                <th>{{ $t("Newnotclockin") }}</th>
                <th>{{ $t("DateEdit") }}</th>
                <th>{{ $t("Incharge") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.previousNotstampdays }}</td>
              <td>{{ item.newNotstampdays }}</td>
              <td>{{ convertFromUTCDateToLocalDate(item.datechange) }}</td>
              <td>{{ item.changeby }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Late day history dialog -->
    <v-dialog v-model="Latedayhistorydialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="font-size: 20px">{{ $t("Latedayhistory") }}</p>

          <v-spacer></v-spacer>
          <v-btn @click="Latedayhistorydialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table style="margin-left: 2rem; margin-right: 2rem; padding-bottom: 2rem" hide-default-footer
          hide-default-header :headers="headers" :items="workingdayhistorylist" :mobile-breakpoint="0">
          <template v-slot:header="{ props: { } }">
            <thead>
              <tr>
                <th>{{ $t("Previouslateday") }}</th>
                <th>{{ $t("Newlateday") }}</th>
                <th>{{ $t("DateEdit") }}</th>
                <th>{{ $t("Incharge") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.previousLatedays }}</td>
              <td>{{ item.newLatedays }}</td>
              <td>{{ convertFromUTCDateToLocalDate(item.datechange) }}</td>
              <td>{{ item.changeby }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Email-staff dialog -->
    <v-dialog id="gg" v-model="emaildialog" persistent max-width="724px" :retain-focus="false">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <label>{{ $t("Sentpaysip") }}</label>
          <v-spacer></v-spacer>
          <v-btn @click="closeemaildialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row style="padding-top: 1rem">
            <v-col lg="9" md="9" cols="12" class="pr-0">
              <v-text-field class="search" color="#F99D20" v-model="SearchEmailTo" :textFieldProps="{
                placeholder: 'Search',
                color: '#F99D20',
                dense: false,
              }" dense outlined :placeholder="$t('SearchEmployeeIDDepartment')"></v-text-field>
            </v-col>
            <v-col>
              <v-btn color="#F99D20" @click="searchEmaillist()" class="white--text text-capitalize ml-5" width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>
          <div>
            <v-data-table height="600px" class="mt-2" :items="emailempList" hide-default-header hide-default-footer
              disable-pagination :mobile-breakpoint="0">
              <template color="#F99D20" v-slot:header="{ props: { } }">
                <thead>
                  <tr style="background-color: #f99d20">
                    <td style="color: #ffffff">
                      <input type="checkbox" v-model="selectAllemailemp" @click="selectallemail" />
                    </td>
                    <td style="color: #ffffff">{{ $t("EmployeeID") }}</td>
                    <td style="color: #ffffff">{{ $t("EmployeeName") }}</td>
                    <td style="color: #ffffff">{{ $t("department") }}</td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td style="border: none">
                    <input type="checkbox" v-model="checkemplistforEmailcheck" :value="item.employee_ID != null
                        ? {
                          employee_ID: item.employee_ID,
                          empname: item.empname,
                          birthDate: item.birthDate,
                        }
                        : {
                          employee_ID: employee_ID,
                          empname: item.empname,
                          birthDate: item.birthDate,
                        }
                      " />
                  </td>
                  <td style="border: none">{{ item.employee_Code }}</td>
                  <td style="border: none">{{ item.empname }}</td>
                  <td style="border: none">{{ item.department_Name }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="120" max-height="35" text
            @click="closeemaildialog()">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit; font-weight: normal" @click="AddStaffEmail">{{ $t("Save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="Noitemdialog" persistent max-width="400">
      <v-card style="border-radius: 15px">
        <v-card-title class="pb-0">
          <v-spacer></v-spacer>
          <v-icon color="#F99D20" x-large>mdi-alert-circle</v-icon>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12" class="pt-0">
                <p style="font-size: 17px; color: #444444" class="pt-4">{{ $t("Noitem") }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn color="#F99D20" class="text-capitalize white--text" width="90" max-height="35"
            @click="Noitemdialog = false">{{ $t("OK") }}</v-btn>
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- assign psw dialog -->
    <v-dialog v-model="assignpswdialog" persistent max-width="500">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p>{{ $t("assignpswtoopen") }}</p>
          <br />
          <p style="font-size: 12px">{{ $t("usecharacter") }}</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <v-text-field color="#FF9900" v-model="assignpsw" dense outlined hide-details></v-text-field>
              </v-col>
              <span v-show="errormsgpsw" style="font-size: 15px" class="red--text ml-4">
                <v-icon color="red">mdi-alert-circle</v-icon>
                {{ $t("comreq") }}
              </span>
              <span v-show="errormsgpswformat" style="font-size: 15px" class="red--text ml-4">
                <v-icon color="red">mdi-alert-circle</v-icon>
                {{ $t("pswreq") }}
              </span>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="120" max-height="35" text
            @click="closeAssigndialog()">{{ $t("Cancel") }}</v-btn>
          <v-btn color="#F99D20" class="text-capitalize white--text" width="90" max-height="35" :disabled="disablepdf"
            @click="generatePdf()">{{ $t("Confirm") }}</v-btn>
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import GetIncome from "@/function/Income.js";
import moment from "moment";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { PDFDocument } from "pdf-lib";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ThaiFontBoldBase64 from "@/function/global.js";
import ThaiFontBase64 from "@/function/global.js";
import XLSX from "xlsx";
import { utils } from "@/function/AuthenticatedRedirect.js";
// import 'jspdf-encrypt';
//import domtoimage from "dom-to-image";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  mixins: [
    GetIncome,
    LogTrace,
    CheckViewAddEditDelete,
    ThaiFontBoldBase64,
    ThaiFontBase64,
    utils
  ],
  components: { pdf },
  data() {
    return {
      btngcolor:"color:#F99D20; border-bottom:3px solid #F99D20;",
      btnwhitecolor:"",
      activeButton: 1,
      empButton: 1,
      paybyButton: 1,
      permission: [],
      iframeShow: false,
      pdfString: "",
      addstafflist: [],
      printdialogforupdate: false,
      assignpswdialog: false,
      printDialog1: false,
      assignpsw: "",
      errormsgpsw: false,
      errormsgpswformat: false,
      selectAll: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      menu10: false,
      clickbtn: 0,
      forsearch: null,
      addpaymentdate: "",
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      items: [10, 20, 30],
      paginatedData: [],
      page1: 1,
      pageCount1: 5,
      itemsPerPage1: 5,
      items1: [5, 10, 20, 30],
      showforupdate: false,
      showrateperday: false,
      showrateperhour: false,
      workingdayhistorydialog: false,
      OTdayhistorydialog: false,
      Notstampdayhistorydialog: false,
      Noitemdialog: false,
      Latedayhistorydialog: false,
      Updatestafftimedialog: false,
      workingdayhistorylist: [],
      selectAllemailemp: false,
      positionMenuX: 0,
      positionMenuY: 0,
      positionAwaitX: 0,
      positionAwaitY: 0,
      subMenuX: 0,
      subMenuY: 0,
      isOutside: false,
      isOutside2: false,
      column: null,
      clearvali: true,
      row: null,
      searchlist: [],
      templist: [],
      edittemp: [],
      itemdata: [],
      exceldata: [],
      tempEmpInfoForaddPayroll: [],
      dummyImage: require("@/assets/images/SSF Logo2.jpg"),
      ver1arr: [],
      ver2arr: [],
      List: [],
      ver2_thaiMonth: "",
      ver2_thaiYear: "",
      ver1: {
        companyName: "Thety",
        accountNumber: "1234567890123",
        address : "",
        subDistrict: "MyaKhwarNyo",
        district: "TharKayTa",
        province: "Yangon",
        postalcode: "116006",
        telephone: "094548515426",
        contributionRate: "1000",
        thaiYear: "",
        thaiMonth: "",
        totalWages: "111",
        totalAmount: "111",
        totalAmount2: "111",
        finalTotal: "111",
        currencyBaht: "",
        insuredPerson: "0",
        ver2pages: "0",
        paymentDate: "",
      },
      checkEmpListForAddStaff1: [],
      url: enurl.apiUrl,
      editadd: false,
      mypersonaltax: 0,
      mysocialsec: 0,
      mypvd: 0,
      disableworkingday: true,
      disableOT: true,
      disablestampday: true,
      disablelateday: true,
      payslipdetail: {
        workingstartdate: "",
        mydeduction: 0,
        workingenddate: "",
        updatedate: "",
        company: null,
        empID: null,
        empcode: null,
        empname: null,
        position: null,
        workingdays: null,
        regularsalary: null,
        rateperday: 0,
        rateperhour: 0,
        YTD: 0,
        OThours: 0,
        OTrate: 0,
        OTPay: 0,
        OThours2: null,
        OTrate2: 0,
        OTPay2: 0,
        OThours3: null,
        OTrate3: 0,
        OTPay3: 0,
        OThours4: null,
        OTrate4: 0,
        OTPay4: 0,
        OTPayTotal: 0,
        Notstampdays: null,
        Notstamprate: 0,
        NotstampDeduction: 0,
        latedays: 0,
        laterate: 0,
        latededuction: 0,
        totalincome: 0,
        totalincomecalculatetax: 0,
        totalincomecalculatesocialsecurity: 0,
        checkpersonaltax: false,
        checksocialsecurity: false,
        checkprovident: false,
        personaltax: 0,
        socialsecurity: 0,
        providentfund: 0,
        totaldeduction: 0,
        totalnetincome: 0,
        alldeduction: 0,
        mychange: 0,
        personalallowanceperyear: 0
      },
      checkpersonaltax: 0,
      rwimages: {
        Right: require("@/assets/images/Group 8356.png"),
        Wrong: require("@/assets/images/Group 8357.png")
      },
      ActiveRadio: 0,
      Radio1: 0,
      Radio2: 1,
      ActiveRadioForEditPayDue: 0,
      checkemplistforcheckstaff: false,
      editclick: false,
      payrollaction: false,
      LoadingDialog: false,
      LoadingDialog2: false,
      bgcolor: "",
      SearchReportTo: [],
      SearchEmailTo: [],
      Searchforemplist: [],
      EditProvidentList: [],
      TempReportToList: [],
      TempEmailList: [],
      Payrolldeductionlist: [],
      updatestafftimeemplist: [],
      payrollInfo: {
        selected: [],
        childSelected: [],
        payroll_Period_ID: null,
        period_ID: null,
        payroll_start_Date: null,
        payroll_end_Date: null,
        payroll_type: "transfer",
        payment_date: null,
        payroll_start_Date_notFormat: null,
        payroll_end_Date_notFormat: null,
        calender_income_date: null
      },
      payrollOption: null,
      initialSelectAll: false,
      initialSelectAll1: false,
      empList: [],
      emailempList: [],
      emaildialog: false,
      Searchemailsentto: [],
      checkemplistforEmailcheck: [],
      myid: 0,
      additionaldeduction: [
        {
          id: 0,
          name: null,
          amount: 0,
          show: true,
          errormessagededuname: ""
        }
      ],
      checkEmpListForAddStaff: [],
      checkEmpListForAddStaff_Emp: [],
      checkemplistforeditstaff: [],
      checkemplistforpayslipdetail: [],
      paymentDueSetupData: [],
      EditpaymentDuesetup: [],
      totalExpenses: 0,
      totalNetPaid: 0,
      totalNetpaid_Unchange: 0,
      totalSalary: 0,
      totalAddition: 0,
      totalDeduction: 0,
      totalTransfer: 0,
      totalCash: 0,
      totalNumberOfEmployee: 0,
      empInfoForaddPayroll: [],
      payrollData: [],
      tabone: false,
      empTypeID: null,
      togglevalue: 0,
      datelogo: require("@/assets/images/Group 8910.svg"),
      datelogow: require("@/assets/images/Group 8910white.svg"),
      daylogo: require("@/assets/images/Group 8908.svg"),
      daylogow: require("@/assets/images/Group 8908white.svg"),
      status: "In Progress",
      bankfee: 0,
      additionOfNetPaidAndBankFee: 0,
      editpaydueList: [],
      editpaydueDetailList: [],
      paymentDue_ID: 0,
      addStaffBtnShow: false,
      editBtnShow: false,
      actualState: null,
      regularEarningOption: null,
      regularEarningOptionNumber: null,
      editPayrollID: null,
      disableduebtn: false,
      ShowPayrollforAddPayDueInEdit: true,
      checkbox: true,
      deletePayrollId: null,
      PrintDataList: [],
      PrintData: null,
      TotalIncome_THB: null,
      TotalNet_THB: null,
      NetIncome_THB: null,
      YTDIncome: null,
      YTDwithholdingtax: null,
      AccumulateSSF: null,
      TempEditPayroll: null,
      firstEdit: false,
      companyBankAccount: null,
      companyName: null,
      confirmEdit: false,
      activeRadioRegularEarning: 0,
      showworkingdays: "Working Days",
      selectForedit: [],
      paymentAcc_ID: 0,
      dueDetailResultFromEdit: [],
      disabledAddPayment: false,
      showResetButton: false,
      paidBy: "Transfer",
      todaymonths: null,
      todayyears: new Date(),
      thisMonths: "",
      thisYear: "",
      addStaffCount: 0,
      addWageEmployeeCountn: 0,
      StatusPerEmp: null,
      SalaryPerEmp: 0,
      editPaymentDueId: null,
      paymentDueConfirm: false,
      resetPayrollBtn: false,
      images: {
        edit: require("@/assets/images/Mask Group 178.png"),
        edit1: require("@/assets/images/pencil-line.png"),
        plus: require("@/assets/images/—Pngtree—button plus icon_3566857.png"),
        delete: require("@/assets/images/Mask Group 177.png"),
        delete1: require("@/assets/images/trash-can-line.png"),
        up: require("@/assets/images/uparrowgreen.png"),
        down: require("@/assets/images/downarrowred.png"),
        print: require("@/assets/images/Group 3272.png"),
        printpdf: require("@/assets/images/Icon awesome-file-download.png"),
        sendemail: require("@/assets/images/sending mail.png")
      },
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png")
      },
      paidEdit: false,
      headers: [
        {
          text: "Pay Period Start Date",
          align: "left",
          value: "paymentPeriod",
          sortable: false,
          class: "header-font"
        },
        {
          text: "Payment Date",
          align: "left",
          value: "paymentDate",
          sortable: false
        },
        {
          text: "Payment Method",
          align: "left",
          value: "paymentMethod",
          sortable: false
        },
        {
          text: "",
          align: "center",
          value: "logo",
          sortable: false
        },
        {
          text: "Employee Expenses",
          align: "left",
          value: "expense",
          sortable: false
        },
        {
          text: "NetPaid",
          align: "left",
          value: "netPaid",
          sortable: false
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false
        },
        {
          text: "Action",
          align: "center",
          value: "actions",
          sortable: false
        }
      ],
      selectAll_Emp: false,
      confrimPaymentData: [],
      thelang: "",
      optionDialog: false,
      customDialog: false,
      payrollDialog: false,
      deleteDialog: false,
      createSetUpDialog: false,
      editSetUpDialog: false,
      staffDialog: false,
      recalculateDialog: false,
      confirmEditDialog: false,
      paymentDetailDialog: false,
      confirmPayment: false,
      payConfirm: false,
      editPayrollDialog: false,
      editCustomDialog: false,
      printDialog: false,
      wageDialog: false,
      recoveremp: [],
      additionlist: [],
      allincomelist: [],
      otheradditionlist: [],
      Selectedincome: 0,
      MyempTax: false,
      MySSF: false,
      PrintRevenueList: [],
      PrintDeductionList: [],
      PrintDeductionCount: 0,
      PrintProvident: 0,
      PrintProvident_Value: 0,
      tempCheckEmpList: [],
      item2: ["Department", "HR", "Sales", "IT"],
      createdDate: this.formatDateForToday(this.todayDate()),
      periodStart: "01-08-2020",
      periodEnd: "31-08-2020",
      payDate: "31-08-2020",
      edstut: false,
      payrollTab: true,
      employeeTab: false,
      slipDetailTab: false,
      EditState: false,
      PaidState: false,
      AddStaffCondition_Salary: true,
      AddStaffCondition_ShiftCheck: true,
      btnshow: false,
      EditText: false,
      companyPaymentAccount: [],
      istherepa: 0,
      deduction: [],
      recalculateemplist: [],
      chooseBank: null,
      myempforprovident: 0,
      ProvidentAlllist: [],
      daymonth: "Days(s)",
      unpaidAmount: 0,
      active: false,
      paymentcalculationlist: [],
      tempPaymentCalculationlist: [],
      disablebtn: false,
      payslipdetaillist: [],
      checklist: [],
      totallist: 0,
      myemp: 0,
      EmpID: 0,
      Deductionlist: [],
      AdditionalEarningList: [],
      PrintmultipleEmplist: [],
      Revenuelistforprint: [],
      Deductionlistforprint: [],
      imgdatalist: [],
      imgbase64list: [],
      totalincomeforprint: null,
      totaldeductionforprint: null,
      netincomeforprint: null,
      disablepdf: true,
      Printpaydue: null,
      Printempdetail: {
        companyName: "",
        Empcode: "",
        Empname: "",
        Position: "",
        Department: "",
        Payrollstartdate: "",
        Payrollenddate: "",
        PaymentDate: "",
        Bank: "",
        YTDincome: 0,
        YTDwithholdingtax: 0,
        AccumulateSSF: 0
      },
      Printfilename: "",
      selectallprintemp: false,
      checkmytax: 0,
      myadd: "Additional Earnings",
      modules: [
        {
          id: 1,
          name: "Business",
          checked: false
        },
        {
          id: 2,
          name: "Business 2",
          checked: true
        }
      ],
      activeemp: 0,
      tempPaymentDueSetupData: [],
      pkgemp: 0,
      thePND: {
        companyName: "Thetys",
        company_Address: "Taunggyi",
        subDistrict: "over here",
        district: "over there",
        province: "Yangon",
        postalCode: "556",
        total_Income: 50564,
        total_Tax: 3577,
        employee_Total: 10
      },
      thesheetnum: 0,
      theAtchempList: [],
      themon: 0,
      theFileName: "",
      theAtch: {
        companyTaxID: "",
        companyName: "Thetys",
        company_Address: "Taunggyi",
        subDistrict: "over here",
        district: "over there",
        province: "Yangon",
        postalCode: "556",
        total_Tax: 3577,
        employee_Total: 10,
      },
      theuploadlength: 0,
      theuploaded: 0,
      theMsg: "",
      menu11: false,
      menu12: false,
      creating: {
        PayPerStartDate: new Date(Date.now()).toISOString().substr(0, 10),
        PayPerEndDate: new Date(Date.now()).toISOString().substr(0, 10)
      },
      OTList: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
      Commonsocial: {
        lowestsalary: 1650,
        highestsalary: 15000,
        highestSubvention: 750,
        lowestSubvention: 83,
        percentage: 5,
      },
      empIdandpayId: [],
      updatedEmp: [],
      selectedText: false,
      oldnotclkin: 0,
      newnotclkin: 0,
      thepayacc: {
        icon: null,
        bank_Name: null,
        bankName_EN: null,
        fullName: null,
      },
    };
  },
  watch: {
    "payrollInfo.payroll_start_Date_notFormat"(date) {
      this.startdateAdd = [];
      if (new Date(date) > new Date(this.payrollInfo.payroll_end_Date_notFormat)) {
        this.payrollInfo.payroll_end_Date_notFormat = null;
      }
    },
    page1() {
      this.updatePaginatedData();
    },
    itemsPerPage1() {
      this.updatePaginatedData();
    },
    "payslipdetail.workingdays"(){
      this.removecommapayslip();
      this.changetodecimal();
      if(this.empButton == 2)
      {
        this.payslipdetail.YTD = this.payslipdetail.regularsalary * this.payslipdetail.workingdays;
        this.payslipdetail.totalincome = this.payslipdetail.YTD + this.payslipdetail.OTPayTotal;
        if(this.payslipdetail.workingdays == 0){
          this.payslipdetail.Notstampdays = 0;
        } else {
          this.payslipdetail.Notstampdays = this.oldnotclkin;
        }
      }
      else
      {
        this.payslipdetail.YTD = 0;
        this.payslipdetail.totalincome = this.payslipdetail.regularsalary + this.payslipdetail.OTPayTotal;
      }

      this.payslipdetail.totalincomecalculatetax = this.payslipdetail.totalincome;//salary + TotalOT
      this.payslipdetail.totalincomecalculatesocialsecurity = this.payslipdetail.totalincome;//salary + TotalOT
      for (let i = 0; i < this.additionlist.length; i++)
      {
        if(this.additionlist[i].Tax == true)
        {
          this.payslipdetail.totalincomecalculatetax = this.payslipdetail.totalincomecalculatetax + this.additionlist[i].amount;
        }
        if(this.additionlist[i].SSF == true)
        {
          this.payslipdetail.totalincomecalculatesocialsecurity = this.payslipdetail.totalincomecalculatesocialsecurity + this.additionlist[i].amount;
        }
        this.payslipdetail.totalincome = this.payslipdetail.totalincome + parseFloat(this.removecomma(this.additionlist[i].amount));
      }
      this.putdecimalpayslip();
      this.putcommapayslip();
      this.checkpersonaltaxon();
      if(this.edstut != true){
        this.checksocail();
        this.changerate();
      }
    },

    thelang() {
      this.tracethehead();
    },

    bankfee(e) {
      this.calcalutionforBankFeeAndNetPaid(e);
    },

    assignpsw(e) {
      if (e)
      {
        this.errormsgpsw = false;
      }
    },

    SearchReportTo() {
      if (this.empList.length != this.TempReportToList.length) 
      {
        this.AddStaff();
      }
      //this.clearvali = true;
    },

    forsearch(e) {
      this.searchemp(e);
    },

    Searchforemplist() {
      // this.searchReportemplist(e);
      this.EditpaymentDuesetup = [];
      this.EditpaymentDuesetup = this.checklist;
    },

    tab(e) {
      if (e == "one") 
      {
        this.payrollTab = true;
        this.employeeTab = false;
        this.slipDetailTab = false;
        this.btnshow = false;
        this.editPaymentDueId = 0;
        this.payslipdetail.mychange = 0;
      }
      if (e == "two") 
      {
        this.payslipdetail.mychange = 0;
        this.btnshow = false;
        // this.editPaymentDueId=0;
      }
    },

    PrintProvident_Value(e) {
      this.provident(e);
    },

    itemsPerPage() {
      this.GetPayrollData();
    }
  },
  computed: {
    combinedLabel() {
      return `${this.$t('PersonalTax/Month')}\n${this.$t('PaySlipDetail')}`;
    },
    // selectAll_Emp: {
    //   get: function () {
    //     return this.initialSelectAll || this.empList ? this.checkEmpListForAddStaff.length == this.empList.length : false;
    //   },
    //   set: function (value) {
    //     let selected = [];    
    //     if (value) 
    //     {
    //       this.empList.forEach(function (user) {
    //           selected.push(user.employee_ID);
    //       });
    //     }
    //     this.checkEmpListForAddStaff = selected;
    //     this.initialSelectAll = value;
    //   }
    // },
    imagesIcon() {
      return this.$store.state.images;
    },
    selectAll_EmpTab2: {
      get: function () {
        return this.initialSelectAll1 || this.EditpaymentDuesetup ? this.checkemplistforeditstaff.length == this.EditpaymentDuesetup.length : false;
      },
      set: function (value) {
        let selected = [];        
        if (value) 
        {
          this.EditpaymentDuesetup.forEach(function (user) {
              selected.push(user.empID);
          });
        }
        this.checkemplistforeditstaff = selected;
        this.initialSelectAll1 = value;
      }
    },

    thaiMonths() {
      return [
        { val: 1, txt: "มกราคม" },
        { val: 2, txt: "กุมภาพันธ์" },
        { val: 3, txt: "มีนาคม" },
        { val: 4, txt: "เมษายน" },
        { val: 5, txt: "พฤษภาคม" },
        { val: 6, txt: "มิถุนายน" },
        { val: 7, txt: "กรกฎาคม" },
        { val: 8, txt: "สิงหาคม" },
        { val: 9, txt: this.$t("กันยายน") },
        { val: 10, txt: this.$t("ตุลาคม") },
        { val: 11, txt: this.$t("พฤศจิกายน") },
        { val: 12, txt: this.$t("ธันวาคม") }
      ];
    },

    OTheaders() {
      return [
        {
          text: this.$t("Code"),
          value: "Code",
          align: "left",
          sortable: false,
          width: "100px"
        },
        {
          text: this.$t("oTHours"),
          value: "OThours",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("OTRate"),
          value: "OTRate",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("OTPay"),
          value: "OTPay",
          align: "center",
          sortable: false
        }
      ];
    },

    OTitems() {
      return [
        {
          text: this.$t("OT1")
        },
        {
          text: this.$t("OT2")
        },
        {
          text: this.$t("OT3")
        },
        {
          text: this.$t("OT4")
        }
      ];
    },

    Checkingtranslation() {
      return function (value) {
        return value == "Paid" ? this.$t("Paid") : this.$t("awaiting");
      };
    },

    Checkingtranslationfortable() {
      return function (value) {
        return value == "Completed" ? this.$t("Completed") : this.$t("awaiting");
      };
    },

    itemsMenu() {
      return [
        { val: 1, Title: this.$t("coverPage") },
        { val: 2, Title: this.$t("attachmentpayroll") },
      ];
    },

    ssoitems() {
      return [
        { val: 1, Title: "SSO 1-10/1 ver.1" },
        { val: 2, Title: "SSO 1-10/1 ver.2" }
      ];
    },

    monthNames() {
      return [
        { val: 0, txt: this.$t("All") },
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") }
      ];
    },

    tab: {
      set(tab) {
        this.$router
          .replace({ query: { ...this.$route.query, tab } })
          .catch(() => { });
      },
      get() {
        return this.$route.query.tab;
      },
    },

    computedDateFormatted1() {
      return this.formatCalendarIncomeDate(this.payrollInfo.calender_income_date);
    },

    computedDateFormatted2() {
      return this.formatCalendarIncomeDate(this.payrollInfo.payroll_start_Date);
    },

    computedDateFormatted3() {
      return this.formatCalendarIncomeDate(this.payrollInfo.payroll_start_Date_notFormat);
    },

    computedDateFormatted4() {
      return this.formatCalendarIncomeDate(this.payrollInfo.payroll_end_Date);
    },

    computedDateFormatted5() {
      return this.formatCalendarIncomeDate(this.payrollInfo.payroll_end_Date_notFormat);
    },

    computedDateFormatted6() {
      return this.formatCalendarIncomeDate(this.payslipdetail.updatedate);
    },

    coDPSDate() {
      return this.formatCalendarIncomeDate1(this.creating.PayPerStartDate);
    },

    coDPEDate() {
      return this.formatCalendarIncomeDate(this.creating.PayPerEndDate);
    },

    thethaihead() {
      return this.tracethehead();
    }
  },

  async mounted() {
    try
    {
      this.LoadingDialog = true;
      //let d = new Date();
      this.todaymonths = this.monthNames[0].val;
      this.timerInterval = setInterval(() => {
        this.thelang = localStorage.getItem("Templang");
      }, 100);
      await this.Getpayrollcalculation();
      await this.GetDeduction();
      await this.GetPayrollData();
      await this.Getfunddeduction();
      await this.getPaymentAccount();
      await this.GetProvidentfund();
      await this.GetPersonal();
      this.Getsocilacommon();
      this.permission = this.CheckViewAddEditDelete(41, 42, 43, 44);
      if(this.permission != [])
      {
        this.LoadingDialog = false;
      }
      this.pageCount1 = Math.ceil(this.paymentDueSetupData.length / this.itemsPerPage1);
      this.updatePaginatedData();
    }
    catch(error)
    {
      this.LoadingDialog = false;
      alert(error);
    }
  },

  methods: {
    checkmyemp() {
      let checkvalidate = this.empList.filter((data) => data.checked == true);

      let checkfalsevalidate = this.empList.filter((date) => date.checked == false);
      if (checkfalsevalidate.length != 0) {
        this.selectAll_Emp = false;
      }

      if (checkvalidate.length != 0) {
        if (checkvalidate.length == this.empList.length) {
          this.selectAll_Emp = true;
        }
      }
      this.checkEmpListForAddStaff = this.empList.filter(user => user.checked).map(user => user.employee_ID);
    },
    checkall(value) {
      this.empList = this.empList.map((v) => ({
        ...v,
        checked: value,
      }));
      this.checkEmpListForAddStaff = this.empList.filter(user => user.checked).map(user => user.employee_ID);

    },
    allowedEndDates(date) {
      if (!this.payrollInfo.payroll_start_Date_notFormat) return false;
      const startDate = new Date(this.payrollInfo.payroll_start_Date_notFormat);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(date);
      endDate.setHours(0, 0, 0, 0); 
      return endDate < startDate;
    },
    updatePaginatedData() {
      const start = (this.page1 - 1) * this.itemsPerPage1;
      const end = start + this.itemsPerPage1;
      this.paginatedData = this.paymentDueSetupData.slice(start, end);
    },
    clickpagiemp(){
      this.updatePaginatedData();
    },
    updateItemsPerPage(value) {
      this.itemsPerPage1 = parseInt(value, 10);
      this.page1 = 1; // Reset to first page on items per page change
      this.updatePaginatedData();
    },
    returnZeroIfNull(value) {
      if (value === null || value === undefined)
      {
        return 0;
      }
      else
      {
        return value;
      }
    },

    addOTtotal(e) {
      let result = e.addition + (e.oT_Hours * e.oT_Rate) + (e.oT_Hours_2 * e.oT_Rate_2) + (e.oT_Hours_3 * e.oT_Rate_3) + (e.oT_Hours_4 * e.oT_Rate_4);
      return result;
    },

    payrollOpen(btnNumber) {
      this.activeButton = btnNumber;
      this.empButton = 1;
      this.paybyButton = 1;
    },
    
    employeeType(btnnumber) {
      this.empButton = btnnumber;
    },

    Payby(btnNumber) {
      this.paybyButton = btnNumber;
      this.ActiveRadioForEditPayDue = btnNumber;
    },

    dualclick(v) {
      this.togglevalue=v;
      this.empTypeID = v;
      this.page = 1;
      this.GetPayrollData();
    },

    ChangeMonth() {
      let self = this;
      self.page = 1;
      self.GetPayrollData();
    },

    async exportssoexcel(item) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        paymentDue_ID: item.paymentDue_ID,
        period_ID: item.periodID,
        companyID: store.state.companyID
      };
      await axios.post(`${self.url}Payment_Due/GetPaydueDetail`, temp)
      .then(function (response) 
      {
        self.itemdata = JSON.parse(JSON.stringify(response.data.data));
      });
      self.exceldata = [];
      self.exceldata = self.itemdata[0].emplist;
      let wsdata = [];
      for (let v of self.exceldata) 
      {
        wsdata.push({
          ["หมายเลขบัตรประชาชน"]: v.personal_ID,
          ["คำนำหน้าชื่อ"]: v.prefix,
          ["ชื่อ"]: v.firstName,
          ["นามสกุล"]: v.surName,
          ["เงินเดือน"]: v.salary,
          ["ประกันสังคม"]: v.socialsecurity
        });
      }
      self.LoadingDialog = false;
      // // Create a worksheet
      let ws = XLSX.utils.json_to_sheet(wsdata);
      // Create a excel workbook and add worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Social Security");
      // Download the excel file
      XLSX.writeFile(wb, "Social Security Report.xlsx", {
        compression: true
      });
    },

    Print() {
      let self = this;
      self.thelang = localStorage.getItem("Templang");
      self.iframeShow = !self.iframeShow;
      const iframe = document.getElementById("i_frame");
      if (iframe != null)
      {
        iframe.contentWindow.location.reload();
        iframe.src = iframe.src.split("?")[0] + "?_=" + new Date().getTime();
      }
      // eslint-disable-next-line no-self-assign
    },

    Cancel() {
      this.printdialogforupdate = false;
      this.iframeShow = false;
    },

    tracethehead() {
      if (this.thelang == "ประเทศไทย") 
      {
        return true;
      } 
      else 
      {
        return false;
      }
    },

    async Getemplistforpdf() {
      let self = this;
      let temp = {
        payDueID: self.Printpaydue,
        companyID: store.state.companyID
      };
      await axios.post(`${self.url}Payment_Due/Getemplistforprintpdf`, temp)
      .then(function (response) 
      {
        self.PrintmultipleEmplist = response.data.data;
        self.disablepdf = false;
      });
    },

    clearallpdf() {
      this.Revenuelistforprint = [];
      this.Deductionlistforprint = [];
    },

    async Caluclateforprintpdf(ID) {
      let self = this;
      await self.printYTDfordownloadandemail(ID);
      self.clearallpdf();
      self.PrintmultipleEmplist.map(function (e) {
      if (e.empID == ID) 
      {
        self.Printempdetail.Empcode = e.employee_Code;
        self.Printempdetail.companyName = e.companyName.includes(".") ? e.companyName.replace(/\./g, "") : e.companyName;
        self.Printempdetail.Department = e.department_Name;
        self.Printfilename = `Payslip-${self.Printempdetail.companyName}-${self.formatDatePDFName(self.Printempdetail.PaymentDate)}`;
        self.Printempdetail.Empname = e.empname;
        self.Printempdetail.Bank = self.bankAccountSplit(e.bankname);
        self.Printempdetail.Position = e.positioinName;
        let salaryam = 0;
        if(e.employmentTypeID == 2){
          salaryam = parseFloat(e.salaryamount) * parseInt(e.workingDayorHour);
        } else {
          salaryam = e.salaryamount;
        }
        self.Revenuelistforprint.push(
          {
            name: "Salary",
            value: salaryam
          },
          {
            name: "OT",
            value: parseFloat((e.oT_Rate * e.oT_Hours) + (e.oT_Rate_2 * e.oT_Hours_2) + (e.oT_Rate_3 * e.oT_Hours_3) + (e.oT_Rate_4 * e.oT_Hours_4)),
          }
        );

        if (e.additionallist[0]) 
        {
          e.additionallist.map(function (data) {
            if (data.name) 
            {
              self.Revenuelistforprint.push({
                name: data.name,
                name_Eng: data.name_Eng,
                value: data.amount
              });
            }
          });
        }

        if (e.social_Security_Fund === 0) 
        {
          self.Deductionlistforprint.push({
            name: "Social Security",
            value: parseInt(e.social_Security_Fund)
          });
        } 
        else 
        {
          if (e.socialSecurity == true) 
          {
            let social = 0;
            let isnumber = Number.isInteger(e.social_Security_Fund);
            if (!isnumber) 
            {
              if (e.social_Security_Fund.includes(" ")) 
              {
                social = parseInt(e.social_Security_Fund.split(" "));
              } 
              else 
              {
                social = parseInt(e.social_Security_Fund);
              }
            } 
            else 
            {
              social = parseInt(e.social_Security_Fund);
            }

            self.Deductionlistforprint.push({
              name: "Social Security",
              value: social
            });
          } 
          else if (e.socialSecurity == false) 
          {
            self.Deductionlistforprint.push({
              name: "Social Security",
              value: 0
            });
          }
        }

        if (e.personalTax === true) 
        {
          self.Deductionlistforprint.push({
            name: "Tax",
            value: e.personal_Tax
          });
        } 
        else
        {
          self.Deductionlistforprint.push({
            name: "Tax",
            value: 0
          });
        }

        if (e.providentFund == true) 
        {
          self.Deductionlistforprint.push({
            name: "PVD",
            value: e.provident_Fund_Amount
          });
        } 
        else 
        {
          self.Deductionlistforprint.push({
            name: "PVD",
            value: parseInt(0)
          });
        }

        if (e.deductionlist[0]) 
        {
          e.deductionlist.map(function (data) {
            if (data.name !== "") 
            {
              self.Deductionlistforprint.push({
                name: data.deduction,
                value: data.amount
              });
            }
          });
        }

        if (e.late_Rate > 0) 
        {
          self.Deductionlistforprint.push({
            name: "Late Deduction",
            value: e.late_Rate * e.late_Days,
          });
        }

        if (e.stamp_Rate > 0 && e.employmentTypeID == 1) 
        {
          self.Deductionlistforprint.push({
            name: "Not Stamp Deduction",
            value: e.stamp_Rate * e.stamp_Days
          });
        }
        self.totalincomeforprint = 0;
        self.totaldeductionforprint = 0;
        self.netincomeforprint = 0;
        if (self.PrintRevenueList) 
        {
          self.Revenuelistforprint.map(function (data) {
            self.totalincomeforprint = self.totalincomeforprint + parseFloat(data.value);
          });

          self.Deductionlistforprint.map(function (data) {
            self.totaldeductionforprint = self.totaldeductionforprint + parseFloat(data.value);
          });
        }

        if (self.totalincomeforprint)
        {
          self.netincomeforprint = parseFloat(self.totalincomeforprint) - parseFloat(self.totaldeductionforprint);
        }
        self.decimalformultipleprint();
        self.commalformultipleprint();
      }
      });
    },

    async printYTDfordownloadandemail(empID) {
      let self = this;
      self.Printempdetail.YTDincome = 0;
      self.Printempdetail.AccumulateSSF = 0;
      self.Printempdetail.YTDwithholdingtax = 0;
      let temp = {
        employeeID: empID,
        companyID: store.state.companyID
      };
      await axios.post(`${self.url}Payment_Due/Getempdataforprintpdf`, temp)
        .then(function (response) {
          let printdatalist = response.data.data.result;
          if (printdatalist.length != 0) 
          {
            let Getpaymentyear = moment(self.Printempdetail.PaymentDate).year();
            let Getpaymentmonth = moment(self.Printempdetail.PaymentDate).month();
            let currentyear = moment().year();
            for (var i = Getpaymentyear; i < currentyear + 1; i++) 
            {
              printdatalist.map(function (data) {
                if (moment(data.year).year() == i && moment(data.year).month() <= Getpaymentmonth && data.paymentDue_ID <= self.Printpaydue) 
                {
                  self.Printempdetail.YTDincome = self.Printempdetail.YTDincome + data.income;
                  self.Printempdetail.AccumulateSSF = self.Printempdetail.AccumulateSSF + data.ssf;
                  // alert(self.YTDIncome);
                  self.Printempdetail.YTDwithholdingtax = self.Printempdetail.YTDwithholdingtax + data.personal_Tax;
                  // alert( self.Printempdetail.YTDincome);
                }
              });
            }
          }
        });
    },

    async pdflist() {
      let input = this.$refs.content1;
      const canvas = await html2canvas(input);
      const data = canvas.toDataURL("image/png");
      //let data = canvas.toDataURL("image/png");
      let pdfWidth = 612;
      this.imgdatalist.push({
        imgdata: data,
        height: (pdfWidth / canvas.width) * canvas.height
      });
    },

    async generatePdf() {
      if (this.assignpsw !== "" && this.assignpsw !== null) 
      {
        let pswlength = this.assignpsw;
        if (pswlength.length >= 8) 
        {
          this.LoadingDialog = true;
          this.imgdatalist = [];
          const doc = new jsPDF({
            orientation: "l",
            unit: "mm",
            format: [210, 139.7],
            encryption: {
              userPassword: this.assignpsw, // Set your desired user password
              ownerPassword: this.assignpsw, // Set your desired owner password
              userPermissions: ["print", "copy"] // Optional: Define user permissions
            }
          });

          let numberOfImages = this.PrintmultipleEmplist.length;
          for (let i = 0; i < numberOfImages; i++) 
          {
            await this.Caluclateforprintpdf(this.PrintmultipleEmplist[i].empID);
            doc.addFileToVFS("THSarabunPSK.ttf", this.ThaiFontBase64());
            doc.addFileToVFS("THSarabunPSKBold.ttf", this.ThaiFontBoldBase64());
            doc.addFont("THSarabunPSKBold.ttf", "THSarabunPSKBold", "normal");
            doc.addFont("THSarabunPSK.ttf", "THSarabunPSK", "normal");
            let boldfont = "THSarabunPSKBold";
            let normalfont = "THSarabunPSK";
            doc.setFont(boldfont);
            doc.setFontSize(14);
            doc.text(13, 15, this.Printempdetail.companyName);
            doc.text(180, 15, this.$t("Payslip"));
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(
              13,
              23,
              this.$t("employeeid") + ": " + this.Printempdetail.Empcode
            );

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(
              75,
              23,
              this.$t("EmployeeName") + ": " + this.Printempdetail.Empname
            );

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(
              155,
              23,
              this.$t("PaymentDate") +
              ": " +
              this.formatDatePDFName(this.Printempdetail.PaymentDate)
            );

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(
              13,
              30,
              this.$t("position") + ": " + this.Printempdetail.Position
            );

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(
              75,
              30,
              this.$t("department") + ": " + this.Printempdetail.Position
            );

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(
              155,
              30,
              this.$t("BankAccount") + ": " + this.Printempdetail.Bank,
              {
                maxWidth: 50
              }
            );

            doc.setFont(boldfont);
            doc.setFontSize(12);
            doc.text(33, 45, this.$t("Income"));

            doc.setFont(boldfont);
            doc.setFontSize(12);
            doc.text(95, 45, this.$t("Deductions"));

            doc.setFont(boldfont);
            doc.setFontSize(12);
            doc.text(165, 45, this.$t("ytd"));

            doc.line(10, 47, 200, 47);

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(144, 53, this.$t("ytdincome"));

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(195, 53, this.Printempdetail.YTDincome, {
              align: "right"
            });

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(144, 59, this.$t("ytdwitholding"));

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(195, 59, this.Printempdetail.YTDwithholdingtax, {
              align: "right"
            });

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(144, 65, this.$t("accmulatedssf"));

            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(195, 65, this.Printempdetail.AccumulateSSF, {
              align: "right"
            });

            let incomeheight = 53;
            this.Revenuelistforprint.map(data => {
              if (data.name == "Salary")
              {
                doc.setFont(normalfont);
                doc.setFontSize(14);
                doc.text(14, incomeheight, this.$t("Salary"));
              }
              else if (data.name == "OT")
              {
                doc.setFont(normalfont);
                doc.setFontSize(14);
                doc.text(14, incomeheight, this.$t("OT"));
              }
              else
              {
                doc.setFont(normalfont);
                doc.setFontSize(14);
                doc.text(
                  14,
                  incomeheight,
                  this.thethaihead == true
                    ? data.name
                    : data.name_Eng
                      ? data.name_Eng
                      : ""
                );
              }
              doc.setFont(normalfont);
              doc.setFontSize(14);
              doc.text(65, incomeheight, data.value, { align: "right" });
              incomeheight = incomeheight + 7;
            });
            let deductionheight = 53;
            this.Deductionlistforprint.map((data) => 
            {
              let name = data.name;
              if (data.name == "Social Security") {
                name = this.$t("social_security");
              }
              if (data.name == "Tax") 
              {
                name = this.$t("witholding");
              }
              if (data.name == "PVD") 
              {
                name = this.$t("provident_fund");
              }
              doc.setFont(normalfont);
              doc.setFontSize(14);
              doc.text(75, deductionheight, name);
              doc.setFont(normalfont);
              doc.setFontSize(14);
              doc.text(135, deductionheight, data.value, { align: "right" });
              deductionheight = deductionheight + 6;
            });

            let mylineheight = 0;
            if (incomeheight > deductionheight) 
            {
              mylineheight = incomeheight;
            }
            else if (deductionheight > incomeheight) 
            {
              mylineheight = deductionheight;
            }
            else 
            {
              mylineheight = incomeheight;
            }
            mylineheight = mylineheight + 6;
            doc.line(10, mylineheight, 140, mylineheight);
            //88
            //doc.setTextColor("#FFFFFF");
            doc.setFillColor("#D9D9D9");
            // doc.setLineWidth(0.6);
            doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
            doc.rect(10, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
            doc.fillStroke();
            doc.setFillColor("#D9D9D9");
            // doc.setLineWidth(0.6);
            doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
            doc.rect(70, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
            doc.fillStroke();
            mylineheight = mylineheight + 4;
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(14, mylineheight, this.$t("TotalIncome"));
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(65, mylineheight, this.totalincomeforprint, {
              align: "right"
            });
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(75, mylineheight, this.$t("TotalDeductions"));
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(135, mylineheight, this.totaldeductionforprint, {
              align: "right"
            });
            doc.setLineWidth(0.2);
            let totalhorizontalline = mylineheight + 2;
            doc.line(10, totalhorizontalline, 140, totalhorizontalline);
            let firstverticalline = mylineheight + 5;
            doc.line(70, 40, 70, firstverticalline);
            let secondverticalline = mylineheight + 8;
            doc.line(140, 40, 140, secondverticalline);
            doc.setFillColor("#D9D9D9");
            doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
            doc.rect(10, totalhorizontalline, 100, 6, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
            doc.fillStroke();
            mylineheight = mylineheight + 6;
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(95, mylineheight, this.$t("netpay"));
            doc.setLineWidth(0.2);
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(135, mylineheight, this.netincomeforprint, {
              align: "right"
            });
            doc.rect(10, 40, 190, mylineheight - 38);
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(12, mylineheight + 8, this.$t("text"), { maxWidth: 190 });
            if (i < numberOfImages - 1) 
            {
              doc.addPage();
            }
          }
          doc.save(this.Printfilename);
          this.assignpswdialog = false;
          this.assignpsw = "";
          this.LoadingDialog = false;
        } 
        else 
        {
          this.errormsgpswformat = true;
        }
      } 
      else 
      {
        this.errormsgpsw = true;
      }
    },

    async createPdf() {
      const pdfDoc = await PDFDocument.create();
      let input = this.$refs.content;
      let pdfWidth = 612;
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      const page = pdfDoc.addPage();
      const image = await pdfDoc.embedPng(imgData);
      const imageWidth = pdfWidth;
      const imageHeight = (imageWidth / image.width) * image.height;
      const imageY = page.getHeight() - imageHeight - 10; // Adjust the value as needed
      page.drawImage(image, {
        x: 0,
        y: imageY,
        width: imageWidth,
        height: imageHeight
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "modified_pdf.pdf";
      link.click();
    },

    provident(e) {
      this.PrintProvident = e;
    },

    async openAssigndialog(ID, startdate, enddate, paymentdate) {
      let self = this;
      self.assignpswdialog = true;
      self.Printpaydue = ID;
      self.Printempdetail.Payrollstartdate = startdate;
      self.Printempdetail.Payrollenddate = enddate;
      self.Printempdetail.PaymentDate = paymentdate;
      await self.Getemplistforpdf();
    },

    closeAssigndialog() {
      let self = this;
      self.assignpswdialog = false;
      self.Printpaydue = null;
      self.PrintmultipleEmplist = [];
      self.Printempdetail.Payrollstartdate = "";
      self.Printempdetail.Payrollenddate = "";
      self.Printempdetail.PaymentDate = "";
      self.assignpsw = "";
      self.errormsgpsw = false;
      self.errormsgpswformat = false;
    },

    async openEmaildialog(ID, startdate, enddate, paymentdate) {
      let self = this;
      self.LoadingDialog = true;
      self.emailempList = [];
      let temp = {
        paydue_ID: ID,
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}Payment_Due/Getemplistforemail`, temp)
        .then(function (response) {
          self.emailempList = response.data.data;
          self.TempEmailList = response.data.data;
          self.emaildialog = true;
          self.Printpaydue = ID;
          self.Printempdetail.Payrollstartdate = startdate;
          self.Printempdetail.Payrollenddate = enddate;
          self.Printempdetail.PaymentDate = paymentdate;
          self.Getemplistforpdf();
          self.LoadingDialog = false;
        });
    },

    closeemaildialog() {
      this.emaildialog = false;
      this.checkemplistforEmailcheck = [];
      // alert(this.selectAllemailemp);
      this.selectAllemailemp = false;
    },

    formatDatePDFName(date) {
      if (date) {
        let spliteDateAndTime = date.split("T");
        let Date = spliteDateAndTime[0].split("-");
        return Date[0] + Date[1] + Date[2];
      }
    },

    async Caluclateforprintpdfforemail(ID) {
      let self = this;
      await self.printYTDfordownloadandemail(ID);
      await self.clearallpdf();
      await self.PrintmultipleEmplist.map(function (e) {
        if (e.empID == ID) 
        {
          self.Printempdetail.Empcode = e.employee_Code;
          self.Printempdetail.companyName = e.companyName.includes(".") ? e.companyName.replace(/\./g, "") : e.companyName;
          self.Printempdetail.Department = e.department_Name;
          self.Printfilename = `Payslip-${self.Printempdetail.companyName}-${self.formatDatePDFName(self.Printempdetail.PaymentDate)}`;
          self.Printempdetail.Empname = e.empname;
          self.Printempdetail.Bank = self.bankAccountSplit(e.bankname);
          self.Printempdetail.Position = e.positioinName;
          let salaryam = 0;
          if(e.employmentTypeID == 2){
            salaryam = parseFloat(e.salaryamount) * parseInt(e.workingDayorHour);
          } else {
            salaryam = e.salaryamount;
          }
          self.Revenuelistforprint.push(
            {
              name: "Salary",
              value: salaryam
            },
            {
              name: "OT",
              value: parseFloat((e.oT_Rate * e.oT_Hours) + (e.oT_Rate_2 * e.oT_Hours_2) + (e.oT_Rate_3 * e.oT_Hours_3) + (e.oT_Rate_4 * e.oT_Hours_4)),
            }
          );

          if (e.additionallist[0]) 
          {
            e.additionallist.map(function (data) {
              if (data.name) 
              {
                self.Revenuelistforprint.push({
                  name: data.name,
                  name_Eng: data.name_Eng,
                  value: data.amount
                });
              }
            });
          }
          if (e.social_Security_Fund === 0) 
          {
            self.Deductionlistforprint.push({
              name: "Social Security",
              value: parseInt(e.social_Security_Fund)
            });
          } 
          else 
          {
            if (e.socialSecurity == true) 
            {
              let social = 0;
              let isnumber = Number.isInteger(e.social_Security_Fund);
              if (isnumber == false) 
              {
                if (e.social_Security_Fund.includes(" ")) 
                {
                  social = parseInt(e.social_Security_Fund.split(" "));
                } 
                else 
                {
                  social = parseInt(e.social_Security_Fund);
                }
              } 
              else 
              {
                social = parseInt(e.social_Security_Fund);
              }
              self.Deductionlistforprint.push({
                name: "Social Security",
                value: social
              });
            } 
            else if (e.socialSecurity == false) 
            {
              self.Deductionlistforprint.push({
                name: "Social Security",
                value: 0
              });
            }
          }

          if (e.personalTax === true) 
          {
            self.Deductionlistforprint.push({
              name: "Tax",
              value: e.personal_Tax
            });
          } 
          else 
          {
            self.Deductionlistforprint.push({
              name: "Tax",
              value: 0
            });
          }

          if (e.providentFund == true) 
          {
            self.Deductionlistforprint.push({
              name: "PVD",
              value: e.provident_Fund_Amount
            });
          } 
          else 
          {
            self.Deductionlistforprint.push({
              name: "PVD",
              value: parseInt(0)
            });
          }

          if (e.deductionlist[0]) 
          {
            e.deductionlist.map(function (data) {
              if (data.name !== "") 
              {
                self.Deductionlistforprint.push({
                  name: data.deduction,
                  value: data.amount
                });
              }
            });
          }

          if (e.late_Rate > 0) 
          {
            self.Deductionlistforprint.push({
              name: "Late Deduction",
              value: parseFloat(e.late_Rate * e.late_Days),
            });
          }

          if (e.stamp_Rate > 0  && e.employmentTypeID == 1) 
          {
            self.Deductionlistforprint.push({
              name: "Not Stamp Deduction",
              value: e.stamp_Rate * e.stamp_Days
            });
          }
          self.totalincomeforprint = 0;
          self.totaldeductionforprint = 0;
          self.netincomeforprint = 0;
          if (self.PrintRevenueList) 
          {
            self.Revenuelistforprint.map(function (data) {
              self.totalincomeforprint = self.totalincomeforprint + parseFloat(data.value);
            });
            self.Deductionlistforprint.map(function (data) {
              self.totaldeductionforprint = self.totaldeductionforprint + parseFloat(data.value);
            });
          }

          if (self.totalincomeforprint) 
          {
            self.netincomeforprint = parseFloat(self.totalincomeforprint) - parseFloat(self.totaldeductionforprint);
          }
          self.decimalformultipleprint();
          self.commalformultipleprint();
        }
      });
      await self.pdflist();
    },

    async pdflistforemail() {
      let input = this.$refs.content1;
      const canvas = await html2canvas(input);
      const data = canvas.toDataURL("image/png");
      //let data = canvas.toDataURL("image/png");
      let pdfWidth = 612;
      this.imgdatalist.push({
        imgdata: data,
        height: (pdfWidth / canvas.width) * canvas.height
      });
    },

    async Sendmail() {
      let self = this;
      let temp = {
        companyID: store.state.companyID,
        period_State_Date: self.formatDateForTable(self.Printempdetail.Payrollstartdate),
        period_End_Date: self.formatDateForTable(self.Printempdetail.Payrollenddate),
        empList: self.addstafflist,
      };
      await axios.post(`${self.url}Payment_Due/MailEmployeeList`, temp)
      .then(function (response) {
        if (response.data.status == 0) 
        {
          alert("Mail sent successfully");
          self.closeemaildialog();
          self.LoadingDialog = false;
        }
      });
    },

    async AddStaffEmail() {
      this.addstafflist = [];
      this.imgdatalist = [];
      if (this.checkemplistforEmailcheck.length == 0) 
      {
        this.Noitemdialog = true;
      } 
      else 
      {
        this.LoadingDialog = true;
        let promises = [];
        for (let i = 0; i < this.checkemplistforEmailcheck.length; i++) 
        {
          let bd = this.formatDateforDOB(this.checkemplistforEmailcheck[i].birthDate);
          await this.Caluclateforprintpdfforemail(this.checkemplistforEmailcheck[i].employee_ID);
          const doc = new jsPDF({
            orientation: "l",
            unit: "mm",
            format: [210, 139.7],
            encryption: {
              userPassword: bd,
              ownerPassword: bd,
              userPermissions: ["print", "copy"]
            }
          });
          doc.addFileToVFS("THSarabunPSK.ttf", this.ThaiFontBase64());
          doc.addFileToVFS("THSarabunPSKBold.ttf", this.ThaiFontBoldBase64());
          doc.addFont("THSarabunPSKBold.ttf", "THSarabunPSKBold", "normal");
          doc.addFont("THSarabunPSK.ttf", "THSarabunPSK", "normal");
          let boldfont = "THSarabunPSKBold";
          let normalfont = "THSarabunPSK";
          doc.setFont(boldfont);
          doc.setFontSize(14);
          doc.text(13, 15, this.Printempdetail.companyName);
          doc.text(180, 15, this.$t("Payslip"));

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            13,
            23,
            this.$t("employeeid") + ": " + this.Printempdetail.Empcode
          );

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            75,
            23,
            this.$t("EmployeeName") + ": " + this.Printempdetail.Empname
          );

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            155,
            23,
            this.$t("PaymentDate") +
            ": " +
            this.formatDatePDFName(this.Printempdetail.PaymentDate)
          );

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            13,
            30,
            this.$t("position") + ": " + this.Printempdetail.Position
          );

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            75,
            30,
            this.$t("department") + ": " + this.Printempdetail.Position
          );

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            155,
            30,
            this.$t("BankAccount") + ": " + this.Printempdetail.Bank,
            {
              maxWidth: 50
            }
          );

          doc.setFont(boldfont);
          doc.setFontSize(12);
          doc.text(33, 45, this.$t("Income"));

          doc.setFont(boldfont);
          doc.setFontSize(12);
          doc.text(95, 45, this.$t("Deductions"));

          doc.setFont(boldfont);
          doc.setFontSize(12);
          doc.text(165, 45, this.$t("ytd"));

          doc.line(10, 47, 200, 47);

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(144, 53, this.$t("ytdincome"));

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(195, 53, this.Printempdetail.YTDincome, { align: "right" });

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(144, 59, this.$t("ytdwitholding"));

          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(195, 59, this.Printempdetail.YTDwithholdingtax, {
            align: "right"
          });
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(144, 65, this.$t("accmulatedssf"));
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(195, 65, this.Printempdetail.AccumulateSSF, {
            align: "right"
          });
          let incomeheight = 53;
          this.Revenuelistforprint.map(data => {
            if (data.name == "Salary") 
            {
              doc.setFont(normalfont);
              doc.setFontSize(14);
              doc.text(14, incomeheight, this.$t("Salary"));
            } 
            else if (data.name == "OT") 
            {
              doc.setFont(normalfont);
              doc.setFontSize(14);
              doc.text(14, incomeheight, this.$t("OT"));
            } 
            else 
            {
              doc.setFont(normalfont);
              doc.setFontSize(14);
              doc.text(
                14,
                incomeheight,
                this.thethaihead == true
                  ? data.name
                  : data.name_Eng
                    ? data.name_Eng
                    : ""
              );
            }
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(65, incomeheight, data.value, { align: "right" });
            incomeheight = incomeheight + 7;
          });
          let deductionheight = 53;

          this.Deductionlistforprint.map(data => {
            let name = data.name;
            if (data.name == "Social Security")
            {
              name = this.$t("social_security");
            }
            if (data.name == "Tax")
            {
              name = this.$t("witholding");
            }
            if (data.name == "PVD")
            {
              name = this.$t("provident_fund");
            }
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(75, deductionheight, name);
            doc.setFont(normalfont);
            doc.setFontSize(14);
            doc.text(135, deductionheight, data.value, { align: "right" });
            deductionheight = deductionheight + 6;
          });

          let mylineheight = 0;
          if (incomeheight > deductionheight)
          {
            mylineheight = incomeheight;
          }
          else if (deductionheight > incomeheight)
          {
            mylineheight = deductionheight;
          }
          else
          {
            mylineheight = incomeheight;
          }
          mylineheight = mylineheight + 6;
          doc.line(10, mylineheight, 140, mylineheight);
          //88
          //doc.setTextColor("#FFFFFF");
          doc.setFillColor("#D9D9D9");
          // doc.setLineWidth(0.6);
          doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
          doc.rect(10, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
          doc.fillStroke();
          doc.setFillColor("#D9D9D9");
          // doc.setLineWidth(0.6);
          doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
          doc.rect(70, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
          doc.fillStroke();
          mylineheight = mylineheight + 4;
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(14, mylineheight, this.$t("TotalIncome"));
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(65, mylineheight, this.totalincomeforprint, {
            align: "right"
          });
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(75, mylineheight, this.$t("TotalDeductions"));
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(135, mylineheight, this.totaldeductionforprint, {
            align: "right"
          });
          doc.setLineWidth(0.2);
          let totalhorizontalline = mylineheight + 2;
          doc.line(10, totalhorizontalline, 140, totalhorizontalline);
          let firstverticalline = mylineheight + 5;
          doc.line(70, 40, 70, firstverticalline);
          let secondverticalline = mylineheight + 8;
          doc.line(140, 40, 140, secondverticalline);
          doc.setFillColor("#D9D9D9");
          // doc.setLineWidth(0.6);
          doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
          doc.rect(10, totalhorizontalline, 100, 6, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
          doc.fillStroke();
          mylineheight = mylineheight + 6;
          // alert(mylineheight);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(95, mylineheight, this.$t("netpay"));
          doc.setLineWidth(0.2);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(135, mylineheight, this.netincomeforprint, {
            align: "right"
          });

          doc.rect(10, 40, 190, mylineheight - 38);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(12, mylineheight + 8, this.$t("text"), { maxWidth: 190 });
          var dataUrl = doc.output("dataurl");
          let ID = this.checkemplistforEmailcheck[i].employee_ID;
          let name = `Payslip-${this.checkemplistforEmailcheck[i].empname}-${this.formatDatePDFName(this.Printempdetail.PaymentDate)}`;
          promises.push({
            empID: ID,
            pdflink: dataUrl.split(",")[1],
            pdffilename: name,
            bd: bd
          });
        }
        this.addstafflist = promises; // Wait for all promises to resolve
        await this.Sendmail();
        // alert(this.emaildialog);
      }
    },

    selectallemail() {
      let self = this;
      if (!self.selectAllemailemp == true)
      {
        for (let i in self.emailempList) 
        {
          let mydata = self.checkemplistforEmailcheck.filter(
            date => date.employee_ID == self.emailempList[i].employee_ID
          );
          if (mydata.length == 0) 
          {
            self.checkemplistforEmailcheck.push({
              employee_ID: self.emailempList[i].employee_ID,
              empname: self.emailempList[i].empname,
              birthDate: self.emailempList[i].birthDate
            });
          }
        }
      }
      else 
      {
        self.checkemplistforEmailcheck = [];
      }
    },

    clickpagi() {
      this.GetPayrollData();
    },

    clear() {
      //this.SearchReportTo = "";
      this.staffDialog = false;
      this.clearvali = false;
      this.addStaffCount = 0;
      this.checkEmpListForAddStaff = this.tempCheckEmpList;
      this.tempPaymentDueSetupData = [];
      this.empList = [];
    },

    async GetPersonal() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}PersonalInfo/GetActiveEmployee`, tempp)
      .then(function(response) {
        if(response.data.status == 0)
        {
          self.Activeemplist = response.data.data;
          if (self.Activeemplist[0].package_ID != 0) 
          {
            self.pkgemp = self.Activeemplist[0].activeEmp;
          }
          self.activeemp = self.Activeemplist[0].currentactiveemp;
        }       
      });
    },

    async GetDeduction() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}Payroll_DefaultDeduction_Setting/GetPayrolldeductionByCompanyID`,temp)
      .then(function (response) 
      {
        self.Payrolldeductionlist = response.data.data;
      });
    },

    async Getallincomeforpayslip() {
      let self = this;
      self.allincomelist = [];
      let temp = { companyID: store.state.companyID, status: self.status };
      await axios.post(`${self.url}Payment_Due/Getincometypeforpay`, temp)
        .then(function (response) {
            if(response.data.status == 0)
            {
              let list = response.data.data;
              let numl = 1;
              for (let i = 0; i < list.length; i++) 
              {
                let temp = {
                  ...list[i],
                  num: numl++
                };
                self.allincomelist.push(temp);
                if (i == 0) 
                {
                  self.Selectedincome = self.allincomelist[0].income_Type_ID;
                  self.MyempTax = self.allincomelist[0].tax_Calculation;
                  self.MySSF = self.allincomelist[0].social_Security_Calculation;
                }
              }
            }
            else
            {
              alert(response.data.message);
            }
          }
        );
    },

    searchemp(input) {
      let self = this;
      self.paymentDueSetupData = [];
      function filterItems() {
        return self.searchlist.filter(function (el) {
          if (input) 
          {
            return (
              el.empname.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
              el.employee_Code.toString().indexOf(input) !== -1
            );
          } 
          else 
          {
            return self.searchlist;
          }
        });
      }
      let a = filterItems();
      self.paymentDueSetupData = a;
    },

    async Getfunddeduction() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}FundDeduction/GetFundDeduction`, temp)
      .then(function (response) {
        self.deduction = response.data.data;
      });
    },

    onlyNumberAdditionalEarnings($event, value, targetProperty) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      let char = String.fromCharCode(keyCode);

      // Allow only numbers, backspace, and decimal point
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46) {
        $event.preventDefault();
      }

      // Prevent typing a second zero at the beginning
      if (value === '0' && keyCode === 48 && !value.includes('.')) {
        $event.preventDefault();
      }

      // Remove leading zeros when a digit is entered after '0'
      if (value === '0' && char >= '0' && char <= '9') {
        if (!value.includes('.')) {
          value = char;
        } else {
          value = '0.' + char;
        }
        this.additionlist.find(temp => temp.id == targetProperty).amount = value;
        $event.preventDefault();
      }

      // Prevent typing a second decimal point
      if (value.includes('.') && keyCode === 46) {
        $event.preventDefault();
      }

      // Allow maximum two decimal places
      if (!this.selectedText && value.includes('.')) {
        let decimalPart = value.split('.')[1];
        if (decimalPart?.length >= 2 && keyCode >= 48 && keyCode <= 57) {
          $event.preventDefault();
        }
      }
      this.selectedText = false;
    },

    onlyNumber($event, value,targetProperty) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      let char = String.fromCharCode(keyCode);
      // Allow only numbers and one decimal point
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8 && keyCode !== 46) 
      {
        $event.preventDefault();
      }
      // Prevent typing a second zero at the beginning
      if (value === '0' && keyCode === 48) 
      {
        $event.preventDefault();
      }
      // Remove leading zeros when a digit is entered after '0'
      if (value === '0' && char >= '0' && char <= '9') {
        this.payslipdetail[targetProperty] = parseInt(value + char).toString();
      }
      // Prevent typing a second decimal point
      if (value.includes('.') && keyCode === 46) 
      {
        $event.preventDefault();
      }
      // Allow maximum two decimal places
      if (!this.selectedText && value.includes('.')) 
      {
        let decimalPart = value.split('.')[1];
        if (decimalPart?.length >= 2 && keyCode >= 48 && keyCode <= 57) 
        {
          $event.preventDefault();
        }
      }
      this.selectedText = false;
    },

    onlyInteger($event, value, targetProperty) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      let char = String.fromCharCode(keyCode);
      // Allow only numbers and backspace
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8)
      {
        $event.preventDefault();
      }
      // Prevent leading zeros
      if (value === '0' && char !== '0')
      {
        $event.preventDefault();
      }
      // Prevent input of '00'
      if (value === '0' && char === '0') {
        $event.preventDefault();
      }
      // Remove leading zeros when a digit is entered after '0'
      if (value === '0' && char >= '0' && char <= '9') {
        this.payslipdetail[targetProperty] = parseInt(value + char).toString();
      }
      // Prevent any dot character
      if (char === '.')
      {
        $event.preventDefault();
      }
    },

    handleFocusout(item,index){
      if(item === '')
      {
        this.payslipdetail[index] = '0';
      }
    },

    intitudeHandlerAdd(newValue, index)
    {
      if (newValue && newValue[0] === '0' && newValue[1] != '.' && newValue.length != 1)
      {
        this.additionlist.filter(temp => temp.id == index)[0].amount = newValue.substring(1);
      }
      this.additionlist.filter(temp => temp.id == index)[0].erroramount = "";
    },

    intitudeHandlerSub(newValue, index)
    {
      if (newValue && newValue[0] === '0' && newValue[1] != '.' && newValue.length != 1)
      {
        this.additionaldeduction.filter(temp => temp.id == index)[0].amount = newValue.substring(1);
      }
      this.additionaldeduction.filter(temp => temp.id == index)[0].erroramount = "";
    },

    handleFocusoutAdd(index){
      if(this.additionlist.filter(temp => temp.id == index)[0].amount != '' && this.additionlist.filter(temp => temp.id == index)[0].amount != '.' && this.additionlist.filter(temp => temp.id == index)[0].amount != null && this.additionlist.filter(temp => temp.id == index)[0].amount != undefined && this.additionlist.filter(temp => temp.id == index)[0].amount != '0.00')
      {
        let temp1 = this.additionlist.filter(temp => temp.id == index)[0].amount;
        let temp = this.decimalnumberWithCommas(parseFloat(temp1.split(',').join('')).toFixed(2));
        this.additionlist.filter(temp => temp.id == index)[0].amount = temp;
      }
      else
      {
        this.additionlist.filter(temp => temp.id == index)[0].amount = '0.00';
      }
      this.additionlist.forEach(item => {
        if (item.amount === "0") 
        {
          item.amount = "0.00";
        }
      });
    },

    handleFocusoutSub(index){
      if(this.additionaldeduction.filter(temp => temp.id == index)[0].amount != '' && this.additionaldeduction.filter(temp => temp.id == index)[0].amount != '.' && this.additionaldeduction.filter(temp => temp.id == index)[0].amount != null && this.additionaldeduction.filter(temp => temp.id == index)[0].amount != undefined && this.additionaldeduction.filter(temp => temp.id == index)[0].amount != '0.00')
      {
        let temp1 = this.additionaldeduction.filter(temp => temp.id == index)[0].amount;
        let temp = this.decimalnumberWithCommas(parseFloat(temp1.split(',').join('')).toFixed(2));
        this.additionaldeduction.filter(temp => temp.id == index)[0].amount = temp;
      }
      else
      {
        this.additionaldeduction.filter(temp => temp.id == index)[0].amount = '0.00';
      }
      this.additionaldeduction.forEach(item => {
        if (item.amount === "0") 
        {
          item.amount = "0.00";
        }
      });
    },

    handleFocusoutbankfee(item){
      if(item != '' && item != '.' && item != null && item != undefined && item != '0.00')
      {
        let temp = this.decimalnumberWithCommas(parseFloat(item.split(',').join('')).toFixed(2));
        this.bankfee = temp;
      }
      else
      {
        this.bankfee = '0.00';
      }
    },
    handleFocusoutlateR(item){
      if(item != '' && item != '.' && item != null && item != undefined && item != '0.00')
      {
        let temp = this.decimalnumberWithCommas(parseFloat(item.split(',').join('')).toFixed(2));
        this.payslipdetail.laterate = temp;
      }
      else
      {
        this.payslipdetail.laterate = '0.00';
      }
    },
    handleFocusoutNotStamp(item){
      if(item != '' && item != '.' && item != null && item != undefined && item != '0.00')
      {
        let temp = this.decimalnumberWithCommas(parseFloat(item.split(',').join('')).toFixed(2));
        this.payslipdetail.Notstamprate = temp;
      }
      else
      {
        this.payslipdetail.Notstamprate = '0.00';
      }
    },

    handleClickItemAdd(index){
      if(this.additionlist.filter(temp => temp.id == index)[0].amount === '0.00')
      {
        this.additionlist.filter(temp => temp.id == index)[0].amount = 0;
      }
    },
    handleClickItemSub(index){
      if(this.additionaldeduction.filter(temp => temp.id == index)[0].amount === '0.00')
      {
        this.additionaldeduction.filter(temp => temp.id == index)[0].amount = 0;
      }
    },
    handleClickbankfee(){
      if(this.bankfee === '0.00')
      {
        this.bankfee = 0;
      }
    },
    handleClickModel(model){
      if(this.payslipdetail[model] === '0.00')
      {
        this.payslipdetail[model] = 0;
      }
    },

    decimalnumberWithCommas(x) {
      if (x != null && x.split(".")[0] != null) 
      {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      else
      {
        return "";
      }
    },

    selectText1(event, item, model)
    {
      // Convert input to string if it's not already
      let numStr = item.toString();
      // Split the input into integer and decimal parts
      let [integerPart, decimalPart] = numStr.split('.');
      // Remove unnecessary commas for processing
      integerPart = integerPart.replace(/,/g, '');
      // Format the integer part with commas
      integerPart = parseInt(integerPart).toLocaleString();
      // If there's no decimal part or it's zero, return the formatted integer part
      if (!decimalPart || parseFloat(decimalPart) === "00") 
      {
        this.payslipdetail[model] = integerPart;
      }
      // Trim trailing zeros in the decimal part
      if(decimalPart== "00"){
        decimalPart = decimalPart.replace(/0+$/, '');
      }
      // If the decimal part is empty after trimming, return only the integer part
      if (decimalPart === '') {
        this.payslipdetail[model] = integerPart;
      }
      // Return the formatted number with the necessary decimal part
      this.payslipdetail[model] = decimalPart === "" ? `${integerPart}` : `${integerPart}.${decimalPart}`;

      if (this.payslipdetail[model] !== '0.00') {
        this.$nextTick(() => {
          event.target.select();
        });
      }
      this.selectedText = true;
    },

    selectText(event, item, model)
    {
      // Convert input to string if it's not already
      let numStr = item.toString();
      // Split the input into integer and decimal parts
      let [integerPart, decimalPart] = numStr.split('.');
      // Remove unnecessary commas for processing
      integerPart = integerPart.replace(/,/g, '');
      // Format the integer part with commas
      integerPart = parseInt(integerPart).toLocaleString();
      // If there's no decimal part or it's zero, return the formatted integer part
      if (!decimalPart || parseFloat(decimalPart) === "00") 
      {
        this[model] = integerPart;
      }
      // Trim trailing zeros in the decimal part
      if(decimalPart== "00"){
        decimalPart = decimalPart.replace(/0+$/, '');
      }
      // If the decimal part is empty after trimming, return only the integer part
      if (decimalPart === '') {
        this[model] = integerPart;
      }
      // Return the formatted number with the necessary decimal part
      this[model] = decimalPart === "" ? `${integerPart}` : `${integerPart}.${decimalPart}`;

      if (this[model] !== '0.00') {
        this.$nextTick(() => {
          event.target.select();
        });
      }
      this.selectedText = true;
    },

    selectTextAddIncome(event,index){
      // Convert input to string if it's not already
      let numStr = this.additionlist.filter(temp => temp.id == index)[0].amount.toString();
      // Split the input into integer and decimal parts
      let [integerPart, decimalPart] = numStr.split('.');
      // Remove unnecessary commas for processing
      integerPart = integerPart.replace(/,/g, '');
      // Format the integer part with commas
      integerPart = parseInt(integerPart).toLocaleString();
      // If there's no decimal part or it's zero, return the formatted integer part
      if (!decimalPart || parseFloat(decimalPart) === "00") 
      {
        this.additionlist.filter(temp => temp.id == index)[0].amount = integerPart;
      }
      // Trim trailing zeros in the decimal part
      decimalPart = decimalPart.replace(/0+$/, '');
      // If the decimal part is empty after trimming, return only the integer part
      if (decimalPart === '') 
      {
        this.additionlist.filter(temp => temp.id == index)[0].amount = integerPart;
      }
      // Return the formatted number with the necessary decimal part
      this.additionlist.filter(temp => temp.id == index)[0].amount = decimalPart === "" ? `${integerPart}` : `${integerPart}.${decimalPart}`;
      if (this.additionlist.filter(temp => temp.id == index)[0].amount !== '0.00') 
      {
        this.$nextTick(() => {
          event.target.select();
        });
      }
      this.selectedText = true;
    },

    selectTextSubIncome(event,index){
      // Convert input to string if it's not already
      let numStr = this.additionaldeduction.filter(temp => temp.id == index)[0].amount.toString();
      // Split the input into integer and decimal parts
      let [integerPart, decimalPart] = numStr.split('.');
      // Remove unnecessary commas for processing
      integerPart = integerPart.replace(/,/g, '');
      // Format the integer part with commas
      integerPart = parseInt(integerPart).toLocaleString();
      // If there's no decimal part or it's zero, return the formatted integer part
      if (!decimalPart || parseFloat(decimalPart) === "00") 
      {
        this.additionaldeduction.filter(temp => temp.id == index)[0].amount = integerPart;
      }
      // Trim trailing zeros in the decimal part
      decimalPart = decimalPart.replace(/0+$/, '');
      // If the decimal part is empty after trimming, return only the integer part
      if (decimalPart === '') 
      {
        this.additionaldeduction.filter(temp => temp.id == index)[0].amount = integerPart;
      }
      // Return the formatted number with the necessary decimal part
      this.additionaldeduction.filter(temp => temp.id == index)[0].amount = decimalPart === "" ? `${integerPart}` : `${integerPart}.${decimalPart}`;
      if (this.additionaldeduction.filter(temp => temp.id == index)[0].amount !== '0.00') 
      {
        this.$nextTick(() => {
          event.target.select();
        });
      }
      this.selectedText = true;
    },

    select() {
      this.selected = [];
      if (!this.selectAll)
      {
        this.empList.forEach(emp => {
          if (!this.checkEmpListForAddStaff.some(date => date.employee_ID == emp.employee_ID)) 
          {
            this.checkEmpListForAddStaff.push({
              employee_Code: emp.employee_Code,
              employee_ID: emp.employee_ID,
              empname: emp.empname,
              positioinName: emp.positioinName
            });
          }
        });
      }
      else
      {
        this.checkEmpListForAddStaff = [];
      }
    },

    todayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = mm + "-" + dd + "-" + yyyy;
      return today;
    },

    showduesetup() {
      let self = this;
      self.checkemplistforeditstaff = [];
      self.recalculateemplist = [];
      self.updatestafftimeemplist = [];
      let myoption = this.actualState;
      self.disableworkingday = true;
      self.disableOT = true;
      self.disablestampday = true;
      self.disablelateday = true;
      if (myoption == "edit") 
      {
        self.createSetUpDialog = true;
        // self.editPaymentDueId = 0;
      } 
      else
      {
        self.editSetUpDialog = true;
        //self.paymentDueSetupData;
      }
    },

    changeincometype(incometype, ID) {
      let self = this;
      for (let i = 0; i < self.allincomelist.length; i++) 
      {
        if (incometype == self.allincomelist[i].income_Type_ID) 
        {
          self.additionlist[ID].name = self.allincomelist[i].income_Type_Name;
          self.additionlist[ID].name_Eng = self.allincomelist[i].income_Type_Name_Eng;
          self.additionlist[ID].Tax = self.allincomelist[i].tax_Calculation;
          self.additionlist[ID].SSF = self.allincomelist[i].social_Security_Calculation;
          self.additionlist[ID].income_Type = self.allincomelist[i].income_Type_ID;
        }
      }
    },

    thedot(amount, myid){
      let self = this;
      const index = this.additionaldeduction.findIndex(object => {
        return object.id === myid;
      });
      if(amount == "."){
        amount = 0;
      }
      self.additionaldeduction[index].amount = amount;
      if(amount == 0){
        self.additionaldeduction[index].errormessagededuname = "";
      }
    },

    Addadditional(amount, myid) {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      const index = this.additionlist.findIndex(object => {
        return object.id === myid;
      });
      if(amount == "."){
        amount = 0;
      }
      let myamount = 0;
      if (amount != "" && amount != null) 
      {
        if (amount.includes(",")) 
        {
          let remvcomma = self.removecomma(amount);
          myamount = parseFloat(remvcomma);
        }
        else
        {
          myamount = parseFloat(amount);
        }
      }
      self.additionlist[index].amount = myamount;
      if(self.empButton == 2)
      {
        self.payslipdetail.totalincome = self.payslipdetail.OTPayTotal + self.payslipdetail.YTD;
        self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.YTD;
      }
      else
      {
        self.payslipdetail.totalincome = self.payslipdetail.regularsalary + self.payslipdetail.OTPayTotal;
        self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.regularsalary;
      }
      self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincome;
      for (let i = 0; i < self.additionlist.length; i++)
      {
        if(self.additionlist[i].Tax == true)
        {
          self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + parseFloat(self.additionlist[i].amount);
        }
        if(self.additionlist[i].SSF == true)
        {
          self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + parseFloat(self.additionlist[i].amount);
        }
        self.payslipdetail.totalincome = self.payslipdetail.totalincome + parseFloat(self.additionlist[i].amount);
      }
      if (self.payslipdetail.checkpersonaltax == true) 
      {
        self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax),self.payslipdetail.personalallowanceperyear) / 12;
        self.payslipdetail.personaltax = self.checkpersonaltax;
        if (self.checkmytax != 0) 
        {
          self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.personaltax;
        }
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
      }
      else 
      {
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.personaltax;
        self.payslipdetail.personaltax = 0;
      }
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      self.putdecimalpayslip();
      self.putcommapayslip();
      self.checkpersonaltaxon();
      self.checksocail();
    },
    
    addlistcomma(event, myid) {
      let self = this;
      let amount = event.target.value;
      
      self.removecommapayslip();
      self.changetodecimal();
      // Recalculate totalincome, totalincomecalculatetax, totalincomecalculatesocialsecurity
      self.payslipdetail.totalincome = 0;
      self.payslipdetail.totalincomecalculatetax = 0;
      self.payslipdetail.totalincomecalculatesocialsecurity = 0;
      self.payslipdetail.totaldeduction = 0;
      self.payslipdetail.NotstampDeduction = 0;
      self.payslipdetail.latededuction = 0;
      self.payslipdetail.alldeduction = 0;

      if (self.empButton == 2) {
        self.payslipdetail.totalincome = self.payslipdetail.OTPayTotal + self.payslipdetail.YTD;
        self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.YTD;
        self.payslipdetail.YTD = self.payslipdetail.regularsalary * self.payslipdetail.workingdays;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.YTD + self.payslipdetail.OTPayTotal;
      } else {
        self.payslipdetail.totalincome = self.payslipdetail.regularsalary + self.payslipdetail.OTPayTotal;
        self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.regularsalary;
        self.payslipdetail.YTD = 0;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.regularsalary + self.payslipdetail.OTPayTotal;
      }

      if (self.payslipdetail.Notstamprate != null && self.empButton == 1) 
      {
        self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.NotstampDeduction;
      }

      if (self.payslipdetail.laterate != null) 
      {
        self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.latededuction;
      }

      for (let i = 0; i < self.additionaldeduction.length; i++) 
      {
        if (self.additionaldeduction[i].amount == "") 
        {
          self.additionaldeduction[i].amount = 0;
        }
        self.payslipdetail.alldeduction = parseFloat(self.additionaldeduction[i].amount) + self.payslipdetail.alldeduction;
        self.payslipdetail.mychange = 1;
      }
      self.payslipdetail.totaldeduction = self.payslipdetail.alldeduction + self.payslipdetail.totaldeduction;

      // Process each item in additionlist
      for (let i = 0; i < self.additionlist.length; i++) {
        let itemAmount = self.additionlist[i].amount;
        if (itemAmount === '.' || itemAmount === '0.') {
          // Allow intermediate values
          continue;
        }
        itemAmount = self.removecomma(itemAmount);
        let parsedItemAmount = parseFloat(itemAmount);
        if (!isNaN(parsedItemAmount) && parsedItemAmount != 0) {
          self.payslipdetail.totalincome += parsedItemAmount;
          if (self.additionlist[i].Tax) {
            self.payslipdetail.totalincomecalculatetax += parsedItemAmount;
          }
          if (self.additionlist[i].SSF) {
            self.payslipdetail.totalincomecalculatesocialsecurity += parsedItemAmount;
          }
          //self.additionlist[i].amount = self.numberWithCommas(parsedItemAmount);
        }
      }

      // Calculate personal tax if applicable
      if (self.payslipdetail.checkpersonaltax) {
        self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax), self.payslipdetail.personalallowanceperyear) / 12;
        self.payslipdetail.personaltax = self.checkpersonaltax;
        if (self.checkmytax != 0) {
          self.payslipdetail.totaldeduction -= self.payslipdetail.personaltax;
        }
        self.payslipdetail.totaldeduction += self.payslipdetail.personaltax;
      } else {
        self.payslipdetail.totaldeduction -= self.payslipdetail.personaltax;
        self.payslipdetail.personaltax = 0;
      }

      if (self.payslipdetail.checksocialsecurity == true) 
      {
        if(self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.lowestSubvention)
        {
          if (self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.highestsalary) 
          {
            self.payslipdetail.socialsecurity = self.Commonsocial.highestSubvention;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else if (self.payslipdetail.totalincomecalculatesocialsecurity < self.Commonsocial.lowestsalary) 
          {
            self.payslipdetail.socialsecurity = 0;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          }
          else if (self.payslipdetail.totalincomecalculatesocialsecurity == self.Commonsocial.lowestsalary) 
          {
            self.payslipdetail.socialsecurity = self.Commonsocial.lowestSubvention;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          }  
          else 
          {
            self.payslipdetail.socialsecurity = Math.round((self.Commonsocial.percentage / 100) * self.payslipdetail.totalincomecalculatesocialsecurity);
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          }
        }
        else
        {
          self.payslipdetail.socialsecurity = 0;
        }
      }

      // Update totalnetincome
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;

      // Format payslip details with commas and decimals
      self.putdecimalpayslip(1, myid);
      self.putcommapayslip();

      const index = self.additionlist.findIndex(object => object.id === myid);

      if (amount === '.') {
        // Allow intermediate values
        self.additionlist[index].amount = '0.';
        return;
      }

      // Allow intermediate values like '0.', '1.', etc.
      if (amount === '0.' || amount.endsWith('.')) {
        self.additionlist[index].amount = amount;
        return;
      }

      // Allow intermediate values with decimals like '0.x'
      if (amount.includes('.') && amount.split('.')[1].length <= 2) {
        self.additionlist[index].amount = amount;
        return;
      }

      // Remove commas from the amount for processing
      amount = self.removecomma1(amount);

      // Parse the input amount
      let parsedAmount = parseFloat(amount);
      if (isNaN(parsedAmount)) {
        parsedAmount = 0;
      }

      if (amount.includes('.')) {
        self.additionlist[index].amount = amount;
      } else {
        self.additionlist[index].amount = self.numberWithCommas(parsedAmount);
      }

    },

    AddIncome() {
      let plusid = this.additionlist.length;
      this.additionlist.push({
        income_Type: this.Selectedincome,
        name: "",
        name_Eng: "",
        amount: '0.00',
        id: plusid,
        Tax: this.MyempTax,
        SSF: this.MySSF,
        Showplus: true,
        Showtrash: true
      });

      for (let i = 0; i < this.additionlist.length; i++) 
      {
        if (i == 0 && this.additionlist.length == 1) 
        {
          this.additionlist[i].Showplus = false;
          this.additionlist[i].Showtrash = false;
        } 
        else if (i == this.additionlist.length - 1)
        {
          this.additionlist[i].Showplus = true;
          this.additionlist[i].Showtrash = true;
        } 
        else if (this.additionlist.length == 1)
        {
          this.additionlist[i].Showplus = true;
          this.additionlist[i].Showtrash = false;
        }
        else
        {
          this.additionlist[i].Showplus = false;
          this.additionlist[i].Showtrash = true;
        }
      }
      // this.additionlist.map(function (e) {
      //   e.amount = parseFloat(e.amount).toFixed(2);
      // });
    },

    Removeincome(myid) {
      this.removecommapayslip();
      this.changetodecimal();
      const index = this.additionlist.findIndex(object => {
        return object.id === myid;
        //
      });
      let myamount = 0;
      let amount = this.additionlist[index].amount;
      myamount = parseFloat(amount);
      this.payslipdetail.totalincome = this.payslipdetail.totalincome - myamount;
      if (this.additionlist[index].Tax == true) 
      {
        this.payslipdetail.totalincomecalculatetax = this.payslipdetail.totalincomecalculatetax - myamount;
      }
      if (this.additionlist[index].SSF == true) 
      {
        this.payslipdetail.totalincomecalculatesocialsecurity = this.payslipdetail.totalincomecalculatesocialsecurity - myamount;
      }
      this.additionlist.splice(index, 1);
      if (index == 1 && this.additionlist.length == 1) 
      {
        this.additionlist[0].Showplus = true;
        this.additionlist[0].Showtrash = false;
      }
      this.putdecimalpayslip();
      this.putcommapayslip();

      if (index == this.additionlist.length) 
      {
        if (index - 1 != 0) 
        {
          this.additionlist[index - 1].Showplus = true;
          this.additionlist[index - 1].Showtrash = true;
        } 
        else 
        {
          this.additionlist[index - 1].Showplus = true;
          this.additionlist[index - 1].Showtrash = false;
        }
      }
      if (this.additionlist.length == 1)
      {
        this.additionlist[this.additionlist.length - 1].Showplus = true;
        this.additionlist[this.additionlist.length - 1].Showtrash = false;
      }
      this.checkpersonaltaxon();
      this.checksocail();
    },

    checkpersonaltaxon(v) {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      let totalAdditionalDeductions = 0;
      self.additionaldeduction.forEach(function(e){
        totalAdditionalDeductions = totalAdditionalDeductions + e.amount;
      });
      if(v == 1)
      {
        self.payslipdetail.providentfund = self.removecomma(self.payslipdetail.providentfund);
        self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund);
        self.payslipdetail.totaldeduction = totalAdditionalDeductions + self.payslipdetail.NotstampDeduction + self.payslipdetail.latededuction + self.payslipdetail.socialsecurity + self.payslipdetail.providentfund;
        self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
        self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
      } 
      else 
      {
        self.payslipdetail.totaldeduction = totalAdditionalDeductions + self.payslipdetail.NotstampDeduction + self.payslipdetail.latededuction + self.payslipdetail.socialsecurity;
      }
      if(self.empButton == 2)
      {
        self.payslipdetail.YTD = self.payslipdetail.regularsalary * self.payslipdetail.workingdays;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.YTD + self.payslipdetail.OTPayTotal;
      }
      else
      {
        self.payslipdetail.YTD = 0;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.regularsalary + self.payslipdetail.OTPayTotal;
      }
      for (let i = 0; i < this.additionlist.length; i++)
      {
        if(this.additionlist[i].Tax == true)
        {
          this.payslipdetail.totalincomecalculatetax = this.payslipdetail.totalincomecalculatetax + this.additionlist[i].amount;
        }
      }
      if (self.payslipdetail.checkpersonaltax == true) 
      {
        self.checkpersonaltax = self.GetIncome(self.payslipdetail.totalincomecalculatetax,self.payslipdetail.personalallowanceperyear) / 12;
        self.payslipdetail.personaltax = self.checkpersonaltax;
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
      } 
      else 
      {
        self.payslipdetail.personaltax = 0;
      }
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      self.putdecimalpayslip();
      self.putcommapayslip();
    },

    async checksocail(v) {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      let totalAdditionalDeductions = 0;
      self.additionaldeduction.forEach(function(e){
        totalAdditionalDeductions = totalAdditionalDeductions + e.amount;
      });
      if(v == 1)
      {
        self.payslipdetail.providentfund = self.removecomma(self.payslipdetail.providentfund);
        self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund);
        self.payslipdetail.totaldeduction = totalAdditionalDeductions + self.payslipdetail.NotstampDeduction + self.payslipdetail.latededuction + self.payslipdetail.personaltax + self.payslipdetail.providentfund;
        self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
        self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
      } 
      else 
      {
        self.payslipdetail.totaldeduction = totalAdditionalDeductions + self.payslipdetail.NotstampDeduction + self.payslipdetail.latededuction + self.payslipdetail.personaltax;
      }
      if(self.empButton == 2){
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.NotstampDeduction;
      }
      if (self.payslipdetail.checksocialsecurity == true && v != 2) 
      {
        if(self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.lowestSubvention)
        {
          if (self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.highestsalary) 
          {
            self.payslipdetail.socialsecurity = self.Commonsocial.highestSubvention;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else if (self.payslipdetail.totalincomecalculatesocialsecurity < self.Commonsocial.lowestsalary) 
          {
            self.payslipdetail.socialsecurity = 0;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          }
          else if (self.payslipdetail.totalincomecalculatesocialsecurity == self.Commonsocial.lowestsalary) 
          {
            self.payslipdetail.socialsecurity = self.Commonsocial.lowestSubvention;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else 
          {
            self.payslipdetail.socialsecurity = Math.round((self.Commonsocial.percentage / 100) * self.payslipdetail.totalincomecalculatesocialsecurity);
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          }
        }
        else
        {
          self.payslipdetail.socialsecurity = 0;
        }
      }
      else
      {
        self.payslipdetail.socialsecurity = 0;
      }
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      //("1",self.payslipdetail.totalnetincome)
      self.putdecimalpayslip();
      self.putcommapayslip();
    },

    async checkprovi() {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      if (self.payslipdetail.checkprovident == true) 
      {
        self.payslipdetail.providentfund = self.removecomma(self.payslipdetail.providentfund);
        self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.providentfund;
        await self.getempprovident(self.myempforprovident);
        if (typeof self.payslipdetail.regularsalary !== "string") 
        {
          self.putdecimalpayslip();
          self.putcommapayslip();
        }
      } 
      else 
      {
        self.payslipdetail.providentfund = self.removecomma(self.payslipdetail.providentfund);
        self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.providentfund;
        self.payslipdetail.providentfund = 0;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.putdecimalpayslip();
        self.putcommapayslip();
        self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
        self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
      }
    },

    formatCalendarIncomeDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    formatCalendarIncomeDate1(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    formatDateforDOB(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    formatDate(date) {
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      return Date[0] + "-" + Date[1] + "-" + Date[2];
    },

    formatDateForTable(date) {
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      return Date[2] + "/" + Date[1] + "/" + Date[0];
    },

    formatDateForToday(date) {
      // let finaldate = date.slice(0, 10).split("-");
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      // let time = spliteDateAndTime[1].split(":");
      return Date[1] + "/" + Date[0] + "/" + Date[2];
    },

    periodFormat(date) {
      let date1 = new Date(date);
      let options = { year: "numeric", month: "short" };
      return date1.toLocaleDateString("en-US", options);
    },

    periodDay(date) {
      let dd = new Date(date);
      return dd.getDate();
    },

    bankSplit(bankName) {
      let splitBank = bankName.split(" ");
      let name = "";
      for (let i = 0; i < splitBank.length; i++) 
      {
        if (i > 0) 
        {
          name = name + " " + splitBank[i];
        }
      }
      return name;
    },

    bankAccountSplit(bankName) {
      let splitBank = bankName.split(" ");
      return splitBank[0];
    },

    bankfeecomma(){
      let self = this;
      let amount = self.bankfee;

      if (amount === '.') {
        self.bankfee = '0.';
        return;
      }

      if (amount === '0.' || amount.endsWith('.')) {
        self.bankfee = amount;
        return;
      }

      if (amount.includes('.') && amount.split('.')[1].length <= 2) {
        self.bankfee = amount;
        return;
      }

      amount = self.removecomma1(amount);

      let parsedAmount = parseFloat(amount);
      if (isNaN(parsedAmount)) {
        parsedAmount = 0;
      }

      if (amount.includes('.')) {
        self.bankfee = amount;
      } else {
        self.bankfee = self.numberWithCommas(parsedAmount);
      }
    },

    commasub(v){
      let self = this;
      let amount = 0;
      if(v == 1){
        amount = self.payslipdetail.Notstamprate;
      } else {
        amount = self.payslipdetail.laterate;
      }
      
      if(v == 2){
        self.subcalculate(3);
      } else {
        self.subcalculate(4);
      }
      let result = null;

      if (amount === '.') {
        result = '0.';
        if(v == 1){
          self.payslipdetail.Notstamprate = result;
        } else {
          self.payslipdetail.laterate = result;
        }
        return;
      }

      if (amount === '0.' || amount.endsWith('.')) {
        result = amount;
        if(v == 1){
          self.payslipdetail.Notstamprate = result;
        } else {
          self.payslipdetail.laterate = result;
        }
        return;
      }

      if (amount.includes('.') && amount.split('.')[1].length <= 2) {
        result = amount;
        if(v == 1){
          self.payslipdetail.Notstamprate = result;
        } else {
          self.payslipdetail.laterate = result;
        }
        return;
      }

      amount = self.removecomma1(amount);

      let parsedAmount = parseFloat(amount);
      if (isNaN(parsedAmount)) {
        parsedAmount = 0;
      }

      if (amount.includes('.')) {
        result = amount;
      } else {
        result = self.numberWithCommas(parsedAmount);
      }
      if(v == 1){
        self.payslipdetail.Notstamprate = result;
      } else {
        self.payslipdetail.laterate = result;
      }
    },

    subcalculate(v, myid){
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      self.payslipdetail.totaldeduction = 0;
      self.payslipdetail.NotstampDeduction = 0;
      self.payslipdetail.latededuction = 0;
      self.payslipdetail.alldeduction = 0;

      if (self.payslipdetail.Notstamprate != null && self.empButton == 1) 
      {
        self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.NotstampDeduction;
      }

      if (self.payslipdetail.laterate != null) 
      {
        self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.latededuction;
      }

      // Process each item in additionaldeduction
      for (let i = 0; i < self.additionaldeduction.length; i++) {
        let itemAmount = self.additionaldeduction[i].amount;
        if (itemAmount === '0' || itemAmount === '.' || itemAmount === '0.') {
          // Allow intermediate values
          continue;
        }
        itemAmount = self.removecomma(itemAmount);
        let parsedItemAmount = parseFloat(itemAmount);
        if (!isNaN(parsedItemAmount) && parsedItemAmount != 0) {
          self.payslipdetail.alldeduction += parsedItemAmount;
        }
      }
      self.payslipdetail.totaldeduction = self.payslipdetail.alldeduction + self.payslipdetail.totaldeduction;

      // Calculate personal tax if applicable
      if (self.payslipdetail.checkpersonaltax) {
        self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax), self.payslipdetail.personalallowanceperyear) / 12;
        self.payslipdetail.personaltax = self.checkpersonaltax;
        if (self.checkmytax != 0) {
          self.payslipdetail.totaldeduction -= self.payslipdetail.personaltax;
        }
        self.payslipdetail.totaldeduction += self.payslipdetail.personaltax;
      } else {
        self.payslipdetail.totaldeduction -= self.payslipdetail.personaltax;
        self.payslipdetail.personaltax = 0;
      }

      if (self.payslipdetail.checksocialsecurity == true) 
      {
        if(self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.lowestSubvention)
        {
          if (self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.highestsalary) 
          {
            self.payslipdetail.socialsecurity = self.Commonsocial.highestSubvention;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else if (self.payslipdetail.totalincomecalculatesocialsecurity < self.Commonsocial.lowestsalary) 
          {
            self.payslipdetail.socialsecurity = 0;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else if (self.payslipdetail.totalincomecalculatesocialsecurity == self.Commonsocial.lowestsalary) 
          {
            self.payslipdetail.socialsecurity = self.Commonsocial.lowestSubvention;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else 
          {
            self.payslipdetail.socialsecurity = Math.round((self.Commonsocial.percentage / 100) * self.payslipdetail.totalincomecalculatesocialsecurity);
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          }
        }
        else
        {
          self.payslipdetail.socialsecurity = 0;
        }
      }

      // Update totalnetincome
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;

      // Format payslip details with commas and decimals
      self.putdecimalpayslip(v, myid);
      self.putcommapayslip();
    },

    sublistcomma(event, myid) {
      let self = this;
      let amount = event.target.value;

      self.subcalculate(2, myid);

      // Find the index of the item in additionaldeduction
      const index = self.additionaldeduction.findIndex(object => object.id === myid);
      
      
      if (amount === '.') {
        // Allow intermediate values
        self.additionaldeduction[index].amount = '0.';
        return;
      }

      // Allow intermediate values like '0.', '1.', etc.
      if (amount === '0.' || amount.endsWith('.')) {
        self.additionaldeduction[index].amount = amount;
        return;
      }

      // Allow intermediate values with decimals like '0.x'
      if (amount.includes('.') && amount.split('.')[1].length <= 2) {
        self.additionaldeduction[index].amount = amount;
        return;
      }

      // Remove commas from the amount for processing
      amount = self.removecomma1(amount);

      // Parse the input amount
      let parsedAmount = parseFloat(amount);
      if (isNaN(parsedAmount)) {
        parsedAmount = 0;
      }

      // Restore the original amount string if it includes a decimal part
      if (amount.includes('.')) {
        self.additionaldeduction[index].amount = amount;
      } else {
        self.additionaldeduction[index].amount = self.numberWithCommas(parsedAmount);
      }
    },

    removededuction() {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      self.payslipdetail.totaldeduction =
        self.payslipdetail.totaldeduction -
        self.additionaldeduction[self.additionaldeduction.length - 1].amount;
      self.payslipdetail.totalnetincome =
        self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      self.additionaldeduction.splice(self.additionaldeduction.length - 1, 1);
      if (self.additionaldeduction.length - 1 == 0) {
        self.additionaldeduction[
          self.additionaldeduction.length - 1
        ].showdelete = false;
      } else {
        self.additionaldeduction[
          self.additionaldeduction.length - 1
        ].showdelete = true;
      }
      self.additionaldeduction[self.additionaldeduction.length - 1].show = true;
      self.putdecimalpayslip();
      self.putcommapayslip();
    },

    adddeduction() {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      let plusid = self.additionaldeduction.length;
      self.additionaldeduction.push({
        id: plusid,
        name: null,
        amount: 0,
        show: true,
        showdelete: false,
        errormessagededuname: ""
      });
      for (let i = 0; i < this.additionaldeduction.length; i++)
      {
        if (i >= 1)
        {
          this.additionaldeduction[i - 1].show = false;
        }
        if (i == this.additionaldeduction.length - 1)
        {
          this.additionaldeduction[i].showdelete = true;
        }
        else
        {
          this.additionaldeduction[i].showdelete = false;
        }
      }
      self.putdecimalpayslip();
      self.putcommapayslip();
    },

    convertUTCDateToLocalDate(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = newDate.getMonth() + 1 + "/" + newDate.getDate() + "/" + newDate.getFullYear();
      if (newDate.getHours() < 10) 
      {
        output = output + "   " + "0" + newDate.getHours();
      } 
      else 
      {
        output = output + "   " + newDate.getHours();
      }
      if (newDate.getMinutes() < 10) 
      {
        output = output + ":" + "0" + newDate.getMinutes();
      } 
      else 
      {
        output = output + ":" + newDate.getMinutes();
      }
      return output;
    },

    convertFromUTCDateToLocalDate(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output =
        newDate.getDate() +
        "/" +
        (newDate.getMonth() + 1) +
        "/" +
        newDate.getFullYear();
      return output;
    },

    putdecimalpayslip(v, t) {
      let self = this;
      self.payslipdetail.regularsalary = (self.payslipdetail.regularsalary + "").replaceAll(",", "");
      self.payslipdetail.regularsalary = parseFloat(self.payslipdetail.regularsalary).toFixed(2);
      self.payslipdetail.YTD = (self.payslipdetail.YTD + "").replaceAll(",", "");
      self.payslipdetail.YTD = parseFloat(self.payslipdetail.YTD).toFixed(2);
      self.payslipdetail.OTrate = (self.payslipdetail.OTrate + "").replaceAll(",", "");
      self.payslipdetail.OTrate = parseFloat(self.payslipdetail.OTrate).toFixed(2);
      self.payslipdetail.OTPay = (self.payslipdetail.OTPay + "").replaceAll(",", "");
      self.payslipdetail.OTPay = parseFloat(self.payslipdetail.OTPay).toFixed(2);
      self.payslipdetail.OTrate2 = (self.payslipdetail.OTrate2 + "").replaceAll(",", "");
      self.payslipdetail.OTrate2 = parseFloat(self.payslipdetail.OTrate2).toFixed(2);
      self.payslipdetail.OTPay2 = (self.payslipdetail.OTPay2 + "").replaceAll(",", "");
      self.payslipdetail.OTPay2 = parseFloat(self.payslipdetail.OTPay2).toFixed(2);
      self.payslipdetail.OTrate3 = (self.payslipdetail.OTrate3 + "").replaceAll(",", "");
      self.payslipdetail.OTrate3 = parseFloat(self.payslipdetail.OTrate3).toFixed(2);
      self.payslipdetail.OTPay3 = (self.payslipdetail.OTPay3 + "").replaceAll(",", "");
      self.payslipdetail.OTPay3 = parseFloat(self.payslipdetail.OTPay3).toFixed(2);
      self.payslipdetail.OTrate4 = (self.payslipdetail.OTrate4 + "").replaceAll(",", "");
      self.payslipdetail.OTrate4 = parseFloat(self.payslipdetail.OTrate4).toFixed(2);
      self.payslipdetail.OTPay4 = (self.payslipdetail.OTPay4 + "").replaceAll(",", "");
      self.payslipdetail.OTPay4 = parseFloat(self.payslipdetail.OTPay4).toFixed(2);
      self.payslipdetail.OTPayTotal = (self.payslipdetail.OTPayTotal + "").replaceAll(",", "");
      self.payslipdetail.OTPayTotal = parseFloat(self.payslipdetail.OTPayTotal).toFixed(2);
      if(v == 1){
        self.additionlist.map(function (e) {
          if(e.id != t){
            e.amount = (parseFloat(e.amount)).toFixed(2);
          }
        });
      } else {
        self.additionlist.map(function (e) {
          e.amount = parseFloat(e.amount).toFixed(2);
        });
      }
      self.payslipdetail.totalincome = (parseFloat(self.payslipdetail.totalincome)).toFixed(2);
      self.payslipdetail.totalincomecalculatetax = (parseFloat(self.payslipdetail.totalincomecalculatetax)).toFixed(2);
      if(v != 4){
        self.payslipdetail.Notstamprate = (parseFloat(self.payslipdetail.Notstamprate)).toFixed(2);
      }
      self.payslipdetail.NotstampDeduction = (parseFloat(self.payslipdetail.NotstampDeduction)).toFixed(2);
      if(v != 3){
        self.payslipdetail.laterate = (parseFloat(self.payslipdetail.laterate)).toFixed(2);
      }
      self.payslipdetail.latededuction = (parseFloat(self.payslipdetail.latededuction)).toFixed(2);
      self.payslipdetail.personaltax = (parseFloat(self.payslipdetail.personaltax)).toFixed(2);
      if (self.payslipdetail.socialsecurity != "N/A") 
      {
        self.payslipdetail.socialsecurity = (parseFloat(self.payslipdetail.socialsecurity)).toFixed(2);
      }
      if(v == 2){
        self.additionaldeduction.map(function (e) {
          if(e.id != t){
            e.amount = (parseFloat(e.amount)).toFixed(2);
          }
        });
      } else {
        self.additionaldeduction.map(function (e) {
            e.amount = (parseFloat(e.amount)).toFixed(2);
        });
      }
      self.otheradditionlist.map(function (e) {
        e.amount = parseFloat(e.amount).toFixed(2);
      });
      self.payslipdetail.rateperday = (parseFloat(self.payslipdetail.rateperday)).toFixed(2);
      self.payslipdetail.rateperhour = (parseFloat(self.payslipdetail.rateperhour)).toFixed(2);
      self.payslipdetail.totaldeduction = (parseFloat(self.payslipdetail.totaldeduction)).toFixed(2);
      self.payslipdetail.totalnetincome = (parseFloat(self.payslipdetail.totalnetincome)).toFixed(2);
    },

    putcommapayslip() {
      let self = this;
      self.removecommapayslip();
      self.payslipdetail.regularsalary = self.numberWithCommas(self.payslipdetail.regularsalary);
      self.payslipdetail.YTD = self.numberWithCommas(self.payslipdetail.YTD);
      self.payslipdetail.OTrate = self.numberWithCommas(
        self.payslipdetail.OTrate
      );
      self.payslipdetail.OTPay = self.numberWithCommas(
        self.payslipdetail.OTPay
      );
      self.payslipdetail.OTrate2 = self.numberWithCommas(
        self.payslipdetail.OTrate2
      );
      self.payslipdetail.OTPay2 = self.numberWithCommas(
        self.payslipdetail.OTPay2
      );
      self.payslipdetail.OTrate3 = self.numberWithCommas(
        self.payslipdetail.OTrate3
      );
      self.payslipdetail.OTPay3 = self.numberWithCommas(
        self.payslipdetail.OTPay3
      );
      self.payslipdetail.OTrate4 = self.numberWithCommas(
        self.payslipdetail.OTrate4
      );
      self.payslipdetail.OTPay4 = self.numberWithCommas(
        self.payslipdetail.OTPay4
      );
      self.payslipdetail.OTPayTotal = self.numberWithCommas(
        self.payslipdetail.OTPayTotal
      );

        self.additionlist.map(function (e) {
        e.amount = self.numberWithCommas(e.amount);
      });
      self.otheradditionlist.map(function (e) 
      {
        e.amount = self.numberWithCommas(e.amount);
      });

      self.payslipdetail.totalincome = self.numberWithCommas(self.payslipdetail.totalincome);
      self.payslipdetail.totalincomecalculatetax = self.numberWithCommas(self.payslipdetail.totalincomecalculatetax);
      self.payslipdetail.Notstamprate = self.numberWithCommas(self.payslipdetail.Notstamprate);
      self.payslipdetail.NotstampDeduction = self.numberWithCommas(self.payslipdetail.NotstampDeduction);
      self.payslipdetail.laterate = self.numberWithCommas(self.payslipdetail.laterate);
      self.payslipdetail.latededuction = self.numberWithCommas(self.payslipdetail.latededuction);
      self.payslipdetail.personaltax = self.numberWithCommas(self.payslipdetail.personaltax);
      if (self.payslipdetail.socialsecurity != "N/A") 
      {
        self.payslipdetail.socialsecurity = self.numberWithCommas(self.payslipdetail.socialsecurity);
      }
      self.additionaldeduction.map(function (e) 
      {
        e.amount = self.numberWithCommas(e.amount);
      });
      self.payslipdetail.rateperday = self.numberWithCommas(self.payslipdetail.rateperday);
      self.payslipdetail.rateperhour = self.numberWithCommas(self.payslipdetail.rateperhour);
      self.payslipdetail.totaldeduction = self.numberWithCommas(self.payslipdetail.totaldeduction);
      self.payslipdetail.totalnetincome = self.numberWithCommas(self.payslipdetail.totalnetincome);
    },

    removecommapayslip() {
      let self = this;
      self.payslipdetail.regularsalary = self.removecomma(
        self.payslipdetail.regularsalary
      );
      self.payslipdetail.YTD = self.removecomma(self.payslipdetail.YTD);
      self.payslipdetail.OTrate = self.removecomma(self.payslipdetail.OTrate);
      self.payslipdetail.OTPay = self.removecomma(self.payslipdetail.OTPay);
      self.payslipdetail.OTrate2 = self.removecomma(self.payslipdetail.OTrate2);
      self.payslipdetail.OTPay2 = self.removecomma(self.payslipdetail.OTPay2);
      self.payslipdetail.OTrate3 = self.removecomma(self.payslipdetail.OTrate3);
      self.payslipdetail.OTPay3 = self.removecomma(self.payslipdetail.OTPay3);
      self.payslipdetail.OTrate4 = self.removecomma(self.payslipdetail.OTrate4);
      self.payslipdetail.OTPay4 = self.removecomma(self.payslipdetail.OTPay4);
      self.payslipdetail.OTPayTotal = self.removecomma(self.payslipdetail.OTPayTotal);

      self.additionlist.map(function (e) {
        e.amount = self.removecomma(e.amount);
      });
      self.payslipdetail.totalincome = self.removecomma(
        self.payslipdetail.totalincome
      );
      self.payslipdetail.totalincomecalculatetax = self.removecomma(
        self.payslipdetail.totalincomecalculatetax
      );

      self.payslipdetail.Notstamprate = self.removecomma(
        self.payslipdetail.Notstamprate
      );
      self.payslipdetail.NotstampDeduction = self.removecomma(
        self.payslipdetail.NotstampDeduction
      );
      self.payslipdetail.laterate = self.removecomma(
        self.payslipdetail.laterate
      );
      self.payslipdetail.latededuction = self.removecomma(
        self.payslipdetail.latededuction
      );
      self.payslipdetail.personaltax = self.removecomma(
        self.payslipdetail.personaltax
      );
      if (self.payslipdetail.socialsecurity != "N/A") {
        self.payslipdetail.socialsecurity = self.removecomma(
          self.payslipdetail.socialsecurity
        );
      }
      self.additionaldeduction.map(function (e) {
        e.amount = self.removecomma(e.amount);
      });
      self.otheradditionlist.map(function (e) {
        e.amount = self.removecomma(e.amount);
      });
      self.payslipdetail.rateperday = self.removecomma(
        self.payslipdetail.rateperday
      );
      self.payslipdetail.rateperhour = self.removecomma(
        self.payslipdetail.rateperhour
      );
      self.payslipdetail.totaldeduction = self.removecomma(
        self.payslipdetail.totaldeduction
      );
      self.payslipdetail.totalnetincome = self.removecomma(
        self.payslipdetail.totalnetincome
      );
    },

    changetodecimal() {
      let self = this;
      self.payslipdetail.regularsalary = parseFloat(self.payslipdetail.regularsalary);
      self.payslipdetail.YTD = parseFloat(self.payslipdetail.YTD);
      self.payslipdetail.OTrate = parseFloat(self.payslipdetail.OTrate);
      self.payslipdetail.OTPay = parseFloat(self.payslipdetail.OTPay);
      self.payslipdetail.OTrate2 = parseFloat(self.payslipdetail.OTrate2);
      self.payslipdetail.OTPay2 = parseFloat(self.payslipdetail.OTPay2);
      self.payslipdetail.OTrate3 = parseFloat(self.payslipdetail.OTrate3);
      self.payslipdetail.OTPay3 = parseFloat(self.payslipdetail.OTPay3);
      self.payslipdetail.OTrate4 = parseFloat(self.payslipdetail.OTrate4);
      self.payslipdetail.OTPay4 = parseFloat(self.payslipdetail.OTPay4);
      self.payslipdetail.OTPayTotal = parseFloat(self.payslipdetail.OTPayTotal);
      self.additionlist.map(function (e) {
        e.amount = parseFloat(e.amount);
      });
      self.payslipdetail.totalincome = parseFloat(
        self.payslipdetail.totalincome
      );
      self.payslipdetail.totalincomecalculatetax = parseFloat(
        self.payslipdetail.totalincomecalculatetax
      );

      self.payslipdetail.Notstamprate = parseFloat(
        self.payslipdetail.Notstamprate
      );
      self.payslipdetail.NotstampDeduction = parseFloat(
        self.payslipdetail.NotstampDeduction
      );
      self.payslipdetail.laterate = parseFloat(self.payslipdetail.laterate);
      self.payslipdetail.latededuction = parseFloat(
        self.payslipdetail.latededuction
      );
      self.payslipdetail.personaltax = parseFloat(
        self.payslipdetail.personaltax
      );
      if (self.payslipdetail.socialsecurity != "N/A") {
        self.payslipdetail.socialsecurity = parseFloat(
          self.payslipdetail.socialsecurity
        );
      }
      // self.payslipdetail.providentfund = parseFloat(
      //   self.payslipdetail.providentfund
      // );
      self.additionaldeduction.map(function (e) {
        e.amount = parseFloat(e.amount);
      });
      self.otheradditionlist.map(function (e) {
        e.amount = parseFloat(e.amount);
      });
      self.payslipdetail.rateperday = parseFloat(self.payslipdetail.rateperday);
      self.payslipdetail.rateperhour = parseFloat(
        self.payslipdetail.rateperhour
      );
      self.payslipdetail.totaldeduction = parseFloat(
        self.payslipdetail.totaldeduction
      );
      self.payslipdetail.totalnetincome = parseFloat(
        self.payslipdetail.totalnetincome
      );
    },

    convertUTCDateToLocalDateforpayslip(date) {
      let stillUtc = moment.utc(date).toDate(); //still utc
      let localDate = moment(stillUtc)
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
      return localDate;
    },

    cleanpayslipcache() {
      let self = this;
      // self.additionlist = [];
      self.payslipdetail.personalallowanceperyear = 0;
      self.payslipdetail.regularsalary = 0;
      self.payslipdetail.mychange = 0;
      self.payslipdetail.YTD = 0;
      self.payslipdetail.latedays = 0;
      self.payslipdetail.OTPay = 0;
      self.payslipdetail.OTPay2 = 0;
      self.payslipdetail.OTPay3 = 0;
      self.payslipdetail.OTPay4 = 0;
      self.payslipdetail.Notstamprate = 0;
      self.payslipdetail.NotstampDeduction = 0;
      self.payslipdetail.totalincome = 0;
      self.payslipdetail.totalincomecalculatetax = 0;
      self.payslipdetail.totalincomecalculatesocialsecurity = 0;
      self.payslipdetail.totaldeduction = 0;
      self.payslipdetail.laterate = 0;
      self.payslipdetail.rateperday = 0;
      self.payslipdetail.OTrate = 0;
      self.payslipdetail.OTrate2 = 0;
      self.payslipdetail.OTrate3 = 0;
      self.payslipdetail.OTrate4 = 0;
      self.payslipdetail.rateperhour = 0;
      self.payslipdetail.latededuction = 0;
      self.payslipdetail.personaltax = 0;
      self.payslipdetail.totaldeduction = 0;
      self.payslipdetail.totalincome = 0;
      self.payslipdetail.alldeduction = 0;
      self.payslipdetail.checkpersonaltax = false;
      self.payslipdetail.checksocialsecurity = false;
      self.payslipdetail.checkprovident = false;
      self.Deductionlist = [];
      self.otheradditionlist = [];
      self.additionaldeduction = [];
      if (self.additionaldeduction.length != 0) 
      {
        self.additionaldeduction.splice(0, self.additionaldeduction.length);
      }
    },

    Getsocilacommon() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurityFund/GetSocialSecurityFund`, temp)
      .then(function (response) {
        self.Commonsocial.lowestsalary = parseInt(response.data.data.lowestBaseSalary);
        self.Commonsocial.highestsalary = parseInt(response.data.data.highestBaseSalary);
        self.Commonsocial.percentage = parseInt(response.data.data.contributionRate);
        self.Commonsocial.highestSubvention = parseInt(response.data.data.highestSubvention);
        self.Commonsocial.lowestSubvention = parseInt(response.data.data.lowestSubvention);
      });
  },

  async clickSlipDetails(item){
    if(this.tab != "three")
    {
      await this.Editdetail(item.employee_ID);
    }
  },

  //Payslip detail from paydue set up table
	async Editdetail(myemp) {
    let self = this;
    self.removecommapayslip();
    self.changetodecimal();
    self.LoadingDialog = true;
    self.tab = "three";
    await self.Getallincomeforpayslip();
    self.cleanpayslipcache();
    let slipdetail = null;
    
    if(self.updatedEmp.includes(myemp))
    {
      slipdetail = self.DetailPageAfteroneupdate(myemp);
    }
    self.status;
    self.employeeTab = true;
    self.slipDetailTab = true;
    self.payrollTab = false;
    self.createSetUpDialog = false;
    self.editSetUpDialog = false;
    self.payslipdetail.checkpersonaltax = false;
    self.payslipdetail.checksocialsecurity = false;
    self.payslipdetail.checkprovident = false;
    self.workingdayhistorylist = [];
    let startdate = "";
    let enddate = "";
    self.btnshow = true;
    self.myempforprovident = myemp;
    self.EmpID = myemp;
    for (let i = 0; i < self.EditpaymentDuesetup.length; i++) 
    {
      // this is for the count of in which employee
      for (let j = 0; j < self.EditpaymentDuesetup.length; j++) 
      {
        if (self.EditpaymentDuesetup[j].empID == myemp) 
        {
          self.myemp = j + 1;
          self.totallist = self.EditpaymentDuesetup.length;
        }
      }

      // for buttons disable
      if (self.EditpaymentDuesetup.length == 1) 
      {
        self.disablebtn = true;
      } 
      else 
      {
        self.disablebtn = false;
      }

      let empID = self.EditpaymentDuesetup[i].empID;
      // this start the retrieve data of detail
      if (self.EmpID === empID) 
      {
        let myoption = "";
        if (self.payrollOption == "Customized") 
        {
          self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
          self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
          startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
          enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
          myoption = self.regularEarningOption;

          if (myoption == "day") 
          {
            self.showrateperday = true;
            self.showrateperhour = false;
            self.daymonth = "Days(s)";
            self.showworkingdays = "Working Days";
          }
          if (myoption == "hour") 
          {
            self.showrateperday = false;
            self.showrateperhour = true;
            self.daymonth = "Hour(s)";
            self.showworkingdays = "Working Hours";
          }
          if (myoption == "month") 
          {
            self.showrateperday = false;
            self.showrateperhour = false;
            self.daymonth = "Days(s)";
            self.showworkingdays = "Working Days";
          }
        } 
        else 
        {
          self.showrateperday = false;
          self.showrateperhour = false;
          self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
          self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
          startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
          enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
          myoption = "";
        }
        
        let mypaydueid = 0;
        if (self.editPaymentDueId != null) 
        {
          mypaydueid = self.editPaymentDueId;
        } 
        else 
        {
          mypaydueid = 0;
        }
        let temp = {
          employee_ID: empID,
          paymentDue_ID: mypaydueid,
          companyID: store.state.companyID,
          updatestafftime: false,
          paidtype: self.disabledAddPayment,
          paymenttype: myoption,
          paydueStart_Date: startdate,
          paydueEnd_Date: enddate
        };
        self.getDetailPage(temp, slipdetail, myoption, mypaydueid);
      }
    }
  },

  async getDetailPage(temp, slipdetail, myoption, mypaydueid) {
    let self = this;
    if(mypaydueid == 0){
      self.edstut = false;
      await axios.post(`${self.url}Payment_Due/GetPayslipDetail`, temp)
      .then(async function (response) {
        self.payslipdetaillist = response.data.data;
        self.payslipdetail.totalincomecalculatesocialsecurity = 0;
        self.workingdayhistorylist = self.payslipdetaillist[0].workingHistorylist;
        for (let j = 0; j < self.additionaldeduction.length; j++) 
        {
          self.additionaldeduction.splice(0, 1);
        }
        if (mypaydueid == 0 || !self.payslipdetaillist[0].checkexistemp) 
        {
          if (self.payslipdetaillist[0].timelist.length != 0) 
          {
            for (let h = 0;h < self.payslipdetaillist[0].timelist.length;h++) 
            {
              var date = self.convertUTCDateToLocalDate(self.payslipdetaillist[0].timelist[h].myUTCdate);
              var d = new Date(date);
              var e = new Date();
              e.setHours(d.getHours(), d.getMinutes());
              var c = new Date();
              var gg = self.payslipdetaillist[0].timelist[h].shiftstart;
              let inputdate = gg.split(":");
              if (self.payslipdetaillist[0].timelist[h].settingexit == true) 
              {
                if (self.payslipdetaillist[0].timelist[h].type == "hour") 
                {
                  inputdate[0] = parseInt(inputdate[0]) + self.payslipdetaillist[0].timelist[h].time;
                } 
                else 
                {
                  inputdate[1] = parseInt(inputdate[1]) + self.payslipdetaillist[0].timelist[h].time;
                }
              }
              c.setHours(inputdate[0]);
              c.setMinutes(inputdate[1]);
              if (e > c) 
              {
                if (self.payslipdetail.latedays != null && self.payslipdetail.latedays != 0) 
                {
                  self.payslipdetail.latedays = self.payslipdetail.latedays + 1;
                } 
                else 
                {
                  self.payslipdetail.latedays = 1;
                }
              }
            }
          }
        } 
        else 
        {
          self.payslipdetail.latedays = self.payslipdetaillist[0].lateDays;
        }
        if(slipdetail != null)
        {
          self.additionaldeduction.splice(0, 1);
          let numbercount = 0;
          for (let k = 0;k < slipdetail.deductionlist.length;k++) 
          {
            self.additionaldeduction.push({
              name: slipdetail.deductionlist[k].deduction,
              amount: slipdetail.deductionlist[k].amount,
              show: true,
              id: numbercount,
              showdelete: true,
              errormessagededuname: ""
            });
            numbercount++;
            if (k == 0) 
            {
              self.additionaldeduction[k].showdelete = false;
            }
          }
        }
        else if (self.payslipdetaillist[0].deductionlist.length != 0) 
        {
          self.additionaldeduction.splice(0, 1);
          let numbercount = 0;
          for (let k = 0;k < self.payslipdetaillist[0].deductionlist.length;k++) 
          {
            self.additionaldeduction.push({
              name: self.payslipdetaillist[0].deductionlist[k].deduction,
              amount: self.payslipdetaillist[0].deductionlist[k].amount,
              show: true,
              id: numbercount,
              showdelete: true,
              errormessagededuname: ""
            });
            numbercount++;
            if (k == 0) 
            {
              self.additionaldeduction[k].showdelete = false;
            }
          }
        }
        else 
        {
          self.additionaldeduction.push({
            name: null,
            amount: 0,
            show: true,
            id: 0,
            showdelete: false,
            errormessagededuname: ""
          });
        }
        
        for (let i = 0; i < self.additionaldeduction.length; i++) 
        {
          self.additionaldeduction[i].show = false;
          if (i == self.additionaldeduction.length - 1) 
          {
            self.additionaldeduction[i].show = true;
          }
        }
        
        if (self.additionaldeduction.length != 0) 
        {
          for (let j = 0; j < self.additionaldeduction.length; j++) 
          {
            self.payslipdetail.mydeduction = self.payslipdetail.mydeduction + self.additionaldeduction[j].amount;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.additionaldeduction[j].amount;
          }
        }

        self.payslipdetail.updatedate = self.formatDate(self.payslipdetaillist[0].employeeUpdatedate);
        self.payslipdetail.company = self.payslipdetaillist[0].companyName;
        self.payslipdetail.empID = self.payslipdetaillist[0].employee_ID;
        self.payslipdetail.empcode = self.payslipdetaillist[0].employee_Code;
        self.payslipdetail.empname = self.payslipdetaillist[0].empname;
        self.payslipdetail.position = self.payslipdetaillist[0].positionName;
        self.payslipdetail.Notstampdays = slipdetail !=null ? slipdetail.stamp_Days : self.payslipdetaillist[0].notStampdays;
        self.oldnotclkin = self.payslipdetail.Notstampdays;
        self.payslipdetail.workingdays = slipdetail != null ? parseInt(slipdetail.workingDayorHour) : self.payslipdetaillist[0].workingdays;
        self.newnotclkin = self.payslipdetail.workingdays;
        if (myoption == "day") 
        {
          self.payslipdetail.rateperday = slipdetail != null ? slipdetail.working_Rate : self.payslipdetaillist[0].working_Rate;
          self.payslipdetail.regularsalary = self.payslipdetail.rateperday * self.payslipdetail.workingdays;
          self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
        }

        if (myoption == "hour") 
        {
          let totalhours = 0;
          if (mypaydueid == 0) 
          {
            for (let a = 0;a < self.payslipdetaillist[0].hourlist.length;a++) 
            {
              var starttime = self.convertUTCDateToLocalDateforpayslip(self.payslipdetaillist[0].hourlist[a].starttime);
              var endtime = "";
              if (self.payslipdetaillist[0].hourlist[a].noclockout) 
              {
                endtime = self.convertUTCDateToLocalDateforpayslip(self.payslipdetaillist[0].hourlist[a].endtime);
              } 
              else 
              {
                endtime = self.payslipdetaillist[0].hourlist[a].endtime;
              }

              // moment(stillUtc).local().format("YYYY-MM-DDTHH:mm:ss")
              starttime = moment(starttime).local().format("YYYY-MM-DD hh:mm:ss a");
              endtime = moment(endtime).local().format("YYYY-MM-DD hh:mm:ss a");
              starttime = moment(starttime);
              endtime = moment(endtime);
              let difftotalhours = 0;
              difftotalhours = endtime.diff(starttime, "hours");
              totalhours = totalhours + difftotalhours;
            }
            if (totalhours != 0) 
            {
              totalhours = totalhours - self.payslipdetaillist[0].timeoffhours;
              if (totalhours < 0) 
              {
                totalhours = 0;
              }
            }
          } 
          else 
          {
            totalhours = self.payslipdetaillist[0].workingdays;
          }
          self.payslipdetail.workingdays = totalhours;
          self.payslipdetail.rateperhour = self.payslipdetaillist[0].working_Rate;
          self.payslipdetail.regularsalary = self.payslipdetail.rateperhour * self.payslipdetail.workingdays;
          self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
        }

        if (myoption == "month" || myoption == "") 
        {
          self.payslipdetail.regularsalary = self.payslipdetaillist[0].salary;
          if(self.empButton == 2)
          {
            if(slipdetail != null)
            {
              self.payslipdetail.YTD = slipdetail.salary * parseFloat(slipdetail.workingDayorHour)
            }
            else
            {
              self.payslipdetail.YTD = self.payslipdetail.workingdays * self.payslipdetail.regularsalary;
            }
          }
          else
          {
            self.payslipdetail.YTD = 0;
          }
        }
        
        //add salary to totalincomecalculatetax, totalincomecalculatesocialsecurity, totalincome
        if( self.empButton == 2)
        {
          self.payslipdetail.totalincomecalculatetax = self.payslipdetail.YTD;
          self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.YTD;
          self.payslipdetail.totalincome = self.payslipdetail.YTD;
          self.checksocialsecurity = self.payslipdetaillist[0].socialamount;
        }
        else
        {
          self.payslipdetail.totalincomecalculatetax = self.payslipdetail.regularsalary;
          self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.regularsalary;
          self.payslipdetail.totalincome = self.payslipdetail.regularsalary;
          self.checksocialsecurity = self.payslipdetaillist[0].socialamount;
        }
        if(slipdetail != null)
        {
          if (slipdetail.providentFund) 
          {
            self.payslipdetail.checkprovident = true;
            if (!self.disabledAddPayment) 
            {
              await self.getempprovident(self.myempforprovident);
            } 
            else 
            {
              self.payslipdetail.providentfund = slipdetail.provident_Fund_Amount;
              self.payslipdetail.totaldeduction = slipdetail.total_Deduction + self.payslipdetail.providentfund;
              self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
              self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
            }
          } 
          else 
          {
            self.payslipdetail.checkprovident = slipdetail.providentFund;
            self.payslipdetail.providentfund = 0;
            self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
            self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
          }
        }
        else if (self.payslipdetaillist[0].checkexistemp) 
        {
          if (self.payslipdetaillist[0].providentcheck) 
          {
            self.payslipdetail.checkprovident = true;
            if (!self.disabledAddPayment) 
            {
              await self.getempprovident(self.myempforprovident);
            } 
            else 
            {
              self.payslipdetail.providentfund = self.payslipdetaillist[0].providentamount;
              self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.providentfund;
              self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
              self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
            }
          } 
          else 
          {
            self.payslipdetail.checkprovident = self.payslipdetaillist[0].providentcheck;
            self.payslipdetail.providentfund = 0;
            self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
            self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
          }
        } 
        else 
        {
          if (self.Payrolldeductionlist[0].pvd) 
          {
            self.payslipdetail.checkprovident = true;
            await self.getempprovident(self.myempforprovident);
          } 
          else 
          {
            self.payslipdetail.checkprovident = false;
            self.payslipdetail.providentfund = 0;
            self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
            self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund );
          }
        }
        self.removecommapayslip();
        self.changetodecimal();
        self.payslipdetail.OThours = slipdetail != null ? slipdetail.oT_Hours : self.payslipdetaillist[0].oT_Hours;
        self.payslipdetail.OThours2 = slipdetail != null ? slipdetail.oT_Hours_2 : self.payslipdetaillist[0].oT_Hours_2;
        self.payslipdetail.OThours3 = slipdetail != null ? slipdetail.oT_Hours_3 : self.payslipdetaillist[0].oT_Hours_3;
        self.payslipdetail.OThours4 = slipdetail != null ? slipdetail.oT_Hours_4 : self.payslipdetaillist[0].oT_Hours_4;
        self.payslipdetail.OTPayTotal = null;

        if(slipdetail != null)
        {
          if (slipdetail.oT_Rate != 0) 
          {
            self.payslipdetail.OTrate = slipdetail.oT_Rate;
            self.payslipdetail.OTPay = self.payslipdetail.OThours * parseFloat(self.payslipdetail.OTrate);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPayTotal;
          }
          if (slipdetail.oT_Rate_2 != 0)
          {
            self.payslipdetail.OTrate2 = slipdetail.oT_Rate_2;
            self.payslipdetail.OTPay2 = self.payslipdetail.OThours2 * parseFloat(self.payslipdetail.OTrate2);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay2 + self.payslipdetail.OTPayTotal;
          }
          if (slipdetail.oT_Rate_3 != 0)
          {
            self.payslipdetail.OTrate3 = slipdetail.oT_Rate_3;
            self.payslipdetail.OTPay3 = self.payslipdetail.OThours3 * parseFloat(self.payslipdetail.OTrate3);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay3 + self.payslipdetail.OTPayTotal;
          }
          if (slipdetail.oT_Rate_4 != 0)
          {
            self.payslipdetail.OTrate4 = slipdetail.oT_Rate_4;
            self.payslipdetail.OTPay4 = self.payslipdetail.OThours4 * parseFloat(self.payslipdetail.OTrate4);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay4 + self.payslipdetail.OTPayTotal;
          }
        }
        else
        {
          if (self.payslipdetaillist[0].oT_Rate != 0)
          {
            self.payslipdetail.OTrate = self.payslipdetaillist[0].oT_Rate;
            self.payslipdetail.OTPay = self.payslipdetail.OThours * parseFloat(self.payslipdetail.OTrate);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPayTotal;
          }
          if (self.payslipdetaillist[0].oT_Rate_2 != 0)
          {
            self.payslipdetail.OTrate2 = self.payslipdetaillist[0].oT_Rate_2;
            self.payslipdetail.OTPay2 = self.payslipdetail.OThours2 * parseFloat(self.payslipdetail.OTrate2);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay2 + self.payslipdetail.OTPayTotal;
          }
          if (self.payslipdetaillist[0].oT_Rate_3 != 0)
          {
            self.payslipdetail.OTrate3 = self.payslipdetaillist[0].oT_Rate_3;
            self.payslipdetail.OTPay3 = self.payslipdetail.OThours3 * parseFloat(self.payslipdetail.OTrate3);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay3 + self.payslipdetail.OTPayTotal;
          }
          if (self.payslipdetaillist[0].oT_Rate_4 != 0)
          {
            self.payslipdetail.OTrate4 = self.payslipdetaillist[0].oT_Rate_4;
            self.payslipdetail.OTPay4 = self.payslipdetail.OThours4 * parseFloat(self.payslipdetail.OTrate4);
            self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay4 + self.payslipdetail.OTPayTotal;
          }
        }
        
        //add OTPayTotal to totalincomecalculatetax, totalincome
        self.payslipdetail.OTPayTotal = self.returnZeroIfNull(self.payslipdetail.OTPayTotal);
        //self.payslipdetail.totalincome = self.payslipdetail.OTPayTotal + self.payslipdetail.totalincome;
        //self.payslipdetail.totalincomecalculatetax = self.payslipdetail.OTPayTotal + self.payslipdetail.totalincomecalculatetax;
        //self.payslipdetail.OTPayTotal = self.returnZeroIfNull(self.payslipdetail.OTPayTotal);
        //self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.OTPayTotal;
        
        if(slipdetail != null)
        {
          if (slipdetail.stamp_Rate != 0)
          {
            self.payslipdetail.Notstamprate = slipdetail.stamp_Rate;
            self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
            self.payslipdetail.totaldeduction = self.payslipdetail.NotstampDeduction + self.payslipdetail.totaldeduction;
          }
        }
        else if (self.payslipdetaillist[0].stamp_Rate != 0)
        {
          if(self.empButton == 2 && self.newnotclkin == 0){
            self.payslipdetail.Notstampdays = 0;
          } else {
            self.payslipdetail.Notstampdays = self.oldnotclkin;
          }
          self.payslipdetail.Notstamprate = self.payslipdetaillist[0].stamp_Rate;
          self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
          self.payslipdetail.totaldeduction = self.payslipdetail.NotstampDeduction + self.payslipdetail.totaldeduction;
        }

        if(slipdetail != null)
        {
          self.payslipdetail.latedays = slipdetail.late_Days;
          if (slipdetail.late_Rate != 0)
          {
            self.payslipdetail.laterate = slipdetail.late_Rate;
            self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
            self.payslipdetail.totaldeduction = self.payslipdetail.latededuction + self.payslipdetail.totaldeduction;
          }
        }
        else if (self.payslipdetaillist[0].late_Rate != 0)
        {
          self.payslipdetail.laterate = self.payslipdetaillist[0].late_Rate;
          self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
          self.payslipdetail.totaldeduction = self.payslipdetail.latededuction + self.payslipdetail.totaldeduction;
        }

        if (self.additionlist.length != 0) 
        {
          self.additionlist.splice(0, self.additionlist.length);
        }
        
        let numbercount = 0;
        // additional earning from salary
        if(slipdetail !=null)
        {
          for (let b = 0;b < slipdetail.additionalEarninglist.length;b++) 
          {
            const filter = self.allincomelist.find((x) => x.income_Type_ID == slipdetail.additionalEarninglist[b].income_Type_ID);
            if (filter != null) 
            {
              self.additionlist.push({
                income_Type: slipdetail.additionalEarninglist[b].income_Type_ID,
                name: filter.income_Type_Name,
                name_Eng: filter.income_Type_Name_Eng,
                amount: slipdetail.additionalEarninglist[b].amount,
                id: numbercount,
                Tax: filter.tax_Calculation,
                SSF: filter.social_Security_Calculation,
                Showplus: false,
                Showtrash: false
              });
              numbercount = numbercount + 1;               
              if (filter.tax_Calculation) 
              {
                self.payslipdetail.totalincomecalculatetax = parseFloat(self.removecomma(self.payslipdetail.totalincomecalculatetax)) + slipdetail.additionalEarninglist[b].amount;
              }
            
              if (filter.social_Security_Calculation) 
              {
                self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + slipdetail.additionalEarninglist[b].amount;
              }
              self.payslipdetail.totalincome =  parseFloat(slipdetail.additionalEarninglist[b].amount) + parseFloat(self.removecomma(self.payslipdetail.totalincome));
              self.payslipdetail.totalincome = parseFloat(self.payslipdetail.totalincome).toFixed(2);
            }
          }
        }
        else
        {
          for (let b = 0;b < self.payslipdetaillist[0].additionallist.length;b++) 
            {
            const filter = self.allincomelist.find((x) => x.income_Type_ID == self.payslipdetaillist[0].additionallist[b].income_Type_ID);
            if (filter != null) 
            {
              self.additionlist.push({
                income_Type: self.payslipdetaillist[0].additionallist[b].income_Type_ID,
                name: self.payslipdetaillist[0].additionallist[b].name,
                name_Eng: self.payslipdetaillist[0].additionallist[b].name_Eng,
                amount: self.payslipdetaillist[0].additionallist[b].amount,
                id: numbercount,
                Tax: filter.tax_Calculation,
                SSF: filter.social_Security_Calculation,
                Showplus: false,
                Showtrash: false
              });
              numbercount = numbercount + 1;

              if (filter.tax_Calculation) 
              {
                self.payslipdetail.totalincomecalculatetax = parseFloat(self.removecomma(self.payslipdetail.totalincomecalculatetax)) + self.payslipdetaillist[0].additionallist[b].amount;
              }

              if (filter.social_Security_Calculation) 
              {
                self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + self.payslipdetaillist[0].additionallist[b].amount;
              }
              self.payslipdetail.totalincome = parseFloat(self.payslipdetaillist[0].additionallist[b].amount) + parseFloat(self.removecomma(self.payslipdetail.totalincome));
              self.payslipdetail.totalincome = parseFloat(self.payslipdetail.totalincome).toFixed(2);
            }
          }
        }

        for (let y = 0; y < self.additionlist.length; y++) 
        {
          let showplus = false;
          let showdelete = false;
          
          if (y == 0) 
          {
            showdelete = false;
            showplus = false;
          } 
          else if (y == self.additionlist.length - 1) 
          {
            showdelete = true;
            showplus = true;
          } 
          else 
          {
            showplus = false;
            showdelete = true;
          }
          if(self.additionlist.length > 1){
            showdelete = true;
          }
          self.additionlist[y].Showplus = showplus;
          self.additionlist[y].Showtrash = showdelete;
        }

        if (self.additionlist.length == 1) 
        {
          self.additionlist[0].Showplus = true;
          self.additionlist[0].Showtrash = false;
        }

        if (self.additionlist.length == 0) 
        {
          if (self.allincomelist.length != 0) 
          {
            self.additionlist.push({
              income_Type: self.allincomelist[0].income_Type_ID,
              name: self.allincomelist[0].income_Type_Name,
              name_Eng: self.allincomelist[0].income_Type_Name_Eng,
              amount: 0,
              id: 0,
              Tax: self.allincomelist[0].tax_Calculation,
              SSF: self.allincomelist[0].social_Security_Calculation,
              Showplus: true,
              Showtrash: false
            });
          } 
          else 
          {
            self.additionlist.push({
              income_Type: 0,
              name: "",
              name_Eng: "",
              amount: 0,
              id: 0,
              Tax: false,
              SSF: false,
              Showplus: true,
              Showtrash: false
            });
          }
        }
        
        //additional earning from benfit
        for (let i = 0;i < self.payslipdetaillist[0].otherAdditionlist.length;i++) 
        {
          self.otheradditionlist.push({
            id: i,
            name: self.payslipdetaillist[0].otherAdditionlist[i].name,
            amount: self.payslipdetaillist[0].otherAdditionlist[i].amount,
            benefitID: self.payslipdetaillist[0].otherAdditionlist[i].benefitID,
          });
          self.payslipdetail.totalincome = parseFloat(self.otheradditionlist[i].amount) + parseFloat(self.payslipdetail.totalincome);
          self.payslipdetail.totalincome = parseFloat(self.payslipdetail.totalincome.toFixed(2));
        }

        if(slipdetail != null)
        {
          if (slipdetail.socialSecurity) 
          {
            self.payslipdetail.checksocialsecurity = slipdetail.socialSecurity;
            self.payslipdetail.socialsecurity = slipdetail.social_Security_Fund;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else 
          {
            self.payslipdetail.checksocialsecurity = slipdetail.socialSecurity;
            self.payslipdetail.socialsecurity = 0;
          }
        } else {
          if (self.payslipdetaillist[0].checkexistemp)
        {
          if (self.payslipdetaillist[0].socialcheck)
          {
            self.payslipdetail.checksocialsecurity = self.payslipdetaillist[0].socialcheck;
            if(self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.lowestSubvention)
            {
              if (!self.disabledAddPayment)
              {
                if (self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.highestsalary)
                {
                  self.payslipdetail.socialsecurity = self.Commonsocial.highestSubvention;
                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                }
                else if (self.payslipdetail.totalincomecalculatesocialsecurity < self.Commonsocial.lowestsalary) 
                {
                  self.payslipdetail.socialsecurity = 0;
                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                }
                else if (self.payslipdetail.totalincomecalculatesocialsecurity == self.Commonsocial.lowestsalary) 
                {
                  self.payslipdetail.socialsecurity = self.Commonsocial.lowestSubvention;
                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                }
                else
                {
                  self.payslipdetail.socialsecurity = Math.round((self.Commonsocial.percentage / 100) *self.payslipdetail.totalincomecalculatesocialsecurity);
                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                }
              } 
              else 
              {
                self.payslipdetail.socialsecurity = parseInt(self.payslipdetaillist[0].socialamount);
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              }
            }
          }
          else
          {
            self.payslipdetail.checksocialsecurity = self.payslipdetaillist[0].socialcheck;
            self.payslipdetail.socialsecurity = 0;
          }
        }
        else
        {
          if (self.Payrolldeductionlist[0].ssf)
          {
            if(self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.lowestSubvention)
            {
              self.payslipdetail.checksocialsecurity = self.Payrolldeductionlist[0].ssf;
              if (self.payslipdetail.totalincomecalculatesocialsecurity >= self.Commonsocial.highestsalary)
              {
                self.payslipdetail.socialsecurity = self.Commonsocial.highestSubvention;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              }
              else if (self.payslipdetail.totalincomecalculatesocialsecurity < self.Commonsocial.lowestsalary)
              {
                self.payslipdetail.socialsecurity = 0;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              }
              else if (self.payslipdetail.totalincomecalculatesocialsecurity == self.Commonsocial.lowestsalary)
              {
                self.payslipdetail.socialsecurity = self.Commonsocial.lowestSubvention;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              }
              else
              {
                self.payslipdetail.socialsecurity = Math.round((self.Commonsocial.percentage / 100) * self.payslipdetail.totalincomecalculatesocialsecurity);
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              }
            }
          } 
          else 
          {
            self.payslipdetail.checksocialsecurity = false;
            self.payslipdetail.socialsecurity = 0;
          }
        }

        }
        

        if (self.additionlist.length == 0) 
        {
          self.myadd = "";
        } 
        else 
        {
          self.myadd = "Additional Earnings";
        }

        if(slipdetail != null)
        {
          self.checkpersonaltax = slipdetail.personal_Tax;
          self.payslipdetail.personalallowanceperyear = self.payslipdetaillist[0].personaltaxamount;
        }
        else
        {
          self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax),self.payslipdetaillist[0].personaltaxamount) / 12;
          self.payslipdetail.personalallowanceperyear = self.payslipdetaillist[0].personaltaxamount;
        }

        if (self.payslipdetaillist[0].checkexistemp) 
        {
          if(slipdetail != null)
          {
            if(slipdetail.personalTax)
            {
              self.payslipdetail.checkpersonaltax = true;
              if (!self.disabledAddPayment) 
              {
                self.payslipdetail.personaltax = self.checkpersonaltax;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
              } 
              else 
              {
                self.payslipdetail.personaltax = self.payslipdetaillist[0].lasttaxamount;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
              }
            }
          }
          else if (self.payslipdetaillist[0].personaltaxcheck) 
          {
            self.payslipdetail.checkpersonaltax = true;
            if (!self.disabledAddPayment) 
            {
              self.payslipdetail.personaltax = self.checkpersonaltax;
              self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
            } 
            else 
            {
              self.payslipdetail.personaltax = self.payslipdetaillist[0].lasttaxamount;
              self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
            }
          } 
          else 
          {
            self.payslipdetail.checkpersonaltax = self.payslipdetaillist[0].personaltaxcheck;
            self.payslipdetail.personaltax = 0;
          }
        } 
        else 
        {
          if (self.Payrolldeductionlist[0].personaltax) 
          {
            self.payslipdetail.checkpersonaltax = true;
            self.payslipdetail.personaltax = self.checkpersonaltax;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
          } 
          else 
          {
            self.payslipdetail.checkpersonaltax = false;
            self.payslipdetail.personaltax = 0;
          }
        }
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.LoadingDialog = false;           
        self.putdecimalpayslip();
        self.putcommapayslip();
        await self.checksocail(1);
      });
    } else {
      self.getDetailPageforPaid(temp, myoption);
    }
    
  },

  async getDetailPageforPaid(temp, myoption) {
    let self = this;
    self.edstut = true;
    await axios.post(`${self.url}Payment_Due/GetPayslipDetail`, temp)
      .then(async function (response) {
        self.payslipdetaillist = response.data.data;
        self.payslipdetail.totalincomecalculatesocialsecurity = 0;
        self.workingdayhistorylist = self.payslipdetaillist[0].workingHistorylist;
        for (let j = 0; j < self.additionaldeduction.length; j++) 
        {
          self.additionaldeduction.splice(0, 1);
        }
         
        self.payslipdetail.latedays = self.payslipdetaillist[0].lateDays;
        if (self.payslipdetaillist[0].deductionlist.length != 0) 
        {
          self.additionaldeduction.splice(0, 1);
          let numbercount = 0;
          for (let k = 0;k < self.payslipdetaillist[0].deductionlist.length;k++) 
          {
            self.additionaldeduction.push({
              name: self.payslipdetaillist[0].deductionlist[k].deduction,
              amount: self.payslipdetaillist[0].deductionlist[k].amount,
              show: true,
              id: numbercount,
              showdelete: true,
              errormessagededuname: ""
            });
            numbercount++;
            if (k == 0) 
            {
              self.additionaldeduction[k].showdelete = false;
            }
          }
        }
        else 
        {
          self.additionaldeduction.push({
            name: null,
            amount: 0,
            show: true,
            id: 0,
            showdelete: false,
            errormessagededuname: ""
          });
        }
        
        for (let i = 0; i < self.additionaldeduction.length; i++) 
        {
          self.additionaldeduction[i].show = false;
          if (i == self.additionaldeduction.length - 1) 
          {
            self.additionaldeduction[i].show = true;
          }
        }
        
        if (self.additionaldeduction.length != 0) 
        {
          for (let j = 0; j < self.additionaldeduction.length; j++) 
          {
            self.payslipdetail.mydeduction = self.payslipdetail.mydeduction + self.additionaldeduction[j].amount;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.additionaldeduction[j].amount;
          }
        }

        self.payslipdetail.updatedate = self.formatDate(self.payslipdetaillist[0].employeeUpdatedate);
        self.payslipdetail.company = self.payslipdetaillist[0].companyName;
        self.payslipdetail.empID = self.payslipdetaillist[0].employee_ID;
        self.payslipdetail.empcode = self.payslipdetaillist[0].employee_Code;
        self.payslipdetail.empname = self.payslipdetaillist[0].empname;
        self.payslipdetail.position = self.payslipdetaillist[0].positionName;
        self.payslipdetail.Notstampdays = self.payslipdetaillist[0].notStampdays;
        self.oldnotclkin = self.payslipdetail.Notstampdays;
        self.payslipdetail.workingdays = self.payslipdetaillist[0].workingdays;
        //self.newnotclkin = self.payslipdetail.workingdays;
        if (myoption == "day") 
        {
          self.payslipdetail.rateperday = self.payslipdetaillist[0].working_Rate;
          self.payslipdetail.regularsalary = self.payslipdetail.rateperday * self.payslipdetail.workingdays;
          self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
        }

        if (myoption == "month" || myoption == "") 
        {
          self.payslipdetail.regularsalary = self.payslipdetaillist[0].salary;
          if(self.empButton == 2)
          {
            
            self.payslipdetail.YTD = self.payslipdetail.workingdays * self.payslipdetail.regularsalary;
            
          }
          else
          {
            self.payslipdetail.YTD = 0;
          }
        }
        
        //add salary to totalincomecalculatetax, totalincomecalculatesocialsecurity, totalincome
        if( self.empButton == 2)
        {
          self.payslipdetail.totalincomecalculatetax = self.payslipdetail.YTD;
          self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.YTD;
          self.payslipdetail.totalincome = self.payslipdetail.YTD;
          self.checksocialsecurity = self.payslipdetaillist[0].socialamount;
        }
        else
        {
          self.payslipdetail.totalincomecalculatetax = self.payslipdetail.regularsalary;
          self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.regularsalary;
          self.payslipdetail.totalincome = self.payslipdetail.regularsalary;
          self.checksocialsecurity = self.payslipdetaillist[0].socialamount;
        }
        if (self.payslipdetaillist[0].providentcheck) 
        {
          self.payslipdetail.checkprovident = true;
          if (!self.disabledAddPayment) 
          {
            await self.getempprovident(self.myempforprovident);
          } 
          else 
          {
            self.payslipdetail.providentfund = self.payslipdetaillist[0].providentamount;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.providentfund;
            self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
            self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
          }
        } 
        else 
        {
          self.payslipdetail.checkprovident = self.payslipdetaillist[0].providentcheck;
          self.payslipdetail.providentfund = 0;
          self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
          self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
        }
        
        self.removecommapayslip();
        self.changetodecimal();
        self.payslipdetail.OThours = self.payslipdetaillist[0].oT_Hours;
        self.payslipdetail.OThours2 = self.payslipdetaillist[0].oT_Hours_2;
        self.payslipdetail.OThours3 = self.payslipdetaillist[0].oT_Hours_3;
        self.payslipdetail.OThours4 = self.payslipdetaillist[0].oT_Hours_4;
        self.payslipdetail.OTPayTotal = null;

        
        if (self.payslipdetaillist[0].oT_Rate != 0)
        {
          self.payslipdetail.OTrate = self.payslipdetaillist[0].oT_Rate;
          self.payslipdetail.OTPay = self.payslipdetail.OThours * parseFloat(self.payslipdetail.OTrate);
          self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPayTotal;
        }
        if (self.payslipdetaillist[0].oT_Rate_2 != 0)
        {
          self.payslipdetail.OTrate2 = self.payslipdetaillist[0].oT_Rate_2;
          self.payslipdetail.OTPay2 = self.payslipdetail.OThours2 * parseFloat(self.payslipdetail.OTrate2);
          self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay2 + self.payslipdetail.OTPayTotal;
        }
        if (self.payslipdetaillist[0].oT_Rate_3 != 0)
        {
          self.payslipdetail.OTrate3 = self.payslipdetaillist[0].oT_Rate_3;
          self.payslipdetail.OTPay3 = self.payslipdetail.OThours3 * parseFloat(self.payslipdetail.OTrate3);
          self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay3 + self.payslipdetail.OTPayTotal;
        }
        if (self.payslipdetaillist[0].oT_Rate_4 != 0)
        {
          self.payslipdetail.OTrate4 = self.payslipdetaillist[0].oT_Rate_4;
          self.payslipdetail.OTPay4 = self.payslipdetail.OThours4 * parseFloat(self.payslipdetail.OTrate4);
          self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay4 + self.payslipdetail.OTPayTotal;
        }
        
        //add OTPayTotal to totalincomecalculatetax, totalincome
        self.payslipdetail.OTPayTotal = self.returnZeroIfNull(self.payslipdetail.OTPayTotal);
        self.payslipdetail.totalincome = self.payslipdetail.OTPayTotal + self.payslipdetail.totalincome;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.OTPayTotal + self.payslipdetail.totalincomecalculatetax;
        if (self.payslipdetaillist[0].stamp_Rate != 0 && self.empButton == 1)
        {
          self.payslipdetail.Notstampdays = self.oldnotclkin;
          self.payslipdetail.Notstamprate = self.payslipdetaillist[0].stamp_Rate;
          self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
          if(self.empButton == 1){
            self.payslipdetail.totaldeduction = self.payslipdetail.NotstampDeduction + self.payslipdetail.totaldeduction;
          }
        }

        if (self.payslipdetaillist[0].late_Rate != 0)
        {
          self.payslipdetail.laterate = self.payslipdetaillist[0].late_Rate;
          self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
          self.payslipdetail.totaldeduction = self.payslipdetail.latededuction + self.payslipdetail.totaldeduction;
        }

        if (self.additionlist.length != 0) 
        {
          self.additionlist.splice(0, self.additionlist.length);
        }
        
        let numbercount = 0;
        // additional earning from salary
        
        for (let b = 0;b < self.payslipdetaillist[0].additionallist.length;b++) 
          {
          const filter = self.allincomelist.find((x) => x.income_Type_ID == self.payslipdetaillist[0].additionallist[b].income_Type_ID);
          if (filter != null) 
          {
            self.additionlist.push({
              income_Type: self.payslipdetaillist[0].additionallist[b].income_Type_ID,
              name: self.payslipdetaillist[0].additionallist[b].name,
              name_Eng: self.payslipdetaillist[0].additionallist[b].name_Eng,
              amount: self.payslipdetaillist[0].additionallist[b].amount,
              id: numbercount,
              Tax: filter.tax_Calculation,
              SSF: filter.social_Security_Calculation,
              Showplus: false,
              Showtrash: false
            });
            numbercount = numbercount + 1;

            if (filter.tax_Calculation) 
            {
              self.payslipdetail.totalincomecalculatetax = parseFloat(self.removecomma(self.payslipdetail.totalincomecalculatetax)) + self.payslipdetaillist[0].additionallist[b].amount;
            }

            if (filter.social_Security_Calculation) 
            {
              self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + self.payslipdetaillist[0].additionallist[b].amount;
            }
            self.payslipdetail.totalincome = parseFloat(self.payslipdetaillist[0].additionallist[b].amount) + parseFloat(self.removecomma(self.payslipdetail.totalincome));
            self.payslipdetail.totalincome = parseFloat(self.payslipdetail.totalincome).toFixed(2);
          }
        }
        
        for (let y = 0; y < self.additionlist.length; y++) 
        {
          let showplus = false;
          let showdelete = false;
          
          if (y == 0) 
          {
            showdelete = false;
            showplus = false;
          } 
          else if (y == self.additionlist.length - 1) 
          {
            showdelete = true;
            showplus = true;
          } 
          else 
          {
            showplus = false;
            showdelete = true;
          }
          if(self.additionlist.length > 1){
            showdelete = true;
          }
          self.additionlist[y].Showplus = showplus;
          self.additionlist[y].Showtrash = showdelete;
        }

        if (self.additionlist.length == 1) 
        {
          self.additionlist[0].Showplus = true;
          self.additionlist[0].Showtrash = false;
        }
        if (self.additionlist.length == 0) 
        {
          if (self.allincomelist.length != 0) 
          {
            self.additionlist.push({
              income_Type: self.allincomelist[0].income_Type_ID,
              name: self.allincomelist[0].income_Type_Name,
              name_Eng: self.allincomelist[0].income_Type_Name_Eng,
              amount: 0,
              id: 0,
              Tax: self.allincomelist[0].tax_Calculation,
              SSF: self.allincomelist[0].social_Security_Calculation,
              Showplus: true,
              Showtrash: false
            });
          } 
          else 
          {
            self.additionlist.push({
              income_Type: 0,
              name: "",
              name_Eng: "",
              amount: 0,
              id: 0,
              Tax: false,
              SSF: false,
              Showplus: true,
              Showtrash: false
            });
          }
        }
        
        //additional earning from benfit
        for (let i = 0;i < self.payslipdetaillist[0].otherAdditionlist.length;i++) 
        {
          self.otheradditionlist.push({
            id: i,
            name: self.payslipdetaillist[0].otherAdditionlist[i].name,
            amount: self.payslipdetaillist[0].otherAdditionlist[i].amount,
            benefitID: self.payslipdetaillist[0].otherAdditionlist[i].benefitID,
          });
          self.payslipdetail.totalincome = parseFloat(self.otheradditionlist[i].amount) + parseFloat(self.payslipdetail.totalincome);
          self.payslipdetail.totalincome = parseFloat(self.payslipdetail.totalincome.toFixed(2));
        }

        if (self.payslipdetaillist[0].checkexistemp)
        {
          if (self.payslipdetaillist[0].socialcheck)
          {
            self.payslipdetail.checksocialsecurity = self.payslipdetaillist[0].socialcheck;
            self.payslipdetail.socialsecurity = parseInt(self.payslipdetaillist[0].socialamount);
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              
          }
        }
        else
        {
          if (self.Payrolldeductionlist[0].ssf)
          {
            self.payslipdetail.socialsecurity = parseInt(self.payslipdetaillist[0].socialamount);
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
          } 
          else 
          {
            self.payslipdetail.checksocialsecurity = false;
            self.payslipdetail.socialsecurity = 0;
          }
        }

        if (self.additionlist.length == 0) 
        {
          self.myadd = "";
        } 
        else 
        {
          self.myadd = "Additional Earnings";
        }

        self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax),self.payslipdetaillist[0].personaltaxamount) / 12;
        self.payslipdetail.personalallowanceperyear = self.payslipdetaillist[0].personaltaxamount;
        
        if (self.payslipdetaillist[0].checkexistemp) 
        {
          if (self.payslipdetaillist[0].personaltaxcheck) 
          {
            self.payslipdetail.checkpersonaltax = true;
            if (!self.disabledAddPayment) 
            {
              self.payslipdetail.personaltax = self.checkpersonaltax;
              self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
            } 
            else 
            {
              self.payslipdetail.personaltax = self.payslipdetaillist[0].lasttaxamount;
              self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
            }
          } 
          else 
          {
            self.payslipdetail.checkpersonaltax = self.payslipdetaillist[0].personaltaxcheck;
            self.payslipdetail.personaltax = 0;
          }
        } 
        else 
        {
          if (self.Payrolldeductionlist[0].personaltax) 
          {
            self.payslipdetail.checkpersonaltax = true;
            self.payslipdetail.personaltax = self.checkpersonaltax;
            self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
          } 
          else 
          {
            self.payslipdetail.checkpersonaltax = false;
            self.payslipdetail.personaltax = 0;
          }
        }
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.LoadingDialog = false;           
        self.putdecimalpayslip();
        self.putcommapayslip();
      });
  },

  //payslip detail from back button
  async prevemp() {
    let self = this;
    self.LoadingDialog = true;
    await self.Getallincomeforpayslip();
    let startdate = "";
    let enddate = "";
    let myoption = "";
    let goprev = false;
    for (let j = 0; j < self.EditpaymentDuesetup.length; j++) 
    {
      if (self.EditpaymentDuesetup[j].empID == self.EmpID) 
      {
        if (j != 0) 
        {
          if (goprev == false) 
          {
            if (self.EditpaymentDuesetup[j - 1].empID != null) 
            {
              self.myemp = j + 1 - 1;
              self.myempforprovident = self.EditpaymentDuesetup[j - 1].empID;
              self.totallist = self.EditpaymentDuesetup.length;
            }
          }
        } 
        else 
        {
          self.myemp = self.EditpaymentDuesetup.length;
          self.myempforprovident = self.EditpaymentDuesetup[self.EditpaymentDuesetup.length - 1].empID;
          self.totallist = self.EditpaymentDuesetup.length;
          self.EmpID =self.EditpaymentDuesetup[self.EditpaymentDuesetup.length - 1].empID;
          goprev = true;
        }
      }
    }

    if (self.EditpaymentDuesetup.length == 1) 
    {
      self.disablebtn = true;
    } 
    else 
    {
      self.disablebtn = false;
    }

    let checktrue = 0;
    for (let i = 0; i < self.EditpaymentDuesetup.length; i++) 
    {
      if (checktrue == 0) 
      {
        if (self.EmpID == self.EditpaymentDuesetup[i].empID) 
        {
          if (i != 0) 
          {
            self.cleanpayslipcache();
            if (self.EditpaymentDuesetup[i - 1].empID != null) 
            {
              checktrue = 1;
              if (goprev == false) 
              {
                self.EmpID = self.EditpaymentDuesetup[i - 1].empID;
              }

              if (self.payrollOption == "Customized") 
              {
                self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
                self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
                startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
                enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
                myoption = self.regularEarningOption;

                if (myoption == "day") 
                {
                  self.showrateperday = true;
                  self.showrateperhour = false;
                  self.daymonth = "Days(s)";
                  self.showworkingdays = "Working Days";
                }
                if (myoption == "hour") 
                {
                  self.showrateperday = false;
                  self.showrateperhour = true;
                  self.daymonth = "Hour(s)";
                  self.showworkingdays = "Working Hours";
                }
                if (myoption == "month") 
                {
                  self.showrateperday = false;
                  self.showrateperhour = false;
                  self.daymonth = "Days(s)";
                  self.showworkingdays = "Working Days";
                }
              } 
              else 
              {
                self.showrateperday = false;
                self.showrateperhour = false;
                self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
                self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
                startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
                enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
                myoption = "";
              }

              let mypaydueid = 0;
              let updatestaffcheck = false;
              if (self.recalculateemplist.length != 0) 
              {
                let check = true;
                self.recalculateemplist.map(function (e) 
                {
                  if (check == true) 
                  {
                    if (self.editPaymentDueId != null) 
                    {
                      if (e.employee_ID == self.EmpID) 
                      {
                        mypaydueid = 0;
                        check = false;
                      } 
                      else 
                      {
                        mypaydueid = self.editPaymentDueId;
                      }
                    } 
                    else 
                    {
                      mypaydueid = 0;
                      check = false;
                    }
                  }
                });
              } 
              else if (self.updatestafftimeemplist.length != 0) 
              {
                let check = true;
                self.updatestafftimeemplist.map(function (e) {
                  if (check == true) 
                  {
                    if (self.editPaymentDueId != null) 
                    {
                      if (e.employee_ID == self.EmpID) 
                      {
                        mypaydueid = 0;
                        check = false;
                        updatestaffcheck = true;
                      } 
                      else 
                      {
                        mypaydueid = self.editPaymentDueId;
                      }
                    } 
                    else 
                    {
                      mypaydueid = 0;
                      check = false;
                    }
                  }
                });
              } 
              else 
              {
                if (self.editPaymentDueId != null) 
                {
                  mypaydueid = self.editPaymentDueId;
                } 
                else 
                {
                  mypaydueid = 0;
                }
              }
              let temp = {
                employee_ID: self.EmpID,
                paymentDue_ID: mypaydueid,
                companyID: store.state.companyID,
                updatestafftime: updatestaffcheck,
                paidtype: self.disabledAddPayment,
                paymenttype: myoption,
                paydueStart_Date: startdate,
                paydueEnd_Date: enddate
              };
              let slipdetail = null;
              self.getDetailPage(temp, slipdetail, myoption, mypaydueid);
            }
          } 
          else 
          {
            self.LoadingDialog = false;
          }
        }
      }
    }
  },

  //payslip detail for front button
  async nextemp() {
    let self = this;
    self.LoadingDialog = true;
    let startdate = "";
    let enddate = "";
    let myoption = "";
    await self.Getallincomeforpayslip();
    let gonext = true;
    for (let j = 0; j < self.EditpaymentDuesetup.length; j++) 
    {
      if (self.EditpaymentDuesetup[j].empID == self.EmpID) 
      {
        if (j + 1 != self.EditpaymentDuesetup.length) 
        {
          if (self.EditpaymentDuesetup[j + 1].empID != null) 
          {
            self.myemp = j + 2;
            self.myempforprovident = self.EditpaymentDuesetup[j + 1].empID;
            self.totallist = self.EditpaymentDuesetup.length;
          }
        } 
        else 
        {
          self.myemp = 1;
          self.myempforprovident = self.EditpaymentDuesetup[0].empID;
          self.totallist = self.EditpaymentDuesetup.length;
          gonext = false;
          self.EmpID = self.EditpaymentDuesetup[0].empID;
        }
      }
    }
    if (self.EditpaymentDuesetup.length == 1) 
    {
      self.disablebtn = true;
    } 
    else 
    {
      self.disablebtn = false;
    }
    let checktrue = 0;
    self.cleanpayslipcache();

    for (let i = 0; i < self.EditpaymentDuesetup.length; i++) 
    {
      if (checktrue == 0) 
      {
        if (self.EmpID == self.EditpaymentDuesetup[i].empID) 
        {
          if (self.EditpaymentDuesetup[i + 1].empID != null) 
          {
            checktrue = 1;
            if (gonext == true) 
            {
              self.EmpID = self.EditpaymentDuesetup[i + 1].empID;
            }

            if (self.payrollOption == "Customized") 
            {
              self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
              self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
              startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
              enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
              myoption = self.regularEarningOption;

              if (myoption == "day") 
              {
                self.showrateperday = true;
                self.showrateperhour = false;
                self.daymonth = "Days(s)";
                self.showworkingdays = "Working Days";
              }

              if (myoption == "hour") 
              {
                self.showrateperday = false;
                self.showrateperhour = true;
                self.daymonth = "Hour(s)";
                self.showworkingdays = "Working Days";
              }

              if (myoption == "month") 
              {
                self.showrateperday = false;
                self.showrateperhour = false;
                self.daymonth = "Days(s)";
                self.showworkingdays = "Working Days";
              }
            } 
            else 
            {
              self.showrateperday = false;
              self.showrateperhour = false;
              self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
              self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
              startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
              enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
              myoption = "";
            }

            let mypaydueid = 0;
            let updatestaffcheck = false;
            if (self.recalculateemplist.length != 0) 
            {
              let check = true;
              self.recalculateemplist.map(function (e) 
              {
                if (check == true) 
                {
                  if (self.editPaymentDueId != null) 
                  {
                    if (e.employee_ID == self.EmpID) 
                    {
                      mypaydueid = 0;
                      check = false;
                    } 
                    else
                    {
                      mypaydueid = self.editPaymentDueId;
                    }
                  }
                  else
                  {
                    mypaydueid = 0;
                    check = false;
                  }
                }
              });
            } 
            else if (self.updatestafftimeemplist.length != 0) 
            {
              let check = true;
              self.updatestafftimeemplist.map(function (e) 
              {
                if (check == true) 
                {
                  if (self.editPaymentDueId != null) 
                  {
                    if (e.employee_ID == self.EmpID) 
                    {
                      mypaydueid = 0;
                      check = false;
                      updatestaffcheck = true;
                    } 
                    else 
                    {
                      mypaydueid = self.editPaymentDueId;
                    }
                  } 
                  else 
                  {
                    mypaydueid = 0;
                    check = false;
                  }
                }
              });
            } 
            else 
            {
              if (self.editPaymentDueId != null) 
              {
                mypaydueid = self.editPaymentDueId;
              } 
              else 
              {
                mypaydueid = 0;
              }
            }
            let temp = {
              employee_ID: self.EmpID,
              paymentDue_ID: mypaydueid,
              companyID: store.state.companyID,
              updatestafftime: updatestaffcheck,
              paidtype: self.disabledAddPayment,
              paymenttype: myoption,
              paydueStart_Date: startdate,
              paydueEnd_Date: enddate
            };
            let slipdetail = null;
            self.getDetailPage(temp, slipdetail, myoption, mypaydueid);
          }
        }
      }
    }
  },

  //payslip detail for recalculate button and show the first employee from recalculate list
  async Recalculateemp() {
    let self = this;
    self.LoadingDialog = true;
    self.showforupdate = true;
    await self.Getallincomeforpayslip();
    self.recalculateDialog = false;
    self.btnshow = true;
    let startdate = "";
    let enddate = "";
    let myoption = "";
    self.checkemplistforeditstaff.map(function (e) {
      self.recalculateemplist.push({
        employee_ID: e
      });
    });

    if(self.EditpaymentDuesetup.length != 0)
    {
      self.checkemplistforeditstaff = self.checkemplistforeditstaff.map(emp => self.EditpaymentDuesetup.find(checkEmp => checkEmp.empID === emp));
    }
    self.EmpID = 0;
    if (self.checkemplistforeditstaff.length != 0) 
    {
      self.tab = "three";
      for (let a = 0; a < self.checkemplistforeditstaff.length; a++) 
      {
        self.EmpID = self.checkemplistforeditstaff[a].empID;
        self.myempforprovident = self.checkemplistforeditstaff[a].empID;
        for (let j = 0; j < self.EditpaymentDuesetup.length; j++) 
        {
          if (self.EditpaymentDuesetup[j].empID == self.checkemplistforeditstaff[a].empID) 
          {
            self.myemp = j + 1;
            self.totallist = self.EditpaymentDuesetup.length;
          }
        }

        if (self.EditpaymentDuesetup.length == 1) 
        {
          self.disablebtn = true;
        } 
        else 
        {
          self.disablebtn = false;
        }

        if (self.payrollOption == "Customized") 
        {
          self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
          self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
          startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
          enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);

          myoption = self.regularEarningOption;
          if (myoption == "day") 
          {
            self.showrateperday = true;
            self.showrateperhour = false;
            self.daymonth = "Days(s)";
            self.showworkingdays = "Working Days";
          }
          if (myoption == "hour") 
          {
            self.showrateperday = false;
            self.showrateperhour = true;
            self.daymonth = "Hour(s)";
            self.showworkingdays = "Working Hours";
          }
          if (myoption == "month") 
          {
            self.showrateperday = false;
            self.showrateperhour = false;
            self.daymonth = "Days(s)";
            self.showworkingdays = "Working Days";
          }
        } 
        else 
        {
          self.showrateperday = false;
          self.showrateperhour = false;
          if (self.editadd == true) 
          {
            self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
            self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
            startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
            enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
            myoption = self.regularEarningOption;
          } 
          else 
          {
            self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
            self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
            startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
            enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
            myoption = "";
          }
        }
        let mypaydueid = 0;
        let temp = {
          employee_ID: self.checkemplistforeditstaff[a].empID,
          paymentDue_ID: mypaydueid,
          companyID: store.state.companyID,
          paidtype: self.disabledAddPayment,
          paymenttype: myoption,
          paydueStart_Date: startdate,
          paydueEnd_Date: enddate
        };
        axios
          .post(`${self.url}Payment_Due/GetPayslipDetail`, temp)
          .then(function (response) {
            self.payslipdetaillist = response.data.data;
            self.cleanpayslipcache();
            self.workingdayhistorylist = self.payslipdetaillist[0].workingHistorylist;
            for (let j = 0; j < self.additionaldeduction.length; j++) 
            {
              self.additionaldeduction.splice(0, 1);
            }

            if (self.payslipdetaillist[0].timelist.length != 0) 
            {
              for (let h = 0;h < self.payslipdetaillist[0].timelist.length;h++) 
              {
                var date = self.convertUTCDateToLocalDate(self.payslipdetaillist[0].timelist[h].myUTCdate);
                var d = new Date(date);
                var e = new Date();
                e.setHours(d.getHours(), d.getMinutes());
                var c = new Date();
                var gg = self.payslipdetaillist[0].timelist[h].shiftstart;
                let inputdate = gg.split(":");
                if (self.payslipdetaillist[0].timelist[h].settingexit == true) 
                {
                  if (self.payslipdetaillist[0].timelist[h].type == "hour") 
                  {
                    inputdate[0] = parseInt(inputdate[0]) + self.payslipdetaillist[0].timelist[h].time;
                  } 
                  else 
                  {
                    inputdate[1] = parseInt(inputdate[1]) + self.payslipdetaillist[0].timelist[h].time;
                  }
                }
                c.setHours(inputdate[0]);
                c.setMinutes(inputdate[1]);
                
                if (e > c) 
                {
                  if ( self.payslipdetail.latedays != null && self.payslipdetail.latedays != 0) 
                  {
                    self.payslipdetail.latedays = self.payslipdetail.latedays + 1;
                  } 
                  else 
                  {
                    self.payslipdetail.latedays = 1;
                  }
                }
              }
            }

            if (self.payslipdetaillist[0].deductionlist.length != 0) 
            {
              self.additionaldeduction.splice(0, 1);
              let numbercount = 0;
              for (let k = 0;k < self.payslipdetaillist[0].deductionlist.length;k++) 
              {
                self.additionaldeduction.push({
                  name: self.payslipdetaillist[0].deductionlist[k].deduction,
                  amount: self.payslipdetaillist[0].deductionlist[k].amount,
                  show: true,
                  id: numbercount,
                  showdelete: true,
                  errormessagededuname: ""
                });
                numbercount++;  
                if (k == 0) 
                {
                  self.additionaldeduction[k].showdelete = false;
                }
              }
            } 
            else 
            {
              self.additionaldeduction.push({
                name: null,
                amount: 0,
                show: true,
                id: 0,
                showdelete: false,
                errormessagededuname: ""
              });
            }
            for (let i = 0; i < self.additionaldeduction.length; i++) 
            {
              self.additionaldeduction[i].show = false;
              if (i == self.additionaldeduction.length - 1) 
              {
                self.additionaldeduction[i].show = true;
              }
            }
            self.payslipdetail.updatedate = self.formatDate(self.payslipdetaillist[0].employeeUpdatedate);
            self.payslipdetail.company = self.payslipdetaillist[0].companyName;
            self.payslipdetail.empID = self.payslipdetaillist[0].employee_ID;
            self.payslipdetail.empcode = self.payslipdetaillist[0].employee_Code;
            self.payslipdetail.empname = self.payslipdetaillist[0].empname;
            self.payslipdetail.position = self.payslipdetaillist[0].positionName;
            self.payslipdetail.Notstampdays = self.payslipdetaillist[0].notStampdays;
            self.oldnotclkin = self.payslipdetail.Notstampdays;
            self.payslipdetail.workingdays = self.payslipdetaillist[0].workingdays;
            self.newnotclkin = self.payslipdetail.workingdays;
            if (myoption == "day") {
              self.payslipdetail.rateperday = self.payslipdetaillist[0].working_Rate;
              self.payslipdetail.regularsalary = self.payslipdetail.rateperday * self.payslipdetail.workingdays;
              self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
            }

            if (myoption == "hour") 
            {
              let totalhours = 0;
              for (let a = 0;a < self.payslipdetaillist[0].hourlist.length;a++) 
              {
                var starttime = self.convertUTCDateToLocalDateforpayslip(self.payslipdetaillist[0].hourlist[a].starttime);
                var endtime = "";
                if (self.payslipdetaillist[0].hourlist[a].noclockout == true) 
                {
                  endtime = self.convertUTCDateToLocalDateforpayslip(self.payslipdetaillist[0].hourlist[a].endtime);
                } 
                else 
                {
                  endtime = self.payslipdetaillist[0].hourlist[a].endtime;
                }
                // moment(stillUtc).local().format("YYYY-MM-DDTHH:mm:ss")
                starttime = moment(starttime).local().format("YYYY-MM-DD hh:mm:ss a");
                endtime = moment(endtime).local().format("YYYY-MM-DD hh:mm:ss a");
                starttime = moment(starttime);
                endtime = moment(endtime);
                let difftotalhours = 0;
                difftotalhours = endtime.diff(starttime, "hours");
                totalhours = totalhours + difftotalhours;
              }
              if (totalhours != 0) 
              {
                totalhours = totalhours - self.payslipdetaillist[0].timeoffhours;
                if (totalhours < 0) 
                {
                  totalhours = 0;
                }
              }
              self.payslipdetail.workingdays = totalhours;
              self.payslipdetail.rateperhour = self.payslipdetaillist[0].working_Rate;
              self.payslipdetail.regularsalary = self.payslipdetail.rateperhour * self.payslipdetail.workingdays;
              self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
            }

            if (myoption == "month" || myoption == "") 
            {
              self.payslipdetail.regularsalary = self.payslipdetaillist[0].salary;
              self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
            }

            self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.regularsalary;
            self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + self.payslipdetail.regularsalary;
            self.payslipdetail.totalincome = self.payslipdetail.regularsalary + self.payslipdetail.totalincome;
            self.checksocialsecurity = self.payslipdetaillist[0].socialamount;
            self.checkprovident = self.payslipdetaillist[0].providentamount;

            if (self.payslipdetaillist[0].checkexistemp) 
            {
              if (self.payslipdetaillist[0].socialcheck) 
              {
                self.payslipdetail.checksocialsecurity = true;
                //  let mysocial = self.checksocialsecurity.split(" ");
                self.payslipdetail.socialsecurity = parseInt(self.checksocialsecurity);
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
              } 
              else 
              {
                self.payslipdetail.checksocialsecurity = self.payslipdetaillist[0].socialcheck;
                self.payslipdetail.socialsecurity = 0;
              }
            } 
            else 
            {
              if (self.Payrolldeductionlist[0].ssf) 
              {
                self.payslipdetail.checksocialsecurity = self.Payrolldeductionlist[0].ssf;
                if (self.payslipdetail.totalincomecalculatesocialsecurity > self.Commonsocial.highestsalary)
                {
                  // let mysocial = self.checksocialsecurity.split(" ");
                  self.payslipdetail.socialsecurity = parseInt(self.checksocialsecurity);
                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                }
                else 
                {
                  if (self.deduction.length != 0) 
                  {
                    self.payslipdetail.socialsecurity = Math.round((self.deduction[0].percentage / 100) * self.payslipdetail.totalincomecalculatesocialsecurity);
                    self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                  } 
                  else 
                  {
                    self.payslipdetail.socialsecurity = "N/A";
                  }
                }
              } 
              else 
              {
                self.payslipdetail.checksocialsecurity = false;
                self.payslipdetail.socialsecurity = 0;
              }
            }

            self.payslipdetail.OThours = self.payslipdetaillist[0].oT_Hours;
            self.payslipdetail.OThours2 = self.payslipdetaillist[0].oT_Hours_2;
            self.payslipdetail.OThours3 = self.payslipdetaillist[0].oT_Hours_3;
            self.payslipdetail.OThours4 = self.payslipdetaillist[0].oT_Hours_4;

            if (self.payslipdetaillist[0].oT_Rate != 0) 
            {
              self.payslipdetail.OTrate = self.payslipdetaillist[0].oT_Rate;
              self.payslipdetail.OTPay = self.payslipdetail.OThours * parseFloat(self.payslipdetail.OTrate);
              self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPayTotal;
              self.payslipdetail.totalincome = self.payslipdetail.OTPay + self.payslipdetail.totalincome;
            }

            if (self.payslipdetaillist[0].oT_Rate_2 != 0) 
            {
              self.payslipdetail.OTrate2 = self.payslipdetaillist[0].oT_Rate_2;
              self.payslipdetail.OTPay2 = self.payslipdetail.OThours2 * parseFloat(self.payslipdetail.OTrate2);
              self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay2 + self.payslipdetail.OTPayTotal;
              self.payslipdetail.totalincome = self.payslipdetail.OTPay2 + self.payslipdetail.totalincome;
            }

            if (self.payslipdetaillist[0].oT_Rate_3 != 0) 
            {
              self.payslipdetail.OTrate3 = self.payslipdetaillist[0].oT_Rate_3;
              self.payslipdetail.OTPay3 = self.payslipdetail.OThours3 * parseFloat(self.payslipdetail.OTrate3);
              self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay3 + self.payslipdetail.OTPayTotal;
              self.payslipdetail.totalincome = self.payslipdetail.OTPay3 + self.payslipdetail.totalincome;
            }

            if (self.payslipdetaillist[0].oT_Rate_4 != 0) 
            {
              self.payslipdetail.OTrate4 = self.payslipdetaillist[0].oT_Rate_4;
              self.payslipdetail.OTPay4 = self.payslipdetail.OThours4 * parseFloat(self.payslipdetail.OTrate4);
              self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay4 + self.payslipdetail.OTPayTotal;
              self.payslipdetail.totalincome = self.payslipdetail.OTPay4 + self.payslipdetail.totalincome;
            }
            self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.OTPay;
            
            if (self.payslipdetaillist[0].stamp_Rate != 0) 
            {
              if(self.empButton == 2 && self.newnotclkin == 0){
                self.payslipdetail.Notstampdays = 0;
              } else {
                self.payslipdetail.Notstampdays = self.oldnotclkin;
              }
              self.payslipdetail.Notstamprate = self.payslipdetaillist[0].stamp_Rate;
              self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
              self.payslipdetail.totaldeduction = self.payslipdetail.NotstampDeduction + self.payslipdetail.totaldeduction;
            }

            // self.payslipdetail.latedays = self.payslipdetaillist[0].lateDays;
            if (self.payslipdetaillist[0].late_Rate != 0) 
            {
              self.payslipdetail.laterate = self.payslipdetaillist[0].late_Rate;
              self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
              self.payslipdetail.totaldeduction = self.payslipdetail.latededuction + self.payslipdetail.totaldeduction;
            }

            if (self.additionlist.length != 0) 
            {
              self.additionlist.splice(0, self.additionlist.length);
            }
            let numbercount = 0;

            for (let b = 0;b < self.payslipdetaillist[0].additionallist.length;b++) 
            {
              const filter = self.allincomelist.find((x) => x.income_Type_ID == self.payslipdetaillist[0].additionallist[b].income_Type_ID);
              if (filter != null) 
              {
                self.additionlist.push({
                  income_Type: self.payslipdetaillist[0].additionallist[b].income_Type_ID,
                  name: self.payslipdetaillist[0].additionallist[b].name,
                  name_Eng: self.payslipdetaillist[0].additionallist[b].name_Eng,
                  amount: self.payslipdetaillist[0].additionallist[b].amount,
                  id: numbercount,
                  Tax: filter.tax_Calculation,
                  SSF: filter.social_Security_Calculation,
                  Showplus: false,
                  Showtrash: false
                });

                numbercount = numbercount + 1;
                if (filter.tax_Calculation == true) 
                {
                  self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetaillist[0].additionallist[b].amount;
                }

                if (filter.social_Security_Calculation == true) 
                {
                  self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + self.payslipdetaillist[0].additionallist[b].amount;
                }
                self.payslipdetail.totalincome = parseFloat(self.payslipdetaillist[0].additionallist[b].amount) + parseFloat(self.payslipdetail.totalincome);
                self.payslipdetail.totalincome = (parseFloat(self.payslipdetail.totalincome)).toFixed(2);
              }
            }
            for (let y = 0; y < self.additionlist.length; y++) 
            {
              let showplus = false;
              let showdelete = false;
              if (y == 0) 
              {
                showdelete = false;
                showplus = false;
              } 
              else if (y == self.additionlist.length - 1) 
              {
                showdelete = true;
                showplus = true;
              } 
              else 
              {
                showplus = false;
                showdelete = true;
              }
              self.additionlist[y].Showplus = showplus;
              self.additionlist[y].Showtrash = showdelete;
            }
            if (self.additionlist.length == 1) 
            {
              self.additionlist[0].Showplus = true;
              self.additionlist[0].Showtrash = false;
            }
            if (self.additionlist.length == 0)
            {
              if (self.allincomelist.length != 0) 
              {
                self.additionlist.push({
                  income_Type: self.allincomelist[0].income_Type_ID,
                  name: self.allincomelist[0].income_Type_Name,
                  name_Eng: self.allincomelist[0].income_Type_Name_Eng,
                  amount: 0,
                  id: 0,
                  Tax: self.allincomelist[0].tax_Calculation,
                  SSF: self.allincomelist[0].social_Security_Calculation,
                  Showplus: true,
                  Showtrash: false
                });
              }
            }
            //additional earning from benfit
            for (let i = 0;i < self.payslipdetaillist[0].otherAdditionlist.length;i++) 
            {
              self.otheradditionlist.push({
                id: i,
                name: self.payslipdetaillist[0].otherAdditionlist[i].name,
                amount: self.payslipdetaillist[0].otherAdditionlist[i].amount,
                benefitID: self.payslipdetaillist[0].otherAdditionlist[i].benefitID,
              });
              self.payslipdetail.totalincome = self.otheradditionlist[i].amount + self.payslipdetail.totalincome;
              self.payslipdetail.totalincome = (parseFloat(self.payslipdetail.totalincome)).toFixed(2);
            }

            if (self.additionlist.length == 0) 
            {
              self.myadd = "";
            } 
            else
            {
              self.myadd = "Additional Earnings";
            }
            self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax),self.payslipdetaillist[0].personaltaxamount) / 12;
            self.payslipdetail.personalallowanceperyear = self.payslipdetaillist[0].personaltaxamount;

            if (self.payslipdetaillist[0].checkexistemp == true) 
            {
              if (self.payslipdetaillist[0].personaltaxcheck == true) 
              {
                self.payslipdetail.checkpersonaltax = true;
                self.payslipdetail.personaltax = self.checkpersonaltax;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
              } 
              else 
              {
                self.payslipdetail.checkpersonaltax = self.payslipdetaillist[0].personaltaxcheck;
                self.payslipdetail.personaltax = 0;
              }
            } 
            else 
            {
              if (self.Payrolldeductionlist[0].personaltax == true) 
              {
                self.payslipdetail.checkpersonaltax = self.Payrolldeductionlist[0].personaltax;
                self.payslipdetail.personaltax = self.checkpersonaltax;
                self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
              } 
              else 
              {
                self.payslipdetail.checkpersonaltax = false;
                self.payslipdetail.personaltax = 0;
              }
            }

            let updateempcheck = false;
            if (self.payslipdetaillist[0].checkexistemp == true) 
            {
              if (self.payslipdetaillist[0].providentcheck == true) 
              {
                self.payslipdetail.checkprovident = true;
                if (self.payslipdetaillist[0].cumulativeexist == true) 
                {
                  updateempcheck = true;
                } 
                else 
                {
                  updateempcheck = false;
                }
                self.getempprovidentforupdate(self.payslipdetaillist[0].employee_ID,self.payslipdetaillist[0].cumulativeexist,self.payslipdetaillist[0].hiredDate);
              }
              else 
              {
                self.payslipdetail.checkprovident = self.payslipdetaillist[0].providentcheck;
                self.payslipdetail.providentfund = 0;
                self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
                self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
              }
            } 
            else 
            {
              if (self.Payrolldeductionlist[0].pvd == true) 
              {
                self.payslipdetail.checkprovident = true;
                if (self.payslipdetaillist[0].cumulativeexist == true) 
                {
                  updateempcheck = true;
                } 
                else 
                {
                  updateempcheck = false;
                }
                self.getempprovidentforupdate(self.payslipdetaillist[0].employee_ID,self.payslipdetaillist[0].cumulativeexist,self.payslipdetaillist[0].hiredDate);
              } 
              else 
              {
                self.payslipdetail.checkprovident = false;
                self.payslipdetail.providentfund = 0;
                self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
                self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
              }
            }
            self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;

            if (!updateempcheck) 
            {
              let MyempID = self.payslipdetail.empID;
              let totaladdition = 0;
              let othertotal = 0;
              self.PrintDeductionList = [];
              self.AdditionalEarningList = [];
              self.removeallcomma();
              self.removecommapayslip();
              self.changetodecimal();

              for (let i = 0; i < self.additionaldeduction.length; i++) 
              {
                if (self.additionaldeduction[i].amount != 0) 
                {
                  othertotal = othertotal + parseFloat(self.additionaldeduction[i].amount);
                  self.Deductionlist.push({
                    deduction: self.additionaldeduction[i].name,
                    amount: parseInt(self.additionaldeduction[i].amount),
                    employee_ID: self.EmpID
                  });
                }
              }

              for (let j = 0; j < self.additionlist.length; j++) 
              {
                if (self.allincomelist.length > 0) {
                  totaladdition = totaladdition + self.additionlist[j].amount;
                  self.AdditionalEarningList.push({
                    income_Type_ID: self.additionlist[j].income_Type,
                    benefit_Name: "",
                    employee_ID: self.EmpID,
                    amount: self.additionlist[j].amount
                  });
                }
              }

              for (let a = 0; a < self.otheradditionlist.length; a++) 
              {
                totaladdition =
                  totaladdition + self.otheradditionlist[a].amount;
                self.AdditionalEarningList.push({
                  income_Type_ID: 0,
                  benefitID: self.otheradditionlist[a].benefitID,
                  benefit_Name: self.otheradditionlist[a].name,
                  employee_ID: self.EmpID,
                  amount: self.otheradditionlist[a].amount
                });
              }

              let myrateperday = 0;
              if (self.payrollOption == "Customized") 
              {
                if (self.regularEarningOption == "month") 
                {
                  myrateperday = 0;
                }
                if (self.regularEarningOption == "day") 
                {
                  myrateperday = self.payslipdetail.rateperday;
                }
                if (self.regularEarningOption == "hour") 
                {
                  myrateperday = self.payslipdetail.rateperhour;
                }
              }

              let myregular = self.payslipdetail.regularsalary + self.payslipdetail.OTPay;
              self.empInfoForaddPayroll.map(function (data) {
                if (data.employee_ID == MyempID) 
                {
                  data.additionalEarninglist = [];
                  data.deductionlist = [];
                  data.other_Total_Deduction = othertotal;
                  data.personal_Tax = self.payslipdetail.personaltax;
                  let resultprovi = self.removecomma(self.payslipdetail.providentfund);
                  resultprovi = parseFloat(resultprovi);
                  data.provident_Fund_Amount = resultprovi;
                  data.oT_Rate = parseFloat(self.payslipdetail.OTrate);
                  data.oT_Rate_2 = (self.payslipdetail.OTrate2).toString();
                  data.oT_Rate_3 = (self.payslipdetail.OTrate3).toString();
                  data.oT_Rate_4 = (self.payslipdetail.OTrate4).toString();
                  data.working_Rate = parseFloat(myrateperday);
                  data.stamp_Rate = parseFloat(self.payslipdetail.Notstamprate);
                  data.late_Rate = parseFloat(self.payslipdetail.laterate);
                  data.total_Addition =
                    self.payslipdetail.totalincome.toString();
                  (data.total_Income_To_Calculate_Tax = parseFloat(
                    self.payslipdetail.totalincomecalculatetax
                  )),
                    (data.total_Income = totaladdition);
                  data.total_Deduction = self.payslipdetail.totaldeduction;
                  data.workingDayorHour = self.payslipdetail.workingdays.toString();
                  data.late_Days = parseInt(self.payslipdetail.latedays);
                  data.oT_Hours = parseInt(self.payslipdetail.OThours);
                  data.oT_Hours_2 = (self.payslipdetail.OThours2).toString();
                  data.oT_Hours_3 = (self.payslipdetail.OThours3).toString();
                  data.oT_Hours_4 = (self.payslipdetail.OThours4).toString();
                  data.stamp_Days = parseInt(self.payslipdetail.Notstampdays);
                  data.deductionlist = self.Deductionlist;
                  data.additionalEarninglist = self.AdditionalEarningList;
                  data.salary = self.payslipdetail.regularsalary;
                  data.personalTax = self.payslipdetail.checkpersonaltax;
                  data.socialSecurity = self.payslipdetail.checksocialsecurity;
                  data.providentFund = self.payslipdetail.checkprovident;

                  if (self.payslipdetail.checksocialsecurity == true) 
                  {
                    if (self.payslipdetail.socialsecurity != "N/A") 
                    {
                      data.social_Security_Fund = parseInt(self.payslipdetail.socialsecurity);
                    } 
                    else 
                    {
                      data.social_Security_Fund = 0;
                    }
                  }

                  let check = false;
                  if (self.recalculateemplist.length != 0) 
                  {
                    self.recalculateemplist.map(function (e) 
                    {
                      if (!check) 
                      {
                        if (e.employee_ID == self.payslipdetail.empID) 
                        {
                          check = true;
                        }
                      }
                    });
                    data.status = "Completed";
                  } 
                  else 
                  {
                    data.status = "Completed";
                  }
                }
              });

              self.paymentDueSetupData.map(function (e) {
                if (e.empID == MyempID) 
                {
                  e.regularearning = myregular;
                  e.addition = totaladdition;
                  e.deduction = self.payslipdetail.totaldeduction;
                  e.netincome = self.payslipdetail.totalnetincome;
                  // e.status = "Non";
                  e.salaryamount = self.payslipdetail.regularsalary;
                  let check = false;
                  if (self.recalculateemplist.length != 0) 
                  {
                    self.recalculateemplist.map(function (data) 
                    {
                      if (!check) 
                      {
                        if (data.employee_ID == self.payslipdetail.empID) 
                        {
                          check = true;
                        }
                      }
                    });
                    e.status = "Completed";
                  } 
                  else 
                  {
                    e.status = "Completed";
                  }
                }
              });

              let temp = [];
              temp.push(
                {
                  name: "Social Security",
                  value: self.payslipdetail.socialsecurity > 0 ? self.payslipdetail.socialsecurity : 0
                },
                {
                  name: "Tax",
                  value: self.payslipdetail.personaltax > 0 ? self.payslipdetail.personaltax : 0
                },
                {
                  name: "PVD",
                  value: self.payslipdetail.providentfund > 0 ? self.payslipdetail.providentfund : 0
                }
              );
              if (self.payslipdetail.Notstamprate > 0 && self.payslipdetail.Notstampdays > 0 && self.empButton == 1) 
              {
                temp.push({
                  name: "Not Stamp Deduction",
                  value: self.payslipdetail.Notstamprate * self.payslipdetail.Notstampdays
                });
              }

              if (self.payslipdetail.laterate > 0 && self.payslipdetail.latedays > 0) 
              {
                temp.push({
                  name: "Late Deduction",
                  value:
                    parseFloat(self.payslipdetail.laterate * self.payslipdetail.latedays),
                });
              }

              self.Deductionlist.map(async function (data) {
                if (data.deduction) 
                {
                  temp.push({
                    name: data.deduction,
                    value: data.amount
                  });
                }
              });

              if (self.PrintDeductionCount < 1)
              {
                self.PrintDataList.map(async (e) => {
                  if (e.empID == MyempID) 
                  {
                    e.deductionlist = temp;
                    e.additionallist = [];
                    e.otheradditionlist = [];
                    e.otheradditionlist = self.otheradditionlist;
                    e.additionallist = self.additionlist;
                    e.salaryamount = self.payslipdetail.regularsalary;
                    e.oT_Hours = self.payslipdetail.OThours;
                    e.oT_Rate = self.payslipdetail.OTrate;
                    e.workingDayorHour = self.payslipdetail.workingdays;
                  }
                  if (e.empID != MyempID) 
                  {
                    let t = [];
                    if (e.deductionlist.length > 0) 
                    {
                      e.deductionlist.map((data) => {
                        if (data.deduction) 
                        {
                          t.push({
                            name: data.deduction,
                            value: data.amount
                          });
                        }
                      });
                    }

                    if (e.socialsecurity === 0) 
                    {
                      t.push({
                        name: "Social Security",
                        value: e.socialsecurity
                      });
                    } 
                    else 
                    {
                      let social = parseInt(e.socialsecurity);
                      t.push({
                        name: "Social Security",
                        value: social
                      });
                    }

                    if (e.personalTax === true) 
                    {
                      //let tempSalary = JSON.parse(JSON.stringify(e));
                      let salary = self.payslipdetail.personaltax;
                      t.push({
                        name: "Tax",
                        value: salary
                      });
                    } 
                    else 
                    {
                      t.push({
                        name: "Tax",
                        value: 0
                      });
                    }

                    if (e.providentFund) 
                    {
                      let temp_t = {
                        employee_ID: e.empID
                      };
                      axios.post(`${self.url}Emp_Provident/GetEmp_ProvidentwithHiredDate`,temp_t)
                        .then(function (response) {
                          if (response.data.data) 
                          {
                            let mycumulative = response.data.data.emp_Cumulative_Startdate;
                            if ( mycumulative != "" &&  mycumulative != undefined && mycumulative != null) 
                            {
                              // let tempp = {
                              //   employee_ID: e.empID,
                              //   company_ID: store.state.companyID
                              // };
                              // axios.post(
                              //     `${self.url}PersonalInfo/GetPersonalInfo`,
                              //     tempp
                              //   )
                              //   .then(function (response) {
                                  let myhiredate = response.data.data.hiredDate;
                                  let currenthiredate = myhiredate.split("T")[0];
                                  let month = currenthiredate.split("-")[1];
                                  let year = currenthiredate.split("-")[0];
                                  let mymonth = 12 - month;
                                  var someDate = new Date();
                                  var thisyear = someDate.getFullYear();
                                  var currentmonth = someDate.getMonth();
                                  let fullyear = thisyear - year;
                                  if (fullyear != null) 
                                  {
                                    let thismonth = 0;
                                    if (fullyear == 0) 
                                    {
                                      thismonth = currentmonth + 1 - month;
                                      self.newmonth = thismonth;
                                    }
                                    if (fullyear > 0) 
                                    {
                                      thismonth = (fullyear - 1) * 12 + currentmonth;
                                      self.newmonth = thismonth + mymonth;
                                    }
                                    let checkcondi = false;
                                    for (let j = 0;j < self.ProvidentAlllist.length;j++) 
                                    {
                                      if (!checkcondi) 
                                      {
                                        if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                                        {
                                          let lessyear = self.ProvidentAlllist[j].year * 12;
                                          if (self.newmonth < lessyear) 
                                          {
                                            self.edittemp = {
                                              year: self.ProvidentAlllist[j].year,
                                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                              company_ID: store.state.companyID,
                                            };
                                            checkcondi = true;
                                          } 
                                          else 
                                          {
                                            self.edittemp = {};
                                          }
                                        }

                                        if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                                        {
                                          let greateryear = self.ProvidentAlllist[j].year * 12;
                                          if (self.newmonth > greateryear) {
                                            self.edittemp = {
                                              year: self.ProvidentAlllist[j].year,
                                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                              company_ID: store.state.companyID,
                                            };
                                            checkcondi = true;
                                          } 
                                          else 
                                          {
                                            self.edittemp = {};
                                          }
                                        }

                                        if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                                        {
                                          let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                                          let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                                          if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                                          {
                                            self.edittemp = {
                                              year: self.ProvidentAlllist[j].year,
                                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                              company_ID: store.state.companyID,
                                            };
                                            checkcondi = true;
                                          } 
                                          else 
                                          {
                                            self.edittemp = {};
                                          }
                                        }

                                        if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                                        {
                                          let equalyear = self.ProvidentAlllist[j].year * 12;
                                          if (self.newmonth == equalyear) 
                                          {
                                            self.edittemp = {
                                              year: self.ProvidentAlllist[j].year,
                                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                              company_ID: store.state.companyID,
                                            };
                                            checkcondi = true;
                                          } 
                                          else 
                                          {
                                            self.edittemp = {};
                                          }
                                        }

                                        if (checkcondi == true) 
                                        {
                                          if (Object.keys(JSON.parse(JSON.stringify(self.edittemp))).length >= 1) 
                                          {
                                            axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                                              .then(function (response) {
                                                self.EditProvidentList = response.data.data;
                                                if (e.salaryamount != null && self.EditProvidentList[0].employmentContribution != null) 
                                                {
                                                  let provident_t = (parseInt(self.EditProvidentList[0].employmentContribution) /100) * e.salaryamount;
                                                  t.push({
                                                    name: "PVD",
                                                    value: provident_t
                                                  });
                                                } 
                                                else 
                                                {
                                                  return 0;
                                                }
                                              });
                                          }
                                        }
                                      }
                                    }
                                  }
                                //});
                            }
                          } else 
                          {
                            t.push({
                              name: "PVD",
                              value: 0
                            });
                          }
                        });
                    }
                    if (!e.providentFund) 
                    {
                      t.push({
                        name: "PVD",
                        value: 0
                      });
                    }
                    e.deductionlist = [];
                    e.deductionlist = t;
                    let lateRate = [];
                    lateRate = await self.RecalculateEMPforPrint(e.empID);
                    if (lateRate[0]) 
                    {
                      if (lateRate[0].working_Rate > 0) 
                      {
                        e.deductionlist.push({
                          name: "Late Deduction",
                          value: parseFloat(lateRate[0].late_Rate * lateRate[0].lateDays),
                        });
                      }
                      if (lateRate[0].stamp_Rate > 0  && self.empButton == 1) 
                      {
                        e.deductionlist.push({
                          name: "Not Stamp Deduction",
                          value:
                            lateRate[0].stamp_Rate * lateRate[0].notStampdays
                        });
                      }
                    }
                  }
                });
              } 
              else if (self.PrintDeductionCount >= 1) 
              {
                self.PrintDataList.map(function (e) {
                  if (e.empID === MyempID) 
                  {
                    e.deductionlist = temp;
                    e.otheradditionlist = [];
                    e.additionallist = [];
                    e.additionallist = self.additionlist;
                    e.otheradditionlist = self.otheradditionlist;
                    e.salaryamount = self.payslipdetail.regularsalary;
                    e.workingDayorHour = self.payslipdetail.workingdays;
                  }
                });
              }
              self.PrintDeductionCount++;
              self.cleanCalculationCache();
              self.totalNetpaid_Unchange = 0;
              self.paymentDueSetupData.map(function (e) {
                // eslint-disable-next-line no-unused-vars
                //var getincome = self.GetIncome(parseInt(e.salaryamount));
                self.totalExpenses = parseFloat(self.totalExpenses) + parseFloat(e.regularearning) + parseFloat(e.addition);
                self.totalNetPaid = parseFloat(self.totalNetPaid)+parseFloat(e.netincome);
                self.totalNetpaid_Unchange = parseFloat(e.netincome+self.totalNetpaid_Unchange);
                self.totalAddition = parseFloat(self.totalAddition) + parseFloat(e.addition);
                self.totalDeduction = parseFloat(self.totalDeduction) + parseFloat(e.deduction);
                self.totalSalary = parseFloat(self.totalSalary) + parseFloat(e.salaryamount);
                self.totalNumberOfEmployee++;
                self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
              });

              self.confrimPaymentData = [];
              self.confrimPaymentData.push({
                paymentDate: self.payrollInfo.calender_income_date,
                totalNetPaid: self.totalNetPaid,
                bankFee: self.bankfee
              });

              if (self.paidBy === "Transfer") 
              {
                self.totalTransfer = self.totalNetPaid;
                self.totalCash = 0;
              } 
              else 
              {
                self.totalCash = self.totalNetPaid;
                self.totalTransfer = 0;
              }

              self.unpaidAmount = self.totalNetPaid;
              self.totalNetPaid = 0;
              self.putdecimalpayslip();
              self.putdecimal();
              self.putcomma();
              self.putcommapayslip();
            }
          });
      }
      self.LoadingDialog = true;
      setTimeout(() => {
        //self.showduesetup();
        self.LoadingDialog = false;
      }, 3000);
    }
  },

    async UpdateStafftime() {
      let self = this;
      self.LoadingDialog = true;
      self.showforupdate = true;
      self.Updatestafftimedialog = false;
      await self.Getallincomeforpayslip();
      self.recalculateDialog = false;
      // self.btnshow = true;
      let startdate = "";
      let enddate = "";
      let myoption = "";
      self.checkemplistforeditstaff.map(function (e) {
        self.updatestafftimeemplist.push({
          employee_ID: e.empID
        });
      });

      self.EmpID = 0;
      if(self.checkemplistforeditstaff.length == 0)
      {
        self.LoadingDialog = false;
        alert("Please Select Employee.")
      }
      else if (self.checkemplistforeditstaff.length != 0) 
      {
        self.tab = "three";
        for (let a = 0; a < self.checkemplistforeditstaff.length; a++) 
        {
          self.EmpID = self.checkemplistforeditstaff[a].empID;
          self.myempforprovident = self.checkemplistforeditstaff[a].empID;
          for (let j = 0; j < self.EditpaymentDuesetup.length; j++) 
          {
            if (self.EditpaymentDuesetup[j].empID == self.checkemplistforeditstaff[a].empID ) 
            {
              self.myemp = j + 1;
              self.totallist = self.EditpaymentDuesetup.length;
            }
          }
          if (self.EditpaymentDuesetup.length == 1) 
          {
            self.disablebtn = true;
          } 
          else 
          {
            self.disablebtn = false;
          }

          if (self.payrollOption == "Customized") 
          {
            self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
            self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
            startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
            enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
            myoption = self.regularEarningOption;

            if (myoption == "day") 
            {
              self.showrateperday = true;
              self.showrateperhour = false;
              self.daymonth = "Days(s)";
              self.showworkingdays = "Working Days";
            }
            if (myoption == "hour") 
            {
              self.showrateperday = false;
              self.showrateperhour = true;
              self.daymonth = "Hour(s)";
              self.showworkingdays = "Working Hours";
            }
            if (myoption == "month") 
            {
              self.showrateperday = false;
              self.showrateperhour = false;
              self.daymonth = "Days(s)";
              self.showworkingdays = "Working Days";
            }
          } 
          else 
          {
            self.showrateperday = false;
            self.showrateperhour = false;
            self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_start_Date_notFormat);
            self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(self.payrollInfo.payroll_end_Date_notFormat);
            startdate = self.formatDate(self.payrollInfo.payroll_start_Date_notFormat);
            enddate = self.formatDate(self.payrollInfo.payroll_end_Date_notFormat);
            if (self.editadd) 
            {
              myoption = self.regularEarningOption;
            } else {
              self.payslipdetail.workingstartdate = self.formatCalendarIncomeDate(
                self.payrollInfo.payroll_start_Date_notFormat
              );
              self.payslipdetail.workingenddate = self.formatCalendarIncomeDate(
                self.payrollInfo.payroll_end_Date_notFormat
              );
              startdate = self.formatDate(
                self.payrollInfo.payroll_start_Date_notFormat
              );
              enddate = self.formatDate(
                self.payrollInfo.payroll_end_Date_notFormat
              );
              myoption = "";
            }
          }

          let mypaydueid = 0;
          if (self.editPaymentDueId != null) 
          {
            mypaydueid = self.editPaymentDueId;
          } 
          else 
          {
            mypaydueid = 0;
          }
          let temp = {
            employee_ID: self.checkemplistforeditstaff[a].empID,
            paymentDue_ID: mypaydueid,
            companyID: store.state.companyID,
            updatestafftime: true,
            paidtype: self.disabledAddPayment,
            paymenttype: myoption,
            paydueStart_Date: startdate,
            paydueEnd_Date: enddate
          };
          axios.post(`${self.url}Payment_Due/GetPayslipDetail`, temp)
            .then(function (response) {
              self.cleanpayslipcache();
              self.payslipdetaillist = response.data.data;
              self.workingdayhistorylist = self.payslipdetaillist[0].workingHistorylist;
              for (let j = 0; j < self.additionaldeduction.length; j++) 
              {
                self.additionaldeduction.splice(0, 1);
              }

              if (self.payslipdetaillist[0].timelist.length != 0) 
              {
                for (let h = 0;h < self.payslipdetaillist[0].timelist.length;h++) 
                {
                  var date = self.convertUTCDateToLocalDate(self.payslipdetaillist[0].timelist[h].myUTCdate);
                  var d = new Date(date);
                  var e = new Date();
                  e.setHours(d.getHours(), d.getMinutes());
                  var c = new Date();
                  var gg = self.payslipdetaillist[0].timelist[h].shiftstart;
                  let inputdate = gg.split(":");
                  if (self.payslipdetaillist[0].timelist[h].settingexit == true) 
                  {
                    if (self.payslipdetaillist[0].timelist[h].type == "hour") 
                    {
                      inputdate[0] = parseInt(inputdate[0]) + self.payslipdetaillist[0].timelist[h].time;
                    } 
                    else 
                    {
                      inputdate[1] = parseInt(inputdate[1]) + self.payslipdetaillist[0].timelist[h].time;
                    }
                  }
                  c.setHours(inputdate[0]);
                  c.setMinutes(inputdate[1]);
                  if (e > c) 
                  {
                    if (self.payslipdetail.latedays != null && self.payslipdetail.latedays != 0) 
                    {
                      self.payslipdetail.latedays =
                        self.payslipdetail.latedays + 1;
                    } 
                    else 
                    {
                      self.payslipdetail.latedays = 1;
                    }
                  }
                }
              }

              if (self.payslipdetaillist[0].deductionlist.length != 0) 
              {
                self.additionaldeduction.splice(0, 1);
                let numbercount = 0;
                for (let k = 0; k < self.payslipdetaillist[0].deductionlist.length;k++) 
                {
                  self.additionaldeduction.push({
                    name: self.payslipdetaillist[0].deductionlist[k].deduction,
                    amount: self.payslipdetaillist[0].deductionlist[k].amount,
                    show: true,
                    id: numbercount,
                    showdelete: true,
                    errormessagededuname: ""
                  });
                  if (k == 0) 
                  {
                    self.additionaldeduction[k].showdelete = false;
                  }
                }
              } 
              else 
              {
                self.additionaldeduction.push({
                  name: null,
                  amount: 0,
                  show: true,
                  id: 0,
                  showdelete: false,
                  errormessagededuname: ""
                });
              }

              for (let i = 0; i < self.additionaldeduction.length; i++) 
              {
                self.additionaldeduction[i].show = false;
                if (i == self.additionaldeduction.length - 1) 
                {
                  self.additionaldeduction[i].show = true;
                }
              }

              if (self.additionaldeduction.length != 0) 
              {
                for (let j = 0; j < self.additionaldeduction.length; j++) 
                {
                  self.payslipdetail.mydeduction = self.payslipdetail.mydeduction + self.additionaldeduction[j].amount;
                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.additionaldeduction[j].amount;
                }
              }
              self.payslipdetail.updatedate = self.formatDate(self.payslipdetaillist[0].employeeUpdatedate);
              self.payslipdetail.company = self.payslipdetaillist[0].companyName;
              self.payslipdetail.empID = self.payslipdetaillist[0].employee_ID;
              self.payslipdetail.empcode = self.payslipdetaillist[0].employee_Code;
              self.payslipdetail.empname = self.payslipdetaillist[0].empname;
              self.payslipdetail.position = self.payslipdetaillist[0].positionName;
              self.payslipdetail.workingdays = self.payslipdetaillist[0].workingdays;

              if (myoption == "day") 
              {
                self.payslipdetail.rateperday = self.payslipdetaillist[0].working_Rate;
                self.payslipdetail.regularsalary = self.payslipdetail.rateperday * self.payslipdetail.workingdays;
                self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
              }

              if (myoption == "hour") {
                let totalhours = 0;
                for (let a = 0;a < self.payslipdetaillist[0].hourlist.length;a++) 
                {
                  var starttime = self.convertUTCDateToLocalDateforpayslip(self.payslipdetaillist[0].hourlist[a].starttime);
                  var endtime = "";
                  if (self.payslipdetaillist[0].hourlist[a].noclockout == true) 
                  {
                    endtime = self.convertUTCDateToLocalDateforpayslip(self.payslipdetaillist[0].hourlist[a].endtime);
                  } 
                  else 
                  {
                    endtime = self.payslipdetaillist[0].hourlist[a].endtime;
                  }

                  // moment(stillUtc).local().format("YYYY-MM-DDTHH:mm:ss")
                  starttime = moment(starttime).local().format("YYYY-MM-DD hh:mm:ss a");
                  endtime = moment(endtime).local().format("YYYY-MM-DD hh:mm:ss a");
                  starttime = moment(starttime);
                  endtime = moment(endtime);
                  let difftotalhours = 0;
                  difftotalhours = endtime.diff(starttime, "hours");
                  totalhours = totalhours + difftotalhours;
                }
                if (totalhours != 0) 
                {
                  totalhours = totalhours - self.payslipdetaillist[0].timeoffhours;
                  if (totalhours < 0) 
                  {
                    totalhours = 0;
                  }
                }
                self.payslipdetail.workingdays = totalhours;
                self.payslipdetail.rateperhour = self.payslipdetaillist[0].working_Rate;
                self.payslipdetail.regularsalary = self.payslipdetail.rateperhour * self.payslipdetail.workingdays;
                self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
              }

              if (myoption == "month" || myoption == "") 
              {
                self.payslipdetail.regularsalary = self.payslipdetaillist[0].salary;
                self.payslipdetail.YTD = self.payslipdetaillist[0].ytd;
              }

              self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.regularsalary;
              self.payslipdetail.totalincomecalculatesocialsecurity = self.payslipdetail.totalincomecalculatesocialsecurity + self.payslipdetail.regularsalary;
              self.payslipdetail.totalincome = self.payslipdetail.regularsalary + self.payslipdetail.totalincome;
              
              // self.checkpersonaltax = self.GetIncome(
              //   self.payslipdetail.regularsalary
              // );
              self.checksocialsecurity = self.payslipdetaillist[0].socialamount;
              self.checkprovident = self.payslipdetaillist[0].providentamount;
              let updateempcheck = false;
              if (self.payslipdetaillist[0].checkexistemp == true) {
                if (self.payslipdetaillist[0].socialcheck == true) {
                  self.payslipdetail.checksocialsecurity =
                    self.payslipdetaillist[0].socialcheck;
                  if (self.disabledAddPayment != true) {
                    if (
                      self.payslipdetail.totalincomecalculatesocialsecurity >
                      self.Commonsocial.highestsalary
                    ) {
                      // let mysocial = self.checksocialsecurity.split(" ");
                      self.payslipdetail.socialsecurity = parseInt(
                        self.checksocialsecurity
                      );
                      self.payslipdetail.totaldeduction =
                        self.payslipdetail.totaldeduction +
                        self.payslipdetail.socialsecurity;
                    } else {
                      if (self.deduction.length != 0) {
                        self.payslipdetail.socialsecurity = Math.round(
                          (self.deduction[0].percentage / 100) *
                          self.payslipdetail
                            .totalincomecalculatesocialsecurity
                        );
                        self.payslipdetail.totaldeduction =
                          self.payslipdetail.totaldeduction +
                          self.payslipdetail.socialsecurity;
                      } else {
                        self.payslipdetail.socialsecurity = "N/A";
                      }
                    }
                  } 
                  else 
                  {
                    self.payslipdetail.socialsecurity = parseInt(self.payslipdetaillist[0].socialamount);
                    self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.socialsecurity;
                  }
                } 
                else 
                {
                  self.payslipdetail.checksocialsecurity = self.payslipdetaillist[0].socialcheck;
                  self.payslipdetail.socialsecurity = 0;
                }
              } else {
                if (self.Payrolldeductionlist[0].ssf == true) {
                  self.payslipdetail.checksocialsecurity =
                    self.Payrolldeductionlist[0].ssf;
                  if (
                    self.payslipdetail.totalincomecalculatesocialsecurity >
                    self.Commonsocial.highestsalary
                  ) {
                    // let mysocial = self.checksocialsecurity.split(" ");
                    self.payslipdetail.socialsecurity = parseInt(
                      self.checksocialsecurity
                    );
                    self.payslipdetail.totaldeduction =
                      self.payslipdetail.totaldeduction +
                      self.payslipdetail.socialsecurity;
                  } else {
                    if (self.deduction.length != 0) {
                      self.payslipdetail.socialsecurity = Math.round(
                        (self.deduction[0].percentage / 100) *
                        self.payslipdetail.totalincomecalculatesocialsecurity
                      );
                      self.payslipdetail.totaldeduction =
                        self.payslipdetail.totaldeduction +
                        self.payslipdetail.socialsecurity;
                    } else {
                      self.payslipdetail.socialsecurity = "N/A";
                    }
                  }
                } 
                else 
                {
                  self.payslipdetail.checksocialsecurity = false;
                  self.payslipdetail.socialsecurity = 0;
                }
              }

              self.payslipdetail.OThours = self.payslipdetaillist[0].oT_Hours;
              self.payslipdetail.OThours2 =
                self.payslipdetaillist[0].oT_Hours_2;
              self.payslipdetail.OThours3 =
                self.payslipdetaillist[0].oT_Hours_3;
              self.payslipdetail.OThours4 =
                self.payslipdetaillist[0].oT_Hours_4;
              if (self.payslipdetaillist[0].oT_Rate != 0) {
                self.payslipdetail.OTrate = self.payslipdetaillist[0].oT_Rate;
                self.payslipdetail.OTPay =
                  self.payslipdetail.OThours *
                  parseFloat(self.payslipdetail.OTrate);
                self.payslipdetail.OTPayTotal =
                  self.payslipdetail.OTPay + self.payslipdetail.OTPayTotal;
                self.payslipdetail.totalincome =
                  self.payslipdetail.OTPay + self.payslipdetail.totalincome;
              }
              if (self.payslipdetaillist[0].oT_Rate_2 != 0) {
                self.payslipdetail.OTrate2 =
                  self.payslipdetaillist[0].oT_Rate_2;
                self.payslipdetail.OTPay2 =
                  self.payslipdetail.OThours2 *
                  parseFloat(self.payslipdetail.OTrate2);
                self.payslipdetail.OTPayTotal =
                  self.payslipdetail.OTPay2 + self.payslipdetail.OTPayTotal;
                self.payslipdetail.totalincome =
                  self.payslipdetail.OTPay2 + self.payslipdetail.totalincome;
              }
              if (self.payslipdetaillist[0].oT_Rate_3 != 0) {
                self.payslipdetail.OTrate3 =
                  self.payslipdetaillist[0].oT_Rate_3;
                self.payslipdetail.OTPay3 =
                  self.payslipdetail.OThours3 *
                  parseFloat(self.payslipdetail.OTrate3);
                self.payslipdetail.OTPayTotal =
                  self.payslipdetail.OTPay3 + self.payslipdetail.OTPayTotal;
                self.payslipdetail.totalincome =
                  self.payslipdetail.OTPay3 + self.payslipdetail.totalincome;
              }
              if (self.payslipdetaillist[0].oT_Rate_4 != 0) {
                self.payslipdetail.OTrate4 =
                  self.payslipdetaillist[0].oT_Rate_4;
                self.payslipdetail.OTPay4 =
                  self.payslipdetail.OThours4 *
                  parseFloat(self.payslipdetail.OTrate4);
                self.payslipdetail.OTPayTotal =
                  self.payslipdetail.OTPay4 + self.payslipdetail.OTPayTotal;
                self.payslipdetail.totalincome =
                  self.payslipdetail.OTPay4 + self.payslipdetail.totalincome;
              }
              self.payslipdetail.totalincomecalculatetax =
                self.payslipdetail.totalincomecalculatetax +
                self.payslipdetail.OTPay;
              self.payslipdetail.Notstampdays =
                self.payslipdetaillist[0].notStampdays;
              if (self.payslipdetaillist[0].stamp_Rate != 0) {
                self.payslipdetail.Notstamprate =
                  self.payslipdetaillist[0].stamp_Rate;
                self.payslipdetail.NotstampDeduction =
                  self.payslipdetail.Notstampdays *
                  parseFloat(self.payslipdetail.Notstamprate);
                self.payslipdetail.totaldeduction =
                  self.payslipdetail.NotstampDeduction +
                  self.payslipdetail.totaldeduction;
              }

              // self.payslipdetail.latedays = self.payslipdetaillist[0].lateDays;
              if (self.payslipdetaillist[0].late_Rate != 0) {
                self.payslipdetail.laterate =
                  self.payslipdetaillist[0].late_Rate;
                self.payslipdetail.latededuction =
                  self.payslipdetail.latedays *
                  parseFloat(self.payslipdetail.laterate);
                self.payslipdetail.totaldeduction =
                  self.payslipdetail.latededuction +
                  self.payslipdetail.totaldeduction;
              }

              if (self.additionlist.length != 0) {
                self.additionlist.splice(0, self.additionlist.length);
              }
              let numbercount = 0;
              for (
                let b = 0;
                b < self.payslipdetaillist[0].additionallist.length;
                b++
              ) {
                const filter = self.allincomelist.find(
                  x =>
                    x.income_Type_ID ==
                    self.payslipdetaillist[0].additionallist[b].income_Type_ID
                );
                if (filter != null) {
                  self.additionlist.push({
                    income_Type:
                      self.payslipdetaillist[0].additionallist[b]
                        .income_Type_ID,
                    name: self.payslipdetaillist[0].additionallist[b].name,
                    name_Eng:
                      self.payslipdetaillist[0].additionallist[b].name_Eng,
                    amount: self.payslipdetaillist[0].additionallist[b].amount,
                    id: numbercount,
                    Tax: filter.tax_Calculation,
                    SSF: filter.social_Security_Calculation,
                    Showplus: false,
                    Showtrash: false
                  });
                  numbercount = numbercount + 1;
                  if (filter.tax_Calculation == true) {
                    self.payslipdetail.totalincomecalculatetax =
                      self.payslipdetail.totalincomecalculatetax +
                      self.payslipdetaillist[0].additionallist[b].amount;
                  }
                  if (filter.social_Security_Calculation == true) {
                    self.payslipdetail.totalincomecalculatesocialsecurity =
                      self.payslipdetail.totalincomecalculatesocialsecurity +
                      self.payslipdetaillist[0].additionallist[b].amount;
                  }
                  self.payslipdetail.totalincome =
                  parseFloat(self.payslipdetaillist[0].additionallist[b].amount) +
                    self.payslipdetail.totalincome;
                  self.payslipdetail.totalincome = parseFloat(
                    self.payslipdetail.totalincome.toFixed(2)
                  );
                }
                // self.payslipdetail.totalnetincome =
                //   self.payslipdetail.totalincome -
                //   self.payslipdetail.totaldeduction;
              }
              for (let y = 0; y < self.additionlist.length; y++) {
                let showplus = false;
                let showdelete = false;

                if (y == 0) {
                  showdelete = false;
                  showplus = false;
                } else if (y == self.additionlist.length - 1) {
                  showdelete = true;
                  showplus = true;
                } else {
                  showplus = false;
                  showdelete = true;
                }
                self.additionlist[y].Showplus = showplus;
                self.additionlist[y].Showtrash = showdelete;
              }
              if (self.additionlist.length == 1) {
                self.additionlist[0].Showplus = true;
                self.additionlist[0].Showtrash = false;
              }
              if (self.additionlist.length == 0) {
                if (self.allincomelist.length != 0) {
                  self.additionlist.push({
                    income_Type: self.allincomelist[0].income_Type_ID,
                    name: self.allincomelist[0].income_Type_Name,
                    name_Eng: self.allincomelist[0].income_Type_Name_Eng,
                    amount: 0,
                    id: 0,
                    Tax: self.allincomelist[0].tax_Calculation,
                    SSF: self.allincomelist[0].social_Security_Calculation,
                    Showplus: true,
                    Showtrash: false
                  });
                }
              }
              //additional earning from benfit
              for (
                let i = 0;
                i < self.payslipdetaillist[0].otherAdditionlist.length;
                i++
              ) {
                self.otheradditionlist.push({
                  id: i,
                  name: self.payslipdetaillist[0].otherAdditionlist[i].name,
                  amount: self.payslipdetaillist[0].otherAdditionlist[i].amount,
                  benefitID:
                    self.payslipdetaillist[0].otherAdditionlist[i].benefitID
                });
                self.payslipdetail.totalincome =
                  self.otheradditionlist[i].amount +
                  self.payslipdetail.totalincome;
                self.payslipdetail.totalincome = parseFloat(
                  self.payslipdetail.totalincome.toFixed(2)
                );
              }

              if (self.additionlist.length == 0) {
                self.myadd = "";
              } else {
                self.myadd = "Additional Earnings";
              }
              self.checkpersonaltax =
                self.GetIncome(
                  parseFloat(self.payslipdetail.totalincomecalculatetax),
                  self.payslipdetaillist[0].personaltaxamount
                ) / 12;
              self.payslipdetail.personalallowanceperyear =
                self.payslipdetaillist[0].personaltaxamount;
              if (self.payslipdetaillist[0].checkexistemp == true) {
                if (self.payslipdetaillist[0].personaltaxcheck == true) {
                  self.payslipdetail.checkpersonaltax =
                    self.payslipdetaillist[0].personaltaxcheck;
                  self.payslipdetail.personaltax = self.checkpersonaltax;
                  self.payslipdetail.totaldeduction =
                    self.payslipdetail.totaldeduction +
                    self.payslipdetail.personaltax;
                } else {
                  self.payslipdetail.checkpersonaltax =
                    self.payslipdetaillist[0].personaltaxcheck;
                  self.payslipdetail.personaltax = 0;
                }
              } else {
                if (self.Payrolldeductionlist[0].personaltax == true) {
                  self.payslipdetail.checkpersonaltax =
                    self.Payrolldeductionlist[0].personaltax;
                  self.payslipdetail.personaltax = self.checkpersonaltax;
                  self.payslipdetail.totaldeduction =
                    self.payslipdetail.totaldeduction +
                    self.payslipdetail.personaltax;
                } else {
                  self.payslipdetail.checkpersonaltax = false;
                  self.payslipdetail.personaltax = 0;
                }
              }

              if (self.payslipdetaillist[0].checkexistemp == true) 
              {
                if (self.payslipdetaillist[0].providentcheck == true) 
                {
                  self.payslipdetail.checkprovident = self.payslipdetaillist[0].providentcheck;
                  if (self.payslipdetaillist[0].cumulativeexist == true) 
                  {
                    updateempcheck = true;
                  } 
                  else 
                  {
                    updateempcheck = false;
                  }
                  self.getempprovidentforupdate(
                    self.payslipdetaillist[0].employee_ID,
                    self.payslipdetaillist[0].cumulativeexist,
                    self.payslipdetaillist[0].hiredDate
                  );
                } 
                else 
                {
                  self.payslipdetail.checkprovident = self.payslipdetaillist[0].providentcheck;
                  self.payslipdetail.providentfund = 0;
                  self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund).toFixed(2);
                  self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
                }
              } 
              else 
              {
                if (self.Payrolldeductionlist[0].pvd == true) 
                {
                  self.payslipdetail.checkprovident = true;
                  if (self.payslipdetaillist[0].cumulativeexist == true) 
                  {
                    updateempcheck = true;
                  } 
                  else 
                  {
                    updateempcheck = false;
                  }
                  self.getempprovidentforupdate(
                    self.payslipdetaillist[0].employee_ID,
                    self.payslipdetaillist[0].cumulativeexist,
                    self.payslipdetaillist[0].hiredDate
                  );
                } 
                else 
                {
                  self.payslipdetail.checkprovident = false;
                  self.payslipdetail.providentfund = 0;
                  self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund).toFixed(2);
                  self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
                }
              }
              self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
              self.putdecimalpayslip();
              self.putcommapayslip();
              if (updateempcheck == false) 
              {
                let MyempID = self.payslipdetail.empID;
                let totaladdition = 0;
                let othertotal = 0;
                self.PrintDeductionList = [];
                self.AdditionalEarningList = [];
                self.removeallcomma();
                self.removecommapayslip();
                self.changetodecimal();

                for (let i = 0; i < self.additionaldeduction.length; i++) 
                {
                  if (self.additionaldeduction[i].amount != 0) 
                  {
                    othertotal = othertotal + parseFloat(self.additionaldeduction[i].amount);
                    self.Deductionlist.push({
                      deduction: self.additionaldeduction[i].name,
                      amount: parseFloat(self.additionaldeduction[i].amount),
                      employee_ID: self.EmpID
                    });
                  }
                }

                for (let j = 0; j < self.additionlist.length; j++) 
                {
                  if (self.allincomelist.length > 0) 
                  {
                    totaladdition = totaladdition + self.additionlist[j].amount;
                    //  totalallincome=totalallincome+self.additionlist[j].amount;
                    self.AdditionalEarningList.push({
                      income_Type_ID: self.additionlist[j].income_Type,
                      benefit_Name: "",
                      employee_ID: self.EmpID,
                      amount: self.additionlist[j].amount
                    });
                  }
                }

                for (let a = 0; a < self.otheradditionlist.length; a++) {
                  totaladdition = totaladdition + self.otheradditionlist[a].amount;
                  // totalallincome=totalallincome+self.otheradditionlist[a].amount;
                  self.AdditionalEarningList.push({
                    income_Type_ID: 0,
                    benefitID: self.otheradditionlist[a].benefitID,
                    benefit_Name: self.otheradditionlist[a].name,
                    employee_ID: self.EmpID,
                    amount: self.otheradditionlist[a].amount
                  });
                }

                let myrateperday = 0;
                if (self.payrollOption == "Customized") 
                {
                  if (self.regularEarningOption == "month") 
                  {
                    myrateperday = 0;
                  }
                  if (self.regularEarningOption == "day") 
                  {
                    myrateperday = self.payslipdetail.rateperday;
                  }
                  if (self.regularEarningOption == "hour") 
                  {
                    myrateperday = self.payslipdetail.rateperhour;
                  }
                }

                let myregular = self.payslipdetail.regularsalary + self.payslipdetail.OTPay;
                self.empInfoForaddPayroll.map(function (data) {
                  if (data.employee_ID == MyempID) 
                  {
                    data.additionalEarninglist = [];
                    data.deductionlist = [];
                    data.other_Total_Deduction = othertotal;
                    data.personal_Tax = self.payslipdetail.personaltax;
                    let resultprovi = self.removecomma(self.payslipdetail.providentfund);
                    resultprovi = parseFloat(resultprovi);
                    data.provident_Fund_Amount = resultprovi;
                    data.oT_Rate = parseFloat(self.payslipdetail.OTrate);
                    data.oT_Rate_2 = (self.payslipdetail.OTrate2).toString();
                    data.oT_Rate_3 = (self.payslipdetail.OTrate3).toString();
                    data.oT_Rate_4 = (self.payslipdetail.OTrate4).toString();
                    data.working_Rate = parseFloat(myrateperday);
                    data.stamp_Rate = parseFloat(self.payslipdetail.Notstamprate);
                    data.late_Rate = parseFloat(self.payslipdetail.laterate);
                    data.total_Addition =
                      self.payslipdetail.totalincome.toString();
                    (data.total_Income_To_Calculate_Tax = parseFloat(
                      self.payslipdetail.totalincomecalculatetax
                    )),
                      (data.total_Income = totaladdition);
                    data.total_Deduction = self.payslipdetail.totaldeduction;
                    data.workingDayorHour = self.payslipdetail.workingdays.toString();
                    data.late_Days = parseInt(self.payslipdetail.latedays);
                    data.oT_Hours = parseInt(self.payslipdetail.OThours);
                    data.oT_Hours_2 = (self.payslipdetail.OThours2).toString();
                    data.oT_Hours_3 = (self.payslipdetail.OThours3).toString();
                    data.oT_Hours_4 = (self.payslipdetail.OThours4).toString();
                    data.stamp_Days = parseInt(self.payslipdetail.Notstampdays);
                    data.deductionlist = self.Deductionlist;
                    data.additionalEarninglist = self.AdditionalEarningList;
                    data.salary = self.payslipdetail.regularsalary;
                    data.personalTax = self.payslipdetail.checkpersonaltax;
                    data.socialSecurity = self.payslipdetail.checksocialsecurity;
                    data.providentFund = self.payslipdetail.checkprovident;

                    if (self.payslipdetail.checksocialsecurity == true) 
                    {
                      if (self.payslipdetail.socialsecurity != "N/A") 
                      {
                        data.social_Security_Fund = parseInt(self.payslipdetail.socialsecurity);
                      } 
                      else 
                      {
                        data.social_Security_Fund = 0;
                      }
                    }

                    let check = false;
                    if (self.recalculateemplist.length != 0) 
                    {
                      self.recalculateemplist.map(function (e) {
                        if (check != true) 
                        {
                          if (e.employee_ID == self.payslipdetail.empID) 
                          {
                            check = true;
                          }
                        }
                      });
                      data.status = "Completed";
                    } 
                    else 
                    {
                      data.status = "Completed";
                    }
                  }
                });
                self.paymentDueSetupData.map(function (e) {
                  if (e.empID == MyempID) 
                  {
                    e.regularearning = myregular;
                    e.addition = totaladdition;
                    e.deduction = self.payslipdetail.totaldeduction;
                    e.netincome = self.payslipdetail.totalnetincome;
                    // e.status = "Non";
                    e.salaryamount = self.payslipdetail.regularsalary;
                    let check = false;
                    if (self.recalculateemplist.length != 0) 
                    {
                      self.recalculateemplist.map(function (data) 
                      {
                        if (check != true) {
                          if (data.employee_ID == self.payslipdetail.empID) 
                          {
                            check = true;
                          }
                        }
                      });
                      e.status = "Completed";
                    } 
                    else 
                    {
                      e.status = "Completed";
                    }
                  }
                });

                let temp = [];
                temp.push(
                  { deduction: "Social Security", amount: self.payslipdetail.socialsecurity > 0 ? self.payslipdetail.socialsecurity : 0 },
                  { deduction: "Tax", amount: self.payslipdetail.personaltax > 0 ? self.payslipdetail.personaltax : 0 },
                  { deduction: "PVD", amount: self.payslipdetail.providentfund > 0 ? self.payslipdetail.providentfund : 0 }
                );

                if (self.payslipdetail.Notstamprate > 0 && self.payslipdetail.Notstampdays > 0 && self.empButton == 1) 
                {
                  temp.push({
                    deduction: "Not Stamp Deduction",
                    amount: self.payslipdetail.Notstamprate * self.payslipdetail.Notstampdays,
                  });
                }
                if (self.payslipdetail.laterate > 0 && self.payslipdetail.latedays > 0 ) 
                {
                  temp.push({
                    deduction: "Late Deduction",
                    amount: self.payslipdetail.laterate * self.payslipdetail.latedays,
                  });
                }

                self.Deductionlist.map(async function (data) {
                  if (data.deduction) 
                  {
                    temp.push({
                      deduction: data.deduction,
                      amount: data.amount
                    });
                  }
                });

                if (self.PrintDeductionCount < 1) 
                {
                  self.PrintDataList.map(async (e) => {
                    if (e.empID == MyempID) 
                    {
                      e.deductionlist = temp;
                      e.additionallist = [];
                      e.otheradditionlist = [];
                      e.otheradditionlist = self.otheradditionlist;
                      e.additionallist = self.additionlist;
                      e.salaryamount = self.payslipdetail.regularsalary;
                      e.oT_Hours = self.payslipdetail.OThours;
                      e.oT_Rate = self.payslipdetail.OTrate;
                      e.oT_Hours_2 = self.payslipdetail.OThours2;
                      e.oT_Hours_3 = self.payslipdetail.OThours3;
                      e.oT_Hours_4 = self.payslipdetail.OThours4;
                      e.workingDayorHour = self.payslipdetail.workingdays;
                    }

                    if (e.empID != MyempID) 
                    {
                      let t = [];
                      if (e.deductionlist.length > 0) 
                      {
                        e.deductionlist.map((data) => {
                          if (data.deduction) 
                          {
                            t.push({
                              deduction: data.deduction,
                              amount: data.amount
                            });
                          }
                        });
                      }

                      if (e.socialsecurity === 0) 
                      {
                        t.push({
                          deduction: "Social Security",
                          amount: e.socialsecurity
                        });
                      } 
                      else 
                      {
                        let social = parseInt(e.socialsecurity);
                        t.push({
                          deduction: "Social Security",
                          amount: social
                        });
                      }

                      if (e.personalTax === true) 
                      {
                        //let tempSalary = JSON.parse(JSON.stringify(e));
                        let salary = self.payslipdetail.personaltax;
                        t.push({
                          deduction: "Tax",
                          amount: salary
                        });
                      } 
                      else 
                      {
                        t.push({
                          deduction: "Tax",
                          amount: 0
                        });
                      }

                      if (e.providentFund === true) 
                      {
                        let temp_t = {
                          employee_ID: e.empID
                        };

                        axios.post(`${self.url}Emp_Provident/GetEmp_ProvidentwithHiredDate`,temp_t)
                          .then(function (response) {
                            if (response.data.data) 
                            {
                              let mycumulative = response.data.data.emp_Cumulative_Startdate;
                              if (mycumulative != "" && mycumulative != undefined && mycumulative != null) 
                              {
                                // let tempp = {
                                //   employee_ID: e.empID,
                                //   company_ID: store.state.companyID
                                // };
                                // axios.post(`${self.url}PersonalInfo/GetPersonalInfo`,tempp)
                                //   .then(function (response) {
                                    let myhiredate = response.data.data.hiredDate;
                                    let currenthiredate = myhiredate.split("T")[0];
                                    let month = currenthiredate.split("-")[1];
                                    let year = currenthiredate.split("-")[0];
                                    let mymonth = 12 - month;
                                    var someDate = new Date();
                                    var thisyear = someDate.getFullYear();
                                    var currentmonth = someDate.getMonth();
                                    let fullyear = thisyear - year;
                                    if (fullyear != null) 
                                    {
                                      let thismonth = 0;
                                      if (fullyear == 0) 
                                      {
                                        thismonth = currentmonth + 1 - month;
                                        self.newmonth = thismonth;
                                      }

                                      if (fullyear > 0) 
                                      {
                                        thismonth = (fullyear - 1) * 12 + currentmonth;
                                        self.newmonth = thismonth + mymonth;
                                      }
                                      let checkcondi = false;
                                      for (let j = 0;j < self.ProvidentAlllist.length;j++) 
                                      {
                                        if (checkcondi == false) 
                                        {
                                          if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                                          {
                                            let lessyear = self.ProvidentAlllist[j].year * 12;
                                            if (self.newmonth < lessyear) 
                                            {
                                              self.edittemp = {
                                                year: self.ProvidentAlllist[j].year,
                                                lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                                company_ID: store.state.companyID,
                                              };
                                              checkcondi = true;
                                            } 
                                            else 
                                            {
                                              self.edittemp = {};
                                            }
                                          }

                                          if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                                          {
                                            let greateryear = self.ProvidentAlllist[j].year * 12;
                                            if (self.newmonth > greateryear) 
                                            {
                                              self.edittemp = {
                                                year: self.ProvidentAlllist[j].year,
                                                lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                                company_ID: store.state.companyID,
                                              };
                                              checkcondi = true;
                                            } 
                                            else 
                                            {
                                              self.edittemp = {};
                                            }
                                          }

                                          if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                                          {
                                            let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                                            let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                                            if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                                            {
                                              self.edittemp = {
                                                year: self.ProvidentAlllist[j].year,
                                                lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                                company_ID: store.state.companyID,
                                              };
                                              checkcondi = true;
                                            } 
                                            else 
                                            {
                                              self.edittemp = {};
                                            }
                                          }

                                          if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                                          {
                                            let equalyear = self.ProvidentAlllist[j].year * 12;
                                            if (self.newmonth == equalyear) 
                                            {
                                              self.edittemp = {
                                                year: self.ProvidentAlllist[j].year,
                                                lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                                company_ID: store.state.companyID,
                                              };
                                              checkcondi = true;
                                            } 
                                            else 
                                            {
                                              self.edittemp = {};
                                            }
                                          }

                                          if (checkcondi == true) 
                                          {
                                            if (Object.keys(JSON.parse(JSON.stringify(self.edittemp))).length >= 1) 
                                            {
                                              axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                                                .then(function (response) {
                                                  self.EditProvidentList =
                                                    response.data.data;
                                                  if (e.salaryamount != null && self.EditProvidentList[0].employmentContribution != null) 
                                                  {
                                                    let provident_t = (parseInt(self.EditProvidentList[0].employmentContribution) / 100) * e.salaryamount;
                                                    t.push({
                                                      deduction: "PVD",
                                                      ammount: provident_t
                                                    });
                                                  } 
                                                  else 
                                                  {
                                                    return 0;
                                                  }
                                                });
                                            }
                                          }
                                        }
                                      }
                                    }
                                  //});
                              }
                            } 
                            else 
                            {
                              t.push({
                                deduction: "PVD",
                                amount: 0
                              });
                            }
                          });
                      }
                      if (e.providentFund === false) 
                      {
                        t.push({
                          deduction: "PVD",
                          amount: 0
                        });
                      }

                      e.deductionlist = [];
                      e.deductionlist = t;
                      let lateRate = [];
                      lateRate = await self.RecalculateEMPforPrint(e.empID);
                      if (lateRate[0]) 
                      {
                        if (lateRate[0].working_Rate > 0) 
                        {
                          e.deductionlist.push({
                            deduction: "Late Deduction",
                            amount: lateRate[0].late_Rate * lateRate[0].lateDays
                          });
                        }
                      }

                      if (lateRate[0]) 
                      {
                        if (lateRate[0].stamp_Rate > 0 && self.empButton == 1)
                        {
                          e.deductionlist.push({
                            deduction: "Not Stamp Deduction",
                            amount:
                              lateRate[0].stamp_Rate * lateRate[0].notStampdays
                          });
                        }
                      }
                    }
                  });
                } 
                else if (self.PrintDeductionCount >= 1) 
                {
                  self.PrintDataList.map(function (e) {
                    if (e.empID === MyempID) 
                    {
                      e.deductionlist = temp;
                      e.otheradditionlist = [];
                      e.additionallist = [];
                      e.additionallist = self.additionlist;
                      e.otheradditionlist = self.otheradditionlist;
                      e.salaryamount = self.payslipdetail.regularsalary;
                      e.workingDayorHour = self.payslipdetail.workingdays;
                    }
                  });
                }

                self.PrintDeductionCount++;
                self.cleanCalculationCache();
                self.totalNetpaid_Unchange = 0;
                self.paymentDueSetupData.map(function (e) {
                  self.totalExpenses = parseFloat(self.totalExpenses) + parseFloat(e.regularearning) + parseFloat(e.addition);
                  self.totalNetPaid = parseFloat(self.totalNetPaid)+parseFloat(e.netincome);
                  self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange)+parseFloat(e.netincome);
                  self.totalAddition = parseFloat(self.totalAddition) + parseFloat(e.addition);
                  self.totalDeduction = parseFloat(self.totalDeduction) + parseFloat(e.deduction);
                  self.totalSalary = parseFloat(self.totalSalary) + parseFloat(e.salaryamount);
                  self.totalNumberOfEmployee++;
                  self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
                });

                self.confrimPaymentData = [];
                self.confrimPaymentData.push({
                  paymentDate: self.payrollInfo.calender_income_date,
                  totalNetPaid: self.totalNetPaid,
                  bankFee: self.bankfee
                });

                if (self.paidBy === "Transfer") 
                {
                  self.totalTransfer = self.totalNetPaid;
                  self.totalCash = 0;
                } 
                else 
                {
                  self.totalCash = self.totalNetPaid;
                  self.totalTransfer = 0;
                }

                self.unpaidAmount = self.totalNetPaid;
                self.totalNetPaid = 0;
                self.putdecimalpayslip();
                self.putdecimal();
                self.putcomma();
                self.putcommapayslip();
              }
            });
        }
        setTimeout(() => {
          self.showduesetup();
        }, 3000);
        self.LoadingDialog = false;
      }    
    },

    DetailPageAfteroneupdate(empID){
        let self = this;
        let slipdetail = self.empInfoForaddPayroll.filter(x => x.employee_ID == empID);
        return slipdetail[0];
    },

    checkItemError(index) {
      this.additionaldeduction[index].errormessagededuname = "";
    },
    //update value from payslip page into pay due set up table
    async Updateemp(MyempID, status) {
      let self = this;
      let gotru = true;
      for (let i = 0; i < self.additionaldeduction.length; i++) 
      {
        if (self.additionaldeduction[i].amount != 0) 
        {
          if(!self.additionaldeduction[i].name){
            self.additionaldeduction[i].errormessagededuname = self.$t("require_field");
            gotru = false;
          }
        }
      }
      if(gotru == true){
        if(!self.updatedEmp.includes(MyempID))
        {
          self.updatedEmp.push(MyempID);
        }
        let totaladdition = 0; // use for total income in payment emp table
        //  let totalallincome=0;
        let othertotal = 0;
        self.PrintDeductionList = [];
        self.AdditionalEarningList = [];
        self.removeallcomma();
        if (typeof self.payslipdetail.regularsalary === "string") 
        {
          self.removecommapayslip();
          self.changetodecimal();
        }

        if (status != true) 
        {
          alert("Update Successful");
        }
        // self.showforupdate = false;
        self.LoadingDialog = true;
        for (let i = 0; i < self.additionaldeduction.length; i++) 
        {
          if (self.additionaldeduction[i].amount != 0) 
          {
            othertotal = othertotal + parseFloat(self.additionaldeduction[i].amount);
            self.Deductionlist.push({
              deduction: self.additionaldeduction[i].name,
              amount: parseFloat(self.additionaldeduction[i].amount),
              employee_ID: self.EmpID
            });
          }
        }
        self.payslipdetail.OTPayTotal = self.removecomma(self.payslipdetail.OTPayTotal);
        totaladdition = totaladdition + parseFloat(self.payslipdetail.OTPayTotal);

        for (let j = 0; j < self.additionlist.length; j++) 
        {
          if (self.allincomelist.length > 0) 
          {
            totaladdition = totaladdition + self.additionlist[j].amount;
            //  totalallincome=totalallincome+self.additionlist[j].amount;
            self.AdditionalEarningList.push({
              income_Type_ID: self.additionlist[j].income_Type,
              benefit_Name: self.additionlist[j].name,
              benefit_Name_Eng: self.additionlist[j].name_Eng,
              employee_ID: self.EmpID,
              amount: self.additionlist[j].amount
            });
          }
        }

        for (let a = 0; a < self.otheradditionlist.length; a++) 
        {
          totaladdition = totaladdition + self.otheradditionlist[a].amount;
          // totalallincome=totalallincome+self.otheradditionlist[a].amount;
          self.AdditionalEarningList.push({
            income_Type_ID: 0,
            benefitID: self.otheradditionlist[a].benefitID,
            benefit_Name: self.otheradditionlist[a].name,
            employee_ID: self.EmpID,
            amount: self.otheradditionlist[a].amount
          });
        }

        let myrateperday = 0;
        if (self.payrollOption == "Customized") 
        {
          if (self.regularEarningOption == "month") 
          {
            myrateperday = 0;
          }
          if (self.regularEarningOption == "day") 
          {
            myrateperday = self.payslipdetail.rateperday;
          }
          if (self.regularEarningOption == "hour") 
          {
            myrateperday = self.payslipdetail.rateperhour;
          }
        }

        let myregular = 0;
        if(self.empButton == 2){
          myregular = (self.payslipdetail.regularsalary*self.payslipdetail.workingdays) + self.payslipdetail.OTPay;
        } else {
          myregular = self.payslipdetail.regularsalary + self.payslipdetail.OTPay;
        }
        self.empInfoForaddPayroll.map(function (data) {
          if (data.employee_ID == MyempID) 
          {
            data.additionalEarninglist = [];
            data.deductionlist = [];
            data.other_Total_Deduction = othertotal;
            data.personal_Tax = self.payslipdetail.personaltax;
            let resultprovi = self.removecomma(self.payslipdetail.providentfund);
            resultprovi = parseFloat(resultprovi);
            data.provident_Fund_Amount = resultprovi;
            data.oT_Rate = parseFloat(self.payslipdetail.OTrate);
            data.oT_Rate_2 = (self.payslipdetail.OTrate2).toString();
            data.oT_Rate_3 = (self.payslipdetail.OTrate3).toString();
            data.oT_Rate_4 = (self.payslipdetail.OTrate4).toString();
            data.working_Rate = parseFloat(myrateperday);
            data.stamp_Rate = parseFloat(self.payslipdetail.Notstamprate);
            data.late_Rate = parseFloat(self.payslipdetail.laterate);
            data.total_Addition = self.payslipdetail.totalincome.toString();
            (data.total_Income_To_Calculate_Tax = parseFloat(self.payslipdetail.totalincomecalculatetax)),(data.total_Income = totaladdition);
            data.total_Deduction = self.payslipdetail.totaldeduction;
            data.workingDayorHour = self.payslipdetail.workingdays.toString();
            data.late_Days = parseInt(self.payslipdetail.latedays);
            data.oT_Hours = parseInt(self.payslipdetail.OThours);
            data.oT_Hours_2 = (self.payslipdetail.OThours2).toString();
            data.oT_Hours_3 = (self.payslipdetail.OThours3).toString();
            data.oT_Hours_4 = (self.payslipdetail.OThours4).toString();
            data.stamp_Days = parseInt(self.payslipdetail.Notstampdays);
            data.deductionlist = self.Deductionlist;
            data.additionalEarninglist = self.AdditionalEarningList;
            data.salary = self.payslipdetail.regularsalary;
            data.personalTax = self.payslipdetail.checkpersonaltax;
            data.socialSecurity = self.payslipdetail.checksocialsecurity;
            data.providentFund = self.payslipdetail.checkprovident;
            if (self.payslipdetail.checksocialsecurity == true) 
            {
              if (self.payslipdetail.socialsecurity != "N/A") 
              {
                data.social_Security_Fund = parseInt(
                  self.payslipdetail.socialsecurity
                );
              } 
              else 
              {
                data.social_Security_Fund = 0;
              }
            }

            let check = false;
            if (self.recalculateemplist.length != 0) 
            {
              self.recalculateemplist.map(function (e) {
                if (check != true) 
                {
                  if (e.employee_ID == self.payslipdetail.empID) 
                  {
                    check = true;
                  }
                }
              });
              data.status = "Completed";
            } 
            else 
            {
              data.status = "Completed";
            }
          }
        });
        self.paymentDueSetupData.map(function (e) {
          if (e.empID == MyempID) 
          {
            e.regularearning = myregular;
            e.addition = totaladdition;
            e.deduction = self.payslipdetail.totaldeduction;
            e.netincome = self.payslipdetail.totalnetincome;
            e.netincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
            // e.status = "Non";
            if(self.empButton == 2){
              e.salaryamount = self.payslipdetail.regularsalary * self.payslipdetail.workingdays;
            } else {
              e.salaryamount = self.payslipdetail.regularsalary;
            }
            let check = false;
            if (self.recalculateemplist.length != 0) 
            {
              self.recalculateemplist.map(function (data) {
                if (check != true) 
                {
                  if (data.employee_ID == self.payslipdetail.empID) 
                  {
                    check = true;
                  }
                }
              });
              e.status = "Completed";
            } 
            else 
            {
              e.status = "Completed";
            }
          }
        });

        let temp = [];
        temp.push(
          {
            deduction: "Social Security",
            amount: self.payslipdetail.socialsecurity > 0 ? self.payslipdetail.socialsecurity : 0 },
          {
            deduction: "Tax",
            amount: self.payslipdetail.personaltax > 0 ? self.payslipdetail.personaltax : 0 },
          {
            deduction: "PVD",
            amount: self.payslipdetail.providentfund > 0 ? self.payslipdetail.providentfund : 0 }
        );

        if (self.payslipdetail.Notstamprate > 0 && self.payslipdetail.Notstampdays > 0 && self.empButton == 1) 
        {
          temp.push({
            deduction: "Not Stamp Deduction",
            amount: self.payslipdetail.Notstamprate * self.payslipdetail.Notstampdays,
          });
        }
        if (self.payslipdetail.laterate > 0 && self.payslipdetail.latedays > 0) 
        {
          temp.push({
            deduction: "Late Deduction",
            amount: self.payslipdetail.laterate * self.payslipdetail.latedays
          });
        }
        self.Deductionlist.map(async function (data) {
          if (data.deduction) 
          {
            temp.push({
              deduction: data.deduction,
              amount: data.amount
            });
          }
        });
        let adlist = [];
        for (let n = 0;n < self.empInfoForaddPayroll.length; n++) 
        {
          if(self.empInfoForaddPayroll[n].employee_ID == MyempID){
            for(let m = 0;m< self.empInfoForaddPayroll[n].additionalEarninglist.length; m++){
              adlist.push({
                income_Type: self.empInfoForaddPayroll[n].additionalEarninglist[m].income_Type_ID,
                name: self.empInfoForaddPayroll[n].additionalEarninglist[m].benefit_Name,
                name_Eng: self.empInfoForaddPayroll[n].additionalEarninglist[m].benefit_Name_Eng,
                amount: self.empInfoForaddPayroll[n].additionalEarninglist[m].amount,
              });
            }
            
          }
        }

        if (self.PrintDeductionCount < 1) 
        {
          self.PrintDataList.map(async (e) => {
            if (e.empID == MyempID) 
            {
              e.deductionlist = temp;
              e.additionallist = [];
              e.otheradditionlist = [];
              e.otheradditionlist = self.otheradditionlist;
              e.additionallist = adlist;
              e.salaryamount = self.payslipdetail.regularsalary;
              e.oT_Hours = self.payslipdetail.OThours;
              e.oT_Hours_2 = self.payslipdetail.OThours2;
              e.oT_Hours_3 = self.payslipdetail.OThours3;
              e.oT_Hours_4 = self.payslipdetail.OThours4;
              e.workingDayorHour = self.payslipdetail.workingdays;
            }
            if (e.empID != MyempID) 
            {
              let t = [];
              if (e.deductionlist.length > 0)
              {
                e.deductionlist.map((data) => {
                  if (data.deduction) 
                  {
                    t.push({
                      name: data.deduction,
                      value: data.amount
                    });
                  }
                });
              }

              if (e.socialsecurity === 0) 
              {
                t.push({
                  deduction: "Social Security",
                  amount: e.socialsecurity
                });
              } 
              else 
              {
                let social = parseInt(e.socialsecurity);
                t.push({
                  deduction: "Social Security",
                  amount: social
                });
              }

              if (e.personalTax === true) 
              {
                //let tempSalary = JSON.parse(JSON.stringify(e));
                let salary = self.payslipdetail.personaltax;
                t.push({
                  deduction: "Tax",
                  amount: salary
                });
              } 
              else 
              {
                t.push({
                  deduction: "Tax",
                  amount: 0
                });
              }
              if (e.providentFund === true) 
              {
                let temp_t = {
                  employee_ID: e.empID
                };
                axios.post(`${self.url}Emp_Provident/GetEmp_ProvidentwithHiredDate`, temp_t)
                  .then(function (response) {
                    if (response.data.data) 
                    {
                      let mycumulative = response.data.data.emp_Cumulative_Startdate;
                      if (mycumulative != "" && mycumulative != undefined && mycumulative != null) 
                      {
                        // let tempp = {
                        //   employee_ID: e.empID,
                        //   company_ID: store.state.companyID
                        // };
                        // axios .post(`${self.url}PersonalInfo/GetPersonalInfo`, tempp)
                        //   .then(function (response) {
                            let myhiredate = response.data.data.hiredDate;
                            let currenthiredate = myhiredate.split("T")[0];
                            let month = currenthiredate.split("-")[1];
                            let year = currenthiredate.split("-")[0];
                            let mymonth = 12 - month;
                            var someDate = new Date();
                            var thisyear = someDate.getFullYear();
                            var currentmonth = someDate.getMonth();
                            let fullyear = thisyear - year;
                            if (fullyear != null) 
                            {
                              let thismonth = 0;
                              if (fullyear == 0) 
                              {
                                thismonth = currentmonth + 1 - month;
                                self.newmonth = thismonth;
                              }

                              if (fullyear > 0) 
                              {
                                thismonth = (fullyear - 1) * 12 + currentmonth;
                                self.newmonth = thismonth + mymonth;
                              }

                              let checkcondi = false;
                              for (let j = 0;j < self.ProvidentAlllist.length;j++ ) 
                              {
                                if (checkcondi == false) {
                                  if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                                  {
                                    let lessyear = self.ProvidentAlllist[j].year * 12;
                                    if (self.newmonth < lessyear) 
                                    {
                                      self.edittemp = {
                                        year: self.ProvidentAlllist[j].year,
                                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                        company_ID: store.state.companyID,
                                      };
                                      checkcondi = true;
                                    } 
                                    else 
                                    {
                                      self.edittemp = {};
                                    }
                                  }

                                  if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                                  {
                                    let greateryear = self.ProvidentAlllist[j].year * 12;
                                    if (self.newmonth > greateryear) 
                                    {
                                      self.edittemp = {
                                        year: self.ProvidentAlllist[j].year,
                                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                        company_ID: store.state.companyID,
                                      };
                                      checkcondi = true;
                                    } 
                                    else 
                                    {
                                      self.edittemp = {};
                                    }
                                  }

                                  if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                                  {
                                    let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                                    let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                                    if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                                    {
                                      self.edittemp = {
                                        year: self.ProvidentAlllist[j].year,
                                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                        company_ID: store.state.companyID,
                                      };
                                      checkcondi = true;
                                    } 
                                    else 
                                    {
                                      self.edittemp = {};
                                    }
                                  }

                                  if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                                  {
                                    let equalyear = self.ProvidentAlllist[j].year * 12;
                                    if (self.newmonth == equalyear) 
                                    {
                                      self.edittemp = {
                                        year: self.ProvidentAlllist[j].year,
                                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                                        company_ID: store.state.companyID,
                                      };
                                      checkcondi = true;
                                    } 
                                    else 
                                    {
                                      self.edittemp = {};
                                    }
                                  }

                                  if (checkcondi == true) 
                                  {
                                    if (Object.keys(JSON.parse(JSON.stringify(self.edittemp))).length >= 1) 
                                    {
                                      axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                                        .then(function (response) {
                                          self.EditProvidentList = response.data.data;
                                          if (e.salaryamount != null && self.EditProvidentList[0].employmentContribution != null) 
                                          {
                                            let provident_t = (parseInt(self.EditProvidentList[0].employmentContribution) / 100) * e.salaryamount;
                                            t.push({
                                              deduction: "PVD",
                                              amount: provident_t
                                            });
                                          } 
                                          else 
                                          {
                                            return 0;
                                          }
                                        });
                                    }
                                  }
                                }
                              }
                            }
                          //});
                      }
                    } 
                    else 
                    {
                      t.push({
                        deduction: "PVD",
                        amount: 0
                      });
                    }
                  });
              }
              if (e.providentFund === false) 
              {
                t.push({
                  deduction: "PVD",
                  amount: 0
                });
              }
              e.deductionlist = [];
              e.deductionlist = t;
              let lateRate = [];
              lateRate = await self.RecalculateEMPforPrint(e.empID);

              if (lateRate[0]) 
              {
                if (lateRate[0].working_Rate > 0) 
                {
                  e.deductionlist.push({
                    deduction: "Late Deduction",
                    amount: lateRate[0].late_Rate * lateRate[0].lateDays
                  });
                }
              }

              if (lateRate[0]) 
              {
                if (lateRate[0].stamp_Rate > 0 && self.empButton == 1) 
                {
                  e.deductionlist.push({
                    deduction: "Not Stamp Deduction",
                    amount: lateRate[0].stamp_Rate * lateRate[0].notStampdays
                  });
                }
              }
            }
          });
        }
        else if (self.PrintDeductionCount >= 1) 
        {
          self.PrintDataList.map(function (e) {
            if (e.empID === MyempID) 
            {
              e.deductionlist = temp;
              e.otheradditionlist = [];
              e.additionallist = [];
              e.additionallist = adlist;
              e.otheradditionlist = self.otheradditionlist;
              e.salaryamount = self.payslipdetail.regularsalary;
              e.workingDayorHour = self.payslipdetail.workingdays;
            }
          });
        }
        self.PrintDeductionCount++;
        self.cleanCalculationCache();
        self.totalNetpaid_Unchange = 0;
        self.paymentDueSetupData.map(function (e) {
          // eslint-disable-next-line no-unused-vars
          //var getincome = self.GetIncome(parseInt(e.salaryamount));
          self.totalExpenses = parseFloat(self.totalExpenses) + parseFloat(e.regularearning) + parseFloat(e.addition);
          self.totalNetPaid = parseFloat(self.totalNetPaid) + parseFloat(e.netincome);
          self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange) + parseFloat(e.netincome);
          self.totalAddition = parseFloat(self.totalAddition) + parseFloat(e.addition);
          self.totalDeduction = parseFloat(self.totalDeduction) + parseFloat(e.deduction);
          self.totalSalary = parseFloat(self.totalSalary) + parseFloat(e.salaryamount);
          self.totalNumberOfEmployee++;
          self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
        });
        self.confrimPaymentData = [];
        self.confrimPaymentData.push({
          paymentDate: self.payrollInfo.calender_income_date,
          totalNetPaid: self.totalNetPaid,
          bankFee: self.bankfee
        });

        if (self.paidBy === "Transfer") 
        {
          self.totalTransfer = self.totalNetPaid;
          self.totalCash = 0;
        } 
        else 
        {
          self.totalCash = self.totalNetPaid;
          self.totalTransfer = 0;
        }
        self.unpaidAmount = self.totalNetPaid;
        self.totalNetPaid = 0;
        self.putdecimalpayslip();
        self.putdecimal();
        self.putcomma();
        self.putcommapayslip();
        self.LoadingDialog = false;
      }
    },

    loading() {
      let self = this;
      self.LoadingDialog = true;
      return self.LoadingDialog;
    },

    changerate(v) {
      let self = this;
      self.removecommapayslip();
      self.changetodecimal();
      if (v == 1) 
      {
        self.payslipdetail.totalincome = self.payslipdetail.totalincome - self.payslipdetail.OTPay;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax - self.payslipdetail.OTPay;
        self.payslipdetail.OTPay = self.payslipdetail.OThours * parseFloat(self.payslipdetail.OTrate);
        self.payslipdetail.totalincome = self.payslipdetail.totalincome + self.payslipdetail.OTPay;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.OTPay;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPay2 + self.payslipdetail.OTPay3 + self.payslipdetail.OTPay4;
      }
      if (v == 2)
      {
        self.payslipdetail.totalincome = self.payslipdetail.totalincome - self.payslipdetail.OTPay2;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax - self.payslipdetail.OTPay2;
        self.payslipdetail.OTPay2 = self.payslipdetail.OThours2 * parseFloat(self.payslipdetail.OTrate2);
        self.payslipdetail.totalincome = self.payslipdetail.totalincome + self.payslipdetail.OTPay2;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.OTPay2;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPay2 + self.payslipdetail.OTPay3 + self.payslipdetail.OTPay4;
      }
      if (v == 3)
      {
        self.payslipdetail.totalincome = self.payslipdetail.totalincome - self.payslipdetail.OTPay3;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax - self.payslipdetail.OTPay3;
        self.payslipdetail.OTPay3 = self.payslipdetail.OThours3 * parseFloat(self.payslipdetail.OTrate3);
        self.payslipdetail.totalincome = self.payslipdetail.totalincome + self.payslipdetail.OTPay3;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.OTPay3;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPay2 + self.payslipdetail.OTPay3 + self.payslipdetail.OTPay4;
      }
      if (v == 4)
      {
        self.payslipdetail.totalincome = self.payslipdetail.totalincome - self.payslipdetail.OTPay4;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax - self.payslipdetail.OTPay4;
        self.payslipdetail.OTPay4 = self.payslipdetail.OThours4 * parseFloat(self.payslipdetail.OTrate4);
        self.payslipdetail.totalincome = self.payslipdetail.totalincome + self.payslipdetail.OTPay4;
        self.payslipdetail.totalincomecalculatetax = self.payslipdetail.totalincomecalculatetax + self.payslipdetail.OTPay4;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
        self.payslipdetail.OTPayTotal = self.payslipdetail.OTPay + self.payslipdetail.OTPay2 + self.payslipdetail.OTPay3 + self.payslipdetail.OTPay4;
      }

      if (self.payslipdetail.Notstamprate != null && self.empButton == 1) 
      {
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.NotstampDeduction;
        self.payslipdetail.NotstampDeduction = self.payslipdetail.Notstampdays * parseFloat(self.payslipdetail.Notstamprate);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.NotstampDeduction;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      }

      if (self.payslipdetail.laterate != null) 
      {
        self.payslipdetail.latededuction = self.payslipdetail.latedays * parseFloat(self.payslipdetail.laterate);
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.latededuction;
        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      }

      if (self.editPaymentDueId != null) 
      {
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.mydeduction;
        self.payslipdetail.mydeduction = 0;
      }

      if (self.payslipdetail.mychange == 1) 
      {
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.alldeduction;
        self.payslipdetail.alldeduction = 0;
      }
      for (let i = 0; i < self.additionaldeduction.length; i++) 
      {
        if (self.additionaldeduction[i].amount == "") 
        {
          self.additionaldeduction[i].amount = 0;
        }
        self.payslipdetail.alldeduction = parseFloat(self.additionaldeduction[i].amount) + self.payslipdetail.alldeduction;
        self.payslipdetail.mychange = 1;
      }
      self.payslipdetail.totaldeduction = self.payslipdetail.alldeduction + self.payslipdetail.totaldeduction;
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      self.payslipdetail.totalincome = parseFloat(self.payslipdetail.totalincome).toFixed(2);
      self.payslipdetail.totalnetincome = parseFloat(self.payslipdetail.totalnetincome).toFixed(2);
      
      if (self.payslipdetail.checkpersonaltax == true)
      {
        self.checkpersonaltax = self.GetIncome(parseFloat(self.payslipdetail.totalincomecalculatetax),self.payslipdetail.personalallowanceperyear) / 12;
        self.payslipdetail.personaltax = self.checkpersonaltax;
        if (self.checkmytax != 0)
        {
          self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.personaltax;
        }
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.personaltax;
      }
      else
      {
        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction - self.payslipdetail.personaltax;
        self.payslipdetail.personaltax = 0;
      }
      self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
      self.putdecimalpayslip();
      self.putcommapayslip();
      self.checkpersonaltaxon(1);
      self.checksocail(1);
    },

    searchReport() {
      let self = this;
      let input = this.SearchReportTo;
      function filterItems() {
        return self.TempReportToList.filter(function (el) {
          if (input) 
          {
            return (
              el.empname.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
              el.employee_Code.toString().indexOf(input) !== -1 ||
              el.department_Name.toLowerCase().indexOf(input.toLowerCase()) !==
              -1 
            );
          } 
          else 
          {
            return self.TempReportToList;
          }
        });
      }
      if(input != "" && input != null){
        let temls = self.empList;
        self.empList = [];
        let a = filterItems();
        let empListMap = new Map();
        self.empList = a;
        a.forEach(item => {
          empListMap.set(item.employee_ID, item);
        });
        temls.forEach(item => {
          if (item.checked) {
            empListMap.set(item.employee_ID, item);
          }
        });
        self.empList = Array.from(empListMap.values());
      } else {
        alert("Please Enter to Search");
      }
    },

    searchEmaillist() {
      let self = this;
      self.emailempList = [];
      let input = this.SearchEmailTo;
      function filterItems() {
        return self.TempEmailList.filter(function (el) {
          if (input) 
          {
            return (
              el.empname.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
              el.employee_Code.toString().indexOf(input) !== -1 ||
              el.department_Name.toLowerCase().indexOf(input.toLowerCase()) !==
              -1
            );
          } 
          else 
          {
            return self.TempEmailList;
          }
        });
      }
      let a = filterItems();
      self.emailempList = a;
    },

    searchReportemplist() {
      let self = this;
      self.EditpaymentDuesetup = [];
      let input = self.Searchforemplist;
      function filterItems() {
        return self.checklist.filter(function (el) {
          if (input) 
          {
            return (
              el.empname.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
              el.employee_Code.toString().indexOf(input) !== -1 ||
              el.positioinName.toString().indexOf(input) !== -1 ||
              el.department_Name.toLowerCase().indexOf(input.toLowerCase()) !==
              -1
            );
          } 
          else 
          {
            return self.checklist;
          }
        });
      }
      let a = filterItems();
      self.EditpaymentDuesetup = a;
    },

    calcalutionforBankFeeAndNetPaid(input) {
      if (input) 
      {
        let amount = 0;
        amount = this.removecomma(this.totalNetpaid_Unchange);
        let fee = this.removecomma(input);
        this.additionOfNetPaidAndBankFee = parseFloat(amount) + parseFloat(fee);
      } 
      else 
      {
        this.additionOfNetPaidAndBankFee = parseInt(this.totalNetpaid_Unchange);
      }

      this.additionOfNetPaidAndBankFee = parseFloat(this.additionOfNetPaidAndBankFee).toFixed(2);
      this.additionOfNetPaidAndBankFee = this.numberWithCommas(this.additionOfNetPaidAndBankFee);
    },

    async GetPayrollData() {
      let self = this;
      if (self.todayyears.getFullYear()) 
      {
        self.thisYear = self.todayyears.getFullYear();
      } 
      else 
      {
        self.todayyears = new Date();
        self.thisYear = new Date().getFullYear();
      }

      if (self.todaymonths.val == undefined) 
      {
        self.thisMonths = self.todaymonths;
      } 
      else 
      {
        self.thisMonths = self.todaymonths.val;
      }
      let temp = {
        year: self.thisYear,
        month: self.thisMonths,
        companyID: store.state.companyID,
        perpage: self.itemsPerPage,
        page: self.page,
        empTypeID: self.empTypeID == null ? 0 : self.empTypeID
      };
      self.LoadingDialog = true;
      await axios.post(`${self.url}Payment_Due/GetPayrollbyyear`, temp)
        .then(function (response) {
          self.payrollData = response.data.data.responseallpay.map((v) => ({
            ...v,
            isClick: false
          }));
          self.pageCount = response.data.data.countallemp / self.itemsPerPage;
          if (self.pageCount % 1 != 0) 
          {
            self.pageCount = parseInt(self.pageCount + 1);
          } 
          else 
          {
            self.pageCount;
          }
          self.payrollTab = true;
          self.employeeTab = false;
          self.slipDetailTab = false;
          self.tab = "one";
          self.btnshow = false;
        });
      self.LoadingDialog = false;
    },

    creatPayrollForEdit() {
      if (this.payrollInfo.selected.payroll_Period_ID && this.payrollInfo.selected.period[0].period_ID) 
      {
        if (this.editPayrollID) 
        {
          this.actualState = "addnewPayDueInEdit";
        } 
        else 
        {
          this.actualState = "create";
        }
        this.addStaffBtnShow = true;
        this.payrollInfo.payroll_Period_ID = JSON.parse(JSON.stringify(this.payrollInfo.selected.payroll_Period_ID));
        this.payrollInfo.period_ID = JSON.parse(JSON.stringify(this.payrollInfo.selected.period[0].period_ID));
        if (this.payrollInfo.selected) 
        {
          this.payrollInfo.payroll_start_Date_notFormat = JSON.parse(JSON.stringify(this.payrollInfo.selected.period[0].start_Date));
          this.payrollInfo.payroll_end_Date_notFormat = JSON.parse(JSON.stringify(this.payrollInfo.selected.period[0].end_Date));
          this.payrollInfo.payroll_start_Date = this.formatDate(this.payrollInfo.selected.period[0].start_Date);
          this.payrollInfo.payroll_end_Date = this.formatDate(this.payrollInfo.selected.period[0].end_Date);
        }
        this.payrollDialog = false;
        this.createSetUpDialog = true;
      } 
      else 
      {
        alert("please select Payroll Period ");
      }
    },

    createdPayroll() {
      // eslint-disable-next-line no-unused-vars
      //let tempSelect = JSON.parse(JSON.stringify(this.payrollInfo.selected));
      this.paidBy = null;
      this.ActiveRadioForEditPayDue = 1;
      if (this.ActiveRadioForEditPayDue === 1) {
        this.paidBy = "Transfer";
      } 
      else if (this.ActiveRadioForEditPayDue === 0) 
      {
        this.paidBy = "By Cash";
      }

      if (this.activeButton == 1)
      {
        this.payrollOption = "Customized";
      }
      else if (this.activeButton == 2)
      {
        this.payrollOption = "Predefined";
      }
      else if (this.activeButton == 3)
      {
        this.payrollOption = "Customized";
      }

      if (this.empButton == 1)
      {
        this.regularEarningOption == "month";
      }
      else if (this.empButton == 2)
      {
        this.regularEarningOption == "day";
      }

      if (this.editpaydueDetailList[0])
      {
        this.addStaffBtnShow = false;
        this.editBtnShow = true;
      } 
      else 
      {
        this.addStaffBtnShow = true;
        this.editBtnShow = false;
      }

      if (this.ShowPayrollforAddPayDueInEdit)
      {
        if (this.ActiveRadioForEditPayDue === 0 || this.ActiveRadioForEditPayDue === 1)
        {
          if (this.activeButton == 1)
          {
            this.payrollInfo.payroll_start_Date_notFormat = JSON.parse(JSON.stringify(this.creating.PayPerStartDate));
            this.payrollInfo.payroll_end_Date_notFormat = JSON.parse(JSON.stringify(this.creating.PayPerEndDate));
            this.payrollInfo.payroll_start_Date = this.formatDate(this.creating.PayPerStartDate);
            this.payrollInfo.payroll_end_Date = this.formatDate(this.creating.PayPerEndDate);
            this.optionDialog = false;
            this.createSetUpDialog = true;
          }
          else if (this.activeButton == 2)
          {
            if (this.payrollInfo.selected[0] && this.payrollInfo.selected[0].item && this.payrollInfo.selected[0].child)
            {
              if (this.editPayrollID)
              {
                this.addStaffBtnShow = false;
                this.ShowPayrollforAddPayDueInEdit = false;
                this.actualState = "addnewPayDueInEdit";
              }
              else 
              {
                this.actualState = "create";
                this.ShowPayrollforAddPayDueInEdit = true;
              }
              this.addStaffBtnShow = true;
              this.payrollInfo.payroll_Period_ID = JSON.parse(JSON.stringify(this.payrollInfo.selected[0].item.payroll_Period_ID));
              this.payrollInfo.period_ID = JSON.parse(JSON.stringify(this.payrollInfo.selected[0].child.period_ID));

              if (this.payrollInfo.selected[0] && this.payrollInfo.selected[0].child && this.payrollInfo.selected[0].item)
              {
                this.payrollInfo.payroll_start_Date_notFormat = JSON.parse(JSON.stringify(this.payrollInfo.selected[0].child.start_Date));
                this.payrollInfo.payroll_end_Date_notFormat = JSON.parse(JSON.stringify(this.payrollInfo.selected[0].child.end_Date));
                this.payrollInfo.payroll_start_Date = this.formatDate(this.payrollInfo.selected[0].child.start_Date);
                this.payrollInfo.payroll_end_Date = this.formatDate(this.payrollInfo.selected[0].child.end_Date);
              }
              this.optionDialog = false;
              this.createSetUpDialog = true;
            }
            else
            {
              alert("please select Payroll Period ");
            }
          }
          else
          {
            this.optionDialog = false;
            this.createSetUpDialog = true;
          }
        } 
        else 
        {
          alert("Please select the payment method");
        }
      } 
      else 
      {
        this.creatPayrollForEdit();
      }
    },

    async GetProvidentfund() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}Providentfund/GetProvidentAll`, tempp)
        .then(function (response) 
        {
          self.ProvidentAlllist = response.data.data;
        })
        .catch(function (error) 
        {
          alert(error);
        });
    },

    async getempprovident(empID) {
      //This getempprovident is temporary will closed because of 'Emp_Provident/GetEmp_ProvidentwithHiredDate' api is tbkey error is occur.Function will open after tbkey error is fixed.Thanks.
      // let id = empID;
      // empID = id;
      let self = this;
      let temp = {
        employee_ID: empID
      };
      await axios
        .post(`${self.url}Emp_Provident/GetEmp_ProvidentwithHiredDate`, temp)
        .then(async function (response) {
          if (response.data.data) 
          {
            
            let mycumulative = response.data.data.emp_Cumulative_Startdate;
            if (mycumulative) 
            {
                  let myhiredate = response.data.data.hiredDate;
                  let currenthiredate = myhiredate.split("T")[0];
                  let month = currenthiredate.split("-")[1];
                  let year = currenthiredate.split("-")[0];
                  let mymonth = 12 - month;
                  var someDate = new Date();
                  var thisyear = someDate.getFullYear();
                  var currentmonth = someDate.getMonth();
                  let fullyear = thisyear - year;
                  if (fullyear != null) 
                  {
                    let thismonth = 0;
                    if (fullyear == 0) 
                    {
                      thismonth = currentmonth + 1 - month;
                      self.newmonth = thismonth;
                    }

                    if (fullyear > 0) 
                    {
                      thismonth = (fullyear - 1) * 12 + currentmonth;
                      self.newmonth = thismonth + mymonth;
                    }

                    let checkcondi = false;
                    for (let j = 0; j < self.ProvidentAlllist.length; j++) 
                    {
                      if (!checkcondi) 
                      {
                        if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                        {
                          let lessyear = self.ProvidentAlllist[j].year * 12;
                          if (self.newmonth < lessyear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                        {
                          let greateryear = self.ProvidentAlllist[j].year * 12;
                          if (self.newmonth > greateryear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                        {
                          let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                          let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                          if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                        {
                          let equalyear = self.ProvidentAlllist[j].year * 12;
                          if (self.newmonth == equalyear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          }
                          else 
                          {
                            self.edittemp = {};
                          }
                        }
                        await axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                          .then(function (response) {
                            self.EditProvidentList = response.data.data;
                            if (typeof self.payslipdetail.regularsalary === "string") 
                            {
                              self.removecommapayslip();
                              self.changetodecimal();
                            }

                            if (self.EditProvidentList.length != 0) 
                            {
                              if (self.payslipdetail.regularsalary != null && self.EditProvidentList[0].employmentContribution != null) 
                              {
                                let beforeyear = (parseInt(self.EditProvidentList[0].employmentContribution) / 100) * self.payslipdetail.regularsalary;
                                if(self.payslipdetail.totalnetincome > beforeyear) {
                                  self.payslipdetail.providentfund = parseInt(beforeyear);
                                  self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.providentfund;
                                  self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
                                  self.putdecimalpayslip();
                                  self.putcommapayslip();
                                  self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund).toFixed(2);
                                  self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
                                }
                              }
                            } 
                            else 
                            {
                              self.payslipdetail.providentfund = 0;
                              self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund).toFixed(2);
                              self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
                            }
                          });
                      }
                    }
                  }
                //});
            } 
            else 
            {
              self.payslipdetail.providentfund = 0;
              self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund).toFixed(2);
              self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
            }
          } 
          else 
          {
            self.payslipdetail.providentfund = 0;
            self.payslipdetail.providentfund = parseFloat(self.payslipdetail.providentfund).toFixed(2);
            self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
          }
        })
        .catch(function(){
          self.putdecimalpayslip();
          self.putcommapayslip();
        });
    },

    async getempprovidentforaddstaff(empID, salary) {
      let self = this;
      let myprovidentamount = 0;
      let temp = {
        employee_ID: empID
      };
      await axios.post(`${self.url}Emp_Provident/GetEmp_ProvidentwithHiredDate`, temp)
        .then(function (response) {
          if (response.data.data) 
          {
            let mycumulative = response.data.data.emp_Cumulative_Startdate;
            if (mycumulative) 
            {
                  let myhiredate = response.data.data.hiredDate;
                  let currenthiredate = myhiredate.split("T")[0];
                  let month = currenthiredate.split("-")[1];
                  let year = currenthiredate.split("-")[0];
                  let mymonth = 12 - month;
                  var someDate = new Date();
                  var thisyear = someDate.getFullYear();
                  var currentmonth = someDate.getMonth();
                  let fullyear = thisyear - year;
                  if (fullyear != null) 
                  {
                    let thismonth = 0;
                    if (fullyear == 0) 
                    {
                      thismonth = currentmonth + 1 - month;
                      self.newmonth = thismonth;
                    }

                    if (fullyear > 0) 
                    {
                      thismonth = (fullyear - 1) * 12 + currentmonth;
                      self.newmonth = thismonth + mymonth;
                    }

                    let checkcondi = false;
                    for (let j = 0; j < self.ProvidentAlllist.length; j++) 
                    {
                      if (checkcondi == false) 
                      {
                        if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                        {
                          let lessyear = self.ProvidentAlllist[j].year * 12;
                          if (self.newmonth < lessyear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                        {
                          let greateryear = self.ProvidentAlllist[j].year * 12;
                          if (self.newmonth > greateryear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                        {
                          let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                          let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                          if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                        {
                          let equalyear = self.ProvidentAlllist[j].year * 12;
                          if (self.newmonth == equalyear) 
                          {
                            self.edittemp = {
                              year: self.ProvidentAlllist[j].year,
                              lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                              company_ID: store.state.companyID,
                            };
                            checkcondi = true;
                          } 
                          else 
                          {
                            self.edittemp = {};
                          }
                        }

                        axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                          .then(function (response) {
                            self.EditProvidentList = response.data.data;
                            if (self.EditProvidentList.length != 0) 
                            {
                              if (salary != null && self.EditProvidentList[0].employmentContribution != null) 
                              {
                                let beforeyear = (parseInt(self.EditProvidentList[0].employmentContribution) / 100) * salary;
                                myprovidentamount = parseInt(beforeyear);
                              }
                            } 
                            else 
                            {
                              myprovidentamount = 0;
                            }
                          });
                      }
                    }
                  }
                //});
            } 
            else 
            {
              myprovidentamount = 0;
            }
          } 
          else 
          {
            myprovidentamount = 0;
          }
          return myprovidentamount;
        });
    },

    async getempprovidentforaddstaffforpayroll(hiredDate,cumulativestartdate,salary) {
      let self = this;
      let myprovidentamount = 0;
      if (hiredDate != null && cumulativestartdate != null) 
      {
        let mycumulative = cumulativestartdate;
        if (mycumulative) 
        {
          let myhiredate = hiredDate;
          let currenthiredate = myhiredate.split("T")[0];
          let month = currenthiredate.split("-")[1];
          let year = currenthiredate.split("-")[0];
          let mymonth = 12 - month;
          var someDate = new Date();
          var thisyear = someDate.getFullYear();
          var currentmonth = someDate.getMonth();
          let fullyear = thisyear - year;
          if (fullyear != null) 
          {
            let thismonth = 0;

            if (fullyear == 0) 
            {
              thismonth = currentmonth + 1 - month;
              self.newmonth = thismonth;
            }

            if (fullyear > 0) 
            {
              thismonth = (fullyear - 1) * 12 + currentmonth;
              self.newmonth = thismonth + mymonth;
            }

            let checkcondi = false;
            for (let j = 0; j < self.ProvidentAlllist.length; j++) 
            {
              if (checkcondi == false) 
              {

                if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                {
                  let lessyear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth < lessyear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                {
                  let greateryear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth > greateryear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                {
                  let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                  let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                  if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                {
                  let equalyear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth == equalyear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }
                await axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                  .then(function (response) {
                    self.EditProvidentList = response.data.data;
                    if (self.EditProvidentList.length != 0) 
                    {
                      if (salary != null && self.EditProvidentList[0].employmentContribution != null) 
                      {
                        let beforeyear = (parseInt(self.EditProvidentList[0].employmentContribution) / 100) * salary;
                          myprovidentamount = parseInt(beforeyear);
                      }
                    } 
                    else 
                    {
                      myprovidentamount = 0;
                    }
                  });
              }
            }
          }
        } 
        else 
        {
          myprovidentamount = 0;
        }
      } 
      else 
      {
        myprovidentamount = 0;
      }
      return myprovidentamount;
    },

    async getempprovidentforupdate(empID, cumulativeexist, Hiredate) {
      let self = this;
      if (cumulativeexist == true) 
      {
        let myhiredate = Hiredate;
        let currenthiredate = myhiredate.split("T")[0];
        let month = currenthiredate.split("-")[1];
        let year = currenthiredate.split("-")[0];
        let mymonth = 12 - month;
        var someDate = new Date();
        var thisyear = someDate.getFullYear();
        var currentmonth = someDate.getMonth();
        let fullyear = thisyear - year;

        if (fullyear != null) 
        {
          let thismonth = 0;
          if (fullyear == 0) 
          {
            thismonth = currentmonth + 1 - month;
            self.newmonth = thismonth;
          }

          if (fullyear > 0) 
          {
            thismonth = (fullyear - 1) * 12 + currentmonth;
            self.newmonth = thismonth + mymonth;
          }

          let checkcondi = false;
          if (self.ProvidentAlllist.length != 0) {
            for (let j = 0; j < self.ProvidentAlllist.length; j++) 
            {
              if (checkcondi == false) 
              {
                if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                {
                  let lessyear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth < lessyear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                {
                  let greateryear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth > greateryear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                {
                  let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                  let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                  if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear ) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                {
                  let equalyear = self.ProvidentAlllist[j].year * 12;
                  if (self.newmonth == equalyear) 
                  {
                    self.edittemp = {
                      year: self.ProvidentAlllist[j].year,
                      lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                      company_ID: store.state.companyID,
                    };
                    checkcondi = true;
                  } 
                  else 
                  {
                    self.edittemp = {};
                  }
                }

                await axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                  .then(function (response) {
                    self.EditProvidentList = response.data.data;
                    if (self.EditProvidentList.length != 0) 
                    {
                      if (self.payslipdetail.regularsalary != null && self.EditProvidentList[0].employmentContribution != null) 
                      {
                        if (typeof self.payslipdetail.regularsalary === "string") 
                        {
                          self.removecommapayslip();
                          self.changetodecimal();
                        }
                        let beforeyear = (parseInt(self.EditProvidentList[0].employmentContribution) / 100) * self.payslipdetail.regularsalary;
                        self.payslipdetail.providentfund = parseInt(beforeyear);
                        self.payslipdetail.totaldeduction = self.payslipdetail.totaldeduction + self.payslipdetail.providentfund;
                        self.payslipdetail.totalnetincome = self.payslipdetail.totalincome - self.payslipdetail.totaldeduction;
                        self.putdecimalpayslip();
                        self.putcommapayslip();
                        self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
                        self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
                      }
                      self.Updateemp(empID, true);
                    } 
                    else 
                    {
                      self.payslipdetail.providentfund = 0;
                      self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
                      self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
                      self.Updateemp(empID, true);
                    }
                  });
              }
            }
          } 
          else 
          {
            self.payslipdetail.providentfund = 0;
            self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
            self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
            self.Updateemp(empID, true);
          }
        }
      } 
      else 
      {
        self.payslipdetail.providentfund = 0;
        self.payslipdetail.providentfund = (parseFloat(self.payslipdetail.providentfund)).toFixed(2);
        self.payslipdetail.providentfund = self.numberWithCommas(self.payslipdetail.providentfund);
        self.Updateemp(empID, true);
      }
    },

    async AddStaff() {
      //alert("Clear")
      this.LoadingDialog = true;
      this.SearchReportTo = "";
      if (this.paymentDueSetupData.length !== 0) 
      {
        this.checkEmpListForAddStaff = this.paymentDueSetupData.map(user => user.empID);
        const checkedEmployeeIDs = new Set(this.checkEmpListForAddStaff);
        this.empList = this.empList.map((v) => {
          if (checkedEmployeeIDs.has(v.employee_ID)) {
            return { ...v, checked: true };
          }
          return { ...v, checked: false };
        });
      }
      const payrollStartDate = this.payrollInfo?.payroll_start_Date_notFormat;
      const payrollEndDate = this.payrollInfo?.payroll_end_Date_notFormat;
      if (this.payrollOption === "Customized") 
      {
        if (payrollStartDate && payrollEndDate) 
        {
          if (moment(payrollStartDate).isBefore(moment(payrollEndDate).add(1, "day"))) 
          {
            await this.fetchEmployeeData();
          } 
          else 
          {
            alert("Pay Period Start Date can't be after Pay Period End Date");
            this.LoadingDialog = false;
          }
        } 
        else 
        {
          alert("Please select payroll period start date and end date");
          this.LoadingDialog = false;
        }
      } 
      else 
      {
        await this.fetchEmployeeData();
      }
      
    },

    async fetchEmployeeData() {
      this.staffDialog = true;
      const temp = {
        employmentTypeID: this.empButton,
        company_ID: store.state.companyID
      };
      try {
        const response = await axios.post(`${this.url}Payment_Due/Getempbydep`, temp);
        if (response.data.status == 0) 
        {
          let list = response.data.data;
          if(this.checkEmpListForAddStaff.length != 0){
            const checkedEmployeeIDs = this.checkEmpListForAddStaff;
            this.empList = list.map((v) => ({
              ...v,
              checked: checkedEmployeeIDs.includes(v.employee_ID),
            }));
          }else {
            this.empList = list.map((v) => ({
              ...v,
              checked: false,
            }));
          }
          //this.empList = response.data.data;
          this.TempReportToList = this.empList;
          this.checkmyemp();
        } 
        else 
        {
          alert("No employees found for the selected criteria.");
        }
        this.LoadingDialog = false;
      } catch (error) {
        this.LoadingDialog = false;
        alert('An error occurred while fetching employee data.',error);
      }
    },
    // async AddStaff() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   self.staffDialog = true;
    //   if (this.paymentDueSetupData.length !== 0) 
    //   {
    //     this.checkEmpListForAddStaff = this.paymentDueSetupData.map(user => user.empID);
    //   }
    //   if (self.payrollOption === "Customized") 
    //   {
    //     if (self.payrollInfo.payroll_start_Date_notFormat && self.payrollInfo.payroll_end_Date_notFormat) 
    //     {
    //       if (moment(self.payrollInfo.payroll_start_Date_notFormat) < moment(self.payrollInfo.payroll_end_Date_notFormat).add(1, "day")) 
    //       {
    //         let temp = {
    //           employmentTypeID: self.empButton,
    //           company_ID: store.state.companyID
    //         };
    //         await axios.post(`${self.url}Payment_Due/Getempbydep`, temp)
    //           .then(function (response) 
    //           {
    //             self.empList = response.data.data;
    //             self.TempReportToList = response.data.data;
    //           });
    //       } 
    //       else 
    //       {
    //         alert("Pay Period Start Date can't be after Pay Period End Date ");
    //       }
    //     } 
    //     else
    //     {
    //       alert("please select payroll period start date and end date");
    //     }
    //   }
    //   else 
    //   {
    //     let temp = {
    //       employmentTypeID: self.empButton,
    //       company_ID: store.state.companyID
    //     };
    //     await axios.post(`${self.url}Payment_Due/Getempbydep`, temp)
    //       .then(function (response) 
    //       {
    //         self.empList = response.data.data;
    //       });
    //   }
    //   self.LoadingDialog = false;
    // },

    putdecimal() {
      let self = this;
      self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
      self.totalNetPaid = parseFloat(self.totalNetPaid).toFixed(2);
      self.bankfee = parseFloat(self.bankfee).toFixed(2);
      self.additionOfNetPaidAndBankFee = parseFloat(self.additionOfNetPaidAndBankFee).toFixed(2);
      self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange).toFixed(2);
      self.totalAddition = parseFloat(self.totalAddition).toFixed(2);
      self.totalTransfer = parseFloat(self.totalTransfer).toFixed(2);
      self.totalDeduction = parseFloat(self.totalDeduction).toFixed(2);
      self.totalSalary = parseFloat(self.totalSalary).toFixed(2);
      self.unpaidAmount = parseFloat(self.unpaidAmount).toFixed(2);
      self.totalNetPaid = parseFloat(self.totalNetPaid).toFixed(2);
      self.totalCash = parseFloat(self.totalCash).toFixed(2);
      self.paymentDueSetupData.map(function (e) 
      {
        e.addition = parseFloat(e.addition).toFixed(2);
        e.netincome = parseFloat(e.netincome).toFixed(2);
        e.salaryamount = parseFloat(e.salaryamount).toFixed(2);
        e.deduction = parseFloat(e.deduction).toFixed(2);
      });
    },

    putdigit() {
      let self = this;
      self.additionOfNetPaidAndBankFee = parseFloat(
        self.additionOfNetPaidAndBankFee
      );
      self.bankfee = parseFloat(self.bankfee);
      self.totalExpenses = parseFloat(self.totalExpenses);
      self.totalNetPaid = parseFloat(self.totalNetPaid);
      self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange);
      self.totalAddition = parseFloat(self.totalAddition);
      self.totalTransfer = parseFloat(self.totalTransfer);
      self.totalDeduction = parseFloat(self.totalDeduction);
      self.totalSalary = parseFloat(self.totalSalary);
      self.unpaidAmount = parseFloat(self.unpaidAmount);
      self.totalNetPaid = parseFloat(self.totalNetPaid);
      self.totalCash = parseFloat(self.totalCash);
      self.paymentDueSetupData.map(function (e) {
        e.addition = parseFloat(e.addition);
        e.netincome = parseFloat(e.netincome);
        e.salaryamount = parseFloat(e.salaryamount);
        e.deduction = parseFloat(e.deduction);
      });
    },

    numberWithCommas(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    removecomma(x) {
      if (x != 0 && x != null)
      {
        x += "";
        if (x.includes(","))
        {
          return x.replace(/,/g, "");
        } 
        else 
        {
          return x;
        }
      } 
      else 
      {
        return parseFloat(0).toFixed(2);
      }
    },
    
    removecomma1(x) {
      if (x != 0 && x != null)
      {
        x += "";
        if (x.includes(","))
        {
          return x.replace(/,/g, "");
        } 
        else 
        {
          return x;
        }
      } 
      else 
      {
        return "0";
      }
    },

    putcomma() {
      let self = this;
      self.removeallcomma();
      self.totalExpenses = self.numberWithCommas(self.totalExpenses);
      self.totalNetPaid = self.numberWithCommas(self.totalNetPaid);
      self.additionOfNetPaidAndBankFee = self.numberWithCommas(self.additionOfNetPaidAndBankFee);
      self.bankfee = self.numberWithCommas(self.bankfee);
      self.totalNetpaid_Unchange = self.numberWithCommas(self.totalNetpaid_Unchange);
      self.totalAddition = self.numberWithCommas(self.totalAddition);
      self.totalDeduction = self.numberWithCommas(self.totalDeduction);
      self.totalTransfer = self.numberWithCommas(self.totalTransfer);
      self.totalSalary = self.numberWithCommas(self.totalSalary);
      self.unpaidAmount = self.numberWithCommas(self.unpaidAmount);
      self.totalNetPaid = self.numberWithCommas(self.totalNetPaid);
      self.totalCash = self.numberWithCommas(self.totalCash);
    },

    removeallcomma() {
      let self = this;
      self.totalExpenses = self.removecomma(self.totalExpenses);
      self.totalNetPaid = self.removecomma(self.totalNetPaid);
      self.bankfee = self.removecomma(self.bankfee);
      self.additionOfNetPaidAndBankFee = self.removecomma(
        self.additionOfNetPaidAndBankFee
      );
      self.totalNetpaid_Unchange = self.removecomma(self.totalNetpaid_Unchange);
      self.totalAddition = self.removecomma(self.totalAddition);
      self.totalTransfer = self.removecomma(self.totalTransfer);
      self.totalDeduction = self.removecomma(self.totalDeduction);
      self.totalSalary = self.removecomma(self.totalSalary);
      self.unpaidAmount = self.removecomma(self.unpaidAmount);
      self.totalNetPaid = self.removecomma(self.totalNetPaid);
      self.totalCash = self.removecomma(self.totalCash);
      self.paymentDueSetupData.map(function (e) {
        e.addition = self.removecomma(e.addition);
        e.netincome = self.removecomma(e.netincome);
        e.salaryamount = self.removecomma(e.salaryamount);
        e.deduction = self.removecomma(e.deduction);
      });
    },
    
    async removeFromPaymentDueSetupData(No, empId) {
      let self = this;
      let temp = [];
      let temp3 = [];
      self.totalNumberOfEmployee = 0;
      self.cleanCalculationCache();
      for (let i = 0; i < self.EditpaymentDuesetup.length; i++) 
      {
        if (self.EditpaymentDuesetup[i].empID == empId) 
        {
          self.EditpaymentDuesetup.splice(i, 1);
        }
      }
      self.empInfoForaddPayroll.map(function (data) {
        if (empId != data.employee_ID) 
        {
          temp3.push(data);
        }
      });

      self.paymentDueSetupData.map(function (data) {
        if (empId != data.empID) 
        {
          temp.push({
            No: temp.length + 1,
            empID: data.empID,
            addition: data.addition,
            bankID: data.bankID,
            bankname_ENG: data.bankname_ENG,
            bankAccount: data.bankAccount,
            employee_Code: data.employee_Code,
            empname: data.empname,
            status: data.status,
            image: data.image,
            bankname: data.bankname,
            deduction: data.deduction,
            salaryamount: data.salaryamount,
            regularearning: data.regularearning,
            netincome: data.netincome
          });
          self.totalNumberOfEmployee++;
        }
      });
      self.checkEmpListForAddStaff = self.checkEmpListForAddStaff.filter(e => empId !== e);
      self.paymentDueSetupData = temp;
      self.empInfoForaddPayroll = temp3;
      self.cleanCalculationCache();
      self.totalNetpaid_Unchange = 0;
      self.totalNumberOfEmployee = 0;
      self.removeallcomma();
      self.putdigit();
      if (self.checkEmpListForAddStaff.length != 0) 
      {
        self.editBtnShow = true;
        self.addStaffBtnShow = false;
        self.paymentDueSetupData.map(function (e) {
          // eslint-disable-next-line no-unused-vars
          // var getincome = self.GetIncome(parseInt(e.salaryamount));
          self.totalExpenses = parseFloat(self.totalExpenses) + parseFloat(e.regularearning) + e.addition;
          self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
          self.totalNetPaid = self.totalNetPaid + e.netincome;
          self.totalNetpaid_Unchange = self.totalNetpaid_Unchange + e.netincome;
          self.totalAddition = parseFloat(self.totalAddition + e.addition).toFixed(2);
          self.totalDeduction = self.totalDeduction + e.deduction;
          self.totalSalary = self.totalSalary + parseFloat(e.salaryamount);
          self.totalNumberOfEmployee++;
        });

        if (self.paidBy === "Transfer") 
        {
          self.totalTransfer = self.totalNetPaid;
          self.totalCash = 0;
        } 
        else 
        {
          self.totalCash = self.totalNetPaid;
          self.totalTransfer = 0;
        }

        self.unpaidAmount = self.totalNetPaid;
        self.totalNetPaid = 0;
        self.createSetUpDialog = false;
        self.staffDialog = false;
        self.editSetUpDialog = true;
        self.empList = [];
        self.firstEdit = false;
        self.LoadingDialog = false;
        self.putdecimal();
        self.putcomma();
      } 
      else 
      {
        self.staffDialog = false;
        self.addStaffBtnShow = true;
        self.editSetUpDialog = false;
        self.createSetUpDialog = true;
        self.LoadingDialog = false;
        self.editBtnShow = false;
        self.paymentDueSetupData = [];
        self.empInfoForaddPayroll = [];
      }
      if (self.paidBy === "Transfer") 
      {
        self.totalTransfer = self.totalNetPaid;
        self.totalCash = 0;
      } 
      else 
      {
        self.totalCash = self.totalNetPaid;
        self.totalTransfer = 0;
      }
    },

    async editpaydueDetailInfoForPaid() {
      this.confirmEdit = true;
      this.cleanCalculationCache();
      if (this.ActiveRadioForEditPayDue === 1) 
      {
        this.paidBy = "Transfer";
      } 
      else if (this.ActiveRadioForEditPayDue === 0) 
      {
        this.paidBy = "By Cash";
      }

      // alert(this.paidBy);
      if (this.editpaydueDetailList[0]) 
      {
        this.addStaffBtnShow = false;
        this.editBtnShow = true;
      } 
      else 
      {
        this.addStaffBtnShow = true;
        this.editBtnShow = false;
      }
      if (this.selectForedit[0]) 
      {
        if (this.selectForedit[0].payroll_Period_ID && this.selectForedit[0].period_ID) 
        {
          let self = this;
          let temp = {
            paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
            period_ID: self.selectForedit[0].period_ID,
            companyID: store.state.companyID
          };

          await axios.post(`${self.url}Payment_Due/GetDueDetail`, temp)
            .then(function (response) 
            {
              self.dueDetailResultFromEdit = response.data.data;
            });

          this.editPayrollDialog = false;
          this.createSetUpDialog = true;
          this.payrollInfo.payroll_Period_ID = this.selectForedit[0].payroll_Period_ID;
          this.payrollInfo.period_ID = this.selectForedit[0].period_ID;

          if (self.dueDetailResultFromEdit) 
          {
            this.payrollInfo.payroll_start_Date_notFormat = self.dueDetailResultFromEdit[0].paydueStart_Date;
            this.payrollInfo.payroll_end_Date_notFormat = self.dueDetailResultFromEdit[0].paydueEnd_Date;
            this.payrollInfo.payroll_start_Date = this.formatDate(self.dueDetailResultFromEdit[0].paydueStart_Date);
            this.payrollInfo.payroll_end_Date = this.formatDate(self.dueDetailResultFromEdit[0].paydueEnd_Date);
          }

          if (self.editpaydueDetailList == []) 
          {
            self.checkEmpListForAddStaff = [];
            if (self.editpaydueDetailList[0]) 
            {
              self.editpaydueDetailList[0].emplist.map(function (e) {
                self.checkEmpListForAddStaff.push({
                  employee_Code: e.employee_Code,
                  employee_ID: e.employee_ID,
                  empname: e.employeeName,
                  positioinName: e.positionname
                });
              });
            }

            var temp1 = self.editpaydueDetailList[0].emplist.map(function (e) {
              return {
                paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
                employeeID: e.employee_ID,
                paymenttype: self.paidBy,
                payroll_Period_ID:
                  self.returnZeroIfNull(self.editpaydueDetailList[0].payroll_Period_ID),
                period_ID: self.returnZeroIfNull(self.editpaydueDetailList[0].period_ID),
                companyID: parseInt(store.state.companyID),
              };
            });
            self.loadingdialog = true;
            axios.post(`${self.url}Payment_Due/GetemplistbypaydueIDforpredefine`,temp1)
              .then(function (response) {
                if (response.data.status == 0) 
                {
                  self.EditpaymentDuesetup = response.data.data;
                  self.checklist = response.data.data;
                  self.paymentDueSetupData = [];
                  response.data.data.map(function (e, index) {
                    self.paymentDueSetupData.push({
                      No: index + 1,
                      addition: e.addition,
                      additionallist: e.additionallist,
                      bankID: e.bankID,
                      bankAccount: self.bankAccountSplit(e.bankname),
                      bankname: self.bankSplit(e.bankname),
                      bankname_ENG: self.bankSplit(e.bankname_ENG),
                      deduction: e.deduction,
                      empID: e.empID,
                      employee_Code: e.employee_Code,
                      empname: e.empname,
                      netincome: parseFloat(e.regularearning + e.addition) - parseFloat(e.deduction),
                      regularearning: e.regularearning,
                      salaryamount: e.salaryamount,
                      status: e.status
                    });

                    // let t = JSON.parse(JSON.stringify(self.Deductionlist));
                    self.empInfoForaddPayroll.push({
                      employee_ID: e.empID,
                      working_Rate: e.working_Rate,
                      oT_Rate: e.oT_Rate,
                      oT_Rate_2: e.oT_Rate_2.toString(),
                      oT_Rate_3: e.oT_Rate_3.toString(),
                      oT_Rate_4: e.oT_Rate_4.toString(),
                      stamp_Rate: e.stamp_Rate,
                      late_Rate: e.late_Rate,
                      total_Income: e.addition,
                      total_Addition: e.total_Addition,
                      total_Deduction: e.deduction,
                      workingDayorHour: e.workingDayorHour,
                      late_Days: e.late_Days,
                      oT_Hours: e.oT_Hours,
                      oT_Hours_2: e.oT_Hours_2.toString(),
                      oT_Hours_3: e.oT_Hours_3.toString(),
                      oT_Hours_4: e.oT_Hours_4.toString(),
                      stamp_Days: e.stamp_Days,
                      active: true,
                      status: e.status,
                      salary: e.salaryamount,
                      deductionlist: e.deductionlist,
                      additionalEarninglist: e.additionalEarninglist,
                      personalTax: e.personalTax,
                      socialSecurity: e.socialSecurity,
                      providentFund: e.providentFund,
                      social_Security_Fund: e.social_Security_Fund,
                      other_Total_Deduction: e.other_Total_Deduction,
                      personal_Tax: e.personal_Tax,
                      provident_Fund_Amount: e.provident_Fund_Amount,
                      total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                    });
                    if (e.socialSecurity === true) 
                    {
                      self.PrintDataWithSocialSecurity(e,e.social_Security_Fund,e.personal_Tax,e.provident_Fund_Amount);
                    } 
                    else 
                    {
                      self.PrintDataWithoutSocialSecurity(e,e.social_Security_Fund,e.personal_Tax,e.provident_Fund_Amount);
                    }
                  });

                  self.cleanCalculationCache();
                  self.totalNetpaid_Unchange = 0;
                  self.searchlist = self.paymentDueSetupData;
                  self.paymentDueSetupData.map(function (e) {
                    // eslint-disable-next-line no-unused-vars
                    self.totalExpenses = parseFloat(self.totalExpenses) + parseFloat(e.regularearning) + e.addition;
                    self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
                    self.totalNetPaid = parseFloat(self.totalNetPaid + e.netincome).toFixed(2);
                    self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange + e.netincome).toFixed(2);
                    self.totalAddition = parseFloat(self.totalAddition + e.addition).toFixed(2);
                    self.totalDeduction = self.totalDeduction + e.deduction;
                    self.totalSalary = self.totalSalary + parseFloat(e.salaryamount);
                    self.totalNumberOfEmployee++;
                  });

                  if (self.paidBy === "Transfer") 
                  {
                    self.totalTransfer = self.totalNetPaid;
                    self.totalCash = 0;
                  } 
                  else 
                  {
                    self.totalCash = self.totalNetPaid;
                    self.totalTransfer = 0;
                  }

                  if (!self.payConfirm && !self.confirmPayment) 
                  {
                    self.unpaidAmount = self.totalNetPaid;
                    self.totalNetPaid = 0;
                  }
                  self.putdecimal();
                  self.putcomma();
                } 
                else 
                {
                  alert("please select employee who already defined");
                  self.checkEmpListForAddStaff = [];
                  self.paymentDueSetupData = [];
                  self.tempPaymentDueSetupData = [];
                }
                self.loadingdialog = false;
              });
            this.editBtnShow = true;
            this.addStaffBtnShow = false;
          }
        } 
        else 
        {
          alert("please select Payroll Period");
        }
      } 
      else 
      {
        alert("Please select both Payroll Period And Payroll Type");
      }
    },

    // this is retrieving all data after click on paid paydue eye icon
    async wagepayroll() {
      let self = this;
      self.cleanCalculationCache();
      self.LoadingDialog = true;
      this.paymentDueSetupData = [];
      if (self.editpaydueDetailList[0]) 
      {
        self.addStaffBtnShow = false;
        self.editBtnShow = true;
      } 
      else 
      {
        self.addStaffBtnShow = true;
        self.editBtnShow = false;
      }
      //self.activeRadioRegularEarning;

      if (this.activeRadioRegularEarning >= 0 && this.activeRadioRegularEarning <= 3) 
      {
        if (this.activeRadioRegularEarning == 0)
        {
          this.regularEarningOption = "month";
          this.regularEarningOptionNumber = 1;
        } 
        else if (this.activeRadioRegularEarning == 1) 
        {
          this.regularEarningOption = "day";
          this.regularEarningOptionNumber = 2;
        } 
        else if (this.activeRadioRegularEarning == 2) 
        {
          this.regularEarningOption = "hour";
          this.regularEarningOptionNumber = 3;
        } 
        else 
        {
          this.regularEarningOptionNumber = null;
          this.regularEarningOption = "month";
        }

        if (this.editpaydueDetailList[0]) 
        {
          this.payrollInfo.payroll_start_Date = this.formatDate(this.editpaydueDetailList[0].paydueStart_Date);
          this.payrollInfo.payroll_end_Date = this.formatDate(this.editpaydueDetailList[0].paydueEnd_Date);
          this.payrollInfo.payroll_start_Date_notFormat = this.editpaydueDetailList[0].paydueStart_Date;
          this.payrollInfo.payroll_end_Date_notFormat = this.editpaydueDetailList[0].paydueEnd_Date;
          this.payrollInfo.calender_income_date = this.formatDate(this.editpaydueDetailList[0].payment_Date);
        }

        if (self.editpaydueDetailList[0]) 
        {
          self.checkEmpListForAddStaff = [];
          if (self.editpaydueDetailList[0]) 
          {
            self.editpaydueDetailList[0].emplist.map(function (e) {
              self.checkEmpListForAddStaff.push({
                employee_Code: e.employee_Code,
                employee_ID: e.employee_ID,
                empname: e.employeeName,
                positioinName: e.positionname
              });
            });
          }

          var temp1 = self.editpaydueDetailList[0].emplist.map(function (e) {
            return {
              paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
              employeeID: e.employee_ID,
              companyID: parseInt(store.state.companyID),
              paymenttype: self.paidBy,
              paydueEnd_Date: self.payrollInfo.payroll_end_Date_notFormat,
              paydueStart_Date: self.payrollInfo.payroll_start_Date_notFormat,
              regularOption: self.regularEarningOption
            };
          });
          await axios.post(`${self.url}Payment_Due/GetemplistbypaydueID`, temp1)
            .then(function (response) {
              if (response.data.status == 0)
              {
                self.EditpaymentDuesetup = response.data.data;
                self.checklist = response.data.data;
                response.data.data.map(function (e, index) {
                  if(self.empButton == 1)
                  {
                    let total = parseFloat(e.regularearning + e.addition) - parseFloat(e.deduction);
                    self.paymentDueSetupData.push({
                      No: index + 1,
                      addition: self.addOTtotal(e),
                      additionallist: e.additionallist,
                      bankID: e.bankID,
                      bankAccount: self.bankAccountSplit(e.bankname),
                      bankname: self.bankSplit(e.bankname),
                      bankname_ENG: self.bankSplit(e.bankname_ENG),
                      deduction: e.deduction,
                      empID: e.empID,
                      employee_Code: e.employee_Code,
                      image: e.image,
                      empname: e.empname,
                      netincome: parseFloat(total.toFixed(2)),
                      regularearning: e.regularearning,
                      salaryamount: e.salaryamount,
                      status: e.status
                    });

                    self.empInfoForaddPayroll.push({
                      employee_ID: e.empID,
                      working_Rate: e.working_Rate,
                      oT_Rate: e.oT_Rate,
                      oT_Rate_2: e.oT_Rate_2.toString(),
                      oT_Rate_3: e.oT_Rate_3.toString(),
                      oT_Rate_4: e.oT_Rate_4.toString(),
                      stamp_Rate: e.stamp_Rate,
                      late_Rate: e.late_Rate,
                      total_Income: e.addition,
                      total_Addition: e.total_Addition,
                      total_Deduction: e.deduction,
                      workingDayorHour: e.workingDayorHour,
                      late_Days: e.late_Days,
                      oT_Hours: e.oT_Hours,
                      oT_Hours_2: e.oT_Hours_2.toString(),
                      oT_Hours_3: e.oT_Hours_3.toString(),
                      oT_Hours_4: e.oT_Hours_4.toString(),
                      stamp_Days: e.stamp_Days,
                      social_Security_Fund: e.social_Security_Fund,
                      active: true,
                      netincome: parseFloat(e.regularearning + e.addition) - parseFloat(e.deduction),
                      status: e.status,
                      salary: e.salaryamount,
                      deductionlist: e.deductionlist,
                      additionalEarninglist: e.additionalEarninglist,
                      personalTax: e.personalTax,
                      socialSecurity: e.socialSecurity,
                      providentFund: e.providentFund,
                      other_Total_Deduction: e.other_Total_Deduction,
                      personal_Tax: e.personal_Tax,
                      provident_Fund_Amount: e.provident_Fund_Amount,
                      total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                    });
                  }
                  else
                  {
                  let addition = (parseFloat(e.total_Addition) - (parseFloat(e.workingDayorHour)*parseFloat(e.salaryamount))).toFixed(2);
                  let total = parseFloat(e.total_Addition) - parseFloat(e.deduction);
                    self.paymentDueSetupData.push({
                      No: index + 1,
                      addition: addition,
                      additionallist: e.additionallist,
                      bankID: e.bankID,
                      bankAccount: self.bankAccountSplit(e.bankname),
                      bankname: self.bankSplit(e.bankname),
                      bankname_ENG: self.bankSplit(e.bankname_ENG),
                      deduction: e.deduction,
                      empID: e.empID,
                      employee_Code: e.employee_Code,
                      image: e.image,
                      empname: e.empname,
                      netincome: parseFloat(total.toFixed(2)),
                      regularearning: e.regularearning,
                      salaryamount: (parseFloat(e.workingDayorHour)*parseFloat(e.salaryamount)).toFixed(2),
                      status: e.status
                    });

                    self.empInfoForaddPayroll.push({
                      employee_ID: e.empID,
                      working_Rate: e.working_Rate,
                      oT_Rate: e.oT_Rate,
                      oT_Rate_2: e.oT_Rate_2.toString(),
                      oT_Rate_3: e.oT_Rate_3.toString(),
                      oT_Rate_4: e.oT_Rate_4.toString(),
                      stamp_Rate: e.stamp_Rate,
                      late_Rate: e.late_Rate,
                      total_Income: e.addition,
                      total_Addition: e.total_Addition,
                      total_Deduction: e.deduction,
                      workingDayorHour: e.workingDayorHour,
                      late_Days: e.late_Days,
                      oT_Hours: e.oT_Hours,
                      oT_Hours_2: e.oT_Hours_2.toString(),
                      oT_Hours_3: e.oT_Hours_3.toString(),
                      oT_Hours_4: e.oT_Hours_4.toString(),
                      stamp_Days: e.stamp_Days,
                      social_Security_Fund: e.social_Security_Fund,
                      active: true,
                      netincome: parseFloat(e.regularearning + e.addition) - parseFloat(e.deduction) + parseFloat(e.stamp_Days * e.stamp_Rate),
                      status: e.status,
                      salary: e.salaryamount,
                      deductionlist: e.deductionlist,
                      additionalEarninglist: e.additionalEarninglist,
                      personalTax: e.personalTax,
                      socialSecurity: e.socialSecurity,
                      providentFund: e.providentFund,
                      other_Total_Deduction: e.other_Total_Deduction,
                      personal_Tax: e.personal_Tax,
                      provident_Fund_Amount: e.provident_Fund_Amount,
                      total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                    });
                  }
                  
                  if (e.socialSecurity) 
                  {
                    self.PrintDataWithSocialSecurity(
                      e,
                      e.social_Security_Fund,
                      e.personal_Tax,
                      e.provident_Fund_Amount
                    );
                  }
                  else 
                  {
                    self.PrintDataWithoutSocialSecurity(
                      e,
                      e.social_Security_Fund,
                      e.personal_Tax,
                      e.provident_Fund_Amount
                    );
                  }
                });

                self.cleanCalculationCache();
                self.totalNetpaid_Unchange = 0;
                self.totalExpenses = 0;
                self.searchlist = self.paymentDueSetupData;
                self.paymentDueSetupData.map(function (e) {
                  self.totalNetPaid = self.totalNetPaid + parseFloat(e.netincome);
                  self.totalNetPaid = parseFloat(self.totalNetPaid.toFixed(2));
                  self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange) + parseFloat(e.netincome);
                  self.totalNetpaid_Unchange = parseFloat(self.totalNetpaid_Unchange).toFixed(2);
                  self.totalAddition = (parseFloat(self.totalAddition)+parseFloat(e.addition)).toFixed(2);
                  self.totalDeduction = self.totalDeduction + e.deduction;
                  self.totalSalary = self.totalSalary + parseFloat(e.salaryamount);
                  self.totalNumberOfEmployee++;
                });
                self.totalExpenses = (parseFloat(self.totalAddition) + parseFloat(self.totalSalary)).toFixed(2);

                if (self.paidBy === "Transfer") 
                {
                  self.totalTransfer = self.totalNetPaid;
                  self.totalCash = 0;
                } 
                else 
                {
                  self.totalCash = self.totalNetPaid;
                  self.totalTransfer = 0;
                }

                if (!self.payConfirm && !self.confirmPayment) 
                {
                  self.unpaidAmount = self.totalNetPaid;
                  self.totalNetPaid = 0;
                }
                self.putdecimal();
                self.putcomma();
              } 
              else 
              {
                alert("please select employee who already defined");
                self.checkEmpListForAddStaff = [];
                self.paymentDueSetupData = [];
                self.tempPaymentDueSetupData = [];
              }
            });

          this.editBtnShow = true;
          this.LoadingDialog = false;
        }
        this.LoadingDialog = false;
        this.wageDialog = false;
        this.createSetUpDialog = true;
      } 
      else 
      {
        alert("Please Select Regular Earning Option");
      }
    },

    filterOrReturnTemp1(temp1, paymentDueSetupData) {
      // Filter records matching the employee_ID
      let filteredData = paymentDueSetupData.filter(function (obj) {
        return temp1.some(function (emp) {
          return emp.employee_ID === obj.empID;
        });
      });

      // If no matching records are found, return temp1
      if (filteredData.length === 0) {
          return [];
      }

      // Return the filtered data if matches are found
      return filteredData;
    },

    //validate which payroll option the caluclation will be differnt depending on option
    async checkStaff() {
      let self = this;
      if(self.checkEmpListForAddStaff.length == 0)
      {
        alert("Select Employee!!");
        return false;
      }
      self.tempPaymentDueSetupData = [];
      self.tempEmpInfoForaddPayroll = [];
      self.checkEmpListForAddStaff1 = self.checkEmpListForAddStaff.map(emp => self.empList.find(checkEmp => checkEmp.employee_ID === emp));
      if (self.payrollOption === "Predefined") 
      {
        self.checkStaffForPredefined();
      } 
      else 
      {
        self.checkStaffForCustomized();
      }
    },

    async checkStaffForCustomized() {
      let self = this;
      if (self.editpaydueDetailList[0]) 
      {
        self.checkStaffForEditCustomized();
      } 
      else 
      {
        self.cleanCalculationCache();
        self.regularEarningOption = "month";
        self.totalNetpaid_Unchange = 0;
        self.totalNumberOfEmployee = 0;
        if (self.checkEmpListForAddStaff1.length != 0) 
        {
          var temp1 = self.checkEmpListForAddStaff1.map(function (e) {
            return {
              employee_ID: e.employee_ID,
              employee_Code: e.employee_Code,
              companyID: parseInt(store.state.companyID),
              paymenttype: self.paidBy,
              periodenddate: self.payrollInfo.payroll_end_Date_notFormat,
              periodstartdate: self.payrollInfo.payroll_start_Date_notFormat,
              regularOption: self.regularEarningOption
            };
          });
          var isDuplicate = temp1.some((item, index) => temp1.findIndex(obj => obj.employee_ID === item.employee_ID) !== index);
          if(isDuplicate)
          {
            self.checkEmpListForAddStaff = [];
            self.checkEmpListForAddStaff1 = [];
            alert("Duplicate Employee");
            return false;
          }
          if (self.paymentDueSetupData.length != 0) 
          {
            temp1 = temp1.filter(function (obj) {
              return !self.paymentDueSetupData.some(function (emp) {
                return emp.empID === obj.employee_ID;
              });
            });
            self.tempPaymentDueSetupData = self.filterOrReturnTemp1(self.checkEmpListForAddStaff1,self.paymentDueSetupData);
            if(self.empInfoForaddPayroll.length != 0)
            {
              self.empInfoForaddPayroll = self.empInfoForaddPayroll.filter(function (obj) {
                return self.checkEmpListForAddStaff1.some(function (emp) {
                  return emp.employee_ID === obj.employee_ID;
                });
              });
            }
          }
          //loop
          let thetemArray = [];
          let theemsg = [];
          self.LoadingDialog2 = true;
          self.theuploadlength = temp1.length;
          let isthereerr = false;
          if(temp1.length != 0)
          {
            self.paymentDueSetupData = self.tempPaymentDueSetupData;
            for (let tp = 0; tp < temp1.length; tp ++)
            {
              let tempArr = [];
              tempArr.push(temp1[tp]);
              await axios.post(`${self.url}Payment_Due/Getemplistforpaydue`, tempArr)
              .then(function (response) {
                if (response.data.status == 0) 
                {
                  let bankcheck = true;
                  let salarycheck = true;
                  if (response.data.data[0].paymenttype == "Bank Transfer") 
                  {
                    if (response.data.data[0].bankname == "") 
                    {
                      bankcheck = false;
                    }
                  }
                  if (response.data.data[0].salaryamount === "0") 
                  {
                    salarycheck = false;
                  }
                  if(salarycheck == true)
                  {
                    if (bankcheck == true)
                    {
                      thetemArray.push(response.data.data[0]);
                      self.theuploaded += 1;
                    } 
                    else 
                    {
                      isthereerr = true;
                      theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have bank account number for transfer. ");
                    }
                  } 
                  else 
                  {
                    isthereerr = true;
                    theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have salary amount. ");
                  }
                } 
                else 
                {
                  isthereerr = true;
                  theemsg.push(response.data.message + " for Employee ID " + temp1[tp].employee_Code);
                }
              }).catch(function (error) {
                isthereerr = true;
                theemsg.push(error + " for Employee ID " + temp1[tp].employee_Code);
              });
            }
          }
          if (thetemArray.length != 0) 
          {
            self.AddStaffCondition_ShiftCheck = true;                       
            if (self.AddStaffCondition_ShiftCheck) 
            {
              //self.paymentDueSetupData = thetemArray;
              //self.empInfoForaddPayroll = [];
              self.EditpaymentDuesetup = thetemArray;
              self.checklist = thetemArray;
              let temp = [];
              if (self.Deductionlist.length == 0) 
              {
                self.Deductionlist.push({
                  deduction: "",
                  amount: 0,
                  employee_ID: 0,
                });
              }
              let t = JSON.parse(JSON.stringify(self.Deductionlist));
              await Promise.all(thetemArray.map(async function (e, index) {
                self.bankSplit(e.bankname);
                let ssf = 0;
                let ssfcheck = false;
                let personaltax = 0;
                let personaltaxcheck = false;
                let pvd = 0;
                let pvdcheck = false;
                let totaldeduction = 0;
                if (self.Payrolldeductionlist[0].pvd == true)
                {
                  pvdcheck = true;
                  pvd = await self.getempprovidentforaddstaffforpayroll(e.hiredDate,e.emp_Cumulative_Startdate,e.salaryamount);
                }
                if (self.Payrolldeductionlist[0].ssf == true)
                {
                  ssfcheck = true;
                  if(self.empButton == 2)
                  {
                    e.total_Income_To_Calculate_SSF = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour);
                  }
                  if(e.total_Income_To_Calculate_SSF >= self.Commonsocial.lowestSubvention)
                  {
                    if (!self.disabledAddPayment)
                    {
                      if (e.total_Income_To_Calculate_SSF >= self.Commonsocial.highestsalary)
                      {
                        ssf = self.Commonsocial.highestSubvention;
                      }
                      else if (e.total_Income_To_Calculate_SSF < self.Commonsocial.lowestsalary) 
                      {
                        ssf = 0;
                      }
                      else if (e.total_Income_To_Calculate_SSF == self.Commonsocial.lowestsalary) 
                      {
                        ssf = self.Commonsocial.lowestSubvention;
                      }
                      else
                      {
                        ssf = Math.round((self.Commonsocial.percentage / 100) * e.total_Income_To_Calculate_SSF);
                      }
                    } 
                    else 
                    {
                      ssf = parseInt(e.social_Security_Fund_Amount_Default);
                    }
                  }
                }
                if (self.Payrolldeductionlist[0].personaltax == true)
                {
                  personaltaxcheck = true;
                  personaltax = self.GetIncome(parseFloat(e.total_Income_To_Calculate_Tax),e.personal_Tax_Default) / 12;
                }
                let latdy = 0;
                if (e.timelist.length != 0) 
                {
                  for (let h = 0;h < e.timelist.length;h++) 
                  {
                    var dated = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                    var dd = new Date(dated);
                    var ed = new Date();
                    ed.setHours(dd.getHours(), dd.getMinutes());
                    var c = new Date();
                    var ggg = e.timelist[h].shiftstart;
                    let inputdate = ggg.split(":");
                    if (e.timelist[h].settingexit == true) 
                    {
                      if (e.timelist[h].type == "hour") 
                      {
                        inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                      } 
                      else 
                      {
                        inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                      }
                    }
                    c.setHours(inputdate[0]);
                    c.setMinutes(inputdate[1]);
                    if (ed > c) 
                    {
                      if (latdy != null && latdy != 0) 
                      {
                        latdy = latdy + 1;
                      } 
                      else 
                      {
                        latdy = 1;
                      }
                    }
                  }
                }
                let latedudu = latdy * parseFloat(e.late_Rate);
                let ncdudu = e.stamp_Days * parseFloat(e.stamp_Rate);
                if(self.empButton == 2)
                {
                  let workingday = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour);
                  if(workingday != 0)
                  {
                    totaldeduction = ssf + personaltax + e.deduction + latedudu  + pvd;
                    e.netincome = parseFloat(workingday + self.addOTtotal(e)) - totaldeduction;
                    e.regularearning = workingday;
                  }
                  else 
                  {
                    e.netincome = 0;
                    e.regularearning = 0;
                  } 
                } 
                else 
                {
                  totaldeduction = ssf + personaltax + e.deduction + latedudu + ncdudu + pvd;
                  e.netincome = parseFloat(e.regularearning + self.addOTtotal(e)) - totaldeduction;
                }
                temp.push({
                  No: index + 1,
                  addition: self.addOTtotal(e),
                  bankID: e.bankID,
                  bankAccount: self.bankAccountSplit(e.bankname),
                  bankname: self.bankSplit(e.bankname),
                  bankname_ENG: self.bankSplit(e.bankname_ENG),
                  deduction: totaldeduction,
                  empID: e.empID,
                  employee_Code: e.employee_Code,
                  empname: e.empname,
                  image: e.image,
                  netincome: e.netincome,
                  total_Income_To_Calculate_SSF: e.total_Income_To_Calculate_SSF,
                  social_Security_Fund_Amount_Default: e.social_Security_Fund_Amount_Default,
                  total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                  personal_Tax_Default: e.personal_Tax_Default,
                  emp_Cumulative_Startdate: e.emp_Cumulative_Startdate,
                  hiredDate: e.hiredDate,
                  regularearning: e.regularearning,
                  salaryamount: e.salaryamount,
                  status: e.status
                });
                let mylateday = 0;
                if (e.timelist.length != 0) 
                {
                  for (let h = 0; h < e.timelist.length; h++) 
                  {
                    var date = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                    var d = new Date(date);
                    var mycheckin = new Date();
                    mycheckin.setHours(d.getHours(), d.getMinutes());
                    var shifttime = new Date();
                    var gg = e.timelist[h].shiftstart;
                    let inputdate = gg.split(":");
                    if (e.timelist[h].settingexit == true) 
                    {
                      if (e.timelist[h].type == "hour") 
                      {
                        inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                      } 
                      else 
                      {
                        inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                      }
                    }
                    shifttime.setHours(inputdate[0]);
                    shifttime.setMinutes(inputdate[1]);
                    if (mycheckin > shifttime) 
                    {
                      mylateday = mylateday + 1;
                    }
                  }
                }
                if (self.regularEarningOption === "month") 
                {
                  self.tempEmpInfoForaddPayroll.push({
                    employee_ID: e.empID,
                    working_Rate: e.working_Rate,
                    oT_Rate: e.oT_Rate,
                    oT_Rate_2: e.oT_Rate_2.toString(),
                    oT_Rate_3: e.oT_Rate_3.toString(),
                    oT_Rate_4: e.oT_Rate_4.toString(),
                    stamp_Rate: e.stamp_Rate,
                    late_Rate: e.late_Rate,
                    total_Income: e.addition,
                    total_Deduction: totaldeduction,
                    total_Addition: e.total_Addition,
                    workingDayorHour: e.workingDayorHour,
                    late_Days: mylateday,
                    oT_Hours: e.oT_Hours,
                    oT_Hours_2: e.oT_Hours_2.toString(),
                    oT_Hours_3: e.oT_Hours_3.toString(),
                    oT_Hours_4: e.oT_Hours_4.toString(),
                    stamp_Days: e.stamp_Days,
                    active: true,
                    status: "In Progress",
                    salary: e.salaryamount,
                    deductionlist: t,
                    additionalEarninglist: e.additionalEarninglist,
                    personalTax: personaltaxcheck,
                    socialSecurity: ssfcheck,
                    providentFund: pvdcheck,
                    social_Security_Fund: ssf,
                    other_Total_Deduction: e.other_Total_Deduction,
                    personal_Tax: personaltax,
                    provident_Fund_Amount: pvd,
                    total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                  });
                } 
                else if (self.regularEarningOption === "day") 
                {
                  totaldeduction = ssf + personaltax + e.deduction;
                  self.tempEmpInfoForaddPayroll.push({
                    employee_ID: e.empID,
                    working_Rate: e.working_Rate,
                    oT_Rate: e.oT_Rate,
                    oT_Rate_2: e.oT_Rate_2.toString(),
                    oT_Rate_3: e.oT_Rate_3.toString(),
                    oT_Rate_4: e.oT_Rate_4.toString(),
                    stamp_Rate: e.stamp_Rate,
                    late_Rate: e.late_Rate,
                    total_Income: e.addition,
                    total_Addition: e.total_Addition,
                    total_Deduction: e.deduction,
                    workingDayorHour: e.workingDayorHour,
                    late_Days: mylateday,
                    oT_Hours: e.oT_Hours,
                    oT_Hours_2: e.oT_Hours_2.toString(),
                    oT_Hours_3: e.oT_Hours_3.toString(),
                    oT_Hours_4: e.oT_Hours_4.toString(),
                    stamp_Days: e.stamp_Days,
                    active: true,
                    status: "In Progress",
                    salary: 0,
                    deductionlist: t,
                    additionalEarninglist: e.additionalEarninglist,
                    personalTax: personaltaxcheck,
                    socialSecurity: ssfcheck,
                    providentFund: pvdcheck,
                    social_Security_Fund: ssf,
                    other_Total_Deduction: e.other_Total_Deduction,
                    personal_Tax: personaltax,
                    provident_Fund_Amount: pvd,
                    total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                  });
                } 
                else if (self.regularEarningOption === "hour") 
                {
                  totaldeduction = ssf + personaltax + e.deduction;
                  let totalhours = 0;
                  for (let a = 0; a < e.hourlist.length; a++) 
                  {
                    var starttime = self.convertUTCDateToLocalDateforpayslip(e.hourlist[a].starttime);
                    var endtime = "";
                    if (e.hourlist[a].noclockout == true) 
                    {
                      endtime = self.convertUTCDateToLocalDateforpayslip(e.hourlist[a].endtime);
                    } 
                    else 
                    {
                      endtime = e.hourlist[a].endtime;
                    }
                    // moment(stillUtc).local().format("YYYY-MM-DDTHH:mm:ss")
                    starttime = moment(starttime).local().format("YYYY-MM-DD hh:mm:ss a");
                    endtime = moment(endtime).local().format("YYYY-MM-DD hh:mm:ss a");
                    starttime = moment(starttime);
                    endtime = moment(endtime);
                    let difftotalhours = 0;
                    difftotalhours = endtime.diff(starttime, "hours");
                    totalhours = totalhours + difftotalhours;
                  }
                  if (totalhours != 0) 
                  {
                    totalhours = totalhours - e.timeoffhours;
                    if (totalhours < 0) 
                    {
                      totalhours = 0;
                    }
                  }
                  self.tempEmpInfoForaddPayroll.push({
                    employee_ID: e.empID,
                    working_Rate: e.working_Rate,
                    oT_Rate: e.oT_Rate,
                    oT_Rate_2: e.oT_Rate_2.toString(),
                    oT_Rate_3: e.oT_Rate_3.toString(),
                    oT_Rate_4: e.oT_Rate_4.toString(),
                    stamp_Rate: e.stamp_Rate,
                    late_Rate: e.late_Rate,
                    total_Income: e.addition,
                    total_Addition: e.total_Addition,
                    total_Deduction: totaldeduction,
                    workingDayorHour: totalhours.toString(),
                    late_Days: mylateday,
                    oT_Hours: e.oT_Hours,
                    oT_Hours_2: e.oT_Hours_2.toString(),
                    oT_Hours_3: e.oT_Hours_3.toString(),
                    oT_Hours_4: e.oT_Hours_4.toString(),
                    stamp_Days: e.stamp_Days,
                    active: true,
                    status: "In Progress",
                    salary: 0,
                    deductionlist: t,
                    additionalEarninglist: e.additionalEarninglist,
                    personalTax: personaltaxcheck,
                    socialSecurity: ssfcheck,
                    providentFund: pvdcheck,
                    social_Security_Fund: ssf,
                    other_Total_Deduction: e.other_Total_Deduction,
                    personal_Tax: personaltax,
                    provident_Fund_Amount: 0,
                    total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                  });
                }
                if (e.socialSecurity === true) 
                {
                  self.PrintDataWithSocialSecurity(e,ssf,personaltax,pvd);
                } 
                else 
                {
                  self.PrintDataWithoutSocialSecurity(e,ssf,personaltax,pvd);
                }
              }));
              self.empInfoForaddPayroll = [...self.empInfoForaddPayroll, ...self.tempEmpInfoForaddPayroll];
              if (self.regularEarningOption) 
              {
                if (self.regularEarningOption === "month") 
                {
                  temp.forEach(function (e) {
                      self.paymentDueSetupData.push({
                        No: self.paymentDueSetupData.length + 1,
                        addition: e.addition,
                        bankID: e.bankID,
                        bankAccount: e.bankAccount,
                        bankname: e.bankname,
                        bankname_ENG: e.bankname_ENG,
                        deduction: e.deduction,
                        image: e.image,
                        empID: e.empID,
                        employee_Code: e.employee_Code,
                        empname: e.empname,
                        netincome: e.netincome,
                        regularearning: e.regularearning,
                        salaryamount: e.salaryamount,
                        status: e.status,
                      });
                    });
                } 
                else 
                {
                  temp.forEach(function (e) {
                      self.paymentDueSetupData.push({
                        No: self.paymentDueSetupData.length + 1,
                        addition: e.addition,
                        bankID: e.bankID,
                        bankAccount: e.bankAccount,
                        bankname: e.bankname,
                        bankname_ENG: e.bankname_ENG,
                        deduction: e.deduction,
                        image: e.image,
                        empID: e.empID,
                        employee_Code: e.employee_Code,
                        empname: e.empname,
                        netincome: e.netincome,
                        regularearning: e.regularearning,
                        salaryamount: e.salaryamount,
                        status: e.status,
                      });
                    });
                }
              }
              self.cleanCalculationCache();           
              self.totalNetpaid_Unchange = 0;
              await Promise.all(self.paymentDueSetupData.map(async function (e) {
                self.totalExpenses += parseFloat(e.regularearning) + parseFloat(e.addition);
                self.totalNetPaid += parseFloat(e.netincome);
                self.totalNetpaid_Unchange += parseFloat(e.netincome);
                self.totalAddition += parseFloat(e.addition);
                self.totalSalary += parseFloat(e.regularearning);
                self.totalDeduction += parseFloat(e.deduction);
                self.totalNumberOfEmployee++;
              }));
              // Convert total expenses and total addition to strings with two decimal places
              self.totalExpenses = self.totalExpenses.toFixed(2);
              self.totalAddition = self.totalAddition.toFixed(2);
              self.totalNetPaid = self.totalNetPaid.toFixed(2);
              self.totalNetpaid_Unchange = self.totalNetpaid_Unchange.toFixed(2);
              self.totalDeduction = self.totalDeduction.toFixed(2);
              if (self.paidBy === "Transfer") 
              {
                self.totalTransfer = self.totalNetPaid;
                self.totalCash = 0;
              } 
              else 
              {
                self.totalCash = self.totalNetPaid;
                self.totalTransfer = 0;
              }  
              self.unpaidAmount = self.totalNetPaid;
              self.totalNetPaid = 0;
              self.createSetUpDialog = false;
              self.staffDialog = false;
              self.editSetUpDialog = true;
              self.empList = [];
              self.firstEdit = false;
            }
            else 
            {
              alert("Please Choose Employees with Shift");
              self.AddStaffCondition_ShiftCheck = true;
            }
          } 
          else 
          {
            alert("please select employee who already defined");
            self.checkEmpListForAddStaff = [];
            self.paymentDueSetupData = [];
            self.tempPaymentDueSetupData = [];
            self.tempEmpInfoForaddPayroll = [];
          }
          if(isthereerr)
          {
            let Message = [...new Set(theemsg)];
            alert(`${Message.join("\n")}`);
          }
          self.LoadingDialog2 = false;
          self.theuploaded = 0;
          self.theuploadlength = 0;
          self.putdecimal();
          self.putcomma();
        } 
        else 
        {
          self.tempPaymentDueSetupData = [];
          self.tempEmpInfoForaddPayroll = [];
          self.paymentDueSetupData = [];
          self.empInfoForaddPayroll = [];
          self.staffDialog = false;
          self.createSetUpDialog = true;
          self.editSetUpDialog = false;
          self.LoadingDialog2 = false;
        }
      }
    },

    //retreive data and calculation after user add staff in updated paydue for customized option
    async checkStaffForEditCustomized() {
      let self = this;
      self.cleanCalculationCache();
      self.totalNetpaid_Unchange = 0;
      self.totalNumberOfEmployee = 0;
      if (self.checkEmpListForAddStaff1.length != 0) 
      {
        var temp1 = self.checkEmpListForAddStaff1.map(function (e) {
          return {
            employee_ID: e.employee_ID,
            employee_Code: e.employee_Code,
            companyID: parseInt(store.state.companyID),
            paymenttype: self.paidBy,
            periodenddate: self.payrollInfo.payroll_end_Date_notFormat,
            periodstartdate: self.payrollInfo.payroll_start_Date_notFormat,
            regularOption: self.regularEarningOption
          };
        });
        var isDuplicate = temp1.some((item, index) => temp1.findIndex(obj => obj.employee_ID === item.employee_ID) !== index);
        if(isDuplicate)
        {
          self.checkEmpListForAddStaff = [];
          self.checkEmpListForAddStaff1 = [];
          alert("Duplicate Employee");
          return false;
        }
        if (self.paymentDueSetupData.length != 0) 
        {
          temp1 = temp1.filter(function (obj) {
            return !self.paymentDueSetupData.some(function (emp) {
              return emp.empID === obj.employee_ID;
            });
          });
          self.tempPaymentDueSetupData = self.filterOrReturnTemp1(self.checkEmpListForAddStaff1,self.paymentDueSetupData);
          if(self.empInfoForaddPayroll.length != 0)
          {
            self.empInfoForaddPayroll = self.empInfoForaddPayroll.filter(function (obj) {
              return self.checkEmpListForAddStaff1.some(function (emp) {
                return emp.employee_ID === obj.employee_ID;
              });
            });
          }
        }
        // self.LoadingDialog2 = true;
        // self.theuploadlength = temp1.length;
        let thetemArray = [];
        let theemsg = [];
        self.LoadingDialog2 = true;
        self.theuploadlength = temp1.length;
        let isthereerr = false;
        if(temp1.length != 0)
        {
          self.paymentDueSetupData = self.tempPaymentDueSetupData;
          for (let tp = 0; tp < temp1.length; tp ++)
          {
            let tempArr = [];
            tempArr.push(temp1[tp]);
            await axios.post(`${self.url}Payment_Due/Getemplistforpaydue`, tempArr)
            .then(function (response) {
              if (response.data.status == 0)
              {
                let bankcheck = true;
                let salarycheck = true;
                if (response.data.data[0].paymenttype == "Bank Transfer")
                {
                  if (response.data.data[0].bankname == "")
                  {
                    bankcheck = false;
                  }
                }
                if (response.data.data[0].salaryamount === "0")
                {
                  salarycheck = false;
                }
                if(salarycheck == true)
                {
                  if (bankcheck == true)
                  {
                    thetemArray.push(response.data.data[0]);
                    self.theuploaded += 1;
                  }
                  else
                  {
                    isthereerr = true;
                    theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have bank account number for transfer. ");
                  }
                }
                else
                {
                  isthereerr = true;
                  theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have salary amount. ");
                }
              }
              else
              {
                isthereerr = true;
                theemsg.push(response.data.message + " for Employee ID " + temp1[tp].employee_Code);
                //alert("please select employee who already defined ");
              }
            }).catch(function (error) {
              isthereerr = true;
              theemsg.push(error + " for Employee ID " + temp1[tp].employee_Code);
            });
          }
        }
        if (thetemArray.length != 0) 
        {
          self.AddStaffCondition_ShiftCheck = true;                       
          if (self.AddStaffCondition_ShiftCheck) 
          {
            //self.empInfoForaddPayroll = [];
            self.EditpaymentDuesetup = thetemArray;
            self.checklist = thetemArray;
            let temp = [];
            if (self.Deductionlist.length == 0) 
            {
              self.Deductionlist.push({
                deduction: "",
                amount: 0,
                employee_ID: 0,
              });
            }
            let t = JSON.parse(JSON.stringify(self.Deductionlist));
            await Promise.all(thetemArray.map(async function (e, index) {
              self.bankSplit(e.bankname);
              let ssf = 0;
              let ssfcheck = false;
              let personaltax = 0;
              let personaltaxcheck = false;
              let pvd = 0;
              let pvdcheck = false;
              let totaldeduction = 0;
              if (self.Payrolldeductionlist[0].pvd == true) 
              {
                pvdcheck = true;
                pvd = await self.getempprovidentforaddstaffforpayroll(e.hiredDate,e.emp_Cumulative_Startdate,e.salaryamount);
              }
              if (self.Payrolldeductionlist[0].ssf == true) 
              {
                ssfcheck = true;
                if(self.empButton == 2)
                {
                  e.total_Income_To_Calculate_SSF = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour);
                }
                if(e.total_Income_To_Calculate_SSF >= self.Commonsocial.lowestSubvention)
                {
                  if (!self.disabledAddPayment)
                  {
                    if (e.total_Income_To_Calculate_SSF >= self.Commonsocial.highestsalary)
                    {
                      ssf = self.Commonsocial.highestSubvention;
                    }
                    else if (e.total_Income_To_Calculate_SSF < self.Commonsocial.lowestsalary) 
                    {
                      ssf = 0;
                    }
                    else if (e.total_Income_To_Calculate_SSF == self.Commonsocial.lowestsalary) 
                    {
                      ssf = self.Commonsocial.lowestSubvention;
                    }
                    else
                    {
                      ssf = Math.round((self.Commonsocial.percentage / 100) * e.total_Income_To_Calculate_SSF);
                    }
                  } 
                  else 
                  {
                    ssf = parseInt(e.social_Security_Fund_Amount_Default);
                  }
                }
              }
              if (self.Payrolldeductionlist[0].personaltax == true) 
              {
                personaltaxcheck = true;
                personaltax = self.GetIncome(parseFloat(e.total_Income_To_Calculate_Tax),e.personal_Tax_Default) / 12;
              }
              let latdy = 0;
              if (e.timelist.length != 0) 
              {
                for (let h = 0;h < e.timelist.length;h++) 
                {
                  var dated = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                  var dd = new Date(dated);
                  var ed = new Date();
                  ed.setHours(dd.getHours(), dd.getMinutes());
                  var c = new Date();
                  var ggg = e.timelist[h].shiftstart;
                  let inputdate = ggg.split(":");
                  if (e.timelist[h].settingexit == true) 
                  {
                    if (e.timelist[h].type == "hour") 
                    {
                      inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                    } 
                    else 
                    {
                      inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                    }
                  }
                  c.setHours(inputdate[0]);
                  c.setMinutes(inputdate[1]);
                  if (ed > c) 
                  {
                    if (latdy != null && latdy != 0) 
                    {
                      latdy = latdy + 1;
                    } 
                    else 
                    {
                      latdy = 1;
                    }
                  }
                }
              }
              let latedudu = latdy * parseFloat(e.late_Rate);
              let ncdudu = e.stamp_Days * parseFloat(e.stamp_Rate);
              if(self.empButton == 2)
              {
                let workingday = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour)
                if(workingday != 0)
                {
                  totaldeduction = ssf + personaltax + e.deduction + latedudu + pvd;
                  e.netincome = parseFloat(e.workingday + self.addOTtotal(e)) - totaldeduction;
                } 
                else 
                {
                  e.netincome = 0;
                } 
              } 
              else 
              {
                totaldeduction = ssf + personaltax + e.deduction + latedudu + ncdudu + pvd;
                e.netincome = parseFloat(e.regularearning + self.addOTtotal(e)) - totaldeduction;
              }
              temp.push({
                No: index + 1,
                addition: self.addOTtotal(e),
                bankID: e.bankID,
                bankAccount: self.bankAccountSplit(e.bankname),
                bankname: self.bankSplit(e.bankname),
                bankname_ENG: self.bankSplit(e.bankname_ENG),
                deduction: totaldeduction,
                empID: e.empID,
                employee_Code: e.employee_Code,
                empname: e.empname,
                image: e.image,
                netincome: e.netincome,
                total_Income_To_Calculate_SSF: e.total_Income_To_Calculate_SSF,
                social_Security_Fund_Amount_Default: e.social_Security_Fund_Amount_Default,
                total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                personal_Tax_Default: e.personal_Tax_Default,
                emp_Cumulative_Startdate: e.emp_Cumulative_Startdate,
                hiredDate: e.hiredDate,
                regularearning: e.regularearning,
                salaryamount: e.salaryamount,
                status: e.status
              });
              let mylateday = 0;
              if (e.timelist.length != 0) 
              {
                for (let h = 0; h < e.timelist.length; h++) 
                {
                  var date = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                  var d = new Date(date);
                  var mycheckin = new Date();
                  mycheckin.setHours(d.getHours(), d.getMinutes());
                  var shifttime = new Date();
                  var gg = e.timelist[h].shiftstart;
                  let inputdate = gg.split(":");
                  if (e.timelist[h].settingexit == true) 
                  {
                    if (e.timelist[h].type == "hour") 
                    {
                      inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                    } 
                    else 
                    {
                      inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                    }
                  }
                  shifttime.setHours(inputdate[0]);
                  shifttime.setMinutes(inputdate[1]);
                  if (mycheckin > shifttime) 
                  {
                    mylateday = mylateday + 1;
                  }
                }
              }
              if (self.regularEarningOption === "month")
              {
                self.tempEmpInfoForaddPayroll.push({
                  employee_ID: e.empID,
                  working_Rate: e.working_Rate,
                  oT_Rate: e.oT_Rate,
                  oT_Rate_2: e.oT_Rate_2.toString(),
                  oT_Rate_3: e.oT_Rate_3.toString(),
                  oT_Rate_4: e.oT_Rate_4.toString(),
                  stamp_Rate: e.stamp_Rate,
                  late_Rate: e.late_Rate,
                  total_Income: e.addition,
                  total_Deduction: totaldeduction,
                  total_Addition: e.total_Addition,
                  workingDayorHour: e.workingDayorHour,
                  late_Days: mylateday,
                  oT_Hours: e.oT_Hours,
                  oT_Hours_2: e.oT_Hours_2.toString(),
                  oT_Hours_3: e.oT_Hours_3.toString(),
                  oT_Hours_4: e.oT_Hours_4.toString(),
                  stamp_Days: e.stamp_Days,
                  active: true,
                  status: "In Progress",
                  salary: e.salaryamount,
                  deductionlist: t,
                  additionalEarninglist: e.additionalEarninglist,
                  personalTax: personaltaxcheck,
                  socialSecurity: ssfcheck,
                  providentFund: pvdcheck,
                  social_Security_Fund: ssf,
                  other_Total_Deduction: e.other_Total_Deduction,
                  personal_Tax: personaltax,
                  provident_Fund_Amount: pvd,
                  total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                });
              } 
              else if (self.regularEarningOption === "day")
              {
                totaldeduction = ssf + personaltax + e.deduction;
                self.tempEmpInfoForaddPayroll.push({
                  employee_ID: e.empID,
                  working_Rate: e.working_Rate,
                  oT_Rate: e.oT_Rate,
                  oT_Rate_2: e.oT_Rate_2.toString(),
                  oT_Rate_3: e.oT_Rate_3.toString(),
                  oT_Rate_4: e.oT_Rate_4.toString(),
                  stamp_Rate: e.stamp_Rate,
                  late_Rate: e.late_Rate,
                  total_Income: e.addition,
                  total_Addition: e.total_Addition,
                  total_Deduction: e.deduction,
                  workingDayorHour: e.workingDayorHour,
                  late_Days: mylateday,
                  oT_Hours: e.oT_Hours,
                  oT_Hours_2: e.oT_Hours_2.toString(),
                  oT_Hours_3: e.oT_Hours_3.toString(),
                  oT_Hours_4: e.oT_Hours_4.toString(),
                  stamp_Days: e.stamp_Days,
                  active: true,
                  status: "In Progress",
                  salary: 0,
                  deductionlist: t,
                  additionalEarninglist: e.additionalEarninglist,
                  personalTax: personaltaxcheck,
                  socialSecurity: ssfcheck,
                  providentFund: pvdcheck,
                  social_Security_Fund: ssf,
                  other_Total_Deduction: e.other_Total_Deduction,
                  personal_Tax: personaltax,
                  provident_Fund_Amount: pvd,
                  total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                });
              }
              else if (self.regularEarningOption === "hour")
              {
                totaldeduction = ssf + personaltax + e.deduction;
                let totalhours = 0;
                for (let a = 0; a < e.hourlist.length; a++)
                {
                  var starttime = self.convertUTCDateToLocalDateforpayslip(e.hourlist[a].starttime);
                  var endtime = "";
                  if (e.hourlist[a].noclockout == true)
                  {
                    endtime = self.convertUTCDateToLocalDateforpayslip(e.hourlist[a].endtime);
                  }
                  else
                  {
                    endtime = e.hourlist[a].endtime;
                  }
                  // moment(stillUtc).local().format("YYYY-MM-DDTHH:mm:ss")
                  starttime = moment(starttime).local().format("YYYY-MM-DD hh:mm:ss a");
                  endtime = moment(endtime).local().format("YYYY-MM-DD hh:mm:ss a");
                  starttime = moment(starttime);
                  endtime = moment(endtime);
                  let difftotalhours = 0;
                  difftotalhours = endtime.diff(starttime, "hours");
                  totalhours = totalhours + difftotalhours;
                }
                if (totalhours != 0)
                {
                  totalhours = totalhours - e.timeoffhours;
                  if (totalhours < 0)
                  {
                    totalhours = 0;
                  }
                }
                self.tempEmpInfoForaddPayroll.push({
                  employee_ID: e.empID,
                  working_Rate: e.working_Rate,
                  oT_Rate: e.oT_Rate,
                  oT_Rate_2: e.oT_Rate_2.toString(),
                  oT_Rate_3: e.oT_Rate_3.toString(),
                  oT_Rate_4: e.oT_Rate_4.toString(),
                  stamp_Rate: e.stamp_Rate,
                  late_Rate: e.late_Rate,
                  total_Income: e.addition,
                  total_Addition: e.total_Addition,
                  total_Deduction: totaldeduction,
                  workingDayorHour: totalhours.toString(),
                  late_Days: mylateday,
                  oT_Hours: e.oT_Hours,
                  oT_Hours_2: e.oT_Hours_2.toString(),
                  oT_Hours_3: e.oT_Hours_3.toString(),
                  oT_Hours_4: e.oT_Hours_4.toString(),
                  stamp_Days: e.stamp_Days,
                  active: true,
                  status: "In Progress",
                  salary: 0,
                  deductionlist: t,
                  additionalEarninglist: e.additionalEarninglist,
                  personalTax: personaltaxcheck,
                  socialSecurity: ssfcheck,
                  providentFund: pvdcheck,
                  social_Security_Fund: ssf,
                  other_Total_Deduction: e.other_Total_Deduction,
                  personal_Tax: personaltax,
                  provident_Fund_Amount: 0,
                  total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                });
              }
              if (e.socialSecurity === true) 
              {
                self.PrintDataWithSocialSecurity(e,ssf,personaltax,pvd);
              } 
              else 
              {
                self.PrintDataWithoutSocialSecurity(e,ssf,personaltax,pvd);
              }
            }));
            self.empInfoForaddPayroll = [...self.empInfoForaddPayroll, ...self.tempEmpInfoForaddPayroll];
            if (self.regularEarningOption)
            {
              if (self.regularEarningOption === "month")
              {
                temp.forEach(function (e) {
                    self.paymentDueSetupData.push({
                      No: self.paymentDueSetupData.length + 1,
                      addition: e.addition,
                      bankID: e.bankID,
                      bankAccount: e.bankAccount,
                      bankname: e.bankname,
                      bankname_ENG: e.bankname_ENG,
                      deduction: e.deduction,
                      image: e.image,
                      empID: e.empID,
                      employee_Code: e.employee_Code,
                      empname: e.empname,
                      netincome: e.netincome,
                      regularearning: e.regularearning,
                      salaryamount: e.salaryamount,
                      status: e.status,
                    });
                  });
              }
              else
              {
                temp.forEach(function (e) {
                    self.paymentDueSetupData.push({
                      No: self.paymentDueSetupData.length + 1,
                      addition: e.addition,
                      bankID: e.bankID,
                      bankAccount: e.bankAccount,
                      bankname: e.bankname,
                      bankname_ENG: e.bankname_ENG,
                      deduction: e.deduction,
                      image: e.image,
                      empID: e.empID,
                      employee_Code: e.employee_Code,
                      empname: e.empname,
                      netincome: e.netincome,
                      regularearning: e.regularearning,
                      salaryamount: e.salaryamount,
                      status: e.status,
                    });
                  });
              }
            }
            self.cleanCalculationCache();
            //self.searchlist = self.paymentDueSetupData;         
            self.totalNetpaid_Unchange = 0;
            await Promise.all(self.paymentDueSetupData.map(async function (e) {
              self.totalExpenses += parseFloat(e.regularearning) + parseFloat(e.addition);
              self.totalNetPaid += parseFloat(e.netincome);
              self.totalNetpaid_Unchange += parseFloat(e.netincome);
              self.totalAddition += parseFloat(e.addition);
              self.totalSalary += parseFloat(e.salaryamount);
              self.totalDeduction += parseFloat(e.deduction);
              self.totalNumberOfEmployee++;
            }));
            // Convert total expenses and total addition to strings with two decimal places
            self.totalExpenses = self.totalExpenses.toFixed(2);
            self.totalAddition = self.totalAddition.toFixed(2);
            self.totalNetPaid = self.totalNetPaid.toFixed(2);
            self.totalNetpaid_Unchange = self.totalNetpaid_Unchange.toFixed(2);
            self.totalDeduction = self.totalDeduction.toFixed(2);
            if (self.paidBy === "Transfer")
            {
              self.totalTransfer = self.totalNetPaid;
              self.totalCash = 0;
            }
            else
            {
              self.totalCash = self.totalNetPaid;
              self.totalTransfer = 0;
            }  
            self.unpaidAmount = self.totalNetPaid;
            self.totalNetPaid = 0;
            self.createSetUpDialog = false;
            self.staffDialog = false;
            self.editSetUpDialog = true;
            self.empList = [];
            self.firstEdit = false;
          }
          else 
          {
            alert("Please Choose Employees with Shift");
            self.AddStaffCondition_ShiftCheck = true;
          }
        }
        else 
        {
          alert("please select employee who already defined");
          self.checkEmpListForAddStaff = [];
          self.paymentDueSetupData = [];
          self.tempPaymentDueSetupData = [];
          self.tempEmpInfoForaddPayroll = [];
        }
        if(isthereerr)
        {
          let Message = [...new Set(theemsg)];
          alert(`${Message.join("\n")}`);
        }
        self.LoadingDialog2 = false;
        self.theuploaded = 0;
        self.theuploadlength = 0;
        self.putdecimal();
        self.putcomma();
      }
      else 
      {
        self.staffDialog = false;
        self.editSetUpDialog = false;
        self.createSetUpDialog = true;
        self.paymentDueSetupData = [];
        self.empInfoForaddPayroll = [];
        self.LoadingDialog = false;
        this.addStaffBtnShow = true;
        this.editBtnShow = false;
      }
    },
    
    //retreive data and calculation after user add staff in new paydue for predefined option
    async checkStaffForPredefined() {
      let self = this;
      self.PrintDataList = [];
      if (this.editpaydueDetailList[0]) 
      {
        this.checkStaffForEditPredefined();
      } 
      else 
      {
        self.cleanCalculationCache();
        self.totalNetpaid_Unchange = 0;
        self.totalNumberOfEmployee = 0;
        if (self.checkEmpListForAddStaff1.length != 0) 
        {
          var temp1 = this.checkEmpListForAddStaff1.map(function (e) {
            return {
              employee_ID: e.employee_ID,
              employee_Code: e.employee_Code,
              companyID: parseInt(store.state.companyID),
              paymenttype: self.paidBy,
              periodenddate: self.payrollInfo.payroll_end_Date_notFormat,
              periodstartdate: self.payrollInfo.payroll_start_Date_notFormat,
              regularOption: "month"
            };
          });
          var isDuplicate = temp1.some((item, index) => temp1.findIndex(obj => obj.employee_ID === item.employee_ID) !== index);
          if(isDuplicate)
          {
            self.checkEmpListForAddStaff = [];
            self.checkEmpListForAddStaff1 = [];
            alert("Duplicate Employee");
            return false;
          }
          if (self.paymentDueSetupData.length != 0) 
          {
            temp1 = temp1.filter(function (obj) {
              return !self.paymentDueSetupData.some(function (emp) {
                return emp.empID === obj.employee_ID;
              });
            });
            self.tempPaymentDueSetupData = self.filterOrReturnTemp1(self.checkEmpListForAddStaff1,self.paymentDueSetupData);
            if(self.empInfoForaddPayroll.length != 0)
            {
              self.empInfoForaddPayroll = self.empInfoForaddPayroll.filter(function (obj) {
                return self.checkEmpListForAddStaff1.some(function (emp) {
                  return emp.employee_ID === obj.employee_ID;
                });
              });
            }
          }
          //loop
          let thetemArray = [];
          let theemsg = [];
          self.LoadingDialog2 = true;
          self.theuploadlength = temp1.length;
          let isthereerr = false;
          if(temp1.length != 0)
          {
            self.paymentDueSetupData = self.tempPaymentDueSetupData;
            for (let tp = 0; tp < temp1.length; tp ++)
            {
              let tempArr = [];
              tempArr.push(temp1[tp]);
              await axios.post(`${self.url}Payment_Due/Getemplistforpaydue`, tempArr)
              .then(function (response) {
                if (response.data.status == 0) 
                {
                  let bankcheck = true;
                  let salarycheck = true;
                    if (response.data.data[0].paymenttype == "Bank Transfer") 
                    {
                      if (response.data.data[0].bankname == "") 
                      {
                        bankcheck = false;
                      }
                    }
                    if (response.data.data[0].salaryamount === "0") 
                    {
                      salarycheck = false;
                    }
                    if(salarycheck == true)
                    {
                      if (bankcheck == true)
                      {
                        thetemArray.push(response.data.data[0]);
                        self.theuploaded += 1;
                      } 
                      else 
                      {
                        isthereerr = true;
                        theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have bank account number for transfer. ");
                      }
                    }
                    else 
                    {
                      isthereerr = true;
                      theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have salary amount. ");
                    }
                }
                else 
                {
                  isthereerr = true;
                  theemsg.push(response.data.message + " for Employee ID " + temp1[tp].employee_Code);
                }
              })
              .catch(function (error) {
                isthereerr = true;
                theemsg.push(error + " for Employee ID " + temp1[tp].employee_Code);
              });
            }
          }
          if (thetemArray.length != 0) 
          {
            if (self.AddStaffCondition_ShiftCheck) 
            {
              //self.paymentDueSetupData = thetemArray;
              //self.empInfoForaddPayroll = [];
              self.EditpaymentDuesetup = thetemArray;
              self.checklist = thetemArray;
              let temp = [];
              if (self.Deductionlist.length == 0) 
              {
                self.Deductionlist.push({
                  deduction: "",
                  amount: 0,
                  employee_ID: 0,
                });
              }
              let t = JSON.parse(JSON.stringify(self.Deductionlist));
              await Promise.all(thetemArray.map(async function (e, index) {
                // self.bankSplit(e.bankname);
                let ssf = 0;
                let ssfcheck = false;
                let personaltax = 0;
                let personaltaxcheck = false;
                let pvd = 0;
                let pvdcheck = false;
                let totaldeduction = 0;
                if (self.Payrolldeductionlist[0].ssf == true)
                {
                  ssfcheck = true;
                  if(self.empButton == 2)
                  {
                    e.total_Income_To_Calculate_SSF = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour);
                  }
                  if(e.total_Income_To_Calculate_SSF >= self.Commonsocial.lowestSubvention)
                  {
                    if (!self.disabledAddPayment)
                    {
                      if (e.total_Income_To_Calculate_SSF >= self.Commonsocial.highestsalary)
                      {
                        ssf = self.Commonsocial.highestSubvention;
                      }
                      else if (e.total_Income_To_Calculate_SSF < self.Commonsocial.lowestsalary) 
                      {
                        ssf = 0;
                      }
                      else if (e.total_Income_To_Calculate_SSF == self.Commonsocial.lowestsalary) 
                      {
                        ssf = self.Commonsocial.lowestSubvention;
                      }
                      else
                      {
                        ssf = Math.round((self.Commonsocial.percentage / 100) * e.total_Income_To_Calculate_SSF);
                      }
                    } 
                    else 
                    {
                      ssf = parseInt(e.social_Security_Fund_Amount_Default);
                    }
                  }
                }
                if (self.Payrolldeductionlist[0].personaltax == true) 
                {
                  personaltaxcheck = true;
                  personaltax = self.GetIncome(parseFloat(e.total_Income_To_Calculate_Tax),e.personal_Tax_Default) / 12;
                }
                if (self.Payrolldeductionlist[0].pvd == true) 
                {
                  pvdcheck = true;
                  const mypvd = await self.getempprovidentforaddstaffforpayroll(e.hiredDate,e.emp_Cumulative_Startdate,e.salaryamount);
                  pvd = mypvd;
                }
                let latdy = 0;
                if (e.timelist.length != 0) 
                {
                  for (let h = 0;h < e.timelist.length;h++) 
                  {
                    var dated = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                    var dd = new Date(dated);
                    var ed = new Date();
                    ed.setHours(dd.getHours(), dd.getMinutes());
                    var c = new Date();
                    var ggg = e.timelist[h].shiftstart;
                    let inputdate = ggg.split(":");
                    if (e.timelist[h].settingexit == true) 
                    {
                      if (e.timelist[h].type == "hour") 
                      {
                        inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                      } 
                      else 
                      {
                        inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                      }
                    }
                    c.setHours(inputdate[0]);
                    c.setMinutes(inputdate[1]);
                    if (ed > c) 
                    {
                      if (latdy != null && latdy != 0) 
                      {
                        latdy = latdy + 1;
                      } 
                      else 
                      {
                        latdy = 1;
                      }
                    }
                  }
                }
                let latedudu = latdy * parseFloat(e.late_Rate);
                let ncdudu = e.stamp_Days * parseFloat(e.stamp_Rate);
                //totaldeduction = ssf + personaltax + e.deduction + latedudu + ncdudu + pvd;
                if(self.empButton == 2)
                {
                  let workingday = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour)
                  if(workingday != 0)
                  {
                    totaldeduction = ssf + personaltax + e.deduction + latedudu + pvd;
                    e.netincome = parseFloat(workingday + self.addOTtotal(e)) - totaldeduction;
                  }
                  else
                  {
                    e.netincome = 0;
                  }
                }
                else
                {
                  totaldeduction = ssf + personaltax + e.deduction + latedudu + ncdudu + pvd;
                  e.netincome = parseFloat(e.regularearning + self.addOTtotal(e)) - totaldeduction;
                }
                //totaldeduction = ssf + personaltax + pvd + e.deduction;
                temp.push({
                  No: index + 1,
                  addition: e.addition,
                  bankID: e.bankID,
                  bankAccount: self.bankAccountSplit(e.bankname),
                  bankname: self.bankSplit(e.bankname),
                  deduction: totaldeduction,
                  empID: e.empID,
                  employee_Code: e.employee_Code,
                  image: e.image,
                  empname: e.empname,
                  total_Income_To_Calculate_SSF: e.total_Income_To_Calculate_SSF,
                  social_Security_Fund_Amount_Default: e.social_Security_Fund_Amount_Default,
                  total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                  personal_Tax_Default: e.personal_Tax_Default,
                  netincome: e.netincome,
                  regularearning: e.regularearning,
                  salaryamount: e.salaryamount,
                  status: e.status,
                  emp_Cumulative_Startdate: e.emp_Cumulative_Startdate,
                  hiredDate: e.hiredDate
                });

                let mylateday = 0;
                if (e.timelist.length != 0)
                {
                  for (let h = 0; h < e.timelist.length; h++) 
                  {
                    var date = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                    var d = new Date(date);
                    var mycheckin = new Date();
                    mycheckin.setHours(d.getHours(), d.getMinutes());
                    var shifttime = new Date();
                    var gg = e.timelist[h].shiftstart;
                    let inputdate = gg.split(":");
                    if (e.timelist[h].settingexit == true) 
                    {
                      if (e.timelist[h].type == "hour") 
                      {
                        inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                      } else 
                      {
                        inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                      }
                    }
                    shifttime.setHours(inputdate[0]);
                    shifttime.setMinutes(inputdate[1]);
                    if (mycheckin > shifttime) 
                    {
                      mylateday = mylateday + 1;
                    }
                  }
                }
                self.tempEmpInfoForaddPayroll.push({
                  employee_ID: e.empID,
                  working_Rate: e.working_Rate,
                  oT_Rate: e.oT_Rate,
                  oT_Rate_2: e.oT_Rate_2.toString(),
                  oT_Rate_3: e.oT_Rate_3.toString(),
                  oT_Rate_4: e.oT_Rate_4.toString(),
                  stamp_Rate: e.stamp_Rate,
                  late_Rate: e.late_Rate,
                  total_Income: e.addition,
                  total_Deduction: totaldeduction,
                  total_Addition: e.total_Addition,
                  status: "In Progress",
                  workingDayorHour: e.workingDayorHour,
                  late_Days: mylateday,
                  oT_Hours: e.oT_Hours,
                  oT_Hours_2: e.oT_Hours_2.toString(),
                  oT_Hours_3: e.oT_Hours_3.toString(),
                  oT_Hours_4: e.oT_Hours_4.toString(),
                  stamp_Days: e.stamp_Days,
                  active: true,
                  salary: e.salaryamount,
                  deductionlist: t,
                  additionalEarninglist: e.additionalEarninglist,
                  personalTax: personaltaxcheck,
                  socialSecurity: ssfcheck,
                  providentFund: pvdcheck,
                  social_Security_Fund: ssf,
                  other_Total_Deduction: e.other_Total_Deduction,
                  personal_Tax: personaltax,
                  provident_Fund_Amount: pvd,
                  total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
                });

                if (e.socialSecurity === true) 
                {
                  self.PrintDataWithSocialSecurity(e,ssf,personaltax,pvd);
                } 
                else 
                {
                  self.PrintDataWithoutSocialSecurity(e,ssf,personaltax,pvd);
                }
              }));
              temp.slice().reverse().forEach(function (e) {
                  self.paymentDueSetupData.push({
                    No: self.paymentDueSetupData.length + 1,
                    addition: e.addition,
                    bankID: e.bankID,
                    bankAccount: e.bankAccount,
                    bankname: e.bankname,
                    bankname_ENG: e.bankname_ENG,
                    deduction: e.deduction,
                    empID: e.empID,
                    image: e.image,
                    employee_Code: e.employee_Code,
                    empname: e.empname,
                    netincome:e.netincome,
                    regularearning: e.regularearning,
                    salaryamount: e.salaryamount,
                    status: "In Progress"
                  });
                });
              self.empInfoForaddPayroll = [...self.empInfoForaddPayroll, ...self.tempEmpInfoForaddPayroll];
              self.cleanCalculationCache();
              self.totalNetpaid_Unchange = 0;
              self.searchlist = self.paymentDueSetupData;
              await Promise.all(self.paymentDueSetupData.map(async function (e) {
                self.totalExpenses += parseFloat(e.regularearning) + parseFloat(e.addition);
                self.totalNetPaid += parseFloat(e.netincome);
                self.totalNetpaid_Unchange += parseFloat(e.netincome);
                self.totalAddition += parseFloat(e.addition);
                self.totalSalary += parseFloat(e.salaryamount);
                self.totalDeduction += parseFloat(e.deduction);
                self.totalNumberOfEmployee++;
              }));
              // Convert total expenses and total addition to strings with two decimal places
              self.totalExpenses = self.totalExpenses.toFixed(2);
              self.totalAddition = self.totalAddition.toFixed(2);
              self.totalNetPaid = self.totalNetPaid.toFixed(2);
              self.totalNetpaid_Unchange = self.totalNetpaid_Unchange.toFixed(2);
              self.totalDeduction = self.totalDeduction.toFixed(2);
              if (self.paidBy === "Transfer")
              {
                self.totalTransfer = self.totalNetPaid;
                self.totalCash = 0;
              }
              else
              {
                self.totalCash = self.totalNetPaid;
                self.totalTransfer = 0;
              }
              self.unpaidAmount = self.totalNetPaid;
              self.totalNetPaid = 0;
              self.createSetUpDialog = false;
              self.staffDialog = false;
              self.editSetUpDialog = true;
              self.empList = [];
              self.firstEdit = false;
            }
            else
            {
              alert("Please Choose Employees with Shift");
              self.AddStaffCondition_ShiftCheck = true;
            }
          }
          else
          {
            alert("please select employee who already defined ");
            self.checkEmpListForAddStaff = [];
            self.paymentDueSetupData = [];
            self.tempPaymentDueSetupData = [];
            self.tempEmpInfoForaddPayroll = [];
          }
          if(isthereerr == true)
          {
            let Message = [...new Set(theemsg)];
            alert(`${Message.join("\n")}`);
          }
          self.LoadingDialog2 = false;
          self.theuploaded = 0;
          self.theuploadlength = 0;
          self.putdecimal();
          self.putcomma();
        }
        else
        {
          self.paymentDueSetupData = [];
          self.empInfoForaddPayroll = [];
          self.createSetUpDialog = true;
          self.LoadingDialog2 = false;
          self.editSetUpDialog = false;
          self.staffDialog = false;
        }
      }
    },

    async checkStaffForEditPredefined() {
      let self = this;
      self.cleanCalculationCache();
      self.totalNetpaid_Unchange = 0;
      self.totalNumberOfEmployee = 0;
      if (self.checkEmpListForAddStaff1.length != 0) 
      {
        var temp1 = this.checkEmpListForAddStaff1.map(function (e) {
          return {
            employee_ID: e.employee_ID,
            employee_Code: e.employee_Code,
            companyID: parseInt(store.state.companyID),
            paymenttype: self.paidBy,
            periodenddate: self.payrollInfo.payroll_end_Date_notFormat,
            periodstartdate: self.payrollInfo.payroll_start_Date_notFormat,
            regularOption: "month"
          };
        });
        var isDuplicate = temp1.some((item, index) => temp1.findIndex(obj => obj.employee_ID === item.employee_ID) !== index);
        if(isDuplicate)
        {
          self.checkEmpListForAddStaff = [];
          self.checkEmpListForAddStaff1 = [];
          alert("Duplicate Employee");
          return false;
        }
        if (self.paymentDueSetupData.length != 0) 
        {
          temp1 = temp1.filter(function (obj) {
            return !self.paymentDueSetupData.some(function (emp) {
              return emp.empID === obj.employee_ID;
            });
          });
          self.tempPaymentDueSetupData = self.filterOrReturnTemp1(self.checkEmpListForAddStaff1,self.paymentDueSetupData);
          if(self.empInfoForaddPayroll.length != 0)
          {
            self.empInfoForaddPayroll = self.empInfoForaddPayroll.filter(function (obj) {
              return self.checkEmpListForAddStaff1.some(function (emp) {
                return emp.employee_ID === obj.employee_ID;
              });
            });
          }
        }
        
        //loop
        let thetemArray = [];
        let theemsg = [];
        self.LoadingDialog2 = true;
        self.theuploadlength = temp1.length;
        let isthereerr = false;
        if(temp1.length != 0)
        {
          self.paymentDueSetupData = self.tempPaymentDueSetupData;
          for (let tp = 0; tp < temp1.length; tp ++)
          {
            let tempArr = [];
            tempArr.push(temp1[tp]);
            await axios.post(`${self.url}Payment_Due/Getemplistforpaydue`, tempArr)
            .then(function (response) {
              if (response.data.status == 0) 
              {
                let bankcheck = true;
                let salarycheck = true;
                  if (response.data.data[0].paymenttype == "Bank Transfer") 
                  {
                    if (response.data.data[0].bankname == "") 
                    {
                      bankcheck = false;
                    }
                  }
                  if (response.data.data[0].salaryamount === "0") 
                  {
                    salarycheck = false;
                  }
                  if(salarycheck == true)
                  {
                    if (bankcheck == true)
                    {
                      thetemArray.push(response.data.data[0]);
                      self.theuploaded += 1;
                    } 
                    else 
                    {
                      isthereerr = true;
                      theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have bank account number for transfer. ");
                    }
                  }
                  else 
                  {
                    isthereerr = true;
                    theemsg.push("Employee ID " + temp1[tp].employee_Code + " don't have salary amount. ");
                  }
              }
              else 
              {
                isthereerr = true;
                theemsg.push(response.data.message + " for Employee ID " + temp1[tp].employee_Code);
              }
            })
            .catch(function (error) {
              isthereerr = true;
              theemsg.push(error + " for Employee ID " + temp1[tp].employee_Code);
            });
          }
        }
        if (thetemArray.length != 0) 
        {
          if (self.AddStaffCondition_ShiftCheck) 
          {
            //self.paymentDueSetupData = thetemArray;
            //self.empInfoForaddPayroll = [];
            self.EditpaymentDuesetup = thetemArray;
            self.checklist = thetemArray;
            let temp = [];
            if (self.Deductionlist.length == 0) 
            {
              self.Deductionlist.push({
                deduction: "",
                amount: 0,
                employee_ID: 0,
              });
            }
            let t = JSON.parse(JSON.stringify(self.Deductionlist));
            await Promise.all(thetemArray.map(async function (e, index) {
              // self.bankSplit(e.bankname);
              let ssf = 0;
              let ssfcheck = false;
              let personaltax = 0;
              let personaltaxcheck = false;
              let pvd = 0;
              let pvdcheck = false;
              let totaldeduction = 0;
              if (self.Payrolldeductionlist[0].ssf == true)
              {
                ssfcheck = true;
                if(self.empButton == 2)
                {
                  e.total_Income_To_Calculate_SSF = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour);
                }
                if(e.total_Income_To_Calculate_SSF >= self.Commonsocial.lowestSubvention)
                {
                  if (!self.disabledAddPayment)
                  {
                    if (e.total_Income_To_Calculate_SSF >= self.Commonsocial.highestsalary)
                    {
                      ssf = self.Commonsocial.highestSubvention;
                    }
                    else if (e.total_Income_To_Calculate_SSF < self.Commonsocial.lowestsalary) 
                    {
                      ssf = 0;
                    }
                    else if (e.total_Income_To_Calculate_SSF == self.Commonsocial.lowestsalary) 
                    {
                      ssf = self.Commonsocial.lowestSubvention;
                    }
                    else
                    {
                      ssf = Math.round((self.Commonsocial.percentage / 100) * e.total_Income_To_Calculate_SSF);
                    }
                  } 
                  else 
                  {
                    ssf = parseInt(e.social_Security_Fund_Amount_Default);
                  }
                }
              }

              if (self.Payrolldeductionlist[0].personaltax == true) 
              {
                personaltaxcheck = true;
                personaltax = self.GetIncome(parseFloat(e.total_Income_To_Calculate_Tax),e.personal_Tax_Default) / 12;
              }

              if (self.Payrolldeductionlist[0].pvd == true) 
              {
                pvdcheck = true;
                const mypvd = await self.getempprovidentforaddstaffforpayroll(e.hiredDate,e.emp_Cumulative_Startdate,e.salaryamount);
                pvd = mypvd;
              }
              let latdy = 0;
                if (e.timelist.length != 0) 
                {
                  for (let h = 0;h < e.timelist.length;h++) 
                  {
                    var dated = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                    var dd = new Date(dated);
                    var ed = new Date();
                    ed.setHours(dd.getHours(), dd.getMinutes());
                    var c = new Date();
                    var ggg = e.timelist[h].shiftstart;
                    let inputdate = ggg.split(":");
                    if (e.timelist[h].settingexit == true) 
                    {
                      if (e.timelist[h].type == "hour") 
                      {
                        inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                      } 
                      else 
                      {
                        inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                      }
                    }
                    c.setHours(inputdate[0]);
                    c.setMinutes(inputdate[1]);
                    if (ed > c) 
                    {
                      if (latdy != null && latdy != 0) 
                      {
                        latdy = latdy + 1;
                      } 
                      else 
                      {
                        latdy = 1;
                      }
                    }
                  }
                }
                let latedudu = latdy * parseFloat(e.late_Rate);
                let ncdudu = e.stamp_Days * parseFloat(e.stamp_Rate);
                //totaldeduction = ssf + personaltax + e.deduction + latedudu + ncdudu + pvd;
                if(self.empButton == 2)
                {
                  let workingday = parseFloat(e.salaryamount) * parseFloat(e.workingDayorHour)
                  if(workingday != 0)
                  {
                    totaldeduction = ssf + personaltax + e.deduction + latedudu + pvd;
                    e.netincome = parseFloat(workingday + self.addOTtotal(e)) - totaldeduction;
                  } 
                  else 
                  {
                    e.netincome = 0;
                  } 
                } 
                else 
                {
                  totaldeduction = ssf + personaltax + e.deduction + latedudu + ncdudu + pvd;
                  e.netincome = parseFloat(e.regularearning + self.addOTtotal(e)) - totaldeduction;
                }
              temp.push({
                No: index + 1,
                addition: e.addition,
                bankID: e.bankID,
                bankAccount: self.bankAccountSplit(e.bankname),
                bankname: self.bankSplit(e.bankname),
                deduction: totaldeduction,
                empID: e.empID,
                employee_Code: e.employee_Code,
                image: e.image,
                empname: e.empname,
                total_Income_To_Calculate_SSF: e.total_Income_To_Calculate_SSF,
                social_Security_Fund_Amount_Default: e.social_Security_Fund_Amount_Default,
                total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                personal_Tax_Default: e.personal_Tax_Default,
                netincome: e.netincome,
                regularearning: e.regularearning,
                salaryamount: e.salaryamount,
                status: e.status,
                emp_Cumulative_Startdate: e.emp_Cumulative_Startdate,
                hiredDate: e.hiredDate
              });

              let mylateday = 0;
              if (e.timelist.length != 0)
              {
                for (let h = 0; h < e.timelist.length; h++) 
                {
                  var date = self.convertUTCDateToLocalDate(e.timelist[h].myUTCdate);
                  var d = new Date(date);
                  var mycheckin = new Date();
                  mycheckin.setHours(d.getHours(), d.getMinutes());
                  var shifttime = new Date();
                  var gg = e.timelist[h].shiftstart;
                  let inputdate = gg.split(":");
                  if (e.timelist[h].settingexit == true) 
                  {
                    if (e.timelist[h].type == "hour") 
                    {
                      inputdate[0] = parseInt(inputdate[0]) + e.timelist[h].time;
                    }
                    else 
                    {
                      inputdate[1] = parseInt(inputdate[1]) + e.timelist[h].time;
                    }
                  }
                  shifttime.setHours(inputdate[0]);
                  shifttime.setMinutes(inputdate[1]);
                  if (mycheckin > shifttime) 
                  {
                    mylateday = mylateday + 1;
                  }
                }
              }
              self.tempEmpInfoForaddPayroll.push({
                employee_ID: e.empID,
                working_Rate: e.working_Rate,
                oT_Rate: e.oT_Rate,
                oT_Rate_2: e.oT_Rate_2.toString(),
                oT_Rate_3: e.oT_Rate_3.toString(),
                oT_Rate_4: e.oT_Rate_4.toString(),
                stamp_Rate: e.stamp_Rate,
                late_Rate: e.late_Rate,
                total_Income: e.addition,
                total_Deduction: totaldeduction,
                total_Addition: e.total_Addition,
                status: "In Progress",
                workingDayorHour: e.workingDayorHour,
                late_Days: mylateday,
                oT_Hours: e.oT_Hours,
                oT_Hours_2: e.oT_Hours_2.toString(),
                oT_Hours_3: e.oT_Hours_3.toString(),
                oT_Hours_4: e.oT_Hours_4.toString(),
                stamp_Days: e.stamp_Days,
                active: true,
                salary: e.salaryamount,
                deductionlist: t,
                additionalEarninglist: e.additionalEarninglist,
                personalTax: personaltaxcheck,
                socialSecurity: ssfcheck,
                providentFund: pvdcheck,
                social_Security_Fund: ssf,
                other_Total_Deduction: e.other_Total_Deduction,
                personal_Tax: personaltax,
                provident_Fund_Amount: pvd,
                total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax
              });

              if (e.socialSecurity === true) 
              {
                self.PrintDataWithSocialSecurity(e,ssf,personaltax,pvd);
              } 
              else {
                self.PrintDataWithoutSocialSecurity(e,ssf,personaltax,pvd);
              }
            }));
            temp.slice().reverse().forEach(function (e) {
                self.paymentDueSetupData.push({
                  No: self.paymentDueSetupData.length + 1,
                  addition: e.addition,
                  bankID: e.bankID,
                  bankAccount: e.bankAccount,
                  bankname: e.bankname,
                  bankname_ENG: e.bankname_ENG,
                  deduction: e.deduction,
                  empID: e.empID,
                  image: e.image,
                  employee_Code: e.employee_Code,
                  empname: e.empname,
                  netincome:e.netincome,
                  regularearning: e.regularearning,
                  salaryamount: e.salaryamount,
                  status: "In Progress"
                });
              });
            self.empInfoForaddPayroll = [...self.empInfoForaddPayroll, ...self.tempEmpInfoForaddPayroll];
            self.cleanCalculationCache();
            self.totalNetpaid_Unchange = 0;
            self.searchlist = self.paymentDueSetupData;
            await Promise.all(self.paymentDueSetupData.map(async function (e) {
              self.totalExpenses += parseFloat(e.regularearning) + parseFloat(e.addition);
              self.totalNetPaid += parseFloat(e.netincome);
              self.totalNetpaid_Unchange += parseFloat(e.netincome);
              self.totalAddition += parseFloat(e.addition);
              self.totalSalary += parseFloat(e.salaryamount);
              self.totalDeduction += parseFloat(e.deduction);
              self.totalNumberOfEmployee++;
            }));
            // Convert total expenses and total addition to strings with two decimal places
            self.totalExpenses = self.totalExpenses.toFixed(2);
            self.totalAddition = self.totalAddition.toFixed(2);
            self.totalNetPaid = self.totalNetPaid.toFixed(2);
            self.totalNetpaid_Unchange = self.totalNetpaid_Unchange.toFixed(2);
            self.totalDeduction = self.totalDeduction.toFixed(2);
            if (self.paidBy === "Transfer") 
            {
              self.totalTransfer = self.totalNetPaid;
              self.totalCash = 0;
            } 
            else 
            {
              self.totalCash = self.totalNetPaid;
              self.totalTransfer = 0;
            }
            self.unpaidAmount = self.totalNetPaid;
            self.totalNetPaid = 0;
            self.createSetUpDialog = false;
            self.staffDialog = false;
            self.editSetUpDialog = true;
            self.empList = [];
            self.firstEdit = false;
            } 
            else 
            {
              alert("Please Choose Employees with Shift");
              self.AddStaffCondition_ShiftCheck = true;
            }
        }
        else
        {
          alert("please select employee who already defined ");
          self.checkEmpListForAddStaff = [];
          self.paymentDueSetupData = [];
          self.tempPaymentDueSetupData = [];
          self.tempEmpInfoForaddPayroll = [];
        }
        //self.LogTrace(null, "AddStaff Paydue", 46, "Low");
        if(isthereerr == true)
        {
          let Message = [...new Set(theemsg)];
          alert(`${Message.join("\n")}`);
        }
        self.LoadingDialog2 = false;
        self.theuploaded = 0;
        self.theuploadlength = 0;
        self.putdecimal();
        self.putcomma();
      } 
      else 
      {
        self.staffDialog = false;
        self.addStaffBtnShow = true;
        self.editSetUpDialog = false;
        self.createSetUpDialog = true;
        self.LoadingDialog = false;
        self.editBtnShow = false;
        self.paymentDueSetupData = [];
        self.empInfoForaddPayroll = [];
      }
    },

    async PrintDataWithSocialSecurity(e, ssf, personaltax, pvd) {
      let self = this;
      let mydeductionlist = [];
      mydeductionlist.push(
        { deduction: "Social Security", amount: ssf, },
        { deduction: "Tax", amount: personaltax },
        { deduction: "PVD", amount: pvd }
      );
      if (e.deductionlist.length != 0) 
      {
        e.deductionlist.map(function (data) {
          mydeductionlist.push({
            deduction: data.deduction,
            amount: data.amount
          });
        });
      }

      self.PrintDataList.push({
        active: e.active,
        addition: e.addition,
        total_Addition: e.total_Addition,
        additionallist: e.additionallist,
        otheradditionlist: [],
        deductionlist: mydeductionlist,
        bankID: e.bankID,
        bankAccount: self.bankAccountSplit(e.bankname),
        bankname: self.bankSplit(e.bankname),
        deduction: e.deduction,
        departmentID: e.departmentID,
        department_Name: e.department_Name,
        empID: e.empID,
        empname: e.empname,
        empCode: e.employee_Code,
        late_Rate: e.late_Rate,
        netincome: e.netincome,
        personal_Tax: e.personal_Tax,
        oT_Rate: e.oT_Rate,
        oT_Rate_2: e.oT_Rate_2,
        oT_Rate_3: e.oT_Rate_3,
        oT_Rate_4: e.oT_Rate_4,
        oT_Hours: e.oT_Hours,
        oT_Hours_2: e.oT_Hours_2,
        oT_Hours_3: e.oT_Hours_3,
        oT_Hours_4: e.oT_Hours_4,
        positioinName: e.positioinName,
        positionID: e.positionID,
        regularearning: e.regularearning,
        salaryamount: e.salaryamount,
        stamp_Rate: e.stamp_Rate,
        status: e.status,
        socialsecurity: e.socialsecurityamount,
        socialSecurity: e.socialSecurity,
        social_Security_Fund: e.social_Security_Fund,
        providentFund: e.providentFund,
        provident_Fund_Amount: e.provident_Fund_Amount,
        personalTax: e.personalTax,
        workingDayorHour: e.workingDayorHour
      });
    },

    async PrintDataWithoutSocialSecurity(e, ssf, personaltax, pvd) {
      let self = this;
      let mydeductionlist = [];
      mydeductionlist.push(
        { deduction: "Social Security", amount: ssf },
        { deduction: "Tax", amount: personaltax },
        { deduction: "PVD", amount: pvd }
      );

      if (e.deductionlist.length != 0) 
      {
        e.deductionlist.map(function (data) {
          mydeductionlist.push({
            deduction: data.deduction,
            amount: data.amount
          });
        });
      }
      self.PrintDataList.push({
        active: e.active,
        addition: e.addition,
        total_Addition: e.total_Addition,
        additionallist: e.additionallist,
        otheradditionlist: [],
        deductionlist: mydeductionlist,
        bankID: e.bankID,
        bankAccount: self.bankAccountSplit(e.bankname),
        bankname: self.bankSplit(e.bankname),
        deduction: e.deduction,
        departmentID: e.departmentID,
        department_Name: e.department_Name,
        empID: e.empID,
        empCode: e.employee_Code,
        empname: e.empname,
        late_Rate: e.late_Rate,
        netincome: e.netincome,
        oT_Rate: e.oT_Rate,
        oT_Rate_2: e.oT_Rate_2,
        oT_Rate_3: e.oT_Rate_3,
        oT_Rate_4: e.oT_Rate_4,
        oT_Hours: e.oT_Hours,
        oT_Hours_2: e.oT_Hours_2,
        oT_Hours_3: e.oT_Hours_3,
        oT_Hours_4: e.oT_Hours_4,
        positioinName: e.positioinName,
        positionID: e.positionID,
        regularearning: e.regularearning,
        salaryamount: e.salaryamount,
        stamp_Rate: e.stamp_Rate,
        status: e.status,
        socialsecurity: 0,
        socialSecurity: e.socialSecurity,
        providentFund: e.providentFund,
        provident_Fund_Amount: e.provident_Fund_Amount,
        personalTax: e.personalTax,
        personal_Tax: e.personal_Tax,
        workingDayorHour: e.workingDayorHour
      });
    },

    RequestpayrollOption() {
      let self = this;
      if (self.ActiveRadio != null) 
      {
        self.optionDialog = false;
        if (self.ActiveRadio == 0) 
        {
          self.payrollDialog = true;
          self.payrollOption = "Predefined";
          self.ActiveRadioForEditPayDue = -1;
        } 
        else 
        {
          self.customDialog = true;
          self.payrollOption = "Customized";
          if (self.editPayrollID) 
          {
            self.addStaffBtnShow = false;
          }
        }

        if (self.editPayrollID) 
        {
          self.ShowPayrollforAddPayDueInEdit = false;
        } 
        else 
        {
          self.ShowPayrollforAddPayDueInEdit = true;
        }
      } 
      else 
      {
        alert("Please fill the payroll option");
      }
    },

    async Getpayrollcalculation() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}Payment_Due/Getpayment`, temp)
        .then(function (response) {
          if(response.data.status == 0)
          {
            self.paymentcalculationlist = response.data.data;
          }
        });
    },

    async savepaydue() {
      let self = this;
      if (self.actualState !== "addnewPayDueInEdit") 
      {
        if (self.chooseBank) 
        {
          if (self.payrollInfo.payroll_start_Date_notFormat) 
          {
            if (self.payrollInfo.payroll_end_Date_notFormat) 
            {
              if (self.payrollInfo.calender_income_date) 
              {
                let startDate = null;
                let endDate = null;
                let paymentDate = null;
                let myoption = "";
                if (self.payrollOption == "Customized") 
                {
                  startDate = moment(self.payrollInfo.payroll_start_Date_notFormat);
                  endDate = moment(self.payrollInfo.payroll_end_Date_notFormat);
                  paymentDate = moment(self.payrollInfo.calender_income_date);
                  self.payrollInfo.payment_date = self.payrollInfo.calender_income_date;
                  self.payrollInfo.payroll_Period_ID = 0;
                  self.payrollInfo.period_ID = 0;
                  myoption = self.regularEarningOption;
                } 
                else 
                {
                  startDate = moment(self.payrollInfo.payroll_start_Date);
                  endDate = moment(self.payrollInfo.payroll_end_Date);
                  paymentDate = moment(self.payrollInfo.calender_income_date);
                  self.payrollInfo.payment_date = self.payrollInfo.calender_income_date;
                  myoption = "";
                }

                if (paymentDate >= startDate && paymentDate <= endDate) 
                {
                  let t = JSON.parse(JSON.stringify(self.empInfoForaddPayroll));
                  for (let i = 0; i < t.length; i++) 
                  {
                    t[i].total_Income = t[i].total_Income.toString();
                    t[i].salary = parseFloat(t[i].salary);
                    t[i].total_Deduction = t[i].total_Deduction.toString();
                    t[i].oT_Rate_2 = t[i].oT_Rate_2.toString();
                    t[i].oT_Hours_2 = t[i].oT_Hours_2.toString();
                    t[i].oT_Rate_3 = t[i].oT_Rate_3.toString();
                    t[i].oT_Hours_3 = t[i].oT_Hours_3.toString();
                    t[i].oT_Rate_4 = t[i].oT_Rate_4.toString();
                    t[i].oT_Hours_4 = t[i].oT_Hours_4.toString();
                  }
                  if (t[0]) 
                  {
                    let mybankfee = 0;
                    if (self.bankfee != null && self.bankfee != "") 
                    {
                      self.bankfee = self.removecomma(self.bankfee);
                      mybankfee = parseFloat(self.bankfee);
                    } 
                    else 
                    {
                      mybankfee = 0;
                    }
                    let temp = {
                      payroll_Option: self.payrollOption,
                      regular_Earning_Option: myoption,
                      paymentDue_Number: "1",
                      paid_By: self.paidBy,
                      payroll_Period_ID: self.returnZeroIfNull(self.payrollInfo.payroll_Period_ID),
                      period_ID: self.returnZeroIfNull(self.payrollInfo.period_ID),
                      confirmPay: self.payConfirm,
                      paymentAcc_ID: parseInt(self.chooseBank),
                      period_State_Date: self.payrollInfo.payroll_start_Date_notFormat,
                      period_End_Date: self.payrollInfo.payroll_end_Date_notFormat,
                      payment_Date: self.payrollInfo.payment_date,
                      bank_Fee: mybankfee,
                      status: self.status,
                      company_ID: store.state.companyID,
                      updateEmp: store.state.employeeID,
                      requestEmpID: t,
                      employmentTypeID: self.empButton
                    };
                    self.LoadingDialog = true;
                    axios.post(`${self.url}Payment_Due/Paymentdue_Request`, temp)
                    .then(async function (response) 
                    {
                      alert(response.data.message);
                      self.editPayrollID = null;
                      if (response.data.status == 0) 
                      {
                        self.createSetUpDialog = false;
                        self.editSetUpDialog = false;
                        self.editPaymentDueId = null;
                        self.payrollTab = true;
                        self.employeeTab = false;
                        self.slipDetailTab = false;
                        await self.GetPayrollData();
                        self.cleanAllCache();
                        self.LoadingDialog = false;
                        //self.LogTrace(null, "Save New Paydue", 46, "Low");
                      }
                    })
                    .catch(function (error) {
                      self.LogTrace(error, "Save Paydue Fail", 46, "Low");
                      alert(error);
                      self.loadingdialog = false;
                    });
                  }
                  else
                  {
                    alert("Please add staff to the payroll");
                  }
                }
                else
                {
                  alert("Payment date must be between the Period start and end dates");
                }
              }
              else
              {
                alert("Please fill in payment date");
              }
            }
            else
            {
              alert("Please fill in the pay period end date");
            }
          }
          else
          {
            alert("Please fill in the pay period start date");
          }
        }
        else
        {
          alert("You must choose a payroll account");
        }
      }
      else if (this.actualState == "addnewPayDueInEdit")
      {
        this.AddingNewPayDueFromEdit();
      }
    },

    AddingNewPayDueFromEdit() {
      let self = this;
      if (self.payrollInfo.calender_income_date) 
      {
        let startDate = null;
        let endDate = null;
        let paymentDate = null;
        if (self.payrollOption == "Customized") 
        {
          startDate = moment(self.payrollInfo.payroll_start_Date_notFormat);
          endDate = moment(self.payrollInfo.payroll_end_Date_notFormat);
          paymentDate = moment(self.payrollInfo.calender_income_date);
          self.payrollInfo.payment_date = self.payrollInfo.calender_income_date;
          self.payrollInfo.payroll_Period_ID = 0;
          self.payrollInfo.period_ID = 0;
        } 
        else 
        {
          startDate = moment(self.payrollInfo.payroll_start_Date);
          endDate = moment(self.payrollInfo.payroll_end_Date);
          paymentDate = moment(self.payrollInfo.calender_income_date);
          self.payrollInfo.payment_date = self.payrollInfo.calender_income_date;
        }

        if (paymentDate >= startDate && paymentDate <= endDate) 
        {
          let t = JSON.parse(JSON.stringify(self.empInfoForaddPayroll));
          let mybankfee = 0;
          if (self.bankfee != null && self.bankfee != "") 
          {
            mybankfee = parseFloat(self.bankfee);
          } 
          else 
          {
            mybankfee = 0;
          }

          let temp = {
            payroll_Option: self.payrollOption,
            regular_Earning_Option: self.regularEarningOption,
            paymentDue_Number: "1",
            payroll_ID: self.editPayrollID,
            paid_By: self.paidBy,
            payroll_Period_ID: self.returnZeroIfNull(self.payrollInfo.payroll_Period_ID),
            period_ID: self.returnZeroIfNull(self.payrollInfo.period_ID),
            period_State_Date: self.payrollInfo.payroll_start_Date_notFormat,
            period_End_Date: self.payrollInfo.payroll_end_Date_notFormat,
            payment_Date: self.payrollInfo.payment_date,
            bank_Fee: mybankfee,
            status: self.status,
            confirmPay: self.payConfirm,
            company_ID: store.state.companyID,
            updateEmp: store.state.employeeID,
            requestEmpID: t
          };
          axios.post(`${self.url}Payment_Due/AddPaymentDueFromEdit`, temp)
          .then(async function (response) 
          {
            alert(response.data.message);
            self.editPayrollID = null;
            if (response.data.status == 0) 
            {
              self.createSetUpDialog = false;
              self.editSetUpDialog = false;
              self.LoadingDialog = true;
              await self.GetPayrollData();
              self.cleanAllCache();
              self.LoadingDialog = false;
            }
          });
        } 
        else 
        {
          alert("Payment date must be between the Period start and end dates");
        }
      } 
      else 
      {
        alert("Please fill in payment date");
      }
    },

    getpayrollaccount() {
      let self = this;
      let tempaccount = {
        paymentAcc_ID: self.chooseBank
      };
      axios.post(`${self.url}Payment_Account/GetPayrollaccount`, tempaccount)
        .then(function (response) 
        {
          if(response.data.status == 0)
          {
            self.companyBankAccount = self.thethaihead == true ? response.data.data[0].bank_Name : response.data.data[0].bank_Name_Eng + " " + response.data.data[0].account_Number;
          }
        });
    },

    async addpayment() {
      let self = this;
      this.loadingdialog = true;
      let t = JSON.parse(JSON.stringify(this.empInfoForaddPayroll));
      if (t[0]) 
      {
        if (this.payrollInfo.calender_income_date) 
        {
          let temp = {
            company_ID: store.state.companyID
          };
          await axios.post(`${self.url}Payment_Account/GetPaymentAccount`, temp)
          .then(function (response) 
          {
            self.companyBankAccount = self.thethaihead == true ? response.data.data[0].bank_Name : response.data.data[0].bank_Name_Eng + " " + response.data.data[0].account_Number;
            if (self.thepayacc.fullName != null) 
            {
              self.companyBankAccount = self.thepayacc.fullName;
            } 
            else 
            {
              self.getpayrollaccount();
            }
          });
          if (self.companyBankAccount) 
          {
            this.payrollInfo.payment_date = this.payrollInfo.calender_income_date;
            this.paymentDetailDialog = true;
            await self.removeallcomma();
            await self.putdigit();
            if (self.paidBy === "Transfer") 
            {
              this.additionOfNetPaidAndBankFee = parseFloat(self.totalTransfer) + self.bankfee;
            } 
            else 
            {
              this.additionOfNetPaidAndBankFee = this.totalCash + this.bankfee;
            }
            self.putdecimal();
            self.putcomma();
          }
        } 
        else 
        {
          alert("Please select Payment Date ");
        }
      } 
      else 
      {
        alert("Please add staff to the payroll");
      }
      this.loadingdialog = false;
    },

    editpaydue(item) {
      this.actualState = "edit";
      this.editPayrollID = item.payrollID;
      this.TempEditPayroll = item;
      let self = this;
      self.editPayrollDialog = true;
      let temp = {
        payrollID: item.payrollID,
        companyID: store.state.companyID
      };
      axios.post(`${self.url}Payment_Due/GetPayduelist`, temp)
      .then(function (response) 
      {
        if(response.data.status == 0)
        {
          self.editpaydueList = response.data.data;
        }
      });
    },

    async editpaydueDetail(item) {
      let self = this;
      self.empButton = item.empTypeID;
      self.updatedEmp = [];
      self.thelang = localStorage.getItem("Templang");
      self.LoadingDialog = true;
      self.cleanAllCache();
      self.cleanCalculationCache();
      self.actualState = "edit";
      self.editPayrollID = item.payrollID;
      self.TempEditPayroll = item;
      self.firstEdit = true;
      self.confrimPaymentData = [];
      self.paymentDue_ID = item.paymentDue_ID;
      self.editpaydueDetailList = [];
      self.editpaydueDetailList[0] = null;
      self.confirmEdit = true;
      self.EditText = true;
      self.empIdandpayId = [];
      self.forsearch = null;
      let temp = {
        paymentDue_ID: item.paymentDue_ID,
        period_ID: item.periodID,
        companyID: store.state.companyID
      };

      await axios.post(`${self.url}Payment_Due/GetPaydueDetail`, temp)
      .then(function (response) {
        if(response.data.status == 0)
        {
          self.editpaydueDetailList = JSON.parse(JSON.stringify(response.data.data));
        }
      });
      
      self.editpaydueDetailList[0].emplist.forEach(element => {
        self.empIdandpayId.push({ empID: element.employee_ID, payempID: element.paymentEMP_ID, });
      });
      if (self.editpaydueDetailList[0]) 
      {
        self.editpaydueDetailList[0].emplist.map(function (e) {
          self.checkEmpListForAddStaff.push({
            employee_Code: e.employee_Code,
            employee_ID: e.employee_ID,
            empname: e.employeeName,
            positioinName: e.positionname
          });
        });
        self.editPaymentDueId = self.editpaydueDetailList[0].paymentDue_ID;
      }
      if (self.editpaydueDetailList[0].confirmPay) 
      {
        self.EditState = true;
        self.PaidState = true;
        self.paymentDue_ID = self.editpaydueDetailList[0].paymentDue_ID;
        self.payrollInfo.payroll_Period_ID = self.editpaydueDetailList[0].payroll_Period_ID;
        self.regularEarningOption = self.editpaydueDetailList[0].regular_Earning_Option;
        self.payrollInfo.payroll_start_Date_notFormat = self.editpaydueDetailList[0].paydueStart_Date;
        self.payrollInfo.payroll_end_Date_notFormat = self.editpaydueDetailList[0].paydueEnd_Date;
        self.payrollInfo.period_ID = self.editpaydueDetailList[0].period_ID;
        self.payrollInfo.calender_income_date = self.editpaydueDetailList[0].payment_Date;
        self.bankfee = self.editpaydueDetailList[0].bank_Fee;
        self.status = "Paid";
        self.payConfirm = self.editpaydueDetailList[0].confirmPay;
        self.paymentAcc_ID = self.editpaydueDetailList[0].paymentAcc_ID;
        self.paidBy = self.editpaydueDetailList[0].paid_By;

        if (self.editpaydueDetailList) 
        {
          if (self.editpaydueDetailList[0].payroll_Option === "Customized") 
          {
            if (self.editpaydueDetailList[0].regular_Earning_Option === "month" || self.editpaydueDetailList[0].regular_Earning_Option == null) 
            {
              self.activeRadioRegularEarning = 0;
              self.regularEarningOptionNumber = 1;
            }
            else if (self.editpaydueDetailList[0].regular_Earning_Option === "day") 
            {
              self.activeRadioRegularEarning = 1;
              self.regularEarningOptionNumber = 2;
            } 
            else if (self.editpaydueDetailList[0].regular_Earning_Option === "hour") 
            {
              self.activeRadioRegularEarning = 2;
              self.regularEarningOptionNumber = 3;
            } 
            else 
            {
              self.regularEarningOptionNumber = null;
              this.regularEarningOption = null;
            }
          }
        }

        if (self.editpaydueDetailList[0].paymentAcc_ID > 0) 
        {
          if (self.editpaydueDetailList) 
          {
            self.chooseBank = self.editpaydueDetailList[0].paymentAcc_ID;
            let tetemp = 
            self.companyPaymentAccount
            .filter((pid) => pid.paymentAcc_ID == self.chooseBank);
            self.thepayacc.icon = tetemp[0].icon;
            self.thepayacc.bank_Name = tetemp[0].bank_Name;
            self.thepayacc.bankName_EN = tetemp[0].bankName_EN;
            self.thepayacc.fullName = tetemp[0].fullName;
          }
        }

        await self.calculateForConfirmPaydue();
        if (self.editpaydueDetailList[0].payroll_Option === "Customized") 
        {
          await self.wagepayroll();
          self.payrollOption = "Customized";
        }
        else 
        {
          if (self.editpaydueDetailList)
          {
            self.selectForedit.push({
              payroll_Period_ID: self.editpaydueDetailList[0].payroll_Period_ID,
              period_ID: self.editpaydueDetailList[0].period_ID
            });
          }
          self.payrollOption = "Predefined";
          await self.editpaydueDetailInfoForPaid();
        }
      }
      else 
      {
        self.EditState = true;
        self.confirmPayment = false;
        self.payConfirm = false;
        self.paymentAcc_ID = self.editpaydueDetailList[0].paymentAcc_ID;
        if (self.editpaydueDetailList) 
        {
          if (self.editpaydueDetailList[0].paid_By === "Transfer") 
          {
            self.ActiveRadioForEditPayDue = 1;
          } 
          else 
          {
            self.ActiveRadioForEditPayDue = 0;
          }
        }
        if (self.editpaydueDetailList) 
        {
          self.selectForedit.push({
            payroll_Period_ID: self.editpaydueDetailList[0].payroll_Period_ID,
            period_ID: self.editpaydueDetailList[0].period_ID
          });
        }
        if (self.editpaydueDetailList) 
        {
          if (self.editpaydueDetailList[0].payroll_Option === "Customized") 
          {
            self.confirmEditDialog = false;
            await self.wagepayroll();
            self.payrollOption = self.editpaydueDetailList[0].payroll_Option;
          } 
          else 
          {
            self.confirmEditDialog = false;
            self.payrollOption = self.editpaydueDetailList[0].payroll_Option;
            await self.editpaydueDetailInfo();
          }
        }
        if (self.editpaydueDetailList) 
        {
          if (self.editpaydueDetailList[0].payroll_Option === "Customized") 
          {
            if (self.editpaydueDetailList[0].regular_Earning_Option === "month") 
            {
              self.activeRadioRegularEarning = 0;
              self.regularEarningOptionNumber = 1;
            } 
            else if (self.editpaydueDetailList[0].regular_Earning_Option === "day") 
            {
              self.activeRadioRegularEarning = 1;
              self.regularEarningOptionNumber = 2;
            } 
            else if (self.editpaydueDetailList[0].regular_Earning_Option === "hour") 
            {
              self.activeRadioRegularEarning = 2;
              self.regularEarningOptionNumber = 3;
            } 
            else 
            {
              self.regularEarningOptionNumber = null;
              this.regularEarningOption = null;
            }
          }
        }
        if (self.editpaydueDetailList) 
        {
          self.chooseBank = self.editpaydueDetailList[0].paymentAcc_ID;
          let tetemp = 
          self.companyPaymentAccount
          .filter((pid) => pid.paymentAcc_ID == self.chooseBank);
          self.thepayacc.icon = tetemp[0].icon;
          self.thepayacc.bank_Name = tetemp[0].bank_Name;
          self.thepayacc.bankName_EN = tetemp[0].bankName_EN;
          self.thepayacc.fullName = tetemp[0].fullName;
          self.payrollInfo.calender_income_date = self.editpaydueDetailList[0].payment_Date;
          await self.calculateForConfirmPaydue();
        }
      }
      this.LoadingDialog = false;
    },

    async calculateForConfirmPaydue() {
      let self = this;
      if (self.editpaydueDetailList) 
      {
        var temp1 = self.editpaydueDetailList[0].emplist.map(function (e) {
          return {
            paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
            employeeID: e.employee_ID,
            paymenttype: self.paidBy,
            payroll_Period_ID: self.editpaydueDetailList[0].payroll_Period_ID,
            period_ID: self.editpaydueDetailList[0].period_ID,
            companyID: parseInt(store.state.companyID)
          };
        });
        let tempForNetpaid = [];
        await axios.post(`${self.url}Payment_Due/GetemplistbypaydueIDforpredefine`,temp1)
          .then(function (response) {
            if (response.data.status == 0) 
            {
              self.EditpaymentDuesetup = response.data.data;
              self.checklist = response.data.data;
              self.totalNetpaid_Unchange = 0;
              response.data.data.map(function (e, index) {
                tempForNetpaid.push({
                      No: index + 1,
                      netincome: e.netincome
                    });
                  });
                tempForNetpaid.map(async function (e) {
                  self.totalNetPaid = (parseFloat(self.totalNetPaid) + parseFloat(e.netincome)).toFixed(2);
                  self.totalNetpaid_Unchange = (parseFloat(self.totalNetpaid_Unchange) + parseFloat(e.netincome)).toFixed(2);
              });
              if (tempForNetpaid) 
              {
                if (self.editpaydueDetailList[0] != null )
                {
                  if(self.editpaydueDetailList[0].confirmPay) 
                  {
                    let amount = (parseFloat(self.totalNetPaid)).toFixed(2);
                    amount = self.numberWithCommas(amount);
                    self.disabledAddPayment = true;
                    self.showResetButton = true;
                    self.confrimPaymentData.push({
                      paymentDate: self.formatDate(self.editpaydueDetailList[0].payment_Date),
                      totalNetPaid: amount,
                      bankFee: self.editpaydueDetailList[0].bank_Fee,
                    });
                    self.confirmPayment = true;
                    self.payConfirm = true;
                  }
                }
              }
            } 
            else 
            {
              alert("please select employee who already defined");
              self.checkEmpListForAddStaff = [];
              self.paymentDueSetupData = [];
              self.tempPaymentDueSetupData = [];
              self.tempEmpInfoForaddPayroll = [];
            }
            self.putcomma();
          });
      }
    },

    async editpaydueDetailInfo() {
      this.LoadingDialog = true;
      this.confirmEdit = true;
      this.PrintData = [];
      if (this.ActiveRadioForEditPayDue === 1) 
      {
        this.paidBy = "Transfer";
      } 
      else if (this.ActiveRadioForEditPayDue === 0) 
      {
        this.paidBy = "By Cash";
      }
      //alert(this.paidBy);
      if (this.editpaydueDetailList[0]) 
      {
        this.addStaffBtnShow = false;
        this.editBtnShow = true;
      }
      else 
      {
        this.addStaffBtnShow = true;
        this.editBtnShow = false;
      }

      if (this.selectForedit[0]) 
      {
        if (this.selectForedit[0].payroll_Period_ID && this.selectForedit[0].period_ID) 
        {
          let self = this;
          let temp = {
            paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
            period_ID: self.selectForedit[0].period_ID,
            companyID: store.state.companyID,
            paidby: this.paidBy
          };
          await axios.post(`${self.url}Payment_Due/GetDueDetail`, temp)
          .then(function (response) 
          {
            if(response.data.status == 0)
            {
              self.dueDetailResultFromEdit = response.data.data;
            }
          });
          this.editPayrollDialog = false;
          this.createSetUpDialog = true;
          this.LoadingDialog = false;
          this.payrollInfo.payroll_Period_ID = this.selectForedit[0].payroll_Period_ID;
          this.payrollInfo.period_ID = this.selectForedit[0].period_ID;
          if (self.dueDetailResultFromEdit) 
          {
            this.payrollInfo.payroll_start_Date_notFormat = self.dueDetailResultFromEdit[0].paydueStart_Date;
            this.payrollInfo.payroll_end_Date_notFormat = self.dueDetailResultFromEdit[0].paydueEnd_Date;
            this.payrollInfo.payroll_start_Date = this.formatDate(self.dueDetailResultFromEdit[0].paydueStart_Date);
            this.payrollInfo.payroll_end_Date = this.formatDate(self.dueDetailResultFromEdit[0].paydueEnd_Date);
          }
          if (self.editpaydueDetailList) 
          {
            self.checkEmpListForAddStaff = [];
            if (self.editpaydueDetailList[0]) 
            {
              self.editpaydueDetailList[0].emplist.map(function (e) {
                self.checkEmpListForAddStaff.push({
                  employee_Code: e.employee_Code,
                  employee_ID: e.employee_ID,
                  empname: e.employeeName,
                  positioinName: e.positionname
                });
              });
            }
            var temp1 = self.editpaydueDetailList[0].emplist.map(function (e) {
              return {
                paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
                employeeID: e.employee_ID,
                paymenttype: self.paidBy,
                payroll_Period_ID: self.editpaydueDetailList[0].payroll_Period_ID,
                period_ID: self.editpaydueDetailList[0].period_ID,
                companyID: parseInt(store.state.companyID)
              };
            });
            axios.post(`${self.url}Payment_Due/GetemplistbypaydueIDforpredefine`,temp1)
              .then(function (response) {
                if (response.data.status == 0) 
                {
                  self.EditpaymentDuesetup = response.data.data;
                  self.checklist = response.data.data;
                  self.paymentDueSetupData = [];
                  response.data.data.map(function (e, index) {
                    self.paymentDueSetupData.push({
                      No: index + 1,
                      addition: e.addition,
                      additionallist: e.additionallist,
                      bankID: e.bankID,
                      bankAccount: self.bankAccountSplit(e.bankname),
                      bankname: self.bankSplit(e.bankname),
                      bankname_ENG: self.bankSplit(e.bankname_ENG),
                      deduction: e.deduction,
                      empID: e.empID,
                      employee_Code: e.employee_Code,
                      empname: e.empname,
                      image: e.image,
                      netincome: parseFloat(e.regularearning + e.addition) - parseFloat(e.deduction),
                      regularearning: e.regularearning,
                      salaryamount: e.salaryamount,
                      status: e.status
                    });

                    self.empInfoForaddPayroll.push({
                      employee_ID: e.empID,
                      working_Rate: e.working_Rate,
                      oT_Rate: e.oT_Rate,
                      oT_Rate_2: e.oT_Rate_2,
                      oT_Rate_3: e.oT_Rate_3,
                      oT_Rate_4: e.oT_Rate_4,
                      stamp_Rate: e.stamp_Rate,
                      late_Rate: e.late_Rate,
                      total_Income: e.addition,
                      total_Addition: e.total_Addition,
                      total_Deduction: e.deduction,
                      workingDayorHour: e.workingDayorHour,
                      late_Days: e.late_Days,
                      oT_Hours: e.oT_Hours,
                      oT_Hours_2: e.oT_Hours_2,
                      oT_Hours_3: e.oT_Hours_3,
                      oT_Hours_4: e.oT_Hours_4,
                      stamp_Days: e.stamp_Days,
                      active: true,
                      status: e.status,
                      salary: e.salaryamount,
                      deductionlist: e.deductionlist,
                      additionalEarninglist: e.additionalEarninglist,
                      personalTax: e.personalTax,
                      socialSecurity: e.socialSecurity,
                      providentFund: e.providentFund,
                      social_Security_Fund: e.social_Security_Fund,
                      other_Total_Deduction: e.other_Total_Deduction,
                      personal_Tax: e.personal_Tax,
                      provident_Fund_Amount: e.provident_Fund_Amount,
                      total_Income_To_Calculate_Tax: e.total_Income_To_Calculate_Tax,
                    });

                    if (e.socialSecurity === true) 
                    {
                      self.PrintDataWithSocialSecurity(
                        e,
                        e.social_Security_Fund,
                        e.personal_Tax,
                        e.provident_Fund_Amount
                      );
                    } 
                    else 
                    {
                      self.PrintDataWithoutSocialSecurity(
                        e,
                        e.social_Security_Fund,
                        e.personal_Tax,
                        e.provident_Fund_Amount
                      );
                    }
                  });
                  self.cleanCalculationCache();
                  self.totalNetpaid_Unchange = 0;
                  self.searchlist = self.paymentDueSetupData;
                  self.paymentDueSetupData.map(function (e) {
                    // eslint-disable-next-line no-unused-vars
                    // var getincome = self.GetIncome(parseInt(e.salaryamount));
                    self.totalExpenses = parseFloat(self.totalExpenses) + parseFloat(e.regularearning) + e.addition;
                    self.totalExpenses = parseFloat(self.totalExpenses).toFixed(2);
                    self.totalNetPaid = self.totalNetPaid + e.netincome;
                    self.totalNetpaid_Unchange = self.totalNetpaid_Unchange + e.netincome;
                    self.totalAddition = parseFloat(self.totalAddition + e.addition).toFixed(2);
                    self.totalDeduction = self.totalDeduction + e.deduction;
                    self.totalSalary = self.totalSalary + parseFloat(e.salaryamount);
                    self.totalNumberOfEmployee++;
                  });

                  if (self.paidBy === "Transfer") 
                  {
                    self.totalTransfer = self.totalNetPaid;
                    self.totalCash = 0;
                  } 
                  else 
                  {
                    self.totalCash = self.totalNetPaid;
                    self.totalTransfer = 0;
                  }

                  if (!self.payConfirm && !self.confirmPayment) 
                  {
                    self.unpaidAmount = self.totalNetPaid;
                    self.totalNetPaid = 0;
                  }
                  self.putdecimal();
                  self.putcomma();
                } 
                else 
                {
                  alert("please select employee who already defined");
                  self.checkEmpListForAddStaff = [];
                  self.paymentDueSetupData = [];
                  self.tempPaymentDueSetupData = [];
                  self.tempEmpInfoForaddPayroll = [];
                }
              });
            this.editBtnShow = true;
            this.addStaffBtnShow = false;
          }
        } 
        else 
        {
          alert("please select Payroll Period");
        }
      } 
      else 
      {
        alert("Please select both Payroll Period And Payroll Type");
      }
    },

    updatePayDueDetailInfo() {
      let self = this;
      if (self.chooseBank) 
      {
        if (self.payrollInfo.calender_income_date) 
        {
          let startDate = moment(self.payrollInfo.payroll_start_Date_notFormat);
          let endDate = moment(self.payrollInfo.payroll_end_Date_notFormat);
          let paymentDate = moment(self.payrollInfo.calender_income_date);
          self.payrollInfo.payment_date = self.payrollInfo.calender_income_date;
          let myoption = "";
          if (self.payrollOption == "Customized") 
          {
            myoption = self.regularEarningOption;
          } 
          else 
          {
            myoption = "";
          }

          if (paymentDate >= startDate && paymentDate <= endDate) 
          {
            let t = JSON.parse(JSON.stringify(self.empInfoForaddPayroll));
            for (let i = 0; i < t.length; i++) 
            {
              t[i].total_Income = t[i].total_Income.toString();
              t[i].salary = parseFloat(t[i].salary);
              t[i].total_Deduction = t[i].total_Deduction.toString();
              t[i].oT_Rate_2 = t[i].oT_Rate_2.toString();
              t[i].oT_Rate_3 = t[i].oT_Rate_3.toString();
              t[i].oT_Rate_4 = t[i].oT_Rate_4.toString();
              t[i].oT_Hours_2 = t[i].oT_Hours_2.toString();
              t[i].oT_Hours_3 = t[i].oT_Hours_3.toString();
              t[i].oT_Hours_4 = t[i].oT_Hours_4.toString();
            }

            if (t[0])
            {
              let mybankfee = 0;
              if (self.bankfee != null && self.bankfee != "") 
              {
                mybankfee = parseFloat(self.bankfee);
              } 
              else 
              {
                mybankfee = 0;
              }
              let temp = {
                paymentDue_ID: self.paymentDue_ID,
                payroll_Period_ID: self.returnZeroIfNull(self.payrollInfo.payroll_Period_ID),
                paid_By: self.paidBy,
                regular_Earning_Option: myoption,
                period_State_Date: self.payrollInfo.payroll_start_Date_notFormat,
                period_End_Date: self.payrollInfo.payroll_end_Date_notFormat,
                period_ID: self.returnZeroIfNull(self.payrollInfo.period_ID),
                payment_Date: self.payrollInfo.calender_income_date,
                bank_Fee: mybankfee,
                status: self.status,
                confirmPay: self.payConfirm,
                paymentAcc_ID: parseInt(self.chooseBank),
                company_ID: store.state.companyID,
                updateEmp: store.state.employeeID,
                requestEmpID: t
              };
              self.LoadingDialog = true;
              axios.post(`${self.url}Payment_Due/UpdatePaymentdue`, temp)
              .then(async function (response) {
                alert(response.data.message);
                if (response.data.status == 0) 
                {
                  self.createSetUpDialog = false;
                  self.editSetUpDialog = false;
                  self.editPayrollID = null;
                  self.editPaymentDueId = null;
                  await self.GetPayrollData();
                  self.LoadingDialog = false;
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Update Paydue Fail", 46, "Medium");
                alert(error);
                self.loadingdialog = false;
              });
            } 
            else 
            {
              alert("Please add staff to the payroll");
            }
          } 
          else 
          {
            alert(
              "Payment date must be between the Period start and end dates"
            );
          }
        } 
        else 
        {
          alert("Please fill in payment date");
        }
      } 
      else 
      {
        alert("You must choose a payroll account");
      }
    },

    // resetPayroll()
    // {
    //   let self = this;
    //   self.resetPayrollBtn = true;
    //   self.paymentDueConfirm = true;
    // },

    // resetUpdatePaydueDetailInfo() {
    //   let self = this;
    //   self.showResetButton = false;
    //   self.disabledAddPayment = false;
    //   self.confirmPayment = false;
    //   self.payConfirm = false;      self.unpaidAmount = self.totalNetPaid;
    //   self.totalNetPaid = 0;
    //   self.status = "In Progress";
    //   self.EditState = true;
    // },

    customCreatePayRoll() {
      let self = this;
      if (self.ActiveRadioForEditPayDue === 1) 
      {
        self.paidBy = "Transfer";
        self.payrollInfo.payroll_Period_ID = 0;
        self.payrollInfo.period_ID = 0;
        (self.customDialog = false), (self.wageDialog = true);
      } 
      else if (self.ActiveRadioForEditPayDue === 0) 
      {
        self.paidBy = "By Cash";
        self.payrollInfo.payroll_Period_ID = 0;
        self.payrollInfo.period_ID = 0;
        (self.customDialog = false), (self.wageDialog = true);
      } 
      else 
      {
        alert("Please select the payment method");
      }
    },

    async AddNewPayDueInEdit() {
      let self = this;
      this.confirmEditDialog = false;
      this.editadd = true;
      this.actualState = "addnewPayDueInEdit";
      this.ShowPayrollforAddPayDueInEdit = false;
      this.payrollInfo.payroll_start_Date = this.formatDate(this.TempEditPayroll.paydueStart_Date);
      this.payrollInfo.payroll_end_Date = this.formatDate(this.TempEditPayroll.paydueEnd_Date);
      this.payrollInfo.payroll_start_Date_notFormat = this.TempEditPayroll.paydueStart_Date;
      this.payrollInfo.payroll_end_Date_notFormat = this.TempEditPayroll.paydueEnd_Date;
      this.payroll_ID = this.TempEditPayroll.payrollID;
      
      let temp = {
        paymentDue_ID: self.TempEditPayroll.paymentDue_ID,
        companyID: store.state.companyID
      };

      let tempPayrollInfo = [];
      await axios.post(`${self.url}Payment_Due/GetPaydueDetail`, temp)
        .then(function (response) {
          tempPayrollInfo = response.data.data;
          self.payrollOption = "Customized";
          self.payrollInfo.payroll_Period_ID = response.data.data[0].payroll_Period_ID;
          self.payrollInfo.period_ID = response.data.data[0].period_ID;
        });

      if (tempPayrollInfo) 
      {
        if (tempPayrollInfo[0].payroll_Option === "Customized") 
        {
          this.wageDialog = true;
        } 
        else 
        {
          this.editSetUpDialog = true;
          this.addStaffBtnShow = true;
        }

        if (tempPayrollInfo[0].regular_Earning_Option === "month") 
        {
          this.activeRadioRegularEarning = 0;
        } 
        else if (tempPayrollInfo[0].regular_Earning_Option === "day") 
        {
          this.activeRadioRegularEarning = 1;
        } 
        else if (tempPayrollInfo[0].regular_Earning_Option === "hour") 
        {
          this.activeRadioRegularEarning = 2;
        }
      }
    },

    async showDialog() {
      let self = this;
      self.updatedEmp = [];
      self.radio = null;
      self.paymentDue_ID = 0;
      self.EditState = false;
      self.empIdandpayId = [];
      self.forsearch = null;
      self.thelang = localStorage.getItem("Templang");
      if (self.activeemp <= self.pkgemp) 
      {
        let tetemp = 
        self.companyPaymentAccount
        .filter((pid) => pid.account_Status == true);
        self.thepayacc.icon = tetemp[0].icon;
        self.thepayacc.bank_Name = tetemp[0].bank_Name;
        self.thepayacc.bankName_EN = tetemp[0].bankName_EN;
        self.thepayacc.fullName = tetemp[0].fullName;
        self.chooseBank = tetemp[0].paymentAcc_ID;
        self.optionDialog = true;
        self.empButton = 1;
        self.ActiveRadioForEditPayDue = 1;
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          2
        );
        self.creating.PayPerStartDate = firstDayOfMonth
          .toISOString()
          .substr(0, 10);
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );
        self.creating.PayPerEndDate = lastDayOfMonth
          .toISOString()
          .substr(0, 10);
      } else {
        alert(
          "Your active employees are reach limit amount and have to contact optimistic admin."
        );
      }
      self.editPaymentDueId = 0;
      await self.cleanAllCache();
      await self.cleanCalculationCache();
    },

    async confirmPay() {
      let self = this;
      self.confrimPaymentData = [];
      self.showResetButton = true;
      self.totalNetPaid = self.unpaidAmount;
      self.unpaidAmount = 0;
      self.status = "Paid";
      self.confrimPaymentData.push({
        paymentDate: self.payrollInfo.calender_income_date,
        totalNetPaid: self.totalNetPaid,
        bankFee: self.bankfee
      });

      self.paymentDetailDialog = false;
      self.confirmPayment = true;
      self.payConfirm = true;
      self.disabledAddPayment = true;

      if (self.actualState == "create" || self.actualState == "addnewPayDueInEdit") 
      {
        self.createSetUpDialog = false;
        self.editSetUpDialog = true;
      } 
      else if (self.actualState == "edit") 
      {
        self.editSetUpDialog = false;
        self.createSetUpDialog = true;
      }
    },

    deletePaydue(payrollID) {
      let self = this;
      self.deleteDialog = true;
      self.deletePayrollId = payrollID;
    },

    async deletePayrollDetail() {
      let self = this;
      let temp = {
        payrollID: self.deletePayrollId,
        companyID: store.state.companyID
      };

      await axios.post(`${self.url}Payment_Due/DeletePayroll`, temp)
        .then(function (response) {
          alert(response.data.message);
        });
        self.deletePayrollId = null;
        self.deleteDialog = false;
        self.LoadingDialog = true;
        await self.GetPayrollData();
        self.LoadingDialog = false;
    },

    editShow() {
      let self = this;
      self.editSetUpDialog = false;
      self.createSetUpDialog = false;
      self.payrollTab = false;
      self.employeeTab = true;
      self.slipDetailTab = true;
      self.tab = "two";
    },

    downloadWithCSS1() {
      let input = this.$refs.content;
      let gg = "Payslip" + " " + this.PrintData.empname;
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape"
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 10, pdfWidth, pdfHeight);
        // const password = 'YourPasswordHere';

        pdf.save(gg);
      });
    },

    downloadPDF() {
      let self = this;
      html2pdf(this.$refs.content, {
        margin: 10,
        filename: `Payslip_${self.PrintData.empname}`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 1000,
          scale: 2,
          useCORS: true,
          letterRendering: false
        },
        jsPDF: { unit: "mm", format: "letter", orientation: "landscape" }
      });
    },

    decimalforprint() {
      let self = this;
      self.PrintRevenueList.map(function (data) {
        data.value = parseFloat(data.value).toFixed(2);
      });
      self.PrintDeductionList.map(function (data) {
        data.value = (parseFloat(data.value)).toFixed(2);
      });
      self.TotalIncome_THB = (parseFloat(self.TotalIncome_THB)).toFixed(2);
      self.TotalNet_THB = (parseFloat(self.TotalNet_THB)).toFixed(2);
      self.NetIncome_THB = (parseFloat(self.NetIncome_THB)).toFixed(2);
      self.YTDIncome = (parseFloat(self.YTDIncome)).toFixed(2);
      self.YTDwithholdingtax = (parseFloat(self.YTDwithholdingtax)).toFixed(2);
      self.AccumulateSSF = (parseFloat(self.AccumulateSSF)).toFixed(2);
    },

    commalforprint() {
      let self = this;
      self.PrintRevenueList.map(function (data) 
      {
        data.value = self.numberWithCommas(data.value);
      });
      self.PrintDeductionList.map(function (data) 
      {
        data.value = self.numberWithCommas(data.value);
      });
      self.TotalIncome_THB = self.numberWithCommas(self.TotalIncome_THB);
      self.TotalNet_THB = self.numberWithCommas(self.TotalNet_THB);
      self.NetIncome_THB = self.numberWithCommas(self.NetIncome_THB);
      self.YTDIncome = self.numberWithCommas(self.YTDIncome);
      self.YTDwithholdingtax = self.numberWithCommas(self.YTDwithholdingtax);
      self.AccumulateSSF = self.numberWithCommas(self.AccumulateSSF);
    },

    decimalformultipleprint() {
      let self = this;
      self.Revenuelistforprint.map(function (data) {
        data.value = (parseFloat(data.value)).toFixed(2);
      });
      self.Deductionlistforprint.map(function (data) {
        data.value = (parseFloat(data.value)).toFixed(2);
      });
      self.totalincomeforprint = (parseFloat(self.totalincomeforprint)).toFixed(2);
      self.totaldeductionforprint = (parseFloat(self.totaldeductionforprint)).toFixed(2);
      self.netincomeforprint = (parseFloat(self.netincomeforprint)).toFixed(2);
      self.Printempdetail.YTDincome = (parseFloat(self.Printempdetail.YTDincome)).toFixed(2);
      self.Printempdetail.YTDwithholdingtax = (parseFloat(self.Printempdetail.YTDwithholdingtax)).toFixed(2);
      self.Printempdetail.AccumulateSSF = (parseFloat(self.Printempdetail.AccumulateSSF)).toFixed(2);
    },

    commalformultipleprint() {
      let self = this;
      self.Revenuelistforprint.map(function (data) {
        data.value = self.numberWithCommas(data.value);
      });
      self.Deductionlistforprint.map(function (data) {
        data.value = self.numberWithCommas(data.value);
      });
      self.totalincomeforprint = self.numberWithCommas(self.totalincomeforprint);
      self.totaldeductionforprint = self.numberWithCommas(self.totaldeductionforprint);
      self.netincomeforprint = self.numberWithCommas(self.netincomeforprint);
      self.Printempdetail.YTDincome = self.numberWithCommas(self.Printempdetail.YTDincome);
      self.Printempdetail.YTDwithholdingtax = self.numberWithCommas(self.Printempdetail.YTDwithholdingtax);
      self.Printempdetail.AccumulateSSF = self.numberWithCommas(self.Printempdetail.AccumulateSSF);
    },

    removecommalforprint() {
      let self = this;
      self.PrintRevenueList.map(function (data) 
      {
        if (typeof data.value === "string") 
        {
          data.value = self.removecomma(data.value.toString());
        }
      });

      self.PrintDeductionList.map(function (data) 
      {
        if (typeof data.value === "string") 
        {
          data.value = self.removecomma(data.value.toString());
        }
      });

      self.TotalIncome_THB = self.removecomma(self.TotalIncome_THB.toString());
      self.TotalNet_THB = self.removecomma(self.TotalNet_THB.toString());
      self.NetIncome_THB = self.removecomma(self.NetIncome_THB.toString());
      self.YTDIncome = self.removecomma(self.YTDIncome.toString());
      self.YTDwithholdingtax = self.removecomma(self.YTDwithholdingtax.toString());
      self.AccumulateSSF = self.removecomma(self.AccumulateSSF.toString());
    },

    async printDilalog(empID) {
      let self = this;
      if (self.editpaydueDetailList[0] && self.PrintDeductionCount < 1) 
      {
        self.printDilalogForEdit(empID);
      } 
      else 
      {
        self.LoadingDialog = true;
        self.PrintRevenueList = [];
        self.PrintDeductionList = [];
        // if (self.PrintDeductionCount < 1) 
        // {
        //   self.PrintDeductionList = [];
        // }
        self.TotalIncome_THB = 0;
        self.TotalNet_THB = 0;
        self.NetIncome_THB = 0;
        let temp = {
          company_ID: store.state.companyID
        };

        await axios.post(`${self.url}Company/GetCompanyID`, temp)
          .then(function (response) 
          {
            self.companyName = response.data.data.companyName;
          });

        self.LoadingDialog = false;
        if (self.payrollInfo.calender_income_date) 
        {
          let startDate = null;
          let endDate = null;
          let paymentDate = moment(self.payrollInfo.calender_income_date);
          self.payrollInfo.payment_date = self.payrollInfo.calender_income_date;
          if (self.payrollOption == "Customized") 
          {
            startDate = moment(self.payrollInfo.payroll_start_Date_notFormat);
            endDate = moment(self.payrollInfo.payroll_end_Date_notFormat);
            self.payrollInfo.payroll_Period_ID = 0;
            self.payrollInfo.period_ID = 0;
          } 
          else 
          {
            startDate = moment(self.payrollInfo.payroll_start_Date);
            endDate = moment(self.payrollInfo.payroll_end_Date);
          }

          if (paymentDate >= startDate && paymentDate <= endDate) 
          {
            self.TotalIncome_THB = 0;
            self.TotalNet_THB = 0;
            self.NetIncome_THB = 0;
            self.PrintData = null;
            let temp = [];
            await self.printYTDforprint(empID);
            self.PrintDataList.map(function (e) {
            if (e.empID == empID) 
            {
              temp.push({
                active: e.active,
                addition: e.addition,
                additionallist: e.additionallist,
                otheradditionlist: e.otheradditionlist,
                deductionlist: e.deductionlist,
                bankID: e.bankID,
                bankAccount: self.bankAccountSplit(e.bankAccount),
                bankname: self.bankSplit(e.bankname),
                deduction: e.deduction,
                departmentID: e.departmentID,
                department_Name: e.department_Name,
                empID: e.empID,
                empname: e.empname,
                empCode: e.empCode,
                late_Rate: e.late_Rate,
                netincome: e.netincome,
                oT_Rate: e.oT_Rate,
                oT_Rate_2: e.oT_Rate_2,
                oT_Rate_3: e.oT_Rate_3,
                oT_Rate_4: e.oT_Rate_4,
                positioinName: e.positioinName,
                positionID: e.positionID,
                regularearning: e.regularearning,
                salaryamount: e.salaryamount,
                stamp_Rate: e.stamp_Rate,
                status: e.status,
                personalTax: e.personalTax,
                bonus: 0,
                position_fee: 0,
                Training_fee: 0,
                Socail_Security: 0,
                Tax: 0,
                Loan: 0,
                PVD: 0,
                oT_Hours: e.oT_Hours,
                oT_Hours_2: e.oT_Hours_2,
                oT_Hours_3: e.oT_Hours_3,
                oT_Hours_4: e.oT_Hours_4,
                workingDayorHour: e.workingDayorHour
              });
            }
            });

            if (temp) 
            {
              self.PrintData = temp[0];
            }
            if (self.PrintData) 
            {
              self.PrintData.deductionlist.map(function (e) {
                self.PrintDeductionList.push({
                  name: e.deduction,
                  value: e.amount
                });
              });
            }

            if (self.PrintData) 
            {
              let salary = 0;
              if(self.empButton == 1){
                salary = self.PrintData.salaryamount;
              } else {
                salary = self.PrintData.salaryamount * parseInt(self.PrintData.workingDayorHour);
              }
              self.PrintRevenueList.push({
                name: "Salary",
                value: salary
              });

              {
                self.PrintRevenueList.push({
                  name: "OT",
                  value: parseFloat(self.PrintData.oT_Rate * self.PrintData.oT_Hours) + parseFloat(self.PrintData.oT_Rate_2 * self.PrintData.oT_Hours_2) + (self.PrintData.oT_Rate_3 * self.PrintData.oT_Hours_3) + (self.PrintData.oT_Rate_4 * self.PrintData.oT_Hours_4),
                });
              }

              self.PrintData.additionallist.map(function (data) 
              {
                if (data.name) 
                {
                  self.PrintRevenueList.push({
                    name_Eng: data.name_Eng,
                    name: data.name,
                    value: data.amount
                  });
                }
              });

              self.PrintData.otheradditionlist.map(function (data) 
              {
                if (data.name) 
                {
                  self.PrintRevenueList.push({
                    name_Eng: data.name_Eng,
                    name: data.name,
                    value: data.amount
                  });
                }
              });
            }

            self.removecommalforprint();
            self.TotalIncome_THB = 0;
            self.NetIncome_THB = 0;
            self.TotalNet_THB = 0;
            if (self.PrintRevenueList) 
            {
              self.PrintRevenueList.map(function (data) {
                let a = parseFloat(data.value);
                self.TotalIncome_THB = parseFloat(self.TotalIncome_THB);
                self.TotalIncome_THB = self.TotalIncome_THB + a;
              });
              self.PrintDeductionList.map(function (data) {
                self.TotalNet_THB = parseFloat(self.TotalNet_THB) + parseFloat(data.value);
              });
            }
            if (self.TotalIncome_THB && self.TotalNet_THB) 
            {
              self.NetIncome_THB = parseFloat(self.TotalIncome_THB) - parseFloat(self.TotalNet_THB);
            }
            self.decimalforprint();
            self.commalforprint();
            self.printdialogforupdate = true;
            //YTD Income For pdf
            let currenteditemp = self.empInfoForaddPayroll.find(x => x.employee_ID == empID);
            // let OTTotal = currenteditemp.oT_Rate * currenteditemp.oT_Hours +
            //   parseFloat(currenteditemp.oT_Rate_2) * parseFloat(currenteditemp.oT_Hours_2) +
            //   parseFloat(currenteditemp.oT_Rate_3) * parseFloat(currenteditemp.oT_Hours_3) +
            //   parseFloat(currenteditemp.oT_Rate_4) * parseFloat(currenteditemp.oT_Hours_4);
            let myregular = 0; 
            if(self.empButton == 2){
              myregular = parseFloat(currenteditemp.salary)*parseFloat(currenteditemp.workingDayorHour);
            } else {
              myregular = parseFloat(currenteditemp.salary);
            }
            self.YTDIncome = (parseFloat(self.YTDIncome.replaceAll(",", "")) + parseFloat(currenteditemp.total_Income) + myregular).toFixed(2);
            self.YTDwithholdingtax = currenteditemp.personal_Tax == 0 ? self.YTDwithholdingtax : (parseFloat(self.YTDwithholdingtax.replaceAll(",", "")) + currenteditemp.personal_Tax).toFixed(2).toString();
            self.AccumulateSSF = currenteditemp.social_Security_Fund == 0 ? self.AccumulateSSF : (parseFloat(self.AccumulateSSF.replaceAll(",", "")) + currenteditemp.social_Security_Fund).toFixed(2).toString();
            self.YTDIncome = self.numberWithCommas(self.YTDIncome);
            self.AccumulateSSF = self.numberWithCommas(self.AccumulateSSF);
            self.YTDwithholdingtax = self.numberWithCommas(self.YTDwithholdingtax);
            self.Getpdfobj();
          } 
          else 
          {
            alert(
              "Payment date must be between the Period start and end dates"
            );
          }
        } 
        else 
        {
          alert("Please fill in payment date");
        }
      }
    },

    async printDilalogForEdit(empID) {
      let self = this;
      self.LoadingDialog = true;
      self.PrintRevenueList = [];
      self.PrintDeductionList = [];
      self.PrintData = null;
      if (self.PrintDeductionCount < 1) 
      {
        self.PrintDeductionList = [];
      }
      self.TotalIncome_THB = 0;
      self.TotalNet_THB = 0;
      self.NetIncome_THB = 0;

      let temp = {
        company_ID: store.state.companyID
      };

      await axios.post(`${self.url}Company/GetCompanyID`, temp)
        .then(function (response) 
        {
          self.companyName = response.data.data.companyName;
        });

      if (this.payrollInfo.calender_income_date) 
      {
        let startDate = null;
        let endDate = null;
        let paymentDate = null;
        if (this.payrollOption == "Customized") 
        {
          startDate = moment(this.payrollInfo.payroll_start_Date_notFormat);
          endDate = moment(this.payrollInfo.payroll_end_Date_notFormat);
          paymentDate = moment(this.payrollInfo.calender_income_date);
          this.payrollInfo.payment_date = this.payrollInfo.calender_income_date;
          this.payrollInfo.payroll_Period_ID = 0;
          this.payrollInfo.period_ID = 0;
        } 
        else 
        {
          startDate = moment(this.payrollInfo.payroll_start_Date);
          endDate = moment(this.payrollInfo.payroll_end_Date);
          paymentDate = moment(this.payrollInfo.calender_income_date);
          this.payrollInfo.payment_date = this.payrollInfo.calender_income_date;
        }

        if (paymentDate >= startDate && paymentDate <= endDate) 
        {
          let self = this;
          self.TotalIncome_THB = 0;
          self.TotalNet_THB = 0;
          self.NetIncome_THB = 0;
          self.PrintData = null;
          let temp = [];
          let lateRate = [];
          lateRate = await self.RecalculateEMPforPrint(empID);
          await self.printYTDforprint(empID);
          self.PrintDataList.map(function (e) {
          if (e.empID === empID) 
          {
              let mysoical = e.social_Security_Fund;
              temp.push({
                active: e.active,
                addition: e.addition,
                additionallist: e.additionallist,
                deductionlist: e.deductionlist,
                bankID: e.bankID,
                bankAccount: self.bankAccountSplit(e.bankAccount),
                bankname: self.bankSplit(e.bankname),
                deduction: e.deduction,
                departmentID: e.departmentID,
                department_Name: e.department_Name,
                empID: e.empID,
                empname: e.empname,
                empCode: e.empCode,
                late_Rate: e.late_Rate,
                netincome: e.netincome,
                oT_Rate: e.oT_Rate,
                oT_Rate_2: e.oT_Rate_2,
                oT_Rate_3: e.oT_Rate_3,
                oT_Rate_4: e.oT_Rate_4,
                oT_Hours: e.oT_Hours,
                oT_Hours_2: e.oT_Hours_2,
                oT_Hours_3: e.oT_Hours_3,
                oT_Hours_4: e.oT_Hours_4,
                positioinName: e.positioinName,
                positionID: e.positionID,
                regularearning: e.regularearning,
                salaryamount: e.salaryamount,
                stamp_Rate: e.stamp_Rate,
                status: e.status,
                socialsecurity: mysoical,
                socialSecurity: e.socialSecurity,
                tax: e.personal_Tax,
                providentFund: e.providentFund,
                provident_Fund_Amount: e.provident_Fund_Amount,
                personalTax: e.personalTax,
                workingDayorHour: e.workingDayorHour
              });
            }
          });

          if (temp) 
          {
            self.PrintData = temp[0];
          }

          if (self.PrintData) 
          {
            if (self.PrintData.deductionlist[0]) 
            {
              self.PrintData.deductionlist.map(function (data) {
                if (data.name !== "") 
                {
                  self.PrintDeductionList.push({
                    name: data.deduction,
                    value: data.amount
                  });
                }
              });
            }
          }

          if (self.PrintData) 
          {
            let salary = self.PrintData.salaryamount;
            if(this.empButton == 2){
              salary = lateRate[0].workingdays * lateRate[0].salary;
            }
            self.PrintRevenueList.push(
              {
                name: "Salary",
                value: salary
              },
              {
                name: "OT",
                value: parseFloat(lateRate[0].oT_Rate * lateRate[0].oT_Hours) + (lateRate[0].oT_Rate_2 * lateRate[0].oT_Hours_2) + (lateRate[0].oT_Rate_3 * lateRate[0].oT_Hours_3) + (lateRate[0].oT_Rate_4 * lateRate[0].oT_Hours_4),
              }
            );
            if (self.PrintData.additionallist[0]) 
            {
              self.PrintData.additionallist.map(function (data) {
                if (data.name) 
                {
                  self.PrintRevenueList.push({
                    name: data.name,
                    name_Eng: data.name_Eng,
                    value: data.amount
                  });
                }
              });
            }
          }

          if (lateRate[0]) 
          {
            if (lateRate[0].late_Rate > 0) 
            {
              self.PrintDeductionList.push({
                name: "Late Deduction",
                value: parseFloat(parseFloat(lateRate[0].late_Rate) * parseFloat(lateRate[0].lateDays)),
              });
            }

            if (lateRate[0].stamp_Rate > 0 && self.empButton == 1) 
            {
              self.PrintDeductionList.push({
                name: "Not Stamp Deduction",
                value: lateRate[0].stamp_Rate * lateRate[0].notStampdays
              });
            }
          }
          self.removecommalforprint();

          self.TotalIncome_THB = 0;
          self.NetIncome_THB = 0;
          self.TotalNet_THB = 0;
          for(let i = 0; i < self.PrintDeductionList.length; i++) {
            if(self.PrintDeductionList[i].name == 'Social Security' && lateRate[0].socialcheck == false)
            {
              self.PrintDeductionList[i].value = 0;
            }
            if(self.PrintDeductionList[i].name == 'Tax' && lateRate[0].personaltaxcheck == false)
            {
              self.PrintDeductionList[i].value = 0;
            }
            if(self.PrintDeductionList[i].name == 'PVD' && lateRate[0].providentcheck == false)
            {
              self.PrintDeductionList[i].value = 0;
            }
          }
          if (self.PrintRevenueList) 
          {
            self.PrintRevenueList.map(function (data) {
              self.TotalIncome_THB = self.TotalIncome_THB + parseFloat(data.value);
            });

            self.PrintDeductionList.map(function (data) {
              self.TotalNet_THB = self.TotalNet_THB + parseFloat(data.value);
            });
          }
          if (self.TotalIncome_THB && self.TotalNet_THB) 
          {
            self.NetIncome_THB = parseFloat(self.TotalIncome_THB) - parseFloat(self.TotalNet_THB);
          }
          // self.removecommalforprint();
          self.decimalforprint();
          self.commalforprint();
          self.LoadingDialog = false;
          self.printdialogforupdate = true;
          self.Getpdfobj();
          // self.printDialog = true;
        } 
        else 
        {
          alert("Payment date must be between the Period start and end dates");
        }
      } 
      else 
      {
        alert("Please fill in payment date");
      }
    },

    Getpdfobj() {
      let self = this;
      //let normalfont = "THSarabunNew";
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: [210, 139.7] // Width (210mm for A4) and custom height.
      });

      doc.addFileToVFS("THSarabunPSK.ttf", self.ThaiFontBase64());
      doc.addFileToVFS("THSarabunPSKBold.ttf", self.ThaiFontBoldBase64());
      doc.addFont("THSarabunPSKBold.ttf", "THSarabunPSKBold", "normal");
      doc.addFont("THSarabunPSK.ttf", "THSarabunPSK", "normal");
      let boldfont = "THSarabunPSKBold";
      let normalfont = "THSarabunPSK";
      doc.setFont(boldfont);
      doc.setFontSize(14);
      doc.text(13, 15, self.companyName);
      doc.text(180, 15, self.$t("Payslip"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(13, 23, self.$t("employeeid") + ": " + self.PrintData.empCode);

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, 23, self.$t("EmployeeName") + ": " + self.PrintData.empname);

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        155,
        23,
        self.$t("PaymentDate") + ": " + self.computedDateFormatted1
      );

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        13,
        30,
        self.$t("position") + ": " + self.PrintData.positioinName
      );

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        75,
        30,
        self.$t("department") + ": " + self.PrintData.department_Name
      );

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        155,
        30,
        self.$t("BankAccount") + ": " + self.PrintData.bankAccount,
        {
          maxWidth: 50
        }
      );

      // doc.setDrawColor("#313131");
      //doc.setLineWidth(0.5);
      //  doc.setFillColor('#D3D3D3');

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text(33, 45, self.$t("Income"));

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text(95, 45, self.$t("Deductions"));

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text(165, 45, self.$t("ytd"));

      doc.line(10, 47, 200, 47); //xline2

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(144, 53, self.$t("ytdincome"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(195, 53, self.YTDIncome, { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(144, 59, self.$t("ytdwitholding"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(195, 59, self.YTDwithholdingtax, { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(144, 65, self.$t("accmulatedssf"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(195, 65, self.AccumulateSSF, { align: "right" });

      let incomeheight = 53;
      self.PrintRevenueList.map(data => {
        if (data.name == "Salary") {
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(14, incomeheight, self.$t("Salary"));
        } else if (data.name == "OT") {
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(14, incomeheight, self.$t("OT"));
        } else {
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text(
            14,
            incomeheight,
            self.thethaihead == true
              ? data.name
              : data.name_Eng
                ? data.name_Eng
                : ""
          );
        }

        doc.setFont(normalfont);
        doc.setFontSize(14);
        doc.text(65, incomeheight, data.value, { align: "right" });
        incomeheight = incomeheight + 7;
      });
      let deductionheight = 53;

      self.PrintDeductionList.map(data => {
        let name = data.name;
        if (data.name == "Social Security") {
          name = self.$t("social_security");
        }
        if (data.name == "Tax") {
          name = self.$t("witholding");
        }
        if (data.name == "PVD") {
          name = self.$t("provident_fund");
        }
        doc.setFont(normalfont);
        doc.setFontSize(14);

        doc.text(75, deductionheight, name);

        doc.setFont(normalfont);
        doc.setFontSize(14);
        doc.text(135, deductionheight, data.value, { align: "right" });
        deductionheight = deductionheight + 6;
      });

      let mylineheight = 0;
      if (incomeheight > deductionheight) {
        mylineheight = incomeheight;
      } else if (deductionheight > incomeheight) {
        mylineheight = deductionheight;
      } else {
        mylineheight = incomeheight;
      }
      mylineheight = mylineheight + 6;

      doc.line(10, mylineheight, 140, mylineheight);
      //88

      //doc.setTextColor("#FFFFFF");

      doc.setFillColor("#D9D9D9");
      // doc.setLineWidth(0.6);
      doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
      doc.rect(10, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
      doc.fillStroke();

      doc.setFillColor("#D9D9D9");
      // doc.setLineWidth(0.6);
      doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
      doc.rect(70, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
      doc.fillStroke();

      mylineheight = mylineheight + 4;

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(14, mylineheight, self.$t("TotalIncome"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(65, mylineheight, self.TotalIncome_THB, { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, mylineheight, self.$t("TotalDeductions"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(135, mylineheight, self.TotalNet_THB, { align: "right" });
      doc.setLineWidth(0.2);
      let totalhorizontalline = mylineheight + 2;
      doc.line(10, totalhorizontalline, 140, totalhorizontalline);

      let firstverticalline = mylineheight + 5;
      doc.line(70, 40, 70, firstverticalline);
      let secondverticalline = mylineheight + 8;
      doc.line(140, 40, 140, secondverticalline);

      doc.setFillColor("#D9D9D9");
      // doc.setLineWidth(0.6);
      doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
      doc.rect(10, totalhorizontalline, 100, 6, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
      doc.fillStroke();
      mylineheight = mylineheight + 6;
      // alert(mylineheight);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(95, mylineheight, self.$t("netpay"));

      doc.setLineWidth(0.2);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        135,
        mylineheight,
        self.numberWithCommas(
          parseFloat(
            parseFloat(self.removecomma(self.TotalIncome_THB)) -
            parseFloat(self.removecomma(self.TotalNet_THB))
          ).toFixed(2)
        ),
        { align: "right" }
      );

      doc.rect(10, 40, 190, mylineheight - 38);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(12, mylineheight + 8, self.$t("text"), { maxWidth: 190 });
      //doc.line(10, 100, 140, 100);
      //  doc.line(10, 110, 140, 110);
      //  doc.line(40, 90, 40, 100);
      // doc.line(20, 50, 20, 50);
      doc.autoPrint();
      const pdfData = doc.output("datauristring");
      self.pdfString = pdfData;

      // return doc.save("table.pdf");
    },

    async printYTDforprint(empID) {
      let self = this;
      let payempID = 0;
      if(self.empIdandpayId.length != 0){
        payempID = self.empIdandpayId.filter(x => x.empID == empID)[0].payempID;
      }
      // else{
      //   payempID = empID;
      // }
      self.YTDIncome = 0;
      self.AccumulateSSF = 0;
      self.YTDwithholdingtax = 0;
      let temp = {
        employeeID: empID,
        companyID: store.state.companyID
      };

      await axios.post(`${self.url}Payment_Due/Getempdataforprintpdf`, temp)
        .then(function (response) {
          let printdatalist = response.data.data.result;
          if (printdatalist.length != 0) 
          {
            let Getpaymentyear = moment(self.payrollInfo.calender_income_date).year();
            let Getpaymentmonth = moment(self.payrollInfo.calender_income_date).month();
            let currentyear = moment().year();

            for (var i = Getpaymentyear; i < currentyear + 1; i++) 
            {
              if(payempID != 0){
                printdatalist.map(function (data) {
                  if (
                    moment(data.year).year() == i &&
                    moment(data.year).month() <= Getpaymentmonth &&
                    data.payEmp_ID <= payempID
                  ) {
                    self.YTDIncome = self.YTDIncome + data.income;
                    self.AccumulateSSF = self.AccumulateSSF + data.ssf;
                    self.YTDwithholdingtax = self.YTDwithholdingtax + data.personal_Tax;
                  }
                });
              } else {
                printdatalist.map(function (data) {
                  if (
                    moment(data.year).year() == i &&
                    moment(data.year).month() <= Getpaymentmonth
                  ) {
                    self.YTDIncome = self.YTDIncome + data.income;
                    self.AccumulateSSF = self.AccumulateSSF + data.ssf;
                    self.YTDwithholdingtax = self.YTDwithholdingtax + data.personal_Tax;
                  }
                });
              }
            }
          }
        });
    },

    async RecalculateEMPforPrint(empID) {
      let self = this;
      let temp = {
        employee_ID: empID,
        paymentDue_ID: self.editpaydueDetailList[0].paymentDue_ID,
        companyID: store.state.companyID,
        paymenttype: self.editpaydueDetailList[0].paidBy,
        paydueStart_Date: self.formatDate(self.payrollInfo.payroll_start_Date_notFormat),
        paydueEnd_Date: self.formatDate(self.payrollInfo.payroll_end_Date_notFormat),
      };
      let rateList = [];
      await axios.post(`${self.url}Payment_Due/GetPayslipDetail`, temp)
        .then(function (response) {
          rateList = response.data.data;
        });
      return rateList;
    },

    getempprovidentForPrint(empID, salary) {
      // alert ('getempprovidentforprint')
      let self = this;
      let temp = {
        employee_ID: empID
      };

      axios.post(`${self.url}Emp_Provident/GetEmp_ProvidentwithHiredDate`, temp)
        .then(function (response) {
          let mycumulative = response.data.data.emp_Cumulative_Startdate;
          if (mycumulative != null) 
          {
            let myhiredate = response.data.data.hiredDate;
            let currenthiredate = myhiredate.split("T")[0];
            let month = currenthiredate.split("-")[1];
            let year = currenthiredate.split("-")[0];
            let mymonth = 12 - month;
            var someDate = new Date();
            var thisyear = someDate.getFullYear();
            var currentmonth = someDate.getMonth();
            let fullyear = thisyear - year;

            if (fullyear != null) 
            {
              let thismonth = 0;
              if (fullyear == 0) 
              {
                thismonth = currentmonth + 1 - month;
                self.newmonth = thismonth;
              }
              if (fullyear > 0) 
              {
                thismonth = (fullyear - 1) * 12 + currentmonth;
                self.newmonth = thismonth + mymonth;
              }

              let checkcondi = false;
              for (let j = 0; j < self.ProvidentAlllist.length; j++) 
              {
                if (checkcondi == false) 
                {
                  if (self.ProvidentAlllist[j].lengthOfEmployment == 1) 
                  {
                    let lessyear = self.ProvidentAlllist[j].year * 12;
                    if (self.newmonth < lessyear) 
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      checkcondi = true;
                    } 
                    else 
                    {
                      self.edittemp = {};
                    }
                  }

                  if (self.ProvidentAlllist[j].lengthOfEmployment == 2) 
                  {
                    let greateryear = self.ProvidentAlllist[j].year * 12;
                    if (self.newmonth > greateryear) 
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      checkcondi = true;
                    } 
                    else 
                    {
                      self.edittemp = {};
                    }
                  }

                  if (self.ProvidentAlllist[j].lengthOfEmployment == 3) 
                  {
                    let betweenstartyear = self.ProvidentAlllist[j].year * 12;
                    let betweenendyear = self.ProvidentAlllist[j].endYear * 12;
                    if (self.newmonth > betweenstartyear && self.newmonth < betweenendyear) 
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      checkcondi = true;
                    } 
                    else 
                    {
                      self.edittemp = {};
                    }
                  }

                  if (self.ProvidentAlllist[j].lengthOfEmployment == 4) 
                  {
                    let equalyear = self.ProvidentAlllist[j].year * 12;
                    if (self.newmonth == equalyear) 
                    {
                      self.edittemp = {
                        year: self.ProvidentAlllist[j].year,
                        lengthOfEmployment: self.ProvidentAlllist[j].lengthOfEmployment,
                        company_ID: store.state.companyID,
                      };
                      checkcondi = true;
                    } 
                    else 
                    {
                      self.edittemp = {};
                    }
                  }

                  if (checkcondi == true) 
                  {
                    if (Object.keys(JSON.parse(JSON.stringify(self.edittemp))).length >= 1) 
                    {
                      axios.post(`${self.url}Providentfund/GetProvidentfundByyear`,self.edittemp)
                      .then(function (response) {
                        self.EditProvidentList = response.data.data;
                        if (salary != null && self.EditProvidentList[0].employmentContribution != null) 
                        {
                          return 1000000;
                        } 
                        else 
                        {
                          return 0;
                        }
                      });
                    }
                  }
                }
              }
            }
              //});
          }
      });
    },

    togglePositionMenu(event) {
      this.positionMenuX = event.clientX;
      this.positionMenuY = event.clientY + 30;
      this.subMenuY = this.positionMenuY + 100;
      this.subMenuX = this.positionMenuX - 380;
    },

    closeBtn() {
      this.btnshow = false;
    },

    payrollOptionDialogCancel() {
      this.ActiveRadio = 0;
      this.optionDialog = false;
    },

    createPayrollDialogCancel() {
      this.selectAll = false;
      this.payrollDialog = false;
      this.optionDialog = true;
      this.payrollInfo.selected = null;
      this.payroll_type = null;
      this.ActiveRadioForEditPayDue = -1;
    },
    
    paymentDueConfirmDialog(){
      let self = this;
      if(self.PaidState)
      {
        if(!self.resetPayrollBtn)
        {
          self.createSetUpDialog = false;
          self.editPayrollDialog = false;
        }
        else
        {
          self.paymentDueConfirm = true;
        }
        // else
        // {
        //   self.paymentDueSetupCancel();
        // }
      }
      else
      {
        self.paymentDueConfirm = true;
      }
    },

    resetPayroll()
    {
      let self = this;
      self.resetPayrollBtn = true;
      self.resetUpdatePaydueDetailInfo();
      //self.paymentDueConfirm = true;
    },

    resetUpdatePaydueDetailInfo() {
      let self = this;
      self.showResetButton = false;
      self.disabledAddPayment = false;
      self.confirmPayment = false;
      self.payConfirm = false;
      self.unpaidAmount = self.totalNetPaid;
      self.totalNetPaid = 0;
      self.status = "In Progress";
      self.EditState = true;
    },

    paymentDueSetupCancel() {
      let self = this;
      if (self.confirmEdit)
      {
        if (self.PaidState)
        {
          self.createSetUpDialog = false;
          self.editPayrollDialog = false;
          self.wageDialog = false;
          self.PaidState = false;
        }
        else
        {
          if (self.payrollOption === "Customized")
          {
            self.createSetUpDialog = false;
            self.wageDialog = false;
            self.confirmEdit = false;
          }
          else
          {
            self.createSetUpDialog = false;
            self.editPayrollDialog = false;
            self.confirmEdit = false;
          }
        }
      }
      else
      {
        if (self.payrollOption === "Customized" && self.resetPayrollBtn)
        {
          self.wageDialog = false;
          self.editPayrollDialog = false;
          self.editSetUpDialog = false;
          self.confirmEdit = false;
          self.createSetUpDialog = false;
          self.resetPayrollBtn = false;
        }
        else
        {
          self.createSetUpDialog = false;
          self.editSetUpDialog = false;
          self.confirmEdit = false;
        }
      }

      self.paymentDueSetupData = [];
      self.cleanDateCache();
      // self.chooseBank = null;
      self.checkEmpListForAddStaff = [];
      self.checkEmpListForAddStaff1 = [];
      self.tempPaymentDueSetupData = [];
      self.tempEmpInfoForaddPayroll = [];
      self.empInfoForaddPayroll = [];
      self.editpaydueDetailList = [];
      self.tab = "one";
      self.totalExpenses = 0;
      self.totalNetPaid = 0;
      self.totalNetpaid_Unchange = 0;
      self.totalAddition = 0;
      self.totalDeduction = 0;
      self.totalSalary = 0;
      self.totalNumberOfEmployee = 0;
      self.disableworkingday = true;
      self.disableOT = true;
      self.disablestampday = true;
      self.disablelateday = true;
      self.totalTransfer = 0;
      self.confirmPayment = false;
      self.payConfirm = false;
      self.PrintDataList = [];
      self.PrintData = null;
      self.addStaffCount = 0;
      self.unpaidAmount = 0;
      self.disabledAddPayment = false;
      self.paymentDueConfirm = false;
    },

    // paymentDueSetupCancel() {
    //   let self = this;
    //   if (self.confirmEdit)
    //   {
    //     if (self.payrollOption === "Customized")
    //     {
    //       if(self.resetPayrollBtn)
    //       {
    //         self.wageDialog = false;
    //         self.editPayrollDialog = false;
    //         self.editSetUpDialog = false;
    //         self.confirmEdit = false;
    //         self.createSetUpDialog = false;
    //         self.resetPayrollBtn = false;
    //       }
    //       else
    //       {
    //         self.wageDialog = false;
    //         self.editPayrollDialog = false;
    //         self.editSetUpDialog = false;
    //         self.confirmEdit = false;
    //         self.createSetUpDialog = false;
    //         self.resetPayrollBtn = false;
    //       }
    //     }
    //     else
    //     {
    //       self.createSetUpDialog = false;
    //       self.editPayrollDialog = false;
    //       self.editSetUpDialog = false;
    //       self.confirmEdit = false;
    //     }
    //   }
    //   else
    //   {
    //     self.createSetUpDialog = false;
    //     self.editSetUpDialog = false;
    //     self.paymentDueSetupData = [];
    //     self.cleanDateCache();
    //     self.chooseBank = null;
    //     self.checkEmpListForAddStaff = [];
    //     self.checkEmpListForAddStaff1 = [];
    //     self.paymentDueSetupData = [];
    //     self.tempPaymentDueSetupData = [];
    //     self.tempEmpInfoForaddPayroll = [];
    //     self.empInfoForaddPayroll = [];
    //     self.editpaydueDetailList = [];
    //     self.tab = "one";
    //     self.totalExpenses = 0;
    //     self.totalNetPaid = 0;
    //     self.totalNetpaid_Unchange = 0;
    //     self.totalAddition = 0;
    //     self.totalDeduction = 0;
    //     self.totalSalary = 0;
    //     self.totalNumberOfEmployee = 0;
    //     self.disableworkingday = true;
    //     self.disableOT = true;
    //     self.disablestampday = true;
    //     self.disablelateday = true;
    //     self.totalTransfer = 0;
    //     self.confirmPayment = false;
    //     self.payConfirm = false;
    //     self.PrintDataList = [];
    //     self.PrintData = null;
    //     self.addStaffCount = 0;
    //     self.unpaidAmount = 0;
    //     self.disabledAddPayment = false;
    //   }
    //   self.paymentDueConfirm = false;
    // },

    async editpaymentDueSetupCancel() {
      let self = this;
      if (self.confirmEdit) 
      {
        if (self.PaidState) 
        {
          self.editSetUpDialog = false;
          self.editPayrollDialog = false;
          self.wageDialog = false;
          self.PaidState = false;
        } 
        else 
        {
          if(!self.EditText)
          {
            self.optionDialog = true;
          }
          if (self.payrollOption == "Customized")
          {
            self.editSetUpDialog = false;
          } 
          else 
          {
            self.editSetUpDialog = false;
          }
        }
      } 
      else 
      {
        self.cleanDateCache();
        // self.chooseBank = null;
        if (self.payrollOption == "Customized") 
        {
          self.editSetUpDialog = false;
          self.optionDialog = true;
        } 
        else
        {
          self.editSetUpDialog = false;
          self.optionDialog = true;
        }
      }
      self.checkEmpListForAddStaff = [];
      self.checkEmpListForAddStaff1 = [];
      self.paymentDueSetupData = [];
      self.empInfoForaddPayroll = [];
      self.tab = "one";
      self.disableworkingday = true;
      self.disableOT = true;
      self.disablestampday = true;
      self.disablelateday = true;
      self.totalExpenses = 0;
      self.totalNetPaid = 0;
      self.totalNetpaid_Unchange = 0;
      self.totalAddition = 0;
      self.totalDeduction = 0;
      self.totalSalary = 0;
      self.totalNumberOfEmployee = 0;
      self.totalTransfer = 0;
      self.confirmPayment = false;
      self.payConfirm = false;
      self.PrintDataList = [];
      self.PrintData = null;
      self.addStaffCount = 0;
      self.disabledAddPayment = false;
    },

    staffDialogCancel() {
      this.staffDialog = false;
      this.checkEmpListForAddStaff = [];
      this.checkEmpListForAddStaff1 = [];
      this.selectAll = false;
      this.addStaffCount = 0;
      if (this.paymentDueSetupData.length > 0) 
      {
        this.editSetUpDialog = true;
        this.createSetUpDialog = false;
      } 
      else 
      {
        this.createSetUpDialog = true;
        this.editSetUpDialog = false;
      }
    },

    customDialogCancel() {
      if (this.editPayrollID) 
      {
        this.customDialog = false;
        this.optionDialog = false;
      } 
      else 
      {
        this.customDialog = false;
        this.optionDialog = true;
      }
      this.payrollInfo.selected = null;
      this.payroll_type = null;
      this.ActiveRadioForEditPayDue = -1;
    },

    wageDialogCancel() 
    {
      this.selectAll = false;
      if (this.editPayrollID) 
      {
        this.wageDialog = false;
        this.payrollInfo.selected = null;
        this.payroll_type = null;
        this.customDialog = true;
      } 
      else 
      {
        this.wageDialog = false;
        this.payrollInfo.selected = null;
        this.payroll_type = null;
        this.customDialog = true;
        this.activeRadioRegularEarning = -1;
      }
    },

    editPayrollDialogCancel() {
      this.editPayrollDialog = false;
      this.cleanAllCache();
    },

    cleanAllCache() {
      let self = this;
      self.paymentDueSetupData = [];
      self.empList = [];
      self.checkEmpListForAddStaff = [];
      self.checkEmpListForAddStaff1 = [];
      self.EditpaymentDuesetup = [];
      self.editpaydueDetailList = [];
      self.selectForedit = [];
      self.cleanpayslipcache();
      self.payrollInfo.calender_income_date = null;
      self.payrollInfo.selected = [];
      self.payrollInfo.payroll_start_Date = null;
      self.payrollInfo.payroll_end_Date = null;
      self.payrollInfo.payroll_start_Date_notFormat = null;
      self.payrollInfo.payroll_end_Date_notFormat = null;
      self.payrollInfo.payment_date = null;
      self.payrollInfo.calender_income_date = null;
      (self.totalNumberOfEmployee = 0), (self.status = "In Progress");
      self.bankfee = 0;
      self.selectForedit = [];
      self.paymentAcc_ID = 0;
      self.dueDetailResultFromEdit = [];
      self.actualState = null;
      self.editPayrollID = null;
      self.confirmPayment = false;
      self.payConfirm = false;
      self.confrimPaymentData = [];
      self.addStaffCount = 0;
      self.regularEarningOptionNumber = 0;
      // self.chooseBank = null;
      self.empInfoForaddPayroll = [];
      self.EditState = false;
      self.PaidState = false;
      self.confirmEdit = false;

      self.ActiveRadio = 0;
      self.addStaffBtnShow = false;
      self.EditText = false;
      self.ActiveRadioForEditPayDue = -1;
      if (self.activeRadioRegularEarning != 0) 
      {
        self.activeRadioRegularEarning = -1;
      }

      self.showResetButton = false;
      self.EditState = false;
      self.disabledAddPayment = false;
      self.PrintDataList = [];
      self.PrintRevenueList = [];
      self.PrintDeductionList = [];
      self.PrintDeductionCount = 0;
      self.totalNetpaid_Unchange = 0;
      self.regularEarningOption = null;
      self.cleanCalculationCache();
    },

    async cleanCalculationCache() {
      let self = this;
      self.unpaidAmount = 0;
      self.totalExpenses = 0;
      self.totalNetPaid = 0;
      self.totalNetpaid_Unchange = 0;
      self.totalAddition = 0;
      self.totalDeduction = 0;
      self.totalSalary = 0;
      self.totalNumberOfEmployee = 0;
      self.totalTransfer = 0;
      self.totalCash = 0;
    },

    cleanDateCache() {
      this.payrollInfo.calender_income_date = null;
      this.payrollInfo.payroll_start_Date = null;
      this.payrollInfo.payroll_end_Date = null;
      this.payrollInfo.payroll_start_Date_notFormat = null;
      this.payrollInfo.payroll_end_Date_notFormat = null;
    },

    justcheckone(id) {
      //this.payrollInfo.selected = [];
      // this.selectForedit = [];
      this.payrollInfo.selected = this.payrollInfo.selected.filter(a => a.id == id);
    },

    async getPaymentAccount() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}Payment_Account/GetPaymentAccount`, temp)
        .then(function (response) {
          let templength = response.data.data;
          self.istherepa = templength.length;
          response.data.data.map(function (data) {
            self.companyPaymentAccount.push({
              icon: data.base64BankIcon,
              account_Name: data.account_Name,
              account_Number: data.account_Number,
              bank_ID: data.bank_ID,
              bank_Name: data.bank_Name,
              bankName_EN: data.bank_Name_Eng,
              branch: data.branch,
              paymentAcc_ID: data.paymentAcc_ID,
              account_Status: data.account_Status,
              fullName: data.bank_Name + " " + data.account_Number
            });
          });
        });
    },

    ChangePaymentAccount(paymentAcc_ID) {
      this.paymentAcc_ID = paymentAcc_ID;
    },

    async downloadSSO(item) {
      let self = this;
      let thendate = new Date(item.payment_Date);
      self.themon = thendate.getMonth() + 1;
        self.LoadingDialog = true;
        let temp = {
          companyID: store.state.companyID,
          paymentDue_ID: item.paymentDue_ID
        };
        await axios.post(`${self.url}Payment_Due/GetDownloadForSSOver1`, temp)
          .then(function (response) {
            self.ver1arr = response.data.data;
            const totalpages = self.ver1arr[0].numforVer2 / 10;
            if (Number.isInteger(totalpages)) 
            {
              self.ver1.ver2pages = totalpages.toString();
            } 
            else 
            {
              self.ver1.ver2pages = Math.ceil(totalpages).toString();
            }
            self.ver1.companyName = self.ver1arr[0].companyName;
            self.ver1.telephone = self.ver1arr[0].telephone;
            self.ver1.accountNumber = self.ver1arr[0].payment_Acc;
            self.ver1.address =  self.ver1arr[0].address;
            self.ver1.contributionRate = self.ver1arr[0].contri_Rate == null ? "0.00" : self.ver1arr[0].contri_Rate;
            self.ver1.subDistrict = self.ver1arr[0].subDistrict == null ? "" : self.ver1arr[0].subDistrict;
            self.ver1.district = self.ver1arr[0].district == null ? "" : self.ver1arr[0].district;
            self.ver1.province = self.ver1arr[0].province == null ? "" : self.ver1arr[0].province;
            self.ver1.postalcode = self.ver1arr[0].postalCode.toString();
            self.ver1.paymentDate = self.ver1arr[0].payment_Date.toString();
            self.ver1.totalWages = self.ver1arr[0].total_Wages == null ? "0.00" : parseFloat(self.ver1arr[0].total_Wages.toString()).toFixed(2);
            self.ver1.totalAmount = self.ver1arr[0].total_Contri == null ? "0.00" : parseFloat(self.ver1arr[0].total_Contri.toString()).toFixed(2);
            self.ver1.totalAmount2 = self.ver1arr[0].total_Contri == null ? "0.00" : parseFloat(self.ver1arr[0].total_Contri.toString()).toFixed(2);
            let finaltemp = self.ver1arr[0].total_Contri + self.ver1arr[0].total_Contri;
            finaltemp = parseFloat(finaltemp).toFixed(2);
            self.ver1.finalTotal = finaltemp.toString();
            self.ver1.insuredPerson = self.ver1arr[0].employee_Total.toString();
            //self.ver1.ver2pages = self.thesheetnum.toString();
            // setTimeout(() => {
            //   self.getPdfCover();
            // }, "700");
            //self.LoadingDialog = false;
          })
          .catch(function (error) {
            alert(error);
            //self.LoadingDialog = false;
          });

        let temp1 = {
          companyID: store.state.companyID,
          paymentDue_ID: item.paymentDue_ID
        };
        await axios.post(`${self.url}Payment_Due/GetDownloadForSSOver2`, temp1)
          .then(function (response) {
            let List = response.data.data;
            self.ver2arr = List;
            const result = List.length / 10;
            if (Number.isInteger(result))
            {
              self.thesheetnum = result + 1;
            }
            else
            {
              self.thesheetnum = Math.ceil(result) + 1;
            }
            const groupSize = 10; // Set the desired group size
            for (let i = 0; i < List.length; i += groupSize) 
            {
              const group = List.slice(i, i + groupSize);
              self.List.push({ List: group });
            }
            setTimeout(() => {
              self.getPdfAttachment();
            }, "700");
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            alert(error);
            self.LoadingDialog = false;
          });
    },

    downloadPND(v, item) {
      let self = this;
      let thendate = new Date(item.payment_Date);
      self.themon = thendate.getMonth() + 1;
      self.theAtchempList = [];
      if (v == 1) 
      {
        self.LoadingDialog = true;
        let temp = {
          companyID: store.state.companyID,
          paymentDue_ID: item.paymentDue_ID
        };
        axios.post(`${self.url}Payment_Due/GetDownloadForPND1`, temp)
          .then(function (response) {
            let List = response.data.data;
            self.thePND.companyName = List[0].companyName;
            self.thePND.companyTaxID = List[0].company_Tax_ID;
            self.thePND.company_Address = List[0].company_Address;
            self.thePND.subDistrict = self.returnnull(List[0].subDistrict);
            self.thePND.district = self.returnnull(List[0].district);
            self.thePND.province = self.returnnull(List[0].province);
            self.thePND.postalCode = self.returnnull(List[0].postalCode);
            self.thePND.total_Income = List[0].total_Income;
            self.thePND.total_Tax = List[0].total_Tax;
            self.thePND.employee_Total = List[0].employee_Total;
            self.theFileName = self.namingFormat(item.payment_Date) + "WH1.pdf";
            if (self.thePND.companyTaxID != null) 
            {
              setTimeout(() => {
                self.getPdfCoverPND();
              }, "700");
            } 
            else 
            {
              alert(this.$t("companyinforeq"));
            }

            self.LoadingDialog = false;
          })
          .catch(function (error) {
            alert(error);
            self.LoadingDialog = false;
          });
      } 
      else 
      {
        self.LoadingDialog = true;
        let temp = {
          companyID: store.state.companyID,
          paymentDue_ID: item.paymentDue_ID
        };
        axios.post(`${self.url}Payment_Due/GetDownloadForPND1Attachment`, temp)
          .then(function (response) {
            let List = response.data.data;
            const result = List.length / 8;
            if (Number.isInteger(result))
            {
              self.thesheetnum = result;
            } 
            else 
            {
              self.thesheetnum = Math.ceil(result);
            }
            const groupSize = 8; // Set the desired group size

            for (let i = 0; i < List.length; i += groupSize) 
            {
              const group = List.slice(i, i + groupSize);
              self.theAtchempList.push({ List: group });
            }

            self.theAtch.companyTaxID = List[0].company_Tax_ID;
            self.thePND.company_Address = List[0].company_Address;
            self.thePND.subDistrict = self.returnnull(List[0].subDistrict);
            self.thePND.district = self.returnnull(List[0].district);
            self.thePND.province = self.returnnull(List[0].province);
            self.thePND.postalCode = self.returnnull(List[0].postalCode);
            self.thePND.total_Income = List[0].total_Income;
            self.thePND.total_Tax = List[0].total_Tax;
            self.thePND.employee_Total = List[0].employee_Total;
            self.theFileName =
              self.namingFormat(item.payment_Date) + "attach1.pdf";
            if (self.theAtch.companyTaxID != null) {
              setTimeout(() => {
                self.getPdfAttachmentPND();
              }, "700");
            } else {
              alert(this.$t("companyinforeq"));
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            alert(error);
            self.LoadingDialog = false;
          });
      }
    },

    returnnull(txt) {
      if (txt == null) 
      {
        return txt = "";
      } 
      else 
      {
        return txt;
      }
    },

    getPdfCoverPND() {
      let self = this;
      const doc = new jsPDF();
      const imgEle = document.createElement("img");
      doc.addFileToVFS("THSarabunNew.ttf", self.ThaiFontBase64());
      doc.addFileToVFS("THSarabunNewBold.ttf", self.ThaiFontBoldBase64());
      doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      let normalfont = "THSarabunNew";
      let boldfont = "THSarabunNewBold";
      imgEle.src = require("@/assets/images/PND1.png");
      let increment = 0;
      var xposition = 117;
      var yposition = 47;
      let recwidth = 0;

      let Xpoint = 16;
      let Ypoint = 10;

      doc.setFillColor(204, 209, 220);

      // Set the border color (if needed)
      doc.setDrawColor(0, 0, 0); // Black border (RGB format: 0, 0, 0)

      // Set the border line width (if needed)
      doc.setLineWidth(1); // You can adjust the line width as needed

      // Define the center point (X, Y) and the radius for the circular background
      const centerX = 27; // X-coordinate of the center point
      const centerY = 20; // Y-coordinate of the center point
      const radius = 10.5; // Radius of the circular background

      // Draw the circular background
      doc.ellipse(centerX, centerY, radius, radius, "F");

      const imageX = 14;
      const imageY = 10;
      const imageWidth = 26;
      const imageHeight = 20;
      const backgroundY = imageY;
      //const backgroundWidth = imageWidth * 0.8;
      const backgroundHeight = 20.7;
      const borderRadius = 1;
      // Set the fill color for the background (gray in RGB format)
      doc.setFillColor(204, 209, 220);
      // Draw the background rectangle with a left-side border radius
      //document.circle(20, 20, 10, 'S');
      doc.rect(25, 9.6, 128, backgroundHeight, "F");
      doc.setDrawColor(153, 163, 186);
      doc.roundedRect(155, backgroundY, 39, backgroundHeight, borderRadius, 1);
      doc.addImage(imgEle, "PNG", imageX, imageY, imageWidth, imageHeight);
      //     doc.setFillColor(204, 204,204,0);
      // doc.rect(10, 10, 150, 160, "F");
      doc.setTextColor("#001C64");
      // Define the text properties
      const text = "แบบยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย";
      const fontSize = 12;
      //const textColor = [0, 0, 0];
      const textX = 72;
      const textY = imageY + (imageHeight - fontSize) / 2;

      // Set the font size and text color
      doc.setFont(boldfont);
      doc.setFontSize(fontSize);
      //doc.setTextColor(textColor[0], textColor[1], textColor[2]);

      // Add the text to the PDF
      doc.text(text, textX, textY);
      doc.text("ตามมาตรา 59 แห่งประมวลรัษฎากร", 73, 20);

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text(
        "สำหรับการหักภาษี ณ ที่จ่ายตามมาตรา 50 (1) กรณีการจ่ายเงินได้พึงประเมินตามมาตรา 40 (1) (2) แห่งประมวลรัษฎากร",
        38,
        25
      );

      doc.setFont(boldfont);
      doc.setFontSize(52);
      doc.text("ภ.ง.ด.1", 157, 24);

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text("เลขประจำตัวผู้เสียภาษีอากร", 16, 35);

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text("(ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย)", 20, 39);

      //self.thePND.companyTaxID = 1234567854324;
      let companytaxcode = self.thePND.companyTaxID.toString().split("");
      xposition = 52;
      yposition = 33;
      companytaxcode = companytaxcode.map((v, i) => ({
        no: ++i,
        xposition: xposition,
        text: v,
      }));
      doc.setFont(normalfont);
      doc.setFontSize(13.5);
      increment = 0;
      companytaxcode.forEach((data) => {
        if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) {
          doc.text(
            data.xposition + 1 + increment + 2.5,
            yposition + 3.2,
            data.text
          );
          increment += 6.5;
        } else {
          doc.text(data.xposition + 1 + increment, yposition + 3.2, data.text);
          increment += 4;
        }
      });
      doc.setLineWidth(0.1);
      recwidth = 4;

      doc.rect(xposition, yposition, recwidth, 4.5);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
      recwidth = 16;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
      recwidth = 20;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
      recwidth = 8;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
      recwidth = 4;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text("ชื่อผู้มีหน้าที่หักภาษีณ ที่จ่าย", 16, 44);

      doc.setFont(normalfont);
      doc.setFontSize(12);
      doc.text("(หน่วยงาน) :", 50, 44);
      doc.text("สาขาที่ ", 90, 44);

      //1. company name here
      doc.text(self.thePND.companyName, 20, 49);
      doc.text(
        "...........................................................................................................................................",
        16,
        50
      );
      doc.setFont(boldfont);
      doc.setFontSize(12);
      //5. address here
      doc.text("ที่อยู่:", 16, 54);
      doc.text(self.thePND.company_Address, 26, 54);
      doc.setFont(normalfont);
      doc.setFontSize(12);
      //6. subdistrict and 7. district here
      doc.text(
        "ตำบล/แขวง................................................................ตำบล/เขต......................................",
        16,
        63
      );
      doc.text(self.thePND.subDistrict, 40, 62);
      doc.text(self.thePND.district, 90, 62);
      //8. province and 9. postal code here
      doc.text(
        "จังหวัด....................................................................... รหัสไปรษณีย์………………………",
        16,
        68
      );
      doc.text(self.thePND.province, 30, 67);
      doc.text(self.thePND.postalCode, 90, 67);
      //doc.setDrawColor(255, 0, 0);
      doc.setLineWidth(0);
      doc.line(115, 32, 115, 100); //vertical one
      doc.line(16, 78, 115, 78); // first
      doc.line(16, 100, 205, 100); // second
      doc.line(115, 74, 205, 74);

      const imgcheck = document.createElement("img");
      imgcheck.src = require("@/assets/images/checkicon.png");

      doc.addImage(imgcheck, "PNG", 25 + 0.4, 87, 3, 3);
      doc.rect(25, 87, 3.5, 3.5);
      doc.text("(1)ยื่นปกติ", 32, 90);
      doc.rect(58, 87, 3.5, 3.5);
      doc.text("(2) ยื่นเพิ่มเติมคร้ังที่", 65, 90);
      doc.rect(90, 83, 10, 8);
      doc.text(".......", 92, 90);

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text("เดือนที่จ่ายเงินได้พึงประเมิน", 117, 35);
      doc.text("(ให้ทำเครื่องหมาย “", 117, 40);
      doc.addImage(imgcheck, "PNG", 142 + 0.4, 37, 3, 3);
      doc.rect(142, 37, 3.5, 3.5);
      doc.text("” ลงใน “", 146, 40);
      doc.rect(158, 37, 3.5, 3.5);
      //3. thai year here
      doc.text("” หน้าชื่อเดือน) พ.ศ. ...................", 162, 40);
      let theyrtxt = (self.todayyears.getFullYear() + 543).toString();
      doc.text(theyrtxt, 189, 39);
      increment = 0;
      xposition = 117;
      yposition = 47;
      let teemonth = self.themon;
      doc.setFont(normalfont);
      [
        { text: "(1) มกราคม", type: 1 },
        { text: "(4) เมษายน", type: 4 },
        { text: "(7) กรกฎาคม ", type: 7 },
        { text: "(10) ตุลาคม", type: 10 },
      ].forEach((data) => {
        doc.rect(xposition + increment, yposition, 3.5, 3.5);
        doc.text(xposition + 5 + increment, yposition + 3, data.text);
        if (data.type == teemonth) {
          doc.addImage(
            imgcheck,
            "PNG",
            xposition + increment + 0.5,
            yposition,
            3,
            3
          );
        }
        increment += 22;
      });
      increment = 0;
      xposition = 117;
      yposition = 57;
      [
        { text: "(2) กุมภาพันธ์ ", type: 2 },
        { text: "(5) พฤษภาคม", type: 5 },
        { text: "(8) สิงหาคม", type: 8 },
        { text: "(11) พฤศจิกายน", type: 11 },
      ].forEach((data) => {
        doc.rect(xposition + increment, yposition, 3.5, 3.5);
        doc.text(xposition + 5 + increment, yposition + 3, data.text);
        if (data.type == teemonth) {
          doc.addImage(
            imgcheck,
            "PNG",
            xposition + increment + 0.5,
            yposition,
            3,
            3
          );
        }
        increment += 22;
      });
      increment = 0;
      xposition = 117;
      yposition = 67;
      [
        { text: "(3) มีนาคม", type: 3 },
        { text: "(6) มิถุนายน", type: 6 },
        { text: "(9) กันยายน", type: 9 },
        { text: "(12) ธันวาคม", type: 12 },
      ].forEach((data) => {
        doc.rect(xposition + increment, yposition, 3.5, 3.5);
        doc.text(xposition + 5 + increment, yposition + 3, data.text);
        if (data.type == teemonth) {
          doc.addImage(
            imgcheck,
            "PNG",
            xposition + increment + 0.5,
            yposition,
            3,
            3
          );
        }
        increment += 22;
      });

      //second part
      doc.setFont(normalfont);
      doc.setFontSize(12);

      doc.text(
        "มีรายละเอียดการหักเป็นรายผู้มีเงินได้ ปรากฏตาม",
        Xpoint + 10,
        Ypoint + 97
      );
      doc.text(
        "(ให้แสดงรายละเอียดในใบแนบ ภ.ง.ด.1 หรือในสื่อ",
        Xpoint + 10,
        Ypoint + 102
      );
      doc.text(
        "บันทึกในระบบคอมพิวเตอร์อย่างใดอย่างหนึ่งเท่านั้น)",
        Xpoint + 10,
        Ypoint + 107
      );

      doc.rect(Xpoint + 84, Ypoint + 94, 3.5, 3.5);
      doc.addImage(imgcheck, "PNG", Xpoint + 84 + 0.5, Ypoint + 94, 3, 3);
      doc.rect(Xpoint + 84, Ypoint + 100, 3.5, 3.5);
      doc.setFont(boldfont);
      doc.text("ใบแนบ ภ.ง.ด.1 ", Xpoint + 89, Ypoint + 97);
      doc.setFont(normalfont);
      doc.text("ที่แนบมาพร้อมน้ี :", Xpoint + 108, Ypoint + 97);
      doc.setFont(boldfont);
      doc.text("สื่อบันทึกในระบบคอมพิวเตอร์ ", Xpoint + 89, Ypoint + 103);
      doc.setFont(normalfont);
      doc.text("ที่แนบมาพร้อมน้ี :", Xpoint + 125, Ypoint + 103);
      //11.1 number of attachment
      let rr = 0;
      const result = self.thePND.employee_Total / 8;
      if (Number.isInteger(result)) {
        // If the result is already an integer, return it as is
        rr = result;
      } else {
        // If the result is a decimal, round it up to the nearest integer
        rr = Math.ceil(result);
      }
      let rt = rr.toString();
      doc.text(rt, Xpoint + 162, Ypoint + 96);
      doc.text("จำนวน ...............แผ่น", Xpoint + 149, Ypoint + 97);
      doc.text("จำนวน ...............แผ่น", Xpoint + 149, Ypoint + 103);

      doc.text(
        "(ตามหนังสือแสดงความประสงค์ฯ ทะเบียนรับเลขที่..................................................................",
        Xpoint + 84,
        Ypoint + 110
      );
      doc.text(
        "หรือตามหนังสือข้อตกลงการใช้งานฯ เลขอ้างอิงการลงทะเบียน…………………………………………)",
        Xpoint + 84,
        Ypoint + 115
      );
      doc.line(Xpoint, Ypoint + 120, 205, Ypoint + 120);
      //second part end

      //third part
      doc.setFillColor(204, 209, 220);
      doc.rect(Xpoint, Ypoint + 125, 110, 8, "F");
      doc.setFont(boldfont);
      doc.text("สรุปรายการภาษีที่นำส่ง", Xpoint + 45, Ypoint + 130);

      doc.roundedRect(Xpoint + 115, Ypoint + 125, 18, 8, 1, 1);
      doc.text("จำนวนราย", Xpoint + 118, Ypoint + 130);

      doc.roundedRect(Xpoint + 135, Ypoint + 125, 25, 8, 1, 1);
      doc.text("เงินได้ทั้งสิ้น ", Xpoint + 140, Ypoint + 130);

      doc.roundedRect(Xpoint + 163, Ypoint + 125, 25, 8, 1, 1);
      doc.text("ภาษีที่นำส่งทั้งสิ้น", Xpoint + 165, Ypoint + 130);

      doc.setFont(normalfont);
      doc.text(
        "1. เงินได้ตามมาตรา 40 (1) เงินเดือน ค่าจ้าง ฯลฯ กรณีทั่วไป",
        Xpoint,
        Ypoint + 140
      );
      //12/13/14 here
      doc.text(
        self.thePND.employee_Total.toString(),
        Xpoint + 117,
        Ypoint + 140
      );
      doc.text(
        (self.thePND.total_Tax + self.thePND.total_Income)
          .toFixed(2)
          .toString(),
        Xpoint + 140,
        Ypoint + 140
      );
      doc.text(
        self.thePND.total_Tax.toFixed(2).toString(),
        Xpoint + 165,
        Ypoint + 140
      );
      doc.text(
        "2. เงินได้ตามมาตรา 40 (1) เงินเดือน ค่าจ้าง ฯลฯ กรณีได้รับ",
        Xpoint,
        Ypoint + 147
      );
      doc.text(
        "อนุมัติจากกรมสรรพากรให้หักอัตราร้อยละ 3",
        Xpoint + 10,
        Ypoint + 154
      );
      doc.text("-", Xpoint + 40, Ypoint + 160.5);
      doc.text("-", Xpoint + 75, Ypoint + 160.5);
      doc.text(
        "(ตามหนังสือที่............................................ลงวันที่…………………………….)",
        Xpoint + 10,
        Ypoint + 161
      );
      doc.text(
        "3. เงินได้ตามมาตรา 40 (1) (2) กรณีนายจา้งจ่ายให้คร้ังเดียวเพราะเหตุออกจากงาน",
        Xpoint,
        Ypoint + 168
      );
      doc.text(
        "4. เงินได้ตามมาตรา 40 (2) กรณีผู้รับเงินได้เป็นผู้อยู่ในประเทศไทย",
        Xpoint,
        Ypoint + 175
      );
      doc.text(
        "5. เงินได้ตามมาตรา 40 (2) กรณีผู้รับเงินได้มิได้เป็นผู้อยู่ในประเทศไทย",
        Xpoint,
        Ypoint + 182
      );
      doc.text("6. รวม", Xpoint, Ypoint + 189);
      //17/18/19 here
      doc.text(
        self.thePND.employee_Total.toString(),
        Xpoint + 117,
        Ypoint + 189
      );
      doc.text(
        (self.thePND.total_Tax + self.thePND.total_Income)
          .toFixed(2)
          .toString(),
        Xpoint + 140,
        Ypoint + 189
      );
      doc.text(
        self.thePND.total_Tax.toFixed(2).toString(),
        Xpoint + 165,
        Ypoint + 189
      );
      doc.text("7. เงินเพิ่ม (ถ้ามี)", Xpoint, Ypoint + 196);
      doc.setFont(boldfont);
      doc.text(
        "8. รวมยอดภาษีที่นำส่งทั้งสิ้น และเงินเพิ่ม (6. + 7.)",
        Xpoint,
        Ypoint + 203
      );
      //20 here
      doc.text(
        self.thePND.total_Tax.toFixed(2).toString(),
        Xpoint + 165,
        Ypoint + 203
      );
      doc.line(Xpoint, Ypoint + 210, 205, Ypoint + 210);
      //third part end

      //fourth part
      const pageWidth = doc.internal.pageSize.getWidth();
      //const pageHeight = doc.internal.pageSize.getHeight();
      const centerX1 = pageWidth / 2;
      //const centerY = pageHeight / 2;
      doc.setFont(normalfont);
      doc.text(
        "ข้าพเจ้าขอรับรองว่า รายการที่แจ้งไว้ข้างต้นนี้ เป็นรายการที่ถูกต้องและครบถ้วนทุกประการ",
        centerX1,
        Ypoint + 216,
        { align: "center" }
      );

      doc.text(
        "ลงชื่อ..........................................................................ผู้จ่ายเงิน",
        centerX1,
        Ypoint + 230,
        { align: "center" }
      );
      doc.text(
        "(.........................................................................)",
        centerX1,
        Ypoint + 237,
        { align: "center" }
      );
      doc.text(
        "ตำแหน่ง........................................................................................",
        centerX1,
        Ypoint + 242,
        { align: "center" }
      );
      doc.text(
        "ยื่นวันที่...........เดือน.......................................พ.ศ. ...................",
        centerX1,
        Ypoint + 249,
        { align: "center" }
      );

      doc.line(Xpoint, Ypoint + 256, 205, Ypoint + 256);

      //end--1st border

      doc.setFont(boldfont);
      doc.setFontSize(12);
      const imgcallRD = document.createElement("img");
      imgcallRD.src = require("@/assets/images/RD.png");
      doc.text(
        Xpoint,
        280,
        "สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร	 โทร. 1161"
      );
      doc.addImage(imgcallRD, "PNG", Xpoint + 58, 276.5, 30, 4.4);
      doc.text(Xpoint + 89, 280, "โทร. 1161");

      return doc.save(self.theFileName);
    },
    
    convertBahttext(number) {
      var num = +(Math.round(Math.abs(number) + "e+2") + "e-2");
      if (num > 9999999999999.99) return "#NUM!";
      if (!num) return "ศูนย์บาทถ้วน";
      var numArray = num.toFixed(2).split(".");
      var bahtText = numArray[0] == "0" ? "" : realConvert(numArray[0]) + "บาท";
      bahtText +=
        numArray[1] == "00" ? "ถ้วน" : realConvert(numArray[1]) + "สตางค์";

      function realConvert(item) {
        var digitArray = [
          "ศูนย์",
          "หนึ่ง",
          "สอง",
          "สาม",
          "สี่",
          "ห้า",
          "หก",
          "เจ็ด",
          "แปด",
          "เก้า",
          "สิบ"
        ];
        var placeValueArray = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน"];
        var numberText = "";
        item = String(+item);

        for (var i = 1; i <= item.length; i++) {
          var digit = item.charAt(i - 1);
          var place = (item.length - i) % 6;
          if (digit != 0)
            numberText +=
              digit == 1 && place == 0 && i != 1
                ? "เอ็ด"
                : digitArray[digit] + placeValueArray[place];
          if (place == 0 && i != item.length) numberText += "ล้าน";
        }

        return numberText
          .replace(/หนึ่งสิบ/g, "สิบ")
          .replace(/สองสิบ/g, "ยี่สิบ");
      }

      return number < 0 ? "ลบ" + bahtText : bahtText;
    },
    
    getPdfCover(doc) {
      let self = this;
      doc.addFileToVFS("THSarabunPSK.ttf", self.ThaiFontBase64());
      doc.addFileToVFS("THSarabunPSKBold.ttf", self.ThaiFontBoldBase64());
      doc.addFont("THSarabunPSKBold.ttf", "THSarabunPSKBold", "normal");
      doc.addFont("THSarabunPSK.ttf", "THSarabunPSK", "normal");
      let boldfont = "THSarabunPSKBold";
      let normalfont = "THSarabunPSK";
      doc.setFont(boldfont);
      doc.setFontSize(16.5);
      doc.addImage(self.dummyImage, "JPEG", 7, 1, 26, 26);
      doc.text("แบบรายการแสดงการส่งเงินสมทบ", 120, 15);
      doc.text("สปส.1-10  (ส่วนที่ 1)", 253, 5);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      let finalTotalNum = parseFloat(self.ver1.finalTotal).toFixed(2);
      let getpaymentMonth = moment(self.ver1.paymentDate).month() + 1;
      let getpaymentYear = moment(self.ver1.paymentDate).year();
      self.ver1.thaiYear = (getpaymentYear + 543).toString();
      self.thaiMonths.forEach((month) => {
        if (month.val === getpaymentMonth) 
        {
          self.ver1.thaiMonth = month.txt;
        }
      });
      self.ver1.accountNumber = self.ver1.accountNumber.replace(/-/g, "");
      let accountnum = self.ver1.accountNumber.split("");
      if (accountnum.length > 12) 
      {
        accountnum = accountnum.slice(0, 12);
      }
      let limit = 0;
      limit = accountnum.length;
      doc.text(self.ver1.companyName, 50, 32);
      doc.text(self.ver1.address + " " + self.ver1.subDistrict + " " + self.ver1.district + " " + self.ver1.province, 50, 47);
      doc.text(self.ver1.postalcode, 34, 62);
      doc.text(self.ver1.telephone, 70, 62);
      let conRate = self.Commonsocial.percentage + " %";
      doc.text(conRate, 230, 62);
      doc.text(self.ver1.thaiMonth, 70, 75);
      doc.text(self.ver1.thaiYear, 125, 75);
      let alignX = 105;
      let alignY = 98.5;
      const textWidth1 = doc.getTextWidth(self.ver1.totalWages);
      const textWidth2 = doc.getTextWidth(self.ver1.totalAmount);
      const textWidth3 = doc.getTextWidth(self.ver1.totalAmount2);
      const textWidth4 = doc.getTextWidth(self.ver1.finalTotal);
      const textWidth5 = doc.getTextWidth(self.ver1.insuredPerson);
      alignX = 130 - textWidth1;
      doc.text(this.numberWithCommas(self.ver1.totalWages.split('.')[0]), alignX, alignY);
      alignX = 130 - textWidth2;
      doc.text(this.numberWithCommas(self.ver1.totalAmount.split('.')[0]), alignX, alignY + 6.5);
      alignX = 130 - textWidth3;
      doc.text(this.numberWithCommas(self.ver1.totalAmount2.split('.')[0]), alignX, alignY + 13);
      alignX = 130 - textWidth4;
      doc.text(this.numberWithCommas(self.ver1.finalTotal.split('.')[0]), alignX, alignY + 19.5);
      alignX = 115 - textWidth5;
      doc.text(self.ver1.insuredPerson, alignX, alignY + 32.5);
      self.ver1.currencyBaht = self.convertBahttext(finalTotalNum);
      doc.text(self.ver1.currencyBaht, 13, alignY + 26.5);
      doc.text(self.ver1.ver2pages, 95, 153);
      doc.text(self.ver1.totalWages.split('.')[1], 130, 98.5);
      doc.text(self.ver1.totalAmount.split('.')[1], 130, 105);
      doc.text(self.ver1.totalAmount2.split('.')[1], 130, 111.5);
      doc.text(self.ver1.finalTotal.split('.')[1], 130, 118);
      let xindex = 201.5;
      let yindex = 31.5;
      for (var x1 = 0; x1 < limit; x1++) {
        if (x1 == 2) 
        {
          doc.text(accountnum[x1], xindex + 5, yindex);
          xindex = xindex + 10;
        } 
        else 
        {
          doc.text(accountnum[x1], xindex, yindex);
          if (x1 == 8) 
          {
            xindex = xindex + 10;
          } 
          else 
          {
            xindex = xindex + 5;
          }
        }
      }
      var startX = 40;
      var startY = 35;
      var numDots = 50;
      var dotSpacing = 2;
      doc.text("ชื่อสถานประกอบการ", 7, startY - 3);
      //Start Blank Dash --------------------------------------------------------------------------
      for (var i = 0; i < numDots + 2; i++) 
      {
        doc.text((startX + i - 22) * dotSpacing, startY, "-");
      }
      doc.text("ชื่อสาขา ( ถ้ามี )", 7, startY + 4.5);
      for (var j = 0; j < numDots + 5; j++) 
      {
        doc.text((startX + j - 25) * dotSpacing, startY + 7.5, "-");
      }
      doc.text("ที่ตั้งสำนักงานใหญ่ / สาขา", 7, startY + 12);
      for (var k = 0; k < numDots - 2; k++) 
      {
        doc.text((startX + k - 18) * dotSpacing, startY + 15, "-");
      }
      for (var l = 0; l < numDots + 16; l++) {
        doc.text((startX - 36 + l) * dotSpacing, startY + 22.5, "-");
      }
      doc.text("รหัสไปรษณีย์", 7, startY + 26.5);
      for (var m = 0; m < numDots - 36; m++) 
      {
        doc.text((startX - 27 + m) * dotSpacing, startY + 30, "-");
      }
      doc.text("โทรศัพท์", 55, startY + 26.5);
      for (var n = 0; n < numDots - 36; n++) 
      {
        doc.text((startX - 6 + n) * dotSpacing, startY + 30, "-");
      }
      doc.text("โทรสาร", 97, startY + 26.5);
      for (var p = 0; p < numDots - 34; p++) 
      {
        doc.text((startX + 14 + p) * dotSpacing, startY + 30, "-");
      }
      //End Blank Dash -----------------------------------------------------------------------------

      //Start Rectangle Blank ----------------------------------------------------------------------
      var rectX = 200;
      var rectY = 27;
      var boxWidth = 5;
      var boxHeight = 7;
      doc.setFont(boldfont);
      doc.text("เลขที่บัญชี", 175, startY - 3);
      doc.text("ลำดับที่สาขา", 175, startY + 13);
      doc.text("อัตราเงินสมทบร้อยละ", 175, startY + 26.5);
      doc.setFontSize(31);
      doc.text("-", 211.5, 33);
      doc.text("-", 251.5, 33);
      doc.setFontSize(14);
      doc.setFont(normalfont);
      // Draw the rectangle box
      doc.rect(rectX, rectY, boxWidth, boxHeight);
      doc.rect(rectX + boxWidth, rectY, boxWidth, boxHeight);
      for (var r = 3; r < 10; r++) 
      {
        doc.rect(rectX + r * boxWidth, rectY, boxWidth, boxHeight);
      }
      doc.rect(rectX + 11 * boxWidth, rectY, boxWidth, boxHeight);
      if (limit > 10) 
      {
        limit = limit - 10;
        for (var r3 = 12; r3 < 12 + limit; r3++) 
        {
          doc.rect(rectX + r3 * boxWidth, rectY, boxWidth, boxHeight);
        }
      }
      for (var r2 = 0; r2 < 6; r2++) 
      {
        doc.rect(rectX + r2 * boxWidth, rectY + 16, boxWidth, boxHeight);
      }
      //Blank Dash Start
      for (var b = 0; b < numDots - 22; b++) {
        doc.text(rectX + 6 + b * dotSpacing, rectY + 38, "-");
      }
      //Blank Dash End

      //End Rectangle Blank ------------------------------------------------------------------------

      //Table start
      doc.setFont(normalfont);
      doc.setFontSize(14);
      for (var q = 0; q < numDots - 33; q++) 
      {
        doc.text((startX + q - 5) * dotSpacing, startY + 43, "-");
      }
      for (var v = 0; v < numDots - 37; v++) 
      {
        doc.text((startX + v + 17) * dotSpacing, startY + 43, "-");
      }
      for (var a = 0; a < numDots + 17; a++) 
      {
        doc.text((startX + a - 37) * dotSpacing, startY + 66.5, "-");
      }
      for (var c = 0; c < numDots + 17; c++) 
      {
        doc.text((startX + c - 37) * dotSpacing, startY + 73, "-");
      }
      for (var d = 0; d < numDots + 17; d++) 
      {
        doc.text((startX + d - 37) * dotSpacing, startY + 79.5, "-");
      }
      for (var e = 0; e < numDots + 17; e++) 
      {
        doc.text((startX + e - 37) * dotSpacing, startY + 86, "-");
      }
      for (var f = 0; f < numDots + 17; f++) 
      {
        doc.text((startX + f - 37) * dotSpacing, startY + 92.5, "-");
      }
      for (var f1 = 0; f1 < numDots - 34; f1++) 
      {
        doc.text((startX + f1 - 31) * dotSpacing, 175.5, "-");
      }
      for (var f2 = 0; f2 < numDots - 38; f2++) 
      {
        doc.text((startX + f2 + 3) * dotSpacing, startY + 120.5, "-");
      }
      for (var f3 = 0; f3 < numDots - 38; f3++) 
      {
        doc.text((startX + f3 + 3) * dotSpacing, startY + 127, "-");
      }
      for (var g = 0; g < numDots + 1; g++) 
      {
        doc.text((startX + g + 52) * dotSpacing, 88.5, "-");
      }
      for (var w = 0; w < numDots - 26; w++) 
      {
        doc.text((startX + w + 53) * dotSpacing, 95, "-");
      }
      for (var w1 = 0; w1 < numDots - 32; w1++) 
      {
        doc.text((startX + w1 + 81) * dotSpacing, 95, "-");
      }
      for (var s = 0; s < numDots - 3; s++) 
      {
        doc.text((startX + s + 56) * dotSpacing, 101.5, "-");
      }
      for (var t = 0; t < numDots + 6; t++) 
      {
        doc.text((startX + t + 47) * dotSpacing, 114.5, "-");
      }
      for (var u = 0; u < numDots + 6; u++) 
      {
        doc.text((startX + u + 47) * dotSpacing, 121, "-");
      }
      for (var u1 = 0; u1 < numDots; u1++) 
      {
        doc.text((startX + u1 + 52) * dotSpacing, 148, "-");
      }
      for (var u2 = 0; u2 < numDots - 4; u2++)
      {
        doc.text((startX + u2 + 56) * dotSpacing, 154.5, "-");
      }
      for (var u3 = 0; u3 < numDots + 6; u3++) 
      {
        doc.text((startX + u3 + 47) * dotSpacing, 174, "-");
      }
      for (var u4 = 0; u4 < numDots + 6; u4++) 
      {
        doc.text((startX + u4 + 47) * dotSpacing, 180.5, "-");
      }
      for (var u5 = 0; u5 < numDots - 14; u5++) 
      {
        doc.text((startX + u5 - 16.5) * dotSpacing, 182, "-");
      }
      for (var u6 = 0; u6 < numDots - 14; u6++) 
      {
        doc.text((startX + u6 - 16.5) * dotSpacing, 188.5, "-");
      }
      for (var u7 = 0; u7 < numDots - 2; u7++) 
      {
        doc.text((startX + u7 - 15) * dotSpacing, 195.5, "-");
      }
      for (var u8 = 0; u8 < numDots - 39; u8++) 
      {
        doc.text((startX + u8 - 12) * dotSpacing, 202.5, "-");
      }
      for (var u9 = 0; u9 < numDots - 34; u9++) 
      {
        doc.text((startX + u9 + 3) * dotSpacing, 202.5, "-");
      }
      for (var u0 = 0; u0 < numDots - 40; u0++) 
      {
        doc.text((startX + u0 + 23) * dotSpacing, 202.5, "-");
      }

      doc.text("1.", 7.5, 99);
      doc.text("เงินค่าจ้างทั้งสิ้น", 13, 99);
      // doc.text("-", 118, 98.5);
      // doc.text("-", 130, 98.5);
      doc.text("2.", 7.5, 105.5);
      doc.text("เงินสมทบผู้ประกันตน", 13, 105.5);
      // doc.text("-", 118, 105);
      // doc.text("-", 130, 105);
      doc.text("3.", 7.5, 112);
      doc.text("เงินสมทบนายจ้าง", 13, 112);
      // doc.text("-", 118, 111.5);
      // doc.text("-", 130, 111.5);
      doc.text("4.", 7.5, 118.5);
      doc.text("รวมเงินสมทบที่นำส่งทั้งสิ้น", 13, 118.5);
      // doc.text("-", 118, 118);
      // doc.text("-", 130, 118);
      doc.text("5.", 7.5, 131.5);
      doc.text("จำนวนผู้ประกันตนที่ส่งเงินสมทบ", 13, 131.5);
      doc.text("คน", 132, 131);
      doc.text("ข้าพเจ้าขอรับรองว่ารายการที่แจ้งไว้เป็นรายการที่ถูกต้องครบถ้วนและเป็นจริงทุกประการ",6,140.5);
      doc.text("พร้อมนี้ได้แนบ", 6, 147);
      doc.text("รายละเอียดการนำส่งเงินสมทบ", 10, 153.5);
      doc.text("จำนวน", 75, 153.5);
      doc.text("แผ่น หรือ", 110, 153.5);
      doc.text("จำนวน", 75, 160);
      doc.text("แผ่น", 110, 160);
      doc.text("แผ่นจานแม่เหล็ก", 10, 160);
      doc.text("อินเตอร์เน็ท", 10, 166.5);
      doc.text("อื่นๆ", 10, 173);
      doc.setFont(boldfont);
      doc.setFontSize(14);
      doc.text("ลงชื่อ", 38, 179.5);
      doc.text("นายจ้าง/ผู้รับมอบอำนาจ", 119, 179.5);
      doc.setFontSize(16.5);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text("(", 45, 187);
      doc.text(")", 119.5, 187);
      doc.text("ตำแหน่ง", 38, 193.5);
      doc.text("ยื่นแบบวันที่", 38, 200);
      doc.text("เดือน", 78, 200);
      doc.text("พ.ศ.", 119, 200);

      function addCheckbox(x, y, checked) {
        // Draw a rectangle for the checkbox
        doc.rect(x, y, 3, 3);

        // If checked is true, fill the rectangle
        if (checked) 
        {
          doc.setFont("zapfdingbats");
          doc.setFontSize(10);
          doc.text("4", x, y + 2.5);
          doc.setFont(normalfont);
          doc.setFontSize(14);
        }
      }
      // Add checkboxes with different states
      addCheckbox(6, 151, true); // Checked checkbox
      addCheckbox(6, 157.5, true);
      addCheckbox(6, 164, false);
      addCheckbox(6, 170.5, true);
      doc.setFont(boldfont);
      doc.setFontSize(16.5);
      //Horizontal//
      doc.line(6, 68, 139.5, 68);
      doc.text("การนำส่งเงินสมทบสำหรับค่าจ้างเดือน", 7, 75.5);
      doc.text("พ.ศ.", 105, 75.5);
      doc.text("รายการ", 39.5, 88.5);
      doc.text("จำนวนเงิน", 105, 85.5);
      doc.text("บาท", 105, 92);
      doc.text("สต.", 131, 92);
      doc.line(6, 81, 139.5, 81);
      doc.line(90, 87.5, 139.5, 87.5);
      doc.line(6, 94, 139.5, 94);
      doc.line(6, 133, 139.5, 133);
      //doc.text("รายการ",)

      //Vartical//
      doc.line(6, 68, 6, 133);
      doc.line(11, 94, 11, 133);
      doc.line(90, 81, 90, 120.2);
      doc.line(90, 126.3, 90, 133);
      doc.line(128, 126.3, 128, 133);
      doc.line(128, 81, 128, 120.2);
      doc.line(139.5, 68, 139.5, 133);
      //Table End

      //Table2 Start
      //Horizontal
      doc.line(160, 68, 290, 68);
      doc.text("สำหรับเจ้าหน้าที่สำนักงานประกันสังคม", 190, 75.5);
      doc.text("สำหรับเจ้าหน้าที่ธนาคาร", 205, 135.5);
      doc.line(160, 81, 290, 81);
      doc.line(160, 127.5, 290, 127.5);
      doc.line(160, 140.5, 290, 140.5);
      doc.line(160, 187, 290, 187);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text("ชำระเงินวันที่", 165, 85.5);
      doc.text("เงินเพิ่ม ( ถ้ามี )", 165, 92);
      doc.text("บาท", 235, 92);
      doc.text("สตางค์", 277, 92);
      doc.text("ใบเสร็จรับเงินเลขที่", 165, 98.5);
      doc.text("ลงชื่อ", 165, 111.5);
      doc.text("(", 172, 118.5);
      doc.text(")", 286, 118.5);
      doc.text("ชำระเงินวันที่", 165, 145);
      doc.text("ใบเสร็จรับเงินเลขที่", 165, 151.5);
      doc.text("ประทับตราธนาคาร", 165, 158);
      doc.text("ลงชื่อ", 165, 171);
      doc.text("(", 172, 177.5);
      doc.text(")", 286, 177.5);
      //doc.text(")",)
      //vartical
      doc.setFont(boldfont);
      doc.setFontSize(16.5);
      doc.line(160, 68, 160, 187);
      doc.line(290, 68, 290, 187);
      //Table2 End
      //doc.save("Social-Security-Report.pdf");
    },

    getPdfAttachment() {
      let self = this;
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: [297, 210], // Width (210mm for A4) and custom height.
      });
      //let theseq = 1;
      for (let i = 0; i < self.thesheetnum; i++) 
      {
        if(i == 0)
        {
          self.getPdfCover(doc);
        } 
        else 
        {
          doc.addFileToVFS("THSarabunNew.ttf", self.ThaiFontBase64());
          doc.addFileToVFS("THSarabunNewBold.ttf", self.ThaiFontBoldBase64());
          doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
          doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
          let normalfont = "THSarabunNew";
          let boldfont = "THSarabunNewBold";
          let Xpoint = 5;
          let Ypoint = 7;
          doc.setFont(boldfont);
          doc.setFontSize(22);
          doc.text("รายละเอียดการนำส่งเงินสมทบ", Xpoint, Ypoint);
          doc.setFont(normalfont);
          doc.setFontSize(14);
          doc.text("สำหรับค่าจ้างเดือน", Xpoint, Ypoint + 6);
          doc.text(".....................................", Xpoint + 25, Ypoint + 6);
          doc.text("พ.ศ.", Xpoint + 55, Ypoint + 6);
          doc.text(".......................", Xpoint + 60, Ypoint + 6);
          doc.text("สปส. 1-10  (ส่วนที่ 2)", Xpoint + 260, Ypoint);
          Xpoint = 227;
          doc.text("แผ่นที่", Xpoint - 8, Ypoint + 6);
          doc.text(".....................", Xpoint + 8, Ypoint + 6);
          doc.text("ในจำนวน", Xpoint + 25, Ypoint + 6);
          doc.text(".....................", Xpoint + 38, Ypoint + 6);
          doc.text("แผ่น", Xpoint + 55, Ypoint + 6);
          Ypoint = 14;
          doc.text("เลขที่บัญชี", Xpoint - 8, Ypoint + 6);
          Ypoint = 21;
          doc.text("ลำดับที่สาขา", Xpoint - 8, Ypoint + 6);
          Xpoint = 30;
          doc.setFont(boldfont);
          doc.text("ชื่อสถานประกอบการ", Xpoint - 1, Ypoint + 6);
          doc.setFont(normalfont);
          doc.text(".......................................................................................................................................", Xpoint + 28, Ypoint + 6);
          let xposition = 10;
          let yposition = 30;
          let increment = 0;
          let recwidth = 0;
          let companytaxcode = "";
          xposition = 230;
          yposition = 16.3;
          self.ver2arr[0].payment_Acc=self.ver2arr[0].payment_Acc.replace(/-/g, "");
          companytaxcode = self.ver2arr[0].payment_Acc.toString().split("");
          while (companytaxcode.length > 12) 
          {
            companytaxcode.pop(); // Remove elements from the end of the array
          }
          xposition = 237;
          yposition = 16.3;
          companytaxcode = companytaxcode.map((v, i) => ({
            no: ++i,
            xposition: xposition,
            text: v,
          }));
          doc.setFont(normalfont);
          doc.setFontSize(13.5);
          increment = 0;
          companytaxcode.forEach((data) => {
            if (data.no == 3 || data.no == 10) 
            {
              doc.text(
                data.xposition + 1 + increment + 2.5,
                yposition + 3.2,
                data.text
              );
              increment += 6.5;
            } 
            else 
            {
              doc.text(
                data.xposition + 1 + increment,
                yposition + 3.2,
                data.text
              );
              increment += 4;
            }
          });
          doc.setLineWidth(0.1);
          recwidth = 8;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.setLineDash([]);
          xposition = xposition + recwidth + 2.5;
          doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
          recwidth = 28;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
          doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
          doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
          doc.line(xposition + 20, yposition, xposition + 20, yposition + 4.5);
          doc.line(xposition + 24, yposition, xposition + 24, yposition + 4.5);
          doc.setLineDash([]);
          doc.rect(xposition, yposition, recwidth, 4.5);
          xposition = xposition + recwidth + 2.5;
          doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
          if (companytaxcode.length > 10) 
          {
            if (companytaxcode.length == 11) 
            {
              recwidth = 8;
              doc.rect(xposition, yposition, recwidth, 4.5);
              doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
              doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
              doc.setLineDash([]);
            }
            else if (companytaxcode.length == 12) 
            {
              recwidth = 12;
              doc.rect(xposition, yposition, recwidth, 4.5);
              doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
              doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
              doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
              doc.setLineDash([]);
            }
          }
          else 
          {
            recwidth = 4;
            doc.rect(xposition, yposition, recwidth, 4.5);
          }
          let thet = "";
          companytaxcode = thet.split("");
          xposition = 237;
          yposition = 23.5;
          companytaxcode = companytaxcode.map((v, i) => ({
            no: ++i,
            xposition: xposition,
            text: v,
          }));
          doc.setFont(normalfont);
          doc.setFontSize(13.5);
          increment = 0;
          companytaxcode.forEach((data) => {
            doc.text(
              data.xposition + 1 + increment,
              yposition + 3.2,
              data.text
            );
            increment += 4;
          });
          doc.setLineWidth(0.1);
          recwidth = 24;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
          doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
          doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
          doc.line(xposition + 20, yposition, xposition + 20, yposition + 4.5);
          doc.setLineDash([]);
          Xpoint = 5;
          Ypoint = 30;
          xposition = 247;
          yposition = 23.5;
          //hori
          doc.line(Xpoint, Ypoint, Xpoint + 287, Ypoint);
          doc.line(Xpoint, Ypoint + 5, Xpoint + 287, Ypoint + 5);
          doc.line(Xpoint, Ypoint + 20, Xpoint + 287, Ypoint + 20);
          doc.line(Xpoint, Ypoint + 100, Xpoint + 287, Ypoint + 100);
          doc.line(Xpoint + 190, Ypoint + 108, Xpoint + 287, Ypoint + 108);
          //verti
          doc.line(Xpoint, Ypoint, Xpoint, Ypoint + 100);
          doc.line(Xpoint + 25, Ypoint, Xpoint + 25, Ypoint + 100);
          doc.line(Xpoint + 110, Ypoint, Xpoint + 110, Ypoint + 100);
          doc.line(Xpoint + 190, Ypoint, Xpoint + 190, Ypoint + 108);
          doc.line(Xpoint + 235, Ypoint, Xpoint + 235, Ypoint + 108);
          doc.line(Xpoint + 287, Ypoint, Xpoint + 287, Ypoint + 108);
          //dash line
          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(Xpoint, Ypoint + 28, Xpoint + 287, Ypoint + 28);
          doc.line(Xpoint, Ypoint + 36, Xpoint + 287, Ypoint + 36);
          doc.line(Xpoint, Ypoint + 44, Xpoint + 287, Ypoint + 44);
          doc.line(Xpoint, Ypoint + 52, Xpoint + 287, Ypoint + 52);
          doc.line(Xpoint, Ypoint + 60, Xpoint + 287, Ypoint + 60);
          doc.line(Xpoint, Ypoint + 68, Xpoint + 287, Ypoint + 68);
          doc.line(Xpoint, Ypoint + 76, Xpoint + 287, Ypoint + 76);
          doc.line(Xpoint, Ypoint + 84, Xpoint + 287, Ypoint + 84);
          doc.line(Xpoint, Ypoint + 92, Xpoint + 287, Ypoint + 92);
          doc.setLineDash([]);
          Ypoint = 33.5;
          doc.setFont(boldfont);
          doc.text("1", Xpoint + 12.5, Ypoint);
          doc.text("2", Xpoint + 65, Ypoint);
          doc.text("3", Xpoint + 147.5, Ypoint);
          doc.text("4", Xpoint + 212.5, Ypoint);
          doc.text("5", Xpoint + 261, Ypoint);
          Ypoint = 40;
          doc.text("ลำดับที่", Xpoint + 9, Ypoint);
          doc.text("เลขประจำตัวประชาชน", Xpoint + 50, Ypoint);
          doc.text("คำนำหน้านาม-ชื่อ-ชื่อสกุล", Xpoint + 131, Ypoint);
          doc.text("ค่าจ้างที่จ่ายจริง", Xpoint + 204, Ypoint);
          doc.text("เงินสมทบผู้ประกันตน", Xpoint + 248, Ypoint);
          doc.text("รวม", Xpoint + 152.5, Ypoint + 95);
          doc.setFont(normalfont);
          doc.setFontSize(11);
          doc.text("(สำหรับคนต่างด้าวให้กรอกเลขที่บัตรประกันสังคม)", Xpoint + 39, Ypoint + 5);
          doc.text("(ค่าจ้างที่ใช้ในการคำนวณไม่ต่ำกว่า", Xpoint + 242.5, Ypoint + 5);
          doc.text("1,650 บาท และไม่เกิน 15,000 บาท)", Xpoint + 242.5, Ypoint + 9);
          doc.setFontSize(14);
          doc.setFont(boldfont);
          //doc.text("คำชี้แจง", Xpoint, Ypoint+110);
          //doc.line(Xpoint,Ypoint+111,Xpoint+11,Ypoint+111)
          doc.text("ลงชื่อ", Xpoint + 180, Ypoint + 110);
          doc.setFont(normalfont);
          doc.text(".................................................................................", Xpoint + 188, Ypoint + 110);
          doc.setFont(boldfont);
          doc.text("นายจ้าง/ผู้รับมอบอำนาจ", Xpoint + 253, Ypoint + 110);
          doc.setFont(normalfont);
          doc.text("ยื่นแบบวันที่", Xpoint + 180, Ypoint + 116);
          doc.text(".................", Xpoint + 196, Ypoint + 116);
          doc.text("เดือน", Xpoint + 210, Ypoint + 116);
          doc.text("........................................", Xpoint + 217, Ypoint + 116);
          doc.text("พ.ศ.", Xpoint + 250, Ypoint + 116);
          doc.text("....................................", Xpoint + 255, Ypoint + 116);
          var increment1 = 0;
          for (let j = 0; j < 10; j++) 
          {
            xposition = Xpoint + 35;
            yposition = Ypoint + increment1 + 12;
            doc.setLineWidth(0.1);
            recwidth = 4;
            doc.rect(xposition, yposition, recwidth, 4.5);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 16;
            doc.rect(xposition, yposition, recwidth, 4.5);
            doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
            doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
            doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
            doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
            doc.setLineDash([]);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 20;
            doc.rect(xposition, yposition, recwidth, 4.5);
            doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
            doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
            doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
            doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
            doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
            doc.setLineDash([]);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 8;
            doc.rect(xposition, yposition, recwidth, 4.5);
            doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
            doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
            doc.setLineDash([]);
            xposition = xposition + recwidth + 2.5;
            doc.line(
              xposition - 2.5,
              yposition + 2.5,
              xposition,
              yposition + 2.5
            );
            recwidth = 4;
            doc.rect(xposition, yposition, recwidth, 4.5);
            increment1 += 7.9;
          }
          doc.text(240, 12, (i).toString());
          doc.text(270, 12, (self.thesheetnum - 1).toString());
          let totalWages = 0.00;
          let totalContri = 0.00;
          let start = ((i - 1) * 10);
          let end = ((i) * 10);
          for (var t2 = start; t2 < end && t2 < self.ver2arr.length; t2++)
          {
            totalWages = self.ver2arr[t2].salary + totalWages;
            // let temp = self.ver2arr[t2].ssF_Amount <= self.Commonsocial.lowestSubvention ? self.Commonsocial.lowestSubvention : ((self.ver2arr[t2].ssF_Amount >= self.Commonsocial.highestSubvention) ? self.Commonsocial.highestSubvention : self.ver2arr[t2].ssF_Amount);
            let temp = self.ver2arr[t2].ssF_Amount;
            totalContri = temp + totalContri;
          }
          totalWages = parseFloat(totalWages).toFixed(2);
          totalContri = parseFloat(totalContri).toFixed(2);
          const widthWages = doc.getTextWidth(totalWages);
          const widthContri = doc.getTextWidth(totalContri);
          doc.text(this.numberWithCommas(totalWages), 234 - widthWages, Ypoint + 95);
          doc.text(this.numberWithCommas(totalContri), 287 - widthContri, Ypoint + 95);
          let date = self.ver2arr[0].payment_Date;
          let getpaymentMonth = moment(date).month() + 1;
          let getpaymentYear = moment(date).year();
          self.ver2_thaiYear = (getpaymentYear + 543).toString();
          self.thaiMonths.forEach((month) => {
            if (month.val === getpaymentMonth) 
            {
              self.ver2_thaiMonth = month.txt;
            }
          });
          doc.text(self.ver2_thaiMonth, Xpoint + 30, 12);
          doc.text(self.ver2_thaiYear, Xpoint + 65, 12);
          doc.text(self.ver2arr[0].companyName, Xpoint + 65, 26);

          let seqX = Xpoint + 12;
          let seqY = 55;
          for (var s2 = start; s2 < end && s2 < self.ver2arr.length; s2++) 
          {
            doc.text((s2+1).toString(), seqX, seqY);
            seqY = seqY + 8;
          }

          let nameX = 140;
          let nameY = 55;
          for (var v2 = start; v2 < end && v2 < self.ver2arr.length; v2++)
          {
            doc.text(self.ver2arr[v2].name + " " + self.ver2arr[v2].lastName, nameX, nameY);
            nameY = nameY + 8;
          }

          let wagesX = 236;
          let wagesY = 55;
          for (var w2 = start; w2 < end && w2 < self.ver2arr.length; w2++)
          {
            let temp = parseFloat(self.ver2arr[w2].salary).toFixed(2);
            let empWage = this.numberWithCommas(temp);
            const widthSalary = doc.getTextWidth(empWage);
            doc.text(empWage, wagesX - widthSalary, wagesY);
            wagesY = wagesY + 8;
          }

          let ssfX = 287;
          let ssfY = 55;
          for (var f2 = start; f2< end && f2 < self.ver2arr.length; f2++)
          {
            // let temp1 = self.ver2arr[f2].ssF_Amount <= self.Commonsocial.lowestSubvention ? self.Commonsocial.lowestSubvention : ((self.ver2arr[f2].ssF_Amount >= self.Commonsocial.highestSubvention) ? self.Commonsocial.highestSubvention : self.ver2arr[f2].ssF_Amount);
            let temp1 = self.ver2arr[f2].ssF_Amount;
            temp1 = parseFloat(temp1).toFixed(2);
            let empSSF = this.numberWithCommas(temp1);
            const widthSSF = doc.getTextWidth(empSSF);
            doc.text(empSSF, ssfX - widthSSF, ssfY);
            ssfY = ssfY + 8;
          }

          //let theincomingarr = self.ver2arr;
          increment1 = 0;
          for (var j2 = start; j2 < end && j2 < self.ver2arr.length; j2++)
          { 
            let personalcode = "-";
            if(self.ver2arr[j2].personalID_Number == null || self.ver2arr[j2].personalID_Number == ""){
              personalcode = "-";
            }
            else {
              personalcode = self.ver2arr[j2].personalID_Number.toString().split("");
            
              xposition = Xpoint + 35;
              yposition = Ypoint + increment1 + 12;
              personalcode = personalcode.map((v, i) => ({
                no: ++i,
                xposition: xposition,
                text: v,
              }));
              doc.setFont(normalfont);
              doc.setFontSize(13.5);
              increment = 0;
              personalcode.forEach((data) => {
                if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) 
                {
                  doc.text(data.xposition + 1 + increment + 2.5,yposition + 3.2,data.text);
                  increment += 6.5;
                } 
                else 
                {
                  doc.text(data.xposition + 1 + increment,yposition + 3.2,data.text);
                  increment += 4;
                }
              });
            }
            increment1 += 7.9;
          }

          
          
          // for (let j1 = 0; j1 < theincomingarr.length; j1++)
          // {
          //   let personalcode = theincomingarr[j1].personalID_Number.toString().split("");
          //   if (personalcode == "N/A") 
          //   {
          //     personalcode = "-";
          //   }
          //   xposition = Xpoint + 35;
          //   yposition = Ypoint + increment1 + 12;
          //   personalcode = personalcode.map((v, i) => ({
          //     no: ++i,
          //     xposition: xposition,
          //     text: v,
          //   }));
          //   doc.setFont(normalfont);
          //   doc.setFontSize(13.5);
          //   increment = 0;
          //   personalcode.forEach((data) => {
          //     if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) 
          //     {
          //       doc.text(data.xposition + 1 + increment + 2.5,yposition + 3.2,data.text);
          //       increment += 6.5;
          //     } 
          //     else 
          //     {
          //       doc.text(data.xposition + 1 + increment,yposition + 3.2,data.text);
          //       increment += 4;
          //     }
          //   });
          //   increment1 += 7.9;
          // }
        }
        if (i < self.thesheetnum - 1) 
        {
          doc.addPage();
        }
      }
      return doc.save("Social-Security-Report2.pdf");
    },

    getPdfAttachmentPND() {
      let self = this;
      const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: [297, 210] // Width (210mm for A4) and custom height.
      });
      let theseq = 1;
      for (let i = 0; i < self.thesheetnum; i++) {
        doc.addFileToVFS("THSarabunNew.ttf", self.ThaiFontBase64());
        doc.addFileToVFS("THSarabunNewBold.ttf", self.ThaiFontBoldBase64());
        doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        let normalfont = "THSarabunNew";
        let boldfont = "THSarabunNewBold";

        let Xpoint = 12;
        let Ypoint = 10;
        let xposition = 0;
        let yposition = 0;
        let increment = 0;
        let recwidth = 0;
        //let totalnum1 = 0;
        //let totalnum2 = 0;

        const imgEle = document.createElement("img");
        imgEle.src = require("@/assets/images/PND1.png");

        const imgcheck = document.createElement("img");
        imgcheck.src = require("@/assets/images/checkicon.png");

        const imgcallRD = document.createElement("img");
        imgcallRD.src = require("@/assets/images/RD.png");

        doc.setTextColor("#001C64");
        doc.setDrawColor(153, 163, 186);

        doc.setFont(boldfont);
        doc.setFontSize(20);
        doc.text(Xpoint, Ypoint + 3, "ใบแนบ");
        doc.setFontSize(26);
        doc.text(Xpoint + 15, Ypoint + 3, "ภ.ง.ด.1");

        doc.setFont(normalfont);
        doc.setFontSize(12);
        doc.text(
          Xpoint + 60,
          Ypoint + 3,
          "เลขประจำตัวผู้เสียภาษีอากร (ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย )"
        );

        let companytaxcode = self.theAtch.companyTaxID.toString().split("");
        xposition = Xpoint + 132;
        yposition = Ypoint - 0.5;
        companytaxcode = companytaxcode.map((v, i) => ({
          no: ++i,
          xposition: xposition,
          text: v
        }));
        doc.setFont(normalfont);
        doc.setFontSize(13.5);
        increment = 0;
        companytaxcode.forEach(data => {
          if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) {
            doc.text(
              data.xposition + 1 + increment + 2.5,
              yposition + 3.2,
              data.text
            );
            increment += 6.5;
          } else {
            doc.text(
              data.xposition + 1 + increment,
              yposition + 3.2,
              data.text
            );
            increment += 4;
          }
        });
        doc.setLineWidth(0.1);
        recwidth = 4;

        doc.rect(xposition, yposition, recwidth, 4.5);

        xposition = xposition + recwidth + 2.5;
        doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
        recwidth = 16;
        doc.rect(xposition, yposition, recwidth, 4.5);

        doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
        doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
        doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
        doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
        doc.setLineDash([]);

        xposition = xposition + recwidth + 2.5;
        doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
        recwidth = 20;
        doc.rect(xposition, yposition, recwidth, 4.5);

        doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
        doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
        doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
        doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
        doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
        doc.setLineDash([]);

        xposition = xposition + recwidth + 2.5;
        doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
        recwidth = 8;
        doc.rect(xposition, yposition, recwidth, 4.5);

        doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
        doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
        doc.setLineDash([]);

        xposition = xposition + recwidth + 2.5;
        doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
        recwidth = 4;
        doc.rect(xposition, yposition, recwidth, 4.5);

        doc.setFillColor(204, 209, 220);

        //first box
        doc.setLineWidth(0.5);
        doc.roundedRect(Xpoint, Ypoint + 5, 215, 25, 1, 1); // x,y,w,h,r,s

        doc.setFont(normalfont);
        doc.setFontSize(12);
        doc.text(
          Xpoint + 2,
          Ypoint + 10,
          "(ให้แยกกรอกรายการในใบแนบนี้ตามเงินได้แต่ละประเภท โดยใส่เครื่องหมาย “ "
        );
        doc.addImage(imgcheck, "PNG", Xpoint + 88, Ypoint + 7.5, 3, 3);
        doc.text(Xpoint + 90, Ypoint + 10, " ” ลงใน “ ");
        doc.setLineWidth(0.3);
        doc.rect(Xpoint + 102, Ypoint + 7, 3.5, 3.5);
        doc.text(
          Xpoint + 106,
          Ypoint + 10,
          "” หน้าข้อความแล้วแต่กรณี เพียงข้อเดียว)"
        );

        let minuss = 3;
        doc.setFont(boldfont);
        doc.setFontSize(12);
        doc.text(Xpoint + 2, Ypoint + 18 - minuss, "ประเภทเงินได้ ");

        doc.rect(Xpoint + 24, Ypoint + 15 - minuss, 3.5, 3.5);
        doc.text(
          Xpoint + 28,
          Ypoint + 18 - minuss,
          "(1) เงินได้ตามมาตรา 40 (1) เงินเดือน ค่าจ้าง ฯลฯ กรณีทั่วไป"
        );
        doc.addImage(
          imgcheck,
          "PNG",
          Xpoint + 24 + 0.3,
          Ypoint + 15.2 - minuss,
          3,
          3
        );

        doc.rect(Xpoint + 105, Ypoint + 15 - minuss, 3.5, 3.5);
        doc.text(
          Xpoint + 109,
          Ypoint + 18 - minuss,
          "(3) เงินได้ตามมาตรา 40 (1) (2) กรณีนายจ้างจ่ายให้ครั้งเดียวเพราะเหตุออกจากงาน"
        );

        doc.rect(Xpoint + 24, Ypoint + 21 - minuss, 3.5, 3.5);
        doc.text(
          Xpoint + 28,
          Ypoint + 24 - minuss,
          "(2) เงินได้ตามมาตรา 40 (1) เงินเดือน ค่าจ้าง ฯลฯ"
        );

        doc.rect(Xpoint + 105, Ypoint + 21 - minuss, 3.5, 3.5);
        doc.text(
          Xpoint + 109,
          Ypoint + 24 - minuss,
          "(4) เงินได้ตามมาตรา 40 (2) กรณีผู้รับเงินได้เป็นผู้อยู่ในประเทศไทย"
        );

        doc.text(
          Xpoint + 32,
          Ypoint + 30 - minuss,
          "กรณีได้รับอนุมัติจากกรมสรรพากรให้หักอัตราร้อยละ 3"
        );
        doc.rect(Xpoint + 105, Ypoint + 27 - minuss, 3.5, 3.5);
        doc.text(
          Xpoint + 109,
          Ypoint + 30 - minuss,
          "(5) เงินได้ตามมาตรา 40 (2) กรณีผู้รับเงินได้มิได้เป็นผู้อยู่ในประเทศไทย"
        );
        //fist box

        doc.text(Xpoint + 240, Ypoint + 10, "สาขาที่");
        xposition = Xpoint + 250;
        yposition = Ypoint + 7;
        recwidth = 20;
        doc.rect(xposition, yposition, recwidth, 4.5);

        doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
        doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
        doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
        doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
        doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
        doc.setLineDash([]);
        doc.setFont(normalfont);
        doc.text(Xpoint + 230, Ypoint + 28, (i + 1).toString());
        doc.text(Xpoint + 255, Ypoint + 28, self.thesheetnum.toString());
        doc.text(
          Xpoint + 217,
          Ypoint + 29,
          "แผ่นที่.................. ในจำนวน......................... แผ่น"
        );

        doc.rect(Xpoint, Ypoint + 31, 275, 157);
        //horizontal
        minuss = 7;
        doc.line(
          Xpoint + 20,
          yposition - minuss + 37,
          Xpoint + 220,
          yposition - minuss + 37
        );
        doc.line(
          Xpoint,
          yposition - minuss + 44,
          Xpoint + 275,
          yposition - minuss + 44
        );
        doc.line(
          Xpoint,
          yposition - minuss + 57,
          Xpoint + 275,
          yposition - minuss + 57
        );
        doc.line(
          Xpoint,
          yposition - minuss + 70,
          Xpoint + 275,
          yposition - minuss + 70
        );
        doc.line(
          Xpoint,
          yposition - minuss + 83,
          Xpoint + 275,
          yposition - minuss + 83
        );
        doc.line(
          Xpoint,
          yposition - minuss + 96,
          Xpoint + 275,
          yposition - minuss + 96
        );
        doc.line(
          Xpoint,
          yposition - minuss + 109,
          Xpoint + 275,
          yposition - minuss + 109
        );
        doc.line(
          Xpoint,
          yposition - minuss + 122,
          Xpoint + 275,
          yposition - minuss + 122
        );
        doc.line(
          Xpoint,
          yposition - minuss + 135,
          Xpoint + 275,
          yposition - minuss + 135
        );
        doc.line(
          Xpoint,
          yposition - minuss + 148,
          Xpoint + 275,
          yposition - minuss + 148
        );
        doc.line(
          Xpoint,
          yposition - minuss + 155,
          Xpoint + 265,
          yposition - minuss + 155
        );
        //vertical
        doc.line(
          Xpoint + 20,
          yposition - minuss + 31,
          Xpoint + 20,
          yposition - minuss + 148
        );
        doc.line(
          Xpoint + 140,
          yposition - minuss + 31,
          Xpoint + 140,
          yposition - minuss + 148
        );
        doc.line(
          Xpoint + 175,
          yposition - minuss + 38,
          Xpoint + 175,
          yposition - minuss + 155
        );
        doc.line(
          Xpoint + 210,
          yposition - minuss + 45,
          Xpoint + 210,
          yposition - minuss + 155
        );
        doc.line(
          Xpoint + 220,
          yposition - minuss + 31,
          Xpoint + 220,
          yposition - minuss + 155
        );
        doc.line(
          Xpoint + 255,
          yposition - minuss + 45,
          Xpoint + 255,
          yposition - minuss + 155
        );
        doc.line(
          Xpoint + 265,
          yposition - minuss + 31,
          Xpoint + 265,
          yposition - minuss + 155
        );
        doc.line(
          Xpoint + 140,
          yposition - minuss + 155,
          Xpoint + 140,
          yposition - minuss + 188
        );

        doc.setFont(boldfont);
        doc.text(Xpoint + 5, yposition - minuss + 38, "ลำดับ");
        doc.text(
          Xpoint + 55,
          yposition - minuss + 35,
          "เลขประจำตัวผู้เสียภาษีอากร (ของผู้มีเงินได้)"
        );
        doc.text(
          Xpoint + 45,
          yposition - minuss + 41.5,
          "ชื่อผู้มีเงินได้(ให้ระบุชัดเจนว่าเป็น นาย นาง นางสาว หรือยศ)"
        );
        doc.text(
          Xpoint + 155,
          yposition - minuss + 35,
          "รายละเอียดเกี่ยวกับการจ่ายเงิน"
        );
        doc.text(
          Xpoint + 145,
          yposition - minuss + 41.5,
          "วัน เดือน ปี ที่จ่าย"
        );
        doc.text(
          Xpoint + 183,
          yposition - minuss + 41.5,
          "จำนวนเงินได้ที่จ่ายในครั้งนี้"
        );
        doc.text(Xpoint + 228, yposition - minuss + 35, "จำนวนเงินภาษีที่หัก");
        doc.text(Xpoint + 230, yposition - minuss + 40, "และนำส่งในครั้งนี้");
        doc.text("เงื่อนไข *", Xpoint + 271, yposition - minuss + 42.5, {
          angle: 90
        });
        // doc.setLineWidth(0.1);
        // doc.circle(Xpoint + 160, yposition + 192.5, 10);
        // doc.setFont(normalfont);
        // doc.text(Xpoint + 153.5, Ypoint + 196.5, 'ประทับตรา');
        // doc.text(Xpoint + 155, Ypoint + 200.5, 'นิติบุคคล ');
        // doc.text(Xpoint + 157, Ypoint + 204.5, '(ถ้ามี)');
        minuss = 25;
        doc.setFont(normalfont);
        doc.setFontSize(12);
        doc.text(
          Xpoint + 75,
          Ypoint - minuss + 178,
          "รวมยอดเงินได้และภาษีที่นำส่ง (นำไปรวมกับใบแนบ ภ.ง.ด.1 แผ่นอื่น (ถ้ามี))"
        );
        doc.text(
          Xpoint + 191,
          Ypoint - minuss + 178.8,
          "............................"
        );
        doc.text(Xpoint + 214, Ypoint - minuss + 178.8, ".........");
        doc.text(
          Xpoint + 236,
          Ypoint - minuss + 178.8,
          "............................"
        );
        doc.text(Xpoint + 259, Ypoint - minuss + 178.8, ".........");

        minuss = 30;
        doc.text(
          Xpoint + 5,
          Ypoint - minuss + 189,
          "(ให้กรอกลำดับที่ต่อเนื่องกันไปทุกแผ่นตามเงินได้แต่ละประเภท)"
        );
        doc.text(
          Xpoint + 5,
          Ypoint - minuss + 195,
          "หมายเหตุ * เงื่อนไขการหักภาษีให้กรอกดังนี้"
        );

        //doc.setFillColor(0, 0, 0);
        doc.rect(Xpoint + 17, Ypoint - minuss + 200, 1, 1, "F");
        doc.rect(Xpoint + 17, Ypoint - minuss + 206, 1, 1, "F");
        doc.rect(Xpoint + 17, Ypoint - minuss + 212, 1, 1, "F");

        doc.text(Xpoint + 21, Ypoint - minuss + 201.5, " หัก ณ ที่จ่าย กรอก 1");
        doc.text(Xpoint + 21, Ypoint - minuss + 207.5, " ออกให้ตลอดไป กรอก 2");
        doc.text(
          Xpoint + 21,
          Ypoint - minuss + 213.5,
          " ออกให้คร้ังเดียวกรอก 3"
        );

        doc.setFont(boldfont);

        doc.text(
          Xpoint + 180,
          Ypoint - minuss + 190,
          "ลงชื่อ..........................................................................ผู้จ่ายเงิน"
        );
        doc.text(
          Xpoint + 187,
          Ypoint - minuss + 197,
          "(.........................................................................)"
        );
        doc.text(
          Xpoint + 180,
          Ypoint - minuss + 204,
          "ตำแหน่ง..................................................................................."
        );
        doc.text(
          Xpoint + 180,
          Ypoint - minuss + 211,
          "ยื่นวันที่...........เดือน.......................................พ.ศ. ..................."
        );

        doc.text(
          Xpoint,
          Ypoint - minuss + 223,
          "สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร"
        );
        doc.addImage(
          imgcallRD,
          "PNG",
          Xpoint + 58,
          Ypoint - minuss + 219.5,
          30,
          4.4
        );
        doc.text(Xpoint + 89, Ypoint - minuss + 223, "โทร. 1161");

        //13
        doc.text(Xpoint + 250, Ypoint - minuss + 223, "พิมพ์ .....…………………");
        let teemonth = self.themon;
        let thaitxt = "";
        if (teemonth == 1) {
          thaitxt = "มกราคม";
        } else if (teemonth == 2) {
          thaitxt = "กุมภาพันธ์";
        } else if (teemonth == 3) {
          thaitxt = "มีนาคม";
        } else if (teemonth == 4) {
          thaitxt = "เมษายน";
        } else if (teemonth == 5) {
          thaitxt = "พฤษภาคม";
        } else if (teemonth == 6) {
          thaitxt = "มิถุนายน";
        } else if (teemonth == 7) {
          thaitxt = "กรกฎาคม";
        } else if (teemonth == 8) {
          thaitxt = "สิงหาคม";
        } else if (teemonth == 9) {
          thaitxt = "กันยายน";
        } else if (teemonth == 10) {
          thaitxt = "ตุลาคม";
        } else if (teemonth == 11) {
          thaitxt = "พฤศจิกายน";
        } else if (teemonth == 12) {
          thaitxt = "ธันวาคม";
        }

        let themoyr = (thaitxt + " " + (self.todayyears.getFullYear() + 543)).toString();
        doc.text(Xpoint + 258, Ypoint - minuss + 222, themoyr);
        minuss = 7;
        var increment1 = 0;
        let salaryTotal = 0;
        let taxTotal = 0;
        for (let j = 0; j < self.theAtchempList[i].List.length; j++) 
        {
          let ind = theseq.toString();
          doc.setFont(normalfont);
          doc.text(Xpoint + 8, Ypoint - minuss + increment1 + 58, ind);
          theseq++;
          let personalcode = "-";
          if(self.theAtchempList[i].List[j].personalID_Number == null || self.theAtchempList[i].List[j].personalID_Number == ""){
            personalcode = "-";
          }
          else {
            personalcode = self.theAtchempList[i].List[j].personalID_Number
            .toString()
            .split("");

            xposition = Xpoint + 22;
            yposition = Ypoint - minuss + increment1 + 52;
            personalcode = personalcode.map((v, i) => ({
              no: ++i,
              xposition: xposition,
              text: v
            }));
            doc.setFont(normalfont);
            doc.setFontSize(13.5);
            increment = 0;
            personalcode.forEach(data => {
              if (
                data.no == 2 ||
                data.no == 6 ||
                data.no == 11 ||
                data.no == 13
              ) 
              {
                doc.text(
                  data.xposition + 1 + increment + 2.5,
                  yposition + 3.2,
                  data.text
                );
                increment += 6.5;
              } 
              else 
              {
                doc.text(
                  data.xposition + 1 + increment,
                  yposition + 3.2,
                  data.text
                );
                increment += 4;
              }
            });
          }
          
          doc.setFontSize(12);
          //7 and 8
          let firstName = self.theAtchempList[i].List[j].name;
          let LastName = self.theAtchempList[i].List[j].lastName;
          doc.text(Xpoint + 30, Ypoint - minuss + increment1 + 61, firstName);
          doc.text(Xpoint + 93, Ypoint - minuss + increment1 + 61, LastName);
          //9
          let payDate = self.formatThaiYearDate(
            self.theAtchempList[i].List[j].payment_Date
          );
          doc.text(Xpoint + 159, Ypoint - minuss + increment1 + 61, payDate);
          //10
          salaryTotal = salaryTotal + self.theAtchempList[i].List[j].salary;
          const decimalNumber = self.theAtchempList[i].List[
            j
          ].salary.toString();
          const wholeNumber = Math.floor(decimalNumber);
          const decimalPart = (decimalNumber - wholeNumber).toFixed(2);
          const decimalString = decimalPart.split(".")[1] || "00";
          doc.text(
            Xpoint + 195,
            Ypoint - minuss + increment1 + 61,
            wholeNumber.toString()
          );
          doc.text(
            Xpoint + 215,
            Ypoint - minuss + increment1 + 61,
            decimalString.toString()
          );
          //11
          taxTotal = taxTotal + self.theAtchempList[i].List[j].tax_Amount;
          const decimalNumber1 = self.theAtchempList[i].List[
            j
          ].tax_Amount.toString();
          const wholeNumber1 = Math.floor(decimalNumber1);
          const decimalPart1 = (decimalNumber1 - wholeNumber1).toFixed(2);
          const decimalString1 = decimalPart1.split(".")[1] || "00";
          doc.text(
            Xpoint + 240,
            Ypoint - minuss + increment1 + 61,
            wholeNumber1.toString()
          );
          doc.text(
            Xpoint + 260,
            Ypoint - minuss + increment1 + 61,
            decimalString1.toString()
          );
          //12
          if (firstName != "") {
            doc.text(Xpoint + 271, Ypoint - minuss + increment1 + 61, "1");
          }
          increment1 += 13;
        }
        increment1 = 0;
        for (let j = 0; j < 8; j++) {
          xposition = Xpoint + 22;
          yposition = Ypoint - minuss + increment1 + 52;
          doc.setLineWidth(0.1);
          recwidth = 4;

          doc.rect(xposition, yposition, recwidth, 4.5);

          xposition = xposition + recwidth + 2.5;
          doc.line(
            xposition - 2.5,
            yposition + 2.5,
            xposition,
            yposition + 2.5
          );
          recwidth = 16;
          doc.rect(xposition, yposition, recwidth, 4.5);

          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
          doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
          doc.setLineDash([]);

          xposition = xposition + recwidth + 2.5;
          doc.line(
            xposition - 2.5,
            yposition + 2.5,
            xposition,
            yposition + 2.5
          );
          recwidth = 20;
          doc.rect(xposition, yposition, recwidth, 4.5);

          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
          doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
          doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
          doc.setLineDash([]);

          xposition = xposition + recwidth + 2.5;
          doc.line(
            xposition - 2.5,
            yposition + 2.5,
            xposition,
            yposition + 2.5
          );
          recwidth = 8;
          doc.rect(xposition, yposition, recwidth, 4.5);

          doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
          doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
          doc.setLineDash([]);

          xposition = xposition + recwidth + 2.5;
          doc.line(
            xposition - 2.5,
            yposition + 2.5,
            xposition,
            yposition + 2.5
          );
          recwidth = 4;
          doc.rect(xposition, yposition, recwidth, 4.5);
          doc.setFontSize(12);
          //7 and 8
          doc.text(
            Xpoint + 22,
            Ypoint - minuss + increment1 + 62,
            "ชื่อ............................................................................. ชื่อสกุล............................................................................"
          );
          //9
          doc.text(
            Xpoint + 156,
            Ypoint - minuss + increment1 + 62,
            "............................"
          );
          doc.text(
            Xpoint + 191,
            Ypoint - minuss + increment1 + 62,
            "............................"
          );
          doc.text(
            Xpoint + 214,
            Ypoint - minuss + increment1 + 62,
            "........."
          );
          doc.text(
            Xpoint + 236,
            Ypoint - minuss + increment1 + 62,
            "............................"
          );
          doc.text(
            Xpoint + 259,
            Ypoint - minuss + increment1 + 62,
            "........."
          );
          //12
          doc.text(
            Xpoint + 269,
            Ypoint - minuss + increment1 + 62,
            "........."
          );

          increment1 += 13;
        }
        minuss = 25;
        doc.setFont(normalfont);
        doc.setFontSize(12);

        const decimalNumber2 = salaryTotal.toString();
        const wholeNumber2 = Math.floor(decimalNumber2);
        const decimalPart2 = (decimalNumber2 - wholeNumber2).toFixed(2);
        const decimalString2 = decimalPart2.split(".")[1] || "00";

        const decimalNumber3 = taxTotal.toString();
        const wholeNumber3 = Math.floor(decimalNumber3);
        const decimalPart3 = (decimalNumber3 - wholeNumber3).toFixed(2);
        const decimalString3 = decimalPart3.split(".")[1] || "00";

        doc.text(Xpoint + 195, Ypoint - minuss + 178, wholeNumber2.toString());
        doc.text(
          Xpoint + 215,
          Ypoint - minuss + 178,
          decimalString2.toString()
        );
        doc.text(Xpoint + 240, Ypoint - minuss + 178, wholeNumber3.toString());
        doc.text(
          Xpoint + 260,
          Ypoint - minuss + 178,
          decimalString3.toString()
        );

        if (i < self.thesheetnum - 1) {
          doc.addPage();
        }
      }
      return doc.save(self.theFileName);
    },
    digitPositionXforPDF(x, v) {
      let count = v ? v.length : 0;
      let singlewidth = 1.7;
      let positionx = x - count * singlewidth;
      return positionx;
    },
    formatThaiYearDate(date) {
      if (!date) return null;
      let output = "-";
      let temp = new Date(date + "Z");

      let day = temp
        .getDate()
        .toString()
        .padStart(2, 0);
      let month = (temp.getMonth() + 1).toString().padStart(2, 0);
      let year = temp.getFullYear() + 543;
      output = `${day}/${month}/${year}`;

      return output;
    },
    namingFormat(date) {
      if (!date) return null;
      let output = "-";
      let temp = new Date(date + "Z");

      let day = temp
        .getDate()
        .toString()
        .padStart(2, 0);
      let month = (temp.getMonth() + 1).toString().padStart(2, 0);
      let year = temp.getFullYear();
      output = `${year}${month}${day}_`;

      return output;
    },
    gotoPayrollAccount() {
      this.$router.push("/organization/companyprofile/companyinformation").catch(() => { });
      this.activeTab = `/organization/companyprofile/companyinformation/payrollaccount`;
      this.$router.push(`/organization/companyprofile/companyinformation/payrollaccount`).catch(() => { });
    },
  }
};
</script>

<style scoped>
::v-deep .createpayroll .mx-input{
    height: 40px!important;
    font-size: 16px!important;
    color: rgba(0, 0, 0, 0.87);
}
::v-deep .createpayroll .mx-input:hover{
  border: 1px solid #f99d20;
}
::v-deep .createpayroll .mx-icon-calendar{
  color: #f99d20;
}
*>>>td.cell.today{
  color: #fff!important;
  background-color: #1284e7!important;
}
/* ::v-deep .hidden-input .mx-input-wrapper {
    display: none !important;
}
::v-deep .datepicker-container .mx-datepicker {
    display: none!important;
}
::v-deep .personalcal .mx-datepicker-popup{
    top: 120%!important;
    left: 27%!important;
} */
*>>>.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0 !important;
}

/* .custom-data-table .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th
{
  padding: 0px 8px;
} */

.OTtable {
  box-shadow: none;
}
.emptabs{
  width: 180px;
}
.calimg{
  height: 18px;
  max-width: 18px!important;
}
.calimg1{
  height: 14px;
  /* //max-width: 14px!important; */
}
.activeclass{
  color:#f89d1f!important;
}
.typetabs .v-tab {
  background-color: transparent!important;
}
.OTheader {
  background: #eee;
}

.OTheader .Rheader th {
  color: #8a8c8e !important;
}

.OTfooter {
  background: #eee;
}

.OTHone {
  background-color: #f8f8f8;
}

.OTHoneactive {
  background-color: #ffffff !important;
}

.goup {
  margin-top: 0;
}
::v-deep .OTtable thead th:first-child {
  border-radius: 8px 0 0 0 !important;
}

::v-deep .OTtable thead th:last-child {
  border-radius: 0 8px 0 0 !important;
}

::v-deep .OTtable .v-data-table-header th {
  font-size: 0.875rem !important;
  background-color: #7070704f !important;
  opacity: 1;
  text-align: center;
  color: #8a8c8e !important;
}

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td
{
    font-size: 0.875rem;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
} */

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td
{
  height : 35px !important;
} */

.status_list {
  border-radius: 20px;
  font-size: 13px;
  text-align: center;
  padding: 2px;
  background: #ccf3e9;
  color: #00c092;
  display: inline-table;
}

.status_list1 {
  border-radius: 20px;
  font-size: 13px;
  text-align: center;
  padding: 2px;
  background: #70707026;
  color: #8a8c8e;
  display: inline-table;
}

::v-deep .v-text-field__details {
  display: none !important;
}

.periodimg {
  max-width: 22px !important;
}

.paidby {
  max-width: 30px !important;
}

.employeeText {
  margin-left: 20%;
}

.payrollTitle {
  font-size: 16px;
  color: #707070;
}

.listgp {
  border: 1px solid #d9d5d5;
}

.employeeType {
  border: 1px solid #d9d5d5;
  height: 50px !important;
  width: 95%;
}

.payrollOpti {
  border: 1px solid #d9d5d5;
  width: 220px !important;
  height: 50px !important;
}

.active {
  background-color: #fff2e3;
  border: 1px solid #7070704f;
}

.payrolltext {
  color: #8a8c8e !important;
}

.createPayroll {
  font-size: 24px;
  color: #444444 !important;
}

.payrolloption {
  font-size: 16px;
  color: #8a8c8e !important;
}

.tooltip-button {
  position: relative;
  cursor: pointer;
}

.pdf-container {
  background-color: #aaa9ae;
  margin-left: 3rem;
  margin-right: 3rem;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: black;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-button:hover .tooltip {
  opacity: 1;
}

@media (min-width: 1600px) {
  .income {
    margin-left: -4.2rem !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .income {
    margin-left: -2.5rem !important;
  }
}

>>>.v-dialog::-webkit-scrollbar {
  width: 2px;
  background-color: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Track */
>>>.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle */
>>>.v-dialog::-webkit-scrollbar-thumb {
  background: #f0f0f7;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle on hover */
>>>.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0f0f7;
}

>>>.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  /* color: rgba(0, 0, 0, 0.6); */
  font-size: 16px;
  /* font-family: "Kanit"; */
  font-weight: normal;
  color: #f99d20;
}

@media (max-width: 375px) {
  .fix-btn {
    max-width: 110px !important;
  }
}

/* @media (min-width: 375px) {
  .fix-btn {
    max-width: 50px !important;
  }
} */

.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding-left: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.v-application p {
  margin-bottom: 0 !important;
}

.delete-pad {
  margin: 0px;
  padding: 0px;
}

table {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  /* text-align: left;     */
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 100px solid red;
  border-left: 100px solid transparent;
}

.inner-triangle {
  border-left: 100px solid transparent;
  border-right: 100px solid #fcce8f;
  border-bottom: 100px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.outer-triangle {
  border-left: 24px solid transparent;
  border-right: 24px solid gray;
  border-bottom: 24px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 1;
}

.example {
  margin: 20px;
  background-color: white;
}

.example input {
  display: none;
  background: white;
}

.example label {
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
}

.ex1 span {
  display: block;
  padding: 5px 10px 5px 25px;

  position: relative;
  transition: all 0.25s linear;

  background-color: white;
}

.ex1 span:before {
  content: "";

  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #dcd4d3;
  transition: all 0.25s linear;
}

.ex1 input:checked+span {
  background-color: white;
}

.ex1 .orange input:checked+span {
  color: orange;
  border-color: orange;
  background-color: white;
}

.ex1 .orange input:checked+span:before {
  background-color: orange;
}

.full-name-column {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%; /* Adjust the max-width as needed */
}

.table-cell-content {
  margin-top: 2px; /* Ensure no additional margin is applied */
  vertical-align: text-top; /* Vertically align the content in the middle */
}

>>>.selectboxs {
  width: 100px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}

.addStaffBtnAlign {
  width: 150px;
}

#middlecol {
  width: 100%;
}

#middlecol table {
  max-width: 2000px;
  width: 100% !important;
  table-layout: fixed;
}

.yearSearchBoxwidth {
  max-width: 270px;
}

.serachForEmployeeTab {
  width: 90%;
}

.recalculateBtnForEmployeeTab {
  /* margin-left: 15px; */
  float: right;
}
.no-scroll-dialog .v-card {
  overflow: hidden;
  max-height: 100%; /* Ensure the card does not exceed the dialog height */
  display: flex;
  flex-direction: column;
}
.no-scroll-dialog .v-card-title,
.no-scroll-dialog .v-card-text,
.no-scroll-dialog .v-card-actions {
  margin: 0;
  padding: 0;
}
.no-scroll-dialog .v-card-title {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
.no-scroll-dialog .v-card-text {
  flex: 1; /* Ensure the text takes up available space */
  padding: 20px 16px;
  overflow: hidden; /* Ensure no scroll in text area */
}
.no-scroll-dialog .v-card-actions {
  padding: 20px 16px 24px 16px;
}

.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; /* Ensures vertical alignment */
  height: 100%; /* Ensures the text spans the full height of its container */
}

@media (min-width: 1440px) and (min-height: 850px) {
  .createPayrollButtonAlign {
    margin-left: 10px;
  }

  .printDialogText {
    font-size: 14px;
  }

  .printDialogTextInTable {
    font-size: 14px;
  }
}

@media (max-width: 1300px) and (max-height: 850px) {
  .createPayrollButtonAlign {
    margin-left: 8px;
  }

  .printDialogText {
    font-size: 14px;
  }

  .printDialogTextInTable {
    font-size: 14px;
  }
}

/* galaxy s5 */
@media (max-width: 370px) and (max-height: 650px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }

  .confirmPaymentBtnAlign {
    font-size: 11px;
  }

  .textForComfirmpayment {
    font-size: 13px;
  }

  .textInputForCofirmpayment {
    font-size: 9px;
    width: 65px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .titleForConfirmpayment {
    font-size: 13px;
  }

  .printDialogText {
    font-size: 12px;
  }

  .printDialogTextInTable {
    font-size: 8px;
  }
}

@media (max-width: 420px) and (max-height: 750px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }

  .addStaffBtnAlign {
    width: 100px;
    margin-top: 50px;
  }

  .printDialogText {
    font-size: 13px;
  }

  .printDialogTextInTable {
    font-size: 8px;
  }
}

@media (min-width: 310px) and (max-height: 570px) {
  .addStaffBtnAlign {
    width: 80px;
    margin-top: 50px;
  }

  .confirmPaymentBtnAlign {
    font-size: 11px;
  }

  .textForComfirmpayment {
    font-size: 10px;
  }

  .textInputForCofirmpayment {
    font-size: 9px;
    width: 55px;
    margin-top: 10px;
  }

  .printDialogText {
    font-size: 10px;
  }

  .printDialogTextInTable {
    font-size: 8.5px;
  }

  .yearSearchBoxwidth {
    max-width: 125px;
  }

  .alignForYear {
    margin-left: 45px;
  }
}

@media (max-width: 380px) and (min-height: 650px) {
  .textForComfirmpayment {
    font-size: 10px;
  }

  .textInputForCofirmpayment {
    font-size: 9px;
    width: 55px;
    margin-top: 10px;
  }

  .printDialogText {
    font-size: 13px;
  }

  .printDialogTextInTable {
    font-size: 8px;
  }

  .alignForYear {
    margin-left: 25px;
  }
}

@media (max-width: 380px) and (max-height: 820px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }

  .addStaffBtnAlign {
    width: 80px;
    margin-top: 50px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .Optipay {
    padding-left: 0px !important;
  }

  .totalnetdiv {
    margin-left: 10% !important;
  }
}

@media (max-width: 420px) and (max-height: 830px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }
}

@media (min-width: 320px) and (max-width: 900px) {
  .bankdiv {
    width: 100% !important;
  }
}

/* for year and month autocomplete align */
@media (max-width: 420px) and (min-height: 720px) {
  .alignForYear {
    margin-left: 45px;
  }
}

@media (max-width: 380px) and (min-height: 810px) {
  .alignForYear {
    margin-left: 26px;
  }
}

@media (width: 360px) and (height: 640px) {
  .alignForYear {
    margin-left: 11px;
  }
}

@media (max-width: 420px) and (min-height: 660px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }

  .textForComfirmpayment {
    font-size: 10px;
  }

  .textInputForCofirmpayment {
    font-size: 9px;
    width: 55px;
    margin-top: 10px;
  }
}

@media (max-width: 780px) and (max-height: 1100px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }
}

@media (max-width: 1030px) and (min-height: 1300px) {
  .createPayrollButtonAlign {
    margin-left: 0px !important;
  }
}

@media (width: 1280px) and (height: 800px) {
  .createPayrollButtonAlign {
    margin-left: 10px;
  }
}

@media (min-width: 1050px) and (max-height: 790px) {
  .createPayrollButtonAlign {
    margin-left: 20px;
  }

  .printDialogText {
    font-size: 13px;
  }

  .printDialogTextInTable {
    font-size: 13px;
  }
}

/* for emp tab */

@media (min-width: 760px) and (min-height: 1000px) {
  .serachForEmployeeTab {
    width: 70%;
  }

  /* .recalculateBtnForEmployeeTab {
    margin-left: 75px;
  } */
}

@media (min-width: 1000px) and (min-height: 1300px) {
  .serachForEmployeeTab {
    width: 70%;
  }

  .recalculateBtnForEmployeeTab {
    margin-left: 5px;
  }
}

@media (min-width: 1200px) and (min-height: 790px) {
  .serachForEmployeeTab {
    width: 50%;
  }

  /* .recalculateBtnForEmployeeTab {
    margin-left:315px
  } */
}

@media (min-width: 1200px) and (max-height: 700px) {
  .serachForEmployeeTab {
    width: 50%;
  }

  /* .recalculateBtnForEmployeeTab {
    margin-left: 365px;
  } */
}

.datetime-picker input[data-v-a46a390c] {
  width: 150px;
  height: 38px;
}

.startDated {
  width: 200px;
  height: 48px;
}

.month {
  width: 200px;
}

.hover-effect {
  position: relative;
}

.tooltip {
  position: absolute;
  top: -35px;
  left: 50%;
  font-size: 12px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.hover-effect:hover .tooltip {
  opacity: 1;
}

@media (max-width: 320px) and (max-height: 568px) {
  .startDated {
    width: 180px !important;
  }

  .month {
    width: 170px !important;
  }
}

#duo {
  background-color: rgb(249, 157, 32);
  border-radius: 5px;
  margin-left: 30px;
  margin-top: 10px;
  width: fit-content;
  height: fit-content;
}

@media only screen and (min-width: 1400px) {
  #duo {
    background-color: #f0d3aa;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 30px;
    min-width: 200px;
    width: fit-content;
    height: fit-content;
  }
}

>>>.selectbox {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}

>>>.v-autocomplete input {
  padding-left: 5px !important;
}

.listhover :hover {
  background-color: #FFF2E3;
}

img.no-hover {
  pointer-events: none; /* This disables all mouse events on the image */
}

.listhover>>>.tile :active {
  background-color: #fad7a7 !important;
}

.sublisthover :hover {
  background-color: #FFF2E3;
}

.sublisthover .list-item--active {
  background-color: #fad7a7;
}

.listhover:active {
  background-color: #FFF2E3;
}

>>>#gk {
  border: 2px solid #d3d3d3 !important;
}

>>>#gk:active {
  border: 2px solid #FFF2E3 !important;
}

>>>.min-square-btn {
  width: 30px !important;
  height: 30px !important;
}
*>>> .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 48px;
    border: none;
    background: transparent;
} 

.custom-data-table .v-data-table__wrapper {
  padding: 0; /* Remove padding from the table wrapper */
}
>>>.cb-cus .v-label {
  white-space: pre-line;
}
::v-deep .v-window{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)!important;
}
</style>
