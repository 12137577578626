<template>
  <div style="margin-left: 0.1%; margin-right: 0.1%">
    <br />
    <v-row>
      <v-col style="padding-left: 5px">
        <v-col class="d-flex" style="margin-left: 2rem; margin-top: -0.5rem">
          <p style="font-size: 19px; color: #3c4043">
            {{ $t("TimeOffCounts") }}
          </p>
        </v-col>
      </v-col>
    </v-row>

    <v-row style="margin-left: 2.4rem; margin-top: -1rem">
      <v-col cols="12" md="12">
        <v-row class="pl-md-0" style="margin-top: -2rem">
          <v-row class="mt-2">
            <v-col cols="6" md="4" style="font-size: 19px">
              <v-radio-group
                :disabled="disradio"
                v-model="included"
                :mandatory="false"
              >
                <v-radio
                  :label="$t('Holiday/DayOffIncluded')"
                  :value="true"
                  color="#707070"
                ></v-radio>
                <v-radio
                  class="mt-4"
                  :label="$t('Holiday/DayOffExcluded')"
                  :value="false"
                  color="#707070"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <div style="height: 23rem">
      <div
        class="d-flex flex-row-reverse"
        style="margin-top: 3rem; padding-bottom: 3rem"
      >
        <div v-show="showedit">
          <v-btn
            color="#F89D1F"
            class="pa-2 mr-7 text_transform_none"
            rounded
            width="160px"
            height="50px"
            @click="editgone"
            :disabled="permission.edit"
            >{{ $t("edit") }}</v-btn
          >
        </div>
        <v-btn
          color="#F89D1F"
          v-show="!showedit"
          class="pa-2 mr-7 text_transform_none fix-btn"
          rounded
          width="160px"
          height="50px"
          @click="UpdateTimeOff"
          :disabled="permission.edit"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          color="#3C4043"
          v-show="!showedit"
          class="pa-2 mr-5 text_transform_none fix-btn"
          rounded
          width="160px"
          height="50px"
          text
          router
          @click="resetTimeOff"
          >{{ $t("cancel") }}</v-btn
        >
      </div>
    </div>
    <v-dialog persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template> 

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewEdit from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, utils, CheckViewEdit],
  data() {
    return {
      permission: {
        edit: true,
      },
      url: enurl.apiUrl,
      LoadingDialog: false,
      TimeOffSetting: [],
      radios: true,
      included: false,
      exculded: false,
      disradio: true,
      showedit: true,
    };
  },
  mounted() {
    this.getTimeOff();
    this.permission = this.CheckViewEdit(137, 138);
  },
  methods: {
    getTimeOff() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };

      axios
        .post(`${self.url}TimeOffSetting/GetTimeOffSettingByCompanyID`, temp)
        .then(function (response) {
          self.TimeOffSetting = response.data.data;
          self.included = self.TimeOffSetting[0].holiday_DayOff_Included;
          self.exculded = self.TimeOffSetting[0].holiday_DayOff_Excluded;
          self.LoadingDialog = false;
          self.LogTrace(null, "Get Company Set Up, Time Off", 31, "Critical");
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Get Company Set Up, Time Off Fail",
            31,
            "Critical"
          );
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    UpdateTimeOff() {
      let self = this;
      self.LoadingDialog = true;

      let temp = {
        holiday_DayOff_Included: self.included,
        holiday_DayOff_Excluded: self.exculded,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOffSetting/UpdateTimeOffSetting`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            self.disradio = true;
            self.showedit = true;
            self.LogTrace(
              null,
              "Update Company Set Up, Time Off",
              31,
              "Medium"
            );
            alert("Change Successfully!");
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Update Company Set Up, Time Off Fail",
            31,
            "Medium"
          );
          alert(error);
          self.LoadingDialog = false;
        });
    },
    resetTimeOff() {
      let self = this;

      self.LoadingDialog = true;
      self.included = self.TimeOffSetting[0].holiday_DayOff_Included;
      self.exculded = self.TimeOffSetting[0].holiday_DayOff_Excluded;
      self.disradio = true;
      self.showedit = true;
      self.LoadingDialog = false;

      // let temp = {
      //   holiday_DayOff_Included: true,
      //   holiday_DayOff_Excluded: false,
      //   company_ID: store.state.companyID,
      // };
      // axios
      //   .post(`${self.url}TimeOffSetting/UpdateTimeOffSetting`, temp)
      //   .then(function (response) {
      //     if (response.data.status == 0) {
      //       self.included = true;
      //       self.exculded = false;
      //       self.disradio = true;
      //       self.showedit = true;
      //       self.LoadingDialog = false;
      //       alert("Change Successfully!");
      //     }
      //   });
    },
    editgone() {
      let self = this;
      self.disradio = false;
      self.showedit = false;
    },
  },
};
</script>

<style scoped>
@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>