<template>
  <v-card class="mycardthree">
    <div class="d-flex ml-2">
      <div style="margin-left: 0.5rem" class="ma-2">
        <p style="margin-top: 10px; color: #0fa7d8; font-size: 17px">
          {{ $t("onTimeOff") }}
        </p>
      </div>
    </div>
    <div class="d-flex ml-2">
      <div style="margin-left: 0.5rem" class="ma-2">
        <p style="margin-top: 10px; color: #f99d20">{{ $t("today") }}</p>
      </div>
    </div>
    <div class="mycard">
      <template v-if="todayList.length">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          v-for="(item, i) in todayList"
          :key="i"
        >
          <v-card>
            <v-card-text>
              <p>{{ item.employee_Name }}</p>
              <p v-show="!thethaihead">{{ item.timeoff_Name }}</p>
              <p v-show="thethaihead">{{ item.timeoff_NameTH }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </template>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-else>
        <v-card>
          <v-card-text>
            <p style="font-size:15px">{{ $t("NobodyRequested") }}</p>
          </v-card-text>
        </v-card>
      </v-col>

      <div class="d-flex ml-2">
        <div style="margin-left: 0.5rem" class="ma-2">
          <p style="margin-top: 10px; color: #f99d20;">{{ $t("tomorrow") }}</p>
        </div>
      </div>
      <template v-if="tmrList.length">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          v-for="(item, i) in tmrList"
          :key="i"
        >
          <v-card>
            <v-card-text>
              <p>{{ item.employee_Name }}</p>
              <p v-show="!thethaihead">{{ item.timeoff_Name }}</p>
              <p v-show="thethaihead">{{ item.timeoff_NameTH }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-else>
        <v-card>
          <v-card-text>
            <p style="font-size:15px">{{ $t("nobodyRequested") }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";

export default {
  data: () => ({
    url: enurl.apiUrl,
    todayList: [],
    tmrList: [],
    thelang: "",
  }),
  mounted() {
    this.GetTimeOff();
    this.GetTimeOffTmr();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  computed: {
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    thelang() {
      this.whatthehead()
    },
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    async GetTimeOff() {
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await Axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_ForHomePage`,
          temp
        );

        let list = response.data.data;
        if (list.length) {
          this.todayList = list;
        }
      } catch (ex) {
        throw ex;
      }
    },
    async GetTimeOffTmr() {
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await Axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_ForTmrHomePage`,
          temp
        );

        let list = response.data.data;
        if (list.length) {
          this.tmrList = list;
        }
      } catch (ex) {
        throw ex;
      }
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0 !important;
}
.mycard {
  overflow-y: auto;
  overflow-x: hidden;
}
.mycardthree {
  overflow-y: auto;
  height: 400px;
  overflow-x: hidden;
}
</style>
