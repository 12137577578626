<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <v-row>
        <v-btn icon color="white" @click="goback()" class="mt-5">
          <img src="@/assets/images/back button.png" />
        </v-btn>
        <p
          style="
            font-size: 25px;
            margin-left: 4px;
            margin-top: 20px;
            color: #43425d;
          "
        >
          {{ $t("TimesheetDetails") }}
        </p>
      </v-row>
    </div>
    <v-card class="mt-6 commonmb">
      <div style="margin-left: 2rem; margin-right: 2rem">
        <v-row style="margin-top: 2rem">
          <v-col class="mt-4" cols="12" sm="6" md="6">
            <div class="d-flex">
              <div>
                <v-avatar width="60" height="60">
                  <img :src="profileimage" />
                </v-avatar>
              </div>
              <div class="ml-2">
                <p class="mt-1">{{ profilename }}</p>
                <p style="color: #444444" class="txt14">
                  {{ profileposition }}
                </p>
                <p style="color: #444444" class="txt14">
                  {{ $t("department") }}: {{ profiledepartment }}
                </p>
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="6">
            <div v-show="showApproveBtn">
              <v-row class="float-right mt-4">
                <v-col>
                  <v-row>
                    <p style="color: #f99d20; margin-left: 2.8rem">
                      {{ $t("Status") }}:
                    </p>
                    <p style="color: #ff0000; margin-left: 1rem">
                      {{ $t("waitingApproval") }}
                    </p>
                  </v-row>
                  <v-row>
                    <p style="color: #f99d20">{{ $t("ApprovedBy") }}:</p>
                    <p style="color: #000000; margin-left: 1rem">
                      {{ approver }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-show="!showApproveBtn">
              <v-row class="float-right mt-4">
                <v-col>
                  <v-row>
                    <p style="color: #f99d20; margin-left: 2.8rem">
                      {{ $t("Status") }}:
                    </p>
                    <p style="color: #46d95a; margin-left: 1rem">
                      {{ $t("approved") }}
                    </p>
                  </v-row>
                  <v-row>
                    <p style="color: #f99d20">{{ $t("ApprovedBy") }}:</p>
                    <p style="color: #46d95a; margin-left: 1rem">
                      {{ approver }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <!-- <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="2">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize empID float-right mt-4"
              width="150"
              max-height="34"
              @click="Approvedialog=true"
            >
              <img src="@/assets/images/correct.png" class="pr-2" />Approve
            </v-btn>
          </v-col>-->
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <p class="txt14">
              {{ $t("ReportDate") }}:
              <span class="ml-2">{{ formatDate1(profiledate) }}</span>
            </p>
          </v-col>
          <v-col v-show="showholiday" cols="12" sm="3" md="3">
            <p class="txt14">
              {{ $t("Holiday") }}:
              <span class="ml-2">{{ holiday_name }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row style="margin-top: -15px">
          <v-col cols="12" sm="6" md="6">
            <p class="txt14">
              {{ $t("TotalHours") }}:
              <span class="ml-2">{{ totaltime }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row style="margin-top: -15px">
          <v-col cols="12" sm="6" md="6">
            <p class="txt14">
              {{ $t("TotalOTHours") }}:
              <span class="ml-2">{{ ottotaltime }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <p class="txt14">
              {{ $t("shiftStartTime") }}:
              <span class="ml-2">{{ shiftstart }}</span>
            </p>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <p class="txt14">
              {{ $t("otStartTime") }}:
              <span class="ml-2">{{ otshiftstart }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row style="margin-top: -15px">
          <v-col cols="12" sm="3" md="3">
            <p class="txt14">
              {{ $t("shiftEndTime") }}:
              <span class="ml-2">{{ shiftend }}</span>
            </p>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <p class="txt14">
              {{ $t("otEndTime") }}:
              <span class="ml-2">{{ otshiftend }}</span>
            </p>
          </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-data-table
                    :headers="headers"
                    :items="TimetableItem"
                    hide-default-footer
                    hide-default-header
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:header="{ props: {} }">
                      <thead style="background-color: #f99d20">
                        <tr>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("PunchType") }}
                          </th>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("Time") }}
                          </th>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("Location") }}
                          </th>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("Late") }}
                          </th>
                          <th class="white--text text-center">
                            {{ $t("shifttimeindetail") }}
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f"
                        >
                          {{ item.PunchType }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ Converttolocal(item.Time) }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ item.Location }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ item.Late }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ item.shifttime }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <!-- OT Table -->
              <v-row class="mt-5">
                <v-col cols="12" sm="12" md="12">
                  <v-data-table
                    :headers="headers"
                    :items="OTTimetableItem"
                    hide-default-footer
                    hide-default-header
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:header="{ props: {} }">
                      <thead style="background-color: #f99d20">
                        <tr>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("PunchType") }}
                          </th>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("Time") }}
                          </th>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("Location") }}
                          </th>
                          <th
                            style="border-right: 1px solid #ffffff"
                            class="white--text text-center"
                          >
                            {{ $t("Late") }}
                          </th>
                          <th class="white--text text-center">
                            {{ $t("shifttimeindetail") }}
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f"
                        >
                          {{ item.PunchType }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ Converttolocal(item.Time) }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ item.Location }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ item.Late }}
                        </td>
                        <td
                          class="text-start"
                          style="border: 1px solid #fcce8f; border-left: 0"
                        >
                          {{ item.shifttime }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
            <!-- Irregular table -->
            <v-col cols="12" sm="6" md="4">
              <v-data-table
                class="mt-3"
                :items="IrregularList"
                hide-default-footer
                hide-default-header
                :mobile-breakpoint="0"
              >
                <template v-slot:header="{ props: {} }">
                  <thead
                    :style="{
                      background: dissave == true ? '#8a979c' : ' #0fa7d8',
                    }"
                  >
                    <tr>
                      <th colspan="3" class="white--text text-center">
                        {{ $t("Irregularities") }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      class="text-start"
                      :style="{
                        color: dissave == true ? '#8a979c' : ' #0fa7d8',
                      }"
                      style="border: 1px solid; min-width: 145px; padding: 0"
                    >
                      <v-autocomplete
                        v-model="item.PunchType"
                        :items="items"
                        flat
                        class="mt-3"
                        dense
                        style="font-size: 15px"
                        solo
                        :disabled="dissave"
                      ></v-autocomplete>
                    </td>
                    <td
                      class="text-center"
                      :style="{
                        color: dissave == true ? '#8a979c' : ' #0fa7d8',
                      }"
                      style="border: 1px solid; border-left: 0"
                    >
                      <span style="color: #121212">{{
                        Converttolocal1(item.Time)
                      }}</span>
                    </td>
                    <td
                      class="text-center"
                      :style="{
                        color: dissave == true ? '#8a979c' : ' #0fa7d8',
                      }"
                      style="border: 1px solid; border-left: 0"
                    >
                      <div class="my-2">
                        <v-btn
                          @click="AddToOther(item)"
                          :disabled="dissave"
                          width="auto"
                          height="30px"
                          class="white--text text-capitalize"
                          color="#0FA7D8"
                        >
                          {{ $t("Add") }}</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-data-table
              :headers="headers1"
              :items="ottotableitems"
              hide-default-footer
              hide-default-header
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead style="background-color: #f99d20">
                  <tr>
                    <th
                      class="white--text text-center"
                      style="border-right: 1px solid #ffffff"
                    >
                      {{ $t("TimeOffType") }}
                    </th>
                    <th
                      class="white--text text-center"
                      style="border-right: 1px solid #ffffff"
                    >
                      {{ $t("Hours") }}
                    </th>
                    <!-- <th class="white--text text-center">OT</th> -->
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-start" style="border: 1px solid #fcce8f">
                    <!-- <v-autocomplete
                      v-model="leavevalue"
                      :items="leaveitems"
                      flat
                      class="mt-3 yellow_drpdown"
                      dense
                      solo
                    ></v-autocomplete> -->
                    {{ item.Type }}
                  </td>
                  <td
                    class="text-start"
                    style="border: 1px solid #fcce8f; border-left: 0"
                  >
                    {{ item.Time }}
                  </td>
                  <!-- <td
                    class="text-center"
                    style="border: 1px solid #fcce8f; border-left: 0"
                  >
                    {{ item.OverTime }}
                  </td> -->
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-row> {{ $t("manualtimeadd") }} : </v-row>
            <v-row>
              <span style="white-space: pre-line">
                {{ thetimes }}
              </span>
            </v-row>
          </v-col>
        </v-row>
        <div
          class="d-flex flex-row-reverse"
          style="
            margin-top: 3rem;
            padding-bottom: 3rem;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
          "
        >
          <div>
            <v-btn
              class="pa-2 mr-3 white--text text_transform_none cancle_btn commondeletebtn"
              :disabled="permission.edit"
              @click="ResetApprove"
              >{{ $t("resetdata") }}</v-btn
            >
            <v-btn
              outlined
              class="pa-2 mr-3 text_transform_none cancle_btn commoncancelbtn"
              :disabled="permission.edit || dissave"
              @click="goback()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="pa-2 mr-3 text_transform_none cancle_btn commoncancelbtn"
              :disabled="permission.edit || dissave"
              @click="UpdateIrregular"
              >{{ $t("Save/Approve") }}</v-btn
            >
          </div>
        </div>
        <div style="height: 10vh"></div>
        <v-dialog v-model="Approvedialog" persistent max-width="500px">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <span class="pl-2"> {{ $t("ConfrimToApprove") }}</span>
            </v-card-title>
            <v-card-text class="mt-4">
              <v-row justify="center">
                <p class="pl-5">{{ $t("ApproveTimeAttendance") }}</p>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-6" style="margin-right: 3%">
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize mr-2"
                  max-height="35"
                  :disabled="dissave"
                  width="120px"
                  @click="goback()"
                  text
                >
                  {{ $t("SaveOnly") }}</v-btn
                >
                <v-btn
                  color="#F99D20"
                  class="mr-2 white--text text-capitalize"
                  max-height="35"
                  :disabled="dissave"
                  width="120px"
                  @click="ApproveTimeAttendance()"
                >
                  {{ $t("confirm") }}</v-btn
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import LogTrace from "@/function/Log.js";
import enurl from "@/api/environment";
import CheckViewApproveSyncTimeExportEdit from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, CheckViewApproveSyncTimeExportEdit],
  data() {
    return {
      permission: {
        approve: true,
        export: true,
        edit: true,
      },
      url: enurl.apiUrl,
      values: ["In"],
      disapprove: true,
      LoadingDialog: false,
      profileimage: "",
      profilename: null,
      profileposition: null,
      profiledepartment: null,
      profiledate: "",
      approver: null,
      clockID: 0,
      dissave: false,
      clocklist: [],
      totaltime: null,
      ottotaltime: null,
      timetype: null,
      shiftstart: null,
      shiftend: null,
      otshiftstart: null,
      otshiftend: null,
      remark: null,
      TimetableItem: [],
      OTTimetableItem: [],
      TimeSettingList: [],
      IrregularList: [],
      thetimes: null,
      startbefore: null,
      startbeforetime: null,
      startafter: null,
      startaftertime: null,
      endbefore: null,
      endbeforetime: null,
      endafter: null,
      endaftertime: null,
      showApproveBtn: true,
      showholiday: false,
      holiday_name: null,
      updateregcount: 0,
      updateotcount: 0,
      someid: 0,
      timeID: 0,
      leaveitems: [
        "Sick Leave",
        "Personal Leave",
        "Leave Without Pay",
        "Vacation",
      ],
      items: ["Regular In", "Regular Out", "Overtime In", "Overtime Out"],
      headers: [
        {
          text: "Punch Type",
          value: "PunchType",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "Time",
          align: "left",
          sortable: false,
        },
        {
          text: "Location",
          value: "Location",
          align: "center",
          sortable: false,
        },
        {
          text: "Late",
          value: "Late",
          align: "center",
          sortable: false,
        },
        {
          text: "Shift Time",
          value: "shifttime",
          align: "center",
          sortable: false,
        },
      ],
      ottotableitems: [],
      headers1: [
        {
          text: "Detail",
          value: "date",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "sst",
          align: "center",
          sortable: false,
        },
      ],
      desserts: [
        {
          PunchType: "Overtime In",
          Time: "-",
          Location: "-",
          Late: "-",
          shifttime: "-",
        },
        {
          PunchType: "Overtime Out",
          Time: "-",
          Location: "-",
          Late: "-",
          shifttime: "-",
        },
      ],
      calendarView: false,
      Approvedialog: false,
    };
  },
  mounted() {
    this.GetTimeAttSetting();
    this.GetSheetDetail();
    this.GetManualTimes();
    this.permission = this.CheckViewApproveSyncTimeExportEdit(10, 11, 12, 13);
  },
  methods: {
    GetManualTimes() {
      let self = this;
      let NowDay = JSON.parse(localStorage.getItem("get_date_of_today"));
      let temp = {
        employee_ID: parseInt(self.$route.params.id),
        company_ID: store.state.companyID,
        date: self.formatDate(NowDay),
      };
      axios
        .post(`${self.url}ClockInOut/GetClockInOutByManual`, temp)
        .then(function (response) {
          let tempa = response.data.data;
          let arr = [];
          for (let i = 0; i < tempa.length; i++) {
            if (tempa[i].clock_In_Time != "-") {
              let theword = "";
              if (tempa[i].time_Type == "Regular") {
                theword = (
                  self.Converttolocal1(tempa[i].clock_In_Time) +
                  " (Regular In) by " +
                  tempa[i].adder_Name
                ).replace(/^[ ]+|[ ]+$/g, "");
              } else {
                theword =
                  self.Converttolocal1(tempa[i].clock_In_Time) +
                  " (Overtime In) by " +
                  tempa[i].adder_Name;
              }
              arr.push(theword);
            }
            if (tempa[i].clock_Out_Time != "-") {
              let theword = "";
              if (tempa[i].time_Type == "Regular") {
                theword =
                  self.Converttolocal1(tempa[i].clock_Out_Time) +
                  " (Regular Out) by " +
                  tempa[i].adder_Name;
              } else {
                theword =
                  self.Converttolocal1(tempa[i].clock_Out_Time) +
                  " (Overtime Out) by " +
                  tempa[i].adder_Name;
              }
              arr.push(theword);
            }
            if (tempa[i].irregular_In_Time != "-") {
              let theword = "";
              if (tempa[i].time_Type == "Regular") {
                theword =
                  self.Converttolocal1(tempa[i].irregular_In_Time) +
                  " " +
                  " (Regular In) by " +
                  tempa[i].adder_Name;
              } else {
                theword =
                  self.Converttolocal1(tempa[i].irregular_In_Time) +
                  " " +
                  " (Overtime In) by " +
                  tempa[i].adder_Name;
              }
              arr.push(theword);
            }
            if (tempa[i].irregular_Out_Time != "-") {
              let theword = "";
              if (tempa[i].time_Type == "Regular") {
                theword =
                  self.Converttolocal1(tempa[i].irregular_Out_Time) +
                  " (Regular Out) by " +
                  tempa[i].adder_Name;
              } else {
                theword =
                  self.Converttolocal1(tempa[i].irregular_Out_Time) +
                  " " +
                  " (Overtime Out) by " +
                  tempa[i].adder_Name;
              }
              arr.push(theword);
            }
          }
          let Message = [...new Set(arr)];
          self.thetimes = `${Message.join("\n")}`;
        });
    },
    GetTimeAttSetting() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,
          temp
        )
        .then(function (response) {
          self.TimeSettingList = response.data.data;
          let [stbefore, stbeforetime] =
            self.TimeSettingList[0].start_Time_Before.split(" ");
          let [stafter, staftertime] =
            self.TimeSettingList[0].start_Time_After.split(" ");
          self.startbefore = stbefore;
          self.startbeforetime = stbeforetime;
          self.startafter = stafter;
          self.startaftertime = staftertime;

          let [edbefore, edbeforetime] =
            self.TimeSettingList[0].end_Time_Before.split(" ");
          let [edafter, edaftertime] =
            self.TimeSettingList[0].end_Time_After.split(" ");
          self.endbefore = edbefore;
          self.endbeforetime = edbeforetime;
          self.endafter = edafter;
          self.endaftertime = edaftertime;
        });
    },
    GetSheetDetail() {
      let self = this;
      self.LoadingDialog = true;
      let NowDay = JSON.parse(localStorage.getItem("get_date_of_today"));
      let temp = {
        employee_ID: parseInt(self.$route.params.id),
        today_Date: self.formatDate(NowDay),
      };
      axios
        .post(`${self.url}Stafftimeattendance/GetEmployeeDataOfSTA`, temp)
        .then(function (response) {
          self.profileimage = response.data.data.image;
          self.profilename = response.data.data.fullName;
          self.profileposition = response.data.data.position_Name;
          self.profiledepartment = response.data.data.department_Name;
          self.profiledate = response.data.data.timeEntry_Date;
          self.shiftstart = JSON.parse(localStorage.getItem("shift_start"));
          self.shiftend = JSON.parse(localStorage.getItem("shift_end"));
          self.otshiftstart = JSON.parse(localStorage.getItem("otshift_start"));
          self.otshiftend = JSON.parse(localStorage.getItem("otshift_end"));
          self.remark = JSON.parse(localStorage.getItem("local_remark"));
          self.approver = response.data.data.approver_Name;
          self.timeID = response.data.data.time_ID;
          if (response.data.data.approve == true) {
            self.showApproveBtn = false;
            self.dissave = true;
          } else {
            self.showApproveBtn = true;
            self.dissave = false;
          }
          let temparr = [];

          let RegularArray = response.data.data.regCinoutData;
          let OvertimeArray = response.data.data.otCinoutData;
          let LateRegArray = response.data.data.irrRegCinoutData;
          let LateOTArray = response.data.data.irrOTCinoutData;
          self.updateregcount = RegularArray.length;
          self.updateotcount = OvertimeArray.length;

          for (let f = 0; f < RegularArray.length; f++) {
            self.clocklist.push({
              clock_ID: RegularArray[f].clock_ID,
            });
          }
          for (let r = 0; r < OvertimeArray.length; r++) {
            self.clocklist.push({
              clock_ID: OvertimeArray[r].clock_ID,
            });
          }

          for (let p = 0; p < LateRegArray.length; p++) {
            if (
              (LateRegArray[p].clock_In_Time != null &&
                LateRegArray[p].clock_In_Time != "-") ||
              (LateRegArray[p].clock_Out_Time != null &&
                LateRegArray[p].clock_Out_Time != "-")
            ) {
              temparr.push({
                clock_ID: LateRegArray[p].clock_ID,
                clock_In_Time: LateRegArray[p].clock_In_Time,
                clock_Out_Time: LateRegArray[p].clock_Out_Time,
                clock_In_Location: LateRegArray[p].clock_In_Location,
                clock_Out_Location: LateRegArray[p].clock_Out_Location,
                clock_In_Lattitude: LateRegArray[p].clock_In_Lattitude,
                clock_In_Longitude: LateRegArray[p].clock_In_Longitude,
                clock_Out_Lattitude: LateRegArray[p].clock_Out_Lattitude,
                clock_Out_Longitude: LateRegArray[p].clock_Out_Longitude,
                time_ID: LateRegArray[p].time_ID,
                time_Type: LateRegArray[p].time_Type,
              });
            }
          }
          for (let p = 0; p < LateOTArray.length; p++) {
            if (
              (LateOTArray[p].clock_In_Time != null &&
                LateOTArray[p].clock_In_Time != "-") ||
              (LateOTArray[p].clock_Out_Time != null &&
                LateOTArray[p].clock_Out_Time != "-")
            ) {
              temparr.push({
                clock_ID: LateOTArray[p].clock_ID,
                clock_In_Time: LateOTArray[p].clock_In_Time,
                clock_Out_Time: LateOTArray[p].clock_Out_Time,
                clock_In_Location: LateOTArray[p].clock_In_Location,
                clock_Out_Location: LateOTArray[p].clock_Out_Location,
                clock_In_Lattitude: LateOTArray[p].clock_In_Lattitude,
                clock_In_Longitude: LateOTArray[p].clock_In_Longitude,
                clock_Out_Lattitude: LateOTArray[p].clock_Out_Lattitude,
                clock_Out_Longitude: LateOTArray[p].clock_Out_Longitude,
                time_ID: LateOTArray[p].time_ID,
                time_Type: LateOTArray[p].time_Type,
              });
            }
          }

          //total time
          if (RegularArray.length != 0) {
            let cin = "-";
            let cout = "-";
            let leg = RegularArray.length - 1;
            let tin = RegularArray[0].clock_In_Time;
            let tout = RegularArray[leg].clock_Out_Time;
            let ltin = LateRegArray[0].clock_In_Time;
            let ltout = LateRegArray[leg].clock_Out_Time;
            if (tin != "-") {
              cin = tin;
            } else if (ltin != "-") {
              cin = ltin;
            }
            if (tout != "-") {
              cout = tout;
            } else if (ltout != "-") {
              cout = ltout;
            }
            if (cin != "-") {
              if (cout != "-") {
                let regfrom = new Date(Date.parse(cin));
                let regto = new Date(Date.parse(cout));
                var reghh = "";
                var regmm = "";
                if (regfrom.getDate() == regto.getDate()) {
                  let frr = regfrom.getHours();
                  let too = regto.getHours();
                  if (frr > too) {
                    let days = regto.getDate();
                    days = days + 1;
                    regto.setDate(days);
                  }
                  let frtimezone = regfrom.getTimezoneOffset() * 60000;
                  let totimezone = regto.getTimezoneOffset() * 60000;
                  let frnewDate = new Date(regfrom.getTime() - frtimezone);
                  let tonewDate = new Date(regto.getTime() - totimezone);
                  var regTimeDiffer1 = Math.abs(tonewDate - frnewDate);
                  reghh = Math.floor(regTimeDiffer1 / 1000 / 60 / 60);
                  if (reghh < 10) {
                    reghh = "0" + reghh;
                  }
                  regTimeDiffer1 -= reghh * 1000 * 60 * 60;
                  regmm = Math.floor(regTimeDiffer1 / 1000 / 60);
                  if (regmm < 10) {
                    regmm = "0" + regmm;
                  }
                } else {
                  let frtimezone = regfrom.getTimezoneOffset() * 60000;
                  let totimezone = regto.getTimezoneOffset() * 60000;
                  let frnewDate = new Date(regfrom.getTime() - frtimezone);
                  let tonewDate = new Date(regto.getTime() - totimezone);
                  var regTimeDiffer = Math.abs(tonewDate - frnewDate);
                  reghh = Math.floor(regTimeDiffer / 1000 / 60 / 60);
                  if (reghh < 10) {
                    reghh = "0" + reghh;
                  }
                  regTimeDiffer -= reghh * 1000 * 60 * 60;
                  regmm = Math.floor(regTimeDiffer / 1000 / 60);
                  if (regmm < 10) {
                    regmm = "0" + regmm;
                  }
                }
                self.totaltime = reghh + ":" + regmm + " ";
              } else {
                self.totaltime = "-";
              }
            } else {
              self.totaltime = "-";
            }
          } else {
            self.totaltime = "-";
          }
          //total time

          //ot total time
          if (OvertimeArray.length != 0) {
            let otcin = "-";
            let otcout = "-";
            let oleg = OvertimeArray.length - 1;
            let tin = OvertimeArray[0].clock_In_Time;
            let tout = OvertimeArray[oleg].clock_Out_Time;
            let ltin = LateOTArray[0].clock_In_Time;
            let ltout = LateOTArray[oleg].clock_Out_Time;
            if (tin != "-") {
              otcin = tin;
            } else if (ltin != "-") {
              otcin = ltin;
            }
            if (tout != "-") {
              otcout = tout;
            } else if (ltout != "-") {
              otcout = ltout;
            }
            if (otcin != "-") {
              if (otcout != "-") {
                let regfrom = new Date(Date.parse(otcin));
                let regto = new Date(Date.parse(otcout));
                var othh = "";
                var otmm = "";
                if (regfrom.getDate() == regto.getDate()) {
                  let frr = regfrom.getHours();
                  let too = regto.getHours();
                  if (frr > too) {
                    let days = regto.getDate();
                    days = days + 1;
                    regto.setDate(days);
                  }
                  let frtimezone = regfrom.getTimezoneOffset() * 60000;
                  let totimezone = regto.getTimezoneOffset() * 60000;
                  let frnewDate = new Date(regfrom.getTime() - frtimezone);
                  let tonewDate = new Date(regto.getTime() - totimezone);
                  var otTimeDiffer = Math.abs(tonewDate - frnewDate);
                  othh = Math.floor(otTimeDiffer / 1000 / 60 / 60);
                  if (othh < 10) {
                    othh = "0" + othh;
                  }
                  otTimeDiffer -= othh * 1000 * 60 * 60;
                  otmm = Math.floor(otTimeDiffer / 1000 / 60);
                  if (otmm < 10) {
                    otmm = "0" + otmm;
                  }
                } else {
                  let frtimezone = regfrom.getTimezoneOffset() * 60000;
                  let totimezone = regto.getTimezoneOffset() * 60000;
                  let frnewDate = new Date(regfrom.getTime() - frtimezone);
                  let tonewDate = new Date(regto.getTime() - totimezone);
                  var otTimeDiffer1 = Math.abs(tonewDate - frnewDate);
                  othh = Math.floor(otTimeDiffer1 / 1000 / 60 / 60);
                  if (othh < 10) {
                    othh = "0" + othh;
                  }
                  otTimeDiffer1 -= othh * 1000 * 60 * 60;
                  otmm = Math.floor(otTimeDiffer1 / 1000 / 60);
                  if (otmm < 10) {
                    otmm = "0" + otmm;
                  }
                }
                self.ottotaltime = othh + ":" + otmm + " ";
              } else {
                self.ottotaltime = "-";
              }
            } else {
              self.ottotaltime = "-";
            }
          } else {
            self.ottotaltime = "-";
          }
          //ot total time

          //Accept time
          //Regular Start
          let shiftbefore = new Date(Date.parse(self.profiledate));
          let shiftafter = new Date(Date.parse(self.profiledate));
          let [sshours, ssminutes] = self.shiftstart.split(":");
          shiftbefore.setHours(+sshours);
          shiftbefore.setMinutes(ssminutes);
          shiftafter.setHours(+sshours);
          shiftafter.setMinutes(ssminutes);
          let ssafterDate = new Date(shiftafter.getTime());
          let ssbeforeDate = new Date(shiftbefore.getTime());
          //Regular End
          let shiftendbefore = new Date(Date.parse(self.profiledate));
          let shiftendafter = new Date(Date.parse(self.profiledate));
          let [sehours, seminutes] = self.shiftend.split(":");
          shiftendbefore.setHours(+sehours);
          shiftendbefore.setMinutes(seminutes);
          shiftendbefore.setSeconds("0");
          shiftendbefore.setMilliseconds("0");
          shiftendafter.setHours(+sehours);
          shiftendafter.setMinutes(seminutes);

          let seafterDate = new Date(shiftendafter.getTime());
          let sebeforeDate = new Date(shiftendbefore.getTime());
          //OT Start
          let o1shiftbefore = new Date(Date.parse(self.profiledate));
          let o1shiftafter = new Date(Date.parse(self.profiledate));
          let [o1sshours, o1ssminutes] = self.otshiftstart.split(":");
          o1shiftbefore.setHours(+o1sshours);
          o1shiftbefore.setMinutes(o1ssminutes);
          o1shiftafter.setHours(+o1sshours);
          o1shiftafter.setMinutes(o1ssminutes);
          if (self.startbeforetime == "hour") {
            let adds = o1shiftbefore.getHours() - parseInt(self.startbefore);
            o1shiftbefore.setHours(+adds);
          } else if (self.startbeforetime == "min") {
            let adds = o1shiftbefore.getMinutes() - parseInt(self.startbefore);
            o1shiftbefore.setMinutes(adds);
          }
          if (self.startaftertime == "hour") {
            let adds = o1shiftafter.getHours() + parseInt(self.startafter);
            o1shiftafter.setHours(+adds);
          } else if (self.startaftertime == "min") {
            let adds = o1shiftafter.getMinutes() + parseInt(self.startafter);
            o1shiftafter.setMinutes(adds);
          }
          let osafterDate = new Date(o1shiftafter.getTime());
          let osbeforeDate = new Date(o1shiftbefore.getTime());
          //OT End
          let o2shiftendbefore = new Date(Date.parse(self.profiledate));
          let o2shiftendafter = new Date(Date.parse(self.profiledate));
          let [o2sehours, o2seminutes] = self.shiftend.split(":");
          o2shiftendbefore.setHours(+o2sehours);
          o2shiftendbefore.setMinutes(o2seminutes);
          o2shiftendbefore.setSeconds("0");
          o2shiftendbefore.setMilliseconds("0");
          o2shiftendafter.setHours(+o2sehours);
          o2shiftendafter.setMinutes(o2seminutes);
          if (self.endbeforetime == "hour") {
            let adds = o2shiftendbefore.getHours() - parseInt(self.endbefore);
            o2shiftendbefore.setHours(+adds);
          } else if (self.endbeforetime == "min") {
            let adds = o2shiftendbefore.getMinutes() - parseInt(self.endbefore);
            o2shiftendbefore.setMinutes(adds);
          }
          if (self.endaftertime == "hour") {
            let adds = o2shiftendafter.getHours() + parseInt(self.endafter);
            o2shiftendafter.setHours(+adds);
          } else if (self.endaftertime == "min") {
            let adds = o2shiftendafter.getMinutes() + parseInt(self.endafter);
            o2shiftendafter.setMinutes(adds);
          }
          let oeafterDate = new Date(o2shiftendafter.getTime());
          let oebeforeDate = new Date(o2shiftendbefore.getTime());
          //Accept time
          let randomnum = 0;
          // For Regular Punch Type Table
          if (RegularArray.length != 0) {
            let sslatetime = "";
            let clockin = "-";
            let incon = 0;
            let outcon = 0;
            let clockout = "-";
            let outlist = [];

            for (let a = 0; a < RegularArray.length; a++) {
              if (RegularArray[a].clock_In_Time != "-") {
                if (self.TimetableItem.length != 0) {
                  if (incon != 1) {
                    if (self.TimetableItem[0].Time != "-") {
                      clockin = RegularArray[a].clock_In_Time;
                      let rfrom = new Date(Date.parse(clockin));
                      let frtimezone = rfrom.getTimezoneOffset() * 60000;
                      let frnewDate = new Date(rfrom.getTime() - frtimezone);
                      if (frnewDate > ssafterDate) {
                        var rtimeDiff = Math.abs(frnewDate - ssafterDate);
                        var rhh = Math.floor(rtimeDiff / 1000 / 60 / 60);
                        if (rhh < 10) {
                          rhh = "0" + rhh;
                        }
                        rtimeDiff -= rhh * 1000 * 60 * 60;
                        var rmm = Math.floor(rtimeDiff / 1000 / 60);
                        if (rmm < 10) {
                          rmm = "0" + rmm;
                        }
                        sslatetime = rhh + ":" + rmm + " Hours";
                        self.TimetableItem.push({
                          PunchType: "Regular In",
                          Time: clockin,
                          clock_ID: RegularArray[a].clock_ID,
                          time_ID: RegularArray[a].time_ID,
                          Location: RegularArray[a].clock_In_Location,
                          Lat: RegularArray[a].clock_In_Lattitude,
                          Lang: RegularArray[a].clock_In_Longitude,
                          Late: sslatetime,
                          shifttime: self.shiftstart,
                        });
                      } else if (ssbeforeDate > frnewDate) {
                        self.TimetableItem.push({
                          PunchType: "Regular In",
                          Time: clockin,
                          clock_ID: RegularArray[a].clock_ID,
                          time_ID: RegularArray[a].time_ID,
                          Location: RegularArray[a].clock_In_Location,
                          Lat: RegularArray[a].clock_In_Lattitude,
                          Lang: RegularArray[a].clock_In_Longitude,
                          Late: "-",
                          shifttime: self.shiftstart,
                        });
                      } else {
                        self.TimetableItem.push({
                          PunchType: "Regular In",
                          Time: clockin,
                          clock_ID: RegularArray[a].clock_ID,
                          time_ID: RegularArray[a].time_ID,
                          Location: RegularArray[a].clock_In_Location,
                          Lat: RegularArray[a].clock_In_Lattitude,
                          Lang: RegularArray[a].clock_In_Longitude,
                          Late: "-",
                          shifttime: self.shiftstart,
                        });
                      }
                      incon = 1;
                    }
                  } else {
                    randomnum = randomnum + 1;
                    self.IrregularList.push({
                      id: randomnum,
                      PunchType: "Regular In",
                      Time: RegularArray[a].clock_In_Time,
                      clock_ID: RegularArray[a].clock_ID,
                      time_ID: RegularArray[a].time_ID,
                      Location: RegularArray[a].clock_In_Location,
                      Lat: RegularArray[a].clock_In_Lattitude,
                      Lang: RegularArray[a].clock_In_Longitude,
                      Late: sslatetime,
                      shifttime: self.shiftstart,
                    });
                  }
                } else {
                  clockin = RegularArray[a].clock_In_Time;
                  let rfrom = new Date(Date.parse(clockin));
                  let frtimezone = rfrom.getTimezoneOffset() * 60000;
                  let frnewDate = new Date(rfrom.getTime() - frtimezone);
                  if (frnewDate > ssafterDate) {
                    var rtimeDiff1 = Math.abs(frnewDate - ssafterDate);
                    var rhh1 = Math.floor(rtimeDiff1 / 1000 / 60 / 60);
                    if (rhh1 < 10) {
                      rhh1 = "0" + rhh1;
                    }
                    rtimeDiff1 -= rhh1 * 1000 * 60 * 60;
                    var rmm1 = Math.floor(rtimeDiff1 / 1000 / 60);
                    if (rmm1 < 10) {
                      rmm1 = "0" + rmm1;
                    }
                    sslatetime = rhh1 + ":" + rmm1 + " Hours";
                    self.TimetableItem.push({
                      PunchType: "Regular In",
                      Time: clockin,
                      clock_ID: RegularArray[a].clock_ID,
                      time_ID: RegularArray[a].time_ID,
                      Location: RegularArray[a].clock_In_Location,
                      Lat: RegularArray[a].clock_In_Lattitude,
                      Lang: RegularArray[a].clock_In_Longitude,
                      Late: sslatetime,
                      shifttime: self.shiftstart,
                    });
                  } else if (ssbeforeDate > frnewDate) {
                    self.TimetableItem.push({
                      PunchType: "Regular In",
                      Time: clockin,
                      clock_ID: RegularArray[a].clock_ID,
                      time_ID: RegularArray[a].time_ID,
                      Location: RegularArray[a].clock_In_Location,
                      Lat: RegularArray[a].clock_In_Lattitude,
                      Lang: RegularArray[a].clock_In_Longitude,
                      Late: "-",
                      shifttime: self.shiftstart,
                    });
                  } else {
                    self.TimetableItem.push({
                      PunchType: "Regular In",
                      Time: clockin,
                      clock_ID: RegularArray[a].clock_ID,
                      time_ID: RegularArray[a].time_ID,
                      Location: RegularArray[a].clock_In_Location,
                      Lat: RegularArray[a].clock_In_Lattitude,
                      Lang: RegularArray[a].clock_In_Longitude,
                      Late: "-",
                      shifttime: self.shiftstart,
                    });
                  }
                  incon = 1;
                }
              }
              let Lists = {
                ...RegularArray[a],
              };
              outlist.unshift(Lists);
            }
            if (incon != 1) {
              self.TimetableItem.push({
                PunchType: "Regular In",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.shiftstart,
              });
            }

            for (let b = 0; b < outlist.length; b++) {
              if (outlist[b].clock_Out_Time != "-") {
                if (outcon != 1) {
                  clockout = outlist[b].clock_Out_Time;
                  let rto = new Date(Date.parse(clockout));
                  let rtotimezone = rto.getTimezoneOffset() * 60000;
                  let rtonewDate = new Date(rto.getTime() - rtotimezone);
                  if (rtonewDate > seafterDate) {
                    var retimeDiff = Math.abs(rtonewDate - seafterDate);
                    var rehh = Math.floor(retimeDiff / 1000 / 60 / 60);
                    if (rehh < 10) {
                      rehh = "0" + rehh;
                    }
                    retimeDiff -= rehh * 1000 * 60 * 60;
                    var remm = Math.floor(retimeDiff / 1000 / 60);
                    if (remm < 10) {
                      remm = "0" + remm;
                    }
                    self.TimetableItem.push({
                      PunchType: "Regular Out",
                      Time: clockout,
                      clock_ID: outlist[b].clock_ID,
                      time_ID: outlist[b].time_ID,
                      Location: outlist[b].clock_Out_Location,
                      Lat: outlist[b].clock_Out_Lattitude,
                      Lang: outlist[b].clock_Out_Longitude,
                      Late: "-",
                      shifttime: self.shiftend,
                    });
                  } else if (sebeforeDate > rtonewDate) {
                    self.TimetableItem.push({
                      PunchType: "Regular Out",
                      Time: clockout,
                      clock_ID: outlist[b].clock_ID,
                      time_ID: outlist[b].time_ID,
                      Location: outlist[b].clock_Out_Location,
                      Lat: outlist[b].clock_Out_Lattitude,
                      Lang: outlist[b].clock_Out_Longitude,
                      Late: "-",
                      shifttime: self.shiftend,
                    });
                  } else {
                    self.TimetableItem.push({
                      PunchType: "Regular Out",
                      Time: clockout,
                      clock_ID: outlist[b].clock_ID,
                      time_ID: outlist[b].time_ID,
                      Location: outlist[b].clock_Out_Location,
                      Lat: outlist[b].clock_Out_Lattitude,
                      Lang: outlist[b].clock_Out_Longitude,
                      Late: "-",
                      shifttime: self.shiftend,
                    });
                  }
                  outcon = 1;
                } else {
                  randomnum = randomnum + 1;
                  self.IrregularList.push({
                    id: randomnum,
                    PunchType: "Regular Out",
                    Time: outlist[b].clock_Out_Time,
                    clock_ID: outlist[b].clock_ID,
                    time_ID: outlist[b].time_ID,
                    Location: outlist[b].clock_Out_Location,
                    Lat: outlist[b].clock_Out_Lattitude,
                    Lang: outlist[b].clock_Out_Longitude,
                    Late: "-",
                    shifttime: self.shiftend,
                  });
                }
              }
            }
            if (outcon != 1) {
              self.TimetableItem.push({
                PunchType: "Regular Out",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.shiftend,
              });
            }
            if (
              clockin == "-" &&
              clockout == "-" &&
              self.TimetableItem.length == 0
            ) {
              self.TimetableItem.push(
                {
                  PunchType: "Regular In",
                  Time: "-",
                  Location: "-",
                  clock_ID: 0,
                  Late: "-",
                  shifttime: self.shiftstart,
                },
                {
                  PunchType: "Regular Out",
                  Time: "-",
                  Location: "-",
                  clock_ID: 0,
                  Late: "-",
                  shifttime: self.shiftend,
                }
              );
            }
          } else {
            self.TimetableItem.push(
              {
                PunchType: "Regular In",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.shiftstart,
              },
              {
                PunchType: "Regular Out",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.shiftend,
              }
            );
          }
          // For Regular Punch Type Table

          // For OverTime Table
          if (OvertimeArray.length != 0) {
            let otsslatetime = "";
            let otcount = OvertimeArray.length - 1;
            let otclockin = OvertimeArray[0].clock_In_Time;
            let otclockout = OvertimeArray[otcount].clock_Out_Time;
            let otincon = 0;
            let otoutcon = 0;
            let otoutlist = [];

            for (let a = 0; a < OvertimeArray.length; a++) {
              if (OvertimeArray[a].clock_In_Time != "-") {
                if (self.OTTimetableItem.length != 0) {
                  if (otincon != 1) {
                    if (self.OTTimetableItem[0].Time != "-") {
                      otclockin = OvertimeArray[a].clock_In_Time;
                      let otfrom = new Date(Date.parse(otclockin));
                      let otfrtimezone = otfrom.getTimezoneOffset() * 60000;
                      let otfrnewDate = new Date(
                        otfrom.getTime() - otfrtimezone
                      );
                      if (otfrnewDate > osafterDate) {
                        var ottimeDiff = Math.abs(otfrnewDate - osafterDate);
                        var othh1 = Math.floor(ottimeDiff / 1000 / 60 / 60);
                        if (othh1 < 10) {
                          othh1 = "0" + othh1;
                        }
                        ottimeDiff -= othh1 * 1000 * 60 * 60;
                        var otmm1 = Math.floor(ottimeDiff / 1000 / 60);
                        if (otmm1 < 10) {
                          otmm1 = "0" + otmm1;
                        }
                        otsslatetime = othh1 + ":" + otmm1 + " Hours";
                        self.OTTimetableItem.push({
                          PunchType: "Overtime In",
                          Time: otclockin,
                          clock_ID: OvertimeArray[a].clock_ID,
                          time_ID: OvertimeArray[a].time_ID,
                          Location: OvertimeArray[a].clock_In_Location,
                          Lat: OvertimeArray[a].clock_In_Lattitude,
                          Lang: OvertimeArray[a].clock_In_Longitude,
                          Late: otsslatetime,
                          shifttime: self.otshiftstart,
                        });
                      } else if (osbeforeDate > otfrnewDate) {
                        self.OTTimetableItem.push({
                          PunchType: "Overtime In",
                          Time: otclockin,
                          clock_ID: OvertimeArray[a].clock_ID,
                          time_ID: OvertimeArray[a].time_ID,
                          Location: OvertimeArray[a].clock_In_Location,
                          Lat: OvertimeArray[a].clock_In_Lattitude,
                          Lang: OvertimeArray[a].clock_In_Longitude,
                          Late: "-",
                          shifttime: self.otshiftstart,
                        });
                      } else {
                        self.OTTimetableItem.push({
                          PunchType: "Overtime In",
                          Time: otclockin,
                          clock_ID: OvertimeArray[a].clock_ID,
                          time_ID: OvertimeArray[a].time_ID,
                          Location: OvertimeArray[a].clock_In_Location,
                          Lat: OvertimeArray[a].clock_In_Lattitude,
                          Lang: OvertimeArray[a].clock_In_Longitude,
                          Late: "-",
                          shifttime: self.otshiftstart,
                        });
                      }
                      otincon = 1;
                    }
                  } else {
                    randomnum = randomnum + 1;
                    self.IrregularList.push({
                      id: randomnum,
                      PunchType: "Overtime In",
                      Time: OvertimeArray[a].clock_In_Time,
                      clock_ID: OvertimeArray[a].clock_ID,
                      time_ID: OvertimeArray[a].time_ID,
                      Location: OvertimeArray[a].clock_In_Location,
                      Lat: OvertimeArray[a].clock_In_Lattitude,
                      Lang: OvertimeArray[a].clock_In_Longitude,
                      Late: otsslatetime,
                      shifttime: self.otshiftstart,
                    });
                  }
                } else {
                  otclockin = OvertimeArray[a].clock_In_Time;
                  let otfrom = new Date(Date.parse(otclockin));
                  let otfrtimezone = otfrom.getTimezoneOffset() * 60000;
                  let otfrnewDate = new Date(otfrom.getTime() - otfrtimezone);
                  if (otfrnewDate > osafterDate) {
                    var ottimeDiff1 = Math.abs(otfrnewDate - osafterDate);
                    var othh11 = Math.floor(ottimeDiff1 / 1000 / 60 / 60);
                    if (othh11 < 10) {
                      othh11 = "0" + othh11;
                    }
                    ottimeDiff1 -= othh11 * 1000 * 60 * 60;
                    var otmm11 = Math.floor(ottimeDiff1 / 1000 / 60);
                    if (otmm11 < 10) {
                      otmm11 = "0" + otmm11;
                    }
                    otsslatetime = othh11 + ":" + otmm11 + " Hours";
                    self.OTTimetableItem.push({
                      PunchType: "Overtime In",
                      Time: otclockin,
                      clock_ID: OvertimeArray[a].clock_ID,
                      time_ID: OvertimeArray[a].time_ID,
                      Location: OvertimeArray[a].clock_In_Location,
                      Lat: OvertimeArray[a].clock_In_Lattitude,
                      Lang: OvertimeArray[a].clock_In_Longitude,
                      Late: otsslatetime,
                      shifttime: self.otshiftstart,
                    });
                  } else if (osbeforeDate > otfrnewDate) {
                    self.OTTimetableItem.push({
                      PunchType: "Overtime In",
                      Time: otclockin,
                      clock_ID: OvertimeArray[a].clock_ID,
                      time_ID: OvertimeArray[a].time_ID,
                      Location: OvertimeArray[a].clock_In_Location,
                      Lat: OvertimeArray[a].clock_In_Lattitude,
                      Lang: OvertimeArray[a].clock_In_Longitude,
                      Late: "-",
                      shifttime: self.otshiftstart,
                    });
                  } else {
                    self.OTTimetableItem.push({
                      PunchType: "Overtime In",
                      Time: otclockin,
                      clock_ID: OvertimeArray[a].clock_ID,
                      time_ID: OvertimeArray[a].time_ID,
                      Location: OvertimeArray[a].clock_In_Location,
                      Lat: OvertimeArray[a].clock_In_Lattitude,
                      Lang: OvertimeArray[a].clock_In_Longitude,
                      Late: "-",
                      shifttime: self.otshiftstart,
                    });
                  }
                  otincon = 1;
                }
              }
              let Lists = {
                ...OvertimeArray[a],
              };
              otoutlist.unshift(Lists);
            }
            if (otincon != 1) {
              self.OTTimetableItem.push({
                PunchType: "Overtime In",
                Time: "-",
                clock_ID: 0,
                Location: "-",
                Late: "-",
                shifttime: self.otshiftstart,
              });
            }

            for (let b = 0; b < otoutlist.length; b++) {
              if (otoutlist[b].clock_Out_Time != "-") {
                let oto = new Date(Date.parse(otclockout));
                let ototimezone = oto.getTimezoneOffset() * 60000;
                let otonewDate = new Date(oto.getTime() - ototimezone);
                if (otonewDate > oeafterDate) {
                  var otimeDiff = Math.abs(otonewDate - oeafterDate);
                  var ohh = Math.floor(otimeDiff / 1000 / 60 / 60);
                  if (ohh < 10) {
                    ohh = "0" + ohh;
                  }
                  otimeDiff -= ohh * 1000 * 60 * 60;
                  var omm = Math.floor(otimeDiff / 1000 / 60);
                  if (omm < 10) {
                    omm = "0" + omm;
                  }
                  self.OTTimetableItem.push({
                    PunchType: "Overtime Out",
                    Time: otclockout,
                    clock_ID: OvertimeArray[otcount].clock_ID,
                    time_ID: OvertimeArray[otcount].time_ID,
                    Location: OvertimeArray[otcount].clock_Out_Location,
                    Lat: OvertimeArray[otcount].clock_Out_Lattitude,
                    Lang: OvertimeArray[otcount].clock_Out_Longitude,
                    Late: "-",
                    shifttime: self.otshiftend,
                  });
                } else if (oebeforeDate > otonewDate) {
                  self.OTTimetableItem.push({
                    PunchType: "Overtime Out",
                    Time: otclockout,
                    clock_ID: OvertimeArray[otcount].clock_ID,
                    time_ID: OvertimeArray[otcount].time_ID,
                    Location: OvertimeArray[otcount].clock_Out_Location,
                    Lat: OvertimeArray[otcount].clock_Out_Lattitude,
                    Lang: OvertimeArray[otcount].clock_Out_Longitude,
                    Late: "-",
                    shifttime: self.otshiftend,
                  });
                } else {
                  self.OTTimetableItem.push({
                    PunchType: "Overtime Out",
                    Time: otclockout,
                    clock_ID: OvertimeArray[otcount].clock_ID,
                    time_ID: OvertimeArray[otcount].time_ID,
                    Location: OvertimeArray[otcount].clock_Out_Location,
                    Lat: OvertimeArray[otcount].clock_Out_Lattitude,
                    Lang: OvertimeArray[otcount].clock_Out_Longitude,
                    Late: "-",
                    shifttime: self.otshiftend,
                  });
                }
                otoutcon = 1;
              }
            }
            if (otoutcon != 1) {
              self.OTTimetableItem.push({
                PunchType: "Overtime Out",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.otshiftend,
              });
            }
            if (
              otclockin == "-" &&
              otclockout == "-" &&
              self.OTTimetableItem.length == 0
            ) {
              self.OTTimetableItem.push(
                {
                  PunchType: "Overtime In",
                  Time: "-",
                  Location: "-",
                  clock_ID: 0,
                  Late: "-",
                  shifttime: self.otshiftstart,
                },
                {
                  PunchType: "Overtime Out",
                  Time: "-",
                  Location: "-",
                  clock_ID: 0,
                  Late: "-",
                  shifttime: self.otshiftend,
                }
              );
            }
          } else {
            self.OTTimetableItem.push(
              {
                PunchType: "Overtime In",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.otshiftstart,
              },
              {
                PunchType: "Overtime Out",
                Time: "-",
                Location: "-",
                clock_ID: 0,
                Late: "-",
                shifttime: self.otshiftend,
              }
            );
          }
          // For OverTime Table

          //For Irregular Table

          for (let k = 0; k < temparr.length; k++) {
            if (temparr[k].time_Type == "Regular") {
              if (temparr[k].clock_In_Time != "-") {
                randomnum = randomnum + 1;
                self.IrregularList.push({
                  id: randomnum,
                  clock_ID: temparr[k].clock_ID,
                  time_ID: temparr[k].time_ID,
                  PunchType: "Regular In",
                  Location: temparr[k].clock_In_Location,
                  Lat: temparr[k].clock_In_Lattitude,
                  Lang: temparr[k].clock_In_Longitude,
                  Time: temparr[k].clock_In_Time,
                  shifttime: self.shiftstart,
                });
              }
              if (temparr[k].clock_Out_Time != "-") {
                randomnum = randomnum + 1;
                self.IrregularList.push({
                  id: randomnum,
                  clock_ID: temparr[k].clock_ID,
                  time_ID: temparr[k].time_ID,
                  PunchType: "Regular Out",
                  Location: temparr[k].clock_Out_Location,
                  Lat: temparr[k].clock_Out_Lattitude,
                  Lang: temparr[k].clock_Out_Longitude,
                  Time: temparr[k].clock_Out_Time,
                  shifttime: self.shiftend,
                });
              }
            } else {
              if (temparr[k].clock_In_Time != "-") {
                randomnum = randomnum + 1;
                self.IrregularList.push({
                  id: randomnum,
                  clock_ID: temparr[k].clock_ID,
                  time_ID: temparr[k].time_ID,
                  PunchType: "Overtime In",
                  Location: temparr[k].clock_In_Location,
                  Lat: temparr[k].clock_In_Lattitude,
                  Lang: temparr[k].clock_In_Longitude,
                  Time: temparr[k].clock_In_Time,
                  shifttime: self.otshiftstart,
                });
              }
              if (temparr[k].clock_Out_Time != "-") {
                randomnum = randomnum + 1;
                self.IrregularList.push({
                  id: randomnum,
                  clock_ID: temparr[k].clock_ID,
                  time_ID: temparr[k].time_ID,
                  PunchType: "Overtime Out",
                  Location: temparr[k].clock_Out_Location,
                  Lat: temparr[k].clock_Out_Lattitude,
                  Lang: temparr[k].clock_Out_Longitude,
                  Time: temparr[k].clock_Out_Time,
                  shifttime: self.otshiftend,
                });
              }
            }
          }
          self.someid = randomnum;
          //For Irregular Table
          if (
            (self.TimetableItem[0].Time != "-" &&
              self.TimetableItem[1].Time != "-") ||
            (self.OTTimetableItem[0].Time != "-" &&
              self.OTTimetableItem[1].Time != "-")
          ) {
            self.disapprove = true;
          } else {
            self.disapprove = true;
          }
          if (
            self.remark != "View" &&
            self.remark != "Holiday" &&
            self.remark != "Over Time" && 
            self.remark != "Approved" 
          ) {
            let tc = JSON.parse(localStorage.getItem("timoffcon"));
            if (tc == false || tc == "false") {
              let st = JSON.parse(localStorage.getItem("toshift_start"));
              let et = JSON.parse(localStorage.getItem("toshift_end"));
              let rt = st + " - " + et;
              self.ottotableitems.push({
                detail: self.remark,
                time: rt,
              });
              self.showholiday = false;
            } else {
              let st = JSON.parse(localStorage.getItem("shift_start"));
              let et = JSON.parse(localStorage.getItem("shift_end"));
              if (st != "-") {
                let rt = st + " - " + et;
                self.ottotableitems.push({
                  Type: self.remark,
                  Time: rt,
                });
                self.showholiday = false;
              } else {
                self.holiday_name = self.remark;
                self.showholiday = true;
              }
            }
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get SheetDetail Fail", 23, "Critical");
          error = "There is no data for this date";
          alert(error);
          self.LoadingDialog = false;
        });
    },
    goback() {
      if (this.$route.name == "Timesheetdetail") {
        this.$router.push({ name: "StaffTimeAttendance" }).catch(() => {});
      } else {
        this.$router
          .push({
            name: "Monthsheetdetail",
            params: { id: this.$route.params.id },
          })
          .catch(() => {});
      }
    },
    GetLateTime(time, shift) {
      let self = this;
      let result = "";
      let localtime = self.Converttolocal2(time);
      let shifttime = new Date(Date.parse(localtime));
      let [hours, minutes] = shift.split(":");
      shifttime.setHours(+hours);
      shifttime.setMinutes(minutes);
      let CheckTime = new Date(shifttime.getTime());
      let intime = new Date(Date.parse(time));
      let timezone = intime.getTimezoneOffset() * 60000;
      let newDate = new Date(intime.getTime() - timezone);
      if (newDate > CheckTime) {
        var timeDiff = Math.abs(newDate - CheckTime);
        var hh = Math.floor(timeDiff / 1000 / 60 / 60);
        if (hh < 10) {
          hh = "0" + hh;
        }
        timeDiff -= hh * 1000 * 60 * 60;
        var mm = Math.floor(timeDiff / 1000 / 60);
        if (mm < 10) {
          mm = "0" + mm;
        }
        result = hh + ":" + mm + " Hours";
      } else {
        result = "-";
      }
      return result;
    },
    AddToOther(item) {
      let self = this;
      if (item.PunchType == "Regular In" || item.PunchType == "Regular Out") {
        if (self.shiftstart != "-" && self.shiftend != "-") {
          if (item.PunchType == "Regular In") {
            if (self.TimetableItem[0].Time != "-") {
              for (var a = 0; a < self.TimetableItem.length; a++) {
                if (self.TimetableItem[a].PunchType == item.PunchType) {
                  let iidd = 0;
                  if (self.TimetableItem[a].id == undefined) {
                    self.someid++;
                    iidd = self.someid;
                  } else {
                    iidd = self.TimetableItem[a].id;
                  }
                  self.someid++;
                  self.IrregularList.unshift({
                    PunchType: self.TimetableItem[a].PunchType,
                    Time: self.TimetableItem[a].Time,
                    Location: self.TimetableItem[a].Location,
                    Lat: self.TimetableItem[a].Lat,
                    Lang: self.TimetableItem[a].Lang,
                    clock_ID: self.TimetableItem[a].clock_ID,
                    time_ID: self.TimetableItem[a].time_ID,
                    id: iidd,
                    Late: "-",
                    shifttime: self.shiftstart,
                  });
                  self.TimetableItem.splice(a, 1);
                }
              }
              self.TimetableItem.unshift({
                PunchType: item.PunchType,
                Time: item.Time,
                Location: item.Location,
                Lat: item.Lat,
                Lang: item.Lang,
                id: item.id,
                clock_ID: item.clock_ID,
                time_ID: item.time_ID,
                Late: self.GetLateTime(item.Time, self.shiftstart),
                shifttime: self.shiftstart,
              });
              for (var b = 0; b < self.IrregularList.length; b++) {
                if (self.IrregularList[b].id == item.id) {
                  self.IrregularList.splice(b, 1);
                }
              }
            } else if (self.TimetableItem[0].Time == "-") {
              for (var h = 0; h < self.TimetableItem.length; h++) {
                if (self.TimetableItem[h].PunchType == item.PunchType) {
                  self.TimetableItem.splice(h, 1);
                }
              }
              self.TimetableItem.unshift({
                PunchType: item.PunchType,
                Time: item.Time,
                Location: item.Location,
                Lat: item.Lat,
                Lang: item.Lang,
                id: item.id,
                clock_ID: item.clock_ID,
                time_ID: item.time_ID,
                Late: self.GetLateTime(item.Time, self.shiftstart),
                shifttime: self.shiftstart,
              });
              for (var i = 0; i < self.IrregularList.length; i++) {
                if (self.IrregularList[i].id == item.id) {
                  self.IrregularList.splice(i, 1);
                }
              }
            }
          }
          if (item.PunchType == "Regular Out") {
            if (self.TimetableItem[1].Time != "-") {
              for (var c = 0; c < self.TimetableItem.length; c++) {
                if (self.TimetableItem[c].PunchType == item.PunchType) {
                  let iidd1 = 0;
                  if (self.TimetableItem[c].id == undefined) {
                    self.someid++;
                    iidd1 = self.someid;
                  } else {
                    iidd1 = self.TimetableItem[c].id;
                  }
                  self.IrregularList.unshift({
                    PunchType: self.TimetableItem[c].PunchType,
                    Time: self.TimetableItem[c].Time,
                    Location: self.TimetableItem[c].Location,
                    Lat: self.TimetableItem[c].Lat,
                    Lang: self.TimetableItem[c].Lang,
                    clock_ID: self.TimetableItem[c].clock_ID,
                    time_ID: self.TimetableItem[c].time_ID,
                    id: iidd1,
                    Late: "-",
                    shifttime: self.shiftstart,
                  });
                  self.TimetableItem.splice(c, 1);
                }
              }
              self.TimetableItem.push({
                PunchType: item.PunchType,
                Time: item.Time,
                Location: item.Location,
                Lat: item.Lat,
                Lang: item.Lang,
                id: item.id,
                clock_ID: item.clock_ID,
                time_ID: item.time_ID,
                Late: "-",
                shifttime: self.shiftend,
              });
              for (var d = 0; d < self.IrregularList.length; d++) {
                if (self.IrregularList[d].id == item.id) {
                  self.IrregularList.splice(d, 1);
                }
              }
            } else if (self.TimetableItem[1].Time == "-") {
              for (var j = 0; j < self.TimetableItem.length; j++) {
                if (self.TimetableItem[j].PunchType == item.PunchType) {
                  self.TimetableItem.splice(j, 1);
                }
              }
              self.TimetableItem.push({
                PunchType: item.PunchType,
                Time: item.Time,
                Location: item.Location,
                Lat: item.Lat,
                Lang: item.Lang,
                clock_ID: item.clock_ID,
                time_ID: item.time_ID,
                id: item.id,
                Late: "-",
                shifttime: self.shiftend,
              });
              for (var k = 0; k < self.IrregularList.length; k++) {
                if (self.IrregularList[k].id == item.id) {
                  self.IrregularList.splice(k, 1);
                }
              }
            }
          }
          if (
            self.TimetableItem[0].Time != "-" &&
            self.TimetableItem[1].Time != "-"
          ) {
            self.totaltime = "-";
            let cin = self.TimetableItem[0].Time;
            let cout = self.TimetableItem[1].Time;
            let regfrom = new Date(Date.parse(cin));
            let regto = new Date(Date.parse(cout));
            var reghh = "";
            var regmm = "";
            if (regfrom.getDate() == regto.getDate()) {
              let frr = regfrom.getHours();
              let too = regto.getHours();
              if (frr > too) {
                let days = regto.getDate();
                days = days + 1;
                regto.setDate(days);
              }
              let frtimezone = regfrom.getTimezoneOffset() * 60000;
              let totimezone = regto.getTimezoneOffset() * 60000;
              let frnewDate = new Date(regfrom.getTime() - frtimezone);
              let tonewDate = new Date(regto.getTime() - totimezone);
              var regTimeDiffer1 = Math.abs(tonewDate - frnewDate);
              reghh = Math.floor(regTimeDiffer1 / 1000 / 60 / 60);
              if (reghh < 10) {
                reghh = "0" + reghh;
              }
              regTimeDiffer1 -= reghh * 1000 * 60 * 60;
              regmm = Math.floor(regTimeDiffer1 / 1000 / 60);
              if (regmm < 10) {
                regmm = "0" + regmm;
              }
            } else {
              let frtimezone = regfrom.getTimezoneOffset() * 60000;
              let totimezone = regto.getTimezoneOffset() * 60000;
              let frnewDate = new Date(regfrom.getTime() - frtimezone);
              let tonewDate = new Date(regto.getTime() - totimezone);
              var regTimeDiffer = Math.abs(tonewDate - frnewDate);
              reghh = Math.floor(regTimeDiffer / 1000 / 60 / 60);
              if (reghh < 10) {
                reghh = "0" + reghh;
              }
              regTimeDiffer -= reghh * 1000 * 60 * 60;
              regmm = Math.floor(regTimeDiffer / 1000 / 60);
              if (regmm < 10) {
                regmm = "0" + regmm;
              }
            }
            self.totaltime = reghh + ":" + regmm + " ";
          }
        } else {
          alert("There is no Shift Time for today!");
        }
      } else {
        //if (self.otshiftstart != "-" && self.otshiftend != "-") {
        if (item.PunchType == "Overtime In") {
          if (self.OTTimetableItem[0].Time != "-") {
            for (var e = 0; e < self.OTTimetableItem.length; e++) {
              if (self.OTTimetableItem[e].PunchType == item.PunchType) {
                let iidd2 = 0;
                if (self.OTTimetableItem[e].id == undefined) {
                  self.someid++;
                  iidd2 = self.someid;
                } else {
                  iidd2 = self.OTTimetableItem[e].id;
                }
                self.IrregularList.unshift({
                  PunchType: self.OTTimetableItem[e].PunchType,
                  Time: self.OTTimetableItem[e].Time,
                  Location: self.OTTimetableItem[e].Location,
                  Lat: self.OTTimetableItem[e].Lat,
                  Lang: self.OTTimetableItem[e].Lang,
                  clock_ID: self.OTTimetableItem[e].clock_ID,
                  time_ID: self.OTTimetableItem[e].time_ID,
                  id: iidd2,
                  Late: "-",
                  shifttime: self.shiftstart,
                });
                self.OTTimetableItem.splice(e, 1);
              }
            }
            self.OTTimetableItem.unshift({
              PunchType: item.PunchType,
              Time: item.Time,
              Location: item.Location,
              Lat: item.Lat,
              Lang: item.Lang,
              id: item.id,
              clock_ID: item.clock_ID,
              time_ID: item.time_ID,
              Late: self.GetLateTime(item.Time, self.otshiftstart),
              shifttime: self.otshiftstart,
            });
            for (var f = 0; f < self.IrregularList.length; f++) {
              if (self.IrregularList[f].id == item.id) {
                self.IrregularList.splice(f, 1);
              }
            }
          } else if (self.OTTimetableItem[0].Time == "-") {
            for (var m = 0; m < self.OTTimetableItem.length; m++) {
              if (self.OTTimetableItem[m].PunchType == item.PunchType) {
                self.OTTimetableItem.splice(m, 1);
              }
            }
            self.OTTimetableItem.unshift({
              PunchType: item.PunchType,
              Time: item.Time,
              Location: item.Location,
              Lat: item.Lat,
              Lang: item.Lang,
              id: item.id,
              clock_ID: item.clock_ID,
              time_ID: item.time_ID,
              Late: self.GetLateTime(item.Time, self.otshiftstart),
              shifttime: self.otshiftstart,
            });
            for (var n = 0; n < self.IrregularList.length; n++) {
              if (self.IrregularList[n].id == item.id) {
                self.IrregularList.splice(n, 1);
              }
            }
          }
        }
        if (item.PunchType == "Overtime Out") {
          if (self.OTTimetableItem[1].Time != "-") {
            for (var g = 0; g < self.OTTimetableItem.length; g++) {
              if (self.OTTimetableItem[g].PunchType == item.PunchType) {
                let iidd3 = 0;
                if (self.OTTimetableItem[g].id == undefined) {
                  self.someid++;
                  iidd3 = self.someid;
                } else {
                  iidd3 = self.OTTimetableItem[g].id;
                }
                self.IrregularList.unshift({
                  PunchType: self.OTTimetableItem[g].PunchType,
                  Time: self.OTTimetableItem[g].Time,
                  Location: self.OTTimetableItem[g].Location,
                  Lat: self.OTTimetableItem[g].Lat,
                  Lang: self.OTTimetableItem[g].Lang,
                  clock_ID: self.OTTimetableItem[g].clock_ID,
                  time_ID: self.OTTimetableItem[g].time_ID,
                  id: iidd3,
                  Late: "-",
                  shifttime: self.shiftstart,
                });
                self.OTTimetableItem.splice(g, 1);
              }
            }
            self.OTTimetableItem.push({
              PunchType: item.PunchType,
              Time: item.Time,
              Location: item.Location,
              Lat: item.Lat,
              Lang: item.Lang,
              id: item.id,
              clock_ID: item.clock_ID,
              time_ID: item.time_ID,
              Late: "-",
              shifttime: self.otshiftend,
            });
            for (var z = 0; z < self.IrregularList.length; z++) {
              if (self.IrregularList[z].id == item.id) {
                self.IrregularList.splice(z, 1);
              }
            }
          } else if (self.OTTimetableItem[1].Time == "-") {
            for (var p = 0; p < self.OTTimetableItem.length; p++) {
              if (self.OTTimetableItem[p].PunchType == item.PunchType) {
                self.OTTimetableItem.splice(p, 1);
              }
            }
            self.OTTimetableItem.push({
              PunchType: item.PunchType,
              Time: item.Time,
              Location: item.Location,
              Lat: item.Lat,
              Lang: item.Lang,
              id: item.id,
              clock_ID: item.clock_ID,
              time_ID: item.time_ID,
              Late: "-",
              shifttime: self.otshiftend,
            });
            for (var l = 0; l < self.IrregularList.length; l++) {
              if (self.IrregularList[l].id == item.id) {
                self.IrregularList.splice(l, 1);
              }
            }
          }
        }
        if (
          self.OTTimetableItem[0].Time != "-" &&
          self.OTTimetableItem[1].Time != "-"
        ) {
          self.ottotaltime = "-";
          let cin = self.OTTimetableItem[0].Time;
          let cout = self.OTTimetableItem[1].Time;
          let ltefrom = new Date(Date.parse(cin));
          let lteto = new Date(Date.parse(cout));
          var ltehh = "";
          var ltemm = "";
          if (ltefrom.getDate() == lteto.getDate()) {
            let frr = ltefrom.getHours();
            let too = lteto.getHours();
            if (frr > too) {
              let days = lteto.getDate();
              days = days + 1;
              lteto.setDate(days);
            }
            let frtimezone = ltefrom.getTimezoneOffset() * 60000;
            let totimezone = lteto.getTimezoneOffset() * 60000;
            let frnewDate = new Date(ltefrom.getTime() - frtimezone);
            let tonewDate = new Date(lteto.getTime() - totimezone);
            var OTTimeDiffer1 = Math.abs(tonewDate - frnewDate);
            ltehh = Math.floor(OTTimeDiffer1 / 1000 / 60 / 60);
            if (ltehh < 10) {
              ltehh = "0" + ltehh;
            }
            OTTimeDiffer1 -= ltehh * 1000 * 60 * 60;
            ltemm = Math.floor(OTTimeDiffer1 / 1000 / 60);
            if (ltemm < 10) {
              ltemm = "0" + ltemm;
            }
          } else {
            let frtimezone = ltefrom.getTimezoneOffset() * 60000;
            let totimezone = lteto.getTimezoneOffset() * 60000;
            let frnewDate = new Date(ltefrom.getTime() - frtimezone);
            let tonewDate = new Date(lteto.getTime() - totimezone);
            var OTTimeDiffer = Math.abs(tonewDate - frnewDate);
            ltehh = Math.floor(OTTimeDiffer / 1000 / 60 / 60);
            if (ltehh < 10) {
              ltehh = "0" + ltehh;
            }
            OTTimeDiffer -= ltehh * 1000 * 60 * 60;
            ltemm = Math.floor(OTTimeDiffer / 1000 / 60);
            if (ltemm < 10) {
              ltemm = "0" + ltemm;
            }
          }
          self.ottotaltime = ltehh + ":" + ltemm + " ";
        }
        // } else {
        //   alert("There is no OT time for today!");
        // }
      }
    },
    tempcreatearray() {
      let self = this;
      let temparr = [
        {
          time_ID: null,
          clock_ID: null,
          clock_In_Time: null,
          clock_Out_Time: null,
          clock_In_Location: null,
          clock_Out_Location: null,
          clock_In_Lattitude: null,
          clock_In_Longitude: null,
          clock_Out_Lattitude: null,
          clock_Out_Longitude: null,
          irregular_In_Time: null,
          irregular_Out_Time: null,
          irregular_In_Location: null,
          irregular_Out_Location: null,
          irregular_In_Lattitude: null,
          irregular_In_Longitude: null,
          irregular_Out_Lattitude: null,
          irregular_Out_Longitude: null,
          time_Type: null,
          user_ID: null,
          employee_ID: null,
          company_ID: null,
        },
      ];

      for (let y = 0; y < self.clocklist.length; y++) {
        let regular = self.TimetableItem.filter(
          (val) => val.clock_ID == self.clocklist[y].clock_ID
        );
        let irregular = self.IrregularList.filter(
          (val) => val.clock_ID == self.clocklist[y].clock_ID
        );
        let ottime = self.OTTimetableItem.filter(
          (val) => val.clock_ID == self.clocklist[y].clock_ID
        );
        if (y == 0) {
          temparr[0].user_ID = store.state.userid;
          if (regular.length == 1) {
            temparr[0].time_ID = regular[0].time_ID;
            temparr[0].clock_ID = regular[0].clock_ID;
            temparr[0].employee_ID = parseInt(self.$route.params.id);
            temparr[0].company_ID = store.state.companyID;
            if (regular[0].PunchType == "Regular In") {
              temparr[0].clock_In_Time = regular[0].Time;
              temparr[0].clock_In_Location = regular[0].Location;
              temparr[0].clock_In_Lattitude = regular[0].Lat;
              temparr[0].clock_In_Longitude = regular[0].Lang;
              temparr[0].clock_Out_Time = "-";
              temparr[0].clock_Out_Location = "-";
              temparr[0].clock_Out_Lattitude = "-";
              temparr[0].clock_Out_Longitude = "-";
              temparr[0].time_Type = "Regular";
            } else {
              temparr[0].clock_In_Time = "-";
              temparr[0].clock_In_Location = "-";
              temparr[0].clock_In_Lattitude = "-";
              temparr[0].clock_In_Longitude = "-";
              temparr[0].clock_Out_Time = regular[0].Time;
              temparr[0].clock_Out_Location = regular[0].Location;
              temparr[0].clock_Out_Lattitude = regular[0].Lat;
              temparr[0].clock_Out_Longitude = regular[0].Lang;
              temparr[0].time_Type = "Regular";
            }
            if (irregular.length != 0) {
              if (irregular[0].PunchType == "Regular In") {
                temparr[0].irregular_In_Time = irregular[0].Time;
                temparr[0].irregular_In_Location = irregular[0].Location;
                temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                temparr[0].irregular_In_Longitude = irregular[0].Lang;
                temparr[0].irregular_Out_Time = "-";
                temparr[0].irregular_Out_Location = "-";
                temparr[0].irregular_Out_Lattitude = "-";
                temparr[0].irregular_Out_Longitude = "-";
                temparr[0].time_Type = "Regular";
              } else {
                temparr[0].irregular_In_Time = "-";
                temparr[0].irregular_In_Location = "-";
                temparr[0].irregular_In_Lattitude = "-";
                temparr[0].irregular_In_Longitude = "-";
                temparr[0].irregular_Out_Time = irregular[0].Time;
                temparr[0].irregular_Out_Location = irregular[0].Location;
                temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                temparr[0].irregular_Out_Longitude = irregular[0].Lang;
                temparr[0].time_Type = "Regular";
              }
            } else {
              temparr[0].irregular_In_Time = "-";
              temparr[0].irregular_In_Location = "-";
              temparr[0].irregular_In_Lattitude = "-";
              temparr[0].irregular_In_Longitude = "-";
              temparr[0].irregular_Out_Time = "-";
              temparr[0].irregular_Out_Location = "-";
              temparr[0].irregular_Out_Lattitude = "-";
              temparr[0].irregular_Out_Longitude = "-";
              temparr[0].time_Type = "Regular";
            }
          } else if (regular.length == 2) {
            temparr[0].time_ID = regular[0].time_ID;
            temparr[0].clock_ID = regular[0].clock_ID;
            temparr[0].employee_ID = parseInt(self.$route.params.id);
            temparr[0].company_ID = store.state.companyID;
            if (regular[0].PunchType == "Regular In") {
              temparr[0].clock_In_Time = regular[0].Time;
              temparr[0].clock_In_Location = regular[0].Location;
              temparr[0].clock_In_Lattitude = regular[0].Lat;
              temparr[0].clock_In_Longitude = regular[0].Lang;
            } else {
              temparr[0].clock_Out_Time = regular[0].Time;
              temparr[0].clock_Out_Location = regular[0].Location;
              temparr[0].clock_Out_Lattitude = regular[0].Lat;
              temparr[0].clock_Out_Longitude = regular[0].Lang;
            }
            if (regular[1].PunchType == "Regular In") {
              temparr[0].clock_In_Time = regular[1].Time;
              temparr[0].clock_In_Location = regular[1].Location;
              temparr[0].clock_In_Lattitude = regular[1].Lat;
              temparr[0].clock_In_Longitude = regular[1].Lang;
            } else {
              temparr[0].clock_Out_Time = regular[1].Time;
              temparr[0].clock_Out_Location = regular[1].Location;
              temparr[0].clock_Out_Lattitude = regular[1].Lat;
              temparr[0].clock_Out_Longitude = regular[1].Lang;
            }
            temparr[0].irregular_In_Time = "-";
            temparr[0].irregular_Out_Time = "-";
            temparr[0].irregular_In_Location = "-";
            temparr[0].irregular_Out_Location = "-";
            temparr[0].irregular_In_Lattitude = "-";
            temparr[0].irregular_In_Longitude = "-";
            temparr[0].irregular_Out_Lattitude = "-";
            temparr[0].irregular_Out_Longitude = "-";
            temparr[0].time_Type = "Regular";
          } else if (ottime.length == 1) {
            temparr[0].time_ID = ottime[0].time_ID;
            temparr[0].clock_ID = ottime[0].clock_ID;
            temparr[0].employee_ID = parseInt(self.$route.params.id);
            temparr[0].company_ID = store.state.companyID;
            temparr[0].time_Type = "Overtime";
            if (ottime[0].PunchType == "Overtime In") {
              temparr[0].clock_In_Time = ottime[0].Time;
              temparr[0].clock_In_Location = ottime[0].Location;
              temparr[0].clock_In_Lattitude = ottime[0].Lat;
              temparr[0].clock_In_Longitude = ottime[0].Lang;
              temparr[0].clock_Out_Time = "-";
              temparr[0].clock_Out_Location = "-";
              temparr[0].clock_Out_Lattitude = "-";
              temparr[0].clock_Out_Longitude = "-";
            } else {
              temparr[0].clock_In_Time = "-";
              temparr[0].clock_In_Location = "-";
              temparr[0].clock_In_Lattitude = "-";
              temparr[0].clock_In_Longitude = "-";
              temparr[0].clock_Out_Time = ottime[0].Time;
              temparr[0].clock_Out_Location = ottime[0].Location;
              temparr[0].clock_Out_Lattitude = ottime[0].Lat;
              temparr[0].clock_Out_Longitude = ottime[0].Lang;
            }
            if (irregular.length != 0) {
              if (irregular[0].PunchType == "Overtime In") {
                temparr[0].irregular_In_Time = irregular[0].Time;
                temparr[0].irregular_In_Location = irregular[0].Location;
                temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                temparr[0].irregular_In_Longitude = irregular[0].Lang;
                temparr[0].irregular_Out_Time = "-";
                temparr[0].irregular_Out_Location = "-";
                temparr[0].irregular_Out_Lattitude = "-";
                temparr[0].irregular_Out_Longitude = "-";
              } else {
                temparr[0].irregular_In_Time = "-";
                temparr[0].irregular_In_Location = "-";
                temparr[0].irregular_In_Lattitude = "-";
                temparr[0].irregular_In_Longitude = "-";
                temparr[0].irregular_Out_Time = irregular[0].Time;
                temparr[0].irregular_Out_Location = irregular[0].Location;
                temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                temparr[0].irregular_Out_Longitude = irregular[0].Lang;
              }
            }
          } else if (ottime.length == 2) {
            temparr[0].time_ID = ottime[0].time_ID;
            temparr[0].clock_ID = ottime[0].clock_ID;
            temparr[0].employee_ID = parseInt(self.$route.params.id);
            temparr[0].company_ID = store.state.companyID;
            if (ottime[0].PunchType == "Overtime In") {
              temparr[0].clock_In_Time = ottime[0].Time;
              temparr[0].clock_In_Location = ottime[0].Location;
              temparr[0].clock_In_Lattitude = ottime[0].Lat;
              temparr[0].clock_In_Longitude = ottime[0].Lang;
            } else {
              temparr[0].clock_Out_Time = ottime[0].Time;
              temparr[0].clock_Out_Location = ottime[0].Location;
              temparr[0].clock_Out_Lattitude = ottime[0].Lat;
              temparr[0].clock_Out_Longitude = ottime[0].Lang;
            }
            if (ottime[1].PunchType == "Overtime In") {
              temparr[0].clock_In_Time = ottime[1].Time;
              temparr[0].clock_In_Location = ottime[1].Location;
              temparr[0].clock_In_Lattitude = ottime[1].Lat;
              temparr[0].clock_In_Longitude = ottime[1].Lang;
            } else {
              temparr[0].clock_Out_Time = ottime[1].Time;
              temparr[0].clock_Out_Location = ottime[1].Location;
              temparr[0].clock_Out_Lattitude = ottime[1].Lat;
              temparr[0].clock_Out_Longitude = ottime[1].Lang;
            }
            temparr[0].irregular_In_Time = "-";
            temparr[0].irregular_Out_Time = "-";
            temparr[0].irregular_In_Location = "-";
            temparr[0].irregular_Out_Location = "-";
            temparr[0].irregular_In_Lattitude = "-";
            temparr[0].irregular_In_Longitude = "-";
            temparr[0].irregular_Out_Lattitude = "-";
            temparr[0].irregular_Out_Longitude = "-";
            temparr[0].time_Type = "Overtime";
          } else {
            temparr[0].time_ID = irregular[0].time_ID;
            temparr[0].clock_ID = irregular[0].clock_ID;
            temparr[0].employee_ID = parseInt(self.$route.params.id);
            temparr[0].company_ID = store.state.companyID;
            if (irregular.length == 2) {
              if (irregular[0].PunchType == "Regular In") {
                temparr[0].irregular_In_Time = irregular[0].Time;
                temparr[0].irregular_In_Location = irregular[0].Location;
                temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                temparr[0].irregular_In_Longitude = irregular[0].Lang;
              } else {
                temparr[0].irregular_Out_Time = irregular[0].Time;
                temparr[0].irregular_Out_Location = irregular[0].Location;
                temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                temparr[0].irregular_Out_Longitude = irregular[0].Lang;
              }
              if (irregular[1].PunchType == "Regular In") {
                temparr[0].irregular_In_Time = irregular[1].Time;
                temparr[0].irregular_In_Location = irregular[1].Location;
                temparr[0].irregular_In_Lattitude = irregular[1].Lat;
                temparr[0].irregular_In_Longitude = irregular[1].Lang;
              } else {
                temparr[0].irregular_Out_Time = irregular[1].Time;
                temparr[0].irregular_Out_Location = irregular[1].Location;
                temparr[0].irregular_Out_Lattitude = irregular[1].Lat;
                temparr[0].irregular_Out_Longitude = irregular[1].Lang;
              }
            } else {
              if (irregular.length != 0) {
                if (irregular[0].PunchType == "Regular In") {
                  temparr[0].irregular_In_Time = irregular[0].Time;
                  temparr[0].irregular_In_Location = irregular[0].Location;
                  temparr[0].irregular_In_Lattitude = irregular[0].Lat;
                  temparr[0].irregular_In_Longitude = irregular[0].Lang;
                  temparr[0].irregular_Out_Time = "-";
                  temparr[0].irregular_Out_Location = "-";
                  temparr[0].irregular_Out_Lattitude = "-";
                  temparr[0].irregular_Out_Longitude = "-";
                } else {
                  temparr[0].irregular_In_Time = "-";
                  temparr[0].irregular_In_Location = "-";
                  temparr[0].irregular_In_Lattitude = "-";
                  temparr[0].irregular_In_Longitude = "-";
                  temparr[0].irregular_Out_Time = irregular[0].Time;
                  temparr[0].irregular_Out_Location = irregular[0].Location;
                  temparr[0].irregular_Out_Lattitude = irregular[0].Lat;
                  temparr[0].irregular_Out_Longitude = irregular[0].Lang;
                }
              } else if (irregular.length == 0) {
                temparr[0].irregular_In_Time = "-";
                temparr[0].irregular_In_Location = "-";
                temparr[0].irregular_In_Lattitude = "-";
                temparr[0].irregular_In_Longitude = "-";
                temparr[0].irregular_Out_Time = "-";
                temparr[0].irregular_Out_Location = "-";
                temparr[0].irregular_Out_Lattitude = "-";
                temparr[0].irregular_Out_Longitude = "-";
              }
            }
            temparr[0].time_Type = "Regular";
            temparr[0].clock_In_Time = "-";
            temparr[0].clock_Out_Time = "-";
            temparr[0].clock_In_Location = "-";
            temparr[0].clock_Out_Location = "-";
            temparr[0].clock_In_Lattitude = "-";
            temparr[0].clock_In_Longitude = "-";
            temparr[0].clock_Out_Lattitude = "-";
            temparr[0].clock_Out_Longitude = "-";
          }
        } else {
          let cid = 0;
          let tid = 0;
          let cin = null;
          let cout = null;
          let cinloc = null;
          let coutloc = null;
          let cinlat = null;
          let coutlat = null;
          let cinlang = null;
          let coutlang = null;
          let latein = null;
          let lateout = null;
          let lateinloc = null;
          let lateoutloc = null;
          let lateinlat = null;
          let lateoutlat = null;
          let lateinlang = null;
          let lateoutlang = null;
          let ttype = null;
          if (regular.length == 1) {
            cid = regular[0].clock_ID;
            tid = regular[0].time_ID;
            ttype = "Regular";
            if (regular[0].PunchType == "Regular In") {
              cin = regular[0].Time;
              cinloc = regular[0].Location;
              cinlat = regular[0].Lat;
              cinlang = regular[0].Lang;
              cout = "-";
              coutloc = "-";
              coutlat = "-";
              coutlang = "-";
            } else {
              cin = "-";
              cinloc = "-";
              cinlat = "-";
              cinlang = "-";
              cout = regular[0].Time;
              coutloc = regular[0].Location;
              coutlat = regular[0].Lat;
              coutlang = regular[0].Lang;
            }
            if (irregular.length != 0) {
              if (irregular[0].PunchType == "Regular In") {
                latein = irregular[0].Time;
                lateinloc = irregular[0].Location;
                lateinlat = irregular[0].Lat;
                lateinlang = irregular[0].Lang;
                lateout = "-";
                lateoutloc = "-";
                lateoutlat = "-";
                lateoutlang = "-";
              } else {
                latein = "-";
                lateinloc = "-";
                lateinlat = "-";
                lateinlang = "-";
                lateout = irregular[0].Time;
                lateoutloc = irregular[0].Location;
                lateoutlat = irregular[0].Lat;
                lateoutlang = irregular[0].Lang;
              }
            } else {
              latein = "-";
              lateinloc = "-";
              lateinlat = "-";
              lateinlang = "-";
              lateout = "-";
              lateoutloc = "-";
              lateoutlat = "-";
              lateoutlang = "-";
            }
          } else if (regular.length == 2) {
            cid = regular[0].clock_ID;
            tid = regular[0].time_ID;
            if (regular[0].PunchType == "Regular In") {
              cin = regular[0].Time;
              cinloc = regular[0].Location;
              cinlat = regular[0].Lat;
              cinlang = regular[0].Lang;
            } else {
              cout = regular[0].Time;
              coutloc = regular[0].Location;
              coutlat = regular[0].Lat;
              coutlang = regular[0].Lang;
            }
            if (regular[1].PunchType == "Regular In") {
              cin = regular[1].Time;
              cinloc = regular[1].Location;
              cinlat = regular[1].Lat;
              cinlang = regular[1].Lang;
            } else {
              cout = regular[1].Time;
              coutloc = regular[1].Location;
              coutlat = regular[1].Lat;
              coutlang = regular[1].Lang;
            }
            ttype = "Regular";
            latein = "-";
            lateout = "-";
            lateinloc = "-";
            lateoutloc = "-";
            lateinlat = "-";
            lateinlang = "-";
            lateoutlat = "-";
            lateoutlang = "-";
          } else if (ottime.length == 1) {
            tid = ottime[0].time_ID;
            cid = ottime[0].clock_ID;
            ttype = "Overtime";
            if (ottime[0].PunchType == "Overtime In") {
              cin = ottime[0].Time;
              cinloc = ottime[0].Location;
              cinlat = ottime[0].Lat;
              cinlang = ottime[0].Lang;
              cout = "-";
              coutloc = "-";
              coutlat = "-";
              coutlang = "-";
            } else {
              cin = "-";
              cinloc = "-";
              cinlat = "-";
              cinlang = "-";
              cout = ottime[0].Time;
              coutloc = ottime[0].Location;
              coutlat = ottime[0].Lat;
              coutlang = ottime[0].Lang;
            }
            if (irregular.length != 0) {
              if (irregular[0].PunchType == "Overtime In") {
                latein = irregular[0].Time;
                lateinloc = irregular[0].Location;
                lateinlat = irregular[0].Lat;
                lateinlang = irregular[0].Lang;
                lateout = "-";
                lateoutloc = "-";
                lateoutlat = "-";
                lateoutlang = "-";
              } else {
                latein = "-";
                lateinloc = "-";
                lateinlat = "-";
                lateinlang = "-";
                lateout = irregular[0].Time;
                lateoutloc = irregular[0].Location;
                lateoutlat = irregular[0].Lat;
                lateoutlang = irregular[0].Lang;
              }
            }
          } else if (ottime.length == 2) {
            tid = ottime[0].time_ID;
            cid = ottime[0].clock_ID;
            if (ottime[0].PunchType == "Overtime In") {
              cin = ottime[0].Time;
              cinloc = ottime[0].Location;
              cinlat = ottime[0].Lat;
              cinlang = ottime[0].Lang;
            } else {
              cout = ottime[0].Time;
              coutloc = ottime[0].Location;
              coutlat = ottime[0].Lat;
              coutlang = ottime[0].Lang;
            }
            if (ottime[1].PunchType == "Overtime In") {
              cin = ottime[1].Time;
              cinloc = ottime[1].Location;
              cinlat = ottime[1].Lat;
              cinlang = ottime[1].Lang;
            } else {
              cout = ottime[1].Time;
              coutloc = ottime[1].Location;
              coutlat = ottime[1].Lat;
              coutlang = ottime[1].Lang;
            }
            ttype = "Overtime";
            latein = "-";
            lateout = "-";
            lateinloc = "-";
            lateoutloc = "-";
            lateinlat = "-";
            lateinlang = "-";
            lateoutlat = "-";
            lateoutlang = "-";
          } else {
            cid = irregular[0].clock_ID;
            tid = irregular[0].time_ID;
            ttype = "Regular";
            if (irregular.length == 2) {
              if (irregular[0].PunchType == "Regular In") {
                latein = irregular[0].Time;
                lateinloc = irregular[0].Location;
                lateinlat = irregular[0].Lat;
                lateinlang = irregular[0].Lang;
              } else {
                lateout = irregular[0].Time;
                lateoutloc = irregular[0].Location;
                lateoutlat = irregular[0].Lat;
                lateoutlang = irregular[0].Lang;
              }
              if (irregular[1].PunchType == "Regular In") {
                latein = irregular[1].Time;
                lateinloc = irregular[1].Location;
                lateinlat = irregular[1].Lat;
                lateinlang = irregular[1].Lang;
              } else {
                lateout = irregular[1].Time;
                lateoutloc = irregular[1].Location;
                lateoutlat = irregular[1].Lat;
                lateoutlang = irregular[1].Lang;
              }
            } else {
              if (irregular[0].PunchType == "Regular In") {
                latein = irregular[0].Time;
                lateinloc = irregular[0].Location;
                lateinlat = irregular[0].Lat;
                lateinlang = irregular[0].Lang;
                lateout = "-";
                lateoutloc = "-";
                lateoutlat = "-";
                lateoutlang = "-";
              } else {
                latein = "-";
                lateinloc = "-";
                lateinlat = "-";
                lateinlang = "-";
                lateout = irregular[0].Time;
                lateoutloc = irregular[0].Location;
                lateoutlat = irregular[0].Lat;
                lateoutlang = irregular[0].Lang;
              }
            }

            cin = "-";
            cinloc = "-";
            cinlat = "-";
            cinlang = "-";
            cout = "-";
            coutloc = "-";
            coutlat = "-";
            coutlang = "-";
          }

          temparr.push({
            clock_ID: cid,
            time_ID: tid,
            clock_In_Time: cin,
            clock_Out_Time: cout,
            clock_In_Location: cinloc,
            clock_Out_Location: coutloc,
            clock_In_Lattitude: cinlat,
            clock_In_Longitude: cinlang,
            clock_Out_Lattitude: coutlat,
            clock_Out_Longitude: coutlang,
            irregular_In_Time: latein,
            irregular_Out_Time: lateout,
            irregular_In_Location: lateinloc,
            irregular_Out_Location: lateoutloc,
            irregular_In_Lattitude: lateinlat,
            irregular_In_Longitude: lateinlang,
            irregular_Out_Lattitude: lateoutlat,
            irregular_Out_Longitude: lateoutlang,
            user_ID: store.state.userid,
            time_Type: ttype,
            employee_ID: parseInt(self.$route.params.id),
            company_ID: store.state.companyID,
          });
        }
      }
      return temparr;
    },
    backtostart() {
      let self = this;
      self.TimetableItem = [];
      self.OTTimetableItem = [];
      self.IrregularList = [];
      self.ottotableitems = [];
      self.GetSheetDetail();
    },
    ResetApprove() {
      let self = this;
      if (self.dissave == true) {
        self.LoadingDialog = true;
        let tempdata = {
          employee_ID: store.state.userid,
          time_ID: self.timeID,
        };
        axios
          .post(`${self.url}ClockInOut/ResetApprove`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              self.TimetableItem = [];
              self.OTTimetableItem = [];
              self.IrregularList = [];
              self.GetSheetDetail();
            }
          })
          .catch(function (error) {
            alert(error);
            self.LoadingDialog = false;
          });
      } else {
        self.backtostart();
      }
    },
    UpdateIrregular() {
      let self = this;
      self.LoadingDialog = true;
      if (
        self.TimetableItem[0].Time == "-" &&
        self.TimetableItem[1].Time == "-" &&
        self.OTTimetableItem[0].Time == "-" &&
        self.OTTimetableItem[1].Time == "-"
      ) {
        self.LoadingDialog = false;
        alert("Add from Irregularities first please.");
      } else if (
        self.TimetableItem[0].Time != "-" ||
        self.TimetableItem[1].Time != "-" ||
        self.OTTimetableItem[0].Time != "-" ||
        self.OTTimetableItem[1].Time != "-"
      ) {
        let arr = self.tempcreatearray();
        axios
          .post(`${self.url}ClockInOut/UpdateIrregularToClockInOut`, arr)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              if(
                (self.TimetableItem[0].Time != "-" && 
                self.TimetableItem[1].Time != "-") ||
                (self.OTTimetableItem[0].Time != "-" && 
                self.OTTimetableItem[1].Time != "-")
                ){
                self.Approvedialog = true;
              } else {
                alert("Changes Saved Successfully!");
                self.goback();
              }
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Irregular Fail", 23, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
      } else {
        alert("Add from Irregularities first please.");
        self.LoadingDialog = false;
      }
    },

    ApproveTimeAttendance() {
      let self = this;
      self.LoadingDialog = true;
      let tempdata = {
        employee_ID: parseInt(self.$route.params.id),
        clockidlist: self.clocklist,
        user_ID: store.state.userid,
        approver_ID: store.state.employeeID,
        time_ID: self.timeID,
        shift_StartTime: self.shiftstart,
        shift_EndTime: self.shiftend,
      };
      axios.post(`${self.url}Stafftimeattendance/UpdateStaffTimeAttendance`,tempdata)
      .then(function (response) {
        if (response.data.status == 0)
        {
          self.LoadingDialog = false;
          alert("Approved Successfully!");
          self.Approvedialog = false;
          self.TimetableItem = [];
          self.OTTimetableItem = [];
          self.IrregularList = [];
          self.goback();
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Approve TimeAttendance Fail", 23, "Medium");
        alert(error);
        self.LoadingDialog = false;
      });
    },
    Converttolocal(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    Converttolocal1(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let theresult = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        theresult =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          output;
        return theresult;
      } else {
        return "-";
      }
    },
    Converttolocal2(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let theresult = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        theresult =
          newDate.getFullYear() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getDate() +
          " " +
          output;
        return theresult;
      } else {
        return "-";
      }
    },
    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      let day = finaldate[2];
      let month = finaldate[1];
      let year = finaldate[0];
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return day + "/" + month + "/" + year;
    },
    formatDate1(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
  },
};
</script>
<style scoped>
>>> .mdi-menu-down::before {
  color: #0fa7d8;
}
>>> .yellow_drpdown .mdi-menu-down::before {
  color: #f99d20 !important;
}
.txt14 {
  font-weight: normal;
  font-size: 15px;
}
.txt16 {
  font-weight: normal;
  font-size: 17px;
}
.txt18 {
  font-weight: normal;
  font-size: 19px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
/* >>> .v-data-table tr td {
  height: 70px;
} */
/* >>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
} */
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}
>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 80px;
}
>>> .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: none;
}
@media only screen and (max-width: 723px) {
  .cancle_btn {
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
}
</style>
