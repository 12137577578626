<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("Role") }}
      </p>
    </div>
    <v-card class="mt-6">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="4" md="4" lg="3">
            <v-text-field
              v-model="searchtxt"
              :placeholder="$t('Search')"
              color="#F99D20"
              dense
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize ml-5"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-row>
        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :items="RoleList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :headers="headers"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("RoleID") }}</th>
                <th>{{ $t("RoleName") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="
                      item.role_ID != null
                        ? { role_ID: item.role_ID, role_Name: item.role_Name }
                        : { role_ID: roleid, role_Name: rolename }
                    "
                    v-model="selected"
                    v-on:click="checkonce()"
                  />
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.role_Code }}</td>
              <td>{{ item.role_Name }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          @click="Cancel"
          class="mr-2 text-capitalize commoncancelbtn"
          text
          >{{ $t("Cancel") }}</v-btn
        >
        <v-btn
          @click="SaveLocal"
          class="mr-4 text-capitalize commonsavebtn"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      RoleList: [],
      loadingdialog: false,
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      selected: [],
      tempuser: [],
      selectAll: false,
      UserRole: [],
      headers: [
        {
          text: "Role ID",
          value: "role_ID",
          align: "left",
          sortable: true,
        },
        {
          text: "Role Name",
          value: "role_Name",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    searchtxt: function () {
      this.GetRole();
    },
  },
  mounted() {
    this.GetRole();
    //this.GetUserRoleByEmpID();
  },
  methods: {
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.RoleList;
        self.RoleList = [];
        let temp = List.filter(
          (v) =>
            v.role_Name.toLowerCase().indexOf(self.searchtxt.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.RoleList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetRole();
      }
    },
    checkonce() {
      this.selected = [];
    },
    Cancel() {
      let self = this;
      self.uid = parseInt(self.$route.params.uid);
      localStorage.setItem("NewUserEditRole", null);
      self.$router.push({ name: "newuserassign" }).catch(()=>{});
    },
    SaveLocal() {
      let self = this;
      self.uid = parseInt(self.$route.params.uid);
      localStorage.setItem("NewUserEditRole", JSON.stringify(this.selected));
      self.$router.push({ name: "newuserassign" }).catch(()=>{});
    },
    GetRole() {
      let self = this;
      //self.loadingdialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Role/GetRoleByCompanyID`, tempp)
        .then(function (response) {
          self.RoleList = response.data.data;
          //self.loadingdialog = false;
          self.tempuser = JSON.parse(localStorage.getItem("NewUserEditRole"));
          if (self.tempuser != null) {
            self.selected.push({
              role_ID: self.tempuser[0].role_ID,
              role_Name: self.tempuser[0].role_Name,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>