<template>
  <div class="ml-5 mr-5">
    <br/>
    <v-row class="pl-md-0" style="margin-left: 15px; margin-right: 10px; margin-top: 0rem !important">
      <div class="d-flex">
        <img class="benefitIcon mt-2" style="margin-top:auto;margin-bottom:auto;" :src="images.benefitIcon" alt="benefitIcon"/>
        <div class="ml-3 mt-2">
          <label style="font-size: 17px; color: #f89d1f" alt="backIcon">
            {{ $t("benefits") }}
          </label>
        </div>
      </div>
      <v-spacer />
      <div class="d-flex ml-3">
        <v-btn :disabled="computedDisabled"
          color="#F99D20"
          class="text-capitalize"
          @click="addRowItem"
          min-width="150">
          {{ $t("assignbenefit") }}
        </v-btn>
      </div>
    </v-row>
    <!-- <v-container class="c pa-3"> -->
    
    <div class="pl-md-0 mt-5 pt-5" style="margin-left: 15px; margin-right: 10px">
      <v-form :disabled="isDisabled">
        <v-row class="colorClass" v-for="(benefit, index) in benefitList" :key="index">
        <v-col cols="12" xs="12" xl="3" sm="12" md="3" lg="3">{{ $t("position") }}
          <!-- <v-text-field
            autocomplete="off"
            v-model="benefit.position_Name"
            color="#F99D20"
            readonly>
          </v-text-field> -->
          <v-select
            color="#F99D20"
            v-model="benefit.position_ID"
            :items="positionArray"
            item-text="position_Name"
            item-value="position_ID"
            @input="(id) => onChangePosition(id, index)"
            return-object
            append-icon="mdi-chevron-down">
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" xl="3" sm="12" md="3" lg="3">
          {{ $t("lengthofemp") }}
          <v-select
            color="#F99D20"
            v-model="benefit.benefit_ID"
            :items="employmentLength"
            item-value="benefit_ID"
            return-object
            @input="(id) => onInputHandler(id, index)"
            item-text="name"
            append-icon="mdi-chevron-down">
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" xl="3" sm="12" md="3" lg="3">
          {{ $t("benefittype") }}
          <v-select
            :items="benefit.benefitTypeList"
            color="#F99D20"
            @input="(obj) => onChange(obj, index)"
            item-text="benefit_Type"
            return-object
            item-value="benefit_ID"
            v-model="benefit.benefit_Type"
            append-icon="mdi-chevron-down">
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" xl="2" sm="12" md="3" lg="2">
          {{ $t("incomeamount") }}
          <v-text-field
            autocomplete="off"
            v-model="benefit.amount"
            color="#F99D20">
          </v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="1" lg="1">
          <div v-show="showDel">
            <v-btn @click="removeDialog(index)" :disabled="isDisabled" icon class="ml-2 mt-11">
              <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      </v-form>
    </div>
      
    <div class="d-flex flex-row-reverse mt-10 mb-10 mr-8 pb-10">
      <v-btn
        v-show="saveBtn"
        color="#F89D1F"
        class="text_transform_none"
        rounded
        width="160px"
        @click="saveBenefit()"
        :disabled="
          $route.path ==
          '/selfservice/PersonalProfile/PersonalInformation/PersonalBenefit'
            ? listBenefit.length != 0
              ? permission.edit
              : permission.add
            : listBenefit.length != 0
            ? permission_management.edit
            : permission_management.add
        "
        height="50px">
        {{ $t("save") }}
      </v-btn>
      <v-btn
        v-show="cancelBtn"
        class="text_transform_none mr-4"
        rounded
        color="#3C4043"
        text
        width="160px"
        @click="cancelBenefit()"
        height="50px"
        >
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        color="#F89D1F"
        class="text_transform_none"
        rounded
        v-show="editBtn"
        width="160px"
        @click="editBenefit()"
        height="50px"
        :disabled="
          $route.path ==
          '/selfservice/PersonalProfile/PersonalInformation/PersonalBenefit'
            ? permission.edit
            : permission_management.edit
        ">
        {{ $t("edit") }}
      </v-btn>
    </div>

    <!-- delete bank dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="Deletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="removeItem()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- </v-container> -->
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewAddEdit from "@/function/RolePermissions.js";
// import res from "express/lib/response";

export default {
  mixins: [CheckViewAddEdit, LogTrace],
  data() {
    return {
      url: enurl.apiUrl,
      permission: {
        add: true,
        edit: true,
      },
      permission_management: {
        add: true,
        edit: true,
      },
      tempRoute: {},
      getPos: {},
      tempEmpID: 0,
      employmentLength: [],
      lenghtList: [],
      benefitType: [],
      Benefitlist: [],
      isDisabled: false,
      cancelBtn: false,
      saveBtn: true,
      editBtn: false,
      LoadingDialog: false,
      showDel: true,
      Deletedialog: false,
      theposition: null,
      listBenefit: [],
      positionArray: [],
      selectedPosition: null,
      benefitList: [
        {
          position_Name: "",
          position_ID: 0,
          employment_length: "",
          benefit_Type: "",
          amount: "",
          benefitTypeList: [],
          benefit_ID: "",
          deleteBtn: false,
          employmentLength: []
        },
      ],
    };
  },

  async mounted() {
    this.permission = this.CheckViewAddEdit(143, 144, 145);
    this.permission_management = this.CheckViewAddEdit(215, 216, 217);
    this.getRightID();
    // await this.GetLengthOfEmployee();
    await this.GetBenefit();
    this.GetBenefitById();
  },

  computed: {
    images() {
      return this.$store.state.images;
    },
    computedDisabled() {
      const routePath = this.$route.path;
      const permissionDisabled = routePath === '/selfservice/PersonalProfile/PersonalInformation/PersonalBenefit' ? this.permission.add : this.permission_management.add;
      return this.isDisabled || permissionDisabled;
    }
  },

  methods: {
    //permission
    // disable_btn() {
    //   let self = this;
    //   if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalBenefit")
    //   {
    //     if (self.permission.add == false)
    //     {
    //       self.permission.add = false;
    //     }
    //     if (self.permission.edit == false)
    //     {
    //       self.permission.edit = false;
    //     }
    //   }
    //   else
    //   {
    //     if (self.permission_management.add == false)
    //     {
    //       self.permission_management.add = false;
    //     }
    //     if (self.permission_management.edit == false)
    //     {
    //       self.permission_management.edit = false;
    //     }
    //   }
    // },
    // disable_btn() {
    //   let self = this;
    //   if (self.$route.path === "/selfservice/PersonalProfile/PersonalInformation/PersonalBenefit") 
    //   {
    //     self.permission.add = false;
    //     self.permission.edit = false;
    //   }
    //   else
    //   {
    //     self.permission_management.add = false;
    //     self.permission_management.edit = false;
    //   }
    // },
    
    addRowItem() {
      this.benefitList.push({
        position_Name: "",
        position_ID: this.selectedPosition,
        employment_length: "",
        benefit_Type: "",
        benefitTypeList: [],
        amount: "",
        deleteBtn: true,
        benefit_ID: "",
        employmentLength: []
      });
    },

    async GetPosition() {
      try {
        const response = await axios.post(`${this.url}Position/GetallPosition`,this.getPos);
        if(response.data.status == 0)
        {
          this.positionArray = response.data.data;
        }
      } catch (error) {
        throw error;
      }
    },

    async GetPositionEmp() {
      try {
        const response = await axios.post(`${this.url}BenefitEmployee/GetPositionByEmployeeID`,this.tempRoute);
        if(response.data.data.length !== 0)
        {
          //this.benefitList[0].position_Name = response.data.data[0].position_Name;
          this.selectedPosition = response.data.data[0].position_ID;
          this.benefitList[0].position_ID = response.data.data[0].position_ID;
        }
      } catch (error) {
        throw error;
      }
    },

    async GetBenefit() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}Benefit/GetBenefit`, tempp)
      .then(function (response) {
        if(response.data.data.length !== 0)
        {
          self.Benefitlist = response.data.data;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Benefit Fail", 9, "Critical");
      });
    },

    async GetBenefitById() {
      try
      {
        this.LoadingDialog = true;
        const response = await axios.post(`${this.url}BenefitEmployee/GetBenefitEmployeeByEmployeeID`,this.tempRoute);
        let list = response.data.data;
        this.listBenefit = response.data.data;
        if (list.length)
        {
          list.forEach((element, index) => {
            if (index > 0)
            {
              this.addRowItem();
            }
            this.onInputHandler(element, index);
            this.benefitList[index].benefit_Type = element.benefit_ID;
            this.onChange(element, index);
            this.benefitList[index].employment_length = element.employment_length;
          });
          this.isDisabled = true;
          this.saveBtn = false;
          this.editBtn = true;
          this.showDel = false;
        }
        this.LoadingDialog = false;
        //this.LogTrace(null, "Get Benefit Employee", 19, "Critical");
      } catch (error) {
        this.LogTrace(error, "Get Benefit Employee Fail", 19, "Critical");
        throw error;
      }
    },

    editBenefit() {
      this.isDisabled = false;
      this.saveBtn = true;
      this.cancelBtn = true;
      this.editBtn = false;
      this.showDel = true;
    },

    cancelBenefit() {
      this.isDisabled = true;
      this.saveBtn = false;
      this.cancelBtn = false;
      this.editBtn = true;
      this.showDel = false;
    },

    removeDialog(index) {
      this.Deletedialog = true;
      this.id = index;
    },

    removeItem() {
      this.benefitList.splice(this.id, 1);
      this.Deletedialog = false;
    },
    
    // async GetLengthOfEmployee() {
    //   try
    //   {
    //     await this.getRightID();
    //     const response = await axios.post(`${this.url}BenefitEmployee/GetBenefitByyear`,this.tempRoute);
    //     const list = response.data.data;
    //     this.employeeList = list;
    //     list.forEach((element) => {
    //       let lenght = element.employment_length;
    //       this.calculateLength(lenght, element);
    //     });
    //   }
    //   catch (error)
    //   {
    //     throw error;
    //   }
    // },

    async getRightID() {
      if (this.$route.params.id)
      {
        if (this.$route.name == "PersonalBenefit")
        {
          this.tempEmpID = store.state.employeeID;
          this.tempRoute = {
            company_ID: store.state.companyID,
            employee_ID: this.tempEmpID
          };
        }
        else if (this.$route.name == "EditPersonalBenefit")
        {
          this.tempEmpID = parseInt(this.$route.params.id);
          this.tempRoute = {
            company_ID: store.state.companyID,
            employee_ID: this.tempEmpID
          };
        }
      }
      else
      {
        this.tempEmpID = await this.changeCodeToId();
        this.tempRoute = {
          company_ID: store.state.companyID,
          employee_ID: this.tempEmpID
        };
      }
      this.getPos = {
        company_ID: store.state.companyID
      };
      await this.GetPosition();
      await this.GetPositionEmp();
    },

    async saveBenefit() {
      try {
        let validation = true;
        this.benefitList.forEach((element, index) => {
          if (!this.benefitList[index].benefit_ID || !this.benefitList[index].amount)
          {
            validation = false;
          }
        });
        if (validation)
        {
          var valueArr = this.benefitList.map((item) => item.benefit_ID);
          var isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) != idx);
          if (!isDuplicate)
          {
            let testing = this.benefitList.map((element) => {
              return {
                benefit_ID: element.benefit_ID,
                ...this.tempRoute,
              };
            });
            this.LoadingDialog = true;
            const removeOld = await axios.post(`${this.url}BenefitEmployee/DeleteOldEmpBeneInfo`,this.tempRoute);
            if (removeOld.data.status == 0)
            {
              const response = await axios.post(`${this.url}BenefitEmployee/AddBenefitEmployee`,testing);
              if (response.data.status == 0)
              {
                if (this.benefitList.length == 0)
                {
                  this.addRowItem();
                }
                else
                {
                  alert(response.data.message);
                  this.isDisabled = true;
                  this.saveBtn = false;
                  this.showDel = false;
                  this.cancelBtn = false;
                  this.editBtn = true;
                }
                //this.LogTrace(null, "Add Benefit Employee", 19, "Low");
              }
            }
          }
          else
          {
            alert("Benefit type can not be same");
          }
          this.LoadingDialog = false;
        }
        else
        {
          alert("Please Fill in Required Fields");
        }
      }
      catch (error) {
        this.LogTrace(error, "Add Benefit Employee Fail", 19, "Low");
        throw error;
      }
    },

    async changeCodeToId() {
      let Id = localStorage.getItem("empcode");
      let temp = {
        employee_Code: Id,
        company_ID: store.state.companyID,
      };
      const response = await axios.post(`${this.url}PersonalInfo/GetPersonalInformationWithCode`,temp);
      let empId = response.data.data.employee_ID;
      return empId;
    },

    onInputHandler(v, index) {
      let benefitType = this.Benefitlist.filter((ele) => ele.employment_length === v.employment_length && ele.start_Year == v.start_Year);
      this.benefitList[index].benefitTypeList = JSON.parse(JSON.stringify(benefitType));
      this.benefitList[index].amount = null;
      //console.log("onInputHandler",this.Benefitlist,v,index,this.benefitList[index].benefitTypeList)
    },

    onChangePosition(v, index) {
    //console.log("Benefitlist", this.positionArray, "Benefitlist", this.Benefitlist);
    this.benefitList[index] = {};  // Initialize as an object
    //let benefitType = this.Benefitlist.filter((ele) => ele.position_ID === v.position_ID);
    const matchedElement = this.positionArray.find((ele) => ele.position_ID === v.position_ID);
    if (matchedElement)
    {
      this.benefitList[index].position_ID = matchedElement.position_ID;
    }
    else
    {
      this.benefitList[index].position_ID = null; // Or some default value
    }
    //console.log("benefitType", benefitType);
    this.benefitList[index].employmentLength = []; // Initialize employmentLength as an array
    this.benefitList.forEach((element,index) => {
      let length = element.employment_length;
      this.calculateLength(length, element, index); // Use the index parameter
    });
    //console.log("onChangePosition", benefitType, index, this.benefitList);
    },

    onChange(v, index) {
      this.benefitList[index].amount = v.amount;
      this.benefitList[index].benefit_ID = v.benefit_ID;
    },

    async calculateLength(length, element) {
      let name;
      switch (length) {
        case 1:
          name = `Less Than ${element.start_Year} Years`;
          break;
        case 2:
          name = `Greater Than ${element.start_Year} Years`;
          break;
        case 3:
          name = `Between ${element.start_Year} - ${element.end_Year} Years`;
          break;
        case 4:
          name = `Equal to ${element.start_Year} Years`;
          break;
        default:
          name = "Unknown Length";
          break;
      }

      const employmentDetail = {
        name: name,
        benefit_ID: element.benefit_ID,
        amount: element.amount,
        employment_length: element.employment_length,
        start_Year: element.start_Year,
      };
      return employmentDetail;
      // Push employmentDetail to benefitList[index].employmentLength
      //this.benefitList[index].employmentLength.push(employmentDetail);

      //console.log("employmentLength", this.benefitList[index].employmentLength);
    },

    // async calculateLength(length, element) {
    //   if (length == 1)
    //   {
    //     this.employmentLength.push({
    //       name: `Less Than ${element.start_Year} Years`,
    //       benefit_ID: element.benefit_ID,
    //       amount: element.amount,
    //       employment_length: element.employment_length,
    //       start_Year: element.start_Year,
    //     });
    //   }
    //   if (length == 2)
    //   {
    //     this.employmentLength.push({
    //       name: `Greater Than ${element.start_Year} Years`,
    //       benefit_ID: element.benefit_ID,
    //       amount: element.amount,
    //       employment_length: element.employment_length,
    //       start_Year: element.start_Year,
    //     });
    //   }
    //   if (length == 3)
    //   {
    //     this.employmentLength.push({
    //       name: `Between ${element.start_Year} - ${element.end_Year} Years`,
    //       benefit_ID: element.benefit_ID,
    //       amount: element.amount,
    //       employment_length: element.employment_length,
    //       start_Year: element.start_Year,
    //     });
    //   }
    //   if (length == 4)
    //   {
    //     this.employmentLength.push({
    //       name: `Equal to ${element.start_Year} Years`,
    //       benefit_ID: element.benefit_ID,
    //       amount: element.amount,
    //       employment_length: element.employment_length,
    //       start_Year: element.start_Year,
    //     });
    //   }
    // },
  },
};
</script>

<style>
.button_example {
  float: right;
}
.colorClass {
  color: #8a8c8e;
}
</style>
