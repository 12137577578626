<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p class="txt-header">
        {{ $t("othersetting") }}
      </p>
    </div>
    <div style="height: auto" class="mt-6">
      <v-card min-height="60vh" class="d-flex flex-wrap flex-column pa-6">
        <v-card-title class="py-2">{{ $t("emailsending") }}</v-card-title>
        <!-- ChangePassword Field -->
        <v-container class="py-0">
          <v-col class="py-0">
            <!-- Send Email to Approver, when have New OT request -->
            <v-col class="d-flex flex-wrap align-center py-1">
              <v-label>
                {{ $t("Send Email to Approver, when have New OT request") }}
              </v-label>
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="status.toggle1"
                tile
                borderless
                mandatory
                dense
              >
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="false"
                  @click="ChangeStatus(0, false)"
                >
                  <label>{{ $t("noo") }}</label>
                </v-btn>
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="true"
                  @click="ChangeStatus(0, true)"
                >
                  <label>{{ $t("yes") }}</label>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <!-- Send Email to Approver, when have New Time Off request  -->
            <v-col class="d-flex flex-wrap align-center py-1">
              <v-label>
                {{
                  $t("Send Email to Approver, when have New Time Off request")
                }}
              </v-label>
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="status.toggle2"
                tile
                borderless
                mandatory
                dense
              >
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="false"
                  @click="ChangeStatus(1, false)"
                >
                  <label>{{ $t("noo") }}</label>
                </v-btn>
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="true"
                  @click="ChangeStatus(1, true)"
                >
                  <label>{{ $t("yes") }}</label>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <!-- Send Email to Employee, when OT request has Approved/Denied -->
            <v-col class="d-flex flex-wrap align-center py-1">
              <v-label>
                {{
                  $t(
                    "Send Email to Employee, when OT request has Approved/Denied"
                  )
                }}
              </v-label>
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="status.toggle3"
                tile
                mandatory
                borderless
                dense
              >
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="false"
                  @click="ChangeStatus(2, false)"
                >
                  <label>{{ $t("noo") }}</label>
                </v-btn>
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="true"
                  @click="ChangeStatus(2, true)"
                >
                  <label>{{ $t("yes") }}</label>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <!-- Send Email to Employee, when Time Off request has Approved/Denied -->
            <v-col class="d-flex flex-wrap align-center py-1">
              <v-label>
                {{
                  $t(
                    "Send Email to Employee, when Time Off request has Approved/Denied"
                  )
                }}
              </v-label>
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="status.toggle4"
                tile
                mandatory
                borderless
                dense
              >
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="false"
                  @click="ChangeStatus(3, false)"
                >
                  <label>{{ $t("noo") }}</label>
                </v-btn>
                <v-btn
                  :disabled="permission.edit"
                  active-class="amber darken-3 white--text"
                  :value="true"
                  true
                  @click="ChangeStatus(3, true)"
                >
                  <label>{{ $t("yes") }}</label>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-col>
        </v-container>
        <!-- Loading Dialog -->
        <v-dialog v-model="LoadingDialog" persistent width="300">
          <v-card color="#FFF4EB" dark>
            <v-card-text class="black--text">
              Loading Please Wait...
              <v-progress-linear
                indeterminate
                color="#F99D20"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>
  <script>
//   import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import CheckViewEdit from "@/function/RolePermissions.js";
import LogTrace from "@/function/Log.js";
export default {
  mixins: [CheckViewEdit, LogTrace],
  name: "ChangePassword",
  data() {
    return {
      permission: {
        edit: true,
      },
      url: enurl.apiUrl,
      SettingList: [],
      LoadingDialog: false,
      status: {
        toggle1: false,
        toggle2: false,
        toggle3: false,
        toggle4: false,
      },
      set: [
        "Send Email to Approver, when have New OT request",
        "Send Email to Approver, when have New Time Off request",
        "Send Email to Employee, when OT request has Approved/Denied",
        "Send Email to Employee, when Time Off request has Approved/Denied",
      ],
    };
  },
  mounted() {
    this.getOtherSetting();
    this.permission = this.CheckViewEdit(195, 196);
  },
  methods: {
    async getOtherSetting() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let temp = {
          user_ID: parseInt(self.$store.state.userid),
          company_ID: parseInt(self.$store.state.companyID),
        };
        await axios
          .post(`${self.url}Other_Setting/GetOtherSetting`, temp)
          .then(function (response) {
            let res = response.data.data;
            self.SettingList = res;
            if (res != 0 || res.length != 0) {
              self.status.toggle1 = res[0].status;
              self.status.toggle2 = res[1].status;
              self.status.toggle3 = res[2].status;
              self.status.toggle4 = res[3].status;
              self.LoadingDialog = false;
            } else {
              self.addOtherSetting();
              self.LoadingDialog = false;
            }
          });
      } catch (ex) {
        throw ex;
      }
    },
    async addOtherSetting() {
      try {
        let self = this;
        let temp = {
          user_ID: parseInt(self.$store.state.userid),
          company_ID: parseInt(self.$store.state.companyID),
        };
        await axios
          .post(`${self.url}Other_Setting/AddOtherSetting`, temp)
          .then(function () {
            self.LoadingDialog = false;
          });
      } catch (ex) {
        throw ex;
      }
    },
    ChangeStatus(x, input) {
      let self = this;
      let temp = {
        user_ID: parseInt(self.$store.state.userid),
        company_ID: parseInt(self.$store.state.companyID),
        status: input,
        other_Setting_Name: self.set[x],
      };
      axios.post(`${self.url}Other_Setting/ChangeStatus`, temp);
    },
  },
};
</script>
  
  <style scoped>
.v-btn-toggle {
  width: 200px;
  height: 40px;
}
.v-btn {
  width: 100px !important;
  height: 40px !important;
  font-size: 15px !important;
}
label {
  font-size: 15px;
}
.sc {
  padding: 8px;
  margin: 8px;
  font-size: 14px;
}
@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>