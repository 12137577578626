<template>
  <div class="ml-5">
    <br />
    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Mask Group 56@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f">{{
          $t("specificempinfo")
        }}</label>
      </div>
    </div>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.5rem">
        <!-- <label style="font-size:13px; color:#8A8C8E">{{ $t('domicile') }}</label> -->
        <v-autocomplete
          :items="domicileitems"
          v-model="familydata.domicile"
          :error-messages="error.errormessagedomicile"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("domicile") }}</span>
          </template></v-autocomplete
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.5rem">
        <v-select
          :items="raceitems"
          item-text="txt"
          item-value="val"
          v-model="familydata.race"
          :error-messages="error.errormessagerace"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("race") }}</span>
          </template></v-select
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.5rem">
        <!-- <v-select
          :items="items1"
          :label="$t('nationality')"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>-->
        <v-text-field
          autocomplete="off"
          v-model="familydata.nationality"
          :error-messages="error.errormessagenation"
          :disabled="disbleall"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("nationality") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.5rem">
        <v-select
          :items="religionitems"
          item-text="txt"
          item-value="val"
          v-model="familydata.religion"
          :error-messages="error.errormessagereligon"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("religion") }}</span>
          </template></v-select
        >
      </v-col>
    </v-row>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3">
        <v-select
          :items="militaryitems"
          item-text="txt"
          item-value="val"
          v-model="familydata.militaryst"
          :error-messages="error.errormessagemilitary"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("militarystatus") }}</span>
          </template></v-select
        >
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          :items="blooditems"
          v-model="familydata.bloodtype"
          :error-messages="error.errormessageblood"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("bloodtype") }}</span>
          </template></v-select
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.height"
          color="#F99D20"
          class="empID"
          :disabled="disbleall"
          :error-messages="error.errormessageheight"
        >
          <template v-slot:label>
            <span> {{ $t("height") }} (cm)</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.weight"
          color="#F99D20"
          class="empID"
          :disabled="disbleall"
          :error-messages="error.errormessageweight"
        >
          <template v-slot:label>
            <span> {{ $t("weight") }} (kg)</span>
          </template></v-text-field
        >
      </v-col>
    </v-row>

    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Mask Group 57@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f">
          {{ $t("registeredhouse") }}
        </label>
      </div>
    </div>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col class="mt-4" cols="12" sm="6" md="3">
        <!-- <v-text-field color="#F99D20" class="empID" :label="$t('address')"></v-text-field> -->
        <v-autocomplete
          class="empID"
          @change="changecountry()"
          v-model="familydata.country"
          :error-messages="error.errormessagecountry"
          style="margin-top: 0"
          :items="CountryList"
          item-value="country_ID"
          :item-text="(item) => ` ${item.country_Name}`"
          append-icon="mdi-chevron-down"
          :disabled="disbleall"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("country") }}</span>
          </template></v-autocomplete
        >
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          class="empID"
          :error-messages="error.errormessageprovince"
          v-model="familydata.province"
          style="margin-top: 0.95rem"
          @change="changeprovince()"
          :items="ProvinceList"
          item-value="province_ID"
          :item-text="(item) => ` ${item.province_Name}`"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("province") }}</span>
          </template></v-autocomplete
        >
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          class="empID"
          :items="Districtlist"
          :error-messages="error.errormessagedistrict"
          v-model="familydata.district"
          @change="changedistrict()"
          item-value="district_ID"
          :item-text="(item) => ` ${item.district_Name}`"
          style="margin-top: 0.95rem"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("district") }}</span>
          </template></v-autocomplete
        >
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          class="empID"
          :error-messages="error.errormessagesubdistrict"
          v-model="familydata.subdistrict"
          @change="changesubdistrict()"
          :items="Subdistrictlist"
          item-value="sub_District_ID"
          :item-text="(item) => ` ${item.sub_District_Name}`"
          style="margin-top: 0.95rem"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("subdistrict") }}</span>
          </template></v-autocomplete
        >
      </v-col>
    </v-row>
    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3" class="mt-2">
        <v-autocomplete
          class="empID"
          :items="Postalcodelist"
          item-value="postal_Code"
          v-model="familydata.postalcode"
          :error-messages="error.errormessagepostalcode"
          :item-text="(item) => ` ${item.postal_Code}`"
          style="margin-top: 0.95rem"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("postalcode") }}</span>
          </template></v-autocomplete
        >
      </v-col>
    </v-row>
    <!-- <v-col cols="12"  sm="6" md="3" style="margin-top: 1.2rem;">
        <v-text-field color="#F99D20" class="empID" :label="$t('address')"></v-text-field>
      </v-col>
      <v-col cols="12"  sm="6" md="3">
        <v-select
          :items="items1"
          :label="$t('county')"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
      </v-col>
      <v-col cols="12"  sm="6" md="3">
        <v-select
          :items="items1"
          :label="$t('province')"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
      </v-col>
      <v-col cols="12"  sm="6" md="3">
        <v-select
          :items="items1"
          :label="$t('postalcode')"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
    </v-col>-->

    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Mask Group 58@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f">
          {{ $t("emergencycontact") }}
        </label>
      </div>
    </div>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.2rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.sosname"
          color="#F99D20"
          class="empID"
          :disabled="disbleall"
          :error-messages="error.errormessagesosname"
        >
          <template v-slot:label>
            <span> {{ $t("name") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.2rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.sosaddress"
          color="#F99D20"
          class="empID"
          :disabled="disbleall"
          :error-messages="error.errormessagesosaddress"
        >
          <template v-slot:label>
            <span> {{ $t("address") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.2rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.sosphone"
          color="#F99D20"
          class="empID"
          :disabled="disbleall"
          :error-messages="error.errormessagesosphone"
        >
          <template v-slot:label>
            <span> {{ $t("phonenum") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          style="margin-top: 0.4rem"
          v-model="familydata.sosrs"
          :items="relationshipitems"
          item-text="txt"
          item-value="val"
          append-icon="mdi-chevron-down"
          :error-messages="error.errormessagesosrs"
          :disabled="disbleall"
          color="#F99D20"
        >
          <template v-slot:label>
            <span> {{ $t("familyrelation") }}</span>
          </template></v-select
        >
      </v-col>
    </v-row>

    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Mask Group 59@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f">
          {{ $t("fatherinfo") }}
        </label>
      </div>
    </div>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.fathername"
          color="#F99D20"
          :disabled="disbleall"
          class="empID"
          :error-messages="error.errormessagefather"
        >
          <template v-slot:label>
            <span> {{ $t("fathername") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.fatherid"
          color="#F99D20"
          :disabled="disbleall"
          class="empID"
          :error-messages="error.errormessagefatherid"
        >
          <template v-slot:label>
            <span> {{ $t("IDcard") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.fatherjob"
          color="#F99D20"
          :disabled="disbleall"
          class="empID"
          @keypress="onlyalpha($event)"
          :error-messages="error.errormessagefatherjob"
        >
          <template v-slot:label>
            <span> {{ $t("occupation") }}</span>
          </template></v-text-field
        >
      </v-col>
      <!-- <v-col cols="12" sm="6" md="3">
        <v-select
          style="margin-top: 0.4rem;"
          v-model="familydata.fathertax"
          :items="fathertaxitems"
          :label="$t('taxdeduction')"
          :error-messages="error.errormessagefathertax"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
      </v-col> -->
    </v-row>

    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Mask Group 60@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f">
          {{ $t("motherinfo") }}
        </label>
      </div>
    </div>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.mothername"
          color="#F99D20"
          :disabled="disbleall"
          class="empID"
          :error-messages="error.errormessagemother"
        >
          <template v-slot:label>
            <span> {{ $t("mothername") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.motherid"
          color="#F99D20"
          :disabled="disbleall"
          class="empID"
          :error-messages="error.errormessagemotherid"
        >
          <template v-slot:label>
            <span> {{ $t("IDcard") }}</span>
          </template></v-text-field
        >
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.motherjob"
          color="#F99D20"
          :disabled="disbleall"
          class="empID"
          @keypress="onlyalpha($event)"
          :error-messages="error.errormessagemotherjob"
        >
          <template v-slot:label>
            <span> {{ $t("occupation") }}</span>
          </template></v-text-field
        >
      </v-col>
      <!-- <v-col cols="12" sm="6" md="3">
        <v-select
          :items="mothertaxitems"
          v-model="familydata.mothertax"
          style="margin-top: 0.4rem;"
          :label="$t('taxdeduction')"
          :error-messages="error.errormessagemothertax"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
      </v-col> -->
    </v-row>

    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/heart spouse@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f">{{
          $t("spouseinfo")
        }}</label>
      </div>
    </div>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          :items="spouseitems"
          item-text="txt"
          item-value="val"
          v-model="familydata.spouse"
          v-on:keyup="keymonitor"
          :error-messages="error.errormessagespouse"
          style="margin-top: 0.4rem"
          :label="$t('spouse')"
          :disabled="disbleall"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.spousename"
          color="#F99D20"
          :error-messages="error.errormessagespousename"
          :disabled="disbleall"
          class="empID"
          :label="$t('spousename')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.spouseid"
          color="#F99D20"
          :error-messages="error.errormessagespouseid"
          :disabled="disbleall"
          class="empID"
          :label="$t('IDcard')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" style="margin-top: 1.1rem">
        <v-text-field
          autocomplete="off"
          v-model="familydata.spousejob"
          color="#F99D20"
          :disabled="disbleall"
          @keypress="onlyalpha($event)"
          :error-messages="error.errormessagespousejob"
          class="empID"
          :label="$t('occupation')"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12" sm="6" md="3">
        <v-autocomplete
          v-on:keyup="keymonitor1"
          :items="spousetaxitems"
          v-model="familydata.spousetax"
          style="margin-top: 0.4rem;"
          :label="$t('taxdeduction')"
          :disabled="disbleall"
          :error-messages="error.errormessagespousetax"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-autocomplete>
      </v-col> -->
    </v-row>

    <v-row class="pl-md-0 pt-9" style="margin-left: 15px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="6" md="4" lg="3">
        <v-row>
          <div>
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/Mask Group 61@2x.png"
            />
          </div>
          <div class="ml-3">
            <label style="font-size: 17px; color: #f89d1f">{{
              $t("childinfo")
            }}</label>
          </div>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        width="150px"
        :disabled="disbleall"
        style="font-weight: normal"
        color="#F99D20"
        class="white--text text-capitalize mr-5 btnlef"
        @click="addItem"
        >{{ $t("Addchild") }}</v-btn
      >
    </v-row>

    <v-row v-for="item in childdata"
      :key="item.value"
      class="pl-md-0"
      style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="mt-7">
        <v-text-field
          v-model="item.name"
          color="#F99D20"
          :disabled="disbleall"
          :error-messages="item.errormessagechildname"
          class="empID"
          :label="$t('childname')">
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="3"
        lg="3"
        class="btnleftzero mt-7">
        <v-text-field
          v-model="item.idcard"
          color="#F99D20"
          :disabled="disbleall"
          :error-messages="item.errormessagechildid"
          class="empID"
          :label="$t('IDcard')">
        </v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="btnleftzero">
        <!-- <v-menu
          v-model="item.menu6"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          fluid
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              readonly
              append-icon="mdi-calendar-month-outline"
              v-model="computedDateFormatted6[item.dateid]"
              :label="$t('date_of_birth')"
              class="kanit-medium"
              color="#F99D20"
              persistent-hint
              :error-messages="item.errormessagechilddob"
              :disabled="disbleall"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.dateob"
            no-title
            @input="item.menu6 = false"
          ></v-date-picker>
        </v-menu> -->
        <label style="color: #8a8c8e">{{ $t("date_of_birth") }}</label>
        <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="item.dateob" :disabled="disbleall" 
        :disabled-date="allowDates" :editable="false" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="1"
        lg="1"
        class="btnleftzero"
        style="margin-left: 6rem"
      >
        <div v-show="showdel">
          <v-btn v-show="item.value2" :disabled="disadelete" @click="removeitem()" icon class="mt-4 ml-4">
            <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div
      class="d-flex flex-row-reverse"
      style="
        margin-top: 3rem;
        padding-bottom: 3rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      "
    >
      <v-btn
        v-show="showEditBtn"
        class="pa-2 mr-2 text_transform_none commonsavebtn"
        @click="ChangeToSave"
        :disabled="
          $route.path ==
          '/selfservice/PersonalProfile/PersonalInformation/Family'
            ? permission.edit
            : permission_management.edit
        "
        >{{ $t("edit") }}</v-btn
      >
      <v-btn
        v-show="showAddWithEID"
        class="pa-2 mr-2 text_transform_none commonsavebtn"
        @click="AddFamilyDatawithEid"
        :disabled="permission_management.add"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        v-show="showAddWithECD"
        class="pa-2 mr-2 text_transform_none commonsavebtn"
        @click="AddFamilyDatawithEcd"
        :disabled="permission_management.add"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        v-show="showAddWithSID"
        class="pa-2 mr-2 text_transform_none commonsavebtn"
        @click="AddFamilyDatawithSid"
        :disabled="permission.add"
        >{{ $t("save") }}</v-btn
      >
      <div>
        <v-btn
          v-show="showCancelBtn"
          class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn"
          @click="cancelgone"
          outlined
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          v-show="showUpdateBtn"
          class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
          :disabled="permission_management.edit"
          @click="UpdateFamilyDataEid"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-show="showUpdateCdeBtn"
          class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
          :disabled="permission_management.edit"
          @click="UpdateFamilyDataEcde"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-show="showUpdateSidBtn"
          class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
          :disabled="permission.edit"
          @click="UpdateFamilyDataSid"
          >{{ $t("save") }}</v-btn
        >
      </div>
    </div>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import moment from 'moment';
export default {
  mixins: [CheckViewAddEdit, LogTrace],
  data: (vm) => ({
    permission: {
      add: true,
      edit: true,
    },
    permission_management: {
      add: true,
      edit: true,
    },
    items: [],
    url: enurl.apiUrl,
    date6: new Date().toISOString().substr(0, 10),
    date7: new Date().toISOString().substr(0, 10),
    date8: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    LoadingDialog: false,
    showEditBtn: false,
    showCancelBtn: false,
    showdel: true,
    showUpdateBtn: false,
    showUpdateCdeBtn: false,
    showUpdateSidBtn: false,
    disbleall: false,
    showAddWithEID: false,
    showAddWithECD: false,
    showAddWithSID: false,
    disadelete: true,
    CountryList: [],
    ProvinceList: [],
    Districtlist: [],
    Subdistrictlist: [],
    Postalcodelist: [],
    FamilyList: [],
    ChildList: [],
    domicileitems: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "The Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Democratic Republic of the Congo",
      "Republic of the Congo",
      "Costa Rica",
      "Côte d’Ivoire",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "The Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "North Korea",
      "South Korea",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (Burma)",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "South Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],

    blooditems: ["A+", "B+", "A-", "B-", "AB+", "AB-", "O+", "O-"],
    fathertaxitems: [
      "Foster care for a father aged 60 years",
      "Father's health insurance premium",
      "Foster care for the disabled or disabled",
    ],
    mothertaxitems: [
      "Foster care for a mother aged 60 years",
      "Mother's health insurance premium",
      "Foster care for the disabled or disabled",
    ],
    spousetaxitems: [
      "Deductive wife or husband (Wife / husband has no income)",
    ],
    childtaxitems: ["A child (Born before 2018)", "A child (Born after 2018)"],
    childdata: [
      {
        condi: 1,
        id: 0,
        name: null,
        idcard: null,
        dateob: "",
        tax: null,
        menu6: false,
        value2: false,
        dateid: 0,
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        errormessagechildname: null,
        errormessagechildid: null,
        errormessagechilddob: null,
        errormessagechildtax: null,
      },
    ],
    familydata: {
      familyID: null,
      domicile: null,
      race: null,
      nationality: null,
      religion: null,
      militaryst: null,
      bloodtype: null,
      height: null,
      weight: null,
      country: null,
      province: null,
      district: null,
      subdistrict: null,
      postalcode: null,
      sosname: null,
      sosaddress: null,
      sosphone: null,
      sosrs: null,
      fathername: null,
      fatherid: null,
      fatherjob: null,
      fathertax: null,
      mothername: null,
      motherid: null,
      motherjob: null,
      mothertax: null,
      spouse: null,
      spousename: null,
      spouseid: null,
      spousejob: null,
      spousetax: null,
    },
    error: {
      errormessagedomicile: null,
      errormessagerace: null,
      errormessagenation: null,
      errormessagereligon: null,
      errormessagemilitary: null,
      errormessageblood: null,
      errormessageheight: null,
      errormessageweight: null,
      errormessagecountry: null,
      errormessageprovince: null,
      errormessagedistrict: null,
      errormessagesubdistrict: null,
      errormessagepostalcode: null,
      errormessagesosname: null,
      errormessagesosaddress: null,
      errormessagesosphone: null,
      errormessagesosrs: null,
      errormessagefather: null,
      errormessagefatherid: null,
      errormessagefatherjob: null,
      errormessagefathertax: null,
      errormessagemother: null,
      errormessagemotherid: null,
      errormessagemotherjob: null,
      errormessagemothertax: null,
      errormessagespouse: null,
      errormessagespousename: null,
      errormessagespouseid: null,
      errormessagespousejob: null,
      errormessagespousetax: null,
    },
    childerror: [
      {
        errormessagechildname: null,
        errormessagechildid: null,
        errormessagechilddob: null,
        errormessagechildtax: null,
      },
    ],
    menu6: false,
    menu7: false,
    menu8: false,
    asid: 1,
    adoid: 1,
    sid: 0,
    doid: 0,
  }),
  computed: {
    raceitems() {
      return [
        { val: "Asian", txt: this.$t("Asian") },
        { val: "American Indian", txt: this.$t("AmericanIndian") },
        { val: "African American", txt: this.$t("AfricanAmerican") },
        { val: "White", txt: this.$t("White") },
        { val: "Latino", txt: this.$t("Latino") },
      ];
    },

    religionitems() {
      return [
        { val: "Buddhism", txt: this.$t("Buddhism") },
        { val: "Islam", txt: this.$t("Islam") },
        { val: "Christianity", txt: this.$t("Christianity") },
        { val: "Hinduism", txt: this.$t("Hinduism") },
        { val: "Sikhism", txt: this.$t("Sikhism") },
        { val: "Judaism", txt: this.$t("Judaism") },
        { val: "Atheism", txt: this.$t("Atheism") },
      ];
    },

    militaryitems() {
      return [
        { val: "Not Indicate", txt: this.$t("NotIndicated") },
        { val: "No Military Service", txt: this.$t("NoMilitaryService") },
        { val: "Active Reserve", txt: this.$t("ActiveReserve") },
        { val: "Inactive Reserve", txt: this.$t("InactiveReserve") },
        { val: "Retired", txt: this.$t("Retired") },
        { val: "Active Duty", txt: this.$t("ActiveDuty") },
        { val: "Vietnam Era Veteran", txt: this.$t("VietnamEraVeteran") },
        { val: "Other Veteran", txt: this.$t("OtherVeteran") },
      ];
    },
    spouseitems() {
      return [
        { val: "Husband", txt: this.$t("Husband") },
        { val: "Wife", txt: this.$t("Wife") },
      ];
    },
    relationshipitems() {
      return [
        { val: "Father", txt: this.$t("Father") },
        { val: "Mother", txt: this.$t("Mother") },
        { val: "Spouse", txt: this.$t("Spouse") },
        { val: "Child", txt: this.$t("Child") },
      ];
    },
    computedDateFormatted6() {
      let gg = [];
      for (let i = 0; i < this.childdata.length; i++) {
        gg.push(this.formatDate(this.childdata[i].dateob));
      }
      return gg;
    },
    computedDateFormatted7() {
      return this.formatDate(this.date7);
    },
    computedDateFormatted8() {
      return this.formatDate(this.date8);
    },
    images() {
      return this.$store.state.images;
    }
  },

  watch: {
    "familydata.domicile"() {
      this.error.errormessagedomicile = [];
    },
    "familydata.race"() {
      this.error.errormessagerace = [];
    },
    "familydata.nationality"() {
      this.error.errormessagenation = [];
    },
    "familydata.religion"() {
      this.error.errormessagereligon = [];
    },
    "familydata.militaryst"() {
      this.error.errormessagemilitary = [];
    },
    "familydata.bloodtype"() {
      this.error.errormessageblood = [];
    },
    "familydata.height"() {
      this.error.errormessageheight = [];
    },
    "familydata.weight"() {
      this.error.errormessageweight = [];
    },
    "familydata.country"() {
      this.error.errormessagecountry = [];
    },
    "familydata.province"() {
      this.error.errormessageprovince = [];
    },
    "familydata.district"() {
      this.error.errormessagedistrict = [];
    },
    "familydata.subdistrict"() {
      this.error.errormessagesubdistrict = [];
    },
    "familydata.postalcode"() {
      this.error.errormessagepostalcode = [];
    },
    "familydata.sosname"() {
      this.error.errormessagesosname = [];
    },
    "familydata.sosaddress"() {
      this.error.errormessagesosaddress = [];
    },
    "familydata.sosrs"() {
      this.error.errormessagesosrs = [];
    },
    "familydata.sosphone"() {
      this.error.errormessagesosphone = [];
    },
    "familydata.fathername"() {
      this.error.errormessagefather = [];
    },
    "familydata.fatherid"() {
      this.error.errormessagefatherid = [];
    },
    "familydata.fatherjob"() {
      this.error.errormessagefatherjob = [];
    },
    "familydata.mothername"() {
      this.error.errormessagemother = [];
    },
    "familydata.motherid"() {
      this.error.errormessagemotherid = [];
    },
    "familydata.motherjob"() {
      this.error.errormessagemotherjob = [];
    },
    "familydata.spouse"() {
      this.error.errormessagespouse = [];
    },
    "familydata.spousename"() {
      this.error.errormessagespousename = [];
    },
    "familydata.spouseid"() {
      this.error.errormessagespouseid = [];
    },
    "familydata.spousejob"() {
      this.error.errormessagespousejob = [];
    },
    // childdata(){
    //   this.childdata.errormessagechildid = [];
    // },
  },

  mounted() {
    this.GetCountry();
    //console.log('aa')
    let id = 0;
    if (this.$route.params.id != undefined)
    {
      id = parseInt(this.$route.params.id);
    }
    else
    {
      id = 0;
    }
    if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Family")
    {
      this.GetFamilyBySid();
      this.GetChildrenBySid();
    }
    else if (id != 0)
    {
      this.GetFamily();
      this.GetChildren();
    }
    else if (localStorage.empcode != null)
    {
      this.showAddWithECD = true;
      this.GetFamilyByCode();
      this.GetChildrenByCode();
    }
    else
    {
      this.showAddWithECD = true;
    }

    this.permission = this.CheckViewAddEdit(149, 150, 151);
    this.permission_management = this.CheckViewAddEdit(221, 222, 223);
    this.disable_btn();
  },

  methods: {
    //permission
    disable_btn() {
      let self = this;
      if (
        self.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/Family"
      ) {
        if (self.permission.add == false) {
          self.permission.add = false;
        }
        if (self.permission.edit == false) {
          self.permission.edit = false;
        }
      } else {
        if (self.permission_management.add == false) {
          self.permission_management.add = false;
        }
        if (self.permission_management.edit == false) {
          self.permission_management.edit = false;
        }
      }
    },

    ChangeToSave() {
      this.showAddWithEID = false;
      this.showEditBtn = false;
      this.showdel = true;
      this.disadelete = false;
      this.showAddWithECD = false;
      this.disbleall = false;
      let id = this.$route.params.id;
      if (
        this.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/Family"
      ) {
        this.showCancelBtn = true;
        this.showUpdateBtn = false;
        this.showUpdateCdeBtn = false;
        this.showUpdateSidBtn = true;
      } else if (id != undefined) {
        this.showCancelBtn = true;
        this.showUpdateBtn = true;
        this.showUpdateCdeBtn = false;
        this.showUpdateSidBtn = false;
      } else {
        this.showCancelBtn = true;
        this.showUpdateBtn = false;
        this.showUpdateCdeBtn = true;
        this.showUpdateSidBtn = false;
      }
    },

    cancelgone() {
      this.showAddWithEID = false;
      this.showEditBtn = true;
      this.showdel = false;
      this.disadelete = false;
      this.showAddWithECD = false;
      this.disbleall = true;
      this.showCancelBtn = false;
      this.showUpdateBtn = false;
      this.showUpdateCdeBtn = false;
      this.showUpdateSidBtn = false;
    },

    keymonitor: function (event) {
      if (event.key == "Backspace")
      {
        this.familydata.spouse = null;
      }
    },

    keymonitor1: function (event) {
      if (event.key == "Backspace")
      {
        this.familydata.spousetax = null;
      }
    },

    mobilenumbervalidation(mobil) {
      let phoneno = /^\d{9}$/;
      let phoneno1 = /^\d{10}$/;
      let phoneno2 = /^\d{11}$/;
      if (phoneno.test(mobil) || phoneno1.test(mobil) || phoneno2.test(mobil))
      {
        return true;
      }
      else
      {
        return "Invalid Mobile format";
      }
    },

    validateDigit(id) {
      let val = /^\d{13}$/;
      return val.test(id) || "Only digit allow";
    },

    onlydigit(id) {
      let val = /^\d*\.?\d*$/;
      return val.test(id) || "Only digit allow";
    },

    onlyalpha(e) {
      var regex = /^\d*\.?\d*$/;
      var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(key))
      {
        e.preventDefault();
        return false;
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    addItem() {
      let self = this;
      let plusid = self.sid++;
      let ha = self.doid++;
      self.childdata.push({
        id: plusid,
        dateid: ha,
        name: null,
        idcard: null,
        dateob: "",
        tax: null,
        value2: true,
      });
      for (let i = 0; i < self.childdata.length; i++)
      {
        if (i >= 1)
        {
          self.childdata[i - 1].value2 = false;
        }
      }
    },

    GetCountry() {
      let self = this;
      axios.get(`${self.url}Country/GetCountryByCompanyID?Company_ID=${store.state.companyID}`)
      .then(function (response) {
        self.CountryList = response.data.data;
      });
    },

    changecountry() {
      let self = this;
      let temp = {
        country_ID: self.familydata.country,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Province/GetProvincebyCountry`, temp)
      .then(function (response) {
        self.ProvinceList = response.data.data;
        self.ProvinceList.sort((a, b) =>
          a.province_Name > b.province_Name ? 1 : -1
        );
      });
    },

    changeprovince() {
      let self = this;
      let temp = {
        province_ID: this.familydata.province,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}District/GetDistrictWithProvince`, temp)
      .then(function (response) {
        self.Districtlist = response.data.data;
        self.Districtlist.sort((a, b) =>
          a.district_Name > b.district_Name ? 1 : -1
        );
      });
    },

    changedistrict() {
      let self = this;
      let temp = {
        district_ID: this.familydata.district,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Sub_District/GetSubDistrictWithDistrict`, temp)
        .then(function (response) {
          self.Subdistrictlist = response.data.data;
          self.Subdistrictlist.sort((a, b) =>
            a.sub_District_Name > b.sub_District_Name ? 1 : -1
          );
        });
    },

    changesubdistrict() {
      let self = this;
      if (self.familydata.subdistrict != "") {
        let temp = {
          company_ID: store.state.companyID,
          sub_District_ID: this.familydata.subdistrict,
        };
        axios.post(`${self.url}PostalCode/GetPostalCodeWithSubDistrict`, temp)
        .then(function (response) {
          self.Postalcodelist = response.data.data;
        });
      }
    },

    //get with id
    GetFamily() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        employee_ID: parseInt(self.$route.params.id),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Family/GetFamilyByEmployeeID`, temp)
      .then(function (response) {
        self.FamilyList = response.data.data;
        if (self.FamilyList.length >= 1)
        {
          self.familydata.familyID = self.FamilyList[0].family_ID;
          self.familydata.domicile = self.FamilyList[0].domicile;
          self.familydata.race = self.FamilyList[0].race;
          self.familydata.nationality = self.FamilyList[0].nationality;
          self.familydata.religion = self.FamilyList[0].religion;
          self.familydata.militaryst = self.FamilyList[0].military_Status;
          self.familydata.bloodtype = self.FamilyList[0].blood_Type;
          self.familydata.height = self.FamilyList[0].height;
          self.familydata.weight = self.FamilyList[0].weight;
          self.familydata.country = self.FamilyList[0].country_ID;
          self.changecountry();
          self.familydata.province = self.FamilyList[0].province_ID;
          self.changeprovince();
          self.familydata.district = self.FamilyList[0].district_ID;
          self.changedistrict();
          self.familydata.subdistrict = self.FamilyList[0].sub_District_ID;
          self.familydata.postalcode = self.FamilyList[0].postal_Code;
          self.familydata.sosname = self.FamilyList[0].eC_Name;
          self.familydata.sosaddress = self.FamilyList[0].eC_Address;
          self.familydata.sosphone = self.FamilyList[0].eC_Ph;
          self.familydata.sosrs = self.FamilyList[0].eC_Relationship;
          self.familydata.fathername = self.FamilyList[0].father_Name;
          self.familydata.fatherid = self.FamilyList[0].father_IDCard;
          self.familydata.fatherjob = self.FamilyList[0].father_Occupation;
          self.familydata.fathertax = self.FamilyList[0].father_Tax_Deduction;
          self.familydata.mothername = self.FamilyList[0].mother_Name;
          self.familydata.motherid = self.FamilyList[0].mother_IDCard;
          self.familydata.motherjob = self.FamilyList[0].mother_Occupation;
          self.familydata.mothertax = self.FamilyList[0].mother_Tax_Deduction;
          self.familydata.spouse = self.FamilyList[0].spouse;
          self.familydata.spousename = self.FamilyList[0].spouse_Name;
          self.familydata.spouseid = self.FamilyList[0].spouse_IDCard;
          self.familydata.spousejob = self.FamilyList[0].spouse_Occupation;
          self.familydata.spousetax = self.FamilyList[0].spouse_Tax_Deduction;
          self.showEditBtn = true;
          self.disadelete = true;
          self.showdel = false;
          self.showCancelBtn = false;
          self.showUpdateCdeBtn = false;
          self.showUpdateSidBtn = false;
          self.showAddWithSID = false;
          self.showAddWithECD = false;
          self.showAddWithEID = false;
          self.showUpdateBtn = false;
          self.disbleall = true;
          self.LoadingDialog = false;
          //self.LogTrace(null, "Get Family", 21, "Critical");
        }
        else
        {
          if (self.$route.params.id != null)
          {
            self.showEditBtn = false;
            self.showAddWithECD = false;
            self.disadelete = false;
            self.showdel = true;
            self.showAddWithEID = true;
            self.disbleall = false;
            self.showUpdateBtn = false;
            self.showUpdateCdeBtn = false;
            self.showUpdateSidBtn = false;
            self.showAddWithSID = false;
            self.LoadingDialog = false;
          }
          else
          {
            self.showEditBtn = false;
            self.showAddWithECD = true;
            self.disadelete = false;
            self.showdel = true;
            self.showAddWithEID = false;
            self.disbleall = false;
            self.showUpdateBtn = false;
            self.showUpdateCdeBtn = false;
            self.showUpdateSidBtn = false;
            self.showAddWithSID = false;
            self.LoadingDialog = false;
          }
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Family Fail", 21, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
    },

    GetChildren() {
      let self = this;
      let temp = {
        employee_ID: parseInt(self.$route.params.id),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Children/GetChildrenByEmployeeID`, temp)
      .then(function (response) {
          self.ChildList = response.data.data;
          if (self.ChildList.length >= 1)
          {
            for (let i = 0; i < self.ChildList.length; i++)
            {
              let ha = self.doid++;
              let ss = self.sid++;
              self.childdata.push({
                id: ss,
                dateid: ha,
                name: self.ChildList[i].child_Name,
                idcard: self.ChildList[i].child_IDCard,
                dateob: self.ChildList[i].birth_Date,
                tax: self.ChildList[i].deduction,
                value2: true,
              });
              if (self.childdata[0].condi == 1)
              {
                self.childdata.splice(0, 1);
              }
              for (let i = 0; i < self.childdata.length; i++)
              {
                if (i > 1)
                {
                  self.childdata[i - 1].value2 = false;
                }
                else if (i == 0)
                {
                  self.childdata[i].value2 = false;
                }
              }
            }

            if (self.childdata.length > self.ChildList.length)
            {
              self.childdata.splice(0, 1);
            }
          }
          else if (self.ChildList.length == 0)
          {
            if (self.childdata.length == 1)
            {
              let plusid = self.sid++;
              let ha = self.doid++;
              self.childdata.push({
                id: plusid,
                dateid: ha,
                name: null,
                idcard: null,
                dateob: "",
                tax: null,
                value2: false,
              });
              self.childdata.splice(0, 1);
              for (let i = 0; i < self.childdata.length; i++)
              {
                if (i >= 1)
                {
                  self.childdata[i - 1].value2 = false;
                }
              }
            }
            else if (self.childdata.length == 0)
            {
              self.childdata.push({
                id: 0,
                dateid: 0,
                name: null,
                idcard: null,
                dateob: "",
                tax: null,
                value2: false,
              });
            }
          }
        });
    },

    //get with session id
    GetFamilyBySid() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Family/GetFamilyByEmployeeID`, temp)
      .then(function (response) {
        self.FamilyList = response.data.data;
        if (self.FamilyList.length >= 1)
        {
          self.familydata.familyID = self.FamilyList[0].family_ID;
          self.familydata.domicile = self.FamilyList[0].domicile;
          self.familydata.race = self.FamilyList[0].race;
          self.familydata.nationality = self.FamilyList[0].nationality;
          self.familydata.religion = self.FamilyList[0].religion;
          self.familydata.militaryst = self.FamilyList[0].military_Status;
          self.familydata.bloodtype = self.FamilyList[0].blood_Type;
          self.familydata.height = self.FamilyList[0].height;
          self.familydata.weight = self.FamilyList[0].weight;
          self.familydata.country = self.FamilyList[0].country_ID;
          self.changecountry();
          self.familydata.province = self.FamilyList[0].province_ID;
          self.changeprovince();
          self.familydata.district = self.FamilyList[0].district_ID;
          self.changedistrict();
          self.familydata.subdistrict = self.FamilyList[0].sub_District_ID;
          self.familydata.postalcode = self.FamilyList[0].postal_Code;
          self.familydata.sosname = self.FamilyList[0].eC_Name;
          self.familydata.sosaddress = self.FamilyList[0].eC_Address;
          self.familydata.sosphone = self.FamilyList[0].eC_Ph;
          self.familydata.sosrs = self.FamilyList[0].eC_Relationship;
          self.familydata.fathername = self.FamilyList[0].father_Name;
          self.familydata.fatherid = self.FamilyList[0].father_IDCard;
          self.familydata.fatherjob = self.FamilyList[0].father_Occupation;
          self.familydata.fathertax = self.FamilyList[0].father_Tax_Deduction;
          self.familydata.mothername = self.FamilyList[0].mother_Name;
          self.familydata.motherid = self.FamilyList[0].mother_IDCard;
          self.familydata.motherjob = self.FamilyList[0].mother_Occupation;
          self.familydata.mothertax = self.FamilyList[0].mother_Tax_Deduction;
          self.familydata.spouse = self.FamilyList[0].spouse;
          self.familydata.spousename = self.FamilyList[0].spouse_Name;
          self.familydata.spouseid = self.FamilyList[0].spouse_IDCard;
          self.familydata.spousejob = self.FamilyList[0].spouse_Occupation;
          self.familydata.spousetax = self.FamilyList[0].spouse_Tax_Deduction;
          self.showEditBtn = true;
          self.showCancelBtn = false;
          self.disadelete = true;
          self.showdel = false;
          self.showUpdateCdeBtn = false;
          self.showAddWithECD = false;
          self.showAddWithEID = false;
          self.showAddWithSID = false;
          self.showUpdateSidBtn = false;
          self.showUpdateBtn = false;
          self.disbleall = true;
          self.LoadingDialog = false;
          //self.LogTrace(null, "Get Family", 21, "Critical");
        }
        else
        {
          self.showEditBtn = false;
          self.showAddWithECD = false;
          self.disadelete = false;
          self.showdel = true;
          self.showAddWithEID = false;
          self.disbleall = false;
          self.showAddWithSID = true;
          self.showUpdateSidBtn = false;
          self.showUpdateBtn = false;
          self.showUpdateCdeBtn = false;
          self.LoadingDialog = false;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Family Fail", 21, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
      self.LoadingDialog = false;
    },

    GetChildrenBySid() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Children/GetChildrenByEmployeeID`, temp)
      .then(function (response) {
          self.ChildList = response.data.data;
          if (self.ChildList.length >= 1)
          {
            for (let i = 0; i < self.ChildList.length; i++)
            {
              let ha = self.doid++;
              let ss = self.sid++;
              self.childdata.push({
                id: ss,
                dateid: ha,
                name: self.ChildList[i].child_Name,
                idcard: self.ChildList[i].child_IDCard,
                dateob: self.ChildList[i].birth_Date,
                tax: self.ChildList[i].deduction,
                value2: true,
              });
              if (self.childdata[0].condi == 1)
              {
                self.childdata.splice(0, 1);
              }
              for (let i = 0; i < self.childdata.length; i++)
              {
                if (i > 1)
                {
                  self.childdata[i - 1].value2 = false;
                }
                else if (i == 0)
                {
                  self.childdata[i].value2 = false;
                }
              }
            }
            if (self.childdata.length > self.ChildList.length)
            {
              self.childdata.splice(0, 1);
            }
          }
          else if (self.ChildList.length == 0)
          {
            if (self.childdata.length == 1)
            {
              let plusid = self.sid++;
              let ha = self.doid++;
              self.childdata.push({
                id: plusid,
                dateid: ha,
                name: null,
                idcard: null,
                dateob: "",
                tax: null,
                value2: false,
              });
              self.childdata.splice(0, 1);
              for (let i = 0; i < self.childdata.length; i++)
              {
                if (i >= 1)
                {
                  self.childdata[i - 1].value2 = false;
                }
              }
            }
            else if (self.childdata.length == 0)
            {
              self.childdata.push({
                id: 0,
                dateid: 0,
                name: null,
                idcard: null,
                dateob: "",
                tax: null,
                value2: false,
              });
            }
          }
        });
    },

    //get with code
    GetFamilyByCode() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        employee_Code: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Family/GetFamilyByEmployeeCode`, temp)
      .then(function (response) {
        self.FamilyList = response.data.data;
        if (self.FamilyList.length >= 1) {
          self.familydata.familyID = self.FamilyList[0].family_ID;
          self.familydata.domicile = self.FamilyList[0].domicile;
          self.familydata.race = self.FamilyList[0].race;
          self.familydata.nationality = self.FamilyList[0].nationality;
          self.familydata.religion = self.FamilyList[0].religion;
          self.familydata.militaryst = self.FamilyList[0].military_Status;
          self.familydata.bloodtype = self.FamilyList[0].blood_Type;
          self.familydata.height = self.FamilyList[0].height;
          self.familydata.weight = self.FamilyList[0].weight;
          self.familydata.country = self.FamilyList[0].country_ID;
          self.changecountry();
          self.familydata.province = self.FamilyList[0].province_ID;
          self.changeprovince();
          self.familydata.district = self.FamilyList[0].district_ID;
          self.changedistrict();
          self.familydata.subdistrict = self.FamilyList[0].sub_District_ID;
          self.familydata.postalcode = self.FamilyList[0].postal_Code;
          self.familydata.sosname = self.FamilyList[0].eC_Name;
          self.familydata.sosaddress = self.FamilyList[0].eC_Address;
          self.familydata.sosphone = self.FamilyList[0].eC_Ph;
          self.familydata.sosrs = self.FamilyList[0].eC_Relationship;
          self.familydata.fathername = self.FamilyList[0].father_Name;
          self.familydata.fatherid = self.FamilyList[0].father_IDCard;
          self.familydata.fatherjob = self.FamilyList[0].father_Occupation;
          self.familydata.fathertax = self.FamilyList[0].father_Tax_Deduction;
          self.familydata.mothername = self.FamilyList[0].mother_Name;
          self.familydata.motherid = self.FamilyList[0].mother_IDCard;
          self.familydata.motherjob = self.FamilyList[0].mother_Occupation;
          self.familydata.mothertax = self.FamilyList[0].mother_Tax_Deduction;
          self.familydata.spouse = self.FamilyList[0].spouse;
          self.familydata.spousename = self.FamilyList[0].spouse_Name;
          self.familydata.spouseid = self.FamilyList[0].spouse_IDCard;
          self.familydata.spousejob = self.FamilyList[0].spouse_Occupation;
          self.familydata.spousetax = self.FamilyList[0].spouse_Tax_Deduction;
          self.showEditBtn = true;
          self.showCancelBtn = false;
          self.disadelete = true;
          self.showdel = false;
          self.showAddWithECD = false;
          self.showAddWithEID = false;
          self.showUpdateBtn = false;
          self.showUpdateCdeBtn = false;
          self.disbleall = true;
          self.LoadingDialog = false;
          //self.LogTrace(null, "Get Family", 21, "Critical");
        } else {
          if (self.$route.params.id != null) {
            self.showEditBtn = false;
            self.showAddWithECD = false;
            self.disadelete = false;
            self.showdel = true;
            self.showAddWithEID = true;
            self.disbleall = false;
            self.showUpdateBtn = false;
            self.showUpdateCdeBtn = false;
            self.LoadingDialog = false;
          } else {
            self.showEditBtn = false;
            self.showAddWithECD = true;
            self.disadelete = false;
            self.showdel = true;
            self.showAddWithEID = false;
            self.disbleall = false;
            self.showUpdateBtn = false;
            self.showUpdateCdeBtn = false;
            self.LoadingDialog = false;
          }
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Family Fail", 21, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
      self.LoadingDialog = false;
    },

    GetChildrenByCode() {
      let self = this;
      let temp = {
        employee_Code: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Children/GetChildrenByEmployeeCode`, temp)
      .then(function (response) {
          self.ChildList = response.data.data;
          if (self.ChildList.length >= 1)
          {
            for (let i = 0; i < self.ChildList.length; i++) {
              let ha = self.doid++;
              let ss = self.sid++;
              self.childdata.push({
                id: ss,
                dateid: ha,
                name: self.ChildList[i].child_Name,
                idcard: self.ChildList[i].child_IDCard,
                dateob: self.ChildList[i].birth_Date,
                tax: self.ChildList[i].deduction,
                value2: true,
              });
              if (self.childdata[0].condi == 1) {
                self.childdata.splice(0, 1);
              }
              for (let i = 0; i < self.childdata.length; i++) {
                if (i > 1) {
                  self.childdata[i - 1].value2 = false;
                } else if (i == 0) {
                  self.childdata[i].value2 = false;
                }
              }
            }

            if (self.childdata.length > self.ChildList.length) {
              self.childdata.splice(0, 1);
            }
          } else if (self.ChildList.length == 0) {
            if (self.childdata.length == 1) {
              let plusid = self.sid++;
              let ha = self.doid++;
              self.childdata.push({
                id: plusid,
                dateid: ha,
                name: null,
                idcard: null,
                dateob: "",
                tax: null,
                value2: false,
              });
              self.childdata.splice(0, 1);
              for (let i = 0; i < self.childdata.length; i++) {
                if (i >= 1) {
                  self.childdata[i - 1].value2 = false;
                }
              }
            } else if (self.childdata.length == 0) {
              self.childdata.push({
                id: 0,
                dateid: 0,
                name: null,
                idcard: null,
                dateob: "",
                tax: null,
                value2: false,
              });
            }
          }
        });
    },

    ifallnull() {
      let self = this;
      if (
        (self.familydata.domicile == null || self.familydata.domicile == "") &&
        (self.familydata.race == null || self.familydata.race == "") &&
        (self.familydata.nationality == null ||
          self.familydata.nationality == "") &&
        (self.familydata.religion == null || self.familydata.religion == "") &&
        (self.familydata.militaryst == null ||
          self.familydata.militaryst == "") &&
        (self.familydata.bloodtype == null ||
          self.familydata.bloodtype == "") &&
        (self.familydata.height == null || self.familydata.height == "") &&
        (self.familydata.weight == null || self.familydata.weight == "") &&
        (self.familydata.country == null || self.familydata.country == "") &&
        (self.familydata.province == null || self.familydata.province == "") &&
        (self.familydata.district == null || self.familydata.district == "") &&
        (self.familydata.subdistrict == null ||
          self.familydata.subdistrict == "") &&
        (self.familydata.postalcode == null ||
          self.familydata.postalcode == "") &&
        (self.familydata.sosname == null || self.familydata.sosname == "") &&
        (self.familydata.sosaddress == null ||
          self.familydata.sosaddress == "") &&
        (self.familydata.sosphone == null || self.familydata.sosphone == "") &&
        (self.familydata.sosrs == null || self.familydata.sosrs == "") &&
        (self.familydata.fathername == null ||
          self.familydata.fathername == "") &&
        (self.familydata.fatherid == null || self.familydata.fatherid == "") &&
        (self.familydata.fatherjob == null ||
          self.familydata.fatherjob == "") &&
        (self.familydata.mothername == null ||
          self.familydata.mothername == "") &&
        (self.familydata.motherid == null || self.familydata.motherid == "") &&
        (self.familydata.motherjob == null || self.familydata.motherjob == "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    //add with id
    AddFamilyDatawithEid() {
      let self = this;
      self.LoadingDialog = true;
      // self.error.errormessagedomicile = !self.familydata.domicile
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagerace = !self.familydata.race
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagenation = !self.familydata.nationality
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagereligon = !self.familydata.religion
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemilitary = !self.familydata.militaryst
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageblood = !self.familydata.bloodtype
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageheight = !self.familydata.height
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageweight = !self.familydata.weight
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagecountry = !self.familydata.country
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageprovince = !self.familydata.province
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagedistrict = !self.familydata.district
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesubdistrict = !self.familydata.subdistrict
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagepostalcode = !self.familydata.postalcode
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosname = !self.familydata.sosname
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosaddress = !self.familydata.sosaddress
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosphone = !self.familydata.sosphone
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosrs = !self.familydata.sosrs
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefather = !self.familydata.fathername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherid = !self.familydata.fatherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherjob = !self.familydata.fatherjob
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemother = !self.familydata.mothername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherid = !self.familydata.motherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherjob = !self.familydata.motherjob
      //   ? "Please fill in the required fields"
      //   : "";
      let isitnull = self.ifallnull();
      if (isitnull == false)
      {
        let tempvalidate = self.ValideTrue();
        let tempvalidspouse = self.ValideSpouseTrue();
        let tempvalidchild = self.ValideChildTrue();
        // if (
        //   (self.familydata.spouse == null &&
        //     self.familydata.spousename == null &&
        //     self.familydata.spouseid == null &&
        //     self.familydata.spousejob == null) ||
        //   (self.familydata.spouse == "" &&
        //     self.familydata.spousename == "" &&
        //     self.familydata.spouseid == "" &&
        //     self.familydata.spousejob == "")
        // ) {
        //   tempvalidspouse = true;
        //   self.error.errormessagespouse = null;
        //   self.error.errormessagespousename = null;
        //   self.error.errormessagespousejob = null;
        //   self.error.errormessagespouseid = null;
        // } else if (
        //   self.familydata.spouse != null ||
        //   self.familydata.spousename != null ||
        //   self.familydata.spouseid != null ||
        //   self.familydata.spousejob != null ||
        //   self.familydata.spouse != "" ||
        //   self.familydata.spousename != "" ||
        //   self.familydata.spouseid != "" ||
        //   self.familydata.spousejob != ""
        // ) {
        if (tempvalidspouse == true)
        {
          tempvalidspouse = true;
        }
        else
        {
          self.ValideSpouseFalse();
        }
        //}
        if (tempvalidchild != 1)
        {
          self.ValideChildFalse();
        }
        if (self.familydata.country == null || self.familydata.country == "")
        {
          self.familydata.country = 0;
        }
        if (self.familydata.province == null || self.familydata.province == "")
        {
          self.familydata.province = 0;
        }
        if (self.familydata.district == null || self.familydata.district == "")
        {
          self.familydata.district = 0;
        }
        if (self.familydata.subdistrict == null || self.familydata.subdistrict == "")
        {
          self.familydata.subdistrict = 0;
        }
        if ( self.familydata.postalcode == null || self.familydata.postalcode == "")
        {
          self.familydata.postalcode = 0;
        }
        if (tempvalidate == true && tempvalidspouse == true && tempvalidchild == 1)
        {
          let arrayte = self.tearrayaddlist();
          let tempadd = {
            domicile: self.familydata.domicile,
            race: self.familydata.race,
            nationality: self.familydata.nationality,
            religion: self.familydata.religion,
            military_Status: self.familydata.militaryst,
            blood_Type: self.familydata.bloodtype,
            height: self.familydata.height,
            weight: self.familydata.weight,
            country_ID: self.familydata.country,
            province_ID: self.familydata.province,
            district_ID: self.familydata.district,
            sub_District_ID: self.familydata.subdistrict,
            postal_Code: self.familydata.postalcode,
            eC_Name: self.familydata.sosname,
            eC_Address: self.familydata.sosaddress,
            eC_Ph: self.familydata.sosphone,
            eC_Relationship: self.familydata.sosrs,
            father_Name: self.familydata.fathername,
            father_IDCard: self.familydata.fatherid,
            father_Occupation: self.familydata.fatherjob,
            father_Tax_Deduction: self.familydata.fathertax,
            mother_Name: self.familydata.mothername,
            mother_IDCard: self.familydata.motherid,
            mother_Occupation: self.familydata.motherjob,
            mother_Tax_Deduction: self.familydata.mothertax,
            spouse: self.familydata.spouse,
            spouse_Name: self.familydata.spousename,
            spouse_IDCard: self.familydata.spouseid,
            spouse_Occupation: self.familydata.spousejob,
            spouse_Tax_Deduction: self.familydata.spousetax,
            employee_ID: parseInt(self.$route.params.id),
            company_ID: store.state.companyID,
            childData: arrayte,
          };
          axios.post(`${self.url}Family/AddFamily`, tempadd)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.showEditBtn = true;
              self.showAddWithECD = false;
              self.disadelete = true;
              self.showdel = false;
              self.showAddWithEID = false;
              self.showUpdateBtn = false;
              self.showUpdateCdeBtn = false;
              self.disbleall = true;

              for (let i = 0; i < self.childdata.length; i++)
              {
                self.childdata[i].errormessagechildid = [];
              }
              // if (
              //   self.childdata[0].name != null &&
              //   self.childdata[0].name != ""
              // ) {
              //   self.childdata = [];
              // }
              // if (self.childdata.length > 1) {
              //   self.childdata = [];
              // }
              self.GetFamily();
              if (self.childdata.length == 0)
              {
                self.sid = 0;
                self.doid = 0;
                setTimeout(function () {
                  self.GetChildren();
                }, 2000);
              }
              self.LoadingDialog = false;
              //self.LogTrace(null, "Add Family ", 21, "Low");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Family Fail", 21, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else
        {
          self.ValideFalse();
          self.LoadingDialog = false;
        }
      }
      else
      {
        self.LoadingDialog = false;
      }
    },

    tearrayaddlist() {
      let self = this;
      let temparr = [
        {
          child_Name: null,
          child_IDCard: null,
          birth_Date: null,
          deduction: null,
          employee_ID: null,
        },
      ];
      if (self.childdata[0].name != null && self.childdata[0].name != "" && self.childdata[0].dateob != null && self.childdata[0].dateob != "")
      {
        for (let i = 0; i < self.childdata.length; i++)
        {
          if (i == 0)
          {
            temparr[i].child_Name = self.childdata[i].name;
            temparr[i].child_IDCard = self.childdata[i].idcard;
            temparr[i].birth_Date = new Date(self.childdata[i].dateob);
            temparr[i].deduction = self.childdata[i].tax;
            temparr[i].employee_ID = parseInt(self.$route.params.id);
          }
          else
          {
            temparr.push({
              child_Name: self.childdata[i].name,
              child_IDCard: self.childdata[i].idcard,
              birth_Date: new Date(self.childdata[i].dateob),
              deduction: self.childdata[i].tax,
              employee_ID: parseInt(self.$route.params.id),
            });
          }
        }
        return temparr;
      }
      else
      {
        return [];
      }
    },

    AddFamilyDatawithSid() {
      let self = this;
      self.LoadingDialog = true;
      // self.error.errormessagedomicile = !self.familydata.domicile
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagerace = !self.familydata.race
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagenation = !self.familydata.nationality
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagereligon = !self.familydata.religion
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemilitary = !self.familydata.militaryst
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageblood = !self.familydata.bloodtype
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageheight = !self.familydata.height
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageweight = !self.familydata.weight
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagecountry = !self.familydata.country
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageprovince = !self.familydata.province
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagedistrict = !self.familydata.district
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesubdistrict = !self.familydata.subdistrict
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagepostalcode = !self.familydata.postalcode
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosname = !self.familydata.sosname
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosaddress = !self.familydata.sosaddress
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosphone = !self.familydata.sosphone
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosrs = !self.familydata.sosrs
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefather = !self.familydata.fathername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherid = !self.familydata.fatherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherjob = !self.familydata.fatherjob
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemother = !self.familydata.mothername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherid = !self.familydata.motherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherjob = !self.familydata.motherjob
      //   ? "Please fill in the required fields"
      //   : "";
      let isitnull = self.ifallnull();
      if (isitnull == false)
      {
        let tempvalidate = self.ValideTrue();
        let tempvalidspouse = self.ValideSpouseTrue();
        let tempvalidchild = self.ValideChildTrue();
        // if (
        //   (self.familydata.spouse == null &&
        //     self.familydata.spousename == null &&
        //     self.familydata.spouseid == null &&
        //     self.familydata.spousejob == null) ||
        //   (self.familydata.spouse == "" &&
        //     self.familydata.spousename == "" &&
        //     self.familydata.spouseid == "" &&
        //     self.familydata.spousejob == "")
        // ) {
        //   tempvalidspouse = true;
        //   self.error.errormessagespouse = null;
        //   self.error.errormessagespousename = null;
        //   self.error.errormessagespousejob = null;
        //   self.error.errormessagespouseid = null;
        // } else if (
        //   self.familydata.spouse != null ||
        //   self.familydata.spousename != null ||
        //   self.familydata.spouseid != null ||
        //   self.familydata.spousejob != null ||
        //   self.familydata.spouse != "" ||
        //   self.familydata.spousename != "" ||
        //   self.familydata.spouseid != "" ||
        //   self.familydata.spousejob != ""
        // ) {
        if (tempvalidspouse == true)
        {
          tempvalidspouse = true;
        }
        else
        {
          self.ValideSpouseFalse();
        }
        //}
        if (tempvalidchild != 1)
        {
          self.ValideChildFalse();
        }
        if (self.familydata.country == null || self.familydata.country == "")
        {
          self.familydata.country = 0;
        }
        if (self.familydata.province == null || self.familydata.province == "")
        {
          self.familydata.province = 0;
        }
        if (self.familydata.district == null || self.familydata.district == "")
        {
          self.familydata.district = 0;
        }
        if (self.familydata.subdistrict == null || self.familydata.subdistrict == "")
        {
          self.familydata.subdistrict = 0;
        }
        if (self.familydata.postalcode == null || self.familydata.postalcode == "")
        {
          self.familydata.postalcode = 0;
        }
        if (tempvalidate == true && tempvalidspouse == true && tempvalidchild == 1)
        {
          let arrayte = self.tearrayaddlistSid();
          let tempadd = {
            domicile: self.familydata.domicile,
            race: self.familydata.race,
            nationality: self.familydata.nationality,
            religion: self.familydata.religion,
            military_Status: self.familydata.militaryst,
            blood_Type: self.familydata.bloodtype,
            height: self.familydata.height,
            weight: self.familydata.weight,
            country_ID: self.familydata.country,
            province_ID: self.familydata.province,
            district_ID: self.familydata.district,
            sub_District_ID: self.familydata.subdistrict,
            postal_Code: self.familydata.postalcode,
            eC_Name: self.familydata.sosname,
            eC_Address: self.familydata.sosaddress,
            eC_Ph: self.familydata.sosphone,
            eC_Relationship: self.familydata.sosrs,
            father_Name: self.familydata.fathername,
            father_IDCard: self.familydata.fatherid,
            father_Occupation: self.familydata.fatherjob,
            father_Tax_Deduction: self.familydata.fathertax,
            mother_Name: self.familydata.mothername,
            mother_IDCard: self.familydata.motherid,
            mother_Occupation: self.familydata.motherjob,
            mother_Tax_Deduction: self.familydata.mothertax,
            spouse: self.familydata.spouse,
            spouse_Name: self.familydata.spousename,
            spouse_IDCard: self.familydata.spouseid,
            spouse_Occupation: self.familydata.spousejob,
            spouse_Tax_Deduction: self.familydata.spousetax,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
            childData: arrayte,
          };
          axios.post(`${self.url}Family/AddFamily`, tempadd)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.showEditBtn = true;
              self.showAddWithECD = false;
              self.disadelete = true;
              self.showdel = false;
              self.showAddWithEID = false;
              self.showUpdateBtn = false;
              self.showUpdateCdeBtn = false;
              self.disbleall = true;
              self.LoadingDialog = false;
              for (let i = 0; i < self.childdata.length; i++)
              {
                self.childdata[i].errormessagechildid = [];
              }
              // if (
              //   self.childdata[0].name != null &&
              //   self.childdata[0].name != ""
              // ) {
              //   self.childdata = [];
              // }
              // if (self.childdata.length > 1) {
              //   self.childdata = [];
              // }
              self.GetFamilyBySid();
              if (self.childdata.length == 0)
              {
                self.sid = 0;
                self.doid = 0;
                setTimeout(function () {
                  self.GetChildrenBySid();
                }, 2000);
              }
              //self.LogTrace(null, "Add Family ", 21, "Low");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Family Fail", 21, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else
        {
          self.ValideFalse();
          self.LoadingDialog = false;
        }
      }
      else
      {
        self.LoadingDialog = false;
      }
    },

    tearrayaddlistSid() {
      let self = this;
      let temparr = [
        {
          child_Name: null,
          child_IDCard: null,
          birth_Date: null,
          deduction: null,
          employee_ID: null,
        },
      ];
      if (self.childdata[0].name != null && self.childdata[0].name != "" && self.childdata[0].dateob != null && self.childdata[0].dateob != "")
      {
        for (let i = 0; i < self.childdata.length; i++)
        {
          if (i == 0)
          {
            temparr[i].child_Name = self.childdata[i].name;
            temparr[i].child_IDCard = self.childdata[i].idcard;
            temparr[i].birth_Date = new Date(self.childdata[i].dateob);
            temparr[i].deduction = self.childdata[i].tax;
            temparr[i].employee_ID = store.state.employeeID;
          }
          else
          {
            temparr.push({
              child_Name: self.childdata[i].name,
              child_IDCard: self.childdata[i].idcard,
              birth_Date: new Date(self.childdata[i].dateob),
              deduction: self.childdata[i].tax,
              employee_ID: store.state.employeeID,
            });
          }
        }
        return temparr;
      }
      else
      {
        return [];
      }
    },

    AddFamilyDatawithEcd() {
      let self = this;
      self.LoadingDialog = true;
      // self.error.errormessagedomicile = !self.familydata.domicile
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagerace = !self.familydata.race
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagenation = !self.familydata.nationality
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagereligon = !self.familydata.religion
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemilitary = !self.familydata.militaryst
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageblood = !self.familydata.bloodtype
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageheight = !self.familydata.height
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageweight = !self.familydata.weight
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagecountry = !self.familydata.country
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageprovince = !self.familydata.province
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagedistrict = !self.familydata.district
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesubdistrict = !self.familydata.subdistrict
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagepostalcode = !self.familydata.postalcode
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosname = !self.familydata.sosname
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosaddress = !self.familydata.sosaddress
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosphone = !self.familydata.sosphone
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosrs = !self.familydata.sosrs
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefather = !self.familydata.fathername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherid = !self.familydata.fatherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherjob = !self.familydata.fatherjob
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemother = !self.familydata.mothername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherid = !self.familydata.motherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherjob = !self.familydata.motherjob
      //   ? "Please fill in the required fields"
      //   : "";
      let isitnull = self.ifallnull();
      if (!isitnull)
      {
        var tempvalidate = self.ValideTrue();
        let tempvalidspouse = self.ValideSpouseTrue();
        let tempvalidchild = self.ValideChildTrue();
        // if (
        //   (self.familydata.spouse == null &&
        //     self.familydata.spousename == null &&
        //     self.familydata.spouseid == null &&
        //     self.familydata.spousejob == null) ||
        //   (self.familydata.spouse == "" &&
        //     self.familydata.spousename == "" &&
        //     self.familydata.spouseid == "" &&
        //     self.familydata.spousejob == "")
        // ) {
        //   tempvalidspouse = true;
        // } else if (
        //   self.familydata.spouse != null ||
        //   self.familydata.spousename != null ||
        //   self.familydata.spouseid != null ||
        //   self.familydata.spousejob != null ||
        //   self.familydata.spouse != "" ||
        //   self.familydata.spousename != "" ||
        //   self.familydata.spouseid != "" ||
        //   self.familydata.spousejob != ""
        // ) {
        if (tempvalidspouse == true) {
          tempvalidspouse = true;
        } else {
          self.ValideSpouseFalse();
        }
        //}
        if (tempvalidchild != 1)
        {
          self.ValideChildFalse();
        }
        if (self.familydata.country == null || self.familydata.country == "")
        {
          self.familydata.country = 0;
        }
        if (self.familydata.province == null || self.familydata.province == "")
        {
          self.familydata.province = 0;
        }
        if (self.familydata.district == null || self.familydata.district == "")
        {
          self.familydata.district = 0;
        }
        if (self.familydata.subdistrict == null || self.familydata.subdistrict == "")
        {
          self.familydata.subdistrict = 0;
        }
        if (self.familydata.postalcode == null || self.familydata.postalcode == "")
        {
          self.familydata.postalcode = 0;
        }
        if (tempvalidate == true && tempvalidspouse == true && tempvalidchild == 1)
        {
          let arrayte = self.tearrayaddcdelist();
          let tempadd = {
            domicile: self.familydata.domicile,
            race: self.familydata.race,
            nationality: self.familydata.nationality,
            religion: self.familydata.religion,
            military_Status: self.familydata.militaryst,
            blood_Type: self.familydata.bloodtype,
            height: self.familydata.height,
            weight: self.familydata.weight,
            country_ID: self.familydata.country,
            province_ID: self.familydata.province,
            district_ID: self.familydata.district,
            sub_District_ID: self.familydata.subdistrict,
            postal_Code: self.familydata.postalcode,
            eC_Name: self.familydata.sosname,
            eC_Address: self.familydata.sosaddress,
            eC_Ph: self.familydata.sosphone,
            eC_Relationship: self.familydata.sosrs,
            father_Name: self.familydata.fathername,
            father_IDCard: self.familydata.fatherid,
            father_Occupation: self.familydata.fatherjob,
            father_Tax_Deduction: self.familydata.fathertax,
            mother_Name: self.familydata.mothername,
            mother_IDCard: self.familydata.motherid,
            mother_Occupation: self.familydata.motherjob,
            mother_Tax_Deduction: self.familydata.mothertax,
            spouse: self.familydata.spouse,
            spouse_Name: self.familydata.spousename,
            spouse_IDCard: self.familydata.spouseid,
            spouse_Occupation: self.familydata.spousejob,
            spouse_Tax_Deduction: self.familydata.spousetax,
            employee_Code: localStorage.empcode,
            company_ID: store.state.companyID,
            childData: arrayte,
          };
          axios.post(`${self.url}Family/AddFamilyWithCode`, tempadd)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.showEditBtn = true;
              self.showAddWithECD = false;
              self.disadelete = true;
              self.showdel = false;
              self.showAddWithEID = false;
              self.showUpdateBtn = false;
              self.showUpdateCdeBtn = false;
              self.disbleall = true;
              self.LoadingDialog = false;
              for (let i = 0; i < self.childdata.length; i++)
              {
                self.childdata[i].errormessagechildid = [];
              }
              self.GetFamilyByCode();
              if (self.childdata.length == 0)
              {
                self.sid = 0;
                self.doid = 0;
                setTimeout(function () {
                  self.GetChildrenByCode();
                }, 2000);
              }
              //self.LogTrace(null, "Add Family", 21, "Low");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Family Fail", 21, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else
        {
          self.ValideFalse();
          self.LoadingDialog = false;
        }
      }
      else
      {
        self.LoadingDialog = false;
      }
    },

    tearrayaddcdelist() {
      let self = this;
      let temparr = [
        {
          child_Name: null,
          child_IDCard: null,
          birth_Date: null,
          deduction: null,
          employee_Code: null,
        },
      ];
      if (self.childdata[0].name != null && self.childdata[0].name != "" && self.childdata[0].dateob != null && self.childdata[0].dateob != "")
      {
        for (let i = 0; i < self.childdata.length; i++)
        {
          if (i == 0)
          {
            temparr[i].child_Name = self.childdata[i].name;
            temparr[i].child_IDCard = self.childdata[i].idcard;
            temparr[i].birth_Date = new Date(self.childdata[i].dateob);
            temparr[i].deduction = self.childdata[i].tax;
            temparr[i].employee_Code = localStorage.empcode;
          }
          else
          {
            temparr.push({
              child_Name: self.childdata[i].name,
              child_IDCard: self.childdata[i].idcard,
              birth_Date: new Date(self.childdata[i].dateob),
              deduction: self.childdata[i].tax,
              employee_Code: localStorage.empcode,
            });
          }
        }
        return temparr;
      }
      else
      {
        return [];
      }
    },

    DeleteOldChild() {
      let self = this;
      self.LoadingDialog = true;
      let id = parseInt(self.$route.params.id);
      let tempdel = {
        employee_ID: id,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Children/DeleteOldChildren`, tempdel)
      .then(function (response) {
        if (response.data.status == 0)
        {
          self.LoadingDialog = false;
        }
      });
    },

    DeleteOldChildSID() {
      let self = this;
      self.LoadingDialog = true;
      let id = store.state.employeeID;
      let tempdel = {
        employee_ID: id,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Children/DeleteOldChildren`, tempdel)
      .then(function (response) {
        if (response.data.status == 0)
        {
          self.LoadingDialog = false;
        }
      });
    },

    DeleteOldChildCde() {
      let self = this;
      self.LoadingDialog = true;
      let id = localStorage.empcode;
      let tempdel = {
        employee_Code: id,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Children/DeleteOldChildrenWithCode`, tempdel)
      .then(function (response) {
        if (response.data.status == 0)
        {
          self.LoadingDialog = false;
        }
      });
    },

    allowDates(date) {
      // Get today's date in 'YYYY-MM-DD' format at the start of the day
      const today = moment().startOf('day');
      // Allow dates greater than today
      return moment(date).isAfter(today);
    },

    UpdateFamilyDataEid() {
      let self = this;
      self.LoadingDialog = true;
      // self.error.errormessagedomicile = !self.familydata.domicile
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagerace = !self.familydata.race
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagenation = !self.familydata.nationality
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagereligon = !self.familydata.religion
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemilitary = !self.familydata.militaryst
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageblood = !self.familydata.bloodtype
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageheight = !self.familydata.height
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageweight = !self.familydata.weight
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagecountry = !self.familydata.country
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageprovince = !self.familydata.province
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagedistrict = !self.familydata.district
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesubdistrict = !self.familydata.subdistrict
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagepostalcode = !self.familydata.postalcode
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosname = !self.familydata.sosname
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosaddress = !self.familydata.sosaddress
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosphone = !self.familydata.sosphone
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosrs = !self.familydata.sosrs
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefather = !self.familydata.fathername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherid = !self.familydata.fatherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherjob = !self.familydata.fatherjob
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemother = !self.familydata.mothername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherid = !self.familydata.motherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherjob = !self.familydata.motherjob
      //   ? "Please fill in the required fields"
      //   : "";
      var tempvalidate = self.ValideTrue();
      let tempvalidspouse = self.ValideSpouseTrue();
      let tempvalidchild = self.ValideChildTrue();
      // if (
      //   (self.familydata.spouse == null &&
      //     self.familydata.spousename == null &&
      //     self.familydata.spouseid == null &&
      //     self.familydata.spousejob == null) ||
      //   (self.familydata.spouse == "" &&
      //     self.familydata.spousename == "" &&
      //     self.familydata.spouseid == "" &&
      //     self.familydata.spousejob == "")
      // ) {
      //   tempvalidspouse = true;
      // } else if (
      //   self.familydata.spouse != null ||
      //   self.familydata.spousename != null ||
      //   self.familydata.spouseid != null ||
      //   self.familydata.spousejob != null ||
      //   self.familydata.spouse != "" ||
      //   self.familydata.spousename != "" ||
      //   self.familydata.spouseid != "" ||
      //   self.familydata.spousejob != ""
      // ) {
      if (tempvalidspouse == true) {
        tempvalidspouse = true;
      } else {
        self.ValideSpouseFalse();
      }
      //}
      // if (
      //   self.familydata.spouse != null ||
      //   self.familydata.spousename != null ||
      //   self.familydata.spouseid != null ||
      //   self.familydata.spousejob != null ||
      //   self.familydata.spouse != "" ||
      //   self.familydata.spousename != "" ||
      //   self.familydata.spouseid != "" ||
      //   self.familydata.spousejob != ""
      // ) {
      //   if (tempvalidspouse == true) {
      //     tempvalidspouse = true;
      //   } else {
      //     self.ValideSpouseFalse();
      //   }
      // }
      if (tempvalidchild != 1) {
        self.ValideChildFalse();
      }
      if (self.familydata.country == null || self.familydata.country == "") {
        self.familydata.country = 0;
      }
      if (self.familydata.province == null || self.familydata.province == "") {
        self.familydata.province = 0;
      }
      if (self.familydata.district == null || self.familydata.district == "") {
        self.familydata.district = 0;
      }
      if (
        self.familydata.subdistrict == null ||
        self.familydata.subdistrict == ""
      ) {
        self.familydata.subdistrict = 0;
      }
      if (
        self.familydata.postalcode == null ||
        self.familydata.postalcode == ""
      ) {
        self.familydata.postalcode = 0;
      }
      if (
        tempvalidate == true &&
        tempvalidspouse == true &&
        tempvalidchild == 1
      ) {
        let tempadd = {
          family_ID: self.familydata.familyID,
          domicile: self.familydata.domicile,
          race: self.familydata.race,
          nationality: self.familydata.nationality,
          religion: self.familydata.religion,
          military_Status: self.familydata.militaryst,
          blood_Type: self.familydata.bloodtype,
          height: self.familydata.height,
          weight: self.familydata.weight,
          country_ID: self.familydata.country,
          province_ID: self.familydata.province,
          district_ID: self.familydata.district,
          sub_District_ID: self.familydata.subdistrict,
          postal_Code: self.familydata.postalcode,
          eC_Name: self.familydata.sosname,
          eC_Address: self.familydata.sosaddress,
          eC_Ph: self.familydata.sosphone,
          eC_Relationship: self.familydata.sosrs,
          father_Name: self.familydata.fathername,
          father_IDCard: self.familydata.fatherid,
          father_Occupation: self.familydata.fatherjob,
          father_Tax_Deduction: self.familydata.fathertax,
          mother_Name: self.familydata.mothername,
          mother_IDCard: self.familydata.motherid,
          mother_Occupation: self.familydata.motherjob,
          mother_Tax_Deduction: self.familydata.mothertax,
          spouse: self.familydata.spouse,
          spouse_Name: self.familydata.spousename,
          spouse_IDCard: self.familydata.spouseid,
          spouse_Occupation: self.familydata.spousejob,
          spouse_Tax_Deduction: self.familydata.spousetax,
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Family/UpdateFamily`, tempadd)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.DeleteOldChild();
              self.UpdateChildwithEid();
              self.showEditBtn = true;
              self.showAddWithECD = false;
              self.showAddWithEID = false;
              self.disadelete = true;
              self.showdel = false;
              self.showUpdateBtn = false;
              self.showUpdateCdeBtn = false;
              self.disbleall = true;

              for (let i = 0; i < self.childdata.length; i++) {
                self.childdata[i].errormessagechildid = [];
              }
              //self.childdata = [];
              self.GetFamily();
              if (self.childdata.length == 0) {
                self.sid = 0;
                self.doid = 0;
                setTimeout(function () {
                  self.GetChildren();
                }, 2000);
              }
              self.LoadingDialog = false;
              self.LogTrace(null, "Update Family ", 21, "Medium");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Family Fail", 21, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
      } else {
        self.ValideFalse();
        self.LoadingDialog = false;
      }
    },
    UpdateFamilyDataSid() {
      let self = this;
      self.LoadingDialog = true;
      // self.error.errormessagedomicile = !self.familydata.domicile
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagerace = !self.familydata.race
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagenation = !self.familydata.nationality
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagereligon = !self.familydata.religion
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemilitary = !self.familydata.militaryst
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageblood = !self.familydata.bloodtype
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageheight = !self.familydata.height
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageweight = !self.familydata.weight
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagecountry = !self.familydata.country
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageprovince = !self.familydata.province
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagedistrict = !self.familydata.district
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesubdistrict = !self.familydata.subdistrict
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagepostalcode = !self.familydata.postalcode
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosname = !self.familydata.sosname
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosaddress = !self.familydata.sosaddress
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosphone = !self.familydata.sosphone
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosrs = !self.familydata.sosrs
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefather = !self.familydata.fathername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherid = !self.familydata.fatherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherjob = !self.familydata.fatherjob
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemother = !self.familydata.mothername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherid = !self.familydata.motherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherjob = !self.familydata.motherjob
      //   ? "Please fill in the required fields"
      //   : "";
      var tempvalidate = self.ValideTrue();
      let tempvalidspouse = self.ValideSpouseTrue();
      let tempvalidchild = self.ValideChildTrue();
      // if (
      //   (self.familydata.spouse == null &&
      //     self.familydata.spousename == null &&
      //     self.familydata.spouseid == null &&
      //     self.familydata.spousejob == null) ||
      //   (self.familydata.spouse == "" &&
      //     self.familydata.spousename == "" &&
      //     self.familydata.spouseid == "" &&
      //     self.familydata.spousejob == "")
      // ) {
      //   tempvalidspouse = true;
      // } else if (
      //   self.familydata.spouse != null ||
      //   self.familydata.spousename != null ||
      //   self.familydata.spouseid != null ||
      //   self.familydata.spousejob != null ||
      //   self.familydata.spouse != "" ||
      //   self.familydata.spousename != "" ||
      //   self.familydata.spouseid != "" ||
      //   self.familydata.spousejob != ""
      // ) {
      if (tempvalidspouse == true) {
        tempvalidspouse = true;
      } else {
        self.ValideSpouseFalse();
      }
      //}
      // if (
      //   self.familydata.spouse != null ||
      //   self.familydata.spousename != null ||
      //   self.familydata.spouseid != null ||
      //   self.familydata.spousejob != null ||
      //   self.familydata.spouse != "" ||
      //   self.familydata.spousename != "" ||
      //   self.familydata.spouseid != "" ||
      //   self.familydata.spousejob != ""
      // ) {
      //   if (tempvalidspouse == true) {
      //     tempvalidspouse = true;
      //   } else {
      //     self.ValideSpouseFalse();
      //   }
      // }
      if (tempvalidchild != 1) {
        self.ValideChildFalse();
      }
      if (self.familydata.country == null || self.familydata.country == "") {
        self.familydata.country = 0;
      }
      if (self.familydata.province == null || self.familydata.province == "") {
        self.familydata.province = 0;
      }
      if (self.familydata.district == null || self.familydata.district == "") {
        self.familydata.district = 0;
      }
      if (
        self.familydata.subdistrict == null ||
        self.familydata.subdistrict == ""
      ) {
        self.familydata.subdistrict = 0;
      }
      if (
        self.familydata.postalcode == null ||
        self.familydata.postalcode == ""
      ) {
        self.familydata.postalcode = 0;
      }
      if (
        tempvalidate == true &&
        tempvalidspouse == true &&
        tempvalidchild == 1
      ) {
        let tempadd = {
          family_ID: self.familydata.familyID,
          domicile: self.familydata.domicile,
          race: self.familydata.race,
          nationality: self.familydata.nationality,
          religion: self.familydata.religion,
          military_Status: self.familydata.militaryst,
          blood_Type: self.familydata.bloodtype,
          height: self.familydata.height,
          weight: self.familydata.weight,
          country_ID: self.familydata.country,
          province_ID: self.familydata.province,
          district_ID: self.familydata.district,
          sub_District_ID: self.familydata.subdistrict,
          postal_Code: self.familydata.postalcode,
          eC_Name: self.familydata.sosname,
          eC_Address: self.familydata.sosaddress,
          eC_Ph: self.familydata.sosphone,
          eC_Relationship: self.familydata.sosrs,
          father_Name: self.familydata.fathername,
          father_IDCard: self.familydata.fatherid,
          father_Occupation: self.familydata.fatherjob,
          father_Tax_Deduction: self.familydata.fathertax,
          mother_Name: self.familydata.mothername,
          mother_IDCard: self.familydata.motherid,
          mother_Occupation: self.familydata.motherjob,
          mother_Tax_Deduction: self.familydata.mothertax,
          spouse: self.familydata.spouse,
          spouse_Name: self.familydata.spousename,
          spouse_IDCard: self.familydata.spouseid,
          spouse_Occupation: self.familydata.spousejob,
          spouse_Tax_Deduction: self.familydata.spousetax,
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Family/UpdateFamily`, tempadd)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.DeleteOldChildSID();
              self.UpdateChildwithSid();
              self.showEditBtn = true;
              self.showAddWithECD = false;
              self.showAddWithEID = false;
              self.disadelete = true;
              self.showdel = false;
              self.showUpdateBtn = false;
              self.showUpdateCdeBtn = false;
              self.disbleall = true;
              self.LoadingDialog = false;
              for (let i = 0; i < self.childdata.length; i++) {
                self.childdata[i].errormessagechildid = [];
              }
              //self.childdata = [];
              self.GetFamilyBySid();
              if (self.childdata.length == 0) {
                self.sid = 0;
                self.doid = 0;
                setTimeout(function () {
                  self.GetChildrenBySid();
                }, 2000);
              }
              self.LogTrace(null, "Update Family ", 21, "Medium");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Family Fail", 21, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
      } else {
        self.ValideFalse();
        self.LoadingDialog = false;
      }
    },
    UpdateFamilyDataEcde() {
      let self = this;
      self.LoadingDialog = true;
      // self.error.errormessagedomicile = !self.familydata.domicile
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagerace = !self.familydata.race
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagenation = !self.familydata.nationality
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagereligon = !self.familydata.religion
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemilitary = !self.familydata.militaryst
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageblood = !self.familydata.bloodtype
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageheight = !self.familydata.height
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageweight = !self.familydata.weight
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagecountry = !self.familydata.country
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessageprovince = !self.familydata.province
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagedistrict = !self.familydata.district
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesubdistrict = !self.familydata.subdistrict
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagepostalcode = !self.familydata.postalcode
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosname = !self.familydata.sosname
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosaddress = !self.familydata.sosaddress
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosphone = !self.familydata.sosphone
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagesosrs = !self.familydata.sosrs
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefather = !self.familydata.fathername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherid = !self.familydata.fatherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagefatherjob = !self.familydata.fatherjob
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemother = !self.familydata.mothername
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherid = !self.familydata.motherid
      //   ? "Please fill in the required fields"
      //   : "";
      // self.error.errormessagemotherjob = !self.familydata.motherjob
      //   ? "Please fill in the required fields"
      //   : "";
      var tempvalidate = self.ValideTrue();
      let tempvalidspouse = self.ValideSpouseTrue();
      let tempvalidchild = self.ValideChildTrue();
      // if (
      //   (self.familydata.spouse == null &&
      //     self.familydata.spousename == null &&
      //     self.familydata.spouseid == null &&
      //     self.familydata.spousejob == null) ||
      //   (self.familydata.spouse == "" &&
      //     self.familydata.spousename == "" &&
      //     self.familydata.spouseid == "" &&
      //     self.familydata.spousejob == "")
      // ) {
      //   tempvalidspouse = true;
      // } else if (
      //   self.familydata.spouse != null ||
      //   self.familydata.spousename != null ||
      //   self.familydata.spouseid != null ||
      //   self.familydata.spousejob != null ||
      //   self.familydata.spouse != "" ||
      //   self.familydata.spousename != "" ||
      //   self.familydata.spouseid != "" ||
      //   self.familydata.spousejob != ""
      // ) {
      //   if (tempvalidspouse == true) {
      //     tempvalidspouse = true;
      //   } else {
      //     self.ValideSpouseFalse();
      //   }
      // }
      // if (
      //   self.familydata.spouse != null ||
      //   self.familydata.spousename != null ||
      //   self.familydata.spouseid != null ||
      //   self.familydata.spousejob != null ||
      //   self.familydata.spouse != "" ||
      //   self.familydata.spousename != "" ||
      //   self.familydata.spouseid != "" ||
      //   self.familydata.spousejob != ""
      // ) {
      if (tempvalidspouse == true) {
        tempvalidspouse = true;
      } else {
        self.ValideSpouseFalse();
      }
      //}
      if (tempvalidchild != 1) {
        self.ValideChildFalse();
      }
      if (self.familydata.country == null || self.familydata.country == "") {
        self.familydata.country = 0;
      }
      if (self.familydata.province == null || self.familydata.province == "") {
        self.familydata.province = 0;
      }
      if (self.familydata.district == null || self.familydata.district == "") {
        self.familydata.district = 0;
      }
      if (
        self.familydata.subdistrict == null ||
        self.familydata.subdistrict == ""
      ) {
        self.familydata.subdistrict = 0;
      }
      if (
        self.familydata.postalcode == null ||
        self.familydata.postalcode == ""
      ) {
        self.familydata.postalcode = 0;
      }
      if (
        tempvalidate == true &&
        tempvalidspouse == true &&
        tempvalidchild == 1
      ) {
        let tempadd = {
          family_ID: self.familydata.familyID,
          domicile: self.familydata.domicile,
          race: self.familydata.race,
          nationality: self.familydata.nationality,
          religion: self.familydata.religion,
          military_Status: self.familydata.militaryst,
          blood_Type: self.familydata.bloodtype,
          height: self.familydata.height,
          weight: self.familydata.weight,
          country_ID: self.familydata.country,
          province_ID: self.familydata.province,
          district_ID: self.familydata.district,
          sub_District_ID: self.familydata.subdistrict,
          postal_Code: self.familydata.postalcode,
          eC_Name: self.familydata.sosname,
          eC_Address: self.familydata.sosaddress,
          eC_Ph: self.familydata.sosphone,
          eC_Relationship: self.familydata.sosrs,
          father_Name: self.familydata.fathername,
          father_IDCard: self.familydata.fatherid,
          father_Occupation: self.familydata.fatherjob,
          father_Tax_Deduction: self.familydata.fathertax,
          mother_Name: self.familydata.mothername,
          mother_IDCard: self.familydata.motherid,
          mother_Occupation: self.familydata.motherjob,
          mother_Tax_Deduction: self.familydata.mothertax,
          spouse: self.familydata.spouse,
          spouse_Name: self.familydata.spousename,
          spouse_IDCard: self.familydata.spouseid,
          spouse_Occupation: self.familydata.spousejob,
          spouse_Tax_Deduction: self.familydata.spousetax,
          employee_Code: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Family/UpdateFamilyWithCode`, tempadd)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.DeleteOldChildCde();
              self.UpdateChildwithCde();
              self.showEditBtn = true;
              self.showAddWithECD = false;
              self.showAddWithEID = false;
              self.disadelete = true;
              self.showdel = false;
              self.showUpdateBtn = false;
              self.showUpdateCdeBtn = false;
              self.disbleall = true;
              self.LoadingDialog = false;
              for (let i = 0; i < self.childdata.length; i++) {
                self.childdata[i].errormessagechildid = [];
              }
              //self.childdata = [];
              self.GetFamilyByCode();
              if (self.childdata.length == 0) {
                self.sid = 0;
                self.doid = 0;
                setTimeout(function () {
                  self.GetChildrenByCode();
                }, 2000);
              }
              self.LogTrace(null, "Update Family ", 21, "Medium");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Family Fail", 21, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
      } else {
        self.ValideFalse();
        self.LoadingDialog = false;
      }
    },
    UpdateChildwithEid() {
      let self = this;
      self.LoadingDialog = true;
      let temparr = [
        {
          child_Name: null,
          child_IDCard: null,
          birth_Date: null,
          deduction: null,
          employee_ID: null,
          company_ID: null,
        },
      ];
      for (let i = 0; i < self.childdata.length; i++) {
        if (i == 0) {
          temparr[i].child_Name = self.childdata[i].name;
          temparr[i].child_IDCard = self.childdata[i].idcard;
          temparr[i].birth_Date = new Date(self.childdata[i].dateob);
          temparr[i].deduction = self.childdata[i].tax;
          temparr[i].employee_ID = parseInt(self.$route.params.id);
          temparr[i].company_ID = store.state.companyID;
        } else {
          temparr.push({
            child_Name: self.childdata[i].name,
            child_IDCard: self.childdata[i].idcard,
            birth_Date: new Date(self.childdata[i].dateob),
            deduction: self.childdata[i].tax,
            employee_ID: parseInt(self.$route.params.id),
            company_ID: store.state.companyID,
          });
        }
      }
      axios
        .post(`${self.url}Children/AddChildren`, temparr)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
          }
        });
      self.LoadingDialog = false;
    },
    UpdateChildwithSid() {
      let self = this;
      self.LoadingDialog = true;
      let temparr = [
        {
          child_Name: null,
          child_IDCard: null,
          birth_Date: null,
          deduction: null,
          employee_ID: null,
          company_ID: null,
        },
      ];
      for (let i = 0; i < self.childdata.length; i++) {
        if (i == 0) {
          temparr[i].child_Name = self.childdata[i].name;
          temparr[i].child_IDCard = self.childdata[i].idcard;
          temparr[i].birth_Date = new Date(self.childdata[i].dateob);
          temparr[i].deduction = self.childdata[i].tax;
          temparr[i].employee_ID = store.state.employeeID;
          temparr[i].company_ID = store.state.companyID;
        } else {
          temparr.push({
            child_Name: self.childdata[i].name,
            child_IDCard: self.childdata[i].idcard,
            birth_Date: new Date(self.childdata[i].dateob),
            deduction: self.childdata[i].tax,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
          });
        }
      }
      axios
        .post(`${self.url}Children/AddChildren`, temparr)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
          }
        });
      self.LoadingDialog = false;
    },
    UpdateChildwithCde() {
      let self = this;
      self.LoadingDialog = true;
      let temparr = [
        {
          child_Name: null,
          child_IDCard: null,
          birth_Date: null,
          deduction: null,
          employee_Code: null,
          company_ID: null,
        },
      ];
      for (let i = 0; i < self.childdata.length; i++) {
        if (i == 0) {
          temparr[i].child_Name = self.childdata[i].name;
          temparr[i].child_IDCard = self.childdata[i].idcard;
          temparr[i].birth_Date = new Date(self.childdata[i].dateob);
          temparr[i].deduction = self.childdata[i].tax;
          (temparr[i].employee_Code = localStorage.empcode),
            (temparr[i].company_ID = store.state.companyID);
        } else {
          temparr.push({
            child_Name: self.childdata[i].name,
            child_IDCard: self.childdata[i].idcard,
            birth_Date: new Date(self.childdata[i].dateob),
            deduction: self.childdata[i].tax,
            employee_Code: localStorage.empcode,
            company_ID: store.state.companyID,
          });
        }
      }
      axios
        .post(`${self.url}Children/AddChildrenWithCode`, temparr)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
          }
        });
      self.LoadingDialog = false;
    },
    isspouseadd() {
      let self = this;
      if (
        self.familydata.spouse != null &&
        self.familydata.spousename != null &&
        self.familydata.spouseid != null &&
        self.familydata.spousejob != null &&
        self.familydata.spouse != "" &&
        self.familydata.spousename != "" &&
        self.familydata.spouseid != "" &&
        self.familydata.spousejob != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    isspouseaddnot() {
      let self = this;
      if (
        self.familydata.spouse == null ||
        self.familydata.spousename == null ||
        self.familydata.spouseid == null ||
        self.familydata.spousejob == null ||
        self.familydata.spouse == "" ||
        self.familydata.spousename == "" ||
        self.familydata.spouseid == "" ||
        self.familydata.spousejob == ""
      ) {
        alert("Please fill the required fields");
      }
    },
    ValideTrue() {
      let self = this;
      let mobilevali = self.mobilenumbervalidation(self.familydata.sosphone);
      let daddigitvali = self.validateDigit(self.familydata.fatherid);
      let momdigitvali = self.validateDigit(self.familydata.motherid);
      let heightvali = self.onlydigit(self.familydata.height);
      let weightvali = self.onlydigit(self.familydata.weight);
      if (
        // self.familydata.domicile != null &&
        // self.familydata.race != null &&
        // self.familydata.nationality != null &&
        // self.familydata.religion != null &&
        // self.familydata.militaryst != null &&
        // self.familydata.bloodtype != null &&
        self.familydata.height != null &&
        self.familydata.weight != null &&
        // self.familydata.country != null &&
        // self.familydata.province != null &&
        // self.familydata.district != null &&
        // self.familydata.subdistrict != null &&
        // self.familydata.postalcode != null &&
        // self.familydata.sosname != null &&
        // self.familydata.sosaddress != null &&
        self.familydata.sosphone != null &&
        // self.familydata.sosrs != null &&
        // self.familydata.fathername != null &&
        self.familydata.fatherid != null &&
        // self.familydata.fatherjob != null &&
        // self.familydata.mothername != null &&
        self.familydata.motherid != null &&
        // self.familydata.motherjob != null &&
        // self.familydata.domicile != "" &&
        // self.familydata.race != "" &&
        // self.familydata.nationality != "" &&
        // self.familydata.religion != "" &&
        // self.familydata.militaryst != "" &&
        // self.familydata.bloodtype != "" &&
        self.familydata.height != "" &&
        self.familydata.weight != "" &&
        // self.familydata.country != "" &&
        // self.familydata.province != "" &&
        // self.familydata.district != "" &&
        // self.familydata.subdistrict != "" &&
        // self.familydata.postalcode != "" &&
        // self.familydata.sosname != "" &&
        // self.familydata.sosaddress != "" &&
        self.familydata.sosphone != "" &&
        // self.familydata.sosrs != "" &&
        // self.familydata.fathername != "" &&
        self.familydata.fatherid != "" &&
        // self.familydata.fatherjob != "" &&
        // self.familydata.mothername != "" &&
        self.familydata.motherid != "" &&
        // self.familydata.motherjob != "" &&
        mobilevali == true &&
        daddigitvali == true &&
        momdigitvali == true &&
        heightvali == true &&
        weightvali == true
      ) {
        return true;
      } else if (
        self.familydata.height == null ||
        self.familydata.weight == null ||
        self.familydata.sosphone == null ||
        self.familydata.fatherid == null ||
        self.familydata.motherid == null ||
        self.familydata.height == "" ||
        self.familydata.weight == "" ||
        self.familydata.sosphone == "" ||
        self.familydata.fatherid == "" ||
        self.familydata.motherid == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValideFalse() {
      let self = this;
      let mobilevali = self.mobilenumbervalidation(self.familydata.sosphone);
      let daddigitvali = self.validateDigit(self.familydata.fatherid);
      let momdigitvali = self.validateDigit(self.familydata.motherid);
      let heightvali = self.onlydigit(self.familydata.height);
      let weightvali = self.onlydigit(self.familydata.weight);
      // if (
      //   self.familydata.domicile == null ||
      //   self.familydata.race == null ||
      //   self.familydata.nationality == null ||
      //   self.familydata.religion == null ||
      //   self.familydata.militaryst == null ||
      //   self.familydata.bloodtype == null ||
      //   self.familydata.height == null ||
      //   self.familydata.weight == null ||
      //   self.familydata.country == null ||
      //   self.familydata.province == null ||
      //   self.familydata.district == null ||
      //   self.familydata.subdistrict == null ||
      //   self.familydata.postalcode == null ||
      //   self.familydata.sosname == null ||
      //   self.familydata.sosaddress == null ||
      //   self.familydata.sosphone == null ||
      //   self.familydata.sosrs == null ||
      //   self.familydata.fathername == null ||
      //   self.familydata.fatherid == null ||
      //   self.familydata.fatherjob == null ||
      //   self.familydata.mothername == null ||
      //   self.familydata.motherid == null ||
      //   self.familydata.motherjob == null ||
      //   self.familydata.name == "" ||
      //   self.familydata.race == "" ||
      //   self.familydata.nationality == "" ||
      //   self.familydata.religion == "" ||
      //   self.familydata.militaryst == "" ||
      //   self.familydata.bloodtype == "" ||
      //   self.familydata.height == "" ||
      //   self.familydata.weight == "" ||
      //   self.familydata.country == "" ||
      //   self.familydata.province == "" ||
      //   self.familydata.district == "" ||
      //   self.familydata.subdistrict == "" ||
      //   self.familydata.postalcode == "" ||
      //   self.familydata.sosname == "" ||
      //   self.familydata.sosaddress == "" ||
      //   self.familydata.sosphone == "" ||
      //   self.familydata.sosrs == "" ||
      //   self.familydata.fathername == "" ||
      //   self.familydata.fatherid == "" ||
      //   self.familydata.fatherjob == "" ||
      //   self.familydata.mothername == "" ||
      //   self.familydata.motherid == "" ||
      //   self.familydata.motherjob == ""
      // ) {
      //   alert("Please fill the required fields");
      //   if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     momdigitvali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     momdigitvali != true &&
      //     heightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     momdigitvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     momdigitvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //   } else if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     heightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (
      //     mobilevali != true &&
      //     daddigitvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     mobilevali != true &&
      //     momdigitvali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     mobilevali != true &&
      //     momdigitvali != true &&
      //     heightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (
      //     mobilevali != true &&
      //     momdigitvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     mobilevali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     daddigitvali != true &&
      //     momdigitvali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     daddigitvali != true &&
      //     momdigitvali != true &&
      //     heightvali != true
      //   ) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (
      //     daddigitvali != true &&
      //     momdigitvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     daddigitvali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (daddigitvali != true && heightvali != true) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (daddigitvali != true && weightvali != true) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (
      //     momdigitvali != true &&
      //     heightvali != true &&
      //     weightvali != true
      //   ) {
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (momdigitvali != true && weightvali != true) {
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (heightvali != true && weightvali != true) {
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (mobilevali != true && daddigitvali != true) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //   } else if (mobilevali != true && momdigitvali != true) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //   } else if (mobilevali != true && heightvali != true) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (mobilevali != true && weightvali != true) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   } else if (daddigitvali != true && momdigitvali != true) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //   } else if (mobilevali != true) {
      //     self.error.errormessagesosphone =
      //       self.familydata.sosphone != mobilevali
      //         ? "Please fill at least 9 to 11 digits."
      //         : self.familydata.sosphone;
      //   } else if (momdigitvali != true) {
      //     self.error.errormessagemotherid =
      //       self.familydata.motherid != momdigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.motherid;
      //   } else if (daddigitvali != true) {
      //     self.error.errormessagefatherid =
      //       self.familydata.fatherid != daddigitvali
      //         ? "Please fill in 13 digits."
      //         : self.familydata.fatherid;
      //   } else if (heightvali != true) {
      //     self.error.errormessageheight =
      //       self.familydata.height != heightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.height;
      //   } else if (weightvali != true) {
      //     self.error.errormessageweight =
      //       self.familydata.weight != weightvali
      //         ? "Only digits are allowed"
      //         : self.familydata.weight;
      //   }
      // } else
      if (
        mobilevali != true &&
        daddigitvali != true &&
        momdigitvali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        mobilevali != true &&
        daddigitvali != true &&
        momdigitvali != true &&
        heightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (
        mobilevali != true &&
        daddigitvali != true &&
        momdigitvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        mobilevali != true &&
        daddigitvali != true &&
        momdigitvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
      } else if (
        mobilevali != true &&
        daddigitvali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        mobilevali != true &&
        daddigitvali != true &&
        heightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (
        mobilevali != true &&
        daddigitvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        mobilevali != true &&
        momdigitvali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        mobilevali != true &&
        momdigitvali != true &&
        heightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (
        mobilevali != true &&
        momdigitvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        mobilevali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        daddigitvali != true &&
        momdigitvali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        daddigitvali != true &&
        momdigitvali != true &&
        heightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (
        daddigitvali != true &&
        momdigitvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        daddigitvali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (daddigitvali != true && heightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (daddigitvali != true && weightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (
        momdigitvali != true &&
        heightvali != true &&
        weightvali != true
      ) {
        alert("Please fill the correct format.");
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (momdigitvali != true && weightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (heightvali != true && weightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (mobilevali != true && daddigitvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
      } else if (mobilevali != true && momdigitvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
      } else if (mobilevali != true && heightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (mobilevali != true && weightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      } else if (daddigitvali != true && momdigitvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
      } else if (mobilevali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagesosphone =
          self.familydata.sosphone != mobilevali
            ? "Please fill at least 9 to 11 digits."
            : self.familydata.sosphone;
      } else if (momdigitvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagemotherid =
          self.familydata.motherid != momdigitvali
            ? "Please fill in 13 digits."
            : self.familydata.motherid;
      } else if (daddigitvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessagefatherid =
          self.familydata.fatherid != daddigitvali
            ? "Please fill in 13 digits."
            : self.familydata.fatherid;
      } else if (heightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessageheight =
          self.familydata.height != heightvali
            ? "Only digits are allowed"
            : self.familydata.height;
      } else if (weightvali != true) {
        alert("Please fill the correct format.");
        self.error.errormessageweight =
          self.familydata.weight != weightvali
            ? "Only digits are allowed"
            : self.familydata.weight;
      }
    },
    ValideSpouseTrue() {
      let self = this;
      let spodigitvali = self.validateDigit(self.familydata.spouseid);
      if (
        // self.familydata.spouse != null &&
        // self.familydata.spousename != null &&
        self.familydata.spouseid != null &&
        // self.familydata.spousejob != null &&
        // self.familydata.spouse != "" &&
        // self.familydata.spousename != "" &&
        self.familydata.spouseid != "" &&
        // self.familydata.spousejob != "" &&
        spodigitvali == true
      ) {
        self.error.errormessagespouse = null;
        self.error.errormessagespousename = null;
        self.error.errormessagespousejob = null;
        self.error.errormessagespouseid = null;
        return true;
      } else if (
        self.familydata.spouseid == null ||
        self.familydata.spouseid == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValideSpouseFalse() {
      let self = this;
      let spodigitvali = self.validateDigit(self.familydata.spouseid);
      // if (
      //   self.familydata.spouse == null ||
      //   self.familydata.spousename == null ||
      //   self.familydata.spouseid == null ||
      //   self.familydata.spousejob == null ||
      //   self.familydata.spouse == "" ||
      //   self.familydata.spousename == "" ||
      //   self.familydata.spouseid == "" ||
      //   self.familydata.spousejob == ""
      // ) {
      //   self.error.errormessagespouse = !self.familydata.spouse
      //     ? "Please fill in the required fields"
      //     : "";
      //   self.error.errormessagespousename = !self.familydata.spousename
      //     ? "Please fill in the required fields"
      //     : "";
      //   self.error.errormessagespouseid = !self.familydata.spouseid
      //     ? "Please fill in the required fields"
      //     : "";
      //   self.error.errormessagespousejob = !self.familydata.spousejob
      //     ? "Please fill in the required fields"
      //     : "";
      // } else
      if (spodigitvali != true) {
        self.error.errormessagespouseid =
          self.familydata.spouseid != spodigitvali
            ? "Please fill in 13 digits."
            : self.familydata.spouseid;
        self.error.errormessagespouse = null;
        self.error.errormessagespousename = null;
        self.error.errormessagespousejob = null;
      }
    },
    Vader() {
      let self = this;
      if (
        (self.childdata[0].name == null && self.childdata[0].idcard == null) ||
        (self.childdata[0].name == "" && self.childdata[0].idcard == "")
      ) {
        return true;
      } else {
        for (let i = 0; i < self.childdata.length; i++) {
          let chidigitvali = self.validateDigit(self.childdata[i].idcard);
          if (
            self.childdata[i].name != null &&
            self.childdata[i].idcard != null &&
            self.childdata[i].name != "" &&
            self.childdata[i].idcard != "" &&
            chidigitvali == true
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    ValideChildTrue() {
      let self = this;
      let result = 1;
      for (let i = 0; i < self.childdata.length; i++) {
        let chidigitvali = self.validateDigit(self.childdata[i].idcard);
        if (
          self.childdata[i].idcard == null ||
          self.childdata[i].idcard == ""
        ) {
          if (result != 1) {
            result = result + 2;
          } else {
            result = 1;
          }
        } else if (
          self.childdata[i].idcard != null &&
          self.childdata[i].idcard != "" &&
          chidigitvali == true
        ) {
          if (result != 1) {
            result = result + 2;
          } else {
            result = 1;
          }
        } else {
          result = result + 2;
        }
      }
      return result;
    },
    ValideChildFalse() {
      let self = this;
      for (let i = 0; i < self.childdata.length; i++) {
        if (
          self.childdata[i].idcard != null &&
          self.childdata[i].idcard != ""
        ) {
          let chiidvali = self.validateDigit(self.childdata[i].idcard);
          if (chiidvali != true) {
            self.childdata[i].errormessagechildid = "Please fill in 13 digits.";
          }
        }
      }
    },
    VaderFalse() {
      let self = this;
      for (let i = 0; i < self.childdata.length; i++) {
        let chiidvali = self.validateDigit(self.childdata[i].idcard);
        if (
          self.childdata[i].name == null ||
          self.childdata[i].idcard == null ||
          self.childdata[i].name == "" ||
          self.childdata[i].idcard == ""
        ) {
          alert("Please fill in the required fields");
          self.childdata[i].errormessagechildname = !self.childdata[i].name
            ? "Please fill in the required fields"
            : "";
          self.childdata[i].errormessagechildid = !self.childdata[i].idcard
            ? "Please fill in the required fields"
            : "";
        } else if (chiidvali != true) {
          self.childdata[i].errormessagechildid =
            self.childdata[i].idcard != chiidvali
              ? "Please fill in 13 digits."
              : self.childdata[i].idcard;
        }
      }
    },
    removeitem() {
      let self = this;
      self.childdata.splice(self.childdata.length - 1, 1);
      self.childdata[self.childdata.length - 1].value2 = true;
      for (var i = 0; i < self.childdata.length; i++) {
        if (self.childdata.length == 1) {
          self.childdata[i].value2 = false;
        }
      }
      self.sid = self.sid - 1;
      self.doid = self.doid - 1;
    },
  },
};
</script>

<style scoped>
>>> .v-select__selections {
  padding-left: unset !important;
}
.empID {
  margin: 0px;
  padding: 0px;
  font-size: 15px;
}
@media (max-width: 960px) {
  .btnleftzero {
    margin-left: 0rem !important;
  }
  .btnlef {
    margin-left: 1.5rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .save_btn {
    margin-top: 1rem !important;
    margin-left: 1.5rem;
  }
  .cancle_btn {
    margin-left: 1.5rem;
  }
}
</style>
