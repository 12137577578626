<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 2.5rem;">
      <p class="txt-header">
        {{ $t("LeaveType") }}
      </p>
    <div style="height: auto" class="mt-6">
      <v-card min-height="80vh" min-width="450px" style="overflow-x: auto;" class="d-flex flex-wrap flex-column pa-6">
        <v-row>
          <v-col cols="12" lg="3" md="4" style="border-right: 1px solid #cfcdcd">
            <v-btn color="#F99D20" class="white--text mt-2 ml-0 mr-4 text-capitalize" style="width: 100%" max-height="35"
              @click="GonnaAddLType">{{ $t("plusLeave") }}</v-btn>
            <v-data-table class="mt-4" style="max-height: 630px; overflow-y: auto;" :items="leaveTypeList"
              disable-pagination hide-default-footer hide-default-header :mobile-breakpoint="0">
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th style="max-width: 180px !important">
                      {{ $t("LeaveType") }}
                    </th>
                    <th class="text-end pr-4">{{ $t("Enable") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr :style="{ backgroundColor: thisRow(item) == true ? '#FBE5D6' : 'transparent' }"
                  @click="chooseThis(item)">
                  <td v-show="!thethaihead" @click="chooseThis(item)">{{ item.timeoff_TypeEN }}</td>
                  <td v-show="thethaihead" @click="chooseThis(item)">{{ item.timeoff_TypeTH }}</td>
                  <td align="right" >
                    <div style="cursor: pointer; width: 66px;" @click="chooseThis(item)" v-if="item.leaveStatus == true"
                    @click.stop="ChangeEnable(item.leaveStatus)">
                      <div v-if="item.leaveStatus == true">
                        <input type="checkbox" v-model="item.leaveStatus" />
                      </div>
                    </div>
                    <div style="cursor: pointer; width: 66px;" @click="chooseThis(item)" v-else @click.stop="ChangeEnable(item.leaveStatus)">
                      <input type="checkbox" style="cursor: pointer" v-model="item.leaveStatus" />
                    </div>
                  </td>
                  <!-- <td align="right">
                    
                  </td> -->
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" lg="9" md="8" style="position: relative;">
            <v-tabs v-model="theTab" show-arrows class="ml-2 mr-2 mb-7">
              <v-tab class="text-capitalize" style="font-size: 16px;">{{ $t("Leave") }}</v-tab>
              <v-tab class="text-capitalize" style="font-size: 16px;">{{ $t("LeaveBenefits") }}</v-tab>
              <v-tab-item style="height: 100%;" class="mb-5">
                <div class="mt-5 ml-4 mr-4">
                  <v-row>
                    <v-col>
                      <p>{{ $t("LeaveTypeName") }} <span class="red--text"> *</span></p>
                      <v-text-field v-model="leaveTypeTH" color="#F99D20" outlined dense
                        :error-messages="error.leaveTypeTH">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.leaveTypeTH }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <p>{{ $t("LeaveTypeName") }} (EN) <span class="red--text"> *</span></p>
                      <v-text-field v-model="leaveTypeEN" color="#F99D20" outlined dense
                        :error-messages="error.leaveTypeEN">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.leaveTypeEN }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <p style="font-size: 16px;">{{ $t("Condition") }}</p>
                  <v-row class="mt-5 ml-0 mb-5">
                    <p class="mt-1" style="color:#707070;">{{ $t("countholidaytxt") }}</p>
                    <span><v-badge class="batman mt-1" color="white" :value="hover" grow transition="slide-y-transition">
                        <template v-slot:badge>
                          <div class="my-badge">{{ $t("countholihovertxt") }}</div>
                        </template>
                        <v-hover v-model="hover">
                          <img class="mt-1" style="width:16px; height:17px; margin-left:5px;"
                            src="@/assets/images/information.png" />
                        </v-hover>
                      </v-badge></span>
                    <v-spacer></v-spacer>
                    <div v-if="holiLeave == true">
                      <input type="checkbox" v-model="holiLeave" />
                    </div>
                    <div v-else>
                      <input type="checkbox" style="cursor: default" v-model="holiLeave" />
                    </div>
                  </v-row>
                  <v-row class="mt-5 ml-0 mb-5">
                    <p class="mt-1" style="color:#707070;">{{ $t("AccAnnLeave") }}</p>
                    <span><v-badge class="batman mt-1" color="white" :value="hover1" grow transition="slide-y-transition">
                        <template v-slot:badge>
                          <div class="my-badge">{{ $t("AccAnnLeavehover") }}</div>
                        </template>
                        <v-hover v-model="hover1">
                          <img class="mt-1" style="width:16px; height:17px; margin-left:5px;"
                            src="@/assets/images/information.png" />
                        </v-hover>
                      </v-badge></span>
                    <v-spacer></v-spacer>
                    <div v-if="annuLeave == true">
                      <input type="checkbox" v-model="annuLeave" />
                    </div>
                    <div v-else>
                      <input type="checkbox" style="cursor: default" v-model="annuLeave" />
                    </div>
                  </v-row>
                  <v-row v-show="annuLeave">
                    <v-col>
                      <p>{{ $t("CanAccumulatetxt") }}</p>
                      <v-text-field v-model="accumulateMax" color="#F99D20" outlined dense
                        :error-messages="error.accumulateMax">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.accumulateMax }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <p>{{ $t("ExpiringMonth") }}</p>
                      <v-select :items="monthNames" v-model="expireMonth" item-text="txt" item-value="val" outlined dense
                        :error-messages="error.expireMonth" color="#F99D20">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.expireMonth }}</span>
                        </template></v-select>
                    </v-col>
                  </v-row>

                </div>
              </v-tab-item>
              <v-tab-item style="height: 100%;" class="mb-7">
                <div class="mt-5 ml-4 mr-4 mb-5" style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); border-radius: 5px;">
                  <v-row class="mt-5 pt-3 ml-0 mr-0" style="border-bottom: 1px solid #cfcdcd; height: 60px;">
                    <v-spacer></v-spacer>
                    <p class="mt-1">{{ $t("DeterminebyExp") }}</p>
                    <div class="ml-3" v-if="isItPerWork == true">
                      <input type="checkbox" v-model="isItPerWork" />
                    </div>
                    <div class="ml-3" v-else>
                      <input type="checkbox" style="cursor: default" v-model="isItPerWork" />
                    </div>
                    <v-btn class="mx-2 mr-4 mt-1" icon small v-show="showCard" @click="showCard = !showCard">
                      <v-icon dark>
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                    <v-btn class="mx-2 mr-4 mt-1" icon small v-show="!showCard" @click="showCard = !showCard">
                      <v-icon dark>
                        mdi-chevron-up
                      </v-icon>
                    </v-btn>
                  </v-row>
                  <div v-show="!showCard">
                    <v-row class="ml-4 mr-4" v-show="!isItPerWork">
                      <v-col cols="6">
                        <p>{{ $t("EntitledLeave") }}</p>
                        <v-text-field v-model="entitleday" color="#F99D20" outlined dense v-mask="mask"
                          class="custom-text-field-1"><template v-slot:append>
                            {{ $t("day") }}
                          </template></v-text-field>
                      </v-col>
                    </v-row>
                    <div v-show="isItPerWork" class="mt-4"
                      style="min-height: 140px; max-height: 450px; overflow-y: auto;">
                      <v-row v-for="item in perWorkList" :key="item.value" class="ml-4 mr-4">
                        <v-col cols="12" md="6" lg="3" sm="6">
                          <p>{{ $t("HaveWorked") }}</p>
                          <v-text-field color="#F99D20" outlined dense v-model="item.workFor" v-mask="mask"
                            class="custom-text-field">
                            <template v-slot:append>
                              <v-select :items="MonYerList" v-model="item.workForType" item-text="txt" item-value="val"
                                dense hide-details color="#F99D20" class="monthbox"></v-select>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" sm="6">
                          <p>{{ $t("ButLess") }}</p>
                          <v-text-field color="#F99D20" v-model="item.lessThan" outlined dense v-mask="mask"
                            class="custom-text-field"><template v-slot:append>
                              <v-select :items="MonYerList" v-model="item.lessThanType" item-text="txt" item-value="val"
                                dense hide-details color="#F99D20" class="monthbox"></v-select>
                            </template></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="5" sm="6">
                          <p>{{ $t("EntitledLeave") }}</p>
                          <v-text-field color="#F99D20" outlined dense v-model="item.leaveDays" v-mask="mask"
                            class="custom-text-field-1">
                            <template v-slot:append>
                              {{ $t("day") }}
                            </template></v-text-field>
                        </v-col>
                        <v-col v-if="perWorkList.length != 1" class="pt-4 alce" cols="12" md="6" lg="1" sm="6">
                          <v-btn icon color="white" @click="deletethis(item.value)">
                            <!-- <span class="tooltip text-capitalize">{{ $t("delete") }}</span> -->
                            <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                            <!-- <img v-if="item.hovering" width="30" height="30" :src="images.trashRed" />
                            <img v-else width="30" height="30" :src="images.trashGray" /> -->
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row class="ml-4 mr-4" v-show="isItPerWork">
                      <v-col>
                        <v-btn icon color="white" @click="addAnother">
                          <!-- <span class="tooltip text-capitalize">{{ $t("Add") }}</span> -->
                          <img class="plusIcon" :src="images.plusIcon" alt="plusIcon"/>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                </div>
              </v-tab-item>
            </v-tabs>
            <v-row style="
                border-top: 1px solid #cfcdcd; 
                position: absolute; bottom: 0; 
                right: 0; 
                width: 100%;" class="mt-7 pb-6 ml-2 pl-2 mr-0">
              <v-spacer></v-spacer>
              <v-btn @click="DiscardDialog = true" color="darken-1" class="mt-3 mr-3 text-capitalize fix-btn" width="150"
                max-height="35" outlined text>
                {{ $t("Cancel") }}
              </v-btn>
              <v-btn v-if="timeID == 0" @click="OpenSaveDialog" color="#F99D20" class="mt-3 mr-3 text-capitalize white--text fix-btn"
                width="150" max-height="35" style="font-family: kanit, Regular; font-weight: normal" :disabled="permission.add">
                {{ $t("save") }}
              </v-btn>
              <v-btn v-else @click="OpenSaveDialog" color="#F99D20" class="mt-3 mr-3 text-capitalize white--text fix-btn"
                width="150" max-height="35" style="font-family: kanit, Regular; font-weight: normal" :disabled="permission.edit">
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- Discard Dialog -->
    <v-dialog v-model="DiscardDialog" persistent max-width="400px">
      <v-card style="border-radius: 5px">
        <v-container>

          <div style="font-size: 20px;" class="d-flex justify-center Body 1 mt-2">
            {{ $t("DiscardChanges") }}
          </div>
          <p class="d-flex justify-center Body 1 mt-2">{{ $t("Changeswillnotsavedtxt") }}</p>
          <div class="d-flex justify-center mb-5 mt-5">
            <v-btn class="text-capitalize mr-2" max-height="35" width="120px" @click="DiscardDialog = false" text>{{
              $t("keepEditing") }}</v-btn>
            <v-btn color="#F99D20" @click="JustDiscard" class="mr-2 white--text text-capitalize" max-height="35"
              width="120px">
              {{ $t("yesDiscard") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Save Dialog -->
    <v-dialog v-model="SaveDialog" persistent max-width="400px">
      <v-card style="border-radius: 5px">
        <v-container>
          <!-- <div class="d-flex">
            <v-spacer></v-spacer>
            
          </div> -->
          <p style="font-size: 20px;" class="d-flex justify-center Body 1 mt-2">
            {{ $t("ConfirmSetting") }}
          </p>
          <p class="d-flex justify-center Body 1 mt-2">{{ $t("AllSettingsAppliedtxt") }}</p>
          <div class="d-flex justify-center mb-5 mt-5">
            <v-btn class="text-capitalize mr-2" max-height="35" width="120px" @click="SaveDialog = false" text>{{
              $t("Cancel") }}</v-btn>
            <v-btn color="#F99D20" @click="SaveSetting" class="mr-2 white--text text-capitalize" max-height="35"
              width="120px">
              {{ $t("Confirm") }}</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import LogTrace from "@/function/Log.js";
export default {
  mixins: [CheckViewAddEdit, LogTrace],
  name: "ChangePassword",
  data() {
    return {
      permission: {
        add: true,
        edit: true,
      },
      url: enurl.apiUrl,
      LoadingDialog: false,
      DiscardDialog: false,
      SaveDialog: false,
      // images: {
      //   addpic: require("@/assets/images/plusic.png"),
      //   editpic: require("@/assets/images/reveal icon.png"),
      //   deletepic: require("@/assets/images/Mask Group 177.png"),
      //   alertpic: require("@/assets/images/Iconly-Bold-Info-Circle.png"),
      //   trashGray: require("@/assets/images/trash-gr.png"),
      //   trashRed: require("@/assets/images/trash-rd.png"),
      // },
      leaveTypeList: [],
      perWorkList: [
        {
          value: 1,
          workFor: null,
          workForType: 1,
          lessThan: null,
          lessThanType: 1,
          leaveDays: null,
          hovering: false,
        }
      ],
      hover: false,
      hover1: false,
      holiLeave: false,
      annuLeave: false,
      isItPerWork: false,
      showCard: false,
      leaveStatus: false,
      leaveTypeEN: null,
      leaveTypeTH: null,
      theCU: false,
      theTab: 0,
      accumulateMax: null,
      expireMonth: null,
      selectedItem: null,
      entitleday: null,
      timeID: 0,
      mask: "########",
      thelang: "",
      whatstage: 0,
      error: {
        leaveTypeTH: "",
        leaveTypeEN: "",
        accumulateMax: "",
        expireMonth: ""
      },
    };
  },
  mounted() {
    this.GetLeaveType();
    this.permission = this.CheckViewAddEdit(89, 90, 91);
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  computed: {
    monthNames() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    MonYerList() {
      return [
        { val: 1, txt: this.$t("Month") },
        { val: 2, txt: this.$t("Year") },
      ];
    },
    thethaihead() {
      return this.whatthehead();
    },
    images() {
      return this.$store.state.images;
    }
  },
  watch: {
    "leaveTypeTH"(v) {
      if (v) this.error.leaveTypeTH = "";
    },
    "leaveTypeEN"(v) {
      if (v) this.error.leaveTypeEN = "";
    },
    "accumulateMax"(v) {
      if (v) this.error.accumulateMax = "";
    },
    "expireMonth"(v) {
      if (v) this.error.expireMonth = "";
    },
    thelang() {
      this.whatthehead()
    },
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    GetLeaveType() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOff/GetLeaveTypeByCompanyID`, tempp)
        .then(function (response) {
          self.leaveTypeList = response.data.data;
          self.LoadingDialog = false;
          self.chooseThis(self.leaveTypeList[0]);
          self.LogTrace(null, "Get Time Off Type", 10, "Critical");
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Time Off Type Fail", 10, "Critical");
          self.LoadingDialog = false;
        });
    },
    GonnaAddLType() {
      let self = this;
      self.timeID = 0;
      self.leaveTypeEN = null;
      self.leaveTypeTH = null;
      self.holiLeave = false;
      self.annuLeave = false;
      self.isItPerWork = false;
      self.entitleday = null;
      self.perWorkList = [{
        value: 1,
        workFor: null,
        workForType: 1,
        lessThan: null,
        lessThanType: 1,
        leaveDays: null,
        hovering: false,
      }];
      self.theTab = 0;
      self.theCU = false;
      self.selectedItem = null;
      self.DiscardDialog = false;
      self.whatstage = 1;
    },
    JustDiscard() {
      let self = this;
      if(self.whatstage == 0){
        self.chooseThis(self.selectedItem);
      } else {
        self.GonnaAddLType();
      }
      self.DiscardDialog = false;
    },
    chooseThis(v) {
      let self = this;
      self.whatstage = 0;
      self.timeID = v.timeOff_ID;
      self.leaveTypeEN = v.timeoff_TypeEN;
      self.leaveTypeTH = v.timeoff_TypeTH;
      self.holiLeave = v.countHolidays;
      self.annuLeave = v.accAnnualLeaves;
      self.accumulateMax = v.accumlateMax;
      self.expireMonth = v.expiringMonth;
      self.isItPerWork = v.leaveBenefitsStatus;
      self.entitleday = v.defaultLeaveDays;
      self.leaveStatus = v.leaveStatus;
      self.theTab = 0;
      if (self.isItPerWork == true) {
        self.perWorkList = [];
        for (let i = 0; i < v.leaveBenefitLists.length; i++) {
          self.perWorkList.push({
            value: i,
            hovering: false,
            ...v.leaveBenefitLists[i]
          });
        }
      }
      self.theCU = true;
      self.selectedItem = v;
    },
    thisRow(item) {
      return item === this.selectedItem;
    },
    addAnother() {
      let self = this;
      let vv = self.perWorkList[self.perWorkList.length - 1].value + 1;
      self.perWorkList.push({
        value: vv,
        workFor: null,
        workForType: 1,
        lessThan: null,
        lessThanType: 1,
        leaveDays: null,
        hovering: false,
      });
    },
    deletethis(v) {
      let self = this;
      let removeIndex = self.perWorkList
        .map((item) => item.value)
        .indexOf(v);
      self.perWorkList.splice(removeIndex, 1);
    },
    OpenSaveDialog() {
      let self = this;
      self.error.leaveTypeTH = !self.leaveTypeTH
        ? self.$t("require_field")
        : "";
      self.error.leaveTypeEN = !self.leaveTypeEN
        ? self.$t("require_field")
        : "";
      if (self.annuLeave == true) {
        self.error.accumulateMax = !self.accumulateMax
          ? self.$t("require_field")
          : "";
        self.error.expireMonth = !self.expireMonth
          ? self.$t("require_field")
          : "";
      }
      let vali = self.ValidateThat();
      if (vali == true) {
        self.SaveDialog = true;
      }
    },
    ValidateThat() {
      let self = this;
      if (
        self.leaveTypeTH != null &&
        self.leaveTypeEN != null &&
        self.leaveTypeTH != "" &&
        self.leaveTypeEN != ""
      ) {
        if (self.annuLeave == true) {
          if (
            self.accumulateMax != null &&
            self.accumulateMax != "" &&
            self.expireMonth != null &&
            self.expireMonth != ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    ChangeEnable(v) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        timeOff_ID: self.timeID,
        leaveStatus: !v,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}TimeOff/UpdateLeaveTypeEnable`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.GonnaAddLType();
            self.LoadingDialog = false;
            self.GetLeaveType();
            self.LogTrace(null, "Update Time Off Type", 10, "Medium");
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Time Off Type Fail", 10, "Medium");
          alert(error + "Correlation ID is 10");
          self.LoadingDialog = false;
        });
    },
    SaveSetting() {
      let self = this;
      self.LoadingDialog = true;
      if (self.timeID == 0) {
        if (self.entitleday == null || self.entitleday == "") {
          self.entitleday = 0;
        }
        let thelist = self.generateList();
        let temp = {
          timeoff_TypeEN: self.leaveTypeEN,
          timeoff_TypeTH: self.leaveTypeTH,
          company_ID: store.state.companyID,
          vacation_Status: false,
          leaveStatus: true,
          countHolidays: self.holiLeave,
          accAnnualLeaves: self.annuLeave,
          accumlateMax: self.accumulateMax,
          expiringMonth: self.expireMonth,
          leaveBenefitsStatus: self.isItPerWork,
          defaultLeaveDays: parseInt(self.entitleday),
          user_ID: store.state.userid,
          leaveBenefitLists: thelist,
        };
        axios
          .post(`${self.url}TimeOff/AddLeaveType`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.GonnaAddLType();
              self.LoadingDialog = false;
              self.SaveDialog = false;
              self.GetLeaveType();
              self.LogTrace(null, "Add Time Off Type", 10, "Low");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Time Off Type Fail", 10, "Low");
            alert(error + "Correlation ID is 10");
            self.LoadingDialog = false;
          });
      } else {
        let thelist = self.generateList();
        let temp = {
          timeOff_ID: self.timeID,
          timeoff_TypeEN: self.leaveTypeEN,
          timeoff_TypeTH: self.leaveTypeTH,
          company_ID: store.state.companyID,
          vacation_Status: false,
          leaveStatus: self.leaveStatus,
          countHolidays: self.holiLeave,
          accAnnualLeaves: self.annuLeave,
          accumlateMax: self.accumulateMax,
          expiringMonth: self.expireMonth,
          leaveBenefitsStatus: self.isItPerWork,
          defaultLeaveDays: parseInt(self.entitleday),
          user_ID: store.state.userid,
          leaveBenefitLists: thelist,
        };
        axios
          .post(`${self.url}TimeOff/UpdateLeaveType`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.GonnaAddLType();
              self.LoadingDialog = false;
              self.SaveDialog = false;
              self.GetLeaveType();
              self.LogTrace(null, "Update Time Off Type", 10, "Medium");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Time Off Type Fail", 10, "Medium");
            alert(error + "Correlation ID is 10");
            self.LoadingDialog = false;
          });
      }
    },
    generateList() {
      let self = this;
      let theArr = [];
      if (self.isItPerWork == true) {
        for (let i = 0; i < self.perWorkList.length; i++) {
          let wfnum = 0;
          if (self.perWorkList[i].workFor == null || self.perWorkList[i].workFor == "") {
            wfnum = 0;
          } else {
            wfnum = self.perWorkList[i].workFor
          }
          let ltnum = 0;
          if (self.perWorkList[i].lessThan == null || self.perWorkList[i].lessThan == "") {
            ltnum = 0;
          } else {
            ltnum = self.perWorkList[i].lessThan
          }
          let ldnum = 0;
          if (self.perWorkList[i].leaveDays == null || self.perWorkList[i].leaveDays == "") {
            ldnum = 0;
          } else {
            ldnum = self.perWorkList[i].leaveDays
          }
          theArr.push({
            timeOff_ID: self.timeID,
            workFor: parseInt(wfnum),
            workForType: self.perWorkList[i].workForType,
            lessThan: parseInt(ltnum),
            lessThanType: self.perWorkList[i].lessThanType,
            leaveDays: parseInt(ldnum)
          });
        }
        return theArr;
      } else {
        return theArr;
      }
    }
  },
};
</script>
    
<style scoped>
>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 15px;
  color: #f99d20;
}

>>>.v-tabs .v-tab.v-tab--active {
  background-color: white !important;
  color: #F99D20;
}

>>>.v-tabs-slider {
  background-color: #F99D20;
  height: 100%;
  width: 100%;
}

.my-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 34px;
  width: 300px;
  color: #FFFF;
  background: #707070;
  white-space: pre-wrap;
  border-radius: 8px;
}

@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(circle 12px,
        white 100%,
        transparent calc(100% + 1px)) #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: transparent;
    /* Hide the text by making it transparent */
  }

  input::before {
    content: "ON";
    font: 12px/32px Kanit;
    /* color: white; */
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
    cursor: pointer;
  }

  input:checked::before {
    content: "OFF";
  }
}

.tooltip-button {
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #707070;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-effect {
  position: relative;
}

.hover-effect:hover .tooltip {
  opacity: 1;
}

.tooltip-button:hover .tooltip {
  opacity: 1;
}

.ex1 span {
  display: block;
  padding: 5px 10px 5px 25px;

  position: relative;
  transition: all 0.25s linear;

  background-color: white;
}

.ex1 span:before {
  content: "";

  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #dcd4d3;
  transition: all 0.25s linear;
}

.ex1 input:checked+span {
  background-color: white;
}

.ex1 .orange input:checked+span {
  color: orange;
  border-color: orange;
  background-color: white;
}

.ex1 .orange input:checked+span:before {
  background-color: orange;
}

.alce {
  display: flex;
  align-items: center;
}

.monthbox {
  /* border-left: 1px solid black; */
  max-width: 100px;
  margin-bottom: 5px;
}

>>>.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: transparent;
  border-style: none;
  border-width: thin 0 0 0;
}

>>>.v-text-field>.v-input__control>.v-input__slot:after {
  border-color: transparent;
  border-style: none;
  border-width: thin 0 0 0;
}

>>>.custom-text-field>.v-input__control>.v-input__slot>.v-input__append-inner {
  border-left: 1px solid #707070;
  margin-top: 0px;
}

>>>.custom-text-field-1>.v-input__control>.v-input__slot>.v-input__append-inner {
  padding-top: 4px;
}

>>>.custom-text-field-1 input {
  /* direction: rtl; Set text direction to right-to-left */
  text-align: right;
  /* Align text to the right */
}

.v-btn-toggle {
  width: 200px;
  height: 40px;
}

label {
  font-size: 15px;
}

.sc {
  padding: 8px;
  margin: 8px;
  font-size: 14px;
}

@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>