<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <v-row style="padding-top: 2rem; margin-top: 0rem !important">
      <v-col cols="12" xs="12" sm="6" md="5" lg="4">
        <v-text-field
          autocomplete="off"
          v-model="searchtxt"
          outlined
          dense
          color="#F99D20"
          style="margin-top: -1rem; color: black; width: 20rem"
          :placeholder="$t('Searchfeedbackno')"
        ></v-text-field>
      </v-col>
      <v-btn
        @click="Search"
        class="text-capitalize ml-5 commonsavebtn"
      >
        <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
      </v-btn>
    </v-row>
    <v-data-table
      class="mytable"
      :headers="headers"
      :items="FeedbackList"
      :page.sync="page"
      hide-default-header
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      :mobile-breakpoint="0"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th class="head b-text" style="color: #f99d20">
              {{ headers[0].text }}
            </th>
            <th class="head b-text" style="color: #f99d20">
              {{ headers[1].text }}
            </th>
            <th class="head b-text" style="color: #f99d20">
              {{ headers[2].text }}
            </th>
            <th class="action b-text" style="color: #f99d20">
              {{ $t("action") }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.feedback_No }}</td>
          <td>{{ item.feedback_Subject }}</td>
          <td class="text-truncate" style="max-width: 130px">
            {{ item.feedback_Message }}
          </td>
          <td class="pl-1">
            <v-btn
              icon
              class="mr-5"
              @click="DetailsFeedback(item)"
              :disabled="permission.add"
            >
              <img class="editIcon" :src="images.edit" alt="editIcon"/>
            </v-btn>
            <v-btn
              icon
              @click="Deletedia(item.feedback_ID)"
              :disabled="permission.delete"
            >
              <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-right">
      <v-row justify="end" class="ml-5 mt-5 mr-5">
        <v-col cols="12" md="6">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="#F09920"
            :total-visible="6"
          ></v-pagination>
        </v-col>
        <v-col cols="12" md="2" class="mt-1">
          <v-select
            dense
            style="width: 120px; float: right"
            solo
            label="10/page"
            v-model="itemsPerPage"
            :items="items"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <!-- Loading -->
    <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loading Chat -->
    <v-dialog v-model="chatloadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-progress-linear
          indeterminate
          color="#F99D20"
          class="mb-0"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
    <!-- DetailDialog -->
    <v-dialog v-model="DetailDialog" persistent max-width="700">
      <v-card style="border-radius: 15px">
        <v-row class="ml-5 mr-5">
          <p style="font-size: 23px" class="mt-5">
            {{ $t("Helpandfeedbackdetail") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn @click="Close" icon class="mt-5">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-row>
        <v-card-text>
          <v-container style="margin-top: -1.3rem" class="container">
            <v-row>
              <v-col class="mydiv" cols="3">
                <div style="margin-left: 0.5rem">
                  <p class="myfont mt-2">{{ $t("FeedbackNo") }}</p>
                </div>
              </v-col>
              <v-col class="mydiv" cols="8">
                <v-text-field
                  color="#F99D20"
                  readonly
                  dense
                  v-model="details.feedbackno"
                  autocomplete="off"
                  class="ml-1 ml-md-0"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -1.7rem">
              <v-col class="mydiv" cols="3">
                <div style="margin-left: 0.5rem">
                  <p class="myfont mt-2">{{ $t("Subject") }}</p>
                </div>
              </v-col>
              <v-col class="mydiv" cols="8">
                <v-text-field
                  color="#F99D20"
                  readonly
                  v-model="details.subject"
                  autocomplete="off"
                  dense
                  class="ml-1 ml-md-0"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -1.3rem">
              <v-col class="mydiv" cols="12">
                <div class="chatroom">
                  <div
                    style="
                      height: 40vh;
                      overflow: auto;
                      background-color: #f5ede1;
                      border-top-left-radius: 14px;
                      border-top-right-radius: 14px;
                    "
                  >
                    <ul v-for="(item, i) in messages" :key="i">
                      <li :style="item.company_ID != 0 ? Mee : Otherr">
                        <v-card-text
                          style="font-size: 13px; font-weight: 100"
                          v-text="
                            item.company_ID != 0 ? item.employee_Name : 'Admin'
                          "
                          :style="item.company_ID != 0 ? MyName : OtherName"
                        ></v-card-text>
                        <v-card
                          :style="item.company_ID != 0 ? Me : Other"
                          :class="
                            item.company_ID != 0
                              ? 'bubble mee'
                              : 'bubble otherr'
                          "
                        >
                          <div class="justify-space-between">
                            <v-card-title
                              v-html="item.chatMessage.replace(/\n/g, '<br/>')"
                              style="font-size: 14px; font-weight: 300"
                            ></v-card-title>
                            <v-card-text
                              v-text="
                                convertUTCDateToLocalDate(item.createDate)
                              "
                              style="font-size: 11px; margin-top: -0.5rem"
                            ></v-card-text>
                          </div>
                        </v-card>
                      </li>
                    </ul>
                  </div>
                  <v-textarea
                    class="flex-shrink-1 ml-1 mr-1 send"
                    dense
                    auto-grow
                    :placeholder="$t('Typemessage')"
                    type="text"
                    v-model="userMessage"
                    rows="1"
                    style="font-size: 13px; max-height: 50px; overflow: auto"
                  >
                    <v-btn slot="append" icon @click="SendChatMessage">
                      <img :src="images.send"/>
                    </v-btn>
                  </v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog
      v-model="DeleteDialog"
      persistent
      max-width="532"
    >
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px;">
            {{ $t("Deletehelpandfeedback") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col align="center" cols="12">
              <p style="font-size: 17px; color: #444444">
                {{ $t("ConfirmDelete") }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              class="mr-2 text-capitalize cancel commoncancelbtn"
              outlined
              @click="DeleteDialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              class="mr-2 commondeletebtn text-capitalize delete"
              @click="DeleteFeedback"
              >{{ $t("delete") }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddDelete from "@/function/RolePermissions.js";
import store from "@/store";
export default {
  mixins: [LogTrace, utils, CheckViewAddDelete],
  data() {
    return {
      permission: {
        add: true,
        delete: true,
      },
      tokenList: [],
      chatloadingdialog: false,
      Mee: {
        float: "right",
      },
      Otherr: {
        float: "left",
      },
      Me: {
        color: "#444444",
        float: "right",
        background: "#fff",
      },
      Other: {
        color: "#444444",
        background: " #fff",
        float: "left",
      },
      OtherName: {
        color: "#FAA91B",
      },
      MyName: {
        color: "#0FA7D8",
      },
      userName: store.state.username,
      userId: store.state.userid,
      userMessage: "",
      connection: "",
      connectionID: "",
      messages: [],
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30, 40],
      feedback_ID: null,
      details: {
        feedback_ID: null,
        feedbackno: null,
        subject: null,
        messages: null,
        employeename: null,
        employee_ID: null,
        createDate: null,
      },
      url: enurl.apiUrl,
      FeedbackList: [],
      loadingdialog: false,
      DetailDialog: false,
      DeleteDialog: false,
      searchtxt: "",
      tempChat: [],
      thesearchnum: 0,
    };
  },

  watch: {
    $route() {
      this.GetFeedbackDetailsByWID();
    },
    searchtxt: function () {
      if(this.thesearchnum == 1){
        this.GetFeedbackList();
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("FeedbackNo"),
          value: "feedback_No",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("Subject"),
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("Messageforfeedback"),
          align: "left",
          sortable: true,
        },
        {
          text: "Subject",
          align: "left",
          sortable: true,
          filterable: true,
        },
        {
          text: "Active",
          value: "actions",
          sortable: true,
          align: "left",
        },
      ];
    },
    images() {
      return this.$store.state.images;
    }
  },

  mounted() {
    this.GetToken();
    this.GetFeedbackList();
    this.sendNotification();
    this.GetFeedbackDetailsByWID();
    this.permission = this.CheckViewAddDelete(120, 121, 122);
  },
  methods: {
    Search() {
      let self = this;
      self.thesearchnum = 1;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.FeedbackList;
        self.FeedbackList = [];
        let temp = List.filter((v) =>
          v.feedback_No.toLowerCase().includes(self.searchtxt.toLowerCase())
        );

        for (let i = 0; i < temp.length; i++) {
          self.FeedbackList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetFeedbackList();
      }
    },
    async GetFeedbackDetailsByWID() {
      try {
        let self = this;
        let notiSring = self.$route.params.notiString;
        if (notiSring) {
          let temp = {
            wF_ID: parseInt(notiSring),
          };
          const response = await axios.post(
            `${self.url}HelpFeedback/GetFeedbackByChatFeedID`,
            temp
          );

          let tempdetail = response.data.data[0];
          self.DetailDialog = true;
          self.GetChatMessages(tempdetail.feedback_ID);
          self.details.feedback_ID = tempdetail.feedback_ID;
          self.details.feedbackno = tempdetail.feedback_No;
          self.details.subject = tempdetail.feedback_Subject;
          self.details.messages = tempdetail.feedback_Message;
          self.details.createDate = tempdetail.createDate;
          self.details.employee_ID = tempdetail.employee_ID;
          self.details.employeename = tempdetail.employee_Name;
        }
      } catch (error) {
        alert(error);
      }
    },
    GetToken() {
      let self = this;
      let temps = {
        user_ID: 0,
      };
      axios
        .post(`${self.url}Firebasetoken/GetFirebaseTokenByUserID`, temps)
        .then(function (response) {
          if (response.data.data.length != 0) {
            let temp = response.data.data.map((x) => x.fireBase_Token);
            self.tokenList = temp;
            //console.log(self.tokenList)
          }
        });
    },
    Close() {
      let self = this;
      self.userMessage = "";
      self.messages = [];
      self.DetailDialog = false;
      self.$router.push({ path: `/HelpFeedback/NewFeedback/SentFeedback` }).catch(()=>{});
    },
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      var hour =
        newDate.getHours() == 0
          ? 12
          : newDate.getHours() > 12
          ? newDate.getHours() - 12
          : newDate.getHours();
      var min =
        newDate.getMinutes() < 10
          ? "0" + newDate.getMinutes()
          : newDate.getMinutes();
      var ampm = newDate.getHours() < 12 ? "AM" : "PM";
      output = hour + ":" + min + " " + ampm;
      return output;
    },
    GetFeedbackList() {
      try {
        let self = this;
        self.loadingdialog = true;
        let temp = {
          employee_ID: store.state.employeeID,
        };
        axios
          .post(`${self.url}HelpFeedback/GetFeedbacksByEmp`, temp)
          .then(function (response) {
            self.FeedbackList = response.data.data;
            self.thesearchnum = 0;
            self.LogTrace(null, "Get Feedback", 50, "Critical");
            self.loadingdialog = false;
          });
      } catch (error) {
        self.LogTrace(error, "Get Feedback Fail", 50, "Critical");
        self.loadingdialog = false;
      }
    },
    DetailsFeedback(temp) {
      let self = this;
      self.DetailDialog = true;
      self.GetChatMessages(temp.feedback_ID);
      self.details.feedback_ID = temp.feedback_ID;
      self.details.feedbackno = temp.feedback_No;
      self.details.subject = temp.feedback_Subject;
      self.details.messages = temp.feedback_Message;
      self.details.createDate = temp.createDate;
      self.details.employee_ID = temp.employee_ID;
      self.details.employeename = temp.employee_Name;
    },
    Deletedia(id) {
      let self = this;
      self.feedback_ID = id;
      self.GetChatMessagesForDelete(self.feedback_ID);
      self.DeleteDialog = true;
    },
    DeleteFeedback() {
      let self = this;
      self.loadingdialog = true;
      let temp = {
        feedback_ID: self.feedback_ID,
      };
      axios
        .post(`${self.url}HelpFeedback/DeleteFeedback`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.LogTrace(null, "Delete Feedback", 50, "High");
            self.DeleteDialog = false;
            self.loadingdialog = false;
            self.GetFeedbackList();
          }
        })
        .catch(function (error) {
          alert(error);
          self.LogTrace(error, "Delete Feedback Fail", 50, "High");
        });
      if (self.tempChat.length != 0) {
        axios
          .post(`${self.url}HelpFeedback/DeleteFeedbackChatMessages`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LogTrace(null, "Delete Feedback", 50, "High");
              self.GetFeedbackList();
            }
          })
          .catch(function (error) {
            alert(error);
            self.LogTrace(error, "Delete Feedback Fail", 50, "High");
          });
      }
    },
    GetChatMessagesForDelete(id) {
      try {
        let self = this;
        let temp = {
          feedback_ID: id,
        };
        axios
          .post(`${self.url}FeedbackChatMessage/GetFeedbackMessages`, temp)
          .then(function (response) {
            self.tempChat = response.data.data;
          });
      } catch (error) {
       alert(error);
      }
    },
    GetChatMessages(id) {
      try {
        let self = this;
        let temp = {
          feedback_ID: id,
        };
        axios
          .post(`${self.url}FeedbackChatMessage/GetFeedbackMessages`, temp)
          .then(function (response) {
            let tempmessages = response.data.data;
            let temp = [];
            temp.push({
              employee_ID: self.details.employee_ID,
              admin_ID: 0,
              chatMessage: self.details.messages,
              feedback_ID: id,
              company_ID: store.state.companyID,
              employee_Name: self.details.employeename,
              user_Name: self.userName,
              createDate: self.details.createDate,
            });
            //console.log(temp);
            self.messages = [...temp, ...tempmessages];
          });
      } catch (error) {
        alert(error);
      }
    },
    async sendNotification() {
      let self = this;
      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${this.url}PersonalInfo/GetShortInfo`, userInfoTemp)
        .then(function (response) {
          var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
          self.username = username;
        });
    },
    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };

      await axios
        .post(`${self.url}HelpFeedback/GetWorkflowByFeedback`, temp)
        .then(function (response) {
          if (response) {
            let WF_ID = response.data.data.wF_ID;
            let senderID = response.data.data.requester_ID;
            let recipientID = response.data.data.approver_ID;
            self.newnotiList(WF_ID, senderID, recipientID);
          }
        });
    },
    async newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `has replied to a feedback`,
      };

      await axios
        .post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.pushNotification(recipientID);
          }
        });
    },
    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Feedback",
        body: self.username + "has replied to a feedback",
        reportID: recipientID,
      };

      await axios
        .post(`${this.url}Firebasetoken/sendNotification`, noti_temp)
        .then(function () {
          //console.log(response.data);
        });
    },
    SendChatMessage() {
      let self = this;
      self.chatloadingdialog = true;
      if (self.userMessage != null && self.userMessage != "") {
        let temp = {
          sender_ID: store.state.employeeID,
          receiver_ID: 0,
          chatMessage: self.userMessage,
          feedback_ID: self.details.feedback_ID,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}FeedbackChatMessage/SendFeedbackMessages`, temp)
          .then(async function (response) {
            if (response.data.status == 0) {
              self.LogTrace(null, "Add Feedback", 50, "Low");
              await self.getLatestData();
            }
            self.chatloadingdialog = false;
            self.GetChatMessages(self.details.feedback_ID);
            self.userMessage = "";
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Feedback Fail", 50, "Low");
            alert(error);
            self.chatloadingdialog = false;
          });
      } else {
        alert("Type Message");
        self.chatloadingdialog = false;
      }
    },
  },
};
</script>
<style>
@media (min-width: 280px) and (max-width: 321px) {
  .delete {
    margin-left: -1rem !important;
    width: 90px !important;
  }
  .cancel {
    margin-left: -1rem !important;
  }
}
.send .v-input__slot::after {
  border-color: white !important;
}
.send .v-input__slot::before {
  border-color: white !important;
}
</style>
<style scoped>
v-text-field__details {
  height: 0% !important;
}
>>> .v-textarea::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}

>>> .v-textarea::-webkit-scrollbar-thumb {
  background: white;
  outline: 1px solid white;
  /* You can add other properties & values */
}

/* >>> .v-dialog::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}
>>> .v-dialog::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
} */
.bubble {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

.otherr::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 8px solid #fff;
  border-top: 5px solid #fff;
  border-bottom: 20px solid transparent;
  left: -12px;
}

.mee::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 8px solid #fff;
  border-right: 10px solid transparent;
  border-top: 5px solid #fff;
  border-bottom: 20px solid transparent;
  right: -12px;
}

.head {
  color: #f99d20;
  cursor: pointer;
  font-weight: 400;
  min-width: 180px !important;
  font-size: 16px !important;
}
.action {
  color: #f99d20;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px !important;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block !important;
  clear: both;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: -20px;
}

.v-card__subtitle,
.v-card__title,
.v-card__text {
  line-height: 1.2rem !important;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 7px !important;
  padding-bottom: 4px !important;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.container {
  width: 100%;
  padding-top: 12px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: -1rem !important;
}
.chatroom {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 2px solid #f99d201a;
  border-radius: 15px;
}
</style>