<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("bank") }}
        </p>
    </div>

    <v-card class="pt-4" style="margin-bottom: 2.5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            :placeholder="$t('Search')"
            v-model.lazy="searchtxt"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;"
            @keyup.enter="Search()"></v-text-field>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <text-field autocomplete="off" v-model.lazy="searchtxt" :placeholder="$t('Search')" color="#F99D20" dense />
        </v-col>
        <v-btn color="#F99D20" @click="Search" class="white--text text-capitalize ml-5 mt-2" width="120" max-height="35">
          <v-icon class="pr-1">mdi-magnify</v-icon>
          {{ $t("Search") }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn color="#F99D20" class="white--text mt-2 mr-5 text-capitalize add_btn" width="150" max-height="35"
          @click="Adddialog = true" :disabled="permission.add">{{ $t("Add") }}</v-btn>
      </v-row>
      <v-data-table :headers="headers" :items="bank" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer
        hide-default-header @page-count="pageCount = $event" class="ml-8 mr-8 mt-4" :mobile-breakpoint="0">
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="b-text">{{ $t("bank_icon") }}</th>
              <th style="cursor: pointer; max-width: 180px !important" class="b-text" @click="sortFun('bank_Name')">
                {{ $t("BankName") }}
                <v-icon small v-if="!bank_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="bank_Name">mdi-arrow-up</v-icon>
              </th>
              <th class="b-text" @click="sortFun('bank_NameEng')">
                {{ $t("bankname_eng") }}
                <v-icon small v-if="!bank_NameEng">mdi-arrow-down</v-icon>
                <v-icon small v-if="bank_NameEng">mdi-arrow-up</v-icon>
              </th>
              <th class="b-text">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td style="border-bottom: 1px solid #fff">
              <v-img :src="item.bankIcon" max-width="42" max-height="42" class="rounded-circle" />
            </td>
            <td style="border-bottom: 1px solid #fff">{{ item.bank_Name }}</td>
            <td style="border-bottom: 1px solid #fff">{{ item.bankName_EN }}</td>
            <td style="border-bottom:1px solid #fff">
              <v-btn icon @click="GetBankDetail(item.bank_ID)" :disabled="permission.edit">
                <img class="editIcon" :src="images.edit" />
              </v-btn>
              <v-btn icon @click="Deletedia(item.bank_ID)" :disabled="permission.delete">
                <img class="deleteIcon" :src="images.delete" />
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="bank.length">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage" :items="items"
              @input="itemsPerPage = parseInt($event, 10)"></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    
    <!-- add bank dialog -->
    <v-dialog v-model="Adddialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">{{ $t("AddBank") }}</p>
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <v-col cols="12" align="center" justify="center" style="display:contents;">
                <v-row align="center" justify="center">
                  <input type="file" accept="image/png, image/jpeg, image/bmp" @change="uploadIcon" ref="inputLogo"
                    style="display: none" id="sky" />
                  <v-badge avatar tile bottom overlap offset-x="25" offset-y="25" color="transparent">
                    <template v-slot:badge>
                     
                        <v-img src="@/assets/edit-final.png" @click="$refs.inputLogo.click()" max-width="26" max-height="26" />
                     
                    </template>
                    <v-avatar size="100">
                      <v-img :src="bankIcon" rounded max-width="100" max-height="100" />
                    </v-avatar>
                  </v-badge>

                </v-row>

                <v-text-field autocomplete="off" color="#F99D20" v-model="add.bankname" :rules="bankname">
                  <template v-slot:label>
                    <span>
                      {{ $t("bankname")
                      }}
                      <span class="red--text">*</span>
                    </span>
                  </template>
                </v-text-field>
                <span v-if="isNull==true" align="left" width="15">
                  <v-img style="position:relative; margin-top:-14px"
                   src="@/assets/images/Icon awesome-exclamation-circle.png"
                    width="13" 
                    height="13">
                  </v-img>
                </span>
                <v-text-field align="center" justify="center" autocomplete="off" color="#F99D20" v-model="add.banknameEng" :rules="banknameEng">                 
                  <template v-slot:label>
                    <span>
                      {{ $t("bankname_eng") }}
                      <span class="red--text">*</span>
                    </span>
                  </template>
                </v-text-field>
                <span v-if="isNullEng==true" align="left" width="15">
                  <v-img style="position:relative; margin-top:-14px" 
                  src="@/assets/images/Icon awesome-exclamation-circle.png" 
                  width="13"
                  height="13">
                  </v-img>
                </span>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn"  text @click="CancelAdd()">{{
            $t("Cancel") }}</v-btn>
          <v-btn @click="AddBank" class="mr-2 text-capitalize commonsavebtn">{{ $t("Add")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- crop dialog --->
    <v-dialog v-model="cropDialog" persistent width="550">
      <v-card style="border-radius: 15px" class="overflow-x-hidden overflow-y-hidden">
        <!--<p class="pt-5 ml-5 mr-2" style="font-size: 23px">Edit Bank Logo</p>-->
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <div class="justify-content:center pa-12">
                <v-row>
                  <v-col cols="12 pb-0" class="d-flex align-center justify-center cropper-container">
                    <cropper v-if="!cropped" class="cropper" ref="cropper" :src="cropperSrc" :stencil-props="{
                      aspectRatio: 1/1,
                      movable: true,
                      resizable: true,
                    }" />
                  </v-col>



                  <v-col cols="12 pb-0" class="d-flex align-center justify-center cropper-container">
                    <v-img v-if="cropped == true" :src="this.default.bankIcon" height="350px" width="256"
                      aspect-ratio="1.4" style="
                      height: 230px;
                  max-width: 230px;
                  border: 5px solid #f89d1f;
                  background: #ffffff;
                  color: #707070;
                  border-radius: 50%;
                  font-size: 25px;
                  margin-left: auto;
                  margin-right: auto;
                "></v-img>
                  </v-col>
                </v-row>
              </div>

            </v-form>
          </v-container>

          <v-row justify="center" align="center">
            <div style="justify-content: center">
              <v-btn class="mr-3 text-capitalize black--text fix-btn" width="150" max-height="35" style="
                    font-family: 'Kanit';
                    font-weight: normal;
                    color: black;
                  " @click="$refs.inputLogo.click()">
                {{ $t("Choosefile") }}
              </v-btn>
              <v-btn text @click="deleteDialog()">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </v-row>
          <hr color="#F99D20" class="mt-5" />
        </v-card-text>


        <v-card-actions v-if="!cropped" class="mt-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="CancelCrop()">{{
            $t("Cancel") }}</v-btn>
          <v-btn @click="Oncrop()" class="mr-2 text-capitalize commonsavebtn">{{ $t("Save")
          }}</v-btn>
        </v-card-actions>

        <v-card-actions v-if="cropped == true" class="mt-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="CancelCrop()">{{
            $t("Cancel") }}</v-btn>
          <v-btn @click="savedefault()" class="mr-2 text-capitalize commonsavebtn" >{{
            $t("Save") }}</v-btn>
        </v-card-actions>


        <!-- <v-card-actions v-if="cropped==true" class="mt-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-2 text-capitalize"
            width="90"
            max-height="35"
            text
            @click="CancelSave()"
          >{{ $t("Cancel") }}</v-btn>
          <v-btn
            @click="SaveCrop()"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="90"
            max-height="35"
          >{{ $t("Save") }}</v-btn>
        </v-card-actions> -->

      </v-card>
    </v-dialog>
    
    <!-- edit cropdialog -->
    <v-dialog v-model="cropEditDialog" persistent width="550">
      <v-card style="border-radius: 15px" class="overflow-x-hidden overflow-y-hidden">
        <!--<p class="pt-5 ml-5 mr-2" style="font-size: 23px">Edit Bank Logo</p>-->
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <div class="justify-content:center pa-12">
                <v-row>
                  <v-col cols="12 pb-0" class="d-flex align-center justify-center cropper-container">
                    <cropper v-if="!cropped" class="cropper1" ref="cropper1" :src="edit.base64img" :stencil-props="{
                      aspectRatio: 1 / 1,
                      movable: true,
                      resizable: true,
                    }" />
                    <v-img v-if="cropped == true" :src="this.default.bankIcon" height="350px" width="256"
                      aspect-ratio="1.4" style="
                      height: 230px;
                  max-width: 230px;
                  border: 5px solid #f89d1f;
                  background: #ffffff;
                  color: #707070;
                  border-radius: 50%;
                  font-size: 25px;
                  margin-left: auto;
                  margin-right: auto;
                "></v-img>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
          <v-row justify="center" align="center">
            <div style="justify-content: center">
              <v-btn class="mr-3 text-capitalize black--text fix-btn" width="150" max-height="35" style="
                    font-family: 'Kanit';
                    font-weight: normal;
                    color: black;
                  " @click="$refs.editLogo.click()">
                {{ $t("Choosefile") }}
              </v-btn>
              <v-btn text @click="deleteDialog()">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </v-row>
          <hr color="#F99D20" class="mt-5" />
        </v-card-text>

        <v-card-actions class="pb-5" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="closeEditcrop()">{{
            $t("Cancel") }}</v-btn>
          <v-btn @click="OnEdit()"  class="mr-2 text-capitalize commonsavebtn">{{ $t("Save")
          }}</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>
   
    <!-- delete crop image dialog -->
    <v-dialog v-model="delDialog" persistent max-width="400">
      <v-card style="border-radius: 15px" class="pa-5">
        <v-card-title class="pb-0">
          <v-spacer></v-spacer>
          <img width="45px" height="45px" style="margin-top:auto;margin:bottom:auto;"
            src="@/assets/images/trashcan.png" />
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12" class="pt-4">
                {{ $t("deleteconfirmation") }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <hr color="#F99D20" />
        <v-card-actions v-if="!editok" class="pb-6">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn" text @click="delDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="text-capitalize commondeletebtn" @click="DelCropPic()">{{
            $t("DeletePhoto") }}</v-btn>
        </v-card-actions>
        <v-card-actions v-if="editok == true" class="pb-6">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text @click="delDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="text-capitalize commondeletebtn" max-height="35" @click="deleteEditCrop()">{{
            $t("DeletePhoto") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit bank dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">{{ $t("EditBank") }}</p>
        <v-card-text class="mt-3">
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formEdit" lazy-validation>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <input type="file" accept="image/png, image/jpeg, image/bmp" @change="editIcon" ref="editLogo"
                    style="display: none" id="earth" />
                  <v-badge v-if="this.edit.bankIcon == this.default.bankIcon" avatar tile bottom overlap offset-x="25"
                    offset-y="25" color="tansparent">
                    <template v-slot:badge>
                      
                        <v-img src="@/assets/edit-final.png" @click="$refs.editLogo.click()" max-width="26" max-height="26" />
                      
                    </template>
                    <v-avatar size="100">
                      <v-img :src="edit.bankIcon" />
                    </v-avatar>
                  </v-badge>
                  <v-badge v-if="this.edit.bankIcon != this.default.bankIcon" avatar bottom tile overlap offset-x="25"
                    offset-y="25" color="transparent">
                    <template v-slot:badge>
                     
                        <v-img src="@/assets/edit-final.png" @click="openeditcrop()" max-width="26" max-height="26"/>
                     
                    </template>
                    <v-avatar size="100">
                      <v-img :src="edit.bankIcon" />
                    </v-avatar>
                  </v-badge>
                </v-row>
                <v-text-field autocomplete="off" color="#F99D20" v-model="edit.bankname" :rules="banknameEdit">
                  <template v-slot:label>
                    <span>
                      {{ $t("bankname")
                      }}
                      <span class="red--text">*</span>
                    </span>
                  </template>
                </v-text-field>
                <span v-if="isNull==true" align="left" width="15">
                  <v-img style="position:relative; margin-top:-14px" 
                  src="@/assets/images/Icon awesome-exclamation-circle.png" 
                  width="13"
                  height="13">
                  </v-img>
                </span>
                <v-text-field autocomplete="off" color="#F99D20" v-model="edit.banknameEng" :rules="banknameEngEdit">
                  <template v-slot:label>
                    <span>
                      {{ $t("bankname_eng")
                      }}
                      <span class="red--text">*</span>
                    </span>
                  </template>
                </v-text-field>
                <span v-if="isNullEng==true" align="left" width="15">
                  <v-img style="position:relative; margin-top:-14px" 
                  src="@/assets/images/Icon awesome-exclamation-circle.png" 
                  width="13"
                  height="13">
                  </v-img>
                </span>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="CancelUpdate()">
            {{
              $t("cancel") }}
          </v-btn>
          <v-btn @click="UpdateBank" class="mr-2 text-capitalize commonsavebtn">
            {{
              $t("Update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete bank dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">{{ $t("deleteitem") }}</p>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">{{ $t("DeleteBank") }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" @click="Deletedialog = false" text>
            {{
              $t("cancel") }}
          </v-btn>
          <v-btn class="mr-2 commondeletebtn text-capitalize"
            @click="DeleteBank">{{ $t("delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload Dialog 
    <v-dialog v-model="uploaddialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col style="margin-left: 3px" align="center" cols="5">
              <v-file-input
                truncate-length="100"
                v-show="chosenfile"
                color="white"
                class="myfile"
                id="myFileInput"
                v-model="chosenfile"
                prepend-icon
                accept=".xlsx"
              >
                <template v-slot:append>
                  <v-btn @click="closeinput" icon v-show="chosenfile">
                    <img
                      width="20"
                      height="20"
                      src="@/assets/closered.png"
                      alt
                    />
                  </v-btn>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-btn
                color="#ACACAC"
                class="white--text text-capitalize"
                width="150"
                max-height="35"
                @click="getupload"
              >
                {{ $t("browse") }}</v-btn
              >
            </v-col>
            <v-col cols="12" md="9">
              <p class="mt-2" style="color: #f74747">
                **{{ $t("excelFileFormat") }}
              </p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
          <v-row>
            <v-col cols="12" md="4">
              <p>Bank Template.xlsx</p>
            </v-col>
            <v-col cols="12" md="8">
              <a :href="CheckLang()" style="color: #f99d20">
                {{ $t("download") }}</a
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
          <br />
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="90"
            height="35"
            text
            @click="closeUpload"
          >
            {{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text mr-2 text-capitalize"
            width="90"
            height="35"
            @click="PreviewFiles"
          >
            {{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    -->
    
    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
//import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    "add.bankname"() {
      this.bankname = [];
      this.isNull=false;
    },
    "add.banknameEng"() {
      this.banknameEng = [];
      this.isNullEng=false;
    },
    "edit.bankname"() {
      this.banknameEdit = [];
      this.isNull=false;
    },
    "edit.banknameEng"() {
      this.banknameEngEdit = [];
      this.isNullEng=false;
    },
    searchtxt: function () {
      if (this.searchlength == 1) {
        this.GetBank();
      }
    },
  },

  data() {
    return {
      bankname: [],
      banknameEng: [],
      banknameEdit: [],
      banknameEngEdit: [],
      bankIcon: "https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Bank Default.png",
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      url: enurl.apiUrl,
      showup: false,
      companyid: store.state.company_ID,
      chosenfile: null,
      importData: [],
      tempimport: [],
      //uploaddialog: false,
      modiIcon:"",
      cropDialog: false,
      cropEditDialog: false,
      cropperSrc: null,
      cropperImageSrc: '',
      croppedimage: "",
      cropped: false,
      delDialog: false,
      dynamicAspectRatio: null,
      LoadingDialog: false,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      bank_ID: 0,
      editok: false,
      Adddialog: false,
      Editdialog: false,
      Deletedialog: false,
      searchtxt: "",
      bank_Name: null,
      bank_NameEng: null,
      isNull:false,
      isNullEng:false,
      default: {
        bankIcon: "https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Bank Default.png"
      },
      add: {
        bankname: null,
        banknameEng: null,
        bankIcon: null
      },
      edit: {
        bank_ID: null,
        bankname: null,
        banknameEng: null,
        bankIcon: null,
        realIcon: null,
        base64img: null,
        swapIcon: null,
      },
      headers: [
        {
          text: "Icon Bank",
          value: "icon_Bank",
          align: "left",
          sortable: false,
        },

        {
          text: "Bank Name",
          value: "bank_Name",
          align: "left",
          sortable: false,
        },

        {
          text: "Bank Name(Eng)",
          value: "bank_NameEng",
          align: "left",
          sortable: false,
        },

        {
          text: "Actions",
          value: "actionadd",
          align: "left",
          sortable: false,
        },
      ],
      bank: [],
    };
  },

  mounted() {
    let self = this;
    self.GetBank();
    self.permission = self.CheckViewAddEditDeleteUpload(79, 80, 81, 82, 83);
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Bank Master Data.xlsx";
      } else {
        url =
          "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Bank Master Data.xlsx";
      }
      return url;
    },
    ValidateBank() {
      let self = this;
      let result;
      let FilterBank = self.bank.filter((data) => data.bank_Name);
      for (let i = 0; i < FilterBank.length; i++) {
        if (
          FilterBank[i].bank_Name.toLowerCase() ==
          self.add.bankname.toLowerCase()
        ) {
          alert("Bank already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    EditValidateBank() {
      let self = this;
      let result;
      let FilterBank = self.bank.filter(
        (data) => data.bank_ID != self.edit.bank_ID
      );
      for (let i = 0; i < FilterBank.length; i++) {
        if (
          FilterBank[i].bank_Name.toLowerCase() ==
          self.edit.bankname.toLowerCase()
        ) {
          alert("Bank already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["bank_Name"];
      if (rowName == "bank_Name") {
        self.bank = self.bank.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.bank = self.bank.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.bank_Name = null;
    },
    /*closeUpload() {
      this.uploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
    },*/
    /*getupload() {
      document.getElementById("myFileInput").click();
      this.showup = true;
    },*/
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },

    /*PreviewFiles() {
      let self = this;
      self.LoadingDialog = true;
      var files = self.chosenfile;
      if (files != null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.importData = XLSX.utils.sheet_to_json(worksheet);
          let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          const file_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          var BankFiltered = temp.filter((org) => org[0] == "");
          if (BankFiltered.length == 0 && temp.length != 0) {
            let combileArray = [];
            for (let i = 0; i < this.importData.length; i++) {
              this.tempimport = {
                bank_Name: file_data[i + 1][0],
                company_ID: store.state.companyID,
              };
              combileArray.push(this.tempimport);
            }
            if (filename == "Bank Master Data.xlsx") {
              axios
                .post(`${self.url}Bank/ImportBankMasterData`, combileArray)
                .then(function (response) {
                  if (response.data.status == 0) {
                    alert("Upload Successfully");
                    self.chosenfile = null;
                    self.showup = false;
                    self.LoadingDialog = false;
                    self.uploaddialog = false;
                    self.GetBank();
                    self.defaultSort();
                    self.LogTrace(null, "Upload  Bank ", 7, "Verbose");
                  }
                })
                .catch(function (error) {
                  self.LogTrace(error, "Upload Bank Fail", 7, "Verbose");
                  //alert(error + "Correlation ID is 7");
                  self.LoadingDialog = false;
                });
            } else {
              alert("File name must be Bank Master Data.xlsx");
              self.LoadingDialog = false;
            }
          } else {
            self.LoadingDialog = false;
            alert("Please fill bank_Name");
          }
        };
        reader.readAsArrayBuffer(files);
        self.LoadingDialog = false;
      } else {
        alert("Please choose File");
        self.LoadingDialog = false;
      }
    },*/
    // keymonitor: function (event) {
    //   if (event.key == "Backspace") {
    //     this.GetBank();
    //   }
    // },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        self.searchlength = 1;
        let List = self.bank;
        self.bank = [];
        let temp = List.filter((v) =>
          v.bank_Name.toLowerCase()
            .includes(self.searchtxt.toLowerCase()) ||
          v.bankName_EN.toLowerCase()
            .includes(self.searchtxt.toLowerCase())
        );

        for (let i = 0; i < temp.length; i++) {
          self.bank.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetBank();
      }
    },
    back() {
      this.$router.push({ name: "Masterdata" });
    },
    GetBank() {
      let self = this;
      self.LoadingDialog = true;
      self.searchlength = 0;
      let temp = { company_ID: store.state.companyID };
      axios
        .post(`${self.url}Bank/GetALLBanksByCompanyID`, temp)
        .then(function (response) {
          self.bank = response.data.data;
          //self.bank.bankIcon="data:image/png;base64,"+self.bank.bankIcon;
          //console.log("result",self.bank)
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Bank Fail", 7, "Critical");
          //alert(error + "Correlation ID is 7");
          self.LoadingDialog = false;
        });
    },
    GetBankDetail(id) {
      let self = this;
      self.LoadingDialog = true;
      self.searchlength = 0;
      let temp = { bank_ID: id };
      axios
        .post(`${self.url}Bank/GetBankDetailsByBankID`, temp)
        .then(function (response) {
          self.Editdialog = true;
          let temp = response.data.data[0];
          self.edit.bank_ID = temp.bank_ID;
          self.edit.bankname = temp.bank_Name;
          self.edit.banknameEng = temp.bankName_EN;
          self.edit.bankIcon = temp.bankIcon;
          self.modiIcon = "data:image/png;base64, " + temp.base64BankIcon;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Bank Detail Fail", 7, "Critical");
          self.LoadingDialog = false;
        });
    },
    CancelAdd() {
      let self = this;
      self.bankname = [];
      self.$refs.formAdd.reset();
      self.bankIcon = self.default.bankIcon;
      self.Adddialog = false;
      self.isNull=false;
      self.isNullEng=false;

    },
    CancelUpdate() {
      let self = this;
      self.banknameEdit = [];
      self.banknameEngEdit = [];
      //self.edit.base64img=null;
      self.$refs.formEdit.reset();
      self.Editdialog = false;
    },
    AddBank() {
      let self = this;
      self.LoadingDialog = true;
      self.bankname = [(v) => !!v || self.$t("require_field")];
      self.banknameEng = [(v) => !!v || self.$t("require_field")];
      if(self.add.bankname==null || self.add.bankname==""){
        self.isNull=true;
      }
      else{
        self.isNull=false;
      }
      if(self.add.banknameEng==null || self.add.banknameEng=="")
      {
        self.isNullEng=true;
      }
      else
      {
        self.isNullEng=false;
      }
      let tempvalidate = self.$refs.formAdd.validate();
      if (
        tempvalidate == true &&
        self.add.bankname != null &&
        self.add.bankname != "" &&
        self.add.banknameEng != null &&
        self.add.banknameEng != ""
      ) {
        let tempbank = self.ValidateBank();
        if (tempbank == true) {
          if (self.add.bankIcon == null) {
            self.add.bankIcon = self.default.bankIcon;
          }

          let tempdept = {
            bankIcon: self.add.bankIcon,
            bankName_TH: self.add.bankname,
            bankName_EN: self.add.banknameEng,
            company_ID: store.state.companyID,

          };
          //console.log(tempdept);
          axios
            .post(`${self.url}Bank/AddBank`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.add.bankname = null;
                self.add.banknameEng = null;
                self.Adddialog = false;
                self.LoadingDialog = false;
                self.GetBank();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Bank Fail", 7, "Low");
              //alert(error + "Correlation ID is 7");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("Please fill in the fields");
        self.LoadingDialog = false;
      }
    },
    // EditBanks(temp) {
    //   var self = this;
    //   self.Editdialog = true;
    //   self.edit.bank_ID = temp.bank_ID;
    //   self.edit.bankname = temp.bank_Name;
    //   self.edit.banknameEng= temp.bankName_EN;
    //   self.edit.bankIcon=temp.bankIcon;
    //   self.edit.base64img="data:image/png;base64, " +temp.base64BankIcon;
    // },
    Deletedia(id) {
      this.bank_ID = id;
      this.Deletedialog = true;
    },
    DeleteBank() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.bank_ID;
      let temp = {
        bank_ID: id,
      };
      axios
        .post(`${self.url}Bank/DeleteBank`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.Deletedialog = false;
            self.LoadingDialog = false;
            self.GetBank();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Bank Fail", 7, "High");
          //alert(error + "Correlation ID is 7");
          self.LoadingDialog = false;
        });
    },
    UpdateBank() {
      let self = this;
      self.LoadingDialog = true;
      self.banknameEdit = [(v) => !!v || self.$t("require_field")];
      self.banknameEngEdit = [(v) => !!v || self.$t("require_field")];
     
      if(self.edit.bankname==null || self.edit.bankname=="")
      {
        self.isNull=true;
      }
      else{
        self.isNull=false;
      }
      if(self.edit.banknameEng==null || self.edit.banknameEng=="")
      {
        self.isNullEng=true;
      }
      else{
        self.isNullEng=false;
      }
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.edit.bankname != null &&
        self.edit.bankname != "" &&
        self.edit.banknameEng != null &&
        self.edit.banknameEng != ""
      ) {
        let tempbank = self.EditValidateBank();
        if (tempbank == true) {

          let temp = {
            bank_ID: self.edit.bank_ID,
            bankName_TH: self.edit.bankname,
            bankName_EN: self.edit.banknameEng,
            bankIcon: self.edit.realIcon,
            company_ID: store.state.companyID,
          };

          axios
            .post(`${self.url}Bank/UpdateBank`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.edit.bankname = null;
                self.edit.banknameEng = null;
                self.Editdialog = false;
                self.LoadingDialog = false;
                self.edit.realIcon=null;
                self.GetBank();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Bank Fail", 7, "Medium");
              //alert(error + "Correlation ID is 7");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("Please fill in the fields");
        self.LoadingDialog = false;
      }
    },
    uploadIcon() {
      let self = this;
      var image = self.$refs.inputLogo;
      var input = self.$refs.inputLogo.files; 
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      self.dynamicAspectRatio = imageWidth / imageHeight;
      if (input[0]) {
        var reader = new FileReader();
        let file = input[0];

        let filesize = Math.round(file.size / 1024).toString();
        let filetype = file.type.split("/")[1];
        if (
          filetype == "png" ||
          filetype == "jpg" ||
          filetype == "bmp" ||
          filetype == "jpeg"
        ) {
          if (filesize < 2048) {

            reader.onload = () => {
              var arr = reader.result.split(","),
                mime = arr[1];
              self.bankIcon = "data:image/png;base64," + mime;
              self.cropperSrc = reader.result;
              self.cropped = false;
            };
            reader.readAsDataURL(input[0]);
            self.cropDialog = true;
          } else {
            alert("Uploaded photo is greater than 2MB");
            document.getElementById("sky").value = "";
          }
        } else {
          alert("Files must be png only");
          document.getElementById("sky").value = "";
        }
      }
    },


    Oncrop() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          this.convertBackToImageFile(blob);
        });

      }

      this.cropDialog = false;
    },

    convertBackToImageFile(imageBlob) {
      let self = this;
      const file = imageBlob;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var arr = reader.result.split(","),
          mime = arr[1];
        self.croppedimage = mime;
        self.bankIcon = "data:image/png;base64," + self.croppedimage;
        self.add.bankIcon = self.croppedimage;
        //console.log(self.croppedimage);
      };
    },

    CancelCrop() {

      //self.cropped=false;
      this.croppedimage = "";
      this.cropDialog = false;
      this.$refs.inputLogo.value='';
      this.bankIcon = this.default.bankIcon;
    },
    deleteDialog() {
      this.delDialog = true;
      this.$refs.inputLogo.value='';
    },
    DelCropPic() {
      this.delDialog = false;
      this.cropped = true;
      this.croppedimage = "";
      this.$refs.editLogo.value='';
    },
    deleteEditCrop() {
      this.cropped = true;
      this.delDialog = false;
      this.$refs.editLogo.value='';

    },
    savedefault() {
      this.bankIcon = null;
      this.bankIcon = this.default.bankIcon;
      this.cropDialog = false;
    },
    openeditcrop() {
      this.cropped = false;
      this.cropEditDialog = true;
      this.edit.base64img=this.modiIcon;
      //console.log(this.edit.base64img);
      this.editok = true;
      //this.loadImageFromUrl();   
    },
    closeEditcrop() { 
      this.editok = false;
      this.cropEditDialog = false;  
      this.$refs.editLogo.value='';
    },
    OnEdit() {
      if (this.cropped == false) {
        const { canvas } = this.$refs.cropper1.getResult();
        if (canvas) {
          canvas.toBlob((blob) => {
            this.convertBackToImageFile1(blob);
          });

        }
      }

      else {
        this.edit.bankIcon = this.default.bankIcon;
        this.edit.realIcon = this.edit.bankIcon;
      }
      this.cropEditDialog = false;
    },

    convertBackToImageFile1(imageBlob) {
      let self = this;
      const file = imageBlob;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var arr = reader.result.split(","),
          mime = arr[1];
        //self.croppedimage = mime;
        self.edit.bankIcon = "data:image/png;base64," + mime;
        self.modiIcon=self.edit.bankIcon;
        self.edit.realIcon = mime;

        //console.log(self.croppedimage);
      };
    },

    editIcon() {
      let self = this;
      var input = self.$refs.editLogo.files;
      var image = self.$refs.editLogo;
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      this.dynamicAspectRatio = imageWidth / imageHeight;
      if (input[0]) {
        var reader = new FileReader();
        let file = input[0];

        let filesize = Math.round(file.size / 1024).toString();
        let filetype = file.type.split("/")[1];
        if (
          filetype == "png" ||
          filetype == "jpg" ||
          filetype == "bmp" ||
          filetype == "jpeg"
        ) {
          if (filesize < 2048) {
            reader.readAsDataURL(input[0]);

            reader.onload = () => {
              var arr = reader.result.split(","),
                mime = arr[1];
              self.cropped = false;
              self.cropEditDialog = true;
              //self.edit.bankIcon = this.isApi? mime:"data:image/png;base64," + mime;
              self.edit.swapIcon = "data:image/png;base64," + mime;
              self.edit.realIcon = self.edit.base64img;
              self.edit.base64img = self.edit.swapIcon;
              //self.edit.base64img="data:image/png;base64,"+mime;
              //console.log(self.add.bankIcon);
            };

          } else {
            alert("Uploaded photo is greater than 2MB");
            document.getElementById("earth").value = "";
          }
        } else {
          alert("Files must be png only");
          document.getElementById("earth").value = "";
        }
      }
    }
  },
};
</script>
<style scoped>
 .cropper .cropper-crop-box {
    border-radius: 50%; /* Make the crop box circular */
    overflow: hidden; /* Hide any content outside the circle */
  }
>>> .vue-preview--fill {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50% !important;
  border: 1px solid white;
}
@media only screen and (max-width: 415px) {
  .add_btn {
    margin-top: -0.6rem;
  }

  /* .sear {
    margin-left: -0.1rem !important;
  } */
}

@media (max-width: 375px) {
  .add_btn {
    margin-top: 1rem !important;
  }
}

@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

element.style {
  margin-left: -1.5rem;
  margin-top: -1.3rem;
}

@media (min-width: 960px) {
  .container {
    max-width: 900px;
    /* justify-content: center; */
    display: contents;
  }
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  color: #f99d20;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}
>>>.v-messages__message {
  padding-left:18px !important;
}


</style>