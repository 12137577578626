<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem;margin-bottom: 1.5rem;">
    <div style="display: flex">
      <v-btn icon router color="white" :to="{ name: 'MyTimeOffRequest' }" class="mt-5">
        <img src="@/assets/images/back button.png" />
      </v-btn>
      <p class="txt-header">
        {{ $t("NewTimeOffRequest") }}
      </p>
    </div>
    <div style="height: auto" class="mt-4">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="9">
          <v-card min-height="80vh">
            <v-container style="padding-left: 25px;padding-right:25px;">
              <v-card-text>
                <div class="mt-8">
                  <v-row>
                    <!-- Request Date -->
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">{{
                      $t("RequestDate") }}:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-text-field autocomplete="off" readonly v-model="timeOff.request_DateTxt" height="10px"
                        color="#F99D20" single-line outlined dense required class="readonly"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" style="font-size: 15px" class="mt-2 pb-0">
                      {{ $t("leavetype") }}
                      <span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-select v-model="timeOff.leaveType" :items="LeaveList" outlined item-value="timeOff_ID" dense
                        color="#F99D20">
                        <template v-slot:selection="data">
                          {{ thethaihead == true ? data.item.timeoff_TypeTH : data.item.timeoff_TypeEN }}
                        </template>
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-content>
                              <v-list-item-subtitle>{{ thethaihead == true ? data.item.timeoff_TypeTH :
                                data.item.timeoff_TypeEN
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      <v-row>
                        <v-col v-if="timeOff.wholeDay">{{ $t("Allday") }}</v-col>
                        <v-col v-if="!timeOff.wholeDay">{{ $t("halfDay") }}</v-col>
                        <v-col class="pt-2">
                          <v-checkbox color="#F99D20" v-model="timeOff.wholeDay" hide-details class="custom-checkbox shrink mr-0 mt-0"></v-checkbox>
                        </v-col>
                      </v-row>
                      <!-- <input id="allday" @change="wholdDayCheck" v-model="timeOff.wholeDay" class="mt-1" type="checkbox"
                      style="margin-left:1rem;" /> -->

                    </v-col>
                  </v-row>

                  <!-- <v-col cols="12" sm="6" md="5">
                                    <label style="color: #8a8c8e">{{ $t("DirectSupervisor") }}
                                        <span class="red--text"> *</span></label>
                                    <v-text-field readonly class="mt-5" outlined dense color="#F99D20"
                                        v-model="timeOff.report_Name"></v-text-field>
                                </v-col> -->
                  <!-- Start Date and End Date -->
                  <div v-if="timeOff.wholeDay == true">
                    <v-row class="mt-3">
                      <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                        {{ $t("startDate") }}
                        <span class="red--text">*</span>:</v-col>
                      <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                        <!-- <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted"
                              class="kanit-medium" persistent-hint v-on="on" :error-messages="error.start_Date" readonly
                              color="#F99D20" outlined dense>
                              <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                                <span>{{ error.start_Date }}</span>
                              </template></v-text-field>
                          </template>
                          <v-date-picker v-model="timeOff.start_Date" no-title @input="menu = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker :class="error.start_Date ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" v-bind:clearable="false" value-type="format" v-model="timeOff.start_Date" 
                        no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                        <span v-if="error.start_Date" class="error-message ml-2">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{ error.start_Date }}</span>
                        </span>
                      </v-col>
                      <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                        {{ $t("enddata") }}
                        <span class="red--text">*</span>:
                      </v-col>
                      <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                        <!-- <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted1"
                              class="kanit-medium" persistent-hint readonly :error-messages="error.end_Date" v-on="on"
                              color="#F99D20" outlined dense>
                              <template v-slot:message="{ }">
                                <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                                <span>{{ error.end_Date }}</span>
                              </template></v-text-field>
                          </template>
                          <v-date-picker v-model="timeOff.end_Date" no-title @input="menu1 = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker :class="error.end_Date ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" v-bind:clearable="false" value-type="format" v-model="timeOff.end_Date" 
                        :disabled-date="allowedEndDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                        <span v-if="error.end_Date" class="error-message ml-2">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{ error.end_Date }}</span>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <!-- Date , start time , end time -->
                <div v-if="timeOff.wholeDay === false">
                  <v-row>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      {{ $t("date") }}<span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <!-- <v-menu v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field append-icon="mdi-calendar-month-outline" v-model="computedDateFormatted3"
                            class="kanit-medium mt-3" persistent-hint :error-messages="error.start_DateForHalf" readonly
                            v-on="on" color="#F99D20" outlined dense></v-text-field>
                        </template>
                        <v-date-picker v-model="timeOff.start_DateForHalf" no-title
                          @input="menu4 = false"></v-date-picker>
                      </v-menu> -->
                      <date-picker :class="error.start_DateForHalf ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" v-bind:clearable="false" value-type="format" v-model="timeOff.start_DateForHalf" 
                      no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      <span v-if="error.start_DateForHalf" class="error-message ml-2">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.start_DateForHalf }}</span>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      {{ $t("StartTime") }}<span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="timeOff.start_Time" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="timeOff.start_Time" append-icon="mdi-alarm" class="kanit-medium" readonly
                            :error-messages="error.start_Time" color="#8A8C8E" v-on="on" outlined dense></v-text-field>
                        </template>
                        <v-time-picker v-if="menu2" v-model="timeOff.start_Time" fluid
                          @click:minute="$refs.menu.save(timeOff.start_Time)"></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                      {{ $t("EndTime") }}<span class="red--text">*</span>:</v-col>
                    <v-col cols="12" xs="3" sm="3" md="4" lg="4" xl="4" class="pb-0">
                      <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="timeOff.end_Time" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field end_Time v-model="timeOff.end_Time" append-icon="mdi-alarm" class="kanit-medium"
                            :error-messages="error.end_Time" readonly dense color="#8A8C8E" v-on="on"
                            outlined></v-text-field>
                        </template>
                        <v-time-picker v-if="menu3" v-model="timeOff.end_Time" fluid
                          @click:minute="$refs.menu3.save(timeOff.end_Time)"></v-time-picker>
                      </v-menu>
                    </v-col>

                  </v-row>
                </div>
                
                <v-row class="mt-6"> 
                  <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                    {{ $t("DescriptionOT") }}<span class="red--text">*</span>:
                  </v-col>
                  <v-col cols="12" xs="3" sm="9" md="10" lg="10" xl="10" class="pb-0">
                    <v-textarea v-model="timeOff.message" :error-messages="error.message"
                    :placeholder="$t('Providefurtherdetailshere')" no-resize rows="3" min-height="70" color="#F99D20"
                      single-line outlined dense required>
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.message }}</span>
                      </template></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">
                    {{ $t("AttachmentOT") }}:
                  </v-col>
                  <v-col cols="12" xs="3" sm="9" md="10" lg="10" xl="10" class="pb-0">
                    <div>
                      <div v-if="uploaddefaultshow == true" @click="onpickfile" class="drop-area"
                        @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                        <div style="text-align: center">
                          <v-icon color="#A3A7AA">mdi-cloud-upload</v-icon>
                          <p style="color: #a3a7aa">{{ $t("draganddrop") }}</p>
                        </div>
                      </div>
                      <div class="container">
                        <div style="width: 130px; height: 120px; margin-right: 5px" v-if="uploaddefaultshow == false"
                          @click="onpickfile" class="drop-area" @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                          <div style="text-align: center" class="mt-2">
                            <v-icon style="color: #a3a7aa">mdi-cloud-upload</v-icon>
                            <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                          </div>
                        </div>
                        <div v-for="file in uploadedFiles" :key="file.name" class="mr-0 pa-3 relative" style="
                            width: 130px;
                            height: 120px;
                            border: 1px solid #ccc;
                            border-radius: 7px;
                          ">
                          <v-btn @click="removefile(file)" class="close-button top-0 right-0"
                            icon><v-icon>close</v-icon></v-btn>
                          <div @click="showiframe(file)" style="margin-top: -1.9rem">
                            <v-img class="mb-3 ml-5" v-if="file.type == 'image/png'" src="@/assets/images/png.png"
                              width="50px"></v-img>
                            <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpeg'" src="@/assets/images/jpg.png"
                              width="50px"></v-img>
                            <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpg'" src="@/assets/images/jpg.png"
                              width="50px"></v-img>
                            <v-img class="mb-3 ml-5" v-if="file.type == 'application/pdf'" src="@/assets/images/pdf.png"
                              width="50px"></v-img>
                            <div class="text-center">
                              {{
                                file.name
                                ? file.name.substring(0, 10) + " ..."
                                : ""
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none"
                        id="profile_picture" accept=".pdf, .png, .jpeg, .jpg" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2 pb-0" style="font-size: 15px">{{
                    $t("Approver")
                  }}:</v-col>
                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" class="pb-0">
                    <v-text-field autocomplete="off" readonly v-model="timeOff.approver_Name" class="readonly"
                      height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>


              <!-- <div class="d-flex"></div> -->
              <!-- <div class="d-flex mt-10">
                            <div>
                                <p>{{ $t("OffBalance") }}</p>
                            </div>
                            <div class="ml-2" style="width: 120px; margin-top: -7px">
                                <v-text-field readonly v-model="timeOff.balance" solo dense></v-text-field>
                            </div>
                            <div class="ml-3">
                                <p>{{ $t("days") }}</p>
                            </div>
                        </div>
                        <div style="height: 6rem"></div> -->
              <v-card-actions style="width:100%">
                <v-row>
                  <v-spacer></v-spacer>

                  <v-col cols="12" sm="3" md="3" lg="2" xl="2">
                    <v-btn :disabled="sentReq" block outlined class="text-capitalize commoncancelbtn" route
                      @click="goback()">{{ $t("Cancel")
                      }}</v-btn>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="2" xl="2">
                    <v-btn :disabled="sentReq" block style="background-color: #A3A7AA" class="white--text text-capitalize"
                      route @click="SaveDraft()">{{ $t("Draft")
                      }}</v-btn>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="2" xl="2">
                    <v-btn :disabled="sentReq" style="background-color: #f99d20" class="white--text text-capitalize" block
                      route @click="sentRequest()">{{
                        $t("Submit") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="3">
          <v-card height="65%" min-height="50vh">
            <div style="padding-left: 3%; padding-right: 3%; border-bottom: 1px solid #cfcdcd">
              <div class="d-flex ml-3" style="display:flex;text-align:center;">
                <v-row class="mt-4 mb-4">
                  <img class="mr-3" src="@/assets/images/balance.png" width="20" height="20" />
                  <span style="margin-top: 0px; color: #F99D20" class="pt-0">{{
                    $t("leavebalance") }}</span>
                </v-row>
              </div>
            </div>
            <v-row style="padding-left: 3%; padding-right: 3%;">
              <v-data-table class="mt-4 ml-2 mr-2" style="max-height: 395px; overflow-y: auto; width: 100%;"
                :items="leaveDaysList" disable-pagination hide-default-footer hide-default-header :mobile-breakpoint="0">
                <template v-slot:item="{ item }">
                  <tr>
                    <td v-show="!thethaihead">
                      <v-col>
                        {{ item.timeoff_TypeEN }}
                        <p style="color:#7F7F7F">
                          {{ item.availablDays }}
                          <span>
                            {{ $t("daysavailable") }}
                          </span>
                        </p>
                      </v-col>
                    </td>
                    <td v-show="thethaihead">
                      <v-col>
                        {{ item.timeoff_TypeTH }}
                        <p style="color:#7F7F7F">
                          {{ item.availablDays }}
                          <span>
                            {{ $t("daysavailable") }}
                          </span>
                        </p>
                      </v-col>
                    </td>
                    <td style="min-width: 100px">
                      {{ item.leaveDaysLeft }} {{ $t("days") }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-row>
          </v-card>
          <v-card height="32.2%" min-height="20vh" class="mt-5 upcomingmain">
            <div class="d-flex mb-4 pb-4" style="border-bottom: 1px solid #cfcdcd">
              <span style="padding-top: 20px; color: #F99D20"> <span class="mr-3 mt-2 ml-4"> <img
                    src="@/assets/images/wall-clock.png" width="20" height="20" /></span>
                {{ $t("upcomingLeave") }}
              </span>
              <!-- <div class="circle" style="margin-top:1.5rem;margin-left:1rem;">1</div> upcomingcard -->

              <v-avatar v-if="lengthOfUpcoming > 0" style="margin-top: 22px" class="ml-1" color="orange" size="18">
                <p class="white--text mt-3" style="font-size: 15px">
                  {{ lengthOfUpcoming }}
                </p>
              </v-avatar>
            </div>
            <p v-if="lengthOfUpcoming == 0" style="margin-top: 18px; color: #f99d20; margin-left: 20px;">
              {{ $t("NoUpcomingTimeOff") }}
            </p>
            <div v-if="approverNameList.length">
              <v-row v-for="item in approverNameList" :key="item.id">
                <v-col class="mx-4">
                  <v-card style="border-bottom: 2px solid #f99d20">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle v-show="!thethaihead">{{ item.days_left ? item.days_left : 1 }}
                          {{ $t("DaysOf") }}
                          {{ item.timeoff_Name }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-show="thethaihead">{{ item.days_left ? item.days_left : 1 }}
                          {{ $t("DaysOf") }}
                          {{ item.timeoff_NameTH }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          item.end_Date == "Invalid date"
                          ? item.start_Date
                          : item.start_Date + "-" + item.end_Date
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ $t("ApprovedBy") }}
                          {{ item.approver_Name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <template>
      <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
        style="overflow: hidden">
        <v-card style="
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
          ">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

            <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
          </v-card-actions>

          <v-img :src="imgForshow" max-width="500"></v-img>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
      <v-card style="background-color: white">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn @click="
            printdialogforupdate = false;
          iframeShow = false;
          " icon style="margin-right: 3rem; margin-top: 1rem">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-row>

        <v-card class="mt-4 pdf-container">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- loading dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Axios from "axios";
import store from "@/store";
import jsPDF from "jspdf";
import enurl from "@/api/environment";
import ReportTrace from "@/function/Report.js";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, ReportTrace, utils],
  data: (vm) => ({
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    url: enurl.apiUrl,
    LoadingDialog: false,
    userIditems: ["123456", "327578", "334565", "454778"],
    timeTypeList: [],
    items: [],
    approverNameList: [],
    checkboxallday: true,
    menu: false,
    menu1: false,
    date1: "",
    startdate: "",
    lengthOfUpcoming: null,
    sentReq: false,
    halfdate: "",
    menu4: "",
    menu2: false,
    menu3: false,
    tempvalid: "",
    error: {
      timeoff_ID: "",
      balance: "",
      start_Date: "",
      end_Date: "",
      start_DateForHalf: "",
      start_Time: "",
      end_Time: "",
      message: "",
    },
    timeOff: {
      request_Date: new Date(),
      request_DateTxt: "",
      start_DateForHalf: "",
      timeoff_ID: 0,
      leaveType: 0,
      wholeDay: true,
      balance: "",
      start_Date: "",
      end_Date: "",
      start_Time: "",
      end_Time: "",
      message: "",
      approver_Name: "",
      saveDraft: false,
      attachment: [],
      requester_ID: store.state.employeeID,
      approver_ID: parseInt(store.state.reportID),
      company_ID: store.state.companyID,
    },
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    LeaveList: [],
    leaveDaysList: [],
    thelang: "",
    dialogforDownload: false,
    printdialogforupdate: false,
    iframeShow: false,
    dllink: null,
    imgForshow: "",
    imgString: "",
    pdfString: "",
    uploaddefaultshow: true,
    selectedFiles: [],
    uploadedFiles: [],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.timeOff.start_Date);
    },
    computedDateFormatted1() {
      return this.formatDate(this.timeOff.end_Date);
    },
    computedDateFormatted3() {
      return this.formatDate(this.timeOff.start_DateForHalf);
    },
    thethaihead() {
      return this.whatthehead();
    },
  },
  mounted() {
    let td = new Date();
    const formattedDate = `${this.padZero(td.getDate())}/${this.padZero(td.getMonth() + 1)}/${td.getFullYear()} ${this.padZero(td.getHours())}:${this.padZero(td.getMinutes())}`;
    this.timeOff.request_DateTxt = formattedDate;
    this.GetLeaveType();
    this.GetLeaveTypeWithRemainingDays();
    // this.GetPosition();
    // this.GetReport();
    this.GetDateApproverName();
    this.sendNotification();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  watch: {
    thelang() {
      this.whatthehead()
    },
    "timeOff.employee"(v) {
      if (v) this.error.employee = "";
    },
    "timeOff.message"(v) {
      if (v) this.error.message = "";
    },
    "timeOff.end_Time"(v) {
      if (v) this.error.end_Time = "";
    },
    "timeOff.start_Time"(v) {
      if (v) this.error.start_Time = "";
    },
    "timeOff.end_Date"(v) {
      if (v) this.error.end_Date = "";
    },
    "timeOff.timeoff_ID"(v) {
      if (v) this.error.timeoff_ID = "";
    },
    "timeOff.start_DateForHalf"(v) {
      if (v) this.error.start_DateForHalf = "";
    },
    'timeOff.start_Date': function(newStartDate) {
      if (newStartDate) this.error.start_Date = "";
      if (!newStartDate || !this.timeOff.end_Date) {
        return;
      }
      // Parse the new start_Date and end_Date strings (format: DD/MM/YYYY) to Date objects
      const [newDay, newMonth, newYear] = newStartDate.split('/');
      const newStart = new Date(newYear, newMonth - 1, newDay);
      newStart.setHours(0, 0, 0, 0); // Reset time part for comparison
      const [endDay, endMonth, endYear] = this.timeOff.end_Date.split('/');
      const endDate = new Date(endYear, endMonth - 1, endDay);
      endDate.setHours(0, 0, 0, 0); // Reset time part for comparison
      // Clear end_Date if new start_Date is greater than end_Date
      if (newStart > endDate) {
        this.timeOff.end_Date = null;
      }
    }
  },
  methods: {
    goback() {
      this.$router.push({ name: "MyTimeOffRequest" });
    },
    padZero(num) {
      return num.toString().padStart(2, '0');
    },
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },

    GetLeaveType() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      Axios.post(`${self.url}TimeOff/GetLeaveTypeByCompanyID`, tempp)
      .then(function (response) {
        let thelist = response.data.data;
        self.LeaveList = thelist.filter((v) => v.leaveStatus == true);
        if (self.LeaveList.length != 0)
        {
          self.timeOff.leaveType = self.LeaveList[0].timeOff_ID;
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    GetLeaveTypeWithRemainingDays() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
      };
      Axios.post(`${self.url}TimeOff_Request/GetLeaveWithRemainingDays`, tempp)
      .then(function (response) {
        self.leaveDaysList = response.data.data;
        self.timeOff.approver_Name = self.leaveDaysList[0].approve_Name;
      })
      .catch(function (error) {
        alert(error);
      });
    },

    async sendNotification() {
      let self = this;
      let TempReportID = null;
      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${this.url}PersonalInfo/GetShortInfo`,userInfoTemp)
      .then(function (response) {
        if(response.data.status == 0)
        {
          var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
          var reportID = response.data.data[0].report_ID;
          self.username = username;
          self.reportID = reportID;
          TempReportID = reportID;
        }
      });
      if (TempReportID)
      {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await Axios.post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`,temp)
        .then(function (response) {
          var temp = response.data.data;
          if (temp[0])
          {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });
        self.tokenList = token;
      }
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}OT_Request/GetworkFlowOfOTRequest`,temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          let WF_ID = response.data.data.wF_ID;
          let senderID = response.data.data.requester_ID;
          let recipientID = response.data.data.approver_ID;
          self.newnotiList(WF_ID, senderID, recipientID);
        }
      });
    },

    newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for Time Off`,
      };
      Axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          self.pushNotification(recipientID);
        }
      });
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Timeoff Request",
        body: "Timeoff Request from " + self.username,
        reportID: recipientID,
      };
      await Axios.post(`${this.url}Firebasetoken/sendNotification`,noti_temp).then(function () {
      });
    },

    wholdDayCheck() {
      this.clearData(this.error);
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    async GetPosition() {
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
        };
        const response = await Axios.post(`${this.url}TimeOff_Request/GetByPositionID`,temp);
        if(response.data.status == 0)
        {
          this.timeTypeList = response.data.data;
        }
      } catch (ex) {
        throw ex;
      }
    },

    async GetReport() {
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
        };
        const response = await Axios.post(`${this.url}TimeOff_Request/GetReporter`,temp);
        let list = response.data.data;
        let { employee_ID, name, lastName } = list;
        this.timeOff.approver_ID = employee_ID;
        this.timeOff.report_Name = `${name} ${lastName}`;
      } catch (ex) {
        throw ex;
      }
    },

    async sentRequest() {
      try {
        // let startDate = this.$moment(this.timeOff.start_Date);
        // let endDate = this.$moment(this.timeOff.end_Date);
        let startDate = this.$moment(this.timeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let endDate = this.$moment(this.timeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.timeOff.timeoff_ID = this.timeOff.leaveType;
        if (this.uploadedFiles.length)
        {
          for (var i = 0; i < this.uploadedFiles.length; i++)
          {
            this.timeOff.attachment.push({
              attachment_file: this.uploadedFiles[i].imgurl,
              filename: this.uploadedFiles[i].name,
              filetype: this.uploadedFiles[i].type
            });
          }
        }
        if (this.timeOff.approver_Name)
        {
          this.timeOff["saveDraft"] = false;
          this.error.timeoff_ID = !this.timeOff.timeoff_ID ? "Time Off Type is required" : "";
          this.error.message = !this.timeOff.message ? "Message is required" : "";
          if (this.timeOff.wholeDay)
          {
            this.error.start_Date = !this.timeOff.start_Date ? "Start date is required" : "";
            this.error.end_Date = !this.timeOff.end_Date ? "End date is required" : "";
            this.timeOff.start_Time = "";
            this.timeOff.end_Time = "";
            let tempvalid = this.ValidateAddTrue();
            if (tempvalid)
            {
              if (startDate <= endDate)
              {
                let anyleft = this.checkDaysLeft();
                if (anyleft)
                {
                  this.LoadingDialog = true;
                  let temp = {
                    timeoff_ID: this.timeOff.timeoff_ID,
                    wholeDay: this.timeOff.wholeDay,
                    start_Date: startDate,
                    end_Date: endDate,
                    requested_Date: this.timeOff.requested_Date,
                    start_Time: this.timeOff.start_Time,
                    end_Time: this.timeOff.end_Time,
                    message: this.timeOff.message,
                    saveDraft: false,
                    requester_ID: this.timeOff.requester_ID,
                    approver_ID: this.timeOff.approver_ID,
                    company_ID: store.state.companyID,
                    workFlowType: "TimeOff",
                    attachments: this.timeOff.attachment,
                    balance: this.timeOff.balance
                  };
                  const respone = await Axios.post(`${this.url}TimeOff_Request/AddTimeOff_RequestForEmp`,temp);
                  if (respone.data.status !== 0)
                  {
                    this.LoadingDialog = false;
                    alert("Please recheck the date since there seems to be a clash with another request");
                  }
                  else
                  {
                    alert(respone.data.message);
                    this.LoadingDialog = false;
                    if (respone.data.status === 0)
                    {
                      await this.getLatestData();
                    }
                    this.ReportTrace(6);
                    this.$router.push({ name: "MyTimeOffRequest" }).catch(() => { });
                    this.clearData(this.timeOff);
                    //this.LogTrace(null, "Add TimeOff", 31, "Low");
                  }
                }
                else
                {
                  alert("Requested balance not available.");
                }
              }
              else
              {
                alert("Start date can't be after end date");
              }
            }
            else
            {
              this.ValidateAddFalse();
            }
          }
          else
          {
            this.error.start_Time = !this.timeOff.start_Time ? "Start Time is required" : "";
            this.error.end_Time = !this.timeOff.end_Time ? "End Time is required" : "";
            this.error.start_DateForHalf = !this.timeOff.start_DateForHalf  ? "Date is required" : "";
            this.timeOff.start_Time = this.timeOff.start_Time;
            this.timeOff.end_Time = this.timeOff.end_Time;
            this.timeOff.end_Date = this.timeOff.start_DateForHalf;
            this.timeOff.start_Date = this.timeOff.start_DateForHalf;
            let tempvalid = this.ValidateAddHalf();
            if (tempvalid)
            {
              let start_Time = this.$moment(this.timeOff.start_Time, "h:mma");
              let end_time = this.$moment(this.timeOff.end_Time, "h:mma");
              if (start_Time.isBefore(end_time))
              {
                let anyleft = this.checkDaysLeft();
                if (anyleft)
                {
                  let temp = {
                    timeoff_ID: this.timeOff.timeoff_ID,
                    wholeDay: this.timeOff.wholeDay,
                    // start_Date: this.timeOff.start_DateForHalf,
                    // end_Date: this.timeOff.start_DateForHalf,
                    start_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    end_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    requested_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').toISOString(),
                    start_Time: this.timeOff.start_Time,
                    end_Time: this.timeOff.end_Time,
                    message: this.timeOff.message,
                    saveDraft: false,
                    requester_ID: this.timeOff.requester_ID,
                    approver_ID: this.timeOff.approver_ID,
                    company_ID: store.state.companyID,
                    workFlowType: "TimeOff",
                    attachments: this.timeOff.attachment,
                    balance: this.timeOff.balance
                  };
                  const respone = await Axios.post(`${this.url}TimeOff_Request/AddTimeOff_RequestForEmp`,temp);
                  if (respone.data.status !== 0)
                  {
                    this.LoadingDialog = false;
                    alert("Please recheck the date since there seems to be a clash with another request");
                  }
                  else
                  {
                    this.LoadingDialog = false;
                    alert(respone.data.message);
                    if (respone.data.status === 0)
                    {
                      await this.getLatestData();
                    }
                    this.ReportTrace(6);
                    this.$router.push({ name: "MyTimeOffRequest" }).catch(() => { });
                    this.clearData(this.timeOff);
                    //this.LogTrace(null, "Add TimeOff", 31, "Low");
                  }
                }
                else
                {
                  alert("Requested balance not available.");
                }
              }
              else
              {
                alert("Start time can't be after end time");
              }
            }
            else
            {
              this.ValidateAddHalfFalse();
            }
          }
        }
        else
        {
          alert("เราไม่สามารถดำเนินการตามการขอร้องของคุณหากไม่มีหัวหน้างานโดยตรง");
        }
      } catch (ex) {
        this.LogTrace(ex, "Add TimeOff Fail", 31, "Low");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    allowedEndDates(date) {
      if (!this.timeOff.start_Date)
      {
        return false; // No start date selected, all dates are enabled
      }
       // Parse the start_Date string (format: DD/MM/YYYY) to a Date object
      const [day, month, year] = this.timeOff.start_Date.split('/');
      const startDate = new Date(year, month - 1, day);
      startDate.setHours(0, 0, 0, 0); // Reset time part for comparison
      return date < startDate; // Disable dates before start date
    },

    async SaveDraft() {
      try
      {
        let startDate = this.$moment(this.timeOff.start_Date);
        let endDate = this.$moment(this.timeOff.end_Date);
        //let dayDiff = endDate.diff(startDate, "days") + 1;
        this.timeOff.timeoff_ID = this.timeOff.leaveType;
        if (this.uploadedFiles.length)
        {
          for (var i = 0; i < this.uploadedFiles.length; i++)
          {
            this.timeOff.attachment.push({
              attachment_file: this.uploadedFiles[i].imgurl,
              filename: this.uploadedFiles[i].name,
              filetype: this.uploadedFiles[i].type,
            });
          }
        }

        if (this.timeOff.approver_Name)
        {
          this.timeOff["saveDraft"] = true;
          this.error.timeoff_ID = !this.timeOff.timeoff_ID ? "Time Off Type is required" : "";
          this.error.message = !this.timeOff.message ? "Message is required" : "";
          if (this.timeOff.wholeDay)
          {
            this.error.start_Date = !this.timeOff.start_Date ? "Start date is required" : "";
            this.error.end_Date = !this.timeOff.end_Date ? "End date is required" : "";
            this.timeOff.start_Time = "";
            this.timeOff.end_Time = "";
            this.timeOff.start_Date = this.timeOff.start_Date;
            let tempvalid = this.ValidateAddTrue();
            if (tempvalid)
            {
              if (startDate.isBefore(endDate.clone().add(1, "day")))
              {
                let anyleft = this.checkDaysLeft();
                if (anyleft)
                {
                  this.LoadingDialog = true;
                  let temp = {
                    timeoff_ID: this.timeOff.timeoff_ID,
                    wholeDay: this.timeOff.wholeDay,
                    start_Date: this.$moment(this.timeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    end_Date: this.$moment(this.timeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    requested_Date: this.timeOff.requested_Date,
                    start_Time: this.timeOff.start_Time,
                    end_Time: this.timeOff.end_Time,
                    message: this.timeOff.message,
                    saveDraft: true,
                    requester_ID: this.timeOff.requester_ID,
                    approver_ID: this.timeOff.approver_ID,
                    company_ID: store.state.companyID,
                    workFlowType: "TimeOff",
                    attachments: this.timeOff.attachment,
                    balance: this.timeOff.balance
                  };
                  const respone = await Axios.post(`${this.url}TimeOff_Request/AddTimeOff_RequestForEmp`,temp);
                  if (respone.data.status !== 0)
                  {
                    this.LoadingDialog = false;
                    alert("Please recheck the date since there seems to be a clash with another request");
                  }
                  else
                  {
                    this.LoadingDialog = false;
                    alert("Add Draft Success");
                    this.$router.push({ name: "MyTimeOffRequest" }).catch(() => { });
                    this.clearData(this.timeOff);
                    this.clearData(this.timeOff);
                    //this.LogTrace(null, "Add TimeOff", 31, "Low");
                  }
                }
                else
                {
                  alert("Requested balance not available.")
                }
              }
              else
              {
                alert("Start date can’t be after end date");
              }
            }
            else
            {
              this.ValidateAddFalse();
            }
          }
          else
          {
            this.error.start_Time = !this.timeOff.start_Time ? "Start Time is required" : "";
            this.error.end_Time = !this.timeOff.end_Time ? "End Time is required" : "";
            this.error.start_DateForHalf = !this.timeOff.start_DateForHalf ? "Date is required" : "";
            this.timeOff.start_Time = this.timeOff.start_Time;
            this.timeOff.end_Time = this.timeOff.end_Time;
            this.timeOff.end_Date = this.timeOff.start_DateForHalf;
            this.timeOff.start_Date = this.timeOff.start_DateForHalf;
            let tempvalid = this.ValidateAddHalf();
            if (tempvalid)
            {
              let start_Time = this.$moment(this.timeOff.start_Time, "h:mma");
              let end_time = this.$moment(this.timeOff.end_Time, "h:mma");
              if (start_Time.isBefore(end_time))
              {
                let anyleft = this.checkDaysLeft();
                if (anyleft)
                {
                  let temp = {
                    timeoff_ID: this.timeOff.timeoff_ID,
                    wholeDay: this.timeOff.wholeDay,
                    // start_Date: this.timeOff.start_DateForHalf,
                    // end_Date: this.timeOff.start_DateForHalf,
                    start_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    end_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    requested_Date: this.$moment(this.timeOff.start_DateForHalf, 'DD/MM/YYYY').toISOString(),
                    start_Time: this.timeOff.start_Time,
                    end_Time: this.timeOff.end_Time,
                    message: this.timeOff.message,
                    saveDraft: true,
                    requester_ID: this.timeOff.requester_ID,
                    approver_ID: this.timeOff.approver_ID,
                    company_ID: store.state.companyID,
                    workFlowType: "TimeOff",
                    attachments: this.timeOff.attachment,
                    balance: this.timeOff.balance
                  };
                  const respone = await Axios.post(`${this.url}TimeOff_Request/AddTimeOff_RequestForEmp`,temp);
                  if (respone.data.status !== 0)
                  {
                    this.LoadingDialog = false;
                    alert( "Please recheck the date since there seems to be a clash with another request");
                  }
                  else
                  {
                    this.LoadingDialog = false;
                    alert(respone.data.message);
                    this.$router.push({ name: "MyTimeOffRequest" }).catch(() => { });
                    this.clearData(this.timeOff);
                    //this.LogTrace(null, "Add TimeOff", 31, "Low");
                  }
                }
                else
                {
                  alert("Requested balance not available.")
                }
              }
              else
              {
                alert("Start time can't be after end time");
              }
            }
            else
            {
              this.ValidateAddHalfFalse();
            }
          }
        }
        else
        {
          alert("เราไม่สามารถดำเนินการตามการขอร้องของคุณหากไม่มีหัวหน้างานโดยตรง");
        }
      }
      catch (ex) {
        this.LogTrace(ex, "Add TimeOff Fail", 31, "Low");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    ValidateAddTrue() {
      if (this.timeOff.wholeDay)
      {
        if (
          this.timeOff.timeoff_ID != 0 &&
          this.timeOff.start_Date != null &&
          this.timeOff.end_Date != null &&
          this.timeOff.message != null &&
          this.timeOff.timeoff_ID != "" &&
          this.timeOff.start_Date != "" &&
          this.timeOff.end_Date != "" &&
          this.timeOff.message != ""
        )
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },

    ValidateAddHalf() {
      if (!this.timeOff.wholeDay)
      {
        if (
          this.timeOff.timeoff_ID != 0 &&
          this.timeOff.start_DateForHalf != null &&
          this.timeOff.start_Time != null &&
          this.timeOff.end_Time != null &&
          this.timeOff.message != null &&
          this.timeOff.start_DateForHalf != "" &&
          this.timeOff.start_Time != "" &&
          this.timeOff.end_Time != "" &&
          this.timeOff.message != ""
        )
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },

    ValidateAddFalse() {
      if (
        this.timeOff.timeoff_ID == 0 ||
        this.timeOff.start_Date == null ||
        this.timeOff.end_Date == null ||
        this.timeOff.message == null ||
        this.timeOff.start_Date == "" ||
        this.timeOff.end_Date == "" ||
        this.timeOff.message == ""
      )
      {
        alert("Please fill in the required fields!");
      }
    },

    ValidateAddHalfFalse() {
      if (
        this.timeOff.timeoff_ID == 0 ||
        this.timeOff.start_DateForHalf == null ||
        this.timeOff.start_Time == null ||
        this.timeOff.end_Time == null ||
        this.timeOff.message == null
      )
      {
        alert("Please fill in the required fields!");
      }
    },

    clearData(obj = {}, defaultValue = null) {
      for (const key in obj) {
        obj[key] = defaultValue;
      }
    },

    async onInputHandler(id, timeTypeList) {
      this.sentReq = false;
      const filter = timeTypeList.find((x) => x.timeoff_ID == id);
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          timeoff_ID: filter.timeoff_ID,
        };
        const respone = await Axios.post(`${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyIdTimeOffId`,temp);
        let date = respone.data.data;
        let availability_Days = filter.availability_Days;
        if (availability_Days == date.avalable_Day || availability_Days < date.avalable_Day)
        {
          this.timeOff.balance = 0;
          this.sentReq = true;
        }
        else
        {
          let result = availability_Days - date.avalable_Day;
          result = String(result);
          if (result == 0.5)
          {
            this.timeOff.balance = "Half";
            this.isHalf = 0.5;
          }
          else if (result.match(/\./))
          {
            let final = result.split(".")[0];
            this.timeOff.balance = final + " and half";
            this.isHalf = +final + 0.5;
          }
          else
          {
            this.timeOff.balance = result;
            this.isHalf = result;
          }
        }
      } catch (ex) {
        throw ex;
      }
    },
    
    async GetDateApproverName() {
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          requester_ID: parseInt(store.state.reportID),
        };
        const respone = await Axios.post(`${this.url}TimeOff_Request/GetTimeOff_ForApprove`,temp);
        this.approverNameList = respone.data.data;
        this.approverNameList.map((x) => {
          x.start_Date = this.$moment(x.start_Date).format("DD/MMM/YYYY").split("/").join(" ");
          x.end_Date = this.$moment(x.end_Date).format("DD/MMM/YYYY").split("/").join(" ");
          x.days_left = x.days_left == 0.5 ? "Half" : x.days_left;
        });
        this.lengthOfUpcoming = this.approverNameList.length;
      }
      catch (ex)
      {
        throw ex;
      }
    },

    checkDaysLeft() {
      let self = this;
      let result = false;
      let takendays = this.getDays();
      let theone = self.leaveDaysList.filter((v) => v.timeOff_ID == self.timeOff.leaveType);
      if (theone[0].availablDays > takendays) {
        result = true;
        this.timeOff.balance = (theone[0].availablDays - takendays).toString();
      }
      return result;
    },

    getDays() {
      if (this.timeOff.wholeDay == true)
      {
        // Split the dates into day, month, and year components
        const [startDay, startMonth, startYear] = this.timeOff.start_Date.split('/').map(Number);
        const [endDay, endMonth, endYear] = this.timeOff.end_Date.split('/').map(Number);

        // Create Date objects with the parsed components
        const sd = new Date(startYear, startMonth - 1, startDay); // Month is 0-based in JavaScript Date
        const ed = new Date(endYear, endMonth - 1, endDay);

        // Calculate the time difference in milliseconds
        const timeDifference = ed.getTime() - sd.getTime();
        
        // Calculate the number of days by dividing the time difference by milliseconds in a day
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Since it's whole day, we include both the start and end dates
        return daysDifference + 1;
        // let sd = new Date(this.timeOff.start_Date);
        // let ed = new Date(this.timeOff.end_Date);
        // // Convert both dates to UTC to ensure consistent calculation
        // const startUtc = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        // const endUtc = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
        // // Calculate the time difference in milliseconds
        // const timeDifference = endUtc - startUtc;
        // // Calculate the number of days by dividing the time difference by milliseconds in a day
        // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        // return daysDifference + 1;
      }
      else
      {
        return 0.5;
      }

    },

    //here lie the attachment all sort of thing function
    onpickfile() {
      this.$refs.fileupload.click();
    },

    async handleFileSelect(event) {
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file)
      {
        if (file.type === "application/pdf")
        {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        }
        else if (file.type.startsWith("image/"))
        {
          //const pdfBlob = await this.convertImageToPdf(file);
          // console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    async handleFiledrag(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file)
      {
        if (file.type === "application/pdf")
        {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        }
        else if (file.type.startsWith("image/"))
        {
          //const pdfBlob = await this.convertImageToPdf(file);
          // console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    uploadFilesall(imgname, type, url, pdfurl) {
      let plusid = this.uploadedFiles.length;
      this.uploadedFiles.push({
        id: plusid,
        name: imgname,
        type: type,
        imgurl: url,
        pdfurl: pdfurl,
      });
      this.uploaddefaultshow = false;
    },
    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },
    base64ToBlobforimg(base64String, contentType) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
    async convertBase64ImageToPdf(base64Image) {
      const pdf = new jsPDF();
      const blob = this.base64ToBlobforimg(base64Image, "image/jpeg"); // Adjust content type if needed
      const imgData = URL.createObjectURL(blob);

      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed

      const pdfData = pdf.output("blob");
      return pdfData;
    },
    async convertImageToPdf(selectedFile) {
      const pdf = new jsPDF();
      const imgData = await this.readFileAsDataUrl(selectedFile);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed

      const pdfData = pdf.output("blob");
      return pdfData;
    },
    readFileAsDataUrl(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },
    removefile(file) {
      const index = this.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1) {
        this.uploadedFiles.splice(index, 1);
      }

      if (this.uploadedFiles.length == 0) {
        this.uploaddefaultshow = true;
      }
    },
    removefileEdit(file) {
      const index = this.EditOT.attachmentlist.findIndex(
        (item) => item.id === file.id
      );
      if (index !== -1) {
        this.EditOT.attachmentlist.splice(index, 1);
      }

      if (this.EditOT.attachmentlist.length == 0) {
        this.uploaddefaultshow = true;
      }
    },
    showiframe(item) {
      let self = this;
      if (
        item.type == "image/png" ||
        item.type == "image/jpeg" ||
        item.type == "image/jpg"
      ) {
        self.imgForshow = "data:image/png;base64," + item.imgurl;
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
      } else {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }
    },
    async showiframeforDetail(item) {
      let self = this;
      if (item.filetype == "pdf") {
        const blob = await self.base64ToBlob(
          item.attachment_file,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      } else {
        self.dialogforDownload = true;
        self.imgForshow = "data:image/png;base64," + item.attachment_file;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },
    handleFileDrop(event) {
      event.preventDefault();
      // const files = event.dataTransfer.files;
      // this.uploadFiles(files);
      this.handleFiledrag(event);
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

.pdf-container {
  background-color: #aaa9ae;
  margin-left: 3rem;
  margin-right: 3rem;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}

.close-button {
  position: relative;
  top: -15px;
  left: 80px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.image-iframe {
  width: 400px;
  /* Set your desired width */
  height: 400px;
  /* Set your desired height */
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  font-size: 10px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  background: #F99D20
}

.upcomingcard {
  overflow-y: auto;
  overflow-x: hidden;
}

.upcomingmain {
  overflow-x: hidden;
}
</style>
