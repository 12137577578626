<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <v-tabs
      grey--text
      slider-color="#F89D1F"
      class="active"
      style="cursor: pointer; padding-top: 30px"
      show-arrows
    >
      <v-tab
        class="text-capitalize"
        style="border: 1.4px solid #f89d1f; width: 20rem"
        @click="showsave()"
        >{{ $t("socialsecuritydetails") }}</v-tab
      >
      <v-tab
        class="text-capitalize"
        style="border: 1.4px solid #f89d1f; width: 20rem"
        @click="showadd()"
        >{{ $t("History") }}</v-tab
      >

      <v-tab-item style="cursor: context-menu">
        <table>
          <tr>
            <td>
              <img
                style="margin-top: 15px"
                src="@/assets/images/Mask Group 45.png"
              />
            </td>
            <td>
              <p
                style="
                  color: #f89d1f;
                  font-size: 17px;
                  margin-top: 12px;
                  margin-left: 10px;
                "
              >
                {{ $t("socialsecuritydetails") }}
              </p>
            </td>
          </tr>
        </table>

        <v-row>
          <v-col cols="12" sm="6" md="3" class="mt-3">
            <v-text-field
              autocomplete="off"
              v-model="social.ssN"
              :disabled="disablessn"
              color="#F99D20"
              :error-messages="error.errormessagessN">
              <template v-slot:label>
                <span> {{ $t("social_security_number") }}</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <!-- <v-menu
              v-model="menu6"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              fluid
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  autocomplete="off"
                  append-icon="mdi-calendar-month-outline"
                  v-model="computedDateFormatted6"
                  :disabled="disabledate"
                  class="kanit-medium"
                  color="#F99D20"
                  persistent-hint
                  readonly
                  v-on="on"
                >
                  <template v-slot:label>
                    <span> {{ $t("effectivedate") }}</span>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="social.comdate"
                no-title
                @input="menu6 = false"
              ></v-date-picker>
            </v-menu> -->
            <label class="custom-label" style="color: #8a8c8e">{{ $t("effectivedate") }}</label>
            <date-picker class="custom-vuedatepicker" :editable="false" v-bind:clearable="false" value-type="format" v-model="social.comdate" :disabled="disabledate"
            no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
          </v-col>
        </v-row>
        <table>
          <tr>
            <td>
              <img
                style="margin-top: 11px"
                src="@/assets/images/Mask Group 46.png"
              />
            </td>
            <td>
              <p
                style="
                  color: #f89d1f;
                  font-size: 17px;
                  margin-top: 10px;
                  margin-left: 10px;
                "
              >
                {{ $t("hospital") }}
              </p>
            </td>
          </tr>
        </table>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
              :items="HospitalList"
              v-model="social.hospital"
              :disabled="disablehospital"
              item-value="hospital_ID"
              :item-text="(item) => ` ${item.hospital_Name}`"
              append-icon="mdi-chevron-down"
              color="#F99D20"
            >
              <template v-slot:label>
                <span> {{ $t("hospital") }}</span>
              </template></v-autocomplete
            >
          </v-col>
        </v-row>

        <div
          style="
            margin-top: 3rem;
            padding-bottom: 2rem;
          "
        >
          <v-row v-show="showedit">
            <v-col>
              <v-btn
                class="float-right mr-2 text_transform_none commonsavebtn" 
                @click="editgone()"
                :disabled="
                  $route.path ==
                  '/selfservice/PersonalProfile/PersonalInformation/SocialSecurity'
                    ? permission.edit
                    : permission_management.edit
                "
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-show="showbtn1">
            <v-col offset-md="9" offset-sm="8" offset-xs="6">
              <v-btn
                class="btnForSave commonsavebtn"
                :disabled="permission_management.add"
                @click="Addsocialsecurity()"
                >{{ $t("save") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-show="showbtnwithcde">
            <v-col class="d-flex justify-end">
              <v-btn
                class="text_transform_none commonsavebtn"
                :disabled="permission_management.add"
                @click="AddsocialsecurityWithCode()"
              >
                {{ $t("save") }}</v-btn
              >
            </v-col>
          </v-row>

          <v-row v-show="showbtnwithsid">
            <v-spacer></v-spacer>
            <v-btn
              class="pa-2 mr-2 text_transform_none commonsavebtn"
              :disabled="permission.add"
              @click="AddsocialsecurityWithSid()"
              >{{ $t("save") }}</v-btn
            >
          </v-row>
          <v-row v-show="showbtnupd">
            <v-spacer></v-spacer>
            <div style="margin-right: 1rem">
              <v-btn
                color="#3C4043"
                class="pa-2 mr-5 text_transform_none commoncancelbtn"
                outlined
                @click="cancelgone()"
                text
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
                :disabled="permission_management.edit"
                @click="Updatesocialsecurity()"
                >{{ $t("save") }}</v-btn
              >
            </div>
          </v-row>
          <v-row v-show="showupdatebtnwithcde">
            <v-spacer></v-spacer>
            <div style="margin-right: 1rem">
              <v-btn
                outlined
                class="pa-2 mr-5 text_transform_none commoncancelbtn"
                @click="cancelgone()"
                text
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
                :disabled="permission_management.edit"
                @click="UpdatesocialsecurityWithCode()"
                >{{ $t("save") }}</v-btn
              >
            </div>
          </v-row>
          <v-row v-show="showupdatebtnwithsid">
            <v-spacer></v-spacer>
            <div style="margin-right: 1rem">
              <v-btn
                outlined
                class="pa-2 mr-5 text_transform_none commoncancelbtn"
                @click="cancelgone()"
                text
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
                :disabled="permission.edit"
                @click="UpdatesocialsecurityWithSid()"
                >{{ $t("save") }}</v-btn
              >
            </div>
          </v-row>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="12" sm="6" md="9"></v-col>
          <v-col cols="12" sm="6" md="3">
            <div v-show="showbtn">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5 float-right"
                width="150"
                max-height="35"
                :disabled="
                  $route.path ==
                  '/selfservice/PersonalProfile/PersonalInformation/SocialSecurity'
                    ? permission.add
                    : permission_management.add
                "
                @click="OpenSocialAdddialog"
                >{{ $t("Add") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-data-table
          style="margin-top: 20px"
          :mobile-breakpoint="0"
          :headers="getHospitalHeader"
          :items="SSHistoryList"
          @click:row="handleClick"
          hide-default-footer
        >
          <template v-slot:item.reason="{ item }">
            <span class="d-inline-block text-truncate" style="max-width: 400px">
              {{ item.reason }}
            </span>
          </template>
          <template v-slot:item.effDate1="{ item }">{{
            formatDate(item.compensation_Date)
          }}</template>
        </v-data-table>
        <div style="height: 10rem"></div>
      </v-tab-item>
    </v-tabs>

    <br />

    <!-- add dialog -->
    <v-dialog v-model="SocialAdddialog" persistent max-width="800px">
      <v-card ref="form">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("newsocail") }}
        </p>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  autocomplete="off"
                  v-model="addhistory.hospital"
                  :error-messages="error.errormessagehosp"
                  color="#F99D20"
                  required
                  readonly
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("hospital")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  autocomplete="off"
                  v-model="computedDateFormatted4"
                  :error-messages="error.errorMsgEff_Date"
                  color="#F99D20"
                  persistent-hint
                  readonly
                >
                  <template v-slot:label>
                    <span
                      >{{ $t("date_ofchange") }}
                      <span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
            </v-row>
            <p class="ml-1" style="font-size: 17px">
              {{ $t("reason") }} <span class="red--text"> *</span>
            </p>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="addhistory.reason"
                  :error-messages="error.errormessagereas"
                  solo
                  outlined
                  color="#F99D20"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            text
            @click="CloseAddDig"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-show="showaddSSHwithEid"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddSSHistory"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            v-show="showaddSSHwithCde"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddSSHistoryWithCode"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            v-show="showaddSSHwithSid"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddSSHistoryWithSid"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewHistory" persistent max-width="800px">
      <v-card ref="form">
        <div class="d-flex pt-3">
          <p
            style="
              font-size: 26px;
              margin-left: 2rem;
              padding-top: 20px;
              margin-right: 2rem;
            "
          >
            Social Security History
          </p>
          <v-spacer></v-spacer>

          <v-btn @click="viewHistory = false" icon>
            <v-icon>close </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  autocomplete="off"
                  v-model="view.hospital_Name"
                  color="#F99D20"
                  required
                  readonly
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("hospital") }}<span class="red--text"> </span
                    ></span> </template
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  autocomplete="off"
                  v-model="computedDateFormatted5"
                  :label="$t('Date Of Change')"
                  color="#F99D20"
                  persistent-hint
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
            <p class="ml-1" style="font-size: 17px">{{ $t("reason") }}</p>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="view.reason"
                  solo
                  readonly
                  outlined
                  color="#F99D20"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loading dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/babel-polyfill/dist/polyfill.min.js"></script>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
export default {
  mixins: [CheckViewAddEditDelete, LogTrace, utils],
  data: (vm) => ({
    permission: {
      add: true,
      edit: true,
      delete: true,
    },
    permission_management: {
      add: true,
      edit: true,
      delete: true,
    },
    empID: store.state.employeeID,
    comID: store.state.companyID,
    url: enurl.apiUrl,
    sshID: 0,
    eeid: 0,
    SSList: [],
    HospitalList: [],
    PersonalList: [],
    SSHistoryList: [],
    HiredDate: null,
    LoadingDialog: false,
    SocialEditdialog: false,
    SocialAdddialog: false,
    SocialDeletedialog: false,
    showupdatebtnwithcde: false,
    showupdatebtnwithsid: false,
    showaddSSHwithEid: false,
    showaddSSHwithCde: false,
    showaddSSHwithSid: false,
    showbtnwithcde: false,
    showbtnwithsid: false,
    showupatewithSSHEid: false,
    showupatewithSSHCde: false,
    showupatewithSSHSid: false,
    showbtnupd: false,
    showcancel: false,
    showbtn: false,
    showbtn1: false,
    showedit: true,
    disableedit: true,
    disablessn: true,
    viewHistory: false,
    disabledate: true,
    disablehospital: true,
    dated: null,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu6: false,
    menu5: false,
    menu4: false,
    deduction_methods: ["Deduction Method 1", "Deduction Method2"],
    items: ["SSC", "Yangon hospital", "Rose Hospital"],
    social: {
      sid: 0,
      ssN: null,
      hospital: null,
      comdate: "",
    },
    addhistory: {
      hospital: null,
      comdate: "",
      reason: null,
    },
    editsocial: {
      id: null,
      hospital: null,
      reason: null,
      comdate: "",
      eid: 0,
    },
    view: {},
    error: {
      errormessagehosp: "",
      errormessagemth: "",
      errormessagedate: "",
      errormessagessN: "",
      errormessagefrst: "",
      errormessagesec: "",
      errormessagethrd: "",
      errormessagereas: "",
      errorMsgEff_Date: "",
      errorMsgAmount: "",
    },
    computedDateFormatted4: null,
  }),
  watch: {
    "social.ssN"() {
      this.error.errormessagessN = [];
    },
    "social.comdate"() {
      this.error.errormessagedate = [];
    },
    "social.hospital"() {
      this.error.errormessagehosp = [];
    },
    "addhistory.hospital"() {
      this.error.errormessagehosp = [];
    },
    computedDateFormatted4() {
      this.error.errorMsgEff_Date = [];
    },
    "addhistory.reason"() {
      this.error.errormessagereas = [];
    },
  },

  computed: {
    computedDateFormatted5() {
      return this.formatDate(this.view.compensation_Date);
    },
    // computedDateFormatted4() {
    //   return this.formatDate(this.addhistory.comdate);
    // },
    form() {
      return {
        dated: this.dated,
      };
    },
    getHospitalHeader() {
      return [
        {
          text: this.$t("hospital"),
          value: "hospital_Name",
          align: "left",
          sortable: false,
        },

        {
          text: this.$t("reason"),
          value: "reason",
          align: "left",
          sortable: false,
          width: "50%",
        },
        {
          text: this.$t("date_ofchange"),
          value: "effDate1",
          align: "left",
          sortable: false,
        },
      ];
    },
  },

  mounted: function () {
    this.GetHospital();
    this.permission = this.CheckViewAddEditDelete(132, 133, 134, 135);
    this.permission_management = this.CheckViewAddEditDelete(
      205,
      206,
      207,
      135
    );
    this.disable_btn();
    if (
      this.$route.path ==
      "/selfservice/PersonalProfile/PersonalInformation/SocialSecurity"
    ) {
      this.GetHistoryWithSID();
      this.GetSSWithSID();
      this.showaddSSHwithEid = false;
      this.showaddSSHwithCde = false;
      this.showaddSSHwithSid = true;
    } else if (this.$route.params.id != undefined) {
      this.GetHistory();
      this.GetSS();
      this.showaddSSHwithEid = true;
      this.showaddSSHwithCde = false;
      this.showaddSSHwithSid = false;
    } else if (localStorage.empcode != null) {
      this.GetHistoryWithCode();
      this.GetSSWithCode();
      this.showaddSSHwithEid = false;
      this.showaddSSHwithCde = true;
      this.showaddSSHwithSid = false;
    } else {
      this.showaddSSHwithEid = false;
      this.showaddSSHwithCde = true;
      this.showaddSSHwithSid = false;
    }
    this.showsave();
  },
  methods: {
    //permission
    disable_btn() {
      let self = this;
      if (
        self.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/SocialSecurity"
      ) {
        if (self.permission.add == false) {
          self.permission.add = false;
        }
        if (self.permission.edit == false) {
          self.permission.edit = false;
        }
        if (self.permission.delete == false) {
          self.permission.delete = false;
        }
      } else {
        if (self.permission_management.add == false) {
          self.permission_management.add = false;
        }
        if (self.permission_management.edit == false) {
          self.permission_management.edit = false;
        }
        if (self.permission_management.delete == false) {
          self.permission_management.delete = false;
        }
      }
    },
    handleClick(val) {
      this.viewHistory = true;
      this.view = Object.assign({}, val);
    },
    editgone() {
      let self = this;
      let id = parseInt(self.$route.params.id);
      if (
        this.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/SocialSecurity"
      ) {
        self.disablessn = false;
        self.disabledate = false;
        self.disablehospital = false;
        self.showedit = false;
        self.showbtn1 = false;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showcancel = true;
        self.showbtnupd = false;
        self.showupdatebtnwithcde = false;
        self.showupdatebtnwithsid = true;
      } else if (id != undefined) {
        self.disablessn = false;
        self.disabledate = false;
        self.disablehospital = false;
        self.showedit = false;
        self.showbtn1 = false;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showcancel = true;
        self.showbtnupd = true;
        self.showupdatebtnwithcde = false;
        self.showupdatebtnwithsid = false;
      } else {
        self.disablessn = false;
        self.disabledate = false;
        self.disablehospital = false;
        self.showedit = false;
        self.showbtn1 = false;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showcancel = true;
        self.showbtnupd = false;
        self.showupdatebtnwithcde = true;
        self.showupdatebtnwithsid = false;
      }
    },
    cancelgone() {
      let self = this;
      self.disablessn = true;
      self.disabledate = true;
      self.disablehospital = true;
      self.showedit = true;
      self.showbtn1 = false;
      self.showcancel = false;
      self.showbtnwithcde = false;
      self.showbtnwithsid = false;
      self.showbtnupd = false;
      self.showupdatebtnwithcde = false;
      self.showupdatebtnwithsid = false;
    },
    showsave() {
      let self = this;
      let id = parseInt(self.$route.params.id);

      if (
        this.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/SocialSecurity"
      ) {
        self.showbtn = false;
        self.showbtn1 = false;
        self.showedit = true;
        self.showcancel = false;
        self.disablessn = true;
        self.disabledate = true;
        self.disablehospital = true;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showupdatebtnwithcde = false;
        self.showupdatebtnwithsid = false;
        self.showbtnupd = false;
        if (self.SSList.length == 0) {
          self.disablessn = false;
          self.disabledate = false;
          self.disablehospital = false;
          self.showedit = false;
          self.showcancel = false;
          self.showbtn1 = false;
          self.showbtnwithcde = false;
          self.showbtnwithsid = true;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
        }
      } else if (id != undefined) {
        self.showbtn = false;
        self.showbtn1 = false;
        self.showedit = true;
        self.showcancel = false;
        self.disablessn = true;
        self.disabledate = true;
        self.disablehospital = true;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showupdatebtnwithcde = false;
        self.showupdatebtnwithsid = false;
        self.showbtnupd = false;
        if (self.SSList.length == 0) {
          self.disablessn = false;
          self.disabledate = false;
          self.disablehospital = false;
          self.showedit = false;
          self.showcancel = false;
          self.showbtn1 = true;
          self.showbtnwithcde = false;
          self.showbtnwithsid = false;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
        }
      } else {
        self.showbtn = false;
        self.showbtn1 = false;
        self.showedit = true;
        self.showcancel = false;
        self.disablessn = true;
        self.disabledate = true;
        self.disablehospital = true;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showupdatebtnwithcde = false;
        self.showupdatebtnwithsid = false;
        self.showbtnupd = false;
        if (self.SSList.length == 0) {
          self.disablessn = false;
          self.disabledate = false;
          self.disablehospital = false;
          self.showedit = false;
          self.showcancel = false;
          self.showbtn1 = false;
          self.showbtnwithcde = true;
          self.showbtnwithsid = false;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
        }
      }
    },
    showadd() {
      let self = this;
      let id = parseInt(self.$route.params.id);
      if (id != undefined) {
        self.showbtn = true;
        self.showbtn1 = false;
        self.showbtnwithcde = false;
        self.showbtnwithsid = false;
        self.showbtnupd = false;
        if (self.SSHistoryList.length == 0) {
          self.showbtn = true;
        }
      } else {
        self.showbtn = true;
      }
    },

    resetForm() {
      this.error.errorMsgAmount = [];
      this.error.errorMsgEff_Date = [];
      this.error.errormessagereas = [];
      this.error.errormessagessN = [];
      this.error.errormessagedate = [];
      this.error.errormessagemth = [];
      this.error.errormessagefrst = [];
      this.error.errormessagehosp = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    CloseEditDig() {
      this.SocialEditdialog = false;
      this.resetForm();
    },
    CloseAddDig() {
      this.SocialAdddialog = false;
      this.addhistory.hospital = null;
      this.addhistory.comdate = "";
      this.addhistory.reason = null;
      this.resetForm();
    },
    validateDigit(ssNID) {
      let emm = /^\d{13}$/;
      return emm.test(ssNID) || "Only digit allow";
    },

    GetHospital() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Hospital/GetHospital`, tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.HospitalList = response.data.data;
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    //get with id
    GetHistory() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: parseInt(self.$route.params.id),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurityHistory/GetSocialSecurityHistoryByEmployeeID`,tempp)
      .then(function (response) {
        self.SSHistoryList = response.data.data;
        self.LoadingDialog = false;
        if (self.SSHistoryList.length > 0)
        {
          self.showaddSSHwithEid = true;
          self.showaddSSHwithCde = false;
          self.showaddSSHwithSid = false;
          //self.LogTrace(null, "Get History", 16, "Critical");
        }
        else
        {
          self.showaddSSHwithEid = true;
          self.showaddSSHwithCde = false;
          self.showaddSSHwithSid = false;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get History Fail", 16, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
      self.LoadingDialog = false;
    },

    //get with code
    GetHistoryWithCode() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_Code: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurityHistory/GetSocialSecurityHistoryByEmployeeCode`,tempp)
      .then(function (response) {
        self.SSHistoryList = response.data.data;
        self.LoadingDialog = false;
        if (self.SSHistoryList.length > 0)
        {
          self.showaddSSHwithEid = false;
          self.showaddSSHwithCde = true;
          self.showaddSSHwithSid = false;
          //self.LogTrace(null, "Get History", 16, "Critical");
        }
        else
        {
          self.showaddSSHwithEid = false;
          self.showaddSSHwithCde = true;
          self.showaddSSHwithSid = false;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get History Fail", 16, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
      self.LoadingDialog = false;
    },

    //get with session id
    GetHistoryWithSID() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurityHistory/GetSocialSecurityHistoryByEmployeeID`,tempp)
      .then(function (response) {
        self.SSHistoryList = response.data.data;
        self.LoadingDialog = false;
        if (self.SSHistoryList.length > 0)
        {
          self.showaddSSHwithEid = false;
          self.showaddSSHwithCde = false;
          self.showaddSSHwithSid = true;
          //self.LogTrace(null, "Get History", 16, "Critical");
        }
        else
        {
          self.showaddSSHwithEid = false;
          self.showaddSSHwithCde = false;
          self.showaddSSHwithSid = true;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get History Fail", 16, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
      self.LoadingDialog = false;
    },

    // get with id
    GetSS() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: parseInt(self.$route.params.id),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurity/GetSocialSecurityByEmployeeID`, tempp)
      .then(function (response) {
        self.SSList = response.data.data;
        self.LoadingDialog = false;
        if (self.SSList.length > 0)
        {
          self.social.sid = self.SSList[0].s_ID;
          self.social.ssN = self.SSList[0].sS_Num;
          if (self.SSList[0].effective_Date != null)
          {
            // let date = new Date(self.SSList[0].effective_Date);
            // let plusday = date.getDate() + 1;
            // date.setDate(plusday);
            // self.social.comdate = new Date(date).toISOString().substr(0, 10);

            let date = self.$moment(self.SSList[0].effective_Date).format('DD/MM/YYYY');
            self.social.comdate = date;
            self.computedDateFormatted4 = date;
          }
          self.social.hospital = self.SSList[0].hospital_ID;
          self.showedit = true;
          self.showcancel = false;
          self.disablessn = true;
          self.disabledate = true;
          self.disablehospital = true;
          self.showbtn1 = false;
          self.showbtnwithcde = false;
          self.showbtnwithsid = false;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
          // self.LogTrace(null, "Get SocialSecurity", 16, "Critical");
        }
        else
        {
          if (self.$route.params.id != null)
          {
            self.disablessn = false;
            self.disabledate = false;
            self.disablehospital = false;
            self.showedit = false;
            self.showcancel = false;
            self.showbtn1 = true;
            self.showbtnwithcde = false;
            self.showbtnwithsid = false;
            self.showbtnupd = false;
            self.showupdatebtnwithcde = false;
            self.showupdatebtnwithsid = false;
          } else {
            self.disablessn = false;
            self.disabledate = false;
            self.disablehospital = false;
            self.showedit = false;
            self.showcancel = false;
            self.showbtn1 = false;
            self.showbtnwithcde = true;
            self.showbtnwithsid = false;
            self.showbtnupd = false;
            self.showupdatebtnwithcde = false;
            self.showupdatebtnwithsid = false;
          }
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get SocialSecurity Fail", 16, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
    },

    //get with code
    GetSSWithCode() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_Code: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurity/GetSocialSecurityByEmployeeCode`,tempp)
      .then(function (response) {
        self.SSList = response.data.data;
        self.LoadingDialog = false;
        if (self.SSList.length > 0)
        {
          self.social.sid = self.SSList[0].s_ID;
          self.social.ssN = self.SSList[0].sS_Num;
          if (self.SSList[0].effective_Date != null)
          {
            // let date = new Date(self.SSList[0].effective_Date);
            // let plusday = date.getDate() + 1;
            // date.setDate(plusday);
            // self.social.comdate = new Date(date).toISOString().substr(0, 10);
            let date = self.$moment(self.SSList[0].effective_Date).format('DD/MM/YYYY');
            self.social.comdate = date;
            self.computedDateFormatted4 = date;
            // let date = self.$moment(self.SSList[0].effective_Date).add(1, 'day');
            // let formattedDate = date.format('DD/MM/YYYY');
            // self.social.comdate = formattedDate;
          }
          self.social.hospital = self.SSList[0].hospital_ID;
          self.showedit = true;
          self.showcancel = false;
          self.disablessn = true;
          self.disabledate = true;
          self.disablehospital = true;
          self.showbtn1 = false;
          self.showbtnwithcde = false;
          self.showbtnwithsid = false;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
          //self.LogTrace(null, "Get SocialSecurity", 16, "Critical");
        }
        else
        {
          if (self.$route.params.id != null)
          {
            self.disablessn = false;
            self.disabledate = false;
            self.disablehospital = false;
            self.showedit = false;
            self.showcancel = false;
            self.showupdatebtnwithcde = false;
            self.showupdatebtnwithsid = false;
            self.showbtn1 = true;
            self.showbtnwithcde = false;
            self.showbtnwithsid = false;
            self.showbtnupd = false;
          }
          else
          {
            self.disablessn = false;
            self.disabledate = false;
            self.disablehospital = false;
            self.showupdatebtnwithcde = false;
            self.showupdatebtnwithsid = false;
            self.showedit = false;
            self.showcancel = false;
            self.showbtn1 = false;
            self.showbtnwithcde = true;
            self.showbtnwithsid = false;
            self.showbtnupd = false;
          }
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "GetSocialSecurity Fail", 16, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
    },

    //get with session id
    GetSSWithSID() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}SocialSecurity/GetSocialSecurityByEmployeeID`, tempp)
      .then(function (response) {
        self.SSList = response.data.data;
        self.LoadingDialog = false;
        if (self.SSList.length > 0)
        {
          self.social.sid = self.SSList[0].s_ID;
          self.social.ssN = self.SSList[0].sS_Num;
          if (self.SSList[0].effective_Date != null)
          {
            // let date = new Date(self.SSList[0].effective_Date);
            // let plusday = date.getDate() + 1;
            // date.setDate(plusday);
            // self.social.comdate = new Date(date).toISOString().substr(0, 10);
            // let date = self.$moment(self.SSList[0].effective_Date).add(1, 'day');
            // let formattedDate = date.format('DD/MM/YYYY');
            // self.social.comdate = formattedDate;
            let date = self.$moment(self.SSList[0].effective_Date).format('DD/MM/YYYY');
            self.social.comdate = date;
            self.computedDateFormatted4 = date;
          }
          self.social.hospital = self.SSList[0].hospital_ID;
          self.showedit = true;
          self.showcancel = false;
          self.disablessn = true;
          self.disabledate = true;
          self.disablehospital = true;
          self.showbtn1 = false;
          self.showbtnwithcde = false;
          self.showbtnwithsid = false;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
          //self.LogTrace(null, "Get SocialSecurity", 16, "Critical");
        }
        else
        {
          self.disablessn = false;
          self.disabledate = false;
          self.disablehospital = false;
          self.showedit = false;
          self.showcancel = false;
          self.showbtn1 = false;
          self.showbtnwithcde = false;
          self.showbtnwithsid = true;
          self.showbtnupd = false;
          self.showupdatebtnwithcde = false;
          self.showupdatebtnwithsid = false;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get SocialSecurity Fail", 16, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
    },

    //add with id
    Addsocialsecurity() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let ssNvali = self.validateDigit(self.social.ssN);
        // this.error.errormessagessN = !this.social.ssN
        //   ? "Social Security Number is required"
        //   : "";

        // this.error.errormessagehosp = !this.social.hospital
        //   ? "Hospital is required"
        //   : "";

        // this.error.errormessagedate = !this.social.comdate
        //   ? "Effective date is requried"
        //   : "";
        if (
          (this.social.comdate == "" &&
            self.social.ssN == null &&
            self.social.hospital == null) ||
          (self.social.comdate == "" &&
            self.social.ssN == "" &&
            self.social.hospital == null)
        )
        {
          //alert("Add Success");
          self.LoadingDialog = false;
        } else if (
          self.social.comdate != null ||
          self.social.comdate != "" ||
          self.social.hospital != null ||
          self.social.hospital != "" ||
          self.social.ssN != null ||
          self.social.ssN != ""
        ) {
          let move = false;
          if (self.social.ssN != null && self.social.ssN != "") {
            if (ssNvali == true) {
              move = true;
            } else {
              move = false;
              alert("please fill the correct format");
              this.error.errormessagessN =
                this.social.ssN != ssNvali
                  ? "Please fill only number and 13 digits"
                  : this.social.ssN;
              self.LoadingDialog = false;
            }
          } else {
            move = true;
          }
          if (self.social.hospital == null) {
            self.social.hospital = 0;
          }
          if (move == true) {
            let socialuser = {
              sS_Num: self.social.ssN,
              hospital_ID: self.social.hospital,
              effective_Date: self.$moment(self.social.comdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              employee_ID: parseInt(self.$route.params.id),
              company_ID: store.state.companyID,
            };
            axios
            .post(`${self.url}SocialSecurity/AddSocialSecurity`, socialuser)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.LoadingDialog = false;
                self.GetSS();
                self.disablessn = true;
                self.disabledate = true;
                self.disablehospital = true;
                self.showedit = true;
                self.showbtn1 = false;
                self.showbtnwithcde = false;
                self.showbtnwithsid = false;
                self.showbtnupd = false;
                self.showupdatebtnwithcde = false;
                self.showupdatebtnwithsid = false;
                //self.LogTrace(null, "Add Social Security", 16, "Low");
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Social Security Fail", 16, "Low");
              alert(error);
              self.LoadingDialog = false;
            });
          }
        }
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    //add with code
    AddsocialsecurityWithCode() {
      //try {
      let self = this;
      self.LoadingDialog = true;
      let ssNvali = self.validateDigit(self.social.ssN);
      // this.error.errormessagessN = !this.social.ssN
      //   ? "Social Security Number is required"
      //   : "";
      // this.error.errormessagehosp = !this.social.hospital
      //   ? "Hospital is required"
      //   : "";
      // this.error.errormessagedate = !this.social.comdate
      //   ? "Effective date is required"
      //   : "";
      if (
        self.social.comdate == "" &&
        self.social.hospital == null &&
        (self.social.ssN == null || self.social.ssN == "")
      )
      {
        // alert("Add Success");
        self.LoadingDialog = false;
      }
      else if (
        self.social.comdate != null ||
        self.social.comdate != "" ||
        self.social.ssN != null ||
        self.social.ssN != "" ||
        self.social.hospital != null ||
        self.social.hospital != ""
      )
      {
        let move = false;
        if (self.social.ssN != null && self.social.ssN != "")
        {
          if (ssNvali)
          {
            move = true;
          }
          else
          {
            move = false;
            alert("please fill the correct format");
            this.error.errormessagessN =
              this.social.ssN != ssNvali
                ? "Please fill only number and 13 digits"
                : this.social.ssN;
            self.LoadingDialog = false;
          }
        }
        else
        {
          move = true;
        }
        if (self.social.hospital == null)
        {
          self.social.hospital = 0;
        }
        if (move)
        {
          let socialuser = {
            s_ID: self.social.sid,
            sS_Num: self.social.ssN,
            hospital_ID: self.social.hospital,
            effective_Date: self.$moment(self.social.comdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            employee_Code: localStorage.empcode,
            company_ID: store.state.companyID,
          };
          axios.post(`${self.url}SocialSecurity/AddSocialSecurityWithCode`,socialuser)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.LoadingDialog = false;
              self.GetSSWithCode();
              self.disablessn = true;
              self.disabledate = true;
              self.disablehospital = true;
              self.showedit = true;
              self.showbtn1 = false;
              self.showbtnwithcde = false;
              self.showbtnwithsid = false;
              self.showbtnupd = false;
              self.showupdatebtnwithcde = false;
              self.showupdatebtnwithsid = false;
              //self.LogTrace(null, "Add Social Security", 16, "Low");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Social Security Fail", 16, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        }
      }
      // } catch (error) {
      //   alert("error");
      //   self.LoadingDialog = false;
      // }
    },

    //add with session id
    AddsocialsecurityWithSid() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let ssNvali = self.validateDigit(self.social.ssN);
        // this.error.errormessagessN = !this.social.ssN
        //   ? "Social Security Number is required"
        //   : "";

        // this.error.errormessagehosp = !this.social.hospital
        //   ? "Hospital is required"
        //   : "";

        // this.error.errormessagedate = !this.social.comdate
        //   ? "Effective date is required"
        //   : "";
        if (
          self.social.comdate == "" &&
          self.social.hospital == null &&
          (self.social.ssN == null || self.social.ssN == "")
        )
        {
          // alert("Add Success");
          self.LoadingDialog = false;
        }
        else if (
          self.social.comdate != null ||
          self.social.comdate != "" ||
          self.social.ssN != null ||
          self.social.ssN != "" ||
          self.social.hospital != null ||
          self.social.hospital != ""
        )
        {
          let move = false;
          if (self.social.ssN != null && self.social.ssN != "")
          {
            if (ssNvali)
            {
              move = true;
            }
            else
            {
              move = false;
              alert("please fill the correct format");
              this.error.errormessagessN =
                this.social.ssN != ssNvali
                  ? "Please fill only number and 13 digits"
                  : this.social.ssN;
              self.LoadingDialog = false;
            }
          }
          else
          {
            move = true;
          }
          if (self.social.hospital == null)
          {
            self.social.hospital = 0;
          }
          if (move)
          {
            let socialuser = {
              sS_Num: self.social.ssN,
              hospital_ID: self.social.hospital,
              effective_Date: self.$moment(self.social.comdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              employee_ID: store.state.employeeID,
              company_ID: store.state.companyID,
            };
            axios.post(`${self.url}SocialSecurity/AddSocialSecurity`, socialuser)
            .then(function (response) {
              if (response.data.status == 0)
              {
                alert(response.data.message);
                self.LoadingDialog = false;
                self.GetSSWithSID();
                self.disablessn = true;
                self.disabledate = true;
                self.disablehospital = true;
                self.showedit = true;
                self.showbtn1 = false;
                self.showbtnwithcde = false;
                self.showbtnwithsid = false;
                self.showbtnupd = false;
                self.showupdatebtnwithcde = false;
                self.showupdatebtnwithsid = false;
                //self.LogTrace(null, "Add Social Security", 16, "Low");
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Social Security Fail", 16, "Low");
              alert(error);
              self.LoadingDialog = false;
            });
          }
        }
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    Updatesocialsecurity() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let ssNvali = self.validateDigit(self.social.ssN);
        if (
          self.social.comdate == "" &&
          self.social.hospital == null &&
          (self.social.ssN == null || self.social.ssN == "")
        ) {
          // alert("Add Success");
          self.LoadingDialog = false;
        } else if (
          self.social.comdate != null ||
          self.social.comdate != "" ||
          self.social.ssN != null ||
          self.social.ssN != "" ||
          self.social.hospital != null ||
          self.social.hospital != ""
        )
        {
          let move = false;
          if (self.social.ssN != null && self.social.ssN != "")
          {
            if (ssNvali)
            {
              move = true;
            }
            else
            {
              move = false;
              alert("please fill the correct format");
              this.error.errormessagessN =
                this.social.ssN != ssNvali
                  ? "Please fill only number and 13 digits"
                  : this.social.ssN;
            }
          }
          else
          {
            move = true;
          }
          if (self.social.hospital == null)
          {
            self.social.hospital = 0;
          }
          if (move)
          {
            let socialuser = {
              s_ID: self.social.sid,
              sS_Num: self.social.ssN,
              hospital_ID: self.social.hospital,
              // effective_Date: new Date(self.social.comdate),
              effective_Date: self.$moment(self.social.comdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              employee_ID: parseInt(self.$route.params.id),
              company_ID: store.state.companyID,
            };
            axios.post(`${self.url}SocialSecurity/UpdateSocialSecurity`,socialuser)
            .then(function (response) {
              if (response.data.status == 0)
              {
                alert(response.data.message);
                self.LoadingDialog = false;
                self.disablessn = true;
                self.disabledate = true;
                self.disablehospital = true;
                self.showedit = true;
                self.showbtn1 = false;
                self.GetSS();
                //self.LogTrace(null, "Update Social Security", 16, "Medium");
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update Social Security Fail",
                16,
                "Medium"
              );
              alert(error);
              self.LoadingDialog = false;
            });
          }
        }
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    UpdatesocialsecurityWithCode() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let ssNvali = self.validateDigit(self.social.ssN);
        if (
          self.social.comdate == "" &&
          self.social.hospital == null &&
          (self.social.ssN == null || self.social.ssN == "")
        )
        {
          self.LoadingDialog = false;
        }
        else if (
          self.social.comdate != null ||
          self.social.comdate != "" ||
          self.social.ssN != null ||
          self.social.ssN != "" ||
          self.social.hospital != null ||
          self.social.hospital != ""
        ) {
          let move = false;
          if (self.social.ssN != null && self.social.ssN != "") {
            if (ssNvali == true) {
              move = true;
            }
            else
            {
              move = false;
              alert("please fill the correct format");
              this.error.errormessagessN =
                this.social.ssN != ssNvali
                  ? "Please fill only number and 13 digits"
                  : this.social.ssN;
            }
          }
          else
          {
            move = true;
          }
          if (self.social.hospital == null) {
            self.social.hospital = 0;
          }
          if (move) {
            let socialuser = {
              s_ID: self.social.sid,
              sS_Num: self.social.ssN,
              hospital_ID: self.social.hospital,
              // effective_Date: new Date(self.social.comdate),
              effective_Date: self.$moment(self.social.comdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              employee_Code: localStorage.empcode,
              company_ID: store.state.companyID,
            };
            axios.post(`${self.url}SocialSecurity/UpdateSocialSecurityWithCode`,socialuser)
            .then(function (response) {
              if (response.data.status == 0)
              {
                alert(response.data.message);
                self.LoadingDialog = false;
                self.GetSSWithCode();
                self.disablessn = true;
                self.disabledate = true;
                self.disablehospital = true;
                self.showedit = true;
                self.showbtn1 = false;
                //self.LogTrace(null, "Update Social Security", 16, "Medium");
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update Social Security Fail",
                16,
                "Medium"
              );
              alert(error);
              self.LoadingDialog = false;
            });
          }
        }
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    UpdatesocialsecurityWithSid() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let ssNvali = self.validateDigit(self.social.ssN);
        if (
          self.social.comdate == "" &&
          self.social.hospital == null &&
          (self.social.ssN == null || self.social.ssN == "")
        )
        {
          self.LoadingDialog = false;
        }
        else if (
          self.social.comdate != null ||
          self.social.comdate != "" ||
          self.social.ssN != null ||
          self.social.ssN != "" ||
          self.social.hospital != null ||
          self.social.hospital != ""
        )
        {
          let move = false;
          if (self.social.ssN != null && self.social.ssN != "")
          {
            if (ssNvali)
            {
              move = true;
            }
            else
            {
              move = false;
              alert("please fill the correct format");
              this.error.errormessagessN =
                this.social.ssN != ssNvali
                  ? "Please fill only number and 13 digits"
                  : this.social.ssN;
            }
          }
          else
          {
            move = true;
          }
          if (self.social.hospital == null)
          {
            self.social.hospital = 0;
          }
          if (move)
          {
            let socialuser = {
              s_ID: self.social.sid,
              sS_Num: self.social.ssN,
              hospital_ID: self.social.hospital,
              // effective_Date: new Date(self.social.comdate),
              effective_Date: self.$moment(self.social.comdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              employee_ID: store.state.employeeID,
              company_ID: store.state.companyID,
            };
            axios.post(`${self.url}SocialSecurity/UpdateSocialSecurity`,socialuser)
            .then(function (response) {
              if (response.data.status == 0)
              {
                alert(response.data.message);
                self.LoadingDialog = false;
                self.GetSSWithSID();
                self.disablessn = true;
                self.disabledate = true;
                self.disablehospital = true;
                self.showedit = true;
                self.showbtn1 = false;
                //self.LogTrace(null, "Update Social Security", 16, "Medium");
              }
            })
            .catch(function (error) {
                self.LogTrace(
                  error,
                  "Update Social Security Fail",
                  16,
                  "Medium"
                );
                alert(error);
                self.LoadingDialog = false;
              });
          }
        }
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    OpenSocialAdddialog() {
      let self = this;
      self.SocialAdddialog = true;
      let id = self.social.hospital;
      if (id)
      {
        const master = self.HospitalList.find((x) => x.hospital_ID == id);
        self.addhistory.hospital = master.hospital_Name;
        self.addhistory.comdate = self.social.comdate;
        self.confirmId = id;
      }
    },

    //add with id
    AddSSHistory() {
      let self = this;
      self.error.errormessagehosp = !self.addhistory.hospital
        ? "Hospital is required"
        : "";
      self.error.errorMsgEff_Date = !self.addhistory.comdate
        ? "Date Of Change is required"
        : "";
      self.error.errormessagereas = !self.addhistory.reason
        ? "Reason is required"
        : "";
      if (
        self.addhistory.hospital != null &&
        self.addhistory.hospital != "" &&
        self.addhistory.comdate != null &&
        self.addhistory.comdate != "" &&
        self.addhistory.reason != null &&
        self.addhistory.reason != ""
      )
      {
        let thecdate = self.$moment(self.addhistory.comdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
        let tempadd = {
          hospital_ID: self.confirmId,
          reason: self.addhistory.reason, 
          compensation_Date: thecdate,
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        self.LoadingDialog = true;
        axios.post(`${self.url}SocialSecurityHistory/AddSocialSecurityHistory`,tempadd)
        .then(function (response) {
          if (response.data.status == 0)
          { 
            alert(response.data.message);
            self.addhistory.hospital = null;
            self.addhistory.comdate = new Date().toISOString().substr(0, 10);
            self.addhistory.comdate = "";
            self.addhistory.reason = null;
            self.LoadingDialog = false;
            self.SocialAdddialog = false;
            self.GetHistory();
            //self.LogTrace(null, "Add Security History", 16, "Low");
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Social Security Fail", 16, "Low");
          alert(error);
          self.LoadingDialog = false;
        });
      }
      else
      {
        if (
          self.addhistory.hospital == null ||
          self.addhistory.hospital == "" ||
          self.addhistory.comdate == null ||
          self.addhistory.comdate == "" ||
          self.addhistory.reason == null ||
          self.addhistory.reason == ""
        )
        {
          alert("Please fill in the required fields");
          self.LoadingDialog = false;
        }
      }
    },

    //add with code
    AddSSHistoryWithCode() {
      try {
        let self = this;
        self.LoadingDialog = true;
        self.error.errormessagehosp = !self.addhistory.hospital
          ? "Please fill in the required fields"
          : "";
        self.error.errorMsgEff_Date = !self.addhistory.comdate
          ? "Please fill in the required fields"
          : "";
        self.error.errormessagereas = !self.addhistory.reason
          ? "Please fill in the required fields"
          : "";
        if (
          self.addhistory.hospital != null &&
          self.addhistory.hospital != "" &&
          self.addhistory.comdate != null &&
          self.addhistory.comdate != "" &&
          self.addhistory.reason != null &&
          self.addhistory.reason != ""
        )
        {
          let thecdate = self.$moment(self.addhistory.comdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
          let tempadd = {
            hospital_ID: self.confirmId,
            reason: self.addhistory.reason,
            compensation_Date: thecdate,
            employee_Code: localStorage.empcode,
            company_ID: store.state.companyID,
          };
          axios.post(`${self.url}SocialSecurityHistory/AddSocialSecurityHistoryWithCode`,tempadd)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.addhistory.hospital = null;
              self.addhistory.comdate = new Date()
                .toISOString()
                .substr(0, 10);
              self.addhistory.comdate = "";
              self.addhistory.reason = null;
              self.LoadingDialog = false;
              self.SocialAdddialog = false;
              self.GetHistoryWithCode();
              //self.LogTrace(null, "Add Social Security History", 16, "Low");
            }
          })
          .catch(function (error) {
              self.LogTrace(
                error,
                "Add Social Security History Fail",
                16,
                "Low"
              );
              alert(error);
              self.LoadingDialog = false;
            });
        }
        else
        {
          if (
            self.addhistory.hospital == null ||
            self.addhistory.hospital == "" ||
            self.addhistory.comdate == null ||
            self.addhistory.comdate == "" ||
            self.addhistory.reason == null ||
            self.addhistory.reason == ""
          )
          {
            alert("Please fill in the required fields");
            self.LoadingDialog = false;
          }
        }
        self.LoadingDialog = false;
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    //add with session id
    AddSSHistoryWithSid() {
      try {
        let self = this;
        self.LoadingDialog = true;
        self.error.errormessagehosp = !self.addhistory.hospital
          ? "Please fill in the required fields"
          : "";
        self.error.errorMsgEff_Date = !self.addhistory.comdate
          ? "Please fill in the required fields"
          : "";
        self.error.errormessagereas = !self.addhistory.reason
          ? "Please fill in the required fields"
          : "";
        if (
          self.addhistory.hospital != null &&
          self.addhistory.hospital != "" &&
          self.addhistory.comdate != null &&
          self.addhistory.comdate != "" &&
          self.addhistory.reason != null &&
          self.addhistory.reason != ""
        )
        {
          let thecdate = self.$moment(self.addhistory.comdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
          let tempadd = {
            hospital_ID: self.confirmId,
            reason: self.addhistory.reason,
            compensation_Date: thecdate,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
          };
          axios.post(`${self.url}SocialSecurityHistory/AddSocialSecurityHistory`,tempadd)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.addhistory.hospital = null;
              self.addhistory.comdate = new Date()
                .toISOString()
                .substr(0, 10);
              self.addhistory.comdate = "";
              self.addhistory.reason = null;
              self.LoadingDialog = false;
              self.SocialAdddialog = false;
              self.GetHistoryWithSID();
              //self.LogTrace(null, "Add Social Security History", 16, "Low");
            }
          })
          .catch(function (error) {
              self.LogTrace(
                error,
                "Add Social Security History Fail",
                16,
                "Low"
              );
              alert(error);
              self.LoadingDialog = false;
            });
        }
        else
        {
          if (
            self.addhistory.hospital == null ||
            self.addhistory.hospital == "" ||
            self.addhistory.comdate == null ||
            self.addhistory.comdate == "" ||
            self.addhistory.reason == null ||
            self.addhistory.reason == ""
          )
          {
            alert("Please fill in the required fields");
            self.LoadingDialog = false;
          }
        }
        self.LoadingDialog = false;
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return `${finaldate[2]}/${finaldate[1]}/${finaldate[0]}`;
    },
    // setFormattedDate(date) {
    // this.computedDateFormatted4 = this.formatDate(date);
    // }

    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style scoped>
>>> .v-dialog::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}
>>> .v-dialog::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
}
>>> .v-select__selections {
  padding-left: unset !important;
}
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f89d1f !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 414px) {
  .save_btn {
    margin-top: 1rem !important;
  }
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 16px;
  font-weight: normal;
  color: #f99d20;
}

@media (max-height: 570px) and (max-width: 330px) {
  .btnForSave {
    margin: 0px;
    padding: 0px;
  }
}

@media (min-width: 370px) and (min-height: 660px) {
  .btnForSave {
    margin-left: 30px;
  }
}

@media (max-width: 420px) and (min-height: 730px) {
  .btnForSave {
    margin-left: 55px;
  }
}

.custom-label{
  font-size: 12px;
  margin-top: -5px;
}
</style>
