<template style="background:#F0F0F7">
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <p style="font-size: 25px; margin-top: 20px; color: #43425d">
      {{ $t("MyTimeOffRequest") }}
    </p>
    <div class="mt-6" style="height: auto">
      <v-card min-height="60vh" class="pt-5 mb-5" style="margin-bottom: 5rem">
        <div style="margin-left: 2%; margin-right: 2%">
          <br />
          <div>
            <v-row class="flex flex-wrap">
              <v-col class="mt-1" cols="12" xs="12" sm="12" md="5" lg="4" xl="2">
              <v-row>
                  <p style="margin-left: 10px; margin-top:0px">{{ $t("Year") }}</p>
                  <p class="lessleft">{{ $t("Month") }}</p>
              </v-row>
              <div class="mr-8 d-flex" style="background-color: #f99d20;border-radius: 5px;margin-left: 0px;max-width: 250px;">
                <date-picker  v-model="todayyears" type="year" color="#0FA7D8" class="
                    selectboxs
                    minizebox
                    ml-1
                    mr-1
                    mt-1
                    mb-1
                    pl-0
                    today_years" v-on:change="ChangeYear(todayyears)">
                  <template slot="icon-calendar">
                    <img src="@/assets/images/down1@2x.png" style="width: 30px;height: 15px;margin-top: 4px;margin-right: -6px;" class="pl-2" />
                  </template>
                </date-picker>
              <v-select class="selectboxs minizebox monthbox ml-0 mt-1 mr-1 mb-1 pl-1" v-model="todaymonths"
                height="34px" single-line :items="monthNames" :item-text="(item) => item.txt"
                :item-value="(item) => item.val" background-color="#FFFFFF" color="#f99d20" return-object persistent-hint
                v-on:change="ChangeMonth(`${todaymonths}`)"></v-select>
              </div>
              </v-col>
              <!-- start date -->
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">

                <p class="mb-0">{{ $t("startdate") }} :</p>
                <date-picker v-bind:clearable="false" format="DD/MM/YYYY" placeholder="DD/MM/YYYY"
                  :style="{ width: '100%' }" readonly color="#F99D20" v-model="FilterStartDate" @change="ChangeMonth">
                  <template slot="icon-calendar">
                    <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                  </template>
                </date-picker>
              </v-col> -->

              <!-- end date -->
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">

                <p class="mb-0">{{ $t("enddate") }} :</p>
                <date-picker v-bind:clearable="false" format="DD/MM/YYYY" placeholder="DD/MM/YYYY"
                  :style="{ width: '100%' }" v-model="FilterEndDate" readonly @change="ChangeMonth">
                  <template slot="icon-calendar">
                    <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                  </template>
                </date-picker>
              </v-col> -->

              <!-- Leave type -->
              <v-col cols="12" xs="12" sm="6" md="3" lg="2" xl="2" style="margin-top:-0.2rem;">

                <p class="mb-0">{{ $t("leavetype") }}</p>
                <v-select v-model="leaveType" :items="LeaveList" @change="changeType" outlined item-value="timeOff_ID"
                  dense color="#F99D20">
                  <template v-slot:selection="data">
                    {{ thethaihead == true ? data.item.timeoff_TypeTH : data.item.timeoff_TypeEN }}
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{ thethaihead == true ? data.item.timeoff_TypeTH : data.item.timeoff_TypeEN
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-col>

              <!-- status -->
              <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="2" style="margin-top:-0.2rem;">

                <p class="mb-0">{{ $t("status") }}</p>
                <v-select v-model="itemInner" :items="status" @change="changestatus" outlined item-text="txt"
                  item-value="val" dense color="#F99D20"></v-select>
              </v-col>
              <v-spacer v-if="!$vuetify.breakpoint.xs" />
              <!-- time off request -->
              <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="1">
                <v-btn color="#F99D20" class="white--text mr-5 text-capitalize mt-5" block max-height="35"
                  :disabled="permission.add" @click="NewTimeOffRequest()">{{ $t("leaverequest") }}</v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="mt-3">
            <v-data-table :headers="headers" :items="theshowstaffList" hide-default-footer hide-default-header
              :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event" :mobile-breakpoint="0">
              <template v-slot:header="{ props: { } }">
                <thead>
                  <tr>
                    <th>{{ $t("requestedDate") }}</th>
                    <th>{{ $t("leavetype") }}</th>
                    <th>{{ $t("date") }}</th>
                    <th>{{ $t("used") }} (-)</th>
                    <th>{{ $t("FrontBalance") }}</th>
                    <th class="text-center">{{ $t("status") }}</th>
                    <th class="text-center">{{ $t("Supervisor") }}</th>
                    <th class="pl-6 text-center">{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.status != 'Draft Request' ? convertUTCDateToLocalDate(item.request_On) : "-" }}</td>
                  <td v-show="!thethaihead">{{ item.timeoff_Name }}</td>
                  <td v-show="thethaihead">{{ item.timeoff_NameTH }}</td>
                  <td>{{ formatDate(item.start_Date) }} - {{
                    item.wholeDay
                    ? formatDate(item.end_Date)
                    : formatDate(item.start_Date)
                  }}</td>
                  <td>{{ "- " + item.usedminus + " " + "Days" }}</td>
                  <td>{{ item.balance + " " + "Days" }}</td>
                  <td :style="{
                    color:
                      item.status == 'Approved'
                        ? '#00C092'
                        : item.status == 'Waiting Approval'
                          ? '#5B9BD5'
                          : item.status == 'Draft Request'
                            ? '#595959'
                            : item.status == 'Denied'
                            ? '#FFFFFF'
                            : item.status == 'Canceled'
                              ? '#FF0000'
                              : '#00C092',
                  }" style="text-align: center">
                    <div :style="{
                      backgroundColor:
                        item.status == 'Approved'
                          ? '#CCF3E9'
                          : item.status == 'Waiting Approval'
                            ? '#DAE3F3'
                            : item.status == 'Draft Request'
                              ? '#D0CECE'
                              : item.status == 'Denied'
                              ? '#7F7F7F'
                              : item.status == 'Canceled'
                                ? '#FFCCCC'
                                : '#CCF3E9',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                    }">
                      {{
                        item.status == "Approved"
                        ? $t("approved")
                        : item.status == "Denied"
                          ? $t("denied")
                          : item.status == "Draft Request"
                            ? $t("Draftreq")
                            : item.status == "Canceled"
                              ? $t("Cancelled")
                              : item.status == "Manual"
                                ? $t("approved")
                                : $t("waitingApproval")
                      }}
                    </div>
                  </td>
                  <td class="text-center">{{ item.approver_Name }}</td>
                  <td style="text-align: center">
                    <v-btn icon class="hover-effect" :disabled="permission.add" @click="ViewTimeOffRequest(item)" @mouseover="item.hovering = true" @mouseout="item.hovering = false">
                      <!-- <span class="tooltip text-capitalize">{{ $t("view") }}</span> -->
                      <v-icon :color="item.hovering ? '#F99D20' : '#A3A7AA'">mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div class="text-right" v-if="theshowstaffList.length">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
              </v-col>
              <v-col cols="12" md="2">
                <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                  :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    <div>  
        
        <v-dialog v-model="editTimeOFf" persistent max-width="600px">
          <v-card>
            <v-container class="pl-11">
              <div class="d-flex mt-3">
                <p class="mt-3" style="font-size: 22px; color: #444444">
                  {{ $t("TimeOffRequest") }}
                </p>

                <v-spacer></v-spacer>
                <div>
                  <v-btn @click="editDiaglog()" icon>
                    <v-icon>close </v-icon>
                  </v-btn>
                </div>
              </div>

              <v-row no-gutters class="mt-7">
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                  {{ $t("TypeOfTimeOff")
                  }}<span class="red--text"> *</span></v-col>

                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-autocomplete outlined dense :disabled="notEditing" :items="timeTypeList"
                    @input="(v) => onInputHandler(v, timeTypeList)" item-value="timeoff_ID"
                    :error-messages="error.timeoff_ID" v-model="viewTimeOff.timeoff_ID"
                    :item-text="(item) => ` ${item.timeoff_Type}`" color="#F99D20"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                  {{ $t("DirectSupervisor")
                  }}<span class="red--text"> *</span></v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="notEditing" v-model="viewTimeOff.approver_Name" outlined readonly dense
                    color="#F99D20"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <div>
                  <v-checkbox class="custom-checkbox mr-3" color="#F99D20" v-model="viewTimeOff.wholeDay" :disabled="notEditing"></v-checkbox>
                  <!-- <input class="mr-3" :disabled="notEditing" v-model="viewTimeOff.wholeDay" id="allday" type="checkbox"
                    style="margin-top: 0rem !important" /> -->
                  <label for="allday"> {{ $t("Allday") }}</label>
                </div>
              </v-row>
              <div v-show="!viewTimeOff.wholeDay">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("date") }}
                   <span class="red--text"> *</span></v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <!-- <v-menu v-model="forDate" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                      max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" v-model="computedDateFormated" class="kanit-medium" outlined
                          :disabled="notEditing" :error-messages="error.start_Date" dense color="#F99D20" persistent-hint
                          append-icon="mdi-calendar-month-outline" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="viewTimeOff.start_Date" no-title @input="forDate = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" :editable="false" v-model="viewTimeOff.start_Date"
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("StartTime") }}
                    <span class="red--text"> *</span></v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" v-model="viewTimeOff.start_Time"
                          :error-messages="error.start_Time" append-icon="access_time" outlined :disabled="notEditing"
                          dense color="#F99D20" readonly v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="menu2" v-model="viewTimeOff.start_Time" no-title fluid
                        @click:minute="$refs.menu2.save(viewTimeOff.start_Time)"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("EndTime") }}
                    <span class="red--text"> *</span></v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" v-model="viewTimeOff.end_Time" append-icon="access_time"
                          :error-messages="error.end_Time" outlined :disabled="notEditing" dense color="#F99D20" readonly
                          v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="menu" v-model="viewTimeOff.end_Time" no-title fluid
                        @click:minute="$refs.menu.save(end_Time)"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>

              <div v-show="viewTimeOff.wholeDay">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("startDate")
                    }}<span class="red--text"> *</span></v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <!-- <v-menu v-model="forDateStart" :close-on-content-click="false" transition="scale-transition" offset-y
                      fluid max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" v-model="computedDateFormatedStart" class="kanit-medium" outlined
                          :error-messages="error.start_Date" dense readonly :disabled="notEditing" color="#F99D20"
                          persistent-hint append-icon="mdi-calendar-month-outline" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="viewTimeOff.start_Date" no-title @input="forDateStart = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" :editable="false" value-type="format" v-model="viewTimeOff.start_Date"
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("enddata") }}<span class="red--text"> *</span></v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <!-- <v-menu v-model="forEndDate" :close-on-content-click="false" transition="scale-transition" offset-y
                      fluid max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" v-model="computedDateFormated2" class="kanit-medium" outlined
                          :disabled="notEditing" :error-messages="error.end_Date" dense readonly color="#F99D20"
                          persistent-hint append-icon="mdi-calendar-month-outline" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="viewTimeOff.end_Date" no-title @input="forEndDate = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" :editable="false" v-model="viewTimeOff.end_Date"
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
              </div>

              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                  {{ $t("Message") }}<span class="red--text"> *</span></v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="notEditing" v-model="viewTimeOff.message" :error-messages="error.message"
                    outlined dense color="#F99D20"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                  {{ $t("status") }}<span class="red--text"> *</span></v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="notEditing" outlined dense readonly :error-messages="error.status"
                    color="#F99D20" v-model="viewTimeOff.status"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                  {{ $t("FrontBalance")
                  }}<span class="red--text"> *</span></v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="notEditing" outlined dense readonly color="#F99D20"
                    v-model="viewTimeOff.balance"></v-text-field>
                </v-col>
              </v-row>
              <div v-if="viewTimeOff.status != 'Approved'" v-show="viewTimeOff.saveDraft">
                <div class="ml-11 mr-11">
                  <v-row>
                    <v-col cols="12" xs="12" sm="4" md="4" v-show="isEdit">
                      <v-btn color="#F99D20" class="white--text mr-5 text-capitalize btnres" block max-height="35"
                        @click="EditGone()" :disabled="permission.edit">
                        {{ $t("edit") }}</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4" v-show="!isEdit">
                      <v-btn color="#F99D20" class="white--text mr-5 text-capitalize btnres" block max-height="35"
                        :disabled="permission.add" @click="SaveGone()">
                        {{ $t("save") }}</v-btn>
                    </v-col>

                    <v-col cols="12" xs="12" sm="4" md="4">
                      <v-btn color="#F99D20" class="white--text mr-5 text-capitalize btnres" block max-height="35"
                        :disabled="sendReq || permission.add" @click="sendRequest()">
                        {{ $t("sendRequest") }}</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4">
                      <v-btn color="#FF0000" class="white--text mr-5 text-capitalize" block max-height="35"
                        :disabled="cancelReq || permission.delete" @click="
                          cancelingRequest(viewTimeOff.time_off_RequestID)
                          ">{{ $t("cancelRequest") }}</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row justify="center" v-show="!viewTimeOff.saveDraft">
                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-btn :disabled="permission.delete" color="#FF0000" class="white--text mr-5 text-capitalize" block
                    max-height="35" v-if="viewTimeOff.status != 'Approved' &&
                      viewTimeOff.status != 'Denied' &&
                      viewTimeOff.status != 'Manual'
                      " @click="cancelingRequest(viewTimeOff.time_off_RequestID)">
                    {{ $t("cancelRequest") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <!-- delete  dialog -->
        <v-dialog v-model="Deletedialog" persistent max-width="532">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <p style="margin-top: 10px; margin-bottom: -30px">
                {{ $t("ConfirmToCancelRequest") }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col align="center" cols="12">
                    <p style="font-size: 16px; color: #444444" class="pt-4">
                      {{ $t("DoYouWantToCancelThisRequest") }}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions style="margin-right: 3%" class="pb-6">
              <v-spacer></v-spacer>
              <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="DeleteCancel" text>
                {{ $t("cancel") }}</v-btn>
              <v-btn color="#FF2727" class="mr-2 white--text text-capitalize" width="120" max-height="35"
                @click="DeleteRow()">
                {{ $t("confirm") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="LoadingDialog" persistent width="300">
          <v-card color="#FFF4EB" dark>
            <v-card-text class="black--text">
              Loading Please Wait...
              <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="TimeOffDialog" persistent max-width="600px">
          <v-card style="border-radius: 15px">
            <v-container class="pl-11">
              <div class="d-flex mt-3">
                <p class="mt-3" style="font-size: 22px; color: #444444">
                  {{ $t("TimeOffRequest") }}
                </p>

                <v-spacer></v-spacer>
                <div>
                  <v-btn @click="closeNoti" icon>
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-row no-gutters class="mt-7">
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">{{ $t("TypeOfTimeOff")
                }}</v-col>

                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="true" outlined dense v-model="TimeOffDetail.timeoff_Name"
                    color="#F99D20"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">{{ $t("DirectSupervisor")
                }}</v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="true" v-model="TimeOffDetail.approver_Name" outlined readonly dense
                    color="#F99D20"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <div>
                  <v-checkbox class="custom-checkbox mb-6 mr-3" color="#F99D20" v-model="TimeOffDetail.wholeDay" :disabled="true"></v-checkbox>
                  <!-- <input :disabled="true" class="mb-6 mr-3" v-model="TimeOffDetail.wholeDay" id="allday" type="checkbox"
                    style="margin-top: 0rem !important" /> -->
                  <label for="allday">{{ $t("Allday") }}</label>
                </div>
              </v-row>
              <div v-show="!TimeOffDetail.wholeDay">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">{{ $t("date") }}</v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field :disabled="true" v-model="computedDateFormatted3" autocomplete="off"
                      class="kanit-medium" outlined dense color="#F99D20" persistent-hint
                      append-icon="mdi-calendar-month-outline"></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("StartTime") }}
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field :disabled="true" autocomplete="off" v-model="TimeOffDetail.start_Time"
                      append-icon="access_time" outlined dense color="#F99D20" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">{{ $t("EndTime") }}</v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field :disabled="true" autocomplete="off" v-model="TimeOffDetail.end_Time"
                      append-icon="access_time" outlined dense color="#F99D20" readonly></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-show="TimeOffDetail.wholeDay">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("startDate") }}
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field :disabled="true" v-model="computedDateFormatted3" autocomplete="off"
                      class="kanit-medium" outlined dense color="#F99D20" persistent-hint
                      append-icon="mdi-calendar-month-outline"></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                    {{ $t("enddata") }}
                  </v-col>
                  <v-col cols="12" xs="12" sm="7" md="7">
                    <v-text-field v-model="computedDateFormatted4" :disabled="true" autocomplete="off"
                      class="kanit-medium" outlined dense color="#F99D20" persistent-hint
                      append-icon="mdi-calendar-month-outline"></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">{{ $t("Message") }}</v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="true" v-model="TimeOffDetail.message" outlined dense
                    color="#F99D20"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">{{ $t("status") }}</v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="true" outlined dense readonly color="#F99D20"
                    v-model="TimeOffDetail.status"></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="4" md="4" class="mt-2" style="font-size: 14px">
                  {{ $t("FrontBalance") }}
                </v-col>
                <v-col cols="12" xs="12" sm="7" md="7">
                  <v-text-field :disabled="true" outlined dense readonly color="#F99D20"
                    v-model="TimeOffDetail.balance"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        
        <v-dialog v-model="TODdialog" persistent max-width="1000px">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <span class="mt-3 ml-3" style="font-size: 23px; color: #444444">
                {{ $t("leaverequestdetail") }}
              </span>
              <v-spacer></v-spacer>
              <v-btn @click="hidedetaildialog()" icon>
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-4">
              <!-- <hr /> -->
              <v-row class="mt-3">
                <!-- Requested Date -->
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("requestedDate") }}:</v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <v-text-field v-model="viewTimeOff.request_Ontxt" height="10px" color="#F99D20" single-line outlined
                        class="readonly" dense readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Status -->
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("status") }} :
                    </v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <v-row class="mt-1">
                        <div :style="{
                          maxWidth: '150px',
                          height: '40px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          textAlign: 'center',
                          color:
                            viewTimeOff.status == 'Approved' || viewTimeOff.status == 'Manual'
                              ? '#00C092'
                              : viewTimeOff.status == 'Waiting Approval'
                                ? '#5B9BD5'
                                : viewTimeOff.status == 'Draft Request'
                                  ? '#595959'
                                  : viewTimeOff.status == 'Denied'
                                  ? '#FFFFFF'
                                  : viewTimeOff.status == 'Canceled'
                                    ? '#FF0000'
                                    : '#000000',
                          backgroundColor:
                            viewTimeOff.status == 'Approved' || viewTimeOff.status == 'Manual'
                              ? '#CCF3E9'
                              : viewTimeOff.status == 'Waiting Approval'
                                ? '#DAE3F3'
                                : viewTimeOff.status == 'Draft Request'
                                  ? '#D0CECE'
                                  : viewTimeOff.status == 'Denied'
                                  ? '#7F7F7F'
                                  : viewTimeOff.status == 'Canceled'
                                    ? '#FFCCCC'
                                    : '#7F7F7F',
                          textAlign: 'center',
                          borderRadius: '20px',
                          padding: '5px',
                        }">
                          {{
                            viewTimeOff.status == "Approved" || viewTimeOff.status == 'Manual'
                            ? $t("approved")
                            : viewTimeOff.status == "Denied"
                              ? $t("denied")
                              : viewTimeOff.status == "Draft Request"
                                ? $t("Draftreq")
                                : viewTimeOff.status == "Canceled"
                                  ? $t("Canceled")
                                  : $t("waitingApproval")
                          }}
                        </div>
                        <v-btn v-if="viewTimeOff.status == 'Draft Request'" color="#F99D20"
                          class="text-capitalize white--text ml-5" width="90" max-height="35" @click="showdetaildata">
                          {{ $t("edit") }}</v-btn>
                        <!-- <v-btn
                      @click="showcancel = true"
                      v-if="
                        viewTimeOff.status == 'Approved' && cancelstatus != true
                      "
                      style="text-decoration: underline"
                      text
                      class="text-capitalize ml-4"
                      >{{ $t("Cancel") }}</v-btn
                    > -->
                      </v-row>
                      <!-- <div
                    v-if="cancelstatus == true"
                    style="
                      background-color: #ffcccc;
                      width: 100px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      border-radius: 20px;
                      padding: 5px;
                      margin-top: 5px;
                    "
                  >
                    <p class="mb-0" style="color: #ff0000">
                      {{ $t("Cancelled") }}
                    </p>
                  </div> -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" class="pt-0">
                      {{ $t("EmployeeName") }} :
                    </v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <v-text-field v-model="viewTimeOff.employee_Name" height="10px" color="#F99D20" single-line outlined
                        class="readonly" readonly dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("position") }} :
                    </v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <v-text-field v-model="viewTimeOff.postion_Name" height="10px" color="#F99D20" single-line outlined
                        dense class="readonly" readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("leavetype") }} :
                    </v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <!-- <v-text-field v-model="viewTimeOff.timeoff_Name" height="10px" color="#F99D20" single-line outlined
                        class="readonly" readonly dense></v-text-field> -->
                      <v-select v-model="viewTimeOff.timeoff_ID" :items="LeaveList1" outlined
                        :item-text="(item) => thethaihead == true ? `${item.timeoff_TypeTH}` : `${item.timeoff_TypeEN}`"
                        item-value="timeOff_ID" dense :disabled="disableviewdata" :class="disableviewdata ? 'readonly' : 'unread'" color="#F99D20">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                  <v-row class="ml-1" style="margin-top: 0.2rem">
                    <span v-if="viewTimeOff.wholeDay">{{ $t("Allday") }}</span>
                    <span v-if="!viewTimeOff.wholeDay">{{ $t("halfDay") }}</span>
                    <v-checkbox class="custom-checkbox" style="margin-top: -0.5rem; margin-left: 4rem" color="#F99D20" v-model="viewTimeOff.wholeDay" hide-details :disabled="disableviewdata"></v-checkbox>
                    <!-- <v-checkbox v-model="viewTimeOff.wholeDay" color="orange" hide-details :disabled="disableviewdata"
                      style="margin-top: -0.5rem; margin-left: 4rem"></v-checkbox> -->
                  </v-row>
                </v-col>
              </v-row>
              <div v-show="!viewTimeOff.wholeDay">
                <v-row class="mt-3">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                    <v-row>
                      <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("date") }} :
                      </v-col>
                      <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                        <!-- <v-menu v-model="forDate" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="computedDateFormated" class="kanit-medium" outlined
                              :disabled="disableviewdata" :error-messages="error.start_Date" :class="disableviewdata ? 'readonly' : 'unread'" dense color="#F99D20"
                              persistent-hint append-icon="mdi-calendar-month-outline" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="viewTimeOff.start_Date" no-title
                            @input="forDate = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker1" :editable="false" v-bind:clearable="false" value-type="format" v-model="viewTimeOff.start_Date"
                        :disabled="disableviewdata" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="mt-10">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                    <v-row>
                      <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("StartTime") }} :
                      </v-col>
                      <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                          :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="viewTimeOff.start_Time"
                              :error-messages="error.start_Time" :class="disableviewdata ? 'readonly' : 'unread'" append-icon="access_time" outlined
                              :disabled="disableviewdata" dense color="#F99D20" readonly v-on="on"></v-text-field>
                          </template>
                          <v-time-picker v-if="menu2" v-model="viewTimeOff.start_Time" no-title fluid
                            @click:minute="$refs.menu2.save(viewTimeOff.start_Time)"></v-time-picker>
                        </v-menu>
                        <!-- <v-text-field
                      v-model="viewTimeOff.start_Time"
                      height="10px"
                      color="#F99D20"
                      single-line
                      outlined
                      class="readonly"
                      readonly
                      dense
                    ></v-text-field> -->
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                    <v-row>
                      <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("EndTime") }} :
                      </v-col>
                      <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                          :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="viewTimeOff.end_Time" append-icon="access_time"
                              :error-messages="error.end_Time" outlined :class="disableviewdata ? 'readonly' : 'unread'" :disabled="disableviewdata" dense color="#F99D20"
                              readonly v-on="on"></v-text-field>
                          </template>
                          <v-time-picker v-if="menu" v-model="viewTimeOff.end_Time" no-title fluid
                            @click:minute="$refs.menu.save(end_Time)"></v-time-picker>
                        </v-menu>
                        <!-- <v-text-field
                      v-model="viewTimeOff.end_Time"
                      height="10px"
                      color="#F99D20"
                      single-line
                      outlined
                      dense
                      class="readonly"
                      readonly
                    ></v-text-field> -->
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-show="viewTimeOff.wholeDay">
                <v-row class="mt-3">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                    <v-row>
                      <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("startDate") }} :
                      </v-col>
                      <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                        <!-- <v-menu v-model="forDateStart" :close-on-content-click="false" transition="scale-transition"
                          offset-y fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="computedDateFormatedStart" class="kanit-medium"
                              outlined :error-messages="error.start_Date" :class="disableviewdata ? 'readonly' : 'unread'" dense readonly :disabled="disableviewdata"
                              color="#F99D20" persistent-hint append-icon="mdi-calendar-month-outline"
                              v-on="on"></v-text-field>
                          </template>

                          <v-date-picker v-model="viewTimeOff.start_Date" no-title
                            @input="forDateStart = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker1" :editable="false" v-bind:clearable="false" value-type="format" v-model="viewTimeOff.start_Date"
                        :disabled="disableviewdata" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                    <v-row>
                      <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("enddate") }} :
                      </v-col>
                      <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                        <!-- <v-menu v-model="forEndDate" :close-on-content-click="false" transition="scale-transition"
                          offset-y fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="computedDateFormated2" class="kanit-medium" outlined
                              :disabled="disableviewdata" :error-messages="error.end_Date" 
                              :class="disableviewdata ? 'readonly' : 'unread'" dense readonly color="#F99D20"
                              persistent-hint append-icon="mdi-calendar-month-outline" v-on="on"></v-text-field>
                          </template>

                          <v-date-picker v-model="viewTimeOff.end_Date" no-title
                            @input="forEndDate = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker1" :editable="false" v-bind:clearable="false" value-type="format" v-model="viewTimeOff.end_Date"
                        :disabled="disableviewdata" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-row class="mt-5">
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2" class="pb-0">
                  {{ $t("DescriptionOT") }}
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10" class="pb-0">
                  <div :style="{
                        width: $vuetify.breakpoint.lgAndUp
                          ? '823px'
                          : $vuetify.breakpoint.md
                            ? '800px'
                            : '',
                      }">
                    <v-textarea v-model="viewTimeOff.message" solo no-resize rows="3" min-height="80" name="input-7-4" color="#F99D20"
                      :disabled="disableviewdata" :error-messages="error.message" outlined :class="disableviewdata ? 'readonly' : 'unread'" :style="$vuetify.breakpoint.mdAndUp
                        ? 'caret-color: orange !important;margin-left:-2.5rem;'
                        : 'caret-color: orange !important'
                        ">
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" /> <span>
                          {{ $t("PleaseSpecify") }}
                        </span>
                      </template></v-textarea>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2" class="pb-0 pt-0">
                  {{ $t("AttachmentOT") }}</v-col>
                <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10" class="pb-0 pt-0">
                  <div class="mr-0 pa-3 relative" v-show="!Editstate" v-if="viewTimeOff.attachmentList.length === 0"
                    :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''
                      " style="
                  width: 130px;
                  height: 120px;
                  border: 1px solid #ccc;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 7px;
                ">
                    <v-img class="mb-3 mt-2" src="@/assets/images/none.png" width="60px"></v-img>
                    <div class="text-center">None</div>
                  </div>
                  <div class="container" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''
                    ">
                    <div style="width: 130px;height:120px;" v-show="Editstate" @click="onpickfile" class="drop-area"
                      @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                      <div style="text-align: center" class="mt-2">
                        <v-icon style="color:#A3A7AA">mdi-cloud-upload</v-icon>
                        <p style="color:#A3A7AA">{{ $t("choosefile") }}</p>
                      </div>
                    </div>
                    <div v-for="file in viewTimeOff.attachmentList" :key="file.filename" class="mr-0 pa-3 relative" style="
                    width: 130px;
                    height: 120px;
                    border: 1px solid #ccc;
                    border-radius: 7px;
                  ">
                      <v-btn @click="removefileEdit(file)" class="close-button top-0 right-0" icon
                        v-if="Editstate == true"><v-icon>close</v-icon></v-btn>
                      <div @click="showiframeforDetail(file)"
                        :style="Editstate == true ? 'margin-top:-1.9rem;' : 'margin-top:5px;'">
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'png'" src="@/assets/images/png.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpeg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.filetype == 'pdf'" src="@/assets/images/pdf.png"
                          width="50px"></v-img>
                        <div class="text-center">
                          {{
                            file.filename
                            ? file.filename.substring(0, 10) + " ..."
                            : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none"
                      id="profile_picture" accept=".pdf, .png, .jpeg, .jpg" />

                    <div v-for="file in uploadedFiles" :key="file.name" class="ml-5 mr-0 pa-3 relative"
                      style="width: 130px;height:120px;border: 1px solid #ccc;border-radius: 7px;">
                      <v-btn @click="removefile(file)" class="close-button top-0 right-0"
                        icon><v-icon>close</v-icon></v-btn>
                      <div @click="showiframe(file)" style='margin-top:-1.9rem;'>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'image/png'" src="@/assets/images/png.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpeg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'application/pdf'" src="@/assets/images/pdf.png"
                          width="50px"></v-img>
                        <div class="text-center">{{ file.name ? file.name.substring(0, 10) + "..." : '' }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                  {{ $t("approver") }}</v-col>
                <v-col>
                  <v-row>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                      <v-text-field v-model="viewTimeOff.approver_Name" height="10px" color="#F99D20" single-line outlined
                        dense class="readonly" :style="$vuetify.breakpoint.mdAndUp
                          ? 'margin-left : -2.5rem; '
                          : ''
                          " readonly></v-text-field>
                    </v-col>
                    <v-col v-if="viewTimeOff.status == 'Approved' || viewTimeOff.status == 'Manual' || viewTimeOff.status == 'Denied'">
                      <p class="mt-3">{{ formatDateRD(viewTimeOff.apporve_Date) }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <hr color="#EEEEEE" class="mb-3" v-if="showcancel == true" />
              <v-row v-if="showcancel == true || viewTimeOff.status == 'Canceled'">
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2" class="pt-0">
                  <span style="font-weight: bold">{{ $t("ActionNote") }}</span><span class="red--text">*</span>
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10" class="pt-0">
                  <div :style="{
                    width: $vuetify.breakpoint.lgAndUp
                      ? '823px'
                      : $vuetify.breakpoint.md
                        ? '800px'
                        : '',
                  }">
                    <v-textarea v-model="viewTimeOff.actionNote" solo no-resize rows="3" min-height="80" name="input-7-4"
                      :disabled="viewTimeOff.status == 'Canceled'"
                      placeholder="Please fill in the reason for cancellation*" class="mb-4" outlined color="#F99D20"
                      :error-messages="ActionnoteSent" :style="$vuetify.breakpoint.mdAndUp
                        ? 'caret-color: orange !important;margin-left:-2.5rem;'
                        : 'caret-color: orange !important'
                        ">
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>
                          {{ $t("Action Note is required") }}
                        </span>
                      </template>
                    </v-textarea>
                  </div>
                  <!-- <span v-if="ActionnoteSent.length != 0" style="font-size: 13px" class="red--text"
                  :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem;' : ''">Action Note is
                  required</span> -->
                </v-col>
              </v-row>
              <v-row v-if="showcancel == true" class="mt-7 mb-5">
                <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
                <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="120" max-height="35" text
                  @click="showcancel = false">
                  {{ $t("Cancel") }}
                </v-btn>
                <v-btn color="#F99D20" class="text-capitalize white--text mr-3" width="90" max-height="35"
                  @click="CancelTimeOffreq()">
                  {{ $t("Confirm") }}</v-btn>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="viewTimeOff.status == 'Draft Request'" style="margin-right: 3%" class="pb-6">
              <v-spacer></v-spacer>
              <div v-if="Editstate == false">
                <v-btn outlined color="#A3A7AA" class="mr-2 text-capitalize" width="120" max-height="35" v-if="viewTimeOff.status != 'Approved' && viewTimeOff.status != 'Denied'
                  " @click="cancelingRequest(viewTimeOff.time_off_RequestID)" :disabled="permission.delete">
                  {{ $t("cancelRequest") }}</v-btn>
                <v-btn v-if="viewTimeOff.status == 'Draft Request'" color="#F99D20"
                  class="ml-3 white--text text-capitalize" width="120" max-height="35" @click="sendRequest(1)">{{
                    $t("Submit") }}</v-btn>
              </div>
              <div v-else>
                <v-btn text class="mr-2 text-capitalize" width="120" max-height="35" @click="Gobackorignial()">
                  {{ $t("Cancel") }}</v-btn>
                <v-btn color="#F99D20" class="ml-3 white--text text-capitalize" width="120" max-height="35"
                  @click="sendRequest(2)">{{ $t("save") }}</v-btn>
                <v-btn color="#F99D20" class="ml-3 white--text text-capitalize" width="120" max-height="35"
                  @click="sendRequest(1)">{{ $t("Savesubmit") }}</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
          style="overflow: hidden">
          <v-card style="padding-left:1.5rem; padding-right:1.5rem; padding-bottom:1.5rem;">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

              <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
            </v-card-actions>

            <v-img :src="imgForshow" max-width="500"></v-img>

          </v-card>
        </v-dialog>
        
        <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
          <v-card style="background-color: white">
            <v-btn @click="
              printdialogforupdate = false;
            iframeShow = false;
            " icon style="margin-right: 3rem; margin-top: 1rem">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
            <v-card class="pdf-container">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import jsPDF from "jspdf";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddEditDelete, utils],
  data: () => ({
    permission: {
      add: true,
      edit: true,
      delete: true,
    },
    url: enurl.apiUrl,
    timetypeitems: ["sick", "personal"],
    focus: new Date().toISOString().substr(0, 10),
    isEdit: true,
    time_off_RequestID: 0,
    Deletedialog: false,
    page: 1,
    pageCount: 15,
    itemsPerPage: 10,
    items: [10, 15, 20],
    LoadingDialog: false,
    cancelReq: false,
    notEditing: true,
    sendReq: false,
    todaymonths: 13,
    todayyears: null,
    editTimeOFf: false,
    time: null,
    menu2: false,
    start_Time: null,
    forDate: false,
    forDateStart: false,
    forEndDate: false,
    menu: false,
    end_Time: null,
    itemInner: "All",
    timeTypeList: [],
    viewTimeOff: {
      employee_Name: "",
      timeoff_Name: "",
      balance: null,
      start_Date: "",
      timeoff_ID: 0,
      end_Date: "",
      start_Time: "",
      end_Time: "",
      request_On: "",
      request_Ontxt: "",
      status: "",
      approver_Name: "",
      message: "",
      wholeDay: false,
      time_off_RequestID: 0,
      reason: "",
      attachmentList: [],
      imgName: "",
      imgString: "",
      imgForshow: "",
      dllink: null,
      iframeShow: false,
      actionNote: "",
      wF_ID: 0
    },
    error: {
      timeoff_ID: "",
      balance: "",
      start_Date: "",
      end_Date: "",
      start_Time: "",
      end_Time: "",
      message: "",
    },
    headers: [
      {
        text: "",
        value: "rowcheckbox",
        align: "center",
        sortable: false,
      },
      {
        text: "Employee Name",
        value: "empname",
        align: "center",
        sortable: false,
      },
      {
        text: "Time Off Type",
        value: "leavetype",
        align: "left",
        sortable: false,
      },
      {
        text: "Balance",
        value: "balance",
        align: "center",
        sortable: false,
      },
      {
        text: "Start Date",
        value: "startdate",
        align: "center",
        sortable: false,
      },
      {
        text: "End Date",
        value: "enddate",
        align: "center",
        sortable: false,
      },
      {
        text: "Requested On",
        value: "requeston",
        align: "center",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        sortable: false,
      },
    ],
    staffTimeOffList: [],
    TimeOffDialog: false,
    TimeOffDetail: {
      timeoff_Name: "",
      empname: "",
      balance: null,
      start_Date: "",
      timeoff_ID: 0,
      end_Date: "",
      start_Time: "",
      end_Time: "",
      Requested_Date: "",
      status: "",
      approver_Name: "",
      message: "",
      wholeDay: false,
      wfID: null,
      FilterStartDate: "",
      FilterEndDate: new Date(),
    },
    FilterStartDate: "",
    FilterEndDate: new Date(),
    LeaveList: [],
    LeaveList1: [],
    leaveDaysList: [],
    thelang: "",
    show: false,
    leaveType: 0,
    TODdialog: false,
    showremark: false,
    Editstate: false,
    dialogforDownload: false,
    printdialogforupdate: false,
    pdfString: '',
    ActionnoteSent: [],
    iframeShow: false,
    imgForshow: "",
    imgName: "",
    imgString: "",
    dllink: null,
    cancelstatus: false,
    theshowstaffList: [],
    uploadedFiles: [],
    uploaddefaultshow: true,
    disableviewdata: false,
    showcancel: false,
  }),

  computed: {
    status() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Waiting Approval", txt: this.$t("waitingApproval") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Denied", txt: this.$t("denied") },
        // { val: "Manual", txt: this.$t("Manual") },
        { val: "Canceled", txt: this.$t("Canceled") },
        { val: "Draft Request", txt: this.$t("Draftreq") },
      ];
    },

    monthNames() {
      return [
        { val: 13, txt: this.$t("All")},
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },

    itemFilter() {
      let returndata = [];
      let stop = false;
      if (this.leaveType == 0) {
        returndata = this.staffTimeOffList;
        stop = true;
      } else {
        returndata = this.staffTimeOffList.filter((x) => x.timeoff_ID == this.leaveType);
        stop = true;
      }
      if (stop == false) {
        if (this.itemInner === "All") {
          returndata = this.staffTimeOffList;
        }
        else {
          returndata = this.staffTimeOffList.filter((x) => x.status === this.itemInner);
        }
      }

      if (!this.itemInner) return this.staffTimeOffList;
      //if(this.itemInner==="All") return this.staffTimeOffList;
      return returndata;
    },
    computedDateFormated() {
      return this.formatDate(this.viewTimeOff.start_Date);
    },
    computedDateFormatedStart() {
      return this.formatDate(this.viewTimeOff.start_Date);
    },
    computedDateFormated2() {
      return this.formatDate(this.viewTimeOff.end_Date);
    },
    // computedDateFormatted3() {
    //   return this.formatDate(this.viewTimeOff.start_DateForHalf);
    // },
    computedDateFormatted3() {
      return this.formatDate(this.TimeOffDetail.start_Date);
    },
    computedDateFormatted4() {
      return this.formatDate(this.TimeOffDetail.end_Date);
    },
    thethaihead() {
      return this.whatthehead();
    },
  },
  mounted() {
    //let d = new Date();
    //this.todaymonths = this.monthNames[d.getMonth()].val;
    this.todayyears = new Date();
    this.FilterStartDate = new Date();
    this.FilterStartDate.setDate(1);
    this.GetTimeOff();
    this.sendNotification();
    this.GetLeaveTypeWithRemainingDays();
    this.permission = this.CheckViewAddEditDelete(159, 160, 161, 162);
    this.itemInner = this.$route.params.condition
      ? this.$route.params.condition
      : "All";
    this.GetLeaveType();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  // define a watcher
  watch: {
    // $route() {
    //   this.getDataFromParams();
    // },
    "viewTimeOff.timeoff_ID"(v) {
      if (v) this.error.timeoff_ID = "";
    },
    "viewTimeOff.start_Date"(v) {
      if (v) this.error.start_Date = "";
    },
    "viewTimeOff.end_Date"(v) {
      if (v) this.error.end_Date = "";
    },
    "viewTimeOff.start_Time"(v) {
      if (v) this.error.start_Time = "";
    },
    "viewTimeOff.end_Time"(v) {
      if (v) this.error.end_Time = "";
    },
    "viewTimeOff.message"(v) {
      if (v) this.error.message = "";
    },
    thelang() {
      this.whatthehead()
    },
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    onpickfile() {
      this.$refs.fileupload.click();
    },
    changeType() {
      let returndata = [];

      if (this.leaveType == 0) {
        returndata = this.staffTimeOffList;
      } else {
        returndata = this.staffTimeOffList.filter((x) => x.timeoff_ID == this.leaveType);
      }
      this.theshowstaffList = returndata;
    },
    changestatus() {
      let returndata = [];

      if (this.itemInner === "All") {
        returndata = this.staffTimeOffList;
      }
      else if (this.itemInner === "Approved"){
        returndata = this.staffTimeOffList.filter((x) => x.status === "Approved" || x.status === "Manual");
      }
      else {
        returndata = this.staffTimeOffList.filter((x) => x.status === this.itemInner);
      }

      this.theshowstaffList = returndata;
    },
    showdetaildata() {
      let self = this;
      self.Editstate = true;
      self.disableviewdata = false;
      self.hideeditbtn = true;
      self.showeditbtn = false;
      self.disablesendbtn = true;
      self.disalbecanclebtn = true;
    },
    Gobackorignial() {
      let self = this;
      // self.Olddata=self.Olddata;
      self.viewdialog = true;
      self.Editstate = false;
      self.disableviewdata = true;
      self.EditOT.OTrequest_ID = self.Olddata.oT_Request_ID;
      self.EditOT.positionname = self.Olddata.positionname;
      self.EditOT.RequestedDate = self.convertUTCDateToLocalDateForReq(
        self.Olddata.requested_Date
      );
      if (self.Olddata.saveDraft == true) {
        self.showeditbtn = false;
      } else {
        self.showeditbtn = true;
      }
      self.EditOT.empname = self.Olddata.requestername;
      self.EditOT.wholeday = self.Olddata.wholeDay;
      if (self.Olddata.wholeDay == true) {
        self.editshowandhide = false;
        let date1 = new Date(self.Olddata.start_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.viewOtstart = self.Olddata.oT_Start_Time;
        self.viewOtend = self.Olddata.oT_End_Time;
      } else {
        self.editshowandhide = true;
        let date1 = new Date(self.Olddata.start_Date);
        let date2 = new Date(self.Olddata.end_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        let plusday2 = date2.getDate() + 1;
        date2.setDate(plusday2);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.EditOT.OTenddateforwholeday = new Date(date2)
          .toISOString()
          .substr(0, 10);
        self.viewOtstart = self.Olddata.oT_Start_Time;
        self.viewOtend = self.Olddata.oT_End_Time;
      }
      let date1 = new Date(self.Olddata.start_Date);
      let plusday1 = date1.getDate() + 1;
      date1.setDate(plusday1);
      let date2 = new Date(self.Olddata.end_Date);
      let plusday2 = date2.getDate() + 1;
      date2.setDate(plusday2);
      self.EditOT.OTstartdate = new Date(date1).toISOString().substr(0, 10);
      self.EditOT.OTenddate = new Date(date2).toISOString().substr(0, 10);
      self.EditOT.OThours = self.Olddata.oT_Hours;

      self.EditOT.purposeOT = self.Olddata.purpose_OT;
      self.EditOT.approver = self.Olddata.approvername;
      self.EditOT.status = self.Olddata.status;
    },
    ChangeMonth() {
      let self = this;
      self.GetTimeOff();
    },

    async sendNotification() {
      let self = this;
      let TempReportID = null;

      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}PersonalInfo/GetShortInfo`,userInfoTemp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
          var reportID = response.data.data[0].report_ID;
          self.username = username;
          self.reportID = reportID;
          TempReportID = reportID;
        }
      });

      if (TempReportID)
      {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await Axios.post(`${self.url}Firebasetoken/GetFirebaseTokenByUserID`,temp)
        .then(function (response) {
          var temp = response.data.data;
          if (temp[0])
          {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });
        self.tokenList = token;
      }
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await Axios.post(`${self.url}OT_Request/GetworkFlowOfOTRequest`,temp)
      .then(function (response) {
        if (response.data.status === 0)
        {
          let WF_ID = response.data.data.wF_ID;
          let senderID = response.data.data.requester_ID;
          let recipientID = response.data.data.approver_ID;
          self.newnotiList(WF_ID, senderID, recipientID);
        }
      });
    },

    newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for Time Off`,
      };

      Axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
      .then(function (response) {
          if (response.data.status == 0)
          {
            self.pushNotification(recipientID);
          }
        }
      );
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Timeoff Request",
        body: "Timeoff Request from " + self.username,
        reportID: recipientID,
      };
      await Axios.post(`${self.url}Firebasetoken/sendNotification`,noti_temp)
      .then(function () {});
    },
    // async getDataFromParams() {
    //   try {
    //     let notiSring = this.$route.params.notiString;
    //     if (notiSring) {
    //       this.TimeOffDialog = true;
    //       let temp = {
    //         wF_ID: parseInt(notiSring),
    //       };
    //       const response = await Axios.post(
    //         `${this.url}NotificationController/Get_workflowByWorkFlowId`,
    //         temp
    //       );
    //       let list = response.data.data;
    //       let condition = this.$route.params.condition;
    //       this.itemInner = condition;
    //       this.TimeOffDetail.timeoff_Name = list[0].timeoff_Name;
    //       this.TimeOffDetail.balance = list[0].balance;
    //       this.TimeOffDetail.start_Date = list[0].start_Date;
    //       this.TimeOffDetail.timeoff_ID = list[0].timeoff_ID;
    //       this.TimeOffDetail.end_Date = list[0].end_Date;
    //       this.TimeOffDetail.start_Time = list[0].start_Time;
    //       this.TimeOffDetail.end_Time = list[0].end_Time;
    //       this.TimeOffDetail.Requested_Date = list[0].Requested_Date;
    //       this.TimeOffDetail.status = list[0].status;
    //       this.TimeOffDetail.approver_Name = list[0].approvername;
    //       this.TimeOffDetail.message = list[0].message;
    //       this.TimeOffDetail.wholeDay = list[0].wholeDay;
    //       this.TimeOffDetail.wfID = list[0].wF_ID;
    //     }
    //   } catch (error) {
    //     throw error;
    //   }
    // },
    closeNoti() {
      this.TimeOffDialog = false;

      this.$router.replace({
        name: "MyTimeOffRequest",
        params: { condition: this.$route.params.condition },
      });
    },

    convertUTCDateToLocalDate(date) {
      let stillUtc = this.$moment.utc(date).toDate(); //still utc
      let localDate = this.$moment(stillUtc).local().format("DD/MM/YYYY");
      return localDate;
    },

    editDiaglog() {
      this.editTimeOFf = false;
      this.isEdit = true;
      this.sendReq = false;
      this.cancelReq = false;
      this.notEditing = true;
    },
    EditGone() {
      this.isEdit = !this.isEdit;
      this.sendReq = !this.sendReq;
      this.cancelReq = !this.cancelReq;
      this.notEditing = !this.notEditing;
    },

    async SaveGone() {
      try {
        let startDate = this.$moment(this.viewTimeOff.start_Date);
        let endDate = this.$moment(this.viewTimeOff.end_Date);
        let dayDiff = endDate.diff(startDate, "days") + 1;
        this.error.timeoff_ID = !this.viewTimeOff.timeoff_ID ? "Time Off Type is required" : "";
        this.error.message = !this.viewTimeOff.message ? "Message is required" : "";
        if (this.viewTimeOff.wholeDay)
        {
          this.error.start_Date = !this.viewTimeOff.start_Date ? "Start date is required" : "";
          this.error.end_Date = !this.viewTimeOff.end_Date ? "End date is required" : "";
          this.viewTimeOff.start_Time = "";
          this.viewTimeOff.end_Time = "";
          // this.viewTimeOff.start_Date = this.viewTimeOff.start_Date;
          this.viewTimeOff.start_Date = this.$moment(this.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          this.viewTimeOff.end_Date = this.$moment(this.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          let tempvalid = this.ValidateAddTrue();
          if (tempvalid)
          {
            // if (startDate < endDate.add(1, "day"))
            if (this.$moment(startDate).isBefore(this.$moment(endDate).add(1, "day")))
            {
              if (dayDiff <= this.isHalf)
              {
                this.LoadingDialog = true;
                this.viewTimeOff.saveDraft = true;
                const respone = await Axios.post(`${this.url}TimeOff_Request/UpdateTimeOff_Request`,this.viewTimeOff);
                if (respone.data.status !== 0)
                {
                  this.LoadingDialog = false;
                  alert("Please recheck the date since there seems to be a clash with another request");
                }
                else
                {
                  this.LoadingDialog = false;
                  alert("Updated successfully");
                  this.isEdit = !this.isEdit;
                  this.sendReq = !this.sendReq;
                  this.cancelReq = !this.cancelReq;
                  this.notEditing = !this.notEditing;
                  this.GetTimeOff();
                  //this.LogTrace(null, "Update TimeOff", 31, "Medium");
                }
              }
              else
              {
                alert("The Time Off requested is more than balance you have");
              }
            }
            else
            {
              alert("Start date can't be after end date");
            }
          }
        }
        else
        {
          this.error.start_Time = !this.viewTimeOff.start_Time ? "Start Time is required"  : "";
          this.error.end_Time = !this.viewTimeOff.end_Time ? "End Time is required"  : "";
          this.viewTimeOff.start_Time = this.viewTimeOff.start_Time;
          this.viewTimeOff.end_Time = this.viewTimeOff.end_Time;
          // this.viewTimeOff.end_Date = this.viewTimeOff.start_Date;
          this.viewTimeOff.start_Date = this.$moment(this.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          this.viewTimeOff.end_Date = this.$moment(this.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          let tempvalid = this.ValidateAddHalf();
          if (tempvalid)
          {
            this.viewTimeOff.saveDraft = true;
            let start_Time = this.$moment(this.viewTimeOff.start_Time, "h:mma");
            let end_time = this.$moment(this.viewTimeOff.end_Time, "h:mma");
            if (start_Time.isBefore(end_time))
            {
              this.LoadingDialog = true;
              const respone = await Axios.post(`${this.url}TimeOff_Request/UpdateTimeOff_Request`,this.viewTimeOff);
              if (respone.data.status !== 0)
              {
                this.LoadingDialog = false;
                alert("Please recheck the date since there seems to be a clash with another request");
              }
              else
              {
                alert("Updated successfully");
                this.LoadingDialog = false;
                this.isEdit = !this.isEdit;
                this.sendReq = !this.sendReq;
                this.cancelReq = !this.cancelReq;
                this.notEditing = !this.notEditing;
                this.GetTimeOff();
                //this.LogTrace(null, "Update TimeOff", 31, "Medium");
              }
            }
            else
            {
              alert("Start time can't be after end time");
            }
          }
        }
      }
      catch (ex)
      {
        this.LogTrace(ex, "Update TimeOff Fail", 31, "Medium");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    async sendRequest(v) {
      try {
        let self = this;
        self.LoadingDialog = true;
        var draft = false;
        if (v == 1)
        {
          draft = false;
        }
        else
        {
          draft = true;
        }
        let myattachments = [];
        if (self.uploadedFiles.length != 0)
        {
          self.uploadedFiles.map(function (data) {
            myattachments.push({
              attachment_file: data.imgurl,
              filename: data.name,
              filetype: data.type,
            });
          });
        }
        if (self.viewTimeOff.attachmentList != 0)
        {
          self.viewTimeOff.attachmentList.map(function (data) {
            myattachments.push({
              attachment_file: data.attachment,
              filename: data.filename,
              filetype: data.filetype,
            });
          });
        }
        let anyleft = self.checkDaysLeft();
        if (anyleft)
        {
          let temp = {
            time_off_RequestID: self.viewTimeOff.time_off_RequestID,
            timeoff_ID: self.viewTimeOff.timeoff_ID,
            wholeDay: self.viewTimeOff.wholeDay,
            // start_Date: self.viewTimeOff.start_Date,
            // end_Date: self.viewTimeOff.end_Date,
            start_Date: self.$moment(self.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            end_Date: self.$moment(self.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            start_Time: self.viewTimeOff.start_Time,
            end_Time: self.viewTimeOff.end_Time,
            message: self.viewTimeOff.message,
            balance: self.viewTimeOff.balance,
            company_ID: store.state.companyID,
            saveDraft: draft,
            attachments: myattachments
          };
          self.error.timeoff_ID = !self.viewTimeOff.timeoff_ID ? "Time Off Type is required" : "";
          self.error.message = !self.viewTimeOff.message ? "Message is required" : "";
          if (self.viewTimeOff.wholeDay)
          {
            self.error.start_Date = !self.viewTimeOff.start_Date ? "Start date is required" : "";
            self.error.end_Date = !self.viewTimeOff.end_Date ? "End date is required" : "";
            self.viewTimeOff.start_Time = "";
            self.viewTimeOff.end_Time = "";
            //self.viewTimeOff.start_Date = self.viewTimeOff.start_Date;
            let tempvalid = self.ValidateAddTrue();
            if (tempvalid)
            {
              if (self.$moment(self.viewTimeOff.start_Date).isBefore(self.$moment(self.viewTimeOff.end_Date).add(1, "day")))
              {
                const respone = await Axios.post(`${self.url}TimeOff_Request/UpdateTimeOff_Request`,temp);
                if (respone.data.status !== 0)
                {
                  self.LoadingDialog = false;
                  alert("Please recheck the date since there seems to be a clash with another request");
                }
                else
                {
                  alert("Updated successfully");
                  self.LoadingDialog = false;
                  self.TODdialog = false;
                  self.GetTimeOff();
                  //self.clearData(self.viewTimeOff);
                  if (respone.data.status == 0 && v == 1)
                  {
                    await self.getLatestData();
                  }
                }
              }
              else
              {
                alert("Start date can't be after end date");
                self.LoadingDialog = false;
              }
            }
          }
          else
          {
            self.error.start_Time = !self.viewTimeOff.start_Time
              ? "Start Time is required"
              : "";
            self.error.end_Time = !self.viewTimeOff.end_Time
              ? "End Time is required"
              : "";
            let temp1 = {
              time_off_RequestID: self.viewTimeOff.time_off_RequestID,
              timeoff_ID: self.viewTimeOff.timeoff_ID,
              wholeDay: self.viewTimeOff.wholeDay,
              // start_Date: self.viewTimeOff.start_Date,
              // end_Date: self.viewTimeOff.start_Date,
              start_Date: self.$moment(self.viewTimeOff.start_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              end_Date: self.$moment(self.viewTimeOff.end_Date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              start_Time: self.viewTimeOff.start_Time,
              end_Time: self.viewTimeOff.end_Time,
              message: self.viewTimeOff.message,
              balance: self.viewTimeOff.balance,
              company_ID: store.state.companyID,
              saveDraft: draft,
              attachments: myattachments
            };

            let tempvalid = self.ValidateAddHalf();
            if (tempvalid)
            {
              const respone = await Axios.post(`${self.url}TimeOff_Request/UpdateTimeOff_Request`,temp1);
              if (respone.data.status !== 0)
              {
                self.LoadingDialog = false;
                alert("Please recheck the date since there seems to be a clash with another request");
              }
              else
              {
                self.LoadingDialog = false;
                alert("Updated successfully");
                self.TODdialog = false;
                self.GetTimeOff();
                //self.clearData(self.viewTimeOff);
                if (respone.data.status == 0 && v == 1)
                {
                  await self.getLatestData();
                }
              }
            }
          }
        }
        else
        {
          alert("Requested balance not available.");
          self.LoadingDialog = false;
        }
      } catch (ex) {
        self.LogTrace(ex, "Update TimeOff Fail", 31, "Medium");
        alert(ex + "Correlation ID is 31");
        self.LoadingDialog = false;
        throw ex;
      }
    },

    checkDaysLeft() {
      let self = this;
      let result = false;
      let takendays = this.getDays1();
      let theone = self.leaveDaysList.filter((v) => v.timeOff_ID == self.viewTimeOff.timeoff_ID);
      if (theone[0].availablDays > takendays) {
        result = true;
        self.viewTimeOff.balance = (theone[0].availablDays - takendays).toString();
      }
      return result;
    },

    getDays1() {
      if (this.viewTimeOff.wholeDay)
      {
         // Split the dates into day, month, and year components
        const [startDay, startMonth, startYear] = this.viewTimeOff.start_Date.split('/').map(Number);
        const [endDay, endMonth, endYear] = this.viewTimeOff.end_Date.split('/').map(Number);

        // Create Date objects with the parsed components
        const sd = new Date(startYear, startMonth - 1, startDay); // Month is 0-based in JavaScript Date
        const ed = new Date(endYear, endMonth - 1, endDay);

        // Calculate the time difference in milliseconds
        const timeDifference = ed.getTime() - sd.getTime();
        
        // Calculate the number of days by dividing the time difference by milliseconds in a day
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Since it's whole day, we include both the start and end dates
        return daysDifference + 1;
        // let sd = new Date(this.viewTimeOff.start_Date);
        // let ed = new Date(this.viewTimeOff.end_Date);
        // // Convert both dates to UTC to ensure consistent calculation
        // const startUtc = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
        // const endUtc = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());

        // // Calculate the time difference in milliseconds
        // const timeDifference = endUtc - startUtc;

        // // Calculate the number of days by dividing the time difference by milliseconds in a day
        // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // return daysDifference + 1;
      }
      else
      {
        return 0.5;
      }
    },

    ValidateAddTrue() {
      if (this.viewTimeOff.wholeDay) {
        if (
          this.viewTimeOff.timeoff_ID != null &&
          this.viewTimeOff.start_Date != null &&
          this.viewTimeOff.end_Date != null &&
          this.viewTimeOff.message != null &&
          this.viewTimeOff.start_Date != "" &&
          this.viewTimeOff.end_Date != "" &&
          this.viewTimeOff.message != ""
        )
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },

    ValidateAddHalf() {
      if (!this.viewTimeOff.wholeDay)
      {
        if (
          this.viewTimeOff.timeoff_ID != 0 &&
          this.viewTimeOff.start_Time != null &&
          this.viewTimeOff.end_Time != null &&
          this.viewTimeOff.message != null &&
          this.viewTimeOff.start_Time != "" &&
          this.viewTimeOff.end_Time != "" &&
          this.viewTimeOff.message != ""
        )
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },

    clearData(obj = {}, defaultValue = null) {
      for (const key in obj) {
        obj[key] = defaultValue;
      }
    },

    cancelingRequest(id) {
      this.time_off_RequestID = id;
      this.TODdialog = false;
      this.Deletedialog = true;
    },

    async DeleteRow() {
      try {
        let temp = {
          time_off_RequestID: this.time_off_RequestID,
        };
        this.LoadingDialog = true;
        const response = await Axios.post(`${this.url}TimeOff_Request/DeleteTimeOff`,temp);
        if (response.data.status == 0) 
        {
          this.LoadingDialog = false;
          alert("Delete Success");
          this.Deletedialog = false;
          this.editDiaglog();
          this.GetTimeOff();
        }
      } catch (ex) {
        throw ex;
      }
    },

    async onInputHandler(id, timeTypeList) {
      const filter = timeTypeList.find((x) => x.timeoff_ID == id);
      try {
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          timeoff_ID: filter.timeoff_ID,
        };
        const respone = await Axios.post(`${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyIdTimeOffId`,temp);
        let date = respone.data.data;
        let availability_Days = filter.availability_Days;
        if (availability_Days == date.avalable_Day || availability_Days < date.avalable_Day)
        {
          this.viewTimeOff.balance = 0;
          this.sendReq = false;
          this.cancelReq = false;
        }
        else
        {
          let result = availability_Days - date.avalable_Day;
          result = String(result);
          if (result == 0.5)
          {
            this.timeOff.balance = "Half";
            this.isHalf = 0.5;
          }
          else if (result.match(/\./))
          {
            let final = result.split(".")[0];
            this.viewTimeOff.balance = final + " and half";
            this.isHalf = +final + 0.5;
          }
          else
          {
            this.viewTimeOff.balance = result;
            this.isHalf = result;
          }
        }
      } catch (ex) {
        throw ex;
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    NewTimeOffRequest() {
      this.$router.push({ name: "NewTimeOffRequest" }).catch(() => { });
    },

    ViewTimeOffRequest(prop) {
      this.TODdialog = true;
      this.viewTimeOff = Object.assign({}, prop);
      this.viewTimeOff.attachmentList = [];
      let alllist = prop.attachmentList;
      for (let i = 0; i < alllist.length; i++) {
        let templist = {
          ...alllist[i],
          id: i,
        };
        this.viewTimeOff.attachmentList.push(templist);
      }
      this.disableviewdata = true;
      this.viewTimeOff.request_Ontxt = this.formatDateRD(this.viewTimeOff.request_On);
      // Format the start_Date and end_Date to 'DD/MM/YYYY'
      this.viewTimeOff.start_Date = this.$moment(this.viewTimeOff.start_Date).format('DD/MM/YYYY');
      this.viewTimeOff.end_Date = this.$moment(this.viewTimeOff.end_Date).format('DD/MM/YYYY');
      //this.viewTimeOff.attachmentlist = prop.attachmentlist;
      // let checkValue = prop.balance;
      // if (checkValue.includes("and half")) {
      //   let balance = checkValue.split(" ");
      //   this.isHalf = +balance[0] + 0.5;
      // } else if (checkValue == "Half") {
      //   this.isHalf = 0.5;
      // } else {
      //   this.isHalf = checkValue;
      // }

      // let date1 = new Date(prop.start_Date);
      // let plusday1 = date1.getDate() + 1;
      // date1.setDate(plusday1);
      // let date2 = new Date(prop.end_Date);
      // let plusday2 = date2.getDate() + 1;
      // date2.setDate(plusday2);
      // this.viewTimeOff.start_Date = new Date(date1).toISOString().substr(0, 10);
      // this.viewTimeOff.end_Date = new Date(date2).toISOString().substr(0, 10);
    },

    hidedetaildialog() {
      let self = this;
      self.TODdialog = false;
      self.hideeditbtn = false;
      self.disablesendbtn = false;
      self.disalbecanclebtn = false;
      self.disableviewdata = true;
      self.Editstate = false;
    },
    DeleteCancel() {
      this.Deletedialog = false;
      this.TODdialog = true;
    },
    ChangeYear() {
      let d = new Date();
      d.setMonth(this.todaymonths - 1);
      this.focus = d;
    },
    async GetTimeOff() {
      try {
        let myyear = this.todayyears;
        let y = myyear.getFullYear();
        let thisyear = "";
        if (this.todaymonths.val == undefined) {
          thisyear = this.todaymonths;
        } else {
          thisyear = this.todaymonths.val;
        }
        this.LoadingDialog = true;
        let temp = {
          month_Num: thisyear,
          year_Num: y,
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          requester_ID: parseInt(store.state.reportID),
          start_Date: this.FilterStartDate,
          end_Date: this.FilterEndDate
        };
        const response = await Axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_RequestByCompanyId`,
          temp
        );

        //this.staffTimeOffList = response.data.data;
        let llist = response.data.data;
        this.staffTimeOffList = llist.map((v) => ({
          ...v,
          usedminus: this.getDays(v.start_Date, v.end_Date, v.wholeDay),
          hovering: false,
        }));
        this.theshowstaffList = this.staffTimeOffList;
        this.changestatus();
        this.LoadingDialog = false;
        //this.LogTrace(null, "Get TimeOff", 31, "Critical");
      } catch (ex) {
        this.LogTrace(ex, "Get TimeOff Fail", 31, "Critical");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },

    getDays(startDate, endDate, wd) {
      // const [startDay, startMonth, startYear] = startDate.split('/').map(Number);
      // const [endDay, endMonth, endYear] = endDate.split('/').map(Number);
      // // Create Date objects with the parsed components
      // const sd = new Date(startYear, startMonth - 1, startDay); // Month is 0-based in JavaScript Date
      // const ed = new Date(endYear, endMonth - 1, endDay);
      // // Calculate the time difference in milliseconds
      // const timeDifference = ed.getTime() - sd.getTime();
      let sd = new Date(startDate);
      let ed = new Date(endDate);
      // Convert both dates to UTC to ensure consistent calculation
      const startUtc = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
      const endUtc = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());
      // Calculate the time difference in milliseconds
      const timeDifference = endUtc - startUtc;
      // Calculate the number of days by dividing the time difference by milliseconds in a day
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      if (wd)
      {
        return daysDifference + 1;
      }
      else
      {
        return "0.5";
      }

    },
    GetLeaveType() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      Axios.post(`${self.url}TimeOff/GetLeaveTypeByCompanyID`, tempp)
        .then(function (response)
        {
          if(response.data.status === 0)
          {
            let thelist = response.data.data;
            let thelist1 = [];
            thelist1.push({
              timeOff_ID: 0,
              timeoff_TypeEN: "All",
              timeoff_TypeTH: "ทั้งหมด",
              leaveStatus: true
            });
            for (let i = 0; i < thelist.length; i++)
            {
              thelist1.push({ ...thelist[i] });
            }
            self.LeaveList = thelist1.filter((v) => v.leaveStatus == true);
            self.LeaveList1 = thelist.filter((v) => v.leaveStatus == true);
            //this.exceptTimeGroupList = [...this.selected, ...exceptTimeGroupList];
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },

    GetLeaveTypeWithRemainingDays() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
      };
      Axios.post(`${self.url}TimeOff_Request/GetLeaveWithRemainingDays`, tempp)
      .then(function (response) {
        self.leaveDaysList = response.data.data;
      })
      .catch(function (error) {
        alert(error);
      });
    },

    async Closedetaildialog() {
      let self = this;
      self.dialog = false;
      self.showcancel = false;
      if (self.cancelstatus)
      {
        await self.GetTimeOff();
      }
      self.cancelstatus = false;
    },

    async showiframeforDetail(item) {
      let self = this;
      //console.log(item);
      if (item.filetype == "pdf")
      {
        const blob = await self.base64ToBlob(
          item.attachment,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      }
      else
      {
        //const pdfBlob = await this.convertBase64ImageToPdf(
        // item.attachment_file
        //);
        self.dialogforDownload = true;
        self.imgForshow = 'data:image/png;base64,' + item.attachment;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;

      }
    },

    downloadImage() {
      let self = this;
      const link = document.createElement('a');
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },

    showiframe(item) {
      let self = this;
      if (item.type == 'image/png' || item.type == 'image/jpeg' || item.type == 'image/jpg') {
        self.imgForshow = 'data:image/png;base64,' + item.imgurl;
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
      }
      else {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }

    },
    // async showiframeforDetail(item) {
    //   //console.log(item);
    //   let self = this;
    //   if (item.filetype == "pdf") {
    //     const blob = await self.base64ToBlob(
    //       item.attachment_file,
    //       "application/pdf"
    //     );
    //     self.printdialogforupdate = true;
    //     self.iframeShow = true;
    //     const blobUrl = URL.createObjectURL(blob);
    //     self.pdfString = blobUrl;
    //   } else {
    //     self.dialogforDownload = true;
    //     self.imgForshow = 'data:image/png;base64,' + item.attachment_file;
    //     self.dllink = self.imgForshow;
    //     self.imgName = item.filename;
    //     // pdfBlob = await this.convertBase64ImageToPdf(
    //     //  item.attachment_file
    //     //);
    //     //const pdfString = URL.createObjectURL(pdfBlob);
    //     //self.imgForshow='data:image/png;base64,'+item.filename;
    //     //self.pdfString = pdfString;
    //   }
    // },
    removefile(file) {

      const index = this.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1) {
        this.uploadedFiles.splice(index, 1);
      }

      if (this.uploadedFiles.length == 0) {
        this.uploaddefaultshow = true;
      }


    },
    removefileEdit(file) {

      const index = this.viewTimeOff.attachmentList.findIndex((item) => item.id === file.id);
      if (index !== -1) {
        this.viewTimeOff.attachmentList.splice(index, 1);
      }

      if (this.viewTimeOff.attachmentList.length == 0) {
        this.uploaddefaultshow = true;
      }


    },
    async handleFileSelect(event) {
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) {
        if (file.type === "application/pdf") {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        } else if (file.type.startsWith("image/")) {
          //const pdfBlob = await this.convertImageToPdf(file);
          //console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];

          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },
    async handleFiledrag(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) {
        if (file.type === "application/pdf") {
          pdfString = URL.createObjectURL(file);
          //console.log("pdfstring", pdfString);
        } else if (file.type.startsWith("image/")) {
          const pdfBlob = await this.convertImageToPdf(file);
          //console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(pdfBlob);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];

          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleFileDrop(event) {
      event.preventDefault();
      // const files = event.dataTransfer.files;
      // this.uploadFiles(files);
      this.handleFiledrag(event);
    },
    uploadFilesall(imgname, type, url, pdfurl) {
      let plusid = this.uploadedFiles.length;
      this.uploadedFiles.push({
        id: plusid,
        name: imgname,
        type: type,
        imgurl: url,
        pdfurl: pdfurl,
      });
      this.uploaddefaultshow = false;
    },
    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },
    async convertBase64ImageToPdf(base64Image) {
      const pdf = new jsPDF();
      const blob = this.base64ToBlobforimg(base64Image, "image/jpeg"); // Adjust content type if needed
      const imgData = URL.createObjectURL(blob);

      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed

      const pdfData = pdf.output("blob");
      return pdfData;
    },
    base64ToBlobforimg(base64String, contentType) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
    formatDateRD(item) {
      if (item != "-" && item != null && item != "") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        output = this.padZero(newDate.getDate()) + "/" +
            this.padZero((newDate.getMonth() + 1)) + "/" + newDate.getFullYear() +
          " " + this.padZero(newDate.getHours()) + ":" + this.padZero(newDate.getMinutes());
        
        return output;
      } else {
        return "-";
      }
    },
    padZero(num) {
      return num.toString().padStart(2, '0');
    },
  },
};
</script>
<style scoped>
.tableCol {
  border-bottom: 1px solid #fff;
}

>>>.mx-input-wrapper:hover input[type="text"] {
  border-color: #f99d20;
}

>>>.mx-input-wrapper input[type="text"]:focus {
  border-color: #f99d20;

}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.v-dialog {
  border-radius: 10px !important;
}
::v-deep .monthbox.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections {
    padding-left:10px;
}

>>>.selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
/* .minizebox {
    width: 150px;
    height: 35px;
  } */
@media (max-width: 350px) {
  .minizebox {
    width: 100px;
    height: 35px;
  }
}

@media (max-width: 310px) {
  .minizebox {
    width: 85px;
    height: 35px;
  }
}
.lessleft{
  margin-left: 100px;
  margin-top: 0px;
}
@media (max-width: 300px) {
  .lessleft {
    margin-left: 50px;
  }
}
@media (min-width: 1904px) and (max-width: 2000px) {
  .lessleft {
    margin-left: 70px;
  }
}
.unread.v-text-field>.v-input__control>.v-input__slot {
  background-color: none !important;
}
>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

>>>.v-select.v-text-field input {
  margin-left: 10px;
}

>>>.mdi-menu-down::before {
  content: "\F035D";
  color: black;
}

>>>.mx-input {
  color: black;
}

>>>.v-text-field>.v-input__control>.v-input__slot:before {
  border: none;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 15px;
  font-weight: normal;
  color: #f99d20;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

.hover-effect {
  position: relative;
}

.hover-effect:hover .tooltip {
  opacity: 1;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #707070;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-button {
  position: relative;
  cursor: pointer;
}

.hover-effect:hover .tooltip {
  opacity: 1;
}

.tooltip-button:hover .tooltip {
  opacity: 1;
}

.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

.image-iframe {
  width: 400px;
  /* Set your desired width */
  height: 400px;
  /* Set your desired height */
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}

.pdf-container {
  background-color: #aaa9ae;
  margin-left: 3rem;
  margin-right: 3rem;
}

.close-button {
  position: relative;
  top: -15px;
  left: 80px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
</style>
