<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("EmpRep") }}
        </p>
    </div>
    
    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <p class="mt-0">{{ $t("startdate_report") }}</p>
          <date-picker
            v-bind:clearable="false"
            v-model="chartStartDate"
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
            class="fromdate"
            style="width: 150px"
            @change="OnChangeStartDate()"
          >
            <template slot="icon-calendar">
              <img
                src="@/assets/images/calendaricon.png"
                style="width: 20px; height: 20px"
              />
            </template>
          </date-picker>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <p class="mt-0">{{ $t("enddata") }}</p>
          <date-picker
            v-bind:clearable="false"
            class="todate"
            v-model="chartEndDate"
            format="DD/MM/YYYY"
            width="100%"
            placeholder="DD/MM/YYYY"
            readonly
            style="width: 150px"
            @change="OnChangeEndDate()"
          >
            <template slot="icon-calendar">
              <img
                src="@/assets/images/calendaricon.png"
                style="width: 20px; height: 20px"
              />
            </template>
          </date-picker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-autocomplete
            :items="exportItem"
            item-text="exptxt"
            item-value="expval"
            color="#F99D20"
            class="movelf ddl"
            outlined
            v-model="expit"
            :placeholder="$t(`Export`)"
            style="
              min-width: 150px;
              max-width: 150px;
              border-radius: 5px;
              margin-top: 2rem;
            "
            dense
            v-on:change="changetoexport(expit)"
            :disabled="permission.export"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="backGG" justify="center">
        <line-chart
          id="line"
          v-if="loaded"
          :chartData="chartdata"
          :options="options"
          class="linecht"
        ></line-chart>
      </v-row>
      <p
        style="
          font-size: 18px;
          font-family: 'Kanit';
          margin-left: 20px;
          margin-top: 20px;
          color: #f99d20;
        "
      >
        {{ $t("Addition") }} ({{ tot }})
      </p>

      <div class="mt-5">
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :items="additionList"
          hide-default-footer
          hide-default-header
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>{{ $t("EmployeeID") }}</th>
                <th>{{ $t("employee_name") }}</th>
                <th>{{ $t("employeetype") }}</th>
                <th>{{ $t("department") }}</th>
                <th>{{ $t("division") }}</th>
                <th>{{ $t("position") }}</th>
                <th>{{ $t("hired_date") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.employee_Code }}</td>
              <td>{{ item.fullName }}</td>
              <td>{{ item.employeeType }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.division }}</td>
              <td>{{ item.position }}</td>
              <td>{{ DateFormat(item.hiredDate) }}</td>
            </tr>
          </template>
        </v-data-table>
        <div v-show="showtablerow" class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#f99d20"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <!-- print dialog -->
    <v-dialog v-model="printDialog" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p
                    class="headtext"
                    style="font-size: 28px; font-family: 'Kanit'"
                  >
                    {{ $t("Addition") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="ggg"
                    style="padding: -10px; padding-right: 0px"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-md-0 mt-0" style="margin-right: 10px">
                <v-col class="mt-0" cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-0">{{ $t("Dates") }}</p>
                  <p class="headtext">
                    {{ DateFormatonTopbet(chartStartDate) }} -
                    {{ DateFormatonTopbet(chartEndDate) }}
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <v-row class="backGG mt-4" justify="center">
              <line-chart
                id="line"
                v-if="loaded"
                :chartData="chartdata"
                :options="options"
                class="linecht"
              ></line-chart>
            </v-row>
            <p
              style="
                font-size: 18px;
                font-family: 'Kanit';
                margin-left: 20px;
                margin-top: 20px;
                color: #f99d20;
              "
            >
              {{ $t("Addition") }} ({{ tot }})
            </p>
            <div id="middlecol">
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :items="additionList"
                hide-default-footer
                hide-default-header
                :items-per-page="itemsPerPage1"
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("employee_name") }}</th>
                      <th>{{ $t("employeetype") }}</th>
                      <th>{{ $t("department") }}</th>
                      <th>{{ $t("division") }}</th>
                      <th>{{ $t("position") }}</th>
                      <th>{{ $t("hired_date") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.employee_Code }}</td>
                    <td>{{ item.fullName }}</td>
                    <td>{{ item.employeeType }}</td>
                    <td>{{ item.department }}</td>
                    <td>{{ item.division }}</td>
                    <td>{{ item.position }}</td>
                    <td>{{ DateFormat(item.hiredDate) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              printDialog = false;
              expit = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-family: kanit, Regular; font-weight: normal"
            @click="downloadPDF()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart";
//import Exporter from "@/function/exporter.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import enurl from "@/api/environment";
import CheckViewExport from "@/function/RolePermissions.js";
import "vue2-datepicker/index.css";
import store from "@/store";
import XLSX from "xlsx";
import $ from "jquery";
export default {
  components: {
    LineChart,
    DatePicker,
  },
  mixins: [CheckViewExport],
  data() {
    return {
      permission: {
        export: true,
      },
      url: enurl.apiUrl,
      loaded: true,
      tot: 0,
      page: 1,
      pageCount: 15,
      itemsPerPage: 5,
      itemsPerPage1: 500,
      items: [5, 10, 20, 30],
      showtablerow: false,
      LoadingDialog: false,
      chartStartDate: null,
      chartEndDate: new Date(),
      todaydate: new Date(),
      expit: "",
      sheetTitle: null,
      thetoto: 0,
      thetofi: 0,
      thetoinbutout: 0,
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      printDialog: false,
      additionList: [],
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      thetemptotal: 0,
      monthname: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      chartdata: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "My First dataset",
            data: [65, 0, 80, 81, 56, 85, 40],
            backgroundColor: "#F69C1F",
            borderColor: "#F69C1F",
            borderWidth: 1,
            fill: true,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          yAxes: [
            {
              label: "My First",
              ticks: {
                min: 0,
                max: 250,
                stepSize: 50,
                reverse: false,
                beginAtZero: true,
              },
              gridLines: {
                color: "rgba(0,0,0,0)",
              },
              scaleLabel: {
                display: true,
                labelString: "No. of Employees",
                fontStyle: "bold",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(0,0,0,0)",
              },
              scaleLabel: {
                display: true,
                labelString: "Time Period",
                fontStyle: "bold",
              },
              ticks: {
                callback: function (value) {
                  return value.trimStart().split(" ")[0];
                },
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
            boxWidth: 0,
          },
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem) =>
              `${tooltipItem.yLabel}, ${this.thespaceback(tooltipItem.label)}`,
            title: () => null,
          },
        },
        gridLines: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.chartStartDate = new Date();
    this.chartStartDate.setDate(1);
    this.chartStartDate.setMonth(0);
    this.chartdata;
    this.getthechart();
    this.permission = this.CheckViewExport(172, 173);
  },
  watch: {
    // chartdata: function () {
    //   this.renderChart(this.chartdata, this.options);
    // },
  },
  methods: {
    back() {
      this.$router.push(`/employmentreport`).catch(()=>{});
    },
    thespaceback(text) {
      return text.trimStart().split(" ")[1];
    },
    OnChangeStartDate() {
      let self = this;
      if (
        moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
      ) {
        self.getthechart();
      } else {
        alert("Start date can’t be after end date.");
      }
    },
    OnChangeEndDate() {
      let self = this;
      if (
        moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
      ) {
        self.getthechart();
      } else {
        alert("Start date can’t be after end date.");
      }
    },

    getthechart() {
      try {
        let self = this;
        let startday = self.chartStartDate.getDate();
        let startmonth = self.chartStartDate.getMonth();
        let startyears = self.chartStartDate.getFullYear();
        let endday = self.chartEndDate.getDate();
        let endmonth = self.chartEndDate.getMonth();
        let endyears = self.chartEndDate.getFullYear();
        let labell = [];
        let dshow = [];
        let startyear = null;
        let endyear = null;
        let company_ID = store.state.companyID;
        self.expit = "";
        if (self.chartStartDate != null || self.chartEndDate != null)
        {
          let tempsdate = self.chartStartDate.setHours(0);
          self.chartStartDate.setMinutes(0);
          self.chartStartDate.setSeconds(0);
          startyear = moment(tempsdate)
            .add(1, "day")
            .utc()
            .format("DD/MM/YYYY");
          let tempedate = self.chartEndDate.setHours(0);
          self.chartEndDate.setMinutes(0);
          self.chartEndDate.setSeconds(0);
          endyear = moment(tempedate).utc().format("DD/MM/YYYY");
        }
        let url = `${self.url}Addition/GetAdditionList?companyID=${company_ID}`;
        if (startyear != null)
        {
          url += `&startDate=${startyear}`;
        }
        if (endyear != null)
        {
          url += `&endDate=${endyear}`;
        }
        self.LoadingDialog = true;
        axios.get(url).then(function (response)
        {
          if(response.data.status == 0)
          {
            self.additionList = response.data.data;
            if (self.additionList.length != 0)
            {
              self.tot = self.additionList[0].total_Staff;
              self.thetoto = self.additionList[0].all_Total_Staff;
              self.thetofi = self.additionList[0].all_Fired_Total_Staff;
              if (self.additionList[0].hired_But_Fired != null)
              {
                self.thetoinbutout = self.additionList[0].hired_But_Fired;
              }
              else
              {
                self.thetoinbutout = 0;
              }
              self.showtablerow = true;
            }
            else
            {
              self.showtablerow = false;
            }
            
            if (startyears == endyears)
            {
              if (startmonth == endmonth)
              {
                if (startday == endday)
                {
                  labell.push(self.monthname[startmonth] + startday);
                }
                else
                {
                  let nu = endday - startday + 1;
                  let dp = startday;
                  for (let i = 0; i < nu; i++)
                  {
                    let ae = self.thetoto - self.thetofi - self.thetoinbutout;
                    let howmany = [];
                    //console.log("theallbefore"+self.thetoto+ "-"+ self.thetofi+ "=" +ae);
                    for (let j = 0; j < self.additionList.length; j++)
                    {
                      let dy = new Date(self.additionList[j].hiredDate + "Z");
                      let ty = "";
                      let yt = 0;
                      let mt = 0;
                      let dt = 0;
                      if (self.additionList[j].terminateDate != null)
                      {
                        ty = new Date(self.additionList[j].terminateDate + "Z");
                        yt = ty.getFullYear();
                        mt = ty.getMonth();
                        dt = ty.getDate();
                      }
                      let tod = 0;
                      if (dy != null && dy != "")
                      {
                        let yr = dy.getFullYear();
                        let dr = dy.getDate();
                        let mr = dy.getMonth();
                        if (startyears == yr)
                        {
                          if (startmonth == mr)
                          {
                            if (dp == dr)
                            {
                              tod++;
                              howmany.push(tod);
                            }
                            if (dr <= dp)
                            {
                              ae = ae + 1;
                            }
                            //console.log(dr+ "<"+ dp +" " +j+"theadd"+ae);
                            if (startyears == yt)
                            {
                              if (startmonth == mt)
                              {
                                if (dp > dt)
                                {
                                  ae = ae - 1;
                                }
                              }
                            }
                            //console.log(dp+ "<="+ dt +" " +j+"theminus"+ae);
                          }
                        }
                      }
                    }
                    if (ae == 0)
                    {
                      labell.push(self.monthname[startmonth] + dp + " " + "0%");
                    }
                    else
                    {
                      labell.push(self.monthname[startmonth] +dp +" " +parseInt((howmany.length / ae) * 100) +"%");
                    }
                    dp++;
                    dshow.push(howmany.length);
                  }
                }
              }
              else
              {
                let nu = endmonth - startmonth + 1;
                let mp = startmonth;
                for (let i = 0; i < nu; i++)
                {
                  let ae = self.thetoto - self.thetofi - self.thetoinbutout;
                  let howmany = [];
                  for (let j = 0; j < self.additionList.length; j++)
                  {
                    let dy = new Date(self.additionList[j].hiredDate + "Z");
                    let ty = "";
                    let yt = 0;
                    let mt = 0;
                    if (self.additionList[j].terminateDate != null)
                    {
                      ty = new Date(self.additionList[j].terminateDate + "Z");
                      yt = ty.getFullYear();
                      mt = ty.getMonth();
                    }
                    let tod = 0;
                    if (dy != null && dy != "")
                    {
                      let yr = dy.getFullYear();
                      let mr = dy.getMonth();
                      if (startyears == yr)
                      {
                        if (mp == mr)
                        {
                          tod++;
                          howmany.push(tod);
                        }
                        if (mr <= mp)
                        {
                          ae = ae + 1;
                        }
                        if (startyears == yt)
                        {
                          if (mp > mt)
                          {
                            ae = ae - 1;
                          }
                        }
                      }
                    }
                  }
                  if (ae == 0)
                  {
                    labell.push(self.monthname[mp] + " " + "0%");
                  }
                  else
                  {
                    labell.push(self.monthname[mp] +" " +parseInt((howmany.length / ae) * 100) +"%");
                  }
                  mp++;
                  dshow.push(howmany.length);
                }
              }
            }
            else
            {
              let nu = endyears - startyears + 1;
              let yp = startyears;
              for (let i = 0; i < nu; i++)
              {
                let ae = self.thetoto - self.thetofi - self.thetoinbutout;
                let howmany = [];
                for (let j = 0; j < self.additionList.length; j++)
                {
                  let dy = new Date(self.additionList[j].hiredDate + "Z");
                  let tod = 0;
                  let ty = "";
                  let yt = 0;
                  if (self.additionList[j].terminateDate != null)
                  {
                    ty = new Date(self.additionList[j].terminateDate + "Z");
                    yt = ty.getFullYear();
                  }
                  if (dy != null && dy != "" && dy != undefined)
                  {
                    let yr = dy.getFullYear();
                    if (yp == yr)
                    {
                      tod++;
                      howmany.push(tod);
                    }
                    if (yr <= yp)
                    {
                      ae = ae + 1;
                    }
                    if (yp > yt && yt != 0)
                    {
                      ae = ae - 1;
                    }
                  }
                }
                if (ae == 0)
                {
                  labell.push(yp + " " + "0%");
                }
                else
                {
                  labell.push(yp + " " + parseInt((howmany.length / ae) * 100) + "%");
                }
                yp++;
                dshow.push(howmany.length);
              }
            }

            let barDataSets = [];
            barDataSets.push({
              label: "My First dataset",
              data: dshow,
              borderWidth: 1,
              backgroundColor: "#F69C1F",
              borderColor: "#F69C1F",
              fill: false,
            });
            self.chartdata = {
              labels: labell,
              datasets: barDataSets,
            };
          }
          else
          {
            self.additionList = [];
            //alert(response.data.message);
          }
          self.LoadingDialog = false;
        });
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },
    changetoexport(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1) {
        // Excel
        if (self.additionList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.additionList) {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.fullName,
                ["ประเภทพนักงาน"]: v.employeeType,
                ["แผนก"]: v.department,
                ["ฝ่าย"]: v.division,
                ["ตำแหน่ง"]: v.position,
                ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hiredDate),
              });
            }
          } else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.additionList) {
                wsdata.push({
                  ["รหัสพนักงาน"]: v.employee_Code,
                  ["ชื่อพนักงาน"]: v.fullName,
                  ["ประเภทพนักงาน"]: v.employeeType,
                  ["แผนก"]: v.department,
                  ["ฝ่าย"]: v.division,
                  ["ตำแหน่ง"]: v.position,
                  ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hiredDate),
                });
              }
            } else {
              for (let v of self.additionList) {
                wsdata.push({
                  ["Employee ID"]: v.employee_Code,
                  ["Employee Name"]: v.fullName,
                  ["Employee Type"]: v.employeeType,
                  ["Department"]: v.department,
                  ["Division"]: v.division,
                  ["Position"]: v.position,
                  ["Hire Date"]: self.DateFormat(v.hiredDate),
                });
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Additions Report`;
          self.sheetTitle = `Additions`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        self.printDialog = true;
        self.todaydate = new Date();
        self.itemsPerPage1 = self.additionList.length;
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.additionList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.additionList) {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.fullName,
                ["ประเภทพนักงาน"]: v.employeeType,
                ["แผนก"]: v.department,
                ["ฝ่าย"]: v.division,
                ["ตำแหน่ง"]: v.position,
                ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hiredDate),
              });
            }
          } else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.additionList) {
                wsdata.push({
                  ["รหัสพนักงาน"]: v.employee_Code,
                  ["ชื่อพนักงาน"]: v.fullName,
                  ["ประเภทพนักงาน"]: v.employeeType,
                  ["แผนก"]: v.department,
                  ["ฝ่าย"]: v.division,
                  ["ตำแหน่ง"]: v.position,
                  ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hiredDate),
                });
              }
            } else {
              for (let v of self.additionList) {
                wsdata.push({
                  ["Employee ID"]: v.employee_Code,
                  ["Employee Name"]: v.fullName,
                  ["Employee Type"]: v.employeeType,
                  ["Department"]: v.department,
                  ["Division"]: v.division,
                  ["Position"]: v.position,
                  ["Hire Date"]: self.DateFormat(v.hiredDate),
                });
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Additions Report`;
          self.sheetTitle = `Additions`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },
    downloadPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Additions Report.pdf");
      });
    },
    DateFormat(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}/${month}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${month}/${day}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTopbet(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
  },
};
</script>
<style scoped>
>>> .mx-input {
  color: #f99d20;
  border: 2px solid #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
.backGG {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}
.ddl {
  float: right;
}
.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
.print-break-page {
  page-break-after: always;
}
.ggg {
  margin-left: 30px;
}
@media print {
  body {
    overflow: auto;
    height: auto;
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
}
@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }
  .ddl {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
  .ddl {
    float: right;
  }
}
@media (max-width: 860px) {
  .ggg {
    margin-left: 0px;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .ggg {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1900px) {
  .movelf {
    margin-right: 2rem;
  }
}
</style>