<template>
  <v-app>
    <navbar
      v-if="
        $route.path != '/' &&
        $route.path != '/new' &&
        $route.path != '/join' &&
        $route.path != '/forgetPassword' &&
        $route.name != 'ResetPassword'
      "
    />
    <v-main style="background-color: #f0f8fc">
      <SessionTimeOut v-if="isIdle && $route.path != '/'"/>
      <CookiePolicy
        :cookDialog="cookDialog"
        @changeTitle="UpdateTitle($event)"
        v-if="$route.path != '/' && cookDialog"
      />
      <UserGuide
        :UserGuideDialog="UserGuideDialog"
        @changeTitle="UpdateTitle1($event)"
        v-if="$route.path != '/' && UserGuideDialog"
      />
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>

<script>
import navbar from "./components/navbar.vue";
import CookiePolicy from "@/views/Welcome/CookiePolicy";
import UserGuide from "@/views/Welcome/UserGuide";
import enurl from "@/api/environment";
import store from "@/store";
import Axios from "axios";
import SessionTimeOut from "./views/SessionTimeOut.vue";
export default {
  name: "App",
  components: {
    navbar,
    CookiePolicy,
    UserGuide,
    SessionTimeOut
  },

  data() {
    return {
      cookDialog: false,
      UserGuideDialog: false,
      manual: true,
      url: enurl.apiUrl,
    };
  },
  computed: {
    isIdle() {
      // console.log(this.$store.state.idleVue.isIdle)
      return this.$store.state.idleVue.isIdle;
    },
  },
  watch: {
    $route() {
      this.cookDialog = false;
      this.UserGuideDialog = false;
      if (this.$route.path != "/" && store.state.userid)
      {
        this.getDataFromParams();
        //this.ManualThing();
      }
    },
  },
  mounted() {
    if (this.$route.path != "/" && store.state.userid)
    {
      this.getDataFromParams();
      //this.ManualThing();
    }
  },

  methods: {
    async getDataFromParams() {
      try {
        let request = {
          userid: store.state.userid,
        };
        if (this.$route.path != "/")
        {
          const response = await Axios.post(`${this.url}Consent/GetConsent`,request);
          let testing = response.data.data;
          if (testing)
          {
            this.cookDialog = false;
            this.ManualThing();
          }
          else
          {
            this.cookDialog = true;
          }
        }
      } catch (ex) {
        throw ex;
      }
    },
    async ManualThing() {
      let self = this;
      let role = store.state.roleID;
      if(role == 'Admin' && this.$route.path != "/" && this.cookDialog == false)
      {
        if(localStorage.FirstLogin ==  1)
        {
          let request = {
            company_ID: store.state.companyID,
            employee_ID: store.state.employeeID
          };
          const response = await Axios.post( `${this.url}Login/AddLoginCount`, request);
          //let msg = response.data.message;
          if (response.data.status == 0)
          {
            self.UserGuideDialog = true;
          }
          else
          {
            self.UserGuideDialog = false;
          }
        }
        else
        {
          self.UserGuideDialog = false;
        }
      }
    },
    UpdateTitle(e) {
      this.cookDialog = e;
    },
    UpdateTitle1(e) {
      this.UserGuideDialog = e;
    },
  },
};
</script>

<style>
  @import './assets/css/style.css';
</style>

<style>
.kanit-medium {
  font-family: "Kanit";
}
.text_transform_none {
  text-transform: none;
}
</style>

<style scoped>
::v-deep .custom-vuedatepicker .mx-input{
  /* height: 48px !important; */
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-top:none !important;
  border-left:none !important;
  border-right:none !important;
  border-radius: 0 !important;
  padding: 5.5px !important; /* Remove all padding */
  line-height: normal; /* Ensure line-height is normal */
  height: auto; /* Ensure height is auto to remove extra space */
  outline: none;
  border-color: #717171 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker .mx-input:disabled, .mx-input.disabled {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.38);
    cursor: default !important;
    border-bottom: 1px dotted #717171;
}

::v-deep .custom-vuedatepicker .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}

::v-deep .custom-vuedatepicker-er-mg .mx-input{
  /* height: 48px !important; */
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-top:none !important;
  border-left:none !important;
  border-right:none !important;
  border-radius: 0 !important;
  padding: 5.5px !important; /* Remove all padding */
  line-height: normal; /* Ensure line-height is normal */
  height: auto; /* Ensure height is auto to remove extra space */
  outline: none;
  border-color: #FF4B55 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker-er-mg .mx-input:disabled, .mx-input.disabled {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.38);
    cursor: default !important;
    border-bottom: 1px dotted #717171;
}

::v-deep .custom-vuedatepicker-er-mg .mx-input:focus{
  border-color: #f89d1f!important;
}
::v-deep .custom-vuedatepicker-er-mg .mx-icon-calendar {
  color: #FF4B55 !important;
}
::v-deep .custom-vuedatepicker-er-mg .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker-er-mg .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}

::v-deep .custom-vuedatepicker1 .mx-input{
  height: 40px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #717171 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker1 .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker1 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker1 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}
::v-deep .custom-vuedatepicker-er-mg1 .mx-input{
  height: 40px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #FF4B55 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker-er-mg1 .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker-er-mg1 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker-er-mg1 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}
::v-deep .custom-vuedatepicker-er-mg1 .mx-icon-calendar {
  color: #FF4B55 !important;
}

::v-deep .custom-vuedatepicker2 .mx-input{
  height: 40px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #E0E0E0 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker2 .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker2 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker2 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;

}::v-deep .custom-vuedatepicker-er-mg2 .mx-input{
  height: 40px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #FF4B55 !important;
  /* margin-top: 1rem; */
}
::v-deep .custom-vuedatepicker-er-mg2 .mx-icon-calendar {
  color: #FF4B55 !important;
}
::v-deep .custom-vuedatepicker-er-mg2 .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker-er-mg2 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker-er-mg2 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}

::v-deep .customicon-vuedatepicker .mx-input{
  height: 40px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #E0E0E0 !important;
  /* margin-top: 1rem; */
}

::v-deep .customicon-vuedatepicker .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .customicon-vuedatepicker .mx-input:focus + .mx-icon-calendar,
::v-deep .customicon-vuedatepicker .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}
::v-deep .error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
</style>