<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div
      style="margin-bottom: 1rem; margin-top: 5px"
    >
      <v-row>
        <v-btn
          icon
          router
          color="white"
          :to="{ name: 'timeentries' }"
          class="mt-6"
        >
          <img src="@/assets/images/back button.png" class="mb-1" />
        </v-btn>
        <p
          style="
            font-size: 25px;
            margin-left: 4px;
            margin-top: 20px;
          "
        >
          {{ $t("TimesheetDetails") }}
        </p>
      </v-row>
    </div>
    <v-card class="mt-6" style="margin-bottom: 5rem">
      <div style="margin-left: 2rem; margin-right: 2rem">
        <v-row>
          <v-col cols="6" sm="6" md="3">
            <div class="d-flex">
              <div>
                <v-avatar width="60" height="60">
                  <img :src="profileimage" />
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="mt-1"></p>
                <p class="txt16">{{ profilename }}</p>
                <p style="color: #acacac" class="txt14">
                  {{ profileposition }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-top: -15px">
          <v-col cols="12" sm="6" md="6">
            <p class="txt14">
              {{ $t("reportClockIn")
              }}<span class="ml-2">{{ formatDate(profiledate) }}</span>
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p class="txt14">
              {{ $t("reportClockOut")
              }}<span class="ml-2">{{ formatDate(profilcoutedate) }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row style="margin-top: -5px">
          <v-col cols="12" sm="6" md="6">
            <p class="txt14">
              {{ $t("totalRegularHours") }}:<span class="ml-2"
                >{{ totaltime }} {{ $t("Hours") }}</span
              >
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p class="txt14">
              {{ $t("TotalOTHours") }} :<span class="ml-2"
                >{{ ottotaltime }}  {{ $t("Hours") }}</span
              >
            </p>
          </v-col>
        </v-row>
        <p class="txt18 mt-5"> {{ $t("TimeEntryDetails") }}</p>
        <v-data-table
          class="expandsize"
          :headers="headers"
          :items="TimetableItem"
          hide-default-footer
          hide-default-header
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th style="width:200px">{{ $t("PunchType") }}</th>
                <th style="width:200px">{{ $t("Time") }}</th>
                <th>{{ $t("Location") }}</th>
                <th style="text-align: center">{{ $t("Late") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.PunchType }}</td>
              <td>{{ Converttolocal(item.Time) }}</td>
              <td style="text-align: left">{{ item.Location }}</td>
              <td style="text-align: center">{{ item.Late }}</td>
            </tr>
          </template>
        </v-data-table>
        <p class="txt18 mt-5"> {{ $t("TimeOffDetails") }}</p>
        <v-data-table
          :headers="timeoffheaders"
          :items="TimeOffTableItem"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th> {{ $t("details") }}</th>
                <th>{{ $t("Time") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td style="text-align: left">{{ item.detail }}</td>
              <td style="text-align: left">{{ item.time }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div style="height: 20vh"></div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
export default {
  data() {
    return {
      url: enurl.apiUrl,
      TimeSheetList: [],
      TimeSettingList: [],
      profileimage: "",
      profilename: null,
      profileposition: null,
      profiledate: "",
      profilcoutedate: "",
      totaltime: null,
      ottotaltime: null,
      LoadingDialog: false,
      TimetableItem: [],
      OTList: [],
      startbefore: null,
      startbeforetime: null,
      startafter: null,
      startaftertime: null,
      endbefore: null,
      endbeforetime: null,
      endafter: null,
      endaftertime: null,
      timetype: null,
      RegularList: [],
      OverTimeList: [],
      headers: [
        {
          text: "Punch Type",
          value: "PunchType",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "Time",
          align: "left",
          sortable: false,
        },
        {
          text: "Location",
          value: "Location",
          align: "left",
          sortable: false,
        },
        {
          text: "Late",
          value: "Late",
          align: "center",
          sortable: false,
        },
      ],
      TimeOffTableItem: [],
      timeoffheaders: [
        {
          text: "Detail",
          value: "detail",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "time",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    await this.GetProfile();
    let tc = JSON.parse(localStorage.getItem("timoffcnd"));
    if (tc == true || tc == "true") {
      this.GetTimeWholeDayOFF();
    } else {
      await this.GetTimesheet();
    }
    await this.GetTimeAttSetting();
  },
  methods: {
    Converttolocal(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        // let [hours, minutes] = item.split(":");
        // todate.setHours(+hours);
        // todate.setMinutes(minutes);
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    // GetOT() {
    //   let self = this;
    //   let temp = {
    //     employee_ID: store.state.employeeID,
    //     company_ID: store.state.companyID,
    //   };
    //   axios
    //     .post(`${self.url}OT_Request/GetRequestforstaff`, temp)
    //     .then(function (response) {
    //       self.OTList = response.data.data;
    //     });
    // },
    async GetTimeAttSetting() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios
        .post(
          `${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,
          temp
        )
        .then(function(response) {
          self.TimeSettingList = response.data.data;
          self.LoadingDialog = false;

          let [
            stbefore,
            stbeforetime,
          ] = self.TimeSettingList[0].start_Time_Before.split(" ");
          let [
            stafter,
            staftertime,
          ] = self.TimeSettingList[0].start_Time_After.split(" ");
          self.startbefore = stbefore;
          self.startbeforetime = stbeforetime;
          self.startafter = stafter;
          self.startaftertime = staftertime;

          let [
            edbefore,
            edbeforetime,
          ] = self.TimeSettingList[0].end_Time_Before.split(" ");
          let [
            edafter,
            edaftertime,
          ] = self.TimeSettingList[0].end_Time_After.split(" ");
          self.endbefore = edbefore;
          self.endbeforetime = edbeforetime;
          self.endafter = edafter;
          self.endaftertime = edaftertime;
        });
    },
    async GetProfile() {
      let self = this;
      self.LoadingDialog = true;
      let NowDay = JSON.parse(localStorage.getItem("dayoff_today"));
      let temp = {
        employee_ID: store.state.employeeID,
        today_Date: self.formatDate(NowDay),
      };
      await axios
        .post(`${self.url}Stafftimeattendance/GetEmployeeDataOfSTA`, temp)
        .then(function(response) {
          self.profileimage = response.data.data.image;
          self.profilename = response.data.data.fullName;
          self.profileposition = response.data.data.position_Name;
          self.profiledate = response.data.data.timeEntry_Date;
          self.LoadingDialog = false;
        });
    },
    GetTimeWholeDayOFF() {
      let self = this;
      self.totaltime = 0;
      self.ottotaltime = 0;
      let st = JSON.parse(localStorage.getItem("lclstarttime"));
      let et = JSON.parse(localStorage.getItem("lclendtime"));
      let rt = st + " - " + et;
      let remark = JSON.parse(localStorage.getItem("dayoff_remark"));
      self.TimeOffTableItem.push({
        detail: remark,
        time: rt,
      });
    },
    async GetTimesheet() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        time_ID: parseInt(this.$route.params.id),
        employee_ID: store.state.employeeID,
      };

      await axios
        .post(`${self.url}ClockInOut/GetClockInOutBy2ID`, temp)
        .then(function(response) {
          self.TimeSheetList = response.data.data;
          if (self.TimeSheetList.length != 0) {
            self.profiledate = self.TimeSheetList[0].timeEntry_Date;
            self.timetype = self.TimeSheetList[0].time_Type;

            for (let i = 0; i < self.TimeSheetList.length; i++) {
              if (self.TimeSheetList[i].time_Type == "Regular") {
                let Lists = {
                  ...self.TimeSheetList[i],
                };
                self.RegularList.push(Lists);
              } else {
                let Lists = {
                  ...self.TimeSheetList[i],
                };
                self.OverTimeList.push(Lists);
              }
            }

            //total hour
            let shiftstart = new Date(
              Date.parse(self.TimeSheetList[0].timeEntry_Date)
            );
            let shiftend = new Date(
              Date.parse(self.TimeSheetList[0].timeEntry_Date)
            );
            let otshiftstart = new Date(
              Date.parse(self.TimeSheetList[0].timeEntry_Date)
            );
            let otshiftend = new Date(
              Date.parse(self.TimeSheetList[0].timeEntry_Date)
            );
            let otstime = localStorage.getItem("ot_shift_start");
            let otetime = localStorage.getItem("ot_shift_end");

            let [sshours, ssminutes] = self.TimeSheetList[0].start_Time.split(
              ":"
            );
            let [sehours, seminutes] = self.TimeSheetList[0].end_Time.split(
              ":"
            );
            let [otsshours, otssminutes] = otstime.split(":");
            let [otsehours, otseminutes] = otetime.split(":");

            shiftstart.setHours(+sshours);
            shiftstart.setMinutes(ssminutes);
            otshiftstart.setHours(+otsshours);
            otshiftstart.setMinutes(otssminutes);

            // if (self.startaftertime == "hour") {
            //   let adds = shiftstart.getHours() + parseInt(self.startafter);
            //   shiftstart.setHours(+adds);
            // } else if (self.startaftertime == "min") {
            //   let adds = shiftstart.getMinutes() + parseInt(self.startafter);
            //   shiftstart.setMinutes(adds);
            // }
            // if (self.startaftertime == "hour") {
            //   let adds = otshiftstart.getHours() + parseInt(self.startafter);
            //   otshiftstart.setHours(+adds);
            // } else if (self.startaftertime == "min") {
            //   let adds = otshiftstart.getMinutes() + parseInt(self.startafter);
            //   otshiftstart.setMinutes(adds);
            // }

            if (sehours > 10) {
              shiftend.setHours(+sehours);
              shiftend.setMinutes(seminutes);
            } else if (sehours <= 10) {
              shiftend.setHours(+sehours);
              shiftend.setMinutes(seminutes);
              let addone = shiftend.getDate();
              shiftend.setDate(addone + 1);
            }
            if (otsehours > 10) {
              otshiftend.setHours(+otsehours);
              otshiftend.setMinutes(otseminutes);
            } else if (otsehours <= 10) {
              otshiftend.setHours(+otsehours);
              otshiftend.setMinutes(otseminutes);
              let addone = otshiftend.getDate();
              otshiftend.setDate(addone + 1);
            }

            if (self.endaftertime == "hour") {
              let adds = shiftend.getHours() + parseInt(self.endafter);
              shiftend.setHours(adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftend.getMinutes() + parseInt(self.endafter);
              shiftend.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = otshiftend.getHours() + parseInt(self.endafter);
              otshiftend.setHours(adds);
            } else if (self.endaftertime == "min") {
              let adds = otshiftend.getMinutes() + parseInt(self.endafter);
              otshiftend.setMinutes(adds);
            }

            //for reg total hour
            if (self.RegularList.length != 0) {
              //let leg1 = self.RegularList.length - 1;
              let regfrom = null;
              //let regto = null;
              let firstc = null;
              let lastc = null;
              let firstlocate = null;
              let lastlocate = null;
              if (self.RegularList[0].clock_In_Time != "-") {
                firstc = self.RegularList[0].clock_In_Time;
                firstlocate = self.RegularList[0].clock_In_Location;
                regfrom = new Date(
                  Date.parse(self.RegularList[0].clock_In_Time)
                );
              } else if (self.RegularList[0].irregular_In_Time != "-") {
                firstc = self.RegularList[0].irregular_In_Time;
                firstlocate = self.RegularList[0].irregular_In_Location;
                regfrom = new Date(
                  Date.parse(self.RegularList[0].irregular_In_Time)
                );
              }
              if (self.RegularList[0].clock_Out_Time != "-") {
                lastc = self.RegularList[0].clock_Out_Time;
                lastlocate = self.RegularList[0].clock_Out_Location;
                // regto = new Date(
                //   Date.parse(self.RegularList[0].clock_Out_Time)
                // );
              } else if (self.RegularList[0].irregular_Out_Time != "-") {
                lastc = self.RegularList[0].irregular_Out_Time;
                lastlocate = self.RegularList[0].irregular_Out_Location;
                // regto = new Date(
                //   Date.parse(self.RegularList[0].irregular_Out_Time)
                // );
              }
              let frtimezone = regfrom.getTimezoneOffset() * 60000;
              //let totimezone = regto.getTimezoneOffset() * 60000;
              let frnewDate = new Date(regfrom.getTime() - frtimezone);
              //let tonewDate = new Date(regto.getTime() - totimezone);

              let outlist = [];
              let outtime = "-";
              for (let d = 0; d < self.RegularList.length; d++) {
                outlist.unshift({
                  regtime: self.RegularList[d].clock_Out_Time,
                  iregtime: self.RegularList[d].irregular_Out_Time,
                });
              }
              for (let e = 0; e < outlist.length; e++) {
                if (outlist[e].regtime != "-") {
                  outtime = outlist[e].regtime;
                  break;
                } else if (outlist[e].iregtime != "-") {
                  outtime = outlist[e].iregtime;
                  break;
                }
              }
              let otto = new Date(Date.parse(outtime));
              let tootimezone = otto.getTimezoneOffset() * 60000;
              let toonewDate = new Date(otto.getTime() - tootimezone);

              //if(self.RegularList[leg1].irregular_Out_Time != "-" || self.RegularList[leg1].clock_Out_Time != "-"){
              var timeDiff = Math.abs(toonewDate - frnewDate);
              var hh = Math.floor(timeDiff / 1000 / 60 / 60);
              if (hh < 10) {
                hh = "0" + hh;
              }
              timeDiff -= hh * 1000 * 60 * 60;
              var mm = Math.floor(timeDiff / 1000 / 60);
              if (mm < 10) {
                mm = "0" + mm;
              }
              timeDiff -= mm * 1000 * 60;
              var ss = Math.floor(timeDiff / 1000);
              if (ss < 10) {
                ss = "0" + ss;
              }
              self.totaltime = hh + ":" + mm + " ";
              //}else{
              // self.totaltime = "-";
              //}

              //regular late time
              let ssnewDate = new Date(shiftstart.getTime());
              let sslatetime = "";
              let selatetime = "";

              if (frnewDate > ssnewDate) {
                var sstimeDiff = Math.abs(frnewDate - ssnewDate);
                var hh1 = Math.floor(sstimeDiff / 1000 / 60 / 60);
                if (hh1 < 10) {
                  hh1 = "0" + hh1;
                }
                sstimeDiff -= hh1 * 1000 * 60 * 60;
                var mm1 = Math.floor(sstimeDiff / 1000 / 60);
                if (mm1 < 10) {
                  mm1 = "0" + mm1;
                }
                sstimeDiff -= mm1 * 1000 * 60;
                var ss1 = Math.floor(sstimeDiff / 1000);
                if (ss1 < 10) {
                  ss1 = "0" + ss1;
                }
                sslatetime = hh1 + ":" + mm1 + "Hours";
              } else {
                sslatetime = "-";
              }
              selatetime = "-";

              for (let s = 0; s < self.RegularList.length; s++) {
                if (s >= 1) {
                  if (self.RegularList[s].clock_In_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Clock-In",
                      Time: self.RegularList[s].clock_In_Time,
                      Location: self.RegularList[s].clock_In_Location,
                      Late: "-",
                    });
                  } else if (self.RegularList[s].irregular_In_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Clock-In",
                      Time: self.RegularList[s].irregular_In_Time,
                      Location: self.RegularList[s].irregular_In_Location,
                      Late: "-",
                    });
                  }
                  if (self.RegularList[s].clock_Out_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Clock-Out",
                      Time: self.RegularList[s].clock_Out_Time,
                      Location: self.RegularList[s].clock_Out_Location,
                      Late: "-",
                    });
                  } else if (self.RegularList[s].irregular_Out_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Clock-Out",
                      Time: self.RegularList[s].irregular_Out_Time,
                      Location: self.RegularList[s].irregular_Out_Location,
                      Late: "-",
                    });
                  }
                } else {
                  if (firstc != null) {
                    self.TimetableItem.push({
                      PunchType: "Clock-In",
                      Time: firstc,
                      Location: firstlocate,
                      Late: sslatetime,
                    });
                    if (lastc != null) {
                      self.TimetableItem.push({
                        PunchType: "Clock-Out",
                        Time: lastc,
                        Location: lastlocate,
                        Late: selatetime,
                      });
                    }
                  }
                }
              }
            } else {
              self.totaltime = "-";
              self.TimetableItem.push({
                PunchType: "Clock-In",
                Time: "-",
                Location: "-",
                Late: "-",
              });
              self.TimetableItem.push({
                PunchType: "Clock-Out",
                Time: "-",
                Location: "-",
                Late: "-",
              });
            }

            // for ot total hour
            if (self.OverTimeList.length != 0) {
              //let leg2 = self.OverTimeList.length - 1;
              let ovtfrom = null;
              let ovtto = null;
              let ovtfirstc = null;
              let ovtlastc = null;
              let ovtfirstlocate = null;
              let ovtlastlocate = null;
              if (self.OverTimeList[0].clock_In_Time != "-") {
                ovtfirstc = self.OverTimeList[0].clock_In_Time;
                ovtfirstlocate = self.OverTimeList[0].clock_In_Location;
                ovtfrom = new Date(
                  Date.parse(self.OverTimeList[0].clock_In_Time)
                );
              } else if (self.OverTimeList[0].irregular_In_Time != "-") {
                ovtfirstc = self.OverTimeList[0].irregular_In_Time;
                ovtfirstlocate = self.OverTimeList[0].irregular_In_Location;
                ovtfrom = new Date(
                  Date.parse(self.OverTimeList[0].irregular_In_Time)
                );
              }
              if (self.OverTimeList[0].clock_Out_Time != "-") {
                ovtlastc = self.OverTimeList[0].clock_Out_Time;
                ovtlastlocate = self.OverTimeList[0].clock_Out_Location;
                ovtto = new Date(
                  Date.parse(self.OverTimeList[0].clock_Out_Time)
                );
              } else if (self.OverTimeList[0].irregular_Out_Time != "-") {
                ovtlastc = self.OverTimeList[0].irregular_Out_Time;
                ovtlastlocate = self.OverTimeList[0].irregular_Out_Location;
                ovtto = new Date(
                  Date.parse(self.OverTimeList[0].irregular_Out_Time)
                );
              }
              let ovtfrtimezone = ovtfrom.getTimezoneOffset() * 60000;
              let ovttotimezone = ovtto.getTimezoneOffset() * 60000;
              let ovtfrnewDate = new Date(ovtfrom.getTime() - ovtfrtimezone);
              let ovttonewDate = new Date(ovtto.getTime() - ovttotimezone);

              if (ovtfirstc != "-" && ovtlastc != "-") {
                var otimeDiff = Math.abs(ovttonewDate - ovtfrnewDate);
                var ohh = Math.floor(otimeDiff / 1000 / 60 / 60);
                if (ohh < 10) {
                  ohh = "0" + ohh;
                }
                otimeDiff -= ohh * 1000 * 60 * 60;
                var omm = Math.floor(otimeDiff / 1000 / 60);
                if (omm < 10) {
                  omm = "0" + omm;
                }
                otimeDiff -= omm * 1000 * 60;
                var oss = Math.floor(otimeDiff / 1000);
                if (oss < 10) {
                  oss = "0" + oss;
                }
                self.ottotaltime = ohh + ":" + omm + " ";
              } else {
                self.ottotaltime = "-";
              }
              //overtime late time
              let otssnewDate = new Date(otshiftstart.getTime());
              let otsslatetime = "";
              let otselatetime = "";

              if (ovtfrnewDate > otssnewDate) {
                var otsstimeDiff = Math.abs(ovtfrnewDate - otssnewDate);
                var othh1 = Math.floor(otsstimeDiff / 1000 / 60 / 60);
                if (othh1 < 10) {
                  othh1 = "0" + othh1;
                }
                otsstimeDiff -= othh1 * 1000 * 60 * 60;
                var otmm1 = Math.floor(otsstimeDiff / 1000 / 60);
                if (otmm1 < 10) {
                  otmm1 = "0" + otmm1;
                }
                otsstimeDiff -= otmm1 * 1000 * 60;
                var otss1 = Math.floor(otsstimeDiff / 1000);
                if (otss1 < 10) {
                  otss1 = "0" + otss1;
                }
                otsslatetime = othh1 + ":" + otmm1 + "Hours";
              } else {
                otsslatetime = "-";
              }
              otselatetime = "-";

              for (let t = 0; t < self.OverTimeList.length; t++) {
                if (t >= 1) {
                  if (self.OverTimeList[t].clock_In_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Overtime Clock-In",
                      Time: self.OverTimeList[t].clock_In_Time,
                      Location: self.OverTimeList[t].clock_In_Location,
                      Late: otsslatetime,
                    });
                  } else if (self.OverTimeList[t].irregular_In_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Overtime Clock-In",
                      Time: self.OverTimeList[t].irregular_In_Time,
                      Location: self.OverTimeList[t].irregular_In_Location,
                      Late: otsslatetime,
                    });
                  }
                  if (self.OverTimeList[t].clock_Out_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Overtime Clock-Out",
                      Time: self.OverTimeList[t].clock_Out_Time,
                      Location: self.OverTimeList[t].clock_Out_Location,
                      Late: "-",
                    });
                  } else if (self.OverTimeList[t].irregular_Out_Time != "-") {
                    self.TimetableItem.push({
                      PunchType: "Overtime Clock-Out",
                      Time: self.OverTimeList[t].irregular_Out_Time,
                      Location: self.OverTimeList[t].irregular_Out_Location,
                      Late: "-",
                    });
                  }
                } else {
                  if (ovtfirstc != null) {
                    self.TimetableItem.push({
                      PunchType: "Overtime Clock-In",
                      Time: ovtfirstc,
                      Location: ovtfirstlocate,
                      Late: otsslatetime,
                    });
                    if (ovtlastc != null) {
                      self.TimetableItem.push({
                        PunchType: "Overtime Clock-Out",
                        Time: ovtlastc,
                        Location: ovtlastlocate,
                        Late: otselatetime,
                      });
                    }
                  }
                }
              }
            } else {
              self.ottotaltime = "-";
              self.TimetableItem.push({
                PunchType: "Overtime Clock-In",
                Time: "-",
                Location: "-",
                Late: "-",
              });
              self.TimetableItem.push({
                PunchType: "Overtime Clock-Out",
                Time: "-",
                Location: "-",
                Late: "-",
              });
            }
          }

          //for clock out date

            let lstout = self.TimetableItem.filter(
              (val) => val.PunchType == "Clock-Out"
            );
            let lstotout = self.TimetableItem.filter(
              (val) => val.PunchType == "Overtime Clock-Out"
            );
            if (lstout.length != 0) {
              let leg = lstout.length - 1;
              let torda = lstout[leg].Time;
              let leg1 = lstotout.length - 1;
              let torda1 = lstotout[leg1].Time;
              //let whtda = lstout[leg].irregular_Out_Time;
              if (torda != "-" && torda != "" && torda != null) {
                let to = new Date(Date.parse(torda));
                let timezone = to.getTimezoneOffset() * 60000;
                let newDate = new Date(to.getTime() - timezone);
                let getmon = parseInt(newDate.getMonth()) + 1;
                let getdate = parseInt(newDate.getDate());
                if (getmon < 10) {
                  getmon = "0" + getmon;
                }
                if (getdate < 10) {
                  getdate = "0" + getdate;
                }
                self.profilcoutedate =
                  to.getFullYear() + "-" + getmon + "-" + getdate;
              }
              else if (torda1 != "-" && torda1 != "" && torda1 != null) {
                let to = new Date(Date.parse(torda1));
                let timezone = to.getTimezoneOffset() * 60000;
                let newDate = new Date(to.getTime() - timezone);
                let getmon = parseInt(newDate.getMonth()) + 1;
                let getdate = parseInt(newDate.getDate());
                if (getmon < 10) {
                  getmon = "0" + getmon;
                }
                if (getdate < 10) {
                  getdate = "0" + getdate;
                }
                self.profilcoutedate =
                  to.getFullYear() + "-" + getmon + "-" + getdate;
              }
              else {
                self.profilcoutedate = "";
              }
            } else {
              self.profilcoutedate = "";
            }
            //for clock out date

          let tc = JSON.parse(localStorage.getItem("timoffcnd"));
          if (tc == false || tc == "false") {
            let st = JSON.parse(localStorage.getItem("to_shift_start"));
            let et = JSON.parse(localStorage.getItem("to_shift_end"));
            let rt = st + " - " + et;
            let remark = JSON.parse(localStorage.getItem("dayoff_remark"));
            self.TimeOffTableItem.push({
              detail: remark,
              time: rt,
            });
          }

          // if (tonewDate > senewDate) {
          //   var setimeDiff = Math.abs(tonewDate - senewDate);
          //   var hh2 = Math.floor(setimeDiff / 1000 / 60 / 60);
          //   if (hh2 < 10) {
          //     hh2 = "0" + hh2;
          //   }
          //   setimeDiff -= hh2 * 1000 * 60 * 60;
          //   var mm2 = Math.floor(setimeDiff / 1000 / 60);
          //   if (mm2 < 10) {
          //     mm2 = "0" + mm2;
          //   }
          //   setimeDiff -= mm2 * 1000 * 60;
          //   var ss2 = Math.floor(setimeDiff / 1000);
          //   if (ss2 < 10) {
          //     ss2 = "0" + ss2;
          //   }
          //   selatetime = hh2 + ":" + mm2 + "Hours";
          // } else {

          //}

          self.LoadingDialog = false;
        });
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    formatDate1(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("/");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
  },
};
</script>
<style scoped>
.txt14 {
  font-weight: normal;
  font-size: 14px;
}
.txt16 {
  font-weight: normal;
  font-size: 16px;
}
.txt18 {
  font-weight: normal;
  font-size: 18px;
}
.txtleft {
  margin-left: -150px !important;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 15px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
@media (max-width: 1675px) {
  .txtleft {
    margin-left: 0px !important;
  }
}
@media (max-width: 650px) {
  .expandsize {
    max-width: 770px;
    border-spacing: 0;
  }
}
</style>
