<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("address") }}
        </p>
    </div>

    <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 2.5rem;">
      <v-tabs
        show-arrows
        grey--text
        background-color="rgb(240 248 252)"
        slider-color="#F99D20">
        <v-tab
          @click="originalCountry(1)"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px">
          {{ $t("country") }}
        </v-tab>
        <v-tab
          @click="originalCountry(2)"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px">
          {{ $t("province") }}
        </v-tab>
        <v-tab
          @click="originalCountry(3)"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px">
          {{ $t("district") }}
        </v-tab>
        <v-tab
          @click="originalCountry(4)"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px">
          {{ $t("subdistrict") }}
        </v-tab>
        <v-tab
          @click="originalCountry(5)"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px">
          {{ $t("postalcode") }}
        </v-tab>

        <!-- Country -->
        <v-tab-item>
          <v-card class="ml-0 pt-5">
            <p style="font-size: 21px; margin-left: 30px; margin-top: 20px">
              {{ $t("country") }}
            </p>
            <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
              <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                <v-text-field :placeholder="$t('Search')"
                  v-model="countrysearchtxt"
                  color="#F99D20"
                  dense
                  outlined
                  autocomplete="off"
                  prepend-inner-icon="mdi-magnify"
                  style="margin-top: -0.1rem; margin-left: 0.5rem"
                  @keyup.enter="Search(1)"></v-text-field>
              </v-col>
                <!-- <v-text-field
                  autocomplete="off"
                  :placeholder="$t('Search') + ' ' + $t('country')"
                  v-model.lazy="countrysearchtxt"
                  color="#F99D20"
                  dense
                  style="margin-top: -0.1rem; margin-left: 0.5rem">
                </v-text-field>
              </v-col>
              <v-btn
                color="#F99D20"
                @click="Search(1)"
                class="white--text text-capitalize ml-5 mt-2"
                width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                color="#F99D20"
                class="orange--text btnres text-capitalize ml-5 mt-2"
                width="150"
                max-height="35"
                @click="CountryUploaddialog = true"
                outlined
                :disabled="permission.upload"
                >{{ $t("upload") }}</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text btnres text-capitalize ml-5 mr-5 mt-2"
                width="150"
                max-height="35"
                :disabled="permission.add"
                @click="CountryAdddialog = true"
                >{{ $t("Add") }}</v-btn
              >
            </v-row>
            <v-data-table
              style="margin-left: 40px; margin-right: 40px"
              class="tbheader"
              :headers="countryheaders"
              :items="CountryList"
              :page.sync="page"
              hide-default-header
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunCountry('country_Name')"
                    >
                      {{ $t("country") }}
                      <v-icon small v-if="!country_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="country_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.country_Name }}</td>
                  <td>
                    <v-btn @click="EditUCountry(item)" icon :disabled="permission.edit">
                      <img class="editIcon" :src="images.edit" alt="editIcon"/>
                    </v-btn>
                    <v-btn @click="OpenCountryDeletedialog(item.country_ID)" icon :disabled="permission.delete || item.disablethis">
                      <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    color="#FFCD2C"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    :items="items"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-tab-item>
        
        <!-- Province -->
        <v-tab-item>
          <v-card class="ml-0 pt-5">
            <p style="font-size: 21px; margin-left: 30px; margin-top: 20px">
              {{ $t("province") }}
            </p>
            <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
              <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                <v-text-field
                  :placeholder="$t('Search')"
                  v-model="provincesearchtxt"
                  color="#F99D20"
                  dense
                  outlined
                  autocomplete="off"
                  prepend-inner-icon="mdi-magnify"
                  style="margin-top: -0.1rem; margin-left: 1rem"
                  @keyup.enter="Search(2)"></v-text-field>
                </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="4">
                <v-text-field
                  autocomplete="off"
                  :placeholder="$t('Search') + ' ' + $t('province')"
                  v-model.lazy="provincesearchtxt"
                  color="#F99D20"
                  dense
                  style="margin-top: -0.1rem; margin-left: 1rem">
                </v-text-field>
              </v-col>
              <v-btn
                color="#F99D20"
                @click="Search(2)"
                class="white--text text-capitalize ml-5 mt-2"
                width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                color="#F99D20"
                class="orange--text btnres text-capitalize ml-5 mt-2"
                width="150"
                max-height="35"
                @click="ProvinceUploaddialog = true"
                outlined
                :disabled="permission.upload"
                >{{ $t("upload") }}</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text btnres text-capitalize ml-5 mr-5 mt-2"
                width="150"
                max-height="35"
                @click="ProvinceAdddialog = true"
                :disabled="permission.add"
                >{{ $t("Add") }}</v-btn
              >
            </v-row>
            <v-data-table
              style="margin-left: 40px; margin-right: 40px"
              class="tbheader"
              :headers="provinceheaders"
              :items="ProvinceList"
              :page.sync="page1"
              :items-per-page="itemsPerPage1"
              hide-default-footer
              @page-count="pageCount1 = $event"
              hide-default-header
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunProvince('province_Name')"
                    >
                      {{ $t("province") }}
                      <v-icon small v-if="!province_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="province_Name">mdi-arrow-up</v-icon>
                    </th>

                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunProvince('country_Name')"
                    >
                      {{ $t("country") }}
                      <v-icon small v-if="!country_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="country_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.province_Name }}</td>
                  <td>{{ item.country_Name }}</td>
                  <td>
                    <v-btn
                      @click="EditUProvince(item)"
                      icon
                      :disabled="permission.edit"
                    >
                      <img class="editIcon" :src="images.edit"/>
                    </v-btn>
                    <v-btn
                      :disabled="permission.delete || item.disablethis"
                      @click="OpenProvinceDeletedialog(item.province_ID)"
                      icon
                    >
                      <img class="deleteIcon" :src="images.delete"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page1"
                    :length="pageCount1"
                    color="#FFCD2C"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage1"
                    :items="items"
                    @input="itemsPerPage1 = parseInt($event, 10)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-tab-item>
        
        <!-- District -->
        <v-tab-item>
          <v-card class="ml-0 pt-5">
            <p style="font-size: 21px; margin-left: 30px; margin-top: 20px">
              {{ $t("district") }}
            </p>
            <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
              <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                <v-text-field
                  :placeholder="$t('Search')"
                  v-model="districtsearchtxt"
                  color="#F99D20"
                  dense
                  outlined
                  autocomplete="off"
                  prepend-inner-icon="mdi-magnify"
                  style="margin-top: -0.1rem; margin-left: 1rem"
                  @keyup.enter="Search(3)"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="4">
                <v-text-field
                  autocomplete="off"
                  :placeholder="$t('Search') + ' ' + $t('district')"
                  v-model.lazy="districtsearchtxt"
                  color="#F99D20"
                  dense
                  style="margin-top: -0.1rem; margin-left: 1rem"
                ></v-text-field>
              </v-col>
              <v-btn
                color="#F99D20"
                @click="Search(3)"
                class="white--text text-capitalize ml-5 mt-2"
                width="120"
                max-height="35"
              >
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                :disabled="permission.upload"
                color="#F99D20"
                class="orange--text btnres text-capitalize ml-5 mt-2"
                width="150"
                max-height="35"
                @click="DistrictUploaddialog = true"
                outlined
                >{{ $t("upload") }}</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text btnres text-capitalize ml-5 mr-5 mt-2"
                width="150"
                max-height="35"
                @click="DistrictAdddialog = true"
                :disabled="permission.add"
                >{{ $t("Add") }}</v-btn
              >
            </v-row>
            <v-data-table
              style="margin-left: 40px; margin-right: 40px"
              class="tbheader"
              :headers="districtheaders"
              :items="DistrictList"
              :page.sync="page2"
              :items-per-page="itemsPerPage2"
              hide-default-footer
              hide-default-header
              @page-count="pageCount2 = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunDistrict('district_Name')"
                    >
                      {{ $t("district") }}
                      <v-icon small v-if="!district_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="district_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunDistrict('province_Name')"
                    >
                      {{ $t("province") }}
                      <v-icon small v-if="!province_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="province_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunDistrict('country_Name')"
                    >
                      {{ $t("country") }}
                      <v-icon small v-if="!country_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="country_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.district_Name }}</td>
                  <td>{{ item.province_Name }}</td>
                  <td>{{ item.country_Name }}</td>
                  <td>
                    <v-btn
                      @click="EditUDistrict(item)"
                      icon
                      :disabled="permission.edit"
                    >
                      <img class="editIcon" :src="images.edit"/>
                    </v-btn>
                    <v-btn
                      @click="OpenDistrictDeletedialog(item.district_ID)"
                      icon
                      :disabled="permission.delete || item.disablethis"
                    >
                      <img class="deleteIcon" :src="images.delete"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page2"
                    :length="pageCount2"
                    color="#FFCD2C"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage2"
                    :items="items"
                    @input="itemsPerPage2 = parseInt($event, 10)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-tab-item>
        
        <!-- Sub-District -->
        <v-tab-item>
          <v-card class="ml-0 pt-5">
            <p style="font-size: 21px; margin-left: 30px; margin-top: 20px">
              {{ $t("subdistrict") }}
            </p>
            <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
              <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                <v-text-field
                  :placeholder="$t('Search')"
                  v-model="subdistrictsearchtxt"
                  color="#F99D20"
                  dense
                  outlined
                  autocomplete="off"
                  prepend-inner-icon="mdi-magnify"
                  style="margin-top: -0.1rem; margin-left: 1rem"
                  @keyup.enter="Search(4)"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="4">
                <v-text-field
                  autocomplete="off"
                  :placeholder="$t('Search') + ' ' + $t('subdistrict')"
                  v-model.lazy="subdistrictsearchtxt"
                  color="#F99D20"
                  dense
                  style="margin-top: -0.1rem; margin-left: 1rem">
                </v-text-field>
              </v-col>
              <v-btn
                color="#F99D20"
                @click="Search(4)"
                class="white--text text-capitalize ml-5 mt-2"
                width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                color="#F99D20"
                class="orange--text btnres text-capitalize ml-5 mt-2"
                width="150"
                max-height="35"
                @click="SubDistrictUploadDialog = true"
                outlined
                :disabled="permission.upload"
                >{{ $t("upload") }}</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text btnres text-capitalize ml-5 mr-5 mt-2"
                width="150"
                max-height="35"
                @click="SubDistrictAddDialog = true"
                :disabled="permission.add"
                >{{ $t("Add") }}</v-btn
              >
            </v-row>
            <v-data-table
              style="margin-left: 40px; margin-right: 40px"
              class="tbheader"
              :headers="subdistrictheaders"
              :items="SubDistrictList"
              :page.sync="page3"
              :items-per-page="itemsPerPage3"
              hide-default-footer
              hide-default-header
              @page-count="pageCount3 = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunSubDistrict('sub_District_Name')"
                    >
                      {{ $t("subdistrict") }}
                      <v-icon small v-if="!sub_District_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="sub_District_Name"
                        >mdi-arrow-up</v-icon
                      >
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunSubDistrict('district_Name')"
                    >
                      {{ $t("district") }}
                      <v-icon small v-if="!district_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="district_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunSubDistrict('province_Name')"
                    >
                      {{ $t("province") }}
                      <v-icon small v-if="!province_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="province_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunSubDistrict('country_Name')"
                    >
                      {{ $t("country") }}
                      <v-icon small v-if="!country_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="country_Name">mdi-arrow-up</v-icon>
                    </th>
                    <!-- <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunSubDistrict('postal_Code')"
                    >
                      {{ $t("postalcode") }}
                      <v-icon small v-if="!postal_Code">mdi-arrow-down</v-icon>
                      <v-icon small v-if="postal_Code">mdi-arrow-up</v-icon>
                    </th> -->
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.sub_District_Name }}</td>
                  <td>{{ item.district_Name }}</td>
                  <td>{{ item.province_Name }}</td>
                  <td>{{ item.country_Name }}</td>
                  <!-- <td>{{ item.postal_Code }}</td> -->
                  <td>
                    <v-btn
                      @click="EditUSubDistrict(item)"
                      icon
                      :disabled="permission.edit"
                    >
                      <img class="editIcon" :src="images.edit"/>
                    </v-btn>
                    <v-btn
                      @click="OpenSubDistrictDeletedialog(item.sub_District_ID)"
                      icon
                      :disabled="permission.delete || item.disablethis"
                    >
                      <img class="deleteIcon" :src="images.delete"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page3"
                    :length="pageCount3"
                    color="#FFCD2C"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage3"
                    :items="items"
                    @input="itemsPerPage3 = parseInt($event, 10)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-tab-item>
        
        <!-- Postal Code -->
        <v-tab-item>
          <v-card class="ml-0 pt-5">
            <p style="font-size: 21px; margin-left: 30px; margin-top: 20px">
              {{ $t("postalcode") }}
            </p>
            <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
              <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                <v-text-field
                  :placeholder="$t('Search')"
                  v-model="postalcodesearchtxt"
                  color="#F99D20"
                  dense
                  outlined
                  autocomplete="off"
                  prepend-inner-icon="mdi-magnify"
                  style="margin-top: -0.1rem; margin-left: 1rem"
                  @keyup.enter="Search(5)"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="4">
                <v-text-field
                  autocomplete="off"
                  :placeholder="$t('Search') + ' ' + $t('postalcode')"
                  v-model.lazy="postalcodesearchtxt"
                  color="#F99D20"
                  dense
                  style="margin-top: -0.1rem; margin-left: 1rem">
                </v-text-field>
              </v-col>
              <v-btn
                color="#F99D20"
                @click="Search(5)"
                class="white--text text-capitalize ml-5 mt-2"
                width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                color="#F99D20"
                class="orange--text btnres text-capitalize ml-5 mt-2"
                width="150"
                max-height="35"
                @click="PostalCodeUploadDialog = true"
                outlined
                :disabled="permission.upload"
                >{{ $t("upload") }}</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text btnres text-capitalize ml-5 mr-5 mt-2"
                width="150"
                max-height="35"
                @click="PostalCodeAddDialog = true"
                :disabled="permission.add"
                >{{ $t("Add") }}</v-btn
              >
            </v-row>
            <v-data-table
              style="margin-left: 40px; margin-right: 40px"
              class="tbheader"
              :headers="districtheaders"
              :items="PostalCodeList"
              :page.sync="page4"
              :items-per-page="itemsPerPage4"
              hide-default-footer
              hide-default-header
              @page-count="pageCount4 = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunPostalCode('postal_Code')"
                    >
                      {{ $t("postalcode") }}
                      <v-icon small v-if="!postal_Code">mdi-arrow-down</v-icon>
                      <v-icon small v-if="postal_Code">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunPostalCode('sub_District_Name')"
                    >
                      {{ $t("subdistrict") }}
                      <v-icon small v-if="!sub_District_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="sub_District_Name"
                        >mdi-arrow-up</v-icon
                      >
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunPostalCode('district_Name')"
                    >
                      {{ $t("district") }}
                      <v-icon small v-if="!district_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="district_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunPostalCode('province_Name')"
                    >
                      {{ $t("province") }}
                      <v-icon small v-if="!province_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="province_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFunPostalCode('country_Name')"
                    >
                      {{ $t("country") }}
                      <v-icon small v-if="!country_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="country_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.postal_Code }}</td>
                  <td>{{ item.sub_District_Name }}</td>
                  <td>{{ item.district_Name }}</td>
                  <td>{{ item.province_Name }}</td>
                  <td>{{ item.country_Name }}</td>
                  <td>
                    <v-btn
                      @click="EditUPostalCode(item)"
                      icon
                      :disabled="permission.edit"
                    >
                      <img class="editIcon" :src="images.edit"/>
                    </v-btn>
                    <v-btn
                      @click="OpenPostalCodeDeletedialog(item.postal_Code_ID)"
                      icon
                      :disabled="permission.delete || item.disablethis"
                    >
                      <img class="deleteIcon" :src="images.delete"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page4"
                    :length="pageCount4"
                    color="#FFCD2C"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="itemsPerPage4"
                    :items="items"
                    @input="itemsPerPage4 = parseInt($event, 10)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    <!-- Country Add -->
    <v-dialog v-model="CountryAdddialog" persistent max-width="700px">
      <v-card ref="form">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("addCountry") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-form ref="CountryformAdd" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <!-- <v-text-field
                    autocomplete="off"
                    ref="country"
                    v-model="addcountry"
                    :rules="addcountryRules"
                    :label="$t('country') + '*'"
                    color="#F89D1F"
                  ></v-text-field> -->
                  <v-text-field
                    autocomplete="off"
                    ref="country"
                    v-model="addcountry"
                    :error-messages="error.errorMsgCrty"
                    color="#F89D1F"
                    required
                    ><template v-slot:label>
                      <span>
                        {{ $t("country")
                        }}<span class="red--text"> *</span></span
                      >
                    </template></v-text-field
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="CloseCountryAddDialog"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="CreateCountry"
            >
              {{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Country Edit -->
    <v-dialog v-model="CountryEditdialog" persistent max-width="657px">
      <v-card ref="form">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("EditCountry") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-form ref="CountryformEdit" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <!-- <v-text-field
                    autocomplete="off"
                    ref="country"
                    v-model="editcountry.country_Name"
                    :rules="editcountryRules"
                    :label="$t('country') + '*'"
                    color="#F89D1F"
                  ></v-text-field> -->
                  <v-text-field
                    autocomplete="off"
                    ref="country"
                    v-model="editcountry.country_Name"
                    :error-messages="error.errorMsgCrty"
                    color="#F89D1F"
                    required
                    ><template v-slot:label>
                      <span>
                        {{ $t("country")
                        }}<span class="red--text"> *</span></span
                      >
                    </template></v-text-field
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseCountryEditDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UpdateCountry"
            >{{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Country -->
    <v-dialog v-model="CountryDeletedialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CountryDeletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commondeletebtn"
            @click="DeleteCountry"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload Country -->
    <v-dialog v-model="CountryUploaddialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Country Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whattheCountrylang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeCountryUpload"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UploadCountry"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Province -->
    <v-dialog v-model="ProvinceAdddialog" persistent max-width="700px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("AddProvince") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="province"
                  v-model="addprovinces.province"
                  :error-messages="error.errorMsgProv"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("province")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="CountryList"
                  v-model="addprovinces.country"
                  item-value="country_Name"
                  :item-text="(item) => ` ${item.country_Name}`"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgCrty"
                  color="#F89D1F"
                >
                  ><template v-slot:label>
                    <span>
                      {{ $t("country") }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-rigt: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseProvinceAddDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="CreateProvince"
          >
            {{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Province -->
    <v-dialog v-model="ProvinceEditdialog" persistent max-width="657px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("EditProvince") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="province"
                  v-model="editprovinces.province_Name"
                  :error-messages="error.errorMsgProv"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("province")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="CountryList"
                  v-model="editprovinces.country_Name"
                  item-value="country_Name"
                  :item-text="(item) => ` ${item.country_Name}`"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgCrty"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("country") }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseProvinceEditDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UpdateProvince"
            >{{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Province -->
    <v-dialog v-model="ProvinceDeletedialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="ProvinceDeletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commondeletebtn"
            @click="DeleteProvince"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload Province -->
    <v-dialog v-model="ProvinceUploaddialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Province Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whattheProvincelang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeProvinceUpload"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UploadProvince"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- District Add -->
    <v-dialog v-model="DistrictAdddialog" persistent max-width="657px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("AddDistrict") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="district"
                  v-model="adddistricts.district"
                  :error-messages="error.errorMsgDist"
                  color="#F89D1F"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("District")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="ProvinceList"
                  v-model="adddistricts.province"
                  item-value="province_Name"
                  :item-text="(item) => ` ${item.province_Name}`"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgProv"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("province")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseDistrictAddDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="CreateDistrict"
          >
            {{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- District Edit -->
    <v-dialog v-model="DistrictEditdialog" persistent max-width="657px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 15px; margin-bottom: -30px">
            {{ $t("EditDistrict") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="district"
                  v-model="editdistricts.district_Name"
                  :error-messages="error.errorMsgDist"
                  color="#F89D1F"
                >
                  ><template v-slot:label>
                    <span>
                      {{ $t("District")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="ProvinceList"
                  v-model="editdistricts.province_Name"
                  item-value="province_Name"
                  :item-text="(item) => ` ${item.province_Name}`"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgProv"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("province")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn" 
            @click="CloseDistrictEditDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UpdateDistrict"
          >
            {{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- District Delete -->
    <v-dialog v-model="DistrictDeletedialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="DistrictDeletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 white--text text-capitalize commondeletebtn"
            @click="DeleteDistrict"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- District Upload -->
    <v-dialog v-model="DistrictUploaddialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>District Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whattheDistrictlang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeDistrictUpload"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UploadDistrict"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sub District Add Dialog -->
    <v-dialog v-model="SubDistrictAddDialog" persistent max-width="700px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("AddSubDistrict") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="sub_District"
                  v-model="addsubdistricts.sub_District"
                  :error-messages="error.errorMsgSub_Dist"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("subdistrict")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  ref="district"
                  class="empID"
                  :items="DistrictList"
                  v-model="addsubdistricts.district"
                  item-value="district_Name"
                  :item-text="(item) => ` ${item.district_Name}`"
                  style="margin-top: 0.95rem"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgDist"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("District")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
                <!-- <v-text-field
                  autocomplete="off"
                  ref="postal_Code"
                  class="mt-3"
                  v-model="addsubdistricts.postal_Code"
                  :error-messages="error.errorMsgPost_Cde"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("postalcode")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                > -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseSubDistrictAddDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="CreateSubDistrict"
            >{{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sub District Edit Dialog -->
    <v-dialog v-model="SubDistrictEditDialog" persistent max-width="700px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("EditSubDistrict") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="sub_District"
                  v-model="editsubdistricts.sub_District_Name"
                  :error-messages="error.errorMsgSub_Dist"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("subdistrict")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  ref="district"
                  class="empID"
                  :items="DistrictList"
                  v-model="editsubdistricts.district_Name"
                  item-value="district_Name"
                  :item-text="(item) => ` ${item.district_Name}`"
                  style="margin-top: 0.95rem"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgDist"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("District")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
                <!-- <v-text-field
                  autocomplete="off"
                  ref="postal_Code"
                  class="mt-3"
                  v-model="editsubdistricts.postal_Code"
                  :error-messages="error.errorMsgPost_Cde"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("postalcode")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                > -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseSubDistrictEditDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UpdateSubDistrict"
            >{{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sub District Delete Dialog -->
    <v-dialog v-model="SubDistrictDeleteDialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="SubDistrictDeleteDialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commondeletebtn"
            @click="DeleteSubDistrict"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sub District Upload Dialog -->
    <v-dialog v-model="SubDistrictUploadDialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Sub District Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whattheSubDistrictlang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeSubDistrictUpload"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UploadSubDistrict"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PostalCode Add Dialog -->
    <v-dialog v-model="PostalCodeAddDialog" persistent max-width="700px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("AddSubDistrict") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="postal_Code"
                  class="mt-3"
                  v-model="addpostalcode.postal_Code"
                  :error-messages="error.errorMsgPost_Cde"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("postalcode")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  ref="district"
                  class="empID"
                  :items="SubDistrictList"
                  v-model="addpostalcode.sub_District"
                  item-value="sub_District_Name"
                  :item-text="(item) => ` ${item.sub_District_Name}`"
                  style="margin-top: 0.95rem"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgSub_Dist"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("subdistrict")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize commoncancelbtn"
            outlined
            @click="ClosePostalCodeAddDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="CreatePostalCode"
            >{{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PostalCode Edit Dialog -->
    <v-dialog v-model="PostalCodeEditDialog" persistent max-width="700px">
      <v-card ref="form">
        <v-card-title>
          <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("EditSubDistrict") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  ref="postal_Code"
                  class="mt-3"
                  v-model="editpostalcode.postal_Code"
                  :error-messages="error.errorMsgPost_Cde"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("postalcode")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <v-autocomplete
                  ref="district"
                  class="empID"
                  :items="SubDistrictList"
                  v-model="editpostalcode.sub_District_Name"
                  item-value="sub_District_Name"
                  :item-text="(item) => ` ${item.sub_District_Name}`"
                  style="margin-top: 0.95rem"
                  append-icon="mdi-chevron-down"
                  :error-messages="error.errorMsgSub_Dist"
                  color="#F89D1F"
                  ><template v-slot:label>
                    <span>
                      {{ $t("District")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-autocomplete
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="ClosePostalCodeEditDialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="UpdatePostalCode"
            >{{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PostalCode Delete Dialog -->
    <v-dialog v-model="PostalCodeDeleteDialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="PostalCodeDeleteDialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commondeletebtn"
            @click="DeletePostalCode"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PostalCode Upload Dialog -->
    <v-dialog v-model="PostalCodeUploadDialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Postal Code Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whatthePostalCodelang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closePostalCodeUpload"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="white--text mr-2 text-capitalize commonsavebtn"
            @click="UploadPostalCode"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import XLSX from "xlsx";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  data() {
    return {
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      addcountryRules: [],
      editcountryRules: [],
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      url: enurl.apiUrl,
      page: 1,
      page1: 1,
      page2: 1,
      page3: 1,
      page4: 1,
      pageCount: 15,
      pageCount1: 15,
      pageCount2: 15,
      pageCount3: 15,
      pageCount4: 15,
      itemsPerPage: 10,
      itemsPerPage1: 10,
      itemsPerPage2: 10,
      itemsPerPage3: 10,
      itemsPerPage4: 10,
      items: [10, 20, 30],
      countrysearchtxt: "",
      provincesearchtxt: "",
      districtsearchtxt: "",
      subdistrictsearchtxt: "",
      postalcodesearchtxt: "",
      orctylength: 0,
      orprolength: 0,
      ordislength: 0,
      orsdilength: 0,
      orpcdlength: 0,
      CountryList: [],
      ProvinceList: [],
      DistrictList: [],
      SubDistrictList: [],
      PostalCodeList: [],
      LoadingDialog: false,
      CountryAdddialog: false,
      CountryEditdialog: false,
      CountryDeletedialog: false,
      CountryUploaddialog: false,
      ProvinceAdddialog: false,
      ProvinceEditdialog: false,
      ProvinceDeletedialog: false,
      ProvinceUploaddialog: false,
      DistrictAdddialog: false,
      DistrictEditdialog: false,
      DistrictDeletedialog: false,
      DistrictUploaddialog: false,
      SubDistrictAddDialog: false,
      SubDistrictEditDialog: false,
      SubDistrictDeleteDialog: false,
      SubDistrictUploadDialog: false,
      PostalCodeAddDialog: false,
      PostalCodeEditDialog: false,
      PostalCodeDeleteDialog: false,
      PostalCodeUploadDialog: false,
      country_Name: null,
      province_Name: null,
      district_Name: null,
      subDest: null,
      editcountry: {
        country_Name: null,
        country_ID: null,
        companyID: null,
      },
      addprovinces: {
        province: null,
        country: null,
      },
      editprovinces: {
        province_ID: null,
        province_Name: null,
        country_Name: null,
        companyID: null,
      },
      adddistricts: {
        district: null,
        province: null,
      },
      editdistricts: {
        district_ID: null,
        district_Name: null,
        province_Name: null,
        companyID: null,
      },
      addsubdistricts: {
        sub_District: null,
        district: null,
        postal_Code: null,
      },
      editsubdistricts: {
        sub_District_ID: null,
        sub_District_Name: null,
        district_Name: null,
        postal_Code: null,
        companyID: null,
      },
      addpostalcode: {
        sub_District: null,
        postal_Code: null,
      },
      editpostalcode: {
        postal_Code_ID: null,
        sub_District_Name: null,
        postal_Code: null,
        companyID: null,
      },
      addcountry: null,
      CountryID: 0,
      ProvinceID: 0,
      DistrictID: 0,
      SubDistrictID: 0,
      PostalCodeID: 0,
      AUploaddialog: false,
      showup: false,
      tempimport: [],
      importData: [],
      chosenfile: null,
      sub_District: null,
      district: null,
      province: null,
      country: null,
      postal_Code: null,
      editadres: {
        address_ID: null,
        sub_District: null,
        district: null,
        province: null,
        country: null,
        postal_Code: null,
      },
      error: {
        errorMsgSub_Dist: "",
        errorMsgDist: "",
        errorMsgProv: "",
        errorMsgCrty: "",
        errorMsgPost_Cde: "",
      },
      errorMessage: [],
      errorMessage1: [],
      errorMessage2: [],
      countryheaders: [
        {
          text: "Country",
          value: "country_Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "actionadd",
          align: "right",
          sortable: false,
        },
      ],
      provinceheaders: [
        {
          text: "Province",
          value: "province_Name",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Country",
        //   value: "country_Name",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Action",
        //   value: "actionadd",
        //   align: "right",
        //   sortable: false,
        // },
      ],
      districtheaders: [
        {
          text: "District",
          value: "district_Name",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Province",
        //   value: "province_Name",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Country",
        //   value: "country_Name",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Action",
        //   value: "actionadd",
        //   align: "right",
        //   sortable: false,
        // },
      ],
      subdistrictheaders: [
        {
          text: "Sub-District",
          value: "sub_District_Name",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "District",
        //   value: "district_Name",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Province",
        //   value: "province_Name",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Country",
        //   value: "country_Name",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Postal Code",
        //   value: "postal_Code",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "Action",
        //   value: "actionadd",
        //   align: "right",
        //   sortable: false,
        // },
      ],
    };
  },
  async mounted() {
    await this.GetCountry();
    this.permission = this.CheckViewAddEditDeleteUpload(64, 65, 66, 67, 68);
  },
  computed: {
    form() {
      return {
        sub_District: this.sub_District,
        district: this.district,
        province: this.province,
        country: this.country,
        postal_Code: this.postal_Code,
      };
    },
    images() {
      return this.$store.state.images;
    }
  },
  watch: {
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
    countrysearchtxt: function () {
      this.GetCountry();
    },
    provincesearchtxt: function () {
      this.GetProvince();
    },
    districtsearchtxt: function () {
      this.GetDistrict();
    },
    subdistrictsearchtxt: function () {
      this.GetSubDistrict();
    },
    postalcodesearchtxt: function () {
      this.GetPostalCode();
    },
    "addcountry"(v) {
      if (v) this.error.errorMsgCrty = "";
    },
    "editcountry.country_Name"(v) {
      if (v) this.error.errorMsgCrty = "";
    },
    "addprovinces.country"(v) {
      if (v) this.error.errorMsgCrty = "";
    },
    "editprovinces.country_Name"(v) {
      if (v) this.error.errorMsgCrty = "";
    },
    "addprovinces.province"(v) {
      if (v) this.error.errorMsgProv = "";
    },
    "editprovinces.province_Name"(v) {
      if (v) this.error.errorMsgProv = "";
    },
    "adddistricts.district"(v) {
      if (v) this.error.errorMsgDist = "";
    },
    "adddistricts.province"(v) {
      if (v) this.error.errorMsgProv = "";
    },
    "editdistricts.district_Name"(v) {
      if (v) this.error.errorMsgDist = "";
    },
    "editdistricts.province_Name"(v) {
      if (v) this.error.errorMsgProv = "";
    },
    "addsubdistricts.sub_District"(v) {
      if (v) this.error.errorMsgSub_Dist = "";
    },
    "addsubdistricts.district"(v) {
      if (v) this.error.errorMsgDist = "";
    },
    "editsubdistricts.sub_District_Name"(v) {
      if (v) this.error.errorMsgSub_Dist = "";
    },
    "editsubdistricts.district_Name"(v) {
      if (v) this.error.errorMsgDist = "";
    },
    "addpostalcode.postal_Code"(v) {
      if (v) this.error.errorMsgPost_Cde = "";
    },
    "addpostalcode.sub_District"(v) {
      if (v) this.error.errorMsgSub_Dist = "";
    },
    "editpostalcode.postal_Code"(v) {
      if (v) this.error.errorMsgPost_Cde = "";
    },
    "editpostalcode.sub_District_Name"(v) {
      if (v) this.error.errorMsgSub_Dist = "";
    },
  },
  methods: {
    async originalCountry(baka) {
      this.CountryList = [];
      this.ProvinceList = [];
      this.DistrictList = [];
      this.SubDistrictList = [];
      this.PostalCodeList = [];
      this.countrysearchtxt = "";
      this.districtsearchtxt = "";
      this.provincesearchtxt = "";
      this.subdistrictsearchtxt = "";
      this.postalcodesearchtxt = "";
      if (baka == 1) {
        await this.GetCountry();
      } else if (baka == 2) {
        await this.GetProvince();
        await this.GetCountry();
      } else if (baka == 3) {
        await this.GetDistrict();
        await this.GetProvince();
      } else if (baka == 4) {
        await this.GetSubDistrict();
        await this.GetDistrict();
      } else if (baka == 5) {
        await this.GetSubDistrict();
        await this.GetPostalCode();
      }
      this.country_Name = null;
      this.province_Name = null;
      this.district_Name = null;
      this.subDest = null;
      this.sub_District_Name = null;
      this.postal_Code = null;
      this.page = 1;
      this.page1 = 1;
      this.page2 = 1;
      this.page3 = 1;
      this.page4 = 1;
      this.itemsPerPage = 10;
      this.itemsPerPage1 = 10;
      this.itemsPerPage2 = 10;
      this.itemsPerPage3 = 10;
      this.itemsPerPage4 = 10;
      this.defaultSort();
    },
    sortFunCountry(rowName) {
      let self = this;
      let keyName = ["country_Name"];
      if (rowName == "country_Name") {
        self.CountryList = self.CountryList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.CountryList = self.CountryList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    sortFunProvince(rowName) {
      let self = this;
      let keyName = ["province_Name", "country_Name"];
      if (rowName == "province_Name" || rowName == "country_Name") {
        self.ProvinceList = self.ProvinceList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.ProvinceList = self.ProvinceList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    sortFunDistrict(rowName) {
      let self = this;
      let keyName = ["province_Name", "country_Name", "district_Name"];
      if (
        rowName == "province_Name" ||
        rowName == "country_Name" ||
        rowName == "district_Name"
      ) {
        self.DistrictList = self.DistrictList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.DistrictList = self.DistrictList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    sortFunSubDistrict(rowName) {
      let self = this;

      let keyName = [
        "district_Name",
        "province_Name",
        "country_Name",
        "postal_Code",
        "sub_District_Name",
      ];
      if (
        rowName == "province_Name" ||
        rowName == "country_Name" ||
        rowName == "sub_District_Name" ||
        rowName == "district_Name"
      ) {
        self.SubDistrictList = self.SubDistrictList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.SubDistrictList = self.SubDistrictList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    sortFunPostalCode(rowName) {
      let self = this;

      let keyName = [
        "district_Name",
        "province_Name",
        "country_Name",
        "postal_Code",
        "sub_District_Name",
      ];
      if (
        rowName == "province_Name" ||
        rowName == "country_Name" ||
        rowName == "sub_District_Name" ||
        rowName == "district_Name"
      ) {
        self.PostalCodeList = self.PostalCodeList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.PostalCodeList = self.PostalCodeList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    whattheCountrylang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Country Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Country Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Country Master Data.xlsx";
        }
      }
      return thelink;
    },
    whattheProvincelang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Province Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Province Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Province Master Data.xlsx";
        }
      }
      return thelink;
    },
    whattheDistrictlang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/District Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/District Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/District Master Data.xlsx";
        }
      }
      return thelink;
    },
    whattheSubDistrictlang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Sub District Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Sub District Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Sub District Master Data.xlsx";
        }
      }
      return thelink;
    },
    whatthePostalCodelang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Postal Code Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Postal Code Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Postal Code Master Data.xlsx";
        }
      }
      return thelink;
    },
    defaultSort() {
      this.country_Name = null;
      this.province_Name = null;
      this.district_Name = null;
      this.subDest = null;
      this.sub_District_Name = null;
      this.postal_Code = null;
    },
    back() {
      this.$router.push({ name: "Masterdata" });
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      //this.showup = true;
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    alphaOnly(event) {
      let alp = /^([^0-9]*)$/;
      let res = alp.test(event) || "Alphabet only";
      return res;
    },
    resetForm() {
      this.error.errorMsgSub_Dist = [];
      this.error.errorMsgDist = [];
      this.error.errorMsgProv = [];
      this.error.errorMsgCrty = [];
      this.error.errorMsgPost_Cde = [];
      // Object.keys(this.form).forEach((f) => {
      //   this.$refs[f].reset();
      // });
    },
    OpenCountryDeletedialog(id) {
      this.CountryID = id;
      this.CountryDeletedialog = true;
    },
    CloseCountryAddDialog() {
      this.addcountry = null;
      this.CountryAdddialog = false;
      //this.$refs.CountryformAdd.reset();
      this.resetForm();
    },
    CloseCountryEditDialog() {
      this.CountryEditdialog = false;
      this.resetForm();
      //this.$refs.CountryformEdit.reset();
    },
    closeCountryUpload() {
      this.CountryUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    OpenProvinceDeletedialog(id) {
      this.ProvinceID = id;
      this.ProvinceDeletedialog = true;
    },
    CloseProvinceAddDialog() {
      this.addprovinces.province = null;
      this.addprovinces.country = null;
      this.ProvinceAdddialog = false;
      this.resetForm();
    },
    CloseProvinceEditDialog() {
      this.ProvinceEditdialog = false;
      this.resetForm();
    },
    closeProvinceUpload() {
      this.ProvinceUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    OpenDistrictDeletedialog(id) {
      this.DistrictID = id;
      this.DistrictDeletedialog = true;
    },
    CloseDistrictAddDialog() {
      this.adddistricts.district = null;
      this.adddistricts.province = "";
      this.DistrictAdddialog = false;
      this.resetForm();
    },
    CloseDistrictEditDialog() {
      this.DistrictEditdialog = false;
      this.resetForm();
    },
    closeDistrictUpload() {
      this.DistrictUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    OpenSubDistrictDeletedialog(id) {
      this.SubDistrictID = id;
      this.SubDistrictDeleteDialog = true;
    },
    CloseSubDistrictAddDialog() {
      this.SubDistrictAddDialog = false;
      this.addsubdistricts.sub_District = null;
      this.addsubdistricts.district = null;
      this.resetForm();
    },
    CloseSubDistrictEditDialog() {
      this.SubDistrictEditDialog = false;
      this.resetForm();
    },
    closeSubDistrictUpload() {
      this.SubDistrictUploadDialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    OpenPostalCodeDeletedialog(id) {
      this.PostalCodeID = id;
      this.PostalCodeDeleteDialog = true;
    },
    ClosePostalCodeAddDialog() {
      this.PostalCodeAddDialog = false;
      this.addpostalcode.sub_District = null;
      this.addpostalcode.postal_Code = null;
      this.resetForm();
    },
    ClosePostalCodeEditDialog() {
      this.PostalCodeEditDialog = false;
      this.resetForm();
    },
    closePostalCodeUpload() {
      this.PostalCodeUploadDialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    async GetCountry() {
      let self = this;
      if (
        self.orctylength == 0 ||
        self.orctylength != self.CountryList.length
      ) {
        self.CountryList = [];
        self.LoadingDialog = true;
        await axios
          .get(
            `${self.url}Country/GetCountryByCompanyID?Company_ID=${store.state.companyID}`
          )
          .then(function (response) {
            let CList = response.data.data;
            self.orctylength = CList.length;
            for (let i = 0; i < CList.length; i++) {
              let disa = false;
              if (CList[i].company_ID == 0) {
                disa = true;
              }
              let Lists = {
                ...CList[i],
                disablethis: disa,
              };
              self.CountryList.push(Lists);
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get Address, Country Fail", 4, "Critical");
            alert(error + "Correlation ID is 4");
            self.LoadingDialog = false;
          });
      }
    },
    async GetProvince() {
      let self = this;
      if (
        self.orprolength == 0 ||
        self.orprolength != self.ProvinceList.length
      ) {
        self.LoadingDialog = true;
        self.ProvinceList = [];
        await axios
          .get(
            `${self.url}Province/GetProvinceWithCountryName?Company_ID=${store.state.companyID}`
          )
          .then(function (response) {
            let PList = response.data.data;
            self.orprolength = PList.length;
            for (let i = 0; i < PList.length; i++) {
              let disa = false;
              if (PList[i].company_ID == 0) {
                disa = true;
              }
              let Lists = {
                ...PList[i],
                disablethis: disa,
              };
              self.ProvinceList.push(Lists);
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get Address, Province Fail", 4, "Critical");
            alert(error + "Correlation ID is 4");
            self.LoadingDialog = false;
          });
      }
    },
    async GetDistrict() {
      let self = this;
      if (
        self.ordislength == 0 ||
        self.ordislength != self.DistrictList.length
      ) {
        self.DistrictList = [];
        self.LoadingDialog = true;
        await axios
          .get(
            `${self.url}District/GetDistrictWithNames?Company_ID=${store.state.companyID}`
          )
          .then(function (response) {
            let DList = response.data.data;
            self.ordislength = DList.length;
            for (let i = 0; i < DList.length; i++) {
              let disa = false;
              if (DList[i].company_ID == 0) {
                disa = true;
              }
              let Lists = {
                ...DList[i],
                disablethis: disa,
              };
              self.DistrictList.push(Lists);
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get Address,District Fail", 4, "Critical");
            alert(error + "Correlation ID is 4");
            self.LoadingDialog = false;
          });
      }
    },
    async GetSubDistrict() {
      let self = this;
      if (
        self.orsdilength == 0 ||
        self.orsdilength != self.SubDistrictList.length
      ) {
        self.LoadingDialog = true;
        self.SubDistrictList = [];
        await axios
          .get(
            `${self.url}Sub_District/GetSubDistrictWithNamesformasterdata?Company_ID=${store.state.companyID}`
          )
          .then(function (response) {
            let SDList = response.data.data;
            self.orsdilength = SDList.length;
            for (let i = 0; i < SDList.length; i++) {
              let disa = false;
              if (SDList[i].company_ID == 0) {
                disa = true;
              }
              let Lists = {
                ...SDList[i],
                disablethis: disa,
              };
              self.SubDistrictList.push(Lists);
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get Address,SubDistrict Fail", 4, "Critical");
            alert(error + "Correlation ID is 4");
            self.LoadingDialog = false;
          });
      }
    },
    async GetPostalCode() {
      let self = this;
      if (
        self.orpcdlength == 0 ||
        self.orpcdlength != self.PostalCodeList.length
      ) {
        self.LoadingDialog = true;
        self.PostalCodeList = [];
        await axios
          .get(
            `${self.url}PostalCode/GetPostalCodeWithNames?Company_ID=${store.state.companyID}`
          )
          .then(function (response) {
            let PCList = response.data.data;
            self.orpcdlength = PCList.length;
            for (let i = 0; i < PCList.length; i++) {
              let disa = false;
              if (PCList[i].company_ID == 0) {
                disa = true;
              }
              let Lists = {
                ...PCList[i],
                disablethis: disa,
              };
              self.PostalCodeList.push(Lists);
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get Address,PostalCode Fail", 4, "Critical");
            alert(error + "Correlation ID is 4");
            self.LoadingDialog = false;
          });
      }
    },
    CreateCountry() {
      let self = this;
      self.error.errorMsgCrty = !self.addcountry
        ? "Please fill in the required fields"
        : "";
      // self.addcountryRules = [
      //     (v) => !!v || "Country Name is required",
      //     (v) => /^[a-zA-Z\s]+$/.test(v) || "Alphabet only",
      //   ];
      // let tempvalidate = self.$refs.CountryformAdd.validate();
      // //let ctyVali = self.alphaOnly(self.addcountry);
      // if (tempvalidate) {
      self.LoadingDialog = true;
      // let tempvalidate = self.$refs.formEdit.validate();
      let ctyVali = self.alphaOnly(self.addcountry);
      if (self.addcountry != "" && self.addcountry != null && ctyVali == true) {
        var ctytxt =
          self.addcountry.charAt(0).toUpperCase() + self.addcountry.slice(1);

        let samecondi = false;
        for (let i = 0; i < self.CountryList.length; i++) {
          if (ctytxt == self.CountryList[i].country_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            country_Name: ctytxt,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}Country/AddCountry`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.addcountry = null;
                self.CountryAdddialog = false;
                self.LoadingDialog = false;
                self.CountryList = [];
                self.GetCountry();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Address,Country Fail", 4, "Low");
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("Country already exists!");
          self.LoadingDialog = false;
        }
      } else if (self.addcountry == "" || self.addcountry == null) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (ctyVali != true) {
        alert("Please Enter Alphabets Only!");
        this.error.errorMsgCrty =
          !this.addcountry != ctyVali ? "Alphabets Only" : "";
        self.LoadingDialog = false;
      }
    },
    CreateProvince() {
      let self = this;
      self.error.errorMsgProv = !self.addprovinces.province
        ? "Please fill in the required fields"
        : "";
      self.error.errorMsgCrty = !self.addprovinces.country
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      let proVali = self.alphaOnly(self.addprovinces.province);
      if (
        self.addprovinces.province != null &&
        self.addprovinces.province != "" &&
        self.addprovinces.country != null &&
        self.addprovinces.country != "" &&
        proVali == true
      ) {
        let protxt =
          self.addprovinces.province.charAt(0).toUpperCase() +
          self.addprovinces.province.slice(1);
          self.GetCountry();
        self.defaultSort();
        let ctyid = null;
        for (let i = 0; i < self.CountryList.length; i++) {
          if (self.addprovinces.country == self.CountryList[i].country_Name) {
            ctyid = self.CountryList[i].country_ID;
          }
        }
        let samecondi = false;
        for (let j = 0; j < self.ProvinceList.length; j++) {
          if (protxt == self.ProvinceList[j].province_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            province_Name: protxt,
            country_ID: ctyid,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}Province/AddProvince`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.addprovinces.province = null;
                self.addprovinces.country = null;
                self.ProvinceAdddialog = false;
                self.LoadingDialog = false;
                self.ProvinceList = [];
                self.GetProvince();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add  Address ,Province Fail", 4, "Low");
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("Province already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.addprovinces.province == null ||
        self.addprovinces.province == "" ||
        self.addprovinces.country == null ||
        self.addprovinces.country == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (proVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgProv =
          !this.addprovinces.province != proVali ? "Alphabets Only" : "";
      }
    },
    CreateDistrict() {
      let self = this;
      this.error.errorMsgDist = !this.adddistricts.district
        ? "Please fill in the required fields"
        : "";
      this.error.errorMsgProv = !this.adddistricts.province
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      let disVali = self.alphaOnly(self.adddistricts.district);
      if (
        self.adddistricts.district != null &&
        self.adddistricts.district != "" &&
        self.adddistricts.province != null &&
        self.adddistricts.province != "" &&
        disVali == true
      ) {
        let distxt =
          self.adddistricts.district.charAt(0).toUpperCase() +
          self.adddistricts.district.slice(1);
        self.GetProvince();
        self.defaultSort();
        let proid = null;
        for (let i = 0; i < self.ProvinceList.length; i++) {
          if (
            self.adddistricts.province == this.ProvinceList[i].province_Name
          ) {
            proid = this.ProvinceList[i].province_ID;
          }
        }
        let samecondi = false;
        for (let j = 0; j < self.DistrictList.length; j++) {
          if (distxt == self.DistrictList[j].district_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            district_Name: distxt,
            province_ID: proid,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}District/AddDistrict`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.adddistricts.district = null;
                self.adddistricts.province = null;
                self.DistrictAdddialog = false;
                self.LoadingDialog = false;
                self.DistrictList = [];
                self.GetDistrict();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Address, District Fail", 4, "Low");
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("District already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.adddistricts.district == null ||
        self.adddistricts.district == "" ||
        self.adddistricts.province == null ||
        self.adddistricts.province == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (disVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgDist =
          !this.adddistricts.district != disVali ? "Alphabets Only" : "";
      }
    },
    CreateSubDistrict() {
      let self = this;
      this.error.errorMsgSub_Dist = !this.addsubdistricts.sub_District
        ? "Please fill in the required fields"
        : "";
      this.error.errorMsgDist = !this.addsubdistricts.district
        ? "Please fill in the required fields"
        : "";
      // this.error.errorMsgPost_Cde = !this.addsubdistricts.postal_Code
      //   ? "Please fill in the required fields"
      //   : "";
      self.LoadingDialog = true;
      let subDVali = self.alphaOnly(self.addsubdistricts.sub_District);
      if (
        self.addsubdistricts.sub_District != null &&
        self.addsubdistricts.sub_District != "" &&
        self.addsubdistricts.district != null &&
        self.addsubdistricts.district != "" &&
        // self.addsubdistricts.postal_Code != null &&
        // self.addsubdistricts.postal_Code != "" &&
        subDVali == true
      ) {
        let subtxt =
          self.addsubdistricts.sub_District.charAt(0).toUpperCase() +
          self.addsubdistricts.sub_District.slice(1);
        self.GetDistrict();
        self.defaultSort();
        let disid = null;
        for (let i = 0; i < self.DistrictList.length; i++) {
          if (
            self.addsubdistricts.district == this.DistrictList[i].district_Name
          ) {
            disid = this.DistrictList[i].district_ID;
          }
        }
        let samecondi = false;
        for (let j = 0; j < self.SubDistrictList.length; j++) {
          if (subtxt == self.SubDistrictList[j].sub_District_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            sub_District_Name: subtxt,
            district_ID: disid,
            //postal_Code: parseInt(self.addsubdistricts.postal_Code),
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}Sub_District/AddSubDistrict`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.addsubdistricts.sub_District = null;
                self.addsubdistricts.district = null;
                //self.addsubdistricts.postal_Code = null;
                self.SubDistrictAddDialog = false;
                self.LoadingDialog = false;
                self.SubDistrictList = [];
                self.GetSubDistrict();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Address, SubDistrict Fail", 4, "Low");
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("SubDistrict already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.addsubdistricts.sub_District == null ||
        self.addsubdistricts.sub_District == "" ||
        self.addsubdistricts.district == null ||
        self.addsubdistricts.district == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (subDVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgSub_Dist =
          !this.addsubdistricts.sub_District != subDVali
            ? "Alphabets Only"
            : "";
      }
    },
    CreatePostalCode() {
      let self = this;
      this.error.errorMsgSub_Dist = !this.addpostalcode.sub_District
        ? "Please fill in the required fields"
        : "";
      this.error.errorMsgPost_Cde = !this.addpostalcode.postal_Code
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      if (
        self.addpostalcode.sub_District != null &&
        self.addpostalcode.sub_District != "" &&
        self.addpostalcode.postal_Code != null &&
        self.addpostalcode.postal_Code != ""
      ) {
        let subtxt = self.addpostalcode.postal_Code;
        self.GetSubDistrict();
        self.defaultSort();
        let disid = null;
        for (let i = 0; i < self.SubDistrictList.length; i++) {
          if (
            self.addpostalcode.sub_District ==
            this.SubDistrictList[i].sub_District_Name
          ) {
            disid = this.SubDistrictList[i].sub_District_ID;
          }
        }
        let samecondi = false;
        for (let j = 0; j < self.PostalCodeList.length; j++) {
          if (
            subtxt == self.PostalCodeList[j].postal_Code &&
            self.addpostalcode.sub_District ==
              self.PostalCodeList[j].sub_District_Name
          ) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            sub_District_ID: disid,
            postal_Code: parseInt(self.addpostalcode.postal_Code),
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}PostalCode/AddPostalCode`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.addpostalcode.sub_District = null;
                self.addpostalcode.postal_Code = null;
                self.PostalCodeAddDialog = false;
                self.LoadingDialog = false;
                self.PostalCodeList = [];
                self.GetPostalCode();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Address, PostalCode Fail", 4, "Low");
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("Postal Code with same Sub District already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.addpostalcode.sub_District == null ||
        self.addpostalcode.sub_District == "" ||
        self.addpostalcode.postal_Code == null ||
        self.addpostalcode.postal_Code == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      }
    },
    EditUCountry(temp) {
      let self = this;
      self.CountryEditdialog = true;
      self.editcountry.country_ID = temp.country_ID;
      self.editcountry.country_Name = temp.country_Name;
      self.editcountry.companyID = temp.company_ID;
    },
    EditUProvince(temp) {
      let self = this;
      self.ProvinceEditdialog = true;
      self.editprovinces.province_ID = temp.province_ID;
      self.editprovinces.province_Name = temp.province_Name;
      self.editprovinces.country_Name = temp.country_Name;
      self.editprovinces.companyID = temp.company_ID;
    },
    EditUDistrict(temp) {
      let self = this;
      self.DistrictEditdialog = true;
      self.editdistricts.district_ID = temp.district_ID;
      self.editdistricts.district_Name = temp.district_Name;
      self.editdistricts.province_Name = temp.province_Name;
      self.editdistricts.companyID = temp.company_ID;
    },
    EditUSubDistrict(temp) {
      var self = this;
      self.SubDistrictEditDialog = true;
      self.editsubdistricts.sub_District_ID = temp.sub_District_ID;
      self.editsubdistricts.sub_District_Name = temp.sub_District_Name;
      self.editsubdistricts.district_Name = temp.district_Name;
      self.editsubdistricts.postal_Code = temp.postal_Code;
      self.editsubdistricts.companyID = temp.company_ID;
    },
    EditUPostalCode(temp) {
      var self = this;
      self.PostalCodeEditDialog = true;
      self.editpostalcode.postal_Code_ID = temp.postal_Code_ID;
      self.editpostalcode.sub_District_Name = temp.sub_District_Name;
      self.editpostalcode.postal_Code = temp.postal_Code;
      self.editpostalcode.companyID = temp.company_ID;
    },
    UpdateCountry() {
      let self = this;
      self.error.errorMsgCrty = !self.editcountry.country_Name
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      let ctyVali = self.alphaOnly(self.editcountry.country_Name);
      if (
        self.editcountry.country_Name != "" &&
        self.editcountry.country_Name != null &&
        ctyVali == true
      ) {
        var ctytxt =
          self.editcountry.country_Name.charAt(0).toUpperCase() +
          self.editcountry.country_Name.slice(1);
        let samecondi = false;
        let FilterCountry = self.CountryList.filter(
          (data) => data.country_ID != self.editcountry.country_ID
        );
        if (FilterCountry.length != 0) {
          for (let i = 0; i < FilterCountry.length; i++) {
            if (ctytxt == FilterCountry[i].country_Name) {
              samecondi = false;
              break;
            } else {
              samecondi = true;
            }
          }
        } else {
          samecondi = true;
        }
        if (samecondi == true) {
          let tempads = {
            country_ID: self.editcountry.country_ID,
            country_Name: ctytxt,
            company_ID:
              self.editcountry.companyID == 0 ? 0 : store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}Country/UpdateCountry`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.editcountry.country_Name = null;
                self.CountryEditdialog = false;
                self.LoadingDialog = false;
                self.CountryList = [];
                self.GetCountry();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Address, Country Fail", 4, "Medium");
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("Country already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.editcountry.country_Name == "" ||
        self.editcountry.country_Name == null
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (ctyVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgCrty =
          !this.editcountry.country_Name != ctyVali ? "Alphabets Only" : "";
      }
    },
    UpdateProvince() {
      let self = this;
      self.error.errorMsgProv = !self.editprovinces.province_Name
        ? "Please fill in the required fields"
        : "";
      self.error.errorMsgCrty = !self.editprovinces.country_Name
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      let proVali = self.alphaOnly(self.editprovinces.province_Name);
      if (
        self.editprovinces.province_Name != null &&
        self.editprovinces.province_Name != "" &&
        self.editprovinces.country_Name != null &&
        self.editprovinces.country_Name != "" &&
        proVali == true
      ) {
        let protxt =
          self.editprovinces.province_Name.charAt(0).toUpperCase() +
          self.editprovinces.province_Name.slice(1);
          self.GetCountry();
        self.defaultSort();
        let ctyid = null;
        for (let i = 0; i < self.CountryList.length; i++) {
          if (
            self.editprovinces.country_Name == self.CountryList[i].country_Name
          ) {
            ctyid = self.CountryList[i].country_ID;
          }
        }
        let samecondi = false;
        let FilterProvince = self.ProvinceList.filter(
          (data) => data.province_ID != self.editprovinces.province_ID
        );
        for (let j = 0; j < FilterProvince.length; j++) {
          if (protxt == FilterProvince[j].province_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            province_ID: self.editprovinces.province_ID,
            province_Name: protxt,
            country_ID: ctyid,
            company_ID:
              self.editprovinces.companyID == 0 ? 0 : store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}Province/UpdateProvince`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.editprovinces.province_Name = null;
                self.editprovinces.country_Name = null;
                self.ProvinceEditdialog = false;
                self.LoadingDialog = false;
                self.ProvinceList = [];
                self.GetProvince();
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update Address, Province Fail",
                4,
                "Medium"
              );
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("Province already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.editprovinces.province_Name == null ||
        self.editprovinces.province_Name == "" ||
        self.editprovinces.country_Name == null ||
        self.editprovinces.country_Name == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (proVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgProv =
          !this.editprovinces.province_Name != proVali ? "Alphabets Only" : "";
      }
    },
    UpdateDistrict() {
      let self = this;
      this.error.errorMsgDist = !this.editdistricts.district_Name
        ? "Please fill in the required fields"
        : "";
      this.error.errorMsgProv = !this.editdistricts.province_Name
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      let disVali = self.alphaOnly(self.editdistricts.district_Name);
      if (
        self.editdistricts.district_Name != null &&
        self.editdistricts.district_Name != "" &&
        self.editdistricts.province_Name != null &&
        self.editdistricts.province_Name != "" &&
        disVali == true
      ) {
        let distxt =
          self.editdistricts.district_Name.charAt(0).toUpperCase() +
          self.editdistricts.district_Name.slice(1);
        self.GetProvince();
        self.defaultSort();
        let proid = null;
        for (let i = 0; i < self.ProvinceList.length; i++) {
          if (
            self.editdistricts.province_Name ==
            this.ProvinceList[i].province_Name
          ) {
            proid = this.ProvinceList[i].province_ID;
          }
        }
        let samecondi = false;
        let FilterDistrict = self.DistrictList.filter(
          (data) => data.district_ID != self.editdistricts.district_ID
        );
        for (let j = 0; j < FilterDistrict.length; j++) {
          if (distxt == FilterDistrict[j].district_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (samecondi == true) {
          let tempads = {
            district_ID: self.editdistricts.district_ID,
            district_Name: distxt,
            province_ID: proid,
            company_ID:
              self.editdistricts.companyID == 0 ? 0 : store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}District/UpdateDistrict`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.editdistricts.district_Name = null;
                self.editdistricts.province_Name = null;
                self.DistrictEditdialog = false;
                self.LoadingDialog = false;
                self.DistrictList = [];
                self.GetDistrict();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update Address, District Fail",
                4,
                "Medium"
              );
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("District already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.editdistricts.district_Name == null ||
        self.editdistricts.district_Name == "" ||
        self.editdistricts.province_Name == null ||
        self.editdistricts.province_Name == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (disVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgDist =
          !this.editdistricts.district_Name != disVali ? "Alphabets Only" : "";
      }
    },
    UpdateSubDistrict() {
      let self = this;
      this.error.errorMsgSub_Dist = !this.editsubdistricts.sub_District_Name
        ? "Please fill in the required fields"
        : "";
      this.error.errorMsgDist = !this.editsubdistricts.district_Name
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      let subDVali = self.alphaOnly(self.editsubdistricts.sub_District_Name);
      if (
        self.editsubdistricts.sub_District_Name != null &&
        self.editsubdistricts.sub_District_Name != "" &&
        self.editsubdistricts.district_Name != null &&
        self.editsubdistricts.district_Name != "" &&
        subDVali == true
      ) {
        let subtxt =
          self.editsubdistricts.sub_District_Name.charAt(0).toUpperCase() +
          self.editsubdistricts.sub_District_Name.slice(1);
        let disid = null;
        for (let i = 0; i < self.DistrictList.length; i++) {
          if (
            self.editsubdistricts.district_Name ==
            this.DistrictList[i].district_Name
          ) {
            disid = this.DistrictList[i].district_ID;
          }
        }
        let samecondi = false;
        let FilterSubDistrict = self.SubDistrictList.filter(
          (data) =>
            data.sub_District_ID != self.editsubdistricts.sub_District_ID
        );
        for (let j = 0; j < FilterSubDistrict.length; j++) {
          if (subtxt == FilterSubDistrict[j].sub_District_Name) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (FilterSubDistrict.length == 0) {
          samecondi = true;
        }
        if (samecondi == true) {
          let tempads = {
            sub_District_ID: self.editsubdistricts.sub_District_ID,
            sub_District_Name: subtxt,
            district_ID: disid,
            //postal_Code: parseInt(self.editsubdistricts.postal_Code),
            company_ID:
              self.editsubdistricts.companyID == 0 ? 0 : store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}Sub_District/UpdateSubDistrict`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.editsubdistricts.sub_District_Name = null;
                self.editsubdistricts.district_Name = null;
                self.editsubdistricts.postal_Code = null;
                self.SubDistrictEditDialog = false;
                self.LoadingDialog = false;
                self.SubDistrictList = [];
                self.GetSubDistrict();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update Address, SubDistrict Fail",
                4,
                "Medium"
              );
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("SubDistrict already exists!");
          self.LoadingDialog = false;
        }
      } else if (
        self.editsubdistricts.sub_District_Name == null ||
        self.editsubdistricts.sub_District_Name == "" ||
        self.editsubdistricts.district_Name == null ||
        self.editsubdistricts.district_Name == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      } else if (subDVali != true) {
        alert("Please Enter Alphabets Only!");
        self.LoadingDialog = false;
        this.error.errorMsgSub_Dist =
          !this.editsubdistricts.sub_District_Name != subDVali
            ? "Alphabets Only"
            : "";
      }
    },
    UpdatePostalCode() {
      let self = this;
      this.error.errorMsgSub_Dist = !this.editpostalcode.sub_District_Name
        ? "Please fill in the required fields"
        : "";
      this.error.errorMsgPost_Cde = !this.editpostalcode.postal_Code
        ? "Please fill in the required fields"
        : "";
      self.LoadingDialog = true;
      if (
        self.editpostalcode.sub_District_Name != null &&
        self.editpostalcode.sub_District_Name != "" &&
        self.editpostalcode.postal_Code != null &&
        self.editpostalcode.postal_Code != ""
      ) {
        let subtxt = self.editpostalcode.postal_Code;
        let disid = null;
        for (let i = 0; i < self.SubDistrictList.length; i++) {
          if (
            self.editpostalcode.sub_District_Name ==
            this.SubDistrictList[i].sub_District_Name
          ) {
            disid = this.SubDistrictList[i].sub_District_ID;
          }
        }
        let samecondi = false;
        let FilterPostalCode = self.PostalCodeList.filter(
          (data) => data.postal_Code_ID != self.editpostalcode.postal_Code_ID
        );
        for (let j = 0; j < FilterPostalCode.length; j++) {
          if (
            subtxt == FilterPostalCode[j].postal_Code &&
            self.editpostalcode.sub_District_Name ==
              self.FilterPostalCode[j].sub_District_Name
          ) {
            samecondi = false;
            break;
          } else {
            samecondi = true;
          }
        }
        if (FilterPostalCode.length == 0) {
          samecondi = true;
        }
        if (samecondi == true) {
          let tempads = {
            sub_District_ID: disid,
            postal_Code_ID: self.editpostalcode.postal_Code_ID,
            postal_Code: parseInt(self.editpostalcode.postal_Code),
            company_ID:
              self.editpostalcode.companyID == 0 ? 0 : store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}PostalCode/UpdatePostalCode`, tempads)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.editpostalcode.sub_District_Name = null;
                self.editpostalcode.postal_Code = null;
                self.PostalCodeEditDialog = false;
                self.LoadingDialog = false;
                self.PostalCodeList = [];
                self.GetPostalCode();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update Address, PostalCode Fail",
                4,
                "Medium"
              );
              alert(error + "Correlation ID is 4");
              self.LoadingDialog = false;
            });
        } else {
          alert("Postal Code with same Sub District already exists !");
          self.LoadingDialog = false;
        }
      } else if (
        self.editpostalcode.sub_District_Name == null ||
        self.editpostalcode.sub_District_Name == "" ||
        self.editpostalcode.postal_Code == null ||
        self.editpostalcode.postal_Code == ""
      ) {
        alert("Fill the required field!");
        self.LoadingDialog = false;
      }
    },
    DeleteCountry() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.CountryID;
      let tempads = {
        country_ID: id,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}Country/DeleteCountry`, tempads)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.CountryDeletedialog = false;
            self.LoadingDialog = false;
            self.CountryList = [];
            self.GetCountry();
            self.GetProvince();
            self.GetDistrict();
            self.GetSubDistrict();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Address,Country Fail", 4, "High");
          alert(error + "Correlation ID is 4");
        });
    },
    DeleteProvince() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.ProvinceID;
      let tempads = {
        province_ID: id,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}Province/DeleteProvince`, tempads)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.ProvinceDeletedialog = false;
            self.LoadingDialog = false;
            self.ProvinceList = [];
            self.GetProvince();
            self.GetDistrict();
            self.GetSubDistrict();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Address, Province Fail", 4, "High");
          alert(error + "Correlation ID is 4");
        });
    },
    DeleteDistrict() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.DistrictID;
      let tempads = {
        district_ID: id,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}District/DeleteDistrict`, tempads)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.DistrictDeletedialog = false;
            self.LoadingDialog = false;
            self.DistrictList = [];
            self.GetDistrict();
            self.GetSubDistrict();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Address, District Fail", 4, "High");
          alert(error + "Correlation ID is 4");
        });
    },
    DeleteSubDistrict() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.SubDistrictID;
      let tempads = {
        sub_District_ID: id,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}Sub_District/DeleteSubDistrict`, tempads)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.SubDistrictDeleteDialog = false;
            self.LoadingDialog = false;
            self.SubDistrictList = [];
            self.GetSubDistrict();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Delete Un Address , SubDistrict Fail",
            4,
            "High"
          );
          alert(error + "Correlation ID is 4");
        });
    },
    DeletePostalCode() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.PostalCodeID;
      let tempads = {
        postal_Code_ID: id,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}PostalCode/DeletePostalCode`, tempads)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.PostalCodeDeleteDialog = false;
            self.LoadingDialog = false;
            self.PostalCodeList = [];
            self.GetPostalCode();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Delete Un Address , PostalCode Fail",
            4,
            "High"
          );
          alert(error + "Correlation ID is 4");
        });
    },
    UploadCountry() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.importData = XLSX.utils.sheet_to_json(worksheet);
          let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          const file_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          if (temp.length != 0) {
            let combileArray = [];
            for (let i = 0; i < this.importData.length; i++) {
              this.tempimport = {
                country_Name: file_data[i + 1][0],
                user_ID: store.state.userid,
                company_ID: store.state.companyID,
              };
              combileArray.push(this.tempimport);
            }
            if (filename == "Country Master Data.xlsx") {
              axios
                .post(
                  `${self.url}Country/ImportCountryMasterData`,
                  combileArray
                )
                .then(function (response) {
                  if (response.data.status == 0) {
                    alert(response.data.message);
                    self.chosenfile = null;
                    self.showup = false;
                    self.CountryUploaddialog = false;
                    self.LoadingDialog = false;
                    self.CountryList = [];
                    self.GetCountry();
                    self.defaultSort();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Upload Address's Country Fail",
                    4,
                    "Verbose"
                  );
                  alert(error + "Correlation ID is 4");
                  self.LoadingDialog = false;
                });
            } else {
              alert("File name must be Country Master Data.xlsx");
              self.LoadingDialog = false;
            }
          } else {
            alert("Please fill country_Name");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    UploadProvince() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Province Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            let go = [];
            for (let j = 0; j < self.importData.length; j++) {
              go.push({
                province_Name: file_data[j + 1][0],
                country_ID: file_data[j + 1][1],
              });
            }
            var AFiltered = go.filter((t) => t.province_Name == undefined);
            var BFiltered = go.filter((t) => t.country_ID == undefined);
            if (
              AFiltered.length == 0 &&
              BFiltered.length == 0 &&
              temp.length != 0
            ) {
              let combileArray = [];
              for (let i = 0; i < self.importData.length; i++) {
                let k = i + 1;
                self.tempimport = {
                  province_Name: file_data[k][0],
                  country_ID: file_data[k][1],
                  user_ID: store.state.userid,
                  company_ID: store.state.companyID,
                };
                self.GetCountry();
                let CountryFound = false;
                for (let j = 0; j < self.CountryList.length; j++) {
                  if (
                    self.tempimport.country_ID ==
                    self.CountryList[j].country_Name
                  ) {
                    self.tempimport.country_ID = self.CountryList[j].country_ID;
                    CountryFound = true;
                    break;
                  }
                }
                if (CountryFound == true) {
                  combileArray.push(self.tempimport);
                }
              }
              if (combileArray.length != 0) {
                axios
                  .post(
                    `${self.url}Province/ImportProvinceMasterData`,
                    combileArray
                  )
                  .then(function (response) {
                    if (response.data.status == 0) {
                      alert(response.data.message);
                      self.chosenfile = null;
                      self.showup = false;
                      self.ProvinceUploaddialog = false;
                      self.ProvinceList = [];
                      self.GetProvince();
                      self.defaultSort();
                      self.LoadingDialog = false;
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(
                      error,
                      "Upload Address's Province Fail",
                      4,
                      "Critical"
                    );
                    alert(error + "Correlation ID is 4");
                    self.LoadingDialog = false;
                  });
              } else {
                alert("There is no Country match in excel!");
                self.LoadingDialog = false;
              }
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert("Please fill province_Name and country_ID.");
              } else {
                if (AFiltered.length != 0) {
                  self.errorMessage.push("province_Name");
                }
                if (BFiltered.length != 0) {
                  self.errorMessage.push("country_ID");
                }
                let Message = [...new Set(self.errorMessage)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          } else {
            alert("File name must be Province Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    UploadDistrict() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "District Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            let to = [];
            for (let j = 0; j < self.importData.length; j++) {
              to.push({
                district_Name: file_data[j + 1][0],
                province_ID: file_data[j + 1][1],
              });
            }
            var AFiltered = to.filter((t) => t.district_Name == undefined);
            var BFiltered = to.filter((t) => t.province_ID == undefined);
            if (
              AFiltered.length == 0 &&
              BFiltered.length == 0 &&
              temp.length != 0
            ) {
              let combileArray = [];
              for (let i = 0; i < self.importData.length; i++) {
                self.tempimport = {
                  district_Name: file_data[i + 1][0],
                  province_ID: file_data[i + 1][1],
                  user_ID: store.state.userid,
                  company_ID: store.state.companyID,
                };
                self.GetProvince();
                let ProvinceFound = false;
                for (let j = 0; j < self.ProvinceList.length; j++) {
                  if (
                    self.tempimport.province_ID ==
                    self.ProvinceList[j].province_Name
                  ) {
                    self.tempimport.province_ID =
                      self.ProvinceList[j].province_ID;
                    ProvinceFound = true;
                    break;
                  }
                }
                if (ProvinceFound == true) {
                  combileArray.push(self.tempimport);
                }
              }
              if (combileArray.length != 0) {
                axios
                  .post(
                    `${self.url}District/ImportDistrictMasterData`,
                    combileArray
                  )
                  .then(function (response) {
                    if (response.data.status == 0) {
                      alert(response.data.message);
                      self.chosenfile = null;
                      self.showup = false;
                      self.DistrictUploaddialog = false;
                      self.LoadingDialog = false;
                      self.DistrictList = [];
                      self.GetDistrict();
                      self.defaultSort();
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(
                      error,
                      "Upload  Address's District Fail",
                      4,
                      "Verbose"
                    );
                    alert(error + "Correlation ID is 4");
                    self.LoadingDialog = false;
                  });
              } else {
                alert("There is no Province match in excel!");
                self.LoadingDialog = false;
              }
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert("Please fill district_Name and province_ID.");
              } else {
                if (AFiltered.length != 0) {
                  self.errorMessage1.push("district_Name");
                }
                if (BFiltered.length != 0) {
                  self.errorMessage1.push("province_ID");
                }
                let Message = [...new Set(self.errorMessage1)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          } else {
            alert("File name must be District Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    UploadSubDistrict() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Sub District Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            let party = [];
            for (let j = 0; j < self.importData.length; j++) {
              party.push({
                sub_District_Name: file_data[j + 1][0],
                district_ID: file_data[j + 1][1],
                //postal_Code: file_data[j + 1][2],
              });
            }
            var AFiltered = party.filter(
              (t) => t.sub_District_Name == undefined
            );
            var BFiltered = party.filter((t) => t.district_ID == undefined);
            //var CFiltered = party.filter((t) => t.postal_Code == undefined);
            if (
              AFiltered.length == 0 &&
              BFiltered.length == 0 &&
              //CFiltered.length == 0 &&
              temp.length != 0
            ) {
              let combileArray = [];
              for (let i = 0; i < self.importData.length; i++) {
                self.tempimport = {
                  sub_District_Name: file_data[i + 1][0],
                  district_ID: file_data[i + 1][1],
                  postal_Code: 0,
                  user_ID: parseInt(store.state.userid),
                  company_ID: store.state.companyID,
                };
                self.GetDistrict();
                let DistrictFound = false;
                for (let j = 0; j < self.DistrictList.length; j++) {
                  if (
                    self.tempimport.district_ID ==
                    self.DistrictList[j].district_Name
                  ) {
                    self.tempimport.district_ID =
                      self.DistrictList[j].district_ID;
                    DistrictFound = true;
                    break;
                  }
                }
                if (DistrictFound == true) {
                  combileArray.push(self.tempimport);
                }
              }
              if (combileArray.length != 0) {
                axios
                  .post(
                    `${self.url}Sub_District/ImportSubDistrictMasterData`,
                    combileArray
                  )
                  .then(function (response) {
                    if (response.data.status == 0) {
                      alert(response.data.message);
                      self.chosenfile = null;
                      self.showup = false;
                      self.SubDistrictUploadDialog = false;
                      self.LoadingDialog = false;
                      self.SubDistrictList = [];
                      self.GetSubDistrict();
                      self.defaultSort();
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(
                      error,
                      "Upload Address's Sub District Fail",
                      4,
                      "Verbose"
                    );
                    alert(error + "Correlation ID is 4");
                    self.LoadingDialog = false;
                  });
              } else {
                alert("There is no District match in excel!");
                self.LoadingDialog = false;
              }
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert(
                  "Please fill sub_District_Name, district_ID and postal_Code"
                );
              } else {
                if (AFiltered.length != 0) {
                  self.errorMessage2.push("sub_District_Name");
                }
                if (BFiltered.length != 0) {
                  self.errorMessage2.push("district_ID");
                }
                // if (CFiltered.length != 0) {
                //   self.errorMessage2.push("postal_Code");
                // }
                let Message = [...new Set(self.errorMessage2)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          } else {
            alert("File name must be Sub District Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    UploadPostalCode() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Postal Code Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            let party = [];
            for (let j = 0; j < self.importData.length; j++) {
              party.push({
                postal_Code: file_data[j + 1][0],
                sub_District_ID: file_data[j + 1][1],
              });
            }
            var AFiltered = party.filter((t) => t.postal_Code == undefined);
            var BFiltered = party.filter((t) => t.sub_District_ID == undefined);
            if (
              AFiltered.length == 0 &&
              BFiltered.length == 0 &&
              temp.length != 0
            ) {
              let combileArray = [];
              for (let i = 0; i < self.importData.length; i++) {
                self.tempimport = {
                  postal_Code: parseInt(file_data[i + 1][0]),
                  sub_District_ID: file_data[i + 1][1],
                  user_ID: parseInt(store.state.userid),
                  company_ID: store.state.companyID,
                };
                self.GetSubDistrict();
                let SubDisFound = false;
                for (let j = 0; j < self.SubDistrictList.length; j++) {
                  if (
                    self.tempimport.sub_District_ID ==
                    self.SubDistrictList[j].sub_District_Name
                  ) {
                    self.tempimport.sub_District_ID =
                      self.SubDistrictList[j].sub_District_ID;
                    SubDisFound = true;
                    break;
                  }
                }
                if (SubDisFound == true) {
                  combileArray.push(self.tempimport);
                }
              }
              if (combileArray.length != 0) {
                axios
                  .post(
                    `${self.url}PostalCode/ImportPostalCodeMasterData`,
                    combileArray
                  )
                  .then(function (response) {
                    if (response.data.status == 0) {
                      alert(response.data.message);
                      self.chosenfile = null;
                      self.showup = false;
                      self.PostalCodeUploadDialog = false;
                      self.LoadingDialog = false;
                      self.PostalCodeList = [];
                      self.GetPostalCode();
                      self.defaultSort();
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(
                      error,
                      "Upload Address's PostalCode Fail",
                      4,
                      "Verbose"
                    );
                    alert(error + " Correlation ID is 4");
                    self.LoadingDialog = false;
                  });
              } else {
                alert("There is no SubDistrict match in excel!");
                self.LoadingDialog = false;
              }
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert("Please fill postal_Code and sub_District_ID");
              } else {
                if (AFiltered.length != 0) {
                  self.errorMessage2.push("postal_Code");
                }
                if (BFiltered.length != 0) {
                  self.errorMessage2.push("sub_District_ID");
                }
                let Message = [...new Set(self.errorMessage2)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          } else {
            alert("File name must be Postal Code Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    Search(num) {
      let self = this;
      if (num == 1) {
        if (self.countrysearchtxt != "" && self.countrysearchtxt != undefined) {
          let List = self.CountryList;
          self.CountryList = [];
          for (let i = 0; i < List.length; i++) {
            if (
              List[i].country_Name
                .toLowerCase()
                .includes(self.countrysearchtxt.toLowerCase())
            ) {
              self.CountryList.push(List[i]);
            }
          }
        } else {
          alert("Please enter anything!!");
          self.GetCountry();
        }
      } else if (num == 2) {
        if (
          self.provincesearchtxt != "" &&
          self.provincesearchtxt != undefined
        ) {
          let List = self.ProvinceList;
          self.ProvinceList = [];
          for (let c = 0; c < List.length; c++) {
            if (
              List[c].province_Name
                .toLowerCase()
                .includes(self.provincesearchtxt.toLowerCase())
            ) {
              self.ProvinceList.push(List[c]);
            }
          }
        } else {
          alert("Please enter anything!!");
          self.GetProvince();
        }
      } else if (num == 3) {
        if (
          self.districtsearchtxt != "" &&
          self.districtsearchtxt != undefined
        ) {
          let List = self.DistrictList;
          self.DistrictList = [];
          for (let i = 0; i < List.length; i++) {
            if (
              List[i].district_Name
                .toLowerCase()
                .includes(self.districtsearchtxt.toLowerCase())
            ) {
              self.DistrictList.push(List[i]);
            }
          }
        } else {
          alert("Please enter anything!!");
          self.GetDistrict();
        }
      } else if (num == 4) {
        if (
          self.subdistrictsearchtxt != "" &&
          self.subdistrictsearchtxt != undefined
        ) {
          let List = self.SubDistrictList;
          self.SubDistrictList = [];
          for (let i = 0; i < List.length; i++) {
            if (
              List[i].sub_District_Name
                .toLowerCase()
                .includes(self.subdistrictsearchtxt.toLowerCase())
            ) {
              self.SubDistrictList.push(List[i]);
            }
          }
        } else {
          alert("Please enter anything!!");
          self.GetSubDistrict();
        }
      } else {
        if (
          self.postalcodesearchtxt != "" &&
          self.postalcodesearchtxt != undefined
        ) {
          let List = self.PostalCodeList;
          self.PostalCodeList = [];
          for (let i = 0; i < List.length; i++) {
            if (
              List[i].postal_Code
                .toString()
                .includes(self.postalcodesearchtxt.toLowerCase())
            ) {
              self.PostalCodeList.push(List[i]);
            }
          }
        } else {
          alert("Please enter anything!!");
          self.GetPostalCode();
        }
      }
    },
  },
};
</script>
<style scoped>
>>> .v-select__selections {
  padding-left: unset !important;
}

::v-deep .v-window {
  overflow:visible!important;
}

@media (max-width: 320px) {
  .delete_btn {
    margin-left: -19px !important;
  }
}

@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

@media (max-width: 380px) {
  .btnres {
    margin-top: 1rem !important;
  }
}

@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}

.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
  border-radius: 15px 15px 0px 0px;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 15px 0px 0px;
}

.v-tabs-bar .v-tab {
  background-color: white;
  width: 140px;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 15px 15px 0px 0px;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

.posbtn {
  width: 22px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

.a {
  text-decoration: none;
}

>>> .mdi-paperclip::before {
  content: none;
}

>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>> .v-dialog {
  border-radius: 15px !important;
}

>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>> .v-data-table tr td {
  height: 70px;
}

>>> .theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  font-family: "Kanit";
  color: #f99d20;
}
</style>
<style>
.mdi-close::before {
  /* content: "\F0156"; */
  color: red !important;
  display: none !important;
}

.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
</style>
