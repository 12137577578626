<template>
  <div>
    <div v-if="back_t" :key="componentKey"><Timeoff :back_t="back_t" :key="componentKey" /></div>
    <div v-else>
      <v-card class="pt-5" style="margin-bottom: 5rem">
        <v-row class="mt-5" style="margin-left: 1rem; margin-right: 1rem">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="8" md="3" lg="3" xl="2">
            <p style="margin-top: -5px" class="text-right company_text">
              {{ $t("CompanyPolicy") }}
            </p>
          </v-col>

          <v-col class="pt-0" cols="12" xs="12" sm="4" md="3" lg="3" xl="2">
            <v-select
              class="txttype1 pl-0"
              :items="itemselect"
              v-model="select"
              single-line
              item-text="txt"
              item-value="val"
              return-object
              persistent-hint
              value="Basic"
            >
              <template v-slot:append>
                <img
                  class="mr-2"
                  style="margin-top: 3px"
                  height="20px"
                  src="@/assets/images/down@2x.png"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row style="margin-left: 1rem; margin-right: 1rem">
          <v-col cols="12" sm="4" md="3" lg="2">
            <p style="margin-top: 10px; font-size: 16px">
              {{ $t("TypeOfTimeOff") }} :
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="typename"
              class="txttype"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          style="margin-left: 2rem; margin-right: 1.5rem"
          :headers="headers"
          :items="PositionList"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :mobile-breakpoint="0"
        >
          <template v-slot:item.availability_Days="{ item }">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="item.availability_Days"
              class="txtnumbox pt-1 ml-0 pl-3"
              type="number"
              @keypress="onlyNumber"
              value="0"
              min="0"
              max="98"
            >
              <template v-slot:append>
                <div style="margin-right: 5px; margin-top: 13px">
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="addnum(item)"
                      icon
                      text
                    >
                      <img src="@/assets/images/tb-up-arrow.png" alt />
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="subnum(item)"
                      icon
                      text
                    >
                      <img
                        src="@/assets/images/tb-down-arrow.png"
                        style="margin-top: 5px"
                        alt
                      />
                    </v-btn>
                  </v-row>
                </div>
              </template>
            </v-text-field>
          </template>
        </v-data-table>
        <div style="height: 10vh"></div>
        <v-row style="margin-left: 2rem; margin-right: 2rem; bottom: 0">
          <v-spacer></v-spacer>
          <v-btn
            color="#F99D20"
            class="text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="back"
          >
            {{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="addnewTOT"
          >
            {{ $t("Save") }}</v-btn
          >
        </v-row>
        <br />
        <br />
      </v-card>
    </div>

    <!-- Upload Dialog -->
    <v-dialog v-model="TOTUploaddialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            Upload File
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col cols="4" md="4">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="6" md="6">
                <p style="margin-top: 0px; margin-left: -40px; color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" />
            <v-row class="mt-7">
              <v-col style="margin-top: -20px" align="left" cols="12">
                <p style="font-size: 19px; color: #444444">
                  {{ $t("excelUploadFormat") }}
                </p>
              </v-col>
              <v-col cols="3">
                <p>Document Template.xlsx</p>
              </v-col>
              <v-col cols="2">
                <a
                  style="color: #f99d20"
                  href="https://optimisticpathfile.blob.core.windows.net/optimisticfile/New Time Off Type Master Data.xlsx"
                >
                  {{ $t("Download") }}</a
                >
              </v-col>
            </v-row>
            <hr class="mt-4" />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="159"
            height="35"
            @click="closeUpload"
            text
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text mr-4 text-capitalize"
            width="159"
            height="35"
            @click="previewFiles"
          >
            {{ $t("Upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import Timeoff from "./Timeoff.vue";
export default {
  name: "Timeoffadd",
  mixins: [LogTrace, utils],
  components: { Timeoff },
  data() {
    return {
      componentKey: 0,
      back_t: false,
      url: enurl.apiUrl,
      itemsPerPage: 1000,
      TOTUploaddialog: false,
      TOTList: [],
      show: false,
      showup: false,
      tempimport: [],
      temptots: {
        availability_Days: null,
        employment_length: null,
        start_Year: null,
        end_Year: null,
        company_Policy: null,
        anuual: null,
        position_ID: null,
        company_ID: null,
        timeoff_Type: null,
      },
      importData: [],
      availability_Days: 0,
      PositionList: [],
      typename: null,
      chosenfile: null,
      LoadingDialog: false,
      images: {
        editpic: require("@/assets/images/Mask Group 178.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
      },
      select: 1,
    };
  },
  computed: {
    itemselect() {
      return [
        { txt: this.$t("Basic"), val: 1 },
        { txt: this.$t("LengthOfService"), val: 2, disabled: true },
        // { txt: this.$t("ProrateVacation"), val: 3, disabled: true },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("positonLevel"),
          value: "position_lvl",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("position"),
          value: "position_Name",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("NumberOfDaysYear"),
          value: "availability_Days",
          align: "left",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.GetPosition();
    this.GetTimeOffType();
    
  },

  methods: {
    back() {
      let self = this;
      self.$router.replace({ path: "/organization/timeoff/timeofftype" }).then(() => {
        self.$nextTick(() => {
          self.$router.replace({ path: self.$route.path });
        });
      });
    },
    addnum(item) {
      //if(item.availability_Days != "" && item.availability_Days != ""){
      if (item.availability_Days < 98 && item.availability_Days >= 0) {
        item.availability_Days++;
      }
      // else{
      //   alert("Enter Number!");
      // }
      //}
    },
    subnum(item) {
      //if(item.availability_Days != "" && item.availability_Days != ""){
      if (item.availability_Days > 0 && item.availability_Days <= 98) {
        item.availability_Days--;
      }
      // else{
      //   alert("Enter Number!");
      // }
      // }
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      this.showup = true;
    },
    closeUpload() {
      this.TOTUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    showtab(mytab) {
      if (mytab == 3) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.lengthof = mytab;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    GetTimeOffType() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOff/GetTimeOffByCompanyID`, tempp)
        .then(function (response) {
          self.TOTList = response.data.data;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    GetPosition() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Position/GetPosition`, tempp)
        .then(function (response) {
          self.PositionList = response.data.data;
          self.LoadingDialog = false;
          // for(let i =0;i<self.PositionList.length;i++){
          //   self.PositionList[i].availability_Days=0;
          // }
        });
    },
    addnewTOT() {
      let self = this;
      self.LoadingDialog = true;
      if (self.typename != "" && self.typename != null) {
        let temparr = self.pusharr();

        // let samecondi = false;
        // for (let j = 0; j < self.TOTList.length; j++) {
        //   if (self.typename.toLowerCase() == self.TOTList[j].timeoff_Type.toLowerCase()) {
        //     samecondi = false;
        //     break;
        //   } else {
        //     samecondi = true;
        //   }
        // }
        // if (samecondi == true) {
        let temptype = {
          staffData: temparr,
          timeOffTypeData: {
            timeoff_Type: self.typename,
            company_ID: store.state.companyID,
          },
        };
        axios.post(`${self.url}TimeOffType/AddTimeOffType`, temptype)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.LoadingDialog = false;
              self.$router.replace({ path: "/organization/timeoff/timeofftype" }).then(() => {
                self.$nextTick(() => {
                  self.$router.replace({ path: self.$route.path });
                });
              });
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Time Off Type Fail", 10, "Low");
            //alert(error);
            self.LoadingDialog = false;
          });
        // } else {
        //   alert("Time off already exists!");
        //   self.LoadingDialog = false;
        // }
      } else {
        alert("Enter Time off type");
        self.LoadingDialog = false;
      }
    },
    pusharr() {
      let self = this;
      let temparr = [
        {
          availability_Days: null,
          employment_length: null,
          start_Year: null,
          end_Year: null,
          company_Policy: null,
          anuual: "",
          position_ID: null,
          company_ID: null,
          timeoff_Type: null,
        },
      ];
      for (let i = 0; i < self.PositionList.length; i++) {
        if (i == 0) {
          if (self.PositionList[i].availability_Days != undefined) {
            temparr[i].availability_Days = parseInt(
              self.PositionList[i].availability_Days
            );
          } else {
            temparr[i].availability_Days = 0;
          }
          temparr[i].employment_length = 0;
          temparr[i].start_Year = 0;
          temparr[i].end_Year = 0;
          temparr[i].company_Policy = 1;
          temparr[i].anuual = "";
          temparr[i].position_ID = self.PositionList[i].position_ID;
          temparr[i].company_ID = store.state.companyID;
          temparr[i].timeoff_Type = self.typename;
        } else {
          let availabledays = null;
          if (self.PositionList[i].availability_Days == undefined) {
            availabledays = 0;
          } else {
            availabledays = parseInt(self.PositionList[i].availability_Days);
          }
          temparr.push({
            availability_Days: availabledays,
            employment_length: 0,
            start_Year: 0,
            end_Year: 0,
            company_Policy: 1,
            anuual: "",
            position_ID: self.PositionList[i].position_ID,
            company_ID: store.state.companyID,
            timeoff_Type: self.typename,
          });
        }
      }
      return temparr;
    },
    previewFiles() {
      let self = this;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "New Time Off Type Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            let combileArray = [];
            for (let i = 0; i < this.importData.length; i++) {
              this.tempimport = {
                ...this.importData[i],
                company_ID: store.state.companyID,
              };
              combileArray.push(this.tempimport);
              //self.PositionList.numtxt = self.importData[i].availability_Days;
              //console.log(combileArray);
            }
            self.typename = combileArray[1].timeoff_Type;
            self.numtxt = combileArray[1].availability_Days;
            //console.log(combileArray);
            //self.PositionList[1].numtxt = combileArray[1].availability_Days;
            // for(let j=0; j<combileArray.length; j++){
            //   self.numtxt = combileArray[j].availability_Days;

            // }
            self.TOTUploaddialog = false;

            // axios
            //   .post(
            //     `https://optimisticwebapi.azurewebsites.net/api/Address/ImportAddressMasterData`,
            //     combileArray
            //   )
            //   .then(function(response) {
            //     if (response.data.status == 0) {
            //       alert(response.data.message);
            //       self.chosenfile = null;
            //       self.showup = false;
            //       self.AUploaddialog = false;
            //       self.GetAddress();
            //     }
            //   })
            //   .catch(function(error) {
            //     alert(error);
            //   });
          } else {
            alert("File name must be New Time Off Type Master Data.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
  },
};
</script>
<style>
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style scoped>
@media only screen and (max-width: 414px) {
  .company_text {
    text-align: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .dropup_btn {
    margin-left: 6rem;
  }
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 10px !important;
}
>>> .theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 16px;
  color: #f99d20;
}
>>> .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}
.v-input__icon--append .v-icon {
  color: purple;
}
.theme--light.v-select .v-select__selections {
  color: #f89d1f;
}
</style>
<style>
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
.txtnumbox {
  text-decoration: none !important;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  /* width: 100px !important; */
  height: 37px !important;
  opacity: 1 !important;
  border-radius: 4px !important;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0px;
}
.txtnumbox.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.txtnumbox.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.txttype {
  text-decoration: none !important;
  border-style: solid;
  border-color: #00000029;
  width: 192px !important;
  height: 37px;
  border-radius: 4px !important;
  padding-top: 0px;
  padding-left: 10px;
  margin-left: 10px;
}

.txttype.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.txttype.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
@media only screen and (max-width: 600px) {
  .txttype {
    margin-right: 1rem;
  }
}
</style>
