<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("salaryhistoryrp") }}
        </p>
    </div>

    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="4" md="4" lg="2" xl="2">
          <p class="mt-0">{{ $t("Employees") }}</p>
          <v-autocomplete
            :items="empsItem"
            item-text="emptxt"
            item-value="empval"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="employsort"
            style="min-width: 160px; max-width: 190px; border-radius: 5px"
            dense
            v-on:change="changetoshowDep(employsort)"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
        <v-col v-if="showDep" cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
          <p class="mt-0 ml-2">{{ $t("department") }}</p>
          <v-select
            class="area-select movelf ml-2"
            v-model="value"
            multiple
            dense
            :items="options"
            item-text="department_Name"
            item-value="department_Name"
            outlined
            color="#F99D20"
            style="min-width: 170px; max-width: 230px; border-radius: 5px"
          >
            <template v-slot:selection="{ index }">
              <span v-if="index === 0"> Department ({{ value.length }})</span>

              <!-- <span v-else>{{ value.department_Name }}</span> -->
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="2" v-if="showDep">
          <v-row class="lefz" style="margin-top: 2.6rem">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize ml-3 mr-5"
              width="150"
              max-height="35"
              max-width="100"
              min-width="100"
              @click="Apply()"
              >{{ $t("Apply") }}</v-btn
            >
            <p class="mt-2" style="cursor: pointer" @click="reseto()">
              {{ $t("Reset") }}
            </p>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="mr-5" cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <p class="mt-0">{{ $t("Showing") }}</p>
          <v-autocomplete
            v-model="itemInnerStatus"
            :items="Activelist"
            item-text="txt"
            item-value="val"
            outlined
            dense
            color="#F99D20"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-autocomplete
            :items="exportItem"
            item-text="exptxt"
            item-value="expval"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="expit"
            :placeholder="$t(`Export`)"
            style="
              min-width: 130px;
              max-width: 130px;
              border-radius: 5px;
              margin-top: 2.5rem;
            "
            dense
            v-on:change="changetoexport(expit)"
            :disabled="permission.export"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
      </v-row>
      <p style="font-size: 18px; margin-left: 30px; color: #f99d20">
        {{ $t("frontPeople") }} {{ LastPayChangeList.length }}
        {{ $t("backPeople") }}
      </p>
      <div class="mt-5">
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :items="itemFilter"
          hide-default-footer
          hide-default-header
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>{{ $t("EmployeeID") }}</th>
                <th>{{ $t("employee_name") }}</th>
                <th>{{ $t("hired_date") }}</th>
                <th>{{ $t("department") }}</th>
                <th>{{ $t("position") }}</th>
                <th>{{ $t("previousday") }}</th>
                <th>{{ $t("currentday") }}</th>
                <th>{{ $t("dateofchange_report") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.employee_Code }}</td>
              <td>{{ item.employee_Name }}</td>
              <td>{{ DateFormat(item.hired_Date) }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.previous_Pay }}</td>
              <td>{{ item.current_Pay }}</td>
              <td>{{ DateFormat(item.date_Of_Change) }}</td>
            </tr>
          </template>
        </v-data-table>
        <div v-show="this.LastPayChangeList.length != 0" class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#f99d20"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <!-- print dialog -->
    <v-dialog v-model="printDialog" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p class="headtext" style="font-size: 28px">
                    {{ $t("lastpaychange") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="ggg"
                    style="padding: -10px; padding-right: 0px"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <div class="mt-4" id="middlecol">
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :items="itemFilter"
                hide-default-footer
                hide-default-header
                :items-per-page="itemsPerPage1"
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("employee_name") }}</th>
                      <th>{{ $t("hired_date") }}</th>
                      <th>{{ $t("department") }}</th>
                      <th>{{ $t("position") }}</th>
                      <th>{{ $t("previousday") }}</th>
                      <th>{{ $t("currentday") }}</th>
                      <th>{{ $t("dateofchange_report") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.employee_Code }}</td>
                    <td>{{ item.employee_Name }}</td>
                    <td>{{ DateFormat(item.hired_Date) }}</td>
                    <td>{{ item.department }}</td>
                    <td>{{ item.position }}</td>
                    <td>{{ item.previous_Pay }}</td>
                    <td>{{ item.current_Pay }}</td>
                    <td>{{ DateFormat(item.date_Of_Change) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              printDialog = false;
              expit = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-weight: normal"
            @click="downloadPDF()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
// import DatePicker from "vue2-datepicker";
import LogTrace from "@/function/Log.js";
import XLSX from "xlsx";
import $ from "jquery";
import CheckViewExport from "@/function/RolePermissions.js";
export default {
  mixins: [CheckViewExport, LogTrace],
  data() {
    return {
      permission: {
        export: true,
      },
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      itemsPerPage1: 500,
      items: [10, 20, 30],
      LoadingDialog: false,
      todaydate: new Date(),
      todayyears: new Date(),
      expit: "",
      value: [],
      employsort: 1,
      showact: 1,
      monthselet: "",
      showDep: false,
      sheetTitle: null,
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      options: [],
      printDialog: false,
      LastPayChangeList: [],
      filter: [],
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      itemInnerStatus: "Active",
      Temp: [],
    };
  },
  mounted() {
    let self = this;
    self.GetLastPayChange();
    self.GetDepartment();
    self.permission = self.CheckViewExport(180, 181);
  },
  computed: {
    empsItem() {
      return [
        { empval: 1, emptxt: this.$t("AllEmployees") },
        { empval: 2, emptxt: this.$t("department") },
      ];
    },
    itemFilter() {
      let self = this;
      let itemInnerStatus = self.itemInnerStatus == "Active" ? 1 : 2;
      if (self.itemInnerStatus == "") {
        itemInnerStatus = 1;
      } else {
        itemInnerStatus = self.itemInnerStatus == "Active" ? 1 : 2;
      }
      if (!itemInnerStatus) return self.LastPayChangeList;
      let filter = self.LastPayChangeList.filter(
        (x) => x.emp_Status == itemInnerStatus
      );

      return filter;
    },
    Activelist() {
      return [
        { val: "Active", txt: this.$t("Active") },
        { val: "Inactive", txt: this.$t("Inactive") },
      ];
    },
  },
  methods: {
    Apply() {
      let self = this;
      if (self.value.length != 0) {
        let List = self.Temp;
        self.filter = [];
        self.LastPayChangeList = [];
        for (let j = 0; j < self.value.length; j++) {
          self.filter = List.filter((v) => v.department === self.value[j]);

          for (let i = 0; i < self.filter.length; i++) {
            self.LastPayChangeList.push(self.filter[i]);
          }
        }
      } else {
        alert("Please Select");
        self.GetLastPayChange();
      }
    },
    GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios
        .post(`${self.url}Department/GetDepartmentsByCompanyID`, temp)
        .then(function (response) {
          let temp = response.data.data;
          for (let i = 0; i < temp.length; i++) {
            let tempimport = {
              department_Name: temp[i].department_Name,
              checked: false,
            };
            self.options.push(tempimport);
            // console.log(self.options);
          }
          self.LogTrace(null, "Get Department", 2, "Critical");
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Department Fail", 2, "Critical");
        });
    },
    GetLastPayChange() {
      let self = this;
      self.LoadingDialog = true;
      let company_ID = store.state.companyID;
      axios
        .get(
          `${self.url}LastPayChange/GetLastPayChangeList?companyID=${company_ID}`
        )
        .then(function (response) {
          let temp = response.data.data;
          self.LastPayChangeList = Object.values(
            temp.reduce((acc, cur) => {
              acc[cur.employee_ID] = cur;
              return acc;
            }, {})
          );
          self.Temp = Object.values(
            temp.reduce((acc, cur) => {
              acc[cur.employee_ID] = cur;
              return acc;
            }, {})
          );

          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    back() {
      this.$router.push(`/payrollreport`).catch(() => {});
    },
    reseto() {
      let self = this;
      self.employsort = 1;
      self.showDep = false;
      self.value = [];
      self.filter = [];
      self.GetLastPayChange();
    },
    changetoshowDep(x) {
      let self = this;
      if (x == 2) {
        self.showDep = true;
      } else {
        self.showDep = false;
      }
    },

    changetoexport(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1) {
        // Excel
        if (self.itemFilter.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          for (let v of self.itemFilter) {
            if (localStorage.Templang == "ประเทศไทย") {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hired_Date),
                ["แผนก"]: v.department,
                ["ตำแหน่ง"]: v.position,
                ["ข้อมูลรายรับรวมครั้งก่อน"]: v.previous_Pay,
                ["ข้อมูลรายรับรวมปัจจุบัน"]: v.current_Pay,
                ["วันที่เปลี่ยนแปลง"]: self.DateFormat(v.date_Of_Change),
              });
            } else {
              wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
                ["Hired Date"]: self.DateFormat(v.hired_Date),
                ["Department"]: v.department,
                ["Position"]: v.position,
                ["Previous Pay"]: v.previous_Pay,
                ["Current Pay"]: v.current_Pay,
                ["Date of Change"]: self.DateFormat(v.date_Of_Change),
              });
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Last Pay Change Report`;
          self.sheetTitle = `Last Pay Change`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        self.printDialog = true;
        self.todaydate = new Date();
        self.itemsPerPage1 = self.itemFilter.length;
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.itemFilter.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          for (let v of self.itemFilter) {
            if (localStorage.Templang == "ประเทศไทย") {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hired_Date),
                ["แผนก"]: v.department,
                ["ตำแหน่ง"]: v.position,
                ["ข้อมูลรายรับรวมครั้งก่อน"]: v.previous_Pay,
                ["ข้อมูลรายรับรวมปัจจุบัน"]: v.current_Pay,
                ["วันที่เปลี่ยนแปลง"]: self.DateFormat(v.date_Of_Change),
              });
            } else {
              wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
                ["Hired Date"]: self.DateFormat(v.hired_Date),
                ["Department"]: v.department,
                ["Position"]: v.position,
                ["Previous Pay"]: v.previous_Pay,
                ["Current Pay"]: v.current_Pay,
                ["Date of Change"]: self.DateFormat(v.date_Of_Change),
              });
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Last Pay Change Report`;
          self.sheetTitle = `Last Pay Change`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },
    downloadPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Last Pay Change Report.pdf");
      });
    },
    DateFormat(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}/${month}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${month}/${day}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTopbet(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
/* >>> ::-webkit-scrollbar-thumb {
  background: #f99d20;
  border-radius: 1px;
  width: 300px;
} */
>>> ::-webkit-scrollbar {
  height: 8px;
}
>>> .mx-input {
  height: 42px;
  border-radius: 5px;
  width: 100px;
  border: 1px solid #808080ab !important;
}
.backGG {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}
.ddl {
  float: right;
}
.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 17px;
}

.print-break-page {
  page-break-after: always;
}
.ggg {
  margin-left: 30px;
}
@media print {
  body {
    overflow: auto;
    height: auto;
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
}
@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }
  .ddl {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
  .ddl {
    float: right;
  }
}
@media (max-width: 860px) {
  .ggg {
    margin-left: 0px;
  }
}
@media (min-width: 960px) {
  .lefz {
    margin-left: 20px;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .ggg {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1900px) {
  .movelf {
    margin-right: 2rem;
  }
}
</style>
