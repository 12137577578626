<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #444444; margin-top: 15px">
        {{ $t("Permission") }}
      </p>
    </div>
    <v-card class="mt-6">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="12" md="5" lg="4">
            <v-text-field
              autocomplete="off"
              v-model.lazy="searchtxt"
              :placeholder="$t('Search')"
              color="#F99D20"
              dense
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize ml-5"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-row>
        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :items="PermissionList"
          :headers="headers"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>
                  <!-- <label class="form-checkbox">
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      @click="select"
                    />
                    <i class="form-icon"></i>
                  </label> -->
                  <v-checkbox class="custom-checkbox mt-0"
                    v-model="selectAll"
                    @click="select(selectAll)" 
                    color="#F89D1F" hide-details>
                  </v-checkbox>
                </th>
                <th>{{ $t("PermissionID") }}</th>
                <th>{{ $t("PermissionName") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <!-- <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="{
                      perid: item.permission_ID,
                      pername: item.permission_Name,
                    }"
                    v-model="selected"
                  />
                  <i class="form-icon"></i>
                </label> -->
                <v-checkbox class="custom-checkbox mt-0" 
                    v-model="item.checked"
                    @change="checkmyemp()" 
                    color="#F89D1F" hide-details>
                </v-checkbox>
              </td>
              <td>{{ item.permission_No }}</td>
              <td>{{ item.permission_Name }}</td>
            </tr>
          </template>
        </v-data-table>

        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-2 text-capitalize commoncancelbtn"
          @click="goback"
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mr-4 text-capitalize commonsavebtn"
          @click="goto"
          >{{ $t("Add") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      PermissionList: [],
      PermissionList1: [],
      LoadingDialog: false,
      searchtxt: "",
      selected: [],
      selectAll: false,
      tempuser: null,
      headers: [
        {
          text: "Permission ID",
          value: "permission_ID",
          align: "left",
          sortable: false,
        },

        {
          text: "Permission Name",
          value: "permission_Name",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    searchtxt: function () {
      if (this.PermissionList.length != this.PermissionList1.length){
        this.GetPermission();
      }
    },
    "selectAll"(){
      if(this.PermissionList1.length == this.selected.length){
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  },
  mounted() {
    this.GetPermission();
  },
  methods: {
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.PermissionList;
        self.PermissionList = [];
        let temp = self.PermissionList1.filter(
          (v) =>
            v.permission_No
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.permission_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1
        );
        let empListMap = new Map();
        self.PermissionList = temp;
        temp.forEach(item => {
          empListMap.set(item.permission_ID, item);
        });
        List.forEach(item => {
          if (item.checked) {
            empListMap.set(item.permission_ID, item);
          }
        });
        self.PermissionList = Array.from(empListMap.values());
      } else {
        alert("Please enter text");
        //self.GetPermission();
      }
    },
    GetIndex(val) {
      let self = this;
      let data = self.PermissionList.indexOf(val);
      data++;
      return data;
    },
    goback() {
      let self = this;
      self.ugID = parseInt(self.$route.params.ugid);
      localStorage.setItem("localroleper", JSON.stringify(null));
      this.$router.push({ name: "roleadd", params: { roleadd: "roleadd" } }).catch(()=>{});
    },
    goto() {
      this.SaveLocal();
      this.$router.push({ name: "roleadd", params: { roleadd: "roleadd" } }).catch(()=>{});
    },
    GetPermission() {
      let self = this;
      //self.LoadingDialog = true;
      axios
        .get(`${self.url}Permission/GetPermission`)
        .then(function (response) {
          let llist = response.data.data;
          //self.LoadingDialog = false;
          self.tempuser = JSON.parse(localStorage.getItem("localroleper"));
          if (self.tempuser != null) {
            self.PermissionList = llist.map((v) => ({
              ...v,
              checked: self.tempuser.some((user) => user.perid === v.permission_ID),
            }));
            self.selected = 
            self.PermissionList
            .filter(user => user.checked)
            .map((v) => {
              return {
                  perid: v.perid,
                  pername: v.pername,
              };
            });
            localStorage.setItem("localroleper", JSON.stringify(self.selected));
          } else {
            self.PermissionList = llist.map((v) => ({
              ...v,
              checked: false,
            }));
          }
          if(self.selected.length != 0){
            self.PermissionList = llist.map((v) => ({
              ...v,
              checked: self.selected.some((user) => user.perid === v.permission_ID),
            }));
          }
          self.PermissionList1 = self.PermissionList;
        });
    },
    select(value) {
      // this.selected = [];
      // if (!this.selectAll) {
      //   for (let i in this.PermissionList) {
      //     this.selected.push({
      //       perid: this.PermissionList[i].permission_ID,
      //       pername: this.PermissionList[i].permission_Name,
      //     });
      //   }
      // }
      this.PermissionList = this.PermissionList.map((v) => ({
        ...v,
        checked: value,
      }));
      this.selected = 
      this.PermissionList
      .filter(user => user.checked)
      .map((v) => {
        return {
          perid: v.permission_ID,
          pername: v.permission_Name,
        };
      });
    },
    checkmyemp() {
      let checkvalidate = this.PermissionList.filter((data) => data.checked == true);

      let checkfalsevalidate = this.PermissionList.filter((date) => date.checked == false);
      if (checkfalsevalidate.length != 0) {
        this.selectAll = false;
      }

      if (checkvalidate.length != 0) {
        if (checkvalidate.length == this.PermissionList.length) {
          this.selectAll = true;
        }
      }
      this.selected = 
      this.PermissionList
      .filter(user => user.checked)
      .map((v) => {
        return {
          perid: v.permission_ID,
          pername: v.permission_Name,
        };
      });
    },
    SaveLocal() {
      localStorage.setItem("localroleper", JSON.stringify(this.selected));
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>