<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("ProvidentFund") }}
        </p>
    </div>
    
    <v-card class="d-flex-row flex-wrap pt-5 pa-2" style="margin-bottom: 2.5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="d-flex-row flex-wrap justify-end pl-md-0 mt-5 mb-2 ml-auto mr-5">
        <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4"></v-col>  -->
        <v-btn
          color="#F99D20"
          class="orange--text text-capitalize mx-2 my-1"
          width="150"
          max-height="35"
          outlined
          @click="uploaddialog = true"
          :disabled="permission.upload">
          {{ $t("upload") }}
        </v-btn>
        <v-btn
          color="#F99D20"
          class="white--text text-capitalize mx-2 my-1 btnres"
          width="150"
          max-height="35"
          @click="Openadddialog"
          :disabled="permission.add">
          {{ $t("AddNewItem") }}
        </v-btn>
      </v-row>
      <div class="d-flex-row flex-wrap" style="margin-left: 2rem; margin-right: 2rem">
        <v-data-table
          class="d-flex-row flex-wrap ma-2"
          hide-default-footer
          hide-default-header
          :items="filteredUsers"
          @page-count="pageCount = $event"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :mobile-breakpoint="0">
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>
                  <v-autocomplete
                    color="#F99D20"
                    v-on:keyup="keymonitor"
                    @change="filterprovident"
                    :label="$t('LengthOfEmployment')"
                    :items="forfilter"
                    v-model="forfilter.lengthemp"
                    class="auto_input"
                    :item-text="(item) => item.lengthemp"
                    :item-value="(item) => item.lengthemp"
                  ></v-autocomplete>
                </th>
                <th
                  style="cursor: pointer; min-width: 180px !important"
                  class="b-text"
                  @click="sortFun('employmentContribution')"
                >
                  {{ $t("employmentContribution(%)") }}
                  <v-icon small v-if="!employmentContribution"
                    >mdi-arrow-down</v-icon
                  >
                  <v-icon small v-if="employmentContribution"
                    >mdi-arrow-up</v-icon
                  >
                </th>
                <th
                  style="cursor: pointer; min-width: 180px !important"
                  class="b-text"
                  @click="sortFun('companyContribution')"
                >
                  {{ $t("CompanyContribution") }}
                  <v-icon small v-if="!companyContribution"
                    >mdi-arrow-down</v-icon
                  >
                  <v-icon small v-if="companyContribution">mdi-arrow-up</v-icon>
                </th>
                <th
                  style="cursor: pointer; min-width: 180px !important"
                  class="b-text"
                  @click="sortFun('effectiveStartDate')"
                >
                  {{ $t("effectivedate") }}
                  <v-icon small v-if="!effectiveStartDate"
                    >mdi-arrow-down</v-icon
                  >
                  <v-icon small v-if="effectiveStartDate">mdi-arrow-up</v-icon>
                </th>
                <th>{{ $t("action") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{
                  item.lengthOfEmployment == 1
                    ? $t("LessThan")
                    : "null" && item.lengthOfEmployment == 2
                    ? $t("GreaterThan")
                    : "null" && item.lengthOfEmployment == 3
                    ? $t("Between")
                    : "null" && item.lengthOfEmployment == 4
                    ? $t("EqualTo")
                    : "null" && item.lengthOfEmployment == 3
                    ? item.endYear
                    : "null"
                }}&nbsp;{{ item.year }}&nbsp;{{
                  item.lengthOfEmployment == 3 ? "-" : ""
                }}&nbsp;{{
                  item.lengthOfEmployment == 3 ? item.endYear : ""
                }}&nbsp;Years
              </td>
              <td>{{ item.employmentContribution }}</td>
              <td>{{ item.companyContribution }}</td>
              <td>{{ formatDate(item.effectiveStartDate) }}</td>
              <td style="width:108px;">
                <v-btn
                  @click="Editprovident(item)"
                  icon
                  :disabled="permission.edit"
                >
                  <img class="editIcon" :src="images.edit" />
                </v-btn>
                <v-btn
                  :disabled="permission.delete"
                  @click="Deleteprovident(item.provident_ID)"
                  icon
                >
                  <img class="deleteIcon" :src="images.delete" />
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div
          class="d-flex-row flex-wrap text-right"
          v-if="filteredUsers.length"
        >
          <v-row justify="end" class="d-flex flex-wrap ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <!-- uploaddialog -->
    <v-dialog v-model="uploaddialog" persistent max-width="786">
      <v-card class="d-flex flex-wrap" style="border-radius: 15px">
        <v-card-title class="headline"> {{ $t("UploadFile") }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col style="margin-left: -30px; margin-top: -20px" cols="5" md="5">
              <v-file-input
                truncate-length="100"
                v-show="showup"
                id="fileInputButton"
                class="inputbot"
                accept=".xlsx"
                v-model="chosenfile">
                <template v-slot:append>
                  <v-btn icon>
                    <img
                      width="20"
                      height="20"
                      @click="closeinput"
                      src="@/assets/closered.png"
                      alt
                    />
                  </v-btn>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-btn
                color="#ACACAC"
                class="white--text text-capitalize"
                width="150"
                max-height="35"
                @click="getupload"
                >{{ $t("browse") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="9">
              <p class="mt-2" style="color: #f74747">
                ** {{ $t("excelFileFormat") }}
              </p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
          <v-row>
            <v-col cols="12" md="4">
              <p>Provident Fund Template.xlsx</p>
            </v-col>
            <v-col cols="12" md="8">
              <!-- <a
                href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Provident Master Data.xlsx"
                style="color: #f99d20"
                >{{ $t("download") }}</a
              > -->
              <a style="color: #f99d20" :href="CheckLang()">
                {{$t("download")}}
              </a>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
          <br />
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap justify-center ml-auto pb-6">
          <v-btn
            outlined
            class="mx-2 text-capitalize commoncancelbtn"
            text
            @click="closeuploaddialog()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="mx-2 commonsavebtn text-capitalize"
            text
            @click="PreviewFiles">
            {{ $t("upload") }}
          </v-btn>
        </v-card-actions>
        <br />
      </v-card>
    </v-dialog>

    <!-- adddialog -->
    <v-dialog v-model="adddialog" persistent max-width="800px">
      <v-card class="d-flex flex-wrap" style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
          {{ $t("AddProvidentFund") }}
        </p>
        <v-card-text>
          <v-container>
            <v-form ref="formAdd" lazy-validation>
              <p>{{ $t("LengthOfEmployment") }}</p>
              <v-row>
                <v-col>
                  <v-tabs show-arrows v-model="providentfund.lengthemp" fixed-tabs light>
                    <v-tab
                      v-for="item in itemss"
                      @click="showtab(item.val)"
                      :key="item.val"
                      style="
                        margin-left: 2px;
                        width: 60px;
                        border-radius: 5px 5px 5px 5px;
                      "
                      class="text-capitalize"
                      >{{ item.text }}</v-tab
                    >
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-show="!show">
                  {{ $t("Year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="providentfund.year"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="yearAdd"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("start_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="providentfund.year"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="startyearAdd"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("end_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="providentfund.endyear"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="endyearAdd"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- {{ $t("employmentContribution(%)") }} <span class="red--text"> *</span> -->
                  {{ $t("employmentContribution(%)") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="providentfund.empcon"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="empconAdd"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("CompanyContribution") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="providentfund.companycon"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="companyconAdd"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("EffectiveStartDate") }}
                  <span class="red--text"> *</span>
                  <date-picker :editable="false" :class="effectdateAdd ? 'custom-vuedatepicker-er-mg mt-3' : 'custom-vuedatepicker mt-3'" v-bind:clearable="false" value-type="format"
                  v-model="providentfund.effectdate" format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  <span v-if="effectdateAdd" class="error-message">
                    {{ effectdateAdd }}</span>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap justify-center ml-auto pb-6">
          <v-btn
            class="mx-2 text-capitalize commoncancelbtn"
            outlined
            @click="CloseDialog()"
            text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="mx-2 text-capitalize commonsavebtn"
            @click="AddProvidentfund()">
            {{ $t("Add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editdialog" persistent max-width="800px">
      <v-card class="d-flex flex-wrap" style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
          {{ $t("EditProvidentFund") }}
        </p>
        <v-card-text>
          <v-container>
            <v-form ref="formEdit" lazy-validation>
              <p>{{ $t("LengthOfEmployment") }}</p>
              <v-row>
                <v-col>
                  <v-tabs
                    show-arrows
                    v-model="edprovidentfund.lengthemp"
                    fixed-tabs
                    light
                  >
                    <v-tab
                      v-for="item in itemss"
                      @click="edshowtab(item.val)"
                      :key="item.val"
                      class="text-capitalize"
                      :class="{
                        active: item.val === edprovidentfund.lengthemp,
                      }"
                      >{{ item.text }}</v-tab
                    >
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-show="!show1">
                  {{ $t("Year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="edprovidentfund.year"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="yearEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show1">
                  {{ $t("start_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="edprovidentfund.year"
                    color="#F99D20"
                    :rules="startyearEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show1">
                  {{ $t("end_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="edprovidentfund.endyear"
                    color="#F99D20"
                    :rules="endyearEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("employmentContribution(%)") }}
                  <span class="red--text"> *</span>
                  <!-- Employee contribution(% of salary)* -->
                  <v-text-field
                    autocomplete="off"
                    v-model="edprovidentfund.empcon"
                    color="#F99D20"
                    @keypress="onlyNumber"
                    :rules="empconEdit"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("CompanyContribution") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="edprovidentfund.companycon"
                    color="#F99D20"
                    :rules="companyconEdit"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("EffectiveStartDate") }}
                  <span class="red--text"> *</span>
                  <!-- <v-menu
                    v-model="edeffdate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="computedDateFormatted7"
                        class="kanit-medium"
                        :rules="effectdateEdit"
                        color="#F99D20"
                        persistent-hint
                        append-icon="mdi-calendar-month-outline"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="edprovidentfund.effectdate"
                      no-title
                      @input="edeffdate = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <date-picker :editable="false" :class="effectdateEdit ? 'custom-vuedatepicker-er-mg mt-3' : 'custom-vuedatepicker mt-3'" v-bind:clearable="false" value-type="format" 
                   v-model="edprovidentfund.effectdate" format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                   <span v-if="effectdateEdit" class="error-message">
                    {{ effectdateEdit }}</span>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap ml-auto justify-center pb-6">
          <v-btn
            class="mx-2 text-capitalize commoncancelbtn"
            outlined
            @click="editdialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            @click="Updateprovident()"
            class="mx-2 text-capitalize commonsavebtn"
            >{{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deletedialog" class="dialog" max-width="532">
      <v-card class="d-flex flex-wrap" style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap ml-auto justify-auto pb-6">
          <v-btn
            outlined
            class="mx-2 text-capitalize commoncancelbtn"
            @click="deletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mx-2 commondeletebtn text-capitalize"
            @click="Deleteprovidentrow()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark style="border-radius: 15px">
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  props: {
    selected: { default: false },
  },
  data() {
    return {
      yearAdd: [],
      endyearAdd: [],
      empconAdd: [],
      companyconAdd: [],
      effectdateAdd: null,
      yearEdit: [],
      endyearEdit: [],
      empconEdit: [],
      companyconEdit: [],
      effectdateEdit: null,
      startyearAdd: [],
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      employmentContribution: null,
      companyContribution: null,
      effectiveStartDate: null,
      showup: false,
      IsActive: false,
      forsearch: "",
      chosenfile: null,
      oldNum: 0,
      providentID: 0,
      url: enurl.apiUrl,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      isHidden: true,
      show: false,
      show1: false,
      uploaddialog: false,
      editdialog: false,
      adddialog: false,
      deletedialog: false,
      effdate: false,
      edeffdate: false,
      myedittab: null,
      Providentlist: [],
      providentsearch: [],
      LoadingDialog: false,
      startyearEdit: [],
      providentfund: {
        lengthemp: null,
        year: null,
        endyear: null,
        companycon: null,
        empcon: null,
        effectdate: "",
      },
      clearprovidentfund: {
        lengthemp: null,
        year: null,
        endyear: null,
        companycon: null,
        empcon: null,
        effectdate: "",
      },
      edprovidentfund: {
        providentid: null,
        lengthemp: null,
        year: null,
        endyear: null,
        companycon: null,
        empcon: null,
        effectdate: "",
      },
      errormsg: [],
      lengthof: 1,
      filters: {
        lengthemp: [],
      },
      forfilter: [
        {
          lengthemp: "",
        },
      ],
      filteralllist: [
        {
          newfilter: "",
        },
      ],
    };
  },

  mounted() {
    let self = this;
    self.GetProvidentfund();
    self.permission = self.CheckViewAddEditDeleteUpload(74, 75, 76, 77, 78);
  },
  watch: {
    forsearch: function () {
      this.GetProvidentfund();
    },
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
    "providentfund.year"() {
      this.yearAdd = [];
      this.startyearAdd = [];
    },
    "providentfund.endyear"() {
      this.endyearAdd = [];
    },
    "providentfund.empcon"() {
      this.empconAdd = [];
    },
    "providentfund.companycon"() {
      this.companyconAdd = [];
    },
    "providentfund.effectdate"() {
      this.effectdateAdd = null;
    },
    "edprovidentfund.year"() {
      this.yearEdit = [];
      this.startyearEdit = [];
    },
    "edprovidentfund.endyear"() {
      this.endyearEdit = [];
    },
    "edprovidentfund.empcon"() {
      this.empconEdit = [];
    },
    "edprovidentfund.companycon"() {
      this.companyconEdit = [];
    },
    "edprovidentfund.effectdate"() {
      this.effectdateEdit = null;
    },
  },
  computed: {
    itemss() {
      return [
        { text: this.$t("LessThan"), val: 1 },
        { text: this.$t("GreaterThan"), val: 2 },
        { text: this.$t("Between"), val: 3 },
        { text: this.$t("EqualTo"), val: 4 },
      ];
    },
    filteredUsers() {
      return this.providentsearch.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    images() {
      return this.$store.state.images;
    }
  },
  methods: {
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Provident Master Data.xlsx";
      } else {
        url =
          "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Provident Master Data.xlsx";
      }
      return url;
    },
    sortFun(rowName) {
      let self = this;
      let keyName = [
        "employmentContribution",
        "companyContribution",
        "effectiveStartDate",
      ];
      if (
        rowName == "employmentContribution" ||
        rowName == "companyContribution" ||
        rowName == "effectiveStartDate"
      ) {
        self.Providentlist = self.Providentlist.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.Providentlist = self.Providentlist.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.employmentContribution = null;
      this.companyContribution = null;
      this.effectiveStartDate = null;
    },
    filterprovident() {
      let self = this;
      let filterlength = self.forfilter.lengthemp;
      if (filterlength == 0) {
        self.GetProvidentfund();
      } else {
        if (self.Providentlist.length != 0) {
          for (let i = 0; i < self.Providentlist.length; i++) {
            let changelength = null;
            if (self.Providentlist[i].lengthOfEmployment == 1) {
              changelength = "Less than";
            }
            if (self.Providentlist[i].lengthOfEmployment == 2) {
              changelength = "Greater than";
            }
            if (self.Providentlist[i].lengthOfEmployment == 3) {
              changelength = "Between";
            }
            if (self.Providentlist[i].lengthOfEmployment == 4) {
              changelength = "Equal to";
            }
            var mylengthemp = changelength;
            let filterlist = null;
            if (mylengthemp == "Between") {
              filterlist =
                mylengthemp +
                " " +
                self.Providentlist[i].year.toString() +
                "-" +
                self.Providentlist[i].endYear.toString() +
                "Years";
              self.filteralllist.push({
                newfilter: filterlist,
              });
            } else {
              filterlist =
                mylengthemp +
                " " +
                self.Providentlist[i].year.toString() +
                " " +
                "Years";
              self.filteralllist.push({
                newfilter: filterlist,
              });
            }

            if (self.forfilter.lengthemp == filterlist) {
              self.providentsearch = [];
              self.providentsearch.push({
                lengthOfEmployment: self.Providentlist[i].lengthOfEmployment,
                year: self.Providentlist[i].year,
                endYear: self.Providentlist[i].endYear,
                provident_ID: self.Providentlist[i].provident_ID,
                employmentContribution:
                  self.Providentlist[i].employmentContribution,
                companyContribution: self.Providentlist[i].companyContribution,
                effectiveStartDate: self.Providentlist[i].effectiveStartDate,
                company_ID: self.Providentlist[i].company_ID,
              });
            }
            self.defaultSort();
          }
        }
      }
    },
    Openadddialog() {
      this.adddialog = true;
      this.show = false;
    },
    CloseDialog() {
      this.adddialog = false;
      this.providentfund.lengthemp = 0;
      if (this.providentfund.lengthemp == 0) {
        this.show = false;
      }
      this.providentfund.year = null;
      this.providentfund.endyear = null;
      this.providentfund.companycon = null;
      this.providentfund.empcon = null;
      this.providentfund.effectdate = "";
      this.$refs.formAdd.reset();
    },

    tabactive() {
      if (this.edprovidentfund.lengthemp == this.providentsearch.lengthOfEmployment)
      {
        this.IsActive = true;
      }
    },

    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => {});
    },

    getupload() {
      document.getElementById("fileInputButton").click();
      // this.showup = true;
    },

    closeUpload() {
      this.HospitalUploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
    },

    closeuploaddialog() {
      this.uploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
    },

    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },

    formatD(d) {
      return this.$moment(d).format("DD/MM/YYYY hh:mm");
    },
    changeeeee(d) {
      var date = new Date((d - (25567 + 2)) * 86400 * 1000);
      return date.toISOString();
    },
    checkexcel(data) {
      //let self=this;
      for (let i = 0; i < data.length; i++) {
        if (data[i].lengthOfEmployment == undefined) {
          this.errormsg.push("lengthOfEmployment");
        }
        if (data[i].year == undefined) {
          this.errormsg.push("year");
        }
        if (data[i].endYear == undefined) {
          this.errormsg.push("endYear");
        }
        if (data[i].companyContribution == undefined) {
          this.errormsg.push("companyContribution");
        }
        if (data[i].employmentContribution == undefined) {
          this.errormsg.push("employmentContribution");
        }
        if (data[i].effectiveStartDate == undefined) {
          this.errormsg.push("effectiveStartDate");
        }
      }
    },
    PreviewFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      if (files != null) {
        let filename = files.name;
        if (filename == "Provident Master Data.xlsx") {
          let reader = new FileReader();
          reader.onload = function (e) {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            let combileArray = [];
            self.checkexcel(this.importData);
            if (self.errormsg.length == 0) {
              for (let i = 0; i < this.importData.length; i++) {
                this.tempimport = {
                  lengthOfEmployment: file_data[i + 1][0],
                  year: file_data[i + 1][1],
                  endYear: file_data[i + 1][2],
                  companyContribution: file_data[i + 1][3],
                  employmentContribution: file_data[i + 1][4],
                  effectiveStartDate: file_data[i + 1][5],
                  company_ID: store.state.companyID,
                };
                if (this.tempimport.lengthOfEmployment == "Less Than") {
                  this.tempimport.lengthOfEmployment = 1;
                } else if (
                  this.tempimport.lengthOfEmployment == "Greater Than"
                ) {
                  this.tempimport.lengthOfEmployment = 2;
                } else if (this.tempimport.lengthOfEmployment == "Between") {
                  this.tempimport.lengthOfEmployment = 3;
                } else if (this.tempimport.lengthOfEmployment == "Equal To") {
                  this.tempimport.lengthOfEmployment = 4;
                }

                if (this.tempimport.effectiveStartDate) {
                  let mychange = self.changeeeee(
                    this.tempimport.effectiveStartDate
                  );
                  this.tempimport.effectiveStartDate = mychange;
                }
                this.tempimport.employmentContribution =
                  this.tempimport.employmentContribution.toString();
                this.tempimport.companyContribution =
                  this.tempimport.companyContribution.toString();
                combileArray.push(this.tempimport);
              }
            }
            if (self.errormsg.length == 0) {
              axios
                .post(
                  `${self.url}Providentfund/ImportProvidentfundMasterData`,
                  combileArray
                )
                .then(function (response) {
                  if (response.data.status == 0) {
                    alert(response.data.message);
                    self.uploaddialog = false;
                    self.chosenfile = null;
                    self.LoadingDialog = false;
                    self.showup = false;
                    self.GetProvidentfund();
                    self.defaultSort();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Upload  Provident Fund Fail",
                    6,
                    "Verbose"
                  );
                  alert(error);
                  self.LoadingDialog = false;
                });
            } else {
              let Message = [...new Set(self.errormsg)];
              alert(` Please fill ${Message.join(", ")}`);
              self.errormsg = [];
            }
          };
          reader.readAsArrayBuffer(files);
          self.LoadingDialog = false;
        } else {
          alert("File name must be Provident Master Data.xlsx");
          self.LoadingDialog = false;
        }
      } else {
        alert("Please choose File");
        self.LoadingDialog = false;
      }
    },
    keymonitor: function (event) {
      if (event.key == "Backspace") {
        this.forfilter.lengthemp = "";
        this.GetProvidentfund();
      }
    },
    showtab(mytab) {
      this.providentfund = Object.assign({}, this.clearprovidentfund);

      this.startyearAdd = [];

      this.yearAdd = [];

      this.endyearAdd = [];

      this.empconAdd = [];

      this.companyconAdd = [];

      this.effectdateAdd = null;

      if (mytab == 3) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.lengthof = mytab;
    },
    edshowtab(edittab) {
      this.startyearEdit = [];

      this.yearEdit = [];

      this.endyearEdit = [];

      this.empconEdit = [];

      this.companyconEdit = [];

      this.effectdateEdit = null;
      if (edittab == 3) {
        this.show1 = true;
      } else {
        this.show1 = false;
      }
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    GetProvidentfund() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Providentfund/GetProvidentAll`, tempp)
        .then(function (response) {
          self.Providentlist = response.data.data;
          self.providentsearch = response.data.data;
          self.LoadingDialog = false;
          if (self.Providentlist.length != 0) {
            for (let i = 0; i < self.Providentlist.length; i++) {
              let changelength = null;

              if (self.Providentlist[i].lengthOfEmployment == 1) {
                changelength = "Less than";
              }
              if (self.Providentlist[i].lengthOfEmployment == 2) {
                changelength = "Greater than";
              }
              if (self.Providentlist[i].lengthOfEmployment == 3) {
                changelength = "Between";

                // endthis=self.Providentlist[i].endYear;
              }
              if (self.Providentlist[i].lengthOfEmployment == 4) {
                changelength = "Equal to";
              }
              var mylengthemp = changelength;
              let filterlist = null;
              if (mylengthemp == "Between") {
                filterlist =
                  mylengthemp +
                  " " +
                  self.Providentlist[i].year.toString() +
                  "-" +
                  self.Providentlist[i].endYear.toString() +
                  "Years";
              } else {
                filterlist =
                  mylengthemp +
                  " " +
                  self.Providentlist[i].year.toString() +
                  " " +
                  "Years";
              }

              if (i == 0) {
                self.forfilter[0].lengthemp = filterlist;
              } else {
                self.forfilter.push({
                  lengthemp: filterlist,
                });
              }
            }
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Providentfund Fail", 6, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },

    AddProvidentfund() {
      let self = this;
      self.LoadingDialog = true;
      if (self.lengthof == 1 || self.lengthof == 2 || self.lengthof == 4)
      {
        self.yearAdd = [(v) => !!v || "Year is required"];
        if (self.providentfund.endyear == null || self.providentfund.endyear == "")
        {
          self.providentfund.endyear = "0";
        }
      }
      else
      {
        self.startyearAdd = [(v) => !!v || "Start Year is required"];
        self.endyearAdd = [(v) => !!v || "End Year is required"];
      }
      self.empconAdd = [(v) => !!v || "Employee contribution is required"];
      self.companyconAdd = [(v) => !!v || "Company Contribution is required"];
      self.effectdateAdd = !this.providentfund.effectdate ? "Effective Start Date is required" : "";
      let tempvalidate = self.$refs.formAdd.validate();
      if (
        tempvalidate == true &&
        self.lengthof != null &&
        self.providentfund.year != null &&
        self.providentfund.year != "" &&
        self.providentfund.endyear != null &&
        self.providentfund.endyear != "" &&
        self.providentfund.empcon != null &&
        self.providentfund.companycon != null &&
        self.providentfund.empcon != "" &&
        self.providentfund.companycon != "" &&
        self.providentfund.effectdate != null &&
        self.providentfund.effectdate != ""
      )
      {
        let myvalidate = self.validateadd();
        if (myvalidate)
        {
          let tempprovident = {
            lengthOfEmployment: self.lengthof,
            year: parseInt(self.providentfund.year),
            endYear: parseInt(self.providentfund.endyear),
            companyContribution: self.providentfund.companycon,
            employmentContribution: self.providentfund.empcon,
            // effectiveStartDate: new Date(self.providentfund.effectdate),
            effectiveStartDate: this.$moment(self.providentfund.effectdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            company_ID: store.state.companyID,
          };
          axios.post(`${self.url}Providentfund/AddProvidentfund`, tempprovident)
          .then(function (response) {
            if (response.data.status == 0)
            {
              self.adddialog = false;
              alert(response.data.message);
              self.lengthof = 1;
              self.show = false;
              self.providentfund.lengthemp = 0;
              self.providentfund.year = null;
              self.providentfund.endyear = null;
              self.providentfund.companycon = null;
              self.providentfund.empcon = null;
              self.providentfund.effectdate = "";
              self.LoadingDialog = false;
              self.GetProvidentfund();
              self.defaultSort();
            }
          })
          .catch(function (error)
          {
            self.LogTrace(error, "Add  ProvidentFund Fail", 6, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else
        {
          self.LoadingDialog = false;
          self.lengthof = 1;
          self.providentfund.lengthemp = 0;
          self.providentfund.year = null;
          self.providentfund.endyear = null;
          self.providentfund.companycon = null;
          self.providentfund.empcon = null;
          self.providentfund.effectdate = "";
          self.adddialog = false;
          alert("This provident fund already exists");
        }
      }
      else
      {
        alert("Please fill all the fields");
        self.LoadingDialog = false;
      }
    },

    Editprovident(temp) {
      let self = this;
      self.editdialog = true;
      self.edprovidentfund.providentid = temp.provident_ID;
      self.edprovidentfund.lengthemp = temp.lengthOfEmployment - 1;
      self.edprovidentfund.year = temp.year;
      self.edprovidentfund.endyear = temp.endYear;
      self.edprovidentfund.companycon = temp.companyContribution;
      self.edprovidentfund.empcon = temp.employmentContribution;
      self.edprovidentfund.effectdate = self.$moment(temp.effectiveStartDate).format('DD/MM/YYYY');
      if (self.edprovidentfund.lengthemp == 2)
      {
        self.show1 = true;
      }
      else
      {
        self.show1 = false;
        self.edprovidentfund.endyear = null;
      }
    },

    Updateprovident() {
      let self = this;
      self.LoadingDialog = true;
      if (self.edprovidentfund.lengthemp == 0 || self.edprovidentfund.lengthemp == 1 || self.edprovidentfund.lengthemp == 3)
      {
        self.yearEdit = [(v) => !!v || "Year is required"];
        if(self.edprovidentfund.endyear == null || self.edprovidentfund.endyear == "")
        {
          self.edprovidentfund.endyear = "0";
        }
      }
      else
      {
        self.startyearEdit = [(v) => !!v || "Start Year is required"];
        self.endyearEdit = [(v) => !!v || "End Year is required"];
      }
      //self.yearEdit = [(v) => !!v || "Year is required"];
      //self.startyearEdit = [(v) => !!v || "Start Year is required"];
      self.empconEdit = [(v) => !!v || "Employee contribution is required"];
      self.companyconEdit = [(v) => !!v || "Company Contribution is required"];
      self.effectdateEdit = !self.edprovidentfund.effectdate ? "Effective Start Date is required" : "";
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.edprovidentfund.lengthemp != null &&
        self.edprovidentfund.year != null &&
        self.edprovidentfund.year != "" &&
        self.edprovidentfund.endyear != null &&
        self.edprovidentfund.endyear != "" &&
        self.edprovidentfund.companycon != null &&
        self.edprovidentfund.empcon != null &&
        self.edprovidentfund.companycon != "" &&
        self.edprovidentfund.empcon != "" &&
        self.edprovidentfund.effectdate != null &&
        self.edprovidentfund.effectdate != ""
      )
      {
        let myvalidate = self.validateedit(self.edprovidentfund.providentid);
        if (myvalidate == true)
        {
          let tempeditprovident = {
            provident_ID: self.edprovidentfund.providentid,
            lengthOfEmployment: self.edprovidentfund.lengthemp + 1,
            year: parseInt(self.edprovidentfund.year),
            endYear: parseInt(self.edprovidentfund.endyear),
            companyContribution: self.edprovidentfund.companycon,
            employmentContribution: self.edprovidentfund.empcon,
            // effectiveStartDate: new Date(self.edprovidentfund.effectdate),
            effectiveStartDate : self.$moment(self.edprovidentfund.effectdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            company_ID: store.state.companyID,
          };
          axios.post(`${self.url}Providentfund/UpdateProvidentfund`,tempeditprovident)
          .then(function (response) {
            if (response.data.status == 0)
            {
              self.editdialog = false;
              alert(response.data.message);
              self.edprovidentfund.year = null;
              self.edprovidentfund.endyear = 0;
              self.edprovidentfund.companycon = null;
              self.edprovidentfund.empcon = null;
              self.edprovidentfund.effectdate = "";
              self.LoadingDialog = false;
              self.GetProvidentfund();
              self.defaultSort();
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update  ProvidentFund Fail", 6, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else
        {
          self.edprovidentfund.year = null;
          self.edprovidentfund.endyear = 0;
          self.edprovidentfund.companycon = null;
          self.edprovidentfund.empcon = null;
          self.edprovidentfund.effectdate = "";
          self.LoadingDialog = false;
          self.editdialog = false;
          alert("This provident fund already exists");
        }
      }
      else
      {
        alert("please fill all the fields");
        self.LoadingDialog = false;
      }
    },

    Deleteprovident(id) {
      let self = this;
      self.providentID = id;
      self.deletedialog = true;
    },

    Deleteprovidentrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.providentID;
      let tempdel = {
        provident_ID: id,
      };
      axios.post(`${self.url}Providentfund/DeleteProvidentfund`, tempdel)
      .then(function (response) {
        if (response.data.status == 0) {
          alert(response.data.message);
          self.deletedialog = false;
          self.LoadingDialog = false;
          self.GetProvidentfund();
          self.defaultSort();
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete  ProvidentFund  Fail", 6, "High");
        alert(error);
        self.LoadingDialog = false;
      });
    },
    validateadd() {
      let self = this;
      let validate = true;
      if (self.Providentlist.length != 0) {
        for (let i = 0; i < self.Providentlist.length; i++) {
          if (validate == true) {
            if (self.lengthof == self.Providentlist[i].lengthOfEmployment) {
              if (self.lengthof == 3) {
                if (
                  self.providentfund.year == self.Providentlist[i].year ||
                  self.providentfund.endyear == self.Providentlist[i].endyear
                ) {
                  validate = false;
                } else {
                  validate = true;
                }
              }
              if (self.lengthof != 3) {
                if (self.providentfund.year == self.Providentlist[i].year) {
                  validate = false;
                } else {
                  validate = true;
                }
              }
            }
          }
        }
      } else {
        validate = true;
      }
      if (validate == true) {
        return true;
      } else {
        return false;
      }
    },
    validateedit(myemp) {
      let self = this;
      let validate = true;
      if (self.Providentlist.length != 0) {
        for (let i = 0; i < self.Providentlist.length; i++) {
          if (validate == true) {
            if (
              self.edprovidentfund.lengthemp + 1 ==
                self.Providentlist[i].lengthOfEmployment &&
              myemp != self.Providentlist[i].provident_ID
            ) {
              if (self.edprovidentfund.lengthemp + 1 == 3) {
                if (
                  self.edprovidentfund.year == self.Providentlist[i].year ||
                  self.edprovidentfund.endyear == self.Providentlist[i].endyear
                ) {
                  validate = false;
                } else {
                  validate = true;
                }
              }
              if (self.edprovidentfund.lengthemp + 1 != 3) {
                if (self.edprovidentfund.year == self.Providentlist[i].year) {
                  validate = false;
                } else {
                  validate = true;
                }
              }
            }
          }
        }
      }

      if (validate == true) {
        return true;
      } else {
        return false;
      }
    },
    Search() {
      let self = this;
      if (self.forsearch != null && self.forsearch != "") {
        let List = self.providentsearch;
        self.providentsearch = [];
        var txt =
          self.forsearch.charAt(0).toUpperCase() + self.forsearch.slice(1);
        for (let i = 0; i < List.length; i++) {
          if (
            List[i].year == txt ||
            List[i].companyContribution == txt ||
            List[i].employmentContribution == txt ||
            List[i].effectiveStartDate == txt
          ) {
            self.providentsearch.push(List[i]);
          }
        }
      } else {
        alert("Please fill in search field");
        self.GetProvidentfund();
      }
    },
  },
};
</script>

<style scoped>
/* ::v-deep .mx-input{
    height: 49px !important;
    font-size: 16px !important;
    font-family: 'Kanit' !important;
    border-radius: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

::v-deep .mx-input:hover{
    border-color: #f89d1f!important;
}

::v-deep .mx-input:hover + .mx-icon-calendar,
::v-deep .mx-input:hover .mx-icon-calendar {
  color: #f89d1f !important;
} */

>>> .auto_input {
  width: 20rem !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
    /* margin-top: -1rem !important; */
  }
}
@media (max-width: 375px) {
  .btnres {
    margin-top: 1rem !important;
  }
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}

>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
>>> .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}
.v-input__icon--append .v-icon {
  color: purple;
}
>>> .v-select .theme--light.v-label {
  color: #f99d20;
  font-size: 13px;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

.v-application .headline{
  font-size: 23px !important;
  font-family: 'Kanit' !important;
  font-weight: normal !important;
}

</style>