<template>
  <div>
    <div
      style="
        padding-left: 0px;
        padding-right: 1%;
        margin-bottom: 1rem;
      "
    >
      <v-row>
        <v-btn icon @click="back()" color="white" class="mt-6 ml-6">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p
          text
          style="
            cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            margin-left: 5px;
            padding: 18px;
          "
          @click="back()"
        >
          {{ $t("otherinfo") }}
        </p>
      </v-row>
    </div>
    <v-card
      class="pt-5"
      height="100vh"
      style="margin-bottom: 5rem; margin-left: 2rem; margin-right: 2rem"
    >
      <v-row>
        <p class="ml-10" style="font-size: 21px; color: black">
          {{ othername }}
        </p>
        <v-spacer></v-spacer>
        <v-btn
          color="#F99D20"
          class="orange--text text-capitalize mr-5 btn"
          width="150"
          max-height="35"
          outlined
          @click="uploaddialog2 = true"
          >{{ $t("upload") }}</v-btn
        >
      </v-row>
      <v-row>
        <v-col cols="12" v-show="showthis">
          <v-row justify="center">
            <v-col cols="6" md="6">
              <v-img
                v-if="imageUrl"
                v-show="!ss"
                id="hidden"
                :src="'data:image/png;base64,' + imageUrl"
                contain
                aspect-ratio="2"
                class="preview_img_file"
                style="
                  background: #ffffff;
                  color: #707070;
                  font-size: 25px;
                  margin-top: 140px;
                  margin-left: auto;
                  margin-right: auto;
                "
              ></v-img>
              <v-img
                v-show="ss"
                :src="imageUrl1"
                id="hidden"
                class="preview_img_file"
                contain
                aspect-ratio="2"
                style="
                  height: auto;
                  max-width: 100%;
                  background: #ffffff;
                  color: #707070;
                  font-size: 25px;
                  margin-top: 140px;
                  margin-left: auto;
                  margin-right: auto;
                "
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <div v-show="showthis" style="width:800px;margin-left:250px">
      
      </div>-->
    </v-card>

    <v-dialog v-model="uploaddialog2" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -15px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <p class="ml-4 mt-2" v-show="showfile">{{ showfilename }}</p>
              </v-col>
              <v-col
                style="margin-left: -10px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-btn v-show="showfile" icon>
                  <img
                    width="20"
                    height="20"
                    @click="closeimage"
                    src="@/assets/closered.png"
                    alt
                  />
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="black--text text-capitalize"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="onPickFile"
                >
                  {{ $t("browse") }}
                  <input
                    ref="image"
                    @change="onFilePicked"
                    accept="image/*"
                    type="file"
                    style="display: none"
                    id="profile_picture"
                  />
                </v-btn>
              </v-col>
              <v-col cols="12" md="9">
                <p style="margin-top: 0px; color: #f74747">
                  **{{ $t("TheImageType") }}**
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            height="35"
            @click="closeall()"
            text
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text mr-2 text-capitalize"
            width="120"
            v-show="!showedit"
            height="35"
            @click="uploadimage(ImageUrl, $event)"
            >{{ $t("upload") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            v-show="showedit"
            class="white--text mr-2 text-capitalize"
            width="120"
            height="35"
            @click="editimage(ImageUrl, $event)"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//import checkPermissionOne from "@/function/PersonalManagementPermission.js";
export default {
  //mixins: [checkPermissionOne],
  data() {
    return {
      // permission: {
      //   upload: true,
      // },
      showthis: true,
      localImageUrl1: "",
      othername: "",
      tempImageUrl: "",
      uploaddialog2: false,
      showfile: false,
      showfilename: null,
      chosenfile: null,
      imageName: "",
      imageUrl: localStorage.localotherinfoUrl,
      imageurl1: "",
      imageFile: "",
      fileName: null,
      // fileshow: false,
      workpermit: [],
      work: "",
      ind: 0,
      ss: false,
      showedit: false,
    };
  },
  mounted() {
    let self = this;
    if (self.$route.params.otherid != null) {
      this.showedit = true;
      let ee = localStorage.editothername;
      this.othername = ee;
    }
    if (localStorage.fortext != "undefined") {
      let ww = localStorage.fortext;
      this.othername = ww;
    } else {
      this.othername = "";
    }
    //this.permission = this.checkPermissionFive(120);
    let task1 = localStorage.localeditotherinfoUrl;
    this.localImageUrl1 = task1;
  },
  created: function () {
    let taskwork = JSON.parse(localStorage.getItem("edit"));
    if (this.$route.params.otherid != null) {
      let task = localStorage.localeditotherinfoUrl;
      if (task == null) {
        this.imageUrl1 = taskwork.otherimage;
        this.ss = true;
        this.localImageUrl1 = "";
      } else {
        taskwork = "";
        this.imageUrl = task;
        this.localImageUrl1 = task;
      }
    } else {
      let taskDB = JSON.parse(localStorage.getItem("localotherinfoUrl"));

      if (taskDB == null) {
        this.localImageUrl1 = "";
        this.imageUrl1 = taskwork.otherimage;
        this.ss = true;
      } else {
        this.localImageUrl1 = taskDB;
      }
    }
  },
  methods: {
    back() {
      let workim = this.$route.params.id;
      if (
        this.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/OtherInformation"
      ) {
        this.$router.push("/selfservice/PersonalProfile/PersonalInformation").catch(()=>{});
      } else if (this.$route.name == "newotherinformation") {
        this.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation"
        ).catch(()=>{});
      } else if (workim != "undefined") {
        this.$router.push(
          `/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation`
        ).catch(()=>{});
      }
    },
    onPickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      e.preventDefault();
      const files = e.target.files;
      const MAX_SIZE = 2;
      // this.fileshow = false;
      this.ss = false;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        this.showfilename = files[0].name;
        let imageSize = parseFloat(files[0].size / (1024 * 1024)).toFixed(0);
        this.showfile = true;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        if (imageSize > MAX_SIZE) {
          alert(
            `Your image size is ${imageSize}MB! Maximum is ${MAX_SIZE}MB.Please choose again!`
          );
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.tempImageUrl = fr.result;

          this.imageUrl = fr.result.split(",")[1];
          //document.getElementById("hidden").style.display = "none";
          this.showthis = false;

          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
        this.tempImageUrl = "";
      }
    },
    uploadimage(a, b) {
      b.preventDefault();
      // this.fileshow = true;
      this.localImageUrl1 = this.imageUrl;
      // document.getElementById("hidden").style.display = "block";
      this.showthis = true;
      this.uploaddialog2 = false;
      localStorage.setItem("localotherinfoUrl", this.localImageUrl1);
      localStorage.setItem("othername", this.imageName);
    },
    editimage(a, b) {
      b.preventDefault();
      // this.fileshow = true;
      this.localImageUrl1 = this.imageUrl;
      //document.getElementById("hidden").style.display = "block";
      this.showthis = true;
      this.uploaddialog2 = false;
      localStorage.setItem("localeditotherinfoUrl", this.localImageUrl1);
      localStorage.setItem("editothername", this.imageName);
    },

    closeall() {
      // let taskwork = JSON.parse(localStorage.getItem("edit"));
      if (this.$route.params.otherid != null) {
        if (localStorage.localeditotherinfoUrl == null) {
          this.ss = true;
        } else {
          let ff = localStorage.localeditotherinfoUrl;
          this.imageUrl = ff;
        }
      } else {
        let gg = localStorage.localotherinfoUrl;

        this.imageUrl = gg;
      }
      this.showthis = true;
      this.uploaddialog2 = false;
      this.showfile = false;
    },
    closeimage() {
      this.showfile = false;
    },
  },
};
</script>
<style scoped>
>>> .mdi-paperclip::before {
  content: none;
}
@media (min-width: 280px) and (max-width: 1025px) {
  .btn {
    margin-left: 2.5rem !important;
  }
}
</style>