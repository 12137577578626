<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("home") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
      </v-btn>
      <p class="ml-4 headtext txt-header">
        {{ $t("announcement") }}
      </p>
    </div>

    <v-card class="pt-4" style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 15px">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field :placeholder="$t('Search') + ' Title'" color="#F99D20" dense outlined autocomplete="off"
            prepend-inner-icon="mdi-magnify" style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;" v-model="searchtxt"
            @keyup.enter="Search()">
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <div v-if="permission.add == false" class="pl-3" style="margin-right:1rem;">
          <v-btn color="#F99D20" class="text-capitalize mt-1" style="font-size: 16px; color: #ffffff" block
            max-height="35" to="/home/newAnnouncement">
            <img class="pr-2" style="width:100%; height:100%;" :src="images.addAnnouncement" alt="addAnnouncement" />
            {{ $t("newPost") }}
          </v-btn>
        </div>
      </v-row>

      <v-data-table :items="resultannoncementlist" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer
        hide-default-header @page-count="pageCount = $event" class="ml-8 mr-8 mt-4" :mobile-breakpoint="0">
        <template v-slot:header="{ props: { } }">
          <thead style="background-color:#EEEEEE;color:#878585;">
            <tr>
              <th></th>
              <th class="table-header" style="text-align:left !important;">{{ $t("details") }}</th>
              <th class="table-header" style="text-align:left !important;">{{ $t("announcedby") }}</th>
              <th class="table-header" style="text-align:center !important;">{{ $t("status") }}</th>
              <th></th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr @dblclick="handleDoubleClick(item.announcement_ID)">
            <td class="pt-2 pb-2 image-cell">
              <img :src="item.announcement_Image" style="border-radius: 10px;" class="announcement-image" alt="Announcement Image" />
            </td>
            <td class="pt-2 pb-2 announcement-content">
              <div class="announcement-header">
                <div class="announcement-title">{{ item.announcement_Title }}</div>
                <img class="pinIcon" :src="images.pinIcon" v-if="item.status" />
              </div>
              <div class="announcement-detail mt-3">
                <div class="announcement-text" v-html="item.announcement_Detail"></div>
              </div>
              <v-row class="d-flex justify-space-between mt-5" no-gutters>
                <div class="d-flex align-center">
                  <img class="eyeIcon" :src="images.eye" />
                  <span class="ml-1">
                    {{ item.viewCount }}
                  </span>
                  <v-img style="margin-left: 8px;" v-if="!item.disable_Comments" class="messageIcon" src="@/assets/images/chat-dots-fill-svgrepo-com.svg"></v-img>
                  <span class="ml-1" v-if="!item.disable_Comments">
                    {{ item.countcomment }}
                  </span>
                </div>
              </v-row>
            </td>
            <td class="pt-2 pb-2">
              {{ item.employee_Name }} <br />
              {{ item.formattedAnnouncementDate }}
            </td>
            <td class="pt-2 pb-2">
              <!-- <v-btn
                  class="status-btn text-capitalize"
                  style="cursor: default; color: #F89D1F;"
                  rounded
                  width="150"
                  text
                  :class="{
                    'expired': item.announcement_Status == 'Expired',
                    'waiting': item.announcement_Status == 'Waiting',
                    'active': item.announcement_Status != 'Expired' && item.announcement_Status != 'Waiting'
                  }">
                  {{ item.announcement_Status }}
                </v-btn> -->
              <div class="d-flex" :style="{
                backgroundColor: item.announcement_Status == 'Expired' ? '#FFCCCC' : item.announcement_Status == 'Waiting' ? '#FFEACD' : '#CCF3E9',
                borderRadius: '20px',
                padding: '5px',
                width: '150px',
                height: '35px',
                alignItems: 'center',
                margin: 'auto',
                justifyContent: 'center',
                textAlign: 'center'
              }">
                <p class="pt-4" :style="{
                    color: item.announcement_Status == 'Expired' ? '#FF4B55' : item.announcement_Status == 'Waiting' ? '#F89D1F' : '#00C092',
                    justifyContent: 'center', display: 'contents'
                  }">
                  {{ item.announcement_Status == "Expired" ? $t("Expired") : item.announcement_Status == "Waiting" ?
                  $t("Waiting") : $t("announcement") }}
                </p>
              </div>
            </td>
            <td class="pt-2 pb-2">
              <div class="button-container">
                <!-- <v-btn icon @click="GetBankDetail(item.announcement_ID)"> -->
                <v-btn :disabled="permission.edit" icon v-show="item.isitmine">
                  <img class="editIcon" :src="images.edit" @click="editannouncement(item.announcement_ID)" />
                </v-btn>
                <!-- <v-btn icon @click="Deletedia(item.announcement_ID)"> -->
                <v-btn :disabled="permission.delete" icon v-show="item.isitmine" @click="deleteshowdialog(item.announcement_ID)">
                  <img class="deleteIcon" :src="images.delete" />
                </v-btn>

                <v-btn icon v-show="!item.isitmine" @click="handleDoubleClick(item.announcement_ID)">
                  <img class="eyeIcon1" :src="images.eye" />
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="text-right" v-if="resultannoncementlist.length">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination v-model="page" :length="pageCount" color="#F99D20" :total-visible="6"></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
              :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog v-model="Deletedialog" class="dialog" max-width="532">
      <v-card style="border-radius: 15px;">
        <v-card-title class="d-flex justify-center align-center">
          {{ $t("confirmation") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center pb-4">
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="Deletedialog = false" text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" width="120" max-height="35"
            @click="deleteAnnouncement">
            {{ $t("Confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import ReportTrace from "@/function/Report.js";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import { formatServerDateToLocal } from '@/plugins/toLocal.js';
export default {
  mixins: [ReportTrace, LogTrace, CheckViewAddEditDelete],
  data() {
    return {
      permission: {
        add: true,
        edit: true,
        delete: true,
      },
      LoadingDialog: false,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      url: enurl.apiUrl,
      resultannoncementlist: [],
      Deletedialog: false,
      tempid: null,
      isitmine: false,
    }
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  watch:{
    searchtxt: function (newVal) {
      if (newVal === null || newVal === '')
      {
        this.getAnnouncement();
      }
    }
  },

  mounted() {
    this.getAnnouncement();
    this.permission = this.CheckViewAddEditDelete(1, 2, 3, 4);
  },

  methods: {
    async getAnnouncement() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
        timezoneOffset: new Date().getTimezoneOffset()
      };
      await axios.post(`${self.url}Announcement/GetCustomerWeb`, temp)
      .then(function (response) {
        if (response.data.status === 0)
        {
          self.resultannoncementlist = response.data.data.map(item => ({
            ...item,
            isitmine: self.checkmine(item.employee_ID),
            formattedAnnouncementDate: formatServerDateToLocal(item.createdAnnouncement_Date)
          }));
          if (store.state.roleID != "HR" && store.state.roleID != "Admin") {
            self.resultannoncementlist = self.resultannoncementlist.filter(item => item.announcement_Status === "Announcement");
          } else {
            self.resultannoncementlist = self.resultannoncementlist.filter(item =>
            item.announcement_Status === "Announcement"
            || (item.isitmine == true && item.announcement_Status != "Announcement")
            );
          }
          
          self.resultannoncementlist.sort((a, b) => {
            // First criteria: Sort by announcement_Status
            if (a.announcement_Status === 'Announcement' && b.announcement_Status !== 'Announcement')
            {
              return -1;
            }
            else if (a.announcement_Status !== 'Announcement' && b.announcement_Status === 'Announcement')
            {
              return 1;
            }

            // Second criteria: Sort by status
            if (a.status === true && b.status !== true)
            {
              return -1;
            } else if (a.status !== true && b.status === true)
            {
              return 1;
            }

            // Third criteria: Sort by announcement_ID
            if (a.announcement_ID !== b.announcement_ID)
            {
              return b.announcement_ID - a.announcement_ID;
            }
            // If all criteria are equal
            return 0;
          });
        }
        else
        {
          alert(response.data.message)
        }
        self.LoadingDialog = false;
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Announcement Fail", 39, "Critical");
        self.LoadingDialog = false;
      });
    },

    checkmine(id){
      let re = false;
      if(id == store.state.employeeID){
        re = true;
      }
      return re;
    },
  
    back() {
      this.$router.push({ name: "home" }).catch(() => {});
    },

    handleDoubleClick(id)
    {
      this.$router.push({ name: "readMore", params: { announcementid: id } }).catch(() => { });
    },

    editannouncement(id){
      this.$router.push({ name: "EditAnnouncement", params: { announcementid: id } }).catch(() => { });
    },

    deleteshowdialog(id) {
      let self = this;
      self.Deletedialog = true;
      self.tempid = id;
    },

    deleteAnnouncement() {
      let self = this;
      self.LoadingDialog = true;
      let tempdelete = {
        announcement_ID: self.tempid,
      };
      axios.post(`${self.url}Announcement/DeleteAnnouncement`, tempdelete)
      .then(async function (response) {
        self.Deletedialog = false;
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.resultannoncementlist = [];
          await self.getAnnouncement();
          self.LoadingDialog = false;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete Announcement Fail", 39, "High");
        //alert(error + "Correlation ID is 39");
        self.LoadingDialog = false;
      });
    },

    Search() {
      if (this.searchtxt && this.searchtxt.trim() !== "")
      {
        const searchTextLower = this.searchtxt.toLowerCase();
        this.resultannoncementlist = this.resultannoncementlist.filter(announcement => announcement.announcement_Title.toLowerCase().includes(searchTextLower) || announcement.announcement_Detail.toLowerCase().includes(searchTextLower));
      }
      else
      {
        alert("Please enter text");
        this.getAnnouncement();
      }
    }
  }
}
</script>

<style>
* {
  font-family: 'Kanit';
}

.button-container {
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
  height: 100%;
  /* Ensure it takes the full height of the parent td */
}

tr .table-header {
  font-size: 18px !important;
  font-family: 'Kanit' !important;
  font-weight: normal !important;
}

.status-btn {
  font-weight: normal;
}

.status-btn.expired {
  background-color: #FF4B55;
  opacity: 0.5;
}

.status-btn.waiting {
  background-color: #F89D1F;
  opacity: 0.5;
}

.status-btn.active {
  background-color: #00D57B;
  opacity: 0.5;
}

.image-cell {
  width: 287px; /* Set the width of the table cell */
  height: 172px; /* Set the height of the table cell */
  padding: 0; /* Remove any padding */
  overflow: hidden; /* Hide any overflow content */
}

.announcement-image {
  width: 255px; 
  height: 155px;
}

.announcement-content {
  max-width: 500px; /* Full width of the table cell */
  padding: 0; /* Remove padding to fit content properly */
  box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}

.announcement-content1 {
  flex-grow: 1;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensures it takes the full width */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  overflow-x: auto; /* Adds a scrollbar if content overflows */
}

.d-flex {
  display: flex;
}

.announcement-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.announcement-title {
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
}

.pinIcon {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

.announcement-detail {
  width: 100%;
  max-width: 875px;
  margin: 0 auto; /* Center align */
  box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}

.announcement-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Show only 2 lines */
  line-height: 1.5; /* Adjust this line height to match your design */
  max-height: calc(1.5em * 2); /* line-height * number of lines */
}


@media (max-width: 768px) {
  .announcement-title {
    font-weight: bold;
    font-size: 16px; /* Adjust for smaller screens */
  }
  
  .pinIcon {
    width: 16px; /* Adjust size for smaller screens */
    height: 16px; /* Adjust size for smaller screens */
  }

  .eyeIcon {
    width: 18px; /* Adjust size for smaller screens */
    height: 18px; /* Adjust size for smaller screens */
  }

  .messageIcon {
    width: 18px; /* Adjust size for smaller screens */
    height: 18px; /* Adjust size for smaller screens */
  }
}

@media (max-width: 480px) {
  .announcement-title {
    font-size: 16px; /* Further adjust for very small screens */
    font-weight: bold;
  }
  
  .pinIcon {
    width: 14px; /* Further adjust size for very small screens */
    height: 14px; /* Further adjust size for very small screens */
  }

  .eyeIcon {
    width: 16px; /* Further adjust size for very small screens */
    height: 16px; /* Further adjust size for very small screens */
  }

  .messageIcon {
    width: 16px; /* Further adjust size for very small screens */
    height: 16px; /* Further adjust size for very small screens */
  }
}

@media (max-width: 1200px) {
  .announcement-detail {
    max-width: 800px;
  }
}

@media (max-width: 992px) {
  .announcement-detail {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .announcement-detail {
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .announcement-detail {
    max-width: 500px;
    /* overflow-x: auto; */
  }
}

@media (max-width: 480px) {
  .announcement-detail {
    max-width: 400px;
  }
}

@media (max-width: 320px) {
  .announcement-detail {
    max-width: 300px;
  }
}
</style>