<template>
  <div>
    <v-card style="margin-bottom: 2.5rem">
      <v-container>
        <v-row style="margin-left: 5px; margin-right: 5px" class="mt-3">
          <!-- <v-col cols="12" xs="12" sm="5" md="2" lg="4">
            <v-text-field color="#F99D20" v-model.lazy="searchtxt" :placeholder="$t('Search')" dense
              style="margin-top: -0.2rem"></v-text-field>
          </v-col>
          <v-btn color="#F99D20" @click="Search" class="white--text text-capitalize ml-5" width="120" max-height="35">
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn> -->
          <v-col class="mt-2" cols="12" xs="12" sm="6" md="4" lg="3">
            <v-text-field
              :placeholder="$t('Search')"
              v-model.lazy="searchtxt"
              color="#F99D20"
              dense
              outlined
              autocomplete="off"
              prepend-inner-icon="mdi-magnify"
              style="font-size:14px; margin-top:-0.7rem; margin-left:1rem;"
              @keyup.enter="Search()">
              </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn color="#F99D20" class="white--text mr-3 text-capitalize add_btn mt-2" width="180" max-height="35"
            @click="openDialog" :disabled="permission.add">
            {{ $t("AddPayrollAccount") }}</v-btn>
        </v-row>
        <v-data-table :headers="headers" :items="paymentList" :page.sync="page" :items-per-page="itemsPerPage"
          hide-default-footer @page-count="pageCount = $event" :mobile-breakpoint="0" class="ml-4 mr-4 mt-4">
          <template v-slot:[`item.bank_Name`]="{ item }">
            <td>
              <div style="display:flex;align-items: center;">
                <img height="30" width="30" style="border-radius: 50px;" :src="item.base64BankIcon" class="mr-5" />
                {{
                  item.account_Number
                }}
              </div>
            </td>
            <!-- <td style="border-bottom: 1px solid #fff">{{ item.branch }}</td>
            <td style="border-bottom: 1px solid #fff">{{ item.account_Name }}</td> -->
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td align="left" v-if="item.account_Status == true" @click.stop="ChangeDefault(item)">
              <div v-if="item.account_Status == true">
                <input :disabled="disthis" type="checkbox" v-model="item.account_Status" />
              </div>
            </td>
            <td align="left" v-else @click.stop="ChangeDefault(item)">
              <input :disabled="disthis" type="checkbox" style="cursor: default" v-model="item.account_Status" />
            </td>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :disabled="permission.edit" @click="editPayRoll(item)">
              <img :src="images.edit" class="editIcon"/>
            </v-btn>
            <v-btn icon :disabled="permission.delete" @click="DeleteOpen(item.paymentAcc_ID, item.account_Status)">
              <img :src="images.delete" class="deleteIcon"/>
            </v-btn>
          </template>
        </v-data-table>

        <div class="text-right" v-if="paymentList.length">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="createPayRoll" persistent width="850px">
      <v-card style="border-radius: 15px">
        <v-container class="fix-bank">
          <div class="d-flex mt-3" style="margin-left: 1rem">
            <p class="mt-3" style="font-size: 23px; color: #444444">
              {{
                createTest == "Create"
                ? $t("AddPayrollAccount")
                : $t("EditPayrollAccount")
              }}
            </p>
          </div>
          <v-row no-gutters class="mt-7" style="margin-left: 1rem; margin-right: 1rem">
            <v-col cols="12" xs="12" sm="3" md="3" class="mt-2" style="font-size: 15px">
              {{ $t("bank") }}<span class="red--text"> *</span></v-col>

            <v-col cols="12" xs="12" sm="9" md="9">
              <v-autocomplete outlined v-model="payroll.bank_ID" item-value="bank_ID" dense
                :error-messages="errorMessage.bank_ID" :items="bankList" color="#F99D20"
                :item-text="(item) => ` ${item.bank_Name}:${item.bankName_EN}`">
                <template v-slot:selection="data">
                  <v-avatar left size="30" style="margin-right:10px;">
                    <v-img :src="data.item.bankIcon"></v-img>
                  </v-avatar>
                  {{ thethaihead == true ? data.item.bank_Name : data.item.bankName_EN }}

                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar size="30">
                      <img :src="data.item.bankIcon">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{ thethaihead == true ? data.item.bank_Name : data.item.bankName_EN
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters style="margin-left: 1rem; margin-right: 1rem">
            <v-col cols="12" xs="12" sm="3" md="3" class="mt-2" style="font-size: 15px">
              {{ $t("AccountNumber") }}<span class="red--text"> *</span>
            </v-col>
            <v-col cols="12" xs="12" sm="9" md="9">
              <v-text-field v-model="payroll.account_Number" outlined
              @keypress="keymonitor"
                :error-messages="errorMessage.account_Number" dense color="#F99D20"></v-text-field>
            </v-col>
          </v-row>
          <!-- v-mask="$store.state.country == 'Thai' ||
                $store.state.country == 'Thailand' ||
                $store.state.country == 'ไทย' ||
                $store.state.country == 'ประเทศไทย'
                ? mask
                : null
                " -->
          <v-row no-gutters style="margin-left: 1rem; margin-right: 1rem">
            <v-col cols="12" xs="12" sm="3" md="3" class="mt-2" style="font-size: 15px">
              {{ $t("Branch") }}<span class="red--text"> *</span></v-col>
            <v-col cols="12" xs="12" sm="9" md="9">
              <v-text-field outlined dense :error-messages="errorMessage.branch" color="#F99D20"
                v-model="payroll.branch"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters style="margin-left: 1rem; margin-right: 1rem">
            <v-col cols="12" xs="12" sm="3" md="3" class="mt-2" style="font-size: 15px">{{ $t("AccountName") }}<span
                class="red--text"> *</span></v-col>
            <v-col cols="12" xs="12" sm="9" md="9">
              <v-text-field outlined dense color="#F99D20" :error-messages="errorMessage.account_Name"
                v-model="payroll.account_Name"></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn class="text-capitalize fix-btn commoncancelbtn mr-5" outlined text
              @click="cancelClear()">
              {{ $t("Cancel") }}</v-btn>
            <v-btn class="text-capitalize mr-3 fix-btn commonsavebtn"
              v-show="isAdding" @click="addPayRoll()">
              {{ $t("Add") }}</v-btn>
            <v-btn class="text-capitalize mr-3 fix-btn commonsavebtn"
              v-show="!isAdding" @click="editedPayRoll()">
              {{ $t("Update") }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="Deletedialog = false" text>
            {{ $t("Cancel") }}</v-btn>
          <v-btn color="#FF2727" class="mr-2 white--text text-capitalize" width="120" max-height="35"
            @click="removePayment">
            {{ $t("Delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import enurl from "@/api/environment";
import store from "@/store";
import Axios from "axios";
import { mask } from "vue-the-mask";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
export default {
  directives: {
    mask,
  },
  mixins: [LogTrace, utils, CheckViewAddEditDelete],
  data() {
    return {
      thelang: "",
      searchlength: 0,
      request: {
        company_ID: store.state.companyID,
      },
      page: 1,
      permission: {
        add: true,
        edit: true,
        delete: true,
      },
      createPayRoll: false,
      url: enurl.apiUrl,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      Deletedialog: false,
      LoadingDialog: false,
      createTest: "Create",
      searchtxt: "",
      isAdding: true,

      mask: "##########",
      errorMessage: {
        account_Number: "",
        branch: "",
        account_Name: "",
        bank_ID: "",
      },
      paymentAcc_ID: 0,
      payroll: {
        account_Number: "",
        branch: "",
        account_Name: "",
        bank_ID: "",
      },

      bankList: [],
      paymentList: [],
      isitfirst: false,
      disthis: false,
    };
  },

  computed: {
    images() {
      return this.$store.state.images;
    },
    headers() {
      return [
        {
          text: this.$t("status"),
          value: "status",
          align: "left",
          sortable: false,
          width: 100,
        },
        {
          text: this.$t("PayrollAccount"),
          value: "bank_Name",
          align: "left",
          sortable: false,
          width: 300,
        },
        {
          text: this.$t("Branch"),
          value: "branch",
          align: "center",
          sortable: false,
          width: 200,
        },
        {
          text: this.$t("AccountName"),
          value: "account_Name",
          align: "center",
          sortable: false,
          width: 200,
        },
        {
          text: this.$t("Actions"),
          value: "actions",
          align: "right",
          sortable: false,
          width: 200,
        },
      ];
    },
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    thelang() {
      this.whatthehead()
    },
    "payroll.account_Name"(v) {
      if (v) this.errorMessage.account_Name = "";
    },
    "payroll.account_Number"(v) {
      if (v) this.errorMessage.account_Number = "";
    },
    "payroll.bank_ID"(v) {
      if (v) this.errorMessage.bank_ID = "";
    },
    "payroll.branch"(v) {
      if (v) this.errorMessage.branch = "";
    },
    searchtxt: function () {
      if (this.searchlength == 1) {
        this.getBankList();
      }
    },
  },

  mounted() {
    this.getBank();
    this.getBankList();
    this.permission = this.CheckViewAddEditDelete(99, 100, 101, 102);
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },

  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    formatPhoneNumber(x) {
      let cleaned = x.replace(/\D/g, '');
      cleaned = cleaned.slice(0, 12);
      let formatted = '';
      for (let i = 0; i < cleaned.length; i++) {
        if (i === 3 || i === 4 || i === 9 || i === 14) {
          formatted += '-';
        }
        formatted += cleaned[i];
      }
      x = formatted;
      return x;
    },
    keymonitor($event){
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var x = this.payroll.account_Number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,1})(\d{0,5})(\d{0,2})/);
      this.payroll.account_Number =  (x[1]) +  (x[2] ? '-' + x[2] : '') +  (x[3] ? '-' + x[3] : '') +  (x[4] ? '-' + x[4] : '');
      
    },
    ValidateTrue(obj) {
      if (
        obj.account_Name != null &&
        obj.account_Name != "" &&
        obj.account_Number != null &&
        obj.account_Number != "" &&
        obj.bank_ID != null &&
        obj.bank_ID != "" &&
        obj.branch != null &&
        obj.branch != ""
      ) {
        return true;
      } else {
        return false;
      }
      // return Object.values(obj).every((x) => x);
    },
    ValidateFalse(obj) {
      if (Object.values(obj).some((x) => !x)) {
        alert("Please fill in the required fields!");
      }
    },
    checkDuplicateAdd(payroll) {
      return !this.paymentList.some(
        (el) =>
          el.bank_ID == payroll.bank_ID &&
          el.account_Number == payroll.account_Number
      );
    },
    checkDuplicateEdit(payroll) {
      let notSameList = this.paymentList.filter(
        (el) => el.paymentAcc_ID != payroll.paymentAcc_ID
      );
      return !notSameList.some(
        (el) =>
          el.bank_ID == payroll.bank_ID &&
          el.account_Number == payroll.account_Number
      );
    },
    async removePayment() {
      try {
        let temp = {
          paymentAcc_ID: this.paymentAcc_ID,
        };
        const response = await Axios.post(`${this.url}Payment_Account/DeletePaymentAccount`,temp);
        if (response.data.status == 0)
        {
          alert(response.data.message);
          this.Deletedialog = false;
          this.getBankList();
        }
      } catch (ex) {
        this.LogTrace(ex, "Delete PayRollAccount Fail", 43, 26, "High");
        throw ex;
      }
    },
    cancelClear() {
      this.clearData(this.payroll);
      this.clearData(this.errorMessage);
      this.payroll = { ...this.errorMessage };
    },
    async getBank() {
      try {
        let { company_ID } = this.request;
        let temp = {
          company_ID: company_ID,
        };
        const response = await Axios.post(
          `${this.url}Bank/GetALLBanksByCompanyID`,
          temp
        );
        this.bankList = response.data.data;
        this.bankList.sort((a, b) => (a.bank_Name > b.bank_Name ? 1 : -1));
      } catch (error) {
        throw error;
      }
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        self.searchlength = 1;
        let List = self.paymentList;
        self.paymentList = [];
        let temp = List.filter((v) =>
          v.bank_Name.toLowerCase().includes(self.searchtxt.toLowerCase()) || v.account_Name.toLowerCase().includes(self.searchtxt.toLowerCase()) || v.account_Number.includes(self.searchtxt) || v.branch.toLowerCase().includes(self.searchtxt.toLowerCase()))
          ;

        for (let i = 0; i < temp.length; i++) {
          self.paymentList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.getBankList();
      }
    },
    async getBankList() {
      try {
        this.LoadingDialog = true;
        this.searchlength = 0;
        const response = await Axios.post(`${this.url}Payment_Account/GetPaymentAccount`,this.request);
        this.paymentList = response.data.data;
        if(this.paymentList.length == 0){
          this.isitfirst = true;
        } else {
          this.isitfirst = false;
        }
        if(this.paymentList.length == 1){
          this.disthis = true;
        } else if(this.paymentList.length > 1){
          this.disthis = false;
        }
        this.LoadingDialog = false;
      } catch (error) {
        this.LogTrace(error, "Get PayRollAccount Fail", 43, "Critical");
        //alert(error + "Correlation ID is 43");
        this.LoadingDialog = false;
      }
    },
    // codeformat(c) {
    //   let code = c;
    //   let match = code.match(/^(\d{3})(\d{1})(\d{5})(\d{1})$/);
    //   let match1 = code.match(/^(\d{3})(\d{1})(\d{5})(\d{3})$/);
    //   if (match) {
    //     return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
    //   }
    //   if (match1) {
    //     return `${match1[1]}-${match1[2]}-${match1[3]}-${match1[4]}`;
    //   }
    //   return "";
    // },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => { });
    },
    editPayRoll(e) {
      this.createTest = "Edit";
      this.thelang = localStorage.getItem('Templang');
      this.isAdding = false;
      this.createPayRoll = true;
      this.payroll = Object.assign({}, e);
      this.payroll.account_Number = this.formatPhoneNumber(this.payroll.account_Number);
    },
    openDialog() {
      this.createTest = "Create";
      this.isAdding = true;
      this.createPayRoll = true;
    },
    clearData(obj = {}, defaultValue = null) {
      for (const key in obj) {
        obj[key] = defaultValue;
      }
      this.createPayRoll = false;
    },
    DeleteOpen(paymentAcc_ID, status) {
      if(status == true){
        alert(this.$t("payaccalert"));
      } else {
        this.paymentAcc_ID = paymentAcc_ID;
        this.Deletedialog = true;
      }
    },
    errorAlert() {
      let { errorMessage, payroll } = this;
      errorMessage.account_Number = !payroll.account_Number
        ? "Please fill in account number field"
        : "";
      errorMessage.branch = !payroll.branch
        ? "Please fill in the branch field"
        : "";
      errorMessage.account_Name = !payroll.account_Name
        ? "Please fill in the account name field"
        : "";
      errorMessage.bank_ID = !payroll.bank_ID
        ? "Please fill in the bank field"
        : "";
    },
    async addPayRoll() {
      try {
        
        let {
          request,
          payroll,
          getBankList,
          clearData,
          errorMessage,
          checkDuplicateAdd,
        } = this;
        let temp = { ...request, ...payroll, account_Status : this.isitfirst };
        this.errorAlert();
        // if (
        //   store.state.country == "Thailand" ||
        //   store.state.country == "Thai" ||
        //   store.state.country == "ไทย" ||
        //   store.state.country == "ประเทศไทย"
        // ) {
          //console.log(this.payroll.account_Number);
          
        //}

        let tempvalid = this.ValidateTrue(payroll);
        if (tempvalid == true) {
          // if (
          //   store.state.country == "Thailand" ||
          //   store.state.country == "Thai" ||
          //   store.state.country == "ไทย" ||
          //   store.state.country == "ประเทศไทย"
          // ) {
            let thebacknum = this.payroll.account_Number.replace(/-/g, '');
            if (thebacknum && thebacknum.length != 10 && thebacknum.length != 12) {
              errorMessage.account_Number =
                "Please fill in 10 or 12 digits for account number";
            }
            if (thebacknum.length == 10 || thebacknum.length == 12)
            {
              this.payroll.account_Number = this.payroll.account_Number.replace(/-/g, '');
              if (checkDuplicateAdd(payroll))
              {
                this.LoadingDialog = true;
                const response = await Axios.post(`${this.url}Payment_Account/AddPaymentAccount`,temp);
                if (response.data.status == 0)
                {
                  alert(response.data.message);
                  clearData(payroll);
                  getBankList();
                  this.LoadingDialog = false;
                }
              } else {
                alert("This payroll already exists");
              }
            } else {
              this.errorMessage.account_Number =
                "Please fill in 10 or 12 digits for account number";
            }
          // } else {
          //   if (checkDuplicateAdd(payroll)) {
          //     this.LoadingDialog = true;
          //     const response = await Axios.post(
          //       `${this.url}Payment_Account/AddPaymentAccount`,
          //       temp
          //     );
          //     if (response.data.status == 0) {
          //       alert(response.data.message);
          //       clearData(payroll);
          //       getBankList();
          //       this.LoadingDialog = false;
          //       this.LogTrace(null, "Add PayRollAccount", 43, "Low");
          //     }
          //   } else {
          //     alert("This payroll already exists");
          //   }
          // }
        }
      } catch (error) {
        this.LogTrace(error, "Add PayRollAccount Fail", 43, "Low");
        //alert(error + "Correlation ID is 43");
        throw error;
      }
    },
    async editedPayRoll() {
      try {
        
        let {
          request,
          payroll,
          getBankList,
          clearData,
          errorMessage,
          checkDuplicateEdit,
        } = this;
        let temp = { ...request, ...payroll };
        this.errorAlert();
        // if (
        //   store.state.country == "Thailand" ||
        //   store.state.country == "Thai" ||
        //   store.state.country == "ไทย" ||
        //   store.state.country == "ประเทศไทย"
        // ) {
          
          
        //}

        let tempvalid = this.ValidateTrue(payroll);
        if (tempvalid == true) {
          // if (
          //   store.state.country == "Thailand" ||
          //   store.state.country == "Thai" ||
          //   store.state.country == "ไทย" ||
          //   store.state.country == "ประเทศไทย"
          // ) {
            let thebacknum = this.payroll.account_Number.replace(/-/g, '');
            if (thebacknum && thebacknum.length != 10 && thebacknum.length != 12) {
              errorMessage.account_Number =
                "Please fill in 10 or 12 digits for account number";
            }
            if (thebacknum.length == 10 || thebacknum.length == 12) {
              this.payroll.account_Number = this.payroll.account_Number.replace(/-/g, '');
              if (checkDuplicateEdit(payroll)) {
                const response = await Axios.post(
                  `${this.url}Payment_Account/UpdatePaymentAccount`,
                  temp
                );
                if (response.data.status == 0)
                {
                  alert("Update Successfully");
                  clearData(payroll);
                  getBankList();
                }
              } else {
                alert("This payroll already exists");
              }
            } else {
              this.errorMessage.account_Number =
                "Please fill in 10 or 12 digits for account number";
            }
          // } else {
          //   if (checkDuplicateEdit(payroll)) {
          //     const response = await Axios.post(
          //       `${this.url}Payment_Account/UpdatePaymentAccount`,
          //       temp
          //     );
          //     if (response.data.status == 0) {
          //       alert("Update Successfully");
          //       clearData(payroll);
          //       getBankList();
          //       this.LogTrace(null, "Update PayRollAccount", 43, "Medium");
          //     }
          //   } else {
          //     alert("This payroll already exists");
          //   }
          // }
        } else {
          this.ValidateFalse(payroll);
        }
      } catch (error) {
        this.LogTrace(error, "Update PayRollAccount Fail", 43, "Medium");
        //alert(error + "Correlation ID is 43");
        throw error;
      }
    },
    async ChangeDefault(item) {
      let self = this;
      if(self.disthis==false){
        self.LoadingDialog = true;
        item.account_Status = !item.account_Status;
        let temp = {
          paymentAcc_ID: item.paymentAcc_ID,
          account_Status: item.account_Status,
          company_ID: store.state.companyID,
          user_ID: store.state.userid
        };
        await Axios
          .post(`${self.url}Payment_Account/UpdatePayAccDefault`, temp)
          .then(function (response) {
            if (response.data.status == 0)
            {
              self.LoadingDialog = false;
              self.getBankList();
            }
          })
          .catch(function (error) {
            alert(error);
          });
        }
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 100%;
}

@media only screen and (max-width: 375px) {
  .add_btn {
    margin-top: 15px;
  }
}

@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  color: #f99d20;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}
/* Switch Enable login */
@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(circle 12px,
        white 100%,
        transparent calc(100% + 1px)) #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}

/* Switch Enable login */
</style>
