<template>
  <div>
    <div class="pt-7">
      <v-row class="pl-md-0 pt-9" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <!-- <div class="input-container custom-height" @keyup.enter="Search()">
            <i class="mdi mdi-magnify prepend-icon"></i>
            <input
              type="text"
              :placeholder="$t('Search') + ' with User Group Name'"
              v-model="searchtxt"
              class="custom-input"
              autocomplete="off"
              
            />
          </div> -->
          <v-text-field
            :placeholder="$t('Search') + ' with User Group Name'"
            v-model="searchtxt"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.7rem; margin-left:1rem;"
            @keyup.enter="Search()"></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="permission.add"
          class="white--text text-capitalize ml-5 mr-5 btnlef commonsavebtn"
          router
          :to="{
            name: 'usergroupadd',
            params: { usergroupadd: 'usergroupadd' },
          }">
            {{ $t("NewGroup") }}
          </v-btn>
      </v-row>
    </div>
    <v-data-table
      :items="UserGroupList"
      style="margin-left: 40px; margin-right: 40px; min-height: 10rem;"
      class="tbheader"
      :mobile-breakpoint="0"
      :headers="headers"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event">
      <template v-slot:item.action="{ item }">
        <v-btn
          icon
          @click="EditUG(item.user_Group_ID)"
          :disabled="permission.edit">
          <img class="editIcon" :src="images.edit" alt="editIcon"/>
        </v-btn>
        <v-btn
          icon
          @click="RemoveUG(item.user_Group_ID)"
          :disabled="permission.delete">
          <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-right" v-if="UserGroupList.length">
      <v-row justify="end" class="ml-5 mt-5 mr-5">
        <v-col cols="12" md="3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="#FFCD2C"
            :total-visible="6"
          ></v-pagination>
        </v-col>
        <v-col cols="12" md="2" class="mt-1">
          <v-select
            dense
            style="width: 120px; float: right"
            solo
            label="10/page"
            v-model="itemsPerPage"
            :items="items"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="DeleteDialog" class="dialog" max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="left" cols="12" v-if="UserGroup.length != 0">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("ugpconfrimdelete") }}
                </p>
              </v-col>
              <v-col v-else>
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize commoncancelbtn"
            outlined
            @click="DeleteDialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 white--text text-capitalize delete_btn commondeletebtn"
            @click="DeleteUserGP"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
export default {
  mixins: [LogTrace, utils, CheckViewAddEditDelete],
  data() {
    return {
      permission: {
        add: true,
        edit: true,
        delete: true,
      },
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      DeleteDialog: false,
      ids: 1,
      UGDID: null,
      UserGroupLength: 0,
      UserGroupList: [],
      UserGroupDataList: [],
      TempEmpList: [],
      TempRoleList: [],
      TempPermitList: [],
      LoadingDialog: false,
      UserGroup: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("GroupName"),
          value: "user_Group_Name",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t("action"),
          value: "action",
          align: "left",
          sortable: false,
        },
      ];
    },
    images() {
      return this.$store.state.images;
    }
  },

  watch: {
    searchtxt: function () {
      this.GetUserGroup();
    },
  },
  mounted() {
    this.GetUserGroup();
    this.permission = this.CheckViewAddEditDelete(107, 108, 109, 110);
  },
  methods: {
    EditUG(id) {
      let self = this;

      self.$router.push({
        name: "usergroupedit",
        params: { usergroupedit: "usergroupedit", ugid: id },
      }).catch(()=>{});

      // });
    },
    RemoveUG(id) {
      let self = this;
      self.UGDID = id;
      let tempp = {
        user_Group_ID: id,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroup/GetUserGroupInfo`, tempp)
        .then(function (response) {
          self.UserGroup = response.data.data.employeeList;
        })
        .catch(function (error) {
          alert(error);
        });
      setTimeout(() => {
        self.DeleteDialog = true;
      }, 300);
    },
    GetUserGroup() {
      let self = this;
      if (
        self.UserGroupLength == 0 ||
        self.UserGroupLength != self.UserGroupList.length
      ) {
        self.LoadingDialog = true;
        let tempp = {
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}UserGroup/GetUserGroup`, tempp)
          .then(function (response) {
            self.UserGroupList = response.data.data;
            self.UserGroupLength = self.UserGroupList.length;
            self.LogTrace(null, "Get User Group", 35, "Critical");
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get User Group Fail", 35, "Critical");
            //alert(error);
            self.LoadingDialog = false;
          });
      }
    },
    DeleteUserGP() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.UGDID;
      let temp = {
        user_Group_ID: id,
      };
      axios
        .post(`${self.url}UserGroup/DeleteUserGroup`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.DeleteDialog = false;
            self.GetUserGroup();
            self.LoadingDialog = false;
            self.LogTrace(null, "Delete User Group", 35, "High");
            self.GetUserGroup();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete User Group Fail", 35, "High");
          alert(error);
          self.DeleteDialog = false;
          self.LoadingDialog = false;
          self.GetUserGroup();
        });
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined)
      {
        let List = self.UserGroupList;
        self.UserGroupList = [];
        let Stxt = self.searchtxt.toLowerCase();
        for (let i = 0; i < List.length; i++) 
        {
          if (List[i].user_Group_Name.toLowerCase().includes(Stxt))
          {
            self.UserGroupList.push(List[i]);
          }
        }
      }
      else 
      {
        alert("Please enter anything!!");
        self.GetUserGroup();
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
@media only screen and (max-width: 320px) {
  .delete_btn {
    margin-left: -10px !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
  .btnlef {
    margin-left: 1.5rem !important;
  }
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
  max-width: 80px !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .v-data-table tr td {
  height: 70px;
  text-align: center;
  width: 800px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
  max-width: 80px !important;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>