<template>
  <div>
    <!-- <div
      style="
        padding-left: 0px;
        padding-right: 1%;
        margin-bottom: 1rem;
        margin-left: 1rem;
      "
    >
      <v-row class="mr-0">
        <v-btn @click="back()" icon color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p
          class="headtext venti"
          style="
            cursor: pointer;
            font-size: 25px;
            font-family: 'Kanit';
            margin-left: 4px;
            margin-top: 20px;
          "
          @click="back()"
        >
          {{ $t("withholdtax") }}
        </p>
      </v-row>
    </div> -->
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("withholdtax") }}
        </p>
    </div>

    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="ml-4 mr-4">
        <v-col cols="12" xs="12" sm="4" md="3" lg="3" xl="3">
          <div class="d-flex">
            <div class="mt-8">{{ $t("Year") }}</div>
            <div>
              <date-picker
                v-model="homeyears"
                type="year"
                class="ml-2"
                dense
                style="
                  margin-top: 1.5rem;
                  min-width: 150px;
                  max-width: 150px;
                  border-radius: 5px;
                "
                v-on:change="getstart()"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/down1@2x.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: -7px;
                      padding-top: 3px;
                    "
                  />
                </template>
              </date-picker>
            </div>
          </div>
        </v-col>
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <v-col cols="12" xs="12" sm="4" md="3" lg="3" xl="2" class="text-right">
          <v-btn
            color="#F99D20"
            class="white--text btnres text-capitalize mr-3"
            width="150"
            max-height="35"
            style="margin-top: 1.5rem"
            @click="NewCertificateDialog = true"
            :disabled="permission.add"
            >{{ $t("New") }}</v-btn
          >
        </v-col>
        <!-- <v-btn @click="generateReport">{{ $t("New") }}</v-btn> -->
      </v-row>

      <div class="mt-5">
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :items="CertificateList"
          hide-default-footer
          hide-default-header
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th style="min-width: 120px">{{ $t("Year") }}</th>
                <th style="min-width: 180px">{{ $t("bookno") }}</th>
                <th></th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ parseInt(item.year) + 543 }}</td>
              <td>{{ item.book_No == 0 ? "" : item.book_No }}</td>
              <td style="float: right">
                <v-btn @click="openedit(item.wT_Sub_ID)" icon>
                  <img :src="images.editpic" width="24px" height="24px"/>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div v-show="showtablerow" class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#f99d20"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <!-- New Dialog -->
    <v-dialog v-model="NewCertificateDialog" persistent max-width="1161px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p>{{ $t("newtaxcertificate") }}</p>
          <v-spacer></v-spacer>
          <v-btn @click="closeNewCertiDialog" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <div style="margin-left: 1rem; margin-top: 1rem">
            <v-row>
              <v-col class="pt-0 pb-0" cols="12" md="6">
                <!-- <span class="red--text"> *</span> -->
                {{ $t("companyName") }}
                <v-text-field
                  v-model="NameOfCompany"
                  autocomplete="off"
                  color="#F99D20"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                {{ $t("CompanyTaxID") }}
                <v-text-field
                  v-model="TaxIDOfCompany"
                  autocomplete="off"
                  @keypress="onlyNumber"
                  color="#F99D20"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="pt-0 pb-0">
                {{ $t("address") }}
                <v-text-field
                  v-model="AddressOfCompany"
                  autocomplete="off"
                  color="#F99D20"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-8 pb-0">
                      {{ $t("Year") }}<span class="red--text"> *</span>
                    </p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
                    <date-picker
                      v-model="addyears"
                      type="year"
                      class="mr-4 mb-1 pl-0"
                      style="
                        margin-top: 1.5rem;
                        min-width: 150px;
                        max-width: 150px;
                      "
                      v-on:change="getEmpOfYear()"
                      :default-value="defaultyear"
                      @open="filtersChanged()"
                    >
                      <template slot="icon-calendar">
                        <img
                          src="@/assets/images/down1@2x.png"
                          style="
                            width: 20px;
                            height: 20px;
                            margin-right: -7px;
                            padding-top: 3px;
                          "
                        />
                      </template>
                    </date-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-8 pb-0">{{ $t("bookno") }}</p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="1">
                    <v-text-field
                      v-model="addbook_No"
                      @keypress="onlyNumber"
                      color="#F99D20"
                      dense
                      class="mt-6"
                      outlined
                      autocomplete="off"
                      style="min-width: 150px; max-width: 150px"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0 mt-3" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-3 pb-0">
                      {{ $t("date") }}<span class="red--text"> *</span>
                    </p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
                    <date-picker
                      v-bind:clearable="false"
                      v-model="adddate"
                      format="DD/MM/YYYY"
                      class="fromdate"
                      style="width: 150px"
                      @open="filtersChangecalendar()"
                      :default-value="defaultcalendar"
                    >
                      <template slot="icon-calendar">
                        <img
                          src="@/assets/images/calendaricon.png"
                          style="width: 20px; height: 20px"
                        />
                      </template>
                    </date-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0 mt-3" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-3 pb-0">{{ $t("startingno") }}</p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="addstarting_No"
                      @keypress="onlyNumber"
                      color="#F99D20"
                      dense
                      outlined
                      autocomplete="off"
                      style="min-width: 150px; max-width: 150px"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    align="right"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      color="#F99D20"
                      class="white--text btnres text-capitalize mr-8 mb-3"
                      width="100"
                      max-height="35"
                      v-show="isitsave"
                      @click="saveNewCertificate()"
                      :disabled="permission.add"
                      >{{ $t("save") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div class="ml-1 mt-2 pr-0">
            <v-data-table
              height="300px"
              :items="EmployeeList"
              hide-default-footer
              hide-default-header
              disable-pagination
              style="border: 1px solid grey"
            >
              <template v-slot:header="{ props: {} }">
                <thead style="background: #f99d20">
                  <tr>
                    <th class="white--text">{{ $t("no") }}</th>
                    <th class="white--text">{{ $t("EmployeeID") }}</th>
                    <th class="white--text">{{ $t("employeeName") }}</th>
                    <th class="white--text">{{ $t("status") }}</th>
                    <th class="white--text">{{ $t("pndtype") }}</th>
                    <th class="white--text"></th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr style="height: 8px">
                  <td>
                    {{ item.starting_No }}
                  </td>
                  <td style="width: 200px">{{ item.employee_Code }}</td>
                  <td>{{ item.employee_Name }}</td>
                  <td>{{ item.employeeStatus }}</td>
                  <td>{{ item.employeeType }}</td>
                  <td>
                    <v-btn
                      :disabled="permission.add"
                      @click="
                        NewGetPDFCertificateData(
                          item.employee_ID,
                          adddate,
                          addyears
                        )
                      "
                      icon
                    >
                      <img :src="images.print" />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-4 pt-0">
          <v-row class="mt-9">
            <v-spacer></v-spacer>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Dialog -->
    <v-dialog v-model="EditCertificateDialog" persistent max-width="1161px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p>{{ $t("withholdtax") }}</p>
          <v-spacer></v-spacer>
          <v-btn @click="EditCertificateDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <div style="margin-left: 1rem; margin-top: 1rem">
            <v-row>
              <v-col class="pt-0 pb-0" cols="12" md="6">
                {{ $t("companyName") }}
                <v-text-field
                  v-model="viewCompanyName"
                  readonly
                  autocomplete="off"
                  color="#F99D20"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                {{ $t("CompanyTaxID") }}
                <v-text-field
                  v-model="viewCompanyTax"
                  readonly
                  autocomplete="off"
                  @keypress="onlyNumber"
                  color="#F99D20"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="pt-0 pb-0">
                {{ $t("address") }}
                <v-text-field
                  v-model="viewCompanyAddress"
                  readonly
                  autocomplete="off"
                  color="#F99D20"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-8 pb-0">
                      {{ $t("Year") }}
                    </p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
                    <date-picker
                      v-model="viewyears"
                      type="year"
                      class="mr-4 mb-1 pl-0"
                      style="
                        margin-top: 1.5rem;
                        min-width: 150px;
                        max-width: 150px;
                      "
                      disabled
                    >
                      <template slot="icon-calendar">
                        <img
                          src="@/assets/images/down1@2x.png"
                          style="
                            width: 20px;
                            height: 20px;
                            margin-right: -7px;
                            padding-top: 3px;
                          "
                        />
                      </template>
                    </date-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-8 pb-0">{{ $t("bookno") }}</p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="1">
                    <v-text-field
                      v-model="viewbook_No"
                      readonly
                      color="#F99D20"
                      dense
                      class="mt-6"
                      outlined
                      autocomplete="off"
                      style="min-width: 150px; max-width: 150px"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0 mt-3" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-3 pb-0">
                      {{ $t("date") }}
                    </p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
                    <date-picker
                      v-model="viewdate"
                      disabled
                      v-bind:clearable="false"
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      class="fromdate"
                      style="width: 150px"
                    >
                      <template slot="icon-calendar">
                        <img
                          src="@/assets/images/calendaricon.png"
                          style="width: 20px; height: 20px"
                        />
                      </template>
                    </date-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0 mt-3" cols="12" md="6">
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    xs="3"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <p class="mt-3 pb-0">{{ $t("startingno") }}</p>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="viewstarting_No"
                      readonly
                      color="#F99D20"
                      dense
                      outlined
                      autocomplete="off"
                      style="min-width: 150px; max-width: 150px"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div class="ml-1 mt-2 pr-0">
            <v-data-table
              height="300px"
              :items="SecEmployeeList"
              hide-default-footer
              hide-default-header
              disable-pagination
              style="border: 1px solid grey"
            >
              <template v-slot:header="{ props: {} }">
                <thead style="background: #f99d20">
                  <tr>
                    <th class="white--text">{{ $t("no") }}</th>
                    <th class="white--text">{{ $t("EmployeeID") }}</th>
                    <th class="white--text">{{ $t("employeeName") }}</th>

                    <th class="white--text">{{ $t("status") }}</th>
                    <th class="white--text">{{ $t("pndtype") }}</th>
                    <th class="white--text"></th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr style="height: 8px">
                  <td>
                    {{ item.starting_No }}
                  </td>
                  <td style="width: 200px">{{ item.employee_Code }}</td>
                  <td>{{ item.employee_Name }}</td>
                  <td>{{ item.employeeStatus }}</td>
                  <td>{{ item.employeeType }}</td>
                  <td>
                    <v-btn
                      :disabled="permission.add"
                      @click="
                        EditGetPDFCertificateData(
                          item.employee_ID,
                          viewdate,
                          viewyears
                        )
                      "
                      icon
                    >
                      <img :src="images.print" />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-4 pt-0">
          <v-row class="mt-9">
            <v-spacer></v-spacer>

            <!-- <v-btn
              color="darken-1"
              class="mr-3 text-capitalize fix-btn"
              width="150"
              max-height="35"
              text
              @click="NewCertificateDialog = false"
            >
              {{ $t("Cancel") }}
            </v-btn> -->
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import VueHtml2pdf from "vue-html2pdf";
import { jsPDF } from "jspdf";
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import DatePicker from "vue2-datepicker";
import ThaiFontBoldBase64 from "@/function/global.js";
import ThaiFontBase64 from "@/function/global.js";
import CheckViewAdd from "@/function/RolePermissions.js";
import LogTrace from "@/function/Log.js";
export default {
  components: { DatePicker },
  mixins: [ThaiFontBoldBase64, ThaiFontBase64, CheckViewAdd, LogTrace],
  data() {
    return {
      permission: {
        add: true,
      },
      book_No: null,
      starting_No: null,
      company_Name: null,
      company_Address: null,
      employee_Name: null,
      employeeType: null,
      companyTaxID: null,
      taxpayerID: null,
      address: null,
      total_Income: null,
      personalTaxPerMonth: null,
      personalTaxPerMonthInThai: null,
      total_Social_Security: null,
      total_Provident_Fund: null,
      wT_Date: null,
      url: enurl.apiUrl,
      tot: 0,
      page: 1,
      pageCount: 15,
      itemsPerPage: 5,
      itemsPerPage1: 500,
      items: [5, 10, 20, 30],
      showtablerow: false,
      LoadingDialog: false,
      date: new Date(),
      adddate: "",
      addyears: "",
      addbook_No: "",
      addstarting_No: "",
      defaultyear: new Date(),
      defaultcalendar: "",
      viewdate: "",
      viewyears: "",
      viewbook_No: "",
      viewstarting_No: "",
      printDialog: false,
      images: {
        editpic: require("@/assets/images/reveal icon.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
        print: require("@/assets/images/Group 3272.png"),
      },
      NewCertificateDialog: false,
      EditCertificateDialog: false,
      CertificateList: [],
      companyList: [],
      EmployeeList: [],
      tempEmployeeList: [],
      isitsave: true,
      NameOfCompany: "",
      AddressOfCompany: "",
      TaxIDOfCompany: "",
      viewCompanyName: "",
      viewCompanyTax: "",
      viewCompanyAddress: "",
      SecEmployeeList: [],
      PdfWithholdList: [],
    };
  },
  computed: {
    homeyears: {
      get() {
        const date = new Date(this.date);
        date.setFullYear(date.getFullYear() + 543);
        return date;
      },
      set(value) {
        if (value) {
          const date = new Date(value);
          date.setFullYear(date.getFullYear() - 543);
          this.date = date;
        }
      },
    },
  },
  async mounted() {
    await this.GetCompanyInfo();
    await this.getstart();
    this.permission = this.CheckViewAdd(186, 187);
  },
  methods: {
    filtersChanged() {
      let date = new Date();

      date.setFullYear(date.getFullYear() + 543);

      this.defaultyear = date;
    },
    filtersChangecalendar() {
      //this.addyears=date;
      this.defaultcalendar = this.formatDatefornew();
    },
    NewGetPDFCertificateData(id, d, y) {
      try {
        let self = this;
        let thisYear = "";
        if (y.getFullYear()) {
          thisYear = y.getFullYear();
        } else {
          y = new Date();
          thisYear = new Date().getFullYear();
        }
        let temp = {
          company_ID: store.state.companyID,
          year: thisYear.toString(),
          wT_Date: self.formatDate(d),
          employee_ID: id,
        };
        axios
          .post(`${self.url}WithholdTax/GetCertificatePDF`, temp)
          .then(function (response) {
            self.PdfWithholdList = response.data.data;
            // console.log(self.PdfWithholdList);
            self.book_No = self.PdfWithholdList[0].book_No;
            self.starting_No = self.PdfWithholdList[0].starting_No;
            self.companyTaxID = self.PdfWithholdList[0].companyTaxID;
            self.company_Name = self.PdfWithholdList[0].company_Name;
            self.company_Address = self.PdfWithholdList[0].company_Address;
            self.taxpayerID = self.PdfWithholdList[0].taxpayerID;
            self.address = self.PdfWithholdList[0].address;
            self.total_Income = self.PdfWithholdList[0].total_Income;
            self.personalTaxPerMonth =
              self.PdfWithholdList[0].personalTaxPerMonth;
            self.personalTaxPerMonthInThai =
              self.PdfWithholdList[0].personalTaxPerMonthInThai;
            self.total_Social_Security =
              self.PdfWithholdList[0].total_Social_Security;
            self.total_Provident_Fund =
              self.PdfWithholdList[0].total_Provident_Fund;
            let tempdate = self.PdfWithholdList[0].wT_Date;
            self.wT_Date = tempdate.split("T00:00:00")[0];
            // console.log(self.wT_Date);
            self.employee_Name = self.PdfWithholdList[0].employee_Name;
            self.employeeType = self.PdfWithholdList[0].employeeType;
            setTimeout(() => {
              self.getPdfObj();
            }, "1000");
          });
      } catch (error) {
        alert(error);
        self.LoadingDialog = false;
      }
    },
    // Get Pdf data
    EditGetPDFCertificateData(id, d, y) {
      try {
        let self = this;
        let thisYear = "";
        if (y.getFullYear()) {
          thisYear = parseInt(y.getFullYear()) - 543;
        } else {
          y = new Date();
          thisYear = parseInt(new Date().getFullYear()) - 543;
        }
        let temp = {
          company_ID: store.state.companyID,
          year: thisYear.toString(),
          wT_Date: self.formatDate(d),
          employee_ID: id,
        };
        axios
          .post(`${self.url}WithholdTax/GetCertificatePDF`, temp)
          .then(function (response) {
            self.PdfWithholdList = response.data.data;
            // console.log(self.PdfWithholdList);
            self.book_No = self.PdfWithholdList[0].book_No;
            self.starting_No = self.PdfWithholdList[0].starting_No;
            self.companyTaxID = self.PdfWithholdList[0].companyTaxID;
            self.company_Name = self.PdfWithholdList[0].company_Name;
            self.company_Address = self.PdfWithholdList[0].company_Address;
            self.taxpayerID = self.PdfWithholdList[0].taxpayerID;
            self.address = self.PdfWithholdList[0].address;
            self.total_Income = self.PdfWithholdList[0].total_Income;
            self.personalTaxPerMonth =
              self.PdfWithholdList[0].personalTaxPerMonth;
            self.personalTaxPerMonthInThai =
              self.PdfWithholdList[0].personalTaxPerMonthInThai;
            self.total_Social_Security =
              self.PdfWithholdList[0].total_Social_Security;
            self.total_Provident_Fund =
              self.PdfWithholdList[0].total_Provident_Fund;
            let tempdate = self.PdfWithholdList[0].wT_Date;
            self.wT_Date = tempdate.split("T00:00:00")[0];
            // console.log(self.wT_Date);
            self.employee_Name = self.PdfWithholdList[0].employee_Name;
            self.employeeType = self.PdfWithholdList[0].employeeType;
            setTimeout(() => {
              self.getPdfObj();
            }, "1000");
          });
      } catch (error) {
        alert(error);
        self.LoadingDialog = false;
      }
    },
    // Export PDf
    getPdfObj() {
      let self = this;
      const doc = new jsPDF();
      const imgEle = document.createElement("img");
      doc.addFileToVFS("THSarabunNew.ttf", self.ThaiFontBase64());
      doc.addFileToVFS("THSarabunNewBold.ttf", self.ThaiFontBoldBase64());
      doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      let normalfont = "THSarabunNew";
      let boldfont = "THSarabunNewBold";
      let day = self.wT_Date != "" ? self.wT_Date.split("-")[2] : "";
      let month = self.wT_Date != "" ? self.wT_Date.split("-")[1] : "";
      let year =
        self.wT_Date != "" ? parseInt(self.wT_Date.split("-")[0]) + 543 : "";
      var income_4 = "";
      var font_length = "";
      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text(11.5, 8, "ฉบับที่ 1");
      doc.setFont(normalfont);
      doc.text(
        26.5,
        8,
        "(สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)"
      );
      doc.setFont(boldfont);
      doc.text(11.5, 13, "ฉบับที่ 2");
      doc.setFont(normalfont);
      doc.text(26.5, 13, "(สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)");
      doc.setDrawColor("#313131");
      doc.setLineWidth(0.3);
      //start--1st border
      doc.rect(10, 15, 190, 265);

      doc.setFontSize(20);
      doc.setFont(boldfont);
      doc.text(68, 21, "หนังสือรับรองการหักภาษี ณ ที่จ่าย");

      doc.setFont(normalfont);
      doc.setFontSize(12);
      doc.text(
        160,
        21,
        "เล่มที่ .............................................."
      );
      doc.text(170, 20, `${self.book_No ? self.book_No.toString() : ""}`);

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(77, 26, "ตามมาตรา 50 ทวิแห่งประมวลรัษฎากร");

      doc.setFont(normalfont);
      doc.setFontSize(12);
      doc.text(
        160,
        26,
        "เลขที่ .............................................."
      );
      doc.text(170, 25, `${self.book_No ? self.starting_No.toString() : ""}`);
      //start--first box
      doc.setFont(normalfont);
      doc.setDrawColor("##313131");
      doc.setLineWidth(0.3);
      doc.roundedRect(16, 28, 178, 26, 1, 1);

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(18.5, 33, "ผู้มีหน้าที่หักภาษี ณ ที่จ่าย : -");

      doc.text(80, 33, "เลขประจำตัวผู้เสียภาษีอากร (13หลัก)*");

      let companytaxcode = self.companyTaxID.toString().split("");
      xposition = 130;

      companytaxcode = companytaxcode.map((v, i) => ({
        no: ++i,
        xposition: xposition,
        text: v,
      }));
      doc.setFont(normalfont);
      doc.setFontSize(13.5);
      let increment = 0;
      companytaxcode.forEach((data) => {
        if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) {
          doc.text(data.xposition + 1 + increment + 2.5, 33, data.text);
          increment += 6.5;
        } else {
          doc.text(data.xposition + 1 + increment, 33, data.text);
          increment += 4;
        }
      });
      doc.setLineWidth(0.1);
      var recwidth = 4;

      doc.rect(xposition, 30, recwidth, 4.5);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, 32.5, xposition, 32.5);
      recwidth = 16;
      doc.rect(xposition, 30, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, 30, xposition + 4, 34.5);
      doc.line(xposition + 8, 30, xposition + 8, 34.5);
      doc.line(xposition + 12, 30, xposition + 12, 34.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, 32.5, xposition, 32.5);
      recwidth = 20;
      doc.rect(xposition, 30, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, 30, xposition + 4, 34.5);
      doc.line(xposition + 8, 30, xposition + 8, 34.5);
      doc.line(xposition + 12, 30, xposition + 12, 34.5);
      doc.line(xposition + 16, 30, xposition + 16, 34.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, 32.5, xposition, 32.5);
      recwidth = 8;
      doc.rect(xposition, 30, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, 30, xposition + 4, 34.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, 32.5, xposition, 32.5);
      recwidth = 4;
      doc.rect(xposition, 30, recwidth, 4.5);

      doc.text(18.5, 40, "ชื่อ ");
      doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
      doc.line(20, 40, 106, 40);
      doc.setFont(boldfont);
      doc.setFontSize(14.5);
      doc.text(25, 39, self.company_Name);

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text(
        30,
        43,
        "(ให้ระบุว่าเป็น บุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)"
      );

      doc.setFont(boldfont);
      doc.setFontSize(13);

      doc.text(108.5, 40, "เลขประจำตัวผู้เสียภาษีอากร");

      xposition = 144.5;
      yposition = 36.5;
      yline = 39;
      recwidth = 4;
      doc.setLineDash([]);
      doc.rect(xposition, yposition, recwidth, 4.5);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yline, xposition, yline);
      recwidth = 16;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yline, xposition, yline);
      recwidth = 16;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yline, xposition, yline);
      recwidth = 4;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(18.5, 49, "ที่อยู่ ");
      doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
      doc.line(26, 49.5, 190, 49.5);
      doc.setLineDash([]);

      doc.setFont(boldfont);
      doc.setFontSize(14.5);
      doc.text(33, 48, self.company_Address);

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text(
        30,
        53,
        "(ให้ระบุ ชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด)"
      );
      //end--first box;

      //start--second box;
      doc.setFont(normalfont);
      doc.setDrawColor("##313131");
      doc.setLineWidth(0.3);
      doc.roundedRect(16, 55.5, 178, 40, 1, 1);

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(18.5, 60, "ผู้ถูกหักภาษี ณ ที่จ่าย : - ");

      doc.text(80, 60, "เลขประจำตัวผู้เสียภาษีอากร (13หลัก)*");
      doc.setLineWidth(0.1);
      xposition = 130;
      let yposition = 57;

      doc.setFont(normalfont);
      doc.setFontSize(13.5);
      if(self.taxpayerID != null){
        let customertaxcode = self.taxpayerID.split("");
        customertaxcode = customertaxcode.map((v, i) => ({
          no: ++i,
          xposition: xposition,
          text: v,
        }));
        
        increment = 0;
        customertaxcode.forEach((data) => {
          if (data.no == 2 || data.no == 6 || data.no == 11 || data.no == 13) {
            doc.text(
              data.xposition + 1 + increment + 2.5,
              yposition + 3,
              data.text
            );
            increment += 6.5;
          } else {
            doc.text(data.xposition + 1 + increment, yposition + 3, data.text);
            increment += 4;
          }
        });
      }

      recwidth = 4;
      doc.rect(xposition, yposition, recwidth, 4.5);
      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);

      recwidth = 16;
      doc.rect(xposition, yposition, recwidth, 4.5);
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);

      recwidth = 20;
      doc.rect(xposition, yposition, recwidth, 4.5);
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.line(xposition + 16, yposition, xposition + 16, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      recwidth = 8;
      doc.rect(xposition, yposition, recwidth, 4.5);
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.setLineDash([]);

      let xposition = xposition + recwidth + 2.5;
      recwidth = 4;
      doc.line(xposition - 2.5, yposition + 2.5, xposition, yposition + 2.5);
      doc.rect(xposition, yposition, recwidth, 4.5);

      ////////////////////////////

      doc.text(18.5, 66, "ชื่อ ");
      doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
      doc.line(20, 66, 106, 66);

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text(
        30,
        69,
        "(ให้ระบุว่าเป็น บุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)"
      );
      let customername = self.employee_Name;
      doc.setFont(boldfont);
      doc.setFontSize(14.5);
      doc.text(25, 65, customername);
      doc.setFont(boldfont);
      doc.setFontSize(13);

      doc.text(108.5, 66.5, "เลขประจำตัวผู้เสียภาษีอากร");

      xposition = 144.5;
      yposition = 63;
      let yline = 65.5;
      recwidth = 4;
      doc.setLineDash([]);
      doc.rect(xposition, yposition, recwidth, 4.5);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yline, xposition, yline);
      recwidth = 16;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yline, xposition, yline);
      recwidth = 16;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setLineDash([0.3, 0.3, 0.3, 0.3], 0);
      doc.line(xposition + 4, yposition, xposition + 4, yposition + 4.5);
      doc.line(xposition + 8, yposition, xposition + 8, yposition + 4.5);
      doc.line(xposition + 12, yposition, xposition + 12, yposition + 4.5);
      doc.setLineDash([]);

      xposition = xposition + recwidth + 2.5;
      doc.line(xposition - 2.5, yline, xposition, yline);
      recwidth = 4;
      doc.rect(xposition, yposition, recwidth, 4.5);

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(18.5, 75.5, "ที่อยู่ ");
      doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
      doc.line(26, 76, 190, 76);
      doc.setLineDash([]);

      doc.setFont(boldfont);
      doc.setFontSize(14.5);
      doc.text(27, 74.5, self.address);

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text(
        30,
        80,
        "(ให้ระบุ ชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด)"
      );

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(18.5, 86, "ลำดับที่");

      doc.rect(29, 82, 28, 6);

      doc.setFont(normalfont);
      doc.setFontSize(13);
      doc.text(58, 86, " ในแบบ");

      doc.setFont(normalfont);
      doc.setFontSize(10);
      doc.text(
        18.5,
        91.5,
        "(ให้สามารถอ้างอิงหรือสอบยันกันได้ระหว่างลำดับที่ตาม"
      );
      doc.text(25.5, 95, "หนังสือรับรองฯ กับยื่นแบบรายการภาษีหักที่จ่าย)");

      imgEle.src = require("@/assets/images/checkicon.png");
      increment = 0;
      xposition = 73;
      yposition = 83;

      doc.setFont(normalfont);
      doc.setFontSize(13);

      [
        { text: "(1) ภ.ง.ด.1ก", type: "P.N.D 1a" },
        { text: "(2) ภ.ง.ด.1ก พิเศษ", type: "two" },
        { text: "(3) ภ.ง.ด.2", type: "three" },
        { text: "(4) ภ.ง.ด.3", type: "P.N.D.3" },
      ].forEach((data) => {
        doc.rect(xposition + increment, yposition, 3.5, 3.5);
        doc.text(xposition + 5 + increment, yposition + 3, data.text);
        if (data.type == self.employeeType) {
          doc.addImage(
            imgEle,
            "PNG",
            xposition + increment + 0.5,
            yposition,
            3,
            3
          );
        }
        increment += 30;
      });
      xposition = 73;
      increment = 0;
      [
        { text: "(5) ภ.ง.ด.2ก", type: "five" },
        { text: "(6) ภ.ง.ด.3ก ", type: "six" },
        { text: "(7) ภ.ง.ด.53", type: "seven" },
      ].forEach((data) => {
        doc.rect(xposition + increment, yposition + 7, 3.5, 3.5);
        doc.text(xposition + 5 + increment, yposition + 10, data.text);
        if (data.type == self.employeeType) {
          doc.addImage(
            imgEle,
            "PNG",
            xposition + increment,
            yposition + 7,
            3,
            3
          );
        }
        increment += 30;
      });

      //end--second box;

      //start--third box;
      doc.setLineWidth(0.3);
      doc.roundedRect(16, 96, 178, 132, 1, 1);

      //form-start
      doc.line(16, 106, 194, 106); //xline
      doc.line(16, 216, 194, 216); //xline
      doc.line(110, 96, 110, 216); //yline
      doc.line(140, 96, 140, 222); //yline
      doc.line(170, 96, 170, 222); //yline
      doc.line(140, 222, 194, 222); //short xline;

      doc.setLineWidth(0.15);
      doc.line(164, 106, 164, 222); //decimal yline
      doc.line(188, 106, 188, 222); //decimal yline

      //header
      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(50, 102, "ประเภทเงินได้พึงประเมินที่จ่าย"); //header1
      doc.text(117.5, 100, "วัน เดือน"); //header2
      doc.text(113, 104.5, "หรือปีภาษี ที่จ่าย"); //header2
      doc.text(143.5, 102, "จำนวนเงินที่จ่าย"); //header3
      doc.text(175.5, 100, "ภาษีที่หัก"); //header4
      doc.text(173.5, 104.5, "และนำส่งไว้"); //header4

      //content in header1;
      doc.setFont(normalfont);
      doc.setFontSize(12.5);

      yposition = 110;

      let total_Income = self.total_Income.toString().includes(".")
        ? self.total_Income.toString().split(".")[0]
        : self.total_Income.toString();
      let decimalamount = self.total_Income.toString().includes(".")
        ? self.total_Income.toFixed(2).toString().split(".")[1]
        : "00";

      let personalTaxPerMonth = self.personalTaxPerMonth
        .toString()
        .includes(".")
        ? self.personalTaxPerMonth.toString().split(".")[0]
        : self.personalTaxPerMonth.toString();
      let decimalwithhold = self.personalTaxPerMonth.toString().includes(".")
        ? self.personalTaxPerMonth.toFixed(2).toString().split(".")[1]
        : "00";

      increment = 0;

      let IncomeTypeArr = [{ IncomeType: 1 }];
      let thirdboxArray = [
        {
          text: "1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)",
          x: 18.5,
          dotted: true,
          Income: 1,
        },
        {
          text: "2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)",
          x: 18.5,
          dotted: true,
          Income: 2,
        },
        {
          text: "3. ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40 (3)",
          x: 18.5,
          dotted: true,
          Income: 3,
        },
        {
          text: "4. (ก) ดอกเบี้ย ฯลฯ ตามมาตรา 40 (4) (ก)",
          x: 18.5,
          dotted: true,
          Income: 4,
        },
        {
          text: "(ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40 (4) (ข)",
          x: 22,
          dotted: false,
          Income: 0,
        },
        {
          text: "(1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจากกำไรสุทธิ",
          x: 26,
          dotted: false,
          Income: 0,
        },
        {
          text: "ของกิจการที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้",
          x: 30,
          dotted: false,
          Income: 0,
        },
        {
          text: "(1.1) อัตราร้อยละ 30 ของกำไรสุทธิ",
          x: 30,
          dotted: true,
          Income: 5,
        },
        {
          text: "(1.2) อัตราร้อยละ 25 ของกำไรสุทธิ",
          x: 30,
          dotted: true,
          Income: 6,
        },
        {
          text: "(1.3) อัตราร้อยละ 20 ของกำไรสุทธิ",
          x: 30,
          dotted: true,
          Income: 7,
        },
        {
          text: "(1.4) อัตราอื่นๆ (ระบุ) .........................ของกำไรสุทธิ",
          x: 30,
          dotted: true,
          Income: 15,
        },
        {
          text: "(2) กรณีผู้ได้รับเงินปันผลไม่ได้รับเครดิตภาษีเนื่องจากจ่ายจาก",
          x: 26,
          dotted: false,
          Income: 0,
        },
        {
          text: "(2.1) กำไรสุทธิของกิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคล",
          x: 30,
          dotted: true,
          Income: 8,
        },
        {
          text: "(2.2) เงินปันผลหรือส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำมารวม",
          x: 30,
          dotted: false,
          Income: 0,
        },
        {
          text: "คำนวณเป็นรายได้เพื่อเสียภาษีเงินได้นิติบุคคล",
          x: 34,
          dotted: true,
          Income: 9,
        },
        {
          text: "(2.3) กำไรสุทธิที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี",
          x: 30,
          dotted: false,
          Income: 0,
        },
        {
          text: "ก่อนรอบระยะเวลาบัญชีปีปัจจุบัน",
          x: 34,
          dotted: true,
          Income: 10,
        },
        {
          text: "(2.4) กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method)",
          x: 30,
          dotted: true,
          Income: 11,
        },
        {
          text: "(2.5) อื่นๆ (ระบุ)...............................................................................",
          x: 30,
          dotted: true,
          Income: 12,
        },
        {
          text: "5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่ายตามคำสั่งกรมสรรพากรที่ออกตามมาตรา",
          x: 18.5,
          dotted: false,
          Income: 0,
        },
        {
          text: "3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย",
          x: 18.5,
          dotted: false,
          Income: 0,
        },
        {
          text: "รางวัลในการประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะ",
          x: 18.5,
          dotted: false,
          Income: 0,
        },
        {
          text: "ค่าจ้างทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย ฯลฯ",
          x: 18.5,
          dotted: true,
          Income: 13,
        },
        {
          text: "6. อื่นๆ (ระบุ)....................................................................................................",
          x: 18.5,
          dotted: true,
          Income: 14,
        },
      ];
      thirdboxArray = thirdboxArray.map((v) => ({
        ...v,
        docdate: IncomeTypeArr.find((x) => x.IncomeType == v.Income)
          ? `${day}/${month}/${year}`
          : "",
        frontamount: IncomeTypeArr.find((x) => x.IncomeType == v.Income)
          ? total_Income
          : "",
        decimalamount: IncomeTypeArr.find((x) => x.IncomeType == v.Income)
          ? decimalamount
          : "",
        frontwithhold: IncomeTypeArr.find((x) => x.IncomeType == v.Income)
          ? personalTaxPerMonth
          : "",
        decimalwithhold: IncomeTypeArr.find((x) => x.IncomeType == v.Income)
          ? decimalwithhold
          : "",
      }));
      //thirdboxArray  console.log("ThirdBox", thirdboxArray);
      thirdboxArray.forEach((data) => {
        // doc.text(data.x, yposition + increment, data.text);

        if (data.Income != 12 && data.Income != 14 && data.Income != 15) {
          doc.text(data.x, yposition + increment, data.text);

          if (data.Income == 7) {
            income_4 = data.income_description ? data.income_description : null;
            //console.log(data.income_description);
          }
        } else if (data.Income == 12) {
          font_length = data.income_description
            ? data.income_description.length
            : 0;
          //doc.text(data.x, yposition + increment, data.income_description);

          doc.text(data.x, yposition + increment, "(2.5) อื่นๆ (ระบุ) ");
          doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
          doc.line(
            data.x + 20,
            yposition + increment,
            107,
            yposition + increment
          );
          doc.setFont(boldfont);
          // console.log("Font Length => " + font_length);

          if (font_length > 33) {
            doc.setFontSize(7);
            doc.text(
              data.x + 21,
              yposition + increment - 1,
              data.income_description ? data.income_description : ""
            );
          } else {
            doc.text(
              data.x + 21,
              yposition + increment - 1,
              data.income_description ? data.income_description : ""
            );
          }

          //doc.text(data.x + 21, yposition + increment - 1, data.income_description ? data.income_description : "" );
          doc.setFont(normalfont);
          doc.setLineDash([]);
        } else if (data.Income == 14) {
          //doc.text(data.x, yposition + increment, data.income_description);
          font_length = data.income_description
            ? data.income_description.length
            : 0;

          doc.text(data.x, yposition + increment, "6. อื่นๆ (ระบุ) ");
          doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
          doc.line(
            data.x + 17,
            yposition + increment,
            107,
            yposition + increment
          );
          doc.setFont(boldfont);

          if (font_length > 42) {
            doc.setFontSize(7);
            doc.text(
              data.x + 18,
              yposition + increment - 1,
              data.income_description ? data.income_description : ""
            );
          } else {
            doc.text(
              data.x + 18,
              yposition + increment - 1,
              data.income_description ? data.income_description : ""
            );
          }

          //doc.text(data.x + 18, yposition + increment - 1, data.income_description ? data.income_description : "" );
          doc.setFont(normalfont);
          doc.setLineDash([]);
        } else if (data.Income == 15) {
          font_length = income_4 ? income_4.length : 0;

          doc.text(data.x, yposition + increment, "(1.4) อัตราอื่นๆ (ระบุ) ");
          doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
          doc.line(
            data.x + 27,
            yposition + increment,
            86,
            yposition + increment
          );
          doc.setFont(boldfont);

          if (font_length > 17) {
            doc.setFontSize(7);
            doc.text(
              data.x + 28,
              yposition + increment - 1,
              income_4 ? income_4 : ""
            );
          } else {
            doc.text(
              data.x + 28,
              yposition + increment - 1,
              income_4 ? income_4 : ""
            );
          }
          //doc.text(data.x + 28, yposition + increment - 1, this.income_4 ? this.income_4 : "" );
          doc.setFont(normalfont);
          doc.setFontSize(12.3);
          doc.text(87, yposition + increment, "ของกำไรสุทธิ");
          doc.setLineDash([]);
        }

        if (data.dotted == true) {
          doc.setLineDash([0.5, 0.5, 0.5, 0.5], 0);
          doc.line(110, yposition + increment, 194, yposition + increment);
          doc.setFont(boldfont);
          doc.setFontSize(13.5);
          doc.text(117, yposition + increment - 1, data.docdate);
          doc.text(
            this.digitPositionXforPDF(162, data.frontamount),
            yposition + increment - 1,
            data.frontamount
          );
          doc.text(
            165,
            yposition + increment - 1,
            data.decimalamount ? `.${data.decimalamount}` : ""
          );
          doc.text(
            this.digitPositionXforPDF(187, data.frontwithhold),
            yposition + increment - 1,
            data.frontwithhold
          );
          doc.text(
            189,
            yposition + increment - 1,
            data.decimalwithhold ? `.${data.decimalwithhold}` : ""
          );
          doc.setFont(normalfont);
          doc.setFontSize(12.5);
          doc.setLineDash([]);
        }

        increment += 4.5;
      });

      doc.setFont(boldfont);
      doc.setFontSize(13.5);
      let totalfrontamount = self.total_Income.toString().includes(".")
        ? self.total_Income.toString().split(".")[0]
        : self.total_Income.toString();
      let totaldecimalamount = self.total_Income.toString().includes(".")
        ? self.total_Income.toFixed(2).toString().split(".")[1]
        : "00";
      let totalfrontwht = self.personalTaxPerMonth.toString().includes(".")
        ? self.personalTaxPerMonth.toString().split(".")[0]
        : self.personalTaxPerMonth.toString();
      let totaldecimalwht = self.personalTaxPerMonth.toString().includes(".")
        ? self.personalTaxPerMonth.toFixed(2).toString().split(".")[1]
        : "00";

      doc.text(
        this.digitPositionXforPDF(162, totalfrontamount),
        220,
        totalfrontamount
      );
      doc.text(165, 220, totaldecimalamount ? `.${totaldecimalamount}` : "");
      doc.text(
        this.digitPositionXforPDF(187, totalfrontwht),
        220,
        totalfrontwht
      );
      doc.text(189, 220, totaldecimalwht ? `.${totaldecimalwht}` : "");

      doc.setFont(boldfont);
      doc.setFontSize(12.5);
      doc.text(98, 220.5, "รวมเงินที่จ่ายและภาษีที่หักนำส่ง");
      doc.text(18.5, 227, "รวมเงินภาษีที่หักนำส่ง");
      doc.setFont(normalfont);
      doc.text(48.5, 227, "(ตัวอักษร)");
      doc.text(70.5, 227, self.personalTaxPerMonthInThai);
      //form-end
      //end--third box;

      //start--fourth box;
      doc.setLineWidth(0.3);
      doc.roundedRect(16, 228.5, 178, 7, 1, 1);
      doc.setFont(boldfont);
      doc.setFontSize(12.5);
      doc.text(18.5, 234.5, "เงินที่จ่ายเข้า");
      doc.setFont(normalfont);
      doc.setFontSize(11.5);
      yposition = 234.5;
      doc.text(
        37,
        yposition,
        "กบข./กสจ./กองทุนสงเคราะห์ครูโรงเรียนเอกชน.................บาท"
      );
      doc.text(105, yposition, "กองทุนประกันสังคม........................บาท");
      doc.text(150, yposition, "กองทุนสำรองเลี้ยงชีพ......................บาท");

      doc.setFontSize(11.5);
      doc.setFont(boldfont);
      doc.text(
        126,
        yposition - 1,
        self.total_Social_Security
          ? parseFloat(self.total_Social_Security).toFixed(2)
          : ""
      );
      doc.text(
        174,
        yposition - 1,
        self.total_Provident_Fund
          ? parseFloat(self.total_Provident_Fund).toFixed(2)
          : ""
      );
      //end--fourth box;

      //start--fifth box;
      doc.setLineWidth(0.3);
      doc.roundedRect(16, 236, 178, 8, 1, 1);
      doc.setFont(boldfont);
      doc.setFontSize(12.5);
      doc.text(18.5, 241, "ผู้ที่จ่ายเงิน");

      doc.setFontSize(11.5);
      doc.setFont(normalfont);
      increment = 0;
      xposition = 40;
      yposition = 238;
      [
        { text: "(1) หัก ณ ที่จ่าย ", type: 1 },
        { text: "(2) ออกให้ตลอดไป ", type: 2 },
        { text: "(3) ออกให้ครั้งเดียว ", type: 3 },
        {
          text: "(4) อื่นๆ (ระบุ).................................................................",
          type: 4,
        },
      ].forEach((data) => {
        doc.rect(xposition + increment, yposition, 3.5, 3.5);
        doc.text(xposition + 5 + increment, yposition + 3, data.text);
        if (data.type == 1) {
          doc.addImage(
            imgEle,
            "PNG",
            xposition + increment + 0.5,
            yposition,
            3,
            3
          );
        }
        increment += 26;
      });
      doc.setFont(boldfont);
      doc.setFontSize(11.5);
      doc.text(142, yposition + 2, "");

      //end--fifth box;

      //start--sixth box A;
      doc.setLineWidth(0.3);
      doc.roundedRect(16, 244.5, 55, 32, 1, 1);
      //end--sixth box A;
      doc.setFontSize(12.5);
      doc.setFont(boldfont);
      doc.text(18.5, 249, "คำเตือน");
      xposition = 30;
      yposition = 249;
      increment = 0;
      doc.setFontSize(11.8);
      doc.setFont(normalfont);
      [
        "ผู้มีหน้าที่ออกหนังสือรับรองการ",
        "หักภาษี ณ ที่จ่าย ฝ่าฝืนไม่ปฏิบัติ",
        "ตามมาตรา 50 ทวิ แห่งประมวล",
        "รัษฎากร ต้องรับโทษทางอาญา",
        "ตามมาตรา 35 แห่งประมวล",
        "รัษฎากร",
      ].forEach((text) => {
        doc.text(xposition, yposition + increment, text);
        increment += 4.5;
      });
      //start--sixth box B;
      doc.roundedRect(71.5, 244.5, 122.5, 32, 1, 1);
      doc.setFont(normalfont);
      doc.setFontSize(11.5);
      doc.text(
        80,
        249,
        "ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้นถูกต้องตรงกับความจริงทุกประการ"
      );
      doc.text(
        84,
        265,
        "ลงชื่อ ...............................................................................................ผู้จ่ายเงิน"
      );
      doc.text(
        100,
        270,
        ".................../.................../..................."
      );
      doc.text(100, 275, "(วัน เดือน ปี ที่ออกหนังสือรับรองฯ)");
      doc.setLineWidth(0.1);
      doc.circle(180, 260, 8);
      doc.setFontSize(11);
      doc.setFont(normalfont);
      [
        { text: "ประทับตรา", x: 174.5, y: 257 },
        { text: "นิติบุคคล", x: 176, y: 261 },
        { text: "(ถ้ามี)", x: 178, y: 265 },
      ].forEach((v) => {
        doc.text(v.x, v.y, v.text);
      });

      doc.setFont(boldfont);
      doc.setFontSize(11.5);
      doc.text(106, 269, day.toString());
      doc.text(118, 269, month.toString());
      doc.text(130, 269, year.toString());

      //end--sixth box B;

      //end--1st border

      doc.setFont(boldfont);
      doc.setFontSize(12);
      doc.text(11, 284.5, "หมายเหตุ");

      doc.setFont(normalfont);
      doc.text(25, 284.5, "เลขประจำตัวผู้เสียภาษีอากร (13 หลัก)* หมายถึง");

      doc.text(
        80,
        284.5,
        "1. กรณีบุคคลธรรมดาไทย ให้ใช้เลขประจำตัวประชาชนของกรมการปกครอง"
      );
      doc.text(
        80,
        288.5,
        "2. กรณีนิติบุคคล ให้ใช้เลขทะเบียนนิติบุคคลของกรมพัฒนาธุรกิจการค้า"
      );
      doc.text(
        80,
        292.5,
        "3. กรณีอื่นๆ นอกหนือจาก 1. และ 2. ให้ใช้เลขประจำตัวผู้เสียภาษีอากร (13 หลัก) ของกรมสรรพากร"
      );
      return doc.save("Withholding Tax Certificate.pdf");
    },
    digitPositionXforPDF(x, v) {
      let count = v ? v.length : 0;
      let singlewidth = 1.7;
      let positionx = x - count * singlewidth;
      return positionx;
    },
    formatDateEdit(value) {
      let output = "-";
      let temp = new Date(value + "Z");
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear() + 543;
        output = `${day}-${month}-${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    formatDate(value) {
      let output = "-";
      let temp = new Date(value + "Z");
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear() - 543;
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    formatDatefornew() {
      let output = "-";
      let temp = new Date();
      try {
        let day = 1;
        let month = 1;
        let year = temp.getFullYear() + 543;
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    back() {
      this.$router.push(`/annualreport`).catch(() => {});
    },
    closeNewCertiDialog() {
      let self = this;
      self.NewCertificateDialog = false;
      self.isitsave = true;
      self.addyears = "";
      self.addbook_No = "";
      self.adddate = "";
      self.addstarting_No = "";
      self.tempEmployeeList = [];
      self.EmployeeList = [];
      self.getstart();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    openedit(v) {
      try {
        let self = this;
        self.EditCertificateDialog = true;
        self.LoadingDialog = true;
        let tempID = v;
        let temp = {
          wT_Sub_ID: tempID,
        };
        axios
          .post(`${self.url}WithholdTax/GetExclusiveWithholdTaxByID`, temp)
          .then(function (response) {
            let theList = response.data.data;
            self.viewCompanyName = theList[0].companyName;
            self.viewCompanyAddress = theList[0].company_Address;
            self.viewCompanyTax = theList[0].company_Tax_ID;
            let ah = new Date();
            ah.setYear(parseInt(theList[0].year) + 543);
            self.viewyears = ah;
            if (theList[0].book_No != 0) {
              self.viewbook_No = theList[0].book_No;
            }
            if (theList[0].starting_No != 0) {
              self.viewstarting_No = theList[0].starting_No;
            }
            let temp = new Date(theList[0].wT_Dates);
            let day = temp.getDate();
            let month = temp.getMonth() + 1;
            let year = parseInt(temp.getFullYear()) + 543;
            let temps = `${month}/${day}/${year}`;
            self.viewdate = new Date(temps);
            if (theList[0].starting_No == 0) {
              self.SecEmployeeList = theList.map((obj) => ({
                ...obj,
                starting_No: "",
              }));
            } else {
              self.SecEmployeeList = theList;
            }
            self.LoadingDialog = false;
          });
      } catch (error) {
        alert(error);
        self.LoadingDialog = false;
      }
    },
    getEmpOfYear() {
      let self = this;
      self.LoadingDialog = true;
      self.tempEmployeeList = [];
      let thisYear = "";
      if (self.addyears.getFullYear()) {
        thisYear = parseInt(self.addyears.getFullYear()) - 543;
      } else {
        self.addyears = new Date();
        thisYear = parseInt(new Date().getFullYear()) - 543;
      }
      let tempads = {
        year: thisYear.toString(),
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}WithholdTax/GetYearlyPaid`, tempads)
        .then(function (response) {
          self.tempEmployeeList = response.data.data;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    async getstart() {
      try {
        let self = this;
        self.CertificateList = [];
        self.LoadingDialog = true;
        let thisYear = "";
        if (self.date.getFullYear()) {
          thisYear = self.date.getFullYear();
        } else {
          self.date = new Date();
          thisYear = new Date().getFullYear();
        }

        await axios
          .get(
            `${self.url}WithholdTax/GetWithholdTaxByCompanyID?Company_ID=${store.state.companyID}&Year=${thisYear}`
          )
          .then(function (response) {
            let list = response.data.data;
            let firstrow = 0;
            for (let i = 0; i < list.length; i++) {
              if (firstrow == 0) {
                self.CertificateList.push({ ...list[i] });
              } else if (firstrow != list[i].wT_Sub_ID) {
                self.CertificateList.push({ ...list[i] });
              }
              firstrow = list[i].wT_Sub_ID;
            }
            if (self.CertificateList.length != 0) {
              self.tot = self.CertificateList.length;
              self.showtablerow = true;
            } else {
              self.showtablerow = false;
            }
            self.LoadingDialog = false;
          });
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },
    async GetCompanyInfo() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let tempID = store.state.companyID;
        let temp = {
          company_ID: tempID,
        };
        await axios
          .post(`${self.url}Company/GetCompanyID`, temp)
          .then(function (response) {
            self.companyList = response.data.data;
            self.NameOfCompany = self.companyList.companyName;
            self.AddressOfCompany = self.companyList.company_Address;
            self.TaxIDOfCompany = self.companyList.company_Tax_ID;
            self.LoadingDialog = false;
          });
      } catch (error) {
        alert(error);
      }
    },

    saveNewCertificate() {
      let self = this;
      self.LoadingDialog = true;

      if (
        self.addyears != "" &&
        self.addyears != null &&
        self.adddate != "" &&
        self.adddate != null
      ) {
        if (self.TaxIDOfCompany != null && self.TaxIDOfCompany != "") {
          self.isitsave = false;
          let book = 0;
          let start1 = 0;
          if (self.addbook_No == null || self.addbook_No == "") {
            book = 0;
          } else {
            book = parseInt(self.addbook_No);
          }
          if (self.addstarting_No == null || self.addstarting_No == "") {
            start1 = 0;
          } else {
            start1 = self.addstarting_No;
          }
          let start = parseInt(start1);
          let temparr = [];
          let thisYear = "";
          if (self.addyears.getFullYear()) {
            thisYear = parseInt(self.addyears.getFullYear()) - 543;
          } else {
            self.addyears = new Date();
            thisYear = parseInt(new Date().getFullYear()) - 543;
          }
          for (let i = 0; i < self.tempEmployeeList.length; i++) {
            if (self.addstarting_No != null && self.addstarting_No != "") {
              let Lists = {
                ...self.tempEmployeeList[i],
                starting_No: start,
              };
              self.EmployeeList.push(Lists);
            } else {
              let Lists = {
                ...self.tempEmployeeList[i],
                starting_No: "",
              };
              self.EmployeeList.push(Lists);
            }
            temparr.push({
              year: thisYear.toString(),
              wT_Dates: self.formatDate(self.adddate),
              book_No: book,
              starting_No: start,
              employee_ID: self.tempEmployeeList[i].employee_ID,
              company_ID: store.state.companyID,
              user_ID: store.state.userid,
            });
            start++;
          }
          axios
            .post(`${self.url}WithholdTax/AddWithholdTax`, temparr)
            .then(function (response) {
              if (response.data.status == 0) {
                self.LoadingDialog = false;
              }
            })
            .catch(function (error) {
              alert(error);
              self.LoadingDialog = false;
            });
        } else {
          alert("Please add Company Iax ID in Company Information");
          self.LoadingDialog = false;
        }
      } else {
        alert(this.$t("FillRequiredFields"));
        self.LoadingDialog = false;
      }
    },
  },
};
</script>
<style scoped>

.personaltax {
  font-family: "angsau", sans-serif;
  font-size: 10px;
}
.tb {
  border-collapse: collapse;
  width: 100%;
}
.tb th {
  padding: 2px;
  border: solid 1px #000;
}
.tb th {
  font-family: "angsau", sans-serif;
  font-weight: 900;
  font-size: small;
}
.tb td {
  font-family: "angsau", sans-serif;
  font-size: 11px;
  border-bottom: dotted #000;
  padding: 2px;
  border: 1px solid #000;
}

input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  transform: scale(1.5);
  padding: 10px;
  -webkit-box-shadow: 0px 0px 0px 1px black;
  -moz-box-shadow: 0px 0px 0px 1px black;
  box-shadow: 0px 0px 0px 1px black;
}
.emptype {
  font-family: "angsau", sans-serif;
  font-weight: 900;
  font-size: small;
}
.number {
  font-family: "angsau", sans-serif;
  font-weight: lighter;
  font-size: smaller;
  margin-right: 0.5rem;
  margin-left: 0.3rem;
}
.pdf-item {
  margin: 1rem 2rem 2rem 2rem;
}
.bookno {
  margin-left: auto;
  margin-right: 0.2rem;
  margin-top: -0.3rem;
}
.smallheader {
  margin-right: 0.5rem;
  font-family: "angsau", sans-serif;
  font-weight: 900;
  font-size: x-small;
}
.ในแบบ {
  font-family: "angsau", sans-serif;
  font-weight: lighter;
  font-size: small;
  margin-left: 5px;
  margin-right: 1rem;
  margin-top: 0.5rem;
}
.smallertxt {
  font-family: "angsau", sans-serif;
  font-size: xx-small;
  font-style: italic;
}
.header {
  font-family: "angsau", sans-serif;
  font-weight: 900;
  font-size: medium;
}
.sheader {
  font-family: "angsau", sans-serif;
  font-size: smaller;
}
.label {
  font-family: "angsau", sans-serif;
  font-size: x-small;
}
.ผู้มีหน้าที่หักภาษีณที่จ่าย,
.ชอ่ื {
  font-family: "angsau", sans-serif;
  font-size: small;
  font-weight: 900;
}
.digit {
  font-family: "angsau", sans-serif;
  font-size: smaller;
}
.เลขประจำตัวผู้เสียภาษีอากร {
  font-family: "angsau", sans-serif;
  font-size: smaller;
  font-weight: 300;
  margin-right: 1.3rem;
}
.dotted-input {
  border: 0px;
  border-bottom: 2px dotted #000;
  font-size: x-small;
  font-family: "angsau", sans-serif;
}
.textbox {
  border: 2px solid #000;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
>>> ::-webkit-scrollbar-thumb {
  background: #f99d20;
  border-radius: 1px;
  width: 300px;
}
>>> ::-webkit-scrollbar {
  height: 8px;
}
>>> .mx-input {
  height: 42px;
  border-radius: 5px;
  width: 150px;
  border: 1px solid #808080ab !important;
}
.backGG {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}
.ddl {
  float: right;
}
.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}

.print-break-page {
  page-break-after: always;
}
.ggg {
  margin-left: 30px;
}
@media print {
  body {
    overflow: auto;
    height: auto;
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
}
@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }
  .ddl {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
  .ddl {
    float: right;
  }
}
@media (max-width: 860px) {
  .ggg {
    margin-left: 0px;
  }
}
@media (min-width: 960px) {
  .lefz {
    margin-left: 20px;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .ggg {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1900px) {
  .movelf {
    margin-right: 2rem;
  }
}
@media (max-width: 389px) {
  .venti {
    padding-left: 15px;
  }
}
</style>