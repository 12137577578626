<template>
  <div>
    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <div style="margin-right: 0.5%">
        <v-row>
          <v-form v-show="showback">
            <v-btn icon router color="white" @click="clearstoarge()" class="mt-5 ml-1">
              <img src="@/assets/images/back button.png" />
            </v-btn>
          </v-form>
          <p style="font-size: 26px;color: #43425d;margin-top: 20px;margin-left: 9px;">
            {{$route.name != "PersonalInformation" ? $t("empinformation") : $t("personalinformation")}}
          </p>
          <v-spacer></v-spacer>
          <div class="d-flex flex-row-reverse showname">
            <v-btn :disabled="disablenxt" v-show="shownavi" @click="nextemp"
              class="white--text text-capitalize mr-4 mt-7" color="#F99D20">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <div v-show="shownavi" class="pl-6 pr-2 mt-5 d-flex mr-2">
              <v-avatar v-show="showavatar" width="50" height="50" style="margin-left: auto; margin-right: 10px">
                <img :src="$store.state.personalimg != 'null' ? $store.state.personalimg : 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png'" style="border: 3px solid #f89d1f" />
              </v-avatar>
              <div class="showmyname">
                <p style="margin-top: -2px; margin-bottom: 0px">
                  {{ EmpName }}
                </p>
                <p style="margin-top: 5px; margin-left: 2px">{{ EmpCode }}</p>
              </div>
            </div>
            <v-btn :disabled="disableprev" @click="prevemp" v-show="shownavi" class="white--text mt-7 mr-0"
              color="#F99D20">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>
      <v-tabs class="mt-6" grey--text background-color="#F0F8FC" slider-color="#F99D20" v-model="activeTab" show-arrows>
        <v-tab class="text-capitalize tablen" style="border-radius: 10px 10px 0px 5px;margin-right: 3px;" v-for="(tab, index) in tabs" :key="tab.id" :to="tab.route" :style="{ fontSize: tabfontsize + 'px' }"
          v-show="tab.showthis" :disabled="tabs[index].lockThis" exact>{{ translate(tab.name) }}</v-tab>
      </v-tabs>
      <v-card>
        <router-view></router-view>
      </v-card>
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
export default {
  props: ["id", "nid", "editid"],
  data: () => ({
    activeTab: null,
    shownavi: false,
    url: enurl.apiUrl,
    activeeditsalary: false,
    activeprofilesalary: false,
    tabs: [],
    showavatar: true,
    personalInfo: false,
    salary: false,
    incometax: false,
    socialsecurity: false,
    Personallist: [],
    providentfund: false,
    tabfontsize: 15,
    healthInfo: false,
    benefit: false,
    guarantor: false,
    family: false,
    timeoff: false,
    training: false,
    showtab: true,
    showback: false,
    showedit: false,
    forpersonal: "Personal Profile",
    EmpCode: "",
    EmpName: "",
    disablenxt: false,
    disableprev: false,
    locki: "",
    newone: false,
  }),

  methods: {
    whatthetab() {
      if (this.locki == "1st")
      {
        if (this.newone == true)
        {
          for (let i = 0; i < this.tabs.length; i++)
          {
            if (i == 0 || i == 1)
            {
              this.tabs[i].lockThis = false;
            }
            else
            {
              this.tabs[i].lockThis = true;
            }
          }
        }
        return 1;
      }
      else if (this.locki == "2nd")
      {
        if (this.newone == true)
        {
          for (let i = 0; i < this.tabs.length; i++)
          {
            this.tabs[i].lockThis = false; // Set each element to true
          }
        }
        return 2;
      }
      else
      {
        return 3;
      }
    },

    clearstoarge() {
      // localStorage.clear();
      localStorage.removeItem("gf");
      localStorage.removeItem("gg");
      localStorage.removeItem("mydisable");
      localStorage.removeItem("showforpersonalprofile");
      localStorage.removeItem("showforeditpersonal");
      localStorage.removeItem("showforeditpersonalprofile");
      localStorage.removeItem("localprofile");
      localStorage.removeItem("empcode");
      localStorage.removeItem("reportinfo");
      localStorage.removeItem("ww");
      localStorage.removeItem("ii");
      localStorage.removeItem("localworkpermitUrl");
      localStorage.removeItem("workpermitname");
      localStorage.removeItem("localworkeditpermitUrl");
      localStorage.removeItem("editworkpermitname");
      localStorage.removeItem("LocalPersonalidUrl");
      localStorage.removeItem("filename");
      localStorage.removeItem("LocaleditPersonalidUrl");
      localStorage.removeItem("get_date_of_today_return");
      localStorage.removeItem("get_date_of_today_return1");
      localStorage.removeItem("editfilename");
      localStorage.removeItem("localotherinfoUrl");
      localStorage.removeItem("othername");
      localStorage.removeItem("localeditotherinfoUrl");
      localStorage.removeItem("editothername");
      localStorage.removeItem("localPassportUrl");
      localStorage.removeItem("passportname");
      localStorage.removeItem("localeditPassportUrl");
      localStorage.removeItem("editpassportname");
      localStorage.removeItem("UserEditUserGp");
      localStorage.removeItem("TempUserGroup");
      localStorage.removeItem("UserEditRole");
      localStorage.removeItem("TempRole");
      localStorage.removeItem("UserEditRole");
      localStorage.removeItem("UserEditPer");
      localStorage.removeItem("locktab");
      this.$router.push(`/PersonnelManagement`).catch(() => { });
    },

    ShowAvatar() {
      if (
        this.$route.name == "PersonalInformation" ||
        this.$route.name == "NewPersonalInformation" ||
        this.$route.name == "NewSalary" ||
        this.$route.name == "NewPersonalIncomeTax" ||
        this.$route.name == "NewSocialSecurity" ||
        this.$route.name == "NewProvidentFund" ||
        this.$route.name == "NewHealthInformation" ||
        this.$route.name == "NewPersonalBenefit" ||
        this.$route.name == "NewGuarantor" ||
        this.$route.name == "NewFamily" ||
        this.$route.name == "Salary" ||
        this.$route.name == "PersonalIncomeTax" ||
        this.$route.name == "SocialSecurity" ||
        this.$route.name == "ProvidentFund" ||
        this.$route.name == "HealthInformation" ||
        this.$route.name == "PersonalBenefit" ||
        this.$route.name == "Guarantor" ||
        this.$route.name == "Family" ||
        this.$route.name == "Account"
      )
      {
        this.showavatar = false;
      }
      else
      {
        this.showavatar = true;
      }
    },

    getpersonal() {
      let self = this;
      let myempID = this.$route.params.id;
      // self.loadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetPersonalInfowithlist`, tempp)
      .then(function (response) {
        self.Personallist = response.data.data;
        for (let i = 0; i < self.Personallist.length; i++)
        {
          if (myempID == self.Personallist[i].employee_ID)
          {
            self.EmpCode = self.Personallist[i].employee_Code;
            self.EmpID = self.Personallist[i].employee_ID;
            self.EmpName = self.Personallist[i].fullName;
            //self.profile=self.Personallist[i].image;
            sessionStorage.setItem("personalimg", self.Personallist[i].image);
            self.profile = sessionStorage.getItem("personalimg");
            if (self.EmpID == self.Personallist[0].employee_ID)
            {
              self.disableprev = true;
            }
            else
            {
              self.disableprev = false;
            }
            let mynum = self.Personallist.length - 1;
            if (self.EmpID == self.Personallist[mynum].employee_ID)
            {
              self.disablenxt = true;
            }
            else
            {
              self.disablenxt = false;
            }
          }
        }
      });
    },
    nextemp() {
      let self = this;
      let pushtrue = false;
      for (let i = 0; i < self.Personallist.length; i++)
      {
        if (pushtrue == false)
        {
          if (self.EmpID == self.Personallist[i].employee_ID)
          {
            if (i != self.Personallist.length)
            {
              let myempID = self.Personallist[i + 1].employee_ID;
              self.pushroutes(myempID);
              localStorage.clear();
              // self.activeTab = `/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`;
              //  this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`);
              pushtrue = true;
              self.disablenxt = false;
              self.disableprev = false;
            }
            else
            {
              self.disablenxt = true;
              self.disableprev = false;
            }
          }
        }
      }
    },
    prevemp() {
      let self = this;
      let pushtrue = false;
      for (let i = 0; i < self.Personallist.length; i++)
      {
        if (pushtrue == false)
        {
          if (self.EmpID == self.Personallist[i].employee_ID)
          {
            if (self.EmpID != self.Personallist[0].employee_ID)
            {
              let myempID = self.Personallist[i - 1].employee_ID;
              self.pushroutes(myempID);
              localStorage.clear();
              //  self.activeTab = `/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`;
              //  this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`);
              pushtrue = true;
              self.disableprev = false;
              self.disablenxt = false;
            }
            else
            {
              let myempID = self.Personallist[i].employee_ID;
              self.pushroutes(myempID);
              localStorage.clear();
              //  self.activeTab = `/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`;
              //  this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`);
              pushtrue = true;
              self.disableprev = true;
              self.disablenxt = false;
            }
          }
        }
      }
    },
    async GetSalary() {
      let self = this;
      if (this.$route.params.id != "undefined")
      {
        let tempp = {
          employee_ID: parseInt(this.$route.params.id),
          company_ID: store.state.companyID,
        };
        await axios.post(`${self.url}Salary/GetSalaryInfo`, tempp)
        .then(function (response)
        {
          let SalaryList = response.data.data;
          if (SalaryList.length != 0)
          {
            for (let i = 0; i < self.tabs.length; i++)
            {
              self.tabs[i].lockThis = false; // Set each element to true
            }
          }
          else
          {
            for (let i = 0; i < self.tabs.length; i++)
            {
              if (i > 1)
              {
                self.tabs[i].lockThis = true; // Set each element to true
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },

    pushroutes(myempID) {
      let self = this;
      if (self.$route.name == "EditPersonalInformation")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}`).catch(() => { });
      }
      if (self.$route.name == "EditSalary")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/Salary`).catch(() => { });
      }
      if (self.$route.name == "EditPersonalIncomeTax")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/PersonalIncomeTax`).catch(() => { });
      }
      if (self.$route.name == "EditSocialSecurity")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/SocialSecurity`).catch(() => { });
      }
      if (self.$route.name == "EditProvidentFund")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/ProvidentFund`).catch(() => { });
      }
      if (self.$route.name == "EditHealthInformation")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/HealthInformation`).catch(() => { });
      }
      if (self.$route.name == "EditPersonalBenefit")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/PersonalBenefit`).catch(() => { });
      }
      if (self.$route.name == "EditGuarantor")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/Guarantor`).catch(() => { });
      }
      if (self.$route.name == "EditFamily")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/Family`).catch(() => { });
      }
      if (self.$route.name == "EditAccount")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${myempID}/${this.editid}/Account`).catch(() => { });
      }
    },
    method1: function () {
      if (
        this.$route.name == "NewPersonalInformation" ||
        this.$route.name == "EditPersonalInformation" ||
        this.$route.name == "NewSalary" ||
        this.$route.name == "NewPersonalIncomeTax" ||
        this.$route.name == "NewSocialSecurity" ||
        this.$route.name == "NewProvidentFund" ||
        this.$route.name == "NewHealthInformation" ||
        this.$route.name == "NewPersonalBenefit" ||
        this.$route.name == "NewGuarantor" ||
        this.$route.name == "NewFamily" ||
        this.$route.name == "NewAccount"
      )
      {
        this.showtab = false;
        this.showback = true;
      }
      else
      {
        this.showtab = true;
        this.showback = false;
      }
    },
  },
  computed: {
    translate() {
      return function (salut) {
        let value = salut;
        return value == "Personal Information"
          ? this.$t("personalinformation")
          : value == "Salary"
            ? this.$t("salary")
            : value == "IncomeTax"
              ? this.$t("IncomeTax")
              : value == "Social Security"
                ? this.$t("social_security")
                : value == "Provident Fund"
                  ? this.$t("provident_fund")
                  : value == "Health Information"
                    ? this.$t("healthinfo")
                    : value == "Benefit"
                      ? this.$t("benefit")
                      : value == "Guarantor"
                        ? this.$t("guarantor")
                        : value == "Family"
                          ? this.$t("family")
                          : this.$t("Account");
      };
    },
    thetabcheck() {
      return this.whatthetab();
    },
  },
  watch: {
    locki() {
      this.whatthetab()
    }
  },
  created: function () {
    this.ShowAvatar();
    this.method1();
    this.getpersonal();
    this.locki = localStorage.getItem('locktab');
    this.timerInterval = setInterval(() => {
      this.locki = localStorage.getItem('locktab')
    }, 100);
    if (
      this.$route.name == "PersonalInformation" ||
      this.$route.name == "Salary" ||
      this.$route.name == "PersonalIncomeTax" ||
      this.$route.name == "SocialSecurity" ||
      this.$route.name == "ProvidentFund" ||
      this.$route.name == "HealthInformation" ||
      this.$route.name == "PersonalBenefit" ||
      this.$route.name == "Guarantor" ||
      this.$route.name == "Family"
    )
    {
      this.shownavi = false;
      if (this.$route.name == "Salary")
      {
        this.activeTab = `/selfservice/PersonalProfile/${this.id}/Salary`;
        this.activeprofilesalary = true;
      }
      else
      {
        this.activeTab = `/selfservice/PersonalProfile/${this.id}`;
        this.activeprofilesalary = false;
      }
      this.tabs.push(
        {
          id: 1,
          name: "Personal Information",
          route: `/selfservice/PersonalProfile/${this.id}`,
          showthis: false,
          lockThis: false
        },
        {
          id: 2,
          name: "Salary",
          route: `/selfservice/PersonalProfile/${this.id}/Salary`,
          showthis: false,
          lockThis: false
        },
        {
          id: 3,
          name: "IncomeTax",
          route: `/selfservice/PersonalProfile/${this.id}/PersonalIncomeTax`,
          showthis: false,
          lockThis: false
        },
        {
          id: 4,
          name: "Social Security",
          route: `/selfservice/PersonalProfile/${this.id}/SocialSecurity`,
          showthis: false,
          lockThis: false
        },
        {
          id: 5,
          name: "Provident Fund",
          route: `/selfservice/PersonalProfile/${this.id}/ProvidentFund`,
          showthis: false,
          lockThis: false
        },
        {
          id: 6,
          name: "Health Information",
          route: `/selfservice/PersonalProfile/${this.id}/HealthInformation`,
          showthis: false,
          lockThis: false
        },
        // {
        //   id: 7,
        //   name: "Benefit",
        //   route: `/selfservice/PersonalProfile/${this.id}/PersonalBenefit`,
        //   showthis: false,
        //   lockThis: false
        // },
        {
          id: 8,
          name: "Guarantor",
          route: `/selfservice/PersonalProfile/${this.id}/Guarantor`,
          showthis: false,
          lockThis: false
        },
        {
          id: 9,
          name: "Family",
          route: `/selfservice/PersonalProfile/${this.id}/Family`,
          showthis: false,
          lockThis: false
        }
      );
      this.thetabcheck;
      let perid = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin")
      {
        for (let j = 0; j < this.tabs.length; j++)
        {
          this.tabs[j].showthis = true;
        }
      }
      if (roleID == "HR")
      {
        for (let j = 0; j < 9; j++)
        {
          this.tabs[j].showthis = true;
        }
      }
      if (perid.some((e) => e == 123))
      {
        this.tabs[0].showthis = true;
      }
      // Salary
      if (perid.some((e) => e == 126))
      {
        this.tabs[1].showthis = true;
      }
      if (perid.some((e) => e == 129))
      {
        this.tabs[2].showthis = true;
      }
      if (perid.some((e) => e == 132))
      {
        this.tabs[3].showthis = true;
      }
      if (perid.some((e) => e == 136))
      {
        this.tabs[4].showthis = true;
      }
      if (perid.some((e) => e == 139))
      {
        this.tabs[5].showthis = true;
      }
      if (perid.some((e) => e == 143))
      {
        this.tabs[6].showthis = true;
      }
      if (perid.some((e) => e == 146))
      {
        this.tabs[7].showthis = true;
      }
      if (perid.some((e) => e == 149))
      {
        this.tabs[8].showthis = true;
      }
      // if (perid.some((e) => e == 191)) {
      //   this.tabs[9].showthis = true;
      // }
    }
    else if (
      this.$route.name == "NewPersonalInformation" ||
      this.$route.name == "NewSalary" ||
      this.$route.name == "NewPersonalIncomeTax" ||
      this.$route.name == "NewSocialSecurity" ||
      this.$route.name == "NewProvidentFund" ||
      this.$route.name == "NewHealthInformation" ||
      this.$route.name == "NewPersonalBenefit" ||
      this.$route.name == "NewGuarantor" ||
      this.$route.name == "NewFamily" ||
      this.$route.name == "NewAccount"
    )
    {
      this.shownavi = false;
      this.newone = true;
      this.activeTab = `/PersonnelManagement/NewEmployee/${this.nid}`;
      this.tabs.push(
        {
          id: 1,
          name: "Personal Information",
          route: `/PersonnelManagement/NewEmployee/${this.nid}`,
          showthis: false,
          lockThis: false
        },
        {
          id: 2,
          name: "Salary",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/Salary`,
          showthis: false,
          lockThis: true
        },
        {
          id: 3,
          name: "IncomeTax",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/PersonalIncomeTax`,
          showthis: false,
          lockThis: true
        },
        {
          id: 4,
          name: "Social Security",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/SocialSecurity`,
          showthis: false,
          lockThis: true
        },
        {
          id: 5,
          name: "Provident Fund",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/ProvidentFund`,
          showthis: false,
          lockThis: true
        },
        {
          id: 6,
          name: "Health Information",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/HealthInformation`,
          showthis: false,
          lockThis: true
        },
        // {
        //   id: 7,
        //   name: "Benefit",
        //   route: `/PersonnelManagement/NewEmployee/${this.nid}/PersonalBenefit`,
        //   showthis: false,
        //   lockThis: true
        // },
        {
          id: 8,
          name: "Guarantor",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/Guarantor`,
          showthis: false,
          lockThis: true
        },
        {
          id: 9,
          name: "Family",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/Family`,
          showthis: false,
          lockThis: true
        },
        {
          id: 10,
          name: "Account",
          route: `/PersonnelManagement/NewEmployee/${this.nid}/Account`,
          showthis: false,
          lockThis: true
        }
      );
      this.thetabcheck;
      let per = sessionStorage.getItem("permissionID").split(",");
      let role = sessionStorage.getItem("roleID");
      if (role == "Admin")
      {
        for (let j = 0; j < this.tabs.length; j++)
        {
          this.tabs[j].showthis = true;
        }
      }
      else if (role == "HR")
      {
        for (let j = 0; j < 9; j++)
        {
          this.tabs[j].showthis = true;
        }
      }
      else
      {
        if (per.some((e) => e == 7))
        {
          if (per.some((e) => e == 199)) {
            this.tabs[0].showthis = true;
          }
          if (per.some((e) => e == 193)) {
            this.tabs[1].showthis = true;
          }
          if (per.some((e) => e == 202)) {
            this.tabs[2].showthis = true;
          }
          if (per.some((e) => e == 205)) {
            this.tabs[3].showthis = true;
          }
          if (per.some((e) => e == 208)) {
            this.tabs[4].showthis = true;
          }
          if (per.some((e) => e == 211)) {
            this.tabs[5].showthis = true;
          }
          if (per.some((e) => e == 215)) {
            this.tabs[6].showthis = true;
          }
          if (per.some((e) => e == 218)) {
            this.tabs[7].showthis = true;
          }
          if (per.some((e) => e == 221)) {
            this.tabs[8].showthis = true;
          }
          if (per.some((e) => e == 191)) {
            this.tabs[9].showthis = true;
          }
        }
      }
    }
    else
    {
      this.shownavi = true;
      this.showback = true;
      if (this.$route.name == "EditSalary")
      {
        this.activeTab = `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/Salary`;
        this.activeeditsalary = true;
      }
      else
      {
        this.activeTab = `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}`;
        this.activeeditsalary = false;
      }
      this.tabs.push(
        {
          id: 1,
          name: "Personal Information",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}`,
          showthis: false,
          lockThis: false
        },
        {
          id: 2,
          name: "Salary",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/Salary`,
          showthis: false,
          lockThis: false
        },
        {
          id: 3,
          name: "IncomeTax",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/PersonalIncomeTax`,
          showthis: false,
          lockThis: false
        },
        {
          id: 4,
          name: "Social Security",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/SocialSecurity`,
          showthis: false,
          lockThis: false
        },
        {
          id: 5,
          name: "Provident Fund",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/ProvidentFund`,
          showthis: false,
          lockThis: false
        },
        {
          id: 6,
          name: "Health Information",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/HealthInformation`,
          showthis: false,
          lockThis: false
        },
        // {
        //   id: 7,
        //   name: "Benefit",
        //   route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/PersonalBenefit`,
        //   showthis: false,
        //   lockThis: false
        // },
        {
          id: 8,
          name: "Guarantor",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/Guarantor`,
          showthis: false,
          lockThis: false
        },
        {
          id: 9,
          name: "Family",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/Family`,
          showthis: false,
          lockThis: false
        },
        {
          id: 10,
          name: "Account",
          route: `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}/Account`,
          showthis: false,
          lockThis: false
        }
      );
      let per = sessionStorage.getItem("permissionID").split(",");
      let roleid = sessionStorage.getItem("roleID");
      if (roleid == "Admin")
      {
        for (let j = 0; j < this.tabs.length; j++)
        {
          this.tabs[j].showthis = true;
        }
      }
      if (roleid == "HR")
      {
        for (let j = 0; j < 9; j++)
        {
          this.tabs[j].showthis = true;
        }
      }
      if (per.some((e) => e == 199))
      {
        this.tabs[0].showthis = true;
      }
      if (per.some((e) => e == 193))
      {
        this.tabs[1].showthis = true;
      }
      if (per.some((e) => e == 202))
      {
        this.tabs[2].showthis = true;
      }
      if (per.some((e) => e == 205))
      {
        this.tabs[3].showthis = true;
      }
      if (per.some((e) => e == 208))
      {
        this.tabs[4].showthis = true;
      }
      if (per.some((e) => e == 211))
      {
        this.tabs[5].showthis = true;
      }
      if (per.some((e) => e == 215))
      {
        this.tabs[6].showthis = true;
      }
      if (per.some((e) => e == 218))
      {
        this.tabs[7].showthis = true;
      }
      if (per.some((e) => e == 221))
      {
        this.tabs[8].showthis = true;
      }
      if (per.some((e) => e == 191))
      {
        this.tabs[9].showthis = true;
      }
      this.GetSalary();
    }

    if (this.activeeditsalary == true)
    {
      this.$router.push(this.tabs[1].route).catch(() => { });
    }
    else if (this.activeprofilesalary == true)
    {
      this.$router.push(this.tabs[1].route).catch(() => { });
    }
    else
    {
      if (this.$route.path == `/selfservice/PersonalProfile/${this.id}` || this.$route.path == `/PersonnelManagement/EditEmployee/${this.$route.params.id}/${this.editid}` || this.$route.path == "/PersonnelManagement/NewEmployee/NewPersonalInformation")
      {
        for (let z = 0; z < this.tabs.length; z++)
        {
          if (this.tabs[z].showthis == true)
          {
            this.activeTab = this.tabs[z].route;
            this.$router.push(this.tabs[z].route).catch(() => { });
            break;
          }
        }
      }
    }
  },
};
</script>
<style scoped>
.showname {
  width: 600px;
}

.showmyname {
  width: 120px;
}

@media only screen and (min-width: 100px) and (max-width: 350px) {}

@media (max-width: 340px) {
  .showname {
    width: 350px;
  }

  .showmyname {
    width: 50px;
  }
}

.tablen {
  width: 150px;
}

.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}

.v-tabs-bar .v-tab {
  background-color: white;
}

>>>.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 10px;
  padding: 0 16px;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}

>>>.v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 100%;
}

@media (max-height: 800px) and (max-width: 1280px) {
  .tablen {
    width: 120px;
    height: 45px;
    font-size: 900px;
  }
}

@media only screen and (max-width:1300px) {
  .v-tabs-bar .v-tab {
    background-color: white;
    font-size: 13px !important;
  }
}
</style>
