<template>
  <div>
    <div style="padding-left: 0px; margin-bottom: 1rem">
      <v-row style="margin-right: 0">
        <p
          class="ml-4"
          text
          style="
            cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            color: #acacac;
            padding: 18px;
          "
          @click="back()"
        >
          {{ $t("masterData") }}
        </p>
        <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext" style="font-size: 26px; margin-top: 20px">
          {{ $t("organization") }}
        </p>
      </v-row>
    </div>
    <v-card
      class="pt-5 mb-5"
      style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem"
    >
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <v-text-field
            autocomplete="off"
            v-model.lazy="searchtxt"
            :placeholder="$t('Search')"
            color="#F99D20"
            dense
            style="margin-top: 0.5rem"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize ml-5 sear"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn
            color="#F99D20"
            class="orange--text mr-5 text-capitalize"
            width="150"
            max-height="35"
            outlined
            @click="uploaddialog = true"
            :disabled="permission.upload"
            >{{ $t("upload") }}</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            color="#F99D20"
            class="white--text mr-5 text-capitalize btnres"
            width="150"
            max-height="35"
            @click="Adddialog = true"
            :disabled="permission.add"
            >{{ $t("Add") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 40px; margin-right: 40px"
        class="tbheader"
        :headers="headers"
        :items="organization"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        hide-default-header
        @page-count="pageCount = $event"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('organization_Lvl')"
              >
                {{ $t("organizationLevel") }}
                <v-icon small v-if="!organization_Lvl">mdi-arrow-down</v-icon>
                <v-icon small v-if="organization_Lvl">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('organization_Name')"
              >
                {{ $t("Name") }}
                <v-icon small v-if="!organization_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="organization_Name">mdi-arrow-up</v-icon>
              </th>

              <th>{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.organization_Lvl }}</td>
            <td>{{ item.organization_Name }}</td>
            <td>
              <v-btn
                icon
                @click="EditOrganization(item)"
                :disabled="permission.edit"
              >
                <img :src="images.edit" />
              </v-btn>
              <v-btn
                icon
                @click="Deletedia(item.organization_ID)"
                :disabled="permission.delete"
              >
                <img :src="images.delete" />
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="organization.length">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#FFCD2C"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPage"
              :items="items"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- add department dialog -->
    <v-dialog v-model="Adddialog" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-7 mr-2" style="font-size: 23px">
          {{ $t("addOrganization") }}
        </p>
        <v-card-text>
          <v-container style="margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    autocomplete="off"
                    v-model="add.orglvl"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    :rules="orglvl"
                  >
                    <template v-slot:label>
                      <span>
                        {{ $t("organizationLevel")
                        }}<span class="red--text"> *</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    autocomplete="off"
                    v-model="add.orgname"
                    color="#F99D20"
                    :rules="orgname"
                    required
                  >
                    <template v-slot:label>
                      <span>
                        {{ $t("name") }}<span class="red--text"> *</span></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="closeAdddialog"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              @click="AddOrgnization"
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit department dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="786px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
          {{ $t("EditOrganization") }}
        </p>
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formEdit" lazy-validation>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  v-model="edit.orglvl"
                  color="#F99D20"
                  :rules="orglvlEdit"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("organizationLevel")
                      }}<span class="red--text"> *</span></span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  autocomplete="off"
                  v-model="edit.orgname"
                  color="#F99D20"
                  :rules="orgnameEdit"
                  required
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("name") }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-3 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="CancelEdit"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              @click="UpdateOrganization"
              color="#F99D20"
              class="mr-3 text-capitalize"
              width="120"
              max-height="35"
              >{{ $t("Update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete department dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("deleteOragainzaiton") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="Deletedialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              @click="DeleteOrganization"
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload Dialog -->
    <v-dialog v-model="uploaddialog" presistent max-width="786">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="left" cols="5">
                <v-file-input
                  truncate-length="100"
                  v-show="chosenfile"
                  color="white"
                  class="myfile"
                  id="myFileInput"
                  v-model="chosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon v-show="chosenfile">
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="4">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize mt-4"
                  style="text-align:left;float:left"
                  width="150"
                  max-height="35"
                  @click="getupload"
                >Browse</v-btn>
              </v-col>
              <v-col cols="6">
                <p
                  style="margin-top:25px; color:#F74747;"
                >** This file type must be .xlsx, .xsl only.</p>
              </v-col>
            </v-row>
            <hr class="mt-5" />-->
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- <v-row class="mt-7">
              <v-col style="margin-top:-20px" align="left" cols="12">
                <p
                  style="font-size:18px;font-family:'Kanit', sans-serif;color:#444444;"
                >Excel file upload formats</p>
              </v-col>
              <v-col cols="4">
                <p>Organization Template.xlsx</p>
              </v-col>
              <v-col cols="5">
                <a
                  style="color:#F99D20;"
                  href="https://optimisticpathfile.blob.core.windows.net/optimisticfile/Organization Master Data.xlsx"
                >Download</a>
              </v-col>
            </v-row>
            <hr class="mt-5" />-->
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Organization Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="CheckLang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              @click="closeUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              color="#F99D20"
              class="mr-2 white--text text-capitalize"
              width="120"
              height="35"
              @click="PreviewFiles"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    "add.orglvl"() {
      this.orglvl = [];
    },
    "add.orgname"() {
      this.orgname = [];
    },
    "edit.orglvl"() {
      this.orglvlEdit = [];
    },
    "edit.orgname"() {
      this.orgnameEdit = [];
    },
    searchtxt: function () {
      this.GetOrganization();
    },
  },
  data() {
    return {
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      orglvl: [],
      orgname: [],
      orglvlEdit: [],
      orgnameEdit: [],
      importData: [],
      tempimport: [],
      chosenfile: null,
      companyid: store.state.company_ID,
      uploaddialog: false,
      url: enurl.apiUrl,
      organization_Lvl: null,
      organization_Name: null,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      Adddialog: false,
      Editdialog: false,
      Deletedialog: false,
      LoadingDialog: false,
      searchtxt: "",
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      images: {
        edit: require("@/assets/images/Mask Group 178.png"),
        delete: require("@/assets/images/Mask Group 177.png"),
      },
      headers: [
        {
          text: "Organization Level",
          value: "organization_Lvl",
          align: "left",
          sortable: false,
        },
        {
          text: "Name",
          value: "organization_Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actionadd",
          align: "left",
          sortable: false,
        },
      ],
      showup: false,
      organization_ID: 0,
      organization: [],
      add: {
        orglvl: null,
        orgname: null,
        orgparent: null,
      },
      edit: {
        organization_ID: null,
        orglvl: null,
        orgname: null,
        orgparent: null,
      },
      errorMessage: [],
    };
  },
  mounted() {
    let self = this;
    self.GetOrganization();
    self.permission = self.CheckViewAddEditDeleteUpload(49, 50, 51, 52, 53);
  },
  methods: {
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Organization Master Data.xlsx";
      } else {
        url =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Organization Master Data.xlsx";
      }
      return url;
    },
    ValidateOrg() {
      let self = this;
      let result;
      let FilterOrg = self.organization.filter(
        (data) => data.organization_Name
      );
      for (let i = 0; i < FilterOrg.length; i++) {
        if (
          FilterOrg[i].organization_Name.toLowerCase() ==
            self.add.orgname.toLowerCase() &&
          FilterOrg[i].organization_Lvl == self.add.orglvl
        ) {
          alert("Organization already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    EditValidateOrg() {
      let self = this;
      let result;
      let FilterOrg = self.organization.filter(
        (data) => data.organization_ID != self.edit.organization_ID
      );
      for (let i = 0; i < FilterOrg.length; i++) {
        if (
          FilterOrg[i].organization_Name.toLowerCase() ==
            self.edit.orgname.toLowerCase() &&
          FilterOrg[i].organization_Lvl == self.edit.orglvl
        ) {
          alert("Organization already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    CancelEdit() {
      this.Editdialog = false;
      this.orglvlEdit = [];
      this.orgnameEdit = [];
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["organization_Lvl", "organization_Name"];
      if (rowName == "organization_Name") {
        self.organization = self.organization.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.organization = self.organization.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.organization_Lvl = null;
      this.organization_Name = null;
    },
    closeUpload() {
      this.uploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
    },
    closeAdddialog() {
      this.add.orglvl = null;
      this.add.orgname = null;
      this.orglvl = [];
      this.orgname = [];
      this.$refs.formAdd.reset();
      this.Adddialog = false;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getupload() {
      document.getElementById("myFileInput").click();
      this.showup = true;
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    PreviewFiles() {
      let self = this;
      self.LoadingDialog = true;
      var files = self.chosenfile;
      if (files != null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          let range = XLSX.utils.decode_range(
            workbook.Sheets[sheetName]["!ref"]
          );
          range.s.c = 0; // 0 == XLSX.utils.decode_col("A")
          range.e.c = 1; // 1 == XLSX.utils.decode_col("B")
          let new_range = XLSX.utils.encode_range(range);
          let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          const file_data = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false,
            defval: "",
            range: new_range,
          });
          if (file_data.length != 1) {
            let NaMFiltered = file_data.filter((org) => org[0] == "");
            let LvLFiltered = file_data.filter((org) => org[1] == "");

            if (
              LvLFiltered.length == 0 &&
              NaMFiltered.length == 0 &&
              temp.length != 0
            ) {
              this.importData = XLSX.utils.sheet_to_json(worksheet);
              let combileArray = [];
              for (let i = 0; i < this.importData.length; i++) {
                this.tempimport = {
                  organization_Name: file_data[i + 1][0],
                  organization_Lvl: file_data[i + 1][1],
                  company_ID: store.state.companyID,
                };
                combileArray.push(this.tempimport);
              }
              if (filename == "Organization Master Data.xlsx") {
                axios
                  .post(
                    `${self.url}Organization/ImportOrganizationMasterData`,
                    combileArray
                  )
                  .then(function (response) {
                    if (response.data.status == 0) {
                      alert(response.data.message);
                      self.chosenfile = null;
                      self.showup = false;
                      self.uploaddialog = false;
                      self.LoadingDialog = false;
                      self.GetOrganization();
                      self.defaultSort();
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(
                      error,
                      "Upload Organization Fail",
                      1,
                      "Verbose"
                    );
                    //alert(error + "Correlation ID is 1");
                    self.LoadingDialog = false;
                  });
              } else {
                alert("File name must be Organization Master Data.xlsx");
                self.LoadingDialog = false;
              }
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert("Please fill organization_Lvl and organization_Name.");
              } else {
                if (LvLFiltered.length != 0) {
                  self.errorMessage.push("organization_Lvl");
                }
                if (NaMFiltered.length != 0) {
                  self.errorMessage.push("organization_Name");
                }
                let Message = [...new Set(self.errorMessage)];
                alert(`Please fill ${Message.join(", ")}`);
                self.errorMessage = [];
              }
            }
          } else {
            alert("Please fill organization_Lvl and organization_Name.");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please choose File");
        self.LoadingDialog = false;
      }
    },
    AddOrgnization() {
      let self = this;
      self.LoadingDialog = true;
      self.orglvl = [(v) => !!v || "Organization Level is required"];
      self.orgname = [(v) => !!v || "Organization Name is required"];
      let tempvalidate = self.$refs.formAdd.validate();
      if (
        tempvalidate == true &&
        self.add.orglvl != null &&
        self.add.orgname != null &&
        self.add.orglvl != "" &&
        self.add.orgname != ""
      ) {
        let temporg = self.ValidateOrg();
        if (temporg == true) {
          let tempdept = {
            organization_Lvl: parseInt(self.add.orglvl),
            organization_Name: self.add.orgname,
            company_ID: store.state.companyID,
          };
          axios
            .post(`${self.url}Organization/AddOrganizationInfo`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.add.orglvl = null;
                self.add.orgname = null;
                self.Adddialog = false;
                self.GetOrganization();
                self.defaultSort();
                self.LoadingDialog = false;
              }
            })
            .catch(function (error) {
              this.LogTrace(error, "Add Organization Fail", 1, "Low");
              //alert(error + "Correlation ID is 1");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("Please fill in the fields");
        self.LoadingDialog = false;
      }
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.organization;
        self.organization = [];
        let temp = List.filter(
          (v) =>
            v.organization_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.organization_Lvl.toString().indexOf(self.searchtxt) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.organization.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetOrganization();
      }
    },

    // keymonitor: function (event) {
    //   if (event.key == "Backspace") {
    //     this.GetOrganization();
    //   }
    // },
    EditOrganization(temp) {
      let self = this;
      self.Editdialog = true;
      self.edit.organization_ID = temp.organization_ID;
      self.edit.orglvl = temp.organization_Lvl;
      self.edit.orgname = temp.organization_Name;
    },
    UpdateOrganization() {
      let self = this;
      self.LoadingDialog = true;
      self.orglvlEdit = [(v) => !!v || "Organization Level is required"];
      self.orgnameEdit = [(v) => !!v || "Organization Name is required"];
      let tempvalidate = self.$refs.formEdit.validate();

      if (
        tempvalidate == true &&
        self.edit.orglvl != null &&
        self.edit.orgname != null &&
        self.edit.orglvl != "" &&
        self.edit.orgname != ""
      ) {
        let temporg = self.EditValidateOrg();
        if (temporg == true) {
          let tempdept = {
            organization_ID: self.edit.organization_ID,
            organization_Lvl: parseInt(self.edit.orglvl),
            organization_Name: self.edit.orgname,
            company_ID: store.state.companyID,
          };
          axios
            .post(`${self.url}Organization/UpdateOrganizationInfo`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.edit.orglvl = null;
                self.edit.orgname = null;
                self.Editdialog = false;
                self.LoadingDialog = false;
                self.GetOrganization();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Organization Fail", 1, "Medium");
              //alert(error + "Correlation ID is 1");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("Please fill in the fields");
        self.LoadingDialog = false;
      }
    },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(()=>{});
    },
    GetOrganization() {
      let self = this;
      //self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Organization/GetOrganizationInfoByCompanyID`, temp)
        .then(function (response) {
          self.organization = response.data.data;
          self.orgList = response.data.data;
          // self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Organization Fail", 1, "Critical");
          //alert(error + "Correlation ID is 1");
          //self.LoadingDialog = false;
        });
    },
    Deletedia(id) {
      this.organization_ID = id;
      this.Deletedialog = true;
    },
    DeleteOrganization() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.organization_ID;
      let temp = {
        organization_ID: id,
      };
      axios.post(`${self.url}Organization/DeleteOrganizationInfo`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.Deletedialog = false;
          self.LoadingDialog = false;
          self.GetOrganization();
          self.defaultSort();
        }
      })
      .catch(function (error) {
        this.LogTrace(error, "Delete  Organization Fail", 1, "High");
        //alert(error + "Correlation ID is 1");
        self.LoadingDialog = false;
      });
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
@media (max-width: 380px) {
  .headtext {
    margin-left: 2rem !important;
  }
}
@media (max-width: 415px) {
  .btnres {
    margin-top: -0.6rem !important;
  }
  .sear {
    margin-left: -0.2rem !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}
tbody tr:hover {
  background-color: #fff9f0 !important;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}

.myfile .v-input__slot {
  border-color: white !important;
}
</style>
<style>
.myfile {
  border: none !important;
}
.mdi-close::before {
  content: "\F0156";
  color: red !important;
}

.myfile.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
</style>
