<template style="background:#F0F0F7">
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 2.5rem;">
    <p class="txt-header">
      {{ $t("EmployeesLeaveRequest") }}
    </p>

    <div style="height: auto" class="d-flex-row flex-wrap mt-6">
      <v-card class="d-flex-row flex-wrap pt-5 mb-5" style="margin-bottom: 5rem">
        <div class="d-flex-row justify-center flex-wrap mb-5">
          <v-row class="d-flex-row flex-wrap ml-3 mr-3">
            <!-- Leave Type -->
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-2">
              <p class="mb-0" style="color: #a3a7aa">{{ $t("leavetype") }}</p>
              <v-autocomplete v-model="leaveType" :items="leaveTypeList" outlined :item-text="thethaihead == true ? 'timeoff_TypeTH' : 'timeoff_TypeEN'
                " item-value="timeOff_ID" dense clearable color="#F99D20"></v-autocomplete>
            </v-col>
            <!-- Employee -->
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-2">
              <p class="mb-0" style="color: #a3a7aa">{{ $t("emp") }}</p>
              <v-autocomplete class="empID" v-model="employee" item-value="employee_ID"
                :items="employeeList" :item-text="(item) => ` ${item.employee_Name}`" outlined
                :error-messages="error.errormessagereportid" append-icon="mdi-chevron-down" dense clearable
                color="#F99D20">
                <template v-slot:selection="data">
                  <span style="font-size: 17px">{{
                    data.item.employee_Name
                  }}</span>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <img :src="data.item.profile_Pic" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.nickname == null
                        ? data.item.employee_Name
                        : data.item.employee_Name +
                        " (" +
                        data.item.nickname +
                        ")"
                      }}</v-list-item-title>
                      <p style="font-size: 11px">
                        {{ data.item.position_Name }}
                      </p>
                    </v-list-item-content>
                  </template>
                </template>
                <!-- <template v-slot:label>
                      <span>
                        {{ $t("Supervisor") }}<span class="red--text"> *</span>
                        </span>
                    </template> -->
              </v-autocomplete>
            </v-col>
            <!-- Start Date -->
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-2" v-if="$vuetify.breakpoint.mdAndUp">
              <p class="mb-0" style="color: #a3a7aa">{{ $t("startdate") }}</p>
              <date-picker v-bind:clearable="false" class="custom-vuedatepicker1" v-model="reportDateFrom" format="DD/MM/YYYY" placeholder="DD/MM/YYYY"
              @change="Getbydatelist()" :disabled-date="(date) => date > new Date(reportDateTo)" style="width:100%;">
                <!-- <template slot="icon-calendar">
                  <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                </template> -->
              </date-picker>
            </v-col>
            <!-- End Date -->
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-2" v-if="$vuetify.breakpoint.mdAndUp">
              <p class="mb-0" style="color: #a3a7aa">{{ $t("enddate") }}</p>
              <date-picker v-bind:clearable="false" class="custom-vuedatepicker1" v-model="reportDateTo" format="DD/MM/YYYY" placeholder="DD/MM/YYYY"
              readonly @change="Getbydatelist()" :disabled-date="(date) => date < new Date(reportDateFrom)" style="width:100%;">
                <!-- <template slot="icon-calendar">
                  <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                </template> -->
              </date-picker>
            </v-col>
            <!-- Status -->
            <v-col cols="12" sm="6" md="4" lg="2" xl="2" class="mt-2">
              <p class="mb-0" style="color: #a3a7aa">{{ $t("status") }}</p>
              <v-autocomplete v-model="itemInner" :placeholder="$t('status')" :items="status" item-text="txt"
                color="#F99D20" item-value="val" outlined dense></v-autocomplete>
            </v-col>

            <!-- Export -->
            <v-col cols="12" xs="6" sm="2" md="1" lg="1" xl="1" class="mt-8 thlr">
              <v-btn @click="exportit()" icon>
                <img width="35" height="35" :src="images.excel" />
              </v-btn>
            </v-col>
            <!-- Request button -->
            <v-col cols="12" xs="6" sm="6" md="4" lg="2" xl="1" class="mt-8" justify="end">
              <v-btn color="#F99D20" class="white--text text-capitalize text-right" max-height="35" style="width: auto"
                @click="newReq()">{{ $t("leaverequest") }}</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- Data table -->
        <div class="mt-2 mr-3 ml-3">
          <v-data-table class="mt-4" hide-default-footer hide-default-header :items="hrTimeOffList" :headers="headers"
            :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event" :mobile-breakpoint="0"
            style="
              border-top: 1px solid #cfcdcd;
              border-bottom: 1px solid #cfcdcd;
            ">
            <template v-slot:header="{ props: { } }">
              <thead>
                <tr style="height: 60px">
                  <th style="text-align: center">
                    <!-- <label class="form-checkbox">
                      <input
                        type="checkbox"
                        v-model="anyRemaining"
                        @change="checkSelectedAll()"
                        class="mt-3 ml-1"
                        width="200"
                      />
                      <span class="checkmark"></span>
                    </label> -->
                    {{ $t("no") }}
                  </th>
                  <th style="text-align: left">{{ $t("EmployeeName") }}</th>
                  <th style="text-align: center">{{ $t("requestedDate") }}</th>
                  <th style="text-align: center">{{ $t("leavetype") }}</th>
                  <th style="text-align: center">{{ $t("date") }}</th>
                  <th style="text-align: center">{{ $t("status") }}</th>
                  <th style="text-align: center">{{ $t("Supervisor") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td style="text-align: center;">
                  {{ item.serialNumber }}
                </td>
                <td @click="ViewDetail(item)" style="text-align: left">
                  {{ item.employee_Name }}
                </td>
                <td @click="ViewDetail(item)" style="text-align: center">
                  {{ formatDateRD(item.request_On) }}
                </td>
                <td @click="ViewDetail(item)" style="text-align: center">
                  {{ item.timeoff_Name }}
                </td>
                <td @click="ViewDetail(item)" style="text-align: center">
                  {{
                    formatDate(item.start_Date) +
                    "-" +
                    formatDate(item.end_Date)
                  }}
                </td>
                <td @click="ViewDetail(item)" :style="{
                      color:
                        item.status == 'Approved' || item.status == 'Manual'
                          ? '#00C092'
                          :item.status == 'Denied'
                          ? '#FFFFFF'
                          : ' #FF0000',
                    }" style="text-align: center">
                  <div :style="{
                    backgroundColor:
                      item.status == 'Approved' || item.status == 'Manual'
                        ? '#CCF3E9'
                        :item.status == 'Denied'
                        ? '#7F7F7F'
                        : '#FFCCCC',
                    textAlign: 'center',
                    borderRadius: '20px',
                    padding: '5px',
                  }">
                    {{
                      item.status == "Approved"
                      ? $t("approved")
                      : item.status == "Denied"
                        ? $t("denied")
                        : item.status == "Draft Request"
                          ? $t("Draftreq")
                          : item.status == "Canceled"
                            ? $t("Cancelled")
                            : item.status == "Manual"
                              ? $t("approved")
                              : $t("waitingApproval")
                    }}
                  </div>
                </td>
                <td @click="ViewDetail(item)" style="text-align: center">
                  {{ item.approver_Name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div class="d-flex-row flex-wrap text-right" v-if="hrTimeOffList.length">
          <v-row justify="end" class="d-flex-row flex-wrap ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1 d-flex-row flex-wrap">
              <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    
    <!-- loading dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- TimeOff dialog -->
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card style="border-radius: 15px;">
        <v-card-title>
          <span class="mt-3 ml-3" style="font-size: 23px; color: #444444">
            {{ $t("leaverequestdetail") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn @click="Closedetaildialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-4">
          <!-- <hr /> -->
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" class="pt-1">
                  {{ $t("requestedDate") }}:</v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="viewTimeOff.request_On" height="10px" color="#F99D20" single-line outlined
                    class="readonly" dense readonly></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("status") }} :
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-row class="mt-1">
                    <div v-if="cancelstatus == false" :style="{
                      width: '100px',
                      height: '40px',
                      display: 'flex',
                      backgroundColor:
                        viewTimeOff.status == 'Approved' || viewTimeOff.status == 'Manual'
                          ? '#CCF3E9'
                          : viewTimeOff.status == 'Denied'
                          ? '#7F7F7F'
                          : '#FFCCCC',
                      textAlign: 'center',
                      justifyContent: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                    }">
                      <p class="mb-0 mt-1" :style="{
                        color:
                          viewTimeOff.status == 'Approved' || viewTimeOff.status == 'Manual'
                            ? '#22A534'
                            : viewTimeOff.status == 'Waiting Approval'
                              ? '#F99D20'
                              : viewTimeOff.status == 'Canceled'
                                ? '#FF0000'
                                : viewTimeOff.status == 'Denied'
                                ? '#FFFFFF'
                                : '#FF6565',
                      }">
                        {{
                          viewTimeOff.status == "Approved" || viewTimeOff.status == "Manual"
                          ? $t("approved")
                          : viewTimeOff.status == "Denied"
                            ? $t("denied")
                            : viewTimeOff.status == "Draft Request"
                              ? $t("Draftreq")
                              : viewTimeOff.status == "Canceled"
                                ? $t("Cancelled")
                                : $t("waitingApproval")
                        }}
                      </p>
                    </div>
                    <v-btn @click="showcancel = true" v-if="viewTimeOff.status == 'Approved' && cancelstatus != true
                              " style="text-decoration: underline" text class="text-capitalize ml-4">{{ $t("Cancel") }}</v-btn>
                  </v-row>
                  <div v-if="cancelstatus == true" style="
                      background-color: #ffcccc;
                      width: 100px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      border-radius: 20px;
                      padding: 5px;
                      margin-top: 5px;
                    ">
                    <p class="mb-0" style="color: #ff0000">
                      {{ $t("Cancelled") }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" class="pt-0">
                  {{ $t("EmployeeName") }} :
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="viewTimeOff.employee_Name" height="10px" color="#F99D20" single-line outlined
                    class="readonly" readonly dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("position") }} :
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="viewTimeOff.postion_Name" height="10px" color="#F99D20" single-line outlined
                    dense class="readonly" readonly></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                  {{ $t("leavetype") }} :
                </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="viewTimeOff.timeoff_Name" height="10px" color="#F99D20" single-line outlined
                    class="readonly" readonly dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
              <v-row class="ml-1" style="margin-top: 0.2rem">
                <span v-if="viewTimeOff.wholeDay">{{ $t("Allday") }}</span>
                <span v-if="!viewTimeOff.wholeDay">{{ $t("halfDay") }}</span>
                <v-checkbox class="custom-checkbox" v-model="viewTimeOff.wholeDay" color="#F99D20" hide-details disabled
                  style="margin-top: -0.4rem; margin-left: 4rem"></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
          <div v-show="!viewTimeOff.wholeDay">
            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                <v-row>
                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("date") }} :
                  </v-col>
                  <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                    <v-text-field v-model="viewTimeOff.start_Date" height="10px" color="#F99D20" single-line outlined
                      class="readonly" readonly dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                <v-row>
                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("StartTime") }} :
                  </v-col>
                  <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                    <v-text-field v-model="viewTimeOff.start_Time" height="10px" color="#F99D20" single-line outlined
                      class="readonly" readonly dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                <v-row>
                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("EndTime") }} :
                  </v-col>
                  <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                    <v-text-field v-model="viewTimeOff.end_Time" height="10px" color="#F99D20" single-line outlined dense
                      class="readonly" readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-show="viewTimeOff.wholeDay">
            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                <v-row>
                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("startDate") }} :
                  </v-col>
                  <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                    <v-text-field v-model="viewTimeOff.start_Date" height="10px" color="#F99D20" single-line outlined
                      class="readonly" readonly dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
                <v-row>
                  <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("enddate") }} :
                  </v-col>
                  <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                    <v-text-field v-model="viewTimeOff.end_Date" height="10px" color="#F99D20" single-line outlined dense
                      class="readonly" readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2" class="pb-0">{{
              $t("DescriptionOT")
            }}</v-col>
            <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10" class="pb-0">
              <div :style="{
                    width: $vuetify.breakpoint.lgAndUp
                      ? '823px'
                      : $vuetify.breakpoint.md
                        ? '800px'
                        : '',
                  }">
                <v-textarea v-model="viewTimeOff.message" solo no-resize rows="3" min-height="80" name="input-7-4"
                  readonly class="mb-4 readonly" :style="$vuetify.breakpoint.mdAndUp
                      ? 'caret-color: orange !important;margin-left:-2.5rem;'
                      : 'caret-color: orange !important'
                    " hide-details></v-textarea>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2" class="pb-0">
              {{ $t("AttachmentOT") }}</v-col>
            <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10" class="pb-0">
              <div class="mr-0 pa-3 relative" v-if="viewTimeOff.attachmentList.length === 0" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''
                " style="
                  width: 130px;
                  height: 120px;
                  border: 1px solid #ccc;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 7px;
                ">
                <v-img class="mb-3 mt-2" src="@/assets/images/none.png" width="60px"></v-img>
                <div class="text-center">None</div>
              </div>
              <div class="container" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''
                ">
                <div v-for="file in viewTimeOff.attachmentList" :key="file.filename" class="mr-0 pa-3 relative" style="
                    width: 130px;
                    height: 120px;
                    border: 1px solid #ccc;
                    border-radius: 7px;
                  ">
                  <div @click="showiframeforDetail(file)" class="mt-2">
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'png'" src="@/assets/images/png.png"
                      width="50px"></v-img>
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpeg'" src="@/assets/images/jpg.png"
                      width="50px"></v-img>
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpg'" src="@/assets/images/jpg.png"
                      width="50px"></v-img>
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'pdf'" src="@/assets/images/pdf.png"
                      width="50px"></v-img>
                    <div class="text-center">
                      {{
                        file.filename
                        ? file.filename.substring(0, 10) + " ..."
                        : ""
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
              {{ $t("approver") }}</v-col>
            <v-col>
              <v-row>
                <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-text-field v-model="viewTimeOff.approver_Name" height="10px" color="#F99D20" single-line outlined
                    dense class="readonly" :style="$vuetify.breakpoint.mdAndUp
                        ? 'margin-left : -2.5rem; '
                        : ''
                      " readonly></v-text-field>
                </v-col>
                <v-col>
                  <p class="mt-3">{{ formatDateRD(viewTimeOff.apporve_Date) }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr color="#EEEEEE" class="mb-3" v-if="showcancel == true" />
          <v-row v-if="showcancel == true || viewTimeOff.status == 'Canceled'">
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2" class="pb-0">
              <span style="font-weight: bold">{{ $t("ActionNote") }}</span><span class="red--text">*</span>
            </v-col>
            <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10" class="pb-0">
              <div :style="{
                width: $vuetify.breakpoint.lgAndUp
                  ? '823px'
                  : $vuetify.breakpoint.md
                    ? '800px'
                    : '',
              }">
                <v-textarea v-model="viewTimeOff.actionNote" solo no-resize rows="3" min-height="80" name="input-7-4"
                  :disabled="viewTimeOff.status == 'Canceled'" placeholder="Please fill in the reason for cancellation*"
                  class="mb-0 pb-0" outlined color="#F99D20" :error-messages="ActionnoteSent" :style="$vuetify.breakpoint.mdAndUp
                      ? 'caret-color: orange !important;margin-left:-2.5rem;'
                      : 'caret-color: orange !important'
                    ">
                  <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>
                      {{ $t("Action Note is required") }}
                    </span>
                  </template>
                </v-textarea>
              </div>
              <!-- <span v-if="ActionnoteSent.length != 0" style="font-size: 13px" class="red--text"
                  :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem;' : ''">Action Note is
                  required</span> -->
            </v-col>
          </v-row>
          <v-row v-if="showcancel == true" class="mb-5 mt-0">
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="120" max-height="35" text
              @click="showcancel = false">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn color="#F99D20" class="text-capitalize white--text mr-3" width="90" max-height="35"
              @click="CancelTimeOffreq()">
              {{ $t("Confirm") }}</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
      <v-card style="background-color: white">
        <v-spacer></v-spacer>
        <v-btn @click="
          printdialogforupdate = false;
        iframeShow = false;
        " icon style="margin-right: 3rem; margin-top: 1rem">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card class="pdf-container">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Delete dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: -30px" class="ml-2">
            {{ $t("CancelApproval") }}
          </p>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container class="mt-2">
            <v-form lazy-validation>
              <v-row>
                <v-col cols="12">
                  <span class="red--text"> {{ $t("Fillremark") }}*</span>
                  <v-text-field v-model="txtreason" outlined autocomplete="off" color="#F89D1F" required
                    class="mt-2"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="Deletedialog = false" text>{{
            $t("Cancel") }}</v-btn>

          <v-btn color="#F99D20" class="mr-2 text-capitalize" width="120" max-height="35" @click="Deleterow()">
            {{ $t("Confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
       
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("Cancelconfirm") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions  style="margin-right: 3%" class="pb-6">
        
          <v-row>
           
            <v-col cols="4"></v-col>
          <v-btn
            class="mr-2 text-capitalize"
            width="90"
            max-height="40"
            @click="Canceldialog()"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="mr-2 white--text text-capitalize"
            width="90"
            max-height="35"
            @click="Deleterow()"
            >{{ $t("delete") }}</v-btn
          >
         
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg" style="overflow: hidden">
      <v-card style="
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          padding-bottom: 1.5rem;
        ">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

          <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
        </v-card-actions>

        <img src="imgForshow" max-width="500" />
      </v-card>
    </v-dialog>

    <!-- exportexcelDialog -->
    <v-dialog v-model="exportexcelDialog" persistent max-width="600px">
      <v-card style="border-radius: 15px" class="overflow-y-hidden">
        <v-card-title>
          <span>{{ $t("ExportTimeOffReport") }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="cancelExport()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pb-0">
              <p class="mt-2">{{ $t("Employees") }}</p>
              <v-text-field outlined dense class="mt-3" readonly v-model="computedForTranslate"
                color="#F99D20"></v-text-field>
            </v-col>
            <v-col v-show="checkByDate" cols="6" class="pb-0">
              <p class="mt-2">{{ $t("date") }}</p>
              <v-text-field outlined dense readonly class="mt-3" :value="this.$t('allDate')"
                color="#F99D20"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!checkByDate">
            <v-col cols="6" class="pt-0">
              <p class="mt-2">
                {{ $t("startDate") }}<span class="red--text"> *</span>
              </p>
              <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" single-line dense outlined readonly class="mt-3"
                    v-model="computedStartFormattedexcel" append-icon="mdi-calendar-month-outline" color="#F99D20"
                    persistent-hint v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="excel.start_Date" no-title @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pt-0">
              <p class="mt-2">
                {{ $t("enddata") }} <span class="red--text"> *</span>
              </p>
              <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" single-line dense readonly outlined class="mt-3"
                    v-model="computedEndFormattedexcel" append-icon="mdi-calendar-month-outline" color="#F99D20"
                    persistent-hint v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="excel.end_Date" no-title @input="menu3 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-2">
          <v-row class="ml-0 mr-0">
            <v-spacer></v-spacer>
            <v-col cols="6" md="3">
              <v-btn class="text-capitalize" text @click="cancelExport()" width="100%" max-height="32">{{ $t("cancel")
              }}</v-btn>
            </v-col>
            <v-col cols="6" md="3">
              <v-btn color="#F99D20" class="white--text text-capitalize" width="100%" max-height="32"
                @click="ExportExcel">{{ $t("Export") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import excel from "vue-excel-export";
import Vue from "vue";
import jsPDF from "jspdf";
import moment from "moment";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewExportDelete from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
Vue.use(excel);
export default {
  mixins: [LogTrace, CheckViewExportDelete, utils],
  data: (vm) => ({
    permission: {
      export: true,
      delete: true,
    },
    showcancel: false,
    cancelstatus: false,
    runningvalue: 0,
    reportDateTo: "",
    checkByDate: true,
    showdatepicker: false,
    reportDateFrom: "",
    selectedAll: false,
    hrTimeOffList: [],
    filterlist: [],
    url: enurl.apiUrl,
    menu3: false,
    menu2: false,
    LoadingDialog: false,
    Deletedialog: false,
    printdialogforupdate: false,
    dialogforDownload: false,
    time: "",
    time1: "",
    historyDate: false,
    historydate: "",
    timetypeitems: ["sick", "personal"],
    historystartDate: false,
    historyendDate: "",
    historyenddate: "",
    startDate: false,
    endDate: false,
    enddate: "",
    startdate: "",
    excel: {
      start_Date: "",
      end_Date: "",
    },
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    dialog: false,
    denyDialog: false,

    exportexcelDialog: false,
    items: [10, 20, 30],
    approveDialog: false,
    page: 1,
    itemforselect: ["Everyone", "Selected Users"],
    selectusertype: "Everyone",
    liesting: [],
    checkData: false,
    pageCount: 15,
    itemsPerPage: 10,
    year: ["Active", "Inactive"],
    searchtxt: null,
    txtreason: "",
    itemInner: "All",
    leaveTypeList: [],
    leaveType: 0,
    headers: [
      {
        text: "Employee Name",
        value: "employee_Name",
        align: "left",
        sortable: true,
      },
    ],
    TimeOffDialog: false,
    timeoffCancelID: 0,
    error: {
      timeoff_ID: "",
      balance: "",
      start_Date: "",
      end_Date: "",
      start_Time: "",
      end_Time: "",
      message: "",
    },
    showpagination: true,
    showremark: false,
    viewTimeOff: {
      employee_Name: "",
      timeoff_Name: "",
      balance: null,
      start_Date: "",
      timeoff_ID: 0,
      end_Date: "",
      start_Time: "",
      end_Time: "",
      request_On: "",
      status: "",
      approver_Name: "",
      message: "",
      wholeDay: false,
      time_off_RequestID: 0,
      reason: "",
      attachmentList: [],
      imgName: "",
      imgString: "",
      imgForshow: "@/assets/images/excel@2x.png",
      dllink: null,
      iframeShow: false,
      actionNote: "",
      wF_ID: 0,
    },
    images: {
      edit: require("@/assets/images/Mask Group 178.png"),
      delete: require("@/assets/images/Mask Group 177.png"),
      excel: require("@/assets/images/excel@2x.png"),
    },
    thelang: "",
    employeeList: [],
    employee: "",
    pdfString: "",
    ActionnoteSent: [],
  }),

  computed: {
    // serializedItems() {
    //   // Create a new array of items with a serial number
    //   return this.hrTimeOffList.map((item, index) => ({
    //     ...item,
    //     serialNumber: index + 1,
    //   }));
    // },

    thethaihead() {
      return this.whatthehead();
    },
    status() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Canceled", txt: this.$t("Cancelled") },
        { val: "Denied", txt: this.$t("denied") },
      ];
    },

    computedForTranslate() {
      return this.selectusertype == "Everyone"
        ? this.$t("Everyone")
        : this.selectusertype == "Selected User"
          ? this.$t("SelectUser")
          : this.$t("SelectUsers");
    },
    anyRemaining() {
      let hrTimeOffList = this.hrTimeOffList.length;
      let hrTimeOffListFilter =
        this.hrTimeOffList.filter((item) => !item.check).length != 0;
      if (!hrTimeOffList || hrTimeOffListFilter) {
        return false;
      }
      return true;
    },
    computedUTCFormatteddate() {
      return this.convertUTCDateToLocalDate(this.viewTimeOff.apporve_Date);
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted1() {
      return this.formatDate(this.startdate);
    },
    computedDateFormatted2() {
      return this.formatDate(this.enddate);
    },
    computedDateFormattedhistory() {
      return this.formatDate(this.viewTimeOff.start_Date);
    },
    computedDateFormattedend() {
      return this.formatDate(this.viewTimeOff.end_Date);
    },
    computedDateFormatteddate() {
      return this.convertUTCDateToLocalDate(this.viewTimeOff.request_On);
    },
    computedStartFormattedexcel() {
      return this.formatDate(this.excel.start_Date);
    },
    computedEndFormattedexcel() {
      return this.formatDate(this.excel.end_Date);
    },
  },
  watch: {
    itemInner(v) {
      if (v) {
        let list = this.filterlist;
        this.hrTimeOffList = [];
        if (v === "All") {
          this.hrTimeOffList = list;
        } else {
          this.hrTimeOffList = list.filter((x) => x.status === v);
        }
      }
    },
    leaveType(v) {
      if (v) {
        if (v == 0) {
          this.hrTimeOffList = this.filterlist;
        } else {
          let list = this.filterlist;
          this.hrTimeOffList = [];

          this.hrTimeOffList = list.filter((x) => x.timeoff_ID === v);
        }
      } else {
        this.hrTimeOffList = this.filterlist;
      }
    },
    employee(v) {
      if (v) {
        let list = this.filterlist;
        this.hrTimeOffList = [];

        this.hrTimeOffList = list.filter((x) => x.requester_ID === v);
      } else {
        this.hrTimeOffList = this.filterlist;
      }
    },
    "viewTimeOff.actionNote"() {
      this.ActionnoteSent = [];
    },
    thelang() {
      this.whatthehead();
    },
    searchtxt() {
      if (this.runningvalue == 1) {
        this.GetTimeOff();
      }
    },
  },
  mounted() {
    let self = this;
    self.GetLeaveTypeList();
    self.GetTimeOff();
    self.GetEmployeeList();
    self.permission = self.CheckViewExportDelete(37, 38, 226);
    self.timerInterval = setInterval(() => {
      self.thelang = localStorage.getItem("Templang");
    }, 100);
  },
  methods: {
    async Closedetaildialog() {
      let self = this;
      self.dialog = false;
      self.showcancel = false;
      if (self.cancelstatus == true) {
        await self.GetTimeOff();
      }
      self.cancelstatus = false;
    },
    async CancelTimeOffreq() {
      let self = this;
      self.ActionnoteSent = [(v) => !!v || "Action Note is required"];
      if (
        self.viewTimeOff.actionNote != "" &&
        self.viewTimeOff.actionNote != null
      ) {
        let temp = {
          wF_ID: self.viewTimeOff.wF_ID,
          status: "Canceled",
          actionNote: self.viewTimeOff.actionNote,
        };
        self.LoadingDialog = true;
        await Axios.post(`${self.url}MyTasks/UpdateTimeOffStatus`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              // self.Detailrequest.ActionNote = "";
              self.dialog = false;
              self.LoadingDialog = false;
              self.cancelstatus = true;
              self.showcancel = true;
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        self.LoadingDialog = false;
        alert("Please fill required field");
      }
    },
    async showiframeforDetail(item) {
      let self = this;
      //console.log(item);
      if (item.filetype == "pdf") {
        const blob = await self.base64ToBlob(
          item.attachment,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      } else {
        //const pdfBlob = await this.convertBase64ImageToPdf(
        // item.attachment_file
        //);
        self.dialogforDownload = true;
        self.imgForshow = "data:image/png;base64," + item.attachment;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },
    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },
    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },
    async convertBase64ImageToPdf(base64Image) {
      const pdf = new jsPDF();
      const blob = this.base64ToBlobforimg(base64Image, "image/jpeg"); // Adjust content type if needed
      const imgData = URL.createObjectURL(blob);

      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed

      const pdfData = pdf.output("blob");
      return pdfData;
    },
    base64ToBlobforimg(base64String, contentType) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
    GetEmployeeList() {
      let self = this;
      Axios.get(
        `${self.url}Confirmemployee/GetConfirmEmployeeByCompanyID?Company_ID=${store.state.companyID}`
      ).then(function (response) {
        let temp = response.data.data;
        self.employeeList = temp;
        for (var i = 0; i < self.employeeList.length; i++) {
          if (self.employeeList[i].profile_Pic == null) {
            self.employeeList[i].profile_Pic =
              "https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group%203166.png";
          }
        }
        self.employeeList.profile_Pic =
          "data:image/png;base64," + self.employeeList.profile_Pic;
        // self.empCount = response.data.data.length;
      });
    },
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    GetLeaveTypeList() {
      try {
        let self = this;
        let temp = {
          company_ID: store.state.companyID,
        };
        self.LoadingDialog = true;
        Axios.post(`${self.url}TimeOff/GetLeaveTypeByCompanyID`, temp).then(
          function (response) {
            let temp = response.data.data;
            let thelist1 = [];
            thelist1.push({
              timeOff_ID: 0,
              timeoff_TypeEN: "All",
              timeoff_TypeTH: "ทั้งหมด",
              leaveStatus: true
            });
            for (let i = 0; i < temp.length; i++) {
              thelist1.push({ ...temp[i] });
            }
            self.leaveTypeList = thelist1.filter((v) => v.leaveStatus == true);
          }
        );
      } catch (ex) {
        throw ex;
      }
    },
    newReq() {
      this.$router.push("/EmployeesTimeOffRequest/New");
      // this.$router.push({ name: "MyOTrequest" });
    },
    Cancelconfirmtimeoffreq(ID) {
      this.Deletedialog = true;
      this.timeoffCancelID = ID;
      // console.log(ID);
    },
    Canceldialog() {
      this.Deletedialog = false;
      this.timeoffCancelID = 0;
    },
    async Deleterow() {
      try {
        if (this.txtreason != "") {
          let temp = {
            reason: this.txtreason,
            time_off_RequestID: this.timeoffCancelID,
          };
          this.LoadingDialog = true;
          const response = await Axios.post(
            `${this.url}TimeOff_Request/CancelTimeOff`,
            temp
          );
          if (response.data.status == 0) {
            this.LoadingDialog = false;
            alert(response.data.message);
            this.Deletedialog = false;
            this.timeoffCancelID = 0;
            this.txtreason = "";
            this.GetTimeOff();
          }
        } else {
          this.LoadingDialog = false;
          alert("Please fill required field");
        }
      } catch (ex) {
        throw ex;
      }
    },
    convertUTCDateToLocalDate(date) {
      let stillUtc = moment.utc(date).toDate(); //still utc
      let localDate = moment(stillUtc).local().format("DD/MM/YYYY");

      return localDate;
    },
    Changecolor(status) {
      let mycolor = "";

      if (status == "Approved") {
        mycolor = "#22A534";
      } else if (status == "Denied" || status == "Canceled") {
        mycolor = "#FF6565";
      } else {
        mycolor = "#000000";
      }
      return mycolor;
    },
    disableDate(value) {
      const date = new Date(value);
      const today = new Date();
      var lastmonth = new Date();
      lastmonth.setMonth(lastmonth.getMonth() - 4);
      lastmonth.setDate(lastmonth.getDate() - 31);
      if (date <= today && date > lastmonth) {
        return date;
      }
    },
    Search() {
      let self = this;
      self.runningvalue = 1;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.hrTimeOffList;
        self.hrTimeOffList = [];
        let temp = List.filter((v) =>
          v.employee_Name.toLowerCase().includes(self.searchtxt.toLowerCase())
        );

        for (let i = 0; i < temp.length; i++) {
          self.hrTimeOffList.push(temp[i]);
        }
      } else {
        alert("Please fill in search field");
        self.GetTimeOff();
      }
    },
    async Getbydatelist() {
      //fro from and to date filter
      try {
        // let temp = {
        //   company_ID: store.state.companyID,
        //   employee_ID: store.state.employeeID,
        //   requester_ID: store.state.reportID,
        // };
        // this.LoadingDialog = true;
        // const response = await Axios.post(
        //   `${this.url}TimeOff_Request/GetTimeOff_RequestForHr`,
        //   temp
        // );
        // let list = response.data.data;

        let filterList = this.filterlist.filter((v) => {
          let stillUtc = moment.utc(v.apporve_Date).toDate(); //still utc
          let request_On = moment(stillUtc).local().format("YYYY-MM-DD");

          return (
            request_On >= moment(this.reportDateFrom).format("YYYY-MM-DD") &&
            request_On <= moment(this.reportDateTo).format("YYYY-MM-DD")
          );
        });

        this.hrTimeOffList = filterList.map((e) => ({
          ...e,
          check: false,
        }));
        this.LoadingDialog = false;
      } catch (ex) {
        this.LogTrace(ex, "Get TimeOff Fail", 31, "Critical");
        alert(ex + "Correlation ID is 31");
        throw ex;
      }
    },
    checkAllDate() {
      if (!this.checkByDate) {
        this.showdatepicker = true;
        this.reportDateFrom = new Date();
        this.reportDateTo = new Date();
        this.Getbydatelist();
      } else {
        this.showdatepicker = false;
        this.GetTimeOff();
      }
    },

    cancelExport() {
      this.exportexcelDialog = false;
      if (this.checkByDate) {
        this.GetTimeOff();
      } else {
        this.Getbydatelist();
      }

      this.excel.start_Date = null;
      this.excel.end_Date = "";
    },

    exporting() {
      this.exportexcelDialog = true;
      this.results = this.hrTimeOffList.filter(
        (date) => (date.apporve_Date = date.apporve_Date + "Z")
      );

      this.liesting = [];
      for (let i = 0; i < this.results.length; i++) {
        if (this.results[i].check == false) {
          this.liesting.push(false);
        } else {
          this.liesting.push(true);
        }
      }
      this.checkData = false;

      if (this.liesting.some((e) => e == true)) {
        this.checkData = false;
      } else {
        this.checkData = true;
      }

      if (this.checkData == true) {
        this.selectusertype = "Everyone";
      } else {
        let temp = this.hrTimeOffList.filter((item) => item.check).length == 1;
        if (temp == true) {
          this.selectusertype = "Selected User";
        } else {
          this.selectusertype = "Selected Users";
        }
      }
    },

    checkSelectedAll() {
      this.hrTimeOffList.map((e) => (e.check = event.target.checked));
    },
    async GetTimeOff() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          requester_ID: store.state.reportID,
        };
        await Axios.post(
          `${self.url}TimeOff_Request/GetTimeOff_RequestForHr`,
          temp
        ).then(function (response) {
          let list = response.data.data;
          self.hrTimeOffList = list.map((e, index) => ({
            ...e,
            check: false,
            serialNumber: index + 1
          }));
          //console.log(self.hrTimeOffList);
          self.filterlist = self.hrTimeOffList;
          self.LoadingDialog = false;
          self.runningvalue = 0;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
      } catch (ex) {
        throw ex;
      }
    },
    ViewDetail(value) {
      let self = this;
      self.dialog = true;
      self.viewTimeOff = Object.assign({}, value);
      //console.log("value" + JSON.stringify(value));
      if (value.status == "Canceled") {
        self.showremark = true;
      } else {
        self.showremark = false;
      }
      self.viewTimeOff.request_On = self.formatDateRD(self.viewTimeOff.request_On);
      self.viewTimeOff.start_Date = self.formatDate(self.viewTimeOff.start_Date);
      self.viewTimeOff.end_Date = self.formatDate(self.viewTimeOff.end_Date);
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    checkDateExport(value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },

    ExportExcel() {
      let combileArray = [];
      this.loadingdialog = true;
      if (this.checkByDate) {
        if (this.checkData == true) {
          this.wsdata = this.results.filter((v) => {
            if (this.excel.start_Date && this.excel.end_Date) {
              const between = moment(v.apporve_Date);
              return between.isBetween(
                moment(this.excel.start_Date),
                moment(this.excel.end_Date).add(1, "day")
              );
            }

            return true;
          });
        } else {
          this.wsdata = this.results.filter((v) => v.check);
          let result = this.results.filter((o1) =>
            this.wsdata.some((o2) => o1.requester_ID === o2.requester_ID)
          );
          this.wsdata = result;
        }
        for (let v of this.wsdata) {
          combileArray.push({
            ["Employee Name"]: v.employee_Name,
            ["Time Off Type"]: v.timeoff_Name,
            ["Balance"]: v.balance + " " + "Days",
            ["Start Date"]: this.formatDate(v.start_Date),
            ["End Date"]: this.formatDate(v.end_Date),
            ["Requested Date"]: this.convertUTCDateToLocalDate(v.request_On),
            ["Status"]: v.status,
            ["Reason"]: v.reason,
            ["Approver"]: v.approver_Name,
            ["Date of Action"]: this.convertUTCDateToLocalDate(v.apporve_Date),
          });
        }

        let wb = XLSX.utils.book_new();
        let tempsheetname = "All Time Off Request History";
        let ws = XLSX.utils.json_to_sheet(combileArray);
        ws["!cols"] = [
          { wch: 20 },
          { wch: 25 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];

        XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
        this.exportexcelDialog = false;
        let exportExcelName = `OptimisticTimeOffRequestHistory_AllDate.xlsx`;

        XLSX.writeFile(wb, exportExcelName);
        this.GetTimeOff();
      } else if (this.excel.start_Date && this.excel.end_Date) {
        if (
          moment(this.excel.start_Date) <
          moment(this.excel.end_Date).add(1, "day")
        ) {
          if (this.checkData == true) {
            this.wsdata = this.results.filter((v) => {
              if (this.excel.start_Date && this.excel.end_Date) {
                const between = moment(v.apporve_Date);
                return between.isBetween(
                  moment(this.excel.start_Date),
                  moment(this.excel.end_Date).add(1, "day")
                );
              }

              return true;
            });
          } else {
            this.wsdata = this.results.filter((v) => {
              if (v.check) {
                if (this.excel.start_Date && this.excel.end_Date) {
                  const between = moment(v.apporve_Date);
                  return between.isBetween(
                    moment(this.excel.start_Date),
                    moment(this.excel.end_Date).add(1, "day")
                  );
                }
              }
            });
            let result = this.results.filter((o1) =>
              this.wsdata.some((o2) => {
                if (o1.requester_ID === o2.requester_ID) {
                  const between = moment(o1.apporve_Date);
                  return between.isBetween(
                    moment(this.excel.start_Date),
                    moment(this.excel.end_Date).add(1, "day")
                  );
                }
              })
            );

            this.wsdata = result;
          }
          let hasData = false;
          let lastSheetData = [];
          let inputdate = this.excel.start_Date.split("-");
          // eslint-disable-next-line no-unused-vars
          let mystartdate =
            inputdate[2] + "/" + inputdate[1] + "/" + inputdate[0];
          let inputenddate = this.excel.end_Date.split("-");
          // eslint-disable-next-line no-unused-vars
          let myenddate =
            inputenddate[2] + "/" + inputenddate[1] + "/" + inputenddate[0];
          let wb = XLSX.utils.book_new();
          let mynewstartdate = new Date(
            inputdate[0],
            inputdate[1],
            inputdate[2]
          );
          let mynewenddate = new Date(
            inputenddate[0],
            inputenddate[1],
            inputenddate[2]
          );

          let Difference_In_Time =
            mynewenddate.getTime() - mynewstartdate.getTime();
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
          let totalsheet = Difference_In_Days;
          for (let sheet = 0; sheet < totalsheet + 1; sheet++) {
            let tempsheetdata = [];
            let input = parseInt(inputdate[2]) + sheet;

            combileArray = [];
            let startoftheyear = new Date(
              inputdate[0],
              inputdate[1] - 1,
              input
            );

            tempsheetdata = this.wsdata.filter((x) => {
              let tempdate2 = new Date(x.apporve_Date);
              let date2 = new Date(
                tempdate2.getFullYear(),
                tempdate2.getMonth(),
                tempdate2.getDate()
              );

              return date2.getTime() == startoftheyear.getTime();
            });
            let tempsheetname =
              this.checkDateExport(startoftheyear.getDate()) +
              "_" +
              this.checkDateExport(startoftheyear.getMonth() + 1) +
              "_" +
              startoftheyear.getFullYear();
            if (this.wsdata.length != lastSheetData.length) {
              lastSheetData = [...lastSheetData, ...tempsheetdata];
            }
            if (tempsheetdata.length) {
              for (let j = 0; j < tempsheetdata.length; j++) {
                combileArray.push({
                  ["Employee Name"]: tempsheetdata[j].employee_Name,
                  ["Time Off Type"]: tempsheetdata[j].timeoff_Name,
                  ["Balance"]: tempsheetdata[j].balance + " " + "Days",
                  ["Start Date"]: this.formatDate(tempsheetdata[j].start_Date),
                  ["End Date"]: this.formatDate(tempsheetdata[j].end_Date),
                  ["Requested Date"]: this.convertUTCDateToLocalDate(
                    tempsheetdata[j].request_On
                  ),
                  ["Status"]: tempsheetdata[j].status,
                  ["Reason"]: tempsheetdata[j].reason,
                  ["Approver"]: tempsheetdata[j].approver_Name,
                  ["Date of Action"]: this.convertUTCDateToLocalDate(
                    tempsheetdata[j].apporve_Date
                  ),
                });
              }
              var ws = XLSX.utils.json_to_sheet(combileArray);
              ws["!cols"] = [
                { wch: 20 },
                { wch: 25 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
              ];
              XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
              hasData = true;
            }
          }
          if (!hasData) {
            alert("Your selected date range doesn't have Data!");
          } else {
            let wsalldata = [];
            lastSheetData.sort(function (a, b) {
              var order = 1;
              a = a.apporve_Date;
              b = b.apporve_Date;
              var results = a > b ? -1 : a < b ? 1 : 0;

              return results * order;
            });
            // lastSheetData.reverse();
            for (let j = 0; j < lastSheetData.length; j++) {
              wsalldata.push({
                ["Employee Name"]: lastSheetData[j].employee_Name,
                ["Time Off Type"]: lastSheetData[j].timeoff_Name,
                ["Balance"]: lastSheetData[j].balance + " " + "Days",
                ["Start Date"]: this.formatDate(lastSheetData[j].start_Date),
                ["End Date"]: this.formatDate(lastSheetData[j].end_Date),
                ["Requested Date"]: this.convertUTCDateToLocalDate(
                  lastSheetData[j].request_On
                ),
                ["Status"]: lastSheetData[j].status,
                ["Reason"]: lastSheetData[j].reason,
                ["Approver"]: lastSheetData[j].approver_Name,
                ["Date of Action"]: this.convertUTCDateToLocalDate(
                  lastSheetData[j].apporve_Date
                ),
              });
            }
            ws = XLSX.utils.json_to_sheet(wsalldata);
            ws["!cols"] = [
              { wch: 20 },
              { wch: 25 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];

            let datestring1 =
              inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
            let datestring2 =
              inputenddate[2] + "_" + inputenddate[1] + "_" + inputenddate[0];
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              datestring1 + "-" + datestring2
            );
          }
          let datestring1 =
            inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
          let datestring2 =
            inputenddate[2] + "_" + inputenddate[1] + "_" + inputenddate[0];
          if (hasData) {
            let exportExcelName = `OptimisticTimeOffRequestHistory_${datestring1}-${datestring2}.xlsx`;

            self.exportList = [];

            XLSX.writeFile(wb, exportExcelName);
          }
          this.exportexcelDialog = false;
          this.excel.start_Date = "";
          this.excel.end_Date = "";
          this.Getbydatelist();
        } else {
          alert("Start date can’t be after end date");
        }
      } else {
        alert("Please select the date to export");
      }
    },
    exportit() {
      let self = this;
      self.LoadingDialog = true;
      if (self.hrTimeOffList.length != 0) {
        let combileArray = [];
        let wb = XLSX.utils.book_new();
        for (let v of self.hrTimeOffList) {
          if (localStorage.Templang == "ประเทศไทย") {
            combileArray.push({
              ["ชื่อพนักงาน"]: v.employee_Name,
              ["ประเภทวันลา"]: v.timeoff_Name,
              ["สมดุล"]: v.balance + " " + "Days",
              ["วันเริ่มใช้โปรแกรม"]: this.formatDate(v.start_Date),
              ["วันที่สิ้นสุด"]: this.formatDate(v.end_Date),
              ["วันที่ส่งคำขอ"]: this.convertUTCDateToLocalDate(v.request_On),
              ["สถานะ"]: v.status,
              ["เหตุผล"]: v.reason,
              ["ผู้อนุมัติ"]: v.approver_Name,
              ["วันที่ดำเนินการ"]: this.convertUTCDateToLocalDate(v.apporve_Date),
            });
          } else {
            if (sessionStorage.flag == "Thai") {
              combileArray.push({
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["ประเภทวันลา"]: v.timeoff_Name,
                ["สมดุล"]: v.balance + " " + "Days",
                ["วันเริ่มใช้โปรแกรม"]: this.formatDate(v.start_Date),
                ["วันที่สิ้นสุด"]: this.formatDate(v.end_Date),
                ["วันที่ส่งคำขอ"]: this.convertUTCDateToLocalDate(v.request_On),
                ["สถานะ"]: v.status,
                ["เหตุผล"]: v.reason,
                ["ผู้อนุมัติ"]: v.approver_Name,
                ["วันที่ดำเนินการ"]: this.convertUTCDateToLocalDate(v.apporve_Date),
              });
            } else {
              combileArray.push({
                ["Employee Name"]: v.employee_Name,
                ["Time Off Type"]: v.timeoff_Name,
                ["Balance"]: v.balance + " " + "Days",
                ["Start Date"]: this.formatDate(v.start_Date),
                ["End Date"]: this.formatDate(v.end_Date),
                ["Requested Date"]: this.convertUTCDateToLocalDate(v.request_On),
                ["Status"]: v.status,
                ["Reason"]: v.reason,
                ["Approver"]: v.approver_Name,
                ["Date of Action"]: this.convertUTCDateToLocalDate(v.apporve_Date),
              });
            }
          }
        }

        let tempsheetname = "All Time Off Request History";
        let ws = XLSX.utils.json_to_sheet(combileArray);
        ws["!cols"] = [
          { wch: 20 },
          { wch: 25 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];

        XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
        this.exportexcelDialog = false;
        let exportExcelName = `OptimisticTimeOffRequestHistory_AllDate.xlsx`;

        XLSX.writeFile(wb, exportExcelName);
        self.LoadingDialog = false;

      } else {
        alert("Your selected date range doesn't have Data!");
        self.LoadingDialog = false;
      }
    },
    formatDateRD(item) {
      if (item != "-" && item != null && item != "") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        output = this.padZero(newDate.getDate()) + "/" +
            this.padZero((newDate.getMonth() + 1)) + "/" + newDate.getFullYear() +
          " " + this.padZero(newDate.getHours()) + ":" + this.padZero(newDate.getMinutes());
        
        return output;
      } else {
        return "-";
      }
    },
    padZero(num) {
      return num.toString().padStart(2, '0');
    },

  },
};
</script>
<style scoped>
.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

.form-checkbox input:checked~.checkmark {
  background-color: #f99d20;
}

>>>.form-checkbox input.white:checked~.checkmark:after {
  border: solid white;
  border-width: 0 3px 3px 0;
}

/* Customize the label (the container) */
.form-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #7f7171 !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  color: #f99d20;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

.tdborder {
  border-right: 1px solid #cfcdcd;
}

>>>.mx-input {
  height: 40px;
}

>>>.mx-input-wrapper:hover input[type="text"] {
  border-color: #f99d20;
}

>>>.mx-input-wrapper input[type="text"]:focus {
  border-color: #f99d20;
}

@media (min-width: 1904px){
  >>>.thlr.col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 6.333333%;
}
}



</style>
